<div class="breadcrumb-container" [ngClass]="{ 'breadcrumb-container-compact': compactSidebar }" id="breadcrumb">
  <div class="breadcrumb-component">
    <app-breadcrumb
      [parents]="breadcrumbParents"
      [self]="breadcrumbSelf">
    </app-breadcrumb>
  </div>
  <div class="breadcrumb-menu d-flex">
    <!--Bulk disable-->
    <div class="breadcrumb-button-icon-container cursor-pointer" *ngIf="rightModel.stockItemDisableBulk.hasRight()"
         href="#" onclick="return false" (click)="openBulkDisableDialog()">
      <a class="btn-setting d-flex align-items-center" title="{{'STOCK_ITEM_BULK_DISABLE_TITLE' | translate}}">
        <i class="icomoon icomoon-bulk-operation"></i>
        <span class="breadcrumb-button-text-visible">{{'STOCK_ITEM_BULK_DISABLE_TITLE' | translate}}</span>
      </a>
    </div>
    <!--Import-->
    <div class="breadcrumb-button-icon-container cursor-pointer" *ngIf="rightModel.stockItemImportFile.hasRight()"
         href="#" onclick="return false" (click)="importDialog.toggleDialog()">
      <a class="btn-setting d-flex align-items-center" title="{{'COMMON_BUTTON_IMPORT' | translate}}">
        <i class="icomoon icomoon-csv-import"></i>
        <span class="breadcrumb-button-text-visible">{{'COMMON_BUTTON_IMPORT' | translate}}</span>
      </a>
    </div>
    <!--DROPDOWN FOR XLS IMPORT-->
    <div class="breadcrumb-button-icon-container-no-text cursor-pointer p-0"
         *ngIf="rightModel.stockItemImportFile.hasRight()">
      <a class="dropdown dropdown-arrow-padding"
         dropdown title="{{'COMMON_EXPORT_XLSX' | translate}}"
         href="#" onclick="return false" dropdownToggle
         data-toggle="dropdown" role="button"
         aria-haspopup="true" aria-expanded="false">
        <i class="icomoon icomoon-dropdown">
        </i>
        <div class="dropdown-menu dropdown-menu-right"
             *dropdownMenu
             aria-labelledby="simple-dropdown">
          <span (click)="exportXlsTemplate()"
                class="btn-setting dropdown-item d-flex align-items-center"
                title="{{'ORDER_XLS_IMPORT_SAMPLE_DOWNLOAD' | translate}}">
            <i class="icomoon icomoon-file-xlsx"></i>
            <span>{{'ORDER_XLS_IMPORT_SAMPLE_DOWNLOAD' | translate}}</span>
          </span>
        </div>
      </a>
    </div>
    <!--Export-->
    <div class="breadcrumb-button-icon-container cursor-pointer" *ngIf="rightModel.stockItemExportFile.hasRight()"
         href="#" onclick="return false" (click)="exportXls()">
      <a class="btn-setting d-flex align-items-center" title="{{'COMMON_BUTTON_EXPORT' | translate}}">
        <i class="icomoon icomoon-csv-export"></i>
        <span class="breadcrumb-button-text-visible">{{'COMMON_BUTTON_EXPORT' | translate}}</span>
      </a>
    </div>
    <!--CREATE-->
    <div>
      <div *ngIf="rightModel.stockItemCreate.hasRight()" (click)="openStockItemCreateTypeSelectorDialog()"
           class="breadcrumb-button-icon-container cursor-pointer negative-margin-right">
        <a class="btn-setting d-flex align-items-center"
           title="{{'COMMON_BUTTON_ADD' | translate}}">
          <i class="icomoon icomoon-add"></i>
          <span class="breadcrumb-button-text-visible">{{'COMMON_BUTTON_ADD' | translate}}</span>
        </a>
      </div>
    </div>
  </div>
</div>
<div class="breadcrumb-placeholder"></div>
<div class="container-horizontal-padding container-vertical-padding animated fadeIn">

  <div class="card">

    <div class="card-header">
      {{'STOCK_ITEM_PANEL_HEADING_LIST' | translate}}

      <div class="card-actions">
        <a class="btn-setting cursor-pointer" (click)="toggleSearch()" title="{{'COMMON_BUTTON_ADD' | translate}}"><i
          class="icomoon icomoon-search"></i></a>
      </div><!-- /.card-actions -->
    </div>

    <div class="card-body border-bottom" *ngIf="showSearch">
      <div class="row form-group">
        <div class="col-md">
          <label class="search-label">{{'COMMON_NAME' | translate}}</label>
          <input type="text" class="form-control" placeholder="{{'COMMON_NAME' | translate}}"
                 maxlength="{{UiConstants.maximumVarcharLength}}"
                 (keyup.enter)="onSearchClicked()"
                 [(ngModel)]="searchModel.name" name="name" id="name" #name="ngModel">
        </div>
        <div class="col-md">
          <label class="search-label">{{'STOCK_ITEM_TABLE_HEADER_EXTERNAL_ID' | translate}}</label>
          <input type="text" class="form-control" placeholder="{{'STOCK_ITEM_TABLE_HEADER_EXTERNAL_ID' | translate}}"
                 maxlength="{{UiConstants.maximumVarcharLength}}"
                 (keyup.enter)="onSearchClicked()"
                 [(ngModel)]="searchModel.externalId" name="externalId" id="externalId" #externalId="ngModel">
        </div>
        <div class="col-md">
          <label class="search-label">{{'STOCK_ITEM_TABLE_HEADER_PRODUCT_CODE' | translate}}</label>
          <input type="text" class="form-control" placeholder="{{'STOCK_ITEM_TABLE_HEADER_PRODUCT_CODE' | translate}}"
                 maxlength="{{UiConstants.maximumVarcharLength}}"
                 (keyup.enter)="onSearchClicked()"
                 [(ngModel)]="searchModel.productCode" name="productCode" id="productCode" #productCode="ngModel">
        </div>
        <div class="col-md">
          <label class="search-label">{{'STOCK_ITEM_COMPANY' | translate}}</label>
          <angular2-multiselect [data]="companies"
                                [settings]="dropdownSettingsForCompanies"
                                [(ngModel)]="searchModel.companies"
                                (onRemoteSearch)="loadCompanies($event.target.value)"
                                name="company" id="company" #company="ngModel">
          </angular2-multiselect>
        </div>
      </div>
      <div class="row form-group">
        <div class="col-md" *ngIf="rightModel.ledgerNumberRead.hasRight()">
          <label class="search-label">{{'LEDGER_NUMBER_LEDGER_NUMBER' | translate}}</label>
          <angular2-multiselect [data]="ledgerNumbers"
                                [settings]="dropdownSettingsForCompanies"
                                [(ngModel)]="searchModel.ledgerNumbers"
                                (onRemoteSearch)="loadLedgerNumbers($event.target.value)"
                                name="ledgerNumber" id="ledgerNumber" #ledgerNumber="ngModel">
          </angular2-multiselect>
        </div>
        <div class="col-md">
          <label class="search-label">{{'STOCK_ITEM_CREATED_ON_LABEL' | translate}}</label>
          <angular2-multiselect [data]="applicationClassifications"
                                [settings]="dropdownSettingsLocalMulti"
                                [(ngModel)]="searchModel.createdOn"
                                name="createdOn" id="createdOn" #createdOn="ngModel">
          </angular2-multiselect>
        </div>
        <div class="col-md">
          <label class="search-label">{{'STOCK_ITEM_VTSZ_NUMBER' | translate}}</label>
          <input type="text" class="form-control" placeholder="{{'STOCK_ITEM_VTSZ_NUMBER' | translate}}"
                 maxlength="{{UiConstants.maximumVarcharLength}}"
                 (keyup.enter)="onSearchClicked()"
                 [(ngModel)]="searchModel.vtszNumber" name="vtszNumber" id="vtszNumber" #vtszNumber="ngModel">
        </div>
        <div class="col-md">
          <label class="search-label">{{'STOCK_ITEM_UNIT' | translate}}</label>
          <input type="text" class="form-control" placeholder="{{'STOCK_ITEM_UNIT' | translate}}"
                 maxlength="{{UiConstants.maximumVarcharLength}}"
                 (keyup.enter)="onSearchClicked()"
                 [(ngModel)]="searchModel.unit" name="unit" id="unit" #unit="ngModel">
        </div>
        <div class="col-md">
          <label class="search-label">{{'COMMON_TYPE' | translate}}</label>
          <select class="form-control"
                  [(ngModel)]="searchModel.type"
                  [compareWith]="SelectUtils.compareObjects"
                  name="type" id="type"
                  #type="ngModel">
            <option *ngFor="let type of stockItemTypes" [ngValue]="type">
              {{type.text}}
            </option>
          </select>
        </div>
      </div>
      <div class="row form-group">
        <div class="col-md">
          <label class="search-label">{{'STOCK_ITEM_UNIT_PRICE_FROM' | translate}}</label>
          <input type="text" class="form-control" placeholder="{{'STOCK_ITEM_UNIT_PRICE_FROM' | translate}}"
                 maxlength="{{UiConstants.maximumVarcharLength}}"
                 (keyup.enter)="onSearchClicked()"
                 [textMask]="{mask: InputMask.NATURAL_FLOAT_WITH_THOUSAND_SEPARATOR, guide: true}"
                 [(ngModel)]="searchModel.unitPriceFrom" name="unitPriceFrom" id="unitPriceFrom"
                 #unitPriceFrom="ngModel">
        </div>
        <div class="col-md">
          <label class="search-label">{{'STOCK_ITEM_UNIT_PRICE_TO' | translate}}</label>
          <input type="text" class="form-control" placeholder="{{'STOCK_ITEM_UNIT_PRICE_TO' | translate}}"
                 maxlength="{{UiConstants.maximumVarcharLength}}"
                 (keyup.enter)="onSearchClicked()"
                 [textMask]="{mask: InputMask.NATURAL_FLOAT_WITH_THOUSAND_SEPARATOR, guide: true}"
                 [(ngModel)]="searchModel.unitPriceTo" name="unitPriceTo" id="unitPriceTo" #unitPriceTo="ngModel">
        </div>
        <div class="col-md">
          <label class="search-label">{{'STOCK_ITEM_UNIT_PRICE_CURRENCY_CODE' | translate}}</label>
          <select class="form-control"
                  [(ngModel)]="searchModel.unitPrice.currency"
                  [compareWith]="Currency.compareCurrencies"
                  name="unit_price_currency_code" id="unit_price_currency_code"
                  #unit_price_currency_code="ngModel">
            <option *ngFor="let currency of selectableCurrencies" [ngValue]="currency">
              {{currency.localizedName}}
            </option>
          </select>
        </div>
        <div class="col-md">
          <label class="search-label">{{'STOCK_ITEM_UNIT_VAT_RATE_WITH_PERCENT' | translate}}</label>
          <input type="text" class="form-control" placeholder="{{'STOCK_ITEM_UNIT_VAT_RATE_WITH_PERCENT' | translate}}"
                 maxlength="{{UiConstants.maximumVarcharLength}}"
                 (keyup.enter)="onSearchClicked()"
                 [textMask]="{mask: InputMask.NATURAL_FLOAT_WITH_THOUSAND_SEPARATOR, guide: true}"
                 [(ngModel)]="searchModel.unitPrice.vatRate" name="unitPriceVatRate" id="unitPriceVatRate"
                 #unitPriceVatRate="ngModel">
        </div>
        <div class="col-md">
          <label class="search-label">{{'STOCK_ITEM_SHORT_PRODUCT_CODE' | translate}}</label>
          <input type="text" class="form-control" placeholder="{{'STOCK_ITEM_SHORT_PRODUCT_CODE' | translate}}"
                 maxlength="{{UiConstants.maximumVarcharLength}}"
                 (keyup.enter)="onSearchClicked()"
                 [(ngModel)]="searchModel.productCategory" name="productCategory" id="productCategory"
                 #productCategory="ngModel">
        </div>
      </div>
      <div class="row form-group">
        <div class="col-md">
          <label class="search-label">{{'STOCK_ITEM_WEIGHT_FROM' | translate}}</label>
          <input type="text" class="form-control" placeholder="{{'STOCK_ITEM_WEIGHT_FROM' | translate}}"
                 maxlength="{{UiConstants.maximumVarcharLength}}"
                 (keyup.enter)="onSearchClicked()"
                 [textMask]="{mask: InputMask.NATURAL_FLOAT_WITH_THOUSAND_SEPARATOR, guide: true}"
                 [(ngModel)]="searchModel.weightFrom" name="weightFrom" id="weightFrom"
                 #weightFrom="ngModel">
        </div>
        <div class="col-md">
          <label class="search-label">{{'STOCK_ITEM_WEIGHT_TO' | translate}}</label>
          <input type="text" class="form-control" placeholder="{{'STOCK_ITEM_WEIGHT_TO' | translate}}"
                 maxlength="{{UiConstants.maximumVarcharLength}}"
                 (keyup.enter)="onSearchClicked()"
                 [textMask]="{mask: InputMask.NATURAL_FLOAT_WITH_THOUSAND_SEPARATOR, guide: true}"
                 [(ngModel)]="searchModel.weightTo" name="weightTo" id="weightTo"
                 #weightTo="ngModel">
        </div>
        <div class="col-md">
          <label class="search-label">{{'STOCK_ITEM_PLACE' | translate}}</label>
          <input type="text" class="form-control" placeholder="{{'STOCK_ITEM_PLACE' | translate}}"
                 maxlength="{{UiConstants.maximumVarcharLength}}"
                 (keyup.enter)="onSearchClicked()"
                 [(ngModel)]="searchModel.place" name="place" id="place" #place="ngModel">
        </div>
        <div class="col-md">
          <label class="search-label">{{'STOCK_ITEM_SERIAL_CODE' | translate}}</label>
          <input type="text" class="form-control" placeholder="{{'STOCK_ITEM_SERIAL_CODE' | translate}}"
                 maxlength="{{UiConstants.maximumVarcharLength}}"
                 (keyup.enter)="onSearchClicked()"
                 [(ngModel)]="searchModel.serialCode" name="serialCode" id="serialCode" #serialCode="ngModel">
        </div>
        <div class="col-md">
          <label class="search-label">{{'STOCK_ITEM_PRODUCT_CATEGORY' | translate}}</label>
          <angular2-multiselect [data]="productCategories"
                                [settings]="dropdownSettingsForProductCategories"
                                [(ngModel)]="searchModel.categories"
                                (onRemoteSearch)="loadProductCategories($event.target.value)"
                                name="product_category" id="product_category" #product_category="ngModel">
          </angular2-multiselect>
        </div>
      </div>
      <div class="row form-group">
        <div class="col-md">
          <label class="search-label">{{'COMMON_STATE' | translate}}</label>
          <select class="form-control" [(ngModel)]="searchModel.disabled"
                  id="disabled" name="disabled" #disabled="ngModel"
                  [compareWith]="SelectUtils.compareObjects">
            <option *ngFor="let item of disabledItems" [ngValue]="item">{{item.text}}</option>
          </select>
        </div>
        <div class="col-md">
          <!--PLACEHOLDER FOR EQUAL DISTRIBUTION-->
        </div>
        <div class="col-md">
          <!--PLACEHOLDER FOR EQUAL DISTRIBUTION-->
        </div>
        <div class="col-md">
          <!--PLACEHOLDER FOR EQUAL DISTRIBUTION-->
        </div>
        <div class="col-md">
          <!--PLACEHOLDER FOR EQUAL DISTRIBUTION-->
        </div>
      </div>
      <!--Button-->
      <div class="col-md-12 d-flex justify-content-md-end align-items-end px-0">
        <div class="btn-group" role="group">
          <button type="button" (click)="onSearchReset()" class="btn btn-outline-primary search-button mr-1">
            {{'COMMON_BUTTON_SEARCH_DELETE' | translate}}
          </button>
          <button type="button" (click)="onSearchClicked()" class="btn btn-primary search-button">
            {{'COMMON_BUTTON_SEARCH' | translate}}
          </button>
        </div>
      </div>
    </div>

    <div class="card-body">
      <table class="table table-striped table-bordered">
        <thead>
        <tr>
          <th class="table-sorter-header fit">
            <app-table-field-sorter [orderField]="StockItem.OrderField.ID"
                                    [orderType]="queryModel.getOrderType(StockItem.OrderField.ID)"
                                    (orderChange)="orderBy($event)"
                                    [checkAllEnabled]="true"
                                    [checkAllSelected]="eachStockItemSelected"
                                    (onCheckAllClicked)="toggleEachStockItem()"
                                    [text]="'COMMON_TABLE_HEADER_ID' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header long">
            <app-table-field-sorter [orderField]="StockItem.OrderField.NAME"
                                    [orderType]="queryModel.getOrderType(StockItem.OrderField.NAME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_NAME' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header w-15 hidden-md-down">
            <app-table-field-sorter [orderField]="StockItem.OrderField.PRODUCT_CODE"
                                    [orderType]="queryModel.getOrderType(StockItem.OrderField.PRODUCT_CODE)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'STOCK_ITEM_TABLE_HEADER_PRODUCT_CODE' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-md-down">
            <app-table-field-sorter [orderField]="StockItem.OrderField.UNIT_VALUE"
                                    [orderType]="queryModel.getOrderType(StockItem.OrderField.UNIT_VALUE)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'STOCK_ITEM_TABLE_HEADER_UNIT_PRICE' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-md-down">
            <app-table-sorter-no-op
              [text]="'STOCK_RECORD_SELECTOR_DIALOG_TABLE_AMOUNT' | translate"></app-table-sorter-no-op>
          </th>
          <th class="w-table-options-menu">
            <app-table-sorter-no-op></app-table-sorter-no-op>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let stockItem of stockItemList | paginate: {
                                                      itemsPerPage: queryModel.itemsPerPage,
                                                      currentPage: queryModel.currentPage,
                                                      totalItems: queryModel.currentNumberOfItems }"
            [disabledTableRow]="stockItem.disabled">
          <td class="fit align-middle">
            <div class="d-flex">
              <app-animated-checkbox
                class="toggle-checkbox"
                [(selected)]="stockItem.selected">
              </app-animated-checkbox>
              {{stockItem.id}}
            </div>
          </td>
          <td class="responsive-table-column long">
            <div class="d-flex align-items-center">
              <div class="stock-item-type-icon-container mr-1">
                <i class="icomoon {{getStockItemTypeIconRes(stockItem.type)}}"
                   popover="{{getStockItemTypeText(stockItem.type)}}" triggers="mouseenter:mouseleave">
                </i>
              </div>
              <div>
                <a class="id-text cursor-pointer"
                   href="#" onclick="return false"
                   uiSref="Admin.StockItemDetail"
                   [uiParams]="{ id: stockItem.id }"
                   title="{{'COMMON_BUTTON_DETAILS' | translate}}">
                  {{stockItem.name}}
                </a>
                <div class="text-truncate table-text-subtitle">
                  {{stockItem.external_id}}
                </div>
              </div>
            </div>
          </td>
          <td class="responsive-table-column hidden-md-down">{{stockItem.product_code}}</td>
          <td class="fit hidden-md-down">{{stockItem.unit_price.value | formattedNumber }} {{ stockItem.unit_price.currency_code}}</td>
          <td class="fit hidden-md-down">
            <div *ngIf="stockItem.inStockAmount">
              <span>{{stockItem.inStockAmount | formattedNumber}} {{stockItem.unit}}</span><br/>
              <span class="table-text-subtitle"
                    *ngIf="stockItem.package_data">{{StockItemUtils.convertToPackageStr(stockItem.inStockAmount, stockItem.package_data)}}</span>
            </div>
          </td>
          <td class="align-middle">
            <app-table-options-menu>
              <app-dropdown-item
                [iconClass]="'icomoon-detail'"
                [titleStringKey]="'COMMON_BUTTON_DETAILS'"
                class="cursor-pointer"
                uiSref="Admin.StockItemDetail"
                [uiParams]="{ id: stockItem.id }">
              </app-dropdown-item>
              <app-dropdown-item
                *ngIf="rightModel.stockItemUpdate.hasRight()"
                [iconClass]="'icomoon-modify'"
                [titleStringKey]="'COMMON_BUTTON_EDIT'"
                class="cursor-pointer"
                uiSref="Admin.StockItemEdit"
                [uiParams]="{ id: stockItem.id }">
              </app-dropdown-item>
              <app-dropdown-item
                *ngIf="stockItem.disabled && rightModel.stockItemDisable.hasRight()"
                [iconClass]="'icomoon-enable'"
                [titleStringKey]="'COMMON_BUTTON_ENABLE'"
                [itemType]="DropdownItemType.ENABLE"
                class="cursor-pointer"
                (click)="setDisabled($event, stockItem, false)">
              </app-dropdown-item>
              <app-dropdown-item
                *ngIf="!stockItem.disabled && rightModel.stockItemDisable.hasRight()"
                [iconClass]="'icomoon-disable'"
                [titleStringKey]="'COMMON_BUTTON_DISABLE'"
                [itemType]="DropdownItemType.DISABLE"
                class="cursor-pointer"
                (click)="setDisabled($event, stockItem, true)">
              </app-dropdown-item>
            </app-table-options-menu>
          </td>
        </tr>
        </tbody>
        <tfoot>
        <tr>
          <th class="table-sorter-header fit">
            <app-table-field-sorter [orderField]="StockItem.OrderField.ID"
                                    [orderType]="queryModel.getOrderType(StockItem.OrderField.ID)"
                                    (orderChange)="orderBy($event)"
                                    [checkAllEnabled]="true"
                                    [checkAllSelected]="eachStockItemSelected"
                                    (onCheckAllClicked)="toggleEachStockItem()"
                                    [text]="'COMMON_TABLE_HEADER_ID' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header long">
            <app-table-field-sorter [orderField]="StockItem.OrderField.NAME"
                                    [orderType]="queryModel.getOrderType(StockItem.OrderField.NAME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_NAME' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header w-15 hidden-md-down">
            <app-table-field-sorter [orderField]="StockItem.OrderField.PRODUCT_CODE"
                                    [orderType]="queryModel.getOrderType(StockItem.OrderField.PRODUCT_CODE)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'STOCK_ITEM_TABLE_HEADER_PRODUCT_CODE' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-md-down">
            <app-table-field-sorter [orderField]="StockItem.OrderField.UNIT_VALUE"
                                    [orderType]="queryModel.getOrderType(StockItem.OrderField.UNIT_VALUE)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'STOCK_ITEM_TABLE_HEADER_UNIT_PRICE' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-md-down">
            <app-table-sorter-no-op
              [text]="'STOCK_RECORD_SELECTOR_DIALOG_TABLE_AMOUNT' | translate"></app-table-sorter-no-op>
          </th>
          <!--<th class="table-sorter-header w-10 hidden-md-down">-->
          <!--<app-table-sorter [orderField]="'creation_time'" [orderType]="queryModel.getOrderType('creation_time')"-->
          <!--(orderChange)="orderBy($event)"-->
          <!--[text]="'COMMON_TABLE_HEADER_CREATION_TIME' | translate"></app-table-sorter>-->
          <!--</th>-->
          <!--<th class="table-sorter-header w-10 hidden-md-down">-->
          <!--<app-table-sorter [orderField]="'update_time'" [orderType]="queryModel.getOrderType('update_time')"-->
          <!--(orderChange)="orderBy($event)"-->
          <!--[text]="'COMMON_TABLE_HEADER_UPDATE_TIME' | translate"></app-table-sorter>-->
          <!--</th>-->
          <th class="w-table-options-menu">
            <app-table-sorter-no-op></app-table-sorter-no-op>
          </th>
        </tr>
        </tfoot>
      </table>
      <app-table-paging
        [currentNumberOfItems]="queryModel.currentNumberOfItems"
        [totalNumberOfItems]="queryModel.totalNumberOfItems"
        [itemsPerPage]="queryModel.itemsPerPage"
        (pageChange)="pageChanged($event)"
        (itemsPerPageChange)="itemsPerPageChanged($event)">
      </app-table-paging>
    </div>
  </div>
</div>
<app-file-upload-dialog
  [uploadPath]="uploadPath"
  (onResult)="onImportSuccess($event)"
  #importDialog>

</app-file-upload-dialog>

<app-confirm-dialog
  #stockItemBulkDisableDialog
  [title]="'STOCK_ITEM_BULK_DISABLE_TITLE' | translate"
  [message]="'STOCK_ITEM_BULK_DISABLE_MESSAGE' | translate: {number: itemsToBeDisabledCount}"
  [positiveButton]="'COMMON_YES' | translate"
  (onConfirm)="bulkDisable($event)"
  [alertType]="AlertType.WARNING">
  <div class="form-group col-md-12 p-0 mt-1">
    <mat-radio-group [required]="true" [(ngModel)]="bulkDisableOperationMode">
      <mat-radio-button class="pl-0 mb-1" [value]="BulkDisableOperationMode.ENABLE"
                        [disableRipple]="true">{{'COMMON_BUTTON_ENABLE' | translate}}</mat-radio-button>
      <br>
      <mat-radio-button [value]="BulkDisableOperationMode.DISABLE"
                        [disableRipple]="true">{{'COMMON_BUTTON_DISABLE' | translate}}</mat-radio-button>
    </mat-radio-group>
  </div>
</app-confirm-dialog>
