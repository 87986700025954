<div *ngIf="!noCard || !readonly">
  <div *ngIf="!readonly || (readonly && AddressModel.PostalAddressModelType.NONE !== m.type)" class="card"
       [formGroup]="formGroup">
    <div class="card-header">
      {{label | translate}}
      <div class="card-actions">
        <a *ngIf="!readonly && AddressModel.PostalAddressModelType.NONE === m.type" href="#" onclick="return false" (click)="setSelectedItemToComplex()" title="{{'COMMON_BUTTON_ADD' | translate}}">
          <i class="icomoon icomoon-add"></i>
        </a>
        <a *ngIf="!readonly && AddressModel.PostalAddressModelType.NONE !== m.type && !autoAdd" href="#" onclick="return false" (click)="setSelectedItemToNone()" title="{{'COMMON_BUTTON_DELETE' | translate}}">
          <i class="icomoon icomoon-trash"></i>
        </a>
      </div>
    </div>
    <ng-container *ngIf="!readonly">
      <div [ngClass]="{'edit-form-input-group': !innerField}"
           class="mw-100 pl-0 pr-0">

        <div class="card-body text-center" *ngIf="!readonly && AddressModel.PostalAddressModelType.NONE === m.type">
          <p class="empty-form-hint-text">{{ 'POSTAL_ADDRESS_ADD_TEXT' | translate }}</p>
          <button type="button" class="btn btn-secondary" (click)="setSelectedItemToComplex()">{{ 'COMMON_BUTTON_ADD' |
            translate }}
          </button>
        </div>

        <div
          *ngIf="AddressModel.PostalAddressModelType.NONE !== m.type"
          class="card-body">

          <div>

            <app-postal-address-complex
              [form]="form"
              [model]="model"
              [readonly]="readonly"
              [allowSimpleAddress]="true"
              [fieldRatio]="fieldRatio">
            </app-postal-address-complex>

          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="readonly">
      <div class="card-body">
        <div class="row" *ngIf="AddressModel.PostalAddressModelType.NONE !== m.type">
          <label
                 class="col-form-label detail-description col-md-8">{{Address.PostalAddressMapper.toString(m, config.postalAddressFormat)}}</label>
        </div>
        <div class="row" *ngIf="AddressModel.PostalAddressModelType.NONE !== m.type && m.complexValue.name.length > 0">
          <label
                 class="col-form-label detail-description col-md-8">{{m.complexValue.name}}</label>
        </div>
      </div>
    </ng-container>
  </div>
</div>
<div *ngIf="noCard && readonly">
  <h6 class="noCard-title">{{label | translate}}</h6>
  <div class="row">
    <label *ngIf="AddressModel.PostalAddressModelType.NONE !== m.type"
           class="col-form-label detail-description col-md-8">{{Address.PostalAddressMapper.toString(m, config.postalAddressFormat)}}</label>
  </div>
</div>
