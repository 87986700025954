/* eslint-disable */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Vehicle, VehicleService } from '../../../lib/vehicles/vehicle.service';
import { OrderType, QueryResult } from '../../../lib/util/services';
import { BreadcrumbParent } from '../../../shared/breadcrumb/breadcrumb/breadcrumb.component';
import { StateName } from '../../../app.state-names';
import { TranslateService } from '@ngx-translate/core';
import { RightModel } from '../../../app.rights';
import { RightResolver, RightService } from '../../../lib/right.service';
import { EmptyMessage, } from '../../../lib/util/messages';
import { OptionItem, QueryFieldModel, SelectUtils, UiConstants } from '../../../util/core-utils';
import { Strings } from '../../../lib/util/strings';
import { Set } from 'immutable';
import { VehicleSearch, VehicleSearchService } from '../../../lib/vehicles/vehicle-search.service';
import { combineLatest, Observable } from 'rxjs';
import { Arrays } from '../../../lib/util/arrays';
import { InputMask } from '../../../util/input-masks';
import { Models } from '../../../util/model-utils';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Company, CompanyService } from '../../../lib/company/company.service';
import { Angular2Multiselects } from '../../../util/multiselect';
import { DisabledEnum, DisabledItem } from '../../../util/search-utils';
import { DropdownItemType } from '../../../shared/dropdown/dropdown-item/dropdown-item-type';

/* eslint-enable */


@Component({
  selector: 'app-vehicle-list',
  templateUrl: './vehicle-list.component.html',
  styleUrls: ['./vehicle-list.component.scss']
})
export class VehicleListComponent implements OnInit, OnDestroy {
  Vehicle = Vehicle;
  InputMask = InputMask;
  SelectUtils = SelectUtils;
  UiConstants = UiConstants;
  DropdownItemType = DropdownItemType;


  queryModel: QueryFieldModel<Vehicle.OrderField> = new QueryFieldModel(Vehicle.OrderField.ID, OrderType.DESC);
  searchModel: VehicleSearchModel = new VehicleSearchModel();

  rightModel: RightModel = RightModel.empty();
  compactSidebar: boolean = document.querySelector('body')!.classList.contains('sidebar-compact');
  breadcrumbParents: BreadcrumbParent[] = [];
  breadcrumbSelf: string;
  showSearch: boolean = false;
  disabledItems: DisabledItem[] = [];

  dropdownSettingsForCompany: Angular2Multiselects.Settings;
  companyItems: CompanyModel[] = [];
  companyForSearch: VehicleSearch.CompanyItem[] = [];

  searchResult: VehicleSearch.SearchDataResult;

  vehicleList: VehicleModel[] = [];

  constructor(private vehicleService: VehicleService,
              private companyService: CompanyService,
              private translateService: TranslateService,
              private rightService: RightService,
              private vehicleSearchService: VehicleSearchService) {
  }

  ngOnInit() {
    this.initDropdownSettings();
    this.loadRightModels();
    this.initBreadcrumb();
    this.loadSearch(() => {
      this.showSearch = !this.searchModel.isEmpty();
      this.initDisabledOptions(DisabledEnum.FALSE);
      this.loadList();
    });
  }

  loadList(pageNumber?: number) {
    const requestedPage = pageNumber ? pageNumber : this.queryModel.currentPage;
    const order = this.queryModel.getOrder();
    const idSet: number[] = [];
    if (Strings.undefinedOrNonEmpty(this.searchModel.id)) {
      idSet.push(+this.searchModel.id);
    }
    const disabled: boolean | undefined = !this.searchModel.disabled ||
    this.searchModel.disabled.id === DisabledEnum.NONE ?
      undefined : this.searchModel.disabled.id === DisabledEnum.TRUE;

    const payloadKgFrom = Strings.undefinedOrNonEmpty(this.searchModel.payloadKgFrom);
    const payloadKgTo = Strings.undefinedOrNonEmpty(this.searchModel.payloadKgTo);
    const totalWeightKgFrom = Strings.undefinedOrNonEmpty(this.searchModel.totalWeightKgFrom);
    const totalWeightKgTo = Strings.undefinedOrNonEmpty(this.searchModel.totalWeightKgTo);

    this.vehicleService.query({
      id: idSet.length > 0 ? Set.of(...idSet) : undefined,
      externalId: Strings.undefinedOrNonEmpty(this.searchModel.externalId),
      model: Strings.undefinedOrNonEmpty(this.searchModel.model),
      registrationType: Strings.undefinedOrNonEmpty(this.searchModel.registrationType),
      licencePlate: Strings.undefinedOrNonEmpty(this.searchModel.licencePlate),
      companyIds: Set.of(...this.searchModel.companyIds.map(t => t.id)),
      payloadKgFrom: payloadKgFrom ? +payloadKgFrom : undefined,
      payloadKgTo: payloadKgTo ? +payloadKgTo : undefined,
      totalWeightKgFrom: totalWeightKgFrom ? +totalWeightKgFrom : undefined,
      totalWeightKgTo: totalWeightKgTo ? +totalWeightKgTo : undefined,
      registrationDateFrom: Models.ngbDateToLocalDate(this.searchModel.registrationDateFrom),
      registrationDateTo: Models.ngbDateToLocalDate(this.searchModel.registrationDateTo),
      etollRequired: this.searchModel.etollRequired ? this.searchModel.etollRequired : undefined,
      disabled: disabled,
      orders: Set.of(order),
      paging: requestedPage ? {
        pageNumber: requestedPage,
        numberOfItems: this.queryModel.itemsPerPage
      } : undefined
    }).subscribe((result: QueryResult<Vehicle.Vehicle>) => {
      this.vehicleList = [];
      result.items.forEach((vehicle: Vehicle.Vehicle) => {
        const vehicleModel = new VehicleModel();
        vehicleModel.id = vehicle.id + '';
        vehicleModel.licencePlate = vehicle.licencePlate;
        vehicleModel.etollRequired = vehicle.etollRequired;
        vehicleModel.externalId = vehicle.externalId;
        vehicleModel.model = vehicle.model ? vehicle.model : '';
        vehicleModel.kmCounter = vehicle.kmCounter;
        vehicleModel.company = vehicle.company;
        vehicleModel.ownerPersonName = vehicle.ownerPersonName ? vehicle.ownerPersonName : '';
        vehicleModel.disabled = vehicle.disabled;
        this.vehicleList.push(vehicleModel);
      });
      this.queryModel.currentPage = requestedPage;
      this.queryModel.totalNumberOfItems = result.pagingResult.totalNumberOfItems;
      this.queryModel.currentNumberOfItems = result.pagingResult.currentNumberOfItems;
    });
  }

  loadCompany(completion: () => void) {
    this.companyService.query({
      disabled: false,
      orders: Set.of({field: Company.OrderField.NAME, type: OrderType.ASC})
    }).subscribe((result: QueryResult<Company.Company>) => {
      this.companyItems = [];
      result.items.forEach((company: Company.Company) => {
        const companyModel = new CompanyModel();
        companyModel.id = company.id;
        companyModel.name = company.name;
        this.companyItems.push(companyModel);
      });
      completion();
    });

  }

  initDropdownSettings() {
    this.dropdownSettingsForCompany = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(false)
      .enableSearchFilter(true)
      .enableCheckAll(true)
      .build();
  }


  initBreadcrumb() {
    this.translateService.get('MENU_NAVBAR_NAV_TITLE_VEHICLES').subscribe(
      (result: string) => {
        this.breadcrumbSelf = result;
      }
    );
    this.translateService.get('MENU_NAVBAR_MENU_ADMINISTRATION').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.ADMIN_DASHBOARD});
      }
    );
  }

  orderBy(field: Vehicle.OrderField) {
    this.queryModel.onOrderFieldChanged(field);
    this.loadList(1);
  }

  private initDisabledOptions(initValue: DisabledEnum) {
    this.disabledItems = [];
    const disabledEnums: DisabledEnum[] = [DisabledEnum.NONE, DisabledEnum.FALSE, DisabledEnum.TRUE];
    Arrays.iterateByIndex(disabledEnums, (key) => {
      const item = new DisabledItem();
      item.id = key;
      this.translateService.get('COMMON_VALUE_DISABLED_ENUM_' + key).subscribe(
        (text: string) => {
          item.text = text;
        }
      );
      this.disabledItems.push(item);
      if (!this.searchModel.disabled) {
        if (key === initValue) {
          this.searchModel.disabled = item;
        }
      }
    });
  }

  pageChanged(selectedPage: number) {
    this.loadList(selectedPage);
  }

  itemsPerPageChanged(itemsPerPage: number) {
    this.queryModel.itemsPerPage = itemsPerPage;
    this.loadList(1);
  }

  private loadRightModels() {
    this.rightService.getRightResolver().subscribe(
      (resolver: RightResolver) => {
        this.rightModel = RightModel.of(resolver);
      }
    );
  }

  toggleSearch() {
    this.showSearch = !this.showSearch;
  }

  onSearchClicked() {
    this.loadList(1);
  }

  onSearchReset() {
    this.vehicleSearchService.resetSearchData({}).subscribe(
      (result) => {
        this.loadSearch(() => {
          this.showSearch = true;
          this.loadList(1);
        });
      }
    );
  }


  private loadSearch(completion: () => void) {
    const obs: Observable<SearchLoadResult> = combineLatest(
      this.vehicleSearchService.getSearchData({}),
      (storedSearchData: VehicleSearch.SearchDataResult) => {
        const result: SearchLoadResult = {
          storedSearchData: storedSearchData
        };
        return result;
      }
    );
    obs.subscribe(
      (result: SearchLoadResult) => {
        this.loadCompaniesForSearch(result.storedSearchData, () => {
          this.searchResult = result.storedSearchData;
          this.postInitSearch(result.storedSearchData, completion);
        });
      }
    );

  }


  private loadCompaniesForSearch(storedSearchData: VehicleSearch.SearchDataResult, completion: () => void) {
    this.searchModel.companyIds = [];
    this.loadCompany(() => {
      this.companyForSearch = [];
      this.companyItems.forEach((state) => {
        const item = new VehicleSearch.CompanyItem();
        item.id = state.id;
        item.name = state.name;
        this.companyForSearch.push(item);
        if (storedSearchData.searchData.companyIds.find(t => t.id === item.id)) {
          this.searchModel.companyIds.push(item);
        }
      });
      completion();
    });
  }

  private postInitSearch(storedSearchData: VehicleSearch.SearchDataResult, completion: () => void) {
    this.queryModel.itemsPerPage = storedSearchData.searchData.itemsPerPage;
    this.queryModel.currentPage = storedSearchData.searchData.pageNumber;
    this.queryModel.setOrder(storedSearchData.searchData.order);
    this.searchModel.id = storedSearchData.searchData.id;
    this.searchModel.externalId = storedSearchData.searchData.externalId;
    this.searchModel.model = storedSearchData.searchData.model;
    this.searchModel.registrationType = storedSearchData.searchData.registrationType;
    this.searchModel.licencePlate = storedSearchData.searchData.licencePlate;
    this.searchModel.payloadKgFrom = storedSearchData.searchData.payloadKgFrom;
    this.searchModel.payloadKgTo = storedSearchData.searchData.payloadKgTo;
    this.searchModel.totalWeightKgFrom = storedSearchData.searchData.totalWeightKgFrom;
    this.searchModel.totalWeightKgTo = storedSearchData.searchData.totalWeightKgTo;
    this.searchModel.registrationDateFrom = Models.localDateToNgbDate(storedSearchData.searchData.registrationDateFrom);
    this.searchModel.registrationDateTo = Models.localDateToNgbDate(storedSearchData.searchData.registrationDateTo);
    this.searchModel.etollRequired = storedSearchData.searchData.etollRequired ?
      storedSearchData.searchData.etollRequired : undefined;
    this.searchModel.disabled = storedSearchData.searchData.disabled;
    completion();
  }

  private saveSearch() {
    const request = {
        searchData: {
          itemsPerPage: this.queryModel.itemsPerPage,
          pageNumber: this.queryModel.currentPage,
          order: this.queryModel.getOrder(),
          id: this.searchModel.id,
          externalId: this.searchModel.externalId,
          model: this.searchModel.model,
          registrationType: this.searchModel.registrationType,
          companyIds: this.searchModel.companyIds,
          licencePlate: this.searchModel.licencePlate,
          registrationDateFrom: Models.ngbDateToLocalDate(this.searchModel.registrationDateFrom),
          registrationDateTo: Models.ngbDateToLocalDate(this.searchModel.registrationDateTo),
          payloadKgFrom: this.searchModel.payloadKgFrom,
          payloadKgTo: this.searchModel.payloadKgTo,
          totalWeightKgFrom: this.searchModel.totalWeightKgFrom,
          totalWeightKgTo: this.searchModel.totalWeightKgTo,
          etollRequired: this.searchModel.etollRequired ?
            this.searchModel.etollRequired : undefined,
          disabled: this.searchModel.disabled,
        }
      }
    ;
    this.vehicleSearchService.setSearchData(request).subscribe(
      (result) => {
      },
      (error) => {
      }
    );
  }


  setDisabled(event: any, vehicle: Vehicle.Vehicle, disabled: boolean) {
    this.vehicleService.setDisabled({
      id: vehicle.id,
      disabled: disabled
    }).subscribe(
      (result: EmptyMessage) => {
        this.loadList();
      },
      (error: any) => {
        this.loadList();
      }
    );
  }

  ngOnDestroy() {
    this.saveSearch();
  }

  getEtollRequiredIcon(etolRequired: boolean): string {
    if (etolRequired) {
      return 'icomoon icomoon-e-toll';
    }
    return '';
  }

}

export class VehicleSearchModel {
  id: string = '';
  externalId: string = '';
  model: string = '';
  registrationType: string = '';
  licencePlate: string = '';
  companyIds: VehicleSearch.CompanyItem[];
  registrationDateFrom: NgbDateStruct | null = null;
  registrationDateTo: NgbDateStruct | null = null;
  payloadKgFrom: string = '';
  payloadKgTo: string = '';
  totalWeightKgFrom: string = '';
  totalWeightKgTo: string = '';
  etollRequired?: boolean = undefined;
  disabled?: DisabledItem = undefined;


  public isEmpty(): boolean {
    return this.id.length === 0
      && this.externalId.length === 0
      && this.model.length === 0
      && this.registrationType.length === 0
      && this.licencePlate.length === 0
      && this.companyIds.length === 0
      && this.payloadKgFrom.length === 0
      && this.payloadKgTo.length === 0
      && this.totalWeightKgFrom.length === 0
      && this.totalWeightKgTo.length === 0
      && this.etollRequired === undefined
      && OptionItem.idOrUndefined(this.disabled) === undefined
      ;
  }
}

class VehicleModel {
  id: string = '';
  licencePlate: string = '';
  externalId: string = '';
  model: string = '';
  etollRequired = false;
  kmCounter: number = 0;
  company = {name: '', id: 0};
  ownerPersonName: string = '';
  disabled = false;

}

class CompanyModel {
  id: number = 0;
  name: string = '';
}


interface SearchLoadResult {
  storedSearchData: VehicleSearch.SearchDataResult,
}
