/* eslint-disable */
import { NamedBlobFile } from '../../lib/util/downloaded-files';
import { Observable } from 'rxjs';
import { Component, EventEmitter, Input, Output, } from '@angular/core';
import { FileAttachmentModel } from '../../util/file-attachment.model';
import { FileAttachment } from '../../lib/util/file-attachments';
import { MatDialog } from '@angular/material/dialog';
import { MatConfirmDialogComponent, MatConfirmDialogData } from '../mat-confirm-dialog/mat-confirm-dialog.component';
import { AlertType } from '../confirm-dialog/confirm-dialog.component';

/* eslint-enable */

@Component({
  selector: 'app-file-attachment-gallery',
  templateUrl: './file-attachment-gallery.component.html',
  styleUrls: ['./file-attachment-gallery.component.scss']
})
/**
 * @deprecated This component should not be used.
 * MaterialAttachment gallery should be used instead.
 */
export class FileAttachmentGalleryComponent {

  @Input()
  loaderFactory: FileLoaderFactory | ThumbnailLoaderFactory;

  @Input()
  get fileAttachments(): FileAttachment[] {
    return this._fileAttachments;
  }
  set fileAttachments(fileAttachments: FileAttachment[]) {
    this._fileAttachments = this.fileAttachments;
    this.attachments = [];
    fileAttachments.forEach((r) => {
      const item = new FileAttachmentGalleryModel(r);
      this.attachments.push(item);
      this.downloadAttachmentOnceIfPreviewPossible(item);
    });
  }

  @Input()
  deleteAllowed?: boolean = false;

  @Output()
  private openImageViewerWindowEvent = new EventEmitter();

  @Output()
  private clickedImageEventEmitter: EventEmitter<FileAttachmentModel> = new EventEmitter();

  @Output()
  private onAttachmentDelete: EventEmitter<number> = new EventEmitter<number>();

  private _fileAttachments: FileAttachment[] = [];

  attachments: FileAttachmentGalleryModel[] = [];
  modalOpened: boolean = false;
  clickedImage: FileAttachmentGalleryModel;
  clickedImageId: number;

  private activated: boolean = false;

  constructor(private dialog: MatDialog) {
  }

  public activateView() {
    this.activated = true;
    this.attachments.forEach((a) => {
      this.downloadAttachmentOnceIfPreviewPossible(a);
    });
  }

  downloadFile(attachment: FileAttachmentGalleryModel) {
    attachment.saveAs();
  }

  downloadFileThenSave(attachment: FileAttachmentGalleryModel) {
    this.downloadAttachmentOnce(attachment, () => {
      this.downloadFile(attachment);
    });
  }

  retryDownloadFile(attachment: FileAttachmentGalleryModel) {
    this.downloadAttachmentOnce(attachment);
  }

  openConfirmDeleteDialog(attachment: FileAttachmentGalleryModel) {
    const dialogConfig: MatConfirmDialogData = {
      titleKey: 'CONFIRM_DIALOG_TITLE_DELETE',
      messageKey: 'CONFIRM_DIALOG_MESSAGE_DELETE',
      alertType: AlertType.DANGER
    };
    const dialogRef = this.dialog.open(MatConfirmDialogComponent, {
      data: dialogConfig,
      width: '50%'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.onAttachmentDelete.emit(attachment.attachment.id);
      }
    });
  }

  onImageClick(image: FileAttachmentGalleryModel) {
    if (this.loaderFactory.hasThumbnails() && !image.downloaded) {
      this.downloadAttachmentOnce(image, () => {
        this.modalOpened = true;
        this.clickedImage = image;
        this.clickedImageEventEmitter.emit(this.clickedImage);
        this.openImageViewerWindowEvent.emit();
      });
    }
    else {
      this.modalOpened = true;
      this.clickedImage = image;
      this.clickedImageEventEmitter.emit(this.clickedImage);
      this.openImageViewerWindowEvent.emit();
    }
  }

  onPreviousClicked(clickedImage: FileAttachmentGalleryModel) {
    this.clickedImageId = this.attachments.indexOf(clickedImage);
    if (this.clickedImageId === 0) {
      this.clickedImageId = this.attachments.length;
    }
    const nextImage = this.attachments[this.clickedImageId - 1];
    if (!this.displayAsImage(nextImage)) {
      this.onPreviousClicked(nextImage);
    }
    else {
      if (this.loaderFactory.hasThumbnails() && !nextImage.downloaded) {
        this.downloadAttachmentOnce(nextImage);
      }
      this.clickedImage = nextImage;
      this.clickedImageEventEmitter.emit(this.clickedImage);
    }
  }

  onNextClicked(clickedImage: FileAttachmentGalleryModel) {
    this.clickedImageId = this.attachments.indexOf(clickedImage);
    if (this.clickedImageId === (this.attachments.length - 1)) {
      this.clickedImageId = -1;
    }
    const nextImage = this.attachments[this.clickedImageId + 1];
    if (!this.displayAsImage(nextImage)) {
      this.onNextClicked(nextImage);
    }
    else {
      if (this.loaderFactory.hasThumbnails() && !nextImage.downloaded) {
        this.downloadAttachmentOnce(nextImage);
      }
      this.clickedImage = nextImage;
      this.clickedImageEventEmitter.emit(this.clickedImage);
    }
  }

  displayAsPlaceholder(m: FileAttachmentGalleryModel): boolean {
    return !this.displayAsImage(m);
  }

  displayAsImage(m: FileAttachmentGalleryModel): boolean {
    if (!m.downloaded && !m.thumbnailDataUrl) {
      return false;
    }
    return m.contentTypeParser.isImageType;
  }

  getPreviewDataUrl(m: FileAttachmentGalleryModel): string | undefined {
    return m.thumbnailDataUrl ? m.thumbnailDataUrl : m.base64dataUrl;
  }

  private hasPreviewSupport(item: FileAttachmentGalleryModel): boolean {
    return item.contentTypeParser.isImageType;
  }

  private downloadAttachmentOnceIfPreviewPossible(item: FileAttachmentGalleryModel) {
    if (this.activated && this.hasPreviewSupport(item)) {
      if (this.loaderFactory.hasThumbnails()) {
        this.downloadThumbnail(item);
      }
      else {
        this.downloadAttachmentOnce(item);
      }
    }
  }

  private downloadAttachmentOnce(item: FileAttachmentGalleryModel, callback?: () => any) {
    item.downloadOnce(this.loaderFactory.createFileLoader(item.attachment), callback);
  }

  private downloadThumbnail(item: FileAttachmentGalleryModel, callback?: () => any) {
    item.downloadThumbnail((<ThumbnailLoaderFactory>this.loaderFactory).createThumbnailLoader(item.attachment), callback);
  }

  hasDeleteRight(image: FileAttachmentGalleryModel) {
    return image.attachment.hasDeleteRight === undefined ? true : image.attachment.hasDeleteRight;
  }
}

export class FileAttachmentGalleryModel extends FileAttachmentModel {
}

export interface FileLoaderFactory {
  createFileLoader(attachment: FileAttachment): Observable<NamedBlobFile>;
  hasThumbnails(): boolean;
}

export interface ThumbnailLoaderFactory extends FileLoaderFactory {
  createThumbnailLoader(attachment: FileAttachment): Observable<NamedBlobFile>;
}
