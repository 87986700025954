/* eslint-disable */
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Transition } from '@uirouter/angular';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../../../lib/auth.service';
import { UserItem } from '../../../util/core-utils';
import { UserGroup, UserGroupService } from '../../../lib/user-group.service';
import { Message, MessagesService } from '../../../lib/message-parent/messages.service';
import { IdentityArray } from '../../../lib/util/messages';
import { Arrays } from '../../../lib/util/arrays';
import { BreadcrumbParent } from '../../../shared/breadcrumb/breadcrumb/breadcrumb.component';
import { StateName } from '../../../app.state-names';
import { Device, DeviceManagementService } from '../../../lib/device-management.service';
import { MessageReadModel } from '../../../util/message/message-utils';
import { UserData, UserDataLoader } from '../../../lib/user-data-loader';
import { ResourceQueryResult, Services } from '../../../lib/util/services';
import { RightModel } from '../../../app.rights';
import { RightResolver, RightService } from '../../../lib/right.service';
import { Set } from 'immutable';
/* eslint-enable */

@Component({
  selector: 'app-notification-read',
  templateUrl: 'notification-read.component.html',
  styleUrls: ['notification-read.component.css']
})
export class NotificationReadComponent implements OnInit, AfterViewInit {

  private messageId: number;

  readModel: MessageReadModel = new MessageReadModel();
  userList: UserItem[] = [];
  userGroupList: UserGroup[] = [];
  mobileAppList: Device[] = [];
  breadcrumbParents: BreadcrumbParent[] = [];
  breadcrumbSelf: string;
  compactSidebar: boolean = document.querySelector('body')!.classList.contains('sidebar-compact');
  rightModel: RightModel = RightModel.empty();

  constructor(
    private translateService: TranslateService,
    private userService: UserDataLoader,
    private authService: AuthService,
    private messageService: MessagesService,
    private userGroupService: UserGroupService,
    private mobileAppService: DeviceManagementService,
    private rightService: RightService,
    private transition: Transition) {
    this.messageId = this.transition.params().id;
  }

  ngOnInit() {
    this.loadRightModels();
    this.translateService.get('MENU_NAVBAR_MENU_ADMINISTRATION').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.ADMIN_DASHBOARD});
      }
    );
    this.translateService.get('COMMON_NOTIFICATIONS').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.NOTIFICATION_LIST});
      }
    );
  }

  ngAfterViewInit(): void {
    this.getUserGroupList(() => {
      this.getMobileAppList(() => {
        this.loadUsers(() => {
          this.loadModel();
        });
      });
    });
  }

  private loadRightModels() {
    this.rightService.getRightResolver().subscribe(
      (resolver: RightResolver) => {
        this.rightModel = RightModel.of(resolver);
      }
    );
  }

  private loadUsers(completion: () => void): void {
    this.userService.loadAll()
      .subscribe(
        (users: IdentityArray<UserData>) => {
          this.userList = [];

          Arrays.iterateByIndex(users, (user) => {
            const item = new UserItem();
            item.id = user.id;
            item.text = user.person_name + ' (' + user.user_name + ')';
            this.userList.push(item);
          });
          completion();
        }
      );
  }

  getUserName(userId: number): string {
    let userName = '';
    Arrays.iterateByIndex(this.userList, (user) => {
      if (user.id === userId) {
        userName = user.text;
      }
    });
    return userName;
  }

  getUserGroupName(userGroupId: number): string {
    let userGroupName = '';
    Arrays.iterateByIndex(this.userGroupList, (userGroup) => {
      if (userGroup.id === userGroupId) {
        userGroupName = userGroup.name;
      }
    });
    return userGroupName;
  }

  getMobileAppName(mobileAppId: number): string {
    let mobileAppName = '';
    Arrays.iterateByIndex(this.mobileAppList, (mobileApp) => {
      if (mobileApp.id === mobileAppId) {
        mobileAppName = mobileApp.name;
      }
    });
    return mobileAppName;
  }

  back() {
    window.history.back();
  }


  private getUserGroupList(completion: () => void): void {
    if (this.rightModel.userGroupRead.hasRight()) {
      this.userGroupService.query({
        order: Services.createOrderFieldParameter(UserGroup.Keys.toOrderFieldKey, Set.of(UserGroup.DEFAULT_ORDER))
      })
        .subscribe(
          (result: ResourceQueryResult<UserGroup>) => {
            this.userGroupList = result.items;
            completion();
          }
        );
    }
    else {
      completion();
    }
  }

  private getMobileAppList(completion: () => void): void {
    if (this.rightModel.mobileAppRead.hasRight()) {
      this.mobileAppService.query({})
        .subscribe(
          (result: ResourceQueryResult<Device>) => {
            this.mobileAppList = result.items;
            completion();
          }
        );
    }
    else {
      completion();
    }
  }

  private loadModel() {
    this.messageService.get({id: this.messageId})
      .subscribe(
      (message: Message) => {

        const readModel = new MessageReadModel();
        readModel.title = message.title ? message.title : '';
        readModel.content = message.content ? message.content : '';
        readModel.sent_date = message.sent_date ? message.sent_date : '';

        readModel.recipients.user_profiles = message.recipients.user_profiles ? message.recipients.user_profiles : [];
        readModel.recipients.user_group_ids = message.recipients.user_group_ids ? message.recipients.user_group_ids : [];
        readModel.recipients.mobile_app_ids = message.recipients.mobile_app_ids ? message.recipients.mobile_app_ids : [];

        const userNames: string[] = [];

        if (readModel.recipients.user_profiles && readModel.recipients.user_profiles.length >= 5) {
          readModel.recipients.userNamesAsString = this.translateService.instant('NOTIFICATION_LIST_RECIPIENT_MULTIPLE');
        }
        else {
          readModel.recipients.user_profiles.forEach((userProfile) => {
            userNames.push(this.getUserName(userProfile.id));
          });
          readModel.recipients.userNamesAsString = userNames.join(', ');

          const userGroupNames: string[] = [];
          readModel.recipients.user_group_ids.forEach((user_group_id) => {
            userGroupNames.push(this.getUserGroupName(user_group_id));
          });
          readModel.recipients.userGroupNamesAsString = userGroupNames.join(', ');
          const mobileAppNames: string[] = [];
          readModel.recipients.mobile_app_ids.forEach((mobileApp) => {
            mobileAppNames.push(this.getMobileAppName(mobileApp));
          });
          readModel.recipients.mobileAppNamesAsString = mobileAppNames.join(', ');
        }

        this.readModel = readModel;
        this.breadcrumbSelf = readModel.title;
      }
    );
  }
}
