/* eslint-disable */
import { Observable, Observer } from 'rxjs';
import { RegistrationSettingsResource, RegistrationSettingsResourceService } from './registration-settings-resource.service';
import { Injectable } from '@angular/core';
import { EmptyMessage } from '../../util/messages';
import { ConfigurationResource } from '../../core-ext/configuration.service';

/* eslint-enable */

@Injectable()
export class RegistrationSettingsService implements RegistrationSettings.Service {

  constructor(private resourceService: RegistrationSettingsResourceService) {
  }

  get(request: RegistrationSettings.GetRequest): Observable<RegistrationSettings.RegistrationSettings> {
    return Observable.create((observer: Observer<RegistrationSettings.RegistrationSettings>) => {
      const resourceRequest: RegistrationSettingsResource.GetRequest = {
        type: request.type
      };
      return this.resourceService.get(resourceRequest).subscribe(
        (result: RegistrationSettingsResource.RegistrationSettings) => {
          observer.next({
            notificationEmailTemplate: result.notification_email_template,
            responseEmailTemplate: result.response_email_template,
            notificationEmailAddresses: result.notification_email_addresses,
            defaultRoleId: result.default_role_id,
            defaultUserGroupId: result.default_user_group_id,
            userGroupSettings: result.user_group_settings
              ? result.user_group_settings.map(s => (
                {
                  roleIds: s.role_ids,
                  applicationTypes: s.application_types,
                  nameTemplate: s.name_template
                }))
              : undefined,
          });
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  update(request: RegistrationSettings.UpdateRequest): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      const resourceRequest: RegistrationSettingsResource.UpdateRequest = {
        type: request.type,
        notification_email_template: request.notificationEmailTemplate,
        response_email_template: request.responseEmailTemplate,
        notification_email_addresses: request.notificationEmailAddresses,
        default_role_id: request.defaultRoleId,
        default_user_group_id: request.defaultUserGroupId,
        user_group_settings: request.userGroupSettings ? request.userGroupSettings.map(s => (
          {
            role_ids: s.roleIds,
            application_types: s.applicationTypes,
            name_template: s.nameTemplate
          })) : undefined,
      };
      return this.resourceService.update(resourceRequest).subscribe(
        (result: EmptyMessage) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }
}

export namespace RegistrationSettings {

  export interface Service {

    get(request: GetRequest): Observable<RegistrationSettings>;

    update(request: UpdateRequest): Observable<EmptyMessage>;

  }

  export interface RegistrationSettings {
    notificationEmailTemplate: number;
    responseEmailTemplate: number;
    notificationEmailAddresses: string[];
    defaultRoleId?: number;
    defaultUserGroupId?: number;
    userGroupSettings?: UserGroupSettings[];
  }

  export interface UserGroupSettings {
    roleIds: number[];
    applicationTypes: string[];
    nameTemplate: string;
  }

  export interface GetRequest {
    type: ConfigurationResource.RegistrationType;
  }

  export interface UpdateRequest extends GetRequest {
    notificationEmailTemplate: number;
    responseEmailTemplate: number;
    notificationEmailAddresses: string[];
    defaultRoleId?: number;
    defaultUserGroupId?: number;
    userGroupSettings?: UserGroupSettings[];
  }

}
