/* eslint-disable */
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { OptionItem } from './core-utils';
import { LegacyProcess } from '../lib/legacy-process/legacy-process.service';
/* eslint-enable */

export class LegacyProcessSearchModel {

  processId: string = '';
  state?: ProcessStateItem = undefined;
  workflowName: string = '';
  name: string = '';
  deadlineFrom: NgbDateStruct | null = null;
  deadlineTo: NgbDateStruct | null = null;
  externalId: string = '';
  numberOfOrdersFrom: string = '';
  numberOfOrdersTo: string = '';
  creationTimeFrom: NgbDateStruct | null = null;
  creationTimeTo: NgbDateStruct | null = null;

  public isEmpty(): boolean {
    return this.processId.length === 0
      && OptionItem.idOrUndefined(this.state) === undefined
      && this.workflowName.length === 0
      && this.name.length === 0
      && this.deadlineFrom === null
      && this.deadlineTo === null
      && this.externalId.length === 0
      && this.numberOfOrdersFrom.length === 0
      && this.numberOfOrdersTo.length === 0
      && this.creationTimeFrom === null
      && this.creationTimeTo === null
      ;
  }

}

export class ProcessStateItem extends OptionItem<LegacyProcess.LegacyProcessState> {
}
