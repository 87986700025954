<pagination-template  #p="paginationApi"
                      [id]="id"
                      [maxSize]="maxSize"
                      (pageChange)="pageChange.emit($event)">
  <ul class="pagination"
      role="navigation"
      *ngIf="!(autoHide && p.pages.length <= 1)">
    <li class="page-item select-none" [class.disabled]="p.isFirstPage()" [class.cursor-pointer]="!p.isFirstPage()" *ngIf="directionLinks">
      <a class="page-link" *ngIf="1 < p.getCurrent()" (click)="p.previous()" [attr.aria-label]="previousLabel">
        {{ previousLabel }}
      </a>
      <a class="page-link" *ngIf="p.isFirstPage()">
        {{ previousLabel }}
      </a>
    </li>
    <li class="select-none" [class.active]="p.getCurrent() === page.value" [class.cursor-pointer]="true" *ngFor="let page of p.pages">
      <a class="page-link" (click)="p.setCurrent(page.value)" *ngIf="p.getCurrent() !== page.value">
        <span>{{ page.label }}</span>
      </a>
      <a class="page-link" *ngIf="p.getCurrent() === page.value">
        <span>{{ page.label }}</span>
      </a>
    </li>
    <li class="page-item select-none" [class.disabled]="p.isLastPage()" [class.cursor-pointer]="!p.isLastPage()" *ngIf="directionLinks">
      <a class="page-link" *ngIf="!p.isLastPage()" (click)="p.next()" [attr.aria-label]="nextLabel">
        {{ nextLabel }}
      </a>
      <a class="page-link" *ngIf="p.isLastPage()">
        {{ nextLabel }}
      </a>
    </li>
  </ul>
</pagination-template>
