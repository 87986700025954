/* eslint-disable */

/* eslint-enable */


import { Injectable } from '@angular/core';
import { BaseHttpService, ResourceHelper } from '../util/http-services';
import { Observable } from 'rxjs';
import { ResourceQueryResult } from '../util/services';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class FormStockResourceService extends BaseHttpService {

  queryComments(request: FormStockResource.QueryRequest): Observable<ResourceQueryResult<string>> {
    return this.http.get<ResourceQueryResult<string>>(this.url + 'comments', this.parseParams(request));
  }

  constructor(private http: HttpClient, private resourceHelper: ResourceHelper) {
    super(resourceHelper, '/form-record-stock-items/');
  }
}

export namespace FormStockResource {

  export interface QueryRequest {
    q?: string;
    page_number?: number;
    number_of_items?: number;
    no_progress_bar?: boolean;
  }
}
