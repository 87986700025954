/* eslint-disable */
import {Injectable} from '@angular/core';
import {FieldError} from './util/errors';
import {Observable} from 'rxjs';
import {Map as ImmutableMap} from 'immutable';
import {Order, OrderType, ResourceQueryResult} from './util/services';
import {
  BaseHttpService,
  EchoHeader,
  EchoHeaderBuilder,
  HeaderKeys,
  HeaderValues,
  ResourceHelper
} from './util/http-services';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {
  CountMessage,
  DisableRequest,
  EmptyMessage,
  Identity,
  IdentityMessage,
  InterceptedRequest,
  ListQuery
} from './util/messages';
import {HistoryLog} from './history-log/history-log.service';
import {UserProfileType} from './auth.service';
import {Company} from './company/company.service';
import {DownloadedFile} from './util/downloaded-files';
import {UrlBuilder} from '../util/url-builder';
import {map} from 'rxjs/operators';
import {Cacheable} from 'ts-cacheable';
import {UiConstants} from '../util/core-utils';
import {FileUtils} from '../util/file-utils';
/* eslint-enable */

export namespace User {

  export enum OrderField {
    ID,
    USER_NAME,
    PERSON_NAME,
    EMAIL_ADDRESS,
    HISTORY_CREATION_TIME,
    HISTORY_ISSUER_USER_PERSON_NAME,
    HISTORY_MOBILE_APPLICATION_APPLICTAION_ID
  }

  export const DEFAULT_ORDER: Order<User.OrderField> = {
    type: OrderType.ASC,
    field: User.OrderField.PERSON_NAME
  };

  export class Keys {

    private static readonly ID = 'id';
    private static readonly USER_NAME = 'user_name';
    private static readonly PERSON_NAME = 'person_name';
    private static readonly EMAIL_ADDRESS = 'email_address';
    private static readonly HISTORY_CREATION_TIME = 'creation_time';
    private static readonly HISTORY_ISSUER_USER_PERSON_NAME = 'issuer_user_person_name';
    private static readonly HISTORY_MOBILE_APPLICATION_APPLICTAION_ID = 'mobile_application_application_id';

    private static readonly orderFieldKeyMap: ImmutableMap<User.OrderField, string> = ImmutableMap.of(
      User.OrderField.ID, Keys.ID,
      User.OrderField.USER_NAME, Keys.USER_NAME,
      User.OrderField.PERSON_NAME, Keys.PERSON_NAME,
      User.OrderField.EMAIL_ADDRESS, Keys.EMAIL_ADDRESS,
      User.OrderField.HISTORY_CREATION_TIME, Keys.HISTORY_CREATION_TIME,
      User.OrderField.HISTORY_ISSUER_USER_PERSON_NAME, Keys.HISTORY_ISSUER_USER_PERSON_NAME,
      User.OrderField.HISTORY_MOBILE_APPLICATION_APPLICTAION_ID, Keys.HISTORY_MOBILE_APPLICATION_APPLICTAION_ID,
    );

    public static toOrderFieldKey(field: User.OrderField): string {
      return Keys.orderFieldKeyMap.get(field)!;
    }

  }

}

export interface UserQuery extends ListQuery.Paging, ListQuery.Order, ListQuery.QueryText, ListQuery.Fields, InterceptedRequest {
  id?: string;
  type?: string;
  disabled?: boolean;
  user_name?: string;
  person_name?: string;
  email_address?: string;
  user_group_scope?: string;
  external_id?: string;
  company_id?: string;
  is_driver?: boolean;
  user_group_ids?: string;
  no_progress_bar?: boolean;
}

export interface UserGet extends IdentityMessage, InterceptedRequest, EchoHeader {
}

export interface UserMeGet extends InterceptedRequest {
}

export interface User extends Identity {
  id: number;
  external_id: string | null;
  calendar_color: number;
  type: UserProfileType;
  user_name: string;
  person_name: string;
  email_address: string;
  phone_number: string;
  user_group_ids: number[];
  creation_time: string;
  update_time: string;
  disabled: boolean;
  protected_user: boolean;
  inactivation_time?: string;
  driver?: Driver;
  companies: UserCompany[];
  enabled_mobile_app_ids?: number[];
  has_signature: boolean;
  vehicle_id?: number;
  profile_picture_hash?: string;
  helpdesk?: HelpdeskData;
  ldap?: LdapData;
}

export interface UserCompany {
  id: number;
  name: string;
  type: Company.CompanyType;
}

export interface Driver {
  driver_id?: number;
  medical_expiry_date?: string;
  permission_expiry_date?: string;
  driving_licence: DrivingLicence;
}

export interface DrivingLicence {
  licence_number: string;
  licence_categories: string;
  licence_expiry_date: string;
}

export interface HelpdeskData {
  contact_person_id?: number;
  customer_record_id?: number;
  contact_location_ids?: number[];
}

export interface LdapData {
  dn: string;
  deleted: boolean;
  company?: string;
  department?: string;
}

export interface CreateUserRequest {
  external_id: string | null;
  calendar_color: number;
  user_name: string;
  password: string;
  person_name: string;
  phone_number: string | undefined;
  email_address?: string;
  company_id?: number;
  user_group_ids: number[];
  inactivation_time?: string;
  driver?: Driver;
  enabled_mobile_app_ids?: number[];
  vehicle_id?: number;
}

export interface UpdateUserRequest extends IdentityMessage {
  person_name: string;
  external_id: string | null;
  calendar_color: number;
  phone_number: string | undefined;
  email_address?: string;
  company_id?: number;
  user_group_ids: number[];
  inactivation_time?: string;
  driver?: Driver;
  enabled_mobile_app_ids?: number[];
  vehicle_id?: number;
}

export interface ChangeUserPasswordRequest extends IdentityMessage {
  password: string;
}

export interface UpdateUserPasswordRequest extends IdentityMessage {
  current_password: string;
  new_password: string;
}

export interface UserFieldErrorMap extends PasswordFieldErrorMap {
  user_name?: FieldError;
  person_name?: FieldError;
  phone_number?: FieldError;
  email_address?: FieldError;
  external_id?: FieldError;
  licence_number?: FieldError;
  user_group_ids?: FieldError;
}

export interface PasswordFieldErrorMap {
  password?: FieldError;
  new_password?: FieldError;
  current_password?: FieldError;
  confirm_password?: FieldError;

}

export interface PasswordChangeFieldErrorMap {
  password?: FieldError;
  confirm_password?: FieldError;
}

export interface HistoryRequest {
  password?: FieldError;
  confirm_password?: FieldError;
}

export interface UserItemResource {
  id: number;
  person_name: string;
}

export interface UserItem {
  id: number;
  personName: string;
}

export interface HistoryRequest {
  user_id: number;
  with_read: boolean;
  q?: string;
  order?: string;
  page_number?: number;
  number_of_items?: number;
}

export interface UserActivationRequest {
  registration_token: string;
}

export interface SyncLdapUserRequest {
  user_name: string;
}

export interface UploadProfilePictureRequest {
  user_id: number;
  picture: string;
}

export interface UserSignatureData {
  id: number;
  content_type: string;
  content_hash: string;
  update_time: string;
  signature_type: UserSignatureType;
}

export enum UserSignatureType {
  APPWORKS = "APPWORKS",
  OFFICIAL = "OFFICIAL"
}

export interface HistoryItemResource extends HistoryLog.HistoryItemBaseResource {
  type: string;
}

export type UserHistoryType =
  'READ' |
  'CREATE' |
  'UPDATE' |
  'DISABLE' |
  'ENABLE' |
  'UPDATE_PASSWORD' |
  'CHANGE_PREVIOUS_PASSWORD' |
  'DELETE';

export class UserHistoryTypeObject {
  type: UserHistoryType;
  stringKey: string;
}

export const userHistoryTypes: UserHistoryTypeObject[] = [
  {type: 'READ', stringKey: 'USER_HISTORY_TYPE_READ'},
  {type: 'CREATE', stringKey: 'USER_HISTORY_TYPE_CREATE'},
  {type: 'UPDATE', stringKey: 'USER_HISTORY_TYPE_UPDATE'},
  {type: 'DISABLE', stringKey: 'USER_HISTORY_TYPE_DISABLE'},
  {type: 'ENABLE', stringKey: 'USER_HISTORY_TYPE_ENABLE'},
  {type: 'UPDATE_PASSWORD', stringKey: 'USER_HISTORY_TYPE_UPDATE_PASSWORD'},
  {type: 'CHANGE_PREVIOUS_PASSWORD', stringKey: 'USER_HISTORY_TYPE_CHANGE_PREVIOUS_PASSWORD'},
  {type: 'DELETE', stringKey: 'USER_HISTORY_TYPE_DELETE'}
];

export type UserDisabled =
  'ALL' |
  'ACTIVE' |
  'INACTIVE';

@Injectable()
export class UserService extends BaseHttpService {

  query(request: UserQuery): Observable<ResourceQueryResult<User>> {
    return this.http.get<ResourceQueryResult<User>>(this.url, this.parseParams(request));
  }

  @Cacheable({
    maxAge: UiConstants.defaultCacheMaxAge,
    slidingExpiration: true
  })
  get(request: UserGet): Observable<User> {
    const headers = new HttpHeaders().set(HeaderKeys.ECHO, HeaderValues.serializeEchoHeader(request));
    return this.http.get<User>(this.url + `${request.id}`, {headers: headers});
  }

  create(request: CreateUserRequest): Observable<IdentityMessage> {
    return this.http.post<IdentityMessage>(this.url, request);
  }

  update(request: UpdateUserRequest): Observable<EmptyMessage> {
    return this.http.put(this.url + `${request.id}`, request);
  }

  delete(request: IdentityMessage): Observable<EmptyMessage> {
    return this.http.delete<EmptyMessage>(this.url + `${request.id}`);
  }

  setDisabled(request: DisableRequest): Observable<EmptyMessage> {
    return this.http.patch(this.url + `${request.id}/disabled`, request);
  }

  changePassword(request: ChangeUserPasswordRequest): Observable<EmptyMessage> {
    return this.http.patch(this.url + `${request.id}/password`, request);
  }

  updatePassword(request: UpdateUserPasswordRequest): Observable<EmptyMessage> {
    return this.http.patch(this.url + `${request.id}/previous-password`, request);
  }

  count(request: EmptyMessage): Observable<CountMessage> {
    return this.http.get<CountMessage>(this.url + `count`);
  }

  history(request: HistoryRequest): Observable<ResourceQueryResult<HistoryItemResource>> {
    return this.http.get<ResourceQueryResult<HistoryItemResource>>(
      this.url + `${request.user_id}/history-logs?with_read=${request.with_read}`,
      this.parseParams(request)
    );
  }

  syncLdapUser(request: SyncLdapUserRequest): Observable<IdentityMessage> {
    return this.http.post<IdentityMessage>(this.url + 'ldap/sync', request);
  }

  activateUser(request: UserActivationRequest): Observable<EmptyMessage> {
    return this.http.post<EmptyMessage>(this.url + 'activate', request);
  }

  exportXls(request: UserQuery): Observable<DownloadedFile> {
    const url = UrlBuilder.create('users/export-xls')
      .baseUrl(this.resourceHelper.getBaseUrl())
      .build();
    return this.http.get(url, {
      observe: 'response',
      responseType: 'blob',
      params: this.parseParams(request).params
    }).pipe(map((res) => {
      return new DownloadedFile(res);
    }));
  }

  exportXlsTemplate(request: EmptyMessage): Observable<DownloadedFile> {
    const url = UrlBuilder.create('users/export-xls-template')
      .baseUrl(this.resourceHelper.getBaseUrl())
      .build();
    return this.http.get(url, {
      observe: 'response',
      responseType: 'blob',
      params: this.parseParams(request).params
    }).pipe(map((res) => {
      return new DownloadedFile(res);
    }));
  }

  @Cacheable({
    maxAge: UiConstants.defaultCacheMaxAge,
    slidingExpiration: true,
    maxCacheCount: 100
  })
  downloadProfilePicture(userId: number): Observable<DownloadedFile> {
    const url = UrlBuilder.create('users/:userId/profile-picture/')
      .baseUrl(this.resourceHelper.getBaseUrl())
      .namedNumber('userId', userId)
      .build();
    const echoHeader: EchoHeader = new EchoHeaderBuilder()
      .ignoreEntityNotFound(true)
      .ignorePermissionDenied(true)
      .build();
    const headers = new HttpHeaders().set(HeaderKeys.ECHO, HeaderValues.serializeEchoHeader(echoHeader));
    return this.http.get(url, {
      headers: headers,
      observe: 'response',
      responseType: 'blob',
    }).pipe(map((res) => {
      return new DownloadedFile(res);
    }));
  }

  uploadProfilePicture(request: UploadProfilePictureRequest): Observable<EmptyMessage> {
    const file = FileUtils.dataURIToBlob(request.picture);
    const formData = new FormData();
    formData.append('file', file, `user-${request.user_id}-profile-picture.png`);
    return this.http.post<EmptyMessage>(this.url + `${request.user_id}/profile-picture/`, formData);
  }

  deleteProfilePicture(request: IdentityMessage): Observable<EmptyMessage> {
    return this.http.delete<EmptyMessage>(this.url + `${request.id}/profile-picture/`);
  }

  constructor(private http: HttpClient, private resourceHelper: ResourceHelper) {
    super(resourceHelper, '/users/');
  }

}

@Injectable()
export class UserSignatureService extends BaseHttpService {

  deleteSignature(userId: number, signatureType: UserSignatureType): Observable<EmptyMessage> {
    return this.http.delete(this.url + `${userId}/${signatureType}`);
  }

  querySignatures(userId: number): Observable<Array<UserSignatureData>> {
    return this.http.get<Array<UserSignatureData>>(this.url + `${userId}`);
  }

  downloadSignature(userId: number, signatureType: UserSignatureType): Observable<DownloadedFile> {
    const url = UrlBuilder.create('user-signature-image/download/:userId/:signatureType/')
      .baseUrl(this.resourceHelper.getBaseUrl())
      .namedNumber('userId', userId)
      .namedString('signatureType', signatureType)
      .build();
    const echoHeader: EchoHeader = new EchoHeaderBuilder()
      .build();
    const headers = new HttpHeaders().set(HeaderKeys.ECHO, HeaderValues.serializeEchoHeader(echoHeader));
    return this.http.get(url, {
      headers: headers,
      observe: 'response',
      responseType: 'blob',
    }).pipe(map((res) => {
      return new DownloadedFile(res);
    }));
  }

  constructor(private http: HttpClient, private resourceHelper: ResourceHelper) {
    super(resourceHelper, '/user-signature-image/');
  }

}
