<div class="d-flex align-items-center" *ngIf="_customerRecord || _name" [class.border-container]="borderEnabled">
    <div class="picture-container">
      <ngx-avatars [size]="imageSize"
                  [name]="cellTitle"
                  textSizeRatio="2"
                  initialsSize="2"
                  [round]="true"
                  [bgColor]="'#e3e8ec'"
                  [fgColor]="'#000000'">
      </ngx-avatars>
    </div>
    <div class="text-truncate">
      <a uiSref="#" class="text-truncate cursor-pointer" (click)="navigateToAssigneeDetail()">
        {{cellTitle}}
      </a>
      <div class="text-truncate table-text-subtitle">{{cellSubtitle}}</div>
    </div>
</div>
