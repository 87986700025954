import { Component, Input, OnInit } from '@angular/core';
import { StockItem, StockItemReservation, StockItemService, StockItemStock } from '../../../lib/stock/stock-item.service';
import { StockItemUtils } from '../../../util/stock/stock-item-utils';
import { OptionItem, QueryModel } from '../../../util/core-utils';
import { QueryResult } from '../../../lib/util/services';
import { Currency, CurrencyService } from '../../../lib/currency.service';

@Component({
  selector: 'app-stock-item-stock-list',
  templateUrl: './stock-item-stock-list.component.html',
  styleUrls: ['./stock-item-stock-list.component.scss']
})
export class StockItemStockListComponent implements OnInit {

  StockItemUtils = StockItemUtils;

  stockItemStock: StockItemStock[] = [];
  currentStockItemStock: StockItemStock[] = [];

  stockItem: StockItem;

  @Input()
  stockItemId: number;

  stockItemReservations: StockItemReservation[] = [];

  totalAmountOfProductsInStock: number = 0;
  intakeNetPriceSum?: number = undefined;
  intakeNetPriceAvg?: number = undefined;

  containingStocks: OptionItem<number>[] = [];

  queryModel: QueryModel = new QueryModel();

  currencies: Currency.Currency[] = [];

  get m(): StockItem {
    return this.stockItem;
  }

  get packageEnabled(): boolean {
    return this.m && this.m.package_data !== undefined;
  }


  constructor(private stockItemService: StockItemService,
              private currencyService: CurrencyService) {
  }

  ngOnInit(): void {
    this.loadStockItem(() => {
      this.loadReservations();
      this.loadStockItemStocks();
      this.loadCurrencies();
    });

  }

  private loadReservations() {
    this.stockItemService.getReservations({id: this.stockItemId}).subscribe((result: StockItemReservation[]) => {
      this.stockItemReservations = result;
    });
  }

  private loadStockItemStocks() {
    this.totalAmountOfProductsInStock = 0;
    this.stockItemService.getStock({id: this.stockItemId, waste: false, disabled: false}).subscribe(result => {
      this.stockItemStock = result;
      this.containingStocks = [];
      this.stockItemStock.forEach((stock) => {
        const stockOptionItem = {
          id: stock.stock_id,
          text: stock.stock_name + ' (' + stock.amount + ' ' + this.stockItem.unit + ')'
        };
        this.containingStocks.push(stockOptionItem);
        this.totalAmountOfProductsInStock += stock.amount;
        if (!this.intakeNetPriceSum) {
          this.intakeNetPriceSum = 0;
        }
        this.intakeNetPriceSum = this.intakeNetPriceSum + (stock.intake_sum_net_price ? stock.intake_sum_net_price : 0);
      });
      const avgs = this.stockItemStock.filter(s => s.intake_avg_net_price)
        .map(s => s.intake_avg_net_price!);
      this.intakeNetPriceAvg = avgs.length === 0
        ? 0
        : avgs.reduce((previousValue, currentValue) => previousValue + currentValue, 0) / avgs.length;

      this.queryModel.totalNumberOfItems = this.stockItemStock.length;
      this.queryModel.currentNumberOfItems = this.stockItemStock.length;
      this.loadCurrentStockItemStocks(1);
    });
  }

  private loadCurrentStockItemStocks(selectedPage: number) {
    this.queryModel.currentPage = selectedPage;
    this.currentStockItemStock = [];
    const startIndex = (this.queryModel.currentPage - 1) * this.queryModel.itemsPerPage;
    const endIndex = ((this.queryModel.currentPage) * this.queryModel.itemsPerPage) - 1;
    this.currentStockItemStock = this.stockItemStock.slice(startIndex, Math.min(endIndex, this.stockItemStock.length));
  }

  private loadCurrencies() {
    this.currencies = [];
    this.currencyService.query({}).subscribe((result: QueryResult<Currency.Currency>) => {
      result.items.forEach(c => {
        if (c) {
          this.currencies.push(c);
        }
      });
    });
  }

  getCurrencyName(currencyCode: string) {
    const cc = this.currencies.find(c => c.currencyCode === currencyCode);
    return cc ? cc.localizedName : currencyCode;
  }

  getCurrency(currencyCode: string) {
    const cc = this.currencies.find(c => c.currencyCode === currencyCode);
    return cc!;
  }

  reload() {
    this.loadStockItemStocks();
  }

  pageChanged(selectedPage: number) {
    this.loadCurrentStockItemStocks(selectedPage);
  }

  itemsPerPageChanged(itemsPerPage: number) {
    this.queryModel.itemsPerPage = itemsPerPage;
    this.loadCurrentStockItemStocks(1);
  }

  private loadStockItem(completion: () => void) {
    this.stockItemService.get({id: this.stockItemId}).subscribe(result => {
      this.stockItem = result;
      completion();
    });
  }

  isDisabled(stock: StockItemStock) {
    return stock.disabled || stock.waste;
  }
}
