import { Observable } from 'rxjs';
import { Order, PagingRequest, QueryResult, ResourceQueryResult, Services } from '../util/services';
import { EmptyMessage, IdentityMessage } from '../util/messages';
import { DqlStoredQueryResource, DqlStoredQueryResourceService } from './dql-stored-query-resource.service';
import { List, Map, Set } from 'immutable';
import { map } from 'rxjs/operators';

/**
 * not injectable
 */
export class DqlStoredQueryService {


  fetchDqlQueryList(request: DqlStoredQuery.DqlStoredQueryListRequest):
    Observable<QueryResult<DqlStoredQuery.DqlStoredQuery>> {
    return this.resourceService.fetchDqlQueryList({
      id: Services.createIdParameter(request.id),
      parent_id: request.parentId,
      document_type: request.documentType,
      name: request.name,
      creator_user_id: Services.createIdParameter(request.creatorUserId),
      order: Services.createOrderFieldParameter(Keys.toOrderFieldKey, request.orders),
      page_number: request.paging ? request.paging.pageNumber : undefined,
      number_of_items: request.paging ? request.paging.numberOfItems : undefined,
      no_progress_bar: request.noProgressBar
    }).pipe(map((result: ResourceQueryResult<DqlStoredQueryResource.DqlStoredQuery>) => {
      return {
        items: this.toPublicList(result.items),
        pagingResult: result.pagingResult
      };
    }));
  }

  createDqlQuery(request: DqlStoredQuery.DqlStoredQueryCreateRequest): Observable<IdentityMessage> {
    return this.resourceService.createDqlQuery({
      name: request.name,
      query: request.query,
      document_type: request.documentType,
      parent_id: request.parentId,
      user_group_ids: request.userGroupIds.toArray()
    });
  }

  updateDqlQuery(id: number, request: DqlStoredQuery.DqlStoredQueryCreateRequest): Observable<EmptyMessage> {
    return this.resourceService.updateDqlQuery(id, {
      name: request.name,
      query: request.query,
      document_type: request.documentType,
      parent_id: request.parentId,
      user_group_ids: request.userGroupIds.toArray()
    });
  }

  deleteDqlQuery(id: number): Observable<EmptyMessage> {
    return this.resourceService.deleteDqlQuery(id);
  }

  constructor(private resourceService: DqlStoredQueryResourceService) {
  }

  private toPublicList(resourceList: Array<DqlStoredQueryResource.DqlStoredQuery>) {
    return List.of(...resourceList.map((r) => this.toPublic(r)));

  }

  private toPublic(r: DqlStoredQueryResource.DqlStoredQuery) {
    return {
      id: r.id,
      name: r.name,
      query: r.query,
      creatorUserId: r.creator_user_id,
      parentId: r.parent_id,
      parentName: r.parent_name,
      userGroupIds: Set.of(...r.user_group_ids)
    };
  }
}

export namespace DqlStoredQuery {

  export interface DqlStoredQueryListRequest {
    id?: Set<number>;
    parentId?: number;
    documentType?: string;
    name?: string;
    creatorUserId?: Set<number>;
    orders?: Set<Order<OrderField>>;
    paging?: PagingRequest;
    noProgressBar?: boolean;
  }

  export interface DqlStoredQuery {
    id: number;
    name: string;
    query: string;
    creatorUserId: number;
    parentId?: number;
    parentName?: string;
    userGroupIds: Set<number>;
  }

  export interface DqlStoredQueryCreateRequest {
    name: string;
    query: string;
    parentId?: number;
    documentType?: string;
    userGroupIds: Set<number>;
  }

  export enum OrderField {
    ID,
    NAME,
  }

  export interface HolderService {
    readonly dqlStoredQueryService: DqlStoredQueryService;
  }

}

class Keys {

  private static readonly ID = 'id';
  private static readonly NAME = 'name';

  private static readonly orderFieldKeyMap: Map<DqlStoredQuery.OrderField, string> = Map.of(
    DqlStoredQuery.OrderField.NAME, Keys.NAME,
    DqlStoredQuery.OrderField.ID, Keys.ID,
  );

  public static toOrderFieldKey(field: DqlStoredQuery.OrderField): string {
    return Keys.orderFieldKeyMap.get(field)!;
  }

}
