<div class="breadcrumb-container" [ngClass]="{ 'breadcrumb-container-compact': compactSidebar }" id="breadcrumb">
  <div class="breadcrumb-component">
    <app-breadcrumb
      [parents]="breadcrumbParents"
      [self]="breadcrumbSelf">
    </app-breadcrumb>
  </div>
  <div *ngIf="rightModel.generalPdfTemplateUpload.hasRight()"
       class="breadcrumb-menu d-flex">
    <div *ngIf="rightModel.generalPdfTemplateUpload.hasRight()"
         (click)="showBaseDialog()"
         class="breadcrumb-button-icon-container negative-margin-right cursor-pointer"
    >
      <a class="btn-setting" title="{{'GENERAL_PDF_TEMPLATE_CREATE' | translate}}">
        <i class="icomoon icomoon-add"></i>
        <span class="breadcrumb-button-text-visible">{{'GENERAL_PDF_TEMPLATE_CREATE' | translate}}</span>
      </a>
    </div>
  </div>
</div>
<div class="breadcrumb-placeholder"></div>

<div class="container-horizontal-padding container-vertical-padding animated fadeIn">

  <div class="card">

    <div class="card-header">
      {{'GENERAL_PDF_TEMPLATE_HEADER' | translate}}
      <div class="card-actions">
        <a class="btn-setting cursor-pointer" (click)="toggleSearch()"
           title="{{'COMMON_SHOW_SEARCH_HINT' | translate}}"><i class="icomoon icomoon-search"></i></a>
      </div><!-- /.card-actions -->
    </div>
    <!----------------------->
    <div class="card-body border-bottom" *ngIf="showSearch">
      <div class="row form-group">
        <div class="col-md-3">
          <label class="search-label">{{'COMMON_TABLE_HEADER_ID' | translate}}</label>
          <input type="text" class="form-control"
                 maxlength="{{UiConstants.maxInputLongLength}}"
                 (keyup.enter)="onSearchClicked()"
                 placeholder="{{'COMMON_TABLE_HEADER_ID' | translate}}"
                 [textMask]="{mask: InputMask.NATURAL_INTEGER, guide: false}"
                 [(ngModel)]="searchModel.id">
        </div>
        <div class="col-md-3">
          <label class="search-label">{{'COMMON_NAME' | translate}}</label>
          <input type="text" class="form-control"
                 maxlength="{{UiConstants.maximumVarcharLength}}"
                 (keyup.enter)="onSearchClicked()"
                 placeholder="{{'COMMON_NAME' | translate}}"
                 [(ngModel)]="searchModel.code">
        </div>
        <div class="col-md-3">
          <label class="search-label"> {{ 'COMMON_STATE' | translate }} </label>
          <select
            class="form-control"
            [(ngModel)]="searchModel.disabled" [compareWith]="SelectUtils.compareObjects">
            <option *ngFor="let item of disabledItems" [ngValue]="item">{{item.text}}</option>
          </select>
        </div>
      </div>
      <div class="col-md-12 d-flex justify-content-md-end align-items-end px-0">
        <div class="btn-group" role="group">
          <button type="button" (click)="onSearchReset()" class="btn btn-outline-primary search-button mr-1">
            {{'COMMON_BUTTON_SEARCH_DELETE' | translate}}
          </button>
          <button type="button" (click)="onSearchClicked()" class="btn btn-primary search-button">
            {{'COMMON_BUTTON_SEARCH' | translate}}
          </button>
        </div>
      </div>
    </div><!-- /.card-body-->
    <!----------------------->
    <div class="card-body">
      <table class="table table-striped table-bordered">
        <thead>
        <tr>
          <th class="table-sorter-header">
            <app-table-field-sorter [orderField]="GeneralPdfTemplate.OrderField.ID"
                                    [orderType]="queryModel.getOrderType(GeneralPdfTemplate.OrderField.ID)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_TABLE_HEADER_ID' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header">
            <app-table-field-sorter [orderField]="GeneralPdfTemplate.OrderField.CODE"
                                    [orderType]="queryModel.getOrderType(GeneralPdfTemplate.OrderField.CODE)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_NAME' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-sm-down">
            <app-table-sorter-no-op [text]="'COMMON_UPLOADED_TIME' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header">
            <app-table-sorter-no-op [text]="'COMMON_UPDATE_TIME' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header w-table-options-menu">
            <app-table-sorter-no-op></app-table-sorter-no-op>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let generalPdfTemplate of generalPdfTemplateList | paginate: {
                                                      itemsPerPage: queryModel.itemsPerPage,
                                                      currentPage: queryModel.currentPage,
                                                      totalItems: queryModel.currentNumberOfItems }"
            [disabledTableRow]="generalPdfTemplate.disabled">
          <td class="fit">{{generalPdfTemplate.id}}</td>
          <td class="responsive-table-column">{{generalPdfTemplate.code}}</td>
          <td class="fit hidden-sm-down">{{generalPdfTemplate.creationTime.toUtcIsoString() | date:'short'}}</td>
          <td class="fit">{{generalPdfTemplate.updateTime.toUtcIsoString() | date:'short'}}</td>
          <td class="align-middle">
            <app-table-options-menu>
              <app-dropdown-item
                *ngIf="rightModel.generalPdfTemplateUpload.hasRight()"
                [iconClass]="'icomoon-modify'"
                [titleStringKey]="'COMMON_BUTTON_EDIT'"
                class="cursor-pointer"
                (click)="showBaseDialog(generalPdfTemplate.code)">
              </app-dropdown-item>
              <app-dropdown-item
                *ngIf="rightModel.generalPdfTemplateRead.hasRight()"
                [iconClass]="'icomoon-csv-export'"
                [titleStringKey]="'COMMON_EXPORT'"
                class="cursor-pointer"
                (click)="exportDocument(generalPdfTemplate)">
              </app-dropdown-item>
              <app-dropdown-item
                *ngIf="!generalPdfTemplate.systemCode && generalPdfTemplate.disabled && rightModel.generalPdfTemplateDisable.hasRight()"
                [iconClass]="'icomoon-enable'"
                [titleStringKey]="'COMMON_BUTTON_ENABLE'"
                [itemType]="DropdownItemType.ENABLE"
                class="cursor-pointer"
                (click)="setDisabled($event, generalPdfTemplate, false)">
              </app-dropdown-item>
              <app-dropdown-item
                *ngIf="!generalPdfTemplate.systemCode && !generalPdfTemplate.disabled && rightModel.generalPdfTemplateDisable.hasRight()"
                [iconClass]="'icomoon-disable'"
                [titleStringKey]="'COMMON_BUTTON_DISABLE'"
                [itemType]="DropdownItemType.DISABLE"
                class="cursor-pointer"
                (click)="setDisabled($event, generalPdfTemplate, true)">
              </app-dropdown-item>
            </app-table-options-menu>
          </td>
        </tr>
        </tbody>
        <tfoot>
        <tr>
          <th class="table-sorter-header">
            <app-table-field-sorter [orderField]="GeneralPdfTemplate.OrderField.ID"
                                    [orderType]="queryModel.getOrderType(GeneralPdfTemplate.OrderField.ID)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_TABLE_HEADER_ID' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header">
            <app-table-field-sorter [orderField]="GeneralPdfTemplate.OrderField.CODE"
                                    [orderType]="queryModel.getOrderType(GeneralPdfTemplate.OrderField.CODE)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_NAME' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-sm-down">
            <app-table-sorter-no-op [text]="'COMMON_UPLOADED_TIME' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header">
            <app-table-sorter-no-op [text]="'COMMON_UPDATE_TIME' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header w-table-options-menu">
            <app-table-sorter-no-op></app-table-sorter-no-op>
          </th>
        </tr>
        </tfoot>
      </table>
      <app-table-paging
        [currentNumberOfItems]="queryModel.currentNumberOfItems"
        [totalNumberOfItems]="queryModel.totalNumberOfItems"
        [itemsPerPage]="queryModel.itemsPerPage"
        (pageChange)="pageChanged($event)"
        (itemsPerPageChange)="itemsPerPageChanged($event)">
      </app-table-paging>
    </div>
  </div>
</div>

<!--BaseDialog Modal-->
<div bsModal [config]="UiConstants.modalConfig" #baseDialog="bs-modal" class="modal fade" tabindex="-1" role="dialog"
     aria-labelledby="myModalLabel"
     aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document" *ngIf="baseDialogVisible">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">{{getBaseModalTitle() | translate}}</h4>
        <button type="button" class="close" (click)="closeBaseDialog()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" [formGroup]="formGroup" #f="ngForm">
        <div class="form-group row"
             [ngClass]="{ 'has-danger': hasLocalFieldError('code') }">
          <label class="col-form-label col-md-4" [class.required-field-label]="!baseGeneralPdfTemplateModel.isEdit">
            {{'COMMON_NAME' | translate}}
          </label>
          <div class="col-md-8">
            <input *ngIf="!baseGeneralPdfTemplateModel.isEdit"
                   type="text" class="form-control"
                   placeholder="{{'COMMON_NAME' | translate}}"
                   maxlength="{{UiConstants.maximumVarcharLength}}"
                   validateOnBlur [validateFormControl]="formGroup.controls['code']"
                   [matAutocomplete]="codeAutoGroup"
                   [ngClass]="{ 'form-control-danger': hasLocalFieldError('code') }"
                   [(ngModel)]="baseGeneralPdfTemplateModel.code"
                   (ngModelChange)="removeFieldError(GeneralPdfTemplate.ValidatedField.CODE)"
                   formControlName="code"
                   (click)="onAutoCompleteClick('code')">
            <mat-autocomplete #codeAutoGroup="matAutocomplete">
              <mat-option *ngFor="let codeOption of codeOptions$ | async" [value]="codeOption">
                {{codeOption}}
              </mat-option>
            </mat-autocomplete>
            <label
              *ngIf="baseGeneralPdfTemplateModel.isEdit"
              class="form-control detail-description"
              readonly>{{baseGeneralPdfTemplateModel.code}}
            </label>
            <div class="form-control-feedback"
                 *ngIf="fieldErrors.code">{{fieldErrors.code.text}}</div>
            <div class="form-control-feedback"
                 *ngIf="hasLocalFieldError('code')">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</div>
          </div>
          <!-- /.col-md-8 -->
        </div>

        <div class="modal-body pt-0">
          <app-file-upload
            #fileUploadComponent
            [uploadPath]="''"
            [uploadOnDrop]="false"
            (onFileDropped)="fileUploadEmptyError = false"
            (onResult)="onUploadResult($event)">
          </app-file-upload>
          <div class="has-danger"
               *ngIf="fileUploadEmptyError">
            <div class="form-control-feedback">
              {{'COMMON_VALIDATION_MESSAGE_NO_FILE_UPLOADED' | translate}}
            </div>
          </div>
          <div class="has-danger"
               *ngIf="fileFormatError">
            <div class="form-control-feedback">
              {{'GENERAL_PDF_TEMPLATE_FILE_FORMAT_ERROR' | translate}}
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="closeBaseDialog()">
          {{'COMMON_BUTTON_CANCEL' | translate}}
        </button>
        <button type="button" class="btn btn-primary btn-ladda"
                data-style="zoom-in" [ladda]="templateSaving" [disabled]="templateSaving"
                (click)="createGeneralPdfTemplate()">{{'COMMON_BUTTON_SAVE' |
          translate}}
        </button>
      </div>
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div><!-- /.modal -->
