<div bsModal [config]="UiConstants.modalConfig" #dialog="bs-modal" class="modal fade" tabindex="-1" role="dialog"
     aria-labelledby="myModalLabel"
     aria-hidden="true">
  <div class="modal-dialog modal-xl modal-dialog-centered" role="document" *ngIf="isVisible">
    <div class="modal-content">
      <div class="modal-header">
        <p class="modal-title"><strong>{{'SHIPPING_DEMAND_SPLIT' | translate}}</strong></p>
        <button type="button" class="close" (click)="hide()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">


        <div class="card">
          <div class="card-header">
            {{'SHIPPING_DEMAND_SPLIT_ITEMS' | translate}}
          </div>
          <div class="card-body">
            <table class="table table-striped table-bordered">
              <thead>
              <tr>
                <th class="table-sorter-header w-checkbox" *ngIf="!selected"></th>
                <th class="table-sorter-header w-5 hidden-xs-down"></th>
                <th class="table-sorter-header long">
                  <app-table-sorter-no-op [text]="'SHIPPING_DEMAND_SPLIT_ITEM_NAME' | translate"></app-table-sorter-no-op>
                </th>
                <th class="table-sorter-header hidden-lg-down">
                  <app-table-sorter-no-op [text]="'SHIPPING_DEMAND_SPLIT_ITEM_AVAILABLE_AMOUNT' | translate"></app-table-sorter-no-op>
                </th>
                <th class="table-sorter-header">
                  <app-table-sorter-no-op [text]="'SHIPPING_DEMAND_SPLIT_ITEM_SELECTED_AMOUNT' | translate"></app-table-sorter-no-op>
                </th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let item of items; index as i ">
                <td class="w-checkbox align-middle" *ngIf="!selected">
                  <app-animated-checkbox [(selected)]="item.selected"></app-animated-checkbox>
                </td>
                <td class="hidden-xs-down w-5 align-middle">
                  {{i + 1}}
                </td>
                <td class="responsive-table-column table-column-double long align-middle">
                  {{item.name}}
                  <p class="text-muted mb-0">{{item.externalId}}</p>
                </td>
                <td class="responsive-table-column hidden-lg-down align-middle">{{item.availableAmount}} {{item.unit}}</td>
                <td class="responsive-table-column align-middle" *ngIf="!selected" [ngClass]="{ 'has-danger': !isItemValid(item) }">
                  <div class="row m-0 d-flex align-items-center" *ngIf="item.selected">
                    <div class="flex-grow-1 mr-1">
                      <input type="number" class="form-control"
                             placeholder="{{'SHIPPING_DEMAND_SPLIT_ITEM_SELECTED_AMOUNT' | translate}}"
                             [(ngModel)]="item.selectedAmount"
                             [ngClass]="{ 'form-control-danger': !isItemValid(item) }">
                    </div>
                    {{item.unit}}
                  </div>
                  <div class="form-control-feedback" *ngIf="!isItemValid(item)">
                    <span *ngIf="item.selectedAmount < 1">
                      {{'SHIPPING_DEMAND_SPLIT_SELECTED_AMOUNT_ERROR_BELOW' | translate}}
                    </span>
                    <span *ngIf="item.selectedAmount > item.availableAmount">
                      {{'SHIPPING_DEMAND_SPLIT_SELECTED_AMOUNT_ERROR_OVER' | translate}}
                    </span>
                  </div>
                </td>
                <td class="responsive-table-column" *ngIf="selected">{{item.selectedAmount}} {{item.unit}}</td>
              </tr>
              </tbody>
              <tfoot>
              <tr>
                <th class="table-sorter-header w-checkbox" *ngIf="!selected"></th>
                <th class="table-sorter-header w-5 hidden-xs-down"></th>
                <th class="table-sorter-header long">
                  <app-table-sorter-no-op [text]="'SHIPPING_DEMAND_SPLIT_ITEM_NAME' | translate"></app-table-sorter-no-op>
                </th>
                <th class="table-sorter-header hidden-lg-down">
                  <app-table-sorter-no-op [text]="'SHIPPING_DEMAND_SPLIT_ITEM_AVAILABLE_AMOUNT' | translate"></app-table-sorter-no-op>
                </th>
                <th class="table-sorter-header">
                  <app-table-sorter-no-op [text]="'SHIPPING_DEMAND_SPLIT_ITEM_SELECTED_AMOUNT' | translate"></app-table-sorter-no-op>
                </th>
              </tr>
              </tfoot>
            </table>
          </div>
        </div>

      </div>
      <!-- /.modal-body -->
      <div class="modal-footer justify-content-end">
        <span>
          <button type="button" class="btn btn-outline-primary" (click)="hide()">
            {{'COMMON_BUTTON_CANCEL' | translate}}
          </button>
          <button type="button" class="btn btn-primary ml-1" *ngIf="!selected" (click)="selectItems()">
            {{'COMMON_BUTTON_CONTINUE' | translate}}
          </button>
          <button type="button" class="btn btn-primary ml-1" *ngIf="selected" (click)="splitDemand()">
            {{'SHIPPING_DEMAND_SPLIT_DEMAND' | translate}}
          </button>
        </span>
      </div>
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div><!-- /.modal -->
