/* eslint-disable */
import { AfterViewInit, Component, Input, OnInit, } from '@angular/core';
import { NamedBlobFile, } from '../../../lib/util/downloaded-files';
import { Observable } from 'rxjs';
import { FileAttachment, FileAttachmentUpdateRequest } from '../../../lib/util/file-attachments';
import { RightResolver, RightService } from '../../../lib/right.service';
import { RightModel } from '../../../app.rights';
import { CustomerRecord, CustomerRecordService } from '../../../lib/customer/customer-record.service';
import { EmptyMessage } from '../../../lib/util/messages';
import { ThumbnailLoaderFactory } from '../../../shared/material-attachment-gallery/material-attachment-gallery.component';
import { CustomerRecordRightModel } from '../../../util/customer-record-utils';

/* eslint-enable */

@Component({
  selector: 'app-customer-record-attachment',
  templateUrl: './customer-record-attachment.component.html',
  styleUrls: ['./customer-record-attachment.component.scss'],
})
export class CustomerRecordAttachmentComponent implements OnInit, AfterViewInit, ThumbnailLoaderFactory {

  @Input()
  customerRecordId: number;

  @Input()
  readonly: boolean;

  @Input()
  customerId: number;

  @Input()
  grantedRights: CustomerRecordRightModel;

  fileLoaderFactory = this;

  constructor(private customerRecordService: CustomerRecordService) {
  }

  getUploadPath(): string {
    return '/customers/' + this.customerId + '/records/' + this.customerRecordId + '/attachments';
  }

  ngOnInit() {
  }

  initComponent() {
    if (this.grantedRights.attachmentRead.hasRight()) {
      this.loadAttachments();
    }
  }

  loadAttachments() {
    return this.customerRecordService.getAttachments({
      customerRecordId: this.customerRecordId,
      customerId: this.customerId,
    });
  }

  deleteAttachment(fileId: number) {
    return this.customerRecordService.deleteAttachment({
      customerId: this.customerId,
      customerRecordId: this.customerRecordId,
      fileId: fileId
    });
  }

  public createFileLoader(attachment: FileAttachment): Observable<NamedBlobFile> {
    return this.customerRecordService.downloadAttachment({
      customerId: this.customerId,
      customerRecordId: this.customerRecordId,
      attachment: attachment,
    });
  }

  hasThumbnails(): boolean {
    return true;
  }

  createThumbnailLoader(attachment: FileAttachment): Observable<NamedBlobFile> {
    return this.customerRecordService.downloadAttachmentThumbnail({
      customerId: this.customerId,
      customerRecordId: this.customerRecordId,
      attachment: attachment,
    });
  }

  updateAttachment(r: FileAttachmentUpdateRequest): Observable<EmptyMessage> {
    const request: CustomerRecord.UpdateAttachmentRequest = {
      name: r.name,
      fileId: r.fileId,
      customerId: this.customerId,
      customerRecordId: this.customerRecordId
    };
    return this.customerRecordService.updateAttachment(request);
  }

  ngAfterViewInit(): void {
  }

}
