<div class="breadcrumb-container" [ngClass]="{ 'breadcrumb-container-compact': compactSidebar }" id="breadcrumb">
  <div class="breadcrumb-component">
    <app-breadcrumb
      [parents]="breadcrumbParents"
      [self]="breadcrumbSelf">
    </app-breadcrumb>
    <app-breadcrumb-menu>
      <!-- region EDIT-->
      <app-breadcrumb-button
        *ngIf="rightModel.stockUpdate.hasRight()"
        [iconClass]="'icomoon-modify'"
        [titleStringKey]="'COMMON_BUTTON_EDIT'"
        uiSref="Admin.StockEdit"
        [uiParams]="{ id: stockId }">
      </app-breadcrumb-button>
      <!-- endregion EDIT-->
    </app-breadcrumb-menu>
  </div>
</div>
<div class="breadcrumb-placeholder"></div>
<div class="container-horizontal-padding container-vertical-padding-half animated fadeIn">
  <responsive-tabset class="fullscreen_tab">
    <responsive-tab heading="{{'COMMON_CARD_HEADER_BASE_DATA'|translate}}">
      <div class="p-0 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-xs-12">
        <div class="card">

          <div class="card-header">
            {{'STOCK_PANEL_HEADING_DETAIL'|translate}}
          </div>

          <div class="card-body">

            <div class="form-group row">
              <label
                class="col-form-label form-control-label col-md-4 detail-title">{{'COMMON_NAME' | translate}}</label>
              <label class="col-form-label form-control-label col-md-8 detail-description">{{model.name}}</label>
            </div>

            <div class="form-group row">
              <label
                class="col-form-label form-control-label col-md-4 detail-title">{{'STOCK_EXTERNAL_ID' | translate}}</label>
              <label class="col-form-label form-control-label col-md-8 detail-description">{{model.externalId}}</label>
            </div>

            <div class="form-group row">
              <label
                class="col-form-label form-control-label col-md-4 detail-title">{{'STOCK_TYPE' | translate}}</label>
              <label
                class="col-form-label form-control-label col-md-8 detail-description">{{StockType.fromName(model.type)!.textDictionaryKey | translate}}</label>
            </div>

            <div *ngIf="model.type === 'PERSONAL'" class="form-group row">
              <label class="col-form-label form-control-label col-md-4 detail-title">{{'STOCK_LABEL_OWNER_USER' |
                translate}}</label>
              <label
                class="col-form-label form-control-label col-md-8 detail-description">{{getOwnerUsersString()}}</label>
            </div>

            <div *ngIf="model.type === 'COMPANY' && rightModel.companyReadAll.hasRight()" class="form-group row">
              <div class="col-md-4">
                <label
                  class="mw-100 col-form-label form-control-label">{{'STOCK_CREATE_EDIT_COMPANY'|translate}}</label>
              </div>
              <label
                class="col-form-label form-control-label col-md-8 detail-description">{{model.company.length > 0 ? model.company[0].itemName : ''}}</label>
            </div>

            <div *ngIf="model.type === 'CUSTOMER' && rightModel.customerRecordRead.hasRight() && model.customerRecord.length > 0" class="form-group row">
              <div class="col-md-4">
                <label
                  class="mw-100 col-form-label form-control-label">{{'STOCK_CREATE_EDIT_CUSTOMER_RECORD'|translate}}</label>
              </div>
              <div class="col-md-8 d-flex flex-column">
              <label
                class="col-form-label form-control-label detail-description">{{model.customerRecord[0].itemName}}</label>
              <label
                class="col-form-label form-control-label text-muted">{{model.customerRecord[0].itemSubtitle}}</label>
              </div>
            </div>

            <div *ngIf="model.type === 'CUSTOMER' && rightModel.customerRecordRead.hasRight() && model.contactLocation.length > 0" class="form-group row">
              <div class="col-md-4">
                <label
                  class="mw-100 col-form-label form-control-label">{{'STOCK_CREATE_EDIT_CONTACT_LOCATION'|translate}}</label>
              </div>
              <label
                class="col-form-label form-control-label col-md-8 detail-description">{{model.contactLocation[0].itemName}}</label>
            </div>

            <div *ngIf="model.type === 'COMPANY'" class="form-group row">
              <div class="col-md-4">
                <label
                  class="mw-100 col-form-label form-control-label">{{'STOCK_CREATE_EDIT_CENTRAL'|translate}}</label>
              </div>
              <div class="col-md-8">
                <div class="row pl-1">
                  <app-animated-checkbox [selected]="model.central" [readonly]="true"
                                         class="mr-1 col-form-label"></app-animated-checkbox>
                </div>
              </div>
            </div>

            <div *ngIf="model.type === 'COMPANY'" class="form-group row">
              <div class="col-md-4">
                <label class="mw-100 col-form-label form-control-label">{{'STOCK_CREATE_EDIT_WASTE'|translate}}</label>
              </div>
              <div class="col-md-8">
                <div class="row pl-1">
                  <app-animated-checkbox [selected]="model.waste" [readonly]="true"
                                         class="mr-1 col-form-label"></app-animated-checkbox>
                </div>
              </div>
            </div>


          </div>
        </div>
      </div>

      <div class="container-vertical-padding-half"></div>

      <div class="p-0 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-xs-12" *ngIf="model.sums">
        <div class="card">

          <div class="card-header">
            {{'STOCK_RECORD_LOT_INTAKE_PRICE_SUM'|translate}}
          </div>

          <div class="card-body">
            <div class="form-group row" *ngFor="let sum of model.sums">
              <div class="col-md-4">
                <label readonly
                       class="mw-100 col-form-label form-control-label">{{ getCurrencyName(sum.currency_code) }}</label>
              </div>
              <div class="col-md-8">
                <div class="row" >
                  <label readonly
                         class="col-form-label form-control-label col-md-8 detail-description font-weight-bold">{{ sum.intake_sum_net_price | formattedNumber }}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="container-vertical-padding-half" *ngIf="model.sums"></div>

      <app-stock-stock-record-list
        [stockId]="stockId"
        [rightModel]="rightModel"
        [readonly]="true"
        (onExport)="exportXls()">
      </app-stock-stock-record-list>
    </responsive-tab>
    <responsive-tab heading="{{'STOCK_RECORD_LOG_PANEL_HEADING_STOCK'|translate}}">
      <app-stock-log-list
        [type]="StockLogListType.STOCK"
        [resourceId]="stockId">
      </app-stock-log-list>
    </responsive-tab>
  </responsive-tabset>

</div>
