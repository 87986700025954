/* eslint-disable */
import { Component, OnInit, ViewChild, } from '@angular/core';
import { Transition, UIRouter } from '@uirouter/angular';
import { MultiselectOptionItem, UiConstants } from '../../../util/core-utils';
import { StateName } from '../../../app.state-names';
import { InputMask } from '../../../util/input-masks';
import {
  DefaultDeadlineTypeItem,
  DefaultDeadlineTypeTextResolverFactory, droidStates, manualTimeTrackingTypes,
  StateFilterModel,
  SubmittedFilterModel,
  TaskCreateModel,
  TaskInvoiceGenerationType,
  taskInvoiceGenerationTypes,
  TaskInvoiceSettingsItem,
  TaskRecordExternalIdSequence, TaskRecordQuickCreateNameTemplate,
  taskSignatureValidationTypes,
  TaskTimeTrackingType,
  taskTimeTrackingTypes,
  TaskValidationTypeTextResolverFactory,
  ValidationTypeItem
} from '../../../util/task-utils';
import { Task, TaskService } from '../../../lib/task/task.service';
import {
  FieldValidationError,
  ForwardingNgFormRef,
  LocalFormGroupValidationErrors,
  OrderType,
  QueryResult,
  ResourceQueryResult,
  Services
} from '../../../lib/util/services';
import { FormBuilder, FormGroup, NgForm, Validators, } from '@angular/forms';
import { LoadingHandler } from '../../../lib/loading-handler';
import { AppValidators } from '../../../util/app-validators';
import { List, Set } from 'immutable';
import { TaskResource } from '../../../lib/task/task-resource.service';
import { BreadcrumbParent } from '../../../shared/breadcrumb/breadcrumb/breadcrumb.component';
import { TranslateService } from '@ngx-translate/core';
import { Angular2Multiselects } from '../../../util/multiselect';
import { Models } from '../../../util/model-utils';
import { Survey, SurveyService } from '../../../lib/survey/survey.service';
import { UserGroup, UserGroupService } from '../../../lib/user-group.service';
import { ToasterService } from '../../../fork/angular2-toaster/angular2-toaster';
import { Strings } from '../../../lib/util/strings';
import { InvoiceSettings, InvoiceSettingsService } from '../../../lib/invoice/invoice-settings/invoice-settings.service';
import { InvoiceBook, InvoiceBookService } from '../../../lib/invoice/invoice-book/invoice-book.service';
import { ConfigurationService } from '../../../lib/core-ext/configuration.service';
import { TaskRecord } from '../../../lib/task/task-record.service';
import { IdentityMessage } from '../../../lib/util/messages';
import { TaskRecordStateMachine } from '../../../lib/task/task-record-statemachine';
import { MessageTemplate, MessageTemplateService } from '../../../lib/message-template/message-template.service';
import { UserService, } from '../../../lib/user.service';
import { StringKey } from '../../../app.string-keys';
import { InvoiceTagMultiselectProvider } from '../../../lib/invoice/tag/invoice-tag-multiselect-provider.service';
import { RightModel } from '../../../app.rights';
import { RightResolver, RightService } from '../../../lib/right.service';
import InvoiceIntegrationType = InvoiceSettings.InvoiceIntegrationType;

/* eslint-enable */

@Component({
  selector: 'app-new-task-create',
  templateUrl: 'task-create.component.html',
  styleUrls: ['task-create.component.scss']
})
export class TaskCreateComponent implements OnInit {

  InputMask = InputMask;
  Task = Task;
  UiConstants = UiConstants;
  TaskRecordExternalIdSequence = TaskRecordExternalIdSequence;
  InvoiceSettings = InvoiceSettings;
  TaskInvoiceGenerationType = TaskInvoiceGenerationType;
  TaskTimeTrackingType = TaskTimeTrackingType;
  TaskRecordQuickCreateNameTemplate = TaskRecordQuickCreateNameTemplate;
  taskTimeTrackingTypes = taskTimeTrackingTypes;
  manualTimeTrackingTypes = manualTimeTrackingTypes;
  taskInvoiceGenerationTypes = taskInvoiceGenerationTypes;
  pocRequirements = Task.placeOfConsumptionRequirements;
  defaultAssigneeTypes = Task.taskDefaultAssigneeTypes;

  @ViewChild('f', {static: true})
  fForm: NgForm;

  formGroup: FormGroup;
  model: TaskCreateModel;
  validationTypeItems: ValidationTypeItem[];
  signatureValidationTypeItems: MultiselectOptionItem<TaskResource.TaskSignatureValidationType>[] = taskSignatureValidationTypes;
  photoEnabledInStateItems: MultiselectOptionItem<string>[] = droidStates;
  defaultDeadlineTypeItems: DefaultDeadlineTypeItem[];
  fieldErrors: FieldValidationError<Task.ValidatedField>;

  surveys: MultiselectOptionItem<number>[] = [];
  userGroups: MultiselectOptionItem<number>[] = [];
  invoiceSettings: TaskInvoiceSettingsItem[] = [];
  invoiceBooks: MultiselectOptionItem<number>[] = [];
  invoiceTags: MultiselectOptionItem<number>[] = [];
  managableFields: MultiselectOptionItem<TaskRecord.TaskRecordField>[] = [];
  _requirableFields: MultiselectOptionItem<TaskRecord.TaskRecordField>[] = [];
  get requirableFields(): MultiselectOptionItem<TaskRecord.TaskRecordField>[] {
    if (this.model.useDefaultManagedFields) {
      return this._requirableFields.filter(f => this.managableFields.map(f => f.id).includes(f.id));
    }
    return this._requirableFields.filter(f => this.model.managedFields.map(f => f.id).includes(f.id));
  }

  get helpdeskRequirableFields(): MultiselectOptionItem<TaskRecord.TaskRecordField>[] {
    return this._requirableFields.filter(f => this.model.helpdeskManagedFields.map(f => f.id).includes(f.id));
  }

  defaultStateOptions: MultiselectOptionItem<TaskRecordStateMachine.State>[] = [];
  editableStateOptions: MultiselectOptionItem<TaskRecordStateMachine.State>[] = [];
  messageTemplates: MultiselectOptionItem<number>[] = [];
  states: MultiselectOptionItem<TaskRecordStateMachine.State>[]
    = TaskRecordStateMachine.getOrderedStates(true).toArray()
    .map(s => ({id: s.state, itemName: s.stringKey}));

  breadcrumbParents: BreadcrumbParent[] = [];
  breadcrumbSelf: string;
  compactSidebar: boolean = document.querySelector('body')!.classList.contains('sidebar-compact');

  paymentDropdownSettings: Angular2Multiselects.Settings;
  invoiceDropdownSettings: Angular2Multiselects.Settings;
  searchDropdownSettings: Angular2Multiselects.Settings;
  managedFieldsDropdownSettings: Angular2Multiselects.Settings;
  defaultStateDropdownSettings: Angular2Multiselects.Settings;
  editableStateDropdownSettings: Angular2Multiselects.Settings;
  pocRequirementDropdownSettings: Angular2Multiselects.Settings;
  submittedFilterDropdownSettings: Angular2Multiselects.Settings;
  stateFilterDropdownSettings: Angular2Multiselects.Settings;
  signatureValidationDropdownSettings: Angular2Multiselects.Settings;
  photoEnabledInStatesDropdownSettings: Angular2Multiselects.Settings;

  rightModel: RightModel = RightModel.empty();

  private formGroupValidationErrors: LocalFormGroupValidationErrors;

  constructor(
    private fb: FormBuilder,
    validationTypeTextResolverFactory: TaskValidationTypeTextResolverFactory,
    defaultDeadlineTypeTextResolverFactory: DefaultDeadlineTypeTextResolverFactory,
    private taskService: TaskService,
    private uiRouter: UIRouter,
    private translateService: TranslateService,
    private rightService: RightService,
    private transition: Transition,
    private surveyService: SurveyService,
    private userGroupService: UserGroupService,
    private userService: UserService,
    private invoiceSettingsService: InvoiceSettingsService,
    private invoiceBookService: InvoiceBookService,
    private invoiceTagMultiselectProvider: InvoiceTagMultiselectProvider,
    private messageTemplateService: MessageTemplateService,
    public configService: ConfigurationService,
    private toasterService: ToasterService) {
    const validationTypeTextResolver = validationTypeTextResolverFactory.createFunction();
    const defaultDeadlineTypeTextResolver = defaultDeadlineTypeTextResolverFactory.createFunction();
    this.validationTypeItems = ValidationTypeItem.fromTypeList(List.of<TaskResource.TaskValidationType>(
      'DISABLED', 'REQUIRED', 'REQUEST_POSSIBLE'
    ), validationTypeTextResolver).toArray();
    this.defaultDeadlineTypeItems = DefaultDeadlineTypeItem.fromTypeList(List.of<TaskResource.DayType>(
      'BUSINESS_DAY', 'CALENDAR_DAY'
    ), defaultDeadlineTypeTextResolver).toArray();
    this.model =
      new TaskCreateModel(this.validationTypeItems[0], this.defaultDeadlineTypeItems[0], this.translateService, this.configService);
    this.formGroup = this.createFormGroup(fb);
    this.formGroupValidationErrors = LocalFormGroupValidationErrors.ofForm(
      this.createForwardingHtmlForm(), this.formGroup
    );
    this.fieldErrors = FieldValidationError.empty<Task.ValidatedField>();
    this.loadSurveys();
    this.loadUserGroups();
    this.loadInvoiceSettings();
    this.loadTaskRecordFields();
    this.loadDefaultStateOptions();
    this.loadEditableStateOptions();
    this.loadMessageTemplates();
    this.initDropDown();
    this.loadRightModel();
  }

  ngOnInit() {
    this.translateService.get('FORM_CREATE_TITLE').subscribe(
      (result: string) => {
        this.breadcrumbSelf = result;
      }
    );
    this.translateService.get('MENU_NAVBAR_MENU_ADMINISTRATION').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.ADMIN_DASHBOARD});
      }
    );
    this.translateService.get('MENU_FORMS').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.TASK_LIST});
      }
    );
  }

  private loadRightModel() {
    this.rightService.getRightResolver().subscribe(
      (resolver: RightResolver) => {
        this.rightModel = RightModel.of(resolver);
      }
    );
  }


  initDropDown() {
    this.paymentDropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(false)
      .enableSearchFilter(false)
      .enableCheckAll(true)
      .translate(true)
      .build();
    this.invoiceDropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(true)
      .enableSearchFilter(true)
      .enableCheckAll(false)
      .remoteSearch(true)
      .build();
    this.searchDropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(false)
      .enableSearchFilter(true)
      .enableCheckAll(true)
      .remoteSearch(true)
      .build();
    this.managedFieldsDropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(false)
      .enableSearchFilter(true)
      .enableCheckAll(true)
      .translate(true)
      .build();
    this.defaultStateDropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(true)
      .enableSearchFilter(false)
      .enableCheckAll(false)
      .translate(true)
      .build();
    this.editableStateDropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(false)
      .enableSearchFilter(true)
      .enableCheckAll(true)
      .translate(true)
      .build();
    this.pocRequirementDropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(true)
      .enableSearchFilter(false)
      .enableCheckAll(false)
      .translate(true)
      .labelKey('stringKey')
      .build();
    this.submittedFilterDropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(true)
      .enableSearchFilter(true)
      .enableCheckAll(false)
      .remoteSearch(true)
      .text('TASK_SUBMITTED_FILTER_USER_GROUP')
      .build();
    this.stateFilterDropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(false)
      .enableSearchFilter(false)
      .enableCheckAll(true)
      .translate(true)
      .text('TASK_STATE_FILTER_STATES')
      .build();
    this.signatureValidationDropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(true)
      .enableSearchFilter(true)
      .enableCheckAll(false)
      .translate(true)
      .text('TASK_SIGNATURE_VALIDATION')
      .build();
    this.photoEnabledInStatesDropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(false)
      .enableSearchFilter(true)
      .enableCheckAll(true)
      .remoteSearch(false)
      .translate(true)
      .build();
  }

  createTask() {
    this.formGroup.get('defaultEstimatedTime')!.updateValueAndValidity();
    let defaultDeadline: Task.TaskDefaultDeadline;
    if (this.model.hasDefaultDeadline && this.model.defaultDeadlineType.id) {
      defaultDeadline = {
        dayType: this.model.defaultDeadlineType.id,
        offset: this.model.offset
      };
    }
    if (this.hasLocalFieldError()) {
      this.showValidationErrorToast();
      return;
    }
    this.taskService.create({
      languageCode: UiConstants.defaultLanguageCode,
      name: this.model.name,
      externalId: this.model.externalId,
      description: this.model.description,
      validationType: this.model.validationTypeItem!.id!,
      customerSignature: this.model.customerSignature,
      userSignature: this.model.userSignature,
      pictureUpload: this.model.pictureUpload,
      photoConfirmDialogEnabled: this.model.photoConfirmDialogEnabled,
      photoEnabledInStates: this.model.photoEnabledInStatesSet,
      minNumOfPics: Models.parseNumber(this.model.minNumOfPics),
      maxNumOfPics: Models.parseNumber(this.model.maxNumOfPics),
      customerAttachmentEnabled: this.model.customerAttachmentEnabled,
      parallelWorkRejected: this.model.parallelWorkRejected,
      icon: this.model.icon,
      timeTrackingEnabled: this.model.taskTimeTrackingType === TaskTimeTrackingType.AUTOMATIC,
      manualTimeTrackingEnabled: this.model.taskTimeTrackingType === TaskTimeTrackingType.MANUAL,
      manualTimeTrackingRequired:  this.getManualTimeTrackingRequired(),
      manualTimeTrackingType: this.getManualTimeTrackingType(),
      collaboratorTimeTrackingEnabled:
        this.model.taskTimeTrackingType === TaskTimeTrackingType.AUTOMATIC
          ? this.model.collaboratorTimeTrackingEnabled
          : false,
      showPicturesOnForm: this.model.showPicturesOnForm,
      defaultEstimatedTimeInMinutes: Models.parseNumber(this.model.defaultEstimatedTimeInMinutes),
      mileageRecordEnabled: this.model.mileageRecordEnabled,
      mileageRecordRequired: this.model.mileageRecordRequired,
      geofencingEnabled: this.model.geofencingEnabled,
      geofencingRadiusInMeters: this.model.geofencingEnabled ? this.model.geofencingRadiusInMeters : undefined,
      autoInvoiceGenerate: this.model.taskInvoiceGenerationType === TaskInvoiceGenerationType.AUTOMATIC,
      manualInvoiceEnabled: this.model.taskInvoiceGenerationType === TaskInvoiceGenerationType.MANUAL,
      invoiceSettingsId: this.model.invoiceSettingsId,
      invoiceBookId: this.model.invoiceBookId,
      invoiceTagId: this.model.invoiceTagId,
      printInvoiceCount: Models.parseNumber(this.model.printInvoiceCount),
      validInvoicePaymentTypes: this.model.validInvoicePaymentTypes,
      generatingInvoicePaymentTypes: this.model.generatingInvoicePaymentTypes,
      defaultLinkedSurveyIds: this.model.defaultLinkedSurveyIds,
      defaultDeadline: this.model.hasDefaultDeadline ? defaultDeadline! : undefined,
      creatorUserGroups: this.model.creatorUserGroupIds,
      enabledAssignees: {
        userGroups: this.model.enabledUserGroupIds
      },
      taskRecordExternalIdSequenceData: this.model.taskRecordExternalIdSequence.length > 0 ? {
        template: this.model.taskRecordExternalIdSequence,
        withinYear: this.model.withinYear
      } : undefined,
      taskRecordNameTemplate: Strings.undefinedOrNonEmpty(this.model.taskRecordNameTemplate),
      useDefaultManagedFields: this.model.useDefaultManagedFields,
      managedFields: this.model.useDefaultManagedFields ? undefined : Set.of(...this.model.managedFields.map(f => f.id)),
      helpdeskManagedFields: !this.model.helpdeskEnabled ? undefined : Set.of(...this.model.helpdeskManagedFields.map(f => f.id)),
      requiredFields: Set.of(...this.model.requiredFields.map(f => f.id)),
      helpdeskRequiredFields: !this.model.helpdeskEnabled ? undefined : Set.of(...this.model.helpdeskRequiredFields.map(f => f.id)),
      defaultState: this.model.getDefaultState(),
      defaultStateForceNew: this.model.defaultStateForceNew,
      showOnDashboard: this.model.showOnDashboard,
      explicitOrderNumber: Models.parseNumber(this.model.explicitOrderNumber),
      placeOfConsumptionRequirement: this.model.pocRequirement!,
      webCreateDefaultAssigneeType: this.model.webCreateDefaultAssigneeType!,
      helpdeskEnabled: this.model.helpdeskEnabled,
      setCustomerOwnerUserAfterFinish: this.model.setCustomerOwnerUserAfterFinish,
      setCustomerOwnerUserGroupAfterFinish: this.model.setCustomerOwnerUserGroupAfterFinish,
      submittedFilters: this.model.submittedFilters
        .map(f => ({userGroupId: f.userGroupId!, days: Models.parseNumber(f.days)!})),
      inProgressSignatureEnabled: this.model.inProgressSignatureEnabled,
      smsRatingEnabled:
        this.configService.getConfiguration().feature_flags.sms_futar_enabled
          ? this.model.smsRatingEnabled
          : false,
      smsRatingDeliveryScheduleMin:
        this.configService.getConfiguration().feature_flags.sms_futar_enabled && this.model.smsRatingEnabled
          ? Models.parseNumber(this.model.smsRatingDeliveryScheduleMin)
          : undefined,
      smsRatingTemplateId:
        this.configService.getConfiguration().feature_flags.sms_futar_enabled && this.model.smsRatingEnabled
          ? this.model.smsRatingTemplateId
          : undefined,
      stateFilters: this.model.stateFilters
        .map(f => ({userGroupId: f.userGroupId!, states: f.rawStates})),
      adminEditableStates: this.model.adminEditableStates.map(s => s.id),
      taskRecordColor: this.model.color
    }).subscribe(
      (response: IdentityMessage) => {
        LoadingHandler.getInstance().refresh();
        this.uiRouter.stateService.go(StateName.TASK_EDIT, {id: response.id});
      },
      (error: any) => {
        if (error instanceof FieldValidationError) {
          this.fieldErrors = error.withForm(this.fForm);
          this.showValidationErrorToast();
        }
      }
    );
  }

  private showValidationErrorToast() {
    this.toasterService.pop({
      timeout: UiConstants.ToastTimeoutLong,
      type: UiConstants.toastTypeError,
      title: this.translateService.instant(StringKey.COMMON_FORM_VALIDATION_ERROR_TOAST_TITLE),
      body: this.translateService.instant(StringKey.COMMON_FORM_VALIDATION_ERROR_TOAST_MESSAGE)
    });
  }

  loadSurveys() {
    this.surveyService.query({
      disabled: false,
      orders: Set.of({type: OrderType.ASC, field: Survey.OrderField.NAME})
    }).subscribe((result: QueryResult<Survey.Survey>) => {
      this.surveys = [];
      result.items.forEach(survey => {
        if (survey) {
          this.surveys.push({id: survey.id, itemName: survey.name, itemSubtitle: survey.externalId});
        }
      })
    });
  }

  loadUserGroups() {
    this.userGroupService.query({
      disabled: false,
      order: Services.createOrderFieldParameter(UserGroup.Keys.toOrderFieldKey, Set.of(UserGroup.DEFAULT_ORDER))
    }).subscribe(
      (result: ResourceQueryResult<UserGroup>) => {
        this.userGroups = [];
        result.items.forEach(u => {
          this.userGroups.push({id: u.id, itemName: u.name});
        })
      }
    )
  }

  loadInvoiceSettings(q?: string) {
    if (this.rightModel.invoiceSettingsRead.hasRight()) {
      this.invoiceSettingsService.query({
        profileName: q,
        disabled: false,
        invoiceDirectionType: 'OUTBOUND',
        orders: Set.of({field: InvoiceSettings.OrderField.PROFILE_NAME, type: OrderType.ASC}),
        paging: {
          numberOfItems: UiConstants.autocompletePageSize,
          pageNumber: 1
        },
        noProgressBar: true
      })
        .subscribe(
          (result: QueryResult<InvoiceSettings.InvoiceSettings>) => {
            this.invoiceSettings = result.items.toArray().map(s => ({
              id: s.id,
              itemName: s.profileName,
              integrationType: s.invoiceIntegrationType
            }));
          }
        );
    }
  }

  loadInvoiceBooks(q?: string) {
    if (this.rightModel.invoiceBookRead.hasRight()) {
      this.invoiceBookService.query({
        prefix: q,
        invoiceSettingsIds: Set.of(this.model.invoiceSettingsId!),
        disabled: false,
        orders: Set.of({field: InvoiceBook.OrderField.PREFIX, type: OrderType.ASC}),
        paging: {
          numberOfItems: UiConstants.autocompletePageSize,
          pageNumber: 1
        },
        noProgressBar: true
      })
        .subscribe(
          (result: QueryResult<InvoiceBook.InvoiceBook>) => {
            this.invoiceBooks = result.items.toArray().map(s => ({id: s.id, itemName: s.prefix}));
          }
        );
    }
  }

  loadInvoiceTags(q?: string) {
    if (this.rightModel.invoiceTagRead.hasRight()) {
      this.invoiceTagMultiselectProvider.loadActive(q).subscribe(tags => {
        this.invoiceTags = tags;
      });
    }
  }


  private loadTaskRecordFields() {
    const managableFields = this.configService.getConfiguration().feature_flags.task_record_managed_fields;
    this.managableFields = managableFields.map(f => {
      const stringKey = TaskRecord.taskRecordFields.find(field => field.field === f)!.stringKey;
      const item = {
        id: f,
        itemName: stringKey
      };
      this.model.managedFields.push(item);
      this.model.helpdeskManagedFields.push(item);
      return item;
    });
    this._requirableFields = TaskRecord.taskRecordRequirableFields.map(f => {
      return {
        id: f.field,
        itemName: f.stringKey
      };
    });
  }

  private loadDefaultStateOptions() {
    const newObject = TaskRecordStateMachine.taskRecordStates.get('NEW');
    const newItem = {
      id: newObject.state,
      itemName: newObject.stringKey
    };
    if (this.model.defaultState.length === 0) {
      this.model.defaultState.push(newItem);
    }
    this.defaultStateOptions.push(newItem);
    const openObject = TaskRecordStateMachine.taskRecordStates.get('OPEN');
    const openItem = {
      id: openObject.state,
      itemName: openObject.stringKey
    };
    this.defaultStateOptions.push(openItem);
  }

  private loadEditableStateOptions() {
    this.editableStateOptions = [];
    TaskRecordStateMachine.taskRecordStates.filter(s => s !== undefined && s.isPresentable && s.state !== 'PENDING_APPROVAL')
      .forEach(s => {
        if (s) {
          this.editableStateOptions.push({
            id: s.state,
            itemName: s.stringKey
          });
        }
      });
    if (this.model.adminEditableStates.length === 0) {
      this.model.adminEditableStates.push(...this.editableStateOptions.filter(s => s.id === 'NEW' || s.id === 'OPEN'
        || s.id === 'SUBMITTED' || s.id === 'PAUSED'));
    }
  }

  loadMessageTemplates(q?: string) {
    if (this.rightModel.messageTemplateRead.hasRight()) {
      this.messageTemplateService.query({
        name: Strings.undefinedOrNonEmpty(q),
        disabled: false,
        orders: Set.of({field: MessageTemplate.OrderField.NAME, type: OrderType.ASC}),
        paging: {
          pageNumber: 1,
          numberOfItems: UiConstants.autocompletePageSize
        },
        noProgressBar: true
      }).subscribe(result => {
        this.messageTemplates = result.items.toArray().map(t => ({id: t.id, itemName: t.name, itemSubtitle: t.externalId}))
      });
    }
  }

  pictureUploadChanged() {
    this.model.minNumOfPics = '';
    this.model.maxNumOfPics = '';
    this.model.showPicturesOnForm = false;
    this.model.photoConfirmDialogEnabled = false;
    this.removeFieldError(Task.ValidatedField.MIN_NUM_OF_PICS);
    if (!this.model.pictureUpload) {
      this.model.photoEnabledInStates = droidStates.filter(s => s.id === 'IN_PROGRESS');
    } else {
      this.model.photoEnabledInStates = [];
    }
  }

  hasDefaultDeadlineChanged() {
    this.model.hasDefaultDeadline = !this.model.hasDefaultDeadline;
    if (this.formGroup) {
      if (this.model.hasDefaultDeadline) {
        this.formGroup.get('offset')!.setValidators([Validators.required, Validators.min(0)]);
        this.formGroup.get('offset')!.updateValueAndValidity();
      }
      else {
        this.formGroup.get('offset')!.setErrors(null);
        this.formGroup.get('offset')!.setValidators(null);
        this.formGroup.get('offset')!.updateValueAndValidity();
      }
    }
  }

  defaultStateForceNewChanged() {
    this.model.defaultStateForceNew = !this.model.defaultStateForceNew;
    if (this.formGroup) {
      if (this.model.defaultStateForceNew) {
        this.formGroup.get('defaultState')!.setValidators([Validators.required]);
        this.formGroup.get('defaultState')!.updateValueAndValidity();
      }
      else {
        this.formGroup.get('defaultState')!.setErrors(null);
        this.formGroup.get('defaultState')!.setValidators(null);
        this.formGroup.get('defaultState')!.updateValueAndValidity();
      }
    }
  }

  timeTrackingEnabledChanged() {
    this.model.defaultEstimatedTimeInMinutes = '';
    this.model.collaboratorTimeTrackingEnabled = false;
  }

  geofencingEnabledChanged() {
    this.model.geofencingRadiusInMeters = this.configService.getConfiguration().feature_flags.geofencing_default_radius_in_meters;
  }

  mileageRecordEnabledChanged() {
    this.model.mileageRecordRequired = false;
  }

  onDefaultDeadlineTypeChange(value) {
    this.model.defaultDeadlineType = value;
  }

  hasLocalFieldError(formControlName?: string, errorCode?: string): boolean {
    return this.formGroupValidationErrors.hasFieldError(formControlName, errorCode);
  }

  hasLocalFormError(errorCode: string): boolean {
    return this.formGroupValidationErrors.hasFormError(errorCode);
  }

  hasFieldError(field: Task.ValidatedField): boolean {
    return this.fieldErrors.hasError(field);
  }

  removeFieldError(field: Task.ValidatedField) {
    this.fieldErrors = this.fieldErrors.removeError(field);
  }

  getFieldErrorText(field: Task.ValidatedField): string {
    return this.fieldErrors.getErrorText(field);
  }

  back() {
    window.history.back();
  }

  onInvoiceGenerationTypeChanged() {
    if (this.model.taskInvoiceGenerationType !== TaskInvoiceGenerationType.AUTOMATIC) {
      this.model._validInvoicePaymentTypes = [];
      this.model._generatingInvoicePaymentTypes = [];
      this.model.invoiceSettings = [];
      this.model.invoiceBook = [];
      this.model.printInvoiceCount = '';
    }
  }

  onInvoiceSettingsChanged() {
    this.loadInvoiceBooks();
    this.model.invoiceBook = [];
    this.formGroup.controls['invoiceBookInput'].updateValueAndValidity();
  }

  private createFormGroup(fb: FormBuilder): FormGroup {
    return fb.group(
      {
        name: fb.control(
          this.model.name,
          [
            Validators.required,
          ]
        ),
        externalIdTagInput: fb.control(
          this.model.taskRecordExternalIdSequence,
          []
        ),
        offset: fb.control(
          this.model.offset,
          []
        ),
        validInvoicePaymentTypes: fb.control(
          this.model._validInvoicePaymentTypes,
          [
            AppValidators.tempValidator({
                validator: AppValidators.validatorChain(
                  Validators.required
                ),
                disabled: () => {
                  return this.model.taskInvoiceGenerationType !== TaskInvoiceGenerationType.AUTOMATIC;
                }
              }
            )]
        ),
        generatingInvoicePaymentTypes: fb.control(
          this.model._generatingInvoicePaymentTypes,
          [
            AppValidators.tempValidator({
                validator: AppValidators.validatorChain(
                  Validators.required
                ),
                disabled: () => {
                  return this.model.taskInvoiceGenerationType !== TaskInvoiceGenerationType.AUTOMATIC
                    || this.model._validInvoicePaymentTypes.length === 0;
                }
              }
            )]
        ),
        invoiceSettingsInput: fb.control(
          this.model.invoiceSettings,
          AppValidators.tempValidator({
              validator: AppValidators.validatorChain(
                Validators.required
              ),
              disabled: () => {
                return this.model.taskInvoiceGenerationType !== TaskInvoiceGenerationType.AUTOMATIC;
              }
            }
          )
        ),
        invoiceBookInput: fb.control(
          this.model.invoiceBook,
          AppValidators.tempValidator({
              validator: AppValidators.validatorChain(
                Validators.required
              ),
              disabled: () => {
                return this.model.invoiceSettings.length === 0
                  || this.model.invoiceSettings[0].integrationType !== InvoiceIntegrationType.APPWORKS;
              }
            }
          )
        ),
        invoiceTagInput: fb.control(
          this.model.invoiceTag,
          []
        ),
        printInvoiceCount: fb.control(
          this.model.printInvoiceCount,
          [
            AppValidators.tempValidator({
                validator: AppValidators.validatorChain(
                  Validators.required,
                  Validators.max(5),
                  Validators.min(0)
                ),
                disabled: () => {
                  return this.model.taskInvoiceGenerationType !== TaskInvoiceGenerationType.AUTOMATIC;
                }
              }
            )]
        ),
        explicitOrderNumber: fb.control(
          this.model.explicitOrderNumber,
          [Validators.min(1)]
        ),
        taskRecordNameTemplate: fb.control(
          this.model.taskRecordNameTemplate,
          []
        ),
        defaultState: fb.control(
          this.model.defaultState,
          [Validators.required]
        ),
        validationType: fb.control(
          this.model.validationType,
          [Validators.required]
        ),
        adminEditableStates: fb.control(
          this.model.adminEditableStates,
          []
        ),
        defaultEstimatedTime: fb.control(
          this.model.defaultEstimatedTimeInMinutes,
          [
            AppValidators.tempValidator({
              validator: AppValidators.validatorChain(
                Validators.required,
                Validators.min(1)
              ),
              disabled: () => {
                return this.model.taskTimeTrackingType === TaskTimeTrackingType.NONE;
              }
            })
          ]
        ),
        geofencingRadiusInMeters: fb.control(
          this.model.geofencingRadiusInMeters,
          [
            AppValidators.tempValidator({
              validator: AppValidators.validatorChain(
                Validators.required,
                Validators.min(100),
                Validators.max(5000),
              ),
              disabled: () => {
                return !this.model.geofencingEnabled;
              }
            })
          ]
        ),
        pocRequirement: fb.control(
          this.model.pocRequirement,
          [Validators.required]
        ),
        webCreateDefaultAssigneeType: fb.control(
          this.model.webCreateDefaultAssigneeType,
          [Validators.required]
        ),
        smsRatingDeliveryScheduleMin: fb.control(
          this.model.smsRatingDeliveryScheduleMin,
          [
            AppValidators.tempValidator({
              validator: Validators.required,
              disabled: () => {
                return !this.model.smsRatingEnabled;
              }
            })
          ]
        ),
        smsRatingTemplate: fb.control(
          this.model.smsRatingTemplate,
          [
            AppValidators.tempValidator({
              validator: Validators.required,
              disabled: () => {
                return !this.model.smsRatingEnabled;
              }
            })
          ]
        ),
        customerSignature: fb.control(
          this.model._customerSignature,
          [Validators.required]
        ),
        userSignature: fb.control(
          this.model._userSignature,
          [Validators.required]
        ),
        photoEnabledInStates: fb.control(
          this.model.photoEnabledInStates,
          AppValidators.tempValidator({
              validator: AppValidators.validatorChain(
                Validators.required
              ),
              disabled: () => {
                return !this.model.pictureUpload;
              }
            }
          )
        ),
      },
      {
        validator: AppValidators.noOpValidator()
      }
    );
  }

  private createForwardingHtmlForm() {
    return new ForwardingNgFormRef({
      formFn: () => {
        return this.fForm;
      }
    });
  }

  searchSurveys(q?: any) {
    this.surveyService.query({
        name: q ? Strings.undefinedOrNonEmpty(q.target.value) : undefined,
        disabled: false,
        paging: {
          pageNumber: 1,
          numberOfItems: 30
        },
        orders: Set.of({field: Survey.OrderField.NAME, type: OrderType.ASC}),
        noProgressBar: true
      }
    ).subscribe((surveys: QueryResult<Survey.Survey>) => {
      this.surveys = [];
      surveys.items.forEach((s) => {
        this.surveys.push({
          id: s!.id,
          itemName: s!.name
        });
      });
    });
  }

  searchUserGroups(q?: any) {
    this.userGroupService.query({
        name: q ? Strings.undefinedOrNonEmpty(q.target.value) : undefined,
        disabled: false,
        page_number: 1,
        number_of_items: 30,
        order: Services.createOrderFieldParameter(UserGroup.Keys.toOrderFieldKey, Set.of(UserGroup.DEFAULT_ORDER)),
        no_progress_bar: true
      }
    ).subscribe((ugs: ResourceQueryResult<UserGroup>) => {
      this.userGroups = [];
      ugs.items.forEach((ug) => {
        this.userGroups.push({
          id: ug.id,
          itemName: ug.name
        });
      });
    });
  }

  addSubmittedFilter() {
    this.model.submittedFilters.push(new SubmittedFilterModel());
    this.formGroup.addControl(
      'submittedFilterUserGroup' + (this.model.submittedFilters.length - 1),
      this.fb.control(
        [],
        [
          Validators.required,
        ]
      ));
    this.formGroup.addControl(
      'submittedFilterDays' + (this.model.submittedFilters.length - 1),
      this.fb.control(
        '',
        [
          Validators.required,
        ]
      ));
  }

  deleteSubmittedFilter(i: number) {
    this.formGroup.removeControl('submittedFilterUserGroup' + i);
    this.formGroup.removeControl('submittedFilterDays' + i);
    this.model.submittedFilters.splice(i, 1);
  }

  addStateFilter() {
    this.model.stateFilters.push(new StateFilterModel());
    this.formGroup.addControl(
      'stateFilterUserGroup' + (this.model.stateFilters.length - 1),
      this.fb.control(
        [],
        [
          Validators.required,
        ]
      ));
    this.formGroup.addControl(
      'stateFilterStates' + (this.model.stateFilters.length - 1),
      this.fb.control(
        '',
        [
          Validators.required,
        ]
      ));
  }

  deleteStateFilter(i: number) {
    this.formGroup.removeControl('stateFilterUserGroup' + i);
    this.formGroup.removeControl('stateFilterStates' + i);
    this.model.stateFilters.splice(i, 1);
  }

  onManagedFieldsChanged() {
    this.model.requiredFields = this.model.requiredFields.filter(f => this.model.managedFields.map(f => f.id).includes(f.id));
  }

  onHelpdeskManagedFieldsChanged() {
    this.model.helpdeskRequiredFields
      = this.model.helpdeskRequiredFields.filter(f => this.model.helpdeskManagedFields.map(f => f.id).includes(f.id));
  }

  private getManualTimeTrackingRequired() {
    let manualTimeTrackingRequired: boolean | undefined;
    if (this.model.taskTimeTrackingType === TaskTimeTrackingType.MANUAL) {
      manualTimeTrackingRequired = this.model.manualTimeTrackingRequired;
    }
    return manualTimeTrackingRequired;
  }

  private getManualTimeTrackingType() {
    let manualTimeTrackingType: TaskResource.TaskManualTimeTrackingType | undefined;
    if (this.model.taskTimeTrackingType === TaskTimeTrackingType.MANUAL) {
      manualTimeTrackingType = this.model.manualTimeTrackingType;
    }
    return manualTimeTrackingType;
  }
}
