<div>

  <div class="d-flex justify-content-between">
    <div>
      <img [src]="sanitizeUrl(model.iconUrl)"/> {{model.log.transport.vehicleLicencePlate}}
    </div>
    <div class="delay" *ngIf="isLate()"><i class="icomoon icomoon-transport-delay"></i>
      {{model.log.transport.delayMinutes | formattedDuration}}
    </div>
  </div>

  <div class="d-flex justify-content-between">
    <div>{{'TRANSPORT_DRIVER' | translate}}:</div>
    <div>{{model.log.driver.personName}}</div>
  </div>

  <div class="d-flex justify-content-between">
    <div>{{'TRANSPORT_WAYBILL_NUMBER' | translate}}:</div>
    <a href="#" onclick="return false" (click)="transportOpen()">{{model.log.transport.waybillNumber}}</a>
  </div>

  <div *ngIf="model.log.toTask">
    <div class="d-flex justify-content-between">
      <div>{{'TRANSPORT_NEXT_PLACE' | translate}}:</div>
      <div>{{model.log.toTask.shippingPlace.name}}</div>
    </div>
    <div class="d-flex flex-row-reverse">
      <div>{{model.log.toTask.shippingPlace.postalAddress.complexValue.zipCode}}
        {{model.log.toTask.shippingPlace.postalAddress.complexValue.city}},
      </div>
    </div>
    <div class="d-flex flex-row-reverse">
      <div>{{model.log.toTask.shippingPlace.postalAddress.complexValue.address}}
        {{model.log.toTask.shippingPlace.postalAddress.complexValue.addressType}}
        {{model.log.toTask.shippingPlace.postalAddress.complexValue.houseNumber}}
      </div>
    </div>
  </div>

  <div *ngIf="model.log.currentTask">
    <div class="d-flex justify-content-between">
      <div>{{'TRANSPORT_CURRENT_PLACE' | translate}}:</div>
      <div>{{model.log.currentTask.shippingPlace.name}}</div>
    </div>
    <div class="d-flex flex-row-reverse">
      <div>{{model.log.currentTask.shippingPlace.postalAddress.complexValue.zipCode}}
        {{model.log.currentTask.shippingPlace.postalAddress.complexValue.city}},
      </div>
    </div>
    <div class="d-flex flex-row-reverse">
      <div>{{model.log.currentTask.shippingPlace.postalAddress.complexValue.address}}
        {{model.log.currentTask.shippingPlace.postalAddress.complexValue.addressType}}
        {{model.log.currentTask.shippingPlace.postalAddress.complexValue.houseNumber}}
      </div>
    </div>
  </div>

  <div class="d-flex flex-row">
    <div>{{model.log.positionLog.creationTime.toUtcIsoString() | date:'short'}}</div>
  </div>

</div>
