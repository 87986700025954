<div class="breadcrumb-container" [ngClass]="{ 'breadcrumb-container-compact': compactSidebar }" id="breadcrumb">
  <div class="breadcrumb-component">
    <app-breadcrumb
      [parents]=breadcrumbParents
      [self]="breadcrumbSelf">
    </app-breadcrumb>
  </div>
  <div class="breadcrumb-menu d-flex">
    <div *ngIf="rightModel.invoiceStorno.hasRight() && invoice && invoice.categoryType !== 'STORNO' && !invoice.reverted"
         class="breadcrumb-button-icon-container cursor-pointer"
         (click)="openStornoDialog()">
      <a class="btn-setting d-flex align-items-center" title="{{'INVOICE_STORNO' | translate}}">
        <i class="icomoon icomoon-invoice-storno-alt"></i>
        <span class="breadcrumb-button-text-visible">{{'INVOICE_STORNO' | translate}}</span>
      </a>
    </div>
    <div *ngIf="rightModel.invoiceCreate.hasRight() && invoice && invoice.categoryType !== 'STORNO'"
         class="breadcrumb-button-icon-container cursor-pointer"
         (click)="cloneInvoice()">
      <a class="btn-setting d-flex align-items-center" title="{{'COMMON_BUTTON_CLONE' | translate}}">
        <i class="icomoon icomoon-clone"></i>
        <span class="breadcrumb-button-text-visible">{{'COMMON_BUTTON_CLONE' | translate}}</span>
      </a>
    </div>
    <div *ngIf="rightModel.invoiceAction.hasRight() && invoice && !invoice.settlementData!.settled"
         class="breadcrumb-button-icon-container cursor-pointer"
         (click)="openSettlementDialog()">
      <a class="btn-setting d-flex align-items-center" title="{{'INVOICE_SETTLE' | translate}}">
        <i class="icomoon icomoon-price"></i>
        <span class="breadcrumb-button-text-visible">{{'INVOICE_SETTLE' | translate}}</span>
      </a>
    </div>
    <div *ngIf="invoice && invoice.hasPdf"
         class="breadcrumb-button-icon-container cursor-pointer"
         (click)="downloadPdf()">
      <a class="btn-setting d-flex align-items-center" title="{{'COMMON_BUTTON_DOWNLOAD' | translate}}">
        <i class="icomoon icomoon-download"></i>
        <span class="breadcrumb-button-text-visible">{{'COMMON_BUTTON_DOWNLOAD' | translate}}</span>
      </a>
    </div>
    <div *ngIf="rightModel.invoiceAction.hasRight() && invoice && invoice.postProcessData && invoice.postProcessData.state === 'FAILED'"
         class="breadcrumb-button-icon-container cursor-pointer"
         (click)="retryPostProcess()">
      <a class="btn-setting d-flex align-items-center" title="{{'INVOICE_POST_PROCESS_RETRY' | translate}}">
        <i class="icomoon icomoon-restart"></i>
        <span class="breadcrumb-button-text-visible">{{'INVOICE_POST_PROCESS_RETRY' | translate}}</span>
      </a>
    </div>
  </div>
</div>
<div class="breadcrumb-placeholder"></div>

<div class="container-custom-padding-outer animated fadeIn col-md-12 col-sm-12 col-xs-12">
  <div class="row m-0">
    <!-- region left card -->
    <div class="col-md-6 col-sm-12 col-xs-12 card-custom-padding-complete">
      <div class="card">
        <div class="card-header">
          <div class="row align-items-center">
            <span class="ml-1 mr-05" *ngIf="invoice">
          {{invoice.invoiceNumber}}
              </span>
            <div *ngIf="invoice && rightModel.invoiceTagRead.hasRight() && rightModel.invoiceAction.hasRight()">
              <app-table-badge
                [badgeStyle]="invoice.invoiceTag.disabled ? BadgeStyle.LIGHT_GRAY : BadgeStyle.SUCCESS"
                [iconClass]="'icomoon-order-import-error-rejected'"
                [breakWord]="true"
                (iconClick)="openInvoiceTagDialog('CLEAR')"
                [fontSize]="'small'"
                [text]="invoice.invoiceTag.invoiceTag"
                *ngIf="invoice.invoiceTag">
              </app-table-badge>
              <app-table-badge
                [badgeStyle]="BadgeStyle.PRIMARY"
                [iconClass]="'icomoon-add'"
                [breakWord]="true"
                [textKey]="'INVOICE_ADD_TAG'"
                [fontSize]="'small'"
                (click)="openInvoiceTagDialog('SET')"
                *ngIf="!invoice.invoiceTag">
              </app-table-badge>
            </div>
          </div>
        </div>
        <div class="card-body">
          <!-- region InvoiceNumber -->
          <div class="form-group row">
            <label class="col-4 col-form-label">{{'INVOICE_DETAIL_INVOICE_NUMBER' | translate}}</label>
            <label class="col-8 col-form-label detail-description"
                   *ngIf="invoice && invoice.invoiceNumber">{{invoice.invoiceNumber}}</label>
            <label class="col-8 col-form-label detail-description"
                   *ngIf="!(invoice && invoice.invoiceNumber)">{{'INVOICE_DETAIL_NOT_FINALIZED'
              | translate}}</label>
          </div>
          <!-- endregion InvoiceNumber -->

          <!-- region InvoiceSettings -->
          <div class="form-group row" *ngIf="invoice">
            <label class="col-4 col-form-label">{{'INVOICE_CREATE_INVOICE_SETTINGS' | translate}}</label>
            <label class="col-8 col-form-label detail-description">
              {{invoice.invoiceSettings.profileName}}</label>
          </div>
          <!-- endregion InvoiceSettings -->

          <!-- region InvoiceAppearanceType -->
          <div class="form-group row" *ngIf="invoice">
            <label class="col-4 col-form-label">{{'INVOICE_CREATE_APPEARANCE_TYPE' | translate}}</label>
            <label class="col-8 col-form-label detail-description">
              {{getInvoiceAppearanceTypeName(invoice.appearanceType) | translate}}
            </label>
          </div>
          <!-- endregion InvoiceAppearanceType -->

          <!-- region InvoiceCategoryType -->
          <div class="form-group row" *ngIf="invoice">
            <label class="col-4 col-form-label">{{'INVOICE_DETAIL_INVOICE_TYPE' | translate}}</label>
            <div class="col-8">
              <i class="icomoon {{getInvoiceIconClass(invoice.categoryType)}}"></i>
              <label
                class="col-form-label detail-description">{{getInvoiceTypeName(invoice.categoryType) | translate}}</label>
            </div>
          </div>
          <!-- endregion InvoiceCategoryType -->

          <!-- region ReferencedInvoiceNumber -->
          <div class="form-group row" *ngIf="invoice && invoice.referencedInvoiceNumber">
            <label class="col-4 col-form-label">{{'INVOICE_DETAIL_REF_INVOICE_NUMBER' | translate}}</label>
            <label class="col-8 col-form-label detail-description"
                   *ngIf="invoice.referencedInvoiceNumber">{{invoice.referencedInvoiceNumber}}</label>
          </div>
          <!-- endregion ReferencedInvoiceNumber -->

          <!-- region issueDate -->
          <div class="form-group row" *ngIf="invoice">
            <label class="col-4 col-form-label">{{'INVOICE_CREATE_ISSUE_DATE' | translate}}</label>
            <label
              class="col-8 col-form-label detail-description">{{invoice.issueDate.toIsoString() | date:'shortDate'}}</label>
          </div>
          <!-- endregion issueDate -->

          <!-- region deliveryDate -->
          <div class="form-group row" *ngIf="invoice">
            <label class="col-4 col-form-label">{{'INVOICE_CREATE_DELIVERY_DATE' | translate}}</label>
            <label class="col-8 col-form-label detail-description">{{invoice.deliveryDate.toIsoString() |
              date:'shortDate'}}</label>
          </div>
          <!-- endregion deliveryDate -->

          <!-- region deadline -->
          <div class="form-group row" *ngIf="invoice">
            <label class="col-4 col-form-label">{{'INVOICE_CREATE_DEADLINE' | translate}}</label>
            <label
              class="col-8 col-form-label detail-description">{{invoice.deadline.toIsoString() | date:'shortDate'}}</label>
          </div>
          <!--endregion deadline -->

          <!-- region paymentType -->
          <div class="form-group row" *ngIf="invoice">
            <label class="col-4 col-form-label">{{'INVOICE_CREATE_PAYMENT_TYPE' | translate}}</label>
            <label class="col-8 col-form-label detail-description">{{getPaymentTypeString()}}</label>
          </div>
          <!-- endregion paymentType -->

          <!-- region creatorUser -->
          <div class="form-group row" *ngIf="invoice && invoice.creatorUser">
            <label class="col-4 col-form-label">{{'INVOICE_CREATE_CREATOR_USER' | translate}}</label>
            <label class="col-8 col-form-label detail-description">{{invoice.creatorUser.personName}} ({{invoice.creatorUser.userName}})</label>
          </div>
          <!-- endregion creatorUser -->

          <!-- region taskRecordData -->
          <div class="form-group row" *ngIf="invoice && invoice.taskRecordData">
            <label class="col-4 col-form-label">{{'COMMON_RELATED_TASK_RECORD' | translate}}</label>
            <div class="col-md-8">
              <div class="mw-100">
                <angular2-multiselect
                  [(ngModel)]="taskRecordAsArray"
                  (onReadonlyItemClick)="navigateToTaskRecordDetail()"
                  [data]="taskRecordAsArray"
                  [settings]="singleDropdownSettings"
                  [readonly]="true">
                </angular2-multiselect>
              </div>
            </div>
          </div>
          <!-- endregion taskRecordData -->

          <!-- region comment-->
          <div class="form-group row" *ngIf="invoice && invoice.comment">
            <label class="col-4 col-form-label">{{'COMMON_COMMENT' | translate}}</label>
            <label class="col-8 col-form-label detail-description">{{invoice.comment}}</label>
          </div>

          <!-- region technical comment-->
          <div class="form-group row" *ngIf="invoice && invoice.technicalComment">
            <label class="col-4 col-form-label">{{'INVOICE_TECHNICAL_COMMENT' | translate}}</label>
            <label class="col-8 col-form-label detail-description">{{invoice.technicalComment}}</label>
          </div>
        </div>
      </div>
      <div class="container-vertical-padding-half"></div>
      <div class="card" *ngIf="invoice">
        <div class="card-header">
          {{'INVOICE_SETTLE' | translate}}
        </div>
        <div class="card-body">
          <div class="form-group row">
            <label class="col-4 col-form-label">{{'INVOICE_SEARCH_SETTLED' | translate}}</label>
            <app-animated-checkbox
              class="cursor-not-allowed"
              [readonly]="true"
              [selected]="invoice.settlementData!.settled">
            </app-animated-checkbox>
          </div>
          <div class="form-group row" *ngIf="invoice && invoice.settlementData!.date">
            <label class="col-4 col-form-label">{{'INVOICE_SETTLEMENT_DATE' | translate}}</label>
            <label
              class="col-8 col-form-label detail-description">{{invoice.settlementData!.date!.toIsoString() | date:'shortDate'}}</label>
          </div>
          <div class="form-group row" *ngIf="invoice && invoice.settlementData!.comment">
            <label class="col-4 col-form-label">{{'COMMON_COMMENT' | translate}}</label>
            <label class="col-8 col-form-label detail-description">{{invoice.settlementData!.comment}}</label>
          </div>
          <div class="form-group row" *ngIf="invoice && invoice.settlementData!.spending">
            <label class="col-4 col-form-label">{{'INVOICE_SPENDING_SPENDING_NUMBER' | translate}}</label>
            <label class="col-8 col-form-label detail-description">{{invoice.settlementData!.spending.spendingNumber}}</label>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6 col-sm-12 col-xs-12 card-custom-padding-complete">
      <div class="card">
        <div class="card-header" *ngIf="invoice && invoice.directionType === 'OUTBOUND'">
          {{'INVOICE_CREATE_CUSTOMER_OUTBOUND' | translate}}
        </div>
        <div class="card-header" *ngIf="invoice && invoice.directionType === 'INBOUND'">
          {{'INVOICE_CREATE_CUSTOMER_INBOUND' | translate}}
        </div>
        <div class="card-body" *ngIf="invoice && invoice.directionType === 'OUTBOUND'">
          <div class="form-group row">
            <label class="col-4 col-form-label">{{'INVOICE_CREATE_CUSTOMER_RECORD' | translate}}</label>
            <label class="col-8 col-form-label detail-description">{{invoice.customerName}}</label>
          </div>

          <div class="form-group row">
            <label class="col-4 col-form-label">
              {{'INVOICE_CREATE_INVOICE_ADDRESS' | translate}}
            </label>
            <div class="col-8">
              <div class="invoice-create-billing-address p-1">
                {{formatCustomerAddress(invoice.customerPostalAddress)}}
              </div>
            </div>
          </div>

          <div class="form-group row" *ngIf="invoice.customerTaxNumber">
            <label class="col-4 col-form-label">
              {{'INVOICE_CREATE_CUSTOMER_TAX_NUMBER' | translate}}
            </label>
            <div class="col-8">
              <div class="invoice-create-billing-address p-1">
                {{invoice.customerTaxNumber}}
              </div>
            </div>
          </div>

          <div class="form-group row" *ngIf="customerRecord">
            <label class="col-4 col-form-label">
              {{'INVOICE_CREATE_POSTAL_ADDRESS' | translate}}
            </label>
            <div class="col-8">
              <div class="invoice-create-billing-address p-1">
                {{formatCustomerAddress(customerRecord.postalAddress)}}
              </div>
            </div>
          </div>

          <div class="form-group row" *ngIf="customerRecord && customerRecord.emailAddresses.size > 0">
            <label class="col-4 col-form-label">
              {{'CUSTOMER_SEARCH_EMAIL_ADDRESS_LABEL' | translate}}
            </label>
            <div class="col-8">
              <div class="invoice-create-billing-address p-1">
                {{formatEmailAddresses(customerRecord.emailAddresses)}}
              </div>
            </div>
          </div>

        </div>
        <div class="card-body" *ngIf="invoice && invoice.directionType === 'INBOUND'">
          <div class="form-group row">
            <label class="col-4 col-form-label">{{'INVOICE_CREATE_CUSTOMER_RECORD' | translate}}</label>
            <label class="col-8 col-form-label detail-description">{{invoice.sellerName}}</label>
          </div>

          <div class="form-group row">
            <label class="col-4 col-form-label">
              {{'INVOICE_CREATE_INVOICE_ADDRESS' | translate}}
            </label>
            <div class="col-8">
              <div class="invoice-create-billing-address p-1">
                {{formatCustomerAddress(invoice.sellerPostalAddress)}}
              </div>
            </div>
          </div>

          <div class="form-group row" *ngIf="invoice.sellerTaxNumber">
            <label class="col-4 col-form-label">
              {{'INVOICE_CREATE_CUSTOMER_TAX_NUMBER' | translate}}
            </label>
            <div class="col-8">
              <div class="invoice-create-billing-address p-1">
                {{invoice.sellerTaxNumber}}
              </div>
            </div>
          </div>

          <div class="form-group row" *ngIf="customerRecord">
            <label class="col-4 col-form-label">
              {{'INVOICE_CREATE_POSTAL_ADDRESS' | translate}}
            </label>
            <div class="col-8">
              <div class="invoice-create-billing-address p-1">
                {{formatCustomerAddress(customerRecord.postalAddress)}}
              </div>
            </div>
          </div>

          <div class="form-group row" *ngIf="customerRecord && customerRecord.emailAddresses.size > 0">
            <label class="col-4 col-form-label">
              {{'CUSTOMER_SEARCH_EMAIL_ADDRESS_LABEL' | translate}}
            </label>
            <div class="col-8">
              <div class="invoice-create-billing-address p-1">
                {{formatEmailAddresses(customerRecord.emailAddresses)}}
              </div>
            </div>
          </div>

        </div>
      </div>
      <div class="container-vertical-padding-half"
           *ngIf="invoice && invoice.invoiceSettings.invoiceIntegrationType === InvoiceSettings.InvoiceIntegrationType.APPWORKS"></div>
      <div class="card"
           *ngIf="invoice && invoice.invoiceSettings.invoiceIntegrationType === InvoiceSettings.InvoiceIntegrationType.APPWORKS">
        <div class="card-header">
          {{'INVOICE_NAV_DATA' | translate}}
        </div>
        <div class="card-body">
          <div class="form-group row" *ngIf="invoice">
            <label class="col-4 col-form-label">{{'INVOICE_NAV_EXPORT_STATE' | translate}}</label>
            <label
              class="col-8 col-form-label detail-description">{{getNavExportStateName(invoice.navData.exportState) | translate}}</label>
          </div>
          <div class="form-group row" *ngIf="invoice && invoice.navData.requestId">
            <label class="col-4 col-form-label">{{'INVOICE_NAV_REQUEST_ID' | translate}}</label>
            <label class="col-8 col-form-label detail-description">{{invoice.navData.requestId}}</label>
          </div>
          <div class="form-group row" *ngIf="invoice && invoice.navData.technicalValidationMessages">
            <label class="col-4 col-form-label">{{'INVOICE_NAV_TECHNICAL_VALIDATION_MESSAGE' | translate}}</label>
            <label
              class="col-8 col-form-label detail-description">{{validationMessages(invoice.navData.technicalValidationMessages)}}</label>
          </div>
          <div class="form-group row" *ngIf="invoice && invoice.navData.businessValidationMessages">
            <label class="col-4 col-form-label">{{'INVOICE_NAV_BUSINESS_VALIDATION_MESSAGE' | translate}}</label>
            <label
              class="col-8 col-form-label detail-description">{{validationMessages(invoice.navData.businessValidationMessages)}}</label>
          </div>
        </div>
      </div>
      <div class="container-vertical-padding-half"
           *ngIf="invoice && invoice.postProcessData"></div>
      <div class="card"
           *ngIf="invoice && invoice.postProcessData">
        <div class="card-header">
          {{'INVOICE_POST_PROCESS_DATA' | translate}}
        </div>
        <div class="card-body">
          <div class="form-group row">
            <label class="col-4 col-form-label">{{'INVOICE_POST_PROCESS_STATE' | translate}}</label>
            <label
              class="col-8 col-form-label detail-description">{{getPostProcessStateName(invoice.postProcessData.state) | translate}}</label>
          </div>
          <div class="form-group row" *ngIf="invoice && invoice.postProcessData.time">
            <label class="col-4 col-form-label">{{'INVOICE_POST_PROCESS_TIME' | translate}}</label>
            <label
              class="col-8 col-form-label detail-description">{{invoice.postProcessData.time.toUtcIsoString() | date:'short'}}</label>
          </div>
          <div class="form-group row" *ngIf="invoice && invoice.postProcessData.info">
            <label class="col-4 col-form-label">
              <i class="icomoon cursor-pointer"
                 [ngClass]="{'icomoon-arrow-filled-down': postProcessInfoOpened, 'icomoon-arrow-filled-right': !postProcessInfoOpened}"
                 (click)="postProcessInfoOpened = !postProcessInfoOpened"></i>
              {{'INVOICE_POST_PROCESS_INFO' | translate}}
            </label>
            <label class="col-8 col-form-label detail-description pb-0" [class.line-clamp-2]="!postProcessInfoOpened">
              {{invoice.postProcessData.info}}
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12 card-custom-padding-complete">
    <div class="card">
      <div class="card-header">
        {{'INVOICE_RECORDS' | translate}}
      </div>
      <div class="card-body" *ngIf="invoice">

        <app-invoice-record-container
          [recordCreateModels]="records"
          [selectableVatRates]="[]"
          [selectableZeroVatRateTypes]="[]"
          [readonly]="true"
          [showSummary]="true"
          [rightModel]="rightModel"
          [summaryModel]="summaryModel"
          [paymentType]="invoice.paymentType"
          class="col-12">
        </app-invoice-record-container>
      </div>
    </div>
  </div>
</div>
