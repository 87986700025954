import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { UserRightModel } from '../../../../app.rights.user';
import { User, UserService, UserSignatureData, UserSignatureService, UserSignatureType } from '../../../../lib/user.service';
import { DownloadedFile } from '../../../../lib/util/downloaded-files';
import { saveAs } from 'file-saver';
import { MatDialog } from '@angular/material/dialog';
import {
  FileUploadMaterialDialogComponent,
  FileUploadMaterialDialogData
} from '../../../../shared/file-upload/material-dialog/file-upload-material-dialog.component';
import { UploadMimeType } from '../../../../shared/file-upload/upload-mime-type';
import { RightResolver, RightService } from '../../../../lib/right.service';
import { RightModel } from '../../../../app.rights';
import { UserDataLoader, UserDataLoaderPermissionDeniedStrategy } from '../../../../lib/user-data-loader';

@Component({
  selector: 'app-user-signature-list',
  templateUrl: './user-signature-list.component.html',
  styleUrls: ['./user-signature-list.component.scss']
})
export class UserSignatureListComponent implements OnInit, AfterViewInit {

  userRightModel: UserRightModel = UserRightModel.empty();

  @Input()
  readonly: boolean = false;

  @Input()
  userId: number;

  user: User;

  signatures: UserSignatureModel[] = [];

  constructor(private userSignatureService: UserSignatureService,
              private rightService: RightService,
              private userDataLoader: UserDataLoader,
              private userService: UserService,
              private dialog: MatDialog) {
  }

  ngOnInit(): void {
    this.loadRightModels(() => this.loadSignatures());
  }

  ngAfterViewInit() {
  }

  private loadRightModels(completion: () => void) {
    this.rightService.getRightResolver().subscribe(
      (resolver: RightResolver) => {
        this.userDataLoader.load(resolver.userProfile!, UserDataLoaderPermissionDeniedStrategy.MISS_ALL).subscribe(userData => {
          this.userRightModel = UserRightModel.of({
            rightModel: RightModel.of(resolver),
            currentUser: userData
          });
          completion();
        });
      }
    );
  }

  loadSignatures() {
    this.userService.get({id: this.userId}).subscribe(u => {
      this.user = u;
      this.signatures = [];
      Object.keys(UserSignatureType).forEach((key, index) => {
        this.signatures.push(new UserSignatureModel(<UserSignatureType>key));
      });
      if (this.user && this.userRightModel.userSignatureRead.hasRightForUser(this.user)) {
        this.userSignatureService.querySignatures(this.user.id).subscribe(result => {
          result.forEach(d => {
            this.signatures.find(s => s.signatureType === d.signature_type)!.data = d;
          })
        });
      }
    });
  }

  deleteSignature(type: UserSignatureType) {
    this.userSignatureService.deleteSignature(this.user.id, type).subscribe(result => {
      this.loadSignatures();
    });
  }

  downloadSignature(type: UserSignatureType) {
    this.userSignatureService.downloadSignature(this.user.id, type).subscribe((res: DownloadedFile) => {
      saveAs(res.getBlob(), res.getFileName(`user_signature-${this.user.id}-${type}.png`));
    });
  }

  uploadOfficialSignature() {
    const data = new FileUploadMaterialDialogData();
    data.acceptedMimeType = UploadMimeType.IMAGE;
    data.uploadPath = `/user-signature-image/${this.user.id}/official`;
    data.titleKey = 'COMMON_BUTTON_UPLOAD';
    FileUploadMaterialDialogComponent.openDialog(this.dialog, data, (res: boolean) => {
      if (res) {
        this.loadSignatures()
      }
    });
  }

  getTypeKey(signatureType: UserSignatureType) {
    switch (signatureType) {
      case UserSignatureType.APPWORKS:
        return 'USER_SIGNATURE_TYPE_APPWORKS';
      case UserSignatureType.OFFICIAL:
        return 'USER_SIGNATURE_TYPE_OFFICIAL';
      default:
        return '';
    }
  }
}

class UserSignatureModel {
  private readonly _signatureType: UserSignatureType;
  data?: UserSignatureData;

  constructor(type: UserSignatureType, data?: UserSignatureData) {
    this._signatureType = type;
    this.data = data;
  }

  get signatureType(): UserSignatureType {
    return this._signatureType;
  }

  get isUploaded(): boolean {
    return !!this.data;
  }
}
