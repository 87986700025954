export namespace MasterDataFilterType {

  export type MasterDataFilterType =
    'GLOBAL' |
    'CUSTOMER' |
    'LOCATION';

  export class MasterDataFilterTypeObject {
    type: MasterDataFilterType;
    stringKey: string;
  }

  export const masterDataFilterTypes: MasterDataFilterTypeObject[] = [
    {type: 'LOCATION', stringKey: 'MASTER_DATA_FILTER_TYPE_LOCATION'},
    {type: 'CUSTOMER', stringKey: 'MASTER_DATA_FILTER_TYPE_CUSTOMER'},
    {type: 'GLOBAL', stringKey: 'MASTER_DATA_FILTER_TYPE_GLOBAL'},
  ];

}
