import { DqlQuery, Query } from '../field';

export class Worklog {

  readonly id: Query.OrderField;
  readonly loggedTimeInMinutes: Query.OrderField;
  readonly estimatedTimeInMinutes: Query.OrderField;
  readonly startTime: Query.OrderField;
  readonly endTime: Query.OrderField;

  constructor(prefix?: string) {
    prefix = prefix ? prefix : '';
    this.id = new DqlQuery.OrderField(prefix + 'id');
    this.loggedTimeInMinutes = new DqlQuery.OrderField(prefix + 'logged_time_in_minutes');
    this.estimatedTimeInMinutes = new DqlQuery.OrderField(prefix + 'estimated_time_in_minutes');
    this.startTime = new DqlQuery.OrderField(prefix + 'start_time');
    this.endTime = new DqlQuery.OrderField(prefix + 'end_time');
  }

}
