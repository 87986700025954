import { Component, OnInit, ViewChild } from '@angular/core';
import { BreadcrumbParent } from '../../shared/breadcrumb/breadcrumb/breadcrumb.component';
import { StateName } from '../../app.state-names';
import { TranslateService } from '@ngx-translate/core';
import { AndroidAppVersionSettingsComponent } from './android-app-version-settings/android-app-version-settings.component';

@Component({
  selector: 'app-android-app-version',
  templateUrl: './android-app-version.component.html',
  styleUrls: ['./android-app-version.component.scss']
})
export class AndroidAppVersionComponent implements OnInit {

  // Variables used for breadcrumb
  breadcrumbParents: BreadcrumbParent[] = [];
  breadcrumbSelf: string;
  compactSidebar: boolean = document.querySelector('body')!.classList.contains('sidebar-compact');
  @ViewChild('settingsComponent', { static: true })
  settingsComponent: AndroidAppVersionSettingsComponent;

  constructor(private translateService: TranslateService) {
  }

  ngOnInit(): void {
    this.initBreadcrumb();
  }

  initBreadcrumb() {
    this.translateService.get('MENU_NAVBAR_MENU_ADMINISTRATION').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.ADMIN_DASHBOARD});
      }
    );
    this.translateService.get('ANDROID_VERSION_SETTINGS').subscribe(
      (result: string) => {
        this.breadcrumbSelf = result;
      }
    );
  }


  onVersionChanged(version: { id: number, versionName: string }) {
    this.settingsComponent.onVersionChanged(version);
  }

  onVersionDeleted(id: number) {
    this.settingsComponent.onVersionDeleted(id);
  }
  }
