/* eslint-disable */
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { SettingsService } from './lib/settings.service';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../environments/environment';
import { RuntimeConfiguration } from './lib/runtime-configuration';
import * as MomentTz from 'moment-timezone';
import * as Moment from 'moment';
import { GoogleMapsLoaderUtil } from './util/google/google-maps-loader.util';
import { ConfigurationService } from './lib/core-ext/configuration.service';
import {StringKey} from "./app.string-keys";
import { ResourceHelper } from './lib/util/http-services';
/* eslint-enable */

/* eslint-disable */
@Component({
  selector: 'body',
  templateUrl: './app.component.html',
  encapsulation: ViewEncapsulation.None
})
/* eslint-enable */
export class AppComponent implements OnInit {

  private _santa = false;

  get santa() {
    return this._santa;
  }

  constructor(
    private translate: TranslateService,
    private settings: SettingsService,
    private configuration: ConfigurationService,
    private resourceHelper: ResourceHelper,
    private title: Title) {
  }

  ngOnInit(): void {
    this.initTranslateService();
    this.updateTitle();
    this.updateFavicon();
    this.initMaps();
    this.subscribeServerConfig();
  }

  private initTranslateService() {
    // this language will be used as a fallback when a translation isn't found in the current language
    this.translate.setDefaultLang(this.settings.getDefaultLocaleCode());

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    this.translate.use(this.settings.getLocaleCode());

    // moment.js global language
    MomentTz.locale(this.settings.getLanguageCode());
    Moment.locale(this.settings.getLanguageCode());
  }

  private updateTitle() {
    this.translate.get(StringKey.INDEX_HTML_TITLE, {}).subscribe((text: string) => {
      this.title.setTitle(text);
    });
  }

  private updateFavicon() {
    const src = this.resourceHelper.getFaviconPngSrc();
    if (!src || src.length === 0) {
      return;
    }
    const links = document.getElementsByTagName('link');
    for (let i = 0; i < links.length; i++) {
      const link = links[i];
      const rel = link.getAttribute('rel');
      if (rel === 'shortcut icon') {
        link.setAttribute('href', src);
        break;
      }
    }
  }

  initMaps() {
    const apiKey = RuntimeConfiguration.getInstance().getGoogleMapsApiKey(environment.googleMapsApiKey);
    GoogleMapsLoaderUtil.loadMap(apiKey);
  }

  subscribeServerConfig() {
    this.configuration.configuration.subscribe((cfg) => {
      this._santa = !!cfg.santa;
    });
  }

}
