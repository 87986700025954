import { RightModel } from '../../../../app.rights';
import { GrantedPermissionSet } from '../../../../lib/right.service';
import { PermissionDefinition, PermissionDefinitions } from '../../../../app.permission-definitions';
import { FileAttachment } from '../../../../lib/util/file-attachments';
import { UserData } from '../../../../lib/user-data-loader';

export interface MasterDataRecordAttachmentRightModelContext {
  rightModel: RightModel;
  currentUser: UserData;
}

/**
 * Extension for user rights.
 * @see UserGrantedPermissionSet
 */
export class MasterDataRecordAttachmentRightModel {

  public readonly masterDataRecordAttachmentDelete: MasterDataRecordAttachmentGrantedPermissionSet;

  static empty() {
    return new MasterDataRecordAttachmentRightModel();
  }

  static of(context: MasterDataRecordAttachmentRightModelContext) {
    return new MasterDataRecordAttachmentRightModel(context);
  }

  private static map<T>(
    presentFactory: (
      context: MasterDataRecordAttachmentRightModelContext) => T,
      absentFactory: () => T,
      context?: MasterDataRecordAttachmentRightModelContext): T {
    if (context) {
      return presentFactory(context);
    }
    else {
      return absentFactory();
    }
  }

  private constructor(context?: MasterDataRecordAttachmentRightModelContext) {
    this.masterDataRecordAttachmentDelete = MasterDataRecordAttachmentRightModel.map(
      (c: MasterDataRecordAttachmentRightModelContext) =>
        MasterDataRecordAttachmentDeleteGrantedPermissionSet.of(c.rightModel, c.currentUser),
      () => MasterDataRecordAttachmentDeleteGrantedPermissionSet.empty(),
      context);
  }

}

/**
 * Extension for masterDataRecordAttachment rights.
 * Method hasRight() check the right globally, that's why it
 * implements hasRightForAttachment(FileAttachment) so we can decide whether we have the requested right for the requested user.
 * If the editing rights (like disable, update) require read right, usage of hasRight() is appropriate,
 * because the server filters the resources based on the read right.
 * Anyway decide user related rights are easy because we know the current user and it is the only extra dependency,
 * so we keep this extension.
 */
class MasterDataRecordAttachmentGrantedPermissionSet implements GrantedPermissionSet {

  constructor(private delegate: GrantedPermissionSet,
              private user?: UserData,
              private allPermission?: string,
              private minePermission?: string) {
  }

  public hasRight(): boolean {
    return this.delegate.hasRight();
  }

  contains(permission: PermissionDefinition): boolean {
    return this.delegate.contains(permission);
  }

  public hasRightForAttachment(attachment: FileAttachment): boolean {
    if (this.hasAllPermission()) {
      return true;
    }
    return this.hasMinePermission(attachment);
  }

  private hasAllPermission(): boolean {
    if (!this.allPermission) {
      return false;
    }
    return this.delegate.contains(this.allPermission);
  }

  private hasMinePermission(attachment: FileAttachment): boolean {
    if (!this.user) {
      return false;
    }
    if (!this.minePermission) {
      return false;
    }
    if (attachment.ownerUser && attachment.ownerUser.id === this.user.id) {
      return this.delegate.contains(this.minePermission);
    }
    return false;
  }

}

export class MasterDataRecordAttachmentDeleteGrantedPermissionSet extends MasterDataRecordAttachmentGrantedPermissionSet {

  static empty(): MasterDataRecordAttachmentGrantedPermissionSet {
    return new MasterDataRecordAttachmentGrantedPermissionSet(GrantedPermissionSet.empty());
  }

  static of(rightModel: RightModel, user?: UserData): MasterDataRecordAttachmentGrantedPermissionSet {
    return new MasterDataRecordAttachmentGrantedPermissionSet(
      rightModel.masterDataRecordAttachmentDelete,
      user,
      PermissionDefinitions.MASTER_DATA_RECORD_ATTACHMENT_DELETE_ALL,
      PermissionDefinitions.MASTER_DATA_RECORD_ATTACHMENT_DELETE_MINE);
  }

}
