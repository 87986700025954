<table class="table table-striped table-bordered mb-0">
  <thead>
  <tr>
    <th class="table-sorter-header w-checkbox">
    </th>
    <th class="table-sorter-header">
      <app-table-sorter-no-op [text]="'SHIPMENT_LIST_DELIVERY_NOTE_NUMBER' | translate"></app-table-sorter-no-op>
    </th>
    <th class="table-sorter-header hidden-lg-down">
      <app-table-sorter-no-op [text]="'SHIPMENT_LIST_WAYBILL_NUMBER' | translate"></app-table-sorter-no-op>
    </th>
    <th class="table-sorter-header hidden-lg-down">
      <app-table-sorter-no-op [text]="'SHIPMENT_LIST_SOURCE_ADDRESS' | translate"></app-table-sorter-no-op>
    </th>
    <th class="table-sorter-header hidden-lg-down">
      <app-table-sorter-no-op [text]="'SHIPMENT_LIST_DESTINATION_ADDRESS' | translate"></app-table-sorter-no-op>
    </th>
    <th class="table-sorter-header">
      <app-table-sorter-no-op [text]="'SHIPMENT_LIST_STATE' | translate"></app-table-sorter-no-op>
    </th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let shipment of shipments">
    <td>
      <app-animated-checkbox
        [(selected)]="shipment.selected">
      </app-animated-checkbox>
    </td>
    <td class="responsive-table-column">
      {{shipment.deliveryNoteNumber}}
    </td>
    <td class="responsive-table-column hidden-lg-down">{{shipment.waybillNumber}}</td>
    <td class="responsive-table-column hidden-lg-down long">{{shipment.sourceAddress}}</td>
    <td class="responsive-table-column hidden-lg-down long">{{shipment.destinationAddress}}</td>
    <td class="fit text-center align-middle">
      <app-table-badge
        [badgeStyle]="shipment.stateObject.badgeStyle"
        [iconClass]="shipment.stateObject.iconClass"
        [text]="shipment.state">
      </app-table-badge>
    </td>
  </tr>
  </tbody>
</table>
