/* eslint-disable */
import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostListener,
  Input,
  NgModule,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { ControlValueAccessor, FormControl, FormsModule, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validator } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MyException } from './multiselect.model';
import { DropdownSettings } from './multiselect.interface';
import { ClickOutsideDirective, ScrollDirective, setPosition, styleDirective } from './clickOutside';
import { ListFilterPipe } from './list-filter';
import { Badge, CIcon, Item, Search, TemplateRenderer } from './menu-item';
import { DataService } from './multiselect.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import { ChangeEvent, VirtualScrollComponent } from './virtual-scroll';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { debounceTime } from 'rxjs/operators';
import { UiConstants } from '../../../../util/core-utils';
import { Angular2Multiselects } from '../../../../util/multiselect';

export const DROPDOWN_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => AngularMultiSelect),
  multi: true
};
export const DROPDOWN_CONTROL_VALIDATION: any = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => AngularMultiSelect),
  multi: true,
}
const noop = () => {
};

@Component({
  selector: 'angular2-multiselect',
  templateUrl: './multiselect.component.html',
  host: {'[class]': 'defaultSettings.classes'},
  styleUrls: ['./multiselect.component.scss'],
  providers: [DROPDOWN_CONTROL_VALUE_ACCESSOR, DROPDOWN_CONTROL_VALIDATION],
  encapsulation: ViewEncapsulation.None,
})

export class AngularMultiSelect implements OnInit, ControlValueAccessor, OnChanges, Validator, AfterViewChecked, OnDestroy {

  @Input()
  data: Array<any>;

  @Input()
  settings: DropdownSettings;

  @Input()
  loading: boolean;

  @Input()
  readonly?: boolean = false;

  @Input()
  readonlyBorderEnabled?: boolean = false;

  @Output('onSelect')
  onSelect: EventEmitter<any> = new EventEmitter<any>();

  @Output('onDeSelect')
  onDeSelect: EventEmitter<any> = new EventEmitter<any>();

  @Output('onSelectAll')
  onSelectAll: EventEmitter<Array<any>> = new EventEmitter<Array<any>>();

  @Output('onDeSelectAll')
  onDeSelectAll: EventEmitter<Array<any>> = new EventEmitter<Array<any>>();

  @Output('onOpen')
  onOpen: EventEmitter<any> = new EventEmitter<any>();

  @Output('onClose')
  onClose: EventEmitter<any> = new EventEmitter<any>();

  @Output('onFilterReset')
  onFilterReset: EventEmitter<any> = new EventEmitter<any>();

  @Output('onScrollToEnd')
  onScrollToEnd: EventEmitter<any> = new EventEmitter<any>();

  @Output('onFilterSelectAll')
  onFilterSelectAll: EventEmitter<Array<any>> = new EventEmitter<Array<any>>();

  @Output('onFilterDeSelectAll')
  onFilterDeSelectAll: EventEmitter<Array<any>> = new EventEmitter<Array<any>>();

  @Output('onAddFilterNewItem')
  onAddFilterNewItem: EventEmitter<any> = new EventEmitter<any>();

  @Output('onGroupSelect')
  onGroupSelect: EventEmitter<any> = new EventEmitter<any>();

  @Output('onGroupDeSelect')
  onGroupDeSelect: EventEmitter<any> = new EventEmitter<any>();

  @Output('onRemoteSearch')
  onRemoteSearch: EventEmitter<any> = new EventEmitter<any>();

  @Output('onReadonlyItemClick')
  onReadonlyItemClick: EventEmitter<any> = new EventEmitter<any>();

  @ContentChild(Item, {static: true}) itemTempl: Item;
  @ContentChild(Badge, {static: true}) badgeTempl: Badge;
  @ContentChild(Search, {static: true}) searchTempl: Search;


  @ViewChild('searchInput') searchInput: ElementRef;
  @ViewChild('selectedList', {static: true}) selectedListElem: ElementRef;
  @ViewChild('dropdownList', {static: true}) dropdownListElem: ElementRef;

  @HostListener('document:keyup.escape', ['$event'])
  onEscapeDown(event: KeyboardEvent) {
    if (this.settings.escapeToClose) {
      this.closeDropdown();
    }
  }

  public searchSubject: BehaviorSubject<any> = new BehaviorSubject<any>({target: {value: ''}});

  filterPipe: ListFilterPipe;
  public selectedItems: Array<any>;
  public isActive: boolean = false;
  public isSelectAll: boolean = false;
  public isFilterSelectAll: boolean = false;
  public isInfiniteFilterSelectAll: boolean = false;
  public groupedData: Array<any>;
  filter: any;
  public chunkArray: any[];
  public scrollTop: any;
  public chunkIndex: any[] = [];
  public cachedItems: any[] = [];
  public groupCachedItems: any[] = [];
  public totalRows: any;
  public itemHeight: any = 41.6;
  public screenItemsLen: any;
  public cachedItemsLen: any;
  public totalHeight: any;
  public scroller: any;
  public maxBuffer: any;
  public lastScrolled: any;
  public lastRepaintY: any;
  public selectedListHeight: any;
  public filterLength: any = 0;
  public infiniteFilterLength: any = 0;
  public viewPortItems: any;
  public item: any;
  public dropdownListYOffset: number = 0;
  subscription: Subscription;
  defaultSettings: DropdownSettings = {
    singleSelection: false,
    text: 'Select',
    enableCheckAll: true,
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    filterSelectAllText: 'Select all filtered results',
    filterUnSelectAllText: 'UnSelect all filtered results',
    enableSearchFilter: false,
    searchBy: [],
    maxHeight: 300,
    badgeShowLimit: 999999999999,
    classes: '',
    disabled: false,
    searchPlaceholderText: 'Search',
    showCheckbox: true,
    noDataLabel: 'No Data Available',
    searchAutofocus: true,
    lazyLoading: false,
    labelKey: 'itemName',
    primaryKey: 'id',
    position: 'bottom',
    autoPosition: false,
    enableFilterSelectAll: true,
    selectGroup: false,
    addNewItemOnFilter: false,
    addNewButtonText: 'Add',
    escapeToClose: true,
    remoteSearch: false,
    translate: false
  }
  public parseError: boolean;
  public filteredList: any = [];

  constructor(public _elementRef: ElementRef, private cdr: ChangeDetectorRef, private ds: DataService, private translateService: TranslateService) {
    this.initSearchSubject();
  }

  ngOnInit() {
    this.settings = Object.assign(this.defaultSettings, this.settings);

    this.cachedItems = this.cloneArray(this.data);
    if (this.settings.position === 'top') {
      setTimeout(() => {
        this.selectedListHeight = {val: 0};
        this.selectedListHeight.val = this.selectedListElem.nativeElement.clientHeight;
      });
    }
    this.subscription = this.ds.getData().subscribe(data => {
      if (data) {
        var len = 0;
        data.forEach((obj: any, i: any) => {
          if (!obj.hasOwnProperty('grpTitle')) {
            len++;
          }
        });
        this.filterLength = len;
        this.onFilterChange(data);
      }

    });
    setTimeout(() => {
      this.calculateDropdownDirection();
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.data && !changes.data.firstChange) {
      if (this.settings.groupBy) {
        this.groupedData = this.transformData(this.data, this.settings.groupBy);
        if (this.data.length === 0) {
          this.selectedItems = [];
        }
      }
      this.cachedItems = this.cloneArray(this.data);
    }
    if (changes.settings && !changes.settings.firstChange) {
      this.settings = Object.assign(this.defaultSettings, this.settings);
    }
    if (changes.loading) {
      console.log(this.loading);
    }
  }

  ngDoCheck() {
    if (this.selectedItems) {
      if (this.selectedItems.length === 0 || this.data.length === 0 || this.selectedItems.length < this.data.length) {
        this.isSelectAll = false;
      }
      else {
        this.isSelectAll = true;
      }
    }
  }

  ngAfterViewInit() {
    if (this.settings.lazyLoading) {
      // this._elementRef.nativeElement.getElementsByClassName("lazyContainer")[0].addEventListener('scroll', this.onScroll.bind(this));
    }
  }

  ngAfterViewChecked() {
    if (this.selectedListElem.nativeElement.clientHeight && this.settings.position === 'top' && this.selectedListHeight) {
      this.selectedListHeight.val = this.selectedListElem.nativeElement.clientHeight;
      this.cdr.detectChanges();
    }
  }

  onItemClick(item: any, index: number, evt: Event) {
    if (this.settings.disabled) {
      return false;
    }

    let found = this.isSelected(item);
    let limit = this.selectedItems.length < this.settings.limitSelection! ? true : false;

    if (!found) {
      if (this.settings.limitSelection) {
        if (limit) {
          this.addSelected(item);
          this.onSelect.emit(item);
        }
      }
      else {
        this.addSelected(item);
        this.onSelect.emit(item);
      }

    }
    else {
      this.removeSelected(item);
      this.onDeSelect.emit(item);
    }
    if (this.isSelectAll || this.data.length > this.selectedItems.length) {
      this.isSelectAll = false;
    }
    if (this.data.length === this.selectedItems.length) {
      this.isSelectAll = true;
    }
    if (this.settings.groupBy) {
      this.updateGroupInfo(item);
    }
  }

  public validate(c: FormControl): any {
    return null;
  }

  private onTouchedCallback: (_: any) => void = noop;
  private onChangeCallback: (_: any) => void = noop;

  writeValue(value: any) {
    if (value !== undefined && value !== null && value !== '') {
      let preprocessedValue = [];
      if (typeof value.forEach === 'function') {
        preprocessedValue = value;
      }
      else if (value.value && typeof value.value.forEach === 'function') {
        preprocessedValue = value.value;
      }
      if (this.settings.singleSelection) {
        try {

          if (preprocessedValue.length > 1) {
            this.selectedItems = [preprocessedValue[0]];
            throw new MyException(404, {'msg': 'Single Selection Mode, Selected Items cannot have more than one item.'});
          }
          else {
            this.selectedItems = preprocessedValue;
          }
        }
        catch (e) {
          console.error(e.body.msg);
        }

      }
      else {
        if (this.settings.limitSelection) {
          this.selectedItems = preprocessedValue.slice(0, this.settings.limitSelection);
        }
        else {
          this.selectedItems = preprocessedValue;
        }
        if (this.data.length > 0) {
          const dataIds = this.data.map(item => item.id).sort();
          const selectedIds = this.selectedItems.map(item => item.id).sort();
          this.isSelectAll = true;
          for (let i = 0; i < dataIds.length; i++) {
            if (selectedIds.indexOf(dataIds[i]) === -1) {
              this.isSelectAll = false;
            }
          }
        }
        if (this.settings.groupBy) {
          this.groupedData = this.transformData(this.data, this.settings.groupBy);
          this.groupCachedItems = this.cloneArray(this.groupedData);
        }
      }
    }
    else {
      this.selectedItems = [];
    }
  }

  //From ControlValueAccessor interface
  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

    //From ControlValueAccessor interface
    registerOnTouched(fn: any) {
        this.onTouchedCallback = fn;
    }
    trackByFn(index: number, item: any) {
        return item[this.settings.primaryKey];
    }
    isSelected(clickedItem: any) {
        let found = false;
        this.selectedItems && this.selectedItems.forEach(item => {
            if (clickedItem[this.settings.primaryKey] === item[this.settings.primaryKey]) {
                found = true;
            }
        });
        return found;
    }
    addSelected(item: any) {
        if (this.settings.singleSelection) {
            this.selectedItems = [];
            this.selectedItems.push(item);
            this.closeDropdown();
        }
        else
            this.selectedItems.push(item);
        this.onChangeCallback(this.selectedItems);
        this.onTouchedCallback(this.selectedItems);
    }
    removeSelected(clickedItem: any) {
        this.selectedItems && this.selectedItems.forEach(item => {
            if (clickedItem[this.settings.primaryKey] === item[this.settings.primaryKey]) {
                this.selectedItems.splice(this.selectedItems.indexOf(item), 1);
            }
        });
        this.onChangeCallback(this.selectedItems);
        this.onTouchedCallback(this.selectedItems);
    }
    toggleDropdown(evt: any) {
        if (this.settings.disabled || this.readonly) {
            return false;
        }
        if ((this.filter === "" || this.filter === null) && this.selectedItems) {
          this.selectedItems.forEach((selectedItem) => {
            if (selectedItem.hasOwnProperty('disabled') && selectedItem.disabled === false) {
              if (!this.data.find(item => item.id === selectedItem.id)) {
                this.data.unshift(selectedItem);
              }
            }
          });
        }
        this.isActive = !this.isActive;
        if (this.isActive) {
            if (this.settings.searchAutofocus && this.searchInput && this.settings.enableSearchFilter && !this.searchTempl) {
                setTimeout(() => {
                    this.searchInput.nativeElement.focus();
                }, 0);
            }
            this.onOpen.emit(true);
        }
        else {
            this.onClose.emit(false);
        }
        setTimeout(() => {
          this.openTowardsTop(this.settings.position === 'top');
        }, 0);

    evt.preventDefault();
  }

  public openDropdown() {
    if (this.settings.disabled) {
      return false;
    }
    this.isActive = true;
    if (this.settings.searchAutofocus && this.searchInput && this.settings.enableSearchFilter && !this.searchTempl) {
      setTimeout(() => {
        this.searchInput.nativeElement.focus();
      }, 0);
    }
    this.onOpen.emit(true);
  }

  public closeDropdown() {
    let wasActive = this.isActive;
    let previousFilter = this.filter;
    if (this.searchInput && this.settings.lazyLoading) {
      this.searchInput.nativeElement.value = '';
    }
    if (this.searchInput) {
      this.searchInput.nativeElement.value = '';
    }
    this.filter = '';
    this.isActive = false;
    this.onClose.emit(false);
    if (wasActive && previousFilter !== '') {
      this.onFilterReset.emit();
    }
  }

  public closeDropdownOnClickOut() {
    const wasActive = this.isActive;
    const previousFilter = this.filter;
    if (this.searchInput && this.settings.lazyLoading) {
      this.searchInput.nativeElement.value = '';
    }
    if (this.searchInput) {
      this.searchInput.nativeElement.value = '';
    }
    this.filter = '';
    this.isActive = false;
    this.onClose.emit(false);
    if (wasActive && previousFilter !== '') {
      this.onFilterReset.emit();
    }
  }

  toggleSelectAll() {
    if (this.settings.remoteSearch) {
      if (!this.isSelectAll) {
        this.data.forEach((data) => {
          const index = this.selectedItems.findIndex(item => item.id === data.id);
          if (index === -1) {
            this.selectedItems.push(data);
          }
        });
        this.isSelectAll = true;
        this.onChangeCallback(this.selectedItems);
        this.onTouchedCallback(this.selectedItems);

        this.onSelectAll.emit(this.selectedItems);
      }
      else {
        this.data.forEach((data) => {
          const index = this.selectedItems.findIndex(item => item.id === data.id);
          if (index > -1) {
            this.selectedItems.splice(index, 1);
          }
        });
        this.isSelectAll = false;
        this.onChangeCallback(this.selectedItems);
        this.onTouchedCallback(this.selectedItems);

        this.onDeSelectAll.emit(this.selectedItems);
      }
    }
    else {
      if (!this.isSelectAll) {
        this.selectedItems = [];
        if (this.settings.groupBy) {
          this.groupedData.forEach((obj) => {
            obj.selected = true;
          });
          this.groupCachedItems.forEach((obj) => {
            obj.selected = true;
          });
        }
        this.selectedItems = this.data.slice();
        this.isSelectAll = true;
        this.onChangeCallback(this.selectedItems);
        this.onTouchedCallback(this.selectedItems);

        this.onSelectAll.emit(this.selectedItems);
      }
      else {
        if (this.settings.groupBy) {
          this.groupedData.forEach((obj) => {
            obj.selected = false;
          });
          this.groupCachedItems.forEach((obj) => {
            obj.selected = false;
          })
        }
        this.selectedItems = [];
        this.isSelectAll = false;
        this.onChangeCallback(this.selectedItems);
        this.onTouchedCallback(this.selectedItems);

            this.onDeSelectAll.emit(this.selectedItems);
          }
        }
    }
    filterGroupedList() {
        if (this.filter === "" || this.filter === null) {
            this.clearSearch();
            return;
        }
        this.groupedData = this.cloneArray(this.groupCachedItems);
        this.groupedData = this.groupedData.filter(obj => {
            var arr = obj.list.filter(t => {
                return t.itemName.toLowerCase().indexOf(this.filter.toLowerCase()) > -1;
            });
            obj.list = arr;
            return arr.some(cat => {
                return cat.itemName.toLowerCase().indexOf(this.filter.toLowerCase()) > -1;
            }
            )
        });
        console.log(this.groupedData);
    }
    toggleFilterSelectAll() {
        if (!this.isFilterSelectAll) {
            let added = [];
            if (this.settings.groupBy) {
                this.groupedData.forEach((item: any) => {
                    if (item.list) {
                        item.list.forEach((el: any) => {
                            if (!this.isSelected(el)) {
                                this.addSelected(el);
                                // @ts-ignore
                              added.push(el);
                            }
                        });
                    }
                    this.updateGroupInfo(item);

        });

      }
      else {
        this.ds.getFilteredData().forEach((item: any) => {
          if (!this.isSelected(item)) {
            this.addSelected(item);
            // @ts-ignore
            added.push(item);
          }

        });
      }

      this.isFilterSelectAll = true;
      this.onFilterSelectAll.emit(added);
    }
    else {
      let removed = [];
      if (this.settings.groupBy) {
        this.groupedData.forEach((item: any) => {
          if (item.list) {
            item.list.forEach((el: any) => {
              if (this.isSelected(el)) {
                this.removeSelected(el);
                // @ts-ignore
                removed.push(el);
              }
            });
          }
        });
      }
      else {
        this.ds.getFilteredData().forEach((item: any) => {
          if (this.isSelected(item)) {
            this.removeSelected(item);
            // @ts-ignore
            removed.push(item);
          }

        });
      }
      this.isFilterSelectAll = false;
      this.onFilterDeSelectAll.emit(removed);
    }
  }

  toggleInfiniteFilterSelectAll() {
    if (!this.isInfiniteFilterSelectAll) {
      this.data.forEach((item: any) => {
        if (!this.isSelected(item)) {
          this.addSelected(item);
        }

      });
      this.isInfiniteFilterSelectAll = true;
    }
    else {
      this.data.forEach((item: any) => {
        if (this.isSelected(item)) {
          this.removeSelected(item);
        }

      });
      this.isInfiniteFilterSelectAll = false;
    }
  }

  clearSearch() {
    this.searchSubject.next({target: {value: ''}});
    if (this.settings.groupBy) {
      this.groupedData = [];
      this.groupedData = this.cloneArray(this.groupCachedItems);
    }
    this.filter = '';
    this.isFilterSelectAll = false;

    }
    onFilterChange(data: any) {
        if (this.filter && this.filter === "" || data.length === 0) {
            this.isFilterSelectAll = false;
        }
        let cnt = 0;
        data.forEach((item: any) => {

      if (!item.hasOwnProperty('grpTitle') && this.isSelected(item)) {
        cnt++;
      }
    });

    if (cnt > 0 && this.filterLength === cnt) {
      this.isFilterSelectAll = true;
    }
    else if (cnt > 0 && this.filterLength !== cnt) {
      this.isFilterSelectAll = false;
    }
    this.cdr.detectChanges();
  }

  cloneArray(arr: any) {
    var i, copy;

    if (Array.isArray(arr)) {
      return JSON.parse(JSON.stringify(arr));
    }
    else if (typeof arr === 'object') {
      throw 'Cannot clone array containing an object!';
    }
    else {
      return arr;
    }
  }

  updateGroupInfo(item: any) {
    var key = this.settings.groupBy;
    this.groupedData.forEach((obj: any) => {
      var cnt = 0;
      // @ts-ignore
      if (obj.grpTitle && (item[key] === obj[key])) {
        if (obj.list) {
          obj.list.forEach((el: any) => {
            if (this.isSelected(el)) {
              cnt++;
            }
          });
        }
      }
      // @ts-ignore
      if (obj.list && (cnt === obj.list.length) && (item[key] === obj[key])) {
        obj.selected = true;
      }
      else { // @ts-ignore
        if (obj.list && (cnt !== obj.list.length) && (item[key!] === obj[key!])) {
          obj.selected = false;
        }
      }
    });
    this.groupCachedItems.forEach((obj: any) => {
      var cnt = 0;
      // @ts-ignore
      if (obj.grpTitle && (item[key] === obj[key])) {
        if (obj.list) {
          obj.list.forEach((el: any) => {
            if (this.isSelected(el)) {
              cnt++;
            }
          });
        }
      }
      // @ts-ignore
      if (obj.list && (cnt === obj.list.length) && (item[key] === obj[key])) {
        obj.selected = true;
      }
      else { // @ts-ignore
        if (obj.list && (cnt !== obj.list.length) && (item[key!] === obj[key!])) {
          obj.selected = false;
        }
      }
    });
  }

  transformData(arr: Array<any>, field: any): Array<any> {
    const groupedObj: any = arr.reduce((prev: any, cur: any) => {
      if (!prev[cur[field]]) {
        prev[cur[field]] = [cur];
      }
      else {
        prev[cur[field]].push(cur);
      }
      return prev;
    }, {});
    const tempArr: any = [];
    Object.keys(groupedObj).map((x: any) => {
      var obj: any = {};
      obj['grpTitle'] = true;
      // @ts-ignore
      obj[this.settings.labelKey] = x;
      // @ts-ignore
      obj[this.settings.groupBy] = x;
      obj['selected'] = false;
      obj['list'] = [];
      var cnt = 0;
      groupedObj[x].forEach((item: any) => {
        item['list'] = [];
        obj.list.push(item);
        if (this.isSelected(item)) {
          cnt++;
        }
      });
      if (cnt === obj.list.length) {
        obj.selected = true;
      }
      else {
        obj.selected = false;
      }
      tempArr.push(obj);
      // obj.list.forEach((item: any) => {
      //     tempArr.push(item);
      // });
    });
    return tempArr;
  }

  public filterInfiniteList(evt: any) {
    var filteredElems: Array<any> = [];
    if (this.settings.groupBy) {
      this.groupedData = this.groupCachedItems.slice();
    }
    else {
      this.data = this.cachedItems.slice();
    }

    if ((evt.target.value !== null || evt.target.value !== '') && !this.settings.groupBy) {
      if (this.settings.searchBy.length > 0) {
        for (var t = 0; t < this.settings.searchBy.length; t++) {

          this.data.filter((el: any) => {
            if (el[this.settings.searchBy[t].toString()].toString().toLowerCase().indexOf(evt.target.value.toString().toLowerCase()) >= 0) {
              filteredElems.push(el);
            }
          });
          /*                    if (filter && item[searchBy[t]] && item[searchBy[t]] !== "") {
                                  if (item[searchBy[t]].toString().toLowerCase().indexOf(filter.toLowerCase()) >= 0) {
                                      found = true;
                                  }
                              }*/
        }

      }
      else {
        this.data.filter(function (el: any) {
          for (var prop in el) {
            if (el[prop].toString().toLowerCase().indexOf(evt.target.value.toString().toLowerCase()) >= 0) {
              filteredElems.push(el);
              break;
            }
          }
        });
      }
      this.data = [];
      this.data = filteredElems;
      this.infiniteFilterLength = this.data.length;
    }
    if (evt.target.value.toString() !== '' && this.settings.groupBy) {
      this.groupedData.filter(function (el: any) {
        if (el.hasOwnProperty('grpTitle')) {
          filteredElems.push(el);
        }
        else {
          for (var prop in el) {
            if (el[prop].toString().toLowerCase().indexOf(evt.target.value.toString().toLowerCase()) >= 0) {
              filteredElems.push(el);
              break;
            }
          }
        }
      });
      this.groupedData = [];
      this.groupedData = filteredElems;
      this.infiniteFilterLength = this.groupedData.length;
    }
    else if (evt.target.value.toString() === '' && this.cachedItems.length > 0) {
      this.data = [];
      this.data = this.cachedItems;
      this.infiniteFilterLength = 0;
    }
  }

  resetInfiniteSearch() {
    this.filter = '';
    this.isInfiniteFilterSelectAll = false;
    this.data = [];
    this.data = this.cachedItems;
    this.groupedData = this.groupCachedItems;
    this.infiniteFilterLength = 0;
  }

  onScrollEnd(e: ChangeEvent) {
    this.onScrollToEnd.emit(e);
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }

  }

  selectGroup(item: any) {
    if (item.selected) {
      item.selected = false;
      item.list.forEach((obj: any) => {
        this.removeSelected(obj);
      });
      this.updateGroupInfo(item);
      this.onGroupSelect.emit(item);
    }
    else {
      item.selected = true;
      item.list.forEach((obj: any) => {
        if (!this.isSelected(obj)) {
          this.addSelected(obj);
        }

      });
      this.updateGroupInfo(item);
      this.onGroupDeSelect.emit(item);
    }


  }

  addFilterNewItem() {
    this.onAddFilterNewItem.emit(this.filter);
    this.filterPipe = new ListFilterPipe(this.ds, this.translateService);
    this.filterPipe.transform(this.data, this.filter, this.settings.searchBy, !!this.settings.translate);
  }

  calculateDropdownDirection() {
    let shouldOpenTowardsTop = this.settings.position === 'top';
    if (!this.settings.autoPosition) {
      if (this.settings.position == 'top') {
        this.openTowardsTop(true);
      }
      else {
        this.openTowardsTop(false);
      }
    } else {
      const dropdownHeight = this.defaultSettings.maxHeight;
      const viewportHeight = document.documentElement.clientHeight;
      const selectedListBounds = this.selectedListElem.nativeElement.getBoundingClientRect();

      const spaceOnTop: number = selectedListBounds.top;
      const spaceOnBottom: number = viewportHeight - selectedListBounds.top;
      if (spaceOnBottom < spaceOnTop && dropdownHeight < spaceOnTop) {
        this.openTowardsTop(true);
      }
      else {
        this.openTowardsTop(false);
      }
      // Keep preference if there is not enough space on either the top or bottom
      /* 			if (spaceOnTop || spaceOnBottom) {
                      if (shouldOpenTowardsTop) {
                          shouldOpenTowardsTop = spaceOnTop;
                      } else {
                          shouldOpenTowardsTop = !spaceOnBottom;
                      }
                  } */
    }

  }

  openTowardsTop(value: boolean) {
    if (value && this.selectedListElem.nativeElement.clientHeight) {
      this.dropdownListYOffset = 15 + this.selectedListElem.nativeElement.clientHeight;
    }
    else {
      this.dropdownListYOffset = 0;
    }
  }

  private initSearchSubject() {
    this.searchSubject.pipe(debounceTime(UiConstants.autocompleteDebounceTime)).subscribe(event => {
      this.onRemoteSearch.emit(event);
    });
    this.onFilterReset.pipe(debounceTime(UiConstants.autocompleteDebounceTime)).subscribe(event => {
      this.onRemoteSearch.emit(Angular2Multiselects.EmptySearchEvent);
    });
  }
}

@NgModule({
  imports: [CommonModule, FormsModule, TranslateModule],
  declarations: [AngularMultiSelect, ClickOutsideDirective, ScrollDirective, styleDirective, ListFilterPipe, Item, TemplateRenderer, Badge, Search, setPosition, VirtualScrollComponent, CIcon],
  exports: [AngularMultiSelect, ClickOutsideDirective, ScrollDirective, styleDirective, ListFilterPipe, Item, TemplateRenderer, Badge, Search, setPosition, VirtualScrollComponent, CIcon],
  providers: [DataService]
})
export class AngularMultiSelectModule {
}
