/* eslint-disable */
import { Component } from '@angular/core';
import {
  FormRecordFieldContext,
  FormRecordFieldValueUpdateArgs,
  FormRecordFieldView,
  FormRecordFieldViewContext,
  FormRecordFieldViewModel,
  SetTmpReadonlyArgs,
  SetTmpReadonlyResult
} from '../../../../../util/form/form-utils';
import { Form } from '../../../../../lib/form/form.service';
import { InputMask } from '../../../../../util/input-masks';
import { FormBuilder, FormGroup } from '@angular/forms';
import { FormRef } from '../../../../../lib/util/services';
import { FieldActivationState, FieldActivationStateResolver } from '../../../../../util/form/form-editors';
import { Command } from '../../../../../util/command';
import { FormRecord } from '../../../../../lib/form/form-record.service';
import { StockRecordService } from '../../../../../lib/stock/stock-record.service';
import { StockItemService, UnitPrice } from '../../../../../lib/stock/stock-item.service';
import { StockService } from '../../../../../lib/stock/stock.service';
import Decimal from 'decimal.js';
import { FormRecordInactivityManager, noOpCommand } from '../../manager/form-record-inactivity-manager';
import { Observable } from 'rxjs';

/* eslint-enable */

@Component({
  selector: 'app-form-record-process-order-stock-outtake-check-field',
  templateUrl: './form-record-process-order-stock-outtake-check-field.component.html',
  styleUrls: ['./form-record-process-order-stock-outtake-check-field.component.scss']
})
export class FormRecordProcessOrderStockOuttakeCheckFieldComponent implements FormRecordFieldView {

  public readonly selector: Form.FieldDataTypeSelector.PROCESS_ORDER_STOCK_OUTTAKE_CHECK;

  formRecordFieldContext?: FormRecordFieldContext;
  formRecordInactivityManager?: FormRecordInactivityManager;

  model: Model = new Model();

  InputMask = InputMask;

  formGroup: FormGroup;
  private fieldId?: number;
  private htmlForm?: FormRef;
  tmpReadonly: boolean = false;

  hide: () => boolean = () => true;

  private readonlyFormFn: () => boolean = () => true;
  private readonlyFieldFn: () => boolean = () => false;
  private hiddenFieldFn: () => boolean = () => false;
get nonEditable(): boolean {
    return FieldActivationStateResolver.isNonEditable(
      this.fieldActivationState
    );
  }

  private get readonlyForm(): boolean {
    return this.readonlyFormFn();
  }

  private get fieldActivationState(): FieldActivationState {
    return FieldActivationStateResolver.resolveFieldActivationState({
      readonlyFormFn: () => this.readonlyFormFn(),
      readonlyFieldFn: () => this.readonlyFieldFn(),
      tmpReadonlyFieldFn: () => this.tmpReadonly,
    });
  }

  setTmpReadonly(args: SetTmpReadonlyArgs): Command<SetTmpReadonlyResult> {
    return noOpCommand;
  }

  registerField(context: FormRecordFieldContext, originalModel?: any): any {
    if (originalModel) {
      this.model = originalModel;
    }
    this.formRecordFieldContext = context;
    this.fieldId = context.field.fieldId;
    this.htmlForm = context.htmlForm;
    this.model.title = context.field.title;
    this.readonlyFormFn = context.readonly;
    this.hiddenFieldFn = () => Form.FormFieldValidationType.HIDDEN === context.validationType;
    this.readonlyFieldFn = () => Form.FormFieldValidationType.READONLY === context.validationType
    || Form.FormFieldValidationType.HIDDEN === context.validationType;
    this.hide = context.cloning;
    if (context.fieldRecord && !this.hide()) {
      this.registerFieldData(context.fieldRecord);
    }
    return this.model;
  }

  registerFieldData(fieldRecord: FormRecord.FieldComposed): void {
  }

  registerFieldViews(context: FormRecordFieldViewContext): void {
    this.formRecordInactivityManager = context.inactivityManager;
  }

  hasLocalFieldError(): boolean {
    return false;
  }

  validateWithInterrupt(): boolean {
    return false;
  }

  shouldNotifyAfterCreation(): boolean {
    return false;
  }

  afterFormRecordCreation(formRecordId: number): Observable<any> | undefined {
    return undefined;
  }

  createModel(): FormRecordFieldViewModel {
    if (this.fieldId === undefined) {
      throw new Error('Field ID is undefined');
    }
    const attrs: FormRecord.FieldDataProcessOrderStockOuttakeCheckAttributes = {
    };
    return {
      fieldEditRequest: {
        fieldId: this.fieldId,
        data: {
          processOrderStockOuttakeCheckAttributes: attrs
        }
      }
    };
  }

  updateValue(data: FormRecordFieldValueUpdateArgs) {
  }

  constructor(private stockRecordService: StockRecordService,
              private stockService: StockService,
              private stockItemService: StockItemService,
              private fb: FormBuilder) {
  }
}

export class Model {
  stocks?: StockItemModel[] = [];
  title: string = '';
}

export class StockItemModel {
  amount?: Decimal = undefined;
  customNetPrice?: string = undefined;
  stockRecord: StockRecordModel = new StockRecordModel();
}

export class StockRecordModel {
  id: number;
  amount?: number = undefined;
  stock?: number = undefined;
  stockItem: StockItemFormModel = new StockItemFormModel();
}

export class StockItemFormModel {
  id: number;
  name?: string;
  external_id?: string;
  product_code?: string;
  serial_code?: string;
  unit?: string;
  unit_price?: UnitPrice;
}
