/* eslint-disable */
import { Injectable } from '@angular/core';
import { Observable, Observer } from 'rxjs';
import { Order, PagingRequest, QueryResult, ResourceQueryResult, Services } from '../util/services';
import { List, Map, Set } from 'immutable';
import { DownloadedFile } from '../util/downloaded-files';
import { ClientDocumentResource, ClientDocumentResourceService } from './client-document-resource.service';
import { OffsetDateTime } from '../util/dates';
import { DqlStoredQuery, DqlStoredQueryService } from '../dql/dql-stored-query.service';

/* eslint-enable */

@Injectable()
export class ClientDocumentService implements ClientDocument.Service, DqlStoredQuery.HolderService {

  private _dqlStoredQueryService: DqlStoredQueryService;

  constructor(private resourceService: ClientDocumentResourceService) {
    this._dqlStoredQueryService = new DqlStoredQueryService(resourceService.dqlStoredResourceService);
  }

  get dqlStoredQueryService(): DqlStoredQueryService {
    return this._dqlStoredQueryService;
  }

  query(request: ClientDocument.QueryRequest): Observable<QueryResult<ClientDocument.ClientDocument>> {
    return Observable.create((observer: Observer<QueryResult<ClientDocument.ClientDocument>>) => {
      const resourceRequest: ClientDocumentResource.QueryRequest = {
        ids: Services.createListParameter(request.ids),
        submitted_only: request.submittedOnly,
        document_type: request.documentType,
        dql: request.dql,
        order: Services.createOrderFieldParameter(Keys.toOrderFieldKey, request.orders),
        page_number: request.paging ? request.paging.pageNumber : undefined,
        number_of_items: request.paging ? request.paging.numberOfItems : undefined,
      };
      return this.resourceService.query(resourceRequest).subscribe(
        (result: ResourceQueryResult<ClientDocumentResource.ClientDocument>) => {
          observer.next({
            items: this.toPublicList(result.items),
            pagingResult: result.pagingResult
          });
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  downloadPdf(request: ClientDocument.DownloadPdfRequest): Observable<DownloadedFile> {
    return this.resourceService.downloadPdf({
      id: request.id,
      document_type: request.documentType
    });
  }

  downloadZip(request: ClientDocument.QueryRequest): Observable<DownloadedFile> {
    const resourceRequest: ClientDocumentResource.QueryRequest = {
      ids: Services.createListParameter(request.ids),
      submitted_only: request.submittedOnly,
      document_type: request.documentType,
      dql: request.dql,
      order: Services.createOrderFieldParameter(Keys.toOrderFieldKey, request.orders),
      page_number: request.paging ? request.paging.pageNumber : undefined,
      number_of_items: request.paging ? request.paging.numberOfItems : undefined,
    };
    return this.resourceService.downloadZip(resourceRequest);
  }

  private toPublicList(resourceList: ClientDocumentResource.ClientDocument[]): List<ClientDocument.ClientDocument> {
    return List.of(...resourceList.map((r) => this.toPublic(r)));
  }

  private toPublic(r: ClientDocumentResource.ClientDocument): ClientDocument.ClientDocument {
    return {
      id: r.id,
      documentType: r.document_type,
      externalId: r.external_id,
      state: <ClientDocument.State> r.state,
      customerRecordId: r.customer_record_id,
      taskRecordId: r.task_record_id,
      creatorUserId: r.creator_user_id,
      lastEditorUserId: r.last_editor_user_id,
      submitterUserId: r.submitter_user_id,
      creationTime: Services.toOffsetDateTime(r.creation_time),
      updateTime: Services.toOffsetDateTime(r.update_time),
      submitTime: Services.toOffsetDateTime(r.submit_time),
      hasPdf: r.has_pdf,
      pdfId: r.pdf_id,
      version: this.toPublicElasticVersion(r.version),
      form: this.toPublicClientDocumentForm(r.form)
    };
  }

  private toPublicElasticVersion(r?: ClientDocumentResource.ElasticVersion): ClientDocument.ElasticVersion | undefined {
    if (r) {
      return {
        seqNo: r.seq_no,
        primaryTerm: r.primary_term
      };
    }
    return undefined;
  }

  private toPublicClientDocumentForm(r: ClientDocumentResource.ClientDocumentForm): ClientDocument.ClientDocumentForm {
    return {
      insuranceProduct: this.toPublicObjectWithText(r.insurance_product),
      insuranceType: this.toPublicObjectWithText(r.insurance_type),
      insuranceLevel: this.toPublicLevelObject(r.insurance_level),
      paymentType: this.toPublicObjectWithText(r.payment_type),
      sponsor: this.toPublicObjectWithText(r.sponsor),
      deletionCause: r.deletion_cause,
      deletionDate: Services.toOffsetDateTime(r.deletion_date)
    };
  }

  private toPublicObjectWithText(r?: ClientDocumentResource.ObjectWithText): ClientDocument.ObjectWithText | undefined {
    if (r) {
      return {
        text: r.text,
        code: r.code
      };
    }
    return undefined;
  }

  private toPublicLevelObject(r?: ClientDocumentResource.LevelObject): ClientDocument.LevelObject | undefined {
    if (r) {
      return {
        level: r.level
      };
    }
    return undefined;
  }

}

export namespace ClientDocument {

  export interface Service {

    // <editor-fold desc="CRUD">

    query(request: QueryRequest): Observable<QueryResult<ClientDocument>>;

    // </editor-fold>

  }

  export interface ClientDocument {
    id: string,
    documentType: string,
    externalId: string,
    state: State,
    customerRecordId?: number,
    taskRecordId?: number,
    creatorUserId: number,
    lastEditorUserId: number,
    submitterUserId?: number,
    creationTime: OffsetDateTime,
    updateTime: OffsetDateTime,
    submitTime: OffsetDateTime,
    hasPdf: boolean,
    pdfId?: number,
    version?: ElasticVersion,
    form: ClientDocumentForm
  }

  export interface ClientDocumentForm {
    insuranceProduct?: ObjectWithText,
    insuranceType?: ObjectWithText,
    paymentType?: ObjectWithText,
    insuranceLevel?: LevelObject,
    sponsor?: ObjectWithText,
    deletionDate: OffsetDateTime,
    deletionCause?: string
  }

  export interface ObjectWithText {
    text?: string;
    code?: string;
  }

  export interface LevelObject {
    level?: number;
  }

  export interface ElasticVersion {
    seqNo: number,
    primaryTerm: number
  }

  export interface DownloadPdfRequest {
    id: string;
    documentType: string;
  }

  export interface QueryRequest {
    ids?: Set<string>;
    submittedOnly?: boolean;
    documentType?: string;
    dql?: string;
    orders?: Set<Order<OrderField>>;
    paging?: PagingRequest;
  }

  export enum State {
    IN_PROGRESS = 'IN_PROGRESS',
    SUBMITTED = 'SUBMITTED',
    DELETED = 'DELETED',
  }

  export enum OrderField {
    EXTERNAL_ID,
    SUBMIT_TIME,
    CREATION_TIME
  }

}

class Keys {

  private static readonly EXTERNAL_ID = 'external_id.keyword';
  private static readonly SUBMIT_TIME = 'submit_time';
  private static readonly CREATION_TIME = 'creation_time';

  private static readonly orderFieldKeyMap: Map<ClientDocument.OrderField, string> = Map.of(
    ClientDocument.OrderField.EXTERNAL_ID, Keys.EXTERNAL_ID,
    ClientDocument.OrderField.SUBMIT_TIME, Keys.SUBMIT_TIME,
    ClientDocument.OrderField.CREATION_TIME, Keys.CREATION_TIME,
  );

  public static toOrderFieldKey(field: ClientDocument.OrderField): string {
    return Keys.orderFieldKeyMap.get(field)!;
  }

}
