export type PermissionDefinition = string;

export class PermissionDefinitions {

  public static readonly WEB_ADMIN_CUSTOMERS = 'Web:Admin:customers';
  public static readonly WEB_ADMIN_EMAIL_ADDRESS_TYPES = 'Web:Admin:email_address_types';
  public static readonly WEB_ADMIN_TASKS = 'Web:Admin:tasks';
  public static readonly WEB_ADMIN_PROJECTS = 'Web:Admin:projects';
  public static readonly WEB_ADMIN_MASTERDATA = 'Web:Admin:masterdata';
  public static readonly WEB_ADMIN_SURVEYS = 'Web:Admin:surveys';
  public static readonly WEB_ADMIN_FORM_TABLES = 'Web:Admin:form_tables';
  public static readonly WEB_ADMIN_LIST_ITEM_TYPES = 'Web:Admin:list_item_types';
  public static readonly WEB_ADMIN_WORKFLOWS = 'Web:Admin:workflows';
  public static readonly WEB_ADMIN_LEGACY_WORKFLOWS = 'Web:Admin:legacy_workflows';
  public static readonly WEB_ADMIN_DOCUMENTS = 'Web:Admin:documents';
  public static readonly WEB_ADMIN_COMPANIES = 'Web:Admin:companies';
  public static readonly WEB_ADMIN_COMPANY_LOCATIONS = 'Web:Admin:company_locations';
  public static readonly WEB_ADMIN_COMPANY_STOCKS = 'Web:Admin:company_stocks';
  public static readonly WEB_ADMIN_SHOP_RENTER_SHOPS = 'Web:Admin:shop_renter_shops';
  public static readonly WEB_ADMIN_APPLICATION_FILES = 'Web:Admin:application_files';
  public static readonly WEB_ADMIN_TABLE_DOCUMENTS = 'Web:Admin:table_documents';
  public static readonly WEB_ADMIN_TABLE_DOCUMENT_SCHEMAS = 'Web:Admin:table_document_schemas';
  public static readonly WEB_ADMIN_STOCK_ITEMS = 'Web:Admin:stock_items';
  public static readonly WEB_ADMIN_STOCK_ITEM_CATEGORIES = 'Web:Admin:stock_item_categories';
  public static readonly WEB_ADMIN_STOCK_CHANGES = 'Web:Admin:stock_changes';
  public static readonly WEB_ADMIN_LIST_ITEMS = 'Web:Admin:list_items';
  public static readonly WEB_ADMIN_VEHICLES = 'Web:Admin:vehicles';
  public static readonly WEB_ADMIN_POST_OFFICES = 'Web:Admin:post_offices';
  public static readonly WEB_ADMIN_PARCEL_COLLECTION_POINTS = 'Web:Admin:parcel_collection_points';
  public static readonly WEB_ADMIN_DELIVERY_METHODS = 'Web:Admin:delivery_methods';
  public static readonly WEB_ADMIN_USERS = 'Web:Admin:users';
  public static readonly WEB_ADMIN_USER_GROUPS = 'Web:Admin:user_groups';
  public static readonly WEB_ADMIN_ROLES = 'Web:Admin:roles';
  public static readonly WEB_ADMIN_EMAIL_TEMPLATES = 'Web:Admin:email_templates';
  public static readonly WEB_ADMIN_PDF_TEMPLATES = 'Web:Admin:pdf_templates';
  public static readonly WEB_ADMIN_MESSAGE_TEMPLATES = 'Web:Admin:message_templates';
  public static readonly WEB_ADMIN_PRINTER_TEMPLATES = 'Web:Admin:printer_templates';
  public static readonly WEB_ADMIN_TASK_RECORD_LOGS = 'Web:Admin:task_record_logs';
  public static readonly WEB_ADMIN_TASK_RECORD_STATISTICS = 'Web:Admin:task_record_statistics';
  public static readonly WEB_ADMIN_WORKLOGS = 'Web:Admin:worklogs';
  public static readonly WEB_ADMIN_INVOICE_STATISTICS = 'Web:Admin:invoice_statistics';
  public static readonly WEB_ADMIN_ORDER_IMPORT_ERRORS = 'Web:Admin:order_import_errors';
  public static readonly WEB_ADMIN_MILEAGE_RECORDS = 'Web:Admin:mileage_records';
  public static readonly WEB_ADMIN_AUDIT_LOGS = 'Web:Admin:audit_logs';
  public static readonly WEB_ADMIN_ENVIRONMENT_CONFIGURATION = 'Web:Admin:environment_configuration';
  public static readonly WEB_ADMIN_REGISTRATION_SETTINGS = 'Web:Admin:registration_settings';
  public static readonly WEB_ADMIN_MOBILE_APPLICATIONS = 'Web:Admin:mobile_applications';
  public static readonly WEB_ADMIN_TOKENS = 'Web:Admin:tokens';
  public static readonly WEB_ADMIN_INVOICE_BOOK = 'Web:Admin:invoice_book';
  public static readonly WEB_ADMIN_INVOICE_PROFILES = 'Web:Admin:invoice_profiles';
  public static readonly WEB_ADMIN_LEDGER_NUMBERS = 'Web:Admin:ledger_numbers';
  public static readonly WEB_ADMIN_INVOICE_TAGS = 'Web:Admin:invoice_tags';
  public static readonly WEB_ADMIN_TRANSPORT_TRIGGERS = 'Web:Admin:transport_triggers';
  public static readonly WEB_ADMIN_NOTIFICATIONS = 'Web:Admin:notifications';
  public static readonly WEB_MENU_ADMIN = 'Web:Menu:admin';
  public static readonly WEB_MENU_DASHBOARD = 'Web:Menu:dashboard';
  public static readonly WEB_MENU_TASK_RECORDS = 'Web:Menu:task_records';
  public static readonly WEB_MENU_TASK_RECORD_BOARD = 'Web:Menu:task_record_board';
  public static readonly WEB_MENU_CUSTOMER_RECORDS = 'Web:Menu:customer_records';
  public static readonly WEB_MENU_MASTERDATA_RECORDS = 'Web:Menu:masterdata_records';
  public static readonly WEB_MENU_SURVEY_RECORDS = 'Web:Menu:survey_records';
  public static readonly WEB_MENU_INVOICES = 'Web:Menu:invoices';
  public static readonly WEB_MENU_INVOICE_SPENDINGS = 'Web:Menu:invoice_spendings';
  public static readonly WEB_MENU_CALENDAR = 'Web:Menu:calendar';
  public static readonly WEB_MENU_GLOBAL_CALENDAR = 'Web:Menu:global_calendar';
  public static readonly WEB_MENU_PROJECTS = 'Web:Menu:projects';
  public static readonly WEB_MENU_ORDERS = 'Web:Menu:orders';
  public static readonly WEB_MENU_LEGACY_PROCESSES = 'Web:Menu:legacy_processes';
  public static readonly WEB_MENU_PROCESSES = 'Web:Menu:processes';
  public static readonly WEB_MENU_STOCKS = 'Web:Menu:stocks';
  public static readonly WEB_MENU_STOCK_TAKINGS = 'Web:Menu:stock_takings';
  public static readonly WEB_MENU_SHIPPING_DEMANDS = 'Web:Menu:shipping_demands';
  public static readonly WEB_MENU_SHIPMENTS = 'Web:Menu:shipments';
  public static readonly WEB_MENU_TRANSPORTS = 'Web:Menu:transports';
  public static readonly WEB_MENU_EXTERIOR_TRANSPORTS = 'Web:Menu:exterior_transports';
  public static readonly WEB_MENU_NOTES = 'Web:Menu:notes';
  public static readonly WEB_MENU_DOCUMENTS = 'Web:Menu:documents';
  public static readonly WEB_MENU_DOCUMENT_LIBRARY = 'Web:Menu:document_library';

  public static readonly APP_TYPE_READ: string = 'AppType:read';

  public static readonly API_USER_KEY_READ: string = 'ApiUserKey:read';
  public static readonly API_USER_KEY_ACTIVATE: string = 'ApiUserKey:activate';
  public static readonly API_USER_KEY_INACTIVATE: string = 'ApiUserKey:inactivate';
  public static readonly API_USER_KEY_REGENERATE: string = 'ApiUserKey:regenerate';

  public static readonly USER_CREATE: string = 'User:create';
  public static readonly USER_CREATE_ALL: string = 'User:create::all';
  public static readonly USER_CREATE_MY_COMPANY: string = 'User:create::my_company';
  public static readonly USER_DISABLE: string = 'User:disable';
  public static readonly USER_DISABLE_ALL: string = 'User:disable::all';
  public static readonly USER_DISABLE_MY_COMPANY: string = 'User:disable::my_company';
  public static readonly USER_DELETE_ALL: string = 'User:delete::all';
  public static readonly USER_DELETE_MY_COMPANY: string = 'User:delete::my_company';
  public static readonly USER_READ_ALL: string = 'User:read::all';
  public static readonly USER_READ_MYSELF: string = 'User:read::myself';
  public static readonly USER_READ_MY_COMPANY: string = 'User:read::my_company';
  public static readonly USER_UPDATE_ALL: string = 'User:update::all';
  public static readonly USER_UPDATE_MYSELF: string = 'User:update::myself';
  public static readonly USER_UPDATE_MY_COMPANY: string = 'User:update::my_company';
  public static readonly USER_HISTORY_LOG_READ: string = 'User:HistoryLog:read';

  public static readonly USER_CHANGE_PASSWORD_ALL: string = 'User:change:password::all';
  public static readonly USER_CHANGE_PASSWORD_MYSELF: string = 'User:change:password::myself';
  public static readonly USER_CHANGE_PASSWORD_MY_COMPANY: string = 'User:change:password::my_company';
  public static readonly USER_CHANGE_PREVIOUS_PASSWORD_ALL: string = 'User:change:previous_password::all';
  public static readonly USER_CHANGE_PREVIOUS_PASSWORD_MYSELF: string = 'User:change:previous_password::myself';

  public static readonly USER_LDAP_SYNC_ALL = 'User:Ldap:sync::all';
  public static readonly USER_SIGNATURE_DELETE_ALL = 'User:Signature:delete::all';
  public static readonly USER_SIGNATURE_DELETE_MINE = 'User:Signature:delete::mine';
  public static readonly USER_SIGNATURE_READ_ALL = 'User:Signature:read::all';
  public static readonly USER_SIGNATURE_READ_MINE = 'User:Signature:read::mine';
  public static readonly USER_SIGNATURE_UPLOAD_ALL = 'User:Signature:upload::all';
  public static readonly USER_SIGNATURE_UPLOAD_MINE = 'User:Signature:upload::mine';

  public static readonly APP_DICTIONARY_UPDATE: string = 'AppDictionary:update';

  public static readonly USER_ATTACHMENT_DOWNLOAD_FILE_ALL: string = 'User:attachment:download::all';
  public static readonly USER_ATTACHMENT_DOWNLOAD_FILE_MYSELF: string = 'User:attachment:download::myself';
  public static readonly USER_ATTACHMENT_UPLOAD_FILE_ALL: string = 'User:attachment:upload::all';
  public static readonly USER_ATTACHMENT_UPLOAD_FILE_MYSELF: string = 'User:attachment:upload::myself';
  public static readonly USER_ATTACHMENT_DELETE_FILE_ALL: string = 'User:attachment:delete::all';
  public static readonly USER_ATTACHMENT_DELETE_FILE_MYSELF: string = 'User:attachment:delete::myself';

  public static readonly USER_IMPORT_FILE: string = 'User:import::file';
  public static readonly USER_EXPORT_FILE: string = 'User:export::file';

  public static readonly APP_FILE_READ_ALL: string = 'AppFile:read::all';
  public static readonly APP_FILE_CREATE: string = 'AppFile:create';
  public static readonly APP_FILE_UPDATE_ALL: string = 'AppFile:update::all';
  public static readonly APP_FILE_UPLOAD_ALL: string = 'AppFile:upload::all';
  public static readonly APP_FILE_DELETE_ALL: string = 'AppFile:delete::all';

  public static readonly AUDIT_LOG_READ: string = 'AuditLog:read';

  public static readonly AUTH_LOGIN_USER_BASIC: string = 'Auth:login:user:basic';
  public static readonly AUTH_LOGIN_LDAP_BASIC: string = 'Auth:login:ldap:basic';
  public static readonly AUTH_LOGIN_USER_MOBILE: string = 'Auth:login:user:mobile';
  public static readonly AUTH_LOGIN_LDAP_MOBILE: string = 'Auth:login:ldap:mobile';
  public static readonly AUTH_OVERRIDE_CURRENT_USER: string = 'Auth:override:current_user';

  public static readonly MOBILE_APP_READ: string = 'MobileApp:read';
  public static readonly MOBILE_APP_CHANGE_NAME: string = 'MobileApp:change:name';
  public static readonly MOBILE_APP_DISABLE: string = 'MobileApp:disable';

  public static readonly USER_EVENT_CREATE: string = 'UserEvent:create';

  public static readonly USER_GROUP_READ: string = 'UserGroup:read';
  public static readonly USER_GROUP_READ_ALL: string = 'UserGroup:read::all';
  public static readonly USER_GROUP_READ_MY_COMPANY: string = 'UserGroup:read::my_company';
  public static readonly USER_GROUP_READ_MINE: string = 'UserGroup:read::mine';
  public static readonly USER_GROUP_CREATE: string = 'UserGroup:create';
  public static readonly USER_GROUP_CREATE_ALL: string = 'UserGroup:create::all';
  public static readonly USER_GROUP_CREATE_MY_COMPANY: string = 'UserGroup:create::my_company';
  public static readonly USER_GROUP_UPDATE: string = 'UserGroup:update';
  public static readonly USER_GROUP_UPDATE_ALL: string = 'UserGroup:update::all';
  public static readonly USER_GROUP_UPDATE_MY_COMPANY: string = 'UserGroup:update::my_company';
  public static readonly USER_GROUP_DISABLE: string = 'UserGroup:disable';
  public static readonly USER_GROUP_DISABLE_ALL: string = 'UserGroup:disable::all';
  public static readonly USER_GROUP_DISABLE_MY_COMPANY: string = 'UserGroup:disable::my_company';
  public static readonly USER_GROUP_DELETE: string = 'UserGroup:delete';
  public static readonly USER_GROUP_DELETE_ALL: string = 'UserGroup:delete::all';
  public static readonly USER_GROUP_DELETE_MY_COMPANY: string = 'UserGroup:delete::my_company';
  public static readonly USER_GROUP_HISTORY_LOG_READ: string = 'UserGroup:HistoryLog:read';
  public static readonly USER_GROUP_LDAP_CHANGE_USER_GROUP: string = 'UserGroupLdap:change:userGroup';

  public static readonly USER_GROUP_LDAP_SYNC_ALL = 'UserGroup:Ldap:sync::all';
  public static readonly USER_GROUP_LDAP_READ_ALL = 'UserGroup:Ldap:read::all';
  public static readonly USER_GROUP_LDAP_MAP_READ_ALL = 'UserGroup:Ldap:Map:read::all';
  public static readonly USER_GROUP_LDAP_MAP_UPDATE_ALL = 'UserGroup:Ldap:Map:update::all';

  public static readonly USER_LDAP_READ_ALL: string = 'UserLdap:read::all';
  public static readonly USER_LDAP_READ_MYSELF: string = 'UserLdap:read::myself';
  public static readonly USER_LDAP_DISABLE: string = 'UserLdap:disable';
  public static readonly USER_LDAP_IMPORT: string = 'UserLdap:import';

  // Document
  public static readonly DOCUMENT_FILE_READ_ALL: string = 'Document:File:read::all';
  public static readonly DOCUMENT_FILE_READ_MINE: string = 'Document:File:read::mine';
  public static readonly DOCUMENT_FILE_UPDATE_ALL: string = 'Document:File:update::all';
  public static readonly DOCUMENT_FILE_UPDATE_MINE: string = 'Document:File:update::mine';
  public static readonly DOCUMENT_FILE_DISABLE_ALL: string = 'Document:File:disable::all';
  public static readonly DOCUMENT_FILE_DISABLE_MINE: string = 'Document:File:disable::mine';
  public static readonly DOCUMENT_FILE_FILE_DOWNLOAD_ALL: string = 'Document:File:file:download::all';
  public static readonly DOCUMENT_FILE_FILE_DOWNLOAD_MINE: string = 'Document:File:file:download::mine';
  public static readonly DOCUMENT_FILE_FILE_CREATE: string = 'Document:File:file:create';
  public static readonly DOCUMENT_FILE_FILE_UPDATE_ALL: string = 'Document:File:file:update::all';
  public static readonly DOCUMENT_FILE_FILE_UPDATE_MINE: string = 'Document:File:file:update::mine';
  public static readonly DOCUMENT_FILE_THUMBNAIL_DOWNLOAD_ALL: string = 'Document:File:thumbnail:download::all';
  public static readonly DOCUMENT_FILE_THUMBNAIL_DOWNLOAD_MINE: string = 'Document:File:thumbnail:download::mine';
  public static readonly DOCUMENT_FILE_THUMBNAIL_UPDATE_ALL: string = 'Document:File:thumbnail:update::all';
  public static readonly DOCUMENT_FILE_THUMBNAIL_UPDATE_MINE: string = 'Document:File:thumbnail:update::mine';

  public static readonly DOCUMENT_HTML_READ_ALL: string = 'Document:Html:read::all';
  public static readonly DOCUMENT_HTML_READ_MINE: string = 'Document:Html:read::mine';
  public static readonly DOCUMENT_HTML_CREATE: string = 'Document:Html:create';
  public static readonly DOCUMENT_HTML_DISABLE: string = 'Document:Html:disable';
  public static readonly DOCUMENT_HTML_UPDATE_ALL: string = 'Document:Html:update::all';
  public static readonly DOCUMENT_HTML_UPDATE_MINE: string = 'Document:Html:update::mine';

  public static readonly DOCUMENT_TEXT_READ_ALL: string = 'Document:Text:read::all';
  public static readonly DOCUMENT_TEXT_READ_MINE: string = 'Document:Text:read::mine';
  public static readonly DOCUMENT_TEXT_CREATE: string = 'Document:Text:create';
  public static readonly DOCUMENT_TEXT_DISABLE: string = 'Document:Text:disable';
  public static readonly DOCUMENT_TEXT_UPDATE_ALL: string = 'Document:Text:update::all';
  public static readonly DOCUMENT_TEXT_UPDATE_MINE: string = 'Document:Text:update::mine';

  public static readonly DOCUMENT_LINK_READ_ALL: string = 'Document:Link:read::all';
  public static readonly DOCUMENT_LINK_READ_MINE: string = 'Document:Link:read::mine';
  public static readonly DOCUMENT_LINK_CREATE: string = 'Document:Link:create';
  public static readonly DOCUMENT_LINK_DISABLE: string = 'Document:Link:disable';
  public static readonly DOCUMENT_LINK_UPDATE_ALL: string = 'Document:Link:update::all';
  public static readonly DOCUMENT_LINK_UPDATE_MINE: string = 'Document:Link:update::mine';

  // Document group
  public static readonly DOCUMENT_GROUP_READ_ALL: string = 'DocumentGroup:read::all';
  public static readonly DOCUMENT_GROUP_READ_MINE: string = 'DocumentGroup:read::mine';
  public static readonly DOCUMENT_GROUP_CREATE: string = 'DocumentGroup:create';
  public static readonly DOCUMENT_GROUP_UPDATE: string = 'DocumentGroup:update';
  public static readonly DOCUMENT_GROUP_DISABLE: string = 'DocumentGroup:disable';

  public static readonly DOCUMENT_LANGUAGE_READ: string = 'Document:Language:read';

  // MasterData
  public static readonly MASTER_DATA_READ_ALL: string = 'MasterData:read::all';
  public static readonly MASTER_DATA_READ_MINE: string = 'MasterData:read::mine';
  public static readonly MASTER_DATA_CREATE: string = 'MasterData:create';
  public static readonly MASTER_DATA_UPDATE_ALL: string = 'MasterData:update::all';
  public static readonly MASTER_DATA_UPDATE_MINE: string = 'MasterData:update::mine';
  public static readonly MASTER_DATA_DISABLE_ALL: string = 'MasterData:disable::all';
  public static readonly MASTER_DATA_DISABLE_MINE: string = 'MasterData:disable::mine';

  // MasterData-Form
  public static readonly MASTER_DATA_FORM_READ: string = 'MasterData:Form:read';
  public static readonly MASTER_DATA_FORM_GROUP_READ: string = 'MasterData:Form:Group:read';
  public static readonly MASTER_DATA_FORM_FIELD_READ: string = 'MasterData:Form:Field:read';
  public static readonly MASTER_DATA_FORM_GROUP_UPDATE: string = 'MasterData:Form:Group:update';
  public static readonly MASTER_DATA_FORM_FIELD_UPDATE: string = 'MasterData:Form:Field:update';
  public static readonly MASTER_DATA_FORM_GROUP_CREATE: string = 'MasterData:Form:Group:create';
  public static readonly MASTER_DATA_FORM_FIELD_CREATE: string = 'MasterData:Form:Field:create';
  public static readonly MASTER_DATA_FORM_GROUP_DISABLE: string = 'MasterData:Form:Group:disable';
  public static readonly MASTER_DATA_FORM_FIELD_DISABLE: string = 'MasterData:Form:Field:disable';
  public static readonly MASTER_DATA_FORM_GROUP_MOVE: string = 'MasterData:Form:Group:move';
  public static readonly MASTER_DATA_FORM_FIELD_MOVE: string = 'MasterData:Form:Field:move';

  // MasterDataRecord
  public static readonly MASTER_DATA_RECORD_READ_ALL: string = 'MasterDataRecord:read::all';
  public static readonly MASTER_DATA_RECORD_CREATE_ALL: string = 'MasterDataRecord:create::all';
  public static readonly MASTER_DATA_RECORD_UPDATE_ALL: string = 'MasterDataRecord:update::all';
  public static readonly MASTER_DATA_RECORD_DISABLE_ALL: string = 'MasterDataRecord:disable::all';
  public static readonly MASTER_DATA_RECORD_READ_MASTER_DATA_USER_GROUP_RELATED: string
    = 'MasterDataRecord:read::master_data_user_group_related';
  public static readonly MASTER_DATA_RECORD_CREATE_MASTER_DATA_USER_GROUP_RELATED: string
    = 'MasterDataRecord:create::master_data_user_group_related';
  public static readonly MASTER_DATA_RECORD_UPDATE_MASTER_DATA_USER_GROUP_RELATED: string
    = 'MasterDataRecord:update::master_data_user_group_related';
  public static readonly MASTER_DATA_RECORD_DISABLE_MASTER_DATA_USER_GROUP_RELATED: string
    = 'MasterDataRecord:disable::master_data_user_group_related';
  public static readonly MASTER_DATA_RECORD_READ_MY_CONTACT_LOCATION: string = 'MasterDataRecord:read::my_contact_location';
  public static readonly MASTER_DATA_RECORD_READ_MINE: string = 'MasterDataRecord:read::mine';
  public static readonly MASTER_DATA_RECORD_UPDATE_MINE: string = 'MasterDataRecord:update::mine';
  public static readonly MASTER_DATA_RECORD_DISABLE_MINE: string = 'MasterDataRecord:disable::mine';
  public static readonly MASTER_DATA_RECORD_IMPORT_FILE: string = 'MasterDataRecord:import:file';
  public static readonly MASTER_DATA_RECORD_EXPORT_FILE: string = 'MasterDataRecord:export:file';

  // MasterDataRecord Attachment
  public static readonly MASTER_DATA_RECORD_ATTACHMENT_READ_ALL = 'MasterDataRecord:attachment:read::all';
  public static readonly MASTER_DATA_RECORD_ATTACHMENT_READ_MINE = 'MasterDataRecord:attachment:read::mine';
  public static readonly MASTER_DATA_RECORD_ATTACHMENT_UPLOAD = 'MasterDataRecord:attachment:upload';
  public static readonly MASTER_DATA_RECORD_ATTACHMENT_DELETE_ALL = 'MasterDataRecord:attachment:delete::all';
  public static readonly MASTER_DATA_RECORD_ATTACHMENT_DELETE_MINE = 'MasterDataRecord:attachment:delete::mine';
  public static readonly MASTER_DATA_RECORD_ATTACHMENT_UPDATE_ALL = 'MasterDataRecord:attachment:update::all';
  public static readonly MASTER_DATA_RECORD_ATTACHMENT_UPDATE_MINE = 'MasterDataRecord:attachment:update::mine';

  // Survey
  public static readonly SURVEY_READ: string = 'Survey:read';
  public static readonly SURVEY_CREATE: string = 'Survey:create';
  public static readonly SURVEY_UPDATE: string = 'Survey:update';
  public static readonly SURVEY_DISABLE: string = 'Survey:disable';

  public static readonly SURVEY_ATTACHMENT_DOWNLOAD_FILE: string = 'Survey:attachment:download';
  public static readonly SURVEY_ATTACHMENT_UPLOAD_FILE: string = 'Survey:attachment:upload';
  public static readonly SURVEY_ATTACHMENT_DELETE_FILE: string = 'Survey:attachment:delete';

  // Survey-Form
  public static readonly SURVEY_FORM_READ: string = 'Survey:Form:read';
  public static readonly SURVEY_FORM_GROUP_READ: string = 'Survey:Form:Group:read';
  public static readonly SURVEY_FORM_FIELD_READ: string = 'Survey:Form:Field:read';
  public static readonly SURVEY_FORM_GROUP_UPDATE: string = 'Survey:Form:Group:update';
  public static readonly SURVEY_FORM_FIELD_UPDATE: string = 'Survey:Form:Field:update';
  public static readonly SURVEY_FORM_GROUP_CREATE: string = 'Survey:Form:Group:create';
  public static readonly SURVEY_FORM_FIELD_CREATE: string = 'Survey:Form:Field:create';
  public static readonly SURVEY_FORM_GROUP_DISABLE: string = 'Survey:Form:Group:disable';
  public static readonly SURVEY_FORM_FIELD_DISABLE: string = 'Survey:Form:Field:disable';
  public static readonly SURVEY_FORM_GROUP_MOVE: string = 'Survey:Form:Group:move';
  public static readonly SURVEY_FORM_FIELD_MOVE: string = 'Survey:Form:Field:move';

  // FormTable
  public static readonly FORM_TABLE_READ: string = 'FormTable:read';
  public static readonly FORM_TABLE_CREATE: string = 'FormTable:create';
  public static readonly FORM_TABLE_UPDATE: string = 'FormTable:update';
  public static readonly FORM_TABLE_DISABLE: string = 'FormTable:disable';

  // FormTable-Form
  public static readonly FORM_TABLE_FORM_READ: string = 'FormTable:Form:read';
  public static readonly FORM_TABLE_FORM_GROUP_READ: string = 'FormTable:Form:Group:read';
  public static readonly FORM_TABLE_FORM_FIELD_READ: string = 'FormTable:Form:Field:read';
  public static readonly FORM_TABLE_FORM_GROUP_UPDATE: string = 'FormTable:Form:Group:update';
  public static readonly FORM_TABLE_FORM_FIELD_UPDATE: string = 'FormTable:Form:Field:update';
  public static readonly FORM_TABLE_FORM_GROUP_CREATE: string = 'FormTable:Form:Group:create';
  public static readonly FORM_TABLE_FORM_FIELD_CREATE: string = 'FormTable:Form:Field:create';
  public static readonly FORM_TABLE_FORM_GROUP_DISABLE: string = 'FormTable:Form:Group:disable';
  public static readonly FORM_TABLE_FORM_FIELD_DISABLE: string = 'FormTable:Form:Field:disable';
  public static readonly FORM_TABLE_FORM_GROUP_MOVE: string = 'FormTable:Form:Group:move';
  public static readonly FORM_TABLE_FORM_FIELD_MOVE: string = 'FormTable:Form:Field:move';

  // SurveyRecord
  public static readonly SURVEY_RECORD_READ_ALL: string = 'SurveyRecord:read::all';
  public static readonly SURVEY_RECORD_READ_MINE: string = 'SurveyRecord:read::mine';
  public static readonly SURVEY_RECORD_CREATE: string = 'SurveyRecord:create';
  public static readonly SURVEY_RECORD_UPDATE_ALL: string = 'SurveyRecord:update::all';
  public static readonly SURVEY_RECORD_UPDATE_MINE: string = 'SurveyRecord:update::mine';
  public static readonly SURVEY_RECORD_DISABLE_ALL: string = 'SurveyRecord:disable::all';
  public static readonly SURVEY_RECORD_DISABLE_MINE: string = 'SurveyRecord:disable::mine';

  public static readonly SURVEY_RECORD_EXPORT_ALL: string = 'SurveyRecord:export::all';
  public static readonly SURVEY_RECORD_EXPORT_MINE: string = 'SurveyRecord:export::mine';

  // Stock
  public static readonly STOCK_READ_ALL: string = 'Stock:read::all';
  public static readonly STOCK_READ_MINE: string = 'Stock:read::mine';
  public static readonly STOCK_READ_MY_COMPANY: string = 'Stock:read::my_company';
  public static readonly STOCK_READ_CENTRAL = 'Stock:read::central';
  public static readonly STOCK_CREATE: string = 'Stock:create';
  public static readonly STOCK_UPDATE_ALL: string = 'Stock:update::all';
  public static readonly STOCK_UPDATE_MINE: string = 'Stock:update::mine';
  public static readonly STOCK_DISABLE: string = 'Stock:disable';

  // StockItem
  public static readonly STOCK_ITEM_READ_ALL: string = 'StockItem:read::all';
  public static readonly STOCK_ITEM_READ_MY_COMPANY: string = 'StockItem:read::my_company';
  public static readonly STOCK_ITEM_READ_MY_STOCK_COMPANY: string = 'StockItem:read::my_stock_company';
  public static readonly STOCK_ITEM_CREATE: string = 'StockItem:create';
  public static readonly STOCK_ITEM_UPDATE: string = 'StockItem:update';
  public static readonly STOCK_ITEM_DISABLE_ONE: string = 'StockItem:disable::one';
  public static readonly STOCK_ITEM_DISABLE_BULK: string = 'StockItem:disable::bulk';
  public static readonly STOCK_ITEM_MOVE_ALL: string = 'StockItem:move::all';
  public static readonly STOCK_ITEM_DISPOSE_ALL: string = 'StockItem:dispose::all';
  public static readonly STOCK_ITEM_IMPORT: string = 'StockItem:import:file';
  public static readonly STOCK_ITEM_EXPORT: string = 'StockItem:export:file';

  // StockRecord
  public static readonly STOCK_RECORD_READ_ALL: string = 'StockRecord:read::all';
  public static readonly STOCK_RECORD_READ_MINE: string = 'StockRecord:read::mine';
  public static readonly STOCK_RECORD_READ_MY_COMPANY: string = 'StockRecord:read::my_company';
  public static readonly STOCK_RECORD_READ_CENTRAL = 'StockRecord:read::central';
  public static readonly STOCK_RECORD_CREATE_ALL: string = 'StockRecord:create::all';
  public static readonly STOCK_RECORD_CREATE_MINE: string = 'StockRecord:create::mine';
  public static readonly STOCK_RECORD_UPDATE_ALL: string = 'StockRecord:update::all';
  public static readonly STOCK_RECORD_UPDATE_MINE: string = 'StockRecord:update::mine';
  public static readonly STOCK_RECORD_IMPORT: string = 'StockRecord:import';

  // StockRecordLog
  public static readonly STOCK_RECORD_LOG_READ_ALL: string = 'StockRecordLog:read::all';
  public static readonly STOCK_RECORD_LOG_READ_MINE: string = 'StockRecordLog:read::mine';
  public static readonly STOCK_RECORD_LOG_READ_MY_COMPANY: string = 'StockRecordLog:read::my_company';

  // ListItem
  public static readonly LIST_ITEM_READ: string = 'ListItem:read';
  public static readonly LIST_ITEM_CREATE: string = 'ListItem:create';
  public static readonly LIST_ITEM_UPDATE: string = 'ListItem:update';
  public static readonly LIST_ITEM_DISABLE: string = 'ListItem:disable';
  public static readonly LIST_ITEM_IMPORT_XLS = 'ListItem:import::xls';

  // ListItemType
  public static readonly LIST_ITEM_TYPE_READ: string = 'ListItemType:read';
  public static readonly LIST_ITEM_TYPE_CREATE: string = 'ListItemType:create';
  public static readonly LIST_ITEM_TYPE_UPDATE: string = 'ListItemType:update';
  public static readonly LIST_ITEM_TYPE_DISABLE: string = 'ListItemType:disable';

  // Message
  public static readonly MESSAGE_READ_MINE: string = 'Message:read::mine';
  public static readonly MESSAGE_CHANGE_READ_MINE: string = 'Message:change:read::mine';
  public static readonly MESSAGE_DISABLE_MINE: string = 'Message:disable::mine';
  public static readonly MESSAGE_DELETE_MINE: string = 'Message:delete::mine';

  // MessageParent
  public static readonly MESSAGE_PARENT_READ: string = 'MessageParent:read';
  public static readonly MESSAGE_PARENT_CREATE: string = 'MessageParent:create';
  public static readonly MESSAGE_PARENT_DISABLE: string = 'MessageParent:disable';
  public static readonly MESSAGE_PARENT_DELETE: string = 'MessageParent:delete';

  // Project record
  public static readonly PROJECT_RECORD_READ_ALL: string = 'ProjectRecord:read::all';
  public static readonly PROJECT_RECORD_READ_PROJECT_USER_GROUP_RELATED: string = 'ProjectRecord:read::project_user_group_related';
  public static readonly PROJECT_RECORD_CREATE_ALL: string = 'ProjectRecord:create::all';
  public static readonly PROJECT_RECORD_CREATE_PROJECT_USER_GROUP_RELATED: string = 'ProjectRecord:create::project_user_group_related';
  public static readonly PROJECT_RECORD_UPDATE_ALL: string = 'ProjectRecord:update::all';
  public static readonly PROJECT_RECORD_UPDATE_PROJECT_USER_GROUP_RELATED: string = 'ProjectRecord:update::project_user_group_related';
  public static readonly PROJECT_RECORD_DISABLE_ALL: string = 'ProjectRecord:disable::all';
  public static readonly PROJECT_RECORD_DISABLE_PROJECT_USER_GROUP_RELATED: string = 'ProjectRecord:disable::project_user_group_related';
  public static readonly PROJECT_RECORD_EXPORT_ALL: string = 'ProjectRecord:export::all';
  public static readonly PROJECT_RECORD_EXPORT_PROJECT_USER_GROUP_RELATED: string = 'ProjectRecord:export::project_user_group_related';
  public static readonly PROJECT_RECORD_IMPORT_ALL: string = 'ProjectRecord:import::all';
  public static readonly PROJECT_RECORD_IMPORT_PROJECT_USER_GROUP_RELATED: string = 'ProjectRecord:import::project_user_group_related';
  public static readonly PROJECT_RECORD_ATTACHMENT_READ_ALL: string = 'ProjectRecord:attachment:read::all';
  public static readonly PROJECT_RECORD_ATTACHMENT_READ_PROJECT_USER_GROUP_RELATED: string = 'ProjectRecord:attachment:read::project_user_group_related';
  public static readonly PROJECT_RECORD_ATTACHMENT_DOWNLOAD_FILE_ALL: string = 'ProjectRecord:attachment:download::all';
  public static readonly PROJECT_RECORD_ATTACHMENT_DOWNLOAD_FILE_PROJECT_USER_GROUP_RELATED: string = 'ProjectRecord:attachment:download::project_user_group_related';
  public static readonly PROJECT_RECORD_ATTACHMENT_UPLOAD_FILE_ALL: string = 'ProjectRecord:attachment:upload::all';
  public static readonly PROJECT_RECORD_ATTACHMENT_UPLOAD_FILE_PROJECT_USER_GROUP_RELATED: string = 'ProjectRecord:attachment:upload::project_user_group_related';
  public static readonly PROJECT_RECORD_ATTACHMENT_DELETE_ALL: string = 'ProjectRecord:attachment:delete::all';
  public static readonly PROJECT_RECORD_ATTACHMENT_DELETE_PROJECT_USER_GROUP_RELATED: string = 'ProjectRecord:attachment:delete::project_user_group_related';

  // Project
  public static readonly PROJECT_READ_ALL: string = 'Project:read::all';
  public static readonly PROJECT_READ_PROJECT_USER_GROUP_RELATED: string = 'Project:read::project_user_group_related';
  public static readonly PROJECT_CREATE: string = 'Project:create';
  public static readonly PROJECT_UPDATE: string = 'Project:update';
  public static readonly PROJECT_DISABLE: string = 'Project:disable';

  public static readonly PROJECT_FORM_READ: string = 'Project:Form:read';
  public static readonly PROJECT_FORM_CREATE: string = 'Project:Form:create';
  public static readonly PROJECT_FORM_UPDATE: string = 'Project:Form:update';
  public static readonly PROJECT_FORM_DISABLE: string = 'Project:Form:disable';
  public static readonly PROJECT_FORM_IMPORT: string = 'Project:Form:update';
  public static readonly PROJECT_FORM_EXPORT: string = 'Project:Form:disable';

  // Customer
  public static readonly CUSTOMER_CREATE: string = 'Customer:create';
  public static readonly CUSTOMER_READ_ALL: string = 'Customer:read::all';
  public static readonly CUSTOMER_UPDATE_ALL: string = 'Customer:update::all';
  public static readonly CUSTOMER_DISABLE_ALL: string = 'Customer:disable::all';
  public static readonly CUSTOMER_READ_MINE: string = 'Customer:read::mine';
  public static readonly CUSTOMER_UPDATE_MINE: string = 'Customer:update::mine';
  public static readonly CUSTOMER_DISABLE_MINE: string = 'Customer:disable::mine';

  // Customer-Form
  public static readonly CUSTOMER_FORM_READ: string = 'Customer:Form:read';
  public static readonly CUSTOMER_FORM_GROUP_READ: string = 'Customer:Form:Group:read';
  public static readonly CUSTOMER_FORM_FIELD_READ: string = 'Customer:Form:Field:read';
  public static readonly CUSTOMER_FORM_GROUP_UPDATE: string = 'Customer:Form:Group:update';
  public static readonly CUSTOMER_FORM_FIELD_UPDATE: string = 'Customer:Form:Field:update';
  public static readonly CUSTOMER_FORM_GROUP_CREATE: string = 'Customer:Form:Group:create';
  public static readonly CUSTOMER_FORM_FIELD_CREATE: string = 'Customer:Form:Field:create';
  public static readonly CUSTOMER_FORM_GROUP_DISABLE: string = 'Customer:Form:Group:disable';
  public static readonly CUSTOMER_FORM_FIELD_DISABLE: string = 'Customer:Form:Field:disable';
  public static readonly CUSTOMER_FORM_GROUP_MOVE: string = 'Customer:Form:Group:move';
  public static readonly CUSTOMER_FORM_FIELD_MOVE: string = 'Customer:Form:Field:move';

  // CustomerRecord
  public static readonly CUSTOMER_RECORD_IMPORT_FILE: string = 'CustomerRecord:import:file';
  public static readonly CUSTOMER_RECORD_EXPORT_FILE: string = 'CustomerRecord:export:file';

  public static readonly CUSTOMER_RECORD_READ_ALL: string = 'CustomerRecord:read::all';
  public static readonly CUSTOMER_RECORD_READ_MINE: string = 'CustomerRecord:read::mine';
  public static readonly CUSTOMER_RECORD_READ_MY_CONTACT_LOCATION: string = 'CustomerRecord:read::my_contact_location';
  public static readonly CUSTOMER_RECORD_READ_CUSTOMER_USER_GROUP_RELATED: string = 'CustomerRecord:read::customer_user_group_related';
  public static readonly CUSTOMER_RECORD_READ_TASK_RECORD_RELATED = 'CustomerRecord:read::task_record_related';
  public static readonly CUSTOMER_RECORD_CREATE_ALL: string = 'CustomerRecord:create::all';
  public static readonly CUSTOMER_RECORD_CREATE_CUSTOMER_USER_GROUP_RELATED: string = 'CustomerRecord:create::customer_user_group_related';
  public static readonly CUSTOMER_RECORD_UPDATE_ALL: string = 'CustomerRecord:update::all';
  public static readonly CUSTOMER_RECORD_UPDATE_MINE: string = 'CustomerRecord:update::mine';
  public static readonly CUSTOMER_RECORD_UPDATE_CUSTOMER_USER_GROUP_RELATED: string = 'CustomerRecord:update::customer_user_group_related';
  public static readonly CUSTOMER_RECORD_UPDATE_TASK_RECORD_RELATED = 'CustomerRecord:update::task_record_related';
  public static readonly CUSTOMER_RECORD_DISABLE_ALL: string = 'CustomerRecord:disable::all';
  public static readonly CUSTOMER_RECORD_DISABLE_MINE: string = 'CustomerRecord:disable::mine';
  public static readonly CUSTOMER_RECORD_DISABLE_CUSTOMER_USER_GROUP_RELATED: string
    = 'CustomerRecord:disable::customer_user_group_related';
  public static readonly CUSTOMER_RECORD_DISABLE_TASK_RECORD_RELATED = 'CustomerRecord:disable::task_record_related';


  public static readonly CUSTOMER_RECORD_CHANGE_OWNER: string = 'CustomerRecord:change:owner';

  public static readonly CUSTOMER_RECORD_HISTORY_LOG_READ: string = 'CustomerRecord:HistoryLog:read';

  public static readonly CUSTOMER_RECORD_ATTACHMENT_READ_ALL = 'CustomerRecord:attachment:read::all';
  public static readonly CUSTOMER_RECORD_ATTACHMENT_READ_MINE = 'CustomerRecord:attachment:read::mine';
  public static readonly CUSTOMER_RECORD_ATTACHMENT_READ_MY_COMPANY = 'CustomerRecord:attachment:read::my_company';
  public static readonly CUSTOMER_RECORD_ATTACHMENT_READ_CUSTOMER_USER_GROUP_RELATED =
    'CustomerRecord:attachment:read::customer_user_group_related';
  public static readonly CUSTOMER_RECORD_ATTACHMENT_READ_TASK_RECORD_RELATED = 'CustomerRecord:attachment:read::task_record_related';
  public static readonly CUSTOMER_RECORD_ATTACHMENT_UPLOAD_FILE_ALL = 'CustomerRecord:attachment:upload::all';
  public static readonly CUSTOMER_RECORD_ATTACHMENT_UPLOAD_FILE_MINE = 'CustomerRecord:attachment:upload::mine';
  public static readonly CUSTOMER_RECORD_ATTACHMENT_UPLOAD_FILE_MY_COMPANY = 'CustomerRecord:attachment:upload::my_company';
  public static readonly CUSTOMER_RECORD_ATTACHMENT_UPLOAD_FILE_CUSTOMER_USER_GROUP_RELATED =
    'CustomerRecord:attachment:upload::customer_user_group_related';
  public static readonly CUSTOMER_RECORD_ATTACHMENT_UPLOAD_FILE_TASK_RECORD_RELATED = 'CustomerRecord:attachment:upload::task_record_related';
  public static readonly CUSTOMER_RECORD_ATTACHMENT_DELETE_FILE_ALL = 'CustomerRecord:attachment:delete::all';
  public static readonly CUSTOMER_RECORD_ATTACHMENT_DELETE_FILE_MINE = 'CustomerRecord:attachment:delete::mine';
  public static readonly CUSTOMER_RECORD_ATTACHMENT_DELETE_FILE_MY_COMPANY = 'CustomerRecord:attachment:delete::my_company';
  public static readonly CUSTOMER_RECORD_ATTACHMENT_DELETE_FILE_CUSTOMER_USER_GROUP_RELATED =
    'CustomerRecord:attachment:delete::customer_user_group_related';
  public static readonly CUSTOMER_RECORD_ATTACHMENT_DELETE_FILE_TASK_RECORD_RELATED = 'CustomerRecord:attachment:delete::task_record_related';
  public static readonly CUSTOMER_RECORD_ATTACHMENT_UPDATE_ALL = 'CustomerRecord:attachment:update::all';
  public static readonly CUSTOMER_RECORD_ATTACHMENT_UPDATE_MINE = 'CustomerRecord:attachment:update::mine';
  public static readonly CUSTOMER_RECORD_ATTACHMENT_UPDATE_MY_COMPANY = 'CustomerRecord:attachment:update::my_company';
  public static readonly CUSTOMER_RECORD_ATTACHMENT_UPDATE_CUSTOMER_USER_GROUP_RELATED =
    'CustomerRecord:attachment:update::customer_user_group_related';
  public static readonly CUSTOMER_RECORD_ATTACHMENT_UPDATE_TASK_RECORD_RELATED = 'CustomerRecord:attachment:update::task_record_related';

  // Note
  public static readonly NOTE_READ_ALL: string = 'Note:read::all';
  public static readonly NOTE_READ_MINE: string = 'Note:read::mine';
  public static readonly NOTE_CREATE: string = 'Note:create';
  public static readonly NOTE_UPDATE_ALL: string = 'Note:update::all';
  public static readonly NOTE_UPDATE_MINE: string = 'Note:update::mine';
  public static readonly NOTE_DISABLE_ALL: string = 'Note:disable::all';
  public static readonly NOTE_DISABLE_MINE: string = 'Note:disable::mine';
  public static readonly NOTE_DELETE_ALL: string = 'Note:delete::all';
  public static readonly NOTE_DELETE_MINE: string = 'Note:delete::mine';
  public static readonly NOTE_ATTACHMENT_READ_ALL: string = 'Note:attachment:read::all';
  public static readonly NOTE_ATTACHMENT_READ_MINE: string = 'Note:attachment:read::mine';
  public static readonly NOTE_ATTACHMENT_DOWNLOAD_FILE_ALL: string = 'Note:attachment:download::all';
  public static readonly NOTE_ATTACHMENT_DOWNLOAD_FILE_MINE: string = 'Note:attachment:download::mine';
  public static readonly NOTE_ATTACHMENT_UPLOAD_FILE_ALL: string = 'Note:attachment:upload::all';
  public static readonly NOTE_ATTACHMENT_UPLOAD_FILE_MINE: string = 'Note:attachment:upload::mine';
  public static readonly NOTE_ATTACHMENT_DELETE_FILE_ALL: string = 'Note:attachment:delete::all';
  public static readonly NOTE_ATTACHMENT_DELETE_FILE_MINE: string = 'Note:attachment:delete::mine';

  // Activity
  public static readonly ACTIVITY_READ_ALL: string = 'Activity:read::all';
  public static readonly ACTIVITY_READ_MINE: string = 'Activity:read::mine';
  public static readonly ACTIVITY_CREATE: string = 'Activity:create';
  public static readonly ACTIVITY_UPDATE_ALL: string = 'Activity:update::all';
  public static readonly ACTIVITY_UPDATE_MINE: string = 'Activity:update::mine';
  public static readonly ACTIVITY_DISABLE_ALL: string = 'Activity:disable::all';
  public static readonly ACTIVITY_DISABLE_MINE: string = 'Activity:disable::mine';
  public static readonly ACTIVITY_DELETE_ALL: string = 'Activity:delete::all';
  public static readonly ACTIVITY_DELETE_MINE: string = 'Activity:delete::mine';

  // Schedule
  public static readonly SCHEDULE_READ_ALL: string = 'Schedule:read::all';
  public static readonly SCHEDULE_READ_MINE: string = 'Schedule:read::mine';
  public static readonly SCHEDULE_CREATE: string = 'Schedule:create';
  public static readonly SCHEDULE_UPDATE_ALL: string = 'Schedule:update::all';
  public static readonly SCHEDULE_UPDATE_MINE: string = 'Schedule:update::mine';
  public static readonly SCHEDULE_DISABLE_ALL: string = 'Schedule:disable::all';
  public static readonly SCHEDULE_DISABLE_MINE: string = 'Schedule:disable::mine';
  public static readonly SCHEDULE_DELETE_ALL: string = 'Schedule:delete::all';
  public static readonly SCHEDULE_DELETE_MINE: string = 'Schedule:delete::mine';

  // Task
  public static readonly TASK_READ: string = 'Task:read';
  public static readonly TASK_CREATE: string = 'Task:create';
  public static readonly TASK_UPDATE: string = 'Task:update';
  public static readonly TASK_DISABLE: string = 'Task:disable';

  public static readonly TASK_ATTACHMENT_DOWNLOAD_FILE: string = 'Task:attachment:download';
  public static readonly TASK_ATTACHMENT_UPLOAD_FILE: string = 'Task:attachment:upload';
  public static readonly TASK_ATTACHMENT_DELETE_FILE: string = 'Task:attachment:delete';

  // Task-Form
  public static readonly TASK_FORM_READ_ALL: string = 'Task:Form:read';
  public static readonly TASK_FORM_CREATE: string = 'Task:Form:create';
  public static readonly TASK_FORM_UPDATE: string = 'Task:Form:update';
  public static readonly TASK_FORM_DISABLE: string = 'Task:Form:disable';

  // Task-Form TODO FIX
  public static readonly TASK_FORM_READ: string = 'Task:Form:read';
  public static readonly TASK_FORM_EXPORT: string = 'Task:Form:export';
  public static readonly TASK_FORM_IMPORT: string = 'Task:Form:import';
  public static readonly TASK_FORM_GROUP_READ: string = 'Task:Form:read';
  public static readonly TASK_FORM_FIELD_READ: string = 'Task:Form:read';
  public static readonly TASK_FORM_GROUP_UPDATE: string = 'Task:Form:update';
  public static readonly TASK_FORM_FIELD_UPDATE: string = 'Task:Form:update';
  public static readonly TASK_FORM_GROUP_CREATE: string = 'Task:Form:create';
  public static readonly TASK_FORM_FIELD_CREATE: string = 'Task:Form:create';
  public static readonly TASK_FORM_GROUP_DISABLE: string = 'Task:Form:disable';
  public static readonly TASK_FORM_FIELD_DISABLE: string = 'Task:Form:disable';
  public static readonly TASK_FORM_GROUP_MOVE: string = 'Task:Form:update';
  public static readonly TASK_FORM_FIELD_MOVE: string = 'Task:Form:update';

  // TaskRecord
  public static readonly TASK_RECORD_READ_ALL: string = 'TaskRecord:read::all';
  public static readonly TASK_RECORD_READ_MY_CONTACT_LOCATION: string = 'TaskRecord:read::my_contact_location';
  public static readonly TASK_RECORD_READ_MINE: string = 'TaskRecord:read::mine';
  public static readonly TASK_RECORD_READ_CUSTOMER_RELATED: string = 'TaskRecord:read::customer_related';
  public static readonly TASK_RECORD_READ_TASK_RECORD_USER_GROUP_RELATED: string = 'TaskRecord:read::task_record_user_group_related';
  public static readonly TASK_RECORD_READ_MY_USER_GROUP: string = 'TaskRecord:read::my_user_group';
  public static readonly TASK_RECORD_READ_CREATED_BY_ME: string = 'TaskRecord:read::created_by_me';
  public static readonly TASK_RECORD_READ_CREATED_BY_MY_USER_GROUP: string = 'TaskRecord:read::created_by_my_user_group';
  public static readonly TASK_RECORD_DOCUMENT_READ_ALL: string = 'TaskRecord:Document:read::all';
  public static readonly TASK_RECORD_DOCUMENT_READ_MY_CONTACT_LOCATION: string = 'TaskRecord:Document:read::my_contact_location';
  public static readonly TASK_RECORD_DOCUMENT_READ_MINE: string = 'TaskRecord:Document:read::mine';
  public static readonly TASK_RECORD_DOCUMENT_READ_CUSTOMER_RELATED: string = 'TaskRecord:Document:read::customer_related';
  public static readonly TASK_RECORD_DOCUMENT_READ_TASK_RECORD_USER_GROUP_RELATED: string = 'TaskRecord:Document:read::task_record_user_group_related';
  public static readonly TASK_RECORD_DOCUMENT_READ_MY_USER_GROUP: string = 'TaskRecord:Document:read::my_user_group';
  public static readonly TASK_RECORD_DOCUMENT_READ_CREATED_BY_ME: string = 'TaskRecord:Document:read::created_by_me';
  public static readonly TASK_RECORD_DOCUMENT_READ_CREATED_BY_MY_USER_GROUP: string = 'TaskRecord:Document:read::created_by_my_user_group';
  public static readonly TASK_RECORD_CREATE_ALL: string = 'TaskRecord:create::all';
  public static readonly TASK_RECORD_CREATE_TASK_USER_GROUP_RELATED: string = 'TaskRecord:create::task_user_group_related';
  public static readonly TASK_RECORD_UPDATE_ALL: string = 'TaskRecord:update::all';
  public static readonly TASK_RECORD_UPDATE_MY_CONTACT_LOCATION: string = 'TaskRecord:update::my_contact_location';
  public static readonly TASK_RECORD_UPDATE_CREATED_BY_ME: string = 'TaskRecord:update::created_by_me';
  public static readonly TASK_RECORD_UPDATE_CREATED_BY_MY_USER_GROUP: string = 'TaskRecord:update::created_by_my_user_group';
  public static readonly TASK_RECORD_UPDATE_MINE: string = 'TaskRecord:update::mine';
  public static readonly TASK_RECORD_UPDATE_QUICK_ALL: string = 'TaskRecord:update:quick::all';
  public static readonly TASK_RECORD_UPDATE_QUICK_MY_CONTACT_LOCATION: string = 'TaskRecord:update:quick::my_contact_location';
  public static readonly TASK_RECORD_UPDATE_QUICK_CREATED_BY_ME: string = 'TaskRecord:update:quick::created_by_me';
  public static readonly TASK_RECORD_UPDATE_QUICK_CREATED_BY_MY_USER_GROUP: string = 'TaskRecord:update:quick::created_by_my_user_group';
  public static readonly TASK_RECORD_UPDATE_QUICK_MINE: string = 'TaskRecord:update:quick::mine';
  public static readonly TASK_RECORD_CHANGE_DEADLINE_ALL: string = 'TaskRecord:change:deadline::all';
  public static readonly TASK_RECORD_CHANGE_DEADLINE_MINE: string = 'TaskRecord:change:deadline::mine';
  public static readonly TASK_RECORD_CHANGE_AGREED_TIME_ALL: string = 'TaskRecord:change:agreed_time::all';
  public static readonly TASK_RECORD_CHANGE_AGREED_TIME_MINE: string = 'TaskRecord:change:agreed_time::mine';
  public static readonly TASK_RECORD_CHANGE_ASSIGNEE_ALL: string = 'TaskRecord:change:assignee::all';
  public static readonly TASK_RECORD_CHANGE_ASSIGNEE_MINE: string = 'TaskRecord:change:assignee::mine';
  public static readonly TASK_RECORD_CHANGE_ASSIGNEE_IN_PROGRESS_ALL: string = 'TaskRecord:change:in_progress:assignee::all';
  public static readonly TASK_RECORD_CHANGE_ASSIGNEE_IN_PROGRESS_MINE: string = 'TaskRecord:change:in_progress:assignee::mine';
  public static readonly TASK_RECORD_CHANGE_IMPORTANCE_ALL: string = 'TaskRecord:change:importance::all';
  public static readonly TASK_RECORD_CHANGE_IMPORTANCE_MINE: string = 'TaskRecord:change:importance::mine';
  public static readonly TASK_RECORD_CHANGE_CONFIRM_ALL: string = 'TaskRecord:change:confirm::all';
  public static readonly TASK_RECORD_CHANGE_CONFIRM_MINE: string = 'TaskRecord:change:confirm::mine';
  public static readonly TASK_RECORD_GEOCODE: string = 'TaskRecord:geocode';
  public static readonly TASK_RECORD_IMPORT_FILE: string = 'TaskRecord:import:file';
  public static readonly TASK_RECORD_EXPORT_FILE: string = 'TaskRecord:export:file';
  public static readonly TASK_RECORD_EXPORT_FILE_CUSTOM: string = 'TaskRecord:export:custom_file';
  public static readonly TASK_RECORD_EXPORT_API: string = 'TaskRecord:export:api';
  public static readonly TASK_RECORD_REVERT: string = 'TaskRecord:revert';

  // TaskRecord-attachment
  public static readonly TASK_RECORD_ATTACHMENT_READ_LIST_ALL: string = 'TaskRecord:attachment:read::all';
  public static readonly TASK_RECORD_ATTACHMENT_READ_LIST_MY_CONTACT_LOCATION: string = 'TaskRecord:attachment:read::my_contact_location';
  public static readonly TASK_RECORD_ATTACHMENT_READ_LIST_CREATED_BY_ME: string = 'TaskRecord:attachment:read::created_by_me';
  public static readonly TASK_RECORD_ATTACHMENT_READ_LIST_CREATED_BY_MY_USER_GROUP: string = 'TaskRecord:attachment:read::created_by_my_user_group';
  public static readonly TASK_RECORD_ATTACHMENT_READ_LIST_MINE: string = 'TaskRecord:attachment:read::mine';
  public static readonly TASK_RECORD_ATTACHMENT_READ_LIST_CUSTOMER_RELATED: string = 'TaskRecord:attachment:read::customer_related';
  public static readonly TASK_RECORD_ATTACHMENT_UPLOAD_FILE_ALL: string = 'TaskRecord:attachment:upload::all';
  public static readonly TASK_RECORD_ATTACHMENT_UPLOAD_FILE_MINE: string = 'TaskRecord:attachment:upload::mine';
  public static readonly TASK_RECORD_ATTACHMENT_UPDATE_FILE_ALL: string = 'TaskRecord:attachment:update::all';
  public static readonly TASK_RECORD_ATTACHMENT_UPDATE_FILE_MINE: string = 'TaskRecord:attachment:update::mine';
  public static readonly TASK_RECORD_ATTACHMENT_DELETE_FILE_ALL: string = 'TaskRecord:attachment:delete::all';
  public static readonly TASK_RECORD_ATTACHMENT_DELETE_FILE_MINE: string = 'TaskRecord:attachment:delete::mine';

  // TaskRecord-state
  public static readonly TASK_RECORD_CHANGE_STATE_OPEN_ALL: string = 'TaskRecord:change:state:open::all';
  public static readonly TASK_RECORD_CHANGE_STATE_OPEN_MINE: string = 'TaskRecord:change:state:open::mine';
  public static readonly TASK_RECORD_CHANGE_STATE_OPEN_MY_CONTACT_LOCATION: string = 'TaskRecord:change:state:open::my_contact_location';
  public static readonly TASK_RECORD_CHANGE_STATE_OPEN_CREATED_BY_ME: string = 'TaskRecord:change:state:open::created_by_me';
  public static readonly TASK_RECORD_CHANGE_STATE_OPEN_CREATED_BY_MY_USER_GROUP: string = 'TaskRecord:change:state:open::created_by_my_user_group';
  public static readonly TASK_RECORD_CHANGE_STATE_REJECT_ALL: string = 'TaskRecord:change:state:reject::all';
  public static readonly TASK_RECORD_CHANGE_STATE_REJECT_MINE: string = 'TaskRecord:change:state:reject::mine';
  public static readonly TASK_RECORD_CHANGE_STATE_REJECT_MY_CONTACT_LOCATION: string = 'TaskRecord:change:state:reject::my_contact_location';
  public static readonly TASK_RECORD_CHANGE_STATE_REJECT_CREATED_BY_ME: string = 'TaskRecord:change:state:reject::created_by_me';
  public static readonly TASK_RECORD_CHANGE_STATE_REJECT_CREATED_BY_MY_USER_GROUP: string = 'TaskRecord:change:state:reject::created_by_my_user_group';
  public static readonly TASK_RECORD_CHANGE_STATE_SUBMIT_ALL: string = 'TaskRecord:change:state:submit::all';
  public static readonly TASK_RECORD_CHANGE_STATE_SUBMIT_MY_CONTACT_LOCATION: string = 'TaskRecord:change:state:submit::my_contact_location';
  public static readonly TASK_RECORD_CHANGE_STATE_SUBMIT_CREATED_BY_ME: string = 'TaskRecord:change:state:submit::created_by_me';
  public static readonly TASK_RECORD_CHANGE_STATE_SUBMIT_CREATED_BY_MY_USER_GROUP: string = 'TaskRecord:change:state:submit::created_by_my_user_group';
  public static readonly TASK_RECORD_CHANGE_STATE_SUBMIT_MINE: string = 'TaskRecord:change:state:submit::mine';
  public static readonly TASK_RECORD_CHANGE_STATE_RESTART_ALL: string = 'TaskRecord:change:state:restart::all';
  public static readonly TASK_RECORD_CHANGE_STATE_RESTART_MY_CONTACT_LOCATION: string = 'TaskRecord:change:state:restart::my_contact_location';
  public static readonly TASK_RECORD_CHANGE_STATE_RESTART_CREATED_BY_ME: string = 'TaskRecord:change:state:restart::created_by_me';
  public static readonly TASK_RECORD_CHANGE_STATE_RESTART_CREATED_BY_MY_USER_GROUP: string = 'TaskRecord:change:state:restart::created_by_my_user_group';
  public static readonly TASK_RECORD_CHANGE_STATE_RESTART_MINE: string = 'TaskRecord:change:state:restart::mine';
  public static readonly TASK_RECORD_CHANGE_STATE_UNSUBMIT_ALL: string = 'TaskRecord:change:state:unsubmit::all';
  public static readonly TASK_RECORD_CHANGE_STATE_UNSUBMIT_MY_CONTACT_LOCATION: string = 'TaskRecord:change:state:unsubmit::my_contact_location';
  public static readonly TASK_RECORD_CHANGE_STATE_UNSUBMIT_CREATED_BY_ME: string = 'TaskRecord:change:state:unsubmit::created_by_me';
  public static readonly TASK_RECORD_CHANGE_STATE_UNSUBMIT_CREATED_BY_MY_USER_GROUP: string = 'TaskRecord:change:state:unsubmit::created_by_my_user_group';
  public static readonly TASK_RECORD_CHANGE_STATE_UNSUBMIT_MINE: string = 'TaskRecord:change:state:unsubmit::mine';
  public static readonly TASK_RECORD_CHANGE_STATE_RECALL_ALL: string = 'TaskRecord:change:state:recall::all';
  public static readonly TASK_RECORD_CHANGE_STATE_RECALL_MY_CONTACT_LOCATION: string = 'TaskRecord:change:state:recall::my_contact_location';
  public static readonly TASK_RECORD_CHANGE_STATE_RECALL_CREATED_BY_ME: string = 'TaskRecord:change:state:recall::created_by_me';
  public static readonly TASK_RECORD_CHANGE_STATE_RECALL_CREATED_BY_MY_USER_GROUP: string = 'TaskRecord:change:state:recall::created_by_my_user_group';
  public static readonly TASK_RECORD_CHANGE_STATE_RECALL_MINE: string = 'TaskRecord:change:state:recall::mine';
  public static readonly TASK_RECORD_CHANGE_STATE_ARCHIVE_ALL: string = 'TaskRecord:change:state:archive::all';
  public static readonly TASK_RECORD_CHANGE_STATE_ARCHIVE_MINE: string = 'TaskRecord:change:state:archive::mine';
  public static readonly TASK_RECORD_CHANGE_STATE_ARCHIVE_MY_CONTACT_LOCATION: string = 'TaskRecord:change:state:archive::my_contact_location';
  public static readonly TASK_RECORD_CHANGE_STATE_ARCHIVE_CREATED_BY_ME: string = 'TaskRecord:change:state:archive::created_by_me';
  public static readonly TASK_RECORD_CHANGE_STATE_ARCHIVE_CREATED_BY_MY_USER_GROUP: string = 'TaskRecord:change:state:archive::created_by_my_user_group';
  public static readonly TASK_RECORD_CHANGE_STATE_VALIDATE_ALL: string = 'TaskRecord:change:state:validate::all';
  public static readonly TASK_RECORD_CHANGE_STATE_VALIDATE_MINE: string = 'TaskRecord:change:state:validate::mine';
  public static readonly TASK_RECORD_CHANGE_STATE_VALIDATE_MY_CONTACT_LOCATION: string = 'TaskRecord:change:state:validate::my_contact_location';
  public static readonly TASK_RECORD_CHANGE_STATE_VALIDATE_CREATED_BY_ME: string = 'TaskRecord:change:state:validate::created_by_me';
  public static readonly TASK_RECORD_CHANGE_STATE_VALIDATE_CREATED_BY_MY_USER_GROUP: string = 'TaskRecord:change:state:validate::created_by_my_user_group';
  public static readonly TASK_RECORD_CHANGE_STATE_REVERT_ALL: string = 'TaskRecord:change:state:revert::all';
  public static readonly TASK_RECORD_CHANGE_STATE_REVERT_MY_CONTACT_LOCATION: string = 'TaskRecord:change:state:revert::my_contact_location';
  public static readonly TASK_RECORD_CHANGE_STATE_REVERT_CREATED_BY_ME: string = 'TaskRecord:change:state:revert::created_by_me';
  public static readonly TASK_RECORD_CHANGE_STATE_REVERT_CREATED_BY_MY_USER_GROUP: string = 'TaskRecord:change:state:revert::created_by_my_user_group';
  public static readonly TASK_RECORD_CHANGE_STATE_REVERT_MINE: string = 'TaskRecord:change:state:revert::mine';
  public static readonly TASK_RECORD_CHANGE_STATE_START_ALL: string = 'TaskRecord:change:state:start::all';
  public static readonly TASK_RECORD_CHANGE_STATE_START_MY_CONTACT_LOCATION: string = 'TaskRecord:change:state:start::my_contact_location';
  public static readonly TASK_RECORD_CHANGE_STATE_START_CREATED_BY_ME: string = 'TaskRecord:change:state:start::created_by_me';
  public static readonly TASK_RECORD_CHANGE_STATE_START_CREATED_BY_MY_USER_GROUP: string = 'TaskRecord:change:state:start::created_by_my_user_group';
  public static readonly TASK_RECORD_CHANGE_STATE_START_MINE: string = 'TaskRecord:change:state:start::mine';
  public static readonly TASK_RECORD_CHANGE_STATE_REOPEN_ALL: string = 'TaskRecord:change:state:reopen::all';
  public static readonly TASK_RECORD_CHANGE_STATE_REOPEN_MY_CONTACT_LOCATION: string = 'TaskRecord:change:state:reopen::my_contact_location';
  public static readonly TASK_RECORD_CHANGE_STATE_REOPEN_CREATED_BY_ME: string = 'TaskRecord:change:state:reopen::created_by_me';
  public static readonly TASK_RECORD_CHANGE_STATE_REOPEN_CREATED_BY_MY_USER_GROUP: string = 'TaskRecord:change:state:reopen::created_by_my_user_group';
  public static readonly TASK_RECORD_CHANGE_STATE_REOPEN_MINE: string = 'TaskRecord:change:state:reopen::mine';
  public static readonly TASK_RECORD_CHANGE_STATE_PAUSE_ALL: string = 'TaskRecord:change:state:pause::all';
  public static readonly TASK_RECORD_CHANGE_STATE_PAUSE_MY_CONTACT_LOCATION: string = 'TaskRecord:change:state:pause::my_contact_location';
  public static readonly TASK_RECORD_CHANGE_STATE_PAUSE_CREATED_BY_ME: string = 'TaskRecord:change:state:pause::created_by_me';
  public static readonly TASK_RECORD_CHANGE_STATE_PAUSE_CREATED_BY_MY_USER_GROUP: string = 'TaskRecord:change:state:pause::created_by_my_user_group';
  public static readonly TASK_RECORD_CHANGE_STATE_PAUSE_MINE: string = 'TaskRecord:change:state:pause::mine';

  // TaskRecord-global
  public static readonly TASK_RECORD_READ_GLOBAL_LIST_ALL: string = 'TaskRecord:read:global::all';
  public static readonly TASK_RECORD_READ_GLOBAL_LIST_MINE: string = 'TaskRecord:read:global::mine';
  public static readonly TASK_RECORD_READ_GLOBAL_LIST_CUSTOMER_RELATED: string = 'TaskRecord:read:global::customer_related';

  public static readonly TASK_RECORD_LOG_READ_ALL: string = 'TaskRecord:Log:read::all';
  public static readonly TASK_RECORD_LOG_READ_MY_CONTACT_LOCATION: string = 'TaskRecord:Log:read::my_contact_location';
  public static readonly TASK_RECORD_LOG_READ_CREATED_BY_ME: string = 'TaskRecord:Log:read::created_by_me';
  public static readonly TASK_RECORD_LOG_READ_CREATED_BY_MY_USER_GROUP: string = 'TaskRecord:Log:read::created_by_my_user_group';
  public static readonly TASK_RECORD_LOG_READ_MINE: string = 'TaskRecord:Log:read::mine';
  public static readonly TASK_RECORD_LOG_READ_CUSTOMER_RELATED: string = 'TaskRecord:Log:read::customer_related';

  // TaskRecordInvoice
  public static readonly TASK_RECORD_MANUAL_INVOICE_CREATE: string = 'TaskRecord:ManualInvoice:create';

  // Invoice
  public static readonly INVOICE_SETTINGS_READ: string = 'InvoiceSettings:read';
  public static readonly INVOICE_SETTINGS_CREATE: string = 'InvoiceSettings:create';
  public static readonly INVOICE_SETTINGS_UPDATE: string = 'InvoiceSettings:update';
  public static readonly INVOICE_SETTINGS_DISABLE: string = 'InvoiceSettings:disable';
  public static readonly INVOICE_BOOK_READ: string = 'InvoiceBook:read';
  public static readonly INVOICE_BOOK_CREATE: string = 'InvoiceBook:create';
  public static readonly INVOICE_BOOK_UPDATE: string = 'InvoiceBook:update';
  public static readonly INVOICE_BOOK_DISABLE: string = 'InvoiceBook:disable';
  public static readonly LEDGER_NUMBER_READ: string = 'LedgerNumber:read';
  public static readonly LEDGER_NUMBER_CREATE: string = 'LedgerNumber:create';
  public static readonly LEDGER_NUMBER_UPDATE: string = 'LedgerNumber:update';
  public static readonly LEDGER_NUMBER_DISABLE: string = 'LedgerNumber:disable';
  public static readonly INVOICE_TAG_READ: string = 'InvoiceTag:read';
  public static readonly INVOICE_TAG_CREATE: string = 'InvoiceTag:create';
  public static readonly INVOICE_TAG_UPDATE: string = 'InvoiceTag:update';
  public static readonly INVOICE_TAG_DISABLE: string = 'InvoiceTag:disable';
  public static readonly INVOICE_CREATE: string = 'Invoice:create';
  public static readonly INVOICE_READ: string = 'Invoice:read';
  public static readonly INVOICE_STORNO: string = 'Invoice:storno';
  public static readonly INVOICE_ACTION: string = 'Invoice:action';
  public static readonly INVOICE_BALANCE_READ: string = 'InvoiceBalance:read';
  public static readonly INVOICE_BALANCE_CREATE: string = 'InvoiceBalance:create';
  public static readonly INVOICE_BALANCE_DELETE: string = 'InvoiceBalance:delete';
  public static readonly INVOICE_SPENDING_READ: string = 'InvoiceSpending:read';
  public static readonly INVOICE_SPENDING_CREATE: string = 'InvoiceSpending:create';

  public static readonly TASK_LOG_STAT_READ: string = 'Task:LogStatistics:read';
  public static readonly TASK_INVOICE_STAT_READ: string = 'Task:InvoiceStatistics:read';
  public static readonly CONTRACT_NUMBER_READ_ALL: string = 'ContractNumber:read::all';

  // Workflow
  public static readonly LEGACY_WORKFLOW_READ_ALL: string = 'LegacyWorkflow:read::all';
  public static readonly LEGACY_WORKFLOW_CREATE: string = 'LegacyWorkflow:create';
  public static readonly LEGACY_WORKFLOW_UPATE_ALL: string = 'LegacyWorkflow:update::all';
  public static readonly LEGACY_WORKFLOW_DISABLE_ALL: string = 'LegacyWorkflow:disable::all';

  public static readonly WORKFLOW_CREATE: string = 'Workflow:create';
  public static readonly WORKFLOW_READ_ALL: string = 'Workflow:read::all';
  public static readonly WORKFLOW_UPDATE_ALL: string = 'Workflow:update::all';
  public static readonly WORKFLOW_DELETE_ALL: string = 'Workflow:delete::all';
  public static readonly WORKFLOW_ACTIVATE_ALL: string = 'Workflow:activate::all';
  public static readonly WORKFLOW_FINALIZE_ALL: string = 'Workflow:finalize::all';
  public static readonly WORKFLOW_CREATE_NEW_VERSION_ALL: string = 'Workflow:create_new_version::all';

  // Process
  public static readonly PROCESS_CREATE: string = 'Process:create';
  public static readonly PROCESS_READ_ALL: string = 'Process:read::all';
  public static readonly PROCESS_UPDATE_ALL: string = 'Process:update::all';
  public static readonly PROCESS_READ_USER_GROUP_RELATED: string = 'Process:read::task_record_user_group_related';
  public static readonly PROCESS_UPDATE_USER_GROUP_RELATED: string = 'Process:update::task_record_user_group_related';
  public static readonly PROCESS_READ_MINE: string = 'Process:read::task_record_mine';
  public static readonly PROCESS_UPDATE_MINE: string = 'Process:update::task_record_mine';

  public static readonly PROCESS_CHAT_MESSAGE_DELETE_ALL: string = 'Process:Chat:Message:delete::all';
  public static readonly PROCESS_CHAT_MESSAGE_DELETE_MINE: string = 'Process:Chat:Message:delete::mine';


  // LegacyProcess
  public static readonly LEGACY_PROCESS_READ_ALL: string = 'LegacyProcess:read::all';
  public static readonly LEGACY_PROCESS_CREATE_ALL: string = 'LegacyProcess:create::all';
  public static readonly LEGACY_PROCESS_UPDATE_ALL: string = 'LegacyProcess:update::all';
  public static readonly LEGACY_PROCESS_CHANGE_STATE_ALL: string = 'LegacyProcess:change:state::all';

  // LegacyProcessTask
  public static readonly LEGACY_PROCESS_TASK_READ_ALL: string = 'LegacyProcessTask:read::all';
  public static readonly LEGACY_PROCESS_TASK_READ_MINE: string = 'LegacyProcessTask:read::mine';
  public static readonly LEGACY_PROCESS_TASK_UPDATE_ALL: string = 'LegacyProcessTask:update::all';
  public static readonly LEGACY_PROCESS_TASK_UPDATE_MINE: string = 'LegacyProcessTask:update::mine';
  public static readonly LEGACY_PROCESS_TASK_CHANGE_ASSIGNEE_ALL: string = 'LegacyProcessTask:change:assignee::all';
  public static readonly LEGACY_PROCESS_TASK_CHANGE_STATE_ALL: string = 'LegacyProcessTask:change:state::all';
  public static readonly LEGACY_PROCESS_TASK_CHANGE_STATE_MINE: string = 'LegacyProcessTask:change:state::mine';

  // Order
  public static readonly ORDER_READ_ALL: string = 'Order:read::all';
  public static readonly ORDER_READ_MINE: string = 'Order:read::mine';
  public static readonly ORDER_READ_MY_COMPANY: string = 'Order:read::my_company';
  public static readonly ORDER_CREATE: string = 'Order:create';
  public static readonly ORDER_UPDATE_ALL: string = 'Order:update::all';
  public static readonly ORDER_UPDATE_MINE: string = 'Order:update::mine';
  public static readonly ORDER_UPDATE_MY_COMPANY: string = 'Order:update::my_company';
  public static readonly ORDER_SUBMIT_ALL: string = 'Order:submit::all';
  public static readonly ORDER_SUBMIT_MINE: string = 'Order:submit::mine';
  public static readonly ORDER_SUBMIT_MY_COMPANY: string = 'Order:submit::my_company';
  public static readonly ORDER_CANCEL_ALL: string = 'Order:cancel::all';
  public static readonly ORDER_CANCEL_MINE: string = 'Order:cancel::mine';
  public static readonly ORDER_CANCEL_MY_COMPANY: string = 'Order:cancel::my_company';

  // Order note
  public static readonly ORDER_NOTE_EXTRA_READ_ALL: string = 'Order:note:extra:read::all';
  public static readonly ORDER_NOTE_EXTRA_READ_MINE: string = 'Order:note:extra:read::mine';
  public static readonly ORDER_NOTE_EXTRA_READ_MY_COMPANY: string = 'Order:note:extra:read::my_company';
  public static readonly ORDER_NOTE_INTERNAL_READ_ALL: string = 'Order:note:internal:read::all';
  public static readonly ORDER_NOTE_INTERNAL_READ_MINE: string = 'Order:note:internal:read::mine';
  public static readonly ORDER_NOTE_INTERNAL_READ_MY_COMPANY: string = 'Order:note:internal:read::my_company';
  public static readonly ORDER_NOTE_EXTRA_UPDATE_ALL: string = 'Order:note:extra:update::all';
  public static readonly ORDER_NOTE_EXTRA_UPDATE_MINE: string = 'Order:note:extra:update::mine';
  public static readonly ORDER_NOTE_EXTRA_UPDATE_MY_COMPANY: string = 'Order:note:extra:update::my_company';
  public static readonly ORDER_NOTE_INTERNAL_UPDATE_ALL: string = 'Order:note:internal:update::all';
  public static readonly ORDER_NOTE_INTERNAL_UPDATE_MINE: string = 'Order:note:internal:update::mine';
  public static readonly ORDER_NOTE_INTERNAL_UPDATE_MY_COMPANY: string = 'Order:note:internal:update::my_company';

  // Task Record Chat
  public static readonly TASK_RECORD_CHAT_READ_ALL: string = 'TaskRecord:Chat:read::all';
  public static readonly TASK_RECORD_CHAT_READ_MINE: string = 'TaskRecord:Chat:read::mine';
  public static readonly TASK_RECORD_CHAT_READ_MY_CONTACT_LOCATION: string = 'TaskRecord:Chat:read::my_contact_location';
  public static readonly TASK_RECORD_CHAT_READ_CUSTOMER_RELATED: string = 'TaskRecord:Chat:read::customer_related';
  public static readonly TASK_RECORD_CHAT_READ_TASK_RECORD_USER_GROUP_RELATED: string
    = 'TaskRecord:Chat:read::task_record_user_group_related';
  public static readonly TASK_RECORD_CHAT_READ_MY_USER_GROUP: string = 'TaskRecord:Chat:read::my_user_group';
  public static readonly TASK_RECORD_CHAT_READ_CREATED_BY_ME: string = 'TaskRecord:Chat:read::created_by_me';
  public static readonly TASK_RECORD_CHAT_READ_CREATED_BY_MY_USER_GROUP: string = 'TaskRecord:Chat:read::created_by_my_user_group';
  public static readonly TASK_RECORD_CHAT_MESSAGE_CREATE_ALL: string = 'TaskRecord:Chat:Message:create::all';
  public static readonly TASK_RECORD_CHAT_MESSAGE_CREATE_MY_CONTACT_LOCATION: string
    = 'TaskRecord:Chat:Message:create::my_contact_location';
  public static readonly TASK_RECORD_CHAT_MESSAGE_CREATE_MINE: string = 'TaskRecord:Chat:Message:create::mine';
  public static readonly TASK_RECORD_CHAT_MESSAGE_CREATE_CUSTOMER_RELATED: string = 'TaskRecord:Chat:Message:create::customer_related';
  public static readonly TASK_RECORD_CHAT_MESSAGE_CREATE_TASK_RECORD_USER_GROUP_RELATED: string
    = 'TaskRecord:Chat:Message:create::task_record_user_group_related';
  public static readonly TASK_RECORD_CHAT_MESSAGE_CREATE_MY_USER_GROUP: string = 'TaskRecord:Chat:Message:create::my_user_group';
  public static readonly TASK_RECORD_CHAT_MESSAGE_CREATE_CREATED_BY_ME: string = 'TaskRecord:Chat:Message:create::created_by_me';
  public static readonly TASK_RECORD_CHAT_MESSAGE_CREATE_CREATED_BY_MY_USER_GROUP: string = 'TaskRecord:Chat:Message:create::created_by_my_user_group';
  public static readonly TASK_RECORD_CHAT_MESSAGE_DELETE_ALL: string = 'TaskRecord:Chat:Message:delete::all';
  public static readonly TASK_RECORD_CHAT_MESSAGE_DELETE_MINE: string = 'TaskRecord:Chat:Message:delete::mine';

  public static readonly STOCK_TAKING_CREATE: string = 'StockTaking:create';
  public static readonly STOCK_TAKING_READ_ALL: string = 'StockTaking:read::all';
  public static readonly STOCK_TAKING_READ_MINE: string = 'StockTaking:read::mine';
  public static readonly STOCK_TAKING_UPDATE_ALL: string = 'StockTaking:update::all';
  public static readonly STOCK_TAKING_UPDATE_MINE: string = 'StockTaking:update::mine';
  public static readonly STOCK_TAKING_SHEET_CREATE: string = 'StockTakingSheet:create';
  public static readonly STOCK_TAKING_SHEET_READ_ALL: string = 'StockTakingSheet:read::all';
  public static readonly STOCK_TAKING_SHEET_READ_MINE: string = 'StockTakingSheet:read::mine';
  public static readonly STOCK_TAKING_SHEET_UPDATE_ALL: string = 'StockTakingSheet:update::all';
  public static readonly STOCK_TAKING_SHEET_UPDATE_MINE: string = 'StockTakingSheet:update::mine';
  public static readonly STOCK_TAKING_SHEET_UPDATE_ASSIGNEE_ALL: string = 'StockTakingSheet:update:assignee::all';
  public static readonly STOCK_TAKING_SHEET_UPDATE_ASSIGNEE_MINE: string = 'StockTakingSheet:update:assignee::mine';
  public static readonly STOCK_TAKING_SHEET_DELETE_ALL: string = 'StockTakingSheet:delete::all';
  public static readonly STOCK_TAKING_SHEET_DELETE_MINE: string = 'StockTakingSheet:delete::mine';

  // Calendar
  public static readonly CALENDAR_READ_ALL: string = 'CalendarEvent:read::all';
  public static readonly CALENDAR_READ_MINE: string = 'CalendarEvent:read::mine';
  public static readonly CALENDAR_CREATE: string = 'CalendarEvent:create';
  public static readonly CALENDAR_UPDATE_ALL: string = 'CalendarEvent:update::all';
  public static readonly CALENDAR_UPDATE_MINE: string = 'CalendarEvent:update::mine';
  public static readonly CALENDAR_DELETE_ALL: string = 'CalendarEvent:delete::all';
  public static readonly CALENDAR_DELETE_MINE: string = 'CalendarEvent:delete::mine';

  // GlobalCalendar
  public static readonly GLOBAL_CALENDAR_READ_ALL: string = 'GlobalCalendarEvent:read::all';
  public static readonly GLOBAL_CALENDAR_CREATE: string = 'GlobalCalendarEvent:create';
  public static readonly GLOBAL_CALENDAR_UPDATE_ALL: string = 'GlobalCalendarEvent:update::all';
  public static readonly GLOBAL_CALENDAR_DELETE_ALL: string = 'GlobalCalendarEvent:delete::all';

  // Role
  public static readonly ROLE_READ_ALL: string = 'Role:read::all';
  public static readonly ROLE_READ_MY_COMPANY: string = 'Role:read::my_company';
  public static readonly ROLE_READ_MINE: string = 'Role:read::mine';
  public static readonly ROLE_CREATE: string = 'Role:create';
  public static readonly ROLE_UPDATE: string = 'Role:update';
  public static readonly ROLE_DISABLE: string = 'Role:disable';
  public static readonly ROLE_HISTORY_LOG_READ: string = 'Role:HistoryLog:read';

  // Order note
  public static readonly ORDER_IMPORT_MPL_RETURN: string = 'Order:import:mpl_return';
  public static readonly ORDER_IMPORT_MPL_FINAL_STATUS: string = 'Order:import:mpl_final_status';
  public static readonly ORDER_IMPORT_XLSX: string = 'Order:import:xlsx';

  // Company
  public static readonly COMPANY_READ_ALL: string = 'Company:read::all';
  public static readonly COMPANY_READ_MINE: string = 'Company:read::mine';
  public static readonly COMPANY_READ_MY_COMPANY_RELATED: string = 'Company:read::my_company_related';
  public static readonly COMPANY_CREATE: string = 'Company:create';
  public static readonly COMPANY_UPDATE_ALL: string = 'Company:update::all';
  public static readonly COMPANY_UPDATE_MINE: string = 'Company:update::mine';
  public static readonly COMPANY_ROLES_UPDATE: string = 'Company:AllowedRole:update';
  public static readonly COMPANY_DISABLE: string = 'Company:disable';
  public static readonly COMPANY_HISTORY_LOG_READ: string = 'Company:HistoryLog:read';

  // Vehicle
  public static readonly VEHICLE_READ_ALL: string = 'Vehicle:read::all';
  public static readonly VEHICLE_READ_MY_COMPANY: string = 'Vehicle:read::my_company';
  public static readonly VEHICLE_CREATE_ALL: string = 'Vehicle:create::all';
  public static readonly VEHICLE_CREATE_MY_COMPANY: string = 'Vehicle:create::my_company';
  public static readonly VEHICLE_UPDATE_ALL: string = 'Vehicle:update::all';
  public static readonly VEHICLE_UPDATE_MY_COMPANY: string = 'Vehicle:update::my_company';
  public static readonly VEHICLE_DISABLE_ALL: string = 'Vehicle:disable::all';
  public static readonly VEHICLE_DISABLE_MY_COMPANY: string = 'Vehicle:disable::my_company';
  public static readonly VEHICLE_HISTORY_LOG_READ: string = 'Vehicle:HistoryLog:read';

  // COMPANY_LOCATION
  public static readonly COMPANY_LOCATION_READ_ALL: string = 'CompanyLocation:read::all';
  public static readonly COMPANY_LOCATION_READ_MY_COMPANY: string = 'CompanyLocation:read::my_company';
  public static readonly COMPANY_LOCATION_CREATE_ALL: string = 'CompanyLocation:create::all';
  public static readonly COMPANY_LOCATION_CREATE_MY_COMPANY: string = 'CompanyLocation:create::my_company';
  public static readonly COMPANY_LOCATION_UPDATE_ALL: string = 'CompanyLocation:update::all';
  public static readonly COMPANY_LOCATION_UPDATE_MY_COMPANY: string = 'CompanyLocation:update::my_company';
  public static readonly COMPANY_LOCATION_DISABLE_ALL: string = 'CompanyLocation:disable::all';
  public static readonly COMPANY_LOCATION_DISABLE_MY_COMPANY: string = 'CompanyLocation:disable::my_company';
  public static readonly COMPANY_LOCATION_HISTORY_LOG_READ: string = 'CompanyLocation:HistoryLog:read';

  // COMPANY_Stock
  public static readonly COMPANY_STOCK_READ_ALL: string = 'CompanyStock:read::all';
  public static readonly COMPANY_STOCK_READ_MY_COMPANY: string = 'CompanyStock:read::my_company';
  public static readonly COMPANY_STOCK_CREATE_ALL: string = 'CompanyStock:create::all';
  public static readonly COMPANY_STOCK_CREATE_MY_COMPANY: string = 'CompanyStock:create::my_company';
  public static readonly COMPANY_STOCK_UPDATE_ALL: string = 'CompanyStock:update::all';
  public static readonly COMPANY_STOCK_UPDATE_MY_COMPANY: string = 'CompanyStock:update::my_company';
  public static readonly COMPANY_STOCK_DISABLE_ALL: string = 'CompanyStock:disable::all';
  public static readonly COMPANY_STOCK_DISABLE_MY_COMPANY: string = 'CompanyStock:disable::my_company';
  public static readonly COMPANY_STOCK_HISTORY_LOG_READ: string = 'CompanyStock:HistoryLog:read';

  // Transport
  public static readonly TRANSPORT_READ_ALL: string = 'Transport:read::all';
  public static readonly TRANSPORT_READ_MY_COMPANY: string = 'Transport:read::my_company';
  public static readonly TRANSPORT_READ_MINE: string = 'Transport:read::mine';
  public static readonly TRANSPORT_CREATE: string = 'Transport:create';
  public static readonly TRANSPORT_UPDATE_ALL: string = 'Transport:update::all';
  public static readonly TRANSPORT_UPDATE_MY_COMPANY: string = 'Transport:update::my_company';
  public static readonly TRANSPORT_UPDATE_MINE: string = 'Transport:update::mine';
  public static readonly TRANSPORT_DELETE_ALL: string = 'Transport:delete::all';
  public static readonly TRANSPORT_DELETE_MY_COMPANY: string = 'Transport:delete::my_company';
  public static readonly TRANSPORT_DELETE_MINE: string = 'Transport:delete::mine';
  public static readonly TRANSPORT_USE_ALL: string = 'Transport:use::all';
  public static readonly TRANSPORT_USE_MINE: string = 'Transport:use::mine';
  public static readonly TRANSPORT_SET_SECURITY_GUARD_ALL = 'Transport:set:security_guard::all';
  public static readonly TRANSPORT_SET_SECURITY_GUARD_MY_COMPANY = 'Transport:set:security_guard::my_company';
  public static readonly TRANSPORT_SET_SECURITY_GUARD_MINE = 'Transport:set:security_guard::mine';
  public static readonly TRANSPORT_SET_DRIVER_ALL = 'Transport:set:driver::all';
  public static readonly TRANSPORT_SET_DRIVER_MY_COMPANY = 'Transport:set:driver::my_company';
  public static readonly TRANSPORT_SET_DRIVER_MINE = 'Transport:set:driver::mine';
  public static readonly TRANSPORT_SET_VEHICLE_ALL = 'Transport:set:vehicle::all';
  public static readonly TRANSPORT_SET_VEHICLE_MY_COMPANY = 'Transport:set:vehicle::my_company';
  public static readonly TRANSPORT_SET_VEHICLE_MINE = 'Transport:set:vehicle::mine';

  // Shipping demand
  public static readonly SHIPPING_DEMAND_READ_ALL: string = 'ShippingDemand:read::all';
  public static readonly SHIPPING_DEMAND_READ_MY_COMPANY: string = 'ShippingDemand:read::my_company';
  public static readonly SHIPPING_DEMAND_READ_MY_COMPANY_SIMPLE: string = 'ShippingDemand:Simple:read::my_company';
  public static readonly SHIPPING_DEMAND_CREATE: string = 'ShippingDemand:create';
  public static readonly SHIPPING_DEMAND_IMPORT_XLSX = 'ShippingDemand:import:xlsx';

  public static readonly SHIPPING_DEMAND_CREATE_SIMPLE: string = 'ShippingDemand:Simple:create';
  public static readonly SHIPPING_DEMAND_UPDATE_ALL: string = 'ShippingDemand:update::all';
  public static readonly SHIPPING_DEMAND_UPDATE_MY_COMPANY: string = 'ShippingDemand:update::my_company';
  public static readonly SHIPPING_DEMAND_UPDATE_MY_COMPANY_SIMPLE: string = 'ShippingDemand:Simple:update::my_company';
  public static readonly SHIPPING_DEMAND_CANCEL_ALL: string = 'ShippingDemand:cancel::all';
  public static readonly SHIPPING_DEMAND_CANCEL_MY_COMPANY: string = 'ShippingDemand:cancel::my_company';
  public static readonly SHIPPING_DEMAND_SPLIT_ALL = 'ShippingDemand:split::all';
  public static readonly SHIPPING_DEMAND_SPLIT_MY_COMPANY = 'ShippingDemand:split::my_company';

  // Client document
  public static readonly CLIENT_DOCUMENT_IN_PROGRESS_READ_MINE = 'ClientDocument:InProgress:read::mine';
  public static readonly CLIENT_DOCUMENT_IN_PROGRESS_READ_ALL = 'ClientDocument:InProgress:read::all';
  public static readonly CLIENT_DOCUMENT_SUBMITTED_READ_MINE = 'ClientDocument:Submitted:read::mine';
  public static readonly CLIENT_DOCUMENT_SUBMITTED_READ_ALL = 'ClientDocument:Submitted:read::all';

  public static readonly CLIENT_DOCUMENT_CREATE = 'ClientDocument:create';
  public static readonly CLIENT_DOCUMENT_UPDATE_ALL = 'ClientDocument:update::all';
  public static readonly CLIENT_DOCUMENT_UPDATE_MINE = 'ClientDocument:update::mine';
  public static readonly CLIENT_DOCUMENT_SUBMIT_ALL = 'ClientDocument:submit::all';
  public static readonly CLIENT_DOCUMENT_SUBMIT_MINE = 'ClientDocument:submit::mine';
  public static readonly CLIENT_DOCUMENT_DELETE_ALL = 'ClientDocument:delete::all';
  public static readonly CLIENT_DOCUMENT_DELETE_MINE = 'ClientDocument:delete::mine';

  // Email template
  public static readonly EMAIL_TEMPLATE_READ = 'EmailTemplate:read';
  public static readonly EMAIL_TEMPLATE_CREATE = 'EmailTemplate:create';
  public static readonly EMAIL_TEMPLATE_UPDATE = 'EmailTemplate:update';
  public static readonly EMAIL_TEMPLATE_DISABLE = 'EmailTemplate:disable';

  // Table Document Schema
  public static readonly TABLE_DOCUMENT_SCHEMA_READ_ALL: string = 'TableDocument:Schema:read::all';
  public static readonly TABLE_DOCUMENT_SCHEMA_CREATE: string = 'TableDocument:Schema:create';
  public static readonly TABLE_DOCUMENT_SCHEMA_CREATE_NEXT_ALL: string = 'TableDocument:Schema:create_next::all';
  public static readonly TABLE_DOCUMENT_SCHEMA_DEPRECATE_ALL: string = 'TableDocument:Schema:deprecate::all';
  public static readonly TABLE_DOCUMENT_SCHEMA_UPDATE_ALL: string = 'TableDocument:Schema:update::all';
  public static readonly TABLE_DOCUMENT_SCHEMA_DELETE_ALL: string = 'TableDocument:Schema:delete::all';
  public static readonly TABLE_DOCUMENT_SCHEMA_FINALIZE_ALL: string = 'TableDocument:Schema:finalize::all';

  // Table Document
  public static readonly TABLE_DOCUMENT_READ_ALL: string = 'TableDocument:Document:read::all';
  public static readonly TABLE_DOCUMENT_CREATE_ALL: string = 'TableDocument:Document:create::all';
  public static readonly TABLE_DOCUMENT_UPDATE_ALL: string = 'TableDocument:Document:update::all';
  public static readonly TABLE_DOCUMENT_DELETE_ALL: string = 'TableDocument:Document:delete::all';
  public static readonly TABLE_DOCUMENT_EXPORT_ALL: string = 'TableDocument:Document:export::all';
  public static readonly TABLE_DOCUMENT_REPLACE_ALL: string = 'TableDocument:Document:replace::all';

  // General pdf template
  public static readonly GENERAL_PDF_TEMPLATE_READ: string = 'GeneralPdf:Template:read';
  public static readonly GENERAL_PDF_TEMPLATE_UPLOAD: string = 'GeneralPdf:Template:upload';
  public static readonly GENERAL_PDF_TEMPLATE_DISABLE: string = 'GeneralPdf:Template:disable';

  // Token
  public static readonly TOKEN_READ_ALL: string = 'Token:read::all';
  public static readonly TOKEN_DELETE_ALL: string = 'Token:delete::all';

  // NFC Card
  public static readonly USER_NFC_CARD_READ_ALL: string = 'User:NfcCard:read::all';
  public static readonly USER_NFC_CARD_CREATE_ALL: string = 'User:NfcCard:create::all';
  public static readonly USER_NFC_CARD_DELETE_ALL: string = 'User:NfcCard:delete::all';

  // Stock Item Category
  public static readonly STOCK_ITEM_CATEGORY_READ: string = 'StockItemCategory:read';
  public static readonly STOCK_ITEM_CATEGORY_CREATE: string = 'StockItemCategory:create';
  public static readonly STOCK_ITEM_CATEGORY_UPDATE: string = 'StockItemCategory:update';
  public static readonly STOCK_ITEM_CATEGORY_DELETE: string = 'StockItemCategory:delete';

  // Environment config
  public static readonly ENVIRONMENT_CONFIG_READ: string = 'EnvironmentConfig:read';
  public static readonly ENVIRONMENT_CONFIG_UPDATE: string = 'EnvironmentConfig:update';

  // Registration settings
  public static readonly REGISTRATION_SETTINGS_READ: string = 'RegistrationSettings:read';
  public static readonly REGISTRATION_SETTINGS_UPDATE: string = 'RegistrationSettings:update';

  // Message template
  public static readonly MESSAGE_TEMPLATE_READ: string = 'MessageTemplate:read';
  public static readonly MESSAGE_TEMPLATE_CREATE: string = 'MessageTemplate:create';
  public static readonly MESSAGE_TEMPLATE_UPDATE: string = 'MessageTemplate:update';
  public static readonly MESSAGE_TEMPLATE_DISABLE: string = 'MessageTemplate:disable';

  // GeneralPrinter
  public static readonly GENERAL_PRINTER_TEMPLATE_READ: string = 'GeneralPrinter:Template:read';
  public static readonly GENERAL_PRINTER_TEMPLATE_UPLOAD: string = 'GeneralPrinter:Template:upload';
  public static readonly GENERAL_PRINTER_TEMPLATE_DISABLE: string = 'GeneralPrinter:Template:disable';

  // DQL
  public static readonly STORED_DQL_QUERY_READ_ALL = 'DqlQuery:read::all';
  public static readonly STORED_DQL_QUERY_READ_MINE = 'DqlQuery:read::mine';
  public static readonly STORED_DQL_QUERY_READ_MY_USER_GROUP = 'DqlQuery:read::my_user_group';

  public static readonly STORED_DQL_QUERY_CREATE = 'DqlQuery:create';

  public static readonly STORED_DQL_QUERY_UPDATE_MINE = 'DqlQuery:update::mine';
  public static readonly STORED_DQL_QUERY_UPDATE_ALL = 'DqlQuery:update::all';

  public static readonly STORED_DQL_QUERY_DELETE_MINE = 'DqlQuery:delete::mine';
  public static readonly STORED_DQL_QUERY_DELETE_ALL = 'DqlQuery:delete::all';

  // Transport trigger
  public static readonly TRANSPORT_TRIGGER_READ_ALL = 'Transport:Trigger:read::all';
  public static readonly TRANSPORT_TRIGGER_DISABLE_ALL = 'Transport:Trigger:disable::all';
  public static readonly TRANSPORT_TRIGGER_DELETE_ALL = 'Transport:Trigger:delete::all';
  public static readonly TRANSPORT_TRIGGER_CREATE_ALL = 'Transport:Trigger:create::all';
  public static readonly TRANSPORT_TRIGGER_UPDATE_ALL = 'Transport:Trigger:update::all';

  // Transport trigger instance
  public static readonly TRANSPORT_TRIGGER_INSTANCE_READ_ALL = 'Transport:TriggerInstance:read::all';

  // TaskTrigger
  public static readonly TASK_TRIGGER_READ_ALL = 'Task:Trigger:read::all';
  public static readonly TASK_TRIGGER_DISABLE_ALL = 'Task:Trigger:disable::all';
  public static readonly TASK_TRIGGER_DELETE_ALL = 'Task:Trigger:delete::all';
  public static readonly TASK_TRIGGER_CREATE_ALL = 'Task:Trigger:create::all';
  public static readonly TASK_TRIGGER_UPDATE_ALL = 'Task:Trigger:update::all';

  // TaskTriggerInstance
  public static readonly TASK_TRIGGER_INSTANCE_READ_ALL = 'Task:TriggerInstance:read::all';

  // OrderDocument
  public static readonly ORDER_DOCUMENT_CREATE_ALL = 'Order:Document:create::all';
  public static readonly ORDER_DOCUMENT_READ_ALL = 'Order:Document:read::all';
  public static readonly ORDER_DOCUMENT_DOWNLOAD_ALL = 'Order:Document:download::all';
  public static readonly ORDER_DOCUMENT_UPDATE_META_ALL = 'Order:Document:update_meta::all';
  public static readonly ORDER_DOCUMENT_DELETE_ALL = 'Order:Document:delete::all';

  // Shipment
  public static readonly SHIPMENT_CREATE = 'Shipment:create';
  public static readonly SHIPMENT_GROUP_READ_ALL = 'ShipmentGroup:read::all';
  public static readonly SHIPMENT_GROUP_READ_MY_COMPANY = 'ShipmentGroup:read::my_company';
  public static readonly SHIPMENT_GROUP_DEPOT_UPDATE_ALL = 'ShipmentGroup:Depot:update::all';
  public static readonly SHIPMENT_GROUP_DEPOT_UPDATE_MY_COMPANY = 'ShipmentGroup:Depot:update::my_company';
  public static readonly SHIPMENT_GROUP_DEMAND_REMOVE_ALL = 'ShipmentGroup:ShippingDemand:remove::all';
  public static readonly SHIPMENT_GROUP_DEMAND_REMOVE_MY_COMPANY = 'ShipmentGroup:ShippingDemand:remove::my_company';
  public static readonly SHIPMENT_CHANGE_SUB_TRANSPORTER_ALL = 'Shipment:SubTransporter:change::all';
  public static readonly SHIPMENT_CHANGE_SUB_TRANSPORTER_MY_COMPANY = 'Shipment:SubTransporter:change::my_company';

  // ExteriorTransport
  public static readonly EXTERIOR_TRANSPORT_CREATE = 'ExteriorTransport:create';
  public static readonly EXTERIOR_TRANSPORT_READ_ALL = 'ExteriorTransport:read::all';
  public static readonly EXTERIOR_TRANSPORT_START_ALL = 'ExteriorTransport:start::all';
  public static readonly EXTERIOR_TRANSPORT_FINISH_ALL = 'ExteriorTransport:finish::all';
  public static readonly EXTERIOR_TRANSPORT_SHIPMENT_ADD_ALL = 'ExteriorTransport:Shipment:add::all';
  public static readonly EXTERIOR_TRANSPORT_SHIPMENT_REMOVE_ALL = 'ExteriorTransport:Shipment:remove::all';

  // ExteriorTransportDocument
  public static readonly EXTERIOR_TRANSPORT_DOCUMENT_READ_ALL = 'ExteriorTransport:Document:read::all';
  public static readonly EXTERIOR_TRANSPORT_DOCUMENT_CREATE_ALL = 'ExteriorTransport:Document:create::all';
  public static readonly EXTERIOR_TRANSPORT_DOCUMENT_DELETE_ALL = 'ExteriorTransport:Document:delete::all';
  public static readonly EXTERIOR_TRANSPORT_DOCUMENT_DOWNLOAD_ALL = 'ExteriorTransport:Document:download::all';
  public static readonly EXTERIOR_TRANSPORT_DOCUMENT_META_UPDATE_ALL = 'ExteriorTransport:Document:Meta:update::all';

  // ShopRenterShop
  public static readonly SHOP_RENTER_SHOP_READ_ALL = 'ShopRenter:Shop:read::all';
  public static readonly SHOP_RENTER_SHOP_READ_MINE = 'ShopRenter:Shop:read::mine';
  public static readonly SHOP_RENTER_SHOP_READ_MY_COMPANY = 'ShopRenter:Shop:read::my_company';
  public static readonly SHOP_RENTER_SHOP_UPDATE_ALL = 'ShopRenter:Shop:update::all';
  public static readonly SHOP_RENTER_SHOP_UPDATE_MINE = 'ShopRenter:Shop:update::mine';
  public static readonly SHOP_RENTER_SHOP_UPDATE_MY_COMPANY = 'ShopRenter:Shop:update::my_company';
  public static readonly SHOP_RENTER_SHOP_SYNC_ALL = 'ShopRenter:Shop:sync::all';
  public static readonly SHOP_RENTER_SHOP_SYNC_MINE = 'ShopRenter:Shop:sync::mine';
  public static readonly SHOP_RENTER_SHOP_SYNC_MY_COMPANY = 'ShopRenter:Shop:sync::my_company';
  public static readonly SHOP_RENTER_SHOP_IMPORT_PRODUCTS_ALL = 'ShopRenter:Shop:import_products::all';
  public static readonly SHOP_RENTER_SHOP_IMPORT_PRODUCTS_MINE = 'ShopRenter:Shop:import_products::mine';
  public static readonly SHOP_RENTER_SHOP_IMPORT_PRODUCTS_MY_COMPANY = 'ShopRenter:Shop:import_products::my_company';

  // Worklog
  public static readonly WORKLOG_READ_ALL = 'Worklog:read::all';
  public static readonly WORKLOG_READ_MINE = 'Worklog:read::mine';
  public static readonly WORKLOG_UPDATE_ALL = 'Worklog:update::all';
  public static readonly WORKLOG_UPDATE_MINE = 'Worklog:update::mine';
  public static readonly WORKLOG_CREATE_ALL = 'Worklog:create::all';
  public static readonly WORKLOG_CREATE_MINE = 'Worklog:create::mine';

  // MileageRecord
  public static readonly MILEAGE_RECORD_READ_ALL = 'MileageRecord:read::all';
  public static readonly MILEAGE_RECORD_READ_MY_COMPANY = 'MileageRecord:read::my_company';
  public static readonly MILEAGE_RECORD_READ_MINE = 'MileageRecord:read::mine';
  public static readonly MILEAGE_RECORD_CREATE = 'MileageRecord:create';
  public static readonly MILEAGE_RECORD_UPDATE_ALL = 'MileageRecord:update::all';
  public static readonly MILEAGE_RECORD_UPDATE_MY_COMPANY = 'MileageRecord:update::my_company';
  public static readonly MILEAGE_RECORD_UPDATE_MINE = 'MileageRecord:update::mine';

  // OrderImportError
  public static readonly ORDER_IMPORT_ERROR_READ_ALL = 'OrderImportError:read::all';
  public static readonly ORDER_IMPORT_ERROR_REJECT_MANUALLY_ALL = 'OrderImportError:reject_manually::all';

  // StockChange
  public static readonly STOCK_CHANGE_READ_ALL = 'StockChange:read::all';
  public static readonly STOCK_CHANGE_READ_MINE = 'StockChange:read::mine';

  // StockItemCustomerRecordPrice
  public static readonly STOCK_ITEM_CUSTOMER_RECORD_PRICE_READ = 'StockItemCustomerRecordPrice:read';
  public static readonly STOCK_ITEM_CUSTOMER_RECORD_PRICE_CREATE = 'StockItemCustomerRecordPrice:create';
  public static readonly STOCK_ITEM_CUSTOMER_RECORD_PRICE_UPDATE = 'StockItemCustomerRecordPrice:update';
  public static readonly STOCK_ITEM_CUSTOMER_RECORD_PRICE_DELETE = 'StockItemCustomerRecordPrice:delete';

  // ParcelCollectionPoint
  public static readonly PARCEL_COLLECTION_POINT_READ = 'ParcelCollectionPoint:read';
  public static readonly PARCEL_COLLECTION_POINT_CREATE = 'ParcelCollectionPoint:create';
  public static readonly PARCEL_COLLECTION_POINT_UPDATE = 'ParcelCollectionPoint:update';
  public static readonly PARCEL_COLLECTION_POINT_DISABLE = 'ParcelCollectionPoint:disable';
  public static readonly PARCEL_COLLECTION_POINT_IMPORT = 'ParcelCollectionPoint:import';

  // DeliveryMethod
  public static readonly DELIVERY_METHOD_READ_ALL = 'DeliveryMethod:read::all';
  public static readonly DELIVERY_METHOD_CREATE_ALL = 'DeliveryMethod:create::all';
  public static readonly DELIVERY_METHOD_UPDATE_ALL = 'DeliveryMethod:update::all';
  public static readonly DELIVERY_METHOD_DISABLE_ALL = 'DeliveryMethod:disable::all';

  // EmailAddressType
  public static readonly EMAIL_ADDRESS_TYPE_CREATE = 'EmailAddressType:create';
  public static readonly EMAIL_ADDRESS_TYPE_UPDATE = 'EmailAddressType:update';
  public static readonly EMAIL_ADDRESS_TYPE_DISABLE = 'EmailAddressType:disable';

  // Colonnade
  public static readonly COLONNADE_B2B_READ_ALL = 'Colonnade:B2B:read::all';
  public static readonly COLONNADE_B2B_READ_MINE = 'Colonnade:B2B:read::mine';

  // Shipment Outtake
  public static readonly SHIPMENT_OUTTAKE_STOREKEEPER_CHANGE_ALL = 'Shipment:Outtake:Storekeeper:change::all';
  public static readonly SHIPMENT_OUTTAKE_STOREKEEPER_CHANGE_MY_COMPANY = 'Shipment:Outtake:Storekeeper:change::my_company';
  public static readonly SHIPMENT_OUTTAKE_REOPEN_FORCE_ALL = 'Shipment:Outtake:reopen:force::all';
  public static readonly SHIPMENT_OUTTAKE_REOPEN_FORCE_MY_COMPANY = 'Shipment:Outtake:reopen:force::my_company';

  public static readonly ANDROID_APP_VERSION_HANDLE = 'AndroidAppVersion:handle';

  // Helpdesk
  public static readonly CUSTOMER_RECORD_HELPDESK_ACTION_ALL = 'CustomerRecord:Helpdesk:action::all';
  public static readonly CUSTOMER_RECORD_HELPDESK_ACTION_MY_COMPANY = 'CustomerRecord:Helpdesk:action::my_company';
  public static readonly CUSTOMER_RECORD_HELPDESK_ACTION_MINE = 'CustomerRecord:Helpdesk:action::mine';
  public static readonly CUSTOMER_RECORD_HELPDESK_ACTION_CUSTOMER_USER_GROUP_RELATED = 'CustomerRecord:Helpdesk:action::customer_user_group_related';

  // Billing info
  public static readonly BILLING_INFO_READ: string = 'BillingInfo:read';
  public static readonly BILLING_INFO_CREATE: string = 'BillingInfo:create';
  public static readonly BILLING_INFO_UPDATE: string = 'BillingInfo:update';
  public static readonly BILLING_INFO_DISABLE: string = 'BillingInfo:disable';
}
