/* eslint-disable */
import { map } from 'rxjs/operators';
import { DownloadedFile } from '../util/downloaded-files';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UrlBuilder } from '../../util/url-builder';
import { ResourceQueryResult } from '../util/services';
import { HttpClient } from '@angular/common/http';
import { BaseHttpService, ResourceHelper } from '../util/http-services';
import { EmptyMessage } from '../util/messages';
import StockTaking = StockTakingsResource.StockTaking;
import CreateRequest = StockTakingsResource.CreateRequest;
import QueryRequest = StockTakingsResource.QueryRequest;
import CreateResponse = StockTakingsResource.CreateResponse;
import GetRequest = StockTakingsResource.GetRequest;
import UpdateRequest = StockTakingsResource.UpdateRequest;
import DeleteTemplateRequest = StockTakingsResource.DeleteTemplateRequest;
/* eslint-enable */

@Injectable()
export class StockTakingsResourceService extends BaseHttpService {

  query(request: QueryRequest): Observable<ResourceQueryResult<StockTaking>> {
    return this.http.get<ResourceQueryResult<StockTaking>>(this.url, this.parseParams(request));
  }

  get(request: GetRequest): Observable<StockTaking> {
    return this.http.get<StockTaking>(this.url + `${request.stock_taking_id}`);
  }

  create(request: CreateRequest): Observable<CreateResponse> {
    return this.http.post<CreateResponse>(this.url, request);
  }

  update(request: UpdateRequest): Observable<EmptyMessage> {
    return this.http.put(this.url + `${request.stock_taking_id}`, request);
  }

  deleteTemplate(request: DeleteTemplateRequest): Observable<EmptyMessage> {
    return this.http.delete<EmptyMessage>(this.url + `${request.stock_taking_id}/pdf-template`);
  }

  downloadTemplate(request: StockTakingsResource.StockTakingDownloadTemplateRequest): Observable<DownloadedFile> {
    const url = UrlBuilder.create(
      'stock-takings/:stockTakingId/pdf-template')
      .baseUrl(this.resourceHelper.getBaseUrl())
      .namedNumber('stockTakingId', request.stockTakingId)
      .build();
    return this.http.get(url, {
      observe: 'response',
      responseType: 'blob'
    }).pipe(map((res) => {
      return new DownloadedFile(res);
    }));
  }

  constructor(private http: HttpClient, private resourceHelper: ResourceHelper) {
    super(resourceHelper, '/stock-takings/');
  }

}

export namespace StockTakingsResource {

  export interface StockTaking {
    stock_taking_id: number;
    trx_id: string;
    creation_time: string;
    update_time: string;
    name: string;
    owner_user_id: number;
    stock_ids: number[];
    pdf_template?: PdfTemplate;
  }

  export interface PdfTemplate {
    creation_time: string;
    update_time: string;
    version: number;
  }

  export interface QueryRequest {
    stock_taking_id?: string;
    owner_user_id?: string;
    name?: string;
    trx_id?: string;
    order?: string;
    page_number?: number;
    number_of_items?: number;
  }

  export interface GetRequest {
    stock_taking_id: number;
  }

  export interface CreateRequest {
    name: string;
    stock_ids: number[];
  }

  export interface CreateResponse {
    stock_taking_id: number;
  }

  export interface UpdateRequest {
    stock_taking_id: number;
    name: string;
    stock_ids: number[];
  }

  export interface DeleteTemplateRequest {
    stock_taking_id: number;
  }

  export interface StockTakingDownloadTemplateRequest {
    stockTakingId: number;
  }

}
