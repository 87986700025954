/* eslint-disable */
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EmptyMessage, } from './util/messages';
import { ResourceQueryResult } from './util/services';
import { BaseHttpService, ResourceHelper } from './util/http-services';
import { HttpClient } from '@angular/common/http';
/* eslint-enable */

@Injectable()
export class LanguageService extends BaseHttpService {

  query(request: EmptyMessage): Observable<ResourceQueryResult<string>> {
    return this.http.get<ResourceQueryResult<string>>(this.url, this.parseParams(request));
  }

  constructor(private http: HttpClient, resourceHelper: ResourceHelper) {
    super(resourceHelper, '/documents/languages/');
  }
}
