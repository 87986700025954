import { Injectable } from '@angular/core';
import { Subject, Subscription } from 'rxjs';

@Injectable()
export class TabSelectService {

  private _subject: Subject<string> = new Subject<string>();

  constructor() {
  }

  subscribe(next?: (value: string) => void, error?: (error: any) => void, complete?: () => void): Subscription {
    return this._subject.subscribe(next, error, complete);
  }

  unsubscribe(subscription: Subscription) {
    subscription.unsubscribe();
  }

  select(id: string) {
    this._subject.next(id);
  }
}
