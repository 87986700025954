/* eslint-disable */
import { DownloadedFile, } from '../util/downloaded-files';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UrlBuilder } from '../../util/url-builder';
import { ResourceQueryResult } from '../util/services';
import { map } from 'rxjs/operators';
import { BaseHttpService, ResourceHelper } from '../util/http-services';
import { HttpClient } from '@angular/common/http';
import { EmptyMessage } from '../util/messages';
/* eslint-enable */

@Injectable()
export class TableDocumentResourceService extends BaseHttpService {

  query(request: TableDocumentResource.QueryRequest): Observable<ResourceQueryResult<TableDocumentResource.TableDocument>> {
    return this.http.get<ResourceQueryResult<TableDocumentResource.TableDocument>>(this.url + `document`, this.parseParams(request));
  }

  get(request: TableDocumentResource.GetRequest): Observable<TableDocumentResource.TableDocument> {
    return this.http.get<TableDocumentResource.TableDocument>(this.url + `schema/${request.schema_id}/document/${request.document_id}`);
  }

  create(request: TableDocumentResource.CreateRequest): Observable<EmptyMessage> {
    return this.http.post(this.url + `schema/${request.schema_id}/document`, request);
  }

  update(request: TableDocumentResource.UpdateRequest): Observable<EmptyMessage> {
    return this.http.put(this.url + `schema/${request.schema_id}/document/${request.document_id}`, request);
  }

  delete(request: TableDocumentResource.DeleteRequest): Observable<EmptyMessage> {
    return this.http.delete<EmptyMessage>(this.url + `schema/${request.schema_id}/document/${request.document_id}`);
  }

  export(request: TableDocumentResource.ExportRequest): Observable<DownloadedFile> {
    const url = UrlBuilder.create(
      '/table-document/schema/:schema_id/document/:document_id/xlsx')
      .baseUrl(this.resourceHelper.getBaseUrl())
      .namedNumber('schema_id', request.schema_id)
      .namedNumber('document_id', request.document_id)
      .build();
    return this.http.get(url, {
      observe: 'response',
      responseType: 'blob',
    }).pipe(map((res) => {
      return new DownloadedFile(res);
    }));
  }

  constructor(private http: HttpClient, private resourceHelper: ResourceHelper) {
    super(resourceHelper, '/table-document/');
  }
}

export namespace TableDocumentResource {

  export interface TableDocument {
    document_id: number;
    creation_time?: string;
    content_update_time?: string;
    code: string;
    name: string;
    note: string;
    exportable: boolean;
    schema: TableDocumentSchema
  }

  export interface TableDocumentSchema {
    schema_id: number;
    version: number;
    version_state: string;
    code: string;
    name: string;
  }

  export interface QueryRequest {
    order?: string;
    page_number?: number;
    number_of_items?: number;
    name?: string;
    code?: string;
    schema_name?: string;
    schema_code?: string;
    schema_version?: number;
    schema_id?: number;
  }

  export interface GetRequest {
    schema_id: number;
    document_id: number;
  }

  export interface CreateRequest {
    schema_id: number;
    code: string;
    name: string;
    note: string;
  }

  export interface UpdateRequest {
    schema_id: number;
    document_id: number;
    name: string;
    note: string;
  }

  export interface DeleteRequest {
    schema_id: number;
    document_id: number;
  }

  export interface ImportDocument {
    version: string;
    descriptor: string;
  }

  export interface ExportRequest {
    schema_id: number;
    document_id: number;
  }

}
