<div class="container-vertical-padding container-horizontal-padding">
  <div class="col-md-12 d-flex justify-content-between" *ngIf="isRemovable">
    <h6>{{'INVOICE_RECORD_MODIFY' | translate}}</h6>
    <input type="button"
           (click)="remove.emit()" class="btn btn-secondary"
           value="{{'INVOICE_RECORD_DELETE' | translate}}">
  </div>
  <form class="form-horizontal d-flex flex-wrap" (ngSubmit)="f.form.valid && saveRecord()" #f="ngForm" novalidate>
    <div class="col-md-4 col-sm-12 col-xs-12 form-group"
         [ngClass]="{ 'has-danger': (f.submitted && !recordStockItem.valid) }">
      <label class="form-control-label">
        {{'FORM_RECORD_STOCK_ITEM' | translate}}
      </label>
      <angular2-multiselect
        [(ngModel)]="m._stockItem"
        [data]="stockItems"
        (onRemoteSearch)="loadStockItems($event.target.value)"
        (onSelect)="stockItemSelected($event)"
        #recordStockItem="ngModel" name="recordStockItem" id="recordStockItem"
        [settings]="stockDropdownSettings" validateEnabledItems>
      </angular2-multiselect>
      <div class="form-control-feedback" *ngIf="f.submitted && !recordStockItem.valid">
        {{'COMMON_VALIDATION_MESSAGE_DISABLED' | translate}}
      </div>
    </div>
    <div class="col-md-4 col-sm-12 col-xs-12 form-group"
         [ngClass]="{ 'has-danger': (f.submitted && !recordName.valid) }">
      <label class="form-control-label required-field-label">
        {{'COMMON_NAME' | translate}}
      </label>
      <input class="form-control" type="text" placeholder="{{'COMMON_NAME' | translate}}"
             [ngClass]="{ 'form-control-danger': (f.submitted && !recordName.valid) }"
             [(ngModel)]="m.recordName" #recordName="ngModel" name="recordName" required>
      <div class="form-control-feedback" *ngIf="f.submitted && !recordName.valid">
        {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
      </div>
    </div>
    <div class="col-md-4 col-sm-12 col-xs-12 form-group"
         [ngClass]="{ 'has-danger': (f.submitted && !comment.valid) }">
      <label class="form-control-label">{{'COMMON_DESCRIPTION' | translate}}</label>
      <input class="form-control" type="text" placeholder="{{'COMMON_DESCRIPTION' | translate}}"
             [ngClass]="{ 'form-control-danger': (f.submitted && !comment.valid) }"
             [(ngModel)]="m.comment" #comment="ngModel" name="comment">
      <div class="form-control-feedback" *ngIf="f.submitted && !comment.valid">
        {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
      </div>
    </div>
    <div class="col-md-2 col-sm-4 col-xs-4 form-group"
         [ngClass]="{ 'has-danger': (f.submitted && !amount.valid) }">
      <label class="form-control-label required-field-label">
        {{'INVOICE_RECORD_AMOUNT' | translate}}
      </label>
      <input class="form-control" type="text" placeholder="{{'INVOICE_RECORD_AMOUNT' | translate}}"
             [ngClass]="{ 'form-control-danger': (f.submitted && !amount.valid) }"
             [textMask]="{mask: InputMask.NATURAL_FLOAT_WITH_THOUSAND_SEPARATOR, guide: true}"
             [(ngModel)]="m.amount" #amount="ngModel" name="amount" required>
      <div class="form-control-feedback" *ngIf="f.submitted && !amount.valid">
        {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
      </div>
    </div>
    <div class="col-md-2 col-sm-4 col-xs-4 form-group"
         [ngClass]="{ 'has-danger': (f.submitted && !unitType.valid) }">
      <label class="form-control-label required-field-label">
        {{'INVOICE_RECORD_UNIT_TYPE' | translate}}
      </label>
      <input class="form-control" type="text" placeholder="{{'INVOICE_RECORD_UNIT_TYPE' | translate}}"
             [ngClass]="{ 'form-control-danger': (f.submitted && !unitType.valid) }"
             [(ngModel)]="m.unitType" #unitType="ngModel" name="unitType" required>
      <div class="form-control-feedback" *ngIf="f.submitted && !unitType.valid">
        {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
      </div>
    </div>
    <div class="col-md-2 col-sm-4 col-xs-4 form-group"
         [ngClass]="{ 'has-danger': (f.submitted && !hunVtszNumber.valid) }">
      <label class="form-control-label">{{'INVOICE_RECORD_HUN_VTSZ_NUMBER' | translate}}</label>
      <input class="form-control" type="text" placeholder="{{'INVOICE_RECORD_HUN_VTSZ_NUMBER' | translate}}"
             [ngClass]="{ 'form-control-danger': (f.submitted && !hunVtszNumber.valid) }"
             [(ngModel)]="m.hunVtszNumber" #hunVtszNumber="ngModel" name="hunVtszNumber">
      <div class="form-control-feedback" *ngIf="f.submitted && !hunVtszNumber.valid">
        {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
      </div>
    </div>
    <div class="col-md-2 col-sm-4 col-xs-4 form-group"
         [ngClass]="{ 'has-danger': (f.submitted && !vatRate.valid) }">
      <label class="form-control-label required-field-label">
        {{'INVOICE_RECORD_VAT_RATE' | translate}}
      </label>
      <select class="form-control"
              [ngClass]="{ 'form-control-danger': (f.submitted && !vatRate.valid) }"
              [(ngModel)]="m.vatRate"
              name="vatRate" id="vatRate"
              #vatRate="ngModel" required>
        <option *ngFor="let vatRate of selectableVatRates" [ngValue]="vatRate.value">
          {{vatRate.value}}%
        </option>
      </select>
      <div class="form-control-feedback" *ngIf="f.submitted && !vatRate.valid">
        {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
      </div>
    </div>
    <div class="col-md-2 col-sm-4 col-xs-4 form-group" *ngIf="m.vatRate === 0"
         [ngClass]="{ 'has-danger': (f.submitted && !vatRate.valid) }">
      <label class="form-control-label required-field-label">
        {{'INVOICE_RECORD_VAT_RATE' | translate}}
      </label>
      <select class="form-control"
              [ngClass]="{ 'form-control-danger': f.submitted && !zero_vat_rate_reason.valid }"
              [(ngModel)]="m.zeroVatRateType"
              [compareWith]="VatRate.compareZeroVatRateTypeToString"
              name="zero_vat_rate_reason" id="zero_vat_rate_reason"
              #zero_vat_rate_reason="ngModel" required>
        <option *ngFor="let type of selectableZeroVatRateTypes" [ngValue]="type.id" [disabled]="type.disabled">
          {{type.text}}
        </option>
      </select>
      <div class="form-control-feedback" *ngIf="f.submitted && !vatRate.valid">
        {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
      </div>
    </div>
    <div class="col-md-4 col-sm-12 col-xs-12 form-group"
         [ngClass]="{ 'has-danger': (f.submitted && !ledgerNumber.valid) }">
      <label class="form-control-label">
        {{'LEDGER_NUMBER_LEDGER_NUMBER' | translate}}
      </label>
      <angular2-multiselect
        [(ngModel)]="m._ledgerNumber"
        [data]="ledgerNumbers"
        (onRemoteSearch)="loadLedgerNumbers($event.target.value)"
        #ledgerNumber="ngModel" name="ledgerNumber" id="ledgerNumber"
        [settings]="stockDropdownSettings" validateEnabledItems>
      </angular2-multiselect>
      <div class="form-control-feedback" *ngIf="f.submitted && !ledgerNumber.valid">
        {{'COMMON_VALIDATION_MESSAGE_DISABLED' | translate}}
      </div>
    </div>
    <div class="col-md-12 row d-flex justify-content-end">
      <div class="col-md-2 col-sm-4 col-xs-4 form-group"
           [ngClass]="{ 'has-danger': (f.submitted && !netUnitPrice.valid) }">
        <label class="form-control-label required-field-label">
          {{'INVOICE_RECORD_NET_UNIT_PRICE' | translate}}
        </label>
        <input class="form-control" type="text" placeholder="{{'INVOICE_RECORD_NET_UNIT_PRICE' | translate}}"
               [ngClass]="{ 'form-control-danger': (f.submitted && !netUnitPrice.valid) }"
               [textMask]="{mask: InputMask.NATURAL_FLOAT_WITH_THOUSAND_SEPARATOR, guide: true}"
               [(ngModel)]="m.netUnitPrice" #netUnitPrice="ngModel" name="netUnitPrice" required>
        <div class="form-control-feedback" *ngIf="f.submitted && !netUnitPrice.valid">
          {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
        </div>
      </div>
      <div class="col-md-2 col-sm-4 col-xs-4 form-group"
           [ngClass]="{ 'has-danger': (f.submitted && !netUnitPrice.valid) }">
        <label class="form-control-label required-field-label">
          {{'INVOICE_RECORD_GROSS_UNIT_PRICE' | translate}}
        </label>
        <input class="form-control" type="text" placeholder="{{'INVOICE_RECORD_GROSS_UNIT_PRICE' | translate}}"
               [ngClass]="{ 'form-control-danger': (f.submitted && !grossUnitPrice.valid) }"
               [textMask]="{mask: InputMask.NATURAL_FLOAT_WITH_THOUSAND_SEPARATOR, guide: true}"
               [(ngModel)]="m.grossUnitPrice" #grossUnitPrice="ngModel" name="grossUnitPrice" required>
        <div class="form-control-feedback" *ngIf="f.submitted && !grossUnitPrice.valid">
          {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
        </div>
      </div>

      <div class="col-md-2 col-sm-4 col-xs-4 form-group">
        <label class="form-control-label">
          {{'INVOICE_RECORD_NET_LINE_PRICE' | translate}}
        </label>
        <input class="form-control" type="text" placeholder="{{'INVOICE_RECORD_NET_LINE_PRICE' | translate}}"
               [textMask]="{mask: InputMask.NATURAL_FLOAT_WITH_THOUSAND_SEPARATOR, guide: true}"
               [ngModel]="m.netLinePrice" name="netLinePrice" disabled>
      </div>
      <div class="col-md-2 col-sm-4 col-xs-4 form-group">
        <label class="form-control-label font-weight-bold">
          {{'INVOICE_RECORD_GROSS_LINE_PRICE' | translate}}
        </label>
        <input class="form-control font-weight-bold" type="text" placeholder="{{'INVOICE_RECORD_GROSS_LINE_PRICE' | translate}}"
               [textMask]="{mask: InputMask.NATURAL_FLOAT_WITH_THOUSAND_SEPARATOR, guide: true}"
               [ngModel]="m.grossLinePrice" name="grossLinePrice" disabled>
      </div>
    </div>

    <div class="col-md-12">
      <div class="pull-right">
        <input type="button" class="btn btn-secondary-green cursor-pointer" (click)="cancel.emit()"
               value="{{'COMMON_BUTTON_CANCEL'|translate}}"/>
        <input type="submit" class="btn btn-primary-green cursor-pointer ml-1"
               value="{{'COMMON_BUTTON_SAVE'|translate}}"/>
      </div>
    </div>
  </form>
</div>
