<div class="breadcrumb-container" [ngClass]="{ 'breadcrumb-container-compact': compactSidebar }" id="breadcrumb">
  <div class="breadcrumb-component">
    <app-breadcrumb
      [parents]=breadcrumbParents
      [self]="breadcrumbSelf">
    </app-breadcrumb>
    <app-breadcrumb-menu>
      <app-breadcrumb-button
        *ngIf="rightModel.parcelCollectionPointUpdate.hasRight() && componentState.isDetailView()"
        [iconClass]="'icomoon-modify'"
        [titleStringKey]="'COMMON_BUTTON_EDIT'"
        uiSref="Admin.ParcelCollectionPointEdit"
        [uiParams]="{ id: componentState.id }">
      </app-breadcrumb-button>
    </app-breadcrumb-menu>
  </div>
</div>
<div class="breadcrumb-placeholder"></div>

<div class="container-horizontal-padding container-vertical-padding-half animated fadeIn">

      <div class="container-custom-padding animated fadeIn">

        <div class="form-group">

          <form class="form-horizontal bordered-row row" (ngSubmit)="f.form.valid && submit()" #f="ngForm" id="parcel_collection_point_form" novalidate>

            <!--region Basic Details-->
            <div class="col-xs-12 col-sm-12 col-md-6 card-custom-padding">
              <div class="card">
                <div class="card-header">
                  {{ getHeadingDictionaryKey() | translate}}
                </div>
                <div class="card-body">

                  <!--region Name-->
                  <div class="form-group row"
                       [ngClass]="{ 'has-danger': (hasLocalFieldError(name)) }">
                    <label class="col-form-label form-control-label col-md-4 detail-title">{{'COMMON_NAME' |
                      translate}}</label>
                    <div class="col-md-8">
                      <input *ngIf="componentState.isEditable()"
                             type="text" class="form-control"
                             maxlength="{{UiConstants.maximumVarcharLength}}"
                             placeholder="{{'COMMON_NAME' | translate}}"
                             [(ngModel)]="editModel.name"
                             [ngClass]="{ 'form-control-danger': hasLocalFieldError(name) }"
                             name="name"
                             id="name"
                             #name="ngModel"
                             [required]="componentState.isEditable()">
                      <div class="form-control-feedback"
                           *ngIf="hasLocalFieldError(name)">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</div>
                      <!--Appears in detailView-->
                      <label
                        *ngIf="componentState.isReadonly()" class="form-control detail-description"
                        readonly>{{detailModel.name}}
                      </label>
                    </div>
                  </div>
                  <!--endregion Name-->

                  <!--region Code-->
                  <div class="form-group row"
                       [ngClass]="{ 'has-danger': (hasLocalFieldError(code) || hasFieldError(ParcelCollectionPoint.ValidatedField.CODE)) }">
                    <label class="col-form-label form-control-label col-md-4 detail-title">{{'PARCEL_COLLECTION_POINT_CODE' |
                      translate}}</label>
                    <div class="col-md-8">
                      <input *ngIf="componentState.isEditable()"
                             type="text" class="form-control"
                             maxlength="{{UiConstants.maximumVarcharLength}}"
                             placeholder="{{'PARCEL_COLLECTION_POINT_CODE' | translate}}"
                             [(ngModel)]="editModel.code"
                             (ngModelChange)="removeFieldError(ParcelCollectionPoint.ValidatedField.CODE)"
                             [ngClass]="{ 'form-control-danger': hasLocalFieldError(code) || hasFieldError(ParcelCollectionPoint.ValidatedField.CODE) }"
                             name="code"
                             id="code"
                             #code="ngModel"
                             [required]="componentState.isEditable()">
                      <div class="form-control-feedback"
                           *ngIf="hasLocalFieldError(code)">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</div>
                      <div class="form-control-feedback"
                           *ngIf="hasFieldError(ParcelCollectionPoint.ValidatedField.CODE)">{{getFieldErrorText(ParcelCollectionPoint.ValidatedField.CODE)}}</div>
                      <!--Appears in detailView-->
                      <label
                        *ngIf="componentState.isReadonly()" class="form-control detail-description"
                        readonly>{{detailModel.code}}
                      </label>
                    </div>
                  </div>
                  <!--endregion Code-->

                  <!--region Delivery Method-->
                  <div class="form-group row"
                       [ngClass]="{ 'has-danger': (hasLocalFieldError(deliveryMethod) || hasFieldError(ParcelCollectionPoint.ValidatedField.DELIVERY_METHOD_ID)) }">
                    <label class="col-form-label form-control-label col-md-4 detail-title">{{'DELIVERY_METHOD' |
                      translate}}</label>
                    <div class="col-md-8">
                      <div class="mw-100">
                        <angular2-multiselect
                          [data]="deliveryMethods"
                          [settings]="dropdownSettings"
                          [(ngModel)]="relevantModel.deliveryMethod"
                          (ngModelChange)="removeFieldError(ParcelCollectionPoint.ValidatedField.DELIVERY_METHOD_ID)"
                          [ngClass]="{ 'form-control-danger': hasLocalFieldError(deliveryMethod) || hasFieldError(ParcelCollectionPoint.ValidatedField.DELIVERY_METHOD_ID) }"
                          (onRemoteSearch)="onDeliveryMethodSearch($event.target.value)"
                          name="deliveryMethod"
                          id="deliveryMethod"
                          #deliveryMethod="ngModel"
                          validateEnabledItems
                          [readonly]="!componentState.isCreateView()"
                          [required]="componentState.isEditable()">
                        </angular2-multiselect>
                      </div>
                      <div class="form-control-feedback"
                           *ngIf="hasLocalFieldError(deliveryMethod)">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</div>
                      <div class="form-control-feedback"
                           *ngIf="hasFieldError(ParcelCollectionPoint.ValidatedField.DELIVERY_METHOD_ID)">{{getFieldErrorText(ParcelCollectionPoint.ValidatedField.DELIVERY_METHOD_ID)}}</div>
                    </div>
                  </div>
                  <!--endregion Delivery Method-->

                  <!--region Opening Times-->
                  <div class="form-group row">
                    <label class="col-form-label form-control-label col-md-4 detail-title">{{'COMMON_LOCATION_OPENING_TIME_HTML_TITLE' |
                      translate}}</label>
                    <div class="col-md-8">
                      <textarea *ngIf="componentState.isEditable()"
                                type="text" class="form-control"
                                placeholder="{{'COMMON_LOCATION_OPENING_TIME_HTML_TITLE' | translate}}"
                                [(ngModel)]="editModel.openingTimes"
                                rows="{{UiConstants.textareaDescriptionRowsNumber}}"
                                name="openingTimes"
                                id="openingTimes"
                                #openingTimes="ngModel">
                      </textarea>
                      <!--Appears in detailView-->
                      <label
                        *ngIf="componentState.isReadonly()" class="form-control detail-description"
                        readonly>{{detailModel.openingTimes}}
                      </label>
                    </div>
                  </div>
                  <!--endregion Opening Times-->

                  <!--region Note-->
                  <div class="form-group row">
                    <label class="col-form-label form-control-label col-md-4 detail-title">{{'COMMON_NOTE' | translate}}</label>
                    <div class="col-md-8">
                      <input *ngIf="componentState.isEditable()"
                             type="text" class="form-control"
                             maxlength="{{UiConstants.maximumVarcharLength}}"
                             placeholder="{{'COMMON_NOTE' | translate}}"
                             [(ngModel)]="editModel.note"
                             name="note"
                             id="note"
                             #note="ngModel">
                      <!--Appears in detailView-->
                      <label
                        *ngIf="componentState.isReadonly()" class="form-control detail-description"
                        readonly>{{detailModel.note}}
                      </label>
                    </div>
                  </div>
                  <!--endregion Note-->


                  <!--Only visible on create/edit views-->
                  <div class="col-md-12 d-flex justify-content-end p-0" *ngIf="componentState.isEditable()">
                    <div>
                      <input type="button" class="btn btn-secondary" (click)="back()"
                             value="{{'COMMON_BUTTON_CANCEL'|translate}}"/>
                      <input type="submit" class="btn btn-primary ml-1" value="{{'COMMON_BUTTON_SAVE'|translate}}"/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- endregion Basic Details-->

            <!--region Postal Address and Coordinates-->
            <div class="col-xs-12 col-sm-12 col-md-6 card-custom-padding">

              <div class="card">
                <div class="card-header">
                  {{ 'COMMON_LOCATION_POSTAL_ADDRESS' | translate}}
                </div>

                <!--region Postal Address-->
                <div *ngIf="componentState.isEditable() && editModel.postalAddress" class="card-body">
                  <app-postal-address-complex
                    id="test_portal_address_component"
                    [form]="f"
                    [model]="editModel.postalAddress"
                    [readonly]="componentState.isReadonly()">
                  </app-postal-address-complex>
                </div>
                <!--Appears in detailView-->
                <div *ngIf="componentState.isReadonly()" class="card-body">
                  <label class="form-control detail-description" readonly>{{detailModel.postalAddress}}</label>
                </div>
                <!--endregion Postal Address-->

              </div>

              <div class="container-vertical-padding-half"></div>

              <div class="card" [ngStyle]="coordinateStyle">
                <div class="card-header">
                  {{ 'TASK_GEOCODING_COORDINATES' | translate}}
                </div>
                <div class="card-body">

                  <!--region Latitude-->
                  <div class="form-group row"
                       [ngClass]="{ 'has-danger': (hasLocalFieldError(latitude)) }">
                    <label class="col-form-label form-control-label col-md-4 detail-title">{{'TASK_GEOCODING_LAT' |
                      translate}}</label>
                    <div class="col-md-8">
                      <input *ngIf="componentState.isEditable()"
                             type="number" class="form-control"
                             maxlength="{{UiConstants.maximumVarcharLength}}"
                             placeholder="{{'TASK_GEOCODING_LAT' | translate}}"
                             [(ngModel)]="editModel.latitude"
                             [ngClass]="{ 'form-control-danger': hasLocalFieldError(latitude) }"
                             name="latitude"
                             id="latitude"
                             #latitude="ngModel"
                             validateOptionalLatitude
                             [required]="componentState.isEditable() && Strings.undefinedOrNonEmpty(editModel.longitude)">
                      <div class="form-control-feedback"
                           *ngIf="hasLocalFieldError(latitude)">{{'COMMON_VALIDATION_MESSAGE_INVALID_LATITUDE' | translate}}</div>
                      <!--Appears in detailView-->
                      <label
                        *ngIf="componentState.isReadonly()" class="form-control detail-description"
                        readonly>{{detailModel.latitude}}
                      </label>
                    </div>
                  </div>
                  <!--endregion Latitude-->

                  <!--region longitude-->
                  <div class="form-group row"
                       [ngClass]="{ 'has-danger': (hasLocalFieldError(longitude)) }">
                    <label class="col-form-label form-control-label col-md-4 detail-title">{{'TASK_GEOCODING_LON' |
                      translate}}</label>
                    <div class="col-md-8">
                      <input *ngIf="componentState.isEditable()"
                             type="number" class="form-control"
                             maxlength="{{UiConstants.maximumVarcharLength}}"
                             placeholder="{{'TASK_GEOCODING_LAT' | translate}}"
                             [(ngModel)]="editModel.longitude"
                             [ngClass]="{ 'form-control-danger': hasLocalFieldError(longitude) }"
                             name="longitude"
                             id="longitude"
                             #longitude="ngModel"
                             validateOptionalLongitude
                             [required]="componentState.isEditable() && Strings.undefinedOrNonEmpty(editModel.latitude)">
                      <div class="form-control-feedback"
                           *ngIf="hasLocalFieldError(longitude)">{{'COMMON_VALIDATION_MESSAGE_INVALID_LONGITUDE' | translate}}</div>
                      <!--Appears in detailView-->
                      <label
                        *ngIf="componentState.isReadonly()" class="form-control detail-description"
                        readonly>{{detailModel.longitude}}
                      </label>
                    </div>
                  </div>
                  <!--endregion longitude-->

                  <!--region Map-->
                  <div class="maps-container" *ngIf="componentState.isDetailView()">
                    <div #gmap style="flex: 1 1 auto;min-height: 300px"></div>
                  </div>
                  <!--endregion Map-->

                </div>
              </div>

            </div>
            <!--endregion Postal Address and Coordinates-->

          </form>


        </div>
        <!-- /.form-group row -->


      </div>
</div>


