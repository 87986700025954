/* eslint-disable */
import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { StateName } from '../../../app.state-names';
import { BreadcrumbParent } from '../../../shared/breadcrumb/breadcrumb/breadcrumb.component';
import { TranslateService } from '@ngx-translate/core';
import { Transition, UIRouter } from '@uirouter/angular';
import { Transport, TransportService, } from '../../../lib/transport/transport.service';
import { Dates, OffsetDateTime } from '../../../lib/util/dates';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { TabsetComponent } from 'ngx-bootstrap/tabs';
import { TransportLogService } from '../../../lib/transport/transport-log/transport-log.service';
import { DetailTab } from './detail-tab';
import { ConfigurationService } from '../../../lib/core-ext/configuration.service';
import { FormBuilder, FormGroup, NgForm, NgModel, Validators } from '@angular/forms';
import { Vehicle, VehicleService } from '../../../lib/vehicles/vehicle.service';
import { User, UserService } from '../../../lib/user.service';
import { RightResolver, RightService } from '../../../lib/right.service';
import { RightModel } from '../../../app.rights';
import {
  FieldValidationError,
  ForwardingNgFormRef, LocalFieldValidationErrors, LocalFieldValidationErrorsFactory,
  LocalFormGroupValidationErrors, OrderType,
  QueryResult,
  ResourceQueryResult
} from '../../../lib/util/services';
import { MultiselectOptionItem, SelectUtils } from '../../../util/core-utils';
import { TransportTaskDialogContainerComponent } from '../transport-task-dialog-container/transport-task-dialog-container.component';
import { Logger, LoggerFactory } from '../../../util/logger-factory';
import { TransportLogDetailModalComponent } from '../transport-log-detail-modal/transport-log-detail-modal.component';
import { ChatService } from '../../../shared/chat/chat-service.interface';
import { List, Set } from 'immutable';
import { TriggerInstance } from '../../../lib/trigger/trigger-instance.service';
import TriggerInstanceService = TriggerInstance.TriggerInstanceService;
import { UiConstants } from '../../../util/core-utils';
import { AppValidators } from '../../../util/app-validators';
import { Company, CompanyService } from '../../../lib/company/company.service';
import { ObservableErrorResourceParser } from '../../../lib/util/errors';
import { StringKey } from '../../../app.string-keys';
import { ToasterService } from '../../../fork/angular2-toaster/src/toaster.service';
import { combineLatest, Observable } from 'rxjs';
import { Angular2Multiselects } from '../../../util/multiselect';
import { Strings } from '../../../lib/util/strings';
/* eslint-enable */

@Component({
  selector: 'app-transport-detail',
  templateUrl: './transport-detail.component.html',
  styleUrls: ['./transport-detail.component.scss']
})

export class TransportDetailComponent implements OnInit, AfterViewInit, OnDestroy {

  UiConstants = UiConstants;
  Transport = Transport;
  SelectUtils = SelectUtils;

  private readonly logger: Logger;

  @ViewChild('transportShipmentList', { static: true })
  transportShipmentList: DetailTab;

  @ViewChild('transportDocumentList', { static: true })
  transportDocumentList: DetailTab;

  @ViewChild('transportLogList', { static: true })
  transportLogList: DetailTab;

  @ViewChild('transportLogDetail', { static: true })
  transportLogDetail: TransportLogDetailModalComponent;

  @ViewChild('transportMessageList', { static: true })
  transportMessageList: DetailTab;

  @ViewChild('transportTaskList', { static: true })
  transportTaskList: DetailTab;

  @ViewChild('transportPositionLogMap', { static: true })
  transportPositionLogMap: DetailTab;

  @ViewChild('transportAttachment', { static: true })
  transportAttachment: DetailTab;

  @ViewChild('taskDetailDialogContainer', { static: true })
  taskDetailDialogContainer: TransportTaskDialogContainerComponent;

  breadcrumbSelf: string;
  breadcrumbParents: BreadcrumbParent[] = [];
  compactSidebar: boolean = document.querySelector('body')!.classList.contains('sidebar-compact');

  activatedTab: TransportDetailTab = 'SHIPMENT_TASKS';

  transportId: number;
  transportBaseData: TransportBaseData = new TransportBaseData();

  postalAddressFormat: string = '';

  rightModel: RightModel = RightModel.empty();

  @ViewChild('transportModifyDialog', { static: true }) transportModifyDialog: ModalDirective;
  transportModifyDialogVisible: boolean = false;

  @ViewChild('transportBaseDataDialog', { static: true }) transportBaseDataDialog: ModalDirective;
  transportBaseDataDialogVisible: boolean = false;

  @ViewChild('f') form?: NgForm;
  transportModifyModel: TransportModifyModel = new TransportModifyModel();
  vehicleList: MultiselectOptionItem<number>[] = [];
  driverList: MultiselectDriverItem[] = [];
  transporterCompanies: MultiselectOptionItem<number>[] = [];

  @ViewChild('transportTabs', { static: true }) transportTabs: TabsetComponent;

  @ViewChild('securityGuardForm')
  securityGuardForm: NgForm;

  @ViewChild('securityGuard') securityGuard: NgModel;
  securityGuardName: string = '';

  @ViewChild('setSecurityGuardDialog', { static: true }) setSecurityGuardDialog: ModalDirective;
  setSecurityGuardDialogVisible = true;

  private validatedSecurityGuardInputs: LocalFieldValidationErrors<NgModel> =
    LocalFieldValidationErrorsFactory.empty();

  driverUser: MultiselectDriverItem[] = [];
  @ViewChild('setDriverDialog', { static: true }) setDriverDialog: ModalDirective;
  setDriverDialogVisible = true;

  vehicle: MultiselectOptionItem<number>[] = [];
  @ViewChild('setVehicleDialog', { static: true }) setVehicleDialog: ModalDirective;
  setVehicleDialogVisible = true;

  dropdownSettings?: Angular2Multiselects.Settings;

  formGroup: FormGroup;
  private submitted: boolean = false;
  private fieldErrors: FieldValidationError<Transport.ValidatedField> =
    FieldValidationError.empty<Transport.ValidatedField>();
  private formGroupValidationErrors: LocalFormGroupValidationErrors;

  constructor(private translateService: TranslateService,
              private uiRouter: UIRouter,
              private transition: Transition,
              private transportService: TransportService,
              private transportLogService: TransportLogService,
              private rightService: RightService,
              private vehicleService: VehicleService,
              private userService: UserService,
              private configurationService: ConfigurationService,
              private companyService: CompanyService,
              private toasterService: ToasterService,
              fb: FormBuilder
  ) {
    this.transportId = this.transition.params().id;
    this.logger = LoggerFactory.createLogger('TransportDetailComponent');
    this.formGroup = this.createFormGroup(fb);
    this.formGroupValidationErrors = LocalFormGroupValidationErrors.ofForm(this.createForwardingHtmlForm(), this.formGroup);
  }


  ngOnInit() {
    this.loadRightModels(() => {
      this.loadTransportBaseData(false, () => {
        if (this.rightModel.vehicleRead.hasRight()) {
          this.loadVehicles();
        }
        if (this.rightModel.userRead.hasRight()) {
          this.loadDrivers();
        }
      });
    });
    this.initBreadcrumb();
    this.initDropdown();
    this.postalAddressFormat = this.configurationService.getPostalAddressFormat();
  }

  ngAfterViewInit() {
    this.loadSecurityGuardLocalFieldValidationErrors();
    const activatedTab = this.transition.params().tabId ? this.transition.params().tabId :
      localStorage.getItem('Transport-Tab-Id') ? localStorage.getItem('Transport-Tab-Id') : this.activatedTab;
    this.activateTab(activatedTab);
  }

  ngOnDestroy() {
    localStorage.removeItem('Transport-Tab-Id');
  }

  private loadRightModels(completion: () => void) {
    this.rightService.getRightResolver().subscribe(
      (resolver: RightResolver) => {
        this.rightModel = RightModel.of(resolver);
        completion();
      }
    );
  }

  loadTransporterCompanies(q?: string) {
    this.companyService.query({
      name: q ? Strings.undefinedOrNonEmpty(q) : undefined,
      disabled: false,
      orders: Set.of({field: Company.OrderField.NAME, type: OrderType.ASC}),
      paging: {
        numberOfItems: UiConstants.autocompletePageSize,
        pageNumber: 1
      },
      noProgressBar: true
    }).subscribe(
      (result: QueryResult<Company.Company>) => {
        this.transporterCompanies = result.items.toArray().map(c => ({id: c.id, itemName: c.name}));
      }
    );
  }

  private loadTransportBaseData(withShippingDemandCount: boolean, completion: () => void) {
    this.transportService.get({
      id: this.transportId,
      withShippingDemandCount: withShippingDemandCount
    }).subscribe((transport: Transport.Transport) => {
      this.breadcrumbSelf = transport.waybillNumber;
      const transportState = Transport.transportStates.find((state) => state.state === transport.state);
      this.transportBaseData.id = transport.id + '';
      this.transportBaseData.externalId = transport.externalId;
      this.transportBaseData.waybillNumber = transport.waybillNumber;
      this.transportBaseData.safetyShipping = transport.safetyShipping;
      this.transportBaseData.creationTime = transport.creationTime;
      this.transportBaseData.openTime = transport.openTime ? transport.openTime : undefined;
      this.transportBaseData.state = transportState ? transportState.stringKey : '';
      this.transportBaseData.stateEnum = transport.state;
      this.transportBaseData.stateIconClass = transportState ? transportState.iconClass : '';
      this.transportBaseData.transporterCompany = transport.transporterCompany.name;
      this.transportBaseData.vehicle = transport.vehicle ? transport.vehicle.licencePlate : undefined;
      if (transport.vehicle) {
        this.vehicle = [];
        this.vehicle.push({
          id: transport.vehicle.id,
          itemName: transport.vehicle.licencePlate
        });
        this.transportModifyModel.vehicle = [];
        this.transportModifyModel.vehicle.push({
          id: transport.vehicle.id,
          itemName: transport.vehicle.licencePlate
        });
      }
      this.transportBaseData.driver = transport.driver ? transport.driver.personName : undefined;
      if (transport.driver) {
        this.userService.get({id: transport.driver.id}).subscribe(u => {
          const driverItem = {
            id: u.driver!.driver_id!,
            userId: u.id,
            itemName: u.person_name
          };
          this.driverUser = [];
          this.driverUser.push(driverItem);
          this.transportModifyModel.driver = [];
          this.transportModifyModel.driver.push(driverItem);
        });
      }
      this.transportBaseData.securityGuard = transport.securityGuard ? transport.securityGuard.personName : undefined;
      this.transportBaseData.startTime = transport.startTime ? transport.startTime : undefined;
      this.transportBaseData.closeTime = transport.closeTime ? transport.closeTime : undefined;
      this.transportBaseData.comment = transport.comment ? transport.comment : '-';
      this.transportModifyModel.transportId = transport.id;
      this.transportModifyModel.externalId = transport.externalId;
      this.transportModifyModel.transporterCompany = [];
      this.transportModifyModel.transporterCompany.push({
        id: transport.transporterCompany.id,
        itemName: transport.transporterCompany.name
      });
      this.transportModifyModel.transporterCompanyUserSettable = transport.shippingDemandCount === 0;
      this.transportModifyModel.safetyShipping = transport.safetyShipping;
      this.transportModifyModel.safetyShippingUserSettable = transport.safetyShippingUserSettable;
      this.transportModifyModel.securityGuard = transport.securityGuard ? transport.securityGuard.personName : '';
      this.transportModifyModel.comment = transport.comment ? transport.comment : '';
      this.securityGuardName = this.transportModifyModel.securityGuard;
      completion();
    });
  }

  private loadVehiclesObservable(q?: string): Observable<QueryResult<Vehicle.Vehicle>> {
    return this.vehicleService.query({
      licencePlate: q ? Strings.undefinedOrNonEmpty(q) : undefined,
      disabled: false,
      companyIds: Set.of(this.transportModifyModel.transporterCompanyId!),
      orders: Set.of({field: Vehicle.OrderField.LICENCE_PLATE, type: OrderType.ASC}),
      paging: {
        numberOfItems: UiConstants.autocompletePageSize,
        pageNumber: 1
      },
      noProgressBar: true
    });
  }

  private loadVehicles(q?: string) {
    this.loadVehiclesObservable(q).subscribe((result: QueryResult<Vehicle.Vehicle>) => {
      this.vehicleList = result.items.toArray().map(v => ({id: v.id, itemName: v.licencePlate + ' (' + v.company.name + ')'}));
    });
  }

  private loadDriversObservable(q?: string): Observable<ResourceQueryResult<User>> {
    return this.userService.query({
      person_name: q ? Strings.undefinedOrNonEmpty(q) : undefined,
      disabled: false,
      is_driver: true,
      company_id: this.transportModifyModel.transporterCompanyId!.toString(),
      order: '+person_name',
      number_of_items: UiConstants.autocompletePageSize,
      page_number: 1,
      no_progress_bar: true
    });
  }

  private loadDrivers(q?: string) {
    this.loadDriversObservable(q).subscribe((result: ResourceQueryResult<User>) => {
      this.driverList = result.items.map(u => ({id: u.driver!.driver_id!, userId: u.id, itemName: u.person_name}));
    });
  }

  onTransporterCompanyChange() {
    this.removeFieldError(Transport.ValidatedField.TRANSPORTER_COMPANY);
    if (this.transportModifyModel.transporterCompanyId) {
      combineLatest(
        this.loadVehiclesObservable(),
        this.loadDriversObservable(),
        (vehicles: QueryResult<Vehicle.Vehicle>,
         drivers: ResourceQueryResult<User>) => {
          return {
            vehicles: vehicles,
            drivers: drivers
          };
        }
      ).subscribe((result) => {
        this.driverList = result.drivers.items.map(u => ({id: u.driver!.driver_id!, userId: u.id, itemName: u.person_name}));
        this.vehicleList = result.vehicles.items.toArray().map(v => ({id: v.id, itemName: v.licencePlate + ' (' + v.company.name + ')'}));
        const driverInCompany = this.transportModifyModel.driverId
          && this.driverList.find(d => d.id === this.transportModifyModel.driverId);
        const vehicleInCompany = this.transportModifyModel.vehicleId
          && this.vehicleList.find(v => v.id === this.transportModifyModel.vehicleId);
        if (!driverInCompany) {
          this.transportModifyModel.driver = [];
        }
        if (!vehicleInCompany) {
          this.transportModifyModel.vehicle = [];
        }
      });
    }
  }

  reload() {
    this.loadTransportBaseData(false, () => {
      if (this.rightModel.vehicleRead.hasRight()) {
        this.loadVehicles();
      }
      if (this.rightModel.userRead.hasRight()) {
        this.loadDrivers();
      }
    });
  }

  activateTab(tab: TransportDetailTab) {
    this.activatedTab = tab;
    switch (tab) {
      case ('SHIPMENT_TASKS'):
        this.transportTaskList.initComponent();
        this.transportTabs.tabs[0].active = true;
        break;
      case ('SHIPMENTS'):
        this.transportShipmentList.initComponent();
        this.transportTabs.tabs[1].active = true;
        break;
      case ('COMMON_MESSAGES'):
        this.transportMessageList.initComponent();
        this.transportTabs.tabs[2].active = true;
        break;
      case ('COMMON_DOCUMENTS'):
        this.transportDocumentList.initComponent();
        this.transportTabs.tabs[3].active = true;
        break;
      case ('TRANSPORT_HISTORY'):
        this.transportLogList.initComponent();
        this.transportTabs.tabs[4].active = true;
        break;
      case ('TRANSPORT_POSITION_LOG_MAP'):
        this.transportPositionLogMap.initComponent();
        this.transportTabs.tabs[5].active = true;
        break;
      case ('TRANSPORT_ATTACHMENT'):
        this.transportAttachment.initComponent();
        this.transportTabs.tabs[6].active = true;
        break;
      default:
        break;
    }
    localStorage.removeItem('Transport-Tab-Id');
    localStorage.setItem('Transport-Tab-Id', tab);
  }

  initBreadcrumb() {
    this.translateService.get('MENU_NAVBAR_MENU_TRANSPORT').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.TRANSPORT_LIST});
      }
    );
  }

  initDropdown() {
    this.dropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(true)
      .enableSearchFilter(true)
      .enableCheckAll(false)
      .remoteSearch(true)
      .build();
  }

//  TransportLogDetail
  showTransportLogDetailDialog(id: number) {
    this.transportLogDetail.show(id);
  }


  modifyTransport() {
    this.submitted = true;
    this.formGroup.updateValueAndValidity();
    if (this.formGroup.invalid) {
      return;
    }
    this.transportService.update({
      transportId: this.transportModifyModel.transportId,
      transporterCompanyId: this.transportModifyModel.transporterCompanyId!,
      externalId: this.transportModifyModel.externalId,
      vehicleId: this.transportModifyModel.vehicleId,
      driverId: this.transportModifyModel.driverId,
      safetyShipping: this.transportModifyModel.safetyShipping,
      comment: this.transportModifyModel.comment
    }).subscribe(
      (response) => {
        this.closeTransportModifyDialog();
        this.loadTransportBaseData(false, () => {
          if (this.rightModel.vehicleRead.hasRight()) {
            this.loadVehicles();
          }
          if (this.rightModel.userRead.hasRight()) {
            this.loadDrivers();
          }
        });
      }
    );
  }

  private setSecurityGuard() {
    if (this.hasSecurityGuardLocalFieldError()) {
      return;
    }
    this.transportService.setSecurityGuard({
      id: this.transportId,
      personName: this.securityGuardName
    }).subscribe(() => {
      this.setSecurityGuardDialog.hide();
      this.reload();
    })
  }

  private setDriver() {
    this.transportService.setDriver({
      id: this.transportId,
      driverId: this.driverUser[0].id
    }).subscribe(() => {
      this.setDriverDialog.hide();
      this.reload();
    })
  }

  private setVehicle() {
    this.transportService.setVehicle({
      id: this.transportId,
      vehicleId: this.vehicle[0].id
    }).subscribe(() => {
      this.setVehicleDialog.hide();
      this.reload();
    })
  }

  openTransportModifyDialog() {
    this.loadTransportBaseData(true, () => {
      if (this.rightModel.vehicleRead.hasRight()) {
        this.loadVehicles();
      }
      if (this.rightModel.userRead.hasRight()) {
        this.loadDrivers();
      }
      if (this.rightModel.companyRead.hasRight()) {
        this.loadTransporterCompanies();
      }
      this.transportModifyDialogVisible = true;
      this.transportModifyDialog.show();
    })
  }

  closeTransportModifyDialog() {
    this.transportModifyDialogVisible = false;
    this.transportModifyDialog.hide();
  }

  openTransportBaseDataDialog() {
    this.transportBaseDataDialogVisible = true;
    this.transportBaseDataDialog.show();
  }

  closeTransportBaseDataDialog() {
    this.transportBaseDataDialogVisible = false;
    this.transportBaseDataDialog.hide();
  }

  hasLocalFieldError(formControlName?: string, errorCode?: string): boolean {
    return this.formGroupValidationErrors.hasFieldError(formControlName, errorCode);
  }

  hasFieldError(field?: Transport.ValidatedField): boolean {
    return this.fieldErrors.hasError(field);
  }

  removeFieldError(field: Transport.ValidatedField) {
    this.fieldErrors = this.fieldErrors.removeError(field);
  }

  getFieldErrorText(field: Transport.ValidatedField): string {
    return this.fieldErrors.getErrorText(field);
  }

  private createFormGroup(fb: FormBuilder): FormGroup {
    return fb.group(
      {
        externalId: fb.control(
          {value: this.transportModifyModel.externalId},
          [
            Validators.required,
          ]
        ),
        vehicle: fb.control(
          {value: this.transportModifyModel.vehicle},
          AppValidators.required({
            value: () => {
              return this.transportModifyModel.vehicleId ? this.transportModifyModel.vehicleId.toString() : '';
            },
            disabled: () => {
              return this.underPlanning;
            }
          })
        ),
        driver: fb.control(
          {value: this.transportModifyModel.driver},
          AppValidators.required({
            value: () => {
              return this.transportModifyModel.driverId ? this.transportModifyModel.driverId.toString() : '';
            },
            disabled: () => {
              return this.underPlanning;
            }
          }),
        ),
        transporterCompany: fb.control(
          {value: this.transportModifyModel.transporterCompanyId},
          Validators.required
        )
      }
    );
  }

  private createForwardingHtmlForm() {
    return new ForwardingNgFormRef({
      formFn: () => {
        return this.form;
      }
    });
  }

  private loadSecurityGuardLocalFieldValidationErrors() {
    const validatedInputs = List.of(this.securityGuard);
    this.validatedSecurityGuardInputs = LocalFieldValidationErrorsFactory.ofFormFields(this.securityGuardForm, validatedInputs);
  }

  hasSecurityGuardLocalFieldError(field?: NgModel): boolean {
    return this.validatedSecurityGuardInputs.hasLocalError(field);
  }

  showSetSecurityGuardDialog() {
    this.setSecurityGuardDialogVisible = true;
    this.setSecurityGuardDialog.show();
  }

  closeSetSecurityGuardDialog() {
    this.setSecurityGuardDialogVisible = false;
    this.setSecurityGuardDialog.hide();
  }

  showSetDriverDialog() {
    this.setDriverDialogVisible = true;
    this.setDriverDialog.show();
  }

  closeSetDriverDialog() {
    this.setDriverDialogVisible = false;
    this.setDriverDialog.hide();
  }

  showSetVehicleDialog() {
    this.setVehicleDialogVisible = true;
    this.setVehicleDialog.show();
  }

  closeSetVehicleDialog() {
    this.setVehicleDialogVisible = false;
    this.setVehicleDialog.hide();
  }

  onModalHidden() {
    this.securityGuardForm.resetForm();
  }

  getMessageSendEnabled(): boolean {
    return !(this.transportBaseData.stateEnum === 'CLOSED')
  }

  getChatService(): ChatService {
    return this.transportService;
  }

  get underPlanning(): boolean {
    return this.transportBaseData.stateEnum === 'UNDER_PLANNING'
  }

  get underReplanning(): boolean {
    return this.transportBaseData.stateEnum === 'UNDER_REPLANNING'
  }

  getTransportService(): TriggerInstanceService {
    return this.transportService;
  }

}

export class TransportBaseData {
  id: string = '';
  externalId: string = '';
  waybillNumber: string = '';
  safetyShipping: boolean = false;
  creationTime: OffsetDateTime = Dates.emptyOffsetDateTime();
  openTime: OffsetDateTime | undefined = undefined;
  state: string = '';
  stateEnum: Transport.TransportState = 'OPEN';
  stateIconClass: string = '';
  transporterCompany: string = '';
  vehicle?: string;
  driver?: string;
  securityGuard?: string;
  startTime: OffsetDateTime | undefined = undefined;
  closeTime: OffsetDateTime | undefined = undefined;
  comment: string = '';
}

export type TransportDetailTab = 'SHIPMENTS' | 'SHIPMENT_TASKS' | 'COMMON_MESSAGES' | 'COMMON_DOCUMENTS' | 'TRANSPORT_HISTORY'
  | 'TRANSPORT_POSITION_LOG_MAP' | 'TRANSPORT_ATTACHMENT';

class TransportModifyModel {
  transportId: number;
  externalId: string;
  transporterCompany: MultiselectOptionItem<number>[] = [];
  transporterCompanyUserSettable: boolean = false;
  vehicle: MultiselectOptionItem<number>[] = [];
  driver: MultiselectDriverItem[] = [];
  safetyShipping: boolean = false;
  safetyShippingUserSettable: boolean = false;
  securityGuard: string = '';
  comment: string = '';

  get transporterCompanyId(): number | undefined {
    return this.transporterCompany.length === 1 ? this.transporterCompany[0].id : undefined;
  }

  get vehicleId(): number | undefined {
    return this.vehicle.length === 1 ? this.vehicle[0].id : undefined;
  }

  get driverId(): number | undefined {
    return this.driver.length === 1 ? this.driver[0].id : undefined;
  }
}

interface MultiselectDriverItem {
  id: number;
  itemName: string;
  userId: number;
}
