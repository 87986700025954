<div bsModal [config]="UiConstants.modalConfig" #importDialog="bs-modal" class="modal fade" tabindex="-1" role="dialog"
     aria-labelledby="myModalLabel"
     aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document" *ngIf="importDialogVisible">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">{{'WORKFLOW_IMPORT_DIALOG_TITLE' | translate}}</h4>
        <button type="button" class="close" (click)="closeImportDialog()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row" *ngIf="!importDnDVisible">
          <div class="col-md-4">
            <label
              class="mw-100 col-form-label form-control-label">{{'COMMON_NAME' | translate}}</label>
          </div>
          <div class="col-md-8">
            <label
              class="mw-100 col-form-label form-control-label">{{model.fileName}}</label>
          </div>
        </div>
        <app-file-upload
          *ngIf="importDnDVisible"
          [uploadPath]="''"
          [uploadOnDrop]="false"
          (onFileDropped)="onImportableWorkflowSelected($event)">
        </app-file-upload>
        <div class="col-sm-12 mt-1 p-0" *ngIf="model.importResult">
          <div class="alert alert-success" *ngIf="model.importResult === 'SUCCESS'">
            <span class="alert-title">{{'ALERT_TEXT_UPLOAD_SUCCESSFUL' | translate}}</span>
            <p>{{'ALERT_TEXT_SUCCESSFULLY_UPLOADED' | translate}}</p>
          </div>
          <div class="alert alert-danger" *ngIf="model.importResult === 'ERROR'">
            <span class="alert-title">{{'ALERT_TEXT_UPLOAD_FAILED' | translate}}</span>
            <p>{{uploadGlobalErrors}}</p>
          </div>
          <div class="alert alert-danger" *ngIf="model.importResult === 'FORMAT_ERROR'">
            <span class="alert-title">{{'ALERT_TEXT_FILE_ERROR' | translate}}</span>
          </div>
          <div class="alert alert-warning" *ngIf="model.importResult === 'EXISTING_WORKFLOW_WARNING'">
            <span class="alert-title">{{'ALERT_EXISTING_WORKFLOW_WARNING' | translate }}</span>
            <p>{{model.existingWorkflowString}}</p>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="closeImportDialog()">
          {{'COMMON_BUTTON_CANCEL' | translate}}
        </button>
        <button type="button" class="btn btn-primary" (click)="importWorkflow()"
                [disabled]="!model.canImport">{{'COMMON_BUTTON_IMPORT' | translate}}
        </button>
      </div>
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div><!-- /.modal -->
