import { Column } from './column.model';
import { TaskRecordBoardModel } from './task-record-board.model';
import { TaskRecordStateMachine } from '../../../../../lib/task/task-record-statemachine';
import { ConfigModel } from '../../../../../util/task-record-utils';
import { TaskRecord, TaskRecordService } from '../../../../../lib/task/task-record.service';
import { Set } from 'immutable';
import { OrderType } from '../../../../../lib/util/services';

export class TaskColumn extends Column<TaskRecordBoardModel> {

  private _presentableStates: TaskRecordStateMachine.State[];

  constructor(private baseState: TaskRecordStateMachine.State, private allStates: TaskRecordStateMachine.State[], private configModel: ConfigModel, private taskRecordService: TaskRecordService) {
    super(TaskRecordStateMachine.taskRecordStates.get(baseState).stringKey, baseState);
    this.dragEnabledTo = TaskRecordStateMachine.StateMachine.getAvailableStatesFromState(this.baseState);
    this._presentableStates = allStates.filter(s => TaskRecordStateMachine.taskRecordStates.get(s).isPresentable);
  }

  get stateSet(): Set<TaskRecordStateMachine.State> {
    return Set.of(...this.allStates);
  }

  public canLoadMore(): boolean {
    return this.maxPages >=this.currentPage + 1;
  }
  public loadMore(): void {
    if (this.canLoadMore()) {
      this.currentPage++;
      this.loadItems();
    }
  }

  public reloadItems() {
    this.currentPage = 1;
    this.loadItems();
  }

  public loadItems(): void {
    if (this.currentPage === 1) {
      this.items = [];
    }
    this.taskRecordService.globalQuery({
      state: Set.of(...this._presentableStates),
      paging: {pageNumber: this.currentPage, numberOfItems: 10},
      orders: Set.of({field: TaskRecord.OrderField.UPDATE_TIME, type: OrderType.DESC}),
      fields: Set.of('id', 'task', 'name', 'update_time', 'state', 'place_of_consumption', 'contract_number', 'customer_record', 'assignee', 'user_group_id', 'user_group_name')
    }).subscribe(result => {
      result.items.toArray().map(t => TaskRecordBoardModel.fromTaskRecord(t, this.configModel)).forEach(t => {
        this.items.push(t);
      });
      this.maxPages = Math.min(10, result.pagingResult.numberOfPages);
    })
  }

}
