import { AfterViewInit, Component, OnInit } from '@angular/core';
import { InvoiceBalance, InvoiceBalanceService } from '../../../../lib/invoice/balance/invoice-balance.service';
import { RightModel } from '../../../../app.rights';
import { RightResolver, RightService } from '../../../../lib/right.service';
import { MatDialog } from '@angular/material/dialog';
import { InvoiceBalanceCreateDialogComponent } from '../invoice-balance-create-dialog/invoice-balance-create-dialog.component';
import { Set } from 'immutable';
import { OrderType, QueryResult } from '../../../../lib/util/services';
import { QueryFieldModel } from '../../../../util/core-utils';
import { MatConfirmDialogComponent, MatConfirmDialogData } from '../../../../shared/mat-confirm-dialog/mat-confirm-dialog.component';
import { AlertType } from '../../../../shared/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-invoice-balance-detail',
  templateUrl: './invoice-balance-detail.component.html',
  styleUrls: ['./invoice-balance-detail.component.scss']
})
export class InvoiceBalanceDetailComponent implements OnInit, AfterViewInit {

  rightModel: RightModel = RightModel.empty();

  queryModel: QueryFieldModel<InvoiceBalance.OrderField> = new QueryFieldModel(InvoiceBalance.OrderField.STARTING_DATE, OrderType.DESC);

  balance: InvoiceBalance.InvoiceBalance | undefined;

  constructor(private invoiceBalanceService: InvoiceBalanceService,
              private dialog: MatDialog,
              private rightService: RightService) {
    this.queryModel.itemsPerPage = 1;
  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.loadRightModels();
    this.loadBalance();
  }

  private loadRightModels() {
    this.rightService.getRightResolver().subscribe(
      (resolver: RightResolver) => {
        this.rightModel = RightModel.of(resolver);
      }
    );
  }

  loadBalance(pageNumber?: number) {
    if (this.rightModel.invoiceBalanceRead.hasRight()) {
      const requestedPage = pageNumber ? pageNumber : this.queryModel.currentPage;
      this.invoiceBalanceService.query({
        orders: Set.of(this.queryModel.getOrder()),
        paging: {
          pageNumber: requestedPage,
          numberOfItems: this.queryModel.itemsPerPage
        }
      }).subscribe((result: QueryResult<InvoiceBalance.InvoiceBalance>) => {
        this.balance = result.items.size > 0 ? result.items.get(0) : undefined;
        this.queryModel.currentPage = requestedPage;
        this.queryModel.totalNumberOfItems = result.pagingResult.totalNumberOfItems;
        this.queryModel.currentNumberOfItems = result.pagingResult.currentNumberOfItems;
      });
    }
  }

  createNewBalance() {
    const dialogRef = this.dialog.open(InvoiceBalanceCreateDialogComponent, {
      width: '60%',
      panelClass: 'custom-dialog-container'
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.loadBalance();
    });

  }

  deleteBalance() {
    const dialogConfig: MatConfirmDialogData = {
      titleKey: 'CONFIRM_DIALOG_TITLE_DELETE',
      messageKey: 'CONFIRM_DIALOG_MESSAGE_DELETE',
      alertType: AlertType.DANGER
    };
    const dialogRef = this.dialog.open(MatConfirmDialogComponent, {
      data: dialogConfig
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.invoiceBalanceService.delete({
          id: this.balance!.id
        }).subscribe(value => {
          this.loadBalance(this.queryModel.currentPage === 1 ? 1 : this.queryModel.currentPage - 1);
        });
      }
    });
  }

  previousBalance() {
    this.loadBalance(this.queryModel.currentPage - 1);
  }

  nextBalance() {
    this.loadBalance(this.queryModel.currentPage + 1);
  }

  hasPrevious() {
    return this.queryModel.currentPage > 1;
  }

  hasNext() {
    return this.queryModel.currentNumberOfItems > this.queryModel.itemsPerPage * this.queryModel.currentPage;
  }

  isActive() {
    return this.queryModel.currentPage === 1;
  }
}
