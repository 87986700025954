<div class="modal-content" id="task-record-list-approve-state-dialog-content">
  <div class="modal-header">
    <p class="modal-title"><strong>{{'TASK_LIST_APPROVE_TITLE' | translate}}</strong></p>
    <button type="button" class="close" (click)="close(false)" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <mat-dialog-content>
      <div *ngIf="stateChangeModel">
        <div>
          <label>{{ (stateChangeModel.isRevert ? 'TASK_LIST_APPROVE_REVERT_MESSAGE' : 'TASK_LIST_APPROVE_STATE_CHANGE_MESSAGE') | translate: {
            count: stateChangeModel.count,
            stateChange:
              getStateChangeString()
          } }}</label>
        </div>
        <div class="form-group mt-1">
          <label>{{'COMMON_COMMENT' | translate}}</label>
          <!--TODO: current limit is 255 characters, this should be changed when server is updated-->
          <input type="text" class="form-control" placeholder="{{'COMMON_COMMENT' | translate}}"
                 [(ngModel)]="stateChangeModel.massStateChangeMessage"
                 [maxlength]="255">
        </div>
        <div class="form-group mt-1" *ngIf="stateChangeModel.canCheckUp">
          <label>{{'TASK_RECORD_ARCHIVE_CHECK_UP_DATE' | translate}}</label>
          <div class="input-group">
            <input placeholder="{{'TASK_RECORD_ARCHIVE_CHECK_UP_DATE' | translate}}" type="text"
                   class="form-control"
                   [(ngModel)]="stateChangeModel.checkUpDate"
                   [ngModelOptions]="{standalone: true}"
                   ngbDatepicker #checkUpDate="ngbDatepicker"
                   validateLocalDate>
            <span class="add-on input-group-addon-gray" (click)="checkUpDate.toggle()">
                              <i class="icomoon icomoon-calendar"></i>
                            </span>
          </div>
        </div>
      </div>
    </mat-dialog-content>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary-green" (click)="close(false)">
      {{'COMMON_BUTTON_CANCEL' | translate}}
    </button>
    <button type="button" class="btn btn-primary-green"
            id="task-record-list-approve-state-save"
            [ladda]="loading" [disabled]="loading"
            (click)="tryStateChange()">{{'COMMON_BUTTON_SAVE_CHANGES' |
      translate}}
    </button>
  </div>
</div><!-- /.modal-content -->
