/* eslint-disable */
import { Injectable } from '@angular/core';
import { List, Map } from 'immutable';
/* eslint-enable */

@Injectable()
export class SettingsService {

  private static instance: SettingsService | null  = null;

  private languages: Languages;

  public static getInstance(): SettingsService | null {
    return SettingsService.instance;
  }

  constructor() {
    SettingsService.instance = this;
    this.languages = new Languages();
  }

  public getDefaultLocaleCode(): string {
    return Languages.defaultLanguage;
  }

  public getLocaleCode(): string {
    const localeCode = LocaleStorage.getInstance().get();
    if (localeCode) {
      return localeCode;
    }
    return this.languages.getPreferredLocaleCode();
  }

  public setLocaleCode(localeCode: string) {
    LocaleStorage.getInstance().set(localeCode);
  }

  public getLanguageCode(): string {
    return this.getLocaleCode().substring(0, 2);
  }

  public getCountryCode(): string {
    return this.getLocaleCode().substring(2);
  }

  public getAvailableLanguages(): List<Language> {
    return List.of(...Languages.languages.toArray()
      .sort(Languages.sortByNativeName)
      .sort(Languages.sortByCurrentLanguage(this.getLocaleCode()))
    );
  }

  public isCurrentLanguage(l: Language): boolean {
    return l.localeCode === this.getLocaleCode();
  }

}

export class Languages {

  public static readonly defaultLanguage = 'hu-HU';

  public static readonly languages = List.of(
    {
      localeCode: 'hu-HU',
      nativeName: 'Magyar',
      englishName: 'Hungarian'
    },
    {
      localeCode: 'en-US',
      nativeName: 'English',
      englishName: 'English (US)'
    }
  );

  public static readonly abbreviations: Map<string, string> = Map.of(
    'en', 'en-US',
    'hu', 'hu-HU'
  );

  public static readonly sortByNativeName = (l: Language, r: Language) => {
    return l.nativeName.localeCompare(r.nativeName);
  };

  public static readonly sortByCurrentLanguage = (currentLocaleCode: string) => (l: Language, r: Language) => {
    if (l.localeCode === currentLocaleCode) {
      return -1;
    }
    if (r.localeCode === currentLocaleCode) {
      return 1;
    }
    return 0;
  };

  constructor() {
  }

  public getPreferredLocaleCode(): string {
    // moved browser default to another function
    return Languages.defaultLanguage;
  }

  // not used anymore
  public getBrowserPreferredLocalCode(): string {
    const nav: any = navigator;
    if (nav && nav.languages) {
      const browserLanguageOrLocales: string[] = nav.languages;
      let result: string | null = null;
      browserLanguageOrLocales.forEach((browserLanguageOrLocale) => {
        if (result) {
          return;
        }
        result = this.findLanguage(browserLanguageOrLocale);
      });
      if (result) {
        return result;
      }
    }
    if (nav && nav.language) {
      const browserLanguageOrLocale = nav.language;
      const result = this.findLanguage(browserLanguageOrLocale);
      if (result) {
        return result;
      }
    }
    return Languages.defaultLanguage;
  }

  private findLanguage(browserLanguageOrLocaleCode?: string): string | null {
    const browserLocaleCode = this.completeLocale(browserLanguageOrLocaleCode);
    if (!browserLocaleCode) {
      return null;
    }
    const browserLocaleCodeFound = Languages.languages.map((l: Language) => {
      return l!.localeCode;
    }).contains(browserLocaleCode);
    if (browserLocaleCodeFound) {
      return browserLocaleCode;
    }
    return null;
  }

  private completeLocale(languageOrLocaleCode?: string): string | null {
    if (!languageOrLocaleCode) {
      return null;
    }
    languageOrLocaleCode = languageOrLocaleCode.replace('_', '-');
    if (languageOrLocaleCode.indexOf('-') !== -1) {
      const parts = languageOrLocaleCode.split('-');
      if (parts.length > 2) {
        return null;
      }
      return parts[0].toLowerCase() + '-' + parts[1].toUpperCase();
    }
    const locale = Languages.abbreviations.get(languageOrLocaleCode);
    if (!locale) {
      return null;
    }
    return locale;
  }

}

export interface Language {
  readonly localeCode: string;
  readonly nativeName: string;
  readonly englishName: string;
}

class LocaleStorage {

  private static readonly KEY_SELECTED_LOCALE = 'selected_locale';

  private static instance = new LocaleStorage();

  private localeCode: string | null = null;

  public static getInstance(): LocaleStorage {
    return LocaleStorage.instance;
  }

  public get(): string | null {
    try {
      return localStorage.getItem(LocaleStorage.KEY_SELECTED_LOCALE);
    }
    catch (error) {
      return this.localeCode;
    }
  }

  public set(l: string): void {
    try {
      this.localeCode = l;
      localStorage.setItem(LocaleStorage.KEY_SELECTED_LOCALE, l);
    }
    catch (error) {
      // Storage is disabled in the browser settings. No problem, we use in-memory store too.
    }
  }

  private constructor() {
  }

}
