import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Icon } from '../../../lib/task/icon.service';
import { MatDialog } from '@angular/material/dialog';
import { IconSelectorDialogComponent, IconSelectorDialogResult } from '../icon-selector-dialog/icon-selector-dialog.component';

@Component({
  selector: 'app-icon-selector',
  templateUrl: './icon-selector.component.html',
  styleUrls: ['./icon-selector.component.scss']
})
export class IconSelectorComponent implements OnInit {


  @Input()
  selectedIcon?: Icon.Icon;

  @Output()
  selectedIconChange: EventEmitter<Icon.Icon> = new EventEmitter<Icon.Icon>();

  @Input()
  readonly?: boolean = false;

  constructor(private dialog: MatDialog) {
  }

  ngOnInit(): void {
  }

  showIconDialog() {
    if (this.readonly) {
      return;
    }
    const dialogRef = this.dialog.open(IconSelectorDialogComponent, {
      width: '70%',
      maxHeight: '100vh',
      data: {previousSelectedIcon: this.selectedIcon}
    });
    dialogRef.afterClosed().subscribe((result: IconSelectorDialogResult) => {
      this.selectedIconChange.emit(result.selectedIcon);
    });
  }

}
