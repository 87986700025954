<div class="breadcrumb-container" [ngClass]="{ 'breadcrumb-container-compact': compactSidebar }" id="breadcrumb">
  <div class="breadcrumb-component">
    <app-breadcrumb
      [parents]="breadcrumbParents"
      [self]="breadcrumbSelf">
    </app-breadcrumb>
  </div>
  <div class="breadcrumb-menu d-flex">
    <div *ngIf="(componentState.isEditView() && editModel.carriageByDemanderCompany && editModel.state === 'WAITING_FOR_CARRIAGE')
                  || (componentState.isDetailView() && detailModel.carriageByDemanderCompanyValue && detailModel.state === 'WAITING_FOR_CARRIAGE')"
         class="breadcrumb-button-icon-container cursor-pointer"
         (click)="generateCarriageTagPdfDocument()">
      <a class="btn-setting" title="{{'SHIPPING_DEMAND_GENERATE_CARRIAGE_TAG' | translate}}">
        <i class="icomoon icomoon-process-order-stock-outtake-check"></i>
        <span class="breadcrumb-button-text-visible">{{'SHIPPING_DEMAND_GENERATE_CARRIAGE_TAG' | translate}}</span>
      </a>
    </div>
    <div *ngIf="(componentState.isEditView() && editModel.carriageByDemanderCompany && editModel.state !== 'WAITING_FOR_CARRIAGE')
                  || (componentState.isDetailView() && detailModel.carriageByDemanderCompanyValue && detailModel.state !== 'WAITING_FOR_CARRIAGE')"
         class="breadcrumb-button-icon-container cursor-pointer"
         (click)="downloadCarriageReceipt()">
      <a class="btn-setting" title="{{'SHIPPING_DEMAND_DOWNLOAD_CARRIAGE_RECEIPT' | translate}}">
        <i class="icomoon icomoon-download"></i>
        <span class="breadcrumb-button-text-visible">{{'SHIPPING_DEMAND_DOWNLOAD_CARRIAGE_RECEIPT' | translate}}</span>
      </a>
    </div>
    <div *ngIf="componentState.isEditView() && rightModel.shippingDemandSplit.hasRight() && editModel && !editModel.carriageByDemanderCompany"
      class="breadcrumb-button-icon-container negative-margin-right cursor-pointer"
      (click)="splitDialog.show()">
      <a class="btn-setting" title="{{'SHIPPING_DEMAND_SPLIT_DEMAND' | translate}}">
        <i class="icomoon icomoon-split"></i>
        <span class="breadcrumb-button-text-visible">{{'SHIPPING_DEMAND_SPLIT_DEMAND' | translate}}</span>
      </a>
    </div>
  </div>
</div>
<div class="breadcrumb-placeholder"></div><div class="container-horizontal-padding container-vertical-padding animated fadeIn">
  <responsive-tabset class="fullscreen_tab">
    <responsive-tab heading="{{'COMMON_CARD_HEADER_BASE_DATA'|translate}}">
      <div class="container-custom-padding animated fadeIn">
        <form class="form-group row mx-0" (ngSubmit)="f.form.valid && submit()" #f="ngForm" novalidate>
          <!--region First column-->
          <div class="col-xs-12 col-sm-12 col-md-6 card-custom-padding">
            <!--BaseData card-->
            <div class="card">
              <div class="card-header">
                {{
                'SHIPPING_DEMAND_BASE_DATA' | translate
                }}
              </div>
              <div class="card-body">
                <div class="form-horizontal bordered-row">
                  <!--region ExternalId-->
                  <div class="form-group row"
                       [ngClass]="{ 'has-danger': hasLocalFieldError(externalId) || fieldErrors.external_id }">
                    <label class="col-form-label form-control-label col-md-4 detail-title" [class.required-field-label]="componentState.isEditView()">
                      {{'SHIPPING_DEMAND_EXTERNAL_ID' | translate}}
                    </label>
                    <div class="col-md-8">
                      <input *ngIf="componentState.isEditable()"
                             type="text" class="form-control"
                             maxlength="{{UiConstants.maximumVarcharLength}}"
                             placeholder="{{'SHIPPING_DEMAND_EXTERNAL_ID' | translate}}"
                             [(ngModel)]="editModel.externalId"
                             [ngClass]="{ 'form-control-danger': hasLocalFieldError(externalId) || fieldErrors.external_id}"
                             name="externalhasLocalFieldErrorId"
                             id="externalId"
                             #externalId="ngModel"
                             [required]="componentState.isEditView()">
                      <div class="form-control-feedback"
                           *ngIf="fieldErrors.external_id">{{fieldErrors.external_id.text}}</div>
                      <div class="form-control-feedback"
                           *ngIf="hasLocalFieldError(externalId)">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</div>
                      <!--Appears in detailView-->
                      <label
                        *ngIf="componentState.isReadonly()" class="form-control detail-description"
                        readonly>{{detailModel.externalId}}
                      </label>
                    </div>
                  </div>
                  <!--endregion ExternalId-->

                  <!--region Deadline-->
                  <div class="form-group row" *ngIf="isEnabledField(EditableField.DEADLINE)">
                    <label
                      class="col-form-label form-control-label col-md-4 detail-title">{{'SHIPPING_DEMAND_LABEL_DEADLINE' |
                        translate}}</label>
                    <div class="col-md-8">
                      <div *ngIf="componentState.isEditable()"
                           class="clearfix row">
                        <div class="col-sm">
                          <div>
                            <div class="row">
                              <div class="input-group col-md-6 mb-1 mb-md-0">
                                <input placeholder="{{'DATE_TIME_PICKER_PLACEHOLDER_DATE' | translate}}" type="text"
                                       class="form-control"
                                       [(ngModel)]="editModel.deadlineDate"
                                       [ngModelOptions]="{standalone: true}"
                                       ngbDatepicker #date_picker="ngbDatepicker"
                                       validateLocalDate>
                                <span class="add-on input-group-addon-gray" (click)="date_picker.toggle()">
                              <i class="icomoon icomoon-calendar"></i>
                             </span>
                              </div>
                              <div class="input-group col-md-6">
                                <input placeholder="{{'DATE_TIME_PICKER_PLACEHOLDER_TIME' | translate}}" type="text"
                                       class="form-control hidden-value-when-disabled"
                                       [(ngModel)]="editModel.deadlineTime"
                                       [ngModelOptions]="{standalone: true}"
                                       [seconds]="false"
                                       [allowEmptyValue]="false"
                                       [disabled]="editModel.deadlineDate === null || editModel.deadlineDate === undefined"
                                       ngbTimepicker #agreed_time_picker="ngbTimepicker" [toggleButtonId]="'agreed_time_toggle_button'"
                                       [spinners]="true" [hourStep]="1" [minuteStep]="10">
                                <span class="add-on input-group-addon-gray"
                                      id="agreed_time_toggle_button"
                                      (click)="agreed_time_picker.toggle()">
                              <i class="icomoon icomoon-time"></i>
                            </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!--Appears in detailView-->
                      <label
                        *ngIf="componentState.isReadonly()" class="form-control detail-description"
                        readonly>{{detailModel.deadline | date:'short'}}
                      </label>
                    </div>
                  </div>
                  <!--endregion Deadline-->

                  <!--region DemandCategory-->
                  <div class="form-group row" *ngIf="isEnabledField(EditableField.DEMAND_CATEGORY)">
                    <label
                      class="col-form-label form-control-label col-md-4 detail-title">{{'SHIPPING_DEMAND_DEMAND_CATEGORY' |
                      translate}}</label>
                    <div class="col-md-8">
                      <input
                        *ngIf="componentState.isEditable()"
                        type="text"
                        class="form-control"
                        maxlength="{{UiConstants.maximumVarcharLength}}"
                        placeholder="{{'SHIPPING_DEMAND_DEMAND_CATEGORY' | translate}}"
                        [(ngModel)]="editModel.demandCategory"
                        name="demandCategory"
                        id="demandCategory">
                      <!--Appears in detailView-->
                      <label
                        *ngIf="componentState.isReadonly()" class="form-control detail-description"
                        readonly>{{detailModel.demandCategory}}
                      </label>
                    </div>
                  </div>
                  <!--endregion DemandCategory-->

                  <!--region Comment-->
                  <div class="form-group row" *ngIf="isEnabledField(EditableField.COMMENT)">
                    <label class="col-form-label form-control-label col-md-4 detail-title">{{'COMMON_COMMENT' |
                      translate}}</label>
                    <div class="col-md-8">
                      <textarea *ngIf="componentState.isEditable()"
                                type="text"
                                class="form-control"
                                maxlength="{{UiConstants.maximumVarcharLength}}"
                                placeholder="{{'COMMON_COMMENT' | translate}}"
                                [(ngModel)]="editModel.comment"
                                rows="{{UiConstants.textareaDescriptionRowsNumber}}"
                                name="comment"
                                id="comment">
                      </textarea>
                      <!--Appears in detailView-->
                      <label
                        *ngIf="componentState.isReadonly()" class="form-control detail-description"
                        readonly>{{detailModel.comment}}
                      </label>
                    </div>
                  </div>
                  <!--endregion Comment-->

                  <!--region DemanderCompany-->
                  <div class="form-group row" *ngIf="isEnabledField(EditableField.DEMANDER)" [ngClass]="{ 'has-danger': hasLocalFieldError(demanderC) }">
                    <label
                      class="col-form-label form-control-label col-md-4 detail-title">{{'SHIPPING_DEMAND_SHIPMENT_DEMANDER_COMPANY' | translate}}</label>
                    <div class="col-md-8">
                      <div *ngIf="componentState.isEditable()">
                        <angular2-multiselect [data]="demanderCompanies" [settings]="dropdownSettingsForDemanderCompany"
                                              class="form-control"
                                              [ngClass]="{'cursor-not-allowed': isNewCarriage}"
                                              [(ngModel)]="editModel.demanderCompany"
                                              (onRemoteSearch)="onDemanderCompanySearch($event.target.value)"
                                              #demanderC="ngModel"
                                              name="demanderCompanies"
                                              id="demanderCompanies"
                                              [required]="(componentState.isCreateView() || componentState.isCloneView()) && editModel.carriageByDemanderCompany">
                        </angular2-multiselect>
                        <div class="form-control-feedback"
                             *ngIf="hasLocalFieldError(demanderC)">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</div>
                      </div>
                      <!--Appears in detailView-->
                      <label
                        *ngIf="componentState.isReadonly()" class="form-control detail-description"
                        readonly>{{detailModel.demanderCompany}}
                      </label>
                    </div>
                  </div>
                  <!--endregion DemanderCompany-->

                  <!--region TransporterCompany-->
                  <div class="form-group row" *ngIf="isEnabledField(EditableField.TRANSPORTER)">
                    <label
                      class="col-form-label form-control-label col-md-4 detail-title">{{'SHIPPING_DEMAND_SHIPMENT_TRANSPORT_COMPANY' | translate}}</label>
                    <div class="col-md-8">
                      <angular2-multiselect *ngIf="componentState.isEditable()"
                                            [data]="transporterCompanies" [settings]="dropdownSettingsForTransporterCompany"
                                            class="form-control"
                                            [(ngModel)]="editModel.transporterCompany"
                                            (onRemoteSearch)="onTransporterCompanySearch($event.target.value)"
                                            #transporterC="ngModel"
                                            name="transporterCompanies"
                                            id="transporterCompanies">
                      </angular2-multiselect>
                      <!--Appears in detailView-->
                      <label
                        *ngIf="componentState.isReadonly()" class="form-control detail-description"
                        readonly>{{detailModel.transporterCompany}}
                      </label>
                    </div>
                  </div>
                  <!--endregion TransporterCompany-->

                  <!--region DemandGroupSerial-->
                  <div class="form-group row" *ngIf="componentState.isEditView() || componentState.isReadonly()">
                    <label
                      class="col-form-label form-control-label col-md-4 detail-title">{{'SHIPPING_DEMAND_DEMAND_GROUP_SERIAL' | translate}}</label>
                    <div class="col-md-8">
                      <label
                        *ngIf="componentState.isReadonly()" class="form-control detail-description"
                        readonly>{{detailModel.demandGroupSerial}}
                      </label>
                      <label
                        *ngIf="componentState.isEditView()" class="form-control detail-description"
                        readonly>{{editModel.demandGroupSerial}}
                      </label>
                    </div>
                  </div>
                  <!--endregion DemandGroupSerial-->

                  <!--region DemandSerial-->
                  <div class="form-group row" *ngIf="componentState.isEditView() || componentState.isReadonly()">
                    <label
                      class="col-form-label form-control-label col-md-4 detail-title">{{'SHIPPING_DEMAND_DEMAND_SERIAL' | translate}}</label>
                    <div class="col-md-8">
                      <label
                        *ngIf="componentState.isReadonly()" class="form-control detail-description"
                        readonly>{{detailModel.demandSerial}}
                      </label>
                      <label
                        *ngIf="componentState.isEditView()" class="form-control detail-description"
                        readonly>{{editModel.demandSerial}}
                      </label>
                    </div>
                  </div>
                  <!--endregion DemandSerial-->

                </div>
              </div>
            </div>
            <!--end BaseData card-->

            <!--Source card-->
            <div class="container-vertical-padding">
              <div class="card">
                <div class="card-header">
                  {{'SHIPPING_DEMAND_SOURCE' | translate}}
                </div>
                <div class="card-body">
                  <app-shipping-demand-address
                    *ngIf="componentState.isEditable()"
                    [form]="f"
                    [model]="editModel.sourceModel"
                    [componentState]="componentState"
                    [rightModel]="rightModel"
                    [editableFieldModel]="editableFieldModel"
                    [disabled]="isNewCarriage">
                  </app-shipping-demand-address>
                  <!--Detail-->
                  <div *ngIf="componentState.isReadonly()">
                    <!--Address-->
                    <div *ngIf="detailModel.sourceModel.addressType.length === 1 && detailModel.sourceModel.addressType[0].id === 'NEW_ADDRESS'">
                      <!--AddressName-->
                      <div class="form-group row">
                        <div class="col-md-4">
                          <label
                            class="col-form-label mw-100 form-control-label">{{'SHIPPING_DEMAND_ADDRESS_NAME'|translate}}</label>
                        </div>
                        <div class="col-md-8">
                          <label
                            *ngIf="componentState.isReadonly()" class="form-control detail-description"
                            readonly>{{detailModel.sourceModel.addressName}}
                          </label>
                        </div>
                      </div>
                      <!-- ./AddressName-->
                      <!--Country-->
                      <div class="form-group row">
                        <div class="col-md-4">
                          <label
                            class="col-form-label mw-100 form-control-label">{{'SHIPPING_DEMAND_ADDRESS_COUNTRY'|translate}}</label>
                        </div>
                        <div class="col-md-8">
                          <label
                            *ngIf="componentState.isReadonly()" class="form-control detail-description"
                            readonly>{{detailModel.selectedSourceCountry}}
                          </label>
                        </div>
                      </div>
                      <!-- ./Country-->
                      <!--Address-->
                      <div class="form-group row">
                        <div class="col-md-4">
                          <label
                            class="col-form-label mw-100 form-control-label">{{'SHIPPING_DEMAND_ADDRESS_TYPE_LOCATION'|translate}}</label>
                        </div>
                        <div class="col-md-8">
                          <label
                            *ngIf="componentState.isReadonly()" class="form-control detail-description"
                            readonly>{{detailModel.selectedSourceAddress}}
                          </label>
                        </div>
                      </div>
                      <ng-container *ngTemplateOutlet="geocodingSourceStatusTemplate"></ng-container>
                      <!-- ./Address-->
                    </div>
                    <!-- ./Address-->
                    <!--Location-->
                    <div *ngIf="detailModel.sourceModel.addressType.length === 1 && detailModel.sourceModel.addressType[0].id === 'LOCATION'">
                      <!--Location-->
                      <div class="form-group row">
                        <div class="col-md-4">
                          <label
                            class="col-form-label mw-100 form-control-label">{{'SHIPPING_DEMAND_ADDRESS_TYPE_LOCATION'|translate}}</label>
                        </div>
                        <div class="col-md-8">
                          <label
                            *ngIf="componentState.isReadonly()" class="form-control detail-description"
                            readonly>{{detailModel.sourceModel.locationItem.length > 0 ? detailModel.sourceModel.locationItem[0].itemName : ''}}
                          </label>
                        </div>
                      </div>
                      <!--./Location-->
                      <!--Stock-->
                      <div class="form-group row" *ngIf="detailModel.selectedSourceStockName !== '' && isEnabledField(EditableField.SOURCE_STOCK)">
                        <div class="col-md-4">
                          <label
                            class="col-form-label mw-100 form-control-label">{{'SHIPPING_DEMAND_ADDRESS_STOCK'|translate}}</label>
                        </div>
                        <div class="col-md-8">
                          <label
                            *ngIf="componentState.isReadonly()" class="form-control detail-description"
                            readonly>{{detailModel.selectedSourceStockName}}
                          </label>
                        </div>
                      </div>
                      <!--./Stock-->
                      <ng-container *ngTemplateOutlet="geocodingSourceStatusTemplate"></ng-container>
                    </div>
                    <!-- ./Location-->
                    <ng-template #geocodingSourceStatusTemplate>
                      <!--Geocoding-->
                      <div class="form-group row" *ngIf="detailModel.sourceModel.geocodeStatus">
                        <label class="col-form-label form-control-label col-md-4 detail-title"> {{ 'TASK_GEOCODING_STATUS' |
                          translate }} </label>
                        <div class="col-md-8">
                          <label *ngIf="detailModel.sourceModel.geocodeStatus === 'DONE'" class="form-control detail-description" readonly>
                            <i class="icomoon icon-large {{detailModel.sourceModel.geocodeStatusIcon}}"></i>
                            {{detailModel.sourceModel.geocodeStatusCoordinateText}}
                          </label>
                          <label *ngIf="detailModel.sourceModel.geocodeStatus !== 'DONE'" class="form-control detail-description" readonly>
                            <i class="icomoon icon-large {{detailModel.sourceModel.geocodeStatusIcon}}"></i>
                            {{detailModel.sourceModel.geocodeStatusNameKey | translate}}
                          </label>
                        </div>
                      </div>
                      <!--./Geocoding-->
                    </ng-template>
                  </div>
                  <!-- ./Detail-->
                </div>
              </div>
            </div>
            <!--end Source card-->

            <!--Transferor card-->
            <div class="card">
              <div class="card-header">
                {{
                  'SHIPPING_DEMAND_TRANSFEROR' | translate
                }}
              </div>
              <div class="card-body">
                <div class="form-horizontal bordered-row">

                  <!--region Transferor name-->
                  <div class="form-group row" [ngClass]="{ 'has-danger': hasLocalFieldError(transferorName) }">
                    <label class="col-form-label form-control-label col-md-4 detail-title" [class.required-field-label]="componentState.isEditable() && !editModel.sourceModel.isLocation()">
                      {{'COMMON_NAME' | translate}}
                    </label>
                    <div class="col-md-8">
                      <input *ngIf="componentState.isEditable()"
                             type="text" class="form-control"
                             maxlength="{{UiConstants.maximumVarcharLength}}"
                             placeholder="{{'COMMON_NAME' | translate}}"
                             [(ngModel)]="editModel.transferorName"
                             [ngClass]="{ 'form-control-danger': hasLocalFieldError(transferorName) }"
                             name="transferorName"
                             id="transferorName"
                             #transferorName="ngModel"
                             [required]="!editModel.sourceModel.isLocation()">
                      <div class="form-control-feedback"
                           *ngIf="hasLocalFieldError(transferorName)">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</div>
                      <!--Appears in detailView-->
                      <label
                        *ngIf="componentState.isReadonly()" class="form-control detail-description"
                        readonly>{{detailModel.transferorName}}
                      </label>
                    </div>
                  </div>
                  <!--endregion Transferor name-->

                  <!--region Transferor phone-->
                  <div class="form-group row" [ngClass]="{ 'has-danger': hasLocalFieldError(transferorPhoneNumber) }" *ngIf="isEnabledField(EditableField.TRANSFEROR_PHONE)">
                    <label
                      class="col-form-label form-control-label col-md-4 detail-title">{{'COMMON_CONTACT_PERSON_PHONE_NUMBER'
                        | translate}}</label>
                    <div class="col-md-8">
                      <div class="input-group" *ngIf="componentState.isEditable()">
                        <input type="text"
                               class="form-control"
                               placeholder="{{'COMMON_CONTACT_PERSON_PHONE_NUMBER' | translate}}"
                               [(ngModel)]="editModel.transferorPhoneNumber"
                               [ngClass]="{ 'form-control-danger': hasLocalFieldError(transferorPhoneNumber) }"
                               name="transferorPhoneNumber"
                               id="transferorPhoneNumber"
                               #transferorPhoneNumber="ngModel"
                               validatePhoneNumber>
                        <span class="input-group-addon-gray icomoon icomoon-phone"
                              id="transferor_phone_number_basic-addon2"></span>
                      </div>
                      <div class="form-control-feedback"
                           *ngIf="hasLocalFieldError(transferorPhoneNumber)">{{'COMMON_VALIDATION_MESSAGE_PHONE' | translate}}</div>
                      <!--Appears in detailView-->
                      <label
                        *ngIf="componentState.isReadonly()" class="form-control detail-description"
                        readonly>{{detailModel.transferorPhoneNumber}}
                      </label>
                    </div>
                  </div>
                  <!--endregion Transferor phone-->

                  <!--region Transferor email-->
                  <div class="form-group row" [ngClass]="{ 'has-danger': hasLocalFieldError(transferorEmailAddress) }" *ngIf="isEnabledField(EditableField.TRANSFEROR_EMAIL)">
                    <label
                      class="col-form-label form-control-label col-md-4 detail-title">{{'COMMON_CONTACT_PERSON_EMAIL_ADDRESS'
                        | translate}}</label>
                    <div class="col-md-8">
                      <div class="input-group" *ngIf="componentState.isEditable()">
                        <input type="text"
                               class="form-control"
                               placeholder="{{'COMMON_CONTACT_PERSON_EMAIL_ADDRESS' | translate}}"
                               [(ngModel)]="editModel.transferorEmailAddress"
                               [ngClass]="{ 'form-control-danger': hasLocalFieldError(transferorEmailAddress) }"
                               name="transferorEmailAddress"
                               id="transferorEmailAddress"
                               #transferorEmailAddress="ngModel"
                               validateOptionalEmail>
                        <span class="input-group-addon-gray icomoon icomoon-message-unread"
                              id="transferor_email_address_basic-addon"></span>
                      </div>
                      <div class="form-control-feedback"
                           *ngIf="hasLocalFieldError(transferorEmailAddress)">{{'COMMON_VALIDATION_MESSAGE_EMAIL' | translate}}</div>
                      <!--Appears in detailView-->
                      <label
                        *ngIf="componentState.isReadonly()" class="form-control detail-description"
                        readonly>{{detailModel.transferorEmailAddress}}
                      </label>
                    </div>
                  </div>
                  <!--endregion Transferor email-->

                  <!--region Transferor license category-->
                  <div class="form-group row" *ngIf="isEnabledField(EditableField.TRANSFEROR_LICENSE_CATEGORY)">
                    <label
                      class="col-form-label form-control-label col-md-4 detail-title">{{'SHIPPING_DEMAND_TRANSFEROR_LICENSE_CATEGORY' |
                        translate}}</label>
                    <div class="col-md-8">
                      <input *ngIf="componentState.isEditable()"
                             type="text"
                             class="form-control"
                             maxlength="{{UiConstants.maximumVarcharLength}}"
                             placeholder="{{'SHIPPING_DEMAND_TRANSFEROR_LICENSE_CATEGORY' | translate}}"
                             [(ngModel)]="editModel.transferorLicenseCategory"
                             name="transferorLicenseCategory"
                             id="transferorLicenseCategory">
                      <!--Appears in detailView-->
                      <label
                        *ngIf="componentState.isReadonly()" class="form-control detail-description"
                        readonly>{{detailModel.transferorLicenseCategory}}
                      </label>
                    </div>
                  </div>
                  <!--endregion Transferor license category-->

                  <!--region Transferor license number-->
                  <div class="form-group row" *ngIf="isEnabledField(EditableField.TRANSFEROR_LICENSE_NUMBER)">
                    <label
                      class="col-form-label form-control-label col-md-4 detail-title">{{'SHIPPING_DEMAND_TRANSFEROR_LICENSE_NUMBER' |
                        translate}}</label>
                    <div class="col-md-8">
                      <input *ngIf="componentState.isEditable()"
                             type="text"
                             class="form-control"
                             maxlength="{{UiConstants.maximumVarcharLength}}"
                             placeholder="{{'SHIPPING_DEMAND_TRANSFEROR_LICENSE_NUMBER' | translate}}"
                             [(ngModel)]="editModel.transferorLicenseNumber"
                             name="transferorLicenseNumber"
                             id="transferorLicenseNumber">
                      <!--Appears in detailView-->
                      <label
                        *ngIf="componentState.isReadonly()" class="form-control detail-description"
                        readonly>{{detailModel.transferorLicenseNumber}}
                      </label>
                    </div>
                  </div>
                  <!--endregion Transferor license number-->

                  <!--region Transferor comment-->
                  <div class="form-group row" *ngIf="isEnabledField(EditableField.TRANSFEROR_COMMENT)">
                    <label class="col-form-label form-control-label col-md-4 detail-title">{{'COMMON_COMMENT' |
                        translate}}</label>
                    <div class="col-md-8">
                      <input *ngIf="componentState.isEditable()"
                             type="text"
                             class="form-control"
                             placeholder="{{'COMMON_COMMENT' | translate}}"
                             [(ngModel)]="editModel.transferorComment"
                             name="transferorComment"
                             id="transferorComment">
                      <!--Appears in detailView-->
                      <label
                        *ngIf="componentState.isReadonly()" class="form-control detail-description"
                        readonly>{{detailModel.transferorComment}}
                      </label>
                    </div>
                  </div>
                  <!--endregion Transferor comment-->

                </div>
              </div>
            </div>
            <!--end Transferor card-->

          </div>
          <!-- end First column-->

          <!-- Second column-->
          <div class="col-xs-12 col-sm-12 col-md-6 card-custom-padding">

            <!--ShippmentData card-->
            <div class="card">
              <div class="card-header">
                {{
                  'SHIPPING_DEMAND_SHIPMENT_DATA' | translate
                }}
              </div>
              <div class="card-body">
                <div class="form-horizontal bordered-row">

                  <!--region Carriage-->
                  <div class="form-group row" *ngIf="carriageFlag !== 'OFF'">
                    <div class="col-md-4">
                      <label
                        class="col-form-label mw-100 form-control-label">{{'SHIPPING_DEMAND_SHIPMENT_CARRIAGE'|translate}}</label>
                    </div>
                    <div class="col-md-8">
                      <label *ngIf="componentState.isEditable()"
                             [ngClass]="{'cursor-not-allowed': !(componentState.isCreateView() || componentState.isCloneView()) || carriageFlag === 'FORCE'}"
                             class="switch switch-text switch-info mb-0">
                        <input type="checkbox" class="switch-input"
                               [ngModelOptions]="{standalone: true}"
                               [(ngModel)]="editModel.carriageByDemanderCompany"
                               [disabled]="!(componentState.isCreateView() || componentState.isCloneView()) || carriageFlag === 'FORCE'">
                        <span class="switch-label" data-on="&#xe957;" data-off="&#xe98e;"></span>
                        <span class="switch-handle"></span>
                      </label>
                      <!--Appears in detailView-->
                      <label
                        *ngIf="componentState.isReadonly()" class="form-control detail-description"
                        readonly>{{detailModel.carriageByDemanderCompany | translate}}
                      </label>
                    </div>
                  </div>
                  <!--endregion Carriage-->

                  <!--region SafetyShipping-->
                  <div class="form-group row" *ngIf="isEnabledField(EditableField.SAFETY_SHIPPING)">
                    <div class="col-md-4">
                      <label
                        class="col-form-label mw-100 form-control-label">{{'SHIPPING_DEMAND_SHIPMENT_SAFETY_SHIPPING'|translate}}</label>
                    </div>
                    <div class="col-md-8">
                      <label *ngIf="componentState.isEditable()"
                             class="switch switch-text switch-info mb-0">
                        <input type="checkbox" class="switch-input"
                               [ngModelOptions]="{standalone: true}"
                               [(ngModel)]="editModel.safetyShipping">
                        <span class="switch-label" data-on="&#xe957;" data-off="&#xe98e;"></span>
                        <span class="switch-handle"></span>
                      </label>
                      <!--Appears in detailView-->
                      <label
                        *ngIf="componentState.isReadonly()" class="form-control detail-description"
                        readonly>{{detailModel.safetyShipping | translate}}
                      </label>
                    </div>
                  </div>
                  <!--endregion SafetyShipping-->

                  <!--region EkaerRequired-->
                  <div class="form-group row" *ngIf="isEnabledField(EditableField.EKAER_REQUIRED)">
                    <div class="col-md-4">
                      <label
                        class="col-form-label mw-100 form-control-label">{{'SHIPPING_DEMAND_SHIPMENT_EKAER_REQUIRED'|translate}}</label>
                    </div>
                    <div class="col-md-8">
                      <label *ngIf="componentState.isEditable()"
                             class="switch switch-text switch-info mb-0">
                        <input type="checkbox" class="switch-input"
                               [ngModelOptions]="{standalone: true}"
                               [(ngModel)]="editModel.ekaerRequired">
                        <span class="switch-label" data-on="&#xe957;" data-off="&#xe98e;"></span>
                        <span class="switch-handle"></span>
                      </label>
                      <!--Appears in detailView-->
                      <label
                        *ngIf="componentState.isReadonly()" class="form-control detail-description"
                        readonly>{{detailModel.ekaerRequired | translate}}
                      </label>
                    </div>
                  </div>
                  <!--endregion EkaerRequired-->

                  <!--region EkaerId-->
                  <div class="form-group row" *ngIf="isEnabledField(EditableField.EKAER_ID)">
                    <label
                      class="col-form-label form-control-label col-md-4 detail-title">{{'SHIPPING_DEMAND_SHIPMENT_EKAER_ID' |
                        translate}}</label>
                    <div class="col-md-8">
                      <input
                        *ngIf="componentState.isEditable()"
                        type="text" class="form-control"
                        maxlength="{{UiConstants.maximumVarcharLength}}"
                        placeholder="{{'SHIPPING_DEMAND_SHIPMENT_EKAER_ID' | translate}}"
                        [(ngModel)]="editModel.ekaerId"
                        name="ekaerId"
                        id="ekaerId">
                      <!--Appears in detailView-->
                      <label
                        *ngIf="componentState.isReadonly()" class="form-control detail-description"
                        readonly>{{detailModel.ekaerId}}
                      </label>
                    </div>
                  </div>
                  <!--endregion EkaerId-->

                  <!--region TourRoute-->
                  <div class="form-group row" *ngIf="isEnabledField(EditableField.TOUR_ROUTE)">
                    <label
                      class="col-form-label form-control-label col-md-4 detail-title">{{'SHIPPING_DEMAND_SEARCH_TOUR_ROUTE' |
                        translate}}</label>
                    <div class="col-md-8">
                      <input
                        *ngIf="componentState.isEditable()"
                        type="text" class="form-control"
                        maxlength="{{UiConstants.maximumVarcharLength}}"
                        placeholder="{{'SHIPPING_DEMAND_SEARCH_TOUR_ROUTE' | translate}}"
                        [(ngModel)]="editModel.tourRoute"
                        name="tourRoute"
                        id="tourRoute">
                      <!--Appears in detailView-->
                      <label
                        *ngIf="componentState.isReadonly()" class="form-control detail-description"
                        readonly>{{detailModel.tourRoute}}
                      </label>
                    </div>
                  </div>
                  <!--endregion TourRoute-->

                  <!--region sourceSystemName-->
                  <div class="form-group row" *ngIf="isEnabledField(EditableField.SOURCE_SYSTEM_NAME)">
                    <label
                      class="col-form-label form-control-label col-md-4 detail-title">{{'SHIPPING_DEMAND_SEARCH_SOURCE_SYSTEM_NAME' |
                        translate}}</label>
                    <div class="col-md-8">
                      <input
                        *ngIf="componentState.isEditable()"
                        type="text"
                        class="form-control"
                        maxlength="{{UiConstants.maximumVarcharLength}}"
                        placeholder="{{'SHIPPING_DEMAND_SEARCH_SOURCE_SYSTEM_NAME' | translate}}"
                        [(ngModel)]="editModel.sourceSystemName"
                        name="sourceSystemName"
                        id="sourceSystemName"
                      >
                      <!--Appears in detailView-->
                      <label
                        *ngIf="componentState.isReadonly()" class="form-control detail-description"
                        readonly>{{detailModel.sourceSystemName}}
                      </label>
                    </div>
                  </div>
                  <!--endregion sourceSystemName-->
                </div>
              </div>
            </div>
            <!--end ShippmentData card-->

            <div class="container-vertical-padding-half"></div>

            <!--Shipment properties card-->
            <div class="card">
              <div class="card-header">
                {{
                  'SHIPPING_DEMAND_SHIPMENT_PROPERTIES' | translate
                }}
              </div>
              <div class="card-body">
                <div class="form-horizontal bordered-row">

                  <!--region Packaging attributes-->
                  <div class="form-group row" *ngIf="isEnabledField(EditableField.PACKAGING_ATTRIBUTES)">
                    <div class="col-md-4">
                      <label
                        class="col-form-label mw-100 form-control-label">{{'SHIPPING_DEMAND_PACKAGING_ATTRIBUTES'|translate}}</label>
                    </div>
                    <div class="col-md-8">
                      <angular2-multiselect *ngIf="componentState.isEditable()"
                                            [data]="packagingAttributes" [settings]="dropdownSettingsForPackagingAttributes"
                                            class="form-control"
                                            [(ngModel)]="editModel.packagingAttributes"
                                            #packagingAttributesInput="ngModel"
                                            name="packagingAttributes"
                                            id="packagingAttributes">
                      </angular2-multiselect>
                      <!--Appears in detailView-->
                      <label
                        *ngIf="componentState.isReadonly()" class="form-control detail-description"
                        readonly>{{detailModel.packagingAttributes | translate}}
                      </label>
                    </div>
                  </div>
                  <!--endregion Packaging attributes-->

                  <!--region WeightInGram-->
                  <div class="form-group row" *ngIf="isEnabledField(EditableField.WEIGHT_IN_GRAM)"
                       [ngClass]="{ 'has-danger': weightIsTooHigh}">
                    <label class="col-form-label form-control-label col-md-4 detail-title">{{'ORDER_LABEL_WEIGHT'
                        | translate}}</label>
                    <div class="col-md-8">
                      <div class="row" *ngIf="componentState.isEditable()">
                        <div class="col-md-8 padding-15-15-for-col-md pr-md-0 mb-md-0">
                          <input type="text" class="form-control"
                                 placeholder="{{'ORDER_LABEL_WEIGHT' | translate}}"
                                 [textMask]="{mask: InputMask.NATURAL_INTEGER_WITH_THOUSAND_SEPARATOR, guide: false}"
                                 [(ngModel)]="editModel.weightInGram"
                                 [ngClass]="{ 'form-control-danger': weightIsTooHigh}"
                                 name="weightInGram"
                                 id="weightInGram">
                          <div class="form-control-feedback"
                               *ngIf="weightIsTooHigh">{{'SHIPPING_DEMAND_ITEM_WEIGHT_MAX' | translate}}</div>
                        </div>
                        <!-- /.col-md-8 -->
                        <div class="col-md-4 padding-15-15-for-col-md mb-0">
                          <select class="form-control" [compareWith]="SelectUtils.compareObjects"
                                  [(ngModel)]="packageWeightUnit"
                                  id="weightUnit" name="weightUnit"
                                  #weightUnit="ngModel" [ngModelOptions]="{standalone: true}">
                            <option *ngFor="let item of selectableWeightUnits" [ngValue]="item">{{item}}</option>
                          </select>
                        </div>
                        <!-- /.col-md-4 -->
                      </div>
                      <!--Appears in detailView-->
                      <label
                        *ngIf="componentState.isReadonly()" class="form-control detail-description"
                        readonly>{{detailModel.weightInGram}}
                      </label>
                    </div>
                  </div>
                  <!--endregion weightInGram-->

                  <!--region Cash on delivery-->
                  <div class="form-group row" *ngIf="isEnabledField(EditableField.CASH_ON_DELIVERY_PRICE)">
                    <label class="col-form-label form-control-label col-md-4 detail-title">{{'ORDER_LABEL_CASH_ON_DELIVERY_PRICE'
                        | translate}}</label>
                    <div class="col-md-8">
                      <div class="row" *ngIf="componentState.isEditable()">
                        <div class="col-md-8 padding-15-15-for-col-md pr-md-0 mb-md-0">
                          <input type="text" class="form-control"
                                 placeholder="{{'ORDER_LABEL_CASH_ON_DELIVERY_PRICE' | translate}}"
                                 [textMask]="{mask: InputMask.NATURAL_INTEGER_WITH_THOUSAND_SEPARATOR, guide: false}"
                                 [(ngModel)]="editModel.cashOnDeliveryPriceAmount"
                                 [ngModelOptions]="{standalone: true}">
                        </div>
                        <!-- /.col-md-8 -->
                        <div class="col-md-4 padding-15-15-for-col-md">
                          <select class="form-control" [compareWith]="SelectUtils.compareStrings"
                                  [(ngModel)]="editModel.cashOnDeliveryPriceCurrencyCode"
                                  [ngModelOptions]="{standalone: true}">
                            <option *ngFor="let item of selectableCashOnDeliveryCurrencies" [ngValue]="item">{{item}}</option>
                          </select>
                        </div>
                        <!-- /.col-md-4 -->
                      </div>
                      <!--Appears in detailView-->
                      <label
                        *ngIf="componentState.isReadonly()" class="form-control detail-description"
                        readonly>{{detailModel.cashOnDeliveryPrice}}
                      </label>
                    </div>
                  </div>
                  <!--endregion Cash on delivery-->

                  <!--region Insurance-->
                  <div class="form-group row" *ngIf="isEnabledField(EditableField.INSURANCE_PRICE)">
                    <label class="col-form-label form-control-label col-md-4 detail-title">{{'SHIPPING_DEMAND_SHIPMENT_INSURANCE'
                        | translate}}</label>
                    <div class="col-md-8">
                      <div class="row" *ngIf="componentState.isEditable()">
                        <div class="col-md-8 padding-15-15-for-col-md pr-md-0 mb-md-0">
                          <input type="text" class="form-control"
                                 placeholder="{{'SHIPPING_DEMAND_SHIPMENT_INSURANCE' | translate}}"
                                 [textMask]="{mask: InputMask.NATURAL_INTEGER_WITH_THOUSAND_SEPARATOR, guide: false}"
                                 [(ngModel)]="editModel.insurancePriceAmount"
                                 [ngModelOptions]="{standalone: true}">
                        </div>
                        <!-- /.col-md-8 -->
                        <div class="col-md-4 padding-15-15-for-col-md">
                          <select class="form-control" [compareWith]="SelectUtils.compareStrings"
                                  [(ngModel)]="editModel.insurancePriceCurrencyCode"
                                  [ngModelOptions]="{standalone: true}">
                            <option *ngFor="let item of selectableInsuranceCurrencies" [ngValue]="item">{{item}}</option>
                          </select>
                        </div>
                        <!-- /.col-md-4 -->
                      </div>
                      <!--Appears in detailView-->
                      <label
                        *ngIf="componentState.isReadonly()" class="form-control detail-description"
                        readonly>{{detailModel.insurancePrice}}
                      </label>
                    </div>
                  </div>
                  <!--endregion Insurance-->
                </div>
              </div>
            </div>
            <!--end Properties card-->

            <!--Destination card-->
            <div class="container-vertical-padding">
              <div class="card">
                <div class="card-header">
                  {{'SHIPPING_DEMAND_DESTINATION' | translate}}
                </div>
                <div class="card-body">
                  <app-shipping-demand-address
                    *ngIf="componentState.isEditable()"
                    [form]="f"
                    [model]="editModel.destinationModel"
                    [componentState]="componentState"
                    [rightModel]="rightModel"
                    [editableFieldModel]="editableFieldModel"
                    [disabled]="isNewCarriage">
                  </app-shipping-demand-address>
                  <!--Detail-->
                  <div *ngIf="componentState.isReadonly()">
                    <!--Address-->
                    <div *ngIf="detailModel.destinationModel.addressType.length === 1 && detailModel.destinationModel.addressType[0].id === 'NEW_ADDRESS'">
                      <!--AddressName-->
                      <div class="form-group row">
                        <div class="col-md-4">
                          <label
                            class="col-form-label mw-100 form-control-label">{{'SHIPPING_DEMAND_ADDRESS_NAME'|translate}}</label>
                        </div>
                        <div class="col-md-8">
                          <label
                            *ngIf="componentState.isReadonly()" class="form-control detail-description"
                            readonly>{{detailModel.destinationModel.addressName}}
                          </label>
                        </div>
                      </div>
                      <!-- ./AddressName-->
                      <!--Country-->
                      <div class="form-group row">
                        <div class="col-md-4">
                          <label
                            class="col-form-label mw-100 form-control-label">{{'SHIPPING_DEMAND_ADDRESS_COUNTRY'|translate}}</label>
                        </div>
                        <div class="col-md-8">
                          <label
                            *ngIf="componentState.isReadonly()" class="form-control detail-description"
                            readonly>{{detailModel.selectedDestinationCountry}}
                          </label>
                        </div>
                      </div>
                      <!-- ./Country-->
                      <!--Address-->
                      <div class="form-group row">
                        <div class="col-md-4">
                          <label
                            class="col-form-label mw-100 form-control-label">{{'SHIPPING_DEMAND_ADDRESS_TYPE_LOCATION'|translate}}</label>
                        </div>
                        <div class="col-md-8">
                          <label
                            *ngIf="componentState.isReadonly()" class="form-control detail-description"
                            readonly>{{detailModel.selectedDestinationAddress}}
                          </label>
                        </div>
                      </div>
                      <!-- ./Address-->
                      <ng-container *ngTemplateOutlet="geocodingDestinationStatusTemplate"></ng-container>
                    </div>
                    <!-- ./Address-->
                    <!--Location-->
                    <div *ngIf="detailModel.destinationModel.addressType.length === 1 && detailModel.destinationModel.addressType[0].id === 'LOCATION'">
                      <!--Location-->
                      <div class="form-group row">
                        <div class="col-md-4">
                          <label
                            class="col-form-label mw-100 form-control-label">{{'SHIPPING_DEMAND_ADDRESS_TYPE_LOCATION'|translate}}</label>
                        </div>
                        <div class="col-md-8">
                          <label
                            *ngIf="componentState.isReadonly()" class="form-control detail-description"
                            readonly>{{detailModel.destinationModel.locationItem.length > 0 ? detailModel.destinationModel.locationItem[0].itemName : ''}}
                          </label>
                        </div>
                      </div>
                      <!--./Location-->
                      <!--Stock-->
                      <div class="form-group row" *ngIf="detailModel.selectedDestinationStockName !== '' && isEnabledField(EditableField.DESTINATION_STOCK)">
                        <div class="col-md-4">
                          <label
                            class="col-form-label mw-100 form-control-label">{{'SHIPPING_DEMAND_ADDRESS_STOCK'|translate}}</label>
                        </div>
                        <div class="col-md-8">
                          <label
                            *ngIf="componentState.isReadonly()" class="form-control detail-description"
                            readonly>{{detailModel.selectedDestinationStockName}}
                          </label>
                        </div>
                      </div>
                      <!--./Stock-->
                      <ng-container *ngTemplateOutlet="geocodingDestinationStatusTemplate"></ng-container>
                    </div>
                    <!-- ./Location-->
                    <ng-template #geocodingDestinationStatusTemplate>
                      <!--Geocoding-->
                      <div class="form-group row" *ngIf="detailModel.destinationModel.geocodeStatus">
                        <label class="col-form-label form-control-label col-md-4 detail-title"> {{ 'TASK_GEOCODING_STATUS' |
                          translate }} </label>
                        <div class="col-md-8">
                          <label *ngIf="detailModel.destinationModel.geocodeStatus === 'DONE'" class="form-control detail-description" readonly>
                            <i class="icomoon icon-large {{detailModel.destinationModel.geocodeStatusIcon}}"></i>
                            {{detailModel.destinationModel.geocodeStatusCoordinateText}}
                          </label>
                          <label *ngIf="detailModel.destinationModel.geocodeStatus !== 'DONE'" class="form-control detail-description" readonly>
                            <i class="icomoon icon-large {{detailModel.destinationModel.geocodeStatusIcon}}"></i>
                            {{detailModel.destinationModel.geocodeStatusNameKey | translate}}
                          </label>
                        </div>
                      </div>
                      <!--./Geocoding-->
                    </ng-template>
                  </div>
                  <!-- ./Detail-->
                </div>
              </div>
            </div>
            <!--end Destination card-->

            <!--Recipient card-->
            <div class="card">
              <div class="card-header">
                {{
                'SHIPPING_DEMAND_RECIPIENT' | translate
                }}
              </div>
              <div class="card-body">
                <div class="form-horizontal bordered-row">

                  <!--region Recipient name-->
                  <div class="form-group row" [ngClass]="{ 'has-danger': hasLocalFieldError(recipientName) }">
                    <label class="col-form-label form-control-label col-md-4 detail-title" [class.required-field-label]="componentState.isEditable() && !editModel.destinationModel.isLocation()">
                      {{'COMMON_NAME' | translate}}
                    </label>
                    <div class="col-md-8">
                      <input *ngIf="componentState.isEditable()"
                             type="text" class="form-control"
                             maxlength="{{UiConstants.maximumVarcharLength}}"
                             placeholder="{{'COMMON_NAME' | translate}}"
                             [(ngModel)]="editModel.recipientName"
                             [ngClass]="{ 'form-control-danger': hasLocalFieldError(recipientName) }"
                             name="recipientName"
                             id="recipientName"
                             #recipientName="ngModel"
                             [required]="!editModel.destinationModel.isLocation()">
                      <div class="form-control-feedback"
                           *ngIf="hasLocalFieldError(recipientName)">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</div>
                      <!--Appears in detailView-->
                      <label
                        *ngIf="componentState.isReadonly()" class="form-control detail-description"
                        readonly>{{detailModel.recipientName}}
                      </label>
                    </div>
                  </div>
                  <!--endregion Recipient name-->

                  <!--region RecipientPhone-->
                  <div class="form-group row" [ngClass]="{ 'has-danger': hasLocalFieldError(recipientPhoneNumber) }" *ngIf="isEnabledField(EditableField.RECIPIENT_PHONE)">
                    <label
                      class="col-form-label form-control-label col-md-4 detail-title">{{'COMMON_CONTACT_PERSON_PHONE_NUMBER'
                      | translate}}</label>
                    <div class="col-md-8">
                      <div class="input-group" *ngIf="componentState.isEditable()">
                        <input type="text"
                               class="form-control"
                               placeholder="{{'COMMON_CONTACT_PERSON_PHONE_NUMBER' | translate}}"
                               [(ngModel)]="editModel.recipientPhoneNumber"
                               [ngClass]="{ 'form-control-danger': hasLocalFieldError(recipientPhoneNumber) }"
                               name="recipientPhoneNumber"
                               id="recipientPhoneNumber"
                               #recipientPhoneNumber="ngModel"
                               validatePhoneNumber>
                        <span class="input-group-addon-gray icomoon icomoon-phone"
                              id="recipient_phone_number_basic-addon2"></span>
                      </div>
                      <div class="form-control-feedback"
                           *ngIf="hasLocalFieldError(recipientPhoneNumber)">{{'COMMON_VALIDATION_MESSAGE_PHONE' | translate}}</div>
                      <!--Appears in detailView-->
                      <label
                        *ngIf="componentState.isReadonly()" class="form-control detail-description"
                        readonly>{{detailModel.recipientPhoneNumber}}
                      </label>
                    </div>
                  </div>
                  <!--endregion RecipientPhone-->

                  <!--region RecipientEmail-->
                  <div class="form-group row" [ngClass]="{ 'has-danger': hasLocalFieldError(recipientEmailAddress) }" *ngIf="isEnabledField(EditableField.RECIPIENT_EMAIL)">
                    <label
                      class="col-form-label form-control-label col-md-4 detail-title">{{'COMMON_CONTACT_PERSON_EMAIL_ADDRESS'
                      | translate}}</label>
                    <div class="col-md-8">
                      <div class="input-group" *ngIf="componentState.isEditable()">
                        <input type="text"
                               class="form-control"
                               placeholder="{{'COMMON_CONTACT_PERSON_EMAIL_ADDRESS' | translate}}"
                               [(ngModel)]="editModel.recipientEmailAddress"
                               [ngClass]="{ 'form-control-danger': hasLocalFieldError(recipientEmailAddress) }"
                               name="recipientEmailAddress"
                               id="recipientEmailAddress"
                               #recipientEmailAddress="ngModel"
                               validateOptionalEmail>
                        <span class="input-group-addon-gray icomoon icomoon-message-unread"
                              id="recipient_email_address_basic-addon"></span>
                      </div>
                      <div class="form-control-feedback"
                           *ngIf="hasLocalFieldError(recipientEmailAddress)">{{'COMMON_VALIDATION_MESSAGE_EMAIL' | translate}}</div>
                      <!--Appears in detailView-->
                      <label
                        *ngIf="componentState.isReadonly()" class="form-control detail-description"
                        readonly>{{detailModel.recipientEmailAddress}}
                      </label>
                    </div>
                  </div>
                  <!--endregion RecipientEmail-->

                  <!--region RecipientLicenseCategory-->
                  <div class="form-group row" *ngIf="isEnabledField(EditableField.RECIPIENT_LICENSE_CATEGORY)">
                    <label
                      class="col-form-label form-control-label col-md-4 detail-title">{{'SHIPPING_DEMAND_RECIPIENT_LICENSE_CATEGORY' |
                        translate}}</label>
                    <div class="col-md-8">
                      <input *ngIf="componentState.isEditable()"
                             type="text"
                             class="form-control"
                             maxlength="{{UiConstants.maximumVarcharLength}}"
                             placeholder="{{'SHIPPING_DEMAND_RECIPIENT_LICENSE_CATEGORY' | translate}}"
                             [(ngModel)]="editModel.recipientLicenseCategory"
                             name="recipientLicenseCategory"
                             id="recipientLicenseCategory">
                      <!--Appears in detailView-->
                      <label
                        *ngIf="componentState.isReadonly()" class="form-control detail-description"
                        readonly>{{detailModel.recipientLicenseCategory}}
                      </label>
                    </div>
                  </div>
                  <!--endregion RecipientLicenseCategory-->

                  <!--region RecipientLicenseNumber-->
                  <div class="form-group row" *ngIf="isEnabledField(EditableField.RECIPIENT_LICENSE_NUMBER)">
                    <label
                      class="col-form-label form-control-label col-md-4 detail-title">{{'SHIPPING_DEMAND_RECIPIENT_LICENSE_NUMBER' |
                        translate}}</label>
                    <div class="col-md-8">
                      <input *ngIf="componentState.isEditable()"
                             type="text"
                             class="form-control"
                             maxlength="{{UiConstants.maximumVarcharLength}}"
                             placeholder="{{'SHIPPING_DEMAND_RECIPIENT_LICENSE_NUMBER' | translate}}"
                             [(ngModel)]="editModel.recipientLicenseNumber"
                             name="recipientLicenseNumber"
                             id="recipientLicenseNumber">
                      <!--Appears in detailView-->
                      <label
                        *ngIf="componentState.isReadonly()" class="form-control detail-description"
                        readonly>{{detailModel.recipientLicenseNumber}}
                      </label>
                    </div>
                  </div>
                  <!--endregion RecipientLicenseNumber-->

                  <!--region RecipientComment-->
                  <div class="form-group row" *ngIf="isEnabledField(EditableField.RECIPIENT_COMMENT)">
                    <label class="col-form-label form-control-label col-md-4 detail-title">{{'COMMON_COMMENT' |
                      translate}}</label>
                    <div class="col-md-8">
                      <input *ngIf="componentState.isEditable()"
                             type="text"
                             class="form-control"
                             placeholder="{{'COMMON_COMMENT' | translate}}"
                             [(ngModel)]="editModel.recipientComment"
                             name="recipientComment"
                             id="recipientComment">
                      <!--Appears in detailView-->
                      <label
                        *ngIf="componentState.isReadonly()" class="form-control detail-description"
                        readonly>{{detailModel.recipientComment}}
                      </label>
                    </div>
                  </div>
                  <!--endregion RecipientComment-->

                </div>
              </div>
            </div>
            <!--end Recipient card-->
          </div>
          <!-- end Second column-->

          <input *ngIf="componentState.isEditable()"
                 type="submit"
                 class="btn btn-primary floating_save_button"
                 value="{{'COMMON_BUTTON_SAVE'|translate}}"/>

        </form>
        <!-- /.form-group row -->

        <!--SHIPPING ITEMS-->
        <div class="card-custom-padding">
          <div class="card">
            <div class="card-header">
              {{'SHIPPING_DEMAND_ITEM_PRODUCTS' | translate}}
            </div>
            <!--region ShippingItems-->
            <div class="card-body">

              <app-shipping-demand-item-container
                [componentState]="componentState"
                [rightModel]="rightModel"
                [editableFieldModel]="editableFieldModel"
                [disabled]="componentState.isEditable() ? isNewCarriage : false"
                #demandItemComponent>

              </app-shipping-demand-item-container>
              <!--endregion ShippingItems-->
            </div>
          </div>
        </div>
        <!--END SHIPPING ITEMS-->

        <div class="container-vertical-padding-half"></div>

      </div>
    </responsive-tab>
    <responsive-tab *ngIf="componentState.isDetailView()"
         heading="{{'COMMON_HISTORY'|translate}}">
      <app-history-log
        [queryModel]="historyQueryModel"
        [Type]="ShippingDemand"
        [historyList]="historyList"
        (openHistoryDetail)="historyDetailModal.openDetailDialog($event)"
        (itemsPerPageChangedOut)="itemsPerPageChanged($event)"
        (orderByOut)="orderBy($event)"
        (pageChangedOut)="pageChanged($event)">
      </app-history-log>
    </responsive-tab>
    <responsive-tab *ngIf="componentState.isDetailView() && ConfigurationService.isEnabledByServer('EXTERIOR_TRANSPORT', configuration)"
         class="p-0" heading="{{'COMMON_MPL_STATISTICS'|translate}}">
      <app-shipping-demand-mpl-statistics
        [shippingDemandId]="componentState.id!">
      </app-shipping-demand-mpl-statistics>
    </responsive-tab>
  </responsive-tabset>
</div>

<app-shipping-demand-split-dialog
  [demandId]="componentState.id"
  [fromDetail]="componentState.isDetailView()"
  (onSubmit)="deregisterWarningModal()"
  #splitDialog>
</app-shipping-demand-split-dialog>

<app-history-detail-dialog
  #historyDetailModal>
</app-history-detail-dialog>
