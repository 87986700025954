import { ANTLRInputStream, CommonTokenStream } from 'antlr4ts';
import { DqlLexer } from '../../../../util/generated/dql/normal/DqlLexer';
import { DqlParser } from '../../../../util/generated/dql/normal/DqlParser';
import { NormalDqlListenerImpl } from './normal-dql-listener.impl';
import { DqlFieldModel, PureDqlQueryBuilder } from '../../dql-search-container/dql-search-container.model';
import { ParseTreeWalker } from 'antlr4ts/tree';
import { DqlQueryParser } from '../dql-query-parser';

export class NormalDqlQueryParser implements DqlQueryParser {

  private readonly listener: NormalDqlListenerImpl;

  constructor(private fields: DqlFieldModel[]) {
    this.listener = new NormalDqlListenerImpl(this.fields);

  }

  public parse(dqlQuery: string): PureDqlQueryBuilder {
    // Create the lexer and parser
    const inputStream = new ANTLRInputStream(dqlQuery);
    const lexer = new DqlLexer(inputStream);
    const tokenStream = new CommonTokenStream(lexer);
    const parser = new DqlParser(tokenStream);

    const tree = parser.sentence();

    ParseTreeWalker.DEFAULT.walk(this.listener, tree);
    return this.listener.queryBuilder;
  }

}
