<div class="breadcrumb-container" [ngClass]="{ 'breadcrumb-container-compact': compactSidebar }" id="breadcrumb">
  <div class="breadcrumb-component">
    <app-breadcrumb
      [parents]=breadcrumbParents
      [self]="breadcrumbSelf">
    </app-breadcrumb>
  </div>
  <div class="breadcrumb-menu d-flex">
    <div *ngIf="rightModel.legacyWorkflowCreate.hasRight()" class="breadcrumb-button-icon-container negative-margin-right cursor-pointer" uiSref="Admin.LegacyWorkflowCreate">
      <a class="btn-setting" title="{{'WORKFLOW_LIST_ADD_WORKFLOW' | translate}}">
        <i class="icomoon icomoon-workflow"></i>
        <span class="breadcrumb-button-text-visible">{{'WORKFLOW_LIST_ADD_WORKFLOW' | translate}}</span>
      </a>
    </div>
  </div>
</div>
<div class="breadcrumb-placeholder"></div>

<div class="container-horizontal-padding-half container-vertical-padding-half animated fadeIn">

  <div class="card">

    <div class="card-header">
      {{'WORKFLOW_LIST' | translate}}

      <div class="card-actions">
        <a class="btn-setting cursor-pointer" (click)="toggleSearch()" title="{{'COMMON_SHOW_SEARCH_HINT' | translate}}"><i class="icomoon icomoon-search"></i></a>
      </div><!-- /.card-actions -->
    </div>

    <div class="card-body border-bottom" *ngIf="showSearch">
      <div class="row form-group">
        <div class="col-md-3">
          <label class="search-label">{{'COMMON_NAME' | translate}}</label>
          <input type="text" class="form-control"
                 maxlength="{{UiConstants.maximumVarcharLength}}"
                 (keyup.enter)="onSearchClicked()"
                 placeholder="{{'COMMON_NAME' | translate}}"
                 [(ngModel)]="searchModel.name">
        </div>
        <div class="col-md-3">
          <label class="search-label">{{'WORKFLOW_CREATE_EDIT_EXTERNAL_ID' | translate}}</label>
          <input type="text" class="form-control"
                 maxlength="{{UiConstants.maximumVarcharLength}}"
                 (keyup.enter)="onSearchClicked()"
                 placeholder="{{'WORKFLOW_CREATE_EDIT_EXTERNAL_ID' | translate}}"
                 [(ngModel)]="searchModel.externalId">
        </div>
        <div class="col-md-3">
          <label class="search-label">{{'COMMON_DESCRIPTION' | translate}}</label>
          <input type="text" class="form-control"
                 maxlength="{{UiConstants.maximumVarcharLength}}"
                 (keyup.enter)="onSearchClicked()"
                 placeholder="{{'COMMON_DESCRIPTION' | translate}}"
                 [(ngModel)]="searchModel.description">
        </div>
        <div class="col">
          <label class="search-label">{{'COMMON_STATE' | translate}}</label>
          <select class="form-control" [(ngModel)]="searchModel.disabled"
                  id="disabled" name="disabled" #disabled="ngModel"
                  [compareWith]="SelectUtils.compareObjects">
            <option *ngFor="let item of disabledItems" [ngValue]="item">{{item.text}}</option>
          </select>
        </div>
      </div>
      <div class="row form-group">

        <div class="col-md-3">
          <label class="search-label">{{'TASK_RECORD_SEARCH_CREATION_TIME_FROM' | translate}}</label>
          <div class="input-group">
            <input type="text" class="form-control date"
                   placeholder="{{'TASK_RECORD_SEARCH_CREATION_TIME_FROM' | translate}}"
                   [(ngModel)]="searchModel.creationTimeFrom" ngbDatepicker name="creationTime_from"
                   id="creationTime_from"
                   #creationTime_from="ngbDatepicker">
            <div class="input-group-addon-gray" (click)="creationTime_from.toggle()">
              <i class="icomoon icomoon-calendar"></i>
            </div>
          </div>
        </div>

        <div class="col-md-3">
          <label class="search-label">{{'TASK_RECORD_SEARCH_CREATION_TIME_TO' | translate}}</label>
          <div class="input-group">
            <input type="text" class="form-control date"
                   placeholder="{{'TASK_RECORD_SEARCH_CREATION_TIME_TO' | translate}}"
                   [(ngModel)]="searchModel.creationTimeTo" ngbDatepicker name="creationTime_to"
                   id="creationTime_to"
                   #creationTime_to="ngbDatepicker">
            <div class="input-group-addon-gray" (click)="creationTime_to.toggle()">
              <i class="icomoon icomoon-calendar"></i>
            </div>
          </div>
        </div>

        <div class="col-md-3">
          <label class="search-label">{{'TASK_SEARCH_FIELD_UPDATE_TIME_FROM' | translate}}</label>
          <div class="input-group">
            <input type="text" class="form-control date"
                   placeholder="{{'TASK_SEARCH_FIELD_UPDATE_TIME_FROM' | translate}}"
                   [(ngModel)]="searchModel.updateTimeFrom" ngbDatepicker name="updateTime_from"
                   id="updateTime_from"
                   #updateTime_from="ngbDatepicker">
            <div class="input-group-addon-gray" (click)="updateTime_from.toggle()">
              <i class="icomoon icomoon-calendar"></i>
            </div>
          </div>
        </div>

        <div class="col-md-3">
          <label class="search-label">{{'TASK_SEARCH_FIELD_UPDATE_TIME_TO' | translate}}</label>
          <div class="input-group">
            <input type="text" class="form-control date"
                   placeholder="{{'TASK_SEARCH_FIELD_UPDATE_TIME_TO' | translate}}"
                   [(ngModel)]="searchModel.updateTimeTo" ngbDatepicker name="updateTime_to" id="updateTime_to"
                   #updateTime_to="ngbDatepicker">
            <div class="input-group-addon-gray" (click)="updateTime_to.toggle()">
              <i class="icomoon icomoon-calendar"></i>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12 d-flex justify-content-md-end align-items-end px-0">
        <div class="btn-group" role="group">
          <button type="button" (click)="onSearchReset()" class="btn btn-outline-primary search-button mr-1">
            {{'COMMON_BUTTON_SEARCH_DELETE' | translate}}
          </button>
          <button type="button" (click)="onSearchClicked()" class="btn btn-primary search-button">
            {{'COMMON_BUTTON_SEARCH' | translate}}
          </button>
        </div>
      </div>
    </div> <!-- ./card-body end -->

    <div class="card-body">
      <table class="table table-striped table-bordered">
      <thead>
      <tr>
        <th class="table-sorter-header w-5 hidden-lg-down"><app-table-sorter-no-op
          [text]="'COMMON_TABLE_HEADER_ICON' | translate">
        </app-table-sorter-no-op></th>
        <th class="table-sorter-header w-5 hidden-sm-down"><app-table-field-sorter
          [orderField]="Workflow.OrderField.ID"
          [orderType]="queryModel.getOrderType(Workflow.OrderField.ID)"
          (orderChange)="orderBy($event)"
          [text]="'COMMON_TABLE_HEADER_ID' | translate">
        </app-table-field-sorter></th>
        <th class="table-sorter-header"><app-table-field-sorter
          [orderField]="Workflow.OrderField.NAME"
          [orderType]="queryModel.getOrderType(Workflow.OrderField.NAME)"
          (orderChange)="orderBy($event)"
          [text]="'COMMON_NAME' | translate">
        </app-table-field-sorter></th>
        <th class="table-sorter-header hidden-md-down"><app-table-field-sorter
          [orderField]="Workflow.OrderField.EXTERNAL_ID"
          [orderType]="queryModel.getOrderType(Workflow.OrderField.EXTERNAL_ID)"
          (orderChange)="orderBy($event)"
          [text]="'WORKFLOW_CREATE_EDIT_EXTERNAL_ID' | translate">
        </app-table-field-sorter></th>
        <th class="table-sorter-header hidden-sm-down"><app-table-field-sorter
          [orderField]="Workflow.OrderField.DESCRIPTION"
          [orderType]="queryModel.getOrderType(Workflow.OrderField.DESCRIPTION)"
          (orderChange)="orderBy($event)"
          [text]="'COMMON_DESCRIPTION' | translate">
        </app-table-field-sorter></th>
        <th class="table-sorter-header w-15 hidden-xs-down"><app-table-field-sorter
          [orderField]="Workflow.OrderField.CREATION_TIME"
          [orderType]="queryModel.getOrderType(Workflow.OrderField.CREATION_TIME)"
          (orderChange)="orderBy($event)"
          [text]="'COMMON_TABLE_HEADER_CREATION_TIME' | translate">
        </app-table-field-sorter></th>
        <th class="table-sorter-header w-15"><app-table-field-sorter
          [orderField]="Workflow.OrderField.UPDATE_TIME"
          [orderType]="queryModel.getOrderType(Workflow.OrderField.UPDATE_TIME)"
          (orderChange)="orderBy($event)"
          [text]="'COMMON_TABLE_HEADER_UPDATE_TIME' | translate">
        </app-table-field-sorter></th>
        <th class="table-sorter-header w-10"><app-table-sorter-no-op
          [text]="'COMMON_TABLE_HEADER_ACTIONS' | translate">
        </app-table-sorter-no-op></th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let workflow of workflowList | paginate: {
                                                      itemsPerPage: queryModel.itemsPerPage,
                                                      currentPage: queryModel.currentPage,
                                                      totalItems: queryModel.currentNumberOfItems }" [disabledTableRow]="workflow.disabled">
        <td class="hidden-lg-down"><app-type-icon [icon]="workflow.icon"></app-type-icon></td>
        <td class="responsive-table-column hidden-sm-down">{{workflow.workflowId}}</td>
        <td class="responsive-table-column">{{workflow.name}}</td>
        <td class="responsive-table-column hidden-md-down">{{workflow.externalId}}</td>
        <td class="responsive-table-column hidden-sm-down">{{workflow.description}}</td>
        <td class="responsive-table-column hidden-xs-down">{{workflow.creationTime.toUtcIsoString() | date:'short'}}</td>
        <td class="responsive-table-column">{{workflow.updateTime.toUtcIsoString() | date:'short'}}</td>
        <td class="responsive-table-column">
          <a *ngIf="!workflow.disabled && rightModel.legacyWorkflowDisable.hasRight()" class="icons-table-group" href="#" onclick="return false" (click)="setDisabled($event, workflow, true)">
            <i class="icomoon icons-table-item icomoon-disable icomoon-disable-table" title="{{'COMMON_BUTTON_DISABLE' | translate}}"></i>
          </a>
          <a *ngIf="workflow.disabled && rightModel.legacyWorkflowDisable.hasRight()" class="icons-table-group" href="#" onclick="return false" (click)="setDisabled($event, workflow, false)">
            <i class="icomoon icons-table-item icomoon-enable icomoon-enable-table" title="{{'COMMON_BUTTON_ENABLE' | translate}}"></i>
          </a>
          <a *ngIf="rightModel.legacyWorkflowUpdate.hasRight()" class="icons-table-group cursor-pointer" uiSref="Admin.LegacyWorkflowEdit" [uiParams]="{ id: workflow.workflowId }">
            <i class="icomoon icons-table-item icomoon-modify icomoon-modify-table" title="{{'COMMON_BUTTON_EDIT' | translate}}"></i>
          </a>
        </td>
      </tr>
      </tbody>
      <tfoot>
      <tr>
        <th class="table-sorter-header w-5 hidden-lg-down"><app-table-sorter-no-op
          [text]="'COMMON_TABLE_HEADER_ICON' | translate">
        </app-table-sorter-no-op></th>
        <th class="table-sorter-header w-5 hidden-sm-down"><app-table-field-sorter
          [orderField]="Workflow.OrderField.ID"
          [orderType]="queryModel.getOrderType(Workflow.OrderField.ID)"
          (orderChange)="orderBy($event)"
          [text]="'COMMON_TABLE_HEADER_ID' | translate">
        </app-table-field-sorter></th>
        <th class="table-sorter-header"><app-table-field-sorter
          [orderField]="Workflow.OrderField.NAME"
          [orderType]="queryModel.getOrderType(Workflow.OrderField.NAME)"
          (orderChange)="orderBy($event)"
          [text]="'COMMON_NAME' | translate">
        </app-table-field-sorter></th>
        <th class="table-sorter-header hidden-md-down"><app-table-field-sorter
          [orderField]="Workflow.OrderField.EXTERNAL_ID"
          [orderType]="queryModel.getOrderType(Workflow.OrderField.EXTERNAL_ID)"
          (orderChange)="orderBy($event)"
          [text]="'WORKFLOW_CREATE_EDIT_EXTERNAL_ID' | translate">
        </app-table-field-sorter></th>
        <th class="table-sorter-header hidden-sm-down"><app-table-field-sorter
          [orderField]="Workflow.OrderField.DESCRIPTION"
          [orderType]="queryModel.getOrderType(Workflow.OrderField.DESCRIPTION)"
          (orderChange)="orderBy($event)"
          [text]="'COMMON_DESCRIPTION' | translate">
        </app-table-field-sorter></th>
        <th class="table-sorter-header w-15 hidden-xs-down"><app-table-field-sorter
          [orderField]="Workflow.OrderField.CREATION_TIME"
          [orderType]="queryModel.getOrderType(Workflow.OrderField.CREATION_TIME)"
          (orderChange)="orderBy($event)"
          [text]="'COMMON_TABLE_HEADER_CREATION_TIME' | translate">
        </app-table-field-sorter></th>
        <th class="table-sorter-header w-15"><app-table-field-sorter
          [orderField]="Workflow.OrderField.UPDATE_TIME"
          [orderType]="queryModel.getOrderType(Workflow.OrderField.UPDATE_TIME)"
          (orderChange)="orderBy($event)"
          [text]="'COMMON_TABLE_HEADER_UPDATE_TIME' | translate">
        </app-table-field-sorter></th>
        <th class="table-sorter-header w-10"><app-table-sorter-no-op
          [text]="'COMMON_TABLE_HEADER_ACTIONS' | translate">
        </app-table-sorter-no-op></th>
      </tr>
      </tfoot>
      </table>
      <app-table-paging
        [currentNumberOfItems]="queryModel.currentNumberOfItems"
        [totalNumberOfItems]="queryModel.totalNumberOfItems"
        [itemsPerPage]="queryModel.itemsPerPage"
        (pageChange)="pageChanged($event)"
        (itemsPerPageChange)="itemsPerPageChanged($event)">
      </app-table-paging>
    </div>
  </div>
</div>
