<div class="breadcrumb-container" [ngClass]="{ 'breadcrumb-container-compact': compactSidebar }" id="breadcrumb">
  <div class="breadcrumb-component">
    <app-breadcrumb
      [parents]="breadcrumbParents"
      [self]="breadcrumbSelf">
    </app-breadcrumb>
    <app-breadcrumb-menu>
      <!-- region XLS EXPORT-->
      <app-breadcrumb-button
        *ngIf="projectGrantedRights &&projectGrantedRights.projectRecordExport.hasRight()"
        [iconClass]="'icomoon-csv-export'"
        [titleStringKey]="'COMMON_BUTTON_EXPORT'"
        (click)="exportXls()">
      </app-breadcrumb-button>
      <!-- endregion XLS EXPORT-->
      <!-- region HEIZTECH IMPORT-->
      <app-breadcrumb-button
        *ngIf="projectGrantedRights &&projectGrantedRights.projectRecordImport.hasRight()"
        [iconClass]="'icomoon-csv-import'"
        [titleStringKey]="'PROJECT_RECORD_HEIZTECH_IMPORT'"
        (click)="openHeiztechImportDialog()">
      </app-breadcrumb-button>
      <!-- endregion HEIZTECH IMPORT-->
      <!-- region CREATE-->
      <app-breadcrumb-button
        *ngIf="projectGrantedRights && projectGrantedRights.projectRecordCreate.hasRight()"
        [iconClass]="'icomoon-add'"
        [titleStringKey]="'PROJECTS_TABLE_ADD'"
        uiSref="Admin.ProjectRecordCreate"
        [uiParams]="{projectId: projectId}">
      </app-breadcrumb-button>
      <!-- endregion CREATE-->
    </app-breadcrumb-menu>
  </div>
</div>
<div class="breadcrumb-placeholder"></div>
<div class="container-horizontal-padding container-vertical-padding animated fadeIn">

  <div class="card">
    <div class="card-header">
      {{'PROJECT_PANEL_HEADING_LIST' | translate}}

      <div class="card-actions">
        <a class="btn-setting cursor-pointer" (click)="toggleSearch()"
           title="{{'COMMON_SHOW_SEARCH_HINT' | translate}}"><i #searchIcon
                                                                class="icomoon icomoon-search"></i></a>
      </div><!-- /.card-actions -->
    </div>

    <div class="card-body border-bottom" *ngIf="showSearch" id="project-record-list-search-panel">
      <responsive-tabset #searchTabs>
        <responsive-tab heading="{{'SEARCH_MODE_SIMPLE' | translate}}" (selectTab)="activeSearchTab = 'simple'"
                        id="simple">
          <div class="row form-group">
            <div class="col-md-3">
              <label class="search-label">{{'COMMON_TABLE_HEADER_ID' | translate}}</label>
              <div class="input-group">
                <input type="text" class="form-control" placeholder="{{'COMMON_TABLE_HEADER_ID' | translate}}"
                       maxlength="{{UiConstants.maxInputLongLength}}"
                       [textMask]="{mask: InputMask.NATURAL_INTEGER_WITH_THOUSAND_SEPARATOR, guide: false}"
                       (keyup.enter)="onSearchClicked()"
                       [(ngModel)]="searchModel.id">
              </div>
            </div>

            <div class="col-md-3">
              <label class="search-label">{{'COMMON_NAME' | translate}}</label>
              <div class="input-group">
                <input type="text" class="form-control" placeholder="{{'COMMON_NAME' | translate}}"
                       maxlength="{{UiConstants.maximumVarcharLength}}"
                       (keyup.enter)="onSearchClicked()"
                       [(ngModel)]="searchModel.name">
              </div>
            </div>

            <div class="col-md-3">
              <label class="search-label">{{'SEARCH_EXTERNAL_ID_LABEL' | translate}}</label>
              <div class="input-group">
                <input type="text" class="form-control" placeholder="{{'SEARCH_EXTERNAL_ID_LABEL' | translate}}"
                       maxlength="{{UiConstants.maximumVarcharLength}}"
                       (keyup.enter)="onSearchClicked()"
                       [(ngModel)]="searchModel.externalId">
              </div>
            </div>

            <div class="col-md-3">
              <label class="search-label">{{'COMMON_USER'|translate}}</label>
              <angular2-multiselect
                [(ngModel)]="searchModel.assigneeUser"
                [data]="users"
                (onRemoteSearch)="loadUsers($event.target.value)"
                [settings]="dropdownSettings">
              </angular2-multiselect>
            </div>
          </div>
          <div class="row form-group">
            <div class="col-md-4 col-sm-6">
              <label class="search-label">{{'PROJECT_SEARCH_START_DATE' | translate}}</label>
              <div class="d-flex align-items-center">
                <div class="input-group">
                  <input type="text" class="form-control date" placeholder="{{'COMMON_DATE_FROM' | translate}}"
                         [(ngModel)]="searchModel.startDateFrom" ngbDatepicker name="start_date_from"
                         id="start_date_from"
                         #start_date_from="ngbDatepicker">
                  <div class="input-group-addon-gray" (click)="start_date_from.toggle()">
                    <i class="icomoon icomoon-calendar"></i>
                  </div>
                </div>
                <span class="search-date-separator"></span>
                <div class="input-group">
                  <input type="text" class="form-control date" placeholder="{{'COMMON_DATE_TO' | translate}}"
                         [(ngModel)]="searchModel.startDateTo" ngbDatepicker name="start_date_to" id="start_date_to"
                         #start_date_to="ngbDatepicker">
                  <div class="input-group-addon-gray" (click)="start_date_to.toggle()">
                    <i class="icomoon icomoon-calendar"></i>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-4 col-sm-6">
              <label class="search-label">{{'PROJECT_SEARCH_END_DATE' | translate}}</label>
              <div class="d-flex align-items-center">
                <div class="input-group">
                  <input type="text" class="form-control date" placeholder="{{'COMMON_DATE_FROM' | translate}}"
                         [(ngModel)]="searchModel.endDateFrom" ngbDatepicker name="end_date_from" id="end_date_from"
                         #end_date_from="ngbDatepicker">
                  <div class="input-group-addon-gray" (click)="end_date_from.toggle()">
                    <i class="icomoon icomoon-calendar"></i>
                  </div>
                </div>
                <span class="search-date-separator"></span>
                <div class="input-group">
                  <input type="text" class="form-control date" placeholder="{{'COMMON_DATE_TO' | translate}}"
                         [(ngModel)]="searchModel.endDateTo" ngbDatepicker name="end_date_to" id="end_date_to"
                         #end_date_to="ngbDatepicker">
                  <div class="input-group-addon-gray" (click)="end_date_to.toggle()">
                    <i class="icomoon icomoon-calendar"></i>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-2">
              <label class="search-label">{{'COMMON_STATE' | translate}}</label>
              <select class="form-control" [(ngModel)]="searchModel.disabled"
                      id="disabled" name="disabled" #disabled="ngModel"
                      [compareWith]="SelectUtils.compareObjects">
                <option *ngFor="let item of disabledItems" [ngValue]="item">{{item.text}}</option>
              </select>
            </div>

            <div class="col-md-2">
              <label class="search-label">{{'COMMON_LOCATION_POSTAL_ADDRESS' | translate}}</label>
              <div class="input-group">
                <input type="text" class="form-control" placeholder="{{'COMMON_LOCATION_POSTAL_ADDRESS' | translate}}"
                       maxlength="{{UiConstants.maximumVarcharLength}}"
                       (keyup.enter)="onSearchClicked()"
                       [(ngModel)]="searchModel.postalAddress">
              </div>
            </div>
          </div>
          <div class="col-md-12 d-flex justify-content-md-end align-items-end px-0">
            <div class="btn-group" role="group">
              <button type="button" (click)="onSearchReset()" class="btn btn-outline-primary search-button mr-1">
                {{'COMMON_BUTTON_SEARCH_DELETE' | translate}}
              </button>
              <button type="button" (click)="onSearchClicked()"
                      class="btn btn-primary search-button">{{'COMMON_BUTTON_SEARCH' | translate}}</button>
            </div>
          </div>
        </responsive-tab>
        <responsive-tab heading="{{'SEARCH_MODE_DQL' | translate}}" (selectTab)="activeSearchTab = 'dql'" id="dql">
          <app-dql-search-container #dqlSearchContainer
                                    (onSearch)="onDqlSearchClicked()"
                                    [dqlStoredQueryArgs]="dqlStoredArgs"
                                    [rightModel]="rightModel"
                                    [dialogContainer]="dqlDialogHolder"></app-dql-search-container>
        </responsive-tab>
      </responsive-tabset>
    </div> <!-- ./card-body end -->

    <div class="card-body">
      <table class="table table-striped table-bordered">
        <thead>
        <tr>
          <th class="table-sorter-header hidden-xs-down">
            <app-table-field-sorter [orderField]="ProjectRecord.OrderField.ID"
                                    [orderType]="queryModel.getOrderType(ProjectRecord.OrderField.ID)"
                                    (orderChange)="orderBy($event)"
                                    [checkAllEnabled]="true"
                                    [checkAllSelected]="eachRowSelected"
                                    (onCheckAllClicked)="toggleEachRow()"
                                    [text]="'COMMON_TABLE_HEADER_ID' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header">
            <app-table-field-sorter [orderField]="ProjectRecord.OrderField.NAME"
                                    [orderType]="queryModel.getOrderType(ProjectRecord.OrderField.NAME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_NAME' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-1300-down">
            <app-table-field-sorter [orderField]="ProjectRecord.OrderField.START_DATE"
                                    [orderType]="queryModel.getOrderType(ProjectRecord.OrderField.START_DATE)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'PROJECT_LIST_TABLE_HEADER_START_DATE' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-1300-down">
            <app-table-field-sorter [orderField]="ProjectRecord.OrderField.END_DATE"
                                    [orderType]="queryModel.getOrderType(ProjectRecord.OrderField.END_DATE)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'PROJECT_LIST_TABLE_HEADER_END_DATE' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-md-down">
            <app-table-sorter-no-op
              [text]="'COMMON_USER' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header hidden-lg-down" *ngIf="formFieldName">
            <app-table-sorter-no-op
              [text]="formFieldName"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header w-table-options-menu">
            <app-table-sorter-no-op></app-table-sorter-no-op>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let item of list | paginate: {
                                                      itemsPerPage: queryModel.itemsPerPage,
                                                      currentPage: queryModel.currentPage,
                                                      totalItems: queryModel.currentNumberOfItems }"
            [disabledTableRow]="item.project.disabled">
          <td class="responsive-table-column hidden-xs-down fit align-middle">
            <div class="d-flex align-items-center">
              <app-animated-checkbox
                class="toggle-checkbox"
                [(selected)]="item.selected">
              </app-animated-checkbox>
              {{item.project.id}}
            </div>
          </td>
          <td class="responsive-table-column long">
            <div class="d-flex">
              <div class="d-flex flex-column justify-content-center">
                <a class="id-text cursor-pointer"
                   href="#" onclick="return false"
                   uiSref="Admin.ProjectRecordDetail"
                   [uiParams]="{ projectId: projectId, id: item.project.id }"
                   title="{{'COMMON_BUTTON_DETAILS' | translate}}">
                  {{ item.project.name }}
                </a>
                <span class="text-truncate table-text-subtitle">
                  {{ item.project.externalId }}
                </span>
              </div>
            </div>
          </td>
          <td
            class="responsive-table-column hidden-1300-down align-middle">{{item.project.startDate.toIsoString() | date:'mediumDate'}}</td>
          <td
            class="responsive-table-column hidden-1300-down align-middle">{{item.project.endDate.toIsoString() | date:'mediumDate'}}</td>
          <td
            class="fit hidden-md-down align-middle">
            <div class="d-flex align-items-center" *ngIf="item.project.assigneeUser">
              <app-user-profile-picture
                class="mr-1"
                [userPersonName]="item.project.assigneeUser.personName"
                [profilePicture]="getProfilePicture(item.project.assigneeUser.id)">
              </app-user-profile-picture>
              <div>
                <div>
                  <a class="id-text cursor-pointer" *ngIf="rightModel.userRead.hasRight()"
                     href="#" onclick="return false"
                     uiSref="Admin.UserDetail"
                     [uiParams]="{ id: item.project.assigneeUser.id }"
                     title="{{'COMMON_BUTTON_DETAILS' | translate}}">
                    {{ item.project.assigneeUser.personName }}
                  </a>
                  <span *ngIf="!rightModel.userRead.hasRight()">{{ item.project.assigneeUser.personName }}</span>
                </div>
                <span class="text-truncate table-text-subtitle">
                  {{ item.project.assigneeUser.userName }}
                </span>
              </div>
            </div>
          </td>
          <td
            class="responsive-table-column hidden-lg-down align-middle"
            *ngIf="formFieldName">
            <app-table-displayed-form-field-cell [model]="item.displayedFormField">
            </app-table-displayed-form-field-cell>
          </td>
          <td class="align-middle">
            <app-table-options-menu>
              <app-dropdown-item
                [iconClass]="'icomoon-detail'"
                [titleStringKey]="'COMMON_BUTTON_DETAILS'"
                class="cursor-pointer"
                uiSref="Admin.ProjectRecordDetail"
                [uiParams]="{ projectId: projectId, id: item.project.id }">
              </app-dropdown-item>
              <app-dropdown-item
                *ngIf="item.rights.projectRecordUpdate.hasRight()"
                [iconClass]="'icomoon-modify'"
                [titleStringKey]="'COMMON_BUTTON_EDIT'"
                class="cursor-pointer"
                uiSref="Admin.ProjectRecordEdit"
                [uiParams]="{ projectId: projectId, id: item.project.id }">
              </app-dropdown-item>
              <app-dropdown-item
                *ngIf="item.project.disabled && item.rights.projectRecordDisable.hasRight()"
                [iconClass]="'icomoon-enable'"
                [titleStringKey]="'COMMON_BUTTON_ENABLE'"
                [itemType]="DropdownItemType.ENABLE"
                class="cursor-pointer"
                (click)="setDisabled(item.project.id, false)">
              </app-dropdown-item>
              <app-dropdown-item
                *ngIf="!item.project.disabled && item.rights.projectRecordDisable.hasRight()"
                [iconClass]="'icomoon-disable'"
                [titleStringKey]="'COMMON_BUTTON_DISABLE'"
                [itemType]="DropdownItemType.DISABLE"
                class="cursor-pointer"
                (click)="setDisabled(item.project.id, true)">
              </app-dropdown-item>
            </app-table-options-menu>
          </td>
        </tr>
        </tbody>
        <tfoot>
        <tr>
          <th class="table-sorter-header hidden-xs-down">
            <app-table-field-sorter [orderField]="ProjectRecord.OrderField.ID"
                                    [orderType]="queryModel.getOrderType(ProjectRecord.OrderField.ID)"
                                    (orderChange)="orderBy($event)"
                                    [checkAllEnabled]="true"
                                    [checkAllSelected]="eachRowSelected"
                                    (onCheckAllClicked)="toggleEachRow()"
                                    [text]="'COMMON_TABLE_HEADER_ID' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header">
            <app-table-field-sorter [orderField]="ProjectRecord.OrderField.NAME"
                                    [orderType]="queryModel.getOrderType(ProjectRecord.OrderField.NAME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_NAME' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-1300-down">
            <app-table-field-sorter [orderField]="ProjectRecord.OrderField.START_DATE"
                                    [orderType]="queryModel.getOrderType(ProjectRecord.OrderField.START_DATE)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'PROJECT_LIST_TABLE_HEADER_START_DATE' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-1300-down">
            <app-table-field-sorter [orderField]="ProjectRecord.OrderField.END_DATE"
                                    [orderType]="queryModel.getOrderType(ProjectRecord.OrderField.END_DATE)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'PROJECT_LIST_TABLE_HEADER_END_DATE' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-md-down">
            <app-table-sorter-no-op
              [text]="'COMMON_USER' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header hidden-lg-down" *ngIf="formFieldName">
            <app-table-sorter-no-op
              [text]="formFieldName"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header w-table-options-menu">
            <app-table-sorter-no-op></app-table-sorter-no-op>
          </th>
        </tr>
        </tfoot>
      </table>
      <app-table-paging
        [currentNumberOfItems]="queryModel.currentNumberOfItems"
        [totalNumberOfItems]="queryModel.totalNumberOfItems"
        [itemsPerPage]="queryModel.itemsPerPage"
        (pageChange)="pageChanged($event)"
        (itemsPerPageChange)="itemsPerPageChanged($event)">
      </app-table-paging>
    </div>
  </div>
</div>

<div bsModal [config]="UiConstants.modalConfig" #heiztechImportDialog="bs-modal" class="modal fade" tabindex="-1"
     role="dialog"
     aria-labelledby="myModalLabel"
     aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document" *ngIf="heiztechImportDialogVisible">
    <div class="modal-content">
      <form class="form-horizontal bordered-row"
            (ngSubmit)="heiztechImportForm.form.valid && closeHeiztechImportDialog(true)" #heiztechImportForm="ngForm"
            novalidate>
        <div class="modal-header">
          <h4 class="modal-title">{{'PROJECT_RECORD_HEIZTECH_IMPORT' | translate}}</h4>
          <button type="button" class="close" (click)="closeHeiztechImportDialog(false)" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="form-group row"
               [ngClass]="{ 'has-danger': heiztechImportForm.submitted && !taskInput.valid }">
            <label class="col-form-label col-md-4 text-md-right">{{'CALENDAR_SEARCH_TASKS' | translate}}</label>
            <div class="col-md-8">
              <angular2-multiselect
                class="form-control"
                [(ngModel)]="heiztechImportModel.task"
                [data]="tasks"
                [settings]="dropdownSettings"
                (onRemoteSearch)="loadTasks($event.target.value)"
                name="taskInput"
                id="taskInput"
                #taskInput="ngModel"
                required>
              </angular2-multiselect>
              <div class="form-control-feedback" *ngIf="heiztechImportForm.submitted && !taskInput.valid">
                {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
              </div>
            </div>
          </div>
          <div class="form-group row"
               [ngClass]="{ 'has-danger': heiztechImportForm.submitted && !customerInput.valid }">
            <label class="col-form-label col-md-4 text-md-right">{{'CUSTOMER_SEARCH_FIELD_TYPE' | translate}}</label>
            <div class="col-md-8">
              <angular2-multiselect
                class="form-control"
                [(ngModel)]="heiztechImportModel.customer"
                [data]="customers"
                [settings]="dropdownSettings"
                (onRemoteSearch)="loadCustomers($event.target.value)"
                name="customerInput"
                id="customerInput"
                #customerInput="ngModel"
                required>
              </angular2-multiselect>
              <div class="form-control-feedback" *ngIf="heiztechImportForm.submitted && !customerInput.valid">
                {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
              </div>
            </div>
          </div>
          <div class="form-group row"
               [ngClass]="{ 'has-danger': heiztechImportForm.submitted && !nameInput.valid }">
            <label class="col-form-label col-md-4 text-md-right">{{'TASK_RECORD_CREATE_NAME' | translate}}</label>
            <div class="col-md-8">
              <input type="text" class="form-control"
                     placeholder="{{'TASK_RECORD_CREATE_NAME' | translate}}"
                     [ngClass]="{ 'form-control-danger': heiztechImportForm.submitted && !nameInput.valid }"
                     [(ngModel)]="heiztechImportModel.name"
                     name="nameInput" id="nameInput" #nameInput="ngModel" required>
              <div class="form-control-feedback" *ngIf="heiztechImportForm.submitted && !nameInput.valid">
                {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" (click)="closeHeiztechImportDialog(false)">
            {{'COMMON_BUTTON_CANCEL' | translate}}
          </button>
          <button type="submit" class="btn btn-primary">{{'COMMON_BUTTON_SAVE' |
            translate}}
          </button>
        </div>
      </form>
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div><!-- /.modal -->

<app-file-upload-dialog
  [uploadPath]="heiztechImportUploadPath"
  (onResult)="onHeiztechImportSuccess($event)"
  #heiztechImportUploadDialog>
</app-file-upload-dialog>

<app-dql-dialog-container #dqlDialogHolder></app-dql-dialog-container>
