/* eslint-disable */
import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../../../lib/auth.service';
import { OwnerUserItemFactory, QueryFieldModel, SelectUtils, UiConstants, } from '../../../util/core-utils';
import { EmptyMessage, IdentityArray } from '../../../lib/util/messages';
import { UserData, UserDataLoader, UserDataLoaderPermissionDeniedStrategy } from '../../../lib/user-data-loader';
import { Arrays } from '../../../lib/util/arrays';
import { CustomerNoteResource, NoteList, NoteService } from '../../../lib/note.service';
import { NoteSearchModel } from '../../../util/note-utils';
import { Strings } from '../../../lib/util/strings';
import { RightModel } from '../../../app.rights';
import { RightResolver, RightService } from '../../../lib/right.service';
import { CustomerRecord, CustomerRecordService } from '../../../lib/customer/customer-record.service';
import { TaskRecord, TaskRecordService } from '../../../lib/task/task-record.service';
import { OrderType, ResourceQueryResult, Services } from '../../../lib/util/services';
import { Set } from 'immutable';
import { combineLatest, Observable } from 'rxjs';
import { NoteSearch, NoteSearchService } from '../../../lib/note-search-service';
import { DisabledEnum, DisabledItem } from '../../../util/search-utils';

/* eslint-enable */

@Component({
  selector: 'app-note-list',
  templateUrl: 'note-list.component.html',
  styleUrls: ['note-list.component.scss']
})
export class NoteListComponent implements OnInit, AfterViewInit, OnDestroy {



  queryModel: QueryFieldModel<NoteList.OrderField> = new QueryFieldModel(NoteList.OrderField.ID, OrderType.DESC);
  searchModel: NoteSearchModel;
  noteList: CustomerNoteListModel[] = [];
  disabledItems: DisabledItem[] = [];
  showSearch: boolean = false;
  rightModel: RightModel = RightModel.empty();
  breadcrumbSelf: string;
  compactSidebar: boolean = document.querySelector('body')!.classList.contains('sidebar-compact');
  SelectUtils = SelectUtils;
  searchResetClicked = false;
  NoteList = NoteList;
  UiConstants = UiConstants;

  ngOnInit() {
    this.initDisabledOptions(DisabledEnum.FALSE);
    this.translateService.get('MENU_NAVBAR_NOTES').subscribe(
      (result: string) => {
        this.breadcrumbSelf = result;
      }
    );
    this.loadSearch(() => {
      this.showSearch = !this.searchModel.isEmpty();
      this.loadRightModels();
      this.loadList();
    });
  }

  ngAfterViewInit(): void {
  }

  private loadSearch(completion: () => void) {
    const obs: Observable<SearchLoadResult> = combineLatest(
      this.noteSearchService.getSearchData({}),
      (storedSearchData: NoteSearch.SearchDataResult) => {
        const result: SearchLoadResult = {
          storedSearchData: storedSearchData
        };
        return result;
      }
    );
    obs.subscribe(
      (result: SearchLoadResult) => {
        this.postInitSearch(result.storedSearchData);
        completion();
      }
    );
  }

  private postInitSearch(storedSearchData: NoteSearch.SearchDataResult) {
    this.queryModel.itemsPerPage = storedSearchData.searchData.itemsPerPage;
    this.queryModel.currentPage = storedSearchData.searchData.pageNumber;
    this.queryModel.setOrder(storedSearchData.searchData.order);
    this.searchModel.id = storedSearchData.searchData.id;
    this.searchModel.content = storedSearchData.searchData.content;
    this.searchModel.taskName = storedSearchData.searchData.taskName;
    this.searchModel.customerName = storedSearchData.searchData.customerName;
    this.searchModel.ownerUserName = storedSearchData.searchData.ownerUserName;
    this.searchModel.disabled = storedSearchData.searchData.disabled;
  }

  private loadRightModels() {
    this.rightService.getRightResolver().subscribe(
      (resolver: RightResolver) => {
        this.rightModel = RightModel.of(resolver);
      }
    );
  }

  onSearchClicked() {
    this.loadList();
  }

  pageChanged(selectedPage: number) {
    this.loadList(selectedPage);
  }

  itemsPerPageChanged(itemsPerPage: number) {
    this.queryModel.itemsPerPage = itemsPerPage;
    this.loadList(1);
  }

  orderBy(field: NoteList.OrderField) {
    this.queryModel.onOrderFieldChanged(field);
    this.loadList(1);
  }

  private initDisabledOptions(initValue: DisabledEnum) {
    this.disabledItems = [];
    const disabledEnums: DisabledEnum[] = [DisabledEnum.NONE, DisabledEnum.FALSE, DisabledEnum.TRUE];
    Arrays.iterateByIndex(disabledEnums, (key) => {
      const item = new DisabledItem();
      item.id = key;
      this.translateService.get('COMMON_VALUE_DISABLED_ENUM_' + key).subscribe(
        (text: string) => {
          item.text = text;
        }
      );
      this.disabledItems.push(item);
      if (key === initValue) {
        this.searchModel.disabled = item;
      }
    });
  }


  private loadList(pageNumber?: number) {
    const disabled: boolean | undefined = !this.searchModel.disabled ||
    this.searchModel.disabled.id === DisabledEnum.NONE ?
      undefined : this.searchModel.disabled.id === DisabledEnum.TRUE;

    const requestedPage = pageNumber ? pageNumber : this.queryModel.currentPage;
    const order = this.queryModel.getOrder();
    this.noteService.query({
      owner_user_name: Strings.undefinedOrNonEmpty(this.searchModel.ownerUserName),
      customer_name: Strings.undefinedOrNonEmpty(this.searchModel.customerName),
      task_record_name: Strings.undefinedOrNonEmpty(this.searchModel.taskName),
      content: Strings.undefinedOrNonEmpty(this.searchModel.content),
      disabled: disabled,
      page_number: requestedPage,
      number_of_items: this.queryModel.itemsPerPage,
      order: Services.createOrderFieldParameter(NoteList.Keys.toOrderFieldKey, Set.of(order)),
    })
      .subscribe(
      (result: ResourceQueryResult<CustomerNoteResource>) => {
        this.noteList = [];
        const customerIds: number[] = [];
        const ownerUserIds: number[] = [];
        const taskRecordIds: number[] = [];
        result.items.forEach((note: CustomerNoteResource) => {
          if (note.customer_id) {
            customerIds.push(note.customer_id);
          }
          if (note.owner_user_id) {
            ownerUserIds.push(note.owner_user_id);
          }
          if (note.task_record_id) {
            taskRecordIds.push(note.task_record_id);
          }
          this.noteList.push({
            id: note.id,
            disabled: note.disabled,
            creationTime: note.creation_time,
            updateTime: note.update_time,
            content: note.content,
            customerId: note.customer_id,
            customerName: '',
            ownerUserId: note.owner_user_id,
            ownerUserName: '',
            taskRecordId: note.task_record_id,
            taskRecordName: '',
            attachmentIds: note.attachment_ids,
          });
        });
        if (result.items.length > 0) {
          this.combineResources(customerIds, ownerUserIds, taskRecordIds);
        }
        this.queryModel.currentPage = requestedPage;
        this.queryModel.totalNumberOfItems = result.pagingResult.totalNumberOfItems;
        this.queryModel.currentNumberOfItems = result.pagingResult.currentNumberOfItems;
      }
    );
  }

  setDisabled(event: any, note: CustomerNoteResource, disabled: boolean) {
    this.noteService.setDisabled({
      id: note.id,
      disabled: disabled
    })
      .subscribe(
      (result: EmptyMessage) => {
        this.loadList();
      },
      (error: any) => {
        this.loadList();
      }
    );
  }

  constructor(private translateService: TranslateService,
              private authService: AuthService,
              private ownerUserItemFactory: OwnerUserItemFactory,
              private userDataLoader: UserDataLoader,
              private customerRecordService: CustomerRecordService,
              private taskRecordService: TaskRecordService,
              private rightService: RightService,
              private noteService: NoteService,
              private noteSearchService: NoteSearchService) {
    this.searchModel = new NoteSearchModel();
  }

  toggleSearch() {
    this.showSearch = !this.showSearch;
  }

  onSearchReset() {
    this.noteSearchService.resetSearchData({}).subscribe(
      (result) => {
        this.searchResetClicked = true;
        this.loadSearch(() => {
          this.loadList(1);
        });
      }
    );
  }

  private saveSearch() {
    const request = {
      searchData: {
        itemsPerPage: this.queryModel.itemsPerPage,
        pageNumber: this.queryModel.currentPage,
        order: this.queryModel.getOrder(),
        id: this.searchModel.id,
        content: this.searchModel.content,
        taskName: this.searchModel.taskName,
        customerName: this.searchModel.customerName,
        ownerUserName: this.searchModel.ownerUserName,
        disabled: this.searchModel.disabled
      }
    };
    this.noteSearchService.setSearchData(request).subscribe(
      (result) => {
      },
      (error) => {
      }
    );
  }

  ngOnDestroy() {
    this.saveSearch();
  }

  combineResources(customerIds: number[], userIds: number[], taskRecordIds: number[]) {
    return combineLatest(
      this.customerRecordService.listQuery({customerRecordIdSet: Set.of(...customerIds), fields: Set.of('id', 'name')}),
      this.userDataLoader.loadAllWithFields(UserDataLoaderPermissionDeniedStrategy.MISS_PERMISSION_DENIED, undefined,
        Set.of('id', 'user_name', 'person_name')), //  TODO
      this.taskRecordService.listQuery({
        taskRecordIdSet: Set.of(...taskRecordIds),
        fields: Set.of('id', 'name')
      }),
      (customers: CustomerRecord.CustomerRecord[],
       userList: IdentityArray<UserData>,
       taskRecordList: TaskRecord.TaskRecord[]) => {
        return {
          customers: customers,
          userList: userList,
          taskRecords: taskRecordList
        }
      }
    ).subscribe((value: {
      customers: CustomerRecord.CustomerRecord[],
      userList: IdentityArray<UserData>,
      taskRecords?: TaskRecord.TaskRecord[]
    }) => {
      this.noteList.forEach(note => {
        const c = value.customers.find((cr) => cr.customerRecordId === note.customerId);
        note.customerName = c ? c.name : '';
        const u = value.userList.find(user => user.id === note.ownerUserId);
        note.ownerUserName = u ? u.person_name + '(' + u.user_name + ')' : '';
        if (value.taskRecords) {
          const t = value.taskRecords.find(tr => tr.taskRecordId === note.taskRecordId);
          note.taskRecordName = t ? t.name : '';
        }
      });
    });
  }

}

export class CustomerNoteListModel {
  id: number;
  disabled: boolean;
  creationTime: string;
  updateTime: string;
  content: string = '';
  customerId: number;
  customerName: string = '';
  ownerUserId: number;
  ownerUserName: string = '';
  taskRecordId?: number;
  taskRecordName: string = '';
  attachmentIds: number[];
}


interface SearchLoadResult {
  storedSearchData: NoteSearch.SearchDataResult,
}

