<div class="modal-content">
  <div class="modal-header">
    <p class="modal-title"><strong>{{headerKey | translate}}</strong></p>
    <button type="button" class="close" (click)="closeDialog()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <form (ngSubmit)="f.form.valid && submit()" #f="ngForm" novalidate>
  <div class="modal-body">
      <mat-dialog-content>

        <app-customer-record-billing-info-base
          [mode]="data.mode"
          [data]="data.data"
          [form]="f">
        </app-customer-record-billing-info-base>

      </mat-dialog-content>
  </div><!-- /.modal-content -->
    <div class="modal-footer justify-content-end">
      <button type="button" class="btn btn-outline-primary" (click)="closeDialog()">
        {{(readonly ? 'COMMON_BUTTON_CLOSE' : 'COMMON_BUTTON_CANCEL')| translate}}
      </button>
      <button type="submit" class="btn btn-primary ml-1" *ngIf="!readonly">
        {{'COMMON_BUTTON_SAVE' | translate}}
      </button>
    </div>
  </form>
</div><!-- /.modal -->

