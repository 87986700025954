/// <reference types="@types/googlemaps" />
import {
  ApplicationRef,
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  EventEmitter,
  Injector,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { Transport, TransportService } from '../../../lib/transport/transport.service';
import { GoogleMapsLoaderUtil } from '../../../util/google/google-maps-loader.util';
import { ConfigurationResource, ConfigurationService } from '../../../lib/core-ext/configuration.service';
import { timer } from 'rxjs/internal/observable/timer';
import { HttpClient } from '@angular/common/http';
import { combineLatest, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { UIRouter } from '@uirouter/angular';
import { StateName } from '../../../app.state-names';
import { PositionLogInfoWindowComponent } from './info-window/position-log-info-window.component';
import { Dates, DefaultOffsetDateTimeTemplate, OffsetDateTime } from '../../../lib/util/dates';
/* eslint-enable */


@Component({
  selector: 'app-transport-running-map',
  templateUrl: './transport-running-map.component.html',
  styleUrls: ['./transport-running-map.component.scss']
})
export class TransportRunningMapComponent implements OnInit, OnDestroy {

  private static poicolors: string[] = [
    '#EF5350',
    '#EC407A',
    '#AB47BC',
    '#7E57C2',
    '#5C6BC0',
    '#42A5F5',
    '#29B6F6',
    '#26C6DA',
    '#26A69A',
    '#66BB6A',
    '#9CCC65',
    '#D4E157',
    '#FFEE58',
    '#FFCA28',
    '#FFA726',
    '#FF7043',
    '#8D6E63',
    '#BDBDBD',
    '#78909CF'];

  private logs: TransportPositionLogModel[] = [];
  @ViewChild('gmap', { static: true }) gmapElement: any;

  @Output()
  onMapRefresh: EventEmitter<OffsetDateTime> = new EventEmitter<OffsetDateTime>();

  map: google.maps.Map;

  private firstLoad: boolean = true;
  private config: ConfigurationResource.Configuration;

  isInfoWindowVisible: boolean;

  private poiIconStr: string;
  private poiDelayedIconStr: string;
  private simpleIconStr: string;
  private poiLostIconStr: string;

  private timerSubscription?: Subscription = undefined;

  private readonly dateTemplate: DefaultOffsetDateTimeTemplate;

  compRef: ComponentRef<PositionLogInfoWindowComponent>;

  constructor(private transportService: TransportService,
              private configurationService: ConfigurationService,
              private http: HttpClient,
              private injector: Injector,
              private resolver: ComponentFactoryResolver,
              private appRef: ApplicationRef,
              private zone: NgZone,
              private uiRouter: UIRouter) {
    this.dateTemplate = new DefaultOffsetDateTimeTemplate('YYYY-MM-DD HH:mm');
  }

  initMap(completion: () => void) {
    if (!this.map) {
      if (GoogleMapsLoaderUtil.didMapLoad()) {
        this.createMap();
        completion();
      }
      else {
        GoogleMapsLoaderUtil.subscribe(() => {
          this.createMap();
          completion();
        });
      }
    }
    else {
      completion();
    }

  }


  createMap() {
    const mapProp = {
      center: new google.maps.LatLng(47.49801, 19.03991),
      zoom: 15,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      streetViewControl: false,
      mapTypeControl: false
    };
    this.map = new google.maps.Map(this.gmapElement.nativeElement, mapProp);
  }

  ngOnInit() {
    this.config = this.configurationService.getConfiguration();
    this.initMap(() => this.loadPoiIcons(() => this.loadData()));
  }

  private loadData() {
    this.destroyTimer();
    this.timerSubscription = timer(0, this.config.position_log_interval_sec * 1000).subscribe(value => {
      this.onMapRefresh.emit(Dates.now());
      this.transportService.getRunningPositionLogs({}).subscribe((logs: Transport.PositionLogExt[]) => {
        this.createMarkers(logs);
      });
    });
  }

  private createMarkers(logs: Transport.PositionLogExt[]) {

    const newLogs: TransportPositionLogModel[] = [];

    logs.forEach((log, index) => {
      let marker: google.maps.Marker;

      const coord = log.positionLog.coordinate;
      const latlng = new google.maps.LatLng(coord.latitude.toNumber(), coord.longitude.toNumber());
      const oldLogIndex = this.logs.findIndex(m => m.log.transport.id === log.transport.id);

      let iconStr: string = this.poiIconStr;
      if (log.transport.delayMinutes.asMilliseconds() > 0) {
        iconStr = this.poiDelayedIconStr;
      }
      if (Dates.now().diffDuration(log.positionLog.creationTime)!.asSeconds()
        > this.config.feature_flags.lost_driver_threshold_in_seconds) {
        iconStr = this.poiLostIconStr;
      }
      const poiUrl = this.createIconUrl(iconStr, log.transport.id);
      const iconUrl = this.createIconUrl(this.simpleIconStr, log.transport.id);

      if (oldLogIndex >= 0) {
        const oldLog = this.logs.splice(oldLogIndex, 1)[0];
        oldLog.log = log;
        marker = oldLog.marker;
        newLogs.push(oldLog);
      }
      else {
        marker = new google.maps.Marker({});
        marker.setMap(this.map);
        marker.setLabel(log.transport.vehicleLicencePlate);
        const model: TransportPositionLogModel = {
          log: log,
          marker: marker,
          infoWindow: new google.maps.InfoWindow(),
          iconUrl: iconUrl
        };
        const zone = this.zone;
        const realThis = this; // I love js
        marker.addListener('click', function (e) {
          zone.run(() => realThis.onMarkerClick(model, e));
        });
        model.infoWindow.addListener('closeclick', _ => {
          realThis.compRef.destroy();
        });
        newLogs.push(model);
      }
      marker.setPosition(latlng);
      marker.setTitle(log.positionLog.creationTime.format(this.dateTemplate));
      marker.setZIndex(index);
      marker.setIcon({
        url: poiUrl,
        labelOrigin: new google.maps.Point(30, 30)
      });

    });
    this.logs.forEach(l => {
      l.marker.setMap(null);
    });
    this.logs = newLogs;
    if (this.firstLoad) {
      this.firstLoad = false;
      this.panToData();
    }

  }

  private onMarkerClick(model: TransportPositionLogModel, e: any) {
    if (this.compRef) {
      this.compRef.destroy();
    }

    // window component create
    const compFactory = this.resolver.resolveComponentFactory(PositionLogInfoWindowComponent);
    this.compRef = compFactory.create(this.injector);

    // set model
    this.compRef.instance.model = model;
    const subscription = this.compRef.instance.onTransportClicked.subscribe(x => {
      this.navigateToTransport(x);
    });

    // node create
    const div = document.createElement('div');
    div.appendChild(this.compRef.location.nativeElement);

    model.infoWindow.setContent(div);
    model.infoWindow.open(this.map, model.marker);

    this.appRef.attachView(this.compRef.hostView);
    this.compRef.onDestroy(() => {
      model.infoWindow.close();
      this.appRef.detachView(this.compRef.hostView);
      subscription.unsubscribe();
    });
  }

  navigateToTransport(id: number) {
    this.uiRouter.stateService.go(StateName.TRANSPORT_DETAIL, {id: id});
  }

  panToData() {
    const bounds: google.maps.LatLngBounds = new google.maps.LatLngBounds();
    this.logs.forEach(l => {
      const position = l.marker.getPosition();
      if (position) {
        bounds.extend(position);
      }
    });
    const map = this.map;
    if (!bounds.isEmpty()) {
      google.maps.event.addListenerOnce(this.map, 'idle', function () {
        map.fitBounds(bounds);
        map.panToBounds(bounds);
      });
      map.setZoom(map.getZoom());
    }
  }

  createIconUrl(iconStr: string, transportId: number): string {
    const svg = iconStr.replace('{{fill}}', TransportRunningMapComponent.poicolors[transportId %
    TransportRunningMapComponent.poicolors.length]);
    const iconUrl = 'data:image/svg+xml;charset=UTF-8;base64,' + btoa(svg);
    return iconUrl;
  }

  loadPoiIcons(completion: () => void) {
    combineLatest(this.http.get('../../../../assets/img/poi/running/ic_map_poi_running.svg', {responseType: 'text'}),
      this.http.get('../../../../assets/img/poi/running/ic_map_poi_running_delayed.svg', {responseType: 'text'}),
      this.http.get('../../../../assets/img/poi/running/ic_map_running.svg', {responseType: 'text'}),
      this.http.get('../../../../assets/img/poi/running/ic_map_poi_lost.svg', {responseType: 'text'}),
    )
      .pipe(map(([poiIcon, delayedIcon, icon, lostIcon]) => {
        return {poiIcon: poiIcon, delayedIcon: delayedIcon, icon: icon, lostIcon: lostIcon};
      })).subscribe(value => {
      this.poiIconStr = value.poiIcon;
      this.poiDelayedIconStr = value.delayedIcon;
      this.poiLostIconStr = value.lostIcon;
      this.simpleIconStr = value.icon;
      completion();
    })
  }

  ngOnDestroy() {
    this.destroyTimer();
  }

  destroyTimer() {
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
      this.timerSubscription = undefined;
    }
  }
}

export class TransportPositionLogModel {

  log: Transport.PositionLogExt;
  marker: google.maps.Marker;
  infoWindow: google.maps.InfoWindow;
  iconUrl: string;
}

