<div class="card-body">
  <form (ngSubmit)="onSearchClicked()" #f="ngForm" novalidate>
    <div class="row form-group">

      <div *ngIf="rightModel.colonnadeB2bReadAll.hasRight()"
           class="col-lg-4 col-md-6" [ngClass]="{ 'has-danger': hasLocalFieldError(user) }">
        <label class="search-label"
               [class.required-field-label]="userRequired">
          {{'COLONNADE_CLIENT_DOCUMENT_STAT_SEARCH_USER' | translate}}
        </label>
        <angular2-multiselect [data]="users" [settings]="dropdownSettings"
                              class="form-control"
                              [ngClass]="{ 'form-control-danger': hasLocalFieldError(user) }"
                              (onRemoteSearch)="onUserSearch($event.target.value)"
                              [(ngModel)]="searchModel.user"
                              [required]="userRequired"
                              #user="ngModel" name="user" id="user">
        </angular2-multiselect>
        <div class="form-control-feedback" *ngIf="hasLocalFieldError(user)">
          {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
        </div>
      </div>

      <div class="col-lg-4 col-md-6" [ngClass]="{ 'has-danger': hasLocalFieldError(sponsorCode) }">
        <label class="search-label"
               [class.required-field-label]="sponsorCodeRequired">
          {{'COLONNADE_CLIENT_DOCUMENT_STAT_SEARCH_SPONSOR_CODE' | translate}}
        </label>
        <angular2-multiselect [data]="sponsorsForSearch" [settings]="dropdownSettings"
                              class="form-control"
                              [ngClass]="{ 'form-control-danger': hasLocalFieldError(sponsorCode) }"
                              (onRemoteSearch)="onSponsorSearch($event.target.value)"
                              [(ngModel)]="searchModel.sponsorCode"
                              (ngModelChange)="onSponsorChanged()"
                              [required]="sponsorCodeRequired"
                              #sponsorCode="ngModel" name="sponsorCode" id="sponsorCode">
        </angular2-multiselect>
        <div class="form-control-feedback" *ngIf="hasLocalFieldError(sponsorCode)">
          {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
        </div>
      </div>

      <div class="col-lg-4 col-md-6" [ngClass]="{ 'has-danger': hasLocalFieldError(branchCode) }">
        <label class="search-label"
               [class.required-field-label]="branchCodeRequired">
          {{'COLONNADE_CLIENT_DOCUMENT_STAT_SEARCH_BRANCH_CODE' | translate}}
        </label>
        <angular2-multiselect [data]="branchesForSearch" [settings]="dropdownSettingsForBranch"
                              class="form-control"
                              [ngClass]="{ 'form-control-danger': hasLocalFieldError(branchCode) }"
                              (onRemoteSearch)="onBranchSearch($event.target.value)"
                              [(ngModel)]="searchModel.branchCode"
                              [required]="branchCodeRequired"
                              #branchCode="ngModel" name="branchCode" id="branchCode">
        </angular2-multiselect>
        <div class="form-control-feedback" *ngIf="hasLocalFieldError(branchCode)">
          {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
        </div>
      </div>

      <div class="col-lg-4 col-md-6" [ngClass]="{ 'has-danger': hasLocalFieldError(dateFrom) }">
        <label class="search-label"
               [class.required-field-label]="dateFromRequired">
          {{'COLONNADE_CLIENT_DOCUMENT_STAT_SEARCH_DATE_FROM' | translate}}
        </label>
        <div class="input-group">
          <input type="text" class="form-control date"
                 placeholder="{{'COLONNADE_CLIENT_DOCUMENT_STAT_SEARCH_DATE_FROM' | translate}}"
                 [ngClass]="{ 'form-control-danger': hasLocalFieldError(dateFrom) }"
                 [(ngModel)]="searchModel.dateFrom" ngbDatepicker name="dateFrom" id="dateFrom"
                 #date_from="ngbDatepicker" #dateFrom="ngModel"
                 [required]="dateFromRequired">
          <div class="input-group-addon-gray" (click)="date_from.toggle()">
            <i class="icomoon icomoon-calendar"></i>
          </div>
        </div>
        <div class="form-control-feedback" *ngIf="hasLocalFieldError(dateFrom)">
          {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
        </div>
      </div>

      <div class="col-lg-4 col-md-6" [ngClass]="{ 'has-danger': hasLocalFieldError(dateTo) }">
        <label class="search-label"
               [class.required-field-label]="dateToRequired">
          {{'COLONNADE_CLIENT_DOCUMENT_STAT_SEARCH_DATE_TO' | translate}}
        </label>
        <div class="input-group">
          <input type="text" class="form-control date"
                 [ngClass]="{ 'form-control-danger': hasLocalFieldError(dateTo) }"
                 placeholder="{{'COLONNADE_CLIENT_DOCUMENT_STAT_SEARCH_DATE_TO' | translate}}"
                 [(ngModel)]="searchModel.dateTo" ngbDatepicker name="dateTo" id="dateTo"
                 #date_to="ngbDatepicker" #dateTo="ngModel"
                 [required]="dateToRequired">
          <div class="input-group-addon-gray" (click)="date_to.toggle()">
            <i class="icomoon icomoon-calendar"></i>
          </div>
        </div>
        <div class="form-control-feedback" *ngIf="hasLocalFieldError(dateTo)">
          {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
        </div>
      </div>

    </div>

    <div class="row p-0">
      <div class="col-md-12 d-flex justify-content-md-end align-items-end">
        <div class="btn-group" role="group">
          <button type="button" (click)="onSearchReset()" class="btn btn-outline-primary search-button mr-1">
            {{'COMMON_BUTTON_SEARCH_DELETE' | translate}}
          </button>
          <button type="submit" class="btn btn-primary search-button">
            {{'COMMON_BUTTON_SEARCH' | translate}}
          </button>
        </div>
      </div>
    </div>

  </form>

</div> <!-- ./card-body end -->
