/* eslint-disable */
import { User, UserDisabled } from '../lib/user.service';
import { Injectable } from '@angular/core';
import { FileItem, FileUploader, FileUploaderOptions, ParsedResponseHeaders } from 'ng2-file-upload';
import { UserData } from '../lib/user-data-loader';
import { Arrays } from '../lib/util/arrays';
import { ScreenSize, ScreenSizes } from './screen-size';
import { NgbDateStruct, NgbModalConfig, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { Order, OrderType, } from '../lib/util/services';
import { Observable, of } from 'rxjs';
import { List, Set } from 'immutable';
import { IdentityArray } from '../lib/util/messages';
import { Models } from './model-utils';
import { UserGroup, UserGroupDisabled } from '../lib/user-group.service';
import { AssigneeItem } from './task-record-utils';
import { CustomerRecord } from '../lib/customer/customer-record.service';
import { DisabledItem } from './search-utils';
import { Query } from '../lib/query/field';
import { BadgeStyle } from '../shared/table-badge/badge-style';
/* eslint-enable */

export class UiConstants {

  public static readonly angular2MultiselectClass = 'multiitem';
  public static readonly angular2MultiselectDefaultBadgeLimit = 5;
  public static readonly angular2MultiselectUserGroupEditorBadgeLimit = 10;

  public static readonly defaultCacheMaxAge = 60000;

  public static readonly toastTypeSuccess = 'success';
  public static readonly toastTypeInfo = 'info';
  public static readonly toastTypeWarning = 'warning';
  public static readonly toastTypeError = 'error';

  public static readonly ToastTimeoutShort = 3000;
  public static readonly ToastTimeoutLong = 5000;
  public static readonly ToastTimeoutInfinite = 0;
  public static readonly formRecordFieldPadding = '0.5rem';

  public static readonly autocompleteDebounceTime = 250; // 250 ms represents the (roughly) median reaction time of a human
  public static readonly autocompletePageSize = 30;

  public static readonly queryWithoutFullScreenLoadingProgress = true;

  public static readonly pageNumbers = [10, 20, 50, 100];
  private static readonly paginationMaxSizeSmall = 5;

  private static readonly paginationMaxSizeNormal = 10;
  public static readonly defaultCountryCode = 'HU';

  public static readonly defaultLanguageCode = 'hu';
  public static readonly maximumVarcharLength: number = 250;
  public static readonly textareaDescriptionRowsNumber: string = '6';
  public static readonly maxInputNumberLength: string = '23';
  public static readonly maxInputBigDecimalLengthWithSpaces: string = '22';
  public static readonly maxInputLongLength: string = '18';
  public static readonly maxInputDoubleLength: string = '17';
  public static readonly maxInputIntLengthWithSpaces: string = '11';
  public static readonly maxInputIntLength: string = '9';
  public static readonly minSupportedScreenSize: number = 800;

  public static readonly minDate: NgbDateStruct = {
    year: 1800, month: 1, day: 1
  };

  public static readonly maxDate: NgbDateStruct = {
    year: 2200, month: 1, day: 1
  };

  public static readonly modalConfig: NgbModalOptions = {
    backdrop: 'static',
    keyboard: true
  };

  public static calculateMultiselectMaxBadge(widthPercent?: number) {
    if (!widthPercent) {
      return 0; // invalid
    }
    if (widthPercent <= 0) {
      return 0;  // invalid
    }
    if (widthPercent > 100) {
      return 0;  // invalid
    }
    if (widthPercent === 100) {
      return 20; // full screen is special
    }
    return Math.round(10 * (widthPercent / 100));
  }

  public static get paginationMaxSize(): number {
    const screenSize = ScreenSizes.getWindowScreenSize();
    switch (screenSize) {
      case ScreenSize.SMALL:
      case ScreenSize.EXTRA_SMALL:
        return UiConstants.paginationMaxSizeSmall;
      default:
        return UiConstants.paginationMaxSizeNormal;
    }
  }

}

export interface BaseStorageModel {
  page_number?: number;
  items_per_page?: number;
  order?: string;
}

export class QueryModel {

  itemsPerPage = UiConstants.pageNumbers[0];
  currentPage = 1;
  currentNumberOfItems = 0;
  totalNumberOfItems = 0;

  private orderField?: string;
  private orderType: '+' | '-' = '+';

  public onOrderFieldChanged(orderField: string) {
    if (orderField === this.orderField) {
      this.orderType = this.orderType === '+' ? '-' : '+';
    }
    else {
      this.orderField = orderField;
      this.orderType = '+';
    }
  }

  public getOrderType(field: string): '+' | '-' | null {
    if (field === this.orderField) {
      return this.orderType;
    }
    return null;
  }

  public getOrder(): string | undefined {
    if (!this.orderField) {
      return undefined;
    }
    return this.orderType + this.orderField;
  }

}

export class QueryFieldModel<T> {

  itemsPerPage = UiConstants.pageNumbers[0];
  currentPage = 1;
  currentNumberOfItems = 0;
  totalNumberOfItems = 0;

  constructor(private orderField: T, private orderType: OrderType) {
  }

  public indeterminateNumberOfItems(currentPage: number) {
    const size = UiConstants.paginationMaxSize; // TODO: recalculate after size change (window resize etc.)
    const halfSize = size / 2.0;
    const lastPage = Math.ceil(Math.max(size, currentPage + halfSize));
    this.currentNumberOfItems = this.itemsPerPage * lastPage;
    this.totalNumberOfItems = this.currentNumberOfItems;
  }

  public onOrderFieldChanged(orderField: T) {
    if (orderField === this.orderField) {
      this.orderType = this.orderType === OrderType.ASC ? OrderType.DESC : OrderType.ASC;
    }
    else {
      this.orderField = orderField;
      this.orderType = OrderType.ASC;
    }
  }

  public getOrderType(field: T): OrderType | undefined {
    if (field === this.orderField) {
      return this.orderType;
    }
    return undefined;
  }

  public getOrder(): Order<T> {
    return {
      type: this.orderType,
      field: this.orderField
    };
  }

  public setOrder(order: Order<T>) {
    this.orderType = order.type;
    this.orderField = order.field;
  }

}

export type OrderFieldFunction<T> = (field: T) => Query.OrderField;

export class OrderFieldModel<T> {

  itemsPerPage = UiConstants.pageNumbers[0];
  currentPage = 1;
  currentNumberOfItems = 0;
  totalNumberOfItems = 0;

  public static create<T>(resolver: OrderFunctionResolver<T>, order: Order<string>, deserialized: OrderFieldModel<T>) {
    const fn = resolver.getFunction(order.field);
    const model = new OrderFieldModel(fn, order.type);
    model.itemsPerPage = deserialized.itemsPerPage;
    model.currentPage = deserialized.currentPage;
    model.currentNumberOfItems = deserialized.currentNumberOfItems;
    model.totalNumberOfItems = deserialized.totalNumberOfItems;
    return model;
  }

  constructor(private orderField: OrderFieldFunction<T>, private orderType: OrderType) {
  }

  public onOrderFieldChanged(orderField: OrderFieldFunction<T>) {
    if (orderField === this.orderField) {
      this.orderType = this.orderType === OrderType.ASC ? OrderType.DESC : OrderType.ASC;
    }
    else {
      this.orderField = orderField;
      this.orderType = OrderType.ASC;
    }
  }

  public getOrderType(field: OrderFieldFunction<T>): OrderType | undefined {
    if (field === this.orderField) {
      return this.orderType;
    }
    return undefined;
  }

  public createOrderFunction(): Query.OrderFunction<T> {
    if (OrderType.ASC === this.orderType) {
      return (t) => List.of(this.orderField(t).asc().nullsLast());
    }
    return (t) => List.of(this.orderField(t).desc().nullsLast());
  }

  public createOrder(resolver: OrderFunctionResolver<T>): Order<string> {
    return {
      field: resolver.getName(this.orderField),
      type: this.orderType
    };
  }

  public setOrder(resolver: OrderFunctionResolver<T>, order: Order<string>) {
    const fn = resolver.getFunction(order.field);
    this.orderType = order.type;
    this.orderField = fn;
  }

}

export class OrderFunctionResolverBuilder<T> {

  readonly names: Map<OrderFieldFunction<T>, string> = new Map();
  readonly fns: Map<string, OrderFieldFunction<T>> = new Map();

  build(): OrderFunctionResolver<T> {
    return new OrderFunctionResolverImpl<T>(this);
  }

  add(fn: (f: T) => Query.OrderField, name: string): OrderFunctionResolverBuilder<T> {
    this.names.set(fn, name);
    this.fns.set(name, fn);
    return this;
  }

}

export interface OrderFunctionResolver<T> {
  getName(fn: OrderFieldFunction<T>): string;

  getFunction(name: string): OrderFieldFunction<T>
}

export namespace OrderFunctionResolver {

  export function builder<T>(): OrderFunctionResolverBuilder<T> {
    return new OrderFunctionResolverBuilder<T>();
  }

}

class OrderFunctionResolverImpl<T> implements OrderFunctionResolver<T> {

  private readonly names: Map<OrderFieldFunction<T>, string>;
  private readonly fns: Map<string, OrderFieldFunction<T>>;

  public constructor(builder: OrderFunctionResolverBuilder<T>) {
    this.names = builder.names;
    this.fns = builder.fns;
  }

  getFunction(name: string): OrderFieldFunction<T> {
    return this.fns.get(name)!;
  }

  getName(fn: OrderFieldFunction<T>): string {
    return this.names.get(fn)!;
  }

}

interface IOptionItem<Id> {
  readonly text;
}

export class OptionItem<Id> implements IOptionItem<Id> {

  public static readonly KEY_TEXT = 'text';

  id: Id | null;
  text: string;
  disabled?: boolean;

  public static idOrUndefined<Id>(item?: OptionItem<Id>): Id | undefined {
    if (item && item.id) {
      return item.id;
    }
    return undefined;
  }

}

export class OptionItemWithName<Id> extends OptionItem<Id> {
  name: string;
}

export class MultiselectOptionItem<Id> {
  id: Id;
  itemName: string;
  itemSubtitle?: string;
  disabled?: boolean;
}

export class MultiselectOptionItemWithParentId<Id, ParentId> extends MultiselectOptionItem<Id> {
  parentId: ParentId;
}

export class MultiselectOptionItemWithData<Id> extends MultiselectOptionItem<Id> {
  data?: any;
}

export interface LocalizedTypeObject<Type> {
  type: Type;
  stringKey: string;
}

export class ImmutableOptionItem<Id> implements IOptionItem<Id> {

  private textResult: string = '';
  private subtitleResult: string = '';
  disabled: boolean;
  unknown: boolean;

  constructor(
    public readonly id: Id | null,
    private readonly textObs: Observable<string>,
    private readonly subtitleObs?: Observable<string>,
    disabled?: boolean, unknown?: boolean) {
    textObs.subscribe((textResult) => {
      this.textResult = textResult;
    });
    if (subtitleObs) {
      subtitleObs.subscribe((result) => this.subtitleResult = result);
    }
    this.disabled = disabled === undefined ? false : disabled;
    this.unknown = unknown === undefined ? false : unknown;
  }

  /**
   * Support for ng-select.
   */
  get text(): string {
    return this.textResult;
  }

  /**
   * Support for angular2-multiselect.
   * Not the best solution because this class allows null as ID.
   * @returns {string}
   */
  get itemName(): string {
    return this.textResult;
  }

  get itemSubtitle(): string {
    return this.subtitleResult;
  }

  get reqId(): Id {
    if (this.id === null) {
      throw Error('Absent ID');
    }
    return this.id;
  }

}

export class OptionItems {

  public static createNameComparator<Id>(placeholderItem?: IOptionItem<Id>):
    (a: IOptionItem<Id>, b: IOptionItem<Id>) => number {
    return (a, b) => {
      if (placeholderItem) {
        if (a === placeholderItem) {
          return -1;
        }
        if (b === placeholderItem) {
          return 1;
        }
      }
      if (a.text.toUpperCase() > b.text.toUpperCase()) {
        return 1;
      }
      if (a.text.toUpperCase() < b.text.toUpperCase()) {
        return -1;
      }
      return 0;
    };
  }

  public static assigneeNameComparator():
    (a: AssigneeItem, b: AssigneeItem) => number {
    return (a, b) => {
      if (a.itemName && b.itemName) {
        if (a.itemName.toUpperCase() > b.itemName.toUpperCase()) {
          return 1;
        }
        if (a.itemName.toUpperCase() < b.itemName.toUpperCase()) {
          return -1;
        }
      }
      return 0;
    };
  }

}

export class OwnerUserItem extends OptionItem<number> {
}

export class DeviceDisabledItem extends OptionItem<DeviceDisabled> {

  public static active(): DeviceDisabledItem {
    const i = new DeviceDisabledItem();
    i.id = 'ACTIVE';
    return i;
  }
}

export class DeviceDisabledItems {
  public static readonly values: DeviceDisabled[] = ['ALL', 'ACTIVE', 'INACTIVE', 'WAITING_FOR_ACTIVATION'];

}

export type DeviceDisabled =
  'ALL' |
  'ACTIVE' |
  'INACTIVE' |
  'WAITING_FOR_ACTIVATION';

export type DeviceActivationState =
  'WAITING_FOR_ACTIVATION' |
  'ACTIVE' |
  'INACTIVE';

export class DeviceActivationStateObject {
  state: DeviceActivationState;
  stringKey: string;
  iconClass: string;
  badgeStyle: string;
}

export const deviceActivationStates: DeviceActivationStateObject[] = [
  {
    state: 'ACTIVE',
    stringKey: 'DEVICE_SEARCH_FIELD_DISABLED_ACTIVE',
    iconClass: 'icomoon-device-active',
    badgeStyle: BadgeStyle.SUCCESS
  },
  {
    state: 'INACTIVE',
    stringKey: 'DEVICE_SEARCH_FIELD_DISABLED_INACTIVE',
    iconClass: 'icomoon-device-disabled',
    badgeStyle: BadgeStyle.DANGER
  },
  {
    state: 'WAITING_FOR_ACTIVATION',
    stringKey: 'DEVICE_SEARCH_FIELD_DISABLED_WAITING_FOR_ACTIVATION',
    iconClass: 'icomoon-device-waiting-for-activation',
    badgeStyle: BadgeStyle.PRIMARY
  },
];

export class UserGroupItem extends OptionItem<number> {
}

export class UserItem extends OptionItem<number> {
}

export class ImmutableUserItem extends ImmutableOptionItem<number> {

  public static absent(text: Observable<string>): ImmutableUserItem {
    return new ImmutableUserItem(null, text);
  }

  public static unknown(id: number, placeholderText: Observable<string>): ImmutableUserItem {
    const disabled = false;
    const unknown = true;
    return new ImmutableUserItem(id, placeholderText, undefined, disabled, unknown);
  }

  public static fromUserData(userData: UserData): ImmutableUserItem {
    const localizedNameObs = of(Models.optToString(userData.person_name));
    const subtitleObs = of(Models.optToString(userData.user_name));
    const unknown = false;
    return new ImmutableUserItem(userData.id, localizedNameObs, subtitleObs, userData.disabled, unknown);
  }

  public static fromUserDataArray(userDataArray: IdentityArray<UserData>): List<ImmutableUserItem> {
    return List.of(...userDataArray.map((item) => {
      return ImmutableUserItem.fromUserData(item);
    }));
  }

}

export class ImmutableUserGroupItem extends ImmutableOptionItem<number> {

  public static absent(text: Observable<string>): ImmutableUserGroupItem {
    return new ImmutableUserGroupItem(null, text);
  }

  public static fromUserGroup(userGroup: UserGroup): ImmutableUserGroupItem {
    const localizedNameObs = of(Models.optToString(userGroup.name));
    return new ImmutableUserGroupItem(userGroup.id, localizedNameObs);
  }

  public static fromUserGroupWithDisabled(userGroup: UserGroup): ImmutableUserGroupItem {
    const localizedNameObs = of(Models.optToString(userGroup.name));
    return new ImmutableUserGroupItem(userGroup.id, localizedNameObs, undefined, userGroup.disabled);
  }

  public static fromUserGroupArray(userGroupArray: Array<UserGroup>): List<ImmutableUserGroupItem> {
    return List.of(...userGroupArray.map((item) => {
      return ImmutableUserGroupItem.fromUserGroup(item);
    }));
  }

}

export class ImmutableCustomerRecord extends ImmutableOptionItem<number> {

  public static absent(text: Observable<string>): ImmutableCustomerRecord {
    return new ImmutableCustomerRecord(null, text);
  }

  public static fromCustomerRecord(customerRecord: CustomerRecord.CustomerRecord): ImmutableCustomerRecord {
    const localizedNameObs = of(Models.optToString(customerRecord.name));
    const subtitleObs = of(Models.optToString(customerRecord.externalId));
    return new ImmutableCustomerRecord(customerRecord.customerRecordId, localizedNameObs, subtitleObs, customerRecord.disabled);
  }

  public static fromCustomerRecordArray(customerRecordArray: List<CustomerRecord.CustomerRecord>): List<ImmutableCustomerRecord> {
    return List.of(...customerRecordArray.toArray().map((item) => {
      return ImmutableCustomerRecord.fromCustomerRecord(item);
    }));
  }

}

export class DeviceItem extends OptionItem<number> {
}

export class ProjectItem extends MultiselectOptionItem<number> {
  projectId: number;
}

export class RoleItem extends OptionItem<number> {
}

export class CompanyItem extends OptionItem<number> {
  allowedRoleIds: Set<number>;
}

export class ApplicationTypeItem extends OptionItem<string> {
}

export class UserGroupSearchModel {
  id: string = '';
  name: string = '';
  applicationTypeIds: string = '';
  applicationTypes: ApplicationTypeItem[] = [];
  roles: MultiselectOptionItem<number>[] = [];
  companies: MultiselectOptionItem<number>[] = [];
  disabled?: DisabledItem = undefined;
  adminGroup?: boolean = undefined;

  public isEmpty(): boolean {
    return this.id.length === 0
      && this.name.length === 0
      && OptionItem.idOrUndefined(this.disabled) === undefined
      && this.adminGroup === undefined
      && this.applicationTypeIds.length === 0
      && this.companies.length === 0
      && this.roles.length === 0
      ;
  }
}

export class UserGroupDisabledItem extends OptionItem<UserDisabled> {
}

export class UserGroupDisabledItems {
  public static readonly values: UserGroupDisabled[] = ['ALL', 'ACTIVE', 'INACTIVE'];
}

export class UserGroupEditModel {

  name: string = '';
  externalId: string = '';
  ldapMapped: boolean = false;
  application_types: ApplicationTypeItem[] = [];
  roles: RoleItem[] = [];
  companies: CompanyItem[] = [];
  users: UserItem[] = [];

  public createApplicationTypeKeys(): string[] {
    const ids: string[] = [];
    Arrays.iterateByIndex(this.application_types, (application_type, index) => {
      const id = application_type.id;
      if (id) {
        ids[index] = id;
      }
    });
    return ids;
  }

  public createRoleKeys(): string[] {
    const ids: string[] = [];
    Arrays.iterateByIndex(this.roles, (role, index) => {
      const id = role.id;
      if (id) {
        ids[index] = id + '';
      }
    });
    return ids;
  }

  public createUserIds(): number[] {
    const ids: number[] = [];
    const users = this.users;
    Arrays.iterateByIndex(users, (user, index) => {
      const id = user.id;
      if (id) {
        ids[index] = id;
      }
    });
    return ids;
  }

}

export class UserGroupCreateModel extends UserGroupEditModel {
}

@Injectable()
export class OwnerUserItemFactory {

  constructor() {
  }

  public createAll(users: UserData[] | User[]): OwnerUserItem[] {
    const items: OwnerUserItem[] = [];
    Arrays.iterateByIndex(users, (user: UserData) => {
      items.push(this.create(user));
    });
    return items;
  }

  public create(user: UserData): OwnerUserItem {
    const item = new OwnerUserItem();
    item.id = user.id;
    item.disabled = user.disabled;
    if (user.user_name) {
      if (user.person_name) {
        item.text = user.person_name + ' (' + user.user_name + ')';
      }
      else {
        item.text = user.user_name;
      }
    }
    else {
      if (user.person_name) {
        item.text = user.person_name;
      }
      else {
        item.text = '?';
      }
    }
    return item;
  }

}

export interface FileUploaderOnCompleteItemListener {
  onCompleteItem(item: FileItem, response: string, status: number, headers: ParsedResponseHeaders): void;
}

export class EventSendingFileUploader extends FileUploader {

  constructor(options: FileUploaderOptions, private readonly onCompleteItemListener: FileUploaderOnCompleteItemListener) {
    super(options);
  }

  onCompleteItem(item: FileItem, response: string, status: number, headers: ParsedResponseHeaders): any {
    this.onCompleteItemListener.onCompleteItem(item, response, status, headers);
    return super.onCompleteItem(item, response, status, headers);
  }

}

export namespace SelectUtils {

  export function compareObjects<Id>(o1?: OptionItem<Id>, o2?: OptionItem<Id>): boolean {
    if (!o1 && !o2) {
      return true;
    }
    else if (o1 && o2) {
      return o1.id === o2.id;
    }
    else {
      return false;
    }
  }

  export function compareStateObjects(o1?: any, o2?: any): boolean {
    if (o1 !== undefined && o2 !== undefined) {
      if (o1 !== null && o2 !== null) {
        return o1.state === o2.state;
      }
      else {
        return o1 === o2;
      }
    }
    else {
      return false;
    }
  }

  export function compareNumbersWithNull(n1?: number, n2?: number): boolean {
    if (!n1 && !n2) {
      return true;
    }
    else if (n1 !== undefined && n2 !== undefined) {
      return n1 === n2;
    }
    else {
      return false;
    }
  }

  export function compareStrings(str1?: string, str2?: string): boolean {
    if (str1 && str2) {
      return str1.trim() === str2.trim();
    }
    else {
      return false;
    }

  }

}

export class ListItemTypeItem extends OptionItem<string> {
}

export class TypeItem extends OptionItem<number> {
}

export interface DataTypeObject<T> {
  id: T;
  stringKey?: string;
  iconClass?: string;
  badgeStyle?: BadgeStyle;
}

export interface RouterStateObject {
  stateName: string;
  params: any;
}

export const groupBy = <T, K extends keyof any>(list: T[], getKey: (item: T) => K) =>
  list.reduce((previous, currentItem) => {
    const group = getKey(currentItem);
    if (!previous.get(group)) {
      previous.set(group, []);
    }
    previous.get(group)!.push(currentItem);
    return previous;
  }, new Map<K, T[]>());
