/* eslint-disable */
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { UIRouter } from '@uirouter/angular';
import { StateName } from '../../app.state-names';
import { ServerError } from '../../lib/util/errors';
import { RightResolver, RightService } from '../../lib/right.service';
import { RightModel } from '../../app.rights';
import { ServiceErrorStorage } from '../../lib/util/storages';
import { HttpClient, HttpHeaders } from '@angular/common/http';

/* eslint-enable */

@Component({
  selector: 'app-server-error-page',
  templateUrl: 'server-error-page.component.html',
  styleUrls: ['server-error-page.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ServerErrorPageComponent implements OnInit, OnDestroy {

  model: ServerError | null;
  rightModel: RightModel = RightModel.empty();

  constructor(private router: UIRouter,
              private rightService: RightService,
              private httpClient: HttpClient) {
    this.model = ServiceErrorStorage.getInstance().getServerError();
  }

  get httpCode(): number | undefined {
    const model = this.model;
    if (model && model.httpCode) {
      return model.httpCode;
    }
    return undefined;
  }

  get errorCode(): string {
    const model = this.model;
    if (model && model.error && model.error.error) {
      return model.error.error;
    }
    return '';
  }

  get errorDetailMessage(): string {
    const model = this.model;
    if (model && model.error && model.error && model.error.detail && model.error.detail.message) {
      return model.error.detail.message;
    }
    return '';
  }

  get errorDetailDescription(): string {
    const model = this.model;
    if (model && model.error && model.error && model.error.detail && model.error.detail.description) {
      return model.error.detail.description;
    }
    return '';
  }

  get errorAuditLogId(): string {
    const model = this.model;
    if (model && model.error && model.error && model.error.audit_log_id) {
      return model.error.audit_log_id + '';
    }
    return '';
  }

  private loadRightModels() {
    this.rightService.getRightResolver().subscribe(
      (resolver: RightResolver) => {
        this.rightModel = RightModel.of(resolver);
      }
    );
  }

  ngOnInit() {
    if (!this.model) {
      this.home();
    }
    this.loadRightModels();
  }

  back() {
    window.history.back();
  }

  home() {
    const destination = ServiceErrorStorage.getInstance().getStateObject();
    if (destination) {
      this.router.stateService.go(StateName.getLoginStateName(destination.stateName));
    }
    else {
      this.router.stateService.go(StateName.LOGIN);
    }
  }

  auditLog() {
    this.router.stateService.go(StateName.AUDIT_LOG_DETAIL, {id: this.errorAuditLogId});
  }

  ngOnDestroy(): void {
    ServiceErrorStorage.getInstance().permissionDeniedHandlingActive = false;
  }

  forceReload() {
    const headers = new HttpHeaders()
      .set('Cache-Control', 'no-cache')
      .set('Pragma', 'no-cache');

    this.httpClient
      .get('', {headers, responseType: 'text'})
      .subscribe(() => location.reload(true));
  }

}

