/* eslint-disable */
import { Observable, Observer } from 'rxjs';
import { Injectable } from '@angular/core';
import { FieldValidationError, PagingRequest, QueryResult, ResourceQueryResult, Services } from '../util/services';
import { List, Map, Set } from 'immutable';
import { OffsetDateTime } from '../util/dates';
import { DqlQuery, Query } from '../query/field';
import { FilterField } from '../query/filterfields';
import { OrderField } from '../query/orderfields';
import { Address, AddressResource } from '../address';
import { DownloadedFile } from '../util/downloaded-files';
import {
  ParcelCollectionPointResource,
  ParcelCollectionPointResourceService
} from './parcel-collection-point-resource.service';
import { EmptyMessage, IdentityMessage } from '../util/messages';
import { ObservableErrorResourceParser } from '../util/errors';
/* eslint-enable */

export namespace ParcelCollectionPoint {

  export interface Service {
    query(request: QueryRequest): Observable<QueryResult<ParcelCollectionPoint>>;
    get(request: GetRequest): Observable<ParcelCollectionPoint>;
    create(request: CreateRequest): Observable<IdentityMessage>;
    update(request: UpdateRequest): Observable<EmptyMessage>;
    setDisabled(request: DisableRequest): Observable<EmptyMessage>;
  }

  export interface ParcelCollectionPoint {
    id: number;
    creationTime?: OffsetDateTime;
    updateTime?: OffsetDateTime;
    name: string;
    code?: string;
    deliveryMethod?: DeliveryMethod;
    openingTimes?: string;
    note?: string;
    disabled: boolean;
    postalAddress?: Address.PostalAddressData;
    coordinates?: Address.Coordinate;
  }

  export interface QueryRequest {
    fields?: Query.FieldFunction<Fields.ParcelCollectionPoint>;
    filter?: Query.FilterFunction<FilterField.ParcelCollectionPoint>;
    order?: Query.OrderFunction<OrderField.ParcelCollectionPoint>;
    paging?: PagingRequest;
    noProgressBar?: boolean;
  }

  export interface CreateRequest {
    name: string;
    code: string;
    deliveryMethodId: number;
    openingTimes?: string;
    note?: string;
    postalAddress: Address.PostalAddressData;
    coordinates?: Address.Coordinate;
  }

  export interface UpdateRequest extends CreateRequest {
    id: number;
  }

  export interface DisableRequest {
    id: number;
    disabled: boolean;
  }

  export interface GetRequest {
    id: number;
  }

  export interface DeliveryMethod {
    id: number;
    name: string;
  }

  export function toResourceCreateRequest(request: ParcelCollectionPoint.CreateRequest): ParcelCollectionPointResource.CreateRequest {
    return {
      name: request.name,
      code: request.code,
      delivery_method_id: request.deliveryMethodId,
      opening_times: request.openingTimes,
      note: request.note,
      postal_address: AddressResource.Mapper.fromPublicPostalAddress(request.postalAddress),
      coordinates: AddressResource.Mapper.fromPublicCoordinate(request.coordinates),
    }
  }

  export function toResourceUpdateRequest(request: ParcelCollectionPoint.UpdateRequest): ParcelCollectionPointResource.UpdateRequest {
    return {
      id: request.id,
      name: request.name,
      code: request.code,
      delivery_method_id: request.deliveryMethodId,
      opening_times: request.openingTimes,
      note: request.note,
      postal_address: AddressResource.Mapper.fromPublicPostalAddress(request.postalAddress),
      coordinates: AddressResource.Mapper.fromPublicCoordinate(request.coordinates),
    }
  }

  export function toResourceDisableRequest(request: ParcelCollectionPoint.DisableRequest): ParcelCollectionPointResource.DisableRequest {
    return {
      id: request.id,
      disabled: request.disabled
    }
  }

  export function toPublic(r: ParcelCollectionPointResource.ParcelCollectionPoint): ParcelCollectionPoint {
    return {
      id: r.id,
      creationTime: Services.toOffsetDateTime(r.creation_time),
      updateTime: Services.toOffsetDateTime(r.update_time),
      name: r.name,
      code: r.code,
      deliveryMethod: toPublicDeliveryMethod(r.delivery_method),
      openingTimes: r.opening_times,
      note: r.note,
      disabled: r.disabled,
      postalAddress: r.postal_address ? AddressResource.Mapper.toPublicPostalAddress(r.postal_address) : undefined,
      coordinates: AddressResource.Mapper.toPublicCoordinate(r.coordinates)
    }
  }

  export function toPublicDeliveryMethod(r?: ParcelCollectionPointResource.DeliveryMethod): DeliveryMethod | undefined {
    if (r) {
      return  {
        id: r.id,
        name: r.name,
      }
    }
    return r;
  }

  export enum ValidatedField {
    UNKNOWN,
    CODE,
    DELIVERY_METHOD_ID,
  }

  export namespace Fields {

    export class ParcelCollectionPoint {

      readonly id: Query.Field = new DqlQuery.Field('id');
      readonly creationTime: Query.Field = new DqlQuery.Field('creation_time');
      readonly updateTime: Query.Field = new DqlQuery.Field('update_time');
      readonly name: Query.Field = new DqlQuery.Field('name');
      readonly code: Query.Field = new DqlQuery.Field('code');
      readonly deliveryMethod: Query.Field = new DqlQuery.Field('delivery_method');
      readonly openingTimes: Query.Field = new DqlQuery.Field('opening_times');
      readonly note: Query.Field = new DqlQuery.Field('note');
      readonly disabled: Query.Field = new DqlQuery.Field('disabled');
      readonly coordinates: Query.Field = new DqlQuery.Field('coordinates');
      readonly postalAddress: Query.Field = new DqlQuery.Field('postal_address');

      get each(): Set<Query.Field> {
        return Set.of(
          this.id,
          this.creationTime,
          this.updateTime,
          this.name,
          this.code,
          this.deliveryMethod,
          this.openingTimes,
          this.note,
          this.disabled,
          this.coordinates,
          this.postalAddress
        );
      }

    }

  }

}

class Keys {

  private static readonly CODE = 'code';
  private static readonly DELIVERY_METHOD_ID = 'delivery_method_id';

  private static readonly keyValidatedFieldMap: Map<string, ParcelCollectionPoint.ValidatedField> = Map.of(
    Keys.CODE, ParcelCollectionPoint.ValidatedField.CODE,
    Keys.DELIVERY_METHOD_ID, ParcelCollectionPoint.ValidatedField.DELIVERY_METHOD_ID,
  );

  public static toValidatedField(fieldKey: string): ParcelCollectionPoint.ValidatedField {
    return Keys.keyValidatedFieldMap.get(fieldKey, ParcelCollectionPoint.ValidatedField.UNKNOWN);
  }

}

@Injectable()
export class ParcelCollectionPointService implements ParcelCollectionPoint.Service {

  private readonly filterField = new FilterField.ParcelCollectionPoint();
  private readonly orderField = new OrderField.ParcelCollectionPoint();
  private readonly fields = new ParcelCollectionPoint.Fields.ParcelCollectionPoint();

  constructor(private resourceService: ParcelCollectionPointResourceService) {
  }

  query(request: ParcelCollectionPoint.QueryRequest): Observable<QueryResult<ParcelCollectionPoint.ParcelCollectionPoint>> {
    return Observable.create((observer: Observer<QueryResult<ParcelCollectionPoint.ParcelCollectionPoint>>) => {
      const resourceRequest = this.toResourceQueryRequest(request);
      return this.resourceService.query(resourceRequest).subscribe(
        (result: ResourceQueryResult<ParcelCollectionPointResource.ParcelCollectionPoint>) => {
          observer.next({
            items: List.of(...result.items.map((item) => ParcelCollectionPoint.toPublic(item))),
            pagingResult: result.pagingResult
          });
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  get(request: ParcelCollectionPoint.GetRequest): Observable<ParcelCollectionPoint.ParcelCollectionPoint> {
    return Observable.create((observer: Observer<ParcelCollectionPoint.ParcelCollectionPoint>) => {
      const resourceRequest: ParcelCollectionPointResource.GetRequest = {
        id: request.id
      };
      return this.resourceService.get(resourceRequest).subscribe(
        (result: ParcelCollectionPointResource.ParcelCollectionPoint) => {
          observer.next(ParcelCollectionPoint.toPublic(result));
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  create(request: ParcelCollectionPoint.CreateRequest): Observable<IdentityMessage> {
    return Observable.create((observer: Observer<IdentityMessage>) => {
      const resourceRequest: ParcelCollectionPointResource.CreateRequest = ParcelCollectionPoint.toResourceCreateRequest(request);
      return this.resourceService.create(resourceRequest).subscribe(
        (result: IdentityMessage) => {
          observer.next(result);
        },
        (error: any) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  update(request: ParcelCollectionPoint.UpdateRequest): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      const resourceRequest: ParcelCollectionPointResource.UpdateRequest = ParcelCollectionPoint.toResourceUpdateRequest(request);
      return this.resourceService.update(resourceRequest).subscribe(
        (result: EmptyMessage) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  setDisabled(request: ParcelCollectionPoint.DisableRequest): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      const resourceRequest: ParcelCollectionPointResource.DisableRequest = ParcelCollectionPoint.toResourceDisableRequest(request);
      return this.resourceService.setDisabled(resourceRequest).subscribe(
        (result: EmptyMessage) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  exportXlsTemplate(request: ParcelCollectionPoint.QueryRequest): Observable<DownloadedFile> {
    const resourceRequest: ParcelCollectionPointResource.QueryRequest = this.toResourceQueryRequest(request);
    return this.resourceService.exportXlsTemplate(resourceRequest);
  }

  toResourceQueryRequest(request: ParcelCollectionPoint.QueryRequest): ParcelCollectionPointResource.QueryRequest {
    const filter: string | undefined = DqlQuery.toOptionalFilter(this.filterField, request.filter);
    const order: string | undefined = DqlQuery.toOptionalOrder(this.orderField, request.order);
    const fields: string | undefined = DqlQuery.toOptionalFields(this.fields, request.fields);
    return {
      filter: filter,
      order: order,
      fields: fields,
      page_number: request.paging ? request.paging.pageNumber : undefined,
      number_of_items: request.paging ? request.paging.numberOfItems : undefined,
      no_progress_bar: request.noProgressBar
    };
  }

  private translateError(error: any): any {
    const res = ObservableErrorResourceParser.parseError(error);
    const fieldErrors = ObservableErrorResourceParser.extractFieldErrors(res);
    const fieldErrorMap = ObservableErrorResourceParser.toFieldErrorMap(Keys.toValidatedField, fieldErrors);
    if (!fieldErrorMap.isEmpty()) {
      return FieldValidationError.of(fieldErrorMap);
    }
    return error;
  }

}
