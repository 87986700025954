/* eslint-disable */
import {Component, ComponentFactoryResolver, EventEmitter, Input, OnInit, Output, Type, ViewChild} from '@angular/core';
import {Observable} from 'rxjs';
import {DqlModel} from '../../../lib/dql/dql.model';
import {
  DqlExpressionBuilder,
  DqlFieldModel,
  DqlLogicalOperation,
  DqlLogicalOperationObject,
  DqlOperandCount,
  PureDqlQueryBuilder
} from './dql-search-container.model';
import {DqlSearchFieldComponent} from '../field/dql-search-field.component';
import {DqlSearchContainerHolderDirective} from './dql-search-container-holder.directive';
import {DqlLogicalOperationComponent} from '../dql-logical-operation/dql-logical-operation.component';
import {DqlSearchFieldTypes} from '../field/dql-search-field.model';
import {TranslateService} from '@ngx-translate/core';
import {TranslateUtils} from '../../../util/translate';
import {DqlDialogContainerComponent} from '../dql-dialog-container/dql-dialog-container.component';
import {DqlSearchFieldUnaryComponent} from '../field/dql-search-field-unary/dql-search-field-unary.component';
import {Angular2Multiselects} from '../../../util/multiselect';
import {DqlStoredQuery} from '../../../lib/dql/dql-stored-query.service';
import {DqlStoredQueryArgs} from './dql-stored-query.args';
import {UiConstants} from '../../../util/core-utils';
import {RightModel} from '../../../app.rights';
import {Strings} from '../../../lib/util/strings';
import {OrderType} from '../../../lib/util/services';
import {Set} from 'immutable';
import {NormalDqlQueryParser} from '../impl/normal/normal-dql-query-parser';
import {DqlQueryParser} from '../impl/dql-query-parser';
import {TypedDqlQueryParser} from '../impl/typed/typed-dql-query-parser';
import {FieldPaymentType} from '../../../util/form/form-field-payment-type';
import {MasterDataRecordDqlFieldTextProvider} from '../../../lib/masterdata/master-data-record.dql.service';
import {CustomerRecordDqlFieldTextProvider} from '../../../lib/customer/customer-record.dql.service';
import DqlStaticFieldTextHelper = DqlModel.DqlStaticFieldTextHelper;
import QueryableFieldDataType = DqlModel.QueryableFieldDataType;

/* eslint-enable */

@Component({
  selector: 'app-dql-search-container',
  templateUrl: './dql-search-container.component.html',
  styleUrls: ['./dql-search-container.component.scss']
})
export class DqlSearchContainerComponent implements OnInit {
  fields: DqlFieldModel[] = [];

  pureQueryBuilder: PureDqlQueryBuilder = new PureDqlQueryBuilder();

  @ViewChild(DqlSearchContainerHolderDirective, {static: true}) fieldHolder: DqlSearchContainerHolderDirective;

  @Output()
  onSearch: EventEmitter<string> = new EventEmitter<string>();

  @Input()
  dialogContainer: DqlDialogContainerComponent;

  @Input()
  isTyped: boolean = false;

  @Input()
  dqlStoredQueryArgs?: DqlStoredQueryArgs;

  @Input()
  rightModel: RightModel = RightModel.empty();

  dropdownSettings?: Angular2Multiselects.Settings;

  _selectedStoredQuery: DqlStoredQuery.DqlStoredQuery[] = [];

  storedQueries: DqlStoredQuery.DqlStoredQuery[] = [];

  parser: DqlQueryParser;
  cannotLoad: boolean = false;

  get selectedStoredQuery(): DqlStoredQuery.DqlStoredQuery | undefined {
    return this._selectedStoredQuery.length > 0 ? this._selectedStoredQuery[0] : undefined;
  }


  constructor(private componentFactoryResolver: ComponentFactoryResolver,
              private translateService: TranslateService) {
  }

  ngOnInit() {
    this.initDropdownSettings();
    this.dialogContainer.onCreateExpression.subscribe((searchField: DqlExpressionBuilder) => {
      this.andExpression(searchField);
    });
    this.dialogContainer.onQuerySavedOrDeleted.subscribe((id: number) => {
      if (id === -1) {
        this._selectedStoredQuery = [];
        this.loadStoredQueries();
      } else {
        this.loadStoredQueries(undefined, id);
      }
    });
  }

  initDropdownSettings() {
    this.dropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(true)
      .text('DQL_SEARCH_STORED_QUERY')
      .enableSearchFilter(true)
      .enableCheckAll(false)
      .remoteSearch(true)
      .labelKey('name')
      .build();
  }

  loadContent() {
    if (this.storedQueries.length === 0) {
      this.loadStoredQueries();
    }
  }

  public setFields(fields: Observable<DqlModel.QueryableModel>, staticHelper: DqlStaticFieldTextHelper, completion: () => void) {
    fields.subscribe((result: DqlModel.QueryableModel) => {
      this.clear();
      this.convertQueryableModel(result, staticHelper, undefined, undefined, () => {
        this.dialogContainer.setFields(this.fields);
        if (this.isTyped) {
          this.parser = new TypedDqlQueryParser(this.fields);
        } else {
          this.parser = new NormalDqlQueryParser(this.fields);
        }
        completion();

      });
    });
  }

  private convertQueryableModel(model: DqlModel.QueryableModel,
                                staticHelper: DqlStaticFieldTextHelper,
                                prefixTitle?: string,
                                prefixId?: number,
                                completion?: () => void) {
    this.translateService.get(staticHelper.getKeys()).subscribe((texts) => {
      model.staticFields.forEach((f) => {
        const isSupported: boolean =
          staticHelper.getKeys().findIndex(value => value === staticHelper.getStringKey(f.fieldTitle)) > -1;
        if (isSupported) {
          this.fields.push({
            title: prefixTitle
              ? prefixTitle + '-' + TranslateUtils.extractValueFromObject(texts, staticHelper.getStringKey(f.fieldTitle))
              : TranslateUtils.extractValueFromObject(texts, staticHelper.getStringKey(f.fieldTitle)),
            id: prefixId ? prefixId + '.' + f.fieldTitle : f.fieldTitle,
            dataType: f.dataType,
            values: staticHelper.getEnumType(f.fieldTitle) ? staticHelper.getEnumType(f.fieldTitle)!
              .map((enumType): { id: string, stringKey: string } => {
                return {id: enumType.id, stringKey: enumType.stringKey};
              }) : undefined
          });
        }
      });
      model.dynamicFields.forEach((f) => {
        const pt = model.hasMultipleModels && f.parentTitle ? f.parentTitle + (prefixTitle ? '-' + prefixTitle : '') : prefixTitle;
        const title = pt ? pt + '-' + f.fieldTitle : f.fieldTitle;
        if (!f.queryableModel) {
          this.fields.push({
            title: title,
            id: prefixId ? prefixId + '.' + f.fieldId : '' + f.fieldId,
            dataType: f.dataType,
            listItemTypeId: f.listItemTypeId,
            values: f.dataType !== QueryableFieldDataType.PAYMENT_TYPE ? undefined : FieldPaymentType.VALUES.toArray()
          });
        } else {
          this.convertQueryableModel(f.queryableModel,
            DqlSearchContainerComponent.getStaticHelper(f.dataType, staticHelper), title, f.fieldId);
        }
      });
      if (completion) {
        completion();
      }
    });
  }

  private static getStaticHelper(dataType: DqlModel.QueryableFieldDataType, defaultHelper: DqlModel.DqlStaticFieldTextHelper):
    DqlStaticFieldTextHelper {
    if (dataType === QueryableFieldDataType.MASTER_DATA) {
      return MasterDataRecordDqlFieldTextProvider.getHelper();
    }
    if (dataType === QueryableFieldDataType.CUSTOMER) {
      return CustomerRecordDqlFieldTextProvider.getHelper();
    }
    return defaultHelper;
  }

  andExpression(expressionBuilder: DqlExpressionBuilder) {
    let op: DqlLogicalOperationObject | undefined = new DqlLogicalOperationObject(DqlLogicalOperation.AND);
    op = this.pureQueryBuilder.expressionCount > 0 ? op : undefined;
    if (op) {
      this.pureQueryBuilder.addLogical(op);
      this.createOperation(op);
    }
    this.pureQueryBuilder.addExpression(expressionBuilder);
    this.createSearchField(expressionBuilder);
  }

  orExpression(expressionBuilder: DqlExpressionBuilder) {
    let op: DqlLogicalOperationObject | undefined = new DqlLogicalOperationObject(DqlLogicalOperation.OR);
    op = this.pureQueryBuilder.expressionCount > 0 ? op : undefined;
    if (op) {
      this.pureQueryBuilder.addLogical(op);
      this.createOperation(op);
    }
    this.pureQueryBuilder.addExpression(expressionBuilder);
    this.createSearchField(expressionBuilder);
  }

  private createSearchField(expressionBuilder: DqlExpressionBuilder): DqlSearchFieldComponent {
    let fieldType: Type<DqlSearchFieldComponent>;
    const crit = expressionBuilder.getFirstCriteria()!;
    if (crit.operandType() === DqlOperandCount.BINARY) {
      fieldType = DqlSearchFieldTypes.get(crit.field!.dataType);
    } else {
      fieldType = DqlSearchFieldUnaryComponent;
    }
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(fieldType);
    const componentRef = this.fieldHolder.viewContainerRef.createComponent(componentFactory);
    componentRef.instance.expression = expressionBuilder;
    componentRef.instance.disableDelete = false;
    componentRef.instance.onDelete.subscribe((expressionBuilder: DqlExpressionBuilder) => this.deleteSearchField(expressionBuilder));
    return componentRef.instance;
  }

  private createOperation(operation: DqlLogicalOperationObject): DqlLogicalOperationComponent {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(DqlLogicalOperationComponent);
    const componentRef = this.fieldHolder.viewContainerRef.createComponent(componentFactory);
    componentRef.instance.operation = operation;
    return componentRef.instance;
  }

  private loadSearchFields() {
    this.pureQueryBuilder.expressionList.forEach((c: DqlExpressionBuilder, i: number) => {
      this.createSearchField(c);
      if (this.pureQueryBuilder.logicalList.length > i) {
        this.createOperation(this.pureQueryBuilder.logicalList[i]);
      }
    });
  }

  private deleteSearchField(expressionBuilder: DqlExpressionBuilder) {
    const index = this.pureQueryBuilder.expressionList.indexOf(expressionBuilder);
    const viewContainerRef = this.fieldHolder.viewContainerRef;
    viewContainerRef.remove(index * 2);
    this.pureQueryBuilder.removeExpression(expressionBuilder);
    if (index !== 0) {
      viewContainerRef.remove(index * 2 - 1); // logical before the removed expression
    } else if (!this.pureQueryBuilder.isEmpty()) {
      viewContainerRef.remove(0); // first logical
    }
    if (this.pureQueryBuilder.isEmpty()) {
      this.clearSearch(true);
    }
  }

  private clear() {
    this.fields = [];
    this.clearSearch(false);
  }

  openNewSearchFieldDialog() {
    this.dialogContainer.openSearchDialog();
  }

  public clearSearch(force?: boolean) {
    this.cannotLoad = false;
    this.pureQueryBuilder = new PureDqlQueryBuilder();
    this.fieldHolder.viewContainerRef.clear();
    if (force) {
      this.onSearchClicked(force);
    }
  }

  public getQueryString(): string | undefined {
    const q = this.pureQueryBuilder.getQueryString(this.isTyped);
    return q;
  }

  onSearchClicked(forceClear?: boolean) {
    if (forceClear) {
      this._selectedStoredQuery = [];
    }
    this.onSearch.emit(this.getQueryString());
  }

  public loadQuery(query?: string): void {
    this.cannotLoad = false;
    try {
      if (this.parser && query) {
        this.pureQueryBuilder = this.parser.parse(query);
        this.loadSearchFields();
      }
    } catch (e) {
      this.cannotLoad = true;
    }
  }

  // stored query


  loadStoredQueries(q?: string, id?: number) {
    if (this.dqlStoredQueryArgs && this.rightModel && this.rightModel.storedDqlRead.hasRight()) {
      const request: DqlStoredQuery.DqlStoredQueryListRequest = {
        parentId: this.dqlStoredQueryArgs.parentId,
        documentType: this.dqlStoredQueryArgs.documentType,
        name: Strings.undefinedOrNonEmpty(q),
        paging: {
          numberOfItems: UiConstants.autocompletePageSize,
          pageNumber: 1
        },
        orders: Set.of({type: OrderType.ASC, field: DqlStoredQuery.OrderField.NAME}),
        noProgressBar: true
      };
      this.storedQueries = [];
      if (id) {
        this.dqlStoredQueryArgs.service.fetchDqlQueryList({
          id: Set.of(id),
          paging: {numberOfItems: 1, pageNumber: 1}
        }).subscribe(result => {
          result.items.forEach(query => {
            if (query) {
              if (this.storedQueries.find(s => s.id === query.id) === undefined) {
                this.storedQueries.unshift(query);
              }
              this._selectedStoredQuery = [];
              this._selectedStoredQuery.push(query);
            }
          });
        });
      }
      this.dqlStoredQueryArgs.service.fetchDqlQueryList(request).subscribe(result => {
        result.items.forEach(query => {
          if (query && this.storedQueries.find(s => s.id === query.id) === undefined) {
            this.storedQueries.push(query);
          }
        });
      });
    }
  }

  onStoredQueryChanged() {
    if (this.selectedStoredQuery) {
      this.clearSearch(false);
      this.loadQuery(this.selectedStoredQuery.query);
    }
  }

  onStoredQueryDeselect() {
    this.cannotLoad = false;
  }

  openSaveDialog() {
    const q = this.getQueryString();
    if (q && this.dqlStoredQueryArgs) {
      this.dialogContainer.openSaveDialog(this.dqlStoredQueryArgs, q);
    }
  }

  openUpdateDialog() {
    const q = this.getQueryString();
    if (q && this.dqlStoredQueryArgs) {
      this.dialogContainer.openSaveDialog(this.dqlStoredQueryArgs, q, this.selectedStoredQuery);
    }
  }
}
