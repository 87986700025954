/* eslint-disable */
import {Component,} from '@angular/core';
import {
  FormRecordFieldContext,
  FormRecordFieldValueUpdateArgs,
  FormRecordFieldView,
  FormRecordFieldViewContext,
  FormRecordFieldViewModel,
  SetTmpReadonlyArgs,
  SetTmpReadonlyResult,
} from '../../../../../util/form/form-utils';
import {Form} from '../../../../../lib/form/form.service';
import {AbstractControl, FormBuilder, FormGroup, Validators,} from '@angular/forms';
import {FormRef, ForwardingFormRef, LocalFormGroupValidationErrors,} from '../../../../../lib/util/services';
import {FormRecord} from '../../../../../lib/form/form-record.service';
import {AppValidators} from '../../../../../util/app-validators';
import {Models} from '../../../../../util/model-utils';
import {List, Set} from 'immutable';
import {FieldActivationState, FieldActivationStateResolver,} from '../../../../../util/form/form-editors';
import {Command} from '../../../../../util/command';
import {OptionItem, SelectUtils} from '../../../../../util/core-utils';
import {Angular2Multiselects} from '../../../../../util/multiselect';
import {Observable} from 'rxjs';
import {ofCondition} from '../../../../../util/wait';
import {FormRecordInactivityManager} from '../../manager/form-record-inactivity-manager';
import {Arrays} from '../../../../../lib/util/arrays';
import {CustomerFilterType} from '../../../../../util/form/form-field-customer-filter-type';
import {CustomerRecordFormFieldModel, FormRecordCustomerFieldLoader} from './form-record-customer-field.loader';
import {MatDialog} from '@angular/material/dialog';
import {
  CustomerRecordSelectorDialogComponent
} from './form-record-customer-add-dialog/customer-record-selector-dialog.component';
import {Address} from "../../../../../lib/address";
import {TranslateService} from "@ngx-translate/core";
import {FormRecordFieldUpdateCustomerLocationArgs} from "../master-data/form-record-master-data-field.component";
import {Customer, CustomerService} from "../../../../../lib/customer/customer.service";
import {OperationRights} from "../../../../../app.right-definitions";
import {
  CustomerRecordQuickCreateMaterialDialogComponent
} from "../../../../customer/customer-record-quick-create-material/customer-record-quick-create-material-dialog.component";

/* eslint-enable */

@Component({
  selector: 'app-form-record-customer-field',
  templateUrl: 'form-record-customer-field.component.html',
  styleUrls: ['form-record-customer-field.component.scss'],
})
export class FormRecordCustomerFieldComponent implements FormRecordFieldView {

  SelectUtils = SelectUtils;

  public readonly selector: Form.FieldDataTypeSelector.MASTER_DATA;

  formGroup: FormGroup;

  model: Model = new Model();

  // context fields are always optional
  formRecordFieldContext?: FormRecordFieldContext;
  formRecordInactivityManager?: FormRecordInactivityManager;
  private fieldId?: number;
  htmlForm?: FormRef;

  tmpReadonly: boolean = false;

  optionalValue: boolean = false;

  customerId: number;

  private ownerCustomerId?: number;
  private ownerCustomerRecordId?: number;
  private ownerContactLocationId?: number;

  availableCustomerFilters: OptionItem<CustomerFilterType.CustomerFilterType>[] = [];
  customerFilter: OptionItem<CustomerFilterType.CustomerFilterType>;

  dropdownSettings: Angular2Multiselects.Settings;

  private formGroupValidationErrors: LocalFormGroupValidationErrors;
  private readonlyFormFn: () => boolean = () => true;

  private readonlyFieldFn: () => boolean = () => false;
  private hiddenFieldFn: () => boolean = () => false;
  private customer: Customer.Customer;

  get nonEditable(): boolean {
    return FieldActivationStateResolver.isNonEditable(
      this.fieldActivationState,
    );
  }

  get requiredDisabled(): boolean {
    return FieldActivationStateResolver.isRequiredDisabled(
      this.fieldActivationState,
    );
  }

  get required(): boolean {
    const optional = this.optionalValue;
    const requiredDisabled = this.requiredDisabled;
    return !optional && !requiredDisabled;
  }


  private get fieldActivationState(): FieldActivationState {
    return FieldActivationStateResolver.resolveFieldActivationState({
      readonlyFormFn: () => this.readonlyFormFn(),
      readonlyFieldFn: () => this.readonlyFieldFn(),
      tmpReadonlyFieldFn: () => this.tmpReadonly,
    });
  }

  private get canApplyDefaultValue(): boolean {
    return FieldActivationStateResolver.canApplyDefaultValue({
      formRecordFieldContext: () => {
        return this.formRecordFieldContext;
      },
      fieldActivationState: () => {
        return this.fieldActivationState;
      },
    });
  }

  private get valueFormControl(): AbstractControl {
    return this.formGroup.get('value')!;
  }

  private get reqContext() {
    return this.formRecordFieldContext!;
  }

  private get reqAttrs(): Form.FieldDataTypeCustomerAttributes {
    return this.formRecordFieldContext!.field.dataType.customerAttributes!;
  }

  private get reqDefaultValue(): CustomerRecordFormFieldModel[] {
    return []; // for now it has no default value
  }

  constructor(fb: FormBuilder,
              private formRecordCustomerFieldLoader: FormRecordCustomerFieldLoader,
              private customerService: CustomerService,
              private translateService: TranslateService,
              private dialog: MatDialog) {
    this.formGroup = this.createFormGroup(fb);
    this.formGroupValidationErrors = LocalFormGroupValidationErrors.ofForm(
      this.createForwardingHtmlForm(),
      this.formGroup,
    );
  }

  setTmpReadonly(args: SetTmpReadonlyArgs): Command<SetTmpReadonlyResult> {
    const customerItemToIdText = (item: CustomerRecordFormFieldModel) => {
      return item.customerRecordId === null ? null : item.customerRecordId.toString();
    };
    let previousTmpReadonly;
    let previousValues;
    return {
      execute: async () => {
        await ofCondition({
          condition: () => {
            return this.model.loaded;
          }
        });
        previousTmpReadonly = this.tmpReadonly;
        previousValues = List.of(...this.model.values).toArray();
        const inactiveValue: CustomerRecordFormFieldModel[] = [];
        const defaultValue: CustomerRecordFormFieldModel[] = this.reqDefaultValue;
        let changed = false;
        if (this.tmpReadonly !== args.tmpReadonly) {
          if (args.tmpReadonly) {
            changed = FieldActivationStateResolver.inactivationChangesTheValue({
              debugId: this.reqContext.field.title,
              valueIsEmpty: Arrays.equals(this.model.values, inactiveValue, customerItemToIdText),
              valueEqualsDefaultValue: Arrays.equals(this.model.values, defaultValue, customerItemToIdText),
              defaultValueIsEmpty: Arrays.equals(defaultValue, inactiveValue, customerItemToIdText),
              canApplyDefaultValue: this.canApplyDefaultValue
            });
            this.model.values = inactiveValue;
            this.tmpReadonly = args.tmpReadonly; // last
          } else {
            this.tmpReadonly = args.tmpReadonly; // first
            this.applyDefaultValue();
          }
          this.valueFormControl.updateValueAndValidity();
        }
        return {
          changed: changed
        };
      },
      undo: async () => {
        await ofCondition({
          condition: () => {
            return this.model.loaded;
          }
        });
        this.tmpReadonly = previousTmpReadonly;
        this.model.values = List.of(...previousValues).toArray();
        this.valueFormControl.updateValueAndValidity();
      }
    };
  }

  registerField(context: FormRecordFieldContext, originalModel?: any): any {
    if (originalModel) {
      this.model = originalModel;
    }
    this.formRecordFieldContext = context;
    this.fieldId = context.field.fieldId;
    this.htmlForm = context.htmlForm;
    this.readonlyFormFn = context.readonly;
    this.hiddenFieldFn = () => Form.FormFieldValidationType.HIDDEN === context.validationType;
    this.readonlyFieldFn = () => Form.FormFieldValidationType.READONLY === context.validationType
      || Form.FormFieldValidationType.HIDDEN === context.validationType;
    const attrs = context.field.dataType.customerAttributes!;
    this.optionalValue = Form.FormFieldValidationType.REQUIRED !== context.validationType;
    this.model.title = context.field.title;
    this.model.hint = Models.optToString(context.field.hint);
    this.model.placeholder = Models.optToString(attrs.hint);
    this.model.multiSelect = attrs.multiSelect;
    this.customerId = attrs.customerId;
    this.availableCustomerFilters = CustomerFilterType.customerFilterTypes.filter(f => attrs.filterTypes.contains(f.type)).map(f => {
      return {id: f.type, text: f.stringKey};
    });
    this.customerFilter = this.availableCustomerFilters[0];
    this.loadFieldData();
    this.loadCustomer();
    return this.model;
  }

  private loadFieldData() {
    const context = this.formRecordFieldContext!;
    if (this.canApplyDefaultValue) {
      const defaultValue: number[] = []; // for now it has no default value
      this.setValuesBeforeLoad(Set.of<number>(...defaultValue));
    }
    if (context.fieldRecord) {
      const dataAttrs = context.fieldRecord.data.customerAttributes!;
      this.setValuesBeforeLoad(dataAttrs.values);
      this.loadCustomerRecords(this.customerId, this.model.prevValueIds?.toArray());
    } else {
      this.model.loaded = true;
    }
  }

  private loadCustomer() {
    this.customerService.get({
      customerId: this.customerId,
      withForm: false,
      rights: Set.of(OperationRights.CUSTOMER_RECORD_CREATE)
    }).subscribe(customer => {
      this.customer = customer;
    })
  }

  private setValuesBeforeLoad(ids: Set<number>) {
    this.model.prevValueIds = ids;
  }

  private applyDefaultValue() {
    if (this.canApplyDefaultValue) {
      this.model.values = this.reqDefaultValue;
    }
  }

  registerFieldViews(context: FormRecordFieldViewContext): void {
    this.formRecordInactivityManager = context.inactivityManager;
  }

  onManualChange() {
    this.formGroup.controls.value.updateValueAndValidity();
    this.formRecordInactivityManager!.onGeneralFieldChangedByUser(this);
  }

  hasLocalFieldError(formControlName?: string, errorCode?: string): boolean {
    return this.formGroupValidationErrors.hasFieldError(formControlName, errorCode);
  }

  validateWithInterrupt(): boolean {
    return false;
  }

  shouldNotifyAfterCreation(): boolean {
    return false;
  }

  afterFormRecordCreation(formRecordId: number): Observable<any> | undefined {
    return undefined;
  }

  createModel(): FormRecordFieldViewModel {
    if (this.fieldId === undefined) {
      throw new Error('Field ID is undefined');
    }
    const attrs: FormRecord.FieldDataCustomerAttributes = {
      values: this.model.getValueIdSet(),
    };
    return {
      fieldEditRequest: {
        fieldId: this.fieldId,
        data: {
          customerAttributes: attrs,
        },
      },
    };
  }

  private createForwardingHtmlForm() {
    return new ForwardingFormRef({
      formFn: () => {
        return this.htmlForm;
      },
    });
  }

  private createFormGroup(fb: FormBuilder) {
    return fb.group(
      {
        value: fb.control(
          {value: this.model.values},
          [
            AppValidators.tempValidator({
              validator: Validators.required,
              disabled: () => {
                return !this.required;
              },
            }),
            AppValidators.tempValidator({
              validator: AppValidators.maxArraySize({
                array: () => {
                  return this.model.values;
                },
                maxSize: 1
              }),
              disabled: () => {
                const requiredDisabled = this.requiredDisabled;
                return this.model.multiSelect || requiredDisabled;
              },
            }),
            AppValidators.validateEnabledItems
          ],
        ),
      },
    );
  }

  loadCustomerRecords(customerId: number, ids?: number[]) {
    if (ids && ids.length > 0) {
      this.formRecordCustomerFieldLoader.getCustomerRecordObservable(this.customerId,
        true,
        true,
        ids)
        .subscribe(result => {
          this.model.values = result.items.toArray();
          this.model.loaded = true;
          this.valueFormControl.updateValueAndValidity({
            onlySelf: false,
            emitEvent: true
          });
        });
    } else {
      this.model.loaded = true;
    }
  }

  updateValue(data: FormRecordFieldValueUpdateArgs) {
    if ((<FormRecordFieldUpdateCustomerLocationArgs>data).customerRecordId !== null) {
      this.ownerCustomerRecordId = (<FormRecordFieldUpdateCustomerLocationArgs>data).customerRecordId!;
      this.ownerCustomerId = (<FormRecordFieldUpdateCustomerLocationArgs>data).customerRecordId!;
    }
    this.ownerContactLocationId = (<FormRecordFieldUpdateCustomerLocationArgs>data).contactLocationId;
  }

  getTitle(): string {
    if (this.formRecordFieldContext && this.formRecordFieldContext.field) {
      return this.formRecordFieldContext.field.title;
    }
    return '';
  }

  canAddCustomerCriteria() {
    if (!this.customer) {
      return false;
    } else {
      if (this.customer.contactPerson) {
        return this.ownerCustomerRecordId !== undefined;
      }
      return true;
    }
  }

  canAdd(): boolean {
    if (this.nonEditable) {
      return false;
    }
    if (!this.canAddCustomerCriteria()) {
      return false;
    }
    if (this.model.multiSelect) {
      return true;
    }
    return this.model.values.length < 1;
  }

  canCreate(): boolean {
    return this.canAdd() && (this.customer !== undefined && this.customer.grantedRights.has(OperationRights.CUSTOMER_RECORD_CREATE));
  }

  canModify(): boolean {
    return !this.nonEditable;
  }

  openAddDialog() {
    CustomerRecordSelectorDialogComponent.openSelector(this.dialog, {
        multiSelect: this.model.multiSelect,
        customerId: this.customerId,
        ownerCustomerRecordIds: this.ownerCustomerRecordId ? Set.of(this.ownerCustomerRecordId) : undefined,
        ownerContactLocationIds: this.ownerContactLocationId ? Set.of(this.ownerContactLocationId) : undefined,
        alreadySelected: this.model.values
      },
      result => {
        if (result) {
          this.model.values = [];
          if (result.selectedCustomerRecords && result.selectedCustomerRecords.length > 0) {
            this.loadCustomerRecords(this.customerId, result.selectedCustomerRecords.map(m => m.customerRecordId));
          }
        }
      });
  }

  openCreateDialog() {
    if (this.ownerCustomerRecordId && this.ownerCustomerId) {
      const forContactPerson = {customerId: this.ownerCustomerId!, customerRecordId: this.ownerCustomerRecordId,
      contactLocationId: this.ownerContactLocationId};
      CustomerRecordQuickCreateMaterialDialogComponent.openDialog(this.dialog,
        {
          forContactPersonData: forContactPerson,
          customerId: this.customerId,
          rights: Set.of(
            OperationRights.CUSTOMER_RECORD_READ,
            OperationRights.CUSTOMER_RECORD_UPDATE,
            OperationRights.CUSTOMER_RECORD_DISABLE
          )
        },
        result => {
          if (result?.success) {
            if (result.customerRecord) {
              this.model.values.push(this.formRecordCustomerFieldLoader.mapCustomerRecordModel(result.customerRecord));
            }
          }
        }
      )
    }

  }

  removeCustomer(idx: number) {
    this.model.values.splice(idx, 1);
  }

  getNarrowestFilter(): CustomerFilterType.CustomerFilterType {
    if (this.availableCustomerFilters.length === 0) {
      return 'GLOBAL';
    }
    return this.availableCustomerFilters[0].id!;
  }

  customerMatches(item: CustomerRecordFormFieldModel): boolean {
    if (this.getNarrowestFilter() === 'GLOBAL') {
      return true;
    }
    return true;
    // return this.customerRecordId === item.ownerCustomerRecordId;
  }

  locationMatches(item: CustomerRecordFormFieldModel): boolean {
    if (this.getNarrowestFilter() !== 'LOCATION') {
      return true;
    }
    return true;
    // return this.contactLocationId === item.ownerContactLocationId;
  }

  getEmailAddressTypeName(email: Address.EmailAddressData) {
    const languageCode = this.translateService.currentLang.substr(0, 2);
    return email.typeNames![languageCode];
  }

}

export class Model {

  title: string = '';
  hint: string = '';
  placeholder: string = '';
  prevValueIds?: Set<number>;
  values: CustomerRecordFormFieldModel[] = [];
  multiSelect: boolean = false;

  loaded: boolean = false;

  getValueIdSet(): Set<number> {
    const ret: number[] = [];
    if (this.loaded && this.values) {
      this.values.forEach((value) => {
        if (value.customerRecordId !== null) {
          ret.push(value.customerRecordId!);
        }
      });
    }
    if (!this.loaded && this.prevValueIds) {
      return this.prevValueIds;
    }
    return ret.length > 0 ? Set.of<number>(...ret) : Set.of<number>();
  }
}

