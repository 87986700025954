/* eslint-disable */
import { DqlModel } from '../dql/dql.model';
import { Map } from 'immutable';
import { TaskRecordStateMachine } from './task-record-statemachine';
import { TaskRecord } from './task-record.service';
import StateObject = TaskRecordStateMachine.StateObject;

/* eslint-enable */

export class TaskRecordDqlFieldTextProvider {
  public static getHelper(): DqlModel.DqlStaticFieldTextHelper {
    const fields: Map<string, DqlModel.DqlFieldTexts> = Map.of(
      'name', {stringKey: 'COMMON_NAME'},
      'id', {stringKey: 'COMMON_TABLE_HEADER_ID'},
      'parent_id', {stringKey: 'DQL_PARENT_ID'},
      'creation_time', {stringKey: 'COMMON_TABLE_HEADER_CREATION_TIME'},
      'update_time', {stringKey: 'COMMON_TABLE_HEADER_UPDATE_TIME'},
      'agreed_time', {stringKey: 'TASK_RECORD_AGREED_TIME'},
      'agreed_time', {stringKey: 'TASK_RECORD_AGREED_TIME'},
      'deadline', {stringKey: 'TASK_RECORD_TABLE_HEADER_DEADLINE'},
      'description', {stringKey: 'COMMON_DESCRIPTION'},
      'estimated_time_in_minutes', {stringKey: 'TASK_RECORD_ESTIMATED_TIME_IN_MINUTES'},
      'time_spent_in_minutes', {stringKey: 'TASK_RECORD_TIME_SPENT_IN_MINUTES'},
      'state', {
        stringKey: 'TASK_RECORD_SEARCH_STATE',
        enumType: TaskRecordStateMachine.getOrderedStates(true).map((value: StateObject) => {
          return {id: <string>value.state, stringKey: value.stringKey};
        })
      },
      'external_id', {stringKey: 'COMMON_EXTERNAL_ID'},
      'finalization_time', {stringKey: 'TASK_RECORD_FINALIZATION_TIME'},
      'release_time', {stringKey: 'TASK_RECORD_RELEASE_TIME'},
      'submit_time', {stringKey: 'TASK_RECORD_SUBMIT_TIME'},
      'importance', {
        stringKey: 'TASK_IMPORTANCE_TITLE', enumType: TaskRecord.taskRecordImportances.map(value => {
          return {id: <string>value.importance, stringKey: value.stringKey}
        })
      },
      'state_change_message', {stringKey: 'COMMON_COMMENT'},
      'user_profile_id', {stringKey: 'TASK_RECORD_USER'},
      'user_group_id', {stringKey: 'TASK_RECORD_USER_GROUP'},
      'mobile_app_id', {stringKey: 'TASK_RECORD_MOBILE_APP'},
      'project_name', {stringKey: 'TASK_RECORD_SEARCH_PROJECT'},
      'process_id', {stringKey: 'TASK_RECORD_SEARCH_PROCESS'},
      'contract_number_id', {stringKey: 'CUSTOMER_RECORD_CONTRACT_NUMBER'},
      'customer_id', {stringKey: 'TASK_RECORD_SEARCH_CUSTOMER_NAME'},
      'contact_location_id', {stringKey: 'TASK_RECORD_SEARCH_CONTACT_LOCATION'},
      'poc_city', {stringKey: 'TASK_RECORD_SEARCH_POC_ADDRESS_CITY'},
      'poc_zip_code', {stringKey: 'TASK_RECORD_SEARCH_POC_ADDRESS_ZIP_CODE'},
      'poc_address', {stringKey: 'TASK_RECORD_SEARCH_POC_ADDRESS_STREET'},
      'poc_house_number', {stringKey: 'TASK_RECORD_SEARCH_POC_ADDRESS_HOUSE_NUMBER'},
      'invoice_number', {stringKey: 'TASK_RECORD_SEARCH_INVOICE_NUMBER'},
      'sms_rating', {stringKey: 'TASK_RECORD_SEARCH_SMS_RATING'},
    );
    return new DqlModel.DqlStaticFieldTextHelper(fields);
  }
}
