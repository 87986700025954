/* eslint-disable */
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { BaseHttpService, ResourceHelper } from '../util/http-services';
import { ResourceQueryResult } from '../util/services';
import { AddressResource } from '../address';
import { DownloadedFile } from '../util/downloaded-files';
import { UrlBuilder } from '../../util/url-builder';
import { map } from 'rxjs/operators';
import { EmptyMessage, IdentityMessage } from '../util/messages';
/* eslint-enable */

@Injectable()
export class ParcelCollectionPointResourceService extends BaseHttpService {

  query(request: ParcelCollectionPointResource.QueryRequest):
    Observable<ResourceQueryResult<ParcelCollectionPointResource.ParcelCollectionPoint>> {
    return this.http.get<ResourceQueryResult<ParcelCollectionPointResource.ParcelCollectionPoint>>(this.url, this.parseParams(request));
  }

  get(request: ParcelCollectionPointResource.GetRequest): Observable<ParcelCollectionPointResource.GetRequest> {
    return this.http.get<ParcelCollectionPointResource.GetRequest>(this.url + `${request.id}`);
  }

  create(request: ParcelCollectionPointResource.CreateRequest): Observable<IdentityMessage> {
    return this.http.post<IdentityMessage>(this.url, request);
  }

  update(request: ParcelCollectionPointResource.UpdateRequest): Observable<EmptyMessage> {
    return this.http.put<EmptyMessage>(this.url + `${request.id}`, request);
  }

  setDisabled(request: ParcelCollectionPointResource.DisableRequest): Observable<EmptyMessage> {
    return this.http.patch<EmptyMessage>(this.url + `${request.id}/disabled`, request);
  }

  exportXlsTemplate(request: ParcelCollectionPointResource.QueryRequest): Observable<DownloadedFile> {
    const url = UrlBuilder.create('parcel-collection-points/export-xls-template')
      .baseUrl(this.resourceHelper.getBaseUrl())
      .build();
    return this.http.get(url, {
      observe: 'response',
      responseType: 'blob',
      params: this.parseParams(request).params
    }).pipe(map((res) => {
      return new DownloadedFile(res);
    }));
  }

  constructor(private http: HttpClient, private resourceHelper: ResourceHelper) {
    super(resourceHelper, 'parcel-collection-points/');
  }

}

export namespace ParcelCollectionPointResource {

  export interface ParcelCollectionPoint {
    id: number;
    creation_time?: string;
    update_time?: string;
    name: string;
    code?: string;
    delivery_method?: DeliveryMethod;
    opening_times?: string;
    note?: string;
    disabled: boolean;
    postal_address?: AddressResource.PostalAddressResource;
    coordinates?: AddressResource.CoordinateResource;
  }

  export interface GetRequest {
    id: number;
  }

  export interface CreateRequest {
    name: string;
    code: string;
    delivery_method_id: number;
    opening_times?: string;
    note?: string;
    postal_address: AddressResource.PostalAddressResource;
    coordinates?: AddressResource.CoordinateResource;
  }

  export interface UpdateRequest extends CreateRequest {
    id: number;
  }

  export interface DisableRequest {
    id: number;
    disabled: boolean;
  }

  export interface QueryRequest {
    fields?: string;
    filter?: string;
    order?: string;
    page_number?: number;
    number_of_items?: number;
    no_progress_bar?: boolean;
  }

  export interface DeliveryMethod {
    id: number;
    name: string;
  }

}
