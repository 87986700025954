<div class="modal-content">
  <div class="modal-header">
    <p class="modal-title"><strong>{{'DEMO_MODE_FEATURE_DISABLED_DIALOG_TITLE' | translate}}</strong></p>
    <button type="button" class="close" mat-dialog-close aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="alert alert-warning">
      <p class="alert-title mb-0" [innerHTML]="'DEMO_MODE_FEATURE_DISABLED_DIALOG_MESSAGE' | translate:{phone: CONTACT_PHONE_NUMBER, email: CONTACT_EMAIL_ADDRESS}"></p>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" mat-dialog-close cdkFocusInitial>
      {{'COMMON_BUTTON_OK' | translate}}
    </button>
  </div>
</div>
