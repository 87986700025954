/* eslint-disable */
import { Injectable } from '@angular/core';
import { Observable, Observer } from 'rxjs';
import { Services } from '../util/services';
import { OffsetDateTime } from '../util/dates';
import Decimal from '../../../../node_modules/decimal.js';
import {
  ColonnadeClientDocumentStatResource,
  ColonnadeClientDocumentStatResourceService
} from './colonnade-client-document-stat-resource.service';
import { DownloadedFile } from '../util/downloaded-files';
import { Set } from 'immutable';

/* eslint-enable */

@Injectable()
export class ColonnadeClientDocumentStatService {

  constructor(private resourceService: ColonnadeClientDocumentStatResourceService) {
  }

  getEnrollerReport(request: ColonnadeClientDocumentStat.ColonnadeB2BStatRequest):
    Observable<ColonnadeClientDocumentStat.ColonnadeB2BBySponsorValue[]> {
      return Observable.create((observer: Observer<ColonnadeClientDocumentStat.ColonnadeB2BBySponsorValue[]>) => {
        const resourceRequest = this.toResourceColonnadeB2BStatRequest(request);
        return this.resourceService.getEnrollerReport(resourceRequest).subscribe(
          (result: ColonnadeClientDocumentStatResource.ColonnadeB2BBySponsorValue[]) => {
            observer.next(result.map(r => this.toPublicColonnadeB2BBySponsorValue(r)));
          },
          (error: Error) => {
            observer.error(error);
          },
          () => {
            observer.complete();
          });
      });
  }

  getEnrollerReportXlsx(request: ColonnadeClientDocumentStat.ColonnadeB2BStatRequest): Observable<DownloadedFile> {
    const resourceRequest = this.toResourceColonnadeB2BStatRequest(request);
    return this.resourceService.getEnrollerReportXlsx(resourceRequest);
  }

  getProductReport(request: ColonnadeClientDocumentStat.ColonnadeB2BStatRequest):
    Observable<ColonnadeClientDocumentStat.ColonnadeB2BByProductValue[]> {
    return Observable.create((observer: Observer<ColonnadeClientDocumentStat.ColonnadeB2BByProductValue[]>) => {
      const resourceRequest = this.toResourceColonnadeB2BStatRequest(request);
      return this.resourceService.getProductReport(resourceRequest).subscribe(
        (result: ColonnadeClientDocumentStatResource.ColonnadeB2BByProductValue[]) => {
          observer.next(result.map(r => this.toPublicColonnadeB2BByProductValue(r)));
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  getProductReportXlsx(request: ColonnadeClientDocumentStat.ColonnadeB2BStatRequest): Observable<DownloadedFile> {
    const resourceRequest = this.toResourceColonnadeB2BStatRequest(request);
    return this.resourceService.getProductReportXlsx(resourceRequest);
  }

  getEnrollerSummary(request: ColonnadeClientDocumentStat.ColonnadeB2BStatRequest):
    Observable<ColonnadeClientDocumentStat.ColonnadeB2BByEnrollerValue[]> {
    return Observable.create((observer: Observer<ColonnadeClientDocumentStat.ColonnadeB2BByEnrollerValue[]>) => {
      const resourceRequest = this.toResourceColonnadeB2BStatRequest(request);
      return this.resourceService.getEnrollerSummary(resourceRequest).subscribe(
        (result: ColonnadeClientDocumentStatResource.ColonnadeB2BByEnrollerValue[]) => {
          observer.next(result.map(r => this.toPublicColonnadeB2BByEnrollerValue(r)));
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  getEnrollerSummaryXlsx(request: ColonnadeClientDocumentStat.ColonnadeB2BStatRequest): Observable<DownloadedFile> {
    const resourceRequest = this.toResourceColonnadeB2BStatRequest(request);
    return this.resourceService.getEnrollerSummaryXlsx(resourceRequest);
  }

  private toResourceColonnadeB2BStatRequest(request: ColonnadeClientDocumentStat.ColonnadeB2BStatRequest):
    ColonnadeClientDocumentStatResource.ColonnadeB2BStatRequest {
      return {
        user_id: Services.createIdParameter(request.userId),
        branch_code: Services.createListParameter(request.branchCode),
        sponsor_code: Services.createListParameter(request.sponsorCode),
        from_time: Services.offsetDateTimeToString(request.fromTime),
        to_time: Services.offsetDateTimeToString(request.toTime)
      };
  }

  private toPublicColonnadeB2BBySponsorValue(r: ColonnadeClientDocumentStatResource.ColonnadeB2BBySponsorValue):
    ColonnadeClientDocumentStat.ColonnadeB2BBySponsorValue {
      return {
        user: {
          id: r.user.id,
          userName: r.user.user_name,
          personName: r.user.person_name
        },
        sponsorText: r.sponsor_text,
        branchText: r.branch_text,
        insuranceCount: r.insurance_count,
        sumPrice: new Decimal(r.sum_price),
        seenCount: r.seen_count,
        presentedCount: r.presented_count,
        customerCount: r.customer_count
      };
  }

  private toPublicColonnadeB2BByProductValue(r: ColonnadeClientDocumentStatResource.ColonnadeB2BByProductValue):
    ColonnadeClientDocumentStat.ColonnadeB2BByProductValue {
    return {
      user: {
        id: r.user.id,
        userName: r.user.user_name,
        personName: r.user.person_name
      },
      productText: r.product_text,
      productCode: r.product_code,
      insuranceCount: r.insurance_count,
      sumPrice: new Decimal(r.sum_price),
      presentedCount: r.presented_count
    };
  }

  private toPublicColonnadeB2BByEnrollerValue(r: ColonnadeClientDocumentStatResource.ColonnadeB2BByEnrollerValue):
    ColonnadeClientDocumentStat.ColonnadeB2BByEnrollerValue {
    return {
      user: {
        id: r.user.id,
        userName: r.user.user_name,
        personName: r.user.person_name
      },
      insuranceCount: r.insurance_count,
      sumPrice: new Decimal(r.sum_price),
      seenCount: r.seen_count,
      presentedCount: r.presented_count,
      customerCount: r.customer_count
    };
  }
}

export namespace ColonnadeClientDocumentStat {

  export interface ColonnadeB2BStatRequest {
    userId?: Set<number>;
    branchCode?: Set<string>;
    sponsorCode?: Set<string>;
    fromTime?: OffsetDateTime;
    toTime?: OffsetDateTime;
  }

  export interface ColonnadeB2BBySponsorValue {
    user: UserData;
    sponsorText: string;
    branchText: string;
    insuranceCount: number;
    sumPrice: Decimal;
    seenCount: number;
    presentedCount: number;
    customerCount: number;
  }

  export interface ColonnadeB2BByProductValue {
    user: UserData;
    productText: string;
    productCode: string;
    insuranceCount: number;
    sumPrice: Decimal;
    presentedCount: number;
  }

  export interface ColonnadeB2BByEnrollerValue {
    user: UserData;
    insuranceCount: number;
    sumPrice: Decimal;
    seenCount: number;
    presentedCount: number;
    customerCount: number;
  }

  export interface UserData {
    id: number;
    userName: string;
    personName: string;
  }

}
