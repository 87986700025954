import {
  StockItemSelectorDialogComponent,
  StockItemSelectorDialogData,
  StockItemSelectorModel
} from '../../admin/stock-item/stock-item-selector-dialog/stock-item-selector-dialog.component';
import { MultiselectOptionItem } from '../core-utils';
import { StockSelectorDialogComponent } from '../../admin/stock/stock-selector-dialog/stock-selector-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { StockTypeName } from './stock-utils';

export namespace StockIntakeSelectorUtils {
  export function startIntakeSelector(args: StockIntakeSelectorArgs) {
    const stockItemData: StockItemSelectorDialogData = {
      usableCategoryIds: args.usableCategoryIds,
      stockItemType: 'STOCK',
      amountLabel: 'FORM_RECORD_STOCK_INTAKE_AMOUNT',
      excludedIds: args.excludedStockItemIds
    };
    StockItemSelectorDialogComponent.openSelector(args.dialog, stockItemData, stockItemResult => {
      if (stockItemResult) {
        if (args.stockId) {
          args.resultCallback({
            selectedStockItemIds: stockItemResult.selectedStockItemIds,
            selectedStockItems: stockItemResult.selectedStockItems,
            selectedStockId: args.stockId
          });
        } else {
          StockSelectorDialogComponent.openSelector(args.dialog, {
              excludedIds: args.excludedStockIds,
              forceStockIds: args.forceDestinationStockIds,
              typeName: args.stockTypeName,
              ownerCustomerRecordId: args.ownerCustomerRecordId,
              ownerContactLocationId: args.ownerContactLocationId
            },
            (stockResult) => {
              if (stockResult) {
                args.resultCallback({
                  selectedStockItemIds: stockItemResult.selectedStockItemIds,
                  selectedStockItems: stockItemResult.selectedStockItems,
                  selectedStock: stockResult
                });
              }
            });
        }
      }
    });
  }

  export interface StockIntakeSelectorArgs {
    dialog: MatDialog;
    usableCategoryIds: number[];
    stockTypeName?: StockTypeName;
    ownerCustomerRecordId?: number;
    ownerContactLocationId?: number;
    excludedStockItemIds: number[];
    excludedStockIds: number[];
    forceDestinationStockIds?: number[];
    stockId?: number;
    resultCallback: (result: StockIntakeSelectorResult) => void;
  }

  export interface StockIntakeSelectorResult {
    selectedStockItemIds?: number[];
    selectedStockItems?: StockItemSelectorModel[];
    selectedStock?: MultiselectOptionItem<number>;
    selectedStockId?: number;
  }
}
