<div class="task" *ngIf="model" [ngStyle]="{'background-color': getBackgroundColor(), 'border-color': getBorderColor()}"
     (click)="onCardClicked()">
  <div class="col-md-12 d-flex flex-wrap">
    <app-type-icon [ngStyle]="{'color': getBorderColor()}" [icon]="model.task.icon" class="mr-05"></app-type-icon>
    <div class="mr-05">{{model.name}}</div>
  </div>
  <div class="col-md-12" *ngIf="model.assigneeName">
    <div class="">{{model.assigneeName}} ({{model.assigneeUserName}})</div>
  </div>
  <div class="col-md-12" *ngIf="!model.assigneeName && model.userGroupName">
    <div class="">{{model.userGroupName}}</div>
  </div>
  <div class="col-md-12" *ngIf="model.customerRecordName">
    <div class="table-text-subtitle">{{model.customerRecordName}}</div>
  </div>
  <div class="col-md-12" *ngIf="model.poc">
    <div class="table-text-subtitle">{{model.poc}}</div>
  </div>
  <div class="col-md-12 d-flex flex-wrap">
    <app-table-badge
      class="mr-05"
      size="small"
      [badgeStyle]="model.state.badgeStyle"
      [iconClass]="model.state.iconClass">
    </app-table-badge>
    <div class="table-text-subtitle">{{model.relevantDate.toUtcIsoString() | date: 'short'}}</div>
  </div>
</div>
