/* eslint-disable */
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { UserData, UserDataLoader, UserDataLoaderPermissionDeniedStrategy } from '../../../lib/user-data-loader';
import { Arrays } from '../../../lib/util/arrays';
import { TranslateService } from '@ngx-translate/core';
import { StateName } from '../../../app.state-names';
import { UIRouter } from '@uirouter/angular';
import { DocumentFileService } from '../../../lib/document/document-file.service';
import { AppworksDashboardProjectModel, AppworksDashboardTaskModel } from '../../../util/appworks-utils';
import { RightModel } from '../../../app.rights';
import { GrantedPermissionSetResolver, RightResolver, RightService } from '../../../lib/right.service';
import { CustomerRecordService } from '../../../lib/customer/customer-record.service';
import { Task, TaskService } from '../../../lib/task/task.service';
import { OrderType, QueryResult, ResourceQueryResult } from '../../../lib/util/services';
import { TaskRecordStateMachine } from '../../../lib/task/task-record-statemachine';
import { CountMessage, IdentityArray } from '../../../lib/util/messages';
import { TaskRecord, TaskRecordService } from '../../../lib/task/task-record.service';
import { List, Set } from 'immutable';
import { Dates, OffsetDateTime } from '../../../lib/util/dates';
import { AssigneeListModel } from '../../../util/task-record-utils';
import { combineLatest } from 'rxjs';
import { Icon } from '../../../lib/task/icon.service';
import { Address } from '../../../lib/address';
import { Device, DeviceManagementService } from '../../../lib/device-management.service';
import { DeviceItem } from '../../../util/core-utils';
import { UserService } from '../../../lib/user.service';
import { INVOICE_VALID_CURRENCY_CODE } from '../../invoicing/invoices/invoice-create/invoice-create-clone.model';
import { ConfigurationResource, ConfigurationService } from '../../../lib/core-ext/configuration.service';
import { DownloadedFile } from '../../../lib/util/downloaded-files';
import { SiteTourBaseComponent, SiteTourId } from '../../../lib/site-tour/site-tour.factory';
import { SiteTourService } from '../../../lib/site-tour/site-tour.service';
import { BaseChartDirective } from 'ng2-charts';
import { OperationRights } from '../../../app.right-definitions';
import { TaskRightModel } from '../../../util/task-utils';
import Log = TaskRecord.Log;
import PostalAddressDataType = Address.PostalAddressDataType;
import PostalAddressStruct = Address.PostalAddressStruct;
import RecentMessage = TaskRecord.RecentMessage;
import {TaskStatisticsService} from "../../../lib/statistics/task-statistics/task-statistics.service";
import {PaletteColorPickerComponent} from "../../../shared/palette-color-picker/palette-color-picker.component";
import {Process, ProcessService} from "../../../lib/process/process.service";

/* eslint-enable */

@Component({
  selector: 'app-appworks-dashboard',
  templateUrl: './appworks.component.html',
  styleUrls: ['./appworks.component.scss']
})
export class AppworksComponent implements OnInit, AfterViewInit, SiteTourBaseComponent {
  ConfigurationService = ConfigurationService;

  rightModel: RightModel = RightModel.empty();
  configuration: ConfigurationResource.Configuration;

  isChartVisible: boolean = true;
  customerCount: number = 0;
  userCount: number = 0;
  taskRecordCount: number = 0;
  documentFileCount: number = 0;
  currencyCode: string = INVOICE_VALID_CURRENCY_CODE;

  logModel: LogModel[] = [];
  taskRecordMessageList: RecentMessage[] = [];
  processMessageList: Process.RecentMessage[] = [];
  taskRecordList: Array<TaskRecordListModelWithAssigneeModel> = [];
  dashboardTaskModels: AppworksDashboardTaskModel[] = [];
  devices: DeviceItem[] = [];
  deviceExtraData: DeviceExtraDataModel[] = [];

  private currentTaskPage: number = 1;
  private maxTaskPages: number = 1;


  @ViewChild(BaseChartDirective) chart: BaseChartDirective;

  showGoogleCharts = false;

  constructor(private rightService: RightService,
              private customerRecordService: CustomerRecordService,
              private userDataLoader: UserDataLoader,
              private taskService: TaskService,
              private taskStatisticsService: TaskStatisticsService,
              private userService: UserService,
              private documentFileService: DocumentFileService,
              private translateService: TranslateService,
              private deviceManagementService: DeviceManagementService,
              private uiRouter: UIRouter,
              private siteTourService: SiteTourService,
              private taskRecordService: TaskRecordService,
              private processService: ProcessService,
              private configurationService: ConfigurationService) {
  }

  ngOnInit() {
    this.loadRightModels();
    this.loadConfiguration();
    this.siteTourService.startTour(SiteTourId.HOME, {
      completeCallback: () => {
        this.uiRouter.stateService.go(StateName.TASK_DASHBOARD);
      }
    });

  }

  ngAfterViewInit(): void {
  }

  loadRightModels() {
    this.rightService.getRightResolver().subscribe(
      (resolver: RightResolver) => {
        this.rightModel = RightModel.of(resolver);
        this.init();
      }
    );
  }

  private loadConfiguration() {
    this.configuration = this.configurationService.getConfiguration();
  }

  init() {
    if (this.rightModel.customerRecordRead.hasRight()) {
      this.loadCustomers();
    }
    if (this.rightModel.userRead.hasRight()) {
      this.loadUserCount();
    }
    if (this.rightModel.taskRead.hasRight()) {
      this.taskStatisticsService.getSimpleStat({helpdesk: false}).subscribe(stat => {
        this.taskRecordCount = Array.from(stat.stateStat.values())
          .reduce((previousValue, currentValue) => previousValue + currentValue, 0);
        if (this.taskRecordCount === 0) {
          this.isChartVisible = false;
          return;
        }
        this.loadTasks();
      });
    }
    if (this.rightModel.documentFileRead.hasRight()) {
      this.loadDocumentFiles();
    }
    if (this.rightModel.taskRecordChatRead.hasRight()) {
      this.loadTaskRecordMessages();
    }
    if (this.rightModel.processRead.hasRight()) {
      this.loadProcessMessages();
    }
    if (this.rightModel.taskRecordRead.hasRight()) {
      this.loadOverdueTasks();
    }
    if (this.rightModel.userRead.hasRight() && this.rightModel.taskRecordRead.hasRight() && this.rightModel.taskRecordLogRead.hasRight()) {
      this.loadLatestActivity();
    }
  }

  isEnabled(key: ConfigurationResource.AdminFeature) {
    if (!this.configuration) {
      return false;
    }
    return ConfigurationService.isEnabledByServer(key, this.configuration);
  }

  loadCustomers() {
    this.customerRecordService.globalCount({disabled: false}).subscribe((count: number) => {
      this.customerCount = count;
    });
  }

  loadUserCount() {
    this.userService.count({}).subscribe((res: CountMessage) => {
      this.userCount = res.current_number_of_items;
    });
  }

  loadTasks() {
    this.taskService.statistics({
      statisticsType: 'ALL',
      orders: Set.of({field: Task.OrderField.EXPLICIT_ORDER_NUMBER, type: OrderType.ASC}),
      showOnDashboard: true,
      disabled: false,
      rights: Set.of(OperationRights.TASK_RECORD_CREATE),
      paging: {
        pageNumber: this.currentTaskPage,
        numberOfItems: this.getTaskNumberOfItems(this.currentTaskPage)
      }
    }).subscribe((result: QueryResult<Task.Task>) => {
      const tasks: Task.Task[] = result.items.toArray();

      this.currentTaskPage++;
      this.maxTaskPages = Math.ceil(result.pagingResult.currentNumberOfItems / this.getTaskNumberOfItems(this.currentTaskPage));

      Arrays.iterateByIndex(tasks, task => {
        const taskRecordCount = this.calcSumRecords(task);
        const taskModel = new AppworksDashboardTaskModel();
        const taskGrantedRights = new TaskRightModel(GrantedPermissionSetResolver.byGrantedRights(task.grantedRights));

        taskModel.taskRecordCount = taskRecordCount;
        taskModel.name = task.name;
        taskModel.taskId = task.taskId;
        if (task.icon) {
          taskModel.icon = task.icon;
        }
        taskModel.color = task.taskRecordColor;
        taskModel.canCreate = taskGrantedRights.taskRecordCreate.hasRight();

        if (task.statistics && taskRecordCount > 0) {
          task.statistics!.forEach((value: number, state: TaskRecordStateMachine.State) => {
            taskModel.stats[state] = {
              state: state,
              count: value,
              percent: Math.round((value / taskRecordCount) * 100)
            };
          });
        }
        this.dashboardTaskModels.push(taskModel);
        this.showGoogleCharts = true;
      });
    });
  }

  private getTaskNumberOfItems(pageNumber: number) {
    return 8;
  }

  hasTaskMorePages(): boolean {
    return this.maxTaskPages >= this.currentTaskPage;
  }
  calcSumRecords(task: Task.Task): number {
    if (!task.statistics) {
      return 0;
    }
    let sum: number = 0;
    task.statistics.forEach((value: number) => {
      sum += value;
    });
    return sum;
  }

  loadDocumentFiles() {
    this.documentFileService.count({}).subscribe((res: CountMessage) => {
      this.documentFileCount = res.current_number_of_items;
    });
  }

  customerCardClicked() {
    if (this.rightModel.webMenuCustomerRecords.hasRight()) {
      this.uiRouter.stateService.go(StateName.CUSTOMER_RECORD_GLOBAL_LIST);
    }
  }

  usersCardClicked() {
    if (this.rightModel.webAdminUsers.hasRight()) {
      this.uiRouter.stateService.go(StateName.USER_LIST);
    }
  }

  tasksCardClicked() {
    if (this.rightModel.webMenuTaskRecords.hasRight()) {
      this.uiRouter.stateService.go(StateName.TASK_RECORD_GLOBAL_LIST);
    }
  }

  documentFileCardClicked() {
    if (this.rightModel.webMenuDocuments.hasRight()) {
      this.uiRouter.stateService.go(StateName.DOCUMENT_FILE_LIST);
    }
  }

  private loadTaskRecordMessages() {
    this.taskRecordService.getRecentMessages({
      paging: {
        pageNumber: 1,
        numberOfItems: 5
      },
      fields: Set.of('id', 'task_id', 'name', 'process')
    }).subscribe(
      (result: QueryResult<RecentMessage>) => {
        this.taskRecordMessageList = result.items.toArray();
      }
    );
  }

  private loadProcessMessages() {
    this.processService.getRecentMessages({
      paging: {
        pageNumber: 1,
        numberOfItems: 5
      },
      fields: field => field.forMessages
    }).subscribe(
      (result: QueryResult<Process.RecentMessage>) => {
        this.processMessageList = result.items.toArray();
      }
    );
  }

  private loadOverdueTasks() {
    const requestedPage = 1;

    this.taskRecordService.globalQuery(<TaskRecord.GlobalQueryRequest>{
      paging: {
        pageNumber: requestedPage,
        numberOfItems: 3
      },
      orders: Set.of({type: OrderType.DESC, field: TaskRecord.OrderField.ID}),
      state: Set.of('NEW', 'IN_PROGRESS', 'OPEN'),
      deadlineTo: Dates.now()
    })
      .subscribe(
        (result: QueryResult<TaskRecord.TaskRecord>) => {
          this.taskRecordList = this.toModelArray(result.items);
          this.loadDataForList();
        }
      );
  }

  private loadDataForList() {
    combineLatest(
      this.userDataLoader.loadAllWithFields(UserDataLoaderPermissionDeniedStrategy.MISS_PERMISSION_DENIED, undefined, Set.of(
        'id',
        'user_name',
        'person_name',
        'profile_picture_hash')),
      (userList: IdentityArray<UserData>) => {
        return {users: userList}
      }
    ).subscribe((value: {
      users: IdentityArray<UserData>
    }) => {
      this.taskRecordList.forEach((tr) => {
        const userData = value.users.find(u => tr.taskRecord.assignee !== undefined && u.id === tr.taskRecord.assignee.userId);
        tr.assigneeModel.assigneeUser = userData;
      });
    });
    this.rightModel.mobileAppRead.hasRight() ? this.loadDevices() : this.setMobileAppNames(false);
  }

  private setMobileAppNames(hasDeviceReadRight?: boolean) {
    this.taskRecordList.forEach((tr) => {
      if (tr.taskRecord.assignee && tr.taskRecord.assignee.mobileApplicationId) {
        if (!hasDeviceReadRight) {
          tr.assigneeModel.miscellaneousAssigneeName = this.translateService.instant('OVERDUE_TASKS_MOBILE_APP');
        }
        this.deviceExtraData.forEach((extraDeviceData) => {
          if (extraDeviceData.id === tr.taskRecord.assignee!.mobileApplicationId) {
            tr.assigneeModel.miscellaneousAssigneeName = extraDeviceData.hasDeviceName ?
              extraDeviceData.name :
              this.translateService.instant('OVERDUE_TASKS_MOBILE_APP');
          }
        });
      }
    })
  }

  private loadDevices() {
    this.deviceManagementService.query({}).subscribe((devices: ResourceQueryResult<Device>) => {
        this.devices = [];
        this.deviceExtraData = [];
        devices.items.forEach((device) => {
          if (device.application_id && device.application_id !== '') {
            const item = {
              id: device.id,
              hasDeviceName: !!device.name,
              name: device.name ? device.name : '',
            };
            this.deviceExtraData.push(item);
          }
        });
      },
      (error: any) => {
      },
      () => this.setMobileAppNames());
  }

  private loadLatestActivity() {
    const requestedPage = 1;
    this.taskRecordService.getGlobalLogHistory(<TaskRecord.GetGlobalLogHistoryRequest>{
      paging: {
        pageNumber: requestedPage,
        numberOfItems: 3
      },
      orders: Set.of({type: OrderType.DESC, field: TaskRecord.LogOrderField.LOG_TIME})
    })
      .subscribe(
        (results: QueryResult<Log>) => {
          results.items.forEach(result => {
            const logModelItem: LogModel = new LogModel();
            if (result) {
              logModelItem.id = result.id;
              logModelItem.logTime = result.logTime;
              logModelItem.logType = result.logType;
              logModelItem.taskRecordId = result.taskRecord.id;
              logModelItem.taskId = result.taskRecord.taskId;
              logModelItem.taskRecordName = result.taskRecord.name;
              logModelItem.userProfileId = result.userProfileId;
              logModelItem.userName = result.user.userName;
              logModelItem.profilePicture = undefined;
              this.logModel.push(logModelItem);
              this.userService.downloadProfilePicture(logModelItem.userProfileId).subscribe(
                (res: DownloadedFile) => {
                  logModelItem.profilePicture = URL.createObjectURL(res.getBlob());
                },
                () => {
                }
              );
            }
          });
        }
      );
  }

  toModelArray(list: List<TaskRecord.TaskRecord>): TaskRecordListModelWithAssigneeModel[] {
    const ret: TaskRecordListModelWithAssigneeModel[] = [];
    list.forEach((record) => {
      if (record) {
        ret.push({taskRecord: record, selected: false, assigneeModel: new AssigneeListModel(), customerName: '',
        icon: record.task?.icon});
      }
    });
    return ret;
  }

  getColor(task: AppworksDashboardTaskModel) {
    const colorId = task.color;
    return PaletteColorPickerComponent.resolveColor(colorId);
  }
  displayPostalAddress(taskRecord: TaskRecord.TaskRecord): string {
    if (taskRecord.placeOfConsumption && taskRecord.placeOfConsumption.address) {
      if (taskRecord.placeOfConsumption.address.type === PostalAddressDataType.TEXT && taskRecord.placeOfConsumption.address.textValue) {
        return taskRecord.placeOfConsumption.address.textValue;
      }
      if (taskRecord.placeOfConsumption.address.type === PostalAddressDataType.COMPLEX &&
        taskRecord.placeOfConsumption.address.complexValue) {
        return this.complexAddressToString(taskRecord.placeOfConsumption.address.complexValue);
      }
    }
    return '';
  }

  // Only outputs part of the address to save space
  complexAddressToString(complexAddress: PostalAddressStruct): string {
    let simpleAddress = '';
    if (complexAddress.city) {
      simpleAddress = simpleAddress.concat(' ' + complexAddress.city);
    }
    if (complexAddress.address) {
      simpleAddress = simpleAddress.concat(' ' + complexAddress.address);
    }
    if (complexAddress.addressType) {
      simpleAddress = simpleAddress.concat(' ' + complexAddress.addressType);
    }
    if (complexAddress.houseNumber) {
      simpleAddress = simpleAddress.concat(' ' + complexAddress.houseNumber);
    }
    return simpleAddress;
  }

}

export class LogModel {
  id: number;
  logType: string;
  logTime: OffsetDateTime;
  userProfileId: number;
  userName: string;
  taskRecordName: string;
  taskRecordId: number;
  taskId: number;
  profilePicture: any | undefined;
}

export class TaskRecordListModelWithAssigneeModel {
  taskRecord: TaskRecord.TaskRecord;
  customerName: string;
  selected: boolean = false;
  assigneeModel: AssigneeListModel;
  icon?: Icon.Icon;
}

export class DeviceExtraDataModel {
  id: number;
  name: string;
  hasDeviceName: boolean;
}
