/* eslint-disable */
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { BaseHttpService, ResourceHelper } from '../util/http-services';
/* eslint-enable */

@Injectable()
export class MplTrackingResourceService extends BaseHttpService {

  query(request: MplTrackingResource.QueryRequest): Observable<MplTrackingResource.MplTracking[]> {
    return this.http.get<MplTrackingResource.MplTracking[]>(this.url, this.parseParams(request));
  }

  constructor(private http: HttpClient, resourceHelper: ResourceHelper) {
    super(resourceHelper, 'mpl-trackings/');
  }

}

export namespace MplTrackingResource {

  export interface MplTracking {
    time: string;
    tracking_number: string;
    shipment?: Shipment;
    order?: Order;
    description?: string;
    category?: string;
    category_code?: string;
    type_code?: string;
    post_office_name?: string;
    background_system_name?: string;
    core_service_name?: string;
    delivery_mode?: string;
    cash_on_delivery_mode?: string;
    retention_period_days?: number;
    size_code_description?: string;
    cash_on_delivery_price?: string;
    insurance_price?: string;
    mass_gram?: string;
  }

  export interface QueryRequest {
    filter?: string;
    order?: string;
  }

  export interface Shipment {
    id: number;
    delivery_note_number: string;
  }

  export interface Order {
    id: number;
  }

}
