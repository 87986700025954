import { DqlQuery, Query } from '../field';
import { LazyReference } from '../../../util/lazy';
import { TaskRecord } from './task-record';
import { User } from './user';
import { Worklog as WorklogNamespace } from '../../worklog/worklog.service';
import NumberField = DqlQuery.NumberField;
import DateTimeField = DqlQuery.DateTimeField;

// Each class should be an interface, but it is easier to define the constant fields in the constructors.
// For now we have only one query language so it is not a problem.
// Always use the public Query namespace for variable definitions so we will be able to introduce other language easily.

export class Worklog {

  private readonly taskRecordRef: LazyReference<TaskRecord>;

  private readonly loggerUserRef: LazyReference<User>;

  private readonly workerUserRef: LazyReference<User>;

  readonly loggedTimeInMinutes: Query.NumberField;

  readonly estimatedTimeInMinutes: Query.NumberField;

  readonly worklogUserType: Query.EnumField<WorklogNamespace.WorklogUserType>;

  readonly startTime: Query.DateTimeField;

  readonly endTime: Query.DateTimeField;

  get taskRecord(): TaskRecord {
    return this.taskRecordRef.get();
  }

  get loggerUser(): User {
    return this.loggerUserRef.get();
  }

  get workerUser(): User {
    return this.workerUserRef.get();
  }

  constructor(prefix?: string) {
    prefix = prefix ? prefix : '';
    this.taskRecordRef = LazyReference.of(
      () => new TaskRecord(prefix + 'task_record.')
    );
    this.loggerUserRef = LazyReference.of(
      () => new User(prefix + 'logger_user.')
    );
    this.workerUserRef = LazyReference.of(
      () => new User(prefix + 'worker_user.')
    );
    this.loggedTimeInMinutes = new NumberField(prefix + 'logged_time_in_minutes');
    this.estimatedTimeInMinutes = new NumberField(prefix + 'estimated_time_in_minutes');
    this.worklogUserType = new DqlQuery.EnumField<WorklogNamespace.WorklogUserType>(prefix + 'worklog_user_type', (sts) => sts);
    this.startTime = new DateTimeField(prefix + 'start_time');
    this.endTime = new DateTimeField(prefix + 'end_time');
  }

}
