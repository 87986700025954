import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Angular2Multiselects } from '../../../util/multiselect';
import { OptionItem, UiConstants } from '../../../util/core-utils';
import { Workflow, WorkflowService } from '../../../lib/workflow/workflow.service';
import { ProcessConfigModel, WorkflowCreateModel, WorkflowProcessConfigNameTemplate } from './workflow-create.model';
import { FieldValidationError, ForwardingNgFormRef, LocalFormGroupValidationErrors } from '../../../lib/util/services';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { AppValidators } from '../../../util/app-validators';
import { TaskRecordExternalIdSequence } from '../../../util/task-utils';

@Component({
  selector: 'app-workflow-base-dialog',
  templateUrl: './workflow-base-dialog.component.html',
  styleUrls: ['./workflow-base-dialog.component.scss']
})
export class WorkflowBaseDialogComponent implements OnInit {

  Workflow = Workflow;
  TaskRecordExternalIdSequence = TaskRecordExternalIdSequence;
  WorkflowProcessConfigNameTemplate = WorkflowProcessConfigNameTemplate;

  model: WorkflowCreateModel = new WorkflowCreateModel();

  dropdownSettings: Angular2Multiselects.Settings;
  multiselectDropdownSettings: Angular2Multiselects.Settings;
  @ViewChild('f', { static: true })
  form: NgForm;
  formGroup: FormGroup;
  private formGroupValidationErrors: LocalFormGroupValidationErrors;
  private fieldErrors: FieldValidationError<Workflow.ValidatedField> =
    FieldValidationError.empty<Workflow.ValidatedField>();

  constructor(public dialogRef: MatDialogRef<WorkflowBaseDialogComponent>,
              private translateService: TranslateService,
              private fb: FormBuilder,
              private workflowService: WorkflowService,
              @Inject(MAT_DIALOG_DATA) public data: WorkflowBaseDialogData) {
    this.formGroup = this.createFormGroup(this.fb);
    this.formGroupValidationErrors = LocalFormGroupValidationErrors.ofForm(
      this.createForwardingHtmlForm(),
      this.formGroup
    );
    if (data.workflow) {
      this.model = new WorkflowCreateModel(data.workflow);
    }
  }

  ngOnInit() {
    this.initDropdownSettings();
  }

  initDropdownSettings() {
    this.dropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(true)
      .enableSearchFilter(true)
      .enableCheckAll(false)
      .remoteSearch(false)
      .translate(true)
      .build();
    this.multiselectDropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(false)
      .enableSearchFilter(true)
      .enableCheckAll(true)
      .remoteSearch(false)
      .translate(true)
      .build();
  }

  closeDialog() {
    this.dialogRef.close();
  }

  save() {
    if (this.hasLocalFieldError() || this.fieldErrors.hasError()) {
      return;
    }
    if (!this.data.workflow) {
      this.workflowService.create(this.model.toCreateRequest()).subscribe((value) => {
        this.dialogRef.close(value);
      }, (error: any) => {
        if (error instanceof FieldValidationError) {
          this.fieldErrors = error.withForm(this.form);
        }
      });
    }
    else if (!this.readonly) {
      this.workflowService.update(this.model.toUpdateRequest()).subscribe((value) => {
        this.dialogRef.close({id: this.model.id});
      }, (error: any) => {
        if (error instanceof FieldValidationError) {
          this.fieldErrors = error.withForm(this.form);
        }
      });
    }
    else {
      this.dialogRef.close();
    }
  }

  hasFieldError(field: Workflow.ValidatedField): boolean {
    return this.fieldErrors.hasError(field);
  }

  removeFieldError(field: Workflow.ValidatedField) {
    this.fieldErrors = this.fieldErrors.removeError(field);
  }

  getFieldErrorText(field: Workflow.ValidatedField): string {
    return this.fieldErrors.getErrorText(field);
  }

  showIconDialog() {

  }

  private createForwardingHtmlForm() {
    return new ForwardingNgFormRef({
      formFn: () => {
        return this.form;
      }
    });
  }

  private createFormGroup(fb: FormBuilder): FormGroup {
    return fb.group(
      {
        externalId: fb.control(
          {value: this.model.externalId, disabled: this.readonly},
          [
            AppValidators.tempValidator({
              validator: Validators.required,
              disabled: () => !this.edit
            })
          ]
        ),
        name: fb.control(
          {value: this.model.name, disabled: this.readonly},
          [
            AppValidators.tempValidator({
              validator: Validators.required,
              disabled: () => this.readonly
            })
          ]
        ),
        description: fb.control(
          {value: this.model.description, disabled: this.readonly},
          []
        ),
        inheritanceMode: fb.control({value: this.model.inheritanceMode, disabled: this.readonly},
          [
            AppValidators.tempValidator({
              validator: Validators.required,
              disabled: () => this.readonly
            })
          ]
        ),
      }
    );
  }

  hasLocalFieldError(formControlName?: string, errorCode?: string): boolean {
    return this.formGroupValidationErrors.hasFieldError(formControlName, errorCode);
  }

  getTextMaximumLength(): number {
    return UiConstants.maximumVarcharLength;
  }

  get readonly(): boolean {
    return this.data.readonly;
  }

  get edit(): boolean {
    return !this.readonly && this.model.workflow !== undefined;
  }

  get showAdditionalFields(): boolean {
    return this.model.workflow !== undefined;
  }

  getTitleKey(): string {
    if (this.readonly) {
      return 'WORKFLOW_DETAIL';
    }
    if (this.edit) {
      return 'WORKFLOW_EDIT';
    }
    return 'WORKFLOW_CREATE';
  }

  onStartWithFirstTaskChanged(value: boolean) {
  }
}

export interface WorkflowBaseDialogData {
  workflow?: Workflow.Workflow;
  readonly: boolean;
}
