/* eslint-disable */
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { BaseHttpService, ResourceHelper } from '../../util/http-services';
import { ResourceQueryResult } from '../../util/services';
import { DisableRequest, IdentityMessage } from '../../util/messages';

/* eslint-enable */

@Injectable()
export class LedgerNumberResourceService extends BaseHttpService {

  // <editor-fold desc="CRUD">

  query(request: LedgerNumberResource.QueryRequest): Observable<ResourceQueryResult<LedgerNumberResource.LedgerNumber>> {
    return this.http.get<ResourceQueryResult<LedgerNumberResource.LedgerNumber>>(this.url, this.parseParams(request));
  }

  get(request: LedgerNumberResource.GetRequest): Observable<LedgerNumberResource.LedgerNumber> {
    return this.http.get<LedgerNumberResource.LedgerNumber>(this.url + `${request.id}`, this.parseParams(request));
  }

  create(request: LedgerNumberResource.CreateRequest): Observable<IdentityMessage> {
    return this.http.post<IdentityMessage>(this.url, request);
  }

  update(request: LedgerNumberResource.UpdateRequest): Observable<IdentityMessage> {
    return this.http.put<IdentityMessage>(this.url + `${request.id}`, request);
  }

  disable(request: DisableRequest): Observable<IdentityMessage> {
    return this.http.patch<IdentityMessage>(this.url + `${request.id}/disable`, request);
  }

  // </editor-fold>

  constructor(private http: HttpClient, resourceHelper: ResourceHelper) {
    super(resourceHelper, '/ledger/number/');
  }

}

export namespace LedgerNumberResource {

  // <editor-fold desc="CRUD">

  export interface QueryRequest {
    fields?: string;
    filter?: string;
    order?: string;
    page_number?: number;
    number_of_items?: number;
    rights?: string;
    no_progress_bar?: boolean;
  }

  export interface LedgerNumber {
    id: number;
    granted_rights: string[];
    creation_time: string;
    update_time: string;
    ledger_number: string;
    name?: string;
    disabled: boolean;
  }

  export interface GetRequest {
    id: number;
    fields?: string;
    rights?: string;
  }

  export interface CreateRequest {
    ledger_number: string;
    name?: string;
  }

  export interface UpdateRequest {
    id: number;
    ledger_number: string;
    name?: string;
  }

  // </editor-fold>

}
