/* eslint-disable */
import { Component, OnInit, ViewChild, } from '@angular/core';
import { Transition, UIRouter } from '@uirouter/angular';
import { ImmutableUserGroupItem, OwnerUserItem, SelectUtils, UiConstants } from '../../../util/core-utils';
import { StateName } from '../../../app.state-names';
import { InputMask } from '../../../util/input-masks';
import { MasterdataCreateModel, RequiredMasterDataIdsItems } from '../../../util/masterdata-utils';
import { MasterData, MasterDataService } from '../../../lib/masterdata/master-data.service';
import { FieldValidationError, ResourceQueryResult, Services } from '../../../lib/util/services';
import { NgForm } from '@angular/forms';
import { LoadingHandler } from '../../../lib/loading-handler';
import { BreadcrumbParent } from '../../../shared/breadcrumb/breadcrumb/breadcrumb.component';
import { TranslateService } from '@ngx-translate/core';
import { CustomerOwnerTypeItem } from '../../../util/customer-utils';
import { Angular2Multiselects } from '../../../util/multiselect';
import { ConfigurationService } from '../../../lib/core-ext/configuration.service';
import { UserGroup, UserGroupService } from '../../../lib/user-group.service';
import { TranslateUtils } from '../../../util/translate';
import { Customer } from '../../../lib/customer/customer.service';
import { Strings } from '../../../lib/util/strings';
import { Set } from 'immutable';
import { ToasterService } from '../../../fork/angular2-toaster/src/toaster.service';
import { StringKey } from '../../../app.string-keys';
/* eslint-enable */

@Component({
  selector: 'app-masterdata-create',
  templateUrl: 'masterdata-create.component.html',
  styleUrls: ['masterdata-create.component.scss']
})
export class MasterdataCreateComponent implements OnInit {

  InputMask = InputMask;
  MasterData = MasterData;
  RequiredMasterDataIdsItems = RequiredMasterDataIdsItems;
  SelectUtils = SelectUtils;
  UiConstants = UiConstants;

  @ViewChild('f', { static: true })
  fForm: NgForm;

  model: MasterdataCreateModel;
  ownerUsers: OwnerUserItem[];
  ownerTypes: CustomerOwnerTypeItem[] = [];
  fieldErrors: FieldValidationError<MasterData.ValidatedField>;
  breadcrumbParents: BreadcrumbParent[] = [];
  breadcrumbSelf: string;
  compactSidebar: boolean = document.querySelector('body')!.classList.contains('sidebar-compact');

  userGroupDropdownSettings: Angular2Multiselects.Settings;

  constructor(
    private masterDataService: MasterDataService,
    private uiRouter: UIRouter,
    private toasterService: ToasterService,
    private translateService: TranslateService,
    private userGroupService: UserGroupService) {
    this.model = new MasterdataCreateModel();
    this.ownerUsers = [];
    this.fieldErrors = FieldValidationError.empty<MasterData.ValidatedField>();
    this.loadCustomerOwnerTypes();
    this.loadUserGroups();
  }

  ngOnInit() {
    this.translateService.get('MASTERDATA_PANEL_HEADING_ADD').subscribe(
      (result: string) => {
        this.breadcrumbSelf = result;
      }
    );
    this.translateService.get('MENU_NAVBAR_MENU_ADMINISTRATION').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.ADMIN_DASHBOARD});
      }
    );
    this.translateService.get('MENU_NAVBAR_MASTERDATA').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.MASTERDATA_LIST});
      }
    );
    this.initDropdown();
  }

  initDropdown() {
    this.userGroupDropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(false)
      .enableSearchFilter(true)
      .enableCheckAll(true)
      .remoteSearch(true)
      .build();
  }

  loadCustomerOwnerTypes() {
    const keys: string[] = [];
    Customer.customerMobileOwnerTypes.forEach((type) => {
      this.ownerTypes.push({id: type.type, text: '', key: type.stringKey});
      keys.push(type.stringKey);
    });
    this.translateService.get(keys).subscribe((texts) => {
      this.ownerTypes.forEach((type) => {
        type.text = TranslateUtils.extractValueFromObject(texts, type.key);
      });
      this.model.mobileOwnerType = this.ownerTypes[0];
    });
  }

  loadUserGroups(q?: any) {
    this.userGroupService.query({
        name: q ? Strings.undefinedOrNonEmpty(q.target.value) : undefined,
        disabled: false,
        order: Services.createOrderFieldParameter(UserGroup.Keys.toOrderFieldKey, Set.of(UserGroup.DEFAULT_ORDER)),
        page_number: 1,
        number_of_items: 30,
        no_progress_bar: true
      }
    ).subscribe((result: ResourceQueryResult<UserGroup>) => {
      this.model.loadUserGroupItems(
        ImmutableUserGroupItem.fromUserGroupArray(result.items)
      );
    });
  }

  createMasterData() {
    this.masterDataService.create({
      name: this.model.name,
      description: this.model.description,
      externalId: this.model.externalId,
      requiredMasterDataId: this.model.requiredMasterDataId,
      mobileOwnerType: this.model.mobileOwnerType!.id!,
      ownerUserGroupIds: this.model.getUserGroupIds(),
    }).subscribe(
      (response: MasterData.MasterData) => {
        LoadingHandler.getInstance().refresh();
        this.uiRouter.stateService.go(StateName.MASTERDATA_EDIT, {id: response.masterDataId});
      },
      (error: any) => {
        if (error instanceof FieldValidationError) {
          this.fieldErrors = error.withForm(this.fForm);
          this.showValidationErrorToast();
        }
      }
    );
  }

  private showValidationErrorToast() {
    this.toasterService.pop({
      timeout: UiConstants.ToastTimeoutLong,
      type: UiConstants.toastTypeError,
      title: this.translateService.instant(StringKey.COMMON_FORM_VALIDATION_ERROR_TOAST_TITLE),
      body: this.translateService.instant(StringKey.COMMON_FORM_VALIDATION_ERROR_TOAST_MESSAGE)
    });
  }

  hasFieldError(field: MasterData.ValidatedField): boolean {
    return this.fieldErrors.hasError(field);
  }

  removeFieldError(field: MasterData.ValidatedField) {
    this.fieldErrors = this.fieldErrors.removeError(field);
  }

  getFieldErrorText(field: MasterData.ValidatedField): string {
    return this.fieldErrors.getErrorText(field);
  }

  back() {
    window.history.back();
  }

}
