/* eslint-disable */
import { FormRecord } from '../../../../../lib/form/form-record.service';
import { Form } from '../../../../../lib/form/form.service';
import { Set } from 'immutable';
import { ConfigurationResource } from '../../../../../lib/core-ext/configuration.service';
import { FieldDataTypeSelectors, SetTmpReadonlyResult, } from '../../../../../util/form/form-utils';
import { CommandManager } from '../../../../../util/command';
import { CommandResultStore } from '../../command-result-store';
import { FormRecordInactivityManager } from '../../manager/form-record-inactivity-manager';
/* eslint-enable */

export namespace FormRecordComposedContainer {

  export interface PreView {
    loadFormRecord(args: Args, view: View);
  }

  export interface View {
    loadFormRecord(args: Args);
    createModel(): Model;
    hasLocalFieldError(): boolean;
  }

  export interface Args {
    commandManager: CommandManager<SetTmpReadonlyResult>;
    commandResultStore: CommandResultStore;
    fieldDataTypeSelectors: FieldDataTypeSelectors;
    configuration: ConfigurationResource.Configuration;
    form: Form.Form;
    record?: FormRecord.FormRecordComposed;
  }

  // Not injected so valid inside the namespace.
  export class Model {
    fields: Set<FormRecord.FieldEditRequest> = Set.of<FormRecord.FieldEditRequest>();
  }

}
