<div class="breadcrumb-container" [ngClass]="{ 'breadcrumb-container-compact': compactSidebar }" id="breadcrumb">
  <div class="breadcrumb-component">
    <app-breadcrumb
      [parents]="breadcrumbParents"
      [self]="breadcrumbSelf">
    </app-breadcrumb>
  </div>
</div>
<div class="breadcrumb-placeholder"></div>
<div class="container-horizontal-padding container-vertical-padding animated fadeIn">

  <div class="card">

    <div class="card-header">
      {{'MENU_NAVBAR_INVOICE_STATISTICS' | translate}}
    </div>

    <div class="card-body">

      <table class="table table-striped table-bordered">
        <thead>
        <tr>
          <th class="table-sorter-header hidden-sm-down">
            <app-table-field-sorter [orderField]="InvoiceStatistics.OrderField.TASK_ID"
                                    [orderType]="queryModel.getOrderType(InvoiceStatistics.OrderField.TASK_ID)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'INVOICE_STATISTICS_TABLE_HEADER_TASK_ID' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-sm-down">
            <app-table-field-sorter [orderField]="InvoiceStatistics.OrderField.TASK_NAME"
                                    [orderType]="queryModel.getOrderType(InvoiceStatistics.OrderField.TASK_NAME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'INVOICE_STATISTICS_TABLE_HEADER_TASK_NAME' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-xs-down">
            <app-table-field-sorter [orderField]="InvoiceStatistics.OrderField.TASK_RECORD_COUNT"
                                    [orderType]="queryModel.getOrderType(InvoiceStatistics.OrderField.TASK_RECORD_COUNT)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'INVOICE_STATISTICS_TABLE_HEADER_TASK_RECORD_COUNT' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header">
            <app-table-field-sorter [orderField]="InvoiceStatistics.OrderField.NET_SUM"
                                    [orderType]="queryModel.getOrderType(InvoiceStatistics.OrderField.NET_SUM)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'INVOICE_STATISTICS_TABLE_HEADER_NET_SUM' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header">
            <app-table-field-sorter [orderField]="InvoiceStatistics.OrderField.GROSS_SUM"
                                    [orderType]="queryModel.getOrderType(InvoiceStatistics.OrderField.GROSS_SUM)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'INVOICE_STATISTICS_TABLE_HEADER_GROSS_SUM' | translate"></app-table-field-sorter>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let model of invoiceStatistics | paginate: {
                                                      itemsPerPage: queryModel.itemsPerPage,
                                                      currentPage: queryModel.currentPage,
                                                      totalItems: queryModel.currentNumberOfItems }">
          <td class="responsive-table-column hidden-sm-down">{{ model.taskId }}</td>
          <td class="responsive-table-column hidden-sm-down">{{ model.taskName }}</td>
          <td class="responsive-table-column hidden-xs-down">{{ model.taskRecordCount }}</td>
          <td class="responsive-table-column">{{ model.netSum | number:'':'hu-HU' }}</td>
          <td class="responsive-table-column">{{ model.grossSum | number:'':'hu-HU' }}</td>
        </tr>
        </tbody>
        <tfoot>
        <tr>
          <th class="table-sorter-header hidden-sm-down">
            <app-table-field-sorter [orderField]="InvoiceStatistics.OrderField.TASK_ID"
                                    [orderType]="queryModel.getOrderType(InvoiceStatistics.OrderField.TASK_ID)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'INVOICE_STATISTICS_TABLE_HEADER_TASK_ID' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-sm-down">
            <app-table-field-sorter [orderField]="InvoiceStatistics.OrderField.TASK_NAME"
                                    [orderType]="queryModel.getOrderType(InvoiceStatistics.OrderField.TASK_NAME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'INVOICE_STATISTICS_TABLE_HEADER_TASK_NAME' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-xs-down">
            <app-table-field-sorter [orderField]="InvoiceStatistics.OrderField.TASK_RECORD_COUNT"
                                    [orderType]="queryModel.getOrderType(InvoiceStatistics.OrderField.TASK_RECORD_COUNT)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'INVOICE_STATISTICS_TABLE_HEADER_TASK_RECORD_COUNT' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header">
            <app-table-field-sorter [orderField]="InvoiceStatistics.OrderField.NET_SUM"
                                    [orderType]="queryModel.getOrderType(InvoiceStatistics.OrderField.NET_SUM)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'INVOICE_STATISTICS_TABLE_HEADER_NET_SUM' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header">
            <app-table-field-sorter [orderField]="InvoiceStatistics.OrderField.GROSS_SUM"
                                    [orderType]="queryModel.getOrderType(InvoiceStatistics.OrderField.GROSS_SUM)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'INVOICE_STATISTICS_TABLE_HEADER_GROSS_SUM' | translate"></app-table-field-sorter>
          </th>
        </tr>
        </tfoot>
      </table>
      <app-table-paging
        [currentNumberOfItems]="queryModel.currentNumberOfItems"
        [totalNumberOfItems]="queryModel.totalNumberOfItems"
        [itemsPerPage]="queryModel.itemsPerPage"
        (pageChange)="pageChanged($event)"
        (itemsPerPageChange)="itemsPerPageChanged($event)">
      </app-table-paging>

    </div><!-- /.card-body -->
  </div><!-- /.card-->
</div>
