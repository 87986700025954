<div class="card">
  <div class="card-header">
    {{'COLONNADE_CLIENT_DOCUMENT_STAT_ENROLLER_SUMMARY' | translate}}
    <div class="card-actions">
      <a class="btn-setting table-header-action-button d-flex align-items-center px-1 w-auto font-weight-normal" title="{{'COMMON_BUTTON_EXPORT' | translate}}"
         (click)="downloadXlsx()">
        <i class="icomoon pr-1 icomoon-file-xlsx"></i>
        <span class="breadcrumb-button-text-visible">{{'COMMON_BUTTON_EXPORT' | translate}}</span>
      </a>
    </div><!-- /.card-actions -->
  </div>
  <app-colonnade-client-document-stat-search
    #searchComponent
    (onSearch)="loadList()"
    [searchScope]="ColonnadeClientDocumentStatSearchSearchScope.ENROLLER_SUMMARY"
    [currentUserId]="currentUserId"
    [userRequired]="false"
    [sponsorCodeRequired]="false"
    [branchCodeRequired]="false"
    [dateFromRequired]="true"
    [dateToRequired]="true"
    [rightModel]="rightModel">
  </app-colonnade-client-document-stat-search>
  <div *ngIf="!searchComponent.didSearch" class="text-center">
    <p class="font-weight-bold text-muted font-xl">{{ 'COLONNADE_CLIENT_DOCUMENT_STAT_NO_DATA_SEARCH_REQUIRED' | translate }}</p>
  </div>
  <div *ngIf="searchComponent.didSearch && enrollerSummaries.length === 0" class="text-center">
    <p class="font-weight-bold text-muted font-xl">{{ 'COLONNADE_CLIENT_DOCUMENT_STAT_NO_DATA' | translate }}</p>
  </div>
  <ng-container *ngIf="searchComponent.didSearch && enrollerSummaries.length !== 0">
    <div class="card-body">
      <table class="table table-striped table-bordered">
        <thead>
        <tr>
          <th class="table-sorter-header">
            <app-table-sorter-no-op [text]="'COLONNADE_CLIENT_DOCUMENT_STAT_SEARCH_USER' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header hidden-md-down">
            <app-table-sorter-no-op [text]="'COLONNADE_CLIENT_DOCUMENT_STAT_SEEN_COUNT' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header hidden-md-down">
            <app-table-sorter-no-op [text]="'COLONNADE_CLIENT_DOCUMENT_STAT_PRESENTED_COUNT' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header">
            <app-table-sorter-no-op [text]="'COLONNADE_CLIENT_DOCUMENT_STAT_CUSTOMER_COUNT' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header">
            <app-table-sorter-no-op [text]="'COLONNADE_CLIENT_DOCUMENT_STAT_SUM_PRICE' | translate"></app-table-sorter-no-op>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr class="table"
            *ngFor="let enrollerSummary of enrollerSummaries">
          <td class="responsive-table-column">{{enrollerSummary.user.personName}}</td>
          <td class="responsive-table-column hidden-md-down">{{enrollerSummary.seenCount}}</td>
          <td class="responsive-table-column hidden-md-down">{{enrollerSummary.presentedCount}}</td>
          <td class="responsive-table-column">{{enrollerSummary.customerCount}}</td>
          <td class="responsive-table-column">{{enrollerSummary.sumPrice | formattedNumber}} Ft</td>
        </tr>
        </tbody>
        <tfoot>
        <tr>
          <th class="table-sorter-header">
            <app-table-sorter-no-op [text]="'COLONNADE_CLIENT_DOCUMENT_STAT_SEARCH_USER' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header hidden-md-down">
            <app-table-sorter-no-op [text]="'COLONNADE_CLIENT_DOCUMENT_STAT_SEEN_COUNT' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header hidden-md-down">
            <app-table-sorter-no-op [text]="'COLONNADE_CLIENT_DOCUMENT_STAT_PRESENTED_COUNT' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header">
            <app-table-sorter-no-op [text]="'COLONNADE_CLIENT_DOCUMENT_STAT_CUSTOMER_COUNT' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header">
            <app-table-sorter-no-op [text]="'COLONNADE_CLIENT_DOCUMENT_STAT_SUM_PRICE' | translate"></app-table-sorter-no-op>
          </th>
        </tr>
        </tfoot>
      </table>
    </div>
    <div class="card-body">
      <ng-container *ngIf="enrollerCustomerChartData">
        <h5>{{'COLONNADE_CLIENT_DOCUMENT_STAT_ENROLLER_CUSTOMER_CHART' | translate}}</h5>
        <google-chart *ngIf="enrollerCustomerChartData" #enrollerCustomerChart [data]="enrollerCustomerChartData"></google-chart>
      </ng-container>

      <ng-container *ngIf="enrollerInsuranceChartData">
        <h5>{{'COLONNADE_CLIENT_DOCUMENT_STAT_ENROLLER_INSURANCE_CHART' | translate}}</h5>
        <google-chart *ngIf="enrollerInsuranceChartData" #enrollerInsuranceChart [data]="enrollerInsuranceChartData"></google-chart>
      </ng-container>

      <ng-container *ngIf="enrollerSumPriceChartData">
        <h5>{{'COLONNADE_CLIENT_DOCUMENT_STAT_ENROLLER_SUM_PRICE_CHART' | translate}}</h5>
        <google-chart *ngIf="enrollerSumPriceChartData" #enrollerSumPriceChart [data]="enrollerSumPriceChartData"></google-chart>
      </ng-container>
    </div>
  </ng-container>
</div>
