<div class="row m-0">
  <div class="col-md p-0">
    <div class="title-container">
      <label class="m-0">{{unselectedTreeTitleStringKey | translate}}</label>
    </div>
    <div class="row m-0 d-flex align-items-center two-way-multiselect-search-container">
      <i class='icomoon icomoon-search two-way-multiselect-search-icon'></i>
      <div class="col p-0">
        <input class="form-control two-way-multiselect-search-field" type="text"
               placeholder="{{'COMMON_PLACEHOLDER_SEARCH' | translate}}"
               [(ngModel)]="unselectedSearch" (ngModelChange)="searchUnselected()" name="search">
      </div>
    </div>
    <div class="select-all-container">
      <mat-checkbox
        [disabled]="unselectedTree.leafCount === 0"
        [checked]="unselectedTree.leafCount > 0 && unselectedTree.selectedLeafCount === unselectedTree.leafCount"
        [indeterminate]="unselectedTree.selectedLeafCount < unselectedTree.leafCount && unselectedTree.selectedLeafCount > 0"
        (click)="toggleAllSelectUnselected()">
        {{'TWO_WAY_TREE_MULTISELECT_SELECT_ALL' | translate: {selectedCount: unselectedTree.selectedLeafCount, count: unselectedTree.leafCount} }}
      </mat-checkbox>
    </div>
    <div class="two-way-multiselect-container">
      <div class="root">
        <app-tree-node-component
          *ngFor="let node of unselectedTree.data"
          [node]="node">
        </app-tree-node-component>
      </div>
    </div>
  </div>
  <div class="col-md-1 p-0 d-flex justify-content-center align-items-center">
    <div>
      <button id="setItem" type="button" class="btn btn-primary-green-gray two-way-multiselect-selector-button"
              (click)="addSelected()"><i class='icomoon icomoon-chevron-right'></i></button>
      <br>
      <button id="removeItem" type="button" class="btn btn-primary-green-gray two-way-multiselect-selector-button"
              (click)="removeSelected()"><i class='icomoon icomoon-chevron-left'></i></button>
    </div>
  </div>
  <div class="col-md p-0">
    <div class="title-container">
      <label class="m-0">{{selectedTreeTitleStringKey | translate}}</label>
    </div>
    <div class="row m-0 d-flex align-items-center two-way-multiselect-search-container">
      <i class='icomoon icomoon-search two-way-multiselect-search-icon'></i>
      <div class="col p-0">
        <input class="form-control two-way-multiselect-search-field" type="text"
               placeholder="{{'COMMON_PLACEHOLDER_SEARCH' | translate}}"
               [(ngModel)]="selectedSearch" (ngModelChange)="searchSelected()" name="selectedSearch">
      </div>
    </div>
    <div class="select-all-container">
      <mat-checkbox
        [disabled]="selectedTree.leafCount === 0"
        [checked]="selectedTree.leafCount > 0 && selectedTree.selectedLeafCount === selectedTree.leafCount"
        [indeterminate]="selectedTree.selectedLeafCount < selectedTree.leafCount && selectedTree.selectedLeafCount > 0"
        (click)="toggleAllSelectSelected()">
        {{'TWO_WAY_TREE_MULTISELECT_SELECT_ALL' | translate: {selectedCount: selectedTree.selectedLeafCount, count: selectedTree.leafCount} }}
      </mat-checkbox>
    </div>
    <div class="two-way-multiselect-container">
      <div class="root">
        <app-tree-node-component
          *ngFor="let node of selectedTree.data"
          [node]="node">
        </app-tree-node-component>
      </div>
    </div>
  </div>
</div>
