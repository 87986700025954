<div bsModal [config]="UiConstants.modalConfig" #fieldDialog="bs-modal" (onHide)="onModalHide($event)"
     class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content" *ngIf="visible">
      <div class="modal-header">
        <h4 class="modal-title">{{dialogTitleDictionaryKey | translate}}</h4>
        <button type="button" class="close" (click)="closeDialog()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="card-body p-0">

          <div class="row">

            <!-- region Title -->
            <div class="form-group col-md-12"
                 [ngClass]="{ 'has-danger': hasFieldError(Form.FieldValidatedField.TITLE) || hasLocalFieldError(title)}">
              <div class="row">
                <label class="col-md-4">{{'COMMON_NAME' | translate}}</label>
                <div class="col-md-8 m-0 p-0">
                  <input type="text" class="form-control" placeholder="{{'COMMON_NAME' | translate}}"
                         maxlength="{{UiConstants.maximumVarcharLength}}"
                         [ngClass]="{ 'form-control-danger': hasFieldError(Form.FieldValidatedField.TITLE) || hasLocalFieldError(title) }"
                         [(ngModel)]="fieldModel.title"
                         (ngModelChange)="removeFieldError(Form.FieldValidatedField.TITLE)"
                         (blur)="onTitleBlur()"
                         name="title" id="title" #title="ngModel" required>
                  <div class="form-control-feedback" *ngIf="hasFieldError(Form.FieldValidatedField.TITLE)">
                    {{getFieldErrorText(Form.FieldValidatedField.TITLE)}}
                  </div>
                  <div class="form-control-feedback" *ngIf="hasLocalFieldError(title)">
                    {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
                  </div>
                </div>
              </div>
            </div>
            <!-- endregion Title -->

            <!-- region Description -->
            <div class="form-group col-md-12">
              <div class="row">
                <label class="col-md-4">{{'COMMON_DESCRIPTION' | translate}}</label>
                <input type="text" class="form-control col-md-8" [(ngModel)]="fieldModel.description"
                       maxlength="{{UiConstants.maximumVarcharLength}}"
                       #description="ngModel"
                       placeholder="{{'COMMON_DESCRIPTION' | translate}}"
                       name="description" id="description">
              </div>
            </div>
            <!-- endregion Description -->

          </div> <!-- ./row end -->

          <div class="row">

            <!-- region API export name -->
            <div class="form-group col-md-12"
                 [ngClass]="{ 'has-danger': hasFieldError(Form.FieldValidatedField.API_EXPORT_NAME) || hasLocalFieldError(api_export_name) }">
              <div class="row">
                <label class="col-md-4">{{'FORM_ITEM_COMMON_EXPORT_NAME' | translate}}</label>
                <div class="col-md-8 m-0 p-0">
                  <input type="text" class="form-control" [(ngModel)]="fieldModel.apiExportName"
                         maxlength="{{UiConstants.maximumVarcharLength}}"
                         #api_export_name="ngModel"
                         [ngClass]="{ 'form-control-danger': hasFieldError(Form.FieldValidatedField.API_EXPORT_NAME) || hasLocalFieldError(api_export_name) }"
                         (ngModelChange)="removeFieldError(Form.FieldValidatedField.API_EXPORT_NAME)"
                         placeholder="{{'FORM_ITEM_COMMON_EXPORT_NAME' | translate}}"
                         name="api_export_name" id="api_export_name"
                         required>
                  <div class="form-control-feedback" *ngIf="hasFieldError(Form.FieldValidatedField.API_EXPORT_NAME)">
                    {{getFieldErrorText(Form.FieldValidatedField.API_EXPORT_NAME)}}
                  </div>
                  <div class="form-control-feedback" *ngIf="hasLocalFieldError(api_export_name)">
                    {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
                  </div>
                </div>
              </div>
            </div>
            <!-- endregion API export name -->

          </div> <!-- ./row end -->

          <div class="row">

            <!-- region PDF export name -->
            <div class="form-group col-md-12"
                 [ngClass]="{ 'has-danger': hasFieldError(Form.FieldValidatedField.PDF_EXPORT_NAME) }">
              <div class="row">
                <label class="col-md-4">{{'FORM_ITEM_COMMON_PDF_EXPORT_NAME' | translate}}</label>
                <input type="text" class="form-control col-md-8" [(ngModel)]="fieldModel.pdfExportName"
                       maxlength="{{UiConstants.maximumVarcharLength}}"
                       #pdfExportName="ngModel" name="pdfExportName" id="pdfExportName"
                       (ngModelChange)="removeFieldError(Form.FieldValidatedField.PDF_EXPORT_NAME)"
                       placeholder="{{'FORM_ITEM_COMMON_PDF_EXPORT_NAME' | translate}}"
                       [ngClass]="{ 'form-control-danger': hasFieldError(Form.FieldValidatedField.PDF_EXPORT_NAME) }">
                <div class="form-control-feedback" *ngIf="hasFieldError(Form.FieldValidatedField.PDF_EXPORT_NAME)">
                  {{getFieldErrorText(Form.FieldValidatedField.PDF_EXPORT_NAME)}}
                </div>
              </div>
            </div>
            <!-- endregion PDF export name -->

          </div> <!-- ./row end -->

          <div class="row">

            <!-- region field width -->
            <div class="form-group col-md-12">
              <div class="row">
                <label class="col-md-4">{{'FORM_ITEM_COMMON_FIELD_WIDTH' | translate}}</label>
                <select class="form-control col-md-8" [(ngModel)]="fieldModel.fieldWidthType"
                        id="fieldWidth" name="fieldWidth" #fieldWidth="ngModel">
                  <option *ngFor="let widthType of fieldWidthTypes" [ngValue]="widthType">{{widthType.textDictionaryKey
                    | translate}}
                  </option>
                </select>
              </div>
            </div>
            <!-- endregion field width -->

          </div> <!-- ./row end -->

          <div class="row">

            <!-- region default value -->
            <div class="form-group col-md-12"
                 [ngClass]="{ 'has-danger': hasFieldError(Form.FieldValidatedField.DEFAULT_VALUE) || hasLocalFieldError(defaultValue) }">
              <div class="row">
                <label class="col-md-4">{{'FORM_ITEM_COMMON_DEFAULT_VALUE' | translate}}</label>
                <div class="col-md-8 m-0 p-0">
                <textarea rows="3" type="text" class="form-control"
                          [ngClass]="{ 'form-control-danger': hasFieldError(Form.FieldValidatedField.DEFAULT_VALUE) || hasLocalFieldError(defaultValue) }"
                          [(ngModel)]="fieldModel.defaultValue" #defaultValue="ngModel" name="defaultValue"
                          id="defaultValue"
                          (ngModelChange)="removeFieldError(Form.FieldValidatedField.DEFAULT_VALUE)"
                          placeholder="{{'FORM_ITEM_COMMON_DEFAULT_VALUE' | translate}}" required></textarea>
                  <div class="form-control-feedback" *ngIf="hasFieldError(Form.FieldValidatedField.DEFAULT_VALUE)">
                    {{getFieldErrorText(Form.FieldValidatedField.DEFAULT_VALUE)}}
                  </div>
                  <div class="form-control-feedback" *ngIf="hasLocalFieldError(defaultValue)">
                    {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
                  </div>
                </div>
              </div>
            </div>
            <!-- endregion default value -->

          </div>

          <div class="row">

            <!-- region display on new row -->
            <div class="form-group col-md-12">
              <div class="row">
                <label class="col-md-4">{{'FORM_ITEM_COMMON_DISPLAY_ON_NEW_ROW' | translate}}</label>
                <br>
                <label class="switch switch-text switch-info mb-0">
                  <input type="checkbox" class="switch-input" [(ngModel)]="fieldModel.displayOnNewRow"
                         #displayOnNewRow="ngModel" id="displayOnNewRow" name="displayOnNewRow">
                  <span class="switch-label" data-on="&#xe957;" data-off="&#xe98e;"></span>
                  <span class="switch-handle"></span>
                </label>
              </div>
            </div>
            <!-- endregion display on new row -->

          </div> <!-- ./row end -->

          <div class="row">
            <!-- region show on receipt -->
            <div class="form-group col-md-12">
              <div class="row">
                <label class="col-md-4">{{'FORM_ITEM_COMMON_SHOW_ON_RECEIPT' | translate}}
                  <i class="icomoon icomoon-info info-icon" popover="{{'FORM_EDIT_SHOW_ON_RECEIPT_INFO' | translate}}"
                     triggers="mouseenter:mouseleave" container="body" placement="right"></i></label>
                <br>
                <label class="switch switch-text switch-info mb-0">
                  <input type="checkbox" class="switch-input" [(ngModel)]="fieldModel.showOnReceipt"
                         #showOnReceipt="ngModel" id="showOnReceipt" name="showOnReceipt">
                  <span class="switch-label" data-on="&#xe957;" data-off="&#xe98e;"></span>
                  <span class="switch-handle"></span>
                </label>
              </div>
            </div>
            <!-- endregion show on receipt -->
          </div>
          <!-- /.row -->

        </div> <!-- ./card-body end -->
      </div>

      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="closeDialog()">{{'COMMON_BUTTON_CANCEL' | translate}}
        </button>
        <button type="button" class="btn btn-primary" #functionButton (click)="saveField()">{{'COMMON_BUTTON_SAVE' |
          translate}}
        </button>
      </div>

    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div><!-- /.modal -->
