/* eslint-disable */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { OptionItem, QueryFieldModel, SelectUtils, UiConstants } from '../../../util/core-utils';
import { OrderType, QueryResult } from '../../../lib/util/services';
import { RightModel } from '../../../app.rights';
import { BreadcrumbParent } from '../../../shared/breadcrumb/breadcrumb/breadcrumb.component';
import { InputMask } from '../../../util/input-masks';
import { StateName } from '../../../app.state-names';
import { RightResolver, RightService } from '../../../lib/right.service';
import { Set } from 'immutable';
import { TranslateService } from '@ngx-translate/core';
import { StockChange, StockChangeService } from '../../../lib/stock/stock-change.service';
import { OffsetDateTime } from '../../../lib/util/dates';
import { IdentityArray } from '../../../lib/util/messages';
import { UserData, UserDataLoader } from '../../../lib/user-data-loader';
import { Arrays } from '../../../lib/util/arrays';
import { combineLatest, Observable } from 'rxjs';
import { Angular2Multiselects } from '../../../util/multiselect';
import { StringKey } from '../../../app.string-keys';
import { TranslateUtils } from '../../../util/translate';
import { StockChangeSearch, StockChangeSearchService } from '../../../lib/stock-change-search.service';
import { Strings } from '../../../lib/util/strings';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Models } from '../../../util/model-utils';
import { NgbDatePickerParserFormatter } from '../../../util/ngb-datepicker';
import StockChangeType = StockChange.StockChangeType;
/* eslint-enable */

@Component({
  selector: 'app-stock-change-list',
  templateUrl: './stock-change-list.component.html',
  styleUrls: ['./stock-change-list.component.scss']
})
export class StockChangeListComponent implements OnInit, OnDestroy {

  StockChange = StockChange;
  UiConstants = UiConstants;

  queryModel: QueryFieldModel<StockChange.OrderField> = new QueryFieldModel(StockChange.OrderField.ID, OrderType.DESC);
  stockChangeList: Array<StockChangeListModel> = [];
  showSearch: boolean = false;
  searchResult: StockChangeSearch.SearchDataResult;
  searchModel: StockChangeSearchModel = new StockChangeSearchModel();
  dropdownSettings: Angular2Multiselects.Settings;
  rightModel: RightModel = RightModel.empty();
  breadcrumbParents: BreadcrumbParent[] = [];
  breadcrumbSelf: string;
  compactSidebar: boolean = document.querySelector('body')!.classList.contains('sidebar-compact');
  InputMask = InputMask;
  SelectUtils = SelectUtils;

  stockChangeTypes: StockChangeTypeItem[] = [];
  userList: UserItem[] = [];

  defaultItem = {
    id: null,
    text: '',
    key: 'COMMON_VALUE_UNSELECTED',
    iconClass: ''
  };

  ngOnInit() {
    this.translateService.get('MENU_NAVBAR_MENU_STOCK_CHANGE').subscribe(
      (result: string) => {
        this.breadcrumbSelf = result;
      }
    );
    this.translateService.get('MENU_NAVBAR_MENU_ADMINISTRATION').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.ADMIN_DASHBOARD});
      }
    );
    this.queryModel = new QueryFieldModel(StockChange.OrderField.ID, OrderType.DESC);
    this.loadRightModels();
    this.loadSearch(() => {
      this.showSearch = !this.searchModel.isEmpty();
      this.loadStockChangeTypes(() => {
        this.createCombinedObservable().subscribe(
          (result: CombinedResult) => {
            this.userList = [];
            Arrays.iterateByIndex(result.users, (user) => {
              const item = new UserItem();
              item.id = user.id;
              item.name = user.person_name + ' (' + user.user_name + ')';
              this.userList.push(item);
              if (this.searchResult.searchData.selectedUserId === item.id) {
                this.searchModel.selectedUser.push(item);
              }
            });
            this.initDropdownSettings();
            result.list.items.forEach((item) => {
              if (item) {
                const stockChangeItem = {
                  id: item.id,
                  trxId: item.trxId,
                  stockChangeType: this.getStockChangeType(item.changeType),
                  assigneeName: this.getAssigneeName(item.assigneeUserId),
                  changeTime: item.changeTime
                };
                this.stockChangeList.push(stockChangeItem);
              }
            });
            this.queryModel.currentPage = 1;
            this.queryModel.totalNumberOfItems = result.list.pagingResult.totalNumberOfItems;
            this.queryModel.currentNumberOfItems = result.list.pagingResult.currentNumberOfItems;
          },
          () => {
          },
          () => {
          }
        );
      });
    });
  }

  private loadRightModels() {
    this.rightService.getRightResolver().subscribe(
      (resolver: RightResolver) => {
        this.rightModel = RightModel.of(resolver);
      }
    );
  }

  private loadSearch(completion: () => void) {
    const obs: Observable<SearchLoadResult> = combineLatest(
      this.stockChangeSearchService.getSearchData({}),
      (storedSearchData: StockChangeSearch.SearchDataResult) => {
        const result: SearchLoadResult = {
          storedSearchData: storedSearchData
        };
        return result;
      }
    );
    obs.subscribe(
      (result: SearchLoadResult) => {
        this.searchResult = result.storedSearchData;
        this.postInitSearch(result.storedSearchData, completion);
      }
    );
  }

  private postInitSearch(storedSearchData: StockChangeSearch.SearchDataResult, completion: () => void) {
    this.queryModel.itemsPerPage = storedSearchData.searchData.itemsPerPage;
    this.queryModel.currentPage = storedSearchData.searchData.pageNumber;
    this.queryModel.setOrder(storedSearchData.searchData.order);
    this.searchModel.trxId = storedSearchData.searchData.trxId;
    this.searchModel.selectedStockChangeType = storedSearchData.searchData.stockChangeType;
    this.searchModel.startDate = Models.localDateToNgbDate(storedSearchData.searchData.startDate);
    this.searchModel.endDate = Models.localDateToNgbDate(storedSearchData.searchData.endDate);
    completion();
  }

  pageChanged(selectedPage: number) {
    this.loadList(selectedPage);
  }

  itemsPerPageChanged(itemsPerPage: number) {
    this.queryModel.itemsPerPage = itemsPerPage;
    this.loadList(1);
  }

  orderBy(field: StockChange.OrderField) {
    this.queryModel.onOrderFieldChanged(field);
    this.loadList(1);
  }

  toggleSearch() {
    this.showSearch = !this.showSearch;
  }

  loadStockChangeTypes(completion: () => void) {
    const keys: string[] = [];
    this.stockChangeTypes.push(this.defaultItem);
    keys.push(this.defaultItem.key);
    StockChange.stockChangeTypes.forEach((type) => {
      this.stockChangeTypes.push({id: type.type, text: '', key: type.stringKey, iconClass: type.iconClass});
      keys.push(type.stringKey);
    });
    this.translateService.get(keys).subscribe((texts) => {
      this.stockChangeTypes.forEach((type) => {
        type.text = texts[type.key];
      });
      completion();
    });
  }

  private loadList(pageNumber?: number) {
    const requestedPage = pageNumber ? pageNumber : this.queryModel.currentPage;
    const order = this.queryModel.getOrder();
    const trxId = Strings.undefinedOrNonEmpty(this.searchModel.trxId);
    const assigneeUserId = this.searchModel.selectedUser[0] ? this.searchModel.selectedUser[0].id : undefined;
    const changeType = this.searchModel.selectedStockChangeType ?
      this.searchModel.selectedStockChangeType.id !== null ?
        this.searchModel.selectedStockChangeType.id : undefined
      : undefined;
    const parsedStartDate = this.datePickerParserFormatter.toOffsetDateTime(this.searchModel.startDate);
    const parsedEndDate = this.datePickerParserFormatter.toOffsetDateTime(this.searchModel.endDate);
    this.stockChangeService.query({
      orders: Set.of(order),
      paging: {
        pageNumber: requestedPage,
        numberOfItems: this.queryModel.itemsPerPage
      },
      trxId: trxId,
      changeType: changeType,
      assigneeUserId: assigneeUserId,
      changeTimeFrom: Strings.undefinedOrNonEmpty(parsedStartDate.startOfDay().toUtcIsoString()),
      changeTimeTo: Strings.undefinedOrNonEmpty(parsedEndDate.endOfDay().toUtcIsoString()),
    }).subscribe(
      (result: QueryResult<StockChange.StockChange>) => {
        this.stockChangeList = [];
        result.items.forEach((item) => {
          if (item) {
            const stockChangeItem = {
              id: item.id,
              trxId: item.trxId,
              stockChangeType: this.getStockChangeType(item.changeType),
              assigneeName: this.getAssigneeName(item.assigneeUserId),
              changeTime: item.changeTime
            };
            this.stockChangeList.push(stockChangeItem);
          }
        });
        this.queryModel.currentPage = requestedPage;
        this.queryModel.totalNumberOfItems = result.pagingResult.totalNumberOfItems;
        this.queryModel.currentNumberOfItems = result.pagingResult.currentNumberOfItems;
      }
    );
  }

  private createCombinedObservable(): Observable<CombinedResult> {
    const order = this.queryModel.getOrder();
    return combineLatest(
      this.stockChangeService.query({
        orders: Set.of(order),
        paging: {
          pageNumber: 1,
          numberOfItems: this.queryModel.itemsPerPage
        }
      }),
      this.userService.loadAll(),
      (
        list: QueryResult<StockChange.StockChange>,
        users: IdentityArray<UserData>) => {
        return {
          list: list,
          users: users
        }
      }
    );
  }

  initDropdownSettings() {
    this.dropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(true)
      .enableSearchFilter(true)
      .enableCheckAll(false)
      .labelKey('name')
      .build();
  }

  onSearchClicked() {
    this.loadList(1);
  }

  onSearchReset() {
    this.stockChangeSearchService.resetSearchData({}).subscribe(
      (result) => {
        this.loadSearch(() => {
          this.searchModel.selectedStockChangeType = this.defaultItem;
          this.searchModel.selectedUser = [];
          this.showSearch = true;
          this.loadList(1);
        });
      }
    );
  }

  private saveSearch() {
    const request = {
      searchData: {
        itemsPerPage: this.queryModel.itemsPerPage,
        pageNumber: this.queryModel.currentPage,
        order: this.queryModel.getOrder(),
        trxId: this.searchModel.trxId,
        stockChangeType: this.searchModel.selectedStockChangeType,
        selectedUserId: this.searchModel.selectedUser[0] ? this.searchModel.selectedUser[0].id : undefined,
        startDate: Models.ngbDateToLocalDate(this.searchModel.startDate),
        endDate: Models.ngbDateToLocalDate(this.searchModel.endDate),
      }
    };
    this.stockChangeSearchService.setSearchData(request).subscribe(
      (result) => {
      },
      (error) => {
      }
    );
  }

  getStockChangeType(stockChangeType: StockChangeType): StockChangeTypeItem {
    const stockChangeTypeItem = this.stockChangeTypes.find(item => item.id === stockChangeType);
    return stockChangeTypeItem!;
  }

  getAssigneeName(assigneeUserId: number): string {
    const assigneeName = this.userList.find((item => item.id === assigneeUserId));
    return assigneeName ? assigneeName.name : '';
  }


  ngOnDestroy() {
    this.saveSearch();
  }

  constructor(
    private rightService: RightService,
    private stockChangeService: StockChangeService,
    private translateService: TranslateService,
    private userService: UserDataLoader,
    private stockChangeSearchService: StockChangeSearchService,
    private datePickerParserFormatter: NgbDatePickerParserFormatter) {
  }

}

export class StockChangeListModel {
  id: number;
  trxId: string;
  stockChangeType: StockChangeTypeItem;
  assigneeName: string;
  changeTime: OffsetDateTime;
}

export class StockChangeTypeItem  extends OptionItem<StockChange.StockChangeType> {
  key: string = '';
  iconClass: string = '';
}

export class UserItem {
  id: number;
  name: string;
}

interface CombinedResult {
  list: QueryResult<StockChange.StockChange>;
  users: IdentityArray<UserData>;
}

export class StockChangeSearchModel {
  trxId: string;
  selectedUser: UserItem[] = [];
  selectedStockChangeType: StockChangeTypeItem | null;
  startDate: NgbDateStruct | null = null;
  endDate: NgbDateStruct | null = null;

  public isEmpty(): boolean {
    return this.trxId.length === 0
      && (this.selectedStockChangeType === null || this.selectedStockChangeType.id === null)
      && this.selectedUser.length === 0
      && this.startDate === null
      && this.endDate === null
      ;
  }
}

interface SearchLoadResult {
  storedSearchData: StockChangeSearch.SearchDataResult,
}
