<div bsModal [config]="UiConstants.modalConfig" #subTaskCreateEditDialog="bs-modal" class="modal fade" tabindex="-1" role="dialog"
     aria-labelledby="myModalLabel"
     aria-hidden="true"
     (onHide)="onModalHidden()">
  <div class="modal-dialog modal-lg" role="document" *ngIf="subTaskCreateEditDialogVisible">
    <div class="modal-content">
      <div class="modal-header">
        <p class="modal-title"><strong>{{ headingDictionaryKey | translate}}</strong></p>
        <button type="button" class="close" (click)="closeSubTaskCreateEditDialog()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <form [formGroup]='subTaskCreateEditForm' (ngSubmit)="saveSubTask()" class="modal-body border-top-1">

        <div class="form-group row"
             [ngClass]="{ 'has-danger': (subTaskCreateEditForm.controls['name'].touched &&
                                        !subTaskCreateEditForm.controls['name'].valid) ||
                                        (subTaskCreateEditFieldErrors.name)}">
          <div class="col-md-4">
            <label class="col-form-label mw-100 form-control-label">{{'COMMON_NAME'|translate}}</label>
          </div>
          <div class="col-md-8">
            <input type="text" class="form-control" placeholder="{{'COMMON_NAME' | translate}}"
                   formControlName="name"
                   [(ngModel)]="model.name"
                   (ngModelChange)="removeFieldError(subTaskCreateEditFieldErrors.name)">
            <div class="form-control-feedback"
                 *ngIf="subTaskCreateEditForm.controls['name'].touched &&
                        subTaskCreateEditForm.controls['name'].hasError('required')">
              {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
            </div>
            <div class="form-control-feedback" *ngIf="subTaskCreateEditFieldErrors.name">
              {{subTaskCreateEditFieldErrors.name.text}}
            </div>
          </div>
        </div>

        <div class="form-group row">
          <div class="col-md-4">
            <label class="col-form-label mw-100 form-control-label">{{'COMMON_TYPE'|translate}}</label>
          </div>
          <div class="col-md-8">
            <input type="text" class="form-control" placeholder="{{'COMMON_TYPE' | translate}}"
                   formControlName="type"
                   [(ngModel)]="model.type">
          </div>
        </div>

        <div class="form-group row">
          <div class="col-md-4">
            <label class="col-form-label mw-100 form-control-label">{{'COMMON_NOTE'|translate}}</label>
          </div>
          <div class="col-md-8">
            <textarea class="form-control" placeholder="{{'COMMON_NOTE' | translate}}"
                   formControlName="note"
                      [(ngModel)]="model.note"></textarea>
          </div>
        </div>

        <div class="form-group row"
             [ngClass]="{ 'has-danger': (subTaskCreateEditForm.controls['workerUser'].touched &&
                                        !subTaskCreateEditForm.controls['workerUser'].valid) ||
                                        (subTaskCreateEditFieldErrors.worker_user_id),
                          'd-none' : this.subTaskId && !TaskRecordSubTask.ValidSubTaskStatesForWorkerChange.contains(model.state)}">
          <div class="col-md-4">
            <label class="col-form-label mw-100 form-control-label">{{'TASK_LABEL_OWNER_USER'|translate}}</label>
          </div>
          <div class="col-md-8">
            <div class="mw-100">
              <angular2-multiselect
                [(ngModel)]="model.workerUser"
                [data]="users"
                [settings]="dropdownSettings"
                formControlName="workerUser"
                (ngModelChange)="removeFieldError(subTaskCreateEditFieldErrors.worker_user_id)">
              </angular2-multiselect>
              <div class="form-control-feedback" *ngIf="subTaskCreateEditForm.controls['workerUser'].touched && subTaskCreateEditForm.controls['workerUser'].hasError('required')">
                {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
              </div>
              <div class="form-control-feedback" *ngIf="subTaskCreateEditFieldErrors.worker_user_id">
                {{subTaskCreateEditFieldErrors.worker_user_id.text}}
              </div>
            </div>
          </div>
        </div>

      </form>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-primary" (click)="closeSubTaskCreateEditDialog()">
          {{'COMMON_BUTTON_CANCEL' | translate}}
        </button>
        <button type="button" class="btn btn-primary ml-1" (click)="saveSubTask()">{{'COMMON_BUTTON_SAVE' |
            translate}}
        </button>
      </div>
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div><!-- /.modal -->
