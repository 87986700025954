<div class="breadcrumb-container" [ngClass]="{ 'breadcrumb-container-compact': compactSidebar }" id="breadcrumb">
  <div class="breadcrumb-component">
    <app-breadcrumb
      [self]="breadcrumbSelf">
    </app-breadcrumb>
    <div class="breadcrumb-menu d-flex">
      <div class="breadcrumb-button-icon-container cursor-pointer"
           *ngIf="configuration && configuration.feature_flags.carriage_mode !== 'OFF'"
           (click)="generateCarriageTagPdfDocument()">
        <a class="btn-setting" title="{{'SHIPPING_DEMAND_GENERATE_CARRIAGE_TAG' | translate}}">
          <i class="icomoon icomoon-process-order-stock-outtake-check"></i>
          <span class="breadcrumb-button-text-visible">{{'SHIPPING_DEMAND_GENERATE_CARRIAGE_TAG' | translate}}</span>
        </a>
      </div>
      <div class="breadcrumb-button-icon-container cursor-pointer"
           *ngIf="isExteriorTransportEnabledByServer() && rightModel.exteriorTransportCreate.hasRight()"
           (click)="openExteriorTransportCreateDialog()">
        <a class="btn-setting" title="{{'EXTERIOR_TRANSPORT_CREATE' | translate}}">
          <i class="icomoon icomoon-exterior-transport"></i>
          <span class="breadcrumb-button-text-visible">{{'EXTERIOR_TRANSPORT_CREATE' | translate}}</span>
        </a>
      </div>
      <div *ngIf="rightModel.shipmentCreate.hasRight()"
           class="breadcrumb-button-icon-container cursor-pointer"
           (click)="openShipmentCreateDialog()">
        <a class="btn-setting" title="{{'SHIPPING_DEMAND_CREATE_SHIPMENT' | translate}}">
          <i class="icomoon icomoon-process-order-packaging"></i>
          <span class="breadcrumb-button-text-visible">{{'SHIPPING_DEMAND_CREATE_SHIPMENT' | translate}}</span>
        </a>
      </div>
      <div *ngIf="rightModel.transportCreate.hasRight()"
           class="breadcrumb-button-icon-container cursor-pointer"
           (click)="openTransportCreateDialog()">
        <a class="btn-setting" title="{{'SHIPPING_DEMAND_CREATE_TRANSPORT' | translate}}">
          <i class="icomoon icomoon-vehicles"></i>
          <span class="breadcrumb-button-text-visible">{{'SHIPPING_DEMAND_CREATE_TRANSPORT' | translate}}</span>
        </a>
      </div>
      <!-- DROPDOWN TRANSPORT START -->
      <div class="breadcrumb-button-icon-container-no-text cursor-pointer p-0"
           *ngIf="rightModel.transportUpdate.hasRight() && shippingDemandList.length > 0">
        <a class="dropdown dropdown-arrow-padding"
           dropdown title="{{'COMMON_BUTTON_ADD' | translate}}"
           href="#" onclick="return false" dropdownToggle
           data-toggle="dropdown" role="button"
           aria-haspopup="true" aria-expanded="false">
          <i class="icomoon icomoon-dropdown"></i>
          <div class="dropdown-menu dropdown-menu-right" *dropdownMenu aria-labelledby="simple-dropdown">
                <span (click)="openAddToTransportDialog()"
                      class="btn-setting dropdown-item" title="{{'SHIPPING_DEMAND_ADD_TO_TRANSPORT' | translate}}">
                  <i class="icomoon icomoon-vehicle-add"></i> {{'SHIPPING_DEMAND_ADD_TO_TRANSPORT' | translate}}
                </span>
          </div>
        </a>
      </div>
      <!-- DROPDOWN TRANSPORT END -->
      <div *ngIf="rightModel.shippingDemandCreate.hasRight() || rightModel.shippingDemandCreateSimple.hasRight()"
           class="breadcrumb-button-icon-container cursor-pointer"
           [ngClass]="{'negative-margin-right': !rightModel.shippingDemandXlsImport.hasRight()}"
           uiSref="Admin.ShippingDemandCreate">
        <a class="btn-setting" title="{{'SHIPPING_DEMAND_CREATE' | translate}}">
          <i class="icomoon icomoon-add"></i>
          <span class="breadcrumb-button-text-visible">{{'SHIPPING_DEMAND_CREATE' | translate}}</span>
        </a>
      </div>
      <!-- DROPDOWN SHIPPING DEMAND START -->
      <div class="breadcrumb-button-icon-container-no-text cursor-pointer p-0 negative-margin-right"
           *ngIf="rightModel.shippingDemandCreate.hasRight() && rightModel.shippingDemandXlsImport.hasRight()">
        <a class="dropdown dropdown-arrow-padding"
           dropdown title="{{'COMMON_BUTTON_ADD' | translate}}"
           href="#" onclick="return false" dropdownToggle
           data-toggle="dropdown" role="button"
           aria-haspopup="true" aria-expanded="false">
          <i class="icomoon icomoon-dropdown"></i>
          <div class="dropdown-menu dropdown-menu-right" *dropdownMenu aria-labelledby="simple-dropdown">
                <span (click)="openImportDialog(false)"
                      class="btn-setting dropdown-item" title="{{'SHIPPING_DEMAND_XLSX_IMPORT' | translate}}">
                  <i class="icomoon icomoon-csv-import"></i> {{'SHIPPING_DEMAND_XLSX_IMPORT' | translate}}
                </span>
            <span (click)="openImportDialog(true)"
                  class="btn-setting dropdown-item" title="{{'SHIPPING_DEMAND_UPDATE_XLSX_IMPORT' | translate}}">
                  <i class="icomoon icomoon-csv-import"></i> {{'SHIPPING_DEMAND_UPDATE_XLSX_IMPORT' | translate}}
                </span>
          </div>
        </a>
      </div>
      <!-- DROPDOWN SHIPPING DEMAND END -->
    </div>
  </div>
</div>
<div class="breadcrumb-placeholder"></div>
<div class="container-horizontal-padding container-vertical-padding animated fadeIn">
  <div class="card">
    <div class="card-header">
      {{'SHIPPING_DEMAND_SHIPPING_DEMANDS' | translate}}
      <span *ngIf="getSelectedShippingDemandCount() > 0" class="card-header-secondary-text">
        - {{ getSelectedShippingDemandCount() }} {{'SHIPPING_DEMAND_SELECTED_ITEMS_COUNT' | translate}}
      </span>
      <div class="card-actions">
        <a class="btn-setting cursor-pointer" (click)="loadList()"
           title="{{'COMMON_BUTTON_REFRESH' | translate}}"><i class="icomoon icomoon-restart"></i></a>
        <a class="btn-setting cursor-pointer" (click)="toggleSearch()"
           title="{{'COMMON_SHOW_SEARCH_HINT' | translate}}"><i class="icomoon icomoon-search"></i></a>
      </div><!-- /.card-actions -->
    </div>
    <div class="card-body border-bottom" *ngIf="showSearch">
      <responsive-tabset #searchTabs>
        <responsive-tab heading="{{'SEARCH_MODE_SIMPLE' | translate}}" (selectTab)="activeSearchTab = 'simple'" id="simple">
          <div class="row form-group mb-0">
            <div class="col-lg-3 col-md-6 col-sm-12">
              <label class="search-label">{{'SHIPPING_DEMAND_SEARCH_ID' | translate}}</label>
              <input type="text" class="form-control" placeholder="{{'SHIPPING_DEMAND_SEARCH_ID' | translate}}"
                     maxlength="{{UiConstants.maxInputLongLength}}"
                     [textMask]="{mask: InputMask.NATURAL_INTEGER, guide: false}"
                     (keyup.enter)="onSearchClicked()"
                     [(ngModel)]="searchModel.id" name="id" id="id" #id="ngModel">
            </div>
            <div class="col-lg-3 col-md-6 col-sm-12" *ngIf="isEnabledField(EditableField.TOUR_ROUTE)">
              <label class="search-label">{{'SHIPPING_DEMAND_SEARCH_TOUR_ROUTE' | translate}}</label>
              <input type="text" class="form-control" placeholder="{{'SHIPPING_DEMAND_SEARCH_TOUR_ROUTE' | translate}}"
                     maxlength="{{UiConstants.maximumVarcharLength}}"
                     (keyup.enter)="onSearchClicked()"
                     [(ngModel)]="searchModel.tourRoute" name="tourRoute" id="tourRoute" #tourRoute="ngModel">
            </div>
            <div class="col-lg-3 col-md-6 col-sm-12" *ngIf="isEnabledField(EditableField.TRANSPORTER)">
              <label class="search-label">{{'SHIPPING_DEMAND_SEARCH_TRANSPORTER_NAME' | translate}}</label>
              <input type="text" class="form-control"
                     placeholder="{{'SHIPPING_DEMAND_SEARCH_TRANSPORTER_NAME' | translate}}"
                     maxlength="{{UiConstants.maximumVarcharLength}}"
                     (keyup.enter)="onSearchClicked()"
                     [(ngModel)]="searchModel.transporterName" name="transporterName" id="transporterName"
                     #transporterName="ngModel">
            </div>
            <div class="col-lg-3 col-md-6 col-sm-12" *ngIf="isEnabledField(EditableField.DEMAND_CATEGORY)">
              <label class="search-label">{{'SHIPPING_DEMAND_SEARCH_DEMAND_CATEGORY' | translate}}</label>
              <input type="text" class="form-control"
                     placeholder="{{'SHIPPING_DEMAND_SEARCH_DEMAND_CATEGORY' | translate}}"
                     maxlength="{{UiConstants.maximumVarcharLength}}"
                     (keyup.enter)="onSearchClicked()"
                     [(ngModel)]="searchModel.demandCategory" name="demandCategory" id="demandCategory"
                     #demandCategory="ngModel">
            </div>
            <div class="col-lg-3 col-md-6 col-sm-12">
              <label class="search-label">{{'SHIPPING_DEMAND_SEARCH_SOURCE_NAME' | translate}}</label>
              <input type="text" class="form-control"
                     placeholder="{{'SHIPPING_DEMAND_SEARCH_SOURCE_NAME' | translate}}"
                     maxlength="{{UiConstants.maximumVarcharLength}}"
                     (keyup.enter)="onSearchClicked()"
                     [(ngModel)]="searchModel.sourceName" name="sourceName" id="sourceName"
                     #sourceName="ngModel">
            </div>
            <div class="col-lg-3 col-md-6 col-sm-12">
              <label class="search-label">{{'SHIPPING_DEMAND_SEARCH_SOURCE_ADDRESS' | translate}}</label>
              <input type="text" class="form-control"
                     placeholder="{{'SHIPPING_DEMAND_SEARCH_SOURCE_ADDRESS' | translate}}"
                     maxlength="{{UiConstants.maximumVarcharLength}}"
                     (keyup.enter)="onSearchClicked()"
                     [(ngModel)]="searchModel.sourceAddress" name="sourceAddress" id="sourceAddress"
                     #sourceAddress="ngModel">
            </div>
            <div class="col-lg-3 col-md-6 col-sm-12">
              <label class="search-label">{{'SHIPPING_DEMAND_SEARCH_DESTINATION_NAME' | translate}}</label>
              <input type="text" class="form-control"
                     placeholder="{{'SHIPPING_DEMAND_SEARCH_DESTINATION_NAME' | translate}}"
                     maxlength="{{UiConstants.maximumVarcharLength}}"
                     (keyup.enter)="onSearchClicked()"
                     [(ngModel)]="searchModel.destinationName" name="destinationName" id="destinationName"
                     #destinationName="ngModel">
            </div>
            <div class="col-lg-3 col-md-6 col-sm-12">
              <label class="search-label">{{'SHIPPING_DEMAND_SEARCH_DESTINATION_ADDRESS' | translate}}</label>
              <input type="text" class="form-control"
                     maxlength="{{UiConstants.maximumVarcharLength}}"
                     (keyup.enter)="onSearchClicked()"
                     placeholder="{{'SHIPPING_DEMAND_SEARCH_DESTINATION_ADDRESS' | translate}}"
                     [(ngModel)]="searchModel.destinationAddress" name="destinationAddress" id="destinationAddress"
                     #destinationAddress="ngModel">
            </div>
            <div class="col-lg-3 col-md-6 col-sm-12">
              <label class="search-label">{{'SHIPPING_ITEM_SEARCH_FIELD' | translate}}</label>
              <input type="text" class="form-control"
                     maxlength="{{UiConstants.maximumVarcharLength}}"
                     (keyup.enter)="onSearchClicked()"
                     placeholder="{{'SHIPPING_ITEM_SEARCH_FIELD' | translate}}"
                     [(ngModel)]="searchModel.shippingItem">
            </div>
            <div class="col-lg-3 col-md-6 col-sm-12" *ngIf="isEnabledField(EditableField.DEADLINE)">
              <label class="search-label">{{'SHIPPING_DEMAND_SEARCH_DEADLINE_FROM' | translate}}</label>
              <div class="input-group">
                <input type="text" class="form-control date"
                       placeholder="{{'SHIPPING_DEMAND_SEARCH_DEADLINE_FROM' | translate}}"
                       [(ngModel)]="searchModel.deadlineFrom" ngbDatepicker name="deadlineFrom" id="deadlineFrom"
                       #deadlineFrom="ngbDatepicker">
                <div class="input-group-addon-gray" (click)="deadlineFrom.toggle()">
                  <i class="icomoon icomoon-calendar"></i>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-12" *ngIf="isEnabledField(EditableField.DEADLINE)">
              <label class="search-label">{{'SHIPPING_DEMAND_SEARCH_DEADLINE_TO' | translate}}</label>
              <div class="input-group">
                <input type="text" class="form-control date"
                       placeholder="{{'SHIPPING_DEMAND_SEARCH_DEADLINE_TO' | translate}}"
                       [(ngModel)]="searchModel.deadlineTo" ngbDatepicker name="deadlineTo"
                       #deadlineTo="ngbDatepicker"
                       id="deadlineTo">
                <div class="input-group-addon-gray" (click)="deadlineTo.toggle()">
                  <i class="icomoon icomoon-calendar"></i>
                </div>
              </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-12">
              <label class="search-label">{{'COMMON_STATUS' | translate}}</label>
              <angular2-multiselect [data]="statesForSearch" [settings]="dropdownSettingsForState"
                                    class="form-control"
                                    [(ngModel)]="searchModel.stateIds"
                                    #state="ngModel" name="state" id="state">
              </angular2-multiselect>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-12 d-flex align-items-end"
                 *ngIf="isEnabledField(EditableField.SAFETY_SHIPPING)">
              <div class="row">
                <app-animated-checkbox class="search-checkbox"
                                       [(selected)]="searchModel.safetyShipping">
                </app-animated-checkbox>
                <label class="search-label">{{'SHIPPING_DEMAND_SEARCH_SAFETY_SHIPPING' | translate}}</label>
              </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-12 d-flex align-items-end">
              <div class="row">
                <app-animated-checkbox class="search-checkbox"
                                       [(selected)]="searchModel.hasTransport">
                </app-animated-checkbox>
                <label class="search-label">{{'SHIPPING_DEMAND_SEARCH_HAS_TRANSPORT' | translate}}</label>
              </div>
            </div>

          </div>
          <div class="col-md-12 d-flex justify-content-md-end align-items-end px-0">
            <div class="btn-group" role="group">
              <button type="button" (click)="onSearchReset()" class="btn btn-outline-primary search-button mr-1">
                {{'COMMON_BUTTON_SEARCH_DELETE' | translate}}
              </button>
              <button type="button" (click)="onSearchClicked()" class="btn btn-primary search-button">
                {{'COMMON_BUTTON_SEARCH' | translate}}
              </button>
            </div>
          </div>
        </responsive-tab>
        <responsive-tab heading="{{'SEARCH_MODE_DQL' | translate}}" (selectTab)="activeSearchTab = 'dql'" id="dql">
          <app-dql-search-container #dqlSearchContainer
                                    (onSearch)="onDqlSearchClicked()"
                                    [dqlStoredQueryArgs]="dqlStoredArgs"
                                    [rightModel]="rightModel"
                                    [dialogContainer]="dqlDialogHolder"></app-dql-search-container>
        </responsive-tab>
      </responsive-tabset>
    </div><!-- /.card-body-->

    <div class="card-body">
      <table class="table table-striped table-bordered">
        <thead>
        <tr>
          <th class="table-sorter-header fit">
            <app-table-field-sorter [orderField]="ShippingDemand.OrderField.DEMAND_ID"
                                    [orderType]="queryModel.getOrderType(ShippingDemand.OrderField.DEMAND_ID)"
                                    (orderChange)="orderBy($event)"
                                    [checkAllEnabled]="true"
                                    [checkAllSelected]="eachShippingDemandSelected"
                                    (onCheckAllClicked)="toggleEachShippingDemand()"
                                    [text]="'COMMON_TABLE_HEADER_ID' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-lg-down" *ngIf="isEnabledField(EditableField.TOUR_ROUTE)">
            <app-table-field-sorter [orderField]="ShippingDemand.OrderField.TOUR_ROUTE"
                                    [orderType]="queryModel.getOrderType(ShippingDemand.OrderField.TOUR_ROUTE)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'SHIPPING_DEMAND_SEARCH_TOUR_ROUTE' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-xs-down">
            <app-table-field-sorter [orderField]="ShippingDemand.OrderField.SOURCE_NAME"
                                    [orderType]="queryModel.getOrderType(ShippingDemand.OrderField.SOURCE_NAME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="('SHIPPING_DEMAND_SEARCH_SOURCE_ADDRESS' | translate)
                                    + ' / '
                                    + ('SHIPPING_DEMAND_SEARCH_DEMANDER_NAME' | translate)">
            </app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-lg-down" *ngIf="isEnabledField(EditableField.TRANSPORTER)">
            <app-table-field-sorter [orderField]="ShippingDemand.OrderField.TRANSPORTER_NAME"
                                    [orderType]="queryModel.getOrderType(ShippingDemand.OrderField.TRANSPORTER_NAME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'SHIPPING_DEMAND_SEARCH_TRANSPORTER_NAME' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header">
            <app-table-field-sorter [orderField]="ShippingDemand.OrderField.DESTINATION_NAME"
                                    [orderType]="queryModel.getOrderType(ShippingDemand.OrderField.DESTINATION_NAME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'SHIPPING_DEMAND_SEARCH_DESTINATION_ADDRESS' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-md-down" *ngIf="isEnabledField(EditableField.DEADLINE)">
            <app-table-sorter-no-op
              [text]="'SHIPPING_DEMAND_LABEL_DEADLINE' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header hidden-sm-down">
            <app-table-sorter-no-op
              [text]="('COMMON_STATUS' | translate)
               + ' / '
               + ('SHIPPING_DEMAND_SEARCH_DEMAND_GROUP_SERIAL' | translate)"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header w-table-options-menu">
            <app-table-sorter-no-op></app-table-sorter-no-op>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let shippingDemand of shippingDemandList | paginate: {
                                                      itemsPerPage: queryModel.itemsPerPage,
                                                      currentPage: queryModel.currentPage,
                                                      totalItems: queryModel.currentNumberOfItems }">
          <td class="responsive-table-column fit">
            <div class="d-flex">
              <app-animated-checkbox
                class="toggle-checkbox"
                [(selected)]="shippingDemand.selected">
              </app-animated-checkbox>
              <a class="id-text cursor-pointer"
                 href="#" onclick="return false"
                 uiSref="Admin.ShippingDemandDetail"
                 [uiParams]="{ id: shippingDemand.id }"
                 title="{{'COMMON_BUTTON_DETAILS' | translate}}">
                {{shippingDemand.id}}
              </a>
            </div>
          </td>
          <td class="responsive-table-column hidden-lg-down"
              *ngIf="isEnabledField(EditableField.TOUR_ROUTE)">{{shippingDemand.tourRoute}}</td>
          <td class="responsive-table-column hidden-xs-down">
            <div class="d-flex text-truncate">
              <i class="geocode-icon icon-medium icomoon {{getGeocodeStatusIcon(shippingDemand.sourceGeocodeStatus)}}"
                 [popover]="sourceGeocodeStatus" triggers="mouseenter:mouseleave" container="body"></i>
              <div class="text-truncate">
                <span class="text-truncate">{{shippingDemand.sourceName}}</span>
                <br>
                <span class="text-truncate table-text-subtitle">{{shippingDemand.demanderName}}</span>
              </div>
            </div>
            <ng-template #sourceGeocodeStatus>
              <div class="d-flex align-items-center">
                <i class="icomoon {{getGeocodeStatusIcon(shippingDemand.sourceGeocodeStatus)}} popover-icon pr-1"></i>
                <div class="">
                  <div>{{shippingDemand.sourceAddress}}</div>
                  <div class="table-text-subtitle">
                    <span *ngIf="shippingDemand.sourceGeocodeStatus !== 'DONE'">
                      {{getGeocodeStatusNameKey(shippingDemand.sourceGeocodeStatus) | translate}}
                    </span>
                    <span *ngIf="shippingDemand.sourceGeocodeStatus !== 'DONE' &&
                          getGeocodeStatusCoordinateText(shippingDemand.sourceCoordinates).length > 0">/</span>
                    <span *ngIf="getGeocodeStatusCoordinateText(shippingDemand.sourceCoordinates).length > 0">
                      {{getGeocodeStatusCoordinateText(shippingDemand.sourceCoordinates)}}
                    </span>
                  </div>
                </div>
              </div>
            </ng-template>
          </td>
          <td class="responsive-table-column hidden-lg-down"
              *ngIf="isEnabledField(EditableField.TRANSPORTER)">
            <span class="line-clamp-2" [popover]="shippingDemand.transporterName" triggers="mouseenter:mouseleave">
              {{shippingDemand.transporterName}}
            </span>
            <br *ngIf="shippingDemand.ekaerId || shippingDemand.safetyShipping">
            <ng-template #ekaerIdPopover>
              <div class="d-flex align-items-center">
                <i class="icomoon icomoon-vehicles popover-icon pr-1"></i>
                <div>
                  <div>{{'SHIPPING_DEMAND_SHIPMENT_EKAER_ID' | translate }}</div>
                  <div class="table-text-subtitle">
                    {{ shippingDemand.ekaerId }}
                  </div>
                </div>
              </div>
            </ng-template>
            <ng-template #safetyShipping>
              <div class="d-flex align-items-center">
                <i class="icomoon icomoon-safety-shipping popover-icon pr-1"></i>
                <div>
                  <div>{{'TRANSPORT_SAFETY_SHIPPING' | translate }}</div>
                </div>
              </div>
            </ng-template>
            <span *ngIf="shippingDemand.ekaerId && isEnabledField(EditableField.EKAER_ID)"
                  class="icomoon icomoon-vehicles popover-trigger-icon icomoon-table-icon"
                  [popover]="ekaerIdPopover" triggers="mouseenter:mouseleave"></span>
            <span *ngIf="shippingDemand.safetyShipping && isEnabledField(EditableField.SAFETY_SHIPPING)"
                  class="icomoon icomoon-safety-shipping popover-trigger-icon icomoon-table-icon"
                  [popover]="safetyShipping" triggers="mouseenter:mouseleave"></span>
          </td>
          <td class="responsive-table-column long">
            <div class="d-flex">
              <i class="geocode-icon icon-medium icomoon {{getGeocodeStatusIcon(shippingDemand.destinationGeocodeStatus)}}"
                 [popover]="destinationGeocodeStatus" triggers="mouseenter:mouseleave" container="body"></i>
                <div>{{shippingDemand.destinationName}}</div>
            </div>
            <ng-template #destinationGeocodeStatus>
              <div class="d-flex align-items-center">
                <i class="icomoon {{getGeocodeStatusIcon(shippingDemand.destinationGeocodeStatus)}} popover-icon pr-1"></i>
                <div class="">
                  <div>{{shippingDemand.destinationAddress}}</div>
                  <div class="table-text-subtitle">
                    <span *ngIf="shippingDemand.destinationGeocodeStatus !== 'DONE'">
                      {{getGeocodeStatusNameKey(shippingDemand.destinationGeocodeStatus) | translate}}
                    </span>
                    <span *ngIf="shippingDemand.destinationGeocodeStatus !== 'DONE' &&
                          getGeocodeStatusCoordinateText(shippingDemand.destinationCoordinates).length > 0">/</span>
                    <span *ngIf="getGeocodeStatusCoordinateText(shippingDemand.destinationCoordinates).length > 0">
                      {{getGeocodeStatusCoordinateText(shippingDemand.destinationCoordinates)}}
                    </span>
                  </div>
                </div>
              </div>
            </ng-template>
          </td>
          <td class="responsive-table-column hidden-md-down long"
              *ngIf="isEnabledField(EditableField.DEADLINE)">
            <ng-container *ngIf="!isOverdue(shippingDemand)">
              {{shippingDemand.deadline.toUtcIsoString() | date: 'short'}}
            </ng-container>
            <ng-container *ngIf="isOverdue(shippingDemand)">
              <app-table-badge
              [popover]="overduePopover"
              triggers="mouseenter:mouseleave"
              container="body"
              [badgeStyle]="BadgeStyle.WARNING"
              [iconClass]="'icomoon-expired'"
              [text]="shippingDemand.deadline.toUtcIsoString() | date: 'short'">
              </app-table-badge>
              <ng-template #overduePopover>
                <div class="d-flex align-items-center">
                  <i class="icomoon icomoon-expired popover-icon pr-1"></i>
                  <div>
                    <div>{{'COMMON_OVERDUE' | translate }}</div>
                  </div>
                </div>
              </ng-template>
            </ng-container>
          </td>
          <td class="fit text-center hidden-sm-down align-middle">
            <app-table-badge
              [popover]="statePopover"
              triggers="mouseenter:mouseleave"
              container="body"
              [badgeStyle]="shippingDemand.stateObject.badgeStyle"
              [iconClass]="shippingDemand.stateObject.iconClass"
              [text]="shippingDemand.demandGroupSerial">
            </app-table-badge>
            <ng-template #statePopover>
              <div class="d-flex align-items-center">
                <i class="icomoon {{shippingDemand.stateObject.iconClass}} popover-icon pr-1"></i>
                <div>
                  <div>{{shippingDemand.stateObject.stringKey | translate }}</div>
                </div>
              </div>
            </ng-template>
          </td>
          <td class="align-middle">
            <app-table-options-menu>
              <app-dropdown-item
                *ngIf="rightModel.shippingDemandRead.hasRight() || rightModel.shippingDemandReadSimple.hasRight()"
                [iconClass]="'icomoon-detail'"
                [titleStringKey]="'COMMON_BUTTON_DETAILS'"
                class="cursor-pointer"
                uiSref="Admin.ShippingDemandDetail"
                [uiParams]="{ id: shippingDemand.id }">
              </app-dropdown-item>
              <app-dropdown-item
                *ngIf="(rightModel.shippingDemandUpdate.hasRight() || rightModel.shippingDemandUpdateSimple.hasRight())
                        && (shippingDemand.state === 'NEW' || shippingDemand.state === 'WAITING_FOR_CARRIAGE')"
                [iconClass]="'icomoon-modify'"
                [titleStringKey]="'COMMON_BUTTON_EDIT'"
                class="cursor-pointer"
                uiSref="Admin.ShippingDemandEdit"
                [uiParams]="{ id: shippingDemand.id }">
              </app-dropdown-item>
              <app-dropdown-item
                *ngIf="rightModel.shippingDemandCreate.hasRight() || rightModel.shippingDemandCreateSimple.hasRight()"
                [iconClass]="'icomoon-clone'"
                [titleStringKey]="'COMMON_BUTTON_CLONE'"
                class="cursor-pointer"
                uiSref="Admin.ShippingDemandClone"
                [uiParams]="{ id: shippingDemand.id }">
              </app-dropdown-item>
              <app-dropdown-item
                *ngIf="rightModel.shippingDemandCancel.hasRight()
                        && (shippingDemand.state === 'WAITING_FOR_CARRIAGE'
                        || (shippingDemand.state === 'NEW' && (!shippingDemand.carriageState || shippingDemand.carriageState === 'OPEN')))"
                [iconClass]="'icomoon-trash'"
                [titleStringKey]="'COMMON_BUTTON_DELETE'"
                class="cursor-pointer"
                (click)="openDeleteDemand(shippingDemand.id)">
              </app-dropdown-item>
              <!-- TRANSPORTS -->
              <div class="d-flex dropdown-item no-hover border-bottom-0"
                   *ngIf="rightModel.transportRead.hasRight() && shippingDemand.transports.length > 0">
                <i class="icon-standard dropdown-icon icomoon icomoon-vehicles"></i>
                <div>
                  <div class="text-nowrap small">{{'SHIPPING_DEMAND_RELATED_TRANSPORTS' | translate}}</div>
                  <div *ngFor="let transport of shippingDemand.transports" class="text-nowrap">
                    <a class="cursor-pointer"
                       uiSref="Admin.TransportDetail"
                       [uiParams]="{ id: transport.id }">
                      {{transport.waybillNumber}}
                    </a>
                  </div>
                </div>
              </div>
              <!-- SHIPMENTS -->
              <div class="d-flex dropdown-item no-hover border-bottom-0"
                   *ngIf="rightModel.shipmentGroupRead.hasRight() && shippingDemand.shipmentGroup">
                <i class="icon-standard dropdown-icon icomoon icomoon-process-order-packaging"></i>
                <div>
                  <div class="text-nowrap small">{{'SHIPPING_DEMAND_RELATED_SHIPMENTS' | translate}}</div>
                  <div *ngFor="let shipment of shippingDemand.shipmentGroup.shipments" class="text-nowrap">
                    <a class="cursor-pointer"
                       uiSref="Admin.ShipmentDetail"
                       [uiParams]="{ id: shippingDemand.shipmentGroup.id }">
                      {{shipment.deliveryNoteNumber}}
                    </a>
                  </div>
                </div>
              </div>
            </app-table-options-menu>
          </td>
        </tr>
        </tbody>
        <tfoot>
        <tr>
          <th class="table-sorter-header fit">
            <app-table-field-sorter [orderField]="ShippingDemand.OrderField.DEMAND_ID"
                                    [orderType]="queryModel.getOrderType(ShippingDemand.OrderField.DEMAND_ID)"
                                    (orderChange)="orderBy($event)"
                                    [checkAllEnabled]="true"
                                    [checkAllSelected]="eachShippingDemandSelected"
                                    (onCheckAllClicked)="toggleEachShippingDemand()"
                                    [text]="'COMMON_TABLE_HEADER_ID' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-lg-down" *ngIf="isEnabledField(EditableField.TOUR_ROUTE)">
            <app-table-field-sorter [orderField]="ShippingDemand.OrderField.TOUR_ROUTE"
                                    [orderType]="queryModel.getOrderType(ShippingDemand.OrderField.TOUR_ROUTE)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'SHIPPING_DEMAND_SEARCH_TOUR_ROUTE' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-xs-down">
            <app-table-field-sorter [orderField]="ShippingDemand.OrderField.SOURCE_NAME"
                                    [orderType]="queryModel.getOrderType(ShippingDemand.OrderField.SOURCE_NAME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="('SHIPPING_DEMAND_SEARCH_SOURCE_ADDRESS' | translate)
                                    + ' / '
                                    + ('SHIPPING_DEMAND_SEARCH_DEMANDER_NAME' | translate)">
            </app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-lg-down" *ngIf="isEnabledField(EditableField.TRANSPORTER)">
            <app-table-field-sorter [orderField]="ShippingDemand.OrderField.TRANSPORTER_NAME"
                                    [orderType]="queryModel.getOrderType(ShippingDemand.OrderField.TRANSPORTER_NAME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'SHIPPING_DEMAND_SEARCH_TRANSPORTER_NAME' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header">
            <app-table-field-sorter [orderField]="ShippingDemand.OrderField.DESTINATION_NAME"
                                    [orderType]="queryModel.getOrderType(ShippingDemand.OrderField.DESTINATION_NAME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'SHIPPING_DEMAND_SEARCH_DESTINATION_ADDRESS' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-md-down" *ngIf="isEnabledField(EditableField.DEADLINE)">
            <app-table-sorter-no-op
              [text]="'SHIPPING_DEMAND_LABEL_DEADLINE' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header hidden-sm-down">
            <app-table-sorter-no-op
              [text]="('COMMON_STATUS' | translate)
               + ' / '
               + ('SHIPPING_DEMAND_SEARCH_DEMAND_GROUP_SERIAL' | translate)"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header w-table-options-menu">
            <app-table-sorter-no-op></app-table-sorter-no-op>
          </th>
        </tr>
        </tfoot>
      </table>
      <app-table-paging
        [currentNumberOfItems]="queryModel.currentNumberOfItems"
        [totalNumberOfItems]="queryModel.totalNumberOfItems"
        [itemsPerPage]="queryModel.itemsPerPage"
        (pageChange)="pageChanged($event)"
        (itemsPerPageChange)="itemsPerPageChanged($event)">
      </app-table-paging>
    </div>
  </div>
</div>

<div bsModal [config]="UiConstants.modalConfig" #transportCreateDialog="bs-modal" class="modal fade" tabindex="-1"
     role="dialog"
     aria-labelledby="myModalLabel"
     aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document" *ngIf="transportCreateDialogVisible">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">{{'SHIPPING_DEMAND_CREATE_TRANSPORT' | translate}}</h4>
        <button type="button" class="close" (click)="closeTransportCreateDialog()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">

        <div class="form-group row">
          <label class="col-form-label col-md-4">{{'TRANSPORT_CREATE_VEHICLE' | translate}}</label>
          <div class="col-md-8">
            <angular2-multiselect [data]="vehicleList" [settings]="singleDropdownSettings"
                                  class="form-control"
                                  (onRemoteSearch)="loadVehicles($event.target.value)"
                                  [(ngModel)]="transportCreateModel.vehicle">
            </angular2-multiselect>
          </div>
          <!-- /.col-md-8 -->
        </div>

        <div class="form-group row">
          <label class="col-form-label col-md-4">{{'TRANSPORT_CREATE_DRIVER' | translate}}</label>
          <div class="col-md-8">
            <angular2-multiselect [data]="driverList" [settings]="singleDropdownSettings"
                                  class="form-control"
                                  (onRemoteSearch)="loadDrivers($event.target.value)"
                                  [(ngModel)]="transportCreateModel.driver">
            </angular2-multiselect>
          </div>
          <!-- /.col-md-8 -->
        </div>

        <div class="form-group row">
          <label class="col-form-label col-md-4">{{'TRANSPORT_CREATE_SAFETY_SHIPPING' | translate}}</label>
          <div class="col-md-8">
            <app-animated-checkbox
              [(selected)]="transportCreateModel.safetyShipping"
              [readonly]="!transportCreateModel.safetyShippingSettable">
            </app-animated-checkbox>
          </div>
          <!-- /.col-md-8 -->
        </div>

        <div class="form-group row">
          <label class="col-form-label col-md-4">{{'COMMON_COMMENT' | translate}}</label>
          <div class="col-md-8">
            <input type="text" class="form-control" placeholder="{{'COMMON_COMMENT' | translate}}"
                   [(ngModel)]="transportCreateModel.comment">
          </div>
          <!-- /.col-md-8 -->
        </div>

        <div class="alert alert-warning mb-0">
          <p class="mb-0">
            <span class="font-weight-bold">
              {{'TRANSPORT_CREATE_BY_DEMANDS_SELECTED_NUMBER' | translate: {number: transportCreateModel.demands.length} }}
            </span>
            <span>
              {{'TRANSPORT_CREATE_SELECTED_CONFIRMATION' | translate }}
            </span>
          </p>
        </div>

      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="closeTransportCreateDialog()">
          {{'COMMON_BUTTON_CANCEL' | translate}}
        </button>
        <button type="button" class="btn btn-primary" (click)="createTransport()">{{'COMMON_BUTTON_SAVE' |
            translate}}
        </button>
      </div>
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div><!-- /.modal -->

<div bsModal [config]="UiConstants.modalConfig" #addToTransportDialog="bs-modal" class="modal fade" tabindex="-1"
     role="dialog"
     aria-labelledby="myModalLabel"
     aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document" *ngIf="addToTransportDialogVisible">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">{{'SHIPPING_DEMAND_ADD_TO_TRANSPORT' | translate}}</h4>
        <button type="button" class="close" (click)="closeAddToTransportDialog()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">

        <div class="form-group row"
             [ngClass]="{ 'has-danger': addToTransportSubmitted && !transport.valid }">
          <label class="col-form-label col-md-4 required-field-label">
            {{'MENU_NAVBAR_MENU_TRANSPORT' | translate}}
          </label>
          <div class="col-md-8">
            <angular2-multiselect
              id="transport"
              name="transport"
              #transport="ngModel"
              [(ngModel)]="addToTransportModel.transport"
              (ngModelChange)="onTransportChosen()"
              (onRemoteSearch)="loadTransports($event.target.value)"
              [data]="transportList"
              [settings]="singleDropdownSettings"
              required>
            </angular2-multiselect>
            <div class="form-control-feedback" *ngIf="addToTransportSubmitted && !transport.valid">
              {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
            </div>
          </div>
          <!-- /.col-md-8 -->
        </div>

        <ng-container *ngIf="addToTransportModel.transportId">

          <div class="form-group row">
            <label class="col-form-label col-md-4">{{'TRANSPORT_CREATE_SAFETY_SHIPPING' | translate}}</label>
            <div class="col-md-8">
              <app-animated-checkbox
                [(selected)]="addToTransportModel.safetyShipping"
                [readonly]="true">
              </app-animated-checkbox>
            </div>
            <!-- /.col-md-8 -->
          </div>

          <div class="form-group row">
            <label class="col-form-label col-md-4">{{'TRANSPORT_CREATE_SECURITY_GUARD_NAME' | translate}}</label>
            <div class="col-md-8">
              <label readonly
                     class="col-form-label form-control-label detail-description">{{addToTransportModel.securityGuard}}
              </label>
            </div>
            <!-- /.col-md-8 -->
          </div>

          <div class="form-group row">
            <label class="col-form-label col-md-4">{{'COMMON_COMMENT' | translate}}</label>
            <div class="col-md-8">
              <label readonly
                     class="col-form-label form-control-label detail-description">{{addToTransportModel.comment}}
              </label>
            </div>
            <!-- /.col-md-8 -->
          </div>

        </ng-container>

        <div class="alert alert-warning mb-0">
          <p class="mb-0">
            <span class="font-weight-bold">
              {{'SHIPPING_DEMAND_ADD_DIALOG_NUMBER' | translate: {number: addToTransportModel.demands.length} }}
            </span>
            <span>
              {{'TRANSPORT_DIALOG_CONFIRMATION_ADD' | translate }}
            </span>
          </p>
        </div>

      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="closeAddToTransportDialog()">
          {{'COMMON_BUTTON_CANCEL' | translate}}
        </button>
        <button type="button" class="btn btn-primary" (click)="addToTransport()">{{'COMMON_BUTTON_SAVE' |
            translate}}
        </button>
      </div>
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div><!-- /.modal -->

<app-file-upload-dialog
  [uploadPath]="shippingDemandImportPath"
  (onResult)="onImportSuccess($event)"
  #shippingDemandImportDialog
></app-file-upload-dialog>

<div bsModal [config]="UiConstants.modalConfig" #deleteDemand="bs-modal" class="modal fade" tabindex="-1" role="dialog"
     aria-labelledby="myModalLabel"
     aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document" *ngIf="deleteDemandVisible">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">{{'SHIPPING_DEMAND_DELETE' | translate}}</h4>
        <button type="button" class="close" (click)="closeDeleteDemand()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="form-group col-md-12 mb-0">
          <label>{{'SHIPPING_DEMAND_DELETE_DEMAND_LABEL' | translate}}</label>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="closeDeleteDemand()">
          {{'COMMON_BUTTON_CANCEL' | translate}}
        </button>
        <button type="button" class="btn btn-primary" (click)="cancelShippingDemand()">{{'COMMON_BUTTON_DELETE' |
            translate}}
        </button>
      </div>
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div><!-- /.modal -->

<div bsModal [config]="UiConstants.modalConfig" #shipmentCreateDialog="bs-modal" class="modal fade" tabindex="-1"
     role="dialog"
     aria-labelledby="myModalLabel"
     aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document" *ngIf="shipmentCreateDialogVisible">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">{{'SHIPPING_DEMAND_CREATE_SHIPMENT' | translate}}</h4>
        <button type="button" class="close" (click)="closeShipmentCreateDialog()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">

        <div class="alert alert-warning mb-0">
          <p class="mb-0">
            <span class="font-weight-bold">
              {{'SHIPMENT_CREATE_SELECTED_NUMBER' | translate: {number: shipmentCreateDemands.length} }}
            </span>
            <span>
              {{'SHIPMENT_CREATE_SELECTED_CONFIRMATION' | translate }}
            </span>
          </p>
        </div>

      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="closeShipmentCreateDialog()">
          {{'COMMON_BUTTON_CANCEL' | translate}}
        </button>
        <button type="button" class="btn btn-primary" (click)="createShipment()">{{'COMMON_BUTTON_SAVE' |
            translate}}
        </button>
      </div>
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div><!-- /.modal -->

<app-dql-dialog-container #dqlDialogHolder></app-dql-dialog-container>

<div bsModal [config]="UiConstants.modalConfig" #exteriorTransportCreateDialog="bs-modal" class="modal fade" tabindex="-1" role="dialog"
     aria-labelledby="myModalLabel"
     aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document" *ngIf="exteriorTransportCreateDialogVisible">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">{{'EXTERIOR_TRANSPORT_CREATE' | translate}}</h4>
        <button type="button" class="close" (click)="closeExteriorTransportCreateDialog()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">

        <div class="alert alert-warning mb-0">
          <p class="mb-0">
            <span class="font-weight-bold">
              {{'EXTERIOR_TRANSPORT_CREATE_BY_SHIPMENTS_SELECTED_NUMBER_SHIPPING_DEMAND' | translate: {number: exteriorTransportCreateModel.demandIds.length} }}
            </span>
            <span>
              {{'EXTERIOR_TRANSPORT_CREATE_SELECTED_CONFIRMATION' | translate }}
            </span>
          </p>
        </div>

      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="closeExteriorTransportCreateDialog()">
          {{'COMMON_BUTTON_CANCEL' | translate}}
        </button>
        <button type="button" class="btn btn-primary" (click)="createExteriorTransport()">{{'COMMON_BUTTON_SAVE' |
          translate}}
        </button>
      </div>
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div><!-- /.modal -->
