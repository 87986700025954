/* eslint-disable */
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BreadcrumbParent } from '../../../shared/breadcrumb/breadcrumb/breadcrumb.component';
import { StateName } from '../../../app.state-names';
import { TranslateService } from '@ngx-translate/core';
import { Token, TokenService } from '../../../lib/token/token.service';
import { QueryFieldModel, UiConstants } from '../../../util/core-utils';
import { Set } from 'immutable';
import { OrderType } from '../../../lib/util/services';
import { combineLatest, Observable } from 'rxjs';
import { TokenSearch, TokenSearchService } from '../../../lib/token/token-search.service';
import { CustomerRecord } from '../../../lib/customer/customer-record.service';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { RightModel } from '../../../app.rights';
import { RightResolver, RightService } from '../../../lib/right.service';
import { InputMask } from '../../../util/input-masks';
import { Strings } from '../../../lib/util/strings';
import { EmptyMessage } from '../../../lib/util/messages';
import MobileApplicationItem = HistoryLog.MobileApplicationItem;
import TokenType = Token.TokenType;
import { UserItem } from '../../../lib/user.service';
import { HistoryLog } from '../../../lib/history-log/history-log.service';
/* eslint-enable */

@Component({
  selector: 'app-token-list',
  templateUrl: './token-list.component.html',
  styleUrls: ['./token-list.component.scss']
})
export class TokenListComponent implements OnInit, OnDestroy {

  Token = Token;
  UiConstants = UiConstants;
  InputMask = InputMask;

  @ViewChild('deleteModal', { static: true }) deleteModal: ModalDirective;
  deleteModalVisible: boolean = false;
  selectedDeleteItem: TokenModel;

  compactSidebar: boolean = document.querySelector('body')!.classList.contains('sidebar-compact');
  breadcrumbParents: BreadcrumbParent[] = [];
  breadcrumbSelf: string;

  queryModel: QueryFieldModel<Token.OrderField> = new QueryFieldModel(Token.OrderField.USER_PROFILE_PERSON_NAME, OrderType.DESC);
  showSearch: boolean = false;
  searchModel: TokenSearchModel = new TokenSearchModel();
  searchResult: TokenSearch.SearchDataResult;

  tokenList: TokenModel[] = [];

  rightModel: RightModel = RightModel.empty();

  tokenTypeList: TokenTypeItem[] = [];

  constructor(
    private tokenService: TokenService,
    private translateService: TranslateService,
    private tokenSearchService: TokenSearchService,
    private rightService: RightService
  ) {
  }

  ngOnInit() {
    this.initBreadcrumb();
    this.loadRightModels();
    this.loadTokenTypeList(() => {
      this.loadSearch(() => {
        this.showSearch = !this.searchModel.isEmpty();
        this.loadList();
      })
    });
  }

  initBreadcrumb() {
    this.translateService.get('MENU_NAVBAR_TOKENS').subscribe(
      (result: string) => {
        this.breadcrumbSelf = result;
      }
    );
    this.translateService.get('MENU_NAVBAR_MENU_ADMINISTRATION').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.ADMIN_DASHBOARD});
      }
    );
  }

  private loadRightModels() {
    this.rightService.getRightResolver().subscribe(
      (resolver: RightResolver) => {
        this.rightModel = RightModel.of(resolver);
      }
    );
  }

  private loadTokenTypeList(completion: () => void) {
    this.tokenTypeList = [];
    const types: TokenType[] = ['DEFAULT', 'ACCESS_TOKEN', 'MOBILE_USER_TOKEN'];
    const list: TokenTypeItem[] = [];
    types.forEach(function (type) {
      const typeItem = new TokenTypeItem();
      typeItem.id = type;
      typeItem.text = 'TOKEN_PLATFORM_TYPE_' + type;
      list.push(typeItem);
    });
    this.tokenTypeList = list;
    completion();

  }

  private loadSearch(completion: () => void) {
    const obs: Observable<SearchLoadResult> = combineLatest(
      this.tokenSearchService.getSearchData({}),
      (storedSearchData: TokenSearch.SearchDataResult) => {
        const result: SearchLoadResult = {
          storedSearchData: storedSearchData
        };
        return result;
      }
    );
    obs.subscribe(
      (result: SearchLoadResult) => {
        this.searchResult = result.storedSearchData;
        this.postInitSearch(result.storedSearchData, completion);
      }
    );
  }

  private postInitSearch(storedSearchData: TokenSearch.SearchDataResult, completion: () => void) {
    this.queryModel.itemsPerPage = storedSearchData.searchData.itemsPerPage;
    this.queryModel.currentPage = storedSearchData.searchData.pageNumber;
    this.queryModel.setOrder(storedSearchData.searchData.order);
    this.searchModel.userProfilePersonName = storedSearchData.searchData.userProfilePersonName;
    this.searchModel.mobileApplicationName = storedSearchData.searchData.mobileApplicationName;
    this.searchModel.type = storedSearchData.searchData.type;
    completion()
  }

  private saveSearch() {
    const request = {
      searchData: {
        itemsPerPage: this.queryModel.itemsPerPage,
        pageNumber: this.queryModel.currentPage,
        order: this.queryModel.getOrder(),
        userProfilePersonName: this.searchModel.userProfilePersonName,
        mobileApplicationName: this.searchModel.mobileApplicationName,
        type: this.searchModel.type,
      }
    };
    this.tokenSearchService.setSearchData(request).subscribe(
      (result) => {
      },
      (error) => {
      }
    );
  }

  toggleSearch() {
    this.showSearch = !this.showSearch;
  }

  onSearchClicked() {
    this.loadList(1);
  }

  onSearchReset() {
    this.tokenSearchService.resetSearchData({}).subscribe(
      (result) => {
        this.loadSearch(() => {
          this.showSearch = true;
          this.loadList(1);
        });
      }
    );
  }

  orderBy(field: Token.OrderField) {
    this.queryModel.onOrderFieldChanged(field);
    this.loadList(1);
  }

  loadList(pageNumber?: number) {
    const requestedPage = pageNumber ? pageNumber : this.queryModel.currentPage;
    const order = this.queryModel.getOrder();
    this.tokenService.query({
      userProfilePersonName: Strings.undefinedOrNonEmpty(this.searchModel.userProfilePersonName),
      displayName: Strings.undefinedOrNonEmpty((this.searchModel.mobileApplicationName)),
      type: this.searchModel.type === 'DEFAULT' ? undefined : this.searchModel.type,
      orders: Set.of(order),
      paging: requestedPage ? {
        pageNumber: requestedPage,
        numberOfItems: this.queryModel.itemsPerPage
      } : undefined
    }).subscribe((result: Token.Token[]) => {
      this.tokenList = [];
      result.forEach((token: Token.Token) => {
        const tokenModel = new TokenModel();
        tokenModel.accessTokenId = token.accessTokenId;
        tokenModel.userProfile = token.userProfile;
        tokenModel.mobileApplication = token.mobileApplication;
        tokenModel.mobileApplicationNameApplicationId = token.mobileApplication ?
          token.mobileApplication.name ?
            token.mobileApplication.name
            : token.mobileApplication.applicationId
          : '';
        tokenModel.createDate = token.createDate.toUtcIsoString();
        tokenModel.accessDate = token.accessDate ? token.accessDate.toUtcIsoString() : '';
        tokenModel.expiry = token.expiry.toUtcIsoString();
        tokenModel.type = 'TOKEN_PLATFORM_TYPE_' + token.type;
        this.tokenList.push(tokenModel);
      });
      this.queryModel.currentPage = requestedPage;
      this.queryModel.indeterminateNumberOfItems(requestedPage);
    });
  }

  deleteToken() {
    this.deleteModalVisible = false;
    this.deleteModal.hide();
    const model = this.selectedDeleteItem;
    if (model.accessTokenId) {
      this.tokenService.deleteWithTokenId(
        {
          accessTokenId: model.accessTokenId
        }).subscribe(
        (result: EmptyMessage) => {
        },
        (error: Error) => {
        },
        () => {
          this.loadList()
        });
    }
    else {
      if (model.mobileApplication) {
        this.tokenService.deleteWithUserMobile(
          {
            userProfileId: model.userProfile.id,
            mobileApplicationId: model.mobileApplication.id
          }).subscribe(
          (result: EmptyMessage) => {
          },
          (error: Error) => {
          },
          () => {
            this.loadList()
          });
      }
    }
  }

  pageChanged(selectedPage: number) {
    this.loadList(selectedPage);
  }

  itemsPerPageChanged(itemsPerPage: number) {
    this.queryModel.itemsPerPage = itemsPerPage;
    this.loadList(1);
  }

  ngOnDestroy() {
    this.saveSearch();
  }

  openDeleteModal(model: TokenModel) {
    this.selectedDeleteItem = model;
    this.deleteModalVisible = true;
    this.deleteModal.show();
  }

  closeDeleteModal() {
    this.deleteModalVisible = false;
    this.deleteModal.hide();
  }


}

class TokenModel {
  accessTokenId: number | undefined;
  userProfile: UserItem;
  mobileApplication?: MobileApplicationItem;
  mobileApplicationNameApplicationId: string = '';
  createDate: string;
  accessDate: string;
  expiry: string;
  type: string = '';
}

class TokenSearchModel {

  userProfilePersonName: string;
  mobileApplicationName: string;
  type: TokenType;

  public isEmpty(): boolean {

    return this.userProfilePersonName.length === 0
      && this.mobileApplicationName.length === 0
      && this.type === 'DEFAULT'
  }

  public clear() {
    this.userProfilePersonName = '';
    this.mobileApplicationName = '';

  }

  constructor() {
  }

}

interface SearchLoadResult {
  storedSearchData: TokenSearch.SearchDataResult,
}

export class TokenTypeItem {
  id: TokenType;
  text: string;
}
