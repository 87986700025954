import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TriggerUtils } from '../../../lib/trigger/trigger-utils';
import { OrderType, QueryResult } from '../../../lib/util/services';
import { Trigger } from '../../../lib/trigger/trigger.service';
import { QueryFieldModel } from '../../../util/core-utils';
import { Set } from 'immutable';
import TriggerType = TriggerUtils.TriggerType;
import { Dates, OffsetDateTime } from '../../../lib/util/dates';
import TriggerInstanceService = TriggerInstance.TriggerInstanceService;
import { TriggerInstance } from '../../../lib/trigger/trigger-instance.service';

@Component({
  selector: 'app-trigger-history',
  templateUrl: './trigger-history.component.html',
  styleUrls: ['./trigger-history.component.scss']
})
export class TriggerHistoryComponent implements OnInit {

  pagingId = 'triggerHistoryList';

  TriggerInstanceOrderField = Trigger.TriggerInstanceOrderField;

  @Input()
  triggerService: TriggerInstanceService;

  @Input()
  triggerParentId?: number;

  @Input()
  instanceParentId: number;

  @Input()
  scope: TriggerUtils.TriggerScope;

  queryModel: QueryFieldModel<Trigger.TriggerInstanceOrderField> =
    new QueryFieldModel(Trigger.TriggerInstanceOrderField.CREATION_TIME, OrderType.DESC);

  triggerHistoryList: TriggerHistoryModel[] = [];

  constructor() {
  }

  ngOnInit() {
    this.loadList();
  }

  loadList(pageNumber?: number) {
    const requestedPage = pageNumber ? pageNumber : this.queryModel.currentPage;
    this.triggerService.getTriggerInstanceHistory({
      triggerParentId: this.triggerParentId,
      instanceParentId: this.instanceParentId,
      order: Set.of(this.queryModel.getOrder()),
      paging: requestedPage ? {
        pageNumber: requestedPage,
        numberOfItems: this.queryModel.itemsPerPage
      } : undefined
    }).subscribe((triggerInstances: QueryResult<TriggerInstance.TriggerInstance>) => {
      this.triggerHistoryList = [];
      triggerInstances.items.forEach(triggerInstance => {
        if (triggerInstance) {
          const triggerType = TriggerUtils.triggerTypes.find((triggerType) => triggerType.type === triggerInstance.type);
          const item = new TriggerHistoryModel();
          item.name = triggerInstance.trigger.name;
          item.type = triggerType ? triggerType!.type : undefined;
          item.typeStringKey = triggerType ? triggerType.stringKey : '';
          item.creationTime = triggerInstance.creationTime;
          this.triggerHistoryList.push(item);
        }
      });
      this.queryModel.currentPage = requestedPage;
      this.queryModel.totalNumberOfItems = triggerInstances.pagingResult.totalNumberOfItems;
      this.queryModel.currentNumberOfItems = triggerInstances.pagingResult.currentNumberOfItems;
    })
  }

  orderBy(field: Trigger.TriggerInstanceOrderField) {
    this.queryModel.onOrderFieldChanged(field);
    this.loadList();
  }

  pageChanged(selectedPage: number) {
    this.loadList(selectedPage);
  }

  itemsPerPageChanged(itemsPerPage: number) {
    this.queryModel.itemsPerPage = itemsPerPage;
    this.loadList(1);
  }

}

class TriggerHistoryModel {
  type?: TriggerType;
  typeStringKey: string = '';
  name: string = '';
  creationTime: OffsetDateTime = Dates.emptyOffsetDateTime()
}
