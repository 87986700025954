<div class="app flex-row align-items-center">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-6 text-center">
        <img src="../../../assets/img/error/function_disabled.svg">
        <h4 class="pt-3">{{'LOGIN_DISABLED_TITLE' | translate}}</h4>
        <p class="pt-1 error-text-label">{{'LOGIN_DISABLED_SUBTITLE' | translate}}</p>
      </div>
    </div>
  </div>
</div>
