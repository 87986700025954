/// <reference types="@types/googlemaps" />
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DetailTab } from '../transport-detail/detail-tab';
import { TransportTask, TransportTaskService } from '../../../lib/transport/transport-task/transport-task.service';
import { List } from 'immutable';
import { Transport, TransportService } from '../../../lib/transport/transport.service';
import { Address, AddressResource } from '../../../lib/address';
import { OffsetDateTime } from '../../../lib/util/dates';
import { ToasterService } from '../../../fork/angular2-toaster/angular2-toaster';
import { UiConstants } from '../../../util/core-utils';
import { StringKey } from '../../../app.string-keys';
import { TranslateService } from '@ngx-translate/core';
import { ErrorDetail, ErrorMessageService } from '../../../lib/error-message-parser.service';
import { Shipment } from '../../../lib/shipment-group/shipment-group.service';
import { Logger, LoggerFactory } from '../../../util/logger-factory';
import * as moment from 'moment';
import { Duration } from 'moment';
import { Models } from '../../../util/model-utils';
import { GoogleMapsLoaderUtil } from '../../../util/google/google-maps-loader.util';
import { IndexPair } from '../transport-task-dialog-container/transport-task-dialog-container.component';
import { RightModel } from '../../../app.rights';
import { RightResolver, RightService } from '../../../lib/right.service';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Message } from '../../../lib/message/message.service';

/* eslint-enable */

@Component({
  selector: 'app-transport-task-list',
  templateUrl: './transport-task-list.component.html',
  styleUrls: ['./transport-task-list.component.scss']
})
export class TransportTaskListComponent implements OnInit, DetailTab {

  Address = Address;

  private readonly logger: Logger;

  @ViewChild('gmap', { static: true }) gmapElement: any;
  map: google.maps.Map;

  @Input()
  transportId: number;

  @Input()
  transportState: Transport.TransportState;

  @Input()
  postalAddressFormat: string = '';

  @Output()
  transportDetailClicked: EventEmitter<TransportTask.TransportTask> = new EventEmitter<TransportTask.TransportTask>();

  @Output()
  transportCreateClicked: EventEmitter<IndexPair> = new EventEmitter<IndexPair>();

  @Output()
  transportArrivalClicked: EventEmitter<TransportTask.TransportTask> = new EventEmitter<TransportTask.TransportTask>();

  @Output()
  waypointOptimizeClicked: EventEmitter<IndexPair> = new EventEmitter<IndexPair>();

  @Output()
  ReloadTransportEvent: EventEmitter<void> = new EventEmitter<void>();

  transportTasks: TransportTask.TransportTask[] = [];

  dragFocus: boolean = false;
  dragging = false;
  taskDragAndDropEnabled: boolean = false;
  private dragStartTask: TransportTask.TransportTask | undefined = undefined;
  private dragStartShipment: TransportTask.Shipment | undefined = undefined;

  private sections: google.maps.Polyline[] = [];
  private markers: google.maps.Marker[] = [];

  taskChainErrors: TransportTaskChainError[] = [];

  directionsData: DirectionsData = new DirectionsData();

  rightModel: RightModel = RightModel.empty();

  private lastSelectedIndex: number | undefined = undefined;

  constructor(private transportTaskService: TransportTaskService,
              private transportService: TransportService,
              private toasterService: ToasterService,
              private translateService: TranslateService,
              private errorMessageService: ErrorMessageService,
              private rightService: RightService) {
    this.logger = LoggerFactory.createLogger('TransportTaskListComponent');
  }


  ngOnInit() {
    this.loadRightModels();
  }

  initMap(completion: () => void) {
    if (!this.map) {
      if (GoogleMapsLoaderUtil.didMapLoad()) {
        this.createMap();
        completion();
      }
      else {
        GoogleMapsLoaderUtil.subscribe(() => {
          this.createMap();
          completion();
        });
      }
    }
    else {
      completion();
    }
  }

  createMap() {
    const mapProp = {
      center: new google.maps.LatLng(47.49801, 19.03991),
      zoom: 15,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      streetViewControl: false,
      mapTypeControl: false
    };
    this.map = new google.maps.Map(this.gmapElement.nativeElement, mapProp);
  }


  initComponent() {
    this.initMap(() => this.loadList());
  }

  private loadList(keepChainErrors?: boolean) {
    if (!keepChainErrors) {
      this.clearChainErrors();
    }
    this.markers.forEach(m => m.setMap(null));
    this.markers = [];
    this.sections.forEach(s => s.setMap(null));
    this.sections = [];
    this.transportTaskService.query({transportId: this.transportId}).subscribe((result: List<TransportTask.TransportTask>) => {
      this.transportTasks = result.toArray();
      this.loadMapBounds();
      if (this.transportTasks.length > 0) {
        const firstTask = this.transportTasks[0];
        const lastTask = this.transportTasks[this.transportTasks.length - 1];
        const deptime: OffsetDateTime | undefined = firstTask.timetable.currentETD.isValid()
          ? firstTask.timetable.currentETD
          : (firstTask.timetable.plannedETD.isValid() ? firstTask.timetable.plannedETD : undefined);
        const arrtime: OffsetDateTime | undefined = lastTask.timetable.currentETA.isValid()
          ? lastTask.timetable.currentETA
          : (lastTask.timetable.plannedETA.isValid() ? lastTask.timetable.plannedETA : undefined);
        this.directionsData.duration = deptime && arrtime ? arrtime.diffDuration(deptime) : undefined;
      }
    });
    this.transportService.getRoute({id: this.transportId}).subscribe(result => {
      result.polylines.forEach((p, index) => {
        const section = new google.maps.Polyline({
          path: google.maps.geometry.encoding.decodePath(p),
          strokeColor: MapSectionColors[index % MapSectionColors.length]
        });
        section.setMap(this.map);
        this.sections.push(section);
      });
      this.directionsData.distanceM = result.sumDistanceMeter;
    });
  }

  private loadMapBounds() {
    const bounds = new google.maps.LatLngBounds();
    this.transportTasks.forEach((t, index) => {
      const coord = t.shippingPlace.coordinate;
      if (coord) {
        const latlng = new google.maps.LatLng(coord.latitude.toNumber(), coord.longitude.toNumber());
        bounds.extend(latlng);

        const infoWindow = new google.maps.InfoWindow({
          content: '<center><b>' + t.shippingPlace.name + '</b></center><br/>' +
            Address.PostalAddressMapper.toString(t.shippingPlace.postalAddress, this.postalAddressFormat),

        });

        const marker = new google.maps.Marker({
          position: latlng,
          map: this.map,
          label: {
            text: (1 + t.index) + '',
            fontSize: '10px'
          },
          icon: {
            url: this.resolvePoiUrl(t, false),
            labelOrigin: this.getLabelOrigin()
          },
          zIndex: index

        });
        marker.addListener('click', function () {
          infoWindow.open(map, marker);
        });
        this.markers[t.index] = marker;
      }
    });
    const map = this.map;
    if (!bounds.isEmpty()) {
      google.maps.event.addListenerOnce(this.map, 'idle', function () {
        map.fitBounds(bounds);
        map.panToBounds(bounds);
      });
    }
  }

  refreshTransportTaskList() {
    this.loadList();
  }

  getPlannedArrivalTime(task: TransportTask.TransportTask): OffsetDateTime {
    return task.timetable.currentETA.isValid() ? task.timetable.currentETA : task.timetable.plannedETA;
  }

  getPlannedDepartureTime(task: TransportTask.TransportTask): OffsetDateTime {
    return task.timetable.currentETD.isValid() ? task.timetable.currentETD : task.timetable.plannedETD;
  }

  firstQueuedTask(): TransportTask.TransportTask | undefined {
    return this.transportTasks.find(t => t.state === 'QUEUED');
  }

  canEdit() {
    return (this.transportState === 'UNDER_PLANNING' || this.transportState === 'UNDER_REPLANNING');
  }

  isMovableTask(task: TransportTask.TransportTask): boolean {
    return this.canEdit()
      && task.state === 'QUEUED';
  }

  isMovableShipment(shipment: TransportTask.Shipment): boolean {
    return this.canEdit()
      && (shipment.state === 'OPEN' || shipment.state === 'NEW');
  }

  isDropZone(task: TransportTask.TransportTask): boolean {
    return this.canEdit()
      && task.state === 'QUEUED';
  }

  canMoveShipment(shipment: TransportTask.Shipment, oldTask: TransportTask.TransportTask, newTask: TransportTask.TransportTask) {
    return this.canEdit() && shipment && oldTask &&
      this.shippingPlaceEquals(oldTask.shippingPlace, newTask.shippingPlace)
      && this.isDropZone(newTask) && this.isMovableShipment(shipment);
  }

  isDeletable(task: TransportTask.TransportTask): boolean {
    return this.canEdit()
      && task.state === 'QUEUED' && task.shipments.length === 0;
  }

  isDeletableShipment(shipment: TransportTask.Shipment): boolean {
    return this.canEdit()
      && (shipment.state === 'NEW' || shipment.state === 'OPEN' || shipment.state === 'STOCK_PROCESSING_DONE'
        || shipment.state === 'TAKEOVER_FAILED');
  }

  canPlanTime(task: TransportTask.TransportTask): boolean {
    return this.transportState === 'UNDER_PLANNING' && task.state === 'QUEUED';
  }

  shippingPlaceEquals(s1: TransportTask.ShippingPlace, s2: TransportTask.ShippingPlace): boolean {
    return (s1.locationId === s2.locationId && s1.stockId === s2.stockId)
      || (s1.locationId === undefined && s2.locationId === undefined && s1.coordinate !== undefined && s1.coordinate === s2.coordinate);
  }

  taskDragStart(task: TransportTask.TransportTask, index: number): void {
    document.getElementById('task_' + index)!.classList.remove('card-accent-primary');
    document.getElementById('task_' + index)!.classList.add('card-success');
  }

  taskDragEnd(task: TransportTask.TransportTask, index: number): void {
    document.getElementById('task_' + index)!.classList.add('card-accent-primary');
    document.getElementById('task_' + index)!.classList.remove('card-success');
    const endIndex = this.transportTasks.indexOf(task);
    this.moveTask(task, endIndex);
  }

  onDragEnter() {
    this.dragFocus = true;
  }

  onDragLeave() {
    this.dragFocus = false;
  }

  shipmentLeft(): void {
    this.taskDragAndDropEnabled = true;
  }

  shipmentEntered(): void {
    this.taskDragAndDropEnabled = false;
  }

  dragStarted(task: TransportTask.TransportTask, shipment?: TransportTask.Shipment): void {
    this.dragStartTask = task;
    this.dragStartShipment = shipment ? shipment : undefined;
    if (!this.dragStartShipment) {
      document.getElementById('task_' + task.index)!.classList.remove('card-accent-primary');
      document.getElementById('task_' + task.index)!.classList.add('card-success');
    }
    this.dragging = true;
  }

  dragEnded(): void {
    if (!this.dragStartShipment) {
      document.getElementById('task_' + this.dragStartTask!.index)!.classList.add('card-accent-primary');
      document.getElementById('task_' + this.dragStartTask!.index)!.classList.remove('card-success');
    }
    this.dragStartTask = undefined;
    this.dragStartShipment = undefined;
    this.dragging = false;
  }

  private moveTask(task: TransportTask.TransportTask, endIndex: number) {
    if (endIndex === task.index) {
      return;
    }
    if (endIndex !== this.transportTasks.length - 1) {
      if (this.transportTasks[endIndex + 1].state !== 'QUEUED') {
        this.resortTasks();
        return;
      }
    }
    this.transportTaskService.moveTask({transportId: this.transportId, taskId: task.id, newIndex: endIndex}).subscribe(
      (result) => {
        this.loadList();
      }, (error) => {
        this.resortTasks();
      }
    );
  }

  private resortTasks() {
    this.transportTasks = this.transportTasks.sort((a, b) => a.index - b.index);
  }

  private moveShipment(oldTask: TransportTask.TransportTask, newTask: TransportTask.TransportTask, shipment: TransportTask.Shipment) {
    if (newTask.id === oldTask.id) {
      return;
    }
    if (this.canMoveShipment(shipment, oldTask, newTask)) {
      this.transportTaskService.moveShipment({
        newTaskId: newTask.id,
        taskId: oldTask.id,
        shipmentId: shipment.id,
        transportId: this.transportId
      }).subscribe((result) => {
        this.loadList();
      }, (error) => {
        newTask.shipments.splice(newTask.shipments.indexOf(shipment), 1);
        oldTask.shipments.push(shipment);
      });
    }
    else {
      newTask.shipments.splice(newTask.shipments.indexOf(shipment), 1);
      oldTask.shipments.push(shipment);
      this.toasterService.pop({
        timeout: UiConstants.ToastTimeoutLong,
        type: UiConstants.toastTypeError,
        title: this.translateService.instant(StringKey.COMMON_ERROR_DIALOG_TITLE),
        body: this.translateService.instant(StringKey.TRANSPORT_TASK_SHIPMENT_MOVE_ERROR)
      });
    }
    this.dragStartTask = undefined;
  }

  private recalculateTaskIndexes() {
    this.transportTasks.forEach((t: TransportTask.TransportTask, index: number) => {
      t.index = index;
    });

  }

  deleteTask(task: TransportTask.TransportTask) {
    this.transportTaskService.deleteTask({transportId: this.transportId, id: task.id}).subscribe(
      result => {
        this.loadList();
      }
    );
  }

  deleteShipment(shipment: TransportTask.Shipment) {
    this.transportService.removeShipment({transportId: this.transportId, shipmentId: shipment.id}).subscribe(
      result => {
        this.loadList();
      }
    );
  }

  showCreateTaskDialog() {
    const t = this.firstQueuedTask();
    this.transportCreateClicked.emit({minIndex: t ? t.index : this.transportTasks.length - 1, maxIndex: this.transportTasks.length - 1});
  }

  showDetail(task: TransportTask.TransportTask) {
    this.logger.debug('taskDetailEmit');
    this.transportDetailClicked.emit(task);
  }

  replanRoute() {
    const t = this.firstQueuedTask();
    if (t) {
      this.transportTaskService.replanRouteAfter({transportId: this.transportId, id: t.id}).subscribe((result) => {
        this.reload();
      }, (error) => {
        this.reload();
      });
    }
  }

  optimizeWaypoints() {
    const t = this.firstQueuedTask();
    if (t) {
      const indexPair = {minIndex: t.index, maxIndex: this.transportTasks.length - 1};
      if (indexPair.maxIndex - indexPair.minIndex >= 3) {
        this.waypointOptimizeClicked.emit(indexPair);
      }
      else {
        this.toasterService.pop({
          timeout: UiConstants.ToastTimeoutLong,
          type: UiConstants.toastTypeError,
          title: this.translateService.instant(StringKey.COMMON_ERROR_DIALOG_TITLE),
          body: this.translateService.instant(StringKey.TRANSPORT_NOT_ENOUGH_WAYPOINTS)
        });
      }
    }
  }

  planTime(task: TransportTask.TransportTask) {
    this.transportArrivalClicked.emit(task);
  }

  clearChainErrors() {
    this.taskChainErrors = [];
  }

  hasTimetableError(index: number): boolean {
    return this.taskChainErrors[index] && this.taskChainErrors[index].timetableErrorMessage !== undefined;
  }

  getTimetableError(index: number): string | undefined {
    if (!this.taskChainErrors[index]) {
      return undefined;
    }
    return this.taskChainErrors[index].timetableErrorMessage;
  }

  hasStateError(index: number): boolean {
    return this.taskChainErrors[index] && this.taskChainErrors[index].stateErrorMessage !== undefined;
  }

  getStateError(index: number): string | undefined {
    if (!this.taskChainErrors[index]) {
      return undefined;
    }
    return this.taskChainErrors[index].stateErrorMessage;
  }

  hasShipmentError(taskIndex: number, shipmentId: number): boolean {
    return this.taskChainErrors[taskIndex] && this.taskChainErrors[taskIndex].shipmentErrorMessages.has(shipmentId);
  }

  getShipmentError(taskIndex: number, shipmentId: number): string | undefined {
    if (!this.taskChainErrors[taskIndex]) {
      return undefined;
    }
    return this.taskChainErrors[taskIndex].shipmentErrorMessages.get(shipmentId);
  }

  open() {
    this.clearChainErrors();
    this.transportService.open({id: this.transportId}).subscribe(result => {
      this.ReloadTransportEvent.emit();
    }, error => {
      this.parseChainConsistencyError(error);
    });
  }

  plan() {
    this.clearChainErrors();
    this.transportService.plan({id: this.transportId}).subscribe(result => {
      this.ReloadTransportEvent.emit();
    });
  }

  replan() {
    this.clearChainErrors();
    this.transportService.replan({id: this.transportId}).subscribe(result => {
      this.ReloadTransportEvent.emit();
    },
      (error: Error) => {
        const msg = this.errorMessageService.parseError(error);
        if (msg && !msg.globalErrors.isEmpty()) {
          const error = 'A műveletet nem lehet végrehajtani, mert a fuvar állapota nem megfelelő. ' +
            'Kérem töltse újra a képernyőt, vagy próbálkozzon később.';
          let found: boolean = false;
          for (let i = 0; i < msg.globalErrors.size; i++) {
            if (msg.globalErrors.get(i) === error) {
              found = true;
            }
          }
          if (found) {
            this.errorMessageService.localize(msg).subscribe((detail: ErrorDetail) => {
              this.toasterService.pop({
                timeout: UiConstants.ToastTimeoutLong,
                type: UiConstants.toastTypeError,
                title: detail.title,
                body: detail.message,
                buttonClickHandler: toast => {
                  location.reload();
                  return true;
                },
                buttonStringKey: 'COMMON_BUTTON_REFRESH'
              });
            });
          }
          else {
            this.errorMessageService.localize(msg).subscribe((detail: ErrorDetail) => {
              this.toasterService.pop({
                timeout: UiConstants.ToastTimeoutLong,
                type: UiConstants.toastTypeError,
                title: detail.title,
                body: detail.message
              });
            });
          }
        }
      });
  }

  close() {
    this.clearChainErrors();
    this.transportService.close({id: this.transportId}).subscribe(result => {
      this.ReloadTransportEvent.emit();
    });
  }

  continue() {
    this.clearChainErrors();
    this.transportService.continue({id: this.transportId}).subscribe(result => {
      this.ReloadTransportEvent.emit();
    }, error => {
      this.parseChainConsistencyError(error);
    });
  }

  parseChainConsistencyError(error: any) {
    const err = this.errorMessageService.parseError(error);
    if (err!.hasFieldErrors) {
      const parsedError: any = err!.extractFieldErrors();
      this.showErrors(parsedError);
    }
  }

  showErrors(parsedError: any) {
    const shipmentRegex = new RegExp('transport_task_index\\[(\\d*)].shipment_id\\[(\\d*)]');
    const timetableRegex = new RegExp('transport_task_index\\[(\\d*)].timetable');
    const stateRegex = new RegExp('transport_task_index\\[(\\d*)].state');
    for (const f in parsedError) {
      if (parsedError.hasOwnProperty(f)) {
        const errorMessage = parsedError[f]['text'];
        if (shipmentRegex.test(f)) {
          const match = shipmentRegex.exec(f);
          if (match) {
            const taskIndex: number = +match[1];
            const shipmentId: number = +match[2];
            if (!this.taskChainErrors[taskIndex]) {
              this.taskChainErrors[taskIndex] = new TransportTaskChainError();
            }
            this.taskChainErrors[taskIndex].shipmentErrorMessages.set(shipmentId, errorMessage);
          }
        }
        else if (timetableRegex.test(f)) {
          const match = timetableRegex.exec(f);
          if (match) {
            const taskIndex: number = +match[1];
            if (!this.taskChainErrors[taskIndex]) {
              this.taskChainErrors[taskIndex] = new TransportTaskChainError();
            }
            this.taskChainErrors[taskIndex].timetableErrorMessage = errorMessage;
          }
        }
        else if (stateRegex.test(f)) {
          const match = stateRegex.exec(f);
          if (match) {
            const taskIndex: number = +match[1];
            if (!this.taskChainErrors[taskIndex]) {
              this.taskChainErrors[taskIndex] = new TransportTaskChainError();
            }
            this.taskChainErrors[taskIndex].stateErrorMessage = errorMessage;
          }
        }
      }
    }
    this.toasterService.pop({
      timeout: UiConstants.ToastTimeoutLong,
      type: UiConstants.toastTypeError,
      title: this.translateService.instant(StringKey.TRANSPORT_OPEN_ERROR_TITLE),
      body: this.translateService.instant(StringKey.TRANSPORT_OPEN_ERROR_MESSAGE)
    });
  }

  getTaskStateIcon(state: TransportTask.TransportTaskState): string {
    const so = TransportTask.transportTaskStates.get(state);
    return so ? so.iconClass : '';
  }

  getTaskStateNameKey(state: TransportTask.TransportTaskState): string {
    const so = TransportTask.transportTaskStates.get(state);
    return so ? so.stringKey : '';
  }

  getGeocodeStatusIcon(geocodeStatus: AddressResource.GeocodeStatus): string {
    const so = AddressResource.geocodeStatuses.find(s => s.status === geocodeStatus);
    return so ? so.iconClass : '';
  }

  getGeocodeStatusNameKey(geocodeStatus: AddressResource.GeocodeStatus): string {
    const so = AddressResource.geocodeStatuses.find(s => s.status === geocodeStatus);
    return so ? so.stringKey : '';
  }

  getGeocodeStatusCoordinateText(coordinates?: Address.Coordinate): string {
    return coordinates ? coordinates.latitude.toString() + ', ' + coordinates.longitude.toString() : '';
  }

  getShipmentStateIcon(state: Shipment.ShipmentState, type: TransportTask.ShipmentType): string {
    const so = this.getShipmentStateObject(state, type);
    return so ? so.iconClass : '';
  }

  getShipmentStateNameKey(state: Shipment.ShipmentState, type: TransportTask.ShipmentType): string {
    const so = this.getShipmentStateObject(state, type);
    return so ? so.stringKey : '';
  }

  getShipmentState(state: Shipment.ShipmentState, type: TransportTask.ShipmentType): string {
    const so = this.getShipmentStateObject(state, type);
    return so ? so.state : '';
  }

  getShipmentStateObject(state: Shipment.ShipmentState, type: TransportTask.ShipmentType): Shipment.ShipmentStateObject | undefined {
    const driverTookOverStateObject = Shipment.shipmentStates.find(s => s.state === 'DRIVER_TOOK_OVER');
    const currentStateObject = Shipment.shipmentStates.find(s => s.state === state);
    if (driverTookOverStateObject && currentStateObject && (type === 'STOCK_TAKEOVER' || type === 'TAKEOVER') &&
      Shipment.shipmentStates.indexOf(driverTookOverStateObject) < Shipment.shipmentStates.indexOf(currentStateObject)) {
      return driverTookOverStateObject;
    }
    return currentStateObject;
  }

  getShipmentTypeIcon(type: TransportTask.ShipmentType): string {
    const so = TransportTask.shipmentTypes.get(type);
    return so ? so.iconClass : '';
  }

  getShipmentTypeNameKey(type: TransportTask.ShipmentType): string {
    const so = TransportTask.shipmentTypes.get(type);
    return so ? so.stringKey : '';
  }

  public reload(event?: TransportTask.WaypointOptimizationResponse) {
    let keepErrors: boolean = false;
    if (event) {
      event = <TransportTask.WaypointOptimizationResponse>event;
      if (event.consistencyErrorMessages) {
        this.showErrors(event.consistencyErrorMessages);
        keepErrors = true;
      }
    }
    this.loadList(keepErrors);
  }

  calculateDelay(originalTime: OffsetDateTime, updatedTime: OffsetDateTime): Duration {
    return updatedTime.diffDuration(originalTime)!;
  }

  getDelay(delayMins?: number): Duration | undefined {
    return delayMins ? moment.duration(delayMins, 'minutes') : undefined;
  }

  private loadRightModels() {
    this.rightService.getRightResolver().subscribe(
      (resolver: RightResolver) => {
        this.rightModel = RightModel.of(resolver);
      }
    );
  }

  onTransportTaskDropped(event: CdkDragDrop<TransportTask.TransportTask[]>) {
    moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    this.moveTask(event.container.data[event.currentIndex], event.currentIndex);
  }

  get taskIds(): string[] {
    return this.transportTasks.map(task => task.id + '');
  }

  onShipmentDropped(event: CdkDragDrop<TransportTask.Shipment[]>) {
    // In case the destination container is different from the previous container, we
    // need to transfer the given shipment to the target data array. This happens if
    // a shipment has been dropped on a different track.
    if (event.previousContainer === event.container) {
      // Normally, you would move the shipments inside the container, but since they are ordered,
      // there is no need to change anything about them
      // moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    }
    else {
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
    }
    this.moveShipment(
      this.transportTasks.find((t) => t.id === +event.previousContainer.id)!,
      this.transportTasks.find((t) => t.id === +event.container.id)!,
      event.container.data[event.currentIndex]);
  }

  onTaskSelected(index?: number) {
    if (this.lastSelectedIndex === index) {
      return;
    }
    if (this.lastSelectedIndex !== undefined) {
      const lastMarker = this.markers[this.lastSelectedIndex];
      lastMarker.setIcon({
        url: this.resolvePoiUrl(this.transportTasks[this.lastSelectedIndex], false),
        labelOrigin: this.getLabelOrigin()
      });
    }
    if (index !== undefined) {
      const currentMarker = this.markers[index];
      if (!currentMarker) {
        this.lastSelectedIndex = undefined;
        return;
      }
      const currentTask = this.transportTasks[index];
      currentMarker.setIcon({
        url: this.resolvePoiUrl(currentTask, true),
        labelOrigin: this.getLabelOrigin()
      });
    }
    this.lastSelectedIndex = index;
  }

  private getLabelOrigin() {
    return new google.maps.Point(13, 42);
  }

  private resolvePoiUrl(transportTask: TransportTask.TransportTask, selected: boolean) {
    let url = '../../../../assets/img/poi/transport-detail/poi';
    let takeover: boolean = false;
    let handover: boolean = false;
    transportTask.shipments.forEach(s => {
      if (s.type === 'HANDOVER') {
        handover = true;
      }
      if (s.type === 'TAKEOVER' || s.type === 'STOCK_TAKEOVER') {
        takeover = true;
      }
    });
    if (!handover && !takeover) {
      url += '_ures';
    }
    else if (handover && takeover) {
      url += '_vegyes';
    }
    else if (handover) {
      url += '_atadas';
    }
    else {
      url += '_atvetel';
    }
    if (selected) {
      url += '_active';
    }
    else {
      url += '_inactive';
    }
    if (transportTask.timetable.delayMinutes) {
      url += '_keses';
    }
    url += '.svg';
    return url;
  }

}

class TransportTaskChainError {
  shipmentErrorMessages: Map<number, string> = new Map<number, string>();
  timetableErrorMessage?: string = undefined;
  stateErrorMessage?: string = undefined;
}

export const MapSectionColors: string[] = ['#0037ae', '#335fbe', '#809bd7', '#b3c3e7', '#002168'];

class DirectionsData {
  duration?: Duration = undefined;
  distanceM?: number = undefined;

  isValid() {
    return this.duration && this.distanceM !== undefined;
  }

  get distanceKm(): string | undefined {
    if (this.distanceM === undefined) {
      return undefined;
    }
    const km = this.distanceM / 1000.0;
    if (km > 10) {
      return Models.decimalToFormattedString(Math.floor(km)) + ' km';
    }
    return Models.decimalToFormattedString(km) + ' km';
  }
}
