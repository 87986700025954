import { DqlQuery, Query } from '../field';
import { AddressResource } from '../../address';
import PostalAddressType = AddressResource.PostalAddressType;

export class PostalAddress {

  readonly id: Query.NumberField;

  readonly type: Query.EnumField<PostalAddressType>;

  readonly countryCode: Query.StringField;

  readonly city: Query.StringField;

  readonly zipCode: Query.StringField;

  readonly street: Query.StringField;

  readonly streetNumber: Query.StringField;

  readonly streetType: Query.StringField;

  readonly building: Query.StringField;

  readonly stairway: Query.StringField;

  readonly floor: Query.StringField;

  constructor(prefix?: string) {
    prefix = prefix ? prefix : '';
    this.id = new DqlQuery.NumberField(prefix + 'id');
    this.type = new DqlQuery.EnumField<PostalAddressType>(prefix + 'type', (type) => (type));
    this.countryCode = new DqlQuery.StringField(prefix + 'country_code');
    this.city = new DqlQuery.StringField(prefix + 'city');
    this.zipCode = new DqlQuery.StringField(prefix + 'zip_code');
    this.street = new DqlQuery.StringField(prefix + 'street');
    this.streetNumber = new DqlQuery.StringField(prefix + 'street_number');
    this.streetType = new DqlQuery.StringField(prefix + 'street_type');
    this.building = new DqlQuery.StringField(prefix + 'building');
    this.stairway = new DqlQuery.StringField(prefix + 'stairway');
    this.floor = new DqlQuery.StringField(prefix + 'floor');
  }

}
