// Not injected so valid inside the namespace.
import { MultiselectOptionItem } from '../../../../util/core-utils';

export class MasterdataRecordConnectToCustomerDialogModel {

  _masterDataRecords: MultiselectOptionItem<number>[] = [];
  _ownerCustomerRecord: MultiselectOptionItem<number>[] = [];
  _ownerContactLocation: MultiselectOptionItem<number>[] = [];


  get ownerCustomerRecord(): MultiselectOptionItem<number> | undefined {
    return this._ownerCustomerRecord.length > 0 ? this._ownerCustomerRecord[0] : undefined;
  }

  get masterDataRecords(): MultiselectOptionItem<number> | undefined {
    return this._masterDataRecords.length > 0 ? this._masterDataRecords[0] : undefined;
  }

  get ownerContactLocation(): MultiselectOptionItem<number> | undefined {
    return this.ownerCustomerRecord && this._ownerContactLocation.length > 0 ? this._ownerContactLocation[0] : undefined;
  }

  hasCustomer(): boolean {
    return this._ownerCustomerRecord.length !== 0;
  }

}
