/* eslint-disable */
import { Injectable } from '@angular/core';
import { List, Map, Set } from 'immutable';
import { FormTableResource, FormTableResourceService } from './form-table-resource.service';
import { Observable, Observer } from 'rxjs';
import {
  FieldValidationError,
  Order,
  PagingRequest,
  QueryResult,
  ResourceQueryResult,
  Services
} from '../util/services';
import { ObservableErrorResourceParser } from '../util/errors';
import { OffsetDateTime, } from '../util/dates';
import { Form } from './form.service';
import { FormResource } from './form-resource.service';

/* eslint-enable */

@Injectable()
export class FormTableService implements FormTable.Service, Form.Service {

  private formMapper: Form.ResourceMapper;

  constructor(private resourceService: FormTableResourceService) {
    this.formMapper = new Form.ResourceMapper();
  }

  // <editor-fold desc="CRUD">

  query(request: FormTable.QueryRequest): Observable<QueryResult<FormTable.FormTable>> {
    return Observable.create((observer: Observer<QueryResult<FormTable.FormTable>>) => {
      const resourceRequest: FormTableResource.QueryRequest = {
        id: Services.createIdParameter(request.formTableIdSet),
        disabled: request.disabled,
        name: request.name,
        external_id: request.externalId,
        q: request.queryText,
        order: Services.createOrderFieldParameter(Keys.toOrderFieldKey, request.orders),
        page_number: request.paging ? request.paging.pageNumber : undefined,
        number_of_items: request.paging ? request.paging.numberOfItems : undefined,
        no_progress_bar: request.noProgressBar
      };
      return this.resourceService.query(resourceRequest).subscribe(
        (result: ResourceQueryResult<FormTableResource.FormTable>) => {
          observer.next({
            items: this.toPublicList(result.items),
            pagingResult: result.pagingResult
          });
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  get(request: FormTable.GetRequest): Observable<FormTable.FormTable> {
    return Observable.create((observer: Observer<FormTable.FormTable>) => {
      const resourceRequest: FormTableResource.GetRequest = {
        form_table_id: request.formTableId
      };
      return this.resourceService.get(resourceRequest).subscribe(
        (result: FormTableResource.FormTable) => {
          observer.next(this.toPublic(result));
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  create(request: FormTable.CreateRequest): Observable<FormTable.FormTable> {
    return Observable.create((observer: Observer<FormTable.FormTable>) => {
      const resourceRequest: FormTableResource.CreateRequest = {
        name: request.name,
        external_id: request.externalId,
        description: request.description
      };
      return this.resourceService.create(resourceRequest).subscribe(
        (result: FormTableResource.FormTable) => {
          observer.next(this.toPublic(result));
        },
        (error: any) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  update(request: FormTable.UpdateRequest): Observable<FormTable.FormTable> {
    return Observable.create((observer: Observer<FormTable.FormTable>) => {
      const resourceRequest: FormTableResource.UpdateRequest = {
        form_table_id: request.formTableId,
        name: request.name,
        external_id: request.externalId,
        description: request.description
      };
      return this.resourceService.update(resourceRequest).subscribe(
        (result: FormTableResource.FormTable) => {
          observer.next(this.toPublic(result));
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  setDisabled(request: FormTable.DisableRequest): Observable<FormTable.FormTable> {
    return Observable.create((observer: Observer<FormTable.FormTable>) => {
      const resourceRequest: FormTableResource.DisableRequest = {
        form_table_id: request.formTableId,
        disabled: request.disabled
      };
      return this.resourceService.setDisabled(resourceRequest).subscribe(
        (result: FormTableResource.FormTable) => {
          observer.next(this.toPublic(result));
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  // </editor-fold>

  // <editor-fold desc="Form">

  getForm(request: Form.GetFormRequest): Observable<Form.Form> {
    return Observable.create((observer: Observer<Form.Form>) => {
      const resourceRequest: FormResource.GetFormRequest =
        this.formMapper.toResourceGetFormRequest(request);
      return this.resourceService.getForm(resourceRequest).subscribe(
        (result: FormResource.Form) => {
          observer.next(this.formMapper.toPublicForm(result));
        },
        (error: Error) => {
          observer.error(this.formMapper.translateGetFormError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  createGroup(request: Form.CreateGroupRequest): Observable<Form.CreateGroupResponse> {
    return Observable.create((observer: Observer<Form.CreateGroupResponse>) => {
      const resourceRequest: FormResource.CreateGroupRequest =
        this.formMapper.toResourceCreateGroupRequest(request);
      return this.resourceService.createFormGroup(resourceRequest).subscribe(
        (result: FormResource.CreateGroupResponse) => {
          observer.next(this.formMapper.toPublicCreateGroupResponse(result));
        },
        (error: Error) => {
          observer.error(this.formMapper.translateCreateGroupError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  updateGroup(request: Form.UpdateGroupRequest): Observable<Form.UpdateGroupResponse> {
    return Observable.create((observer: Observer<Form.UpdateGroupResponse>) => {
      const resourceRequest: FormResource.UpdateGroupRequest =
        this.formMapper.toResourceUpdateGroupRequest(request);
      return this.resourceService.updateFormGroup(resourceRequest).subscribe(
        (result: FormResource.UpdateGroupResponse) => {
          observer.next(this.formMapper.toPublicUpdateGroupResponse(result));
        },
        (error: Error) => {
          observer.error(this.formMapper.translateUpdateGroupError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  disableGroup(request: Form.DisableGroupRequest): Observable<Form.DisableGroupResponse> {
    return Observable.create((observer: Observer<Form.DisableGroupResponse>) => {
      const resourceRequest: FormResource.DisableGroupRequest =
        this.formMapper.toResourceDisableGroupRequest(request);
      return this.resourceService.disableFormGroup(resourceRequest).subscribe(
        (result: FormResource.DisableGroupResponse) => {
          observer.next(this.formMapper.toPublicDisableGroupResponse(result));
        },
        (error: Error) => {
          observer.error(this.formMapper.translateDisableGroupError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  moveGroup(request: Form.MoveGroupRequest): Observable<Form.MoveGroupResponse> {
    return Observable.create((observer: Observer<Form.MoveGroupResponse>) => {
      const resourceRequest: FormResource.MoveGroupRequest =
        this.formMapper.toResourceMoveGroupRequest(request);
      return this.resourceService.moveFormGroup(resourceRequest).subscribe(
        (result: FormResource.MoveGroupResponse) => {
          observer.next(this.formMapper.toPublicMoveGroupResponse(result));
        },
        (error: Error) => {
          observer.error(this.formMapper.translateMoveGroupError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  createField(request: Form.CreateFieldRequest): Observable<Form.CreateFieldResponse> {
    return Observable.create((observer: Observer<Form.CreateFieldResponse>) => {
      const resourceRequest: FormResource.CreateFieldRequest =
        this.formMapper.toResourceCreateFieldRequest(request);
      return this.resourceService.createFormField(resourceRequest).subscribe(
        (result: FormResource.CreateFieldResponse) => {
          observer.next(this.formMapper.toPublicCreateFieldResponse(result));
        },
        (error: Error) => {
          observer.error(this.formMapper.translateCreateFieldError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  updateField(request: Form.UpdateFieldRequest): Observable<Form.UpdateFieldResponse> {
    return Observable.create((observer: Observer<Form.UpdateFieldResponse>) => {
      const resourceRequest: FormResource.UpdateFieldRequest =
        this.formMapper.toResourceUpdateFieldRequest(request);
      return this.resourceService.updateFormField(resourceRequest).subscribe(
        (result: FormResource.UpdateFieldResponse) => {
          observer.next(this.formMapper.toPublicUpdateFieldResponse(result));
        },
        (error: Error) => {
          observer.error(this.formMapper.translateUpdateFieldError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  disableField(request: Form.DisableFieldRequest): Observable<Form.DisableFieldResponse> {
    return Observable.create((observer: Observer<Form.DisableFieldResponse>) => {
      const resourceRequest: FormResource.DisableFieldRequest =
        this.formMapper.toResourceDisableFieldRequest(request);
      return this.resourceService.disableFormField(resourceRequest).subscribe(
        (result: FormResource.DisableFieldResponse) => {
          observer.next(this.formMapper.toPublicDisableFieldResponse(result));
        },
        (error: Error) => {
          observer.error(this.formMapper.translateDisableFieldError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  moveFieldToGroup(request: Form.MoveFieldToGroupRequest): Observable<Form.MoveFieldToGroupResponse> {
    return Observable.create((observer: Observer<Form.MoveFieldToGroupResponse>) => {
      const resourceRequest: FormResource.MoveFieldToGroupRequest =
        this.formMapper.toResourceMoveFieldToGroupRequest(request);
      return this.resourceService.moveFormFieldToGroup(resourceRequest).subscribe(
        (result: FormResource.MoveFieldToGroupResponse) => {
          observer.next(this.formMapper.toPublicMoveFieldToGroupResponse(result));
        },
        (error: Error) => {
          observer.error(this.formMapper.translateMoveFieldToGroupError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  importForm(request: Form.ImportRequest): Observable<Form.Form> {
    return Observable.create((observer: Observer<Form.Form>) => {
      return this.resourceService.import({
        parent_id: request.parentId,
        ignore_disabled_items: request.ignoreDisabledFields,
        form: request.form,
        version: request.version
      }).subscribe((result: FormResource.Form) => {
          observer.next(this.formMapper.toPublicForm(result));
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        }
      );
    });
  }

  exportForm(request: Form.ExportRequest): Observable<Form.FormImportDocument> {
    return Observable.create((observer: Observer<Form.FormImportDocument>) => {
      return this.resourceService.export({
        parent_id: request.parentId,
      }).subscribe((result: FormResource.FormImportDocument) => {
          observer.next({form: result.form, version: result.version});
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        }
      );
    });
  }

  // </editor-fold>

  // <editor-fold desc="Internal">

  private translateError(error: any): any {
    const res = ObservableErrorResourceParser.parseError(error);
    const fieldErrors = ObservableErrorResourceParser.extractFieldErrors(res);
    const fieldErrorMap = ObservableErrorResourceParser.toFieldErrorMap(Keys.toValidatedField, fieldErrors);
    if (!fieldErrorMap.isEmpty()) {
      return FieldValidationError.of(fieldErrorMap);
    }
    return error;
  }

  private toPublicList(resourceList: FormTableResource.FormTable[]): List<FormTable.FormTable> {
    return List.of(...resourceList.map((r) => this.toPublic(r)));
  }

  private toPublic(r: FormTableResource.FormTable): FormTable.FormTable {
    return {
      id: r.id,
      disabled: r.disabled,
      creationTime: Services.toOffsetDateTime(r.creation_time),
      updateTime: Services.toOffsetDateTime(r.update_time),
      name: r.name,
      externalId: r.external_id,
      description: r.description
    };
  }

  // </editor-fold>

}

export namespace FormTable {

  export interface Service {

    // <editor-fold desc="CRUD">

    query(request: QueryRequest): Observable<QueryResult<FormTable>>;

    get(request: GetRequest): Observable<FormTable>;

    create(request: CreateRequest): Observable<FormTable>;

    update(request: UpdateRequest): Observable<FormTable>;

    setDisabled(request: DisableRequest): Observable<FormTable>;

    // </editor-fold>

  }

  // <editor-fold desc="CRUD">

  export interface FormTable {
    id: number;
    disabled: boolean;
    creationTime: OffsetDateTime;
    updateTime: OffsetDateTime;
    name: string;
    externalId: string;
    description: string;
  }

  export interface QueryRequest {
    formTableIdSet?: Set<number>;
    disabled?: boolean;
    name?: string;
    externalId?: string;
    queryText?: string;
    orders?: Set<Order<OrderField>>;
    paging?: PagingRequest;
    noProgressBar?: boolean;
  }

  export interface GetRequest {
    formTableId: number;
  }

  export interface CreateRequest {
    name: string;
    externalId?: string;
    description?: string;
  }

  export interface UpdateRequest {
    formTableId: number;
    name: string;
    externalId: string;
    description?: string;
  }

  export interface DisableRequest {
    formTableId: number;
    disabled: boolean;
  }

  export enum OrderField {
    ID,
    DISABLED,
    CREATION_TIME,
    UPDATE_TIME,
    NAME,
    EXTERNAL_ID,
    DESCRIPTION,
  }

  export enum ValidatedField {
    UNKNOWN,
    NAME,
    EXTERNAL_ID,
  }

  // </editor-fold>

}

// <editor-fold desc="Internal">

class Keys {
  private static readonly ID = 'id';
  private static readonly DISABLED = 'disabled';
  private static readonly CREATION_TIME = 'creation_time';
  private static readonly UPDATE_TIME = 'update_time';
  private static readonly NAME = 'name';
  private static readonly EXTERNAL_ID = 'external_id';
  private static readonly DESCRIPTION = 'description';

  private static readonly orderFieldKeyMap: Map<FormTable.OrderField, string> = Map.of(
    FormTable.OrderField.ID, Keys.ID,
    FormTable.OrderField.DISABLED, Keys.DISABLED,
    FormTable.OrderField.CREATION_TIME, Keys.CREATION_TIME,
    FormTable.OrderField.UPDATE_TIME, Keys.UPDATE_TIME,
    FormTable.OrderField.NAME, Keys.NAME,
    FormTable.OrderField.EXTERNAL_ID, Keys.EXTERNAL_ID,
    FormTable.OrderField.DESCRIPTION, Keys.DESCRIPTION,
  );

  private static readonly keyValidatedFieldMap: Map<string, FormTable.ValidatedField> = Map.of(
    Keys.NAME, FormTable.ValidatedField.NAME,
    Keys.EXTERNAL_ID, FormTable.ValidatedField.EXTERNAL_ID,
  );

  public static toOrderFieldKey(field: FormTable.OrderField): string {
    return Keys.orderFieldKeyMap.get(field)!;
  }

  public static toValidatedField(fieldKey: string): FormTable.ValidatedField {
    return Keys.keyValidatedFieldMap.get(fieldKey, FormTable.ValidatedField.UNKNOWN);
  }

}


// </editor-fold>
