<div class="breadcrumb-container" [ngClass]="{ 'breadcrumb-container-compact': compactSidebar }" id="breadcrumb">
  <div class="breadcrumb-component">
    <app-breadcrumb
      [parents]="breadcrumbParents"
      [self]="breadcrumbSelf">
    </app-breadcrumb>
  </div>
</div>
<div class="breadcrumb-placeholder"></div>
<div class="container-horizontal-padding container-vertical-padding animated fadeIn">
  <div class="card col-xs-12 col-sm-12 col-md-8 p-0">

    <div class="card-header">
      {{'NOTE_DETAIL_TITLE'|translate}}
    </div>

    <div class="card-body">
      <form class="form-horizontal bordered-row" #f="ngForm" novalidate>
        <div class="form-group row mb-0">
          <label class="col-form-label form-control-label col-md-4 detail-title">{{'NOTE_DETAIL_CONTENT' | translate}}</label>
          <div class="col-md-8">
            <input readonly type="text" class="form-control detail-description" [(ngModel)]="model.content"
                   name="content" id="content" #content="ngModel" aria-readonly>
          </div>
        </div>

        <div class="form-group row mb-0">
          <label class="col-form-label form-control-label col-md-4 detail-title">{{'NOTE_DETAIL_OWNER_USER' | translate}}</label>
          <div class="col-md-8">
            <input readonly type="text" class="form-control detail-description" value="{{getOwnerUserText()}}"
                   name="ownerUser" id="ownerUser" aria-readonly>
          </div>
        </div>

        <div class="form-group row mb-0">
          <label class="col-form-label form-control-label col-md-4 detail-title">{{'NOTE_DETAIL_CUSTOMER' | translate}}</label>
          <div class="col-md-8">
            <input readonly type="text" class="form-control detail-description" value="{{getCustomerName()}}"
                   name="customer" id="customer" aria-readonly>
          </div>
        </div>

        <div class="form-group row mb-0" *ngIf="!(getTaskName() === this.createDefaultTask().text)">
          <label class="col-form-label form-control-label col-md-4 detail-title">{{'NOTE_DETAIL_TASK_RECORD' | translate}}</label>
          <div class="col-md-8">
            <input readonly type="text" class="form-control detail-description" value="{{getTaskName()}}"
                   name="task_record" id="task_record" aria-readonly>
          </div>
        </div>

        <div class="form-group row mb-0" *ngIf="!(getAttachments() === '')">
          <label class="col-form-label form-control-label col-md-4 detail-title">{{'NOTE_DETAIL_TASK_ATTACHMENTS' | translate}}</label>
          <div class="col-md-8">
            <input readonly type="text" class="form-control detail-description" value="{{getAttachments()}}"
                   name="attachments" id="attachments" aria-readonly>
          </div>
        </div>

      </form>
    </div>
  </div>

</div>
