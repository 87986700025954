<!--HistoryDetailDialog Modal-->
<div bsModal [config]="UiConstants.modalConfig" #detailDialog="bs-modal" class="modal fade" tabindex="-1" role="dialog"
     aria-labelledby="myModalLabel"
     aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document" *ngIf="detailDialogVisible">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">{{'COMMON_BUTTON_DETAILS' | translate}}</h4>
        <button type="button" class="close" (click)="closeDetailDialog()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="form-group row">
        <div class="col-md-12" >
          <pre id="default-spacing" class="form-control detail-description"><code [highlight]="selectedHistoryDetail" ></code></pre>
        </div>
        <!-- /.col-md-8 -->
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="closeDetailDialog()">
          {{'COMMON_BUTTON_CLOSE' | translate}}
        </button>
      </div>
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div><!-- /.modal -->
<!--/HistoryDetailDialog-->
