import {Component, Injector, OnDestroy, OnInit} from '@angular/core';
import {saveAs} from 'file-saver';
import {
  MultiselectOptionItem,
  OrderFieldFunction,
  OrderFieldModel,
  SelectUtils,
  UiConstants
} from '../../../util/core-utils';
import {SearchUtils} from '../../../util/search-utils';
import {OrderField} from '../../../lib/query/orderfields';
import {OrderType, QueryResult} from '../../../lib/util/services';
import {Angular2Multiselects} from '../../../util/multiselect';
import {RightModel} from '../../../app.rights';
import {ProcessListModel} from './process-list.model';
import {GrantedPermissionSetResolver, RightResolver, RightService} from '../../../lib/right.service';
import {Process, ProcessService} from '../../../lib/process/process.service';
import {FilterField} from '../../../lib/query/filterfields';
import {CriteriaBuilder, Models} from '../../../util/model-utils';
import {ProcessRightModel} from '../../../lib/process/process-right.model';
import {BadgeStyle} from '../../../shared/table-badge/badge-style';
import {InputMask} from '../../../util/input-masks';
import {MatDialog} from '@angular/material/dialog';
import {StateName} from '../../../app.state-names';
import {BreadcrumbParent} from '../../../shared/breadcrumb/breadcrumb/breadcrumb.component';
import {UIRouter} from '@uirouter/angular';
import {ProcessSearch, ProcessSearchService} from '../../../lib/process/process-search.service';
import {ProcessBaseDialogComponent} from '../process-base-dialog/process-base-dialog.component';
import {IdentityMessage} from '../../../lib/util/messages';
import {List} from 'immutable';
import {UserMultiselectProvider} from '../../../lib/user/user-multiselect.provider';
import {
  WorkflowMultiselectOptionItem,
  WorkflowMultiselectProvider
} from '../../../lib/workflow/workflow-multiselect.provider';
import {DownloadedFile} from '../../../lib/util/downloaded-files';
import {TaskRecord} from "../../../lib/task/task-record.service";
import {Workflow} from "../../../lib/workflow/workflow.service";

@Component({
  selector: 'app-process-list',
  templateUrl: './process-list.component.html',
  styleUrls: ['./process-list.component.scss']
})
export class ProcessListComponent extends SearchUtils.SearchableList<ProcessSearch.Model> implements OnInit, OnDestroy {

  UiConstants = UiConstants;
  BadgeStyle = BadgeStyle;
  InputMask = InputMask;
  SelectUtils = SelectUtils;
  ProcessSearch = ProcessSearch;

  queryModel: OrderFieldModel<OrderField.Process>
    = new OrderFieldModel(ProcessSearch.OrderFunctions.ID, OrderType.DESC);
  searchModel: ProcessSearch.Model = new ProcessSearch.Model();

  dropdownSettings: Angular2Multiselects.Settings;

  processList: ProcessListModel[] = [];
  states: ProcessSearch.StateFilterOption[] = [];
  rightModel: RightModel = RightModel.empty();

  breadcrumbSelf: string;
  breadcrumbParents: BreadcrumbParent[] = [];
  compactSidebar: boolean = document.querySelector('body')!.classList.contains('sidebar-compact');

  users: MultiselectOptionItem<number>[] = [];
  workflows: WorkflowMultiselectOptionItem[] = [];

  constructor(private processService: ProcessService,
              private processSearchService: ProcessSearchService,
              private rightService: RightService,
              private dialog: MatDialog,
              private uiRouter: UIRouter,
              private userMultiselectProvider: UserMultiselectProvider,
              private workflowMultiselectProvider: WorkflowMultiselectProvider,
              injector: Injector) {
    super(ProcessSearch.Model, injector);
  }

  ngOnInit() {
    this.loadRightModels();
    this.initBreadcrumb();
    this.initSearch();
    this.initDropdownSettings();
  }

  private loadRightModels() {
    this.rightService.getRightResolver().subscribe(
      (resolver: RightResolver) => {
        this.rightModel = RightModel.of(resolver);
      }
    );
  }

  initBreadcrumb() {
    this.translateService.get('MENU_NAVBAR_NAV_TITLE_PROCESSS').subscribe(
      (result: string) => {
        this.breadcrumbSelf = result;
      }
    );
  }

  initDropdownSettings() {
    this.dropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(false)
      .enableSearchFilter(true)
      .remoteSearch(true)
      .enableCheckAll(true)
      .build();
  }

  loadSearch(completion: () => void) {
    this.processSearchService.getSearchData({})
      .subscribe(
        (result: ProcessSearch.SearchDataResult) => {
          this.queryModel = result.searchData.queryModel;
          this.searchModel = result.searchData.searchModel;
          completion();
        }
      );
  }

  onFirstSearchOpen(): void {
    this.loadStateFilterOptions();
  }

  onUserSearch(q?: string) {
    this.userMultiselectProvider.loadAll(q).subscribe(users => {
      this.users = users;
    });
  }

  onWorkflowSearch(q?: string) {
    this.workflowMultiselectProvider.loadLatestVersions(q).subscribe(workflows => {
      this.workflows = workflows;
    });
  }

  private loadStateFilterOptions() {
    this.states = [];
    this.states.push(ProcessSearch.Model.defaultSelectedState);
    Process.processStates.forEach((stateObject) => {
      this.states.push({
        id: stateObject.state,
        stringKey: stateObject.stringKey
      });
    });
  }

  pageChanged(selectedPage: number) {
    this.loadList(selectedPage);
  }

  itemsPerPageChanged(itemsPerPage: number) {
    this.queryModel.itemsPerPage = itemsPerPage;
    this.loadList(1);
  }

  orderBy(field: OrderFieldFunction<OrderField.Process>) {
    this.queryModel.onOrderFieldChanged(field);
    this.loadList(1);
  }

  loadList(pageNumber?: number) {
    const requestedPage = pageNumber ? pageNumber : this.queryModel.currentPage;
    const rights = undefined;
    const order = this.queryModel.createOrderFunction();
    const filter = this.createFilter();
    this.processService.query({
      fields: f => f.forList,
      order: order,
      filter: filter,
      rights: rights,
      paging: requestedPage ? {
        pageNumber: requestedPage,
        numberOfItems: this.queryModel.itemsPerPage
      } : undefined,
    }).subscribe((result: QueryResult<Process.Process>) => {
      this.processList = [];
      result.items.forEach((process: Process.Process) => {
        const model = new ProcessListModel();
        model.id = process.id;
        model.rights = new ProcessRightModel(GrantedPermissionSetResolver.byGrantedRights(process.grantedRights));
        model.name = process.name;
        model.externalId = process.externalId;
        model.state = process.state;
        const stateObject = Process.processStates.find(state => state.state === process.state);
        if (stateObject) {
          model.stateObject = stateObject;
        }
        model.icon = process.workflow!.icon;
        model.updateTime = process.updateTime;
        model.deadline = process.deadline;
        model.creationTime = process.creationTime;
        model.lastModifiedUser = process.lastModifiedUser!;
        model.workflow = process.workflow!;
        this.processList.push(model);
      });
      this.queryModel.currentPage = requestedPage;
      this.queryModel.totalNumberOfItems = result.pagingResult.totalNumberOfItems;
      this.queryModel.currentNumberOfItems = result.pagingResult.currentNumberOfItems;
    });
  }

  private createFilter() {
    return (f: FilterField.Process) => CriteriaBuilder.builder()
      .addEnum((notInactiveStates) => f.workflow.versionState.in(notInactiveStates), List.of(Workflow.VersionState.DRAFT, Workflow.VersionState.DEPRECATED, Workflow.VersionState.FINALIZED)) // do not return disabled wf related processes
      .addNumber((id) => f.id.eq(id), this.searchModel.id)
      .addString((externalId) => f.externalId.containsIgnoreCase(externalId), this.searchModel.externalId)
      .addString((name) => f.name.containsIgnoreCase(name), this.searchModel.name)
      .addEnum((state) => f.state.eq(state), this.searchModel.state.id)
      .addDateTime((creationTime) => f.creationTime.after(creationTime), Models.parseDateTimeFrom(this.searchModel.creationTimeFrom))
      .addDateTime((creationTime) => f.creationTime.before(creationTime), Models.parseDateTimeTo(this.searchModel.creationTimeTo))
      .addDateTime((updateTime) => f.updateTime.after(updateTime), Models.parseDateTimeFrom(this.searchModel.updateTimeFrom))
      .addDateTime((updateTime) => f.updateTime.before(updateTime), Models.parseDateTimeTo(this.searchModel.updateTimeTo))
      .addDateTime((startTime) => f.startTime.after(startTime), Models.parseDateTimeFrom(this.searchModel.startTimeFrom))
      .addDateTime((startTime) => f.startTime.before(startTime), Models.parseDateTimeTo(this.searchModel.startTimeTo))
      .addDateTime((finishTime) => f.finishTime.after(finishTime), Models.parseDateTimeFrom(this.searchModel.finishTimeFrom))
      .addDateTime((finishTime) => f.finishTime.before(finishTime), Models.parseDateTimeTo(this.searchModel.finishTimeTo))
      .addNumberList((creatorUserId) => f.creatorUser.id.in(creatorUserId), List.of(...this.searchModel.creatorUserIds))
      .addNumberList((lastModifiedUserId) => f.lastModifiedUser.id.in(lastModifiedUserId), List.of(...this.searchModel.lastModifiedUserIds))
      .addNumberList((workflowBaseIds) => f.workflow.baseId.in(workflowBaseIds), List.of(...this.searchModel.workflowBaseIds))
      .build();
  }

  onSearchClicked() {
    this.loadList(1);
  }

  onSearchReset() {
    this.processSearchService.resetSearchData({}).subscribe(
      (result) => {
        this.loadSearch(() => {
          this.toggleSearch();
          this.loadList(1);
        });
      }
    );
  }


  ngOnDestroy(): void {
    this.saveSearch();
  }

  private saveSearch() {
    const request = {
        searchData: {
          queryModel: this.queryModel,
          searchModel: this.searchModel
        }
      }
    ;
    this.processSearchService.setSearchData(request).subscribe(
      (result) => {
      },
      (error) => {
      }
    );
  }

  exportProcessXls() {
    this.processService.exportXls({
      filter: this.createFilter()
    }).subscribe(
      (res: DownloadedFile) => {
        saveAs(res.getBlob(), res.getFileName('process.xlsx'));
      }
    );
  }

  openProcessCreateDialog() {
    const dialogRef = this.dialog.open(ProcessBaseDialogComponent, {
      width: '60%',
      data: {readonly: false}
    });

    dialogRef.afterClosed().subscribe((result: IdentityMessage) => {
      if (result) {
        this.uiRouter.stateService.go(StateName.PROCESS_DETAIL, {id: result.id});
      }
    });
  }

  protected readonly TaskRecord = TaskRecord;
}
