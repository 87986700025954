import { AfterViewInit, Directive, ElementRef } from '@angular/core';
import * as striptags from 'striptags';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '.responsive-table-column, .text-truncate'
})
export class TextEllipsisDirective implements AfterViewInit {

  constructor(private elementRef: ElementRef) {}

  ngAfterViewInit(): void {
    setTimeout(() => {
      const element = this.elementRef.nativeElement;
      if (element.offsetWidth < element.scrollWidth) {
        element.title = striptags(element.innerHTML);
      }
    }, 300);
  }
}
