import { GrantedPermissionSetResolver } from '../../../../lib/right.service';
import { Dates, OffsetDateTime } from '../../../../lib/util/dates';
import { LedgerNumberRightModel } from '../../../../lib/ledger/number/ledger-number-right.model';

export class LedgerNumberListModel {
  id: number;
  rights: LedgerNumberRightModel = new LedgerNumberRightModel(GrantedPermissionSetResolver.empty());
  creationTime: OffsetDateTime = Dates.emptyOffsetDateTime();
  updateTime: OffsetDateTime = Dates.emptyOffsetDateTime();
  ledgerNumber: string;
  name?: string;
  disabled: boolean;
}
