export enum ScreenSize {
  EXTRA_SMALL, // bootstrap 4 name: xs
  SMALL, // bootstrap 4 name: sm
  MEDIUM, // bootstrap 4 name: md
  LARGE, // bootstrap 4 name: lg
  EXTRA_LARGE, // bootstrap 4 name: xl
}

/**
 * Based on Bootstrap 4. Do not change them.
 */
export class ScreenSizes {

  // Names based on browser Google Chrome.
  public static readonly WIDTH_MOBILE_S = 320;
  public static readonly WIDTH_MOBILE_M = 375;
  public static readonly WIDTH_MOBILE_L = 425;
  public static readonly WIDTH_TABLET = 768;
  public static readonly WIDTH_LAPTOP = 1024;
  public static readonly WIDTH_LAPTOP_L = 1440;
  public static readonly WIDTH_4K = 2560;

  private static readonly EXTRA_SMALL = 544; // <= 544
  private static readonly SMALL = 768; // > 544 && <= 768
  private static readonly MEDIUM = 992; // > 768 && <= 992
  private static readonly LARGE = 1200; // > 992 && <= 1200

  public static getWindowScreenSize(): ScreenSize {
    return ScreenSizes.getScreenSize(window.innerWidth);
  }

  public static getScreenSize(width: number): ScreenSize {
    if (width <= ScreenSizes.EXTRA_SMALL) {
      return ScreenSize.EXTRA_SMALL;
    }
    if (width <= ScreenSizes.SMALL) {
      return ScreenSize.SMALL;
    }
    if (width <= ScreenSizes.MEDIUM) {
      return ScreenSize.MEDIUM;
    }
    if (width <= ScreenSizes.LARGE) {
      return ScreenSize.LARGE;
    }
    return ScreenSize.EXTRA_LARGE;
  }

}
