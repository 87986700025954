<div bsModal [config]="UiConstants.modalConfig" #fieldDialog="bs-modal" (onHide)="onModalHide($event)"
     class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content" *ngIf="visible">
      <div class="modal-header">
        <h4 class="modal-title">{{dialogTitleDictionaryKey | translate}}</h4>
        <button type="button" class="close" (click)="closeDialog()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" [formGroup]="formGroup">
        <div class="card-body p-0">

          <div class="row pl-1 pr-1 mb-1 d-flex justify-content-between">
            <h6 class="">{{'COMMON_BASIC_DATA' | translate}}</h6>
            <div *ngIf="!clone">
              <button type="button" class="btn btn-secondary" (click)="fillDeadline()">{{'INVOICE_CREATE_DEADLINE' | translate}}</button>
              <button type="button" class="btn btn-secondary" (click)="fillDeliveryDate()">{{'INVOICE_CREATE_DELIVERY_DATE' | translate}}</button>
              <button type="button" class="btn btn-secondary" (click)="resetModel()">{{'COMMON_BUTTON_SEARCH_DELETE' | translate}}</button>
            </div>
          </div>
          <div class="row">

            <!-- region Title -->
            <div class="form-group col-md-6"
                 [ngClass]="{ 'has-danger': hasFieldError(Form.FieldValidatedField.TITLE) || hasLocalFieldError('title')}">
              <label>{{'COMMON_NAME' | translate}}</label>
              <input type="text" class="form-control" placeholder="{{'COMMON_NAME' | translate}}"
                     maxlength="{{UiConstants.maximumVarcharLength}}"
                     [ngClass]="{ 'form-control-danger': hasFieldError(Form.FieldValidatedField.TITLE) || hasLocalFieldError('title') }"
                     [(ngModel)]="fieldModel.title" formControlName="title"
                     (ngModelChange)="removeFieldError(Form.FieldValidatedField.TITLE)"
                     (blur)="onTitleBlur()"
                     trim="blur" validateOnBlur [validateFormControl]="formGroup.controls['title']">
              <div class="form-control-feedback" *ngIf="hasFieldError(Form.FieldValidatedField.TITLE)">
                {{getFieldErrorText(Form.FieldValidatedField.TITLE)}}
              </div>
              <div class="form-control-feedback" *ngIf="hasLocalFieldError('title')">
                <span *ngIf="hasLocalFieldError('title', 'required')">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</span>
              </div>
            </div>
            <!-- endregion Title -->

            <!-- region field width -->
            <div class="form-group col-md-3">
              <label>{{'FORM_ITEM_COMMON_FIELD_WIDTH' | translate}}</label>
              <select class="form-control"
                      [ngModelOptions]="{standalone: true}"
                      [(ngModel)]="fieldModel.fieldWidthType">
                <option *ngFor="let widthType of fieldWidthTypes" [ngValue]="widthType">{{widthType.textDictionaryKey | translate}}</option>
              </select>
            </div>
            <!-- endregion field width -->

            <!-- region display on new row -->
            <div class="form-group col-md-3">
              <label>{{'FORM_ITEM_COMMON_DISPLAY_ON_NEW_ROW' | translate}}</label>
              <br>
              <label class="switch switch-text switch-info" style="margin-left: 0.3rem">
                <input type="checkbox" class="switch-input"
                       [ngModelOptions]="{standalone: true}"
                       [(ngModel)]="fieldModel.displayOnNewRow">
                <span class="switch-label" data-on="&#xe957;" data-off="&#xe98e;"></span>
                <span class="switch-handle"></span>
              </label>
            </div>
            <!-- endregion display on new row -->

          </div> <!-- ./row end -->

          <div class="row">

            <!-- region Field validation type (admin) -->
            <div class="form-group col-md-3">
              <label>{{'FORM_ITEM_COMMON_ADMIN_FIELD_VALIDATION_TYPE' | translate}}</label>
              <select class="form-control"
                      [(ngModel)]="fieldModel.adminFormFieldValidationType"
                      [ngModelOptions]="{standalone: true}"
                      (change)="onAdminFormFieldValidationTypeChanged()">
                <option *ngFor="let vt of fieldValidationTypes" [ngValue]="vt">{{getFieldValidationTypeDictionaryKey(vt) | translate}}</option>
              </select>
            </div>
            <!-- endregion Field validation type (admin) -->

            <!-- region Field validation type (mobile) -->
            <div class="form-group col-md-3">
              <label>{{'FORM_ITEM_COMMON_MOBILE_FIELD_VALIDATION_TYPE' | translate}}</label>
              <select class="form-control"
                      [(ngModel)]="fieldModel.mobileFormFieldValidationType"
                      [ngModelOptions]="{standalone: true}"
                      (change)="onMobileFormFieldValidationTypeChanged()">
                <option *ngFor="let vt of fieldValidationTypes" [ngValue]="vt">{{getFieldValidationTypeDictionaryKey(vt) | translate}}</option>
              </select>
            </div>
            <!-- endregion Field validation type (mobile) -->

            <!-- region Field validation type (helpdesk) -->
            <div class="form-group col-md-3">
              <label>{{'FORM_ITEM_COMMON_HELPDESK_FIELD_VALIDATION_TYPE' | translate}}</label>
              <select class="form-control"
                      [(ngModel)]="fieldModel.helpdeskFormFieldValidationType"
                      [ngModelOptions]="{standalone: true}"
                      #helpdeskFormFieldValidationType="ngModel" id="helpdeskFormFieldValidationType" name="helpdeskFormFieldValidationType">
                <option *ngFor="let vt of fieldValidationTypes" [ngValue]="vt">{{getFieldValidationTypeDictionaryKey(vt) | translate}}</option>
              </select>
            </div>
            <!-- endregion Field validation type (helpdesk) -->

            <!-- region visible on master detail (mobile) -->
            <div class="form-group col-md-3">
              <label>{{'FORM_ITEM_COMMON_MOBILE_VISIBLE_ON_MASTER_DETAIL' | translate}}</label>
              <br>
              <label class="switch switch-text switch-info mb-0">
                <input type="checkbox" class="switch-input"
                       [(ngModel)]="fieldModel.mobileVisibleOnMasterDetail"
                       [ngModelOptions]="{standalone: true}">
                <span class="switch-label" data-on="&#xe957;" data-off="&#xe98e;"></span>
                <span class="switch-handle"></span>
              </label>
            </div>
            <!-- endregion visible on master detail (mobile) -->

          </div> <!-- ./row end -->

          <div class="row">

            <!-- region API export name -->
            <div class="form-group col-md-6" [ngClass]="{ 'has-danger': hasFieldError(Form.FieldValidatedField.API_EXPORT_NAME) || hasLocalFieldError('apiExportName')}">
              <label>{{'FORM_ITEM_COMMON_EXPORT_NAME' | translate}}</label>
              <input type="text" class="form-control"
                     maxlength="{{UiConstants.maximumVarcharLength}}"
                     [ngClass]="{ 'form-control-danger': hasFieldError(Form.FieldValidatedField.API_EXPORT_NAME) || hasLocalFieldError('apiExportName') }"
                     [(ngModel)]="fieldModel.apiExportName"
                     [disabled]="editableApiName"
                     formControlName="apiExportName"
                     (ngModelChange)="removeFieldError(Form.FieldValidatedField.API_EXPORT_NAME)"
                     placeholder="{{'FORM_ITEM_COMMON_EXPORT_NAME' | translate}}"
                     trim="blur" validateOnBlur [validateFormControl]="formGroup.controls['apiExportName']"
                     >
              <div class="form-control-feedback" *ngIf="hasFieldError(Form.FieldValidatedField.API_EXPORT_NAME)">
                {{getFieldErrorText(Form.FieldValidatedField.API_EXPORT_NAME)}}
              </div>
              <div class="form-control-feedback" *ngIf="hasLocalFieldError('apiExportName')">
                {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
              </div>
            </div>
            <!-- endregion API export name -->

            <!-- region PDF export name -->
            <div class="form-group col-md-6" [ngClass]="{ 'has-danger': hasFieldError(Form.FieldValidatedField.PDF_EXPORT_NAME) }">
              <label>{{'FORM_ITEM_COMMON_PDF_EXPORT_NAME' | translate}}</label>
              <input type="text" class="form-control"
                     maxlength="{{UiConstants.maximumVarcharLength}}"
                     [(ngModel)]="fieldModel.pdfExportName"
                     [disabled]="editableApiName"
                     formControlName="pdfExportName"
                     (ngModelChange)="removeFieldError(Form.FieldValidatedField.PDF_EXPORT_NAME)"
                     placeholder="{{'FORM_ITEM_COMMON_PDF_EXPORT_NAME' | translate}}"
                     [ngClass]="{ 'form-control-danger': hasFieldError(Form.FieldValidatedField.PDF_EXPORT_NAME) }">
              <div class="form-control-feedback" *ngIf="hasFieldError(Form.FieldValidatedField.PDF_EXPORT_NAME)">
                {{getFieldErrorText(Form.FieldValidatedField.PDF_EXPORT_NAME)}}
              </div>
            </div>
            <!-- endregion PDF export name -->

          </div> <!-- ./row end -->

          <div class="row">

            <!-- region placeholder -->
            <div class="form-group col-md-6">
              <label>{{'FORM_ITEM_COMMON_PLACEHOLDER' | translate}}</label>
              <input type="text" class="form-control"
                     maxlength="{{UiConstants.maximumVarcharLength}}"
                     [ngModelOptions]="{standalone: true}"
                     [(ngModel)]="fieldModel.placeholder"
                     placeholder="{{'FORM_ITEM_COMMON_PLACEHOLDER' | translate}}">
            </div>
            <!-- endregion placeholder -->

            <!-- region default value -->
            <div class="form-group col-md-6" [ngClass]="{ 'has-danger': hasFieldError(Form.FieldValidatedField.DEFAULT_VALUE) || hasLocalFieldError('defaultValue') || hasLocalFormError('validateLocalDateRange') }">
              <label>{{'FORM_ITEM_COMMON_DEFAULT_VALUE' | translate}}</label>
              <div class="input-group">
                <input type="text" class="form-control"
                       disabled="{{isDefaultDateDisabled()}}"
                       [(ngModel)]="fieldModel.defaultValue" formControlName="defaultValue"
                       ngbDatepicker #date_default_date="ngbDatepicker"
                       [ngClass]="{ 'form-control-danger': hasFieldError(Form.FieldValidatedField.DEFAULT_VALUE) || hasLocalFieldError('defaultValue') || hasLocalFormError('validateLocalDateRange') }"
                       placeholder="{{'FORM_ITEM_COMMON_DEFAULT_VALUE' | translate}}">
                <span class="add-on input-group-addon-gray" (click)="date_default_date.toggle()">
                      <i class="icomoon icomoon-calendar"></i>
                </span>
              </div>
              <div class="form-control-feedback" *ngIf="hasFieldError(Form.FieldValidatedField.DEFAULT_VALUE)">
                {{getFieldErrorText(Form.FieldValidatedField.DEFAULT_VALUE)}}
              </div>
              <div class="form-control-feedback" *ngIf="hasLocalFieldError('defaultValue') || hasLocalFormError('validateLocalDateRange')">
                <span *ngIf="hasLocalFieldError('defaultValue', 'validateLocalDate')">{{'COMMON_VALIDATION_MESSAGE_INVALID_LOCAL_DATE' | translate}}</span>
                <span *ngIf="hasLocalFormError('validateLocalDateRange')">{{'COMMON_VALIDATION_MESSAGE_INVALID_LOCAL_DATE_RANGE' | translate}}</span>
              </div>
            </div>
            <!-- endregion default value -->

          </div>

          <div class="row">

            <!-- region Description -->
            <div class="form-group col-md-6">
              <label>{{'COMMON_DESCRIPTION' | translate}}</label>
              <input type="text" class="form-control"
                     maxlength="{{UiConstants.maximumVarcharLength}}"
                     [ngModelOptions]="{standalone: true}"
                     [(ngModel)]="fieldModel.description"
                     placeholder="{{'COMMON_DESCRIPTION' | translate}}">
            </div>
            <!-- endregion Description -->

            <!-- region hint -->
            <div class="form-group col-md-6">
              <label>{{'FORM_ITEM_COMMON_HINT' | translate}}</label>
              <textarea rows="1" class="form-control" [(ngModel)]="fieldModel.hint"
                        [ngModelOptions]="{standalone: true}"
                        placeholder="{{'FORM_ITEM_COMMON_HINT' | translate}}"></textarea>
            </div>
            <!-- endregion hint -->

          </div>

          <h6 class="mb-1 mt-1">{{'FORM_ITEM_CREATE_DOCUMENT_EXTRA_DATA' | translate}}</h6>

          <div class="row">

            <!-- region min value -->
            <div class="form-group col-md-6" [ngClass]="{ 'has-danger': hasFieldError(Form.FieldValidatedField.MIN_VALUE) || hasLocalFieldError('minValue') || hasLocalFormError('validateLocalDateFromTo')}">
              <label>{{'FORM_ITEM_CREATE_DATE_MIN_DATE' | translate}}</label>
              <div class="input-group">
                <input type="text" class="form-control"
                       disabled="{{isMinValueDisabled()}}"
                       [(ngModel)]="fieldModel.minValue"
                       formControlName="minValue"
                       ngbDatepicker #date_min_date="ngbDatepicker"
                       placeholder="{{'FORM_ITEM_CREATE_DATE_MIN_DATE' | translate}}"
                       [ngClass]="{ 'form-control-danger': hasFieldError(Form.FieldValidatedField.MIN_VALUE) || hasLocalFieldError('minValue') || hasLocalFormError('validateLocalDateFromTo')}"
                       (ngModelChange)="removeFieldError(Form.FieldValidatedField.MIN_VALUE)">
                <span class="add-on input-group-addon-gray" (click)="date_min_date.toggle()">
                      <i class="icomoon icomoon-calendar"></i>
                </span>
              </div>
              <div class="form-control-feedback" *ngIf="hasFieldError(Form.FieldValidatedField.MIN_VALUE)">
                {{getFieldErrorText(Form.FieldValidatedField.MIN_VALUE)}}
              </div>
              <div class="form-control-feedback" *ngIf="hasLocalFieldError('minValue') || hasLocalFormError('validateLocalDateFromTo')">
                <span *ngIf="hasLocalFieldError('minValue', 'validateLocalDate')">{{'COMMON_VALIDATION_MESSAGE_INVALID_LOCAL_DATE' | translate}}</span>
                <span *ngIf="hasLocalFieldError('minValue', 'validateMaxLocalDate')">{{'COMMON_VALIDATION_MESSAGE_FUTURE_IS_NOT_VALID' | translate}}</span>
                <span *ngIf="hasLocalFormError('validateLocalDateFromTo')">{{'COMMON_VALIDATION_MESSAGE_INVALID_LOCAL_DATE_FROM_TO' | translate}}</span>
              </div>
            </div>
            <!-- endregion min value -->

            <!-- region max value -->
            <div class="form-group col-md-6" [ngClass]="{ 'has-danger': hasFieldError(Form.FieldValidatedField.MAX_VALUE) || hasLocalFieldError('maxValue') || hasLocalFormError('validateLocalDateFromTo') }">
              <label>{{'FORM_ITEM_CREATE_DATE_MAX_DATE' | translate}}</label>
              <div class="input-group">
                <input type="text" class="form-control"
                       [(ngModel)]="fieldModel.maxValue"
                       formControlName="maxValue"
                       disabled="{{isMaxValueDisabled()}}"
                       ngbDatepicker #date_max_date="ngbDatepicker"
                       [ngClass]="{ 'form-control-danger': hasFieldError(Form.FieldValidatedField.MAX_VALUE) || hasLocalFieldError('maxValue') || hasLocalFormError('validateLocalDateFromTo') }"
                       placeholder="{{'FORM_ITEM_CREATE_DATE_MAX_DATE' | translate}}">
                <span class="add-on input-group-addon-gray" (click)="date_max_date.toggle()">
                      <i class="icomoon icomoon-calendar"></i>
                </span>
              </div>
              <div class="form-control-feedback" *ngIf="hasFieldError(Form.FieldValidatedField.MAX_VALUE)">
                {{getFieldErrorText(Form.FieldValidatedField.MAX_VALUE)}}
              </div>
              <div class="form-control-feedback" *ngIf="hasLocalFieldError('maxValue') || hasLocalFormError('validateLocalDateFromTo')">
                <span *ngIf="hasLocalFieldError('maxValue', 'validateLocalDate')">{{'COMMON_VALIDATION_MESSAGE_INVALID_LOCAL_DATE' | translate}}</span>
                <span *ngIf="hasLocalFormError('validateLocalDateFromTo')">{{'COMMON_VALIDATION_MESSAGE_INVALID_LOCAL_DATE_FROM_TO' | translate}}</span>
              </div>
            </div>
            <!-- endregion max value -->

          </div> <!-- ./row end -->

          <div class="row">
            <!-- region default today-->
            <div class="form-group col-md-3">
              <label>{{'FORM_FIELD_DEFAULT_TODAY' | translate}}</label>
              <br>
              <label class="switch switch-text switch-info mb-0">
                <input type="checkbox" class="switch-input"
                       [ngModelOptions]="{standalone: true}"
                       [(ngModel)]="fieldModel.defaultToday"
                       (change)="dateDefaultTodayChanged()">
                <span class="switch-label" data-on="&#xe957;" data-off="&#xe98e;"></span>
                <span class="switch-handle"></span>
              </label>
            </div>
            <!-- endregion default today-->

            <!-- region disable past-->
            <div class="form-group col-md-3">
              <label>{{'FORM_FIELD_DISABLE_PAST_DATE' | translate}}</label>
              <br>
              <label class="switch switch-text switch-info mb-0">
                <input type="checkbox" class="switch-input"
                       [ngModelOptions]="{standalone: true}"
                       [(ngModel)]="fieldModel.disablePast"
                       (change)="dateDisablePastChanged()">
                <span class="switch-label" data-on="&#xe957;" data-off="&#xe98e;"></span>
                <span class="switch-handle"></span>
              </label>
            </div>
            <!-- endregion disable past-->

            <!-- region disable future-->
            <div class="form-group col-md-3">
              <label>{{'FORM_FIELD_DISABLE_FUTURE_DATE' | translate}}</label>
              <br>
              <label class="switch switch-text switch-info mb-0">
                <input type="checkbox" class="switch-input"
                       [ngModelOptions]="{standalone: true}"
                       [(ngModel)]="fieldModel.disableFuture"
                       (change)="dateDisableFutureChanged()"
                       [disabled]="isDisableFutureDisabled()">
                <span class="switch-label" data-on="&#xe957;" data-off="&#xe98e;"></span>
                <span class="switch-handle"></span>
              </label>
            </div>
            <!-- endregion disable future-->

            <!-- region show on receipt -->
            <div class="form-group col-md-3">
              <label>{{'FORM_ITEM_COMMON_SHOW_ON_RECEIPT' | translate}}
                <i class="icomoon icomoon-info info-icon" popover="{{'FORM_EDIT_SHOW_ON_RECEIPT_INFO' | translate}}"
                   triggers="mouseenter:mouseleave" container="body" placement="right"></i></label>
              <br>
              <label class="switch switch-text switch-info mb-0">
                <input type="checkbox" class="switch-input" [(ngModel)]="fieldModel.showOnReceipt"
                       [ngModelOptions]="{standalone: true}"
                       #showOnReceipt="ngModel" id="showOnReceipt" name="showOnReceipt">
                <span class="switch-label" data-on="&#xe957;" data-off="&#xe98e;"></span>
                <span class="switch-handle"></span>
              </label>
            </div>
            <!-- endregion show on receipt -->
          </div>

        </div> <!-- ./card-body end -->
      </div>

      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="closeDialog()">{{'COMMON_BUTTON_CANCEL' | translate}}</button>
        <button type="button" class="btn btn-primary" #functionButton (click)="saveField()">{{'COMMON_BUTTON_SAVE' | translate}}</button>
      </div>

    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div><!-- /.modal -->
