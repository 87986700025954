import { SiteTourBaseComponent, SiteTourFactory, SiteTourId } from '../site-tour.factory';
import { TourStep } from 'ngx-guided-tour';
import { SiteTourStringKeys } from '../site-tour-string-keys';

export class HomeTourFactory extends SiteTourFactory {

  protected getTourSteps(baseComponent?: SiteTourBaseComponent): TourStep[] {
    return [
      {
        selector: '#user-data',
        title: SiteTourStringKeys.SITE_TOUR_HOME_TOUR_USER_STEP_TITLE,
        content: SiteTourStringKeys.SITE_TOUR_HOME_TOUR_USER_STEP_CONTENT,
        orientation: 'left',
        action: () => {
        },
        closeAction: () => {
        },
        skipStep: false,
      },
      {
        selector: '#dashboard-user-cards',
        title: SiteTourStringKeys.SITE_TOUR_HOME_TOUR_CARD_STEP_TITLE,
        content: SiteTourStringKeys.SITE_TOUR_HOME_TOUR_CARD_STEP_CONTENT,
        orientation: 'bottom',
        action: () => {
        },
        closeAction: () => {
        },
        skipStep: false,
      },
      {
        selector: '#dashboard-task-chart-0',
        title: SiteTourStringKeys.SITE_TOUR_HOME_TOUR_TASK_CHART_STEP_TITLE,
        content: SiteTourStringKeys.SITE_TOUR_HOME_TOUR_TASK_CHART_STEP_CONTENT,
        orientation: 'bottom',
        action: () => {
        },
        closeAction: () => {
        },
        skipStep: false,
      },
      {
        selector: '#sidebar-nav-item-task-record',
        title: SiteTourStringKeys.SITE_TOUR_HOME_TOUR_TASK_MENU_STEP_TITLE,
        content: SiteTourStringKeys.SITE_TOUR_HOME_TOUR_TASK_MENU_STEP_CONTENT,
        orientation: 'right',
        action: () => {
        },
        closeAction: () => {
        },
        skipStep: false,
      },
    ];
  }

  protected get tourKey(): SiteTourId {
    return SiteTourId.HOME;
  }

}
