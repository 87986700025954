import { DqlQuery, Query } from '../field';

export class Vehicle {

  readonly id: Query.NumberField;

  readonly licence_plate: Query.StringField;

  constructor(prefix?: string) {
    prefix = prefix ? prefix : '';
    this.id = new DqlQuery.NumberField(prefix + 'id');
    this.licence_plate = new DqlQuery.StringField(prefix + 'licence_plate');
  }

}
