<div class="card">

  <div class="card-header">
    {{'TRANSPORT_TASKS' | translate}}
    <div class="card-actions">
      <a class="btn-setting table-header-action-button d-flex align-items-center px-1 w-auto font-weight-normal"
         (click)="refreshTransportTaskList()"
         title="{{'TRANSPORT_TASK_REFRESH' | translate}}">
        <i class="icomoon responsive-pr-1 icomoon-state-action-restart"></i>
        <div class="table-header-action-button-text">{{'TRANSPORT_TASK_REFRESH' | translate}}</div>
      </a>
      <a class="btn-setting table-header-action-button d-flex align-items-center px-1 w-auto font-weight-normal"
         (click)="showCreateTaskDialog()"
         *ngIf="canEdit() && rightModel.transportUpdate.hasRight()"
         title="{{'TRANSPORT_TASK_CREATE' | translate}}">
        <i class="icomoon responsive-pr-1 icomoon-add"></i>
        <div class="table-header-action-button-text">{{'TRANSPORT_TASK_CREATE' | translate}}</div>
      </a>
      <a class="btn-setting table-header-action-button d-flex align-items-center px-1 w-auto font-weight-normal"
         (click)="replanRoute()"
         title="{{'TRANSPORT_REPLAN_ROUTE' | translate}}"
         *ngIf="canEdit() && rightModel.transportUpdate.hasRight()">
        <i class="icomoon responsive-pr-1 icomoon-transport-map"></i>
        <div class="table-header-action-button-text">{{'TRANSPORT_REPLAN_ROUTE' | translate}}</div>
      </a>
      <a class="btn-setting table-header-action-button d-flex align-items-center px-1 w-auto font-weight-normal"
         (click)="optimizeWaypoints()"
         title="{{'TRANSPORT_TASK_OPTIMIZE_WAYPOINTS' | translate}}"
         *ngIf="canEdit() && rightModel.transportUpdate.hasRight()">
        <i class="icomoon responsive-pr-1 icomoon-status-change-alt-2"></i>
        <div class="table-header-action-button-text">{{'TRANSPORT_TASK_OPTIMIZE_WAYPOINTS' | translate}}</div>
      </a>
      <a class="btn-setting table-header-action-button d-flex align-items-center px-1 w-auto font-weight-normal"
         (click)="open()"
         title="{{'TRANSPORT_OPEN' | translate}}"
         *ngIf="transportState === 'UNDER_PLANNING' && rightModel.transportUse.hasRight()">
        <i class="icomoon responsive-pr-1 icomoon-start"></i>
        <div class="table-header-action-button-text">{{'TRANSPORT_OPEN' | translate}}</div>
      </a>
      <a class="btn-setting table-header-action-button d-flex align-items-center px-1 w-auto font-weight-normal"
         (click)="plan()"
         title="{{'TRANSPORT_PLAN' | translate}}"
         *ngIf="transportState === 'OPEN' && rightModel.transportUse.hasRight()">
        <i class="icomoon responsive-pr-1 icomoon-restart"></i>
        <div class="table-header-action-button-text">{{'TRANSPORT_PLAN' | translate}}</div>
      </a>
      <a class="btn-setting table-header-action-button d-flex align-items-center px-1 w-auto font-weight-normal"
         (click)="replan()"
         title="{{'TRANSPORT_PLAN' | translate}}"
         *ngIf="transportState === 'RUNNING' && rightModel.transportUse.hasRight()">
        <i class="icomoon responsive-pr-1 icomoon-restart"></i>
        <div class="table-header-action-button-text">{{'TRANSPORT_PLAN' | translate}}</div>
      </a>
      <a class="btn-setting table-header-action-button d-flex align-items-center px-1 w-auto font-weight-normal"
         (click)="continue()"
         title="{{'TRANSPORT_CONTINUE' | translate}}"
         *ngIf="transportState === 'UNDER_REPLANNING' && rightModel.transportUse.hasRight()">
        <i class="icomoon responsive-pr-1 icomoon-process-start"></i>
        <div class="table-header-action-button-text">{{'TRANSPORT_CONTINUE' | translate}}</div>
      </a>
      <a class="btn-setting table-header-action-button d-flex align-items-center px-1 w-auto font-weight-normal"
         (click)="close()"
         title="{{'TRANSPORT_CLOSE' | translate}}"
         *ngIf="transportState === 'FINISHED' && rightModel.transportUpdate.hasRight()">
        <i class="icomoon responsive-pr-1 icomoon-process-close"></i>
        <div class="table-header-action-button-text">{{'TRANSPORT_CLOSE' | translate}}</div>
      </a>
    </div><!-- /.card-actions -->

  </div>
  <div class="card-body">

    <div class="row">
      <div class="col-md-8 col-sm-12 col-xs-12 px-0">
        <div class="card-body py-quarter border-y mb-1">
          <div class="row">
            <div class="col-md-1 font-weight-bold">{{'TRANSPORT_TASK_ID' | translate}}</div>
            <div class="col-md-4 font-weight-bold">{{'TRANSPORT_TASK_NAME' | translate}}</div>
            <div class="col-md-3 font-weight-bold">{{'TRANSPORT_TASK_PLANNED_ACTUAL_ARRIVE_TIME' | translate}}</div>
            <div class="col-md-3 font-weight-bold">{{'TRANSPORT_TASK_PLANNED_ACTUAL_DEPARTURE_TIME' | translate}}</div>
            <div class="col-md-1"></div>
          </div>
        </div>
        <div cdkDropList
          [cdkDropListData]="transportTasks"
          (cdkDropListDropped)="onTransportTaskDropped($event)">
          <div *ngFor="let task of transportTasks; let i = index; let last = last;"
               (click)="onTaskSelected(i)"
               cdkDrag [cdkDragDisabled]="!isMovableTask(task)" cdkDragLockAxis="y" cdkDropListGroup [cdkDragData]="task"
               (cdkDragStarted)="dragStarted(task)" (cdkDragEnded)="dragEnded()"
               [ngClass]="{ 'pb-1': !last }">
            <div id="task_{{i}}" class="card card-accent-primary cursor-default-important border-left-0 border-right-0 border-bottom-0"
                 [ngClass]="{'task-delay': task.timetable.delayMinutes }">
              <div class="p-0 m-0"
                   cdkDropList [id]="task.id+''" [cdkDropListData]="task.shipments"
                   [cdkDropListConnectedTo]="taskIds" (cdkDropListDropped)="onShipmentDropped($event)">
                <div class="card-header border-x">
                  <div class="row" [ngClass]="{'task-state-error': hasStateError(task.index)}"
                       title="{{hasStateError(task.index) ? getStateError(task.index) : ''}}">
                    <div class="col-md-1 d-flex align-items-center justify-content-start flex-nowrap flex-row" cdkDragHandle>
                      <i *ngIf="rightModel.transportUpdate.hasRight()"
                         class="icomoon icomoon-menu-thick pr-1 icon-large cursor-pointer"
                         title="{{'COMMON_MOVE_TASK' | translate}}"
                         (mouseenter)="onDragEnter()" (mouseleave)="onDragLeave()"></i>
                      <span>{{task.index + 1}}.</span>
                    </div>
                    <div class="col-md-4">
                      <div class="d-flex flex-row flex-wrap">
                        <div>{{task.shippingPlace.name}}</div>
                        <i class="icomoon {{getTaskStateIcon(task.state)}} px-quarter icon-standard cursor-pointer"
                           title="{{getTaskStateNameKey(task.state) | translate}}"></i>
                        <i class="icomoon {{getGeocodeStatusIcon(task.shippingPlace.geocodeStatus)}} px-quarter icon-standard cursor-pointer"
                           title="{{getGeocodeStatusNameKey(task.shippingPlace.geocodeStatus) | translate}}"
                           [popover]="geocodeStatus" triggers="mouseenter:mouseleave" container="body"></i>
                        <ng-template #geocodeStatus>
                          <div class="d-flex align-items-center">
                            <i class="icomoon {{getGeocodeStatusIcon(task.shippingPlace.geocodeStatus)}} popover-icon pr-1"></i>
                            <div class="">
                              {{getGeocodeStatusNameKey(task.shippingPlace.geocodeStatus) | translate}}
                              <div class="table-text-subtitle">
                                <span>
                                  {{getGeocodeStatusCoordinateText(task.shippingPlace.coordinate)}}
                                </span>
                              </div>
                            </div>
                          </div>
                        </ng-template>
                      </div>
                      <div class="d-flex transport-label">{{Address.PostalAddressMapper.toString(task.shippingPlace.postalAddress,
                        postalAddressFormat)}}
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="d-flex flex-row flex-wrap">
                    <span class="transport-label">{{getPlannedArrivalTime(task).toUtcIsoString() | date:'MM. dd. HH:mm'}}
                        <span *ngIf="task.timetable.delayMinutes" class="transport-label-delay">({{getDelay(task.timetable.delayMinutes) | formattedDuration}})</span>
                      <i *ngIf="task.timetable.currentETA.isValid() || hasTimetableError(task.index)"
                         class="icomoon icomoon-time px-quarter icon-standard cursor-pointer"
                         [ngClass]="{'task-timetable-error': hasTimetableError(task.index)}"
                         title="{{hasTimetableError(task.index) ? getTimetableError(task.index) : ''}}"></i>
                    </span>
                      </div>
                      <div class="d-flex flex-row flex-wrap" *ngIf="task.timetable.realArrivalTime.isValid()">
                    <span class="transport-label">{{task.timetable.realArrivalTime.toUtcIsoString() | date:'HH:mm'}}
                    </span>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="d-flex flex-row flex-wrap">
                    <span class="transport-label">{{getPlannedDepartureTime(task).toUtcIsoString() | date:'MM. dd. HH:mm'}}
                      <i *ngIf="task.timetable.currentETD.isValid() || hasTimetableError(task.index)"
                         class="icomoon icomoon-time px-quarter icon-standard cursor-pointer"
                         [ngClass]="{'task-timetable-error': hasTimetableError(task.index)}"
                         title="{{hasTimetableError(task.index) ? getTimetableError(task.index) : ''}}"></i>
                    </span>
                      </div>
                      <div class="d-flex flex-row flex-wrap" *ngIf="task.timetable.realDepartureTime.isValid()">
                    <span class="transport-label">{{task.timetable.realDepartureTime.toUtcIsoString() | date:'HH:mm'}}
                      <span class="transport-label-delay">({{calculateDelay(getPlannedDepartureTime(task), task.timetable.realDepartureTime) | formattedDuration}})</span>
                    </span>
                      </div>
                    </div>
                    <div class="col-md-1 d-inline-flex align-items-center justify-content-end">
                      <i class="icomoon icons-table-item icomoon-detail icomoon-detail-table"
                         title="{{'COMMON_BUTTON_DETAILS' | translate}}" (click)="showDetail(task)"></i>
                      <i *ngIf="rightModel.transportUpdate.hasRight() && canPlanTime(task)"
                         class="icomoon icons-table-item icomoon-modify icomoon-modify-table"
                         title="{{'COMMON_BUTTON_EDIT' | translate}}" (click)="planTime(task)"></i>
                      <i *ngIf="isDeletable(task) && rightModel.transportUpdate.hasRight()" class="icomoon icons-table-item icomoon-trash icomoon-detail-table"
                         title="{{'COMMON_BUTTON_DELETE' | translate}}" (click)="deleteTask(task)"></i>
                    </div>
                  </div>
                </div>

                <div class="card-body p-0">
                  <ul class="list-group" (mouseleave)="shipmentLeft()" (mouseenter)="shipmentEntered()">
                    <div *ngFor="let shipment of task.shipments; let x = index; let first = first; let last = last"
                         cdkDrag [cdkDragDisabled]="!isMovableShipment(shipment)" cdkDragLockAxis="y" [cdkDragData]="shipment"
                         (cdkDragStarted)="dragStarted(task, shipment)" (cdkDragEnded)="dragEnded()">
                      <li
                          id="shipment_{{shipment.id}}"
                          [ngClass]="{'task-shipment-error': hasShipmentError(task.index, shipment.id), 'border-top-0': first}"
                          title="{{hasShipmentError(task.index, shipment.id) ? getShipmentError(task.index, shipment.id) : ''}}"
                          class="cursor-default-important list-group-item border-top-0 pr-0 ml-2">
                        <div class="row" style="width: 100%;">
                          <div class="col-md-1 d-flex align-items-center justify-content-start">
                            <i *ngIf="rightModel.transportUpdate.hasRight()"
                               class="icomoon icomoon-menu-thick icon-large cursor-pointer pr-1"
                               cdkDragHandle
                               title="{{'COMMON_BUTTON_MOVE' | translate}}"
                               (mouseenter)="onDragEnter()" (mouseleave)="onDragLeave()"></i>
                          </div>
                          <div class="col-md-4 col-lg-4">
                            <div class="text-truncate">{{shipment.demanderCompany ? shipment.demanderCompany.name : ''}}</div>
                            <div class="text-truncate transport-label-small">{{shipment.deliveryNoteNumber}}</div>
                          </div>
                          <div class="col-md-3 d-flex align-items-center justify-content-start">
                            <i class="icomoon {{getShipmentTypeIcon(shipment.type)}} px-quarter icon-large cursor-pointer"
                               title="{{getShipmentTypeNameKey(shipment.type) | translate}}"></i>
                            <div class="detail-label px-quarter">{{getShipmentTypeNameKey(shipment.type) | translate}}</div>
                          </div>
                          <div class="col-md-3 d-flex align-items-center justify-content-start">
                            <i class="icomoon {{getShipmentStateIcon(shipment.state, shipment.type)}} px-quarter icon-large cursor-pointer"
                               title="{{getShipmentStateNameKey(shipment.state, shipment.type) | translate}}"></i>
                            <div class="detail-label px-quarter"
                                 [ngClass]="{'shipment-success': getShipmentState(shipment.state, shipment.type) === 'DELIVERED'}">
                              {{getShipmentStateNameKey(shipment.state, shipment.type) | translate}}
                            </div>
                          </div>
                          <div class="col-md-1 d-inline-flex align-items-center justify-content-end pr-0">
                            <i *ngIf="isDeletableShipment(shipment) && rightModel.transportUpdate.hasRight()"
                               class="icomoon icons-table-item icomoon-trash icomoon-detail-table"
                               title="{{'COMMON_BUTTON_DELETE' | translate}}" (click)="deleteShipment(shipment)"></i>
                          </div>
                        </div>
                      </li>
                    </div>
                  </ul>
                </div>
              </div>


            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4 col-sm-12 col-xs-12 pr-0">
        <div *ngIf="directionsData.isValid()" class="mb-1">
          {{'TRANSPORT_DIRECTIONS_FULL_LENGTH' | translate}}<span><b>{{directionsData.duration | formattedDuration}} ({{directionsData.distanceKm}})</b></span>
        </div>
        <div #gmap style="height:90%;width:100%;min-height: 300px"></div>
      </div>
    </div>

  </div>
</div>
