/* eslint-disable */
import { Injectable } from '@angular/core';
import { EmptyMessage, IdentityMessage } from '../util/messages';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { BaseHttpService, ResourceHelper } from '../util/http-services';
import { ResourceQueryResult } from '../util/services';
import { CompanyResource } from '../company/company-resource.service';
import { HistoryLog } from '../history-log/history-log.service';
import { Vehicle } from './vehicle.service';

/* eslint-enable */

@Injectable()
export class VehicleResourceService extends BaseHttpService {

  query(request: VehicleResource.QueryRequest): Observable<ResourceQueryResult<VehicleResource.Vehicle>> {
    return this.http.get<ResourceQueryResult<VehicleResource.Vehicle>>(this.url, this.parseParams(request));
  }

  get(request: VehicleResource.GetRequest): Observable<VehicleResource.Vehicle> {
    return this.http.get<VehicleResource.Vehicle>(this.url + `${request.id}`);
  }

  create(request: VehicleResource.CreateRequest): Observable<IdentityMessage> {
    return this.http.post<IdentityMessage>(this.url, request);
  }

  update(request: VehicleResource.UpdateRequest): Observable<EmptyMessage> {
    return this.http.put(this.url + `${request.id}`, request);
  }

  setDisabled(request: VehicleResource.DisableRequest): Observable<EmptyMessage> {
    return this.http.patch(this.url + `${request.id}/disabled`, request);
  }

  history(request: CompanyResource.HistoryRequest): Observable<ResourceQueryResult<CompanyResource.HistoryItem>> {
    return this.http.get<ResourceQueryResult<CompanyResource.HistoryItem>>(
      this.url + `${request.id}/history-logs?with_read=${request.with_read}`,
      this.parseParams(request)
    );
  }

  constructor(private http: HttpClient, resourceHelper: ResourceHelper) {
    super(resourceHelper, '/vehicles/');
  }

}

export namespace VehicleResource {

  import VehiclePassType = Vehicle.VehiclePassType;

  export interface Vehicle {
    id: number;
    external_id: string;
    manufacturer?: string;
    model?: string;
    licence_plate: string;
    registration_date?: string;
    payload_kg?: number;
    total_weight_kg?: number;
    number_of_seats?: number;
    color?: string;
    comment?: string;
    etoll_required: boolean;
    registration_type?: string;
    owner_person_name?: string;
    keeper_person_name?: string;
    maturity_expiry_date?: string;
    company?: VehicleCompany;
    km_counter: number;
    disabled: boolean;
    passes: VehiclePass[];
  }

  export interface QueryRequest {
    id?: string;
    external_id?: string;
    manufacturer?: string;
    model?: string;
    registration_type?: string;
    licence_plate?: string;
    registration_date_from?: string;
    registration_date_to?: string;
    payload_kg_from?: number;
    payload_kg_to?: number;
    total_weight_kg_from?: number;
    total_weight_kg_to?: number;
    etoll_required?: boolean;
    disabled?: boolean;
    company_ids?: string;
    q?: string;
    fields?: string;
    order?: string;
    page_number?: number;
    number_of_items?: number;
    no_progress_bar?: boolean;
  }

  export interface GetRequest {
    id: number;
  }

  export interface CreateRequest {
    external_id?: string;
    manufacturer?: string;
    model?: string;
    licence_plate: string;
    registration_date?: string;
    payload_kg?: number;
    total_weight_kg?: number;
    number_of_seats?: number;
    color?: string;
    comment?: string;
    etoll_required: boolean;
    registration_type?: string;
    owner_person_name?: string;
    keeper_person_name?: string;
    maturity_expiry_date?: string;
    company_id?: number;
    km_counter: number;
    passes: VehiclePass[];
  }

  export interface UpdateRequest {
    id: number;
    external_id: string;
    manufacturer?: string;
    model?: string;
    licence_plate: string;
    registration_date?: string;
    payload_kg?: number;
    total_weight_kg?: number;
    number_of_seats?: number;
    color?: string;
    comment?: string;
    etoll_required: boolean;
    registration_type?: string;
    owner_person_name?: string;
    keeper_person_name?: string;
    maturity_expiry_date?: string;
    company_id?: number;
    km_counter: number;
    passes: VehiclePass[];
  }

  export interface DisableRequest {
    id: number;
    disabled: boolean;
  }

  export interface VehicleCompany {
    id: number;
    name: string;
  }

  export interface VehiclePass {
    type: VehiclePassType;
    name: string;
    validity_start_date: string;
    validity_end_date: string;
    note?: string;
  }

  export interface HistoryRequest {
    id: number;
    with_read: boolean;
    q?: string;
    order?: string;
    page_number?: number;
    number_of_items?: number;
  }

  export interface HistoryItem extends HistoryLog.HistoryItemBaseResource {
  }
}
