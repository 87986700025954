<div class="breadcrumb-container" [ngClass]="{ 'breadcrumb-container-compact': compactSidebar }" id="breadcrumb">
  <div class="breadcrumb-component">
    <app-breadcrumb
      [parents]="breadcrumbParents"
      [self]="breadcrumbSelf">
    </app-breadcrumb>
  </div>
</div>
<div class="breadcrumb-placeholder"></div>
<div class="container-horizontal-padding container-vertical-padding animated fadeIn">
  <div class="col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 p-0">
    <div class="card">

      <div class="card-header">
        {{'LEGACY_WORKFLOW_CREATE'|translate}}
      </div>

      <div class="card-body">
        <form class="form-horizontal bordered-row" (ngSubmit)="f.form.valid && createWorkflow()" #f="ngForm" novalidate>

          <div class="form-group row"
               [ngClass]="{ 'has-danger': hasFieldError(Workflow.ValidatedField.NAME) || (f.submitted && !name.valid) }">
            <div class="col-md-4">
              <label class="mw-100 col-form-label form-control-label required-field-label">
                {{'COMMON_NAME' | translate}}
              </label>
            </div>
            <div class="col-md-8">
              <div class="mw-100">
                <input type="text" class="form-control" placeholder="{{'COMMON_NAME' | translate}}"
                       [ngClass]="{ 'form-control-danger': hasFieldError(Workflow.ValidatedField.NAME) || (f.submitted && !name.valid) }"
                       [(ngModel)]="model.name" (ngModelChange)="removeFieldError(Workflow.ValidatedField.NAME)"
                       name="name"
                       id="name" #name="ngModel" required>
                <div class="form-control-feedback" *ngIf="hasFieldError(Workflow.ValidatedField.NAME)">
                  {{getFieldErrorText(Workflow.ValidatedField.NAME)}}
                </div>
                <div class="form-control-feedback" *ngIf="f.submitted && !name.valid">
                  {{'COMMON_VALIDATION_MESSAGE_REQUIRED'
                  | translate}}
                </div>
              </div>
            </div>
          </div>

          <div class="form-group row" [ngClass]="{ 'has-danger': hasFieldError(Workflow.ValidatedField.EXTERNAL_ID) }">
            <div class="col-md-4">
              <label class="mw-100 col-form-label form-control-label required-field-label">
                {{'WORKFLOW_CREATE_EDIT_EXTERNAL_ID' | translate}}
              </label>
            </div>
            <div class="col-md-8">
              <div class="mw-100">
                <input type="text" class="form-control" placeholder="{{'WORKFLOW_CREATE_EDIT_EXTERNAL_ID' | translate}}"
                       [ngClass]="{ 'form-control-danger': hasFieldError(Workflow.ValidatedField.EXTERNAL_ID) }"
                       [ngModelOptions]="{standalone: true}"
                       [(ngModel)]="model.externalId"
                       (ngModelChange)="removeFieldError(Workflow.ValidatedField.EXTERNAL_ID)" name="external_id"
                       id="external_id" #external_id="ngModel" required>
                <div class="form-control-feedback" *ngIf="hasFieldError(Workflow.ValidatedField.EXTERNAL_ID)">
                  {{getFieldErrorText(Workflow.ValidatedField.EXTERNAL_ID)}}
                </div>
              </div>
            </div>
          </div>

          <div class="form-group row">
            <div class="col-md-4">
              <label class="mw-100 col-form-label form-control-label">{{'COMMON_DESCRIPTION' | translate}}</label>
            </div>
            <div class="col-md-8">
              <div class="mw-100">
                <input type="text" class="form-control" placeholder="{{'COMMON_DESCRIPTION' | translate}}"
                       [(ngModel)]="model.description" name="description" id="description" #description="ngModel">
              </div>
            </div>
          </div>

          <app-icon-selector
          [(selectedIcon)]="model.icon"></app-icon-selector>
          <div>
            <div class="d-flex justify-content-end">
              <input type="submit" class="btn btn-primary" value="{{'COMMON_BUTTON_SAVE'|translate}}"/>
            </div>
          </div>

        </form>
      </div>
    </div>
  </div>
</div>
