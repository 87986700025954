/* eslint-disable */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DqlLogicalOperation, DqlLogicalOperationObject } from '../dql-search-container/dql-search-container.model';

/* eslint-enable */

@Component({
  selector: 'app-dql-logical-operation',
  templateUrl: './dql-logical-operation.component.html',
  styleUrls: ['./dql-logical-operation.component.scss']
})
export class DqlLogicalOperationComponent implements OnInit {

  @Input()
  operation: DqlLogicalOperationObject;

  @Input()
  index: number;

  operations: DqlLogicalOperation[] = [DqlLogicalOperation.AND, DqlLogicalOperation.OR];

  constructor() {
  }

  ngOnInit() {
  }

}
