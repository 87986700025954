/* eslint-disable */
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { StateName } from '../../../app.state-names';
import { TranslateService } from '@ngx-translate/core';
import { BreadcrumbParent } from '../../../shared/breadcrumb/breadcrumb/breadcrumb.component';
import { OffsetDateTime } from '../../../lib/util/dates';
import { MultiselectOptionItem, QueryFieldModel, UiConstants } from '../../../util/core-utils';
import { Observable } from 'rxjs';
import { Set } from 'immutable';
import { OrderType, QueryResult } from '../../../lib/util/services';
import { StockItemCategory, StockItemCategoryService } from '../../../lib/stock-item-category/stock-item-category.service';
import {
  StockItemCategorySearch,
  StockItemCategorySearchService
} from '../../../lib/stock-item-category/stock-item-category-search.service';
import { Strings } from '../../../lib/util/strings';
import { map } from 'rxjs/operators';
import { RightModel } from '../../../app.rights';
import { RightResolver, RightService } from '../../../lib/right.service';
import { Angular2Multiselects } from '../../../util/multiselect';
import { FileUploadDialogComponent } from '../../../shared/file-upload/dialog/file-upload-dialog.component';
import { saveAs } from 'file-saver';

/* eslint-enable */

@Component({
  selector: 'app-stock-item-category-list',
  templateUrl: './stock-item-category-list.component.html',
  styleUrls: ['./stock-item-category-list.component.scss']
})
export class StockItemCategoryListComponent implements OnInit, OnDestroy {

  StockItemCategory = StockItemCategory;
  UiConstants = UiConstants;

  queryModel: QueryFieldModel<StockItemCategory.OrderField> = new QueryFieldModel(StockItemCategory.OrderField.ID, OrderType.DESC);
  compactSidebar: boolean = document.querySelector('body')!.classList.contains('sidebar-compact');
  breadcrumbParents: BreadcrumbParent[] = [];
  breadcrumbSelf: string;
  showSearch: boolean = false;

  rightModel: RightModel = RightModel.empty();

  searchModel: StockItemCategorySearchModel = new StockItemCategorySearchModel();
  searchResult: StockItemCategorySearch.SearchDataResult;
  categoryList: CategoryModel[] = [];
  parentCategories: MultiselectOptionItem<number>[] = [];

  dropdownSettings: Angular2Multiselects.Settings;

  @ViewChild('importDialog', {static: true}) importDialog: FileUploadDialogComponent;
  uploadPath: string;

  constructor(
    private translateService: TranslateService,
    private stockItemCategoryService: StockItemCategoryService,
    private stockItemCategorySearchService: StockItemCategorySearchService,
    private rightService: RightService
  ) {
    this.uploadPath = '/stock-items/categories/import-xls';
  }

  ngOnInit() {
    this.initBreadCrumb();
    this.initDropdownSettings();
    this.loadRightModels();
    this.loadSearch(() => {
      this.showSearch = !this.searchModel.isEmpty();
      this.loadList();
      this.loadParentCategories();
    });
  }

  initBreadCrumb() {
    this.translateService.get('MENU_NAVBAR_STOCK_ITEM_CATEGORY_MANAGEMENT').subscribe(
      (result: string) => {
        this.breadcrumbSelf = result;
      }
    );
    this.translateService.get('MENU_NAVBAR_MENU_ADMINISTRATION').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.ADMIN_DASHBOARD});
      }
    );
  }

  initDropdownSettings() {
    this.dropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(true)
      .enableSearchFilter(true)
      .enableCheckAll(false)
      .remoteSearch(true)
      .build();
  }

  private loadRightModels() {
    this.rightService.getRightResolver().subscribe(
      (resolver: RightResolver) => {
        this.rightModel = RightModel.of(resolver);
      }
    );
  }

  deselectAll() {
    this.categoryList.forEach((category) => {
      category.selected = false;
    });
  }

  toggleEachStockItemCategory() {
    const eachStockItemCategorySelected = !this.eachStockItemCategorySelected;
    this.categoryList.forEach((category) => {
      category.selected = eachStockItemCategorySelected;
    });
  }

  get eachStockItemCategorySelected(): boolean {
    if (this.categoryList.length === 0) {
      return false;
    }
    let selected: boolean | undefined = true;
    this.categoryList.forEach((category) => {
      selected = selected && category.selected;
    });
    return selected;
  }

  loadList(pageNumber?: number) {
    const requestedPage = pageNumber ? pageNumber : this.queryModel.currentPage;
    this.stockItemCategoryService.query(this.getQueryRequest(requestedPage))
      .subscribe((result: QueryResult<StockItemCategory.StockItemCategory>) => {
        const ids: number[] = [];
        result.items.toArray().forEach(c => {
          if (c.parentId) {
            ids.push(c.parentId);
          }
        })
        if (ids.length > 0) {
          this.stockItemCategoryService.query({
            id: Set.of(...ids)
          }).subscribe(parentResult => {
            this.categoryList = [];
            result.items.forEach((category: StockItemCategory.StockItemCategory) => {
              const categoryModel = new CategoryModel();
              categoryModel.id = category.id;
              categoryModel.externalId = category.externalId;
              if (category.parentId) {
                categoryModel.parentCategory = parentResult.items.toArray().find(c => c.id === category.parentId)!.name;
              }
              categoryModel.name = category.name;
              categoryModel.creationTime = category.creationTime;
              this.categoryList.push(categoryModel);
            });
          });
        }
        else {
          this.categoryList = [];
          result.items.forEach((category: StockItemCategory.StockItemCategory) => {
            const categoryModel = new CategoryModel();
            categoryModel.id = category.id;
            categoryModel.externalId = category.externalId;
            categoryModel.name = category.name;
            categoryModel.creationTime = category.creationTime;
            this.categoryList.push(categoryModel);
          });
        }
        this.queryModel.currentPage = requestedPage;
        this.queryModel.totalNumberOfItems = result.pagingResult.totalNumberOfItems;
        this.queryModel.currentNumberOfItems = result.pagingResult.currentNumberOfItems;
      });
  }

  loadParentCategories(q?: string) {
    this.stockItemCategoryService.query({
      name: q ? Strings.undefinedOrNonEmpty(q) : undefined,
      orders: Set.of({field: StockItemCategory.OrderField.NAME, type: OrderType.ASC}),
      paging: {
        numberOfItems: UiConstants.autocompletePageSize,
        pageNumber: 1
      },
      noProgressBar: true
    }).subscribe(result => {
      this.parentCategories = result.items.toArray().map(c => ({id: c.id, itemName: c.name}));
    })
  }

  orderBy(field: StockItemCategory.OrderField) {
    this.queryModel.onOrderFieldChanged(field);
    this.loadList(1);
  }

  toggleSearch() {
    this.showSearch = !this.showSearch;
  }

  onSearchClicked() {
    this.loadList(1);
  }

  onSearchReset() {
    this.stockItemCategorySearchService.resetSearchData({}).subscribe(
      (result) => {
        this.loadSearch(() => {
          this.showSearch = true;
          this.loadList(1);
        });
      }
    );
  }

  pageChanged(selectedPage: number) {
    this.loadList(selectedPage);
  }

  itemsPerPageChanged(itemsPerPage: number) {
    this.queryModel.itemsPerPage = itemsPerPage;
    this.loadList(1);
  }

  private loadSearch(completion: () => void) {
    const obs: Observable<SearchLoadResult> = this.stockItemCategorySearchService.getSearchData({}).pipe(map(
      (storedSearchData) => {
        const result: SearchLoadResult = {
          storedSearchData: storedSearchData
        };
        return result;
      }));
    obs.subscribe(
      (result: SearchLoadResult) => {
        this.searchResult = result.storedSearchData;
        this.postInitSearch(result.storedSearchData, completion);
      }
    );
  }

  private postInitSearch(storedSearchData: StockItemCategorySearch.SearchDataResult, completion: () => void) {
    this.queryModel.itemsPerPage = storedSearchData.searchData.itemsPerPage;
    this.queryModel.currentPage = storedSearchData.searchData.pageNumber;
    this.queryModel.setOrder(storedSearchData.searchData.order);
    this.searchModel.id = storedSearchData.searchData.id;
    this.searchModel.externalId = storedSearchData.searchData.externalId;
    this.searchModel.parentCategory = storedSearchData.searchData.parentCategory;
    this.searchModel.name = storedSearchData.searchData.name;
    completion()
  }

  private saveSearch() {
    const request = {
      searchData: {
        itemsPerPage: this.queryModel.itemsPerPage,
        pageNumber: this.queryModel.currentPage,
        order: this.queryModel.getOrder(),
        id: this.searchModel.id,
        externalId: this.searchModel.externalId,
        parentCategory: this.searchModel.parentCategory,
        name: this.searchModel.name
      }
    };
    this.stockItemCategorySearchService.setSearchData(request).subscribe(
      (result) => {
      },
      (error) => {
      }
    );
  }

  deleteCategory(id: number) {
    this.stockItemCategoryService.delete({
      id: id
    }).subscribe(result => {
      this.loadList();
    });
  }

  exportXlsTemplate() {
    this.stockItemCategoryService.exportXlsTemplate().subscribe(
      res => {
        saveAs(res.getBlob(), res.getFileName('categories-template-xls.xlsx'));
      }
    );
  }

  exportXls() {
    const ids: number[] = this.categoryList.filter(item => !!item.selected).map(item => item.id);
    let request: StockItemCategory.QueryRequest;
    if (ids.length === 0) {
      request = this.getQueryRequest();
    }
    else {
      request = {
        id: Set.of(...ids)
      };
    }
    this.stockItemCategoryService.exportXls(request).subscribe(
      res => {
        saveAs(res.getBlob(), res.getFileName('stock-item.xlsx'));
      }
    );
  }

  public onImportSuccess(succeeded: boolean) {
    if (succeeded) {
      this.loadList(1);
    }
  }

  ngOnDestroy() {
    this.saveSearch();
  }


  private getQueryRequest(requestedPage?: number): StockItemCategory.QueryRequest {
    const order = this.queryModel.getOrder();
    const idSet: number[] = [];
    if (Strings.undefinedOrNonEmpty(this.searchModel.id)) {
      idSet.push(+this.searchModel.id);
    }
    return {
      id: idSet.length > 0 ? Set.of(...idSet) : undefined,
      externalId: Strings.undefinedOrNonEmpty(this.searchModel.externalId),
      name: Strings.undefinedOrNonEmpty(this.searchModel.name),
      parentId: this.searchModel.parentCategoryId,
      orders: Set.of(order),
      paging: requestedPage ? {
        pageNumber: requestedPage,
        numberOfItems: this.queryModel.itemsPerPage
      } : undefined
    }
  }
}

class CategoryModel {
  id: number;
  name: string = '';
  parentCategory: string = '';
  externalId: string = '';
  creationTime: OffsetDateTime;
  selected: boolean = false;
}

class StockItemCategorySearchModel {
  id: string = '';
  externalId: string = '';
  parentCategory: MultiselectOptionItem<number>[] = [];
  name: string = '';

  get parentCategoryId(): number | undefined {
    return this.parentCategory.length > 0 ? this.parentCategory[0].id : undefined;
  }

  public isEmpty(): boolean {
    return this.id.length === 0
      && this.externalId.length === 0
      && this.parentCategory.length === 0
      && this.name.length === 0
      ;
  }

  public clear() {
    this.id = '';
    this.externalId = '';
    this.parentCategory = [];
    this.name = '';
  }

  constructor() {
  }

}

interface SearchLoadResult {
  storedSearchData: StockItemCategorySearch.SearchDataResult,
}
