import { Component, Injector, Input, OnDestroy, OnInit } from '@angular/core';
import { MultiselectOptionItem, OwnerUserItemFactory, QueryFieldModel, UiConstants, } from '../../../util/core-utils';
import { OrderType, ResourceQueryResult, Services } from '../../../lib/util/services';
import { SearchUtils } from '../../../util/search-utils';
import { Angular2Multiselects } from '../../../util/multiselect';
import { StockLogSearch, StockLogSearchService } from '../../../lib/stock-log-search-service';
import { RightService } from '../../../lib/right.service';
import { Models } from '../../../util/model-utils';
import {
  StockRecordLog,
  StockRecordLogQuery,
  StockRecordLogService,
  StockRecordLogSource,
  stockRecordLogSources
} from '../../../lib/stock/stock-record-log.service';
import { StockItem, StockItemService } from '../../../lib/stock/stock-item.service';
import { Stock, StockService } from '../../../lib/stock/stock.service';
import { OffsetDateTime } from '../../../lib/util/dates';
import { Set } from 'immutable';
import { AuthService } from '../../../lib/auth.service';
import { StockRecordService } from '../../../lib/stock/stock-record.service';
import { Strings } from '../../../lib/util/strings';
import { User, UserService } from '../../../lib/user.service';
import { Observable } from 'rxjs';
import { AssigneeType } from '../../../shared/assignee-table-cell/assignee-table-cell.component';
import { TaskRecordMultiselectProvider } from '../../../lib/task/task-record-multiselect.provider';
import { StockItemUtils } from '../../../util/stock/stock-item-utils';

@Component({
  selector: 'app-stock-log-list',
  templateUrl: './stock-log-list.component.html',
  styleUrls: ['./stock-log-list.component.scss']
})
export class StockLogListComponent extends SearchUtils.SearchableList<StockLogSearch.Model> implements OnInit, OnDestroy {
  StockRecordLog = StockRecordLog;
  StockLogListType = StockLogListType;
  AssigneeType = AssigneeType;
  StockItemUtils = StockItemUtils;

  @Input()
  type: StockLogListType;

  @Input()
  resourceId?: number;

  searchModel: StockLogSearch.Model = new StockLogSearch.Model();
  queryModel: QueryFieldModel<StockRecordLog.OrderField> = new QueryFieldModel(StockRecordLog.OrderField.ID, OrderType.DESC);

  stockRecordLogList: StockRecordLog[] = [];

  stockList: MultiselectOptionItem<number>[] = [];
  userList: MultiselectOptionItem<number>[] = [];
  taskRecordList: MultiselectOptionItem<number>[] = [];
  stockItemList: MultiselectOptionItem<number>[] = [];
  sourceList: MultiselectOptionItem<StockRecordLogSource>[] = [];
  dropdownSettings: Angular2Multiselects.Settings;
  sourceDropdownSettings: Angular2Multiselects.Settings;

  headingKey: string = '';

  constructor(
    private authService: AuthService,
    private stockItemService: StockItemService,
    private stockService: StockService,
    private stockRecordService: StockRecordService,
    private userService: UserService,
    private taskRecordMultiselectProvider: TaskRecordMultiselectProvider,
    private rightService: RightService,
    private stockRecordLogService: StockRecordLogService,
    private stockLogSearchService: StockLogSearchService,
    private ownerUserItemFactory: OwnerUserItemFactory,
    injector: Injector
  ) {
    super(StockLogSearch.Model, injector);
  }

  ngOnInit() {
    this.headingKey = stockLogListTypes.find(t => t.type === this.type)!.stringKey;
    this.loadSources();
    this.initSearch();
    this.initDropDown();
  }

  private loadSources() {
    this.sourceList = [];
    stockRecordLogSources.forEach(t => {
      const item: MultiselectOptionItem<StockRecordLogSource> = {
        id: t.source,
        itemName: '....'
      };
      this.sourceList.push(item);
      this.translateService.get(t.stringKey).subscribe((text: string) => {
        item.itemName = text;
      });
    });
  }

  initDropDown() {
    this.dropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(false)
      .enableSearchFilter(true)
      .enableCheckAll(true)
      .remoteSearch(true)
      .build();
    this.sourceDropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(true)
      .enableSearchFilter(true)
      .enableCheckAll(false)
      .build();
  }

  onFirstSearchOpen(): void {
    this.loadStocks();
    this.loadUsers();
    this.loadStockItems();
  }

  loadSearch(completion: () => void) {
    this.stockLogSearchService.getSearchData({
      type: this.type,
      resourceId: this.resourceId
    }).subscribe(
      (result: StockLogSearch.SearchDataResult) => {
        this.queryModel.itemsPerPage = result.searchData.itemsPerPage;
        this.queryModel.currentPage = result.searchData.pageNumber;
        this.queryModel.setOrder(result.searchData.order);
        this.searchModel.stock = result.searchData.stock;
        this.searchModel.editor = result.searchData.editor;
        this.searchModel.stockItem = result.searchData.stockItem;
        this.searchModel.creationTimeFrom = result.searchData.creationTimeFrom;
        this.searchModel.creationTimeTo = result.searchData.creationTimeTo;
        this.searchModel.source = result.searchData.source;
        completion();
      }
    );
  }

  loadList(pageNumber?: number) {
    const requestedPage = pageNumber ? pageNumber : this.queryModel.currentPage;
    const request = this.createRequest(pageNumber);
    this.getQueryObservable(request).subscribe((result: ResourceQueryResult<StockRecordLog>) => {
      this.stockRecordLogList = result.items;
      this.queryModel.currentPage = requestedPage;
      this.queryModel.totalNumberOfItems = result.pagingResult.totalNumberOfItems;
      this.queryModel.currentNumberOfItems = result.pagingResult.currentNumberOfItems;
    });
  }

  private createRequest(pageNumber?: number): StockRecordLogQuery {
    let stockId: string | undefined;
    let stockItemId: string | undefined;
    switch (this.type) {
      case StockLogListType.STOCK:
        stockId = this.resourceId!.toString();
        stockItemId = Strings.undefinedOrNonEmpty(this.searchModel.stockItem.map(s => s.id).join(','));
        break;
      case StockLogListType.STOCK_ITEM:
        stockId = Strings.undefinedOrNonEmpty(this.searchModel.stock.map(s => s.id).join(','));
        stockItemId = this.resourceId!.toString();
        break;
      default:
        stockId = Strings.undefinedOrNonEmpty(this.searchModel.stock.map(s => s.id).join(','));
        stockItemId = Strings.undefinedOrNonEmpty(this.searchModel.stockItem.map(s => s.id).join(','));
        break;
    }
    const requestedPage = pageNumber ? pageNumber : this.queryModel.currentPage;
    const order = this.queryModel.getOrder();
    const creationFrom: OffsetDateTime = Models.parseDateTimeFrom(this.searchModel.creationTimeFrom);
    const creationTo = Models.parseDateTimeFrom(this.searchModel.creationTimeTo);
    return {
      stock_id: stockId,
      editor_id: Strings.undefinedOrNonEmpty(this.searchModel.editor.map(s => s.id).join(',')),
      stock_item_id: stockItemId,
      task_record_id: Services.createIdParameter(this.searchModel.taskRecordIds),
      creation_time_from: creationFrom.isValid() ? creationFrom.toUtcIsoString() : undefined,
      creation_time_to: creationTo.isValid() ? creationTo.toUtcIsoString() : undefined,
      source: this.searchModel.source.length > 0 ? this.searchModel.source[0].id : undefined,
      page_number: requestedPage,
      number_of_items: this.queryModel.itemsPerPage,
      order: Services.createOrderFieldParameter(StockRecordLog.Keys.toOrderFieldKey, Set.of(order)),
    };
  }

  private getQueryObservable(request: StockRecordLogQuery): Observable<ResourceQueryResult<StockRecordLog>> {
    switch (this.type) {
      case StockLogListType.STOCK:
        return this.stockService.stockRecordLogQuery(request);
      case StockLogListType.STOCK_ITEM:
        return this.stockItemService.stockRecordLogQuery(request);
      default:
        return this.stockRecordLogService.query(request);
    }
  }

  orderBy(field: StockRecordLog.OrderField) {
    this.queryModel.onOrderFieldChanged(field);
    this.loadList(1);
  }

  itemsPerPageChanged(itemsPerPage: number) {
    this.queryModel.itemsPerPage = itemsPerPage;
    this.loadList(1);
  }

  pageChanged(selectedPage: number) {
    this.loadList(selectedPage);
  }

  onSearchClicked() {
    this.loadList(1);
  }

  onSearchReset() {
    this.stockLogSearchService.resetSearchData({
      type: this.type,
      resourceId: this.resourceId
    }).subscribe(
      (result) => {
        this.loadSearch(() => {
          this.loadList(1);
        });
      }
    );
  }

  private saveSearch() {
    const request = {
      type: this.type,
      resourceId: this.resourceId,
      searchData: {
        itemsPerPage: this.queryModel.itemsPerPage,
        pageNumber: this.queryModel.currentPage,
        order: this.queryModel.getOrder(),
        stock: this.searchModel.stock,
        editor: this.searchModel.editor,
        stockItem: this.searchModel.stockItem,
        creationTimeFrom: this.searchModel.creationTimeFrom,
        creationTimeTo: this.searchModel.creationTimeTo,
        source: this.searchModel.source
      }
    };
    this.stockLogSearchService.setSearchData(request).subscribe(
      (result) => {
      },
      (error) => {
      }
    );
  }

  loadStocks(q?: string) {
    this.stockService.query({
      name: q ? Strings.undefinedOrNonEmpty(q) : undefined,
      order: Services.createOrderFieldParameter(Stock.Keys.toOrderFieldKey,
        Set.of({field: Stock.OrderField.NAME, type: OrderType.ASC})),
      page_number: 1,
      number_of_items: UiConstants.autocompletePageSize,
      no_progress_bar: true
    }).subscribe((result: ResourceQueryResult<Stock>) => {
      this.stockList = result.items.map(s => ({id: s.id, itemName: s.name}));
    });
  }

  loadUsers(q?: string) {
    this.userService.query({
      fields: ['id', 'person_name', 'user_name'].join(','),
      q: q ? Strings.undefinedOrNonEmpty(q) : undefined,
      order: Services.createOrderFieldParameter(User.Keys.toOrderFieldKey,
        Set.of({field: User.OrderField.PERSON_NAME, type: OrderType.ASC})),
      page_number: 1,
      number_of_items: UiConstants.autocompletePageSize,
      no_progress_bar: true
    }).subscribe(
      (result: ResourceQueryResult<User>) => {
        this.userList = result.items.map(u => ({id: u.id, itemName: u.person_name + ' (' + u.user_name + ')'}));
      }
    );
  }

  loadTaskRecords(q?: string) {
    this.taskRecordMultiselectProvider.loadActive(q).subscribe(
      (result: MultiselectOptionItem<number>[]) => {
        this.taskRecordList = result;
      }
    );
  }

  loadStockItems(q?: string) {
    this.stockItemService.query({
      name: q ? Strings.undefinedOrNonEmpty(q) : undefined,
      order: Services.createOrderFieldParameter(StockItem.Keys.toOrderFieldKey,
        Set.of({field: StockItem.OrderField.NAME, type: OrderType.ASC})),
      page_number: 1,
      number_of_items: UiConstants.autocompletePageSize,
      no_progress_bar: true
    }).subscribe((result: ResourceQueryResult<StockItem>) => {
      this.stockItemList = result.items.map(i => ({id: i.id, itemName: i.name}));
    });
  }

  getSourceText(source: StockRecordLogSource): string {
    return this.sourceList.find(s => s.id === source)!.itemName;
  }

  ngOnDestroy() {
    this.saveSearch();
  }

}

export enum StockLogListType {
  STOCK_LOG,
  STOCK,
  STOCK_ITEM,
}

export interface StockLogListTypeObject {
  type: StockLogListType;
  stringKey: string;
}

export const stockLogListTypes: StockLogListTypeObject[] = [
  {type: StockLogListType.STOCK_LOG, stringKey: 'STOCK_RECORD_LOG_PANEL_HEADING_LIST'},
  {type: StockLogListType.STOCK, stringKey: 'STOCK_RECORD_LOG_PANEL_HEADING_STOCK'},
  {type: StockLogListType.STOCK_ITEM, stringKey: 'STOCK_RECORD_LOG_PANEL_HEADING_STOCK_ITEM'},
];
