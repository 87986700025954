<div bsModal [config]="UiConstants.modalConfig" #dialog="bs-modal"
     class="modal fade" tabindex="-1"
     role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <!-- region Header -->
      <div class="modal-header">
        <h4 class="modal-title">{{'FORM_ITEM_UNKNOWN_TITLE' | translate}}</h4>
        <button type="button" class="close" (click)="cancelDialog()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <!-- endregion Header -->
      <!-- region Body -->
      <div class="modal-body">
        <div class="form-group">
          <h5>{{'FORM_ITEM_UNKNOWN_MESSAGE' | translate}}</h5>
        </div>
      </div>
      <!-- endregion Body -->
      <!-- region Footer buttons -->
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="cancelDialog()">{{'COMMON_BUTTON_CANCEL' | translate}}</button>
      </div>
      <!-- endregion Footer buttons -->
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div><!-- /.modal -->
