import { Component, OnInit } from '@angular/core';
import { UiConstants } from '../../util/core-utils';
import { BugReportSearchService } from '../../lib/helpdesk/bug-report/bug-report-search-service';
import { UIRouter } from '@uirouter/angular';
import { StateName } from '../../app.state-names';
import {RightResolver, RightService} from "../../lib/right.service";
import {RightModel} from "../../app.rights";

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class HelpdeskDashboardComponent implements OnInit {

  UiConstants = UiConstants;

  bugReportQuery: string = '';

  rightModel: RightModel = RightModel.empty();

  constructor(
    private bugReportSearchService: BugReportSearchService,
    private rightService: RightService,
    private router: UIRouter
  ) { }

  ngOnInit(): void {
    this.loadRightModels();
  }

  private loadRightModels() {
    this.rightService.getRightResolver().subscribe(
      (resolver: RightResolver) => {
        this.rightModel = RightModel.of(resolver);
      }
    );
  }


  onBugReportSearch() {
    this.bugReportSearchService.setQuery(this.bugReportQuery).subscribe(result => {
      this.router.stateService.go(StateName.HELPDESK_BUG_REPORT_LIST);
    });
  }

}
