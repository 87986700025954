/* eslint-disable */
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { BaseHttpService, ResourceHelper } from '../../util/http-services';
import { ResourceQueryResult } from '../../util/services';
import { EmptyMessage } from '../../util/messages';

/* eslint-enable */

@Injectable()
export class InvoiceBalanceResourceService extends BaseHttpService {

  query(request: InvoiceBalanceResource.QueryRequest): Observable<ResourceQueryResult<InvoiceBalanceResource.InvoiceBalance>> {
    return this.http.get<ResourceQueryResult<InvoiceBalanceResource.InvoiceBalance>>(this.url, this.parseParams(request));
  }

  get(request: InvoiceBalanceResource.GetRequest): Observable<InvoiceBalanceResource.InvoiceBalance> {
    return this.http.get<InvoiceBalanceResource.InvoiceBalance>(this.url + `${request.invoice_balance_id}`);
  }

  create(request: InvoiceBalanceResource.CreateRequest): Observable<InvoiceBalanceResource.InvoiceBalance> {
    return this.http.post<InvoiceBalanceResource.InvoiceBalance>(this.url, request);
  }

  delete(request: InvoiceBalanceResource.GetRequest): Observable<EmptyMessage> {
    return this.http.delete<EmptyMessage>(this.url + `${request.invoice_balance_id}`);
  }

  constructor(private http: HttpClient, resourceHelper: ResourceHelper) {
    super(resourceHelper, '/invoice/balances/');
  }

}

export namespace InvoiceBalanceResource {

  export interface InvoiceBalance {
    id: number,
    creation_time: string,
    update_time: string,
    update_count: number,
    starting_date: string, // date
    starting_balance: number,
    current_balance: number,
    revenue: number,
    expense: number,
    valid_invoice_payment_types: string[],
    currency_code: string
  }

  export interface QueryRequest {
    id?: string,
    q?: string,
    order?: string,
    page_number?: number,
    number_of_items?: number,
    no_progress_bar?: boolean,
    starting_date_from?: string,
    starting_date_to?: string,
  }

  export interface GetRequest {
    invoice_balance_id: number;
  }

  export interface CreateRequest {
    starting_date: string, // date
    starting_balance: number,
    valid_invoice_payment_types: string[],
    currency_code: string
  }
}
