import { Injectable } from '@angular/core';
import { MultiselectProvider } from '../multiselect-provider';
import { MultiselectOptionItem, UiConstants } from '../../util/core-utils';
import { Observable, Observer } from 'rxjs';
import { OrderType, ResourceQueryResult, Services } from '../util/services';
import { Set } from 'immutable';
import { StockItemService } from './stock-item.service';
import { StockItemUnitOfMeasure } from './stock-item-unit-of-measure';

@Injectable()
export class StockItemUnitOfMeasureMultiselectProvider implements MultiselectProvider {

  private multiselectQueryRequest: StockItemUnitOfMeasure.StockItemUnitOfMeasureQuery = {
    page_number: 1,
    number_of_items: UiConstants.autocompletePageSize,
    no_progress_bar: true,
    order: Services.createOrderFieldParameter(StockItemUnitOfMeasure.Keys.toOrderFieldKey,
      Set.of({field: StockItemUnitOfMeasure.OrderField.UNIT_NAME, type: OrderType.ASC})),
    disabled: undefined,
    q: undefined,
    include_base_unit: false,
    stock_item_id: -1
  };

  constructor(private stockItemService: StockItemService) {
  }

  load(queryRequest: StockItemUnitOfMeasure.StockItemUnitOfMeasureQuery): Observable<StockItemUnitOfMeasureMultiselectOptionItem[]> {
    return Observable.create((observer: Observer<StockItemUnitOfMeasureMultiselectOptionItem[]>) => {
      this.stockItemService.queryUnitOfMeasures(queryRequest)
        .subscribe((result: ResourceQueryResult<StockItemUnitOfMeasure.StockItemUnitOfMeasure>) => {
            observer.next(result.items.map(item => this.toMultiselectOptionItem(item!)));
          },
          (error: Error) => {
            observer.error(error);
          },
          () => {
            observer.complete();
          });
    });
  }

  loadAll(stockItemId: number, predicate?: string): Observable<StockItemUnitOfMeasureMultiselectOptionItem[]> {
    const queryRequest = Object.assign({}, this.multiselectQueryRequest);
    queryRequest.q = predicate;
    queryRequest.stock_item_id = stockItemId;
    return this.load(queryRequest);
  }

  loadActive(stockItemId: number, predicate?: string): Observable<StockItemUnitOfMeasureMultiselectOptionItem[]> {
    const queryRequest = Object.assign({}, this.multiselectQueryRequest);
    queryRequest.q = predicate;
    queryRequest.disabled = false;
    queryRequest.stock_item_id = stockItemId;
    return this.load(queryRequest);
  }

  //deprecated
  getById(id: number): Observable<StockItemUnitOfMeasureMultiselectOptionItem> {
    throw new Error('do not use this function');
  }

  getByIdAndStockItem(stockItemId: number, id: number): Observable<StockItemUnitOfMeasureMultiselectOptionItem> {
    throw new Error('do not use this function');
    return Observable.create((observer: Observer<StockItemUnitOfMeasureMultiselectOptionItem>) => {
      this.stockItemService.getUnitOfMeasure({
        stock_item_id: stockItemId,
        id: id
      }).subscribe((result: StockItemUnitOfMeasure.StockItemUnitOfMeasure) => {
          observer.next(this.toMultiselectOptionItem(result!));
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  getByIds(ids: number[]): Observable<StockItemUnitOfMeasureMultiselectOptionItem[]> {
    const queryRequest = Object.assign([], this.multiselectQueryRequest);
    queryRequest.id = ids.join(',');
    return this.load(queryRequest);
  }

  public toMultiselectOptionItem(item: StockItemUnitOfMeasure.StockItemUnitOfMeasure): StockItemUnitOfMeasureMultiselectOptionItem {
    const ret = new StockItemUnitOfMeasureMultiselectOptionItem();
    ret.id = item.id;
    ret.itemName = item.unit_name;
    ret.disabled = item.disabled;
    if (!item.base_unit) {
      ret.itemSubtitle = 'x' + item.unit_conversion;
    }
    ret.code = item.code;
    ret.baseUnit = item.base_unit;
    ret.conversion = item.unit_conversion;
    return ret;
  }

  toMultiselectOptionItems(measurements: StockItemUnitOfMeasure.StockItemUnitOfMeasure[] | undefined):
    StockItemUnitOfMeasureMultiselectOptionItem[] {
    if (!measurements) {
      return [];
    }
    return measurements.map(m => this.toMultiselectOptionItem(m));
  }
}

export class StockItemUnitOfMeasureMultiselectOptionItem extends MultiselectOptionItem<number> {
  code: string;
  baseUnit: boolean;

  conversion: number;

  get unit_name(): string {
    return this.itemName;
  }
  get unit_conversion(): number {
    return this.conversion;
  }

}
