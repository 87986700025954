/* eslint-disable */
import { Component, } from '@angular/core';
import {
  FormRecordFieldContext,
  FormRecordFieldValueUpdateArgs,
  FormRecordFieldView,
  FormRecordFieldViewContext,
  FormRecordFieldViewModel,
  SetTmpReadonlyArgs,
  SetTmpReadonlyResult,
} from '../../../../../util/form/form-utils';
import { Form } from '../../../../../lib/form/form.service';
import { FormRecord } from '../../../../../lib/form/form-record.service';
import { Command } from '../../../../../util/command';
import { noOpCommand } from '../../manager/form-record-inactivity-manager';
import { Observable } from 'rxjs';

/* eslint-enable */

@Component({
  selector: 'app-form-record-disabled-field',
  templateUrl: 'form-record-disabled-field.component.html',
  styleUrls: ['form-record-disabled-field.component.scss'],
})
export class FormRecordDisabledFieldComponent implements FormRecordFieldView {

  public selector: Form.FieldDataTypeSelector;

  formRecordFieldContext?: FormRecordFieldContext;

  private fieldRecord?: FormRecord.FieldComposed;

  tmpReadonly: boolean = false;

  private get reqContext() {
    return this.formRecordFieldContext!;
  }

  constructor() {
  }

  setFieldDataTypeSelector(selector: Form.FieldDataTypeSelector) {
    this.selector = selector;
  }

  setTmpReadonly(args: SetTmpReadonlyArgs): Command<SetTmpReadonlyResult> {
    return noOpCommand;
  }

  registerField(context: FormRecordFieldContext) {
    this.formRecordFieldContext = context;
    this.fieldRecord = context.fieldRecord;
  }

  registerFieldViews(context: FormRecordFieldViewContext): void {
  }

  hasLocalFieldError(): boolean {
    return false;
  }

  validateWithInterrupt(): boolean {
    return false;
  }

  shouldNotifyAfterCreation(): boolean {
    return false;
  }

  afterFormRecordCreation(formRecordId: number): Observable<any> | undefined {
    return undefined;
  }

  createModel(): FormRecordFieldViewModel | undefined {
    return undefined;
  }

  updateValue(data: FormRecordFieldValueUpdateArgs) {
  }

}
