<div class="breadcrumb-container" [ngClass]="{ 'breadcrumb-container-compact': compactSidebar }" id="breadcrumb">
  <div class="breadcrumb-component">
    <app-breadcrumb
      [parents]="breadcrumbParents"
      [self]="breadcrumbSelf">
    </app-breadcrumb>
  </div>
</div>
<div class="breadcrumb-placeholder"></div>
<div class="container-vertical-padding container-horizontal-padding animated fadeIn col-md-12 col-sm-12 col-xs-12">
  <div class="card">
    <div class="card-header">
      {{'ROLE_CREATE_HEADING' | translate}}
    </div>
    <div class="card-body">
      <form class="form-horizontal" (ngSubmit)="f.form.valid && create()" #f="ngForm" id="role_create_form" novalidate>
        <div class="form-group row"
             [ngClass]="{ 'has-danger': hasFieldError(Role.ValidatedField.NAME) || (f.submitted && !name.valid) }">
          <label class="col-md-2 col-form-label">{{'ROLE_CREATE_NAME' | translate}}</label>
          <div class="col-md-10 row pr-1">
            <input class="form-control" type="text" placeholder="{{'ROLE_CREATE_NAME' | translate}}"
                   maxlength="{{UiConstants.maximumVarcharLength}}"
                   [ngClass]="{ 'form-control-danger': hasFieldError(Role.ValidatedField.NAME) || (f.submitted && !name.valid) }"
                   [(ngModel)]="model.name" (ngModelChange)="removeFieldError(Role.ValidatedField.NAME)"
                   name="name" id="name" #name="ngModel" required>
            <div class="form-control-feedback" *ngIf="hasFieldError(Role.ValidatedField.NAME)">
              {{getFieldErrorText(Role.ValidatedField.NAME)}}
            </div>
            <div class="form-control-feedback" *ngIf="f.submitted && !name.valid">
              {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
            </div>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-md-2 col-form-label">{{'ROLE_CREATE_PERMISSIONS' | translate}}</label>
          <div class="col-md-10 pl-0 pr-1">
            <app-two-way-tree-multiselect
              [selectedTreeTitleStringKey]="StringKey.ROLE_CREATE_UPDATE_SELECTED_ROLES"
              [unselectedTreeTitleStringKey]="StringKey.ROLE_CREATE_UPDATE_AVAILABLE_ROLES"
              #permissionSelector>
            </app-two-way-tree-multiselect>
          </div>
        </div>
        <div class="col-md-12 d-flex justify-content-end p-0 pl-1 pr-1">
          <div>
            <input type="submit" class="btn btn-primary" value="{{'COMMON_BUTTON_SAVE'|translate}}"/>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
