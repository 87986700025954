import { Observable, Observer } from 'rxjs';
import { FieldValidationError, PagingRequest, QueryResult, ResourceQueryResult, Services } from '../util/services';
import { EmptyMessage, IdentityMessage } from '../util/messages';
import { OffsetDateTime } from '../util/dates';
import { DqlQuery, Query } from '../query/field';
import { FilterField } from '../query/filterfields';
import { OrderField } from '../query/orderfields';
import { List, Map, Set } from 'immutable';
import { Injectable } from '@angular/core';
import { ObservableErrorResourceParser } from '../util/errors';
import {
  DeliveryMethodResource,
  DeliveryMethodResourceService
} from './delivery-method-resource.service';
import Decimal from 'decimal.js';
import { LocalizedTypeObject } from '../../util/core-utils';

export namespace DeliveryMethod {

  export interface Service {
    query(request: QueryRequest): Observable<QueryResult<DeliveryMethod>>;
    get(request: GetRequest): Observable<DeliveryMethod>;
    create(request: CreateRequest): Observable<IdentityMessage>;
    update(request: UpdateRequest): Observable<EmptyMessage>;
    setDisabled(request: DisableRequest): Observable<EmptyMessage>;
  }

  export interface DeliveryMethod {
    id: number;
    grantedRights: Set<string>;
    disabled: boolean;
    externalId: string;
    creationTime: OffsetDateTime;
    name: string;
    type: DeliveryMethodType;
    integrationType: DeliveryMethodIntegrationType;
    transporterCompany?: {
      id: number;
      name: string;
    };
    packageWeightRequired: boolean;
    maxPackageWeightInGram?: number;
    packageSizeRequired: boolean;
    maxPackageSize?: DeliveryMethodPackageSizeData;
    maxInsurancePrice?: DeliveryMethodPriceData;
    maxCashOnDeliveryPrice?: DeliveryMethodPriceData;
    recipientEmailAddressRequired: boolean;
    recipientPhoneNumberRequired: boolean;
  }

  export interface DeliveryMethodPackageSizeData {
    widthInMeter: Decimal;
    heightInMeter: Decimal;
    depthInMeter: Decimal;
  }

  export interface DeliveryMethodPriceData {
    currencyCode: string;
    amount: Decimal;
  }

  export interface QueryRequest {
    fields?: Query.FieldFunction<Fields.DeliveryMethod>;
    filter?: Query.FilterFunction<FilterField.DeliveryMethod>;
    order?: Query.OrderFunction<OrderField.DeliveryMethod>;
    paging?: PagingRequest;
    rights?: Set<string>;
    noProgressBar?: boolean;
  }

  export interface CreateRequest {
    externalId: string;
    name: string;
    type: DeliveryMethodType;
    integrationType: DeliveryMethodIntegrationType;
    transporterCompany: {
      id: number;
    };
    packageWeightRequired: boolean;
    maxPackageWeightInGram?: number;
    packageSizeRequired: boolean;
    maxPackageSize?: DeliveryMethodPackageSizeData;
    maxInsurancePrice?: DeliveryMethodPriceData;
    maxCashOnDeliveryPrice?: DeliveryMethodPriceData;
    recipientEmailAddressRequired: boolean;
    recipientPhoneNumberRequired: boolean;
  }

  export interface UpdateRequest extends CreateRequest {
    id: number;
  }

  export interface DisableRequest {
    id: number;
    disabled: boolean;
  }

  export interface GetRequest {
    id: number;
  }

  export function toResourceCreateRequest(request: DeliveryMethod.CreateRequest): DeliveryMethodResource.CreateRequest {
    return {
      external_id: request.externalId,
      name: request.name,
      type: request.type,
      integration_type: request.integrationType,
      transporter_company: request.transporterCompany,
      package_weight_required: request.packageWeightRequired,
      max_package_weight_in_gram: request.maxPackageWeightInGram,
      package_size_required: request.packageSizeRequired,
      max_package_size: toResourceDeliveryMethodPackageSizeData(request.maxPackageSize),
      max_insurance_price: toResourceDeliveryMethodPriceData(request.maxInsurancePrice),
      max_cash_on_delivery_price: toResourceDeliveryMethodPriceData(request.maxCashOnDeliveryPrice),
      recipient_email_address_required: request.recipientEmailAddressRequired,
      recipient_phone_number_required: request.recipientPhoneNumberRequired
    }
  }

  export function toResourceUpdateRequest(request: DeliveryMethod.UpdateRequest): DeliveryMethodResource.UpdateRequest {
    return {
      id: request.id,
      external_id: request.externalId,
      name: request.name,
      type: request.type,
      integration_type: request.integrationType,
      transporter_company: request.transporterCompany,
      package_weight_required: request.packageWeightRequired,
      max_package_weight_in_gram: request.maxPackageWeightInGram,
      package_size_required: request.packageSizeRequired,
      max_package_size: toResourceDeliveryMethodPackageSizeData(request.maxPackageSize),
      max_insurance_price: toResourceDeliveryMethodPriceData(request.maxInsurancePrice),
      max_cash_on_delivery_price: toResourceDeliveryMethodPriceData(request.maxCashOnDeliveryPrice),
      recipient_email_address_required: request.recipientEmailAddressRequired,
      recipient_phone_number_required: request.recipientPhoneNumberRequired
    }
  }

  export function toResourceDeliveryMethodPackageSizeData(request?: DeliveryMethodPackageSizeData):
    DeliveryMethodResource.DeliveryMethodPackageSizeData | undefined {
    if (!request) {
      return undefined;
    }
    return {
      width_in_meter: Services.decimalToString(request.widthInMeter)!,
      height_in_meter: Services.decimalToString(request.heightInMeter)!,
      depth_in_meter: Services.decimalToString(request.depthInMeter)!
    }
  }

  export function toResourceDeliveryMethodPriceData(request?: DeliveryMethodPriceData):
    DeliveryMethodResource.DeliveryMethodPriceData | undefined {
    if (!request) {
      return undefined;
    }
    return {
      currency_code: request.currencyCode,
      amount: Services.decimalToString(request.amount)!
    }
  }

  export function toResourceDisableRequest(request: DeliveryMethod.DisableRequest): DeliveryMethodResource.DisableRequest {
    return {
      id: request.id,
      disabled: request.disabled
    }
  }

  export function toPublic(r: DeliveryMethodResource.DeliveryMethod): DeliveryMethod {
    return {
      id: r.id,
      grantedRights: r.granted_rights ? Set.of(...r.granted_rights) : Set.of(),
      disabled: r.disabled,
      externalId: r.external_id,
      creationTime: Services.toOffsetDateTime(r.creation_time),
      name: r.name,
      type: <DeliveryMethodType>r.type,
      integrationType: <DeliveryMethodIntegrationType>r.integration_type,
      transporterCompany: r.transporter_company,
      packageWeightRequired: r.package_weight_required,
      maxPackageWeightInGram: r.max_package_weight_in_gram,
      packageSizeRequired: r.package_size_required,
      maxPackageSize: toPublicDeliveryMethodPackageSizeData(r.max_package_size),
      maxInsurancePrice: toPublicDeliveryMethodPriceData(r.max_insurance_price),
      maxCashOnDeliveryPrice: toPublicDeliveryMethodPriceData(r.max_cash_on_delivery_price),
      recipientEmailAddressRequired: r.recipient_email_address_required,
      recipientPhoneNumberRequired: r.recipient_phone_number_required,
    }
  }

  export function toPublicDeliveryMethodPackageSizeData(r?: DeliveryMethodResource.DeliveryMethodPackageSizeData):
    DeliveryMethodPackageSizeData | undefined {
    if (!r) {
      return undefined;
    }
    return {
      widthInMeter: Services.toDecimal(r.width_in_meter)!,
      heightInMeter: Services.toDecimal(r.height_in_meter)!,
      depthInMeter: Services.toDecimal(r.depth_in_meter)!
    }
  }

  export function toPublicDeliveryMethodPriceData(r?: DeliveryMethodResource.DeliveryMethodPriceData): DeliveryMethodPriceData | undefined {
    if (!r) {
      return undefined;
    }
    return {
      currencyCode: r.currency_code,
      amount: Services.toDecimal(r.amount)!
    }
  }

  export type DeliveryMethodType = 'HOME_DELIVERY' | 'PARCEL_COLLECTION_POINT';

  export const types: LocalizedTypeObject<DeliveryMethodType>[] = [
    {type: 'HOME_DELIVERY', stringKey: 'DELIVERY_METHOD_TYPE_HOME_DELIVERY'},
    {type: 'PARCEL_COLLECTION_POINT', stringKey: 'DELIVERY_METHOD_TYPE_PARCEL_COLLECTION_POINT'},
  ];

  export type DeliveryMethodIntegrationType = 'MPL';

  export const integrationTypes: LocalizedTypeObject<DeliveryMethodIntegrationType>[] = [
    {type: 'MPL', stringKey: 'DELIVERY_METHOD_INTEGRATION_TYPE_MPL'},
  ];

  export enum ValidatedField {
    UNKNOWN,
    NAME,
    EXTERNAL_ID
  }

  export namespace Fields {

    export class DeliveryMethod {

      readonly id: Query.Field = new DqlQuery.Field('id');
      readonly grantedRights: Query.Field = new DqlQuery.Field('granted_rights');
      readonly disabled: Query.Field = new DqlQuery.Field('disabled');
      readonly externalId: Query.Field = new DqlQuery.Field('external_id');
      readonly creationTime: Query.Field = new DqlQuery.Field('creation_time');
      readonly name: Query.Field = new DqlQuery.Field('name');
      readonly type: Query.Field = new DqlQuery.Field('type');
      readonly transporterCompany: Query.Field = new DqlQuery.Field('transporter_company');
      readonly integrationType: Query.Field = new DqlQuery.Field('integration_type');
      readonly packageWeightRequired: Query.Field = new DqlQuery.Field('package_weight_required');
      readonly maxPackageWeightInGram: Query.Field = new DqlQuery.Field('max_package_weight_in_gram');
      readonly packageSizeRequired: Query.Field = new DqlQuery.Field('package_size_required');
      readonly maxPackageSize: Query.Field = new DqlQuery.Field('max_package_size');
      readonly maxInsurancePrice: Query.Field = new DqlQuery.Field('max_insurance_price');
      readonly maxCashOnDeliveryPrice: Query.Field = new DqlQuery.Field('max_cash_on_delivery_price');
      readonly recipientEmailAddressRequired: Query.Field = new DqlQuery.Field('recipient_email_address_required');
      readonly recipientPhoneNumberRequired: Query.Field = new DqlQuery.Field('recipient_phone_number_required');

      get each(): Set<Query.Field> {
        return Set.of(
          this.id,
          this.disabled,
          this.externalId,
          this.creationTime,
          this.name,
          this.type,
          this.transporterCompany,
          this.integrationType,
          this.packageWeightRequired,
          this.maxPackageWeightInGram,
          this.packageSizeRequired,
          this.maxPackageSize,
          this.maxInsurancePrice,
          this.maxCashOnDeliveryPrice,
          this.recipientEmailAddressRequired,
          this.recipientPhoneNumberRequired
        );
      }

      get forList(): Set<Query.Field> {
        return Set.of(
          this.id,
          this.disabled,
          this.externalId,
          this.creationTime,
          this.name,
          this.type
        );
      }

    }

  }

}

class Keys {

  private static readonly NAME = 'name';
  private static readonly EXTERNAL_ID = 'external_id';

  private static readonly keyValidatedFieldMap: Map<string, DeliveryMethod.ValidatedField> = Map.of(
    Keys.NAME, DeliveryMethod.ValidatedField.NAME,
    Keys.EXTERNAL_ID, DeliveryMethod.ValidatedField.EXTERNAL_ID,
  );

  public static toValidatedField(fieldKey: string): DeliveryMethod.ValidatedField {
    return Keys.keyValidatedFieldMap.get(fieldKey, DeliveryMethod.ValidatedField.UNKNOWN);
  }

}

@Injectable()
export class DeliveryMethodService implements DeliveryMethod.Service {

  private readonly filterField = new FilterField.DeliveryMethod();
  private readonly orderField = new OrderField.DeliveryMethod();
  private readonly fields = new DeliveryMethod.Fields.DeliveryMethod();

  constructor(private resourceService: DeliveryMethodResourceService) {
  }

  query(request: DeliveryMethod.QueryRequest): Observable<QueryResult<DeliveryMethod.DeliveryMethod>> {
    return Observable.create((observer: Observer<QueryResult<DeliveryMethod.DeliveryMethod>>) => {
      const resourceRequest = this.toResourceQueryRequest(request);
      return this.resourceService.query(resourceRequest).subscribe(
        (result: ResourceQueryResult<DeliveryMethodResource.DeliveryMethod>) => {
          observer.next({
            items: List.of(...result.items.map((item) => DeliveryMethod.toPublic(item))),
            pagingResult: result.pagingResult
          });
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  get(request: DeliveryMethod.GetRequest): Observable<DeliveryMethod.DeliveryMethod> {
    return Observable.create((observer: Observer<DeliveryMethod.DeliveryMethod>) => {
      const resourceRequest: DeliveryMethodResource.GetRequest = {
        id: request.id
      };
      return this.resourceService.get(resourceRequest).subscribe(
        (result: DeliveryMethodResource.DeliveryMethod) => {
          observer.next(DeliveryMethod.toPublic(result));
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  create(request: DeliveryMethod.CreateRequest): Observable<IdentityMessage> {
    return Observable.create((observer: Observer<IdentityMessage>) => {
      const resourceRequest: DeliveryMethodResource.CreateRequest = DeliveryMethod.toResourceCreateRequest(request);
      return this.resourceService.create(resourceRequest).subscribe(
        (result: IdentityMessage) => {
          observer.next(result);
        },
        (error: any) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  update(request: DeliveryMethod.UpdateRequest): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      const resourceRequest: DeliveryMethodResource.UpdateRequest = DeliveryMethod.toResourceUpdateRequest(request);
      return this.resourceService.update(resourceRequest).subscribe(
        (result: EmptyMessage) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  setDisabled(request: DeliveryMethod.DisableRequest): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      const resourceRequest: DeliveryMethodResource.DisableRequest = DeliveryMethod.toResourceDisableRequest(request);
      return this.resourceService.setDisabled(resourceRequest).subscribe(
        (result: EmptyMessage) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  toResourceQueryRequest(request: DeliveryMethod.QueryRequest): DeliveryMethodResource.QueryRequest {
    const filter: string | undefined = DqlQuery.toOptionalFilter(this.filterField, request.filter);
    const order: string | undefined = DqlQuery.toOptionalOrder(this.orderField, request.order);
    const fields: string | undefined = DqlQuery.toOptionalFields(this.fields, request.fields);
    return {
      filter: filter,
      order: order,
      fields: fields,
      page_number: request.paging ? request.paging.pageNumber : undefined,
      number_of_items: request.paging ? request.paging.numberOfItems : undefined,
      rights: DqlQuery.RightRequestSerializer.getInstance().serialize(request.rights),
      no_progress_bar: request.noProgressBar
    };
  }

  private translateError(error: any): any {
    const res = ObservableErrorResourceParser.parseError(error);
    const fieldErrors = ObservableErrorResourceParser.extractFieldErrors(res);
    const fieldErrorMap = ObservableErrorResourceParser.toFieldErrorMap(Keys.toValidatedField, fieldErrors);
    if (!fieldErrorMap.isEmpty()) {
      return FieldValidationError.of(fieldErrorMap);
    }
    return error;
  }

}
