/* eslint-disable */
import { Component, OnInit, ViewChild } from '@angular/core';
import { Transition, UIRouter } from '@uirouter/angular';
import { StateName } from '../../../app.state-names';
import { BreadcrumbParent } from '../../../shared/breadcrumb/breadcrumb/breadcrumb.component';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { FieldValidationError } from '../../../lib/util/services';
import { MultiselectOptionItem, UiConstants } from '../../../util/core-utils';
import { ComponentStateResolver } from '../../../util/component-state/component-state-resolver';
import { EmptyMessage, IdentityMessage } from '../../../lib/util/messages';
import { RightResolver, RightService } from '../../../lib/right.service';
import { RightModel, Rights } from '../../../app.rights';
import { Project, ProjectService } from '../../../lib/project/project.service';
import { Icon } from '../../../lib/task/icon.service';
import { Models } from '../../../util/model-utils';
import { Strings } from '../../../lib/util/strings';
import { InputMask } from '../../../util/input-masks';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { FormServiceCode } from '../../../lib/form/form-service-factory';
import { FieldDataTypeSelectors, FormRecordSerializer, FormRights } from '../../../util/form/form-utils';
import { Form } from '../../../lib/form/form.service';
import { AppValidators } from '../../../util/app-validators';
import { Angular2Multiselects } from '../../../util/multiselect';
import { FormEditComponent } from '../../form/form-edit/form-edit.component';
import { saveAs } from 'file-saver';
import { ToasterService } from '../../../fork/angular2-toaster/src/toaster.service';
import { StringKey } from '../../../app.string-keys';
import { Set } from 'immutable';
import { UserGroupMultiselectProvider } from '../../../lib/user-group/user-group-multiselect.provider';


/* eslint-enable */

@Component({
  selector: 'app-project-base',
  templateUrl: './project-base.component.html',
  styleUrls: ['./project-base.component.scss']
})
export class ProjectBaseComponent implements OnInit {

  // If you need to access certain classes from HTML, declare them here
  Project = Project;
  UiConstants = UiConstants;
  InputMask = InputMask;
  FormServiceCode = FormServiceCode;

  @ViewChild('f', {static: true})
  form: NgForm;
  projectForm?: Form.Form;

  @ViewChild('importDialog') importDialog: ModalDirective;

  @ViewChild('formEdit', {static: false})
  formEdit: FormEditComponent;

  formGroup: FormGroup;
  formSubmitted: boolean = false;

  // Declare model before use
  model: Model = new Model();
  formFields: MultiselectOptionItem<number>[] = [];
  dropdownSettings: Angular2Multiselects.Settings = Angular2Multiselects.LOCAL_SINGLE_SELECT;
  remoteDropdownSettings: Angular2Multiselects.Settings = Angular2Multiselects.REMOTE_MULTI_SELECT;
  userGroups: MultiselectOptionItem<number>[] = [];

  // Component state resolver, determines the state of the component
  componentState: ComponentStateResolver;

  // Variables used for breadcrumb
  breadcrumbParents: BreadcrumbParent[] = [];
  breadcrumbSelf: string;
  compactSidebar: boolean = document.querySelector('body')!.classList.contains('sidebar-compact');

  private fieldErrors: FieldValidationError<Project.ValidatedField> =
    FieldValidationError.empty<Project.ValidatedField>();

  rightModel: RightModel = RightModel.empty();
  formRights: FormRights = {
    formRead: Rights.PROJECT_FORM_READ,
    formGroupUpdate: Rights.PROJECT_FORM_UPDATE,
    formFieldUpdate: Rights.PROJECT_FORM_UPDATE,
    formGroupCreate: Rights.PROJECT_FORM_CREATE,
    formFieldCreate: Rights.PROJECT_FORM_CREATE,
    formGroupDisable: Rights.PROJECT_FORM_DISABLE,
    formFieldDisable: Rights.PROJECT_FORM_DISABLE,
    formGroupMove: Rights.PROJECT_FORM_UPDATE,
    formFieldMove: Rights.PROJECT_FORM_UPDATE,
  };

  get formService(): Form.Service {
    return this.projectService;
  }

  constructor(private uiRouter: UIRouter,
              private transition: Transition,
              private toasterService: ToasterService,
              private projectService: ProjectService,
              private formBuilder: FormBuilder,
              private rightService: RightService,
              private userGroupMultiselectProvider: UserGroupMultiselectProvider,
              private translateService: TranslateService) {
    this.componentState = new ComponentStateResolver(uiRouter, transition,
      'id',
      {stateName: StateName.PROJECT_CREATE, stateHeaderKey: 'PROJECT_CREATE'},
      {stateName: StateName.PROJECT_EDIT, stateHeaderKey: 'PROJECT_EDIT'},
      {stateName: StateName.PROJECT_DETAIL, stateHeaderKey: 'PROJECT_DETAIL'});
    this.formGroup = this.formBuilder.group({
      name: ['', Validators.required],
      externalId: ['', this.componentState.isEditView() ? Validators.required : null],
      explicitOrderNumber: ['', Validators.min(1)],
      displayedFormField: [[], AppValidators.validateEnabledItems],
      relatedUserGroups: [[], AppValidators.validateEnabledItems]
    });
  }

  ngOnInit() {
    this.initComponentState(); // Must be called first
    this.initBreadcrumb();
    this.loadRightModels();
  }

  private initComponentState() {
    // component state contains the id
    if (this.componentState.id) {
      this.loadModel();
    }
  }

  // Called by the init method first
  private loadModel() {
    this.projectService.get({
      id: this.componentState.id!
    }).subscribe(result => {
      this.model.name = result.name;
      this.breadcrumbSelf = result.name;
      this.model.externalId = result.externalId;
      this.model.description = result.description ? result.description : '';
      this.model.icon = result.icon;
      this.model.explicitOrderNumber = Models.numberToString(result.explicitOrderNumber);
      if (result.displayedFormField) {
        this.model.displayedFormField.push(this.createFormFieldMultiselectOptionItem(result.displayedFormField));
        this.formGroup.controls['displayedFormField'].updateValueAndValidity();
      }
      if (result.relatedUserGroups.size > 0) {
        this.userGroupMultiselectProvider.getByIds(result.relatedUserGroups.toArray()).subscribe(ug => {
          this.model.relatedUserGroups = ug;
        })
      }
    });
  }

  initBreadcrumb() {
    // Set breadcrumbSelf if createView, otherwise set in loadModel()
    if (this.componentState.isCreateView()) {
      this.translateService.get('PROJECT_CREATE').subscribe(
        (result: string) => {
          this.breadcrumbSelf = result;
        }
      );
    }
    this.translateService.get('MENU_NAVBAR_MENU_ADMINISTRATION').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.ADMIN_DASHBOARD});
      }
    );
    this.translateService.get('MENU_NAVBAR_PROJECTS').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.PROJECT_LIST});
      }
    );
  }

  private loadRightModels() {
    this.rightService.getRightResolver().subscribe(
      (resolver: RightResolver) => {
        this.rightModel = RightModel.of(resolver);
      }
    );
  }

  // Get corresponding dictionary key
  getHeadingDictionaryKey(): string {
    return this.componentState.getCurrentHeaderKey();
  }

  hasLocalFieldError(control?: string, error?: string): boolean {
    if (control) {
      if (error) {
        return this.formSubmitted && this.formGroup.controls[control].hasError(error);
      }
      return this.formSubmitted && this.formGroup.controls[control].invalid;
    }
    return this.formSubmitted && this.formGroup.invalid;
  }

  hasFieldError(field: Project.ValidatedField): boolean {
    return this.fieldErrors.hasError(field);
  }

  removeFieldError(field: Project.ValidatedField) {
    this.fieldErrors = this.fieldErrors.removeError(field);
  }

  getFieldErrorText(field: Project.ValidatedField): string {
    return this.fieldErrors.getErrorText(field);
  }

  onUserGroupSearch(q?: string) {
    this.userGroupMultiselectProvider.loadActive(q).subscribe(ug => {
      this.userGroups = ug;
    });
  }

  submit() {
    this.formSubmitted = true;
    if (this.hasLocalFieldError()) {
      return;
    }
    if (this.componentState.isCreateView()) {
      this.create();
    }
    else if (this.componentState.isEditView()) {
      this.update();
    }
  }

  create() {
    this.projectService.create({
      name: this.model.name,
      externalId: Strings.undefinedOrNonEmpty(this.model.externalId),
      description: Strings.undefinedOrNonEmpty(this.model.description),
      icon: this.model.icon,
      explicitOrderNumber: Models.parseNumber(this.model.explicitOrderNumber),
      relatedUserGroups: this.model.relatedUserGroupIds
    }).subscribe(
      (response: IdentityMessage) => {
        this.uiRouter.stateService.go(StateName.PROJECT_LIST);
      },
      (error: any) => {
        if (error instanceof FieldValidationError) {
          this.fieldErrors = error.withForm(this.form);
          this.showValidationErrorToast();
        }
      });
  }

  update() {
    this.projectService.update({
      id: this.componentState.id!,
      name: this.model.name,
      externalId: this.model.externalId,
      description: Strings.undefinedOrNonEmpty(this.model.description),
      icon: this.model.icon,
      explicitOrderNumber: Models.parseNumber(this.model.explicitOrderNumber),
      displayedFormField: this.model.displayedFormFieldId,
      relatedUserGroups: this.model.relatedUserGroupIds
    }).subscribe(
      (response: EmptyMessage) => {
        this.uiRouter.stateService.go(StateName.PROJECT_LIST);
      },
      (error: any) => {
        if (error instanceof FieldValidationError) {
          this.fieldErrors = error.withForm(this.form);
          this.showValidationErrorToast();
        }
      });
  }

  private showValidationErrorToast() {
    this.toasterService.pop({
      timeout: UiConstants.ToastTimeoutLong,
      type: UiConstants.toastTypeError,
      title: this.translateService.instant(StringKey.COMMON_FORM_VALIDATION_ERROR_TOAST_TITLE),
      body: this.translateService.instant(StringKey.COMMON_FORM_VALIDATION_ERROR_TOAST_MESSAGE)
    });
  }

  back() {
    window.history.back();
  }

  exportForm() {
    this.projectService.exportForm({
      parentId: this.componentState.id!
    }).subscribe((result: Form.FormImportDocument) => {
      const blob = new Blob([JSON.stringify(result)], {type: 'text/json;charset=utf-8'});
      saveAs(blob, 'project_' + this.componentState.id! + '.json');
    });
  }

  onFormLoaded(form: Form.Form) {
    this.projectForm = form;
    this.createSelectableFormFields();
  }

  private createSelectableFormFields() {
    this.formFields = [];
    this.projectForm!.groups.toArray().forEach(g => {
      g.fields.toArray().forEach(f => {
        if (FormRecordSerializer.isSerializableField(f)) {
          this.formFields.push(this.createFormFieldMultiselectOptionItem(f));
        }
      });
    });
  }

  private createFormFieldMultiselectOptionItem(field: Form.Field): MultiselectOptionItem<number> {
    const item = {
      id: field.fieldId,
      itemName: field.title,
      itemSubtitle: '',
      disabled: field.disabled
    };
    this.translateService.get(FieldDataTypeSelectors.getFormFieldNameKey(field.dataTypeSelector)).subscribe(result => {
      item.itemSubtitle = result;
    });
    return item;
  }

  reloadForm() {
    this.formEdit.reloadForm();
  }
}

class Model {
  name: string = '';
  externalId: string = '';
  description: string = '';
  icon?: Icon.Icon = {
    iconType: 'ICON_SET',
    iconSet: {
      setCode: 'google_material',
      setClass: 'material-icons',
      iconCode: 'insert_chart',
      usageType: 'TEXT'
    }
  };
  explicitOrderNumber: string = '';
  displayedFormField: MultiselectOptionItem<number>[] = [];
  relatedUserGroups: MultiselectOptionItem<number>[] = [];

  get displayedFormFieldId(): number | undefined {
    return this.displayedFormField.length === 1 ? this.displayedFormField[0].id : undefined;
  }
  get relatedUserGroupIds(): Set<number> {
    return Set.of(...this.relatedUserGroups.map(u => u.id));
  }
}
