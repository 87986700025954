import { AfterViewInit, Directive, ElementRef, Inject, Input, Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'tr[disabledTableRow], div[disabledTableRow]'
})
export class DisabledTableRowDirective implements AfterViewInit {

  @Input()
  disabledTableRow: boolean;

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document) {}

  ngAfterViewInit(): void {
    if (this.disabledTableRow) {
      const row = this.elementRef.nativeElement;
      const height = Math.floor((<HTMLElement>row).getBoundingClientRect().height) - 1;
      const cells: any[] = Array.from(row.children);
      for (let i = 0; i < cells.length; i++) {
        const cell = cells[i];
        cell.style.position = 'relative';
        const child = document.createElement('div');
        child.classList.add('disabled-table-row-overlay');
        child.style.height = height + 'px';
        this.renderer.appendChild(cell, child);
      };
    }
  }
}
