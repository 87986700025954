import { DqlQuery, Query } from '../field';
import { LazyReference } from '../../../util/lazy';
import { TaskRecordSubTask as trst } from '../../task/task-record-subtask/task-record-sub-task.service';
import { TaskRecord } from './task-record';

// Each class should be an interface, but it is easier to define the constant fields in the constructors.
// For now we have only one query language so it is not a problem.
// Always use the public Query namespace for variable definitions so we will be able to introduce other language easily.
import SubTaskState = trst.SubTaskState;

export class TaskRecordSubTask {

  readonly id: Query.NumberField;

  private readonly taskRecordReference: LazyReference<TaskRecord>; // Lazy to support circular references.

  private readonly workerUserReference: LazyReference<WorkerUser>; // Lazy to support circular references.

  readonly name: Query.StringField;

  readonly type: Query.StringField;

  readonly note: Query.StringField;

  readonly state: Query.EnumField<SubTaskState>;

  readonly creationTime: Query.DateTimeField;

  readonly startTime: Query.DateTimeField;

  readonly endTime: Query.DateTimeField;

  get taskRecord(): TaskRecord {
    return this.taskRecordReference.get();
  }

  get workerUser(): WorkerUser {
    return this.workerUserReference.get();
  }

  constructor(prefix?: string) {
    prefix = prefix ? prefix : '';
    this.id = new DqlQuery.NumberField(prefix + 'id');
    this.taskRecordReference = LazyReference.of(
      () => new TaskRecord(prefix + 'task_record.')
    );
    this.workerUserReference = LazyReference.of(
      () => new WorkerUser(prefix + 'worker_user.')
    );
    this.name = new DqlQuery.StringField(prefix + 'name');
    this.type = new DqlQuery.StringField(prefix + 'type');
    this.note = new DqlQuery.StringField(prefix + 'note');
    this.state = new DqlQuery.EnumField<SubTaskState>(prefix + 'state', (sts) => sts);
    this.creationTime = new DqlQuery.DateTimeField(prefix + 'creation_time');
    this.startTime = new DqlQuery.DateTimeField(prefix + 'start_time');
    this.endTime = new DqlQuery.DateTimeField(prefix + 'end_time');
  }

}

export class WorkerUser {

  readonly id: Query.NumberField;

  constructor(prefix?: string) {
    prefix = prefix ? prefix : '';
    this.id = new DqlQuery.NumberField(prefix + 'id');
  }

}
