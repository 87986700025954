/* eslint-disable */
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { InputMask } from '../../../util/input-masks';
import { LegacyWorkflow, LegacyWorkflowService } from '../../../lib/legacy-workflow/legacy-workflow-service';
import { FormBuilder, FormGroup, NgForm, NgModel, Validators } from '@angular/forms';
import { LegacyWorkflowEditModel, LegacyWorkflowTaskCreateModel } from '../../../lib/legacy-workflow/legacy-workflow-utils';
import {
  FieldValidationError,
  LocalFieldValidationErrors,
  LocalFieldValidationErrorsFactory,
  QueryResult
} from '../../../lib/util/services';
import { BreadcrumbParent } from '../../../shared/breadcrumb/breadcrumb/breadcrumb.component';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { TranslateService } from '@ngx-translate/core';
import { StateName } from '../../../app.state-names';
import { LoadingHandler } from '../../../lib/loading-handler';
import { Transition, UIRouter } from '@uirouter/angular';
import { List } from 'immutable';
import { SelectUtils, UiConstants } from '../../../util/core-utils';
import {
  LegacyWorkflowTask,
  LegacyWorkflowTaskService,
  WorkflowTaskCreateFieldErrorMap
} from '../../../lib/legacy-workflow/legacy-workflow-tasks/legacy-workflow-task.service';
import { FieldError, FieldErrors, ObservableErrorResourceParser } from '../../../lib/util/errors';
import { Strings } from '../../../lib/util/strings';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ToasterService } from '../../../fork/angular2-toaster/src/toaster.service';
import { StringKey } from '../../../app.string-keys';

/* eslint-enable */

@Component({
  selector: 'app-legacy-workflow-edit',
  templateUrl: './legacy-workflow-edit.component.html',
  styleUrls: ['./legacy-workflow-edit.component.scss']
})
export class LegacyWorkflowEditComponent implements OnInit, AfterViewInit {

  UiConstants = UiConstants;
  InputMask = InputMask;
  Workflow = LegacyWorkflow;

  @ViewChild('f', {static: true})
  fForm: NgForm;

  @ViewChild('name', {static: true})
  nameInput: NgModel;

  @ViewChild('external_id', {static: true})
  externalIdInput: NgModel;

  model: LegacyWorkflowEditModel = new LegacyWorkflowEditModel();
  workflowTasks: LegacyWorkflowTask.WorkflowTask[] = [];

  breadcrumbParents: BreadcrumbParent[] = [];
  breadcrumbSelf: string;
  compactSidebar: boolean = document.querySelector('body')!.classList.contains('sidebar-compact');
  workflowId: number;
  SelectUtils = SelectUtils;

  @ViewChild('workflowTaskCreateDialog', {static: true}) workflowTaskCreateDialog: ModalDirective;
  workflowTaskCreateDialogVisible = false;
  workflowTaskCreateForm: FormGroup;
  workflowTaskCreateModel: LegacyWorkflowTaskCreateModel = new LegacyWorkflowTaskCreateModel();
  workflowTaskCreateFieldErrors: WorkflowTaskCreateFieldErrorMap;


  private fieldErrors: FieldValidationError<LegacyWorkflow.ValidatedField> =
    FieldValidationError.empty<LegacyWorkflow.ValidatedField>();

  private validatedInputs: LocalFieldValidationErrors<NgModel> =
    LocalFieldValidationErrorsFactory.empty();

  constructor(private legacyWorkflowService: LegacyWorkflowService,
              private legacyWorkflowTaskService: LegacyWorkflowTaskService,
              private toasterService: ToasterService,
              private uiRouter: UIRouter,
              private transition: Transition,
              private translateService: TranslateService,
              private formBuilder: FormBuilder) {
    this.workflowId = this.transition.params().id;
    LegacyWorkflowTaskService.setWorkflowId(this.workflowId);
    this.workflowTaskCreateForm = formBuilder.group({
      name: [[], Validators.required],
      inProgressStateName: [[], Validators.required],
      finishedStateName: [[], Validators.required]
    });
    this.workflowTaskCreateFieldErrors = {};
  }

  ngOnInit() {
    this.translateService.get('MENU_NAVBAR_MENU_ADMINISTRATION').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.ADMIN_DASHBOARD});
      }
    );
    this.translateService.get('WORKFLOW_LIST').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.LEGACY_WORKFLOW_LIST});
      }
    );
  }

  ngAfterViewInit(): void {
    this.loadLocalFieldValidationErrors();
    this.loadModel();
  }

  private loadLocalFieldValidationErrors() {
    const validatedInputs = List.of(this.externalIdInput, this.nameInput);
    this.validatedInputs = LocalFieldValidationErrorsFactory.ofFormFields(this.fForm, validatedInputs);
  }

  showWorkflowTaskCreateDialog() {
    this.workflowTaskCreateDialogVisible = true;
    this.workflowTaskCreateDialog.show();
  }

  closeWorkflowTaskCreateDialog() {
    this.workflowTaskCreateDialogVisible = false;
    this.workflowTaskCreateDialog.hide();
  }

  onModalHidden() {
    this.workflowTaskCreateModel = new LegacyWorkflowTaskCreateModel();
    this.workflowTaskCreateForm.reset();
  }

  updateWorkflow() {
    this.legacyWorkflowService.update({
      workflowId: this.workflowId,
      name: this.model.name,
      description: this.model.description,
      externalId: this.model.externalId,
      version: this.model.version,
      icon: this.model.icon,
    }).subscribe(
      (response: LegacyWorkflow.WorkflowIdentity) => {
        LoadingHandler.getInstance().refresh();
        this.uiRouter.stateService.go(StateName.LEGACY_WORKFLOW_LIST);
      },
      (error: any) => {
        if (error instanceof FieldValidationError) {
          this.fieldErrors = error.withForm(this.fForm);
          this.showValidationErrorToast();
        }
      }
    );
  }

  private showValidationErrorToast() {
    this.toasterService.pop({
      timeout: UiConstants.ToastTimeoutLong,
      type: UiConstants.toastTypeError,
      title: this.translateService.instant(StringKey.COMMON_FORM_VALIDATION_ERROR_TOAST_TITLE),
      body: this.translateService.instant(StringKey.COMMON_FORM_VALIDATION_ERROR_TOAST_MESSAGE)
    });
  }

  hasFieldError(field: LegacyWorkflow.ValidatedField): boolean {
    return this.fieldErrors.hasError(field);
  }

  removeFieldError(field: LegacyWorkflow.ValidatedField) {
    this.fieldErrors = this.fieldErrors.removeError(field);
  }

  removeWorkflowTaskCreateFieldError(fieldError?: FieldError) {
    FieldErrors.remove(this.workflowTaskCreateFieldErrors, fieldError);
  }

  getFieldErrorText(field: LegacyWorkflow.ValidatedField): string {
    return this.fieldErrors.getErrorText(field);
  }

  back() {
    window.history.back();
  }

  private loadModel() {
    this.legacyWorkflowService.get({
      workflowId: this.workflowId
    }).subscribe(
      (workflow: LegacyWorkflow.Workflow) => {
        this.breadcrumbSelf = workflow.name;
        this.model.name = workflow.name;
        this.model.description = workflow.description;
        this.model.externalId = workflow.externalId ? workflow.externalId : '';
        this.model.icon = workflow.icon;
        this.model.version = workflow.version;
        this.loadWorkflowTasks();
      });
  }

  private loadWorkflowTasks() {
    this.workflowTasks = [];
    this.legacyWorkflowTaskService.query({
      workflowId: this.workflowId
    }).subscribe(
      (result: QueryResult<LegacyWorkflowTask.WorkflowTask>) => {
        this.workflowTasks = result.items.toArray();
      });
  }

  onWorkflowTaskDropped(event: CdkDragDrop<LegacyWorkflowTask.WorkflowTask[]>) {
    moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    this.moveField(event.container.data[event.currentIndex], event.currentIndex);
  }

  private moveField(field: LegacyWorkflowTask.WorkflowTask, index: number) {
    this.legacyWorkflowTaskService.moveField({
      workflowId: field.workflowId,
      workflowTaskId: field.workflowTaskId,
      index: index
    }).subscribe(
      (response) => {
        this.loadWorkflowTasks();
      },
      (error) => {
        this.loadWorkflowTasks();
      }
    );
  }

  setDisabled(field: LegacyWorkflowTask.WorkflowTask, disabled: boolean): void {
    this.legacyWorkflowTaskService.setDisabled({
      workflowId: this.workflowId,
      workflowTaskId: field.workflowTaskId,
      disabled: disabled
    }).subscribe(
      (response) => {
        this.loadWorkflowTasks()
      },
      (error) => {
        this.loadWorkflowTasks()
      }
    );
  }

  createWorkflowTask() {
    if (!this.workflowTaskCreateForm.valid) {
      this.workflowTaskCreateForm.get('name')!.markAsTouched();
      this.workflowTaskCreateForm.get('inProgressStateName')!.markAsTouched();
      this.workflowTaskCreateForm.get('finishedStateName')!.markAsTouched();
      return;
    }
    else {
      this.legacyWorkflowTaskService.create({
        workflowId: this.workflowId,
        name: this.workflowTaskCreateModel.name,
        externalId: Strings.undefinedOrNonEmpty(this.workflowTaskCreateModel.externalId),
        description: Strings.undefinedOrNonEmpty(this.workflowTaskCreateModel.description),
        inProgressStateName: this.workflowTaskCreateModel.inProgressStateName,
        finishedStateName: this.workflowTaskCreateModel.finishedStateName,
        customerSignatureEnabled: this.workflowTaskCreateModel.customerSignature,
        userSignatureEnabled: this.workflowTaskCreateModel.userSignature
      }).subscribe((response) => {
          this.closeWorkflowTaskCreateDialog();
          this.loadWorkflowTasks();
          this.uiRouter.stateService.go(StateName.LEGACY_WORKFLOW_TASK_EDIT, {workflowId: this.workflowId, id: response.workflowTaskId});
        },
        (error: any) => {
          const res = ObservableErrorResourceParser.parseError(error);
          this.workflowTaskCreateFieldErrors = ObservableErrorResourceParser.extractFieldErrors(res);
        });
    }
  }

}
