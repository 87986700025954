/* eslint-disable */
import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TransportBaseData } from '../transport-detail/transport-detail.component';
import { RightResolver, RightService } from '../../../lib/right.service';
import { RightModel } from '../../../app.rights';
/* eslint-enable */

@Component({
  selector: 'app-transport-base-data',
  templateUrl: './transport-base-data.component.html',
  styleUrls: ['./transport-base-data.component.scss']
})
export class TransportBaseDataComponent implements OnInit, AfterViewInit {

  @Input()
  transportBaseData: TransportBaseData;

  @Output()
  openModifyBaseDataDialog: EventEmitter<any> = new EventEmitter();

  @Output()
  openBaseDataDetailDialog: EventEmitter<any> = new EventEmitter();

  @Output()
  openSecurityGuardDialog: EventEmitter<any> = new EventEmitter();

  @Output()
  openDriverDialog: EventEmitter<any> = new EventEmitter();

  @Output()
  openVehicleDialog: EventEmitter<any> = new EventEmitter();

  rightModel: RightModel = RightModel.empty();

  get closed(): boolean {
    return this.transportBaseData.stateEnum === 'CLOSED';
  }

  constructor(
    private rightService: RightService
  ) { }

  ngOnInit() {
    this.loadRightModels();
  }

  ngAfterViewInit() {
  }

  onOpenBaseDataDetailButtonClicked() {
    this.openBaseDataDetailDialog.emit();
  }

  onModifyBaseDataButtonClicked() {
    this.openModifyBaseDataDialog.emit();
  }

  private loadRightModels() {
    this.rightService.getRightResolver().subscribe(
      (resolver: RightResolver) => {
        this.rightModel = RightModel.of(resolver);
      }
    );
  }

  get underPlanning(): boolean {
    return this.transportBaseData.stateEnum === 'UNDER_PLANNING'
  }

  get underReplanning(): boolean {
    return this.transportBaseData.stateEnum === 'UNDER_REPLANNING'
  }

  onSecurityGuardClick() {
    if (this.rightModel.transportSetSecurityGuard.hasRight()
      && this.underPlanning
      && this.transportBaseData.safetyShipping) {
      this.openSecurityGuardDialog.emit();
    }
  }

  onDriverClick() {
    if (this.rightModel.transportSetDriver.hasRight()
      && this.underReplanning) {
      this.openDriverDialog.emit();
      return;
    }
    if (this.rightModel.transportUpdate.hasRight()
      && this.underPlanning) {
      this.openModifyBaseDataDialog.emit();
      return;
    }
  }

  onVehicleClick() {
    if (this.rightModel.transportSetVehicle.hasRight()
      && this.underReplanning) {
      this.openVehicleDialog.emit();
    }
    if (this.rightModel.transportUpdate.hasRight()
      && this.underPlanning) {
      this.openModifyBaseDataDialog.emit();
    }
  }
}
