import { Injectable } from '@angular/core';
import { MultiselectProvider } from '../multiselect-provider';
import { Observable, Observer } from 'rxjs';
import { MultiselectOptionItem, MultiselectOptionItemWithData, UiConstants } from '../../util/core-utils';
import { OrderType, QueryResult } from '../util/services';
import { Set } from 'immutable';
import { CustomerRecord, CustomerRecordService } from './customer-record.service';
import { map } from 'rxjs/operators';
import { ConfigurationService } from '../core-ext/configuration.service';
import { Address } from '../address';

@Injectable()
export class CustomerRecordMultiselectProvider implements MultiselectProvider {

  private multiselectQueryRequest: CustomerRecord.GlobalQueryRequest = {
    paging: {
      pageNumber: 1,
      numberOfItems: UiConstants.autocompletePageSize
    },
    noProgressBar: true,
    fields: Set.of('id', 'name', 'disabled', 'postal_address', 'customer_id'),
    orders: Set.of({field: CustomerRecord.OrderField.NAME, type: OrderType.ASC})
  };

  constructor(private customerRecordService: CustomerRecordService,
              private configurationService: ConfigurationService) {
  }

  load(queryRequest: CustomerRecord.GlobalQueryRequest): Observable<CustomerRecordMultiselectItem[]> {
    return Observable.create((observer: Observer<CustomerRecordMultiselectItem[]>) => {
      this.customerRecordService.globalQuery(queryRequest).subscribe((result: QueryResult<CustomerRecord.CustomerRecord>) => {
          observer.next(result.items.toArray().map(item => this.toMultiselectOptionItem(item!)));
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  loadWithAddress(queryRequest: CustomerRecord.GlobalQueryRequest): Observable<CustomerRecordMultiselectItem[]> {
    return Observable.create((observer: Observer<CustomerRecordMultiselectItem[]>) => {
      this.customerRecordService.globalQuery(queryRequest).subscribe((result: QueryResult<CustomerRecord.CustomerRecord>) => {
          observer.next(result.items.toArray().map(item => this.toMultiselectOptionItem(item!)));
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  loadAll(predicate?: string): Observable<CustomerRecordMultiselectItem[]> {
    const queryRequest = Object.assign({}, this.multiselectQueryRequest);
    queryRequest.queryText = predicate;
    return this.load(queryRequest);
  }

  loadActive(predicate?: string): Observable<CustomerRecordMultiselectItem[]> {
    const queryRequest = Object.assign({}, this.multiselectQueryRequest);
    queryRequest.queryText = predicate;
    queryRequest.disabled = false;
    return this.load(queryRequest);
  }

  loadActiveNonContact(predicate?: string): Observable<CustomerRecordMultiselectItem[]> {
    const queryRequest = Object.assign({}, this.multiselectQueryRequest);
    queryRequest.queryText = predicate;
    queryRequest.contactPerson = false;
    queryRequest.disabled = false;
    return this.load(queryRequest);
  }

  loadActiveWithAddress(predicate?: string): Observable<CustomerRecordMultiselectItem[]> {
    const queryRequest = Object.assign({}, this.multiselectQueryRequest);
    const fieldArray = queryRequest.fields!.toArray();
    fieldArray.push('postal_address');
    queryRequest.fields = Set.of(...fieldArray);
    queryRequest.queryText = predicate;
    queryRequest.disabled = false;
    return this.loadWithAddress(queryRequest);
  }

  getById(id: number): Observable<CustomerRecordMultiselectItem> {
    const queryRequest = Object.assign([], this.multiselectQueryRequest);
    queryRequest.customerRecordIdSet = Set.of(id);
    return this.load(queryRequest).pipe(map(result => result[0]));
  }

  getByIds(ids: number[]): Observable<CustomerRecordMultiselectItem[]> {
    const queryRequest = Object.assign([], this.multiselectQueryRequest);
    queryRequest.customerRecordIdSet = Set.of(...ids);
    return this.load(queryRequest);
  }

  toMultiselectOptionItem(item: CustomerRecord.CustomerRecord): CustomerRecordMultiselectItem {
    const ret = new CustomerRecordMultiselectItem();
    ret.id = item.customerRecordId;
    ret.customerId = item.customerId;
    ret.itemName = item.name;
    ret.itemSubtitle = item.postalAddress
      ? Address.PostalAddressMapper.toString(item.postalAddress, this.configurationService.getPostalAddressFormat())
      : undefined;
    ret.disabled = item.disabled;
    ret.data = item.postalAddress;
    return ret;
  }

}

export class CustomerRecordMultiselectItem extends MultiselectOptionItemWithData<number>{
  customerId: number;
}
