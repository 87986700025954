import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ErrorDetail } from '../../../lib/util/errors';
import { LoginRequiredReason, LoginRequiredReasonStorage } from '../../../lib/util/storages';
import { ConfigurationService } from '../../../lib/core-ext/configuration.service';

@Component({
  selector: 'app-helpdesk-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class HelpdeskLoginComponent implements OnInit {

  @Input()
  model: HelpdeskLoginModel;

  @Output()
  onSubmit: EventEmitter<any> = new EventEmitter<any>();

  passwordVisible: boolean = false;

  get environmentName(): string {
    return this.configurationService.getConfiguration().environment
      ? this.configurationService.getConfiguration().environment!
      : '';
  }

  constructor(
    private configurationService: ConfigurationService
  ) { }

  ngOnInit(): void {
  }

  togglePasswordVisibility() {
    this.passwordVisible = !this.passwordVisible;
  }

  getLoginRequiredReason(): LoginRequiredReason | null {
    return LoginRequiredReasonStorage.getInstance().getReason();
  }

}

export class HelpdeskLoginModel {
  username: string = '';
  password: string = '';
  inProgress: boolean = false;
  error: ErrorDetail | null = null;
}
