<div class="card">
  <div class="card-header">
    {{'COLONNADE_CLIENT_DOCUMENT_STAT_ENROLLER_REPORT' | translate}}
    <div class="card-actions">
      <a class="btn-setting table-header-action-button d-flex align-items-center px-1 w-auto font-weight-normal" title="{{'COMMON_BUTTON_EXPORT' | translate}}"
         (click)="downloadXlsx()">
        <i class="icomoon pr-1 icomoon-file-xlsx"></i>
        <span class="breadcrumb-button-text-visible">{{'COMMON_BUTTON_EXPORT' | translate}}</span>
      </a>
    </div><!-- /.card-actions -->
  </div>
  <app-colonnade-client-document-stat-search
    #searchComponent
    (onSearch)="loadList()"
    [searchScope]="ColonnadeClientDocumentStatSearchSearchScope.ENROLLER_REPORT"
    [currentUserId]="currentUserId"
    [userRequired]="false"
    [sponsorCodeRequired]="false"
    [branchCodeRequired]="false"
    [dateFromRequired]="true"
    [dateToRequired]="true"
    [rightModel]="rightModel">
  </app-colonnade-client-document-stat-search>
  <div *ngIf="!searchComponent.didSearch" class="text-center">
    <p class="font-weight-bold text-muted font-xl">{{ 'COLONNADE_CLIENT_DOCUMENT_STAT_NO_DATA_SEARCH_REQUIRED' | translate }}</p>
  </div>
  <div *ngIf="searchComponent.didSearch && sponsorValues.length === 0" class="text-center">
    <p class="font-weight-bold text-muted font-xl">{{ 'COLONNADE_CLIENT_DOCUMENT_STAT_NO_DATA' | translate }}</p>
  </div>
  <div class="card-body" *ngIf="searchComponent.didSearch && sponsorValues.length !== 0">
    <table class="table table-striped table-bordered">
      <thead>
      <tr>
        <th class="table-sorter-header">
          <app-table-sorter-no-op [text]="'COLONNADE_CLIENT_DOCUMENT_STAT_SEARCH_USER' | translate"></app-table-sorter-no-op>
        </th>
        <th class="table-sorter-header">
          <app-table-sorter-no-op [text]="'COLONNADE_CLIENT_DOCUMENT_STAT_SEARCH_SPONSOR_CODE' | translate"></app-table-sorter-no-op>
        </th>
        <th class="table-sorter-header">
          <app-table-sorter-no-op [text]="'COLONNADE_CLIENT_DOCUMENT_STAT_SEARCH_BRANCH_CODE' | translate"></app-table-sorter-no-op>
        </th>
        <th class="table-sorter-header hidden-lg-down">
          <app-table-sorter-no-op [text]="'COLONNADE_CLIENT_DOCUMENT_STAT_SEEN_COUNT' | translate"></app-table-sorter-no-op>
        </th>
        <th class="table-sorter-header hidden-md-down">
          <app-table-sorter-no-op [text]="'COLONNADE_CLIENT_DOCUMENT_STAT_PRESENTED_COUNT' | translate"></app-table-sorter-no-op>
        </th>
        <th class="table-sorter-header hidden-sm-down">
          <app-table-sorter-no-op [text]="'COLONNADE_CLIENT_DOCUMENT_STAT_CUSTOMER_COUNT' | translate"></app-table-sorter-no-op>
        </th>
        <th class="table-sorter-header">
          <app-table-sorter-no-op [text]="'COLONNADE_CLIENT_DOCUMENT_STAT_SUM_PRICE' | translate"></app-table-sorter-no-op>
        </th>
      </tr>
      </thead>
      <tbody>
      <tr class="table"
          *ngFor="let sponsorValue of sponsorValues">
        <td class="responsive-table-column">{{sponsorValue.user.personName}} ({{sponsorValue.user.userName}})</td>
        <td class="responsive-table-column">{{sponsorValue.sponsorText}}</td>
        <td class="responsive-table-column long">{{sponsorValue.branchText}}</td>
        <td class="responsive-table-column hidden-lg-down">{{sponsorValue.seenCount}}</td>
        <td class="responsive-table-column hidden-md-down">{{sponsorValue.presentedCount}}</td>
        <td class="responsive-table-column hidden-sm-down">{{sponsorValue.customerCount}}</td>
        <td class="responsive-table-column">{{sponsorValue.sumPrice | formattedNumber}} Ft</td>
      </tr>
      </tbody>
      <tfoot>
      <tr>
        <th class="table-sorter-header">
          <app-table-sorter-no-op [text]="'COLONNADE_CLIENT_DOCUMENT_STAT_SEARCH_USER' | translate"></app-table-sorter-no-op>
        </th>
        <th class="table-sorter-header">
          <app-table-sorter-no-op [text]="'COLONNADE_CLIENT_DOCUMENT_STAT_SEARCH_SPONSOR_CODE' | translate"></app-table-sorter-no-op>
        </th>
        <th class="table-sorter-header">
          <app-table-sorter-no-op [text]="'COLONNADE_CLIENT_DOCUMENT_STAT_SEARCH_BRANCH_CODE' | translate"></app-table-sorter-no-op>
        </th>
        <th class="table-sorter-header hidden-lg-down">
          <app-table-sorter-no-op [text]="'COLONNADE_CLIENT_DOCUMENT_STAT_SEEN_COUNT' | translate"></app-table-sorter-no-op>
        </th>
        <th class="table-sorter-header hidden-md-down">
          <app-table-sorter-no-op [text]="'COLONNADE_CLIENT_DOCUMENT_STAT_PRESENTED_COUNT' | translate"></app-table-sorter-no-op>
        </th>
        <th class="table-sorter-header hidden-sm-down">
          <app-table-sorter-no-op [text]="'COLONNADE_CLIENT_DOCUMENT_STAT_CUSTOMER_COUNT' | translate"></app-table-sorter-no-op>
        </th>
        <th class="table-sorter-header">
          <app-table-sorter-no-op [text]="'COLONNADE_CLIENT_DOCUMENT_STAT_SUM_PRICE' | translate"></app-table-sorter-no-op>
        </th>
      </tr>
      </tfoot>
    </table>
  </div>
</div>
