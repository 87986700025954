/* eslint-disable */
import { ComponentFactoryResolver, Directive, ViewContainerRef, } from '@angular/core';
import { DefaultFormRecordGroupViewFactory } from '../../../util/form/form-utils-group.default';
import { FormRecordGroupViewFactory } from '../../../util/form/form-utils';
/* eslint-enable */

/* eslint-disable */
@Directive({
  selector: '[app-form-record-group-holder]',
})
/* eslint-enable */
export class FormRecordGroupHolderDirective {

  public readonly groupViewFactory: FormRecordGroupViewFactory;

  constructor(
      public viewContainerRef: ViewContainerRef, cfr: ComponentFactoryResolver) {
    this.groupViewFactory = new DefaultFormRecordGroupViewFactory(
      viewContainerRef.injector, cfr
    );
  }

}
