/* eslint-disable */
import { Form } from '../../../../lib/form/form.service';
import {
  FormEditCreateUpdateDateFieldDialogComponent
} from '../../../form/form-edit/dialogs/fields/date/create-update/form-edit-create-update-date-field-dialog.component';
import { Set } from 'immutable';
import { FormRecordContainer } from '../../../form/form-record/form-record-container';
import { FormRecordDateFieldUpdateArgs } from '../../../form/form-record/fields/date/form-record-date-field.component';
import { CustomerRecordItem } from '../../../../util/customer-record-utils';
import {
  FormRecordFieldUpdateCustomerLocationArgs
} from '../../../form/form-record/fields/master-data/form-record-master-data-field.component';
import { OwnerUserItem } from '../../../../util/core-utils';
import { FormRecordStockFieldUserUpdateArgs } from '../../../form/form-record/fields/stock/form-record-stock-field.component';
import { TaskRecordStateMachine } from '../../../../lib/task/task-record-statemachine';
import FieldDataTypeSelector = Form.FieldDataTypeSelector;

/* eslint-enable */

export class TaskRecordFormFieldUpdater {

  updatableFormFields: Set<{ groupId: number, field: Form.Field }> = Set.of();

  constructor(form: Form.Form, private formRecordContainerView: FormRecordContainer.View) {
    this.findUpdatableFields(form);
  }

  private findUpdatableFields(form: Form.Form) {
    const updatableFields: { groupId: number, field: Form.Field }[] = [];
    for (const group of form.groups.toArray()) {
      if (group) {
        for (const field of group.fields.toArray()) {
          if (field.apiExportName === FormEditCreateUpdateDateFieldDialogComponent.DEADLINE_API_NAME
            || field.dataTypeSelector === FieldDataTypeSelector.STOCK
            || field.dataTypeSelector === FieldDataTypeSelector.STOCK_INTAKE
            || field.dataTypeSelector === FieldDataTypeSelector.STOCK_MOVE
            || field.dataTypeSelector === FieldDataTypeSelector.MASTER_DATA
            || field.dataTypeSelector === FieldDataTypeSelector.CUSTOMER) {
            updatableFields.push({groupId: group.groupId, field: field});
          }
        }
      }
    }
    this.updatableFormFields = Set.of(...updatableFields);
  }

  onCustomerRecordChanged(newCustomer: CustomerRecordItem | undefined, locationId: number | undefined) {
    const updateFieldRequests: FormRecordContainer.FieldUpdateArgs[] = [];
    let customerRecordId: number | undefined;
    let customerId: number | undefined;
    if (newCustomer && newCustomer.customerRecord) {
      customerRecordId = newCustomer.customerRecord.customerRecordId;
      customerId = newCustomer.customerId;
    }
    this.updatableFormFields.forEach(ff => {
      if (ff) {
        const fieldIdentifier = {
          groupId: ff.groupId,
          fieldId: ff.field.fieldId
        };

        if (ff.field.apiExportName === FormEditCreateUpdateDateFieldDialogComponent.DEADLINE_API_NAME) {
          let plusDays: number | undefined;
          if (newCustomer) {
            plusDays = newCustomer.invoiceDeadlineAdditionalDays;
          }
          const data: FormRecordDateFieldUpdateArgs = new FormRecordDateFieldUpdateArgs(plusDays);
          updateFieldRequests.push({
            fieldIdentifier: fieldIdentifier,
            data: data
          });
        }
        else if (ff.field.dataTypeSelector === FieldDataTypeSelector.MASTER_DATA ||
          ff.field.dataTypeSelector === FieldDataTypeSelector.CUSTOMER ||
          ff.field.dataTypeSelector === FieldDataTypeSelector.STOCK ||
          ff.field.dataTypeSelector === FieldDataTypeSelector.STOCK_INTAKE ||
          ff.field.dataTypeSelector === FieldDataTypeSelector.STOCK_MOVE) {
          const data: FormRecordFieldUpdateCustomerLocationArgs =
            new FormRecordFieldUpdateCustomerLocationArgs(customerId, customerRecordId, locationId);
          updateFieldRequests.push({
            fieldIdentifier: fieldIdentifier,
            data: data
          });
        }
      }
    });
    if (updateFieldRequests.length > 0) {
      this.formRecordContainerView.updateFormRecordValues({fields: updateFieldRequests});
    }
  }

  onAssigneeUserChanged(user: OwnerUserItem | undefined, taskRecordState?: TaskRecordStateMachine.State) {
    const updateFieldRequests: FormRecordContainer.FieldUpdateArgs[] = [];
    this.updatableFormFields.forEach(ff => {
        if (ff) {
          const fieldIdentifier = {
            groupId: ff.groupId,
            fieldId: ff.field.fieldId
          };
          if (ff.field.dataTypeSelector === FieldDataTypeSelector.STOCK) {
            const data: FormRecordStockFieldUserUpdateArgs =
              new FormRecordStockFieldUserUpdateArgs(user ? user.id! : undefined, taskRecordState);
            updateFieldRequests.push({
              fieldIdentifier: fieldIdentifier,
              data: data
            });
          }
        }
      }
    );
    if (updateFieldRequests.length > 0) {
      this.formRecordContainerView.updateFormRecordValues({fields: updateFieldRequests});
    }
  }

  onContactLocationChanged(locationId: number | undefined) {
    const updateFieldRequests: FormRecordContainer.FieldUpdateArgs[] = [];
    this.updatableFormFields.forEach(ff => {
        if (ff) {
          const fieldIdentifier = {
            groupId: ff.groupId,
            fieldId: ff.field.fieldId
          };
          if (ff.field.dataTypeSelector === FieldDataTypeSelector.MASTER_DATA ||
            ff.field.dataTypeSelector === FieldDataTypeSelector.CUSTOMER ||
            ff.field.dataTypeSelector === FieldDataTypeSelector.STOCK ||
            ff.field.dataTypeSelector === FieldDataTypeSelector.STOCK_INTAKE ||
            ff.field.dataTypeSelector === FieldDataTypeSelector.STOCK_MOVE) {
            const data: FormRecordFieldUpdateCustomerLocationArgs = new FormRecordFieldUpdateCustomerLocationArgs(null,null, locationId);
            updateFieldRequests.push({
              fieldIdentifier: fieldIdentifier,
              data: data
            });
          }
        }
      }
    );
    if (updateFieldRequests.length > 0) {
      this.formRecordContainerView.updateFormRecordValues({fields: updateFieldRequests});
    }
  }
}
