import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MultiselectOptionItem } from '../../../../../util/core-utils';
import { TypeBasedRoleModel, UserGroupRoleType } from '../user-group-type-based-role-card.component';
import { Task, TaskService } from '../../../../../lib/task/task.service';
import { Customer, CustomerService } from '../../../../../lib/customer/customer.service';
import { Observable } from 'rxjs';
import { QueryResult, ResourceQueryResult } from '../../../../../lib/util/services';
import { Strings } from '../../../../../lib/util/strings';
import { Angular2Multiselects } from '../../../../../util/multiselect';
import { TypeBasedRoleResource, UserGroup, UserGroupService } from '../../../../../lib/user-group.service';
import { PermissionResourceService } from '../../../../../lib/permission-resource.service';

@Component({
  selector: 'app-user-group-type-based-role-edit',
  templateUrl: './user-group-type-based-role-edit.component.html',
  styleUrls: ['./user-group-type-based-role-edit.component.scss']
})
export class UserGroupTypeBasedRoleEditComponent implements OnInit {

  Type = UserGroupRoleType;

  @Input()
  _model?: TypeBasedRoleModel;

  @Input()
  type: UserGroupRoleType;

  @Output()
  onSubmit: EventEmitter<TypeBasedRoleEditModel> = new EventEmitter();

  @Output()
  onCancel: EventEmitter<any> = new EventEmitter();

  model: TypeBasedRoleEditModel;

  types: MultiselectOptionItem<number>[] = [];
  privileges: MultiselectOptionItem<string>[] = [];

  typeDropdownSettings: Angular2Multiselects.Settings;
  privilegeDropdownSettings: Angular2Multiselects.Settings;

  get typeKey(): string {
    switch (this.type) {
      case UserGroupRoleType.USERGROUP_TASK:
        return 'COMMON_TASK_TYPE';
      case UserGroupRoleType.USERGROUP_CUSTOMER:
        return 'CUSTOMER_SEARCH_FIELD_TYPE';
      case UserGroupRoleType.TASK:
      case UserGroupRoleType.CUSTOMER:
        return 'COMMON_USER_GROUP';
    }
  }

  constructor(
    private taskService: TaskService,
    private customerService: CustomerService,
    private userGroupService: UserGroupService,
    private permissionService: PermissionResourceService
  ) {
  }

  ngOnInit(): void {
    this.model = new TypeBasedRoleEditModel(this._model);
    this.initDropdownSettings();
    this.loadTypes();
    this.loadPrivileges();
  }

  private initDropdownSettings() {
    this.typeDropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(this.type === UserGroupRoleType.USERGROUP_TASK || this.type === UserGroupRoleType.USERGROUP_CUSTOMER)
      .enableSearchFilter(true)
      .enableCheckAll(this.type !== UserGroupRoleType.USERGROUP_TASK && this.type !== UserGroupRoleType.USERGROUP_CUSTOMER)
      .remoteSearch(true)
      .build();
    this.privilegeDropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(false)
      .enableSearchFilter(true)
      .enableCheckAll(true)
      .build();
  }

  loadTypes(q?: string) {
    this.getTypeQueryObservable(q).subscribe(result => {
      this.types = this.toTypeModel(result.items);
    });
  }

  private getTypeQueryObservable(q?: string)
    : Observable<QueryResult<Task.Task> | QueryResult<Customer.Customer> | ResourceQueryResult<UserGroup>> {
    switch (this.type) {
      case UserGroupRoleType.USERGROUP_TASK:
        return this.taskService.query({name: q ? Strings.undefinedOrNonEmpty(q) : undefined, noProgressBar: true});
      case UserGroupRoleType.USERGROUP_CUSTOMER:
        return this.customerService.query({name: q ? Strings.undefinedOrNonEmpty(q) : undefined, noProgressBar: true});
      case UserGroupRoleType.TASK:
      case UserGroupRoleType.CUSTOMER:
        return this.userGroupService.query({name: q ? Strings.undefinedOrNonEmpty(q) : undefined, no_progress_bar: true});
    }
  }

  private toTypeModel(types): MultiselectOptionItem<number>[] {
    switch (this.type) {
      case UserGroupRoleType.USERGROUP_TASK:
        return types.toArray().map(t => ({id: t.taskId!, itemName: t.name, itemSubtitle: t.externalId}));
      case UserGroupRoleType.USERGROUP_CUSTOMER:
        return types.toArray().map(t => ({id: t.customerId!, itemName: t.name, itemSubtitle: t.externalId}));
      case UserGroupRoleType.TASK:
      case UserGroupRoleType.CUSTOMER:
        return types.map(t => ({id: t.id, itemName: t.name}));
    }
  }

  private loadPrivileges() {
    this.permissionService.query({
      explicit: false,
    }).subscribe(permissions => {
      permissions.forEach(p => {
        const value = p.definition;
        if (value.startsWith(this.getPrivilegeFilter())) {
          this.privileges.push({
            id: value,
            itemName: value
          });
        }
      });
    });

  }

  private getPrivilegeFilter(): string {
    switch (this.type) {
      case UserGroupRoleType.USERGROUP_TASK:
      case UserGroupRoleType.TASK:
        return 'TaskRecord:';
      case UserGroupRoleType.USERGROUP_CUSTOMER:
      case UserGroupRoleType.CUSTOMER:
        return 'CustomerRecord:';
    }
  }

}

export class TypeBasedRoleEditModel {
  id?: number;
  userGroups: MultiselectOptionItem<number>[] = [];
  type: MultiselectOptionItem<number>[] = [];
  privileges: MultiselectOptionItem<string>[] = [];

  constructor(model?: TypeBasedRoleModel) {
    if (model) {
      this.id = model.id;
      this.userGroups = model.userGroups;
      this.type.push({
        id: model.typeId,
        itemName: model.typeName,
        itemSubtitle: model.typeExternalId
      });
      this.privileges = model.privileges.map(p => ({id: p, itemName: p}));
    }
  }

  toRequest(): TypeBasedRoleResource {
    return {
      id: this.id,
      type_id: this.type.length === 1 ? this.type[0]!.id : 0,
      user_group_ids: this.userGroups.length > 0
        ? this.userGroups.map(i => i.id)
        : undefined,
      privileges: this.privileges.map(p => p.id)
    };
  }
}
