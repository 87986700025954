<div class="rule-container mt-half">
  <div class="d-flex justify-content-between">
    <span class="font-weight-bold mb-1">{{
      create
        ? ('WORKFLOW_EDIT_TRANSITION_FORM_FIELD_RULE_CREATE' | translate)
        : readonly
          ? ('WORKFLOW_EDIT_TRANSITION_FORM_FIELD_RULE_DETAIL' | translate)
          : ('WORKFLOW_EDIT_TRANSITION_FORM_FIELD_RULE_MODIFY' | translate)
      }}</span>
    <a class="btn-setting cursor-pointer" (click)="deleteRule.emit()" *ngIf="!create && !readonly"
       title="{{'COMMON_BUTTON_DELETE' | translate}}"><i class="icomoon icomoon-trash"></i></a>
  </div>
  <form class="form-horizontal bordered-row" (ngSubmit)="f.form.valid && save()" #f="ngForm" novalidate>
    <p class="w-100 m-0">{{getName()}}</p>
    <div *ngIf="operatorRequired">
      <label class="search-label">{{'WORKFLOW_EDIT_TRANSITION_FORM_FIELD_RULE_OPERATOR' | translate}}</label>
      <div class="row m-0">
        <button type="button" class="btn col mr-half"
                [class.btn-primary-applion-green-gray]="model.operator !== RuleOperator.AND"
                [class.btn-secondary-applion-green-gray]="model.operator === RuleOperator.AND"
                (click)="!readonly && setOperator(RuleOperator.AND)">
          {{'WORKFLOW_TRANSITION_RULE_OPERATOR_AND' | translate}}
        </button>
        <button type="button" class="btn col"
                [class.btn-primary-applion-green-gray]="model.operator !== RuleOperator.OR"
                [class.btn-secondary-applion-green-gray]="model.operator === RuleOperator.OR"
                (click)=" !readonly && setOperator(RuleOperator.OR)">
          {{'WORKFLOW_TRANSITION_RULE_OPERATOR_OR' | translate}}
        </button>
      </div>
    </div>
    <div [ngClass]="{ 'has-danger': f.submitted && !formField.valid}">
      <label class="search-label form-control-label">{{'WORKFLOW_EDIT_TRANSITION_FORM_FIELD_RULE_FORM_FIELD' | translate}}</label>
      <angular2-multiselect [data]="formFields"
                            [settings]="dropdownSettingsForFormField"
                            class="form-control"
                            id="formField"
                            name="formField"
                            #formField="ngModel"
                            [(ngModel)]="model.formField"
                            (ngModelChange)="onFormFieldChanged()"
                            required
                            validateEnabledItems
                            [readonly]="readonly"
                            [readonlyBorderEnabled]="true">
      </angular2-multiselect>
      <div class="form-control-feedback" *ngIf="f.submitted && !formField.valid && formField.errors['required']">
        {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
      </div>
      <div class="form-control-feedback" *ngIf="f.submitted && !formField.valid && formField.errors['hasDisabledItem']">
        {{'COMMON_VALIDATION_MESSAGE_DISABLED' | translate}}
      </div>
    </div>
    <div [ngClass]="{ 'has-danger': f.submitted && !command.valid}">
      <label class="search-label form-control-label">{{'WORKFLOW_EDIT_TRANSITION_FORM_FIELD_RULE_COMMAND' | translate}}</label>
      <angular2-multiselect [data]="commands"
                            [settings]="dropdownSettingsForCommand"
                            class="form-control"
                            id="command"
                            name="command"
                            #command="ngModel"
                            [(ngModel)]="model.command"
                            required
                            [readonly]="readonly"
                            [readonlyBorderEnabled]="true">
      </angular2-multiselect>
      <div class="form-control-feedback" *ngIf="f.submitted && !command.valid">
        {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
      </div>
    </div>
    <div *ngIf="model.type === RuleType.LIST"
         [ngClass]="{ 'has-danger': f.submitted && !listItemValue.valid}">
      <label class="search-label form-control-label">
        {{'WORKFLOW_EDIT_TRANSITION_FORM_FIELD_RULE_VALUE' | translate}}
      </label>
      <angular2-multiselect [data]="listItems"
                            [settings]="dropdownSettingsForListItem"
                            class="form-control"
                            id="listItemValue"
                            name="listItemValue"
                            #listItemValue="ngModel"
                            (onRemoteSearch)="loadListItems($event.target.value)"
                            [(ngModel)]="model.listItemValue"
                            [required]="model.type === RuleType.LIST"
                            [readonly]="readonly"
                            [readonlyBorderEnabled]="true">
      </angular2-multiselect>
      <div class="form-control-feedback" *ngIf="f.submitted && !listItemValue.valid">
        {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
      </div>
    </div>
    <div *ngIf="model.type === RuleType.STRING"
         [ngClass]="{ 'has-danger': f.submitted && !stringValue.valid}">
      <label class="search-label form-control-label">
        {{'WORKFLOW_EDIT_TRANSITION_FORM_FIELD_RULE_VALUE' | translate}}
      </label>
      <input type="text" class="form-control" placeholder="{{'WORKFLOW_EDIT_TRANSITION_FORM_FIELD_RULE_VALUE' | translate}}"
             maxlength="{{UiConstants.maximumVarcharLength}}"
             [ngClass]="{ 'form-control-danger': f.submitted && !stringValue.valid }"
             id="stringValue"
             name="stringValue"
             #stringValue="ngModel"
             [(ngModel)]="model.stringValue"
             [required]="model.type === RuleType.STRING"
             [readOnly]="readonly">
      <div class="form-control-feedback" *ngIf="f.submitted && !stringValue.valid">
        {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
      </div>
    </div>
    <div *ngIf="model.type === RuleType.NUMBER"
         [ngClass]="{ 'has-danger': f.submitted && !numberValue.valid}">
      <label class="search-label form-control-label">
        {{'WORKFLOW_EDIT_TRANSITION_FORM_FIELD_RULE_VALUE' | translate}}
      </label>
      <input type="number" class="form-control" placeholder="{{'WORKFLOW_EDIT_TRANSITION_FORM_FIELD_RULE_VALUE' | translate}}"
             maxlength="{{UiConstants.maximumVarcharLength}}"
             [ngClass]="{ 'form-control-danger': f.submitted && !numberValue.valid }"
             id="numberValue"
             name="numberValue"
             #numberValue="ngModel"
             [(ngModel)]="model.numberValue"
             [required]="model.type === RuleType.NUMBER"
             [readOnly]="readonly">
      <div class="form-control-feedback" *ngIf="f.submitted && !numberValue.valid">
        {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
      </div>
    </div>
    <button type="button" class="btn w-100 btn-primary-applion-green-gray mt-1" *ngIf="create"
            (click)="createSubmitted.emit(false)">
      {{'COMMON_BUTTON_CANCEL' | translate}}
    </button>
    <button type="submit" class="btn w-100 btn-secondary-applion-green-gray mt-half" *ngIf="!readonly">
      {{'COMMON_BUTTON_SAVE' | translate}}
    </button>
  </form>
</div>
