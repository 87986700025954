import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { WorkflowEditorApplication } from './utils/workflow-editor-application';
import { CanvasDragLogService } from './utils/service/canvas-drag-log-service';
import { SidebarTogglerService } from '../../../lib/util/sidebar-toggler.service';
import { Subscription } from 'rxjs';
import { Workflow, WorkflowService } from '../../../lib/workflow/workflow.service';
import {
  CanvasSelectionData,
  CanvasSelectionService,
  CanvasSelectionType
} from './utils/service/canvas-selection-service';
import { MatDialog } from '@angular/material/dialog';
import { TaskRecordStateMachine } from '../../../lib/task/task-record-statemachine';
import { OffsetDateTime } from '../../../lib/util/dates';

@Component({
  selector: 'app-workflow-create-editor',
  templateUrl: './workflow-create-editor.component.html',
  styleUrls: ['./workflow-create-editor.component.scss']
})
export class WorkflowCreateEditorComponent implements OnInit, AfterViewInit, OnDestroy {

  CanvasSelectionType = CanvasSelectionType;

  @Input()
  set workflow(workflow: Workflow.Workflow) {
    if (workflow) {
      this._workflow = workflow;
      if (this.app) {
        this.app.canvas.setWorkflowId(workflow.id);
        if (workflow.stateMachine) {
          this.app.canvas.setReadonly(this.readonly);
          this.app.setStateMachine(this.readonly, this.workflow.stateMachine!, this.taskStateMap);
        }
      }
    }
  }
  get workflow(): Workflow.Workflow {
    return this._workflow;
  }
  _workflow: Workflow.Workflow;

  @Input()
  workflowId: number;

  private _readonly: boolean;
  @Input()
  set readonly(value: boolean) {
    this._readonly = value;
  }
  get readonly(): boolean {
    return this._readonly || (this.workflow && this.workflow.versionState !== Workflow.VersionState.DRAFT);
  }

  @Input()
  displayOnProcessDetail: boolean;

  @Input()
  taskStateMap?: Map<number, {state: TaskRecordStateMachine.State, creationTime: OffsetDateTime}>;

  @ViewChild('zoomContainer', { static: true })
  zoomContainer: ElementRef;

  app: WorkflowEditorApplication;

  sidebarSubscription: Subscription;
  canvasSubscription: Subscription;
  canvasSelection: CanvasSelectionData = {
    type: CanvasSelectionType.NONE
  };

  constructor(
    private canvasDragLogService: CanvasDragLogService,
    private canvasSelectionService: CanvasSelectionService,
    private sidebarTogglerService: SidebarTogglerService,
    private workflowService: WorkflowService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.canvasSubscription = this.canvasSelectionService.subscribe(data => {
      this.canvasSelection = data;
    });
    // This creates and runs the workflow editor application
    this.app = new WorkflowEditorApplication(
      this.canvasDragLogService,
      this.canvasSelectionService,
      this.workflowService,
      this.dialog
    );
    this.workflow = this._workflow;
  }

  ngAfterViewInit(): void {
    this.sidebarSubscription = this.sidebarTogglerService.subscribe(open => {
      if (!this.readonly) {
        if (open) {
          this.zoomContainer.nativeElement.classList.remove('zoom-container-compact');
        }
        else {
          this.zoomContainer.nativeElement.classList.add('zoom-container-compact');
        }
      }
    });
  }

  zoomIn() {
    this.app.canvas.setZoom(this.app.canvas.getZoom() * 0.7);
  }

  zoomOut() {
    this.app.canvas.setZoom(this.app.canvas.getZoom() * 1.3);
  }

  ngOnDestroy() {
    this.sidebarTogglerService.unsubscribe(this.sidebarSubscription);
    if (!this.displayOnProcessDetail) {
      this.canvasSubscription.unsubscribe();
    }
    this.app.canvas.onDestroy();
  }

}
