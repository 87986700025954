import { SiteTourBaseComponent, SiteTourFactory, SiteTourId } from '../site-tour.factory';
import { TourStep } from 'ngx-guided-tour';
import { SiteTourStringKeys } from '../site-tour-string-keys';

export class TaskRecordCreateTourFactory extends SiteTourFactory {

  protected getTourSteps(baseComponent?: SiteTourBaseComponent): TourStep[] {
    return [
      {
        selector: '#task-record-base-card',
        title: SiteTourStringKeys.SITE_TOUR_TASK_RECORD_CREATE_BASE_STEP_TITLE,
        content: SiteTourStringKeys.SITE_TOUR_TASK_RECORD_CREATE_BASE_STEP_CONTENT,
        orientation: 'center',
        action: () => {
        },
        closeAction: () => {
        },
        skipStep: false,
      },
      {
        selector: '#task-record-base-name-group',
        title: SiteTourStringKeys.SITE_TOUR_TASK_RECORD_CREATE_NAME_STEP_TITLE,
        content: SiteTourStringKeys.SITE_TOUR_TASK_RECORD_CREATE_NAME_STEP_CONTENT,
        orientation: 'bottom',
        action: () => {
        },
        closeAction: () => {
        },
        skipStep: false,
      },
      {
        selector: '#task-record-base-assignee-user-group',
        title: SiteTourStringKeys.SITE_TOUR_TASK_RECORD_CREATE_ASSIGNEE_USER_STEP_TITLE,
        content: SiteTourStringKeys.SITE_TOUR_TASK_RECORD_CREATE_ASSIGNEE_USER_STEP_CONTENT,
        orientation: 'bottom',
        action: () => {
        },
        closeAction: () => {
        },
        skipStep: false,
      },
      {
        selector: '#task-record-base-assignee-group-group',
        title: SiteTourStringKeys.SITE_TOUR_TASK_RECORD_CREATE_ASSIGNEE_GROUP_STEP_TITLE,
        content: SiteTourStringKeys.SITE_TOUR_TASK_RECORD_CREATE_ASSIGNEE_GROUP_STEP_CONTENT,
        orientation: 'bottom',
        action: () => {
        },
        closeAction: () => {
        },
        skipStep: false,
      },
      {
        selector: '#task-record-base-customer-group',
        title: SiteTourStringKeys.SITE_TOUR_TASK_RECORD_CREATE_CUSTOMER_STEP_TITLE,
        content: SiteTourStringKeys.SITE_TOUR_TASK_RECORD_CREATE_CUSTOMER_STEP_CONTENT,
        orientation: 'bottom',
        action: () => {
        },
        closeAction: () => {
        },
        skipStep: false,
      },
      {
        selector: '#task-record-base-poc-card',
        title: SiteTourStringKeys.SITE_TOUR_TASK_RECORD_CREATE_POC_STEP_TITLE,
        content: SiteTourStringKeys.SITE_TOUR_TASK_RECORD_CREATE_POC_STEP_CONTENT,
        orientation: 'top',
        action: () => {
        },
        closeAction: () => {
        },
        skipStep: false,
      },
      {
        selector: '.form-record-default-group',
        title: SiteTourStringKeys.SITE_TOUR_TASK_RECORD_CREATE_FORM_STEP_TITLE,
        content: SiteTourStringKeys.SITE_TOUR_TASK_RECORD_CREATE_FORM_STEP_CONTENT,
        orientation: 'top',
        action: () => {
        },
        closeAction: () => {
        },
        skipStep: false,
        scrollAdjustment: 100
      },
      {
        selector: '#task-record-base-save-button',
        title: SiteTourStringKeys.SITE_TOUR_TASK_RECORD_CREATE_SAVE_STEP_TITLE,
        content: SiteTourStringKeys.SITE_TOUR_TASK_RECORD_CREATE_SAVE_STEP_CONTENT,
        orientation: 'top-right',
        useHighlightPadding: true,
        action: () => {
        },
        closeAction: () => {
        },
        skipStep: false,
      },
    ];
  }

  protected get tourKey(): SiteTourId {
    return SiteTourId.TASK_RECORD_CREATE;
  }

}
