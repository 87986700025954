<div class="breadcrumb-container" [ngClass]="{ 'breadcrumb-container-compact': compactSidebar }" id="breadcrumb"
     *ngIf="!componentEmbedded">
  <div class="breadcrumb-component">
    <app-breadcrumb
      [parents]="breadcrumbParents"
      [self]="breadcrumbSelf">
    </app-breadcrumb>
  </div>
</div>
<div class="breadcrumb-placeholder" *ngIf="!componentEmbedded"></div>
<div class="animated fadeIn"
     [ngClass]="{
      'container-horizontal-padding container-vertical-padding': !componentEmbedded,
      'modal-content': componentEmbedded
     }">
  <div class="modal-header" *ngIf="componentEmbedded">
    <p class="modal-title"><strong>{{'LIST_ITEM_PANEL_HEADING_CREATE' | translate}}</strong></p>
    <button type="button" class="close" (click)="closeDialog(false)" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <form class="form-horizontal bordered-row" #f="ngForm" novalidate>
    <div [ngClass]="{
         'modal-body': componentEmbedded
       }">
      <div class="col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 p-0">
        <div [ngClass]="{
               'card': !componentEmbedded
             }">

          <div class="card-header" *ngIf="!componentEmbedded">
            {{'LIST_ITEM_PANEL_HEADING_CREATE'|translate}}
          </div>

          <div [ngClass]="{
                 'card-body': !componentEmbedded
               }">
            <div class="form-group row" *ngIf="!typeId"
                 [ngClass]="{ 'has-danger': (fieldErrors.type) || (submitted && !type.valid) || (submitted && !model.type) }">
              <div class="col-md-4">
                <label class="mw-100 col-form-label form-control-label required-field-label">
                  {{'COMMON_TYPE'|translate}}
                </label>
              </div>
              <div class="col-md-8">
                <div class="mw-100">
                  <angular2-multiselect
                    id="type"
                    name="type"
                    #type="ngModel"
                    [(ngModel)]="model._type"
                    [ngModelOptions]="{standalone: true}"
                    [ngClass]="{ 'form-control-danger': (fieldErrors.type) || (submitted && !type.valid) || (submitted&& !model.type) }"
                    (ngModelChange)="onListItemTypeChange()"
                    (onRemoteSearch)="loadListItemTypes($event.target.value)"
                    [data]="listItemTypes"
                    [settings]="dropdownSettings">
                  </angular2-multiselect>
                  <div class="form-control-feedback" *ngIf="fieldErrors.type">{{fieldErrors.type.text}}</div>
                  <div class="form-control-feedback"
                       *ngIf="(submitted && !type.valid) || (submitted && !model.type)">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</div>
                </div>
              </div>
            </div>

            <div class="form-group row" [ngClass]="{ 'has-danger': (fieldErrors.code) || (submitted && !code.valid) }">
              <div class="col-md-4">
                <label class="mw-100 col-form-label form-control-label required-field-label">
                  {{'LIST_ITEM_CODE' | translate}}
                </label>
              </div>
              <div class="col-md-8">
                <div class="mw-100">
                  <input type="text" class="form-control"
                         maxlength="{{UiConstants.maximumVarcharLength}}"
                         [ngClass]="{ 'form-control-danger': (fieldErrors.code) || (submitted && !code.valid) }"
                         (ngModelChange)="removeFieldError(fieldErrors.code)"
                         placeholder="{{'LIST_ITEM_CODE' | translate}}" [(ngModel)]="model.code"
                         name="code" id="code" #code="ngModel" required>
                  <div class="form-control-feedback" *ngIf="fieldErrors.code">{{fieldErrors.code.text}}</div>
                  <div class="form-control-feedback"
                       *ngIf="submitted && !code.valid">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</div>
                </div>
              </div>
            </div>

            <div class="form-group row" [ngClass]="{ 'has-danger': (fieldErrors.text) || (submitted && !text.valid) }">
              <div class="col-md-4">
                <label class="mw-100 col-form-label form-control-label required-field-label">
                  {{'LIST_ITEM_TEXT' | translate}}
                </label>
              </div>
              <div class="col-md-8">
                <div class="mw-100">
                  <input type="text" class="form-control" [(ngModel)]="model.text" #text="ngModel"
                         [ngClass]="{ 'form-control-danger': (fieldErrors.text) || (submitted && !text.valid) }"
                         (ngModelChange)="removeFieldError(fieldErrors.text)"
                         placeholder="{{'LIST_ITEM_TEXT' | translate}}"
                         name="text" id="text" required>
                  <div class="form-control-feedback"
                       *ngIf="submitted && !text.valid">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="container-vertical-padding-half" *ngIf="model.params.length > 0"></div>

        <div class="card" *ngIf="model.params.length > 0">

          <div class="card-header">
            {{'LIST_ITEM_TYPE_PANEL_HEADING_PARAMS' | translate}}
          </div>
          <div class="card-body">

            <div class="form-group row">
              <div class="col">
                <label class="col-form-label">{{'COMMON_TYPE' | translate}}</label>
              </div>
              <div class="col">
                <label class="col-form-label">{{'LIST_ITEM_TYPE_PARAM_KEY' | translate}}</label>
              </div>
              <div class="col">
                <label class="col-form-label required-field-label">
                  {{'LIST_ITEM_PARAM_DEFAULT_VALUE' | translate}}
                </label>
              </div>
            </div>

            <div class="form-group row"
                 *ngFor="let param of model.params; let i = index">
              <div class="col">
                <label class="col-form-label">{{getParamTypeString(param)}}</label>
              </div>
              <div class="col">
                <label class="col-form-label">{{param.key}}</label>
              </div>
              <div class="col" *ngIf="param.param_type === 'STRING'"
                   [ngClass]="{ 'has-danger': submitted && !paramStringValue.valid}">
                <input type="text" class="form-control"
                       maxlength="{{UiConstants.maximumVarcharLength}}"
                       [ngClass]="{ 'form-control-danger': submitted && !paramStringValue.valid }"
                       [(ngModel)]="param.string_value"
                       name="{{'paramStringValue' + i}}" id="{{'paramStringValue' + i}}" #paramStringValue="ngModel"
                       required>
                <div class="form-control-feedback"
                     *ngIf="submitted && !paramStringValue.valid">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</div>
              </div>
              <div class="col" *ngIf="param.param_type === 'NUMBER'"
                   [ngClass]="{ 'has-danger': submitted && !paramNumberValue.valid}">
                <input type="text" class="form-control"
                       maxlength="17"
                       [ngClass]="{ 'form-control-danger': submitted && !paramNumberValue.valid }"
                       [(ngModel)]="param.number_value"
                       name="{{'paramNumberValue' + i}}" id="{{'paramNumberValue' + i}}" #paramNumberValue="ngModel"
                       required>
                <div class="form-control-feedback"
                     *ngIf="submitted && !paramNumberValue.valid">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</div>
              </div>
            </div>
          </div> <!-- card block end -->

        </div> <!-- card end -->

        <div class="container-vertical-padding-half" *ngIf="model.type && model.type.filterTypeId"></div>

        <div class="card" *ngIf="model.type && model.type.filterTypeId">

          <div class="card-header">
            {{'LIST_ITEM_PANEL_HEADING_FILTER' | translate}}
            <i class="icomoon icomoon-info info-icon" popover="{{'LIST_ITEM_FILTER_INFO' | translate}}"
               triggers="mouseenter:mouseleave" container="body" placement="right"></i>
          </div>
          <div class="card-body" [ngClass]="{ 'has-danger': submitted && !filterItemsInput.valid }">

            <angular2-multiselect [data]="filterItems" [settings]="filterItemDropdownSettings"
                                  class="form-control"
                                  [(ngModel)]="model.filterItems"
                                  (onRemoteSearch)="loadFilterItems($event.target.value)"
                                  #filterItemsInput="ngModel" name="filterItems" id="filterItems" required>
            </angular2-multiselect>
            <div class="form-control-feedback"
                 *ngIf="submitted && !filterItemsInput.valid">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</div>

          </div> <!-- card block end -->

        </div> <!-- card end -->

      </div>

      <button class="btn btn-primary floating-save-button" *ngIf="!componentEmbedded"
              (click)="create(false)">{{'COMMON_BUTTON_SAVE'|translate}}</button>
      <button class="btn btn-outline-primary floating-save-and-create-other-button" *ngIf="!componentEmbedded"
              (click)="create(true)">{{'LIST_ITEM_CREATE_SAVE_AND_CREATE_NEW'|translate}}</button>
    </div>
    <div class="modal-footer" *ngIf="componentEmbedded">
      <button type="button" class="btn btn-outline-primary" (click)="closeDialog(false)">
        {{'COMMON_BUTTON_CANCEL' | translate}}
      </button>
      <button type="button" class="btn btn-primary" (click)="create(false)">
        {{'COMMON_BUTTON_SAVE' | translate}}
      </button>
    </div>
  </form>

</div>
