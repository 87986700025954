/* eslint-disable */
import { TransportResource, TransportResourceService } from './transport-resource.service';
import { Injectable } from '@angular/core';
import { Observable, Observer } from 'rxjs';
import {
  FieldValidationError,
  Order,
  PagingRequest,
  QueryResult,
  ResourceQueryResult,
  Services
} from '../util/services';
import { ObservableErrorResourceParser } from '../util/errors';
import { List, Map, Set } from 'immutable';
import { OffsetDateTime } from '../util/dates';
import { Address, AddressResource } from '../address';
import { Chat, ChatResource } from '../chat/chat.utils';
import { ChatService } from '../../shared/chat/chat-service.interface';
import { TransportLog } from './transport-log/transport-log.service';
import { TransportTask } from './transport-task/transport-task.service';
import { HttpClient } from '@angular/common/http';
import * as moment from 'moment';
import { Duration } from 'moment';
import { EmptyMessage, IdentityMessage } from '../util/messages';
import { TriggerInstance } from '../trigger/trigger-instance.service';
import { TriggerInstanceResource } from '../trigger/trigger-instance-resource.service';
import { TriggerUtils } from '../trigger/trigger-utils';
import { ExteriorTransport } from '../exterior-transport/exterior-transport.service';
import { DqlStoredQuery, DqlStoredQueryService } from '../dql/dql-stored-query.service';
import { DqlModel } from '../dql/dql.model';
import { DqlResourceModel } from '../dql/dql-resource.model';
import { BadgeStyle } from '../../shared/table-badge/badge-style';
import TriggerInstanceService = TriggerInstance.TriggerInstanceService;

/* eslint-enable */

@Injectable()
export class TransportService
  implements Transport.Service, ChatService, TriggerInstanceService, DqlStoredQuery.HolderService {

  private readonly _dqlStoredQueryService: DqlStoredQueryService;

  constructor(private resourceService: TransportResourceService,
              private http: HttpClient) {
    this._dqlStoredQueryService = new DqlStoredQueryService(resourceService.dqlStoredResourceService);
  }

  get dqlStoredQueryService(): DqlStoredQueryService {
    return this._dqlStoredQueryService;
  }

  query(request: Transport.QueryRequest): Observable<QueryResult<Transport.Transport>> {
    return Observable.create((observer: Observer<QueryResult<Transport.Transport>>) => {
      const resourceRequest: TransportResource.QueryRequest = {
        id: Services.createIdParameter(request.id),
        external_id: request.externalId,
        waybill_number: request.waybillNumber,
        vehicle_id: Services.createIdParameter(request.vehicleIds),
        driver_id: Services.createIdParameter(request.driverIds),
        safety_shipping: request.safetyShipping,
        state: Services.createListParameter(request.state),
        close_time_from: Services.offsetDateTimeToString(request.closeTimeFrom),
        close_time_to: Services.offsetDateTimeToString(request.closeTimeTo),
        creation_time_from: Services.offsetDateTimeToString(request.creationTimeFrom),
        creation_time_to: Services.offsetDateTimeToString(request.creationTimeTo),
        start_time_from: Services.offsetDateTimeToString(request.startTimeFrom),
        start_time_to: Services.offsetDateTimeToString(request.startTimeTo),
        open_time_from: Services.offsetDateTimeToString(request.openTimeFrom),
        open_time_to: Services.offsetDateTimeToString(request.openTimeTo),
        shipping_item: request.shippingItem,
        with_message_count: request.withMessageCount,
        with_shipping_demands: request.withShippingDemands,
        with_shipments: request.withShipments,
        q: request.queryText,
        dql: request.dqlText,
        order: Services.createOrderFieldParameter(Keys.toOrderFieldKey, request.orders),
        page_number: request.paging ? request.paging.pageNumber : undefined,
        number_of_items: request.paging ? request.paging.numberOfItems : undefined,
        no_progress_bar: request.noProgressBar
      };
      return this.resourceService.query(resourceRequest).subscribe(
        (result: ResourceQueryResult<TransportResource.Transport>) => {
          observer.next({
            items: this.toPublicList(result.items),
            pagingResult: result.pagingResult
          });
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  get(request: Transport.GetRequest): Observable<Transport.Transport> {
    return Observable.create((observer: Observer<Transport.Transport>) => {
      const resourceRequest: TransportResource.GetRequest = {
        id: request.id,
        with_shipping_demand_count: request.withShippingDemandCount
      };
      return this.resourceService.get(resourceRequest).subscribe(
        (result: TransportResource.Transport) => {
          observer.next(this.toPublic(result));
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  delete(request: Transport.GetRequest): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      const resourceRequest: TransportResource.GetRequest = {
        id: request.id
      };
      return this.resourceService.delete(resourceRequest).subscribe(
        (result: EmptyMessage) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  createByDemands(request: Transport.CreateByDemandsRequest): Observable<Transport.CreateResponse> {
    return Observable.create((observer: Observer<Transport.CreateResponse>) => {
      const resourceRequest: TransportResource.CreateByDemandsRequest = {
        demand_ids: request.demandIds.toArray(),
        vehicle_id: request.vehicleId,
        driver_id: request.driverId,
        safety_shipping: request.safetyShipping,
        comment: request.comment
      };
      return this.resourceService.createByDemands(resourceRequest).subscribe(
        (result: TransportResource.CreateResponse) => {
          observer.next({
            transportId: result.transport_id
          });
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  createByShipments(request: Transport.CreateByShipmentsRequest): Observable<Transport.CreateResponse> {
    return Observable.create((observer: Observer<Transport.CreateResponse>) => {
      const resourceRequest: TransportResource.CreateByShipmentsRequest = {
        shipment_ids: request.shipmentIds.toArray(),
        vehicle_id: request.vehicleId,
        driver_id: request.driverId,
        safety_shipping: request.safetyShipping,
        comment: request.comment
      };
      return this.resourceService.createByShipments(resourceRequest).subscribe(
        (result: TransportResource.CreateResponse) => {
          observer.next({
            transportId: result.transport_id
          });
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  createEmpty(request: Transport.CreateEmptyRequest): Observable<Transport.CreateResponse> {
    return Observable.create((observer: Observer<Transport.CreateResponse>) => {
      const resourceRequest: TransportResource.CreateEmptyRequest = {
        transporter_company_id: request.transporterCompanyId,
        vehicle_id: request.vehicleId,
        driver_id: request.driverId,
        safety_shipping: request.safetyShipping
      };
      return this.resourceService.createEmpty(resourceRequest).subscribe(
        (result: TransportResource.CreateResponse) => {
          observer.next({
            transportId: result.transport_id
          });
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  getRoute(request: Transport.GetRequest): Observable<Transport.Directions> {
    return Observable.create((observer: Observer<Transport.Directions>) => {
      const resourceRequest: TransportResource.GetRequest = {
        id: request.id
      };
      return this.resourceService.getRoute({id: request.id}).subscribe(
        (result: TransportResource.Directions) => {
          observer.next({
            polylines: result.polylines,
            sumDistanceMeter: result.sum_distance_meter
          });
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        })
    });
  }

  update(request: Transport.UpdateRequest): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      const resourceRequest: TransportResource.UpdateRequest = {
        transport_id: request.transportId,
        transporter_company_id: request.transporterCompanyId,
        external_id: request.externalId,
        vehicle_id: request.vehicleId,
        driver_id: request.driverId,
        safety_shipping: request.safetyShipping,
        comment: request.comment
      };
      return this.resourceService.update(resourceRequest).subscribe(
        (result: EmptyMessage) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  addDemands(request: Transport.AddDemandsRequest): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      const resourceRequest: TransportResource.AddDemandsRequest = {
        id: request.id,
        demand_ids: request.demandIds.toArray(),
      };
      return this.resourceService.addDemands(resourceRequest).subscribe(
        (result: TransportResource.CreateResponse) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  addShipments(request: Transport.AddShipmentsRequest): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      const resourceRequest: TransportResource.AddShipmentsRequest = {
        id: request.id,
        shipment_ids: request.shipmentIds.toArray(),
      };
      return this.resourceService.addShipments(resourceRequest).subscribe(
        (result: TransportResource.CreateResponse) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  removeShipment(request: Transport.RemoveShipmentRequest): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      const resourceRequest: TransportResource.RemoveShipmentRequest = {
        transport_id: request.transportId,
        shipment_id: request.shipmentId,
      };
      return this.resourceService.removeShipment(resourceRequest).subscribe(
        (result: EmptyMessage) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  open(request: Transport.GetRequest): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      const resourceRequest: TransportResource.GetRequest = {
        id: request.id
      };
      return this.resourceService.open(resourceRequest).subscribe(
        (result) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  plan(request: Transport.GetRequest): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      const resourceRequest: TransportResource.GetRequest = {
        id: request.id
      };
      return this.resourceService.plan(resourceRequest).subscribe(
        (result) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  replan(request: Transport.GetRequest): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      const resourceRequest: TransportResource.GetRequest = {
        id: request.id
      };
      return this.resourceService.replan(resourceRequest).subscribe(
        (result) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  continue(request: Transport.GetRequest): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      const resourceRequest: TransportResource.GetRequest = {
        id: request.id
      };
      return this.resourceService.continue(resourceRequest).subscribe(
        (result) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  close(request: Transport.GetRequest): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      const resourceRequest: TransportResource.GetRequest = {
        id: request.id
      };
      return this.resourceService.close(resourceRequest).subscribe(
        (result) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  getPositionLogs(request: Transport.GetRequest): Observable<Transport.PositionLog[]> {
    return Observable.create((observer: Observer<Transport.PositionLog[]>) => {
      const resourceRequest: TransportResource.GetRequest = {
        id: request.id
      };
      return this.resourceService.getPositionLogs(resourceRequest).subscribe(
        (result: TransportResource.PositionLog[]) => {
          observer.next(result.map(pl => {
            return this.toPublicPositionLog(pl);
          }));
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  setSecurityGuard(request: Transport.SecurityGuardRequest): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      const resourceRequest: TransportResource.SecurityGuardRequest = {
        id: request.id,
        person_name: request.personName
      };
      return this.resourceService.setSecurityGuard(resourceRequest).subscribe(
        (result: EmptyMessage) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  setDriver(request: Transport.DriverRequest): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      const resourceRequest: TransportResource.DriverRequest = {
        id: request.id,
        driver_id: request.driverId
      };
      return this.resourceService.setDriver(resourceRequest).subscribe(
        (result: EmptyMessage) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  setVehicle(request: Transport.VehicleRequest): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      const resourceRequest: TransportResource.VehicleRequest = {
        id: request.id,
        vehicle_id: request.vehicleId
      };
      return this.resourceService.setVehicle(resourceRequest).subscribe(
        (result: EmptyMessage) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  getDqlModel(): Observable<DqlModel.QueryableModel> {
    return Observable.create((observer: Observer<DqlModel.QueryableModel>) => {
      return this.resourceService.getDqlModel().subscribe((result: DqlResourceModel.QueryableModel) => {
          observer.next(new DqlModel.DqlQueryableModelConverter(false).toPublic(result));
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        }
      );
    });
  }

  private translateError(error: any): any {
    const res = ObservableErrorResourceParser.parseError(error);
    const fieldErrors = ObservableErrorResourceParser.extractFieldErrors(res);
    const fieldErrorMap = ObservableErrorResourceParser.toFieldErrorMap(Keys.toValidatedField, fieldErrors);
    if (!fieldErrorMap.isEmpty()) {
      return FieldValidationError.of(fieldErrorMap);
    }
    return error;
  }

  private toPublicList(resourceList: TransportResource.Transport[]): List<Transport.Transport> {
    return List.of(...resourceList.map((r) => this.toPublic(r)));
  }

  private toPublic(r: TransportResource.Transport): Transport.Transport {
    return {
      id: r.id,
      creationTime: Services.toOffsetDateTime(r.creation_time),
      updateTime: Services.toOffsetDateTime(r.update_time),
      externalId: r.external_id,
      state: <Transport.TransportState>r.state,
      transporterCompany: r.transporter_company,
      waybillNumber: r.waybill_number,
      comment: r.comment,
      dispatcher: r.dispatcher,
      vehicle: r.vehicle ? {
        id: r.vehicle.id,
        licencePlate: r.vehicle.licence_plate
      } : undefined,
      driver: r.driver ? {
        id: r.driver.id,
        personName: r.driver.person_name
      } : undefined,
      openTime: Services.toOffsetDateTime(r.open_time),
      startTime: Services.toOffsetDateTime(r.start_time),
      closeTime: Services.toOffsetDateTime(r.close_time),
      safetyShipping: r.safety_shipping,
      safetyShippingUserSettable: r.safety_shipping_user_settable,
      securityGuard: this.toPublicSecurityGuard(r.security_guard),
      delayMinutes: r.delay_minutes,
      shippingDemandCount: r.shipping_demand_count,
      chatMessageCount: r.chat_message_count,
      attachmentCount: r.attachment_count,
      shipments: this.toPublicShipmentList(r.shipments),
      shippingDemands: this.toPublicShippingDemandList(r.shipping_demands),
    };
  }

  private toPublicSecurityGuard(r?: TransportResource.SecurityGuard): Transport.SecurityGuard | undefined {
    if (r) {
      return {
        personName: r.person_name
      };
    }
    return undefined;
  }

  private toResourceSecurityGuard(r?: Transport.SecurityGuard): TransportResource.SecurityGuard | undefined {
    if (r) {
      return {
        person_name: r.personName
      };
    }
    return undefined;
  }

  private toPublicShippingDemandList(r?: TransportResource.ShippingDemand[]): Set<Transport.ShippingDemand> | undefined {
    if (r) {
      return Set.of(...r.map(demand => this.toPublicShippingDemand(demand)));
    }
    return undefined;
  }

  private toPublicShippingDemand(r: TransportResource.ShippingDemand): Transport.ShippingDemand {
    return {
      id: r.id,
      demandSerial: r.demand_serial
    };
  }

  private toPublicShipmentList(r?: TransportResource.Shipment[]): Set<Transport.Shipment> | undefined {
    if (r) {
      return Set.of(...r.map(shipment => this.toPublicShipment(shipment)));
    }
    return undefined;
  }

  private toPublicShipment(r: TransportResource.Shipment): Transport.Shipment {
    return {
      id: r.id,
      groupId: r.group_id,
      deliveryNoteNumber: r.delivery_note_number
    };
  }

  messageQuery(request: Chat.MessageQueryRequest): Observable<QueryResult<Chat.Message>> {
    return Observable.create((observer: Observer<QueryResult<Chat.Message>>) => {
      const resourceRequest: TransportResource.MessageQueryRequest = {
        transport_id: request.id,
        content: request.content,
        user_profile_id: request.userProfileId,
        creation_time_from: request.creationTimeFrom && request.creationTimeFrom.isValid()
          ? request.creationTimeFrom.toUtcIsoString() : undefined,
        creation_time_to: request.creationTimeTo && request.creationTimeTo.isValid()
          ? request.creationTimeTo.toUtcIsoString() : undefined,
        order: Services.createOrderFieldParameter(Chat.Keys.toOrderFieldKey, request.order),
        page_number: request.paging ? request.paging.pageNumber : undefined,
        number_of_items: request.paging ? request.paging.numberOfItems : undefined,
      };
      return this.resourceService.messageQuery(resourceRequest).subscribe(
        (result: ResourceQueryResult<ChatResource.Message>) => {
          observer.next({
            items: Chat.Mapper.toPublicMessageList(result.items),
            pagingResult: result.pagingResult
          });
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  sendMessage(request: Chat.SendMessageRequest): Observable<IdentityMessage> {
    return Observable.create((observer: Observer<IdentityMessage>) => {
      const resourceRequest: TransportResource.SendMessageRequest = {
        transport_id: request.id,
        content: request.content,
      };
      return this.resourceService.sendMessage(resourceRequest).subscribe(
        (result: IdentityMessage) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }
  getRunningPositionLogs(request: EmptyMessage): Observable<Transport.PositionLogExt[]> {
    return new Observable((observer: Observer<Transport.PositionLogExt[]>) => {
      const resourceRequest: EmptyMessage = {};
      return this.resourceService.getRunningPositionLogs(resourceRequest).subscribe(
        (result: TransportResource.PositionLogExt[]) => {
          observer.next(result.map(r => this.toPublicPositionLogExt(r)));
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }


  private toPublicPositionLogExt(r: TransportResource.PositionLogExt): Transport.PositionLogExt {
    return {
      positionLog: this.toPublicPositionLog(r.position_log),
      driver: {
        id: r.driver.id,
        personName: r.driver.person_name
      },
      transport: {
        id: r.transport.id,
        delayMinutes: moment.duration(r.transport.delay_minutes, 'minutes'),
        vehicleLicencePlate: r.transport.vehicle_licence_plate,
        waybillNumber: r.transport.waybill_number
      },
      toTask: r.to_task ? {
        index: r.to_task.index,
        shippingPlace: TransportTask.ToPublicMapper.toPublicShippingPlace(r.to_task.shipping_place)
      } : undefined,
      currentTask: r.current_task ? {
        index: r.current_task.index,
        shippingPlace: TransportTask.ToPublicMapper.toPublicShippingPlace(r.current_task.shipping_place)
      } : undefined,

    };
  }

  private toPublicPositionLog(pl) {
    return {
      coordinate: AddressResource.Mapper.toPublicCoordinate(pl.coordinate)!,
      creationTime: Services.toOffsetDateTime(pl.creation_time)
    };
  }

  getTriggerInstanceHistory(request: TriggerInstance.TriggerInstanceHistoryRequest):
    Observable<QueryResult<TriggerInstance.TriggerInstance>> {
    return Observable.create((observer: Observer<QueryResult<TriggerInstance.TriggerInstance>>) => {
      const resourceRequest: TriggerInstanceResource.TriggerInstanceHistoryRequest
        = TriggerUtils.toResourceTriggerInstanceHistoryRequest(request);
      return this.resourceService.getTriggerInstanceHistory(resourceRequest).subscribe(
        (result: ResourceQueryResult<TriggerInstanceResource.TriggerInstance>) => {
          observer.next({
            items: TriggerUtils.toPublicTriggerInstanceList(result.items),
            pagingResult: result.pagingResult
          });
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

}

export namespace Transport {

  import Company = ExteriorTransport.Company;

  export interface Service {

    // <editor-fold desc="CRUD">

    query(request: QueryRequest): Observable<QueryResult<Transport>>;

    get(request: GetRequest): Observable<Transport>;

    createByDemands(request: CreateByDemandsRequest): Observable<CreateResponse>;

    createByShipments(request: CreateByShipmentsRequest): Observable<CreateResponse>;

    // </editor-fold>

  }

  export interface Transport {
    id: number;
    creationTime: OffsetDateTime;
    updateTime: OffsetDateTime;
    externalId: string;
    transporterCompany: Company;
    state: TransportState;
    waybillNumber: string;
    comment?: string;
    dispatcher: Dispatcher;
    vehicle?: Vehicle;
    driver?: Driver;
    openTime?: OffsetDateTime;
    startTime?: OffsetDateTime;
    closeTime?: OffsetDateTime;
    safetyShipping: boolean;
    safetyShippingUserSettable: boolean;
    securityGuard?: SecurityGuard;
    delayMinutes: number;
    shippingDemandCount: number;
    chatMessageCount?: number;
    attachmentCount?: number;
    shippingDemands?: Set<ShippingDemand>;
    shipments?: Set<Shipment>;
  }

  export interface QueryRequest {
    id?: Set<number>;
    externalId?: string;
    waybillNumber?: string;
    vehicleIds?: Set<number>;
    driverIds?: Set<number>;
    safetyShipping?: boolean;
    state?: Set<TransportState>;
    closeTimeFrom?: OffsetDateTime;
    closeTimeTo?: OffsetDateTime;
    creationTimeFrom?: OffsetDateTime;
    creationTimeTo?: OffsetDateTime;
    startTimeFrom?: OffsetDateTime;
    startTimeTo?: OffsetDateTime;
    openTimeFrom?: OffsetDateTime;
    openTimeTo?: OffsetDateTime;
    withMessageCount?: boolean;
    withShippingDemands?: boolean;
    withShipments?: boolean;
    queryText?: string;
    shippingItem?: string;
    orders?: Set<Order<OrderField>>;
    paging?: PagingRequest;
    dqlText?: string;
    noProgressBar?: boolean;
  }

  export interface GetRequest {
    id: number;
    withShippingDemandCount?: boolean;
  }

  export interface CreateRequest {
    vehicleId?: number;
    driverId?: number;
    comment?: string;
    safetyShipping: boolean;
  }

  export interface CreateByDemandsRequest extends CreateRequest {
    demandIds: Set<number>;
  }

  export interface CreateByShipmentsRequest extends CreateRequest {
    shipmentIds: Set<number>;
  }

  export interface CreateEmptyRequest {
    transporterCompanyId: number;
    vehicleId?: number;
    driverId?: number;
    safetyShipping: boolean;
  }

  export interface UpdateRequest {
    transportId: number;
    transporterCompanyId: number;
    externalId: string;
    vehicleId?: number;
    driverId?: number;
    safetyShipping: boolean;
    comment?: string;
  }

  export interface CreateResponse {
    transportId: number;
  }

  export interface Dispatcher {
    id: number;
  }

  export interface Vehicle {
    id: number;
    licencePlate: string;
  }

  export interface Driver {
    id: number;
    personName: string;
  }

  export interface SecurityGuard {
    personName: string;
  }

  export interface ShippingDemand {
    id: number;
    demandSerial: string;
  }

  export interface Shipment {
    id: number;
    groupId: number;
    deliveryNoteNumber: string;
  }

  export interface SecurityGuardRequest extends SecurityGuard, IdentityMessage {
  }

  export interface DriverRequest extends IdentityMessage {
    driverId: number;
  }

  export interface VehicleRequest extends IdentityMessage {
    vehicleId: number;
  }

  export interface AddDemandsRequest extends IdentityMessage {
    demandIds: Set<number>;
  }

  export interface AddShipmentsRequest extends IdentityMessage {
    shipmentIds: Set<number>;
  }

  export interface RemoveShipmentRequest {
    transportId: number;
    shipmentId: number;
  }

  export interface PositionLog {
    coordinate: Address.Coordinate;
    creationTime: OffsetDateTime;
  }

  export interface Directions {
    polylines: string[];
    sumDistanceMeter: number;
  }

  export interface PositionLogExt {
    positionLog: PositionLog;
    driver: Driver;
    transport: TransportData;
    toTask?: TransportLog.TransportTaskData;
    currentTask?: TransportLog.TransportTaskData;
  }

  export interface TransportData {
    id: number;
    waybillNumber: string;
    vehicleLicencePlate: string;
    delayMinutes: Duration;
  }

  export enum OrderField {
    ID,
    EXTERNAL_ID,
    WAYBILL_NUMBER,
    VEHICLE_LICENCE_PLATE,
    DRIVER_PERSON_NAME,
    CREATION_TIME,
    START_TIME,
    CLOSE_TIME,
    OPEN_TIME
  }

  export enum ValidatedField {
    UNKNOWN,

    EXTERNAL_ID,
    DISPATCHER,
    VEHICLE,
    DRIVER,
    TRANSPORTER_COMPANY
  }

  export type TransportState = 'UNDER_PLANNING' | 'OPEN' | 'RUNNING' | 'UNDER_REPLANNING' | 'FINISHED' | 'CLOSED';

  export class TransportStateObject {
    state: TransportState;
    stringKey: string;
    iconClass: string;
    badgeStyle: string;
  }

  export const transportStates: TransportStateObject[] = [
    {
      state: 'UNDER_PLANNING',
      stringKey: 'TRANSPORT_STATE_UNDER_PLANNING',
      iconClass: 'icomoon-new-state-in-progress',
      badgeStyle: BadgeStyle.PRIMARY
    },
    {
      state: 'OPEN',
      stringKey: 'TRANSPORT_STATE_OPEN',
      iconClass: 'icomoon-open',
      badgeStyle: BadgeStyle.SECONDARY
    },
    {
      state: 'RUNNING',
      stringKey: 'TRANSPORT_STATE_RUNNING',
      iconClass: 'icomoon-transport-delay',
      badgeStyle: BadgeStyle.SECONDARY
    },
    {
      state: 'UNDER_REPLANNING',
      stringKey: 'TRANSPORT_STATE_UNDER_REPLANNING',
      iconClass: 'icomoon-state-unfinished',
      badgeStyle: BadgeStyle.PRIMARY
    },
    {
      state: 'FINISHED',
      stringKey: 'TRANSPORT_STATE_FINISHED',
      iconClass: 'icomoon-transport-state-done',
      badgeStyle: BadgeStyle.SUCCESS
    },
    {
      state: 'CLOSED',
      stringKey: 'TRANSPORT_STATE_CLOSED',
      iconClass: 'icomoon-new-state-archived',
      badgeStyle: BadgeStyle.SUCCESS
    },
  ];

}

class Keys {

  private static readonly ID = 'id';
  private static readonly EXTERNAL_ID = 'external_id';
  private static readonly WAYBILL_NUMBER = 'waybill_number';
  private static readonly VEHICLE_LICENCE_PLATE = 'vehicle_licence_plate';
  private static readonly DRIVER_PERSON_NAME = 'driver_person_name';
  private static readonly CREATION_TIME = 'creation_time';
  private static readonly START_TIME = 'start_time';
  private static readonly CLOSE_TIME = 'close_time';
  private static readonly OPEN_TIME = 'open_time';

  private static readonly DISPATCHER = 'dispatcher';
  private static readonly VEHICLE = 'vehicle';
  private static readonly DRIVER = 'driver';
  private static readonly TRANSPORTER_COMPANY = 'transporter_company';


  private static readonly orderFieldKeyMap: Map<Transport.OrderField, string> = Map.of(
    Transport.OrderField.ID, Keys.ID,
    Transport.OrderField.EXTERNAL_ID, Keys.EXTERNAL_ID,
    Transport.OrderField.WAYBILL_NUMBER, Keys.WAYBILL_NUMBER,
    Transport.OrderField.VEHICLE_LICENCE_PLATE, Keys.VEHICLE_LICENCE_PLATE,
    Transport.OrderField.DRIVER_PERSON_NAME, Keys.DRIVER_PERSON_NAME,
    Transport.OrderField.CREATION_TIME, Keys.CREATION_TIME,
    Transport.OrderField.START_TIME, Keys.START_TIME,
    Transport.OrderField.CLOSE_TIME, Keys.CLOSE_TIME,
    Transport.OrderField.OPEN_TIME, Keys.OPEN_TIME,
  );

  private static readonly keyValidatedFieldMap: Map<string, Transport.ValidatedField> = Map.of(
    Transport.ValidatedField.EXTERNAL_ID, Keys.EXTERNAL_ID,
    Transport.ValidatedField.DISPATCHER, Keys.DISPATCHER,
    Transport.ValidatedField.VEHICLE, Keys.VEHICLE,
    Transport.ValidatedField.DRIVER, Keys.DRIVER,
    Transport.ValidatedField.TRANSPORTER_COMPANY, Keys.TRANSPORTER_COMPANY
  );

  public static toOrderFieldKey(field: Transport.OrderField): string {
    return Keys.orderFieldKeyMap.get(field)!;
  }

  public static toValidatedField(fieldKey: string): Transport.ValidatedField {
    return Keys.keyValidatedFieldMap.get(fieldKey, Transport.ValidatedField.UNKNOWN);
  }

}
