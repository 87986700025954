/* eslint-disable */
import {
  ComponentFactoryResolver,
  ComponentRef,
  Directive,
  ElementRef,
  EventEmitter,
  forwardRef, HostListener,
  Inject,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  Output,
  Renderer2,
  SimpleChanges,
  ViewContainerRef,
} from '@angular/core';
import { AbstractControl, ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator, } from '@angular/forms';
import { DOCUMENT } from '@angular/common';
import { Subject } from 'rxjs';
import { NgbTimepicker } from '@ng-bootstrap/ng-bootstrap';
import { NgbTimeParserFormatter } from './time-parser-formatter';
import { NgbTimeAdapter } from './time-adapter';
import { NgbTime } from './ngb-time';
import { AppNgbTimeStruct } from '../../util/ngb-datepicker';
/* eslint-enable */

const NGB_TIMEPICKER_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => NgbInputTimepickerDirective),
  multi: true,
};

const NGB_TIMEPICKER_VALIDATOR = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => NgbInputTimepickerDirective),
  multi: true,
};

/**
 * A directive that makes it possible to have timepickers on input fields.
 * Manages integration with the input field itself (data entry) and ngModel (validation etc.).
 */

/* eslint-disable */
@Directive({
  selector: 'input[ngbTimepicker]',
  exportAs: 'ngbTimepicker',
  host: {
    '(input)': 'manualTimeChange($event.target.value, false)',
    '(change)': 'manualTimeChange($event.target.value, true)',
    '(blur)': 'onBlur()',
    '[disabled]': 'disabled',
  },
  providers: [NGB_TIMEPICKER_VALUE_ACCESSOR, NGB_TIMEPICKER_VALIDATOR],
})
/* eslint-enable */
export class NgbInputTimepickerDirective implements OnChanges,
  OnDestroy, ControlValueAccessor, Validator {

  private static readonly DEFAULT_STRUCT: AppNgbTimeStruct = {
    hour: 0,
    minute: 0,
    second: 0,
    millis: 0
  };

  private _closed$ = new Subject();
  private _cRef: ComponentRef<NgbTimepicker> | null = null;
  private _disabled = false;
  private _model: NgbTime | null = null;
  private _zoneSubscription: any;

  /**
   * If false, the empty text will be replaced by zero time value.
   */
  @Input() allowEmptyValue: boolean = false;

  /**
   * Whether to display the spinners above and below the inputs.
   */
  @Input() spinners: boolean = true;

  /**
   * Whether to display seconds input.
   */
  @Input() seconds: boolean = true;

  /**
   * Number of hours to increase or decrease when using a button.
   */
  @Input() hourStep: number = 1;

  /**
   * Number of minutes to increase or decrease when using a button.
   */
  @Input() minuteStep: number = 10;

  /**
   * Number of seconds to increase or decrease when using a button.
   */
  @Input() secondStep: number = 10;

  /**
   * To make timepicker readonly
   */
  @Input() readonlyInputs: boolean = false;

  /**
   * To set the size of the inputs and button
   */
  @Input() size: 'small' | 'medium' | 'large' = 'small';

  /**
   * Placement of a timepicker popup accepts:
   *    "top", "top-left", "top-right", "bottom", "bottom-left", "bottom-right",
   *    "left", "left-top", "left-bottom", "right", "right-top", "right-bottom"
   * and array of above values.
   */
  @Input() placement: string = 'bottom-left';

  /**
   * A selector specifying the element the timepicker popup should be appended to.
   * Currently only supports "body".
   */
  @Input() container?: string;

  /**
   * An event fired when user selects a time using keyboard or mouse.
   * The payload of the event is currently selected NgbTime.
   *
   * @since 1.1.1
   */
  @Output() timeSelect = new EventEmitter<NgbTime | null>();

  @Input()
  get disabled() {
    return this._disabled;
  }

  @Input()
  toggleButtonId: string;

  @HostListener('document:click', ['$event'])
  clickedOutside($event) {
    let element = $event.target;
    // check whether the toggle button container was clicked
    if (element.id === this.toggleButtonId) {
      return;
    }
    // check whether the icon inside the toggle button container was clicked
    if (element.parentElement && element.parentElement.id === this.toggleButtonId) {
      return;
    }
    // check whether an element inside the popup was clicked
    while (element) {
      if (element.classList.length > 0
        && ((<string>element.classList[0]).startsWith('ngb-tp'))) {
        return;
      }
      element = element.parentElement;
    }
    if (this.isOpen()) {
      this.close();
    }
  }

  set disabled(value: any) {
    this._disabled = value === '' || (value && value !== 'false');

    if (this.isOpen()) {
      this._cRef!.instance.setDisabledState(this._disabled);
    }
  }

  private _onChange = (_: any) => {
  };
  private _onTouched = () => {
  };


  constructor(
    private _parserFormatter: NgbTimeParserFormatter, private _elRef: ElementRef,
    private _vcRef: ViewContainerRef, private _renderer: Renderer2, private _cfr: ComponentFactoryResolver,
    private _ngZone: NgZone, private _timeAdapter: NgbTimeAdapter<any>, @Inject(DOCUMENT) private _document: any) {
    this._zoneSubscription = _ngZone.onStable.subscribe(() => {
      if (this._cRef) {
        // TODO ANGULAR-6
        // positionElements(
        //   this._elRef.nativeElement,
        //   this._cRef.location.nativeElement,
        //   this.placement,
        //   this.container === 'body');
      }
    });
  }

  registerOnChange(fn: (value: any) => any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: () => any): void {
    this._onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(value) {
    this._model = this._fromTimeStruct(this._timeAdapter.fromModel(value));
    this._writeModelValue(this._model);
  }

  manualTimeChange(value: string, updateView: boolean) {
    this._model = this._fromTimeStruct(this._parserFormatter.parse(value, this.seconds));
    this._onChange(this._model ? this._timeAdapter.toModel(this._model) : (value === '' ? null : value));
    if (updateView && this._model) {
      this._writeModelValue(this._model);
    }
  }

  isOpen() {
    return !!this._cRef;
  }

  /**
   * Opens the timepicker with the selected time indicated by the ngModel value.
   */
  open() {
    if (!this.isOpen()) {
      const cf = this._cfr.resolveComponentFactory(NgbTimepicker);
      this._cRef = this._vcRef.createComponent(cf);

      this._applyPopupStyling(this._cRef.location.nativeElement);
      this._applyTimepickerInputs(this._cRef.instance);
      this._subscribeForTimepickerOutputs(this._cRef.instance);
      if (this._model) {
        this._cRef.instance.writeValue(this._timeAdapter.toModel(this._model));
      }
      else {
        // NOTE: Time picker does not support null well (f.e. throws an error when clicking on a button),
        // so we defaults to zero value.
        this._cRef.instance.writeValue(this._timeAdapter.toModel(NgbInputTimepickerDirective.DEFAULT_STRUCT));
      }

      // time selection event handling
      this._cRef.instance.registerOnChange((selectedTime) => {
        this.writeValue(selectedTime);
        this._onChange(selectedTime);
      });

      this._cRef.changeDetectorRef.detectChanges();

      this._cRef.instance.setDisabledState(this.disabled);

      if (this.container === 'body') {
        window.document.querySelector(this.container)!.appendChild(this._cRef.location.nativeElement);
      }
    }
  }

  /**
   * Closes the timepicker popup.
   */
  close() {
    if (this.isOpen()) {
      this._vcRef.remove(this._vcRef.indexOf(this._cRef!.hostView));
      this._cRef = null;
      this._closed$.next();
    }
  }

  /**
   * Toggles the timepicker popup (opens when closed and closes when opened).
   */
  toggle() {
    if (this.isOpen()) {
      this.close();
    }
    else {
      this.open();
    }
  }

  onBlur() {
    this._onTouched();
  }

  ngOnChanges(changes: SimpleChanges) {
  }

  ngOnDestroy() {
    this.close();
    this._zoneSubscription.unsubscribe();
  }

  private _applyTimepickerInputs(timepickerInstance: NgbTimepicker): void {
    ['meridian', 'spinners', 'seconds', 'hourStep', 'minuteStep', 'secondStep', 'readonlyInputs', 'size']
      .forEach((optionName: string) => {
        if (this[optionName] !== undefined) {
          timepickerInstance[optionName] = this[optionName];
        }
      });
  }

  private _applyPopupStyling(nativeElement: any) {
    this._renderer.addClass(nativeElement, 'dropdown-menu');
    this._renderer.setStyle(nativeElement, 'padding', '0.25rem');
    this._renderer.addClass(nativeElement, 'd-inline-block');
  }

  private _subscribeForTimepickerOutputs(timepickerInstance: NgbTimepicker) {
    timepickerInstance.onChange = () => {
      this.timeSelect.emit(this._model);
    };
  }

  private _writeModelValue(model: NgbTime | null) {
    this._renderer.setProperty(this._elRef.nativeElement, 'value', this._parserFormatter.format(model, this.seconds));
    if (this.isOpen() && model) {
      this._cRef!.instance.writeValue(this._timeAdapter.toModel(model));
      this._onTouched();
    }
  }

  private _fromTimeStruct(time: AppNgbTimeStruct | null): NgbTime | null {
    if (!time) {
      if (this.allowEmptyValue) {
        return null;
      }
      // Empty value is not enabled so we defaults to zero.
      // Sample usage: DateTime value, so time exists if date exists.
      time = NgbInputTimepickerDirective.DEFAULT_STRUCT;
    }
    const ngbTime = new NgbTime(time.hour, time.minute, time.second, time.millis);
    if (ngbTime.isValid(true)) {
      return ngbTime;
    }
    time = NgbInputTimepickerDirective.DEFAULT_STRUCT;
    return new NgbTime(time.hour, time.minute, time.second, time.millis);
  }

  registerOnValidatorChange(fn: () => void): void {
    // The directive has no validation arguments so implementation is not required.
  }

  validate(c: AbstractControl): ValidationErrors | null {
    const value = c.value;
    if (value === null || value === undefined) {
      return null;
    }
    const ngbTime: NgbTime | null = this._fromTimeStruct(this._timeAdapter.fromModel(value));
    if (ngbTime === null) {
      return null;
    }
    if (ngbTime.isValid(true)) {
      return null;
    }
    return {
      'ngbTime': {
        invalid: value
      }
    };
  }

}
