import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { StockRecordFacade, StockRecordLot } from '../../../lib/stock/stock-record.service';
import { UiConstants } from '../../../util/core-utils';
import { StockSubStockRecordService } from '../../../lib/stock/stock-sub-stock-record.service';
import { StockRecordResourceEditRequestModel } from '../../../util/stock/stock-utils';
import { ObservableErrorResourceParser } from '../../../lib/util/errors';
import { StockItemUpdateFieldErrorMap } from '../../../lib/stock/stock.service';
import { InputMask } from '../../../util/input-masks';
import { Models } from '../../../util/model-utils';

@Component({
  selector: 'app-stock-record-lot-edit-dialog',
  templateUrl: './stock-record-lot-edit-dialog.component.html',
  styleUrls: ['./stock-record-lot-edit-dialog.component.scss']
})
export class StockRecordLotEditDialogComponent implements OnInit {

  UiConstants = UiConstants;
  InputMask = InputMask;
  editStockRecordModel: StockRecordResourceEditRequestModel = new StockRecordResourceEditRequestModel();
  stockItemUpdateFieldErrors: StockItemUpdateFieldErrorMap;

  constructor(public dialogRef: MatDialogRef<StockRecordLotEditDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: StockRecordLotEditDialogData,
              private stockRecordService: StockSubStockRecordService
  ) {
    this.editStockRecordModel.amount = Models.numberToString(data.stockRecordLot.amount);
    this.editStockRecordModel.intakePrice = Models.numberToString(data.stockRecordLot.intake_unit_net_price);
    this.editStockRecordModel.reservedAmount = data.stockRecord.stock_item.reserved_amount;
    this.editStockRecordModel.unit = data.stockRecord.stock_item.unit;
    this.stockItemUpdateFieldErrors = {};
  }

  ngOnInit(): void {
  }

  closeDialog(modified: boolean) {
    this.dialogRef.close({modified: modified});
  }

  updateStockItem() {
    const req = {
      id: this.data.stockRecord.stock_record_id,
      stockId: this.data.stockRecord.stock.stock_id,
      lotId: this.data.stockRecordLot.id,
      amount: Models.parseNumber(this.editStockRecordModel.amount),
      intake_unit_net_price: Models.parseNumber(this.editStockRecordModel.intakePrice)
    };
    this.stockRecordService.update(req).subscribe(result => {
      this.closeDialog(true);
    }, (error: any) => {
      const res = ObservableErrorResourceParser.parseError(error);
      this.stockItemUpdateFieldErrors = ObservableErrorResourceParser.extractFieldErrors(res);
    });
  }

  resetStockItemUpdateFieldErrors() {
    this.stockItemUpdateFieldErrors = {};
  }

}

export interface StockRecordLotEditDialogData {
  stockRecord: StockRecordFacade;
  stockRecordLot: StockRecordLot;
}
