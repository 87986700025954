/* eslint-disable */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { InvoiceRecordCreateModel } from '../invoice-create/invoice-create-clone.model';
import { InvoiceRecordEditModel } from './invoice-record-edit.model';
import { InputMask } from '../../../../util/input-masks';
import { VatRate } from '../../../../lib/vat-rate.service';
import { MultiselectOptionItem, OptionItem } from '../../../../util/core-utils';
import { StockItemMultiselectOptionItem, StockItemMultiselectProvider } from '../../../../lib/stock/stock-item-multiselect.provider';
import { Angular2Multiselects } from '../../../../util/multiselect';
import { LedgerNumberMultiselectProvider } from '../../../../lib/ledger/number/ledger-number-multiselect.provider';
import { RightModel } from '../../../../app.rights';

/* eslint-enable */

@Component({
  selector: 'app-invoice-record-edit',
  templateUrl: './invoice-record-edit.component.html',
  styleUrls: ['./invoice-record-edit.component.scss']
})
export class InvoiceRecordEditComponent implements OnInit {

  InputMask = InputMask;
  VatRate = VatRate;

  m: InvoiceRecordEditModel;

  @Input()
  isRemovable: boolean = true;

  @Input()
  selectableVatRates: VatRate.VatRate[] = [];

  @Input()
  rightModel: RightModel = RightModel.empty();

  @Input()
  selectableZeroVatRateTypes: OptionItem<VatRate.ZeroVatRateType>[] = [];

  @Input()
  zeroPricedRecordsAllowed: boolean = true;
  // TODO use this

  stockDropdownSettings: Angular2Multiselects.Settings;
  stockItems: StockItemMultiselectOptionItem[] = [];
  ledgerNumbers: MultiselectOptionItem<number>[] = [];

  @Input()
  set model(model: InvoiceRecordCreateModel) {

    this.m = new InvoiceRecordEditModel();
    this.m.recordName = model.recordName;
    this.m.hunVtszNumber = model.hunVtszNumber;
    this.m.amount = model.amount;
    this.m.unitType = model.unitType;
    this.m.vatRate = model.vatRate;
    this.m.netUnitPrice = model.netUnitPrice;
    this.m.zeroVatRateType = model.zeroVatRateType;
    this.m.comment = model.comment;
    this.m._stockItem = model._stockItem;
    this.m._ledgerNumber = [];
    model._ledgerNumber.forEach(l => {
      this.m._ledgerNumber.push(l);
    });
  }

  get model(): InvoiceRecordCreateModel {
    const model = new InvoiceRecordCreateModel();
    model.recordName = this.m.recordName;
    model.hunVtszNumber = this.m.hunVtszNumber;
    model.amount = this.m.amount;
    model.unitType = this.m.unitType;
    model.vatRate = this.m.vatRate;
    model.netUnitPrice = this.m.netUnitPrice;
    model.zeroVatRateType = this.m.zeroVatRateType;
    model.comment = this.m.comment;
    model.editing = true;
    model._stockItem = this.m._stockItem;
    model._ledgerNumber = this.m._ledgerNumber;
    return model;
  }

  @Output()
  remove: EventEmitter<undefined> = new EventEmitter();

  @Output()
  save: EventEmitter<InvoiceRecordCreateModel> = new EventEmitter<InvoiceRecordCreateModel>();

  @Output()
  cancel: EventEmitter<undefined> = new EventEmitter();

  constructor(private stockItemMultiselectProvider: StockItemMultiselectProvider,
              private ledgerNumberMultiselectProvider: LedgerNumberMultiselectProvider) {
    this.initDropdownSettings();
  }

  ngOnInit() {
    this.loadStockItems();
  }

  saveRecord() {
    this.save.emit(this.model);
  }

  initDropdownSettings() {
    this.stockDropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(true)
      .enableSearchFilter(true)
      .enableCheckAll(false)
      .remoteSearch(true)
      .build();
  }

  loadStockItems(predicate?: string) {
    this.stockItemMultiselectProvider.loadActive(predicate).subscribe(items => {
      this.stockItems = items;
    });
  }

  loadLedgerNumbers(predicate?: string) {
    if (this.rightModel.ledgerNumberRead.hasRight()) {
      this.ledgerNumberMultiselectProvider.loadActive(predicate).subscribe(items => {
        this.ledgerNumbers = items;
      });
    }
  }

  stockItemSelected(stockItem: StockItemMultiselectOptionItem) {
    this.m.loadStockItem(stockItem);
  }
}
