<div class="breadcrumb-container" [ngClass]="{ 'breadcrumb-container-compact': compactSidebar }" id="breadcrumb">
  <div class="breadcrumb-component">
    <app-breadcrumb
      [parents]="breadcrumbParents"
      [self]="breadcrumbSelf">
    </app-breadcrumb>
  </div>
  <div class="breadcrumb-menu d-flex">
    <div class="breadcrumb-button-icon-container cursor-pointer"
         (click)="openImportDialog()">
      <a class="btn-setting d-flex align-items-center" title="{{'COMMON_BUTTON_IMPORT' | translate}}">
        <i class="icomoon icomoon-csv-import"></i>
        <span class="breadcrumb-button-text-visible">{{'COMMON_BUTTON_IMPORT' | translate}}</span>
      </a>
    </div>
    <div class="breadcrumb-button-icon-container cursor-pointer negative-margin-right"
         (click)="exportTaskJson()">
      <a class="btn-setting d-flex align-items-center" title="{{'COMMON_BUTTON_EXPORT' | translate}}">
        <i class="icomoon icomoon-csv-export"></i>
        <span class="breadcrumb-button-text-visible">{{'COMMON_BUTTON_EXPORT' | translate}}</span>
      </a>
    </div>
  </div>
</div>
<div class="breadcrumb-placeholder"></div>
<div class="container-horizontal-padding-half container-vertical-padding-half animated fadeIn">

  <form class="form-horizontal bordered-row" (ngSubmit)="update()" #f="ngForm" novalidate>

    <div class="row ml-0 mr-0">

      <div class="col-xs-12 col-sm-12 col-md-6 card-custom-padding">

        <div class="container-vertical-padding-half">
          <div class="card">

            <div class="card-header">
              {{'FORM_EDIT_TITLE'|translate}}
            </div>

            <div class="card-body">
              <div class="form-group row"
                   [ngClass]="{ 'has-danger': hasFieldError(Task.ValidatedField.NAME) || hasLocalFieldError(name) }">
                <label class="col-form-label form-control-label col-md-4 detail-title required-field-label">
                  {{'FORM_CREATE_NAME_TITLE' | translate}}
                </label>
                <div class="col-md-8">
                  <input type="text" class="form-control" placeholder="{{'FORM_CREATE_NAME_TITLE' | translate}}"
                         maxlength="{{UiConstants.maximumVarcharLength}}"
                         [ngClass]="{ 'form-control-danger': hasFieldError(Task.ValidatedField.NAME) || hasLocalFieldError(name) }"
                         [(ngModel)]="model.name" (ngModelChange)="removeFieldError(Task.ValidatedField.NAME)"
                         name="name"
                         id="name" #name="ngModel" required>
                  <div class="form-control-feedback" *ngIf="hasFieldError(Task.ValidatedField.NAME)">
                    {{getFieldErrorText(Task.ValidatedField.NAME)}}
                  </div>
                  <div class="form-control-feedback" *ngIf="(f.submitted && !name.valid) || hasLocalFieldError(name)">
                    {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
                  </div>
                </div>
              </div>

              <div class="form-group row"
                   [ngClass]="{ 'has-danger': hasFieldError(Task.ValidatedField.EXTERNAL_ID) || hasLocalFieldError(external_id) }">
                <label class="col-form-label form-control-label col-md-4 detail-title required-field-label">
                  {{'COMMON_EXTERNAL_ID' | translate}}
                </label>
                <div class="col-md-8">
                  <input type="text" class="form-control" placeholder="{{'COMMON_EXTERNAL_ID' | translate}}"
                         maxlength="{{UiConstants.maximumVarcharLength}}"
                         [ngClass]="{ 'form-control-danger': hasFieldError(Task.ValidatedField.EXTERNAL_ID) || hasLocalFieldError(external_id) }"
                         [(ngModel)]="model.externalId"
                         (ngModelChange)="removeFieldError(Task.ValidatedField.EXTERNAL_ID)" name="external_id"
                         id="external_id" #external_id="ngModel" required>
                  <div class="form-control-feedback" *ngIf="hasFieldError(Task.ValidatedField.EXTERNAL_ID)">
                    {{getFieldErrorText(Task.ValidatedField.EXTERNAL_ID)}}
                  </div>
                  <div class="form-control-feedback" *ngIf="hasLocalFieldError(external_id)">
                    {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
                  </div>
                </div>
              </div>

              <div class="form-group row"
                   [ngClass]="{ 'has-danger': hasLocalFieldError(externalIdTagInput) || hasFieldError(Task.ValidatedField.TEMPLATE) }">
                <label class="col-form-label form-control-label col-md-4 detail-title">{{'COMMON_EXTERNAL_ID_TEMPLATE' |
                  translate}}</label>
                <div class="col-md-8">
                  <app-material-tag-input
                    name="externalIdTagInput"
                    id="externalIdTagInput"
                    #externalIdTagInput="ngModel"
                    [(ngModel)]="model.taskRecordExternalIdSequence"
                    (modelChange)="removeFieldError(Task.ValidatedField.TEMPLATE)"
                    [placeholderStringKey]="'COMMON_EXTERNAL_ID_TEMPLATE'"
                    [autocompleteItems]="TaskRecordExternalIdSequence.availableSequenceBlocks"
                    [separatorCharacter]="TaskRecordExternalIdSequence.sequenceBlockSeparator"
                    [generatedStringExampleMap]="TaskRecordExternalIdSequence.sequenceBlockExampleMap"
                    [separatorKeyCodes]="TaskRecordExternalIdSequence.separatorKeysCodes"
                    [shouldGenerateExample]="true"
                    [form]="f">
                  </app-material-tag-input>
                  <div class="form-control-feedback" *ngIf="hasFieldError(Task.ValidatedField.TEMPLATE)">
                    {{getFieldErrorText(Task.ValidatedField.TEMPLATE)}}
                  </div>
                </div>
              </div>

              <div class="form-group row">
                <label
                  class="col-form-label form-control-label col-md-4 detail-title">{{'TASK_EXTERNAL_ID_TEMPLATE_WITHIN_YEAR'
                  | translate}}
                  <i class="icomoon icomoon-info info-icon"
                     popover="{{'TASK_INFO_EXTERNAL_ID_TEMPLATE_WITHIN_YEAR' | translate}}"
                     triggers="mouseenter:mouseleave" container="body" placement="right"></i>
                </label>
                <div class="col-md-8">
                  <label class="switch switch-text switch-info mb-0">
                    <input type="checkbox" class="switch-input"
                           [ngModelOptions]="{standalone: true}"
                           [(ngModel)]="model.withinYear">
                    <span class="switch-label" data-on="&#xe957;" data-off="&#xe98e;"></span>
                    <span class="switch-handle"></span>
                  </label>
                </div>
              </div>

              <div class="form-group row"
                   [ngClass]="{ 'has-danger': hasLocalFieldError(taskRecordNameTemplate) || hasFieldError(Task.ValidatedField.TASK_RECORD_NAME_TEMPLATE) }">
                <label class="col-form-label form-control-label col-md-4 detail-title">{{'TASK_RECORD_CREATE_NAME_TEMPLATE' |
                  translate}}</label>
                <div class="col-md-8">
                  <app-material-tag-input
                    name="taskRecordNameTemplate"
                    id="taskRecordNameTemplate"
                    #taskRecordNameTemplate="ngModel"
                    [(ngModel)]="model.taskRecordNameTemplate"
                    (modelChange)="removeFieldError(Task.ValidatedField.TASK_RECORD_NAME_TEMPLATE)"
                    [(ngModel)]="model.taskRecordNameTemplate"
                    [placeholderStringKey]="'TASK_RECORD_CREATE_NAME_TEMPLATE'"
                    [autocompleteItems]="TaskRecordQuickCreateNameTemplate.availableTemplateBlocks"
                    [separatorCharacter]="TaskRecordQuickCreateNameTemplate.templateBlockSeparator"
                    [generatedStringExampleMap]="TaskRecordQuickCreateNameTemplate.templateBlockExampleMap"
                    [separatorKeyCodes]="TaskRecordQuickCreateNameTemplate.separatorKeysCodes"
                    [shouldGenerateExample]="true"
                    [form]="f">
                  </app-material-tag-input>
                  <div class="form-control-feedback" *ngIf="hasFieldError(Task.ValidatedField.TASK_RECORD_NAME_TEMPLATE)">
                    {{getFieldErrorText(Task.ValidatedField.TASK_RECORD_NAME_TEMPLATE)}}
                  </div>
                </div>
              </div>

              <div class="form-group row">
                <label class="col-md-4 col-form-label form-control-label inverted-label-align required-field-label">
                  {{'USER_LABEL_COLOR'|translate}}
                </label>
                <div class="col-md-8">
                  <div class="mw-100">
                    <palette-color-picker
                      [(color)]="model.color">
                    </palette-color-picker>
                  </div>
                </div>
              </div>

              <div class="form-group row">
                <label
                  class="col-form-label form-control-label col-md-4 detail-title">{{'TASK_USE_DEFAULT_MANAGED_FIELDS' | translate}}
                </label>
                <div class="col-md-8">
                  <label class="switch switch-text switch-info mb-0">
                    <input type="checkbox" class="switch-input"
                           [ngModelOptions]="{standalone: true}"
                           [(ngModel)]="model.useDefaultManagedFields">
                    <span class="switch-label" data-on="&#xe957;" data-off="&#xe98e;"></span>
                    <span class="switch-handle"></span>
                  </label>
                </div>
              </div>

              <div class="form-group row" *ngIf="!model.useDefaultManagedFields">
                <label
                  class="col-form-label form-control-label col-md-4 detail-title">{{'TASK_MANAGED_FIELDS' | translate}}
                </label>
                <div class="col-md-8">
                  <angular2-multiselect [data]="managableFields" [settings]="managedFieldsDropdownSettings"
                                        class="form-control"
                                        [(ngModel)]="model.managedFields"
                                        [ngModelOptions]="{standalone: true}"
                                        (ngModelChange)="onManagedFieldsChanged()">
                  </angular2-multiselect>
                </div>
              </div>

              <div class="form-group row">
                <label
                  class="col-form-label form-control-label col-md-4 detail-title">{{'TASK_REQUIRED_FIELDS' | translate}}
                </label>
                <div class="col-md-8">
                  <angular2-multiselect [data]="requirableFields" [settings]="managedFieldsDropdownSettings"
                                        class="form-control"
                                        [(ngModel)]="model.requiredFields"
                                        [ngModelOptions]="{standalone: true}">
                  </angular2-multiselect>
                </div>
              </div>

              <!--TASK POC REQUIREMENT-->
              <div class="form-group row"
                   [ngClass]="{ 'has-danger': hasLocalFieldError(pocRequirement) }">
                <label
                  class="col-form-label form-control-label col-md-4 detail-title required-field-label">{{'FORM_CREATE_POC_REQUIREMENT' |
                  translate}}
                </label>
                <div class="col-md-8">
                  <angular2-multiselect [data]="pocRequirements" [settings]="pocRequirementDropdownSettings"
                                        class="form-control"
                                        name="pocRequirement"
                                        id="pocRequirement"
                                        #pocRequirement="ngModel"
                                        [(ngModel)]="model._pocRequirement"
                                        required>
                  </angular2-multiselect>
                  <div class="form-control-feedback" *ngIf="hasLocalFieldError(pocRequirement)">
                    {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
                  </div>
                </div>
              </div>

              <div class="form-group row">
                <label class="col-form-label form-control-label col-md-4 detail-title">{{'COMMON_DESCRIPTION'
                  |
                  translate}}</label>
                <div class="col-md-8">
                  <input type="text" class="form-control" placeholder="{{'COMMON_DESCRIPTION' | translate}}"
                         maxlength="{{UiConstants.maximumVarcharLength}}"
                         [(ngModel)]="model.description" name="description" id="description" #description="ngModel">
                </div>
              </div>

              <div class="form-group row" [ngClass]="{ 'has-danger': hasLocalFieldError(validationType) }">
                <label class="col-form-label form-control-label col-md-4 detail-title required-field-label">{{'TASK_VALIDATION_TYPE' |
                  translate}}</label>
                  <div class="col-md-8">
                    <angular2-multiselect [data]="validationTypeItems" [settings]="defaultStateDropdownSettings"
                                          class="form-control"
                                          required
                                          id="validationType" name="validationType" #validationType="ngModel"
                                          [ngClass]="{ 'form-control-danger': hasLocalFieldError(validationType) }"
                                          [(ngModel)]="model.validationType"
                                          [ngModelOptions]="{standalone: true}">
                    </angular2-multiselect>
                    <div class="form-control-feedback" *ngIf="hasLocalFieldError(validationType)">
                      {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
                    </div>

                  </div>
              </div>

              <app-icon-selector [(selectedIcon)]="model.icon"></app-icon-selector>

              <!--TASK DEFAULT STATE FORCE NEW-->
              <div class="form-group row">
                <label
                  class="col-form-label form-control-label col-md-4 detail-title">{{'FORM_CREATE_DEFAULT_STATE_FORCE_NEW' | translate}}
                  <i class="icomoon icomoon-info info-icon"
                     popover="{{'TASK_INFO_DEFAULT_STATE_FORCE_NEW' | translate}}"
                     triggers="mouseenter:mouseleave" container="body" placement="right"></i>
                </label>
                <div class="col-md-8">
                  <label class="switch switch-text switch-info mb-0">
                    <input type="checkbox" class="switch-input"
                           [(ngModel)]="model.defaultStateForceNew"
                           (click)="defaultStateForceNewChanged()"
                           name="defaultStateForceNew">
                    <span class="switch-label" data-on="&#xe957;" data-off="&#xe98e;"></span>
                    <span class="switch-handle"></span>
                  </label>
                </div>
              </div>

              <!--TASK DEFAULT STATE-->
              <div class="form-group row"
                   [ngClass]="{ 'has-danger': hasLocalFieldError(defaultState) }">
                <label
                  class="col-form-label form-control-label col-md-4 detail-title required-field-label">{{'FORM_CREATE_DEFAULT_STATE' |
                  translate}}
                  <i class="icomoon icomoon-info info-icon" popover="{{'TASK_INFO_DEFAULT_STATE' | translate}}"
                     triggers="mouseenter:mouseleave" container="body" placement="right"></i>
                </label>
                <div class="col-md-8">
                  <angular2-multiselect [data]="defaultStateOptions" [settings]="defaultStateDropdownSettings"
                                        class="form-control"
                                        [ngClass]="{ 'form-control-danger': hasLocalFieldError(defaultState) }"
                                        name="defaultState"
                                        id="defaultState"
                                        #defaultState="ngModel"
                                        [(ngModel)]="model.defaultState"
                                        [readonly]="model.defaultStateForceNew"
                                        required>
                  </angular2-multiselect>
                  <div class="form-control-feedback" *ngIf="hasLocalFieldError(defaultState)">
                    {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
                  </div>
                </div>
              </div>
              <!--TASK DEFAULT DEADLINE-->
              <div class="form-group row">
                <label
                  class="col-form-label form-control-label col-md-4 detail-title">{{'FORM_CREATE_DEFAULT_DEADLINE' | translate}}
                  <i class="icomoon icomoon-info info-icon" popover="{{'TASK_INFO_DEFAULT_DEADLINE' | translate}}"
                     triggers="mouseenter:mouseleave" container="body" placement="right"></i>
                </label>
                <div class="col-md-8">
                  <label class="switch switch-text switch-info mb-0">
                    <input type="checkbox" class="switch-input"
                           [(ngModel)]="model.hasDefaultDeadline"
                           (click)="hasDefaultDeadlineChanged()"
                           name="hasDefaultDeadline">
                    <span class="switch-label" data-on="&#xe957;" data-off="&#xe98e;"></span>
                    <span class="switch-handle"></span>
                  </label>
                </div>
              </div>

              <div *ngIf="model.hasDefaultDeadline">
                <div class="form-group row">
                  <label
                    class="col-form-label form-control-label col-md-4 detail-title">{{'TASK_DEFAULT_DEADLINE_TYPE' |
                    translate}}</label>
                  <div class="col-md-8">
                    <select class="form-control" [ngModel]="model.defaultDeadlineType"
                            (ngModelChange)="onDefaultDeadlineTypeChange($event)" name="defaultDeadlineType"
                            id="defaultDeadlineType">
                      <option *ngFor="let item of defaultDeadlineTypeItems" [ngValue]="item">{{item.text}}</option>
                    </select>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-4">
                    <label class="col-form-label form-control-label detail-title required-field-label">
                      {{'TASK_DEFAULT_DEADLINE_OFFSET' | translate}}
                    </label>
                  </div>
                  <div class="form-group edit-form-input-group col-md-8"
                       [ngClass]="{ 'has-danger': hasLocalFieldError(offset) }">
                    <input type="text" class="form-control"
                           [ngClass]="{ 'form-control-danger': hasLocalFieldError(offset) }"
                           [(ngModel)]="model.offset"
                           [textMask]="{mask: InputMask.NATURAL_FLOAT, guide: false}"
                           placeholder="{{'TASK_DEFAULT_DEADLINE_OFFSET' | translate}}"
                           name="offset" id="offset" #offset="ngModel" min="0" required>
                    <div class="form-control-feedback" *ngIf="hasLocalFieldError(offset)">
                      {{'COMMON_VALIDATION_MESSAGE_REQUIRED'
                      | translate}}
                    </div>
                  </div>
                </div>
              </div>
              <!--TASK DEFAULT DEADLINE END-->

              <div class="form-group row">
                <label
                  class="col-form-label form-control-label col-md-4 detail-title">{{'SETTINGS_TASK_RECORD_ADMIN_EDITABLE_STATES' |
                  translate}}
                  <i class="icomoon icomoon-info info-icon" popover="{{'TASK_INFO_ADMIN_EDITABLE_STATES' | translate}}"
                     triggers="mouseenter:mouseleave" container="body" placement="right"></i>
                </label>
                <div class="col-md-8">
                  <angular2-multiselect [data]="editableStateOptions" [settings]="editableStateDropdownSettings"
                                        class="form-control"
                                        name="adminEditableStates"
                                        id="adminEditableStates"
                                        #adminEditableStates="ngModel"
                                        [(ngModel)]="model.adminEditableStates">
                  </angular2-multiselect>
                </div>
              </div>

              <div class="form-group row">
                <label
                  class="col-form-label form-control-label col-md-4 detail-title">{{'FORM_CREATE_PARALLEL_WORK_REJECTED' |
                  translate}}
                  <i class="icomoon icomoon-info info-icon" popover="{{'TASK_INFO_PARALLEL_WORK_REJECTED' | translate}}"
                     triggers="mouseenter:mouseleave" container="body" placement="right"></i>
                </label>
                <div class="col-md-8">
                  <label class="switch switch-text switch-info mb-0">
                    <input type="checkbox" class="switch-input"
                           [ngModelOptions]="{standalone: true}"
                           [(ngModel)]="model.parallelWorkRejected">
                    <span class="switch-label" data-on="&#xe957;" data-off="&#xe98e;"></span>
                    <span class="switch-handle"></span>
                  </label>
                </div>
              </div>

              <div class="form-group row">
                <label class="col-form-label form-control-label col-md-4 detail-title">
                  {{'TASK_IN_PROGRESS_SIGNATURE_ENABLED' | translate}}
                </label>
                <div class="col-md-8">
                  <label class="switch switch-text switch-info mb-0">
                    <input type="checkbox" class="switch-input"
                           [ngModelOptions]="{standalone: true}"
                           [(ngModel)]="model.inProgressSignatureEnabled">
                    <span class="switch-label" data-on="&#xe957;" data-off="&#xe98e;"></span>
                    <span class="switch-handle"></span>
                  </label>
                </div>
              </div>

              <div class="form-group row">
                <label
                  class="col-form-label form-control-label col-md-4 detail-title required-field-label">{{'FORM_CREATE_CUSTOMER_SIGNATURE'
                  | translate}}
                  <i class="icomoon icomoon-info info-icon" popover="{{'TASK_INFO_CUSTOMER_SIGNATURE' | translate}}"
                     triggers="mouseenter:mouseleave" container="body" placement="right"></i>
                </label>
                <div class="col-md-8 d-flex align-items-center">
                  <div class="w-100">
                    <angular2-multiselect [data]="signatureValidationTypeItems"
                                          [settings]="signatureValidationDropdownSettings"
                                          class="form-control"
                                          [(ngModel)]="model._customerSignature"
                                          required
                                          name="customerSignature" id="customerSignature"
                                          #customerSignature="ngModel">
                    </angular2-multiselect>
                    <div class="form-control-feedback" *ngIf="hasLocalFieldError(customerSignature)">
                      <div class="form-control-feedback"
                           *ngIf="f.submitted && !customerSignature.valid && customerSignature.errors['required']">
                        {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
                      </div>
                      <div class="form-control-feedback"
                           *ngIf="f.submitted && !customerSignature.valid && customerSignature.errors['hasDisabledItem']">
                        {{'COMMON_VALIDATION_MESSAGE_DISABLED' | translate}}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-group row">
                <label
                  class="col-form-label form-control-label col-md-4 detail-title required-field-label">{{'FORM_CREATE_USER_SIGNATURE' |
                  translate}}
                  <i class="icomoon icomoon-info info-icon" popover="{{'TASK_INFO_USER_SIGNATURE' | translate}}"
                     triggers="mouseenter:mouseleave" container="body" placement="right"></i>
                </label>
                <div class="col-md-8 d-flex align-items-center">
                  <div class="w-100">
                    <angular2-multiselect [data]="signatureValidationTypeItems"
                                          [settings]="signatureValidationDropdownSettings"
                                          class="form-control"
                                          [(ngModel)]="model._userSignature"
                                          required
                                          name="userSignature" id="userSignature"
                                          #userSignature="ngModel">
                    </angular2-multiselect>
                    <div class="form-control-feedback" *ngIf="hasLocalFieldError(userSignature)">
                      <div class="form-control-feedback"
                           *ngIf="f.submitted && !userSignature.valid && userSignature.errors['required']">
                        {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
                      </div>
                      <div class="form-control-feedback"
                           *ngIf="f.submitted && !userSignature.valid && userSignature.errors['hasDisabledItem']">
                        {{'COMMON_VALIDATION_MESSAGE_DISABLED' | translate}}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-group row">
                <label class="col-form-label form-control-label col-md-4 detail-title">{{'FORM_CREATE_ATTACHMENT' |
                  translate}}
                  <i class="icomoon icomoon-info info-icon" popover="{{'TASK_INFO_ATTACHMENT' | translate}}"
                     triggers="mouseenter:mouseleave" container="body" placement="right"></i>
                </label>
                <div class="col-md-8">
                  <label class="switch switch-text switch-info mb-0">
                    <input type="checkbox" class="switch-input" [(ngModel)]="model.customerAttachmentEnabled"
                           #customerAttachmentEnabled="ngModel" id="customerAttachmentEnabled"
                           name="customerAttachmentEnabled">
                    <span class="switch-label" data-on="&#xe957;" data-off="&#xe98e;"></span>
                    <span class="switch-handle"></span>
                  </label>
                </div>
              </div>

              <div class="form-group row" [ngClass]="{ 'has-danger':  hasLocalFieldError(defaultLinkedSurveys) }">
                <label
                  class="col-form-label form-control-label col-md-4 detail-title">{{'FORM_CREATE_DEFAULT_LINKED_SURVEYS'
                  | translate}}
                  <i class="icomoon icomoon-info info-icon" popover="{{'TASK_INFO_DEFAULT_LINKED_SURVEYS' | translate}}"
                     triggers="mouseenter:mouseleave" container="body" placement="right"></i>
                </label>
                <div class="col-md-8">
                  <angular2-multiselect [data]="surveys" [settings]="searchDropdownSettings"
                                        class="form-control"
                                        [ngClass]="{ 'form-control-danger': hasLocalFieldError(defaultLinkedSurveys) }"
                                        [(ngModel)]="model.defaultLinkedSurveys"
                                        (onRemoteSearch)="searchSurveys($event)"
                                        name="defaultLinkedSurveys" id="defaultLinkedSurveys" validateEnabledItems
                                        #defaultLinkedSurveys="ngModel">
                  </angular2-multiselect>
                  <div class="form-control-feedback" *ngIf="hasLocalFieldError(defaultLinkedSurveys)">
                    <div class="form-control-feedback"
                         *ngIf="f.submitted && !defaultLinkedSurveys.valid && defaultLinkedSurveys.errors['required']">
                      {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
                    </div>
                    <div class="form-control-feedback"
                         *ngIf="f.submitted && !defaultLinkedSurveys.valid && defaultLinkedSurveys.errors['hasDisabledItem']">
                      {{'COMMON_VALIDATION_MESSAGE_DISABLED' | translate}}
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-group row" [ngClass]="{ 'has-danger':  hasLocalFieldError(creatorUserGroups) }">
                <label
                  class="col-form-label form-control-label col-md-4 detail-title">{{'FORM_CREATE_CREATOR_USER_GROUPS'
                  | translate}}
                  <i class="icomoon icomoon-info info-icon" popover="{{'TASK_INFO_CREATOR_USER_GROUPS' | translate}}"
                     triggers="mouseenter:mouseleave" container="body" placement="right" placement="right"></i>
                </label>
                <div class="col-md-8">
                  <angular2-multiselect [data]="userGroups" [settings]="searchDropdownSettings"
                                        class="form-control"
                                        [ngClass]="{ 'form-control-danger': hasLocalFieldError(creatorUserGroups) }"
                                        [(ngModel)]="model.creatorUserGroups"
                                        (onRemoteSearch)="searchUserGroups($event)"
                                        name="creatorUserGroups" id="creatorUserGroups" validateEnabledItems
                                        #creatorUserGroups="ngModel">
                  </angular2-multiselect>
                  <div class="form-control-feedback" *ngIf="hasLocalFieldError(creatorUserGroups)">
                    <div class="form-control-feedback"
                         *ngIf="f.submitted && !creatorUserGroups.valid && creatorUserGroups.errors['required']">
                      {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
                    </div>
                    <div class="form-control-feedback"
                         *ngIf="f.submitted && !creatorUserGroups.valid && creatorUserGroups.errors['hasDisabledItem']">
                      {{'COMMON_VALIDATION_MESSAGE_DISABLED' | translate}}
                    </div>
                  </div>

                </div>
              </div>

              <div class="form-group row" [ngClass]="{ 'has-danger':  hasLocalFieldError(enabledUserGroups) }">
                <label
                  class="col-form-label form-control-label col-md-4 detail-title">{{'FORM_CREATE_ENABLED_ASSIGNEE_USER_GROUPS'
                  | translate}}
                  <i class="icomoon icomoon-info info-icon"
                     popover="{{'TASK_INFO_ENABLED_ASSIGNEE_USER_GROUPS' | translate}}"
                     triggers="mouseenter:mouseleave" container="body" placement="right" placement="right"></i>
                </label>
                <div class="col-md-8">
                  <angular2-multiselect [data]="userGroups" [settings]="searchDropdownSettings"
                                        class="form-control"
                                        [ngClass]="{ 'form-control-danger': hasLocalFieldError(enabledUserGroups) }"
                                        [(ngModel)]="model.enabledUserGroups"
                                        (onRemoteSearch)="searchUserGroups($event)"
                                        name="enabledUserGroups" id="enabledUserGroups" validateEnabledItems
                                        #enabledUserGroups="ngModel">
                  </angular2-multiselect>
                  <div class="form-control-feedback" *ngIf="hasLocalFieldError(enabledUserGroups)">
                    <div class="form-control-feedback"
                         *ngIf="f.submitted && !enabledUserGroups.valid && enabledUserGroups.errors['required']">
                      {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
                    </div>
                    <div class="form-control-feedback"
                         *ngIf="f.submitted && !enabledUserGroups.valid && enabledUserGroups.errors['hasDisabledItem']">
                      {{'COMMON_VALIDATION_MESSAGE_DISABLED' | translate}}
                    </div>
                  </div>

                </div>
              </div>

              <!--TASK POC REQUIREMENT-->
              <div class="form-group row"
                   [ngClass]="{ 'has-danger': hasLocalFieldError(webCreateDefaultAssigneeType) }">
                <label
                  class="col-form-label form-control-label col-md-4 detail-title required-field-label">{{'FORM_CREATE_WEB_CREATE_DEFAULT_ASSGINEE' |
                  translate}}
                </label>
                <div class="col-md-8">
                  <angular2-multiselect [data]="defaultAssigneeTypes" [settings]="pocRequirementDropdownSettings"
                                        class="form-control"
                                        name="webCreateDefaultAssigneeType"
                                        id="webCreateDefaultAssigneeType"
                                        #webCreateDefaultAssigneeType="ngModel"
                                        [(ngModel)]="model._webCreateDefaultAssigneeType"
                                        required>
                  </angular2-multiselect>
                  <div class="form-control-feedback" *ngIf="hasLocalFieldError(webCreateDefaultAssigneeType)">
                    {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
                  </div>
                </div>
              </div>

              <div class="form-group row">
                <label
                  class="col-form-label form-control-label col-md-4 detail-title">{{'FORM_CREATE_SET_CUSTOMER_OWNER_USER_AFTER_FINISH'
                  | translate}}
                  <i class="icomoon icomoon-info info-icon"
                     popover="{{'TASK_INFO_SET_CUSTOMER_OWNER_USER_AFTER_FINISH' | translate}}"
                     triggers="mouseenter:mouseleave" container="body" placement="right"></i>
                </label>
                <div class="col-md-8">
                  <label class="switch switch-text switch-info mb-0">
                    <input type="checkbox" class="switch-input" [(ngModel)]="model.setCustomerOwnerUserAfterFinish"
                           #setCustomerOwnerUserAfterFinish="ngModel" id="setCustomerOwnerUserAfterFinish"
                           name="setCustomerOwnerUserAfterFinish">
                    <span class="switch-label" data-on="&#xe957;" data-off="&#xe98e;"></span>
                    <span class="switch-handle"></span>
                  </label>
                </div>
              </div>

              <div class="form-group row">
                <label
                  class="col-form-label form-control-label col-md-4 detail-title">{{'FORM_CREATE_SET_CUSTOMER_OWNER_USER_GROUP_AFTER_FINISH'
                  | translate}}
                  <i class="icomoon icomoon-info info-icon"
                     popover="{{'TASK_INFO_SET_CUSTOMER_OWNER_USER_GROUP_AFTER_FINISH' | translate}}"
                     triggers="mouseenter:mouseleave" container="body" placement="right"></i>
                </label>
                <div class="col-md-8">
                  <label class="switch switch-text switch-info mb-0">
                    <input type="checkbox" class="switch-input" [(ngModel)]="model.setCustomerOwnerUserGroupAfterFinish"
                           #setCustomerOwnerUserGroupAfterFinish="ngModel" id="setCustomerOwnerUserGroupAfterFinish"
                           name="setCustomerOwnerUserGroupAfterFinish">
                    <span class="switch-label" data-on="&#xe957;" data-off="&#xe98e;"></span>
                    <span class="switch-handle"></span>
                  </label>
                </div>
              </div>


            </div>
          </div>
        </div>
        <!-- /.container-vertical-padding-half -->

        <div class="container-vertical-padding-half">

          <div class="card">

            <div class="card-header">
              {{'TASK_SUBMITTED_FILTERS'|translate}}
            </div>

            <div class="card-body">

              <ng-container *ngFor="let submittedFilter of model.submittedFilters; let i = index">
                <div class="form-group row"
                     [ngClass]="{ 'has-danger': hasLocalFieldError(submittedFilterUserGroupInput) || hasLocalFieldError(submittedFilterDaysInput) }">
                  <div class="col-md-6">
                    <angular2-multiselect [data]="userGroups" [settings]="submittedFilterDropdownSettings"
                                          class="form-control"
                                          [(ngModel)]="submittedFilter.userGroup"
                                          (onRemoteSearch)="searchUserGroups($event)"
                                          validateEnabledItems required
                                          name="submittedFilterUserGroupInput{{i}}"
                                          id="submittedFilterUserGroupInput{{i}}"
                                          #submittedFilterUserGroupInput="ngModel">
                    </angular2-multiselect>
                    <div class="form-control-feedback" *ngIf="hasLocalFieldError(submittedFilterUserGroupInput)">
                      <div class="form-control-feedback"
                           *ngIf="f.submitted && !submittedFilterUserGroupInput.valid && submittedFilterUserGroupInput.errors['required']">
                        {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
                      </div>
                      <div class="form-control-feedback"
                           *ngIf="f.submitted && !submittedFilterUserGroupInput.valid && submittedFilterUserGroupInput.errors['hasDisabledItem']">
                        {{'COMMON_VALIDATION_MESSAGE_DISABLED' | translate}}
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 d-flex align-items-center">
                    <div>
                      <input type="text" class="form-control"
                             placeholder="{{'TASK_SUBMITTED_FILTER_DAYS' | translate}}"
                             [(ngModel)]="submittedFilter.days"
                             [textMask]="{mask: InputMask.NATURAL_INTEGER, guide: false}"
                             name="submittedFilterDaysInput{{i}}" id="submittedFilterDaysInput{{i}}"
                             #submittedFilterDaysInput="ngModel"
                             required>
                      <div class="form-control-feedback" *ngIf="hasLocalFieldError(submittedFilterDaysInput)">
                        <div class="form-control-feedback"
                             *ngIf="f.submitted && !submittedFilterDaysInput.valid && submittedFilterDaysInput.errors['required']">
                          {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
                        </div>
                      </div>
                    </div>
                    <div class="cursor-pointer ml-1 font-xl" (click)="deleteSubmittedFilter(i)"
                         title="{{'COMMON_BUTTON_DELETE' | translate}}">
                      <i class="icomoon icomoon-trash">
                      </i>
                    </div>
                  </div>
                </div>
              </ng-container>

              <div class="d-flex justify-content-end">
                <button type="button" (click)="addSubmittedFilter()" class="btn btn-primary">
                  {{'TASK_SUBMITTED_FILTER_ADD_NEW' | translate}}
                </button>
              </div>

            </div>

          </div>

        </div>

        <!--TASK SMS RATING-->
        <div class="container-vertical-padding-half"
             *ngIf="configService.getConfiguration().feature_flags.sms_futar_enabled">

          <div class="card">

            <div class="card-header">
              {{'TASK_SMS_RATING'|translate}}
            </div>

            <div class="card-body">

              <div class="form-group row">
                <label class="col-form-label form-control-label col-md-4 detail-title">
                  {{'TASK_SMS_RATING_ENABLED' | translate}}
                </label>
                <div class="col-md-8">
                  <label class="switch switch-text switch-info mb-0">
                    <input type="checkbox" class="switch-input"
                           [ngModelOptions]="{standalone: true}"
                           [(ngModel)]="model.smsRatingEnabled">
                    <span class="switch-label" data-on="&#xe957;" data-off="&#xe98e;"></span>
                    <span class="switch-handle"></span>
                  </label>
                </div>
              </div>

              <div *ngIf="model.smsRatingEnabled">
                <div class="form-group row">
                  <label class="col-form-label form-control-label col-md-4 detail-title">
                    {{'TASK_SMS_RATING_DELIVERY_SCHEDULE_MIN' | translate}}
                  </label>
                  <div class="form-group edit-form-input-group col-md-8"
                       [ngClass]="{ 'has-danger': f.submitted && !smsRatingDeliveryScheduleMin.valid }">
                    <input type="text" class="form-control"
                           [ngClass]="{ 'form-control-danger': f.submitted && !smsRatingDeliveryScheduleMin.valid }"
                           [(ngModel)]="model.smsRatingDeliveryScheduleMin"
                           [textMask]="{mask: InputMask.NATURAL_INTEGER, guide: false}"
                           placeholder="{{'TASK_SMS_RATING_DELIVERY_SCHEDULE_MIN' | translate}}"
                           id="smsRatingDeliveryScheduleMin" name="smsRatingDeliveryScheduleMin"
                           #smsRatingDeliveryScheduleMin="ngModel"
                           [required]="model.smsRatingEnabled">
                    <div class="form-control-feedback" *ngIf="f.submitted && !smsRatingDeliveryScheduleMin.valid">
                      {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
                    </div>
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-form-label form-control-label col-md-4 detail-title">
                    {{'TASK_SMS_RATING_TEMPLATE' | translate}}
                  </label>
                  <div class="col-md-8"
                       [ngClass]="{ 'has-danger': f.submitted && !smsRatingTemplate.valid }">
                    <angular2-multiselect [data]="messageTemplates"
                                          [settings]="invoiceDropdownSettings"
                                          class="form-control"
                                          [(ngModel)]="model.smsRatingTemplate"
                                          (onRemoteSearch)="loadMessageTemplates($event.target.value)"
                                          id="smsRatingTemplate" name="smsRatingTemplate" #smsRatingTemplate="ngModel"
                                          [required]="model.smsRatingEnabled" validateEnabledItems>
                    </angular2-multiselect>
                    <div class="form-control-feedback"
                         *ngIf="f.submitted && !smsRatingTemplate.valid && smsRatingTemplate.errors['required']">
                      {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
                    </div>
                    <div class="form-control-feedback"
                         *ngIf="f.submitted && !smsRatingTemplate.valid && smsRatingTemplate.errors['hasDisabledItem']">
                      {{'COMMON_VALIDATION_MESSAGE_DISABLED' | translate}}
                    </div>
                  </div>
                </div>
              </div>

            </div>

          </div>

        </div>
        <!--TASK SMS RATING END-->

        <div class="container-vertical-padding-half">

          <div class="card">

            <div class="card-header">
              {{'TASK_STATE_FILTERS'|translate}}
            </div>

            <div class="card-body">

              <ng-container *ngFor="let stateFilter of model.stateFilters; let i = index">
                <div class="form-group row"
                     [ngClass]="{ 'has-danger': hasLocalFieldError(stateFilterUserGroupInput) || hasLocalFieldError(stateFilterStatesInput) }">
                  <div class="col-md-6">
                    <angular2-multiselect [data]="userGroups" [settings]="submittedFilterDropdownSettings"
                                          class="form-control"
                                          [(ngModel)]="stateFilter.userGroup"
                                          (onRemoteSearch)="searchUserGroups($event)"
                                          validateEnabledItems required
                                          name="stateFilterUserGroupInput{{i}}" id="stateFilterUserGroupInput{{i}}"
                                          #stateFilterUserGroupInput="ngModel">
                    </angular2-multiselect>
                    <div class="form-control-feedback" *ngIf="hasLocalFieldError(stateFilterUserGroupInput)">
                      <div class="form-control-feedback"
                           *ngIf="f.submitted && !stateFilterUserGroupInput.valid && stateFilterUserGroupInput.errors['required']">
                        {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
                      </div>
                      <div class="form-control-feedback"
                           *ngIf="f.submitted && !stateFilterUserGroupInput.valid && stateFilterUserGroupInput.errors['hasDisabledItem']">
                        {{'COMMON_VALIDATION_MESSAGE_DISABLED' | translate}}
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 d-flex align-items-center">
                    <div class="w-100">
                      <angular2-multiselect [data]="states" [settings]="stateFilterDropdownSettings"
                                            class="form-control"
                                            [(ngModel)]="stateFilter.states"
                                            required
                                            name="stateFilterStatesInput{{i}}" id="stateFilterStatesInput{{i}}"
                                            #stateFilterStatesInput="ngModel">
                      </angular2-multiselect>
                      <div class="form-control-feedback" *ngIf="hasLocalFieldError(stateFilterStatesInput)">
                        <div class="form-control-feedback"
                             *ngIf="f.submitted && !stateFilterStatesInput.valid && stateFilterStatesInput.errors['required']">
                          {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
                        </div>
                      </div>
                    </div>
                    <div class="cursor-pointer ml-1 font-xl" (click)="deleteStateFilter(i)"
                         title="{{'COMMON_BUTTON_DELETE' | translate}}">
                      <i class="icomoon icomoon-trash">
                      </i>
                    </div>
                  </div>
                </div>
              </ng-container>

              <div class="d-flex justify-content-end">
                <button type="button" (click)="addStateFilter()" class="btn btn-primary">
                  {{'TASK_SUBMITTED_FILTER_ADD_NEW' | translate}}
                </button>
              </div>

            </div>

          </div>

        </div>

        <div class="container-vertical-padding-half">
          <app-usergroup-type-based-role-card
            [type]="UserGroupRoleType.TASK"
            [typeId]="taskId"
            [readonly]="false"
            [rightModel]="rightModel">
          </app-usergroup-type-based-role-card>
        </div>

      </div>

      <div class="col-xs-12 col-sm-12 col-md-6 card-custom-padding">

        <div class="container-vertical-padding-half"
             *ngIf="configService.getConfiguration().feature_flags.helpdesk_enabled">

          <div class="card">

            <div class="card-header">
              {{'COMMON_HELPDESK'|translate}}
            </div>

            <div class="card-body">

              <div class="form-group row">
                <label
                  class="col-form-label form-control-label col-md-4 detail-title">{{'FORM_CREATE_HELPDESK_ENABLED'
                  | translate}}
                  <i class="icomoon icomoon-info info-icon" popover="{{'TASK_INFO_HELPDESK' | translate}}"
                     triggers="mouseenter:mouseleave" container="body" placement="right"></i>
                </label>
                <div class="col-md-8">
                  <label class="switch switch-text switch-info mb-0">
                    <input type="checkbox" class="switch-input" [(ngModel)]="model.helpdeskEnabled"
                           #helpdeskEnabled="ngModel" id="helpdeskEnabled" name="helpdeskEnabled">
                    <span class="switch-label" data-on="&#xe957;" data-off="&#xe98e;"></span>
                    <span class="switch-handle"></span>
                  </label>
                </div>
              </div>

              <div class="form-group row" *ngIf="model.helpdeskEnabled">
                <label
                  class="col-form-label form-control-label col-md-4 detail-title">{{'TASK_HELPDESK_MANAGED_FIELDS' | translate}}
                </label>
                <div class="col-md-8">
                  <angular2-multiselect [data]="managableFields" [settings]="managedFieldsDropdownSettings"
                                        class="form-control"
                                        [(ngModel)]="model.helpdeskManagedFields"
                                        [ngModelOptions]="{standalone: true}"
                                        (ngModelChange)="onHelpdeskManagedFieldsChanged()">
                  </angular2-multiselect>
                </div>
              </div>

              <div class="form-group row" *ngIf="model.helpdeskEnabled">
                <label
                  class="col-form-label form-control-label col-md-4 detail-title">{{'TASK_HELPDESK_REQUIRED_FIELDS' | translate}}
                </label>
                <div class="col-md-8">
                  <angular2-multiselect [data]="helpdeskRequirableFields" [settings]="managedFieldsDropdownSettings"
                                        class="form-control"
                                        [(ngModel)]="model.helpdeskRequiredFields"
                                        [ngModelOptions]="{standalone: true}">
                  </angular2-multiselect>
                </div>
              </div>

            </div>

          </div>

        </div>

        <div class="container-vertical-padding-half">

          <div class="card">

            <div class="card-header">
              {{'FORM_CREATE_TIMETRACKING_ENABLED'|translate}}
            </div>

            <div class="card-body">

              <div class="form-group row"
                   [ngClass]="{ 'has-danger':  hasLocalFieldError(defaultEstimatedTimeInMinutes) }">
                <label
                  class="col-form-label form-control-label col-md-4 detail-title"
                  [class.required-field-label]="model.taskTimeTrackingType !== TaskTimeTrackingType.NONE">{{'FORM_CREATE_DEFAULT_ESTIMATED_TIME'
                  | translate}}
                </label>
                <div class="col-md-8">
                  <input type="text" class="form-control"
                         [(ngModel)]="model.defaultEstimatedTimeInMinutes" #defaultEstimatedTimeInMinutes="ngModel"
                         [ngClass]="{ 'form-control-danger': hasLocalFieldError(defaultEstimatedTimeInMinutes) }"
                         [textMask]="{mask: InputMask.NATURAL_FLOAT, guide: false}"
                         placeholder="{{'FORM_CREATE_DEFAULT_ESTIMATED_TIME' | translate}}"
                         (ngModelChange)="removeFieldError(Task.ValidatedField.UNKNOWN)"
                         name="defaultEstimatedTimeInMinutes" id="defaultEstimatedTimeInMinutes"
                         [required]="model.taskTimeTrackingType !== TaskTimeTrackingType.NONE">
                  <div class="form-control-feedback" *ngIf="hasLocalFieldError(defaultEstimatedTimeInMinutes)">
                    {{'COMMON_VALIDATION_MESSAGE_REQUIRED'
                    | translate}}
                  </div>
                </div>
              </div>

              <div class="form-group row">
                <label
                  class="col-form-label form-control-label col-md-4 detail-title">{{'FORM_CREATE_TIMETRACKING_ENABLED'
                  | translate}}
                  <i class="icomoon icomoon-info info-icon" popover="{{'TASK_INFO_TIMETRACKING' | translate}}"
                     triggers="mouseenter:mouseleave" container="body" placement="right"></i>
                </label>
                <div class="col-md-8">
                  <mat-button-toggle-group
                    [(ngModel)]="model.taskTimeTrackingType"
                    (ngModelChange)="timeTrackingEnabledChanged()"
                    [ngModelOptions]="{standalone: true}">
                    <mat-button-toggle *ngFor="let type of taskTimeTrackingTypes" [value]="type">
                      {{'FORM_CREATE_TIME_TRACKING_TYPE_' + type.toString() | translate}}
                    </mat-button-toggle>
                  </mat-button-toggle-group>
                </div>
              </div>

              <div class="form-group row" *ngIf="model.taskTimeTrackingType === TaskTimeTrackingType.AUTOMATIC">
                <label
                  class="col-form-label form-control-label col-md-4 detail-title">{{'FORM_CREATE_COLLABORATOR_TIMETRACKING_ENABLED'
                  | translate}}
                  <i class="icomoon icomoon-info info-icon"
                     popover="{{'TASK_INFO_COLLABORATOR_TIMETRACKING' | translate}}"
                     triggers="mouseenter:mouseleave" container="body" placement="right"></i>
                </label>
                <div class="col-md-8">
                  <label class="switch switch-text switch-info mb-0">
                    <input type="checkbox" class="switch-input"
                           [ngModelOptions]="{standalone: true}"
                           [(ngModel)]="model.collaboratorTimeTrackingEnabled">
                    <span class="switch-label" data-on="&#xe957;" data-off="&#xe98e;"></span>
                    <span class="switch-handle"></span>
                  </label>
                </div>
              </div>

              <div class="form-group row" *ngIf="model.taskTimeTrackingType === TaskTimeTrackingType.MANUAL">
                <label
                  class="col-form-label form-control-label col-md-4 detail-title">{{'FORM_CREATE_MANUAL_TIMETRACKING_REQUIRED'
                  | translate}}
                </label>
                <div class="col-md-8">
                  <label class="switch switch-text switch-info mb-0">
                    <input type="checkbox" class="switch-input"
                           [ngModelOptions]="{standalone: true}"
                           [(ngModel)]="model.manualTimeTrackingRequired">
                    <span class="switch-label" data-on="&#xe957;" data-off="&#xe98e;"></span>
                    <span class="switch-handle"></span>
                  </label>
                </div>
              </div>

              <div class="form-group row" *ngIf="model.taskTimeTrackingType === TaskTimeTrackingType.MANUAL">
                <label
                  class="col-form-label form-control-label col-md-4 detail-title">{{'FORM_CREATE_MANUAL_TIMETRACKING_TYPE'
                  | translate}}
                  <i class="icomoon icomoon-info info-icon" popover="{{'TASK_INFO_MANUAL_TIMETRACKING_TYPE' | translate}}"
                     triggers="mouseenter:mouseleave" container="body" placement="right"></i>
                </label>
                <div class="col-md-8">
                  <mat-button-toggle-group
                    [(ngModel)]="model.manualTimeTrackingType"
                    [ngModelOptions]="{standalone: true}">
                    <mat-button-toggle *ngFor="let type of manualTimeTrackingTypes" [value]="type">
                      {{'FORM_CREATE_MANUAL_TIME_TRACKING_TYPE_' + type.toString() | translate}}
                    </mat-button-toggle>
                  </mat-button-toggle-group>
                </div>
              </div>

            </div>

          </div>

        </div>

        <div class="container-vertical-padding-half">

          <div class="card">

            <div class="card-header">
              {{'TASK_GEOFENCING'|translate}}
            </div>

            <div class="card-body">

              <div class="form-group row">
                <label
                  class="col-form-label form-control-label col-md-4 detail-title">{{'TASK_GEOFENCING'
                  | translate}}
                  <i class="icomoon icomoon-info info-icon" popover="{{'TASK_GEOFENCING_HINT' | translate}}"
                     triggers="mouseenter:mouseleave" container="body" placement="right"></i>
                </label>
                <div class="col-md-8">
                  <label class="switch switch-text switch-info mb-0">
                    <input type="checkbox" class="switch-input"
                           [ngModelOptions]="{standalone: true}"
                           [(ngModel)]="model.geofencingEnabled"
                           (click)="geofencingEnabledChanged()">
                    <span class="switch-label" data-on="&#xe957;" data-off="&#xe98e;"></span>
                    <span class="switch-handle"></span>
                  </label>
                </div>
              </div>

              <div class="form-group row"
                   [ngClass]="{ 'has-danger':  hasLocalFieldError(geofencingRadiusInMeters) || hasFieldError(Task.ValidatedField.GEOFENCING_RADIUS_IN_METERS)}"
                   *ngIf="model.geofencingEnabled">
                <label
                  class="col-form-label form-control-label col-md-4 detail-title">{{'TASK_GEOFENCING_RADIUS'
                  | translate}}
                </label>
                <div class="col-md-8">
                  <input type="text" class="form-control"
                         [(ngModel)]="model.geofencingRadiusInMeters" #geofencingRadiusInMeters="ngModel"
                         [ngClass]="{ 'form-control-danger': hasLocalFieldError(geofencingRadiusInMeters) || hasFieldError(Task.ValidatedField.GEOFENCING_RADIUS_IN_METERS)}"
                         [textMask]="{mask: InputMask.NATURAL_FLOAT, guide: false}"
                         placeholder="{{'TASK_GEOFENCING_RADIUS' | translate}} *"
                         (ngModelChange)="removeFieldError(Task.ValidatedField.GEOFENCING_RADIUS_IN_METERS)"
                         name="geofencingRadiusInMeters" id="geofencingRadiusInMeters" required>
                  <div class="form-control-feedback" *ngIf="hasLocalFieldError(geofencingRadiusInMeters)">
                    {{'COMMON_VALIDATION_MESSAGE_REQUIRED'
                    | translate}}
                  </div>
                  <div class="form-control-feedback"
                       *ngIf="hasFieldError(Task.ValidatedField.GEOFENCING_RADIUS_IN_METERS)">
                    {{ getFieldErrorText(Task.ValidatedField.GEOFENCING_RADIUS_IN_METERS) }}
                  </div>
                </div>
              </div>

            </div>

          </div>

        </div>

        <div class="container-vertical-padding-half">

          <div class="card">

            <div class="card-header">
              {{'FORM_CREATE_INVOICE_SETTINGS'|translate}}
            </div>

            <div class="card-body">

              <div class="row form-group">
                <label
                  class="col-form-label form-control-label col-md-4 detail-title">{{'FORM_CREATE_INVOICE_GENERATE'
                  | translate}}
                  <i class="icomoon icomoon-info info-icon" popover="{{'TASK_INFO_INVOICE_GENERATE' | translate}}"
                     triggers="mouseenter:mouseleave" container="body" placement="right"></i>
                </label>
                <div class="col-md-8">
                  <mat-button-toggle-group
                    [(ngModel)]="model.taskInvoiceGenerationType"
                    (ngModelChange)="onInvoiceGenerationTypeChanged()"
                    [ngModelOptions]="{standalone: true}">
                    <mat-button-toggle *ngFor="let type of taskInvoiceGenerationTypes" [value]="type">
                      {{'FORM_CREATE_INVOICE_GENERATION_TYPE_' + type.toString() | translate}}
                    </mat-button-toggle>
                  </mat-button-toggle-group>
                </div>
              </div>

              <div *ngIf="model.taskInvoiceGenerationType === TaskInvoiceGenerationType.AUTOMATIC">
                <div class="form-group row" [ngClass]="{ 'has-danger':  hasLocalFieldError(validInvoicePaymentTypes) }">
                  <label
                    class="col-form-label form-control-label col-md-4 detail-title required-field-label">
                    {{'FORM_CREATE_VALID_INVOICE_PAYMENT_TYPES' | translate}}
                    <i class="icomoon icomoon-info info-icon"
                       popover="{{'TASK_INFO_VALID_INVOICE_PAYMENT_TYPES' | translate}}"
                       triggers="mouseenter:mouseleave" container="body" placement="right"></i>
                  </label>
                  <div class="col-md-8">
                    <angular2-multiselect [data]="model.paymentTypes" [settings]="paymentDropdownSettings"
                                          class="form-control"
                                          [ngClass]="{ 'form-control-danger': hasLocalFieldError(validInvoicePaymentTypes) }"
                                          [(ngModel)]="model._validInvoicePaymentTypes"
                                          name="validInvoicePaymentTypes" id="validInvoicePaymentTypes"
                                          #validInvoicePaymentTypes="ngModel"
                                          (onDeSelect)="model.refreshGeneratingPaymentTypes()"
                                          (onDeSelectAll)="model.refreshGeneratingPaymentTypes()"
                                          [required]="model.taskInvoiceGenerationType === TaskInvoiceGenerationType.AUTOMATIC">
                    </angular2-multiselect>
                    <div class="form-control-feedback" *ngIf="hasLocalFieldError(validInvoicePaymentTypes)">
                      <span
                        *ngIf="hasLocalFieldError(validInvoicePaymentTypes)">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</span>
                    </div>
                  </div>
                </div>
                <div class="form-group row" [ngClass]="{ 'has-danger':  hasLocalFieldError(generatingInvoicePaymentTypes) }">
                  <label
                    class="col-form-label form-control-label col-md-4 detail-title required-field-label">
                    {{'FORM_CREATE_GENERATING_INVOICE_PAYMENT_TYPES' | translate}}
                    <i class="icomoon icomoon-info info-icon"
                       popover="{{'TASK_INFO_GENERATING_INVOICE_PAYMENT_TYPES' | translate}}"
                       triggers="mouseenter:mouseleave" container="body" placement="right"></i>
                  </label>
                  <div class="col-md-8">
                    <angular2-multiselect [data]="model._validInvoicePaymentTypes" [settings]="paymentDropdownSettings"
                                          class="form-control"
                                          [ngClass]="{ 'form-control-danger': hasLocalFieldError(generatingInvoicePaymentTypes) }"
                                          [(ngModel)]="model._generatingInvoicePaymentTypes"
                                          name="generatingInvoicePaymentTypes" id="generatingInvoicePaymentTypes"
                                          #generatingInvoicePaymentTypes="ngModel"
                                          [required]="model.taskInvoiceGenerationType === TaskInvoiceGenerationType.AUTOMATIC">
                    </angular2-multiselect>
                    <div class="form-control-feedback" *ngIf="hasLocalFieldError(generatingInvoicePaymentTypes)">
                      <span
                        *ngIf="hasLocalFieldError(generatingInvoicePaymentTypes)">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</span>
                    </div>
                  </div>
                </div>
                <div class="form-group row" [ngClass]="{ 'has-danger':  hasLocalFieldError(invoiceSettingsInput) }">
                  <label
                    class="col-form-label form-control-label col-md-4 detail-title">{{'FORM_CREATE_INVOICE_SETTINGS'
                    | translate}}
                  </label>
                  <div class="col-md-8">
                    <angular2-multiselect [data]="invoiceSettings" [settings]="invoiceDropdownSettings"
                                          class="form-control"
                                          [ngClass]="{ 'form-control-danger': hasLocalFieldError(invoiceSettingsInput) }"
                                          [(ngModel)]="model.invoiceSettings"
                                          (ngModelChange)="onInvoiceSettingsChanged()"
                                          (onRemoteSearch)="searchInvoiceSettings($event)"
                                          name="invoiceSettingsInput" id="invoiceSettingsInput" validateEnabledItems
                                          required
                                          #invoiceSettingsInput="ngModel">
                    </angular2-multiselect>
                    <div class="form-control-feedback" *ngIf="hasLocalFieldError(invoiceSettingsInput)">
                      <div class="form-control-feedback"
                           *ngIf="f.submitted && !invoiceSettingsInput.valid && invoiceSettingsInput.errors['required']">
                        {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
                      </div>
                      <div class="form-control-feedback"
                           *ngIf="f.submitted && !invoiceSettingsInput.valid && invoiceSettingsInput.errors['hasDisabledItem']">
                        {{'COMMON_VALIDATION_MESSAGE_DISABLED' | translate}}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-group row"
                     *ngIf="model.invoiceSettings.length > 0 && model.invoiceSettings[0].integrationType === InvoiceSettings.InvoiceIntegrationType.APPWORKS"
                     [ngClass]="{ 'has-danger':  hasLocalFieldError(invoiceBookInput) }">
                  <label
                    class="col-form-label form-control-label col-md-4 detail-title">{{'FORM_CREATE_INVOICE_BOOK'
                    | translate}}
                  </label>
                  <div class="col-md-8">
                    <angular2-multiselect [data]="invoiceBooks" [settings]="invoiceDropdownSettings"
                                          class="form-control"
                                          [ngClass]="{ 'form-control-danger': hasLocalFieldError(invoiceBookInput) }"
                                          [(ngModel)]="model.invoiceBook"
                                          (onRemoteSearch)="loadInvoiceBooks($event.target.value)"
                                          name="invoiceBookInput" id="invoiceBookInput" validateEnabledItems required
                                          #invoiceBookInput="ngModel">
                    </angular2-multiselect>
                    <div class="form-control-feedback" *ngIf="hasLocalFieldError(invoiceBookInput)">
                      <div class="form-control-feedback"
                           *ngIf="f.submitted && !invoiceBookInput.valid && invoiceBookInput.errors['required']">
                        {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
                      </div>
                      <div class="form-control-feedback"
                           *ngIf="f.submitted && !invoiceBookInput.valid && invoiceBookInput.errors['hasDisabledItem']">
                        {{'COMMON_VALIDATION_MESSAGE_DISABLED' | translate}}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-group row"
                     *ngIf="model.taskInvoiceGenerationType === TaskInvoiceGenerationType.AUTOMATIC"
                     [ngClass]="{ 'has-danger':  hasLocalFieldError(invoiceTagInput) }">
                  <label
                    class="col-form-label form-control-label col-md-4 detail-title">{{'FORM_CREATE_INVOICE_TAG'
                    | translate}}
                  </label>
                  <div class="col-md-8">
                    <angular2-multiselect [data]="invoiceTags" [settings]="invoiceDropdownSettings"
                                          class="form-control"
                                          [ngClass]="{ 'form-control-danger': hasLocalFieldError(invoiceTagInput) }"
                                          [(ngModel)]="model.invoiceTag"
                                          (onRemoteSearch)="onInvoiceTagSearch($event.target.value)"
                                          name="invoiceTagInput" id="invoiceTagInput" validateEnabledItems
                                          #invoiceTagInput="ngModel">
                    </angular2-multiselect>
                    <div class="form-control-feedback" *ngIf="hasLocalFieldError(invoiceTagInput)">
                      <div class="form-control-feedback"
                           *ngIf="f.submitted && !invoiceTagInput.valid && invoiceTagInput.errors['required']">
                        {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
                      </div>
                      <div class="form-control-feedback"
                           *ngIf="f.submitted && !invoiceTagInput.valid && invoiceTagInput.errors['hasDisabledItem']">
                        {{'COMMON_VALIDATION_MESSAGE_DISABLED' | translate}}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-group row" [ngClass]="{ 'has-danger':  hasLocalFieldError(printInvoiceCount) }">
                  <label
                    class="col-form-label form-control-label col-md-4 detail-title required-field-label">
                    {{'FORM_CREATE_PRINT_INVOICE_COUNT' | translate}}
                    <i class="icomoon icomoon-info info-icon" popover="{{'TASK_INFO_PRINT_INVOICE_COUNT' | translate}}"
                       triggers="mouseenter:mouseleave" container="body" placement="right"></i>
                  </label>
                  <div class="col-md-8">
                    <input type="text" class="form-control"
                           [ngClass]="{ 'form-control-danger': hasLocalFieldError(printInvoiceCount)}"
                           [(ngModel)]="model.printInvoiceCount" maxlength="1" required max="5"
                           name="printInvoiceCount" id="printInvoiceCount" #printInvoiceCount="ngModel"
                           [textMask]="{mask: InputMask.NATURAL_INTEGER, guide: false}">
                    <div class="form-control-feedback" *ngIf="hasLocalFieldError(printInvoiceCount)">
                      <span
                        *ngIf="printInvoiceCount.hasError('max')"> {{ 'COMMON_VALIDATION_MESSAGE_MAX' | translate: {maxValue: 5} }} </span>
                      <span
                        *ngIf="printInvoiceCount.hasError('required')">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</span>
                    </div>
                  </div>
                </div>
              </div>

            </div>

          </div>

        </div>

        <div class="container-vertical-padding-half">

          <div class="card">

            <div class="card-header">
              {{'FORM_CREATE_PICTURE_UPLOAD_SETTINGS'|translate}}
            </div>

            <div class="card-body">

              <div class="form-group row">
                <label class="col-form-label form-control-label col-md-4 detail-title">{{'FORM_CREATE_PICTURE_UPLOAD' |
                  translate}}
                  <i class="icomoon icomoon-info info-icon" popover="{{'TASK_INFO_PICTURE_UPLOAD' | translate}}"
                     triggers="mouseenter:mouseleave" container="body" placement="right"></i>
                </label>
                <div class="col-md-8">
                  <label class="switch switch-text switch-info mb-0">
                    <input type="checkbox" class="switch-input" [(ngModel)]="model.pictureUpload"
                           (click)="pictureUploadChanged()"
                           #pictureUpload="ngModel" id="pictureUpload" name="pictureUpload">
                    <span class="switch-label" data-on="&#xe957;" data-off="&#xe98e;"></span>
                    <span class="switch-handle"></span>
                  </label>
                </div>
              </div>

              <div *ngIf="model.pictureUpload">
                <div class="form-group row">
                  <div class="col-md-4"></div>
                  <div class="col-md-8" [ngClass]="{ 'has-danger': hasFieldError(Task.ValidatedField.MIN_NUM_OF_PICS)}">
                    <input type="text" class="form-control"
                           [(ngModel)]="model.minNumOfPics" #minNumOfPics="ngModel"
                           maxlength="10"
                           [ngClass]="{ 'form-control-danger': hasFieldError(Task.ValidatedField.MIN_NUM_OF_PICS)}"
                           [textMask]="{mask: InputMask.NATURAL_FLOAT, guide: false}"
                           (ngModelChange)="removeFieldError(Task.ValidatedField.MIN_NUM_OF_PICS)"
                           placeholder="{{'FORM_CREATE_MIN_PICTURE_NUM' | translate}}"
                           name="minNumOfPics" id="minNumOfPics">
                  </div>
                </div>

                <div class="form-group row">
                  <div class="col-md-4"></div>
                  <div class="col-md-8" [ngClass]="{ 'has-danger': hasFieldError(Task.ValidatedField.MIN_NUM_OF_PICS)}">
                    <input type="text" class="form-control"
                           maxlength="10"
                           [(ngModel)]="model.maxNumOfPics" #maxNumOfPics="ngModel"
                           [ngClass]="{ 'form-control-danger': hasFieldError(Task.ValidatedField.MIN_NUM_OF_PICS)}"
                           [textMask]="{mask: InputMask.NATURAL_FLOAT, guide: false}"
                           (ngModelChange)="removeFieldError(Task.ValidatedField.MIN_NUM_OF_PICS)"
                           placeholder="{{'FORM_CREATE_MAX_PICTURE_NUM' | translate}}"
                           name="maxNumOfPics" id="maxNumOfPics">
                    <div class="form-control-feedback" *ngIf="hasFieldError(Task.ValidatedField.MIN_NUM_OF_PICS)">
                      {{ getFieldErrorText(Task.ValidatedField.MIN_NUM_OF_PICS) }}
                    </div>
                  </div>
                </div>

                <div class="form-group row">
                  <label
                    class="col-form-label form-control-label col-md-4 detail-title">{{'FORM_CREATE_PICTURE_SHOW_ON_FORM'
                    | translate}}</label>
                  <div class="col-md-8">
                    <label class="switch switch-text switch-info mb-0">
                      <input type="checkbox" class="switch-input" [(ngModel)]="model.showPicturesOnForm"
                             [disabled]="!model.pictureUpload"
                             #showPicturesOnForm="ngModel" id="showPicturesOnForm" name="showPicturesOnForm">
                      <span class="switch-label" data-on="&#xe957;" data-off="&#xe98e;"></span>
                      <span class="switch-handle"></span>
                    </label>
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-form-label form-control-label col-md-4 detail-title">{{'FORM_CREATE_PHOTO_CONFIRM_DIALOG_ENABLED' |
                    translate}}
                    <i class="icomoon icomoon-info info-icon" popover="{{'TASK_INFO_PHOTO_CONFIRM_DIALOG_ENABLED' | translate}}"
                       triggers="mouseenter:mouseleave" container="body" placement="right"></i>
                  </label>
                  <div class="col-md-8">
                    <label class="switch switch-text switch-info mb-0">
                      <input type="checkbox" class="switch-input" [(ngModel)]="model.photoConfirmDialogEnabled"
                             [disabled]="!model.pictureUpload"
                             #photoConfirmDialogEnabled="ngModel" id="photoConfirmDialogEnabled" name="photoConfirmDialogEnabled">
                      <span class="switch-label" data-on="&#xe957;" data-off="&#xe98e;"></span>
                      <span class="switch-handle"></span>
                    </label>
                  </div>
                </div>

                <div class="form-group row" [ngClass]="{ 'has-danger': hasLocalFieldError(photoEnabledInStates) }">
                  <label
                    class="col-form-label form-control-label col-md-4 detail-title" [ngClass]="{'required-field-label': model.pictureUpload }">{{'FORM_CREATE_PHOTO_ENABLED_IN_STATES'
                    | translate}}
                    <i class="icomoon icomoon-info info-icon" popover="{{'TASK_INFO_PHOTO_ENABLED_IN_STATES' | translate}}"
                       triggers="mouseenter:mouseleave" container="body" placement="right"></i>
                  </label>
                  <div class="col-md-8 d-flex align-items-center">
                    <div class="w-100">
                      <angular2-multiselect [data]="photoEnabledInStateItems"
                                            [settings]="photoEnabledInStatesDropdownSettings"
                                            class="form-control"
                                            [ngClass]="{ 'form-control-danger': hasLocalFieldError(photoEnabledInStates) }"
                                            [(ngModel)]="model.photoEnabledInStates"
                                            [disabled]="!model.pictureUpload"
                                            name="photoEnabledInStates" id="photoEnabledInStates"
                                            [required]="model.pictureUpload"
                                            #photoEnabledInStates="ngModel">
                      </angular2-multiselect>
                      <div class="form-control-feedback" *ngIf="hasLocalFieldError(photoEnabledInStates)">
                        <div class="form-control-feedback"
                             *ngIf="f.submitted && !photoEnabledInStates.valid && photoEnabledInStates.errors['required']">
                          {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>

          </div>

        </div>

        <div class="container-vertical-padding-half">

          <div class="card">

            <div class="card-header">
              {{'TASK_DASHBOARD_DISPLAY_SETTINGS'|translate}}
            </div>

            <div class="card-body">

              <div class="form-group row">
                <label
                  class="col-form-label form-control-label col-md-4 detail-title">{{'TASK_DASHBOARD_DISPLAY_SETTINGS_SHOW_ON_DASHBOARD'
                  | translate}}
                </label>
                <div class="col-md-8">
                  <label class="switch switch-text switch-info mb-0">
                    <input type="checkbox" class="switch-input"
                           [ngModelOptions]="{standalone: true}"
                           [(ngModel)]="model.showOnDashboard">
                    <span class="switch-label" data-on="&#xe957;" data-off="&#xe98e;"></span>
                    <span class="switch-handle"></span>
                  </label>
                </div>
              </div>

              <div class="form-group row"
                   [ngClass]="{ 'has-danger':  hasFieldError(Task.ValidatedField.EXPLICIT_ORDER_NUMBER)}">
                <label
                  class="col-form-label form-control-label col-md-4 detail-title">
                  {{'TASK_DASHBOARD_DISPLAY_SETTINGS_ORDER_NUMBER' | translate}}
                  <i class="icomoon icomoon-info info-icon"
                     popover="{{'TASK_DASHBOARD_DISPLAY_SETTINGS_ORDER_NUMBER_HINT' | translate}}"
                     triggers="mouseenter:mouseleave" container="body" placement="right"></i>
                </label>
                <div class="col-md-8">
                  <input type="text" class="form-control"
                         [ngClass]="{ 'form-control-danger': hasFieldError(Task.ValidatedField.EXPLICIT_ORDER_NUMBER)}"
                         [(ngModel)]="model.explicitOrderNumber"
                         [ngModelOptions]="{standalone: true}"
                         (ngModelChange)="removeFieldError(Task.ValidatedField.EXPLICIT_ORDER_NUMBER)"
                         [textMask]="{mask: InputMask.NATURAL_INTEGER, guide: false}">
                  <div class="form-control-feedback" *ngIf="hasFieldError(Task.ValidatedField.EXPLICIT_ORDER_NUMBER)">
                    {{ getFieldErrorText(Task.ValidatedField.EXPLICIT_ORDER_NUMBER) }}
                  </div>
                </div>
              </div>

            </div>

          </div>

        </div>

        <div class="container-vertical-padding-half">

          <div class="card">

            <div class="card-header">
              {{'TASK_MILEAGE_RECORD_ENABLED'|translate}}
            </div>

            <div class="card-body">

              <div class="form-group row">
                <label
                  class="col-form-label form-control-label col-md-4 detail-title">{{'TASK_MILEAGE_RECORD_ENABLED'
                  | translate}}
                  <i class="icomoon icomoon-info info-icon" popover="{{'TASK_MILEAGE_RECORD_ENABLED_INFO' | translate}}"
                     triggers="mouseenter:mouseleave" container="body" placement="right"></i>
                </label>
                <div class="col-md-8">
                  <label class="switch switch-text switch-info mb-0">
                    <input type="checkbox" class="switch-input"
                           [ngModelOptions]="{standalone: true}"
                           [(ngModel)]="model.mileageRecordEnabled"
                           (click)="mileageRecordEnabledChanged()">
                    <span class="switch-label" data-on="&#xe957;" data-off="&#xe98e;"></span>
                    <span class="switch-handle"></span>
                  </label>
                </div>
              </div>

              <div class="form-group row" *ngIf="model.mileageRecordEnabled">
                <label
                  class="col-form-label form-control-label col-md-4 detail-title">{{'TASK_MILEAGE_RECORD_REQUIRED'
                  | translate}}
                  <i class="icomoon icomoon-info info-icon"
                     popover="{{'TASK_MILEAGE_RECORD_REQUIRED_INFO' | translate}}"
                     triggers="mouseenter:mouseleave" container="body" placement="right"></i>
                </label>
                <div class="col-md-8">
                  <label class="switch switch-text switch-info mb-0">
                    <input type="checkbox" class="switch-input"
                           [ngModelOptions]="{standalone: true}"
                           [(ngModel)]="model.mileageRecordRequired">
                    <span class="switch-label" data-on="&#xe957;" data-off="&#xe98e;"></span>
                    <span class="switch-handle"></span>
                  </label>
                </div>
              </div>

            </div>

          </div>

        </div>
      </div>
    </div>

    <div *ngIf="rightModel.taskTriggerRead.hasRight()"
         class="container-vertical-padding-half card-custom-padding-outer">

      <!-- region trigger card -->
      <app-trigger-list #triggerList
                        [scope]="'TASK'"
                        [triggerParentId]="taskId"
                        [triggerService]="getTaskService()"
                        [expandable]="true"
                        (triggerCreate)="triggerPreselectDialog.showTriggerPreselectDialog()"
                        (triggerEdit)="triggerEditDialog.showTriggerEditDialog($event.type, $event.id, false)"
                        (triggerClone)="triggerEditDialog.showTriggerEditDialog($event.type, $event.id, true)">
      </app-trigger-list>
      <!-- endregion trigger card -->

    </div>

    <div class="container-vertical-padding-half card-custom-padding-outer form-editor-card-container"
         id="form-editor-card-container">

      <!-- region Form card -->
      <app-form-edit #formEdit
                     [formServiceCode]="FormServiceCode.TASK"
                     [parentId]="taskId"
                     [formRights]="formRights"
                     [dialogContainer]="dialogContainer"
                     (formLoad)="onFormLoaded($event)">
      </app-form-edit>
      <!-- endregion Form card -->

    </div>

    <input type="submit" class="btn btn-primary floating_save_button" value="{{'COMMON_BUTTON_SAVE'|translate}}"/>

  </form>

</div>

<!-- region Dialogs -->
<app-form-edit-dialog-container #dialogContainer></app-form-edit-dialog-container>
<!-- endregion Dialogs -->

<app-trigger-preselect-dialog
  #triggerPreselectDialog
  (triggerTypeSelected)="triggerEditDialog.showTriggerEditDialog($event)"
  [scope]="'TASK'">
</app-trigger-preselect-dialog>

<app-trigger-edit-dialog
  #triggerEditDialog
  [triggerService]="getTaskService()"
  [triggerParentId]="taskId"
  [scope]="'TASK'"
  [form]="taskForm"
  (onTriggerCreated)="onTriggerCreated()">
</app-trigger-edit-dialog>
