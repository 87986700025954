import { Injectable } from '@angular/core';
import { BaseHttpService, ResourceHelper } from '../util/http-services';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EmptyMessage, IdentityMessage } from '../util/messages';
import { ResourceQueryResult } from '../util/services';

@Injectable()
export class AndroidAppVersionResourceService extends BaseHttpService {

  private settingsService: AndroidAppVersionsSettingsResourceService;

  query(request: AndroidAppVersionResource.QueryRequest): Observable<ResourceQueryResult<AndroidAppVersionResource.AndroidAppVersion>> {
    return this.http.get<ResourceQueryResult<AndroidAppVersionResource.AndroidAppVersion>>(this.url, this.parseParams(request));
  }

  create(request: AndroidAppVersionResource.CreateRequest): Observable<IdentityMessage> {
    return this.http.post<IdentityMessage>(this.url, request);
  }

  update(request: AndroidAppVersionResource.UpdateRequest): Observable<EmptyMessage> {
    return this.http.put<EmptyMessage>(this.url + `${request.id}`, request);
  }

  delete(request: IdentityMessage): Observable<EmptyMessage> {
    return this.http.delete<EmptyMessage>(this.url + `${request.id}`);
  }

  updateSettings(request: AndroidAppVersionResource.VersionSettingsRequest): Observable<EmptyMessage> {
    return this.settingsService.update(request);
  }

  getSettings(): Observable<AndroidAppVersionResource.VersionSettings> {
    return this.settingsService.get();
  }

  constructor(private http: HttpClient, resourceHelper: ResourceHelper) {
    super(resourceHelper, '/android-app-versions/');
    this.settingsService = new AndroidAppVersionsSettingsResourceService(http, resourceHelper);
  }
}

class AndroidAppVersionsSettingsResourceService extends BaseHttpService {

  update(request: AndroidAppVersionResource.VersionSettings): Observable<EmptyMessage> {
    return this.http.put<EmptyMessage>(this.url, request);
  }

  get(): Observable<AndroidAppVersionResource.VersionSettings> {
    return this.http.get<AndroidAppVersionResource.VersionSettings>(this.url);
  }

  constructor(private http: HttpClient, resourceHelper: ResourceHelper) {
    super(resourceHelper, '/android-app-versions-settings/');
  }
}

export namespace AndroidAppVersionResource {

  export interface AndroidAppVersion {
    id: number;
    version_code: number;
    version_name: string;
    build_slug: string;
    change_log: string;
    artifact_url?: string;
  }

  export interface CreateRequest {
    version_code: number;
    version_name: string;
    build_slug: string;
    change_log: string;
  }

  export interface UpdateRequest extends CreateRequest {
    id: number;
  }

  export interface QueryRequest {
    version_name?: string;
    with_artifact_url?: boolean;
    page_number?: number;
    number_of_items?: number;
    order?: string;
    no_progress_bar?: boolean;
  }

  export interface VersionSettingsRequest {
    auto_update_enabled: boolean;
    minimum_version_id?: number;
  }

  export interface VersionSettings {
    auto_update_enabled: boolean;
    minimum_version?: AndroidAppVersion;
  }

}
