<div class="breadcrumb-container" [ngClass]="{ 'breadcrumb-container-compact': compactSidebar }" id="breadcrumb">
  <div class="breadcrumb-component">
    <app-breadcrumb
      [parents]="breadcrumbParents"
      [self]="breadcrumbSelf">
    </app-breadcrumb>
    <div class="breadcrumb-menu d-flex">
      <div *ngIf="rightModel.transportCreate.hasRight()"
           class="breadcrumb-button-icon-container cursor-pointer negative-margin-right"
           (click)="openTransportEmptyCreateDialog()">
        <a class="btn-setting" title="{{'TRANSPORT_LIST_CREATE_EMPTY_TRANSPORT' | translate}}">
          <i class="icomoon icomoon-vehicles"></i>
          <span class="breadcrumb-button-text-visible">{{'TRANSPORT_LIST_CREATE_EMPTY_TRANSPORT' | translate}}</span>
        </a>
      </div>
    </div>
  </div>
</div>
<div class="breadcrumb-placeholder"></div>

<div class="container-horizontal-padding container-vertical-padding animated fadeIn">

  <div class="card">

    <div class="card-header">
      <span *ngIf="showMap">{{'MENU_NAVBAR_MENU_RUNNING_TRANSPORT' | translate}} </span>
      <span *ngIf="!showMap">{{'MENU_NAVBAR_MENU_TRANSPORT' | translate}}</span>
      <span class="text-muted" *ngIf="showMap && latestMapRefreshTime">({{'TRANSPORT_MAP_LATEST_REFRESH' | translate}}: {{latestMapRefreshTime.toUtcIsoString() | date:'HH:mm'}})</span>
      <div class="card-actions">
        <a class="btn-setting cursor-pointer" (click)="toggleSearch()" *ngIf="!showMap"
        title="{{'COMMON_SHOW_SEARCH_HINT' | translate}}"><i class="icomoon icomoon-search"></i></a>
        <a *ngIf="!showMap" class="btn-setting cursor-pointer" (click)="toggleMap()"
           title="{{ 'TRANSPORT_LIST_SHOW_MAP' | translate}}"><i class="icomoon icomoon-transport-map"></i></a>
        <a *ngIf="showMap" class="btn-setting cursor-pointer" (click)="toggleMap()"
           title="{{ 'TRANSPORT_LIST_SHOW_LIST' | translate}}"><i class="icomoon icomoon-transport-list"></i></a>
      </div><!-- /.card-actions -->
    </div>
    <div class="card-body border-bottom" *ngIf="showSearch && !showMap">
      <responsive-tabset #searchTabs>
        <responsive-tab heading="{{'SEARCH_MODE_SIMPLE' | translate}}" (select)="activeSearchTab = 'simple'" id="simple">
        <div class="row form-group">
          <div class="col-md-3">
            <label class="search-label">{{'COMMON_TABLE_HEADER_ID' | translate}}</label>
            <input type="text" class="form-control"
                   maxlength="{{UiConstants.maxInputLongLength}}"
                   (keyup.enter)="onSearchClicked()"
                   placeholder="{{'COMMON_TABLE_HEADER_ID' | translate}}"
                   [textMask]="{mask: InputMask.NATURAL_INTEGER, guide: false}"
                   [(ngModel)]="searchModel.id">
          </div>
          <div class="col-md-3">
            <label class="search-label">{{'TRANSPORT_WAYBILL_NUMBER' | translate}}</label>
            <input type="text" class="form-control"
                   maxlength="{{UiConstants.maximumVarcharLength}}"
                   (keyup.enter)="onSearchClicked()"
                   placeholder="{{'TRANSPORT_WAYBILL_NUMBER' | translate}}"
                   [(ngModel)]="searchModel.waybillNumber">
          </div>
          <div class="col-md-3">
            <label class="search-label">{{'VEHICLE_LICENCE_PLATE' | translate}}</label>
            <angular2-multiselect [data]="vehiclesForSearch" [settings]="dropdownSettingsForVehicle"
                                  maxlength="{{UiConstants.maximumVarcharLength}}"
                                  class="form-control"
                                  [(ngModel)]="searchModel.vehicleIds"
                                  #vehicle="ngModel" name="vehicle" id="vehicle">
            </angular2-multiselect>
          </div>
          <div class="col-md-3">
            <label class="search-label">{{'USER_LABEL_IS_DRIVER' | translate}}</label>
            <angular2-multiselect [data]="driversForSearch" [settings]="dropdownSettingsForDriver"
                                  class="form-control"
                                  [(ngModel)]="searchModel.driverIds"
                                  #driver="ngModel" name="driver" id="driver">
            </angular2-multiselect>
          </div>
        </div>
        <!--Second row-->
        <div class="row form-group">
          <div class="col-md-3">
            <label class="search-label">{{'TRANSPORT_CREATION_TIME_FROM' | translate}}</label>
            <div class="input-group">
              <input type="text" class="form-control date" placeholder="{{'TRANSPORT_CREATION_TIME_FROM' | translate}}"
                     [(ngModel)]="searchModel.creationTimeFrom" ngbDatepicker name="creationTimeFrom" id="creationTimeFrom"
                     #creationTimeFrom="ngbDatepicker">
              <div class="input-group-addon-gray" (click)="creationTimeFrom.toggle()">
                <i class="icomoon icomoon-calendar"></i>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <label class="search-label">{{'TRANSPORT_CREATION_TIME_TO' | translate}}</label>
            <div class="input-group">
              <input type="text" class="form-control date" placeholder="{{'TRANSPORT_CREATION_TIME_TO' | translate}}"
                     [(ngModel)]="searchModel.creationTimeTo" ngbDatepicker name="creationTimeTo" id="creationTimeTo"
                     #creationTimeTo="ngbDatepicker">
              <div class="input-group-addon-gray" (click)="creationTimeTo.toggle()">
                <i class="icomoon icomoon-calendar"></i>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <label class="search-label">{{'TRANSPORT_CLOSE_TIME_FROM' | translate}}</label>
            <div class="input-group">
              <input type="text" class="form-control date" placeholder="{{'TRANSPORT_CLOSE_TIME_FROM' | translate}}"
                     [(ngModel)]="searchModel.closeTimeFrom" ngbDatepicker name="closeTimeFrom" id="closeTimeFrom"
                     #closeTimeFrom="ngbDatepicker">
              <div class="input-group-addon-gray" (click)="closeTimeFrom.toggle()">
                <i class="icomoon icomoon-calendar"></i>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <label class="search-label">{{'TRANSPORT_CLOSE_TIME_TO' | translate}}</label>
            <div class="input-group">
              <input type="text" class="form-control date" placeholder="{{'TRANSPORT_CLOSE_TIME_TO' | translate}}"
                     [(ngModel)]="searchModel.closeTimeTo" ngbDatepicker name="closeTimeTo" id="closeTimeTo"
                     #closeTimeTo="ngbDatepicker">
              <div class="input-group-addon-gray" (click)="closeTimeTo.toggle()">
                <i class="icomoon icomoon-calendar"></i>
              </div>
            </div>
          </div>
        </div>
        <!--Third row-->
        <div class="row form-group">
          <div class="col-md-3">
            <label class="search-label">{{'SHIPPING_ITEM_SEARCH_FIELD' | translate}}</label>
            <input type="text" class="form-control"
                   maxlength="{{UiConstants.maximumVarcharLength}}"
                   (keyup.enter)="onSearchClicked()"
                   placeholder="{{'SHIPPING_ITEM_SEARCH_FIELD' | translate}}"
                   [(ngModel)]="searchModel.shippingItem">
          </div>
          <div class="col-md-3 d-flex align-items-end ">
            <div class="row">
              <app-animated-checkbox class="search-checkbox"
                                     [(selected)]="searchModel.safetyShipping">
              </app-animated-checkbox>
              <label class="search-label">{{'TRANSPORT_SAFETY_SHIPPING' | translate}}</label>
            </div>
          </div>
          <div class="col-md-3">
            <label class="search-label">{{'TRANSPORT_STATE' | translate}}</label>
            <angular2-multiselect [data]="statesForSearch" [settings]="dropdownSettingsForState"
                                  class="form-control"
                                  [(ngModel)]="searchModel.stateIds"
                                  #state="ngModel" name="state" id="state">
            </angular2-multiselect>
          </div>
        </div>
        <div class="col-md-12 d-flex justify-content-md-end align-items-end px-0">
        <div class="btn-group" role="group">
          <button type="button" (click)="onSearchReset()" class="btn btn-outline-primary search-button mr-1">
            {{'COMMON_BUTTON_SEARCH_DELETE' | translate}}
          </button>
          <button type="button" (click)="onSearchClicked()" class="btn btn-primary search-button">
            {{'COMMON_BUTTON_SEARCH' | translate}}
          </button>
        </div>
      </div>
        </responsive-tab>
        <responsive-tab heading="{{'SEARCH_MODE_DQL' | translate}}" (select)="activeSearchTab = 'dql'" id="dql">
          <app-dql-search-container #dqlSearchContainer
                                    (onSearch)="onDqlSearchClicked()"
                                    [dqlStoredQueryArgs]="dqlStoredArgs"
                                    [rightModel]="rightModel"
                                    [dialogContainer]="dqlDialogHolder"></app-dql-search-container>
        </responsive-tab>
      </responsive-tabset>
    </div><!-- /.card-body-->

    <div class="card-body" *ngIf="!showMap">
      <table class="table table-striped table-bordered">
        <thead>
        <tr>
          <th class="table-sorter-header">
            <app-table-field-sorter [orderField]="Transport.OrderField.ID"
                                    [orderType]="queryModel.getOrderType(Transport.OrderField.ID)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_TABLE_HEADER_ID' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header">
            <app-table-field-sorter [orderField]="Transport.OrderField.WAYBILL_NUMBER"
                                    [orderType]="queryModel.getOrderType(Transport.OrderField.WAYBILL_NUMBER)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'TRANSPORT_WAYBILL_NUMBER' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-lg-down">
            <app-table-field-sorter [orderField]="Transport.OrderField.VEHICLE_LICENCE_PLATE"
                                    [orderType]="queryModel.getOrderType(Transport.OrderField.VEHICLE_LICENCE_PLATE)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'TRANSPORT_LICENCE_PLATE' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-sm-down">
            <app-table-field-sorter [orderField]="Transport.OrderField.DRIVER_PERSON_NAME"
                                    [orderType]="queryModel.getOrderType(Transport.OrderField.DRIVER_PERSON_NAME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'TRANSPORT_DRIVER' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-lg-down">
            <app-table-field-sorter [orderField]="Transport.OrderField.CREATION_TIME"
                                    [orderType]="queryModel.getOrderType(Transport.OrderField.CREATION_TIME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_CREATION_TIME' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-md-down">
            <app-table-field-sorter [orderField]="Transport.OrderField.START_TIME"
                                    [orderType]="queryModel.getOrderType(Transport.OrderField.START_TIME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'TRANSPORT_START_TIME' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-xs-down">
            <app-table-sorter-no-op [text]="'TRANSPORT_STATE' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header w-table-options-menu">
            <app-table-sorter-no-op></app-table-sorter-no-op>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let transport of transportList | paginate: {
                                                      itemsPerPage: queryModel.itemsPerPage,
                                                      currentPage: queryModel.currentPage,
                                                      totalItems: queryModel.currentNumberOfItems }">
          <td class="responsive-table-column">{{transport.id}}</td>
          <td class="responsive-table-column long">
            <a class="id-text cursor-pointer"
               href="#" onclick="return false"
               uiSref="Admin.TransportDetail"
               [uiParams]="{ id: transport.id }"
               title="{{'COMMON_BUTTON_DETAILS' | translate}}">
              {{transport.waybillNumber}}
            </a>
            <br>
            <span *ngIf="transport.safetyShipping"
                  class="icomoon icomoon-safety-shipping popover-trigger-icon icomoon-table-icon"
                  [popover]="safetyShipping" triggers="mouseenter:mouseleave"></span>
            <span *ngIf="transport.attachmentCount > 0"
                  class="icomoon icomoon-attachments popover-trigger-icon icomoon-table-icon"
                  [popover]="attachmentCount" triggers="mouseenter:mouseleave"></span>
            <span *ngIf="transport.messageCount > 0"
                  class="icomoon icomoon-transport-message popover-trigger-icon icomoon-table-icon"
                  [popover]="messageCount" triggers="mouseenter:mouseleave"></span>
            <ng-template #safetyShipping>
              <div class="d-flex align-items-center">
                <i class="icomoon icomoon-safety-shipping popover-icon pr-1"></i>
                <div>
                  <div>{{'TRANSPORT_SAFETY_SHIPPING' | translate }}</div>
                  <div class="table-text-subtitle">
                    <div class="table-text-subtitle">
                      <div>{{'TRANSPORT_LIST_SECURITY_GUARD' | translate: { securityGuardName: transport.securityGuard.personName} }} </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
            <ng-template #attachmentCount>
              <div class="d-flex align-items-center">
                <i class="icomoon icomoon-attachments popover-icon pr-1"></i>
                <div>
                  <div>{{'COMMON_LABEL_ATTACHMENT_COUNT' | translate: { count: transport.attachmentCount} }} </div>
                </div>
              </div>
            </ng-template>
            <ng-template #messageCount>
              <div class="d-flex align-items-center">
                <i class="icomoon icomoon-transport-message popover-icon pr-1"></i>
                <div>
                  <div>
                    <div>{{'COMMON_LABEL_MESSAGE_COUNT' | translate: { count: transport.messageCount} }} </div>
                  </div>
                </div>
              </div>
            </ng-template>
          </td>
          <td class="responsive-table-column hidden-lg-down">
            <div class="text-truncate">
              <span class="text-truncate">{{transport.vehicleLicencePlate}}</span>
              <br>
              <span class="text-truncate table-text-subtitle">{{transport.transporterCompany}}</span>
            </div>
          </td>
          <td class="responsive-table-column hidden-sm-down">{{transport.driverName}}</td>
          <td class="responsive-table-column hidden-lg-down">{{transport.creationTime | date:'short'}}</td>
          <td class="responsive-table-column hidden-md-down">{{(transport.startTime | date:'short') || '-'}}</td>
          <td class="fit text-center hidden-xs-down align-middle">
            <app-table-badge
              [badgeStyle]="transport.stateObject.badgeStyle"
              [iconClass]="transport.stateObject.iconClass"
              [text]="transport.stateObject.stringKey | translate">
            </app-table-badge>
          </td>
          <td class="align-middle">
            <app-table-options-menu>
              <app-dropdown-item
                [iconClass]="'icomoon-detail'"
                [titleStringKey]="'COMMON_BUTTON_DETAILS'"
                class="cursor-pointer"
                uiSref="Admin.TransportDetail"
                [uiParams]="{ id: transport.id }">
              </app-dropdown-item>
              <app-dropdown-item
                *ngIf="transport.stateObject.state === 'UNDER_PLANNING' && rightModel.transportDelete.hasRight()"
                [iconClass]="'icomoon-trash'"
                [titleStringKey]="'COMMON_BUTTON_DELETE'"
                class="cursor-pointer"
                (click)="transportDeleteDialog.showDialog(transport.id)">
              </app-dropdown-item>
              <!-- SHIPMENTS -->
              <div class="d-flex dropdown-item no-hover border-bottom-0"
                   *ngIf="rightModel.shipmentGroupRead.hasRight() && transport.shipments && transport.shipments.size > 0">
                <i class="icon-standard dropdown-icon icomoon icomoon-process-order-packaging"></i>
                <div>
                  <div class="text-nowrap small">{{'TRANSPORT_RELATED_SHIPMENTS' | translate: { count: transport.shipments.size } }}</div>
                  <div *ngFor="let shipment of transport.shipments" class="text-nowrap">
                    <a class="cursor-pointer"
                       uiSref="Admin.ShipmentDetail"
                       [uiParams]="{ id: shipment.groupId }">
                      {{shipment.deliveryNoteNumber}}
                    </a>
                  </div>
                </div>
              </div>
              <!-- SHIPPING DEMANDS -->
              <div class="d-flex dropdown-item no-hover border-bottom-0"
                   *ngIf="rightModel.shippingDemandRead.hasRight() && transport.shippingDemands && transport.shippingDemands.size > 0">
                <i class="icon-standard dropdown-icon icomoon icomoon-shipment-demand"></i>
                <div>
                  <div class="text-nowrap small">{{'TRANSPORT_RELATED_SHIPPING_DEMANDS' | translate: { count: transport.shippingDemands.size } }}</div>
                  <div *ngFor="let shippingDemand of transport.shippingDemands" class="text-nowrap">
                    <a class="cursor-pointer"
                       uiSref="Admin.ShippingDemandDetail"
                       [uiParams]="{ id: shippingDemand.id }">
                      {{shippingDemand.demandSerial}}
                    </a>
                  </div>
                </div>
              </div>
            </app-table-options-menu>
          </td>
        </tr>
        </tbody>
        <tfoot>
        <tr>
          <th class="table-sorter-header">
            <app-table-field-sorter [orderField]="Transport.OrderField.ID"
                                    [orderType]="queryModel.getOrderType(Transport.OrderField.ID)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_TABLE_HEADER_ID' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header">
            <app-table-field-sorter [orderField]="Transport.OrderField.WAYBILL_NUMBER"
                                    [orderType]="queryModel.getOrderType(Transport.OrderField.WAYBILL_NUMBER)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'TRANSPORT_WAYBILL_NUMBER' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-lg-down">
            <app-table-field-sorter [orderField]="Transport.OrderField.VEHICLE_LICENCE_PLATE"
                                    [orderType]="queryModel.getOrderType(Transport.OrderField.VEHICLE_LICENCE_PLATE)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'TRANSPORT_LICENCE_PLATE' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-sm-down">
            <app-table-field-sorter [orderField]="Transport.OrderField.DRIVER_PERSON_NAME"
                                    [orderType]="queryModel.getOrderType(Transport.OrderField.DRIVER_PERSON_NAME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'TRANSPORT_DRIVER' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-lg-down">
            <app-table-field-sorter [orderField]="Transport.OrderField.CREATION_TIME"
                                    [orderType]="queryModel.getOrderType(Transport.OrderField.CREATION_TIME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_CREATION_TIME' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-md-down">
            <app-table-field-sorter [orderField]="Transport.OrderField.START_TIME"
                                    [orderType]="queryModel.getOrderType(Transport.OrderField.START_TIME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'TRANSPORT_START_TIME' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-xs-down">
            <app-table-sorter-no-op [text]="'TRANSPORT_STATE' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header w-table-options-menu">
            <app-table-sorter-no-op></app-table-sorter-no-op>
          </th>
        </tr>
        </tfoot>
      </table>
      <app-table-paging
        [currentNumberOfItems]="queryModel.currentNumberOfItems"
        [totalNumberOfItems]="queryModel.totalNumberOfItems"
        [itemsPerPage]="queryModel.itemsPerPage"
        (pageChange)="pageChanged($event)"
        (itemsPerPageChange)="itemsPerPageChanged($event)">
      </app-table-paging>
    </div>
    <app-transport-running-map *ngIf="showMap"
    (onMapRefresh)="onMapRefresh($event)"></app-transport-running-map>
  </div>
</div>

<div bsModal #transportEmptyCreateDialog="bs-modal" class="modal fade" tabindex="-1" role="dialog"
     aria-labelledby="myModalLabel"
     aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document" *ngIf="transportEmptyCreateDialogVisible">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">{{'SHIPPING_DEMAND_CREATE_TRANSPORT' | translate}}</h4>
        <button type="button" class="close" (click)="closeTransportEmptyCreateDialog()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" [formGroup]="formGroup" #f="ngForm">

        <div class="form-group row"
             [ngClass]="{ 'has-danger': hasLocalFieldError('transporter')}">
          <label class="col-form-label col-md-4 required-field-label">
            {{'TRANSPORT_CREATE_TRANSPORTER_COMPANY' | translate}}
          </label>
          <div class="col-md-8">
            <angular2-multiselect [data]="transporterCompanyList" [settings]="singleDropdownSettings"
                                  class="form-control"
                                  (onRemoteSearch)="loadTransporterCompanies($event.target.value)"
                                  [(ngModel)]="transportEmptyCreateModel.transporterCompany"
                                  formControlName="transporter">
            </angular2-multiselect>
            <div class="form-control-feedback" *ngIf="hasLocalFieldError('transporter')">
              {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
            </div>
          </div>
          <!-- /.col-md-8 -->
        </div>

        <div class="form-group row">
          <label class="col-form-label col-md-4">{{'TRANSPORT_CREATE_VEHICLE' | translate}}</label>
          <div class="col-md-8">
            <angular2-multiselect [data]="vehicleList" [settings]="singleDropdownSettings"
                                  class="form-control"
                                  (onRemoteSearch)="loadVehicles($event.target.value)"
                                  [(ngModel)]="transportEmptyCreateModel.vehicle"
                                  [ngModelOptions]="{standalone: true}">
            </angular2-multiselect>
          </div>
          <!-- /.col-md-8 -->
        </div>

        <div class="form-group row">
          <label class="col-form-label col-md-4">{{'TRANSPORT_CREATE_DRIVER' | translate}}</label>
          <div class="col-md-8">
            <angular2-multiselect [data]="driverList" [settings]="singleDropdownSettings"
                                  class="form-control"
                                  (onRemoteSearch)="loadDrivers($event.target.value)"
                                  [(ngModel)]="transportEmptyCreateModel.driver"
                                  [ngModelOptions]="{standalone: true}">
            </angular2-multiselect>
          </div>
          <!-- /.col-md-8 -->
        </div>

        <div class="form-group row">
          <label class="col-form-label col-md-4">{{'TRANSPORT_CREATE_SAFETY_SHIPPING' | translate}}</label>
          <div class="col-md-8">
            <app-animated-checkbox
              [(selected)]="transportEmptyCreateModel.safetyShipping">
            </app-animated-checkbox>
          </div>
          <!-- /.col-md-8 -->
        </div>

      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="closeTransportEmptyCreateDialog()">
          {{'COMMON_BUTTON_CANCEL' | translate}}
        </button>
        <button type="button" class="btn btn-primary" (click)="createEmptyTransport()">{{'COMMON_BUTTON_SAVE' |
            translate}}
        </button>
      </div>
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div><!-- /.modal -->

<app-confirm-dialog
  #transportDeleteDialog
  (onConfirm)="deleteTransport($event)"
  [alertType]="AlertType.DANGER">
</app-confirm-dialog>

<app-dql-dialog-container #dqlDialogHolder></app-dql-dialog-container>
