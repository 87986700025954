import { GrantedPermissionSet, GrantedPermissionSetResolver } from '../right.service';
import { OperationRights } from '../../app.right-definitions';

export class WorkflowRightModel {

  public readonly create: GrantedPermissionSet;
  public readonly read: GrantedPermissionSet;
  public readonly update: GrantedPermissionSet;
  public readonly delete: GrantedPermissionSet;
  public readonly activate: GrantedPermissionSet;
  public readonly finalize: GrantedPermissionSet;
  public readonly createNewVersion: GrantedPermissionSet;

  constructor(resolver: GrantedPermissionSetResolver) {
    this.create = resolver.of(OperationRights.WORKFLOW_CREATE);
    this.read = resolver.of(OperationRights.WORKFLOW_READ);
    this.update = resolver.of(OperationRights.WORKFLOW_UPDATE);
    this.delete = resolver.of(OperationRights.WORKFLOW_DELETE);
    this.activate = resolver.of(OperationRights.WORKFLOW_ACTIVATE);
    this.finalize = resolver.of(OperationRights.WORKFLOW_FINALIZE);
    this.createNewVersion = resolver.of(OperationRights.WORKFLOW_CREATE_NEW_VERSION);
  }
}
