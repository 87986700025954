/* eslint-disable */
import { Injectable } from '@angular/core';
import { versions } from '../../environments/versions';
import { Dates, OffsetDateTime } from './util/dates';
import { Strings } from './util/strings';
/* eslint-enable */

@Injectable()
export class AppConfigurationService {

  private packageJson: any;
  private formattedAppVersion: string;
  private commitDateTime: OffsetDateTime;

  constructor() {
    this.packageJson = require('../../../package.json');
    this.formattedAppVersion = this.createFormattedAppVersion();
    this.commitDateTime = Dates.parseOffsetDateTimeIsoString(versions.commitDateTime);
  }

  public getVersion(): string {
    return versions.version;
  }

  public getRevision(): string {
    return versions.revision;
  }

  public getCommitDateTime(): OffsetDateTime {
    return this.commitDateTime;
  }

  public getFormattedAppVersion(): string {
    return this.formattedAppVersion;
  }

  private createFormattedAppVersion(): string {
    const version = this.getVersion();
    const snapshot = Strings.endsWith(version, '-SNAPSHOT');
    const clearVersion = snapshot ? version.substring(0, version.length - '-SNAPSHOT'.length) : version;
    return clearVersion + (snapshot ? ('-' + this.getRevision() + '-SNAPSHOT') : '');
  }

}
