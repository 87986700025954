/* eslint-disable */
import { List, Map, Set } from 'immutable';
import { Injectable } from '@angular/core';
import { OrderResource, OrderResourceService } from './order-resource.service';
import { Address, AddressResource } from '../address';
import { OffsetDateTime } from '../util/dates';
import { Order as UtilOrder, PagingRequest, QueryResult, ResourceQueryResult, Services } from '../util/services';
import { Observable, Observer } from 'rxjs';
import { FieldError } from '../util/errors';
import { Decimal } from 'decimal.js';
import { PhoneNumber } from '../util/phone-number';
import { EmailAddress } from '../util/email-address';
import { EmptyMessage } from '../util/messages';
import { DownloadedFile } from '../util/downloaded-files';
import { LegacyProcess } from '../legacy-process/legacy-process.service';
import { Icon, IconService } from '../task/icon.service';
import OrderState = Order.OrderState;
import CreateResponse = OrderResource.CreateResponse;
import OrderReturnState = Order.OrderReturnState;
import ProcessState = LegacyProcess.LegacyProcessState;
import OrderFinalState = Order.OrderFinalState;
import OrderLogicState = Order.OrderLogicState;

/* eslint-enable */

@Injectable()
export class OrderService implements Order.Service {

  query(request: Order.QueryRequest): Observable<QueryResult<Order.Order>> {
    return Observable.create((observer: Observer<QueryResult<Order.Order>>) => {
      const resourceRequest: OrderResource.QueryRequest = this.toResourceQueryRequest(request);
      return this.resourceService.query(resourceRequest).subscribe(
        (result: ResourceQueryResult<OrderResource.Order>) => {
          observer.next({
            items: this.toPublicList(result.items),
            pagingResult: result.pagingResult
          });
        });
    });
  }

  create(request: Order.CreateRequest): Observable<Order.CreateResponse> {
    return Observable.create((observer: Observer<Order.CreateResponse>) => {
      return this.resourceService.create({
        owner_company_id: request.companyId
      }).subscribe((result: CreateResponse) => {
          observer.next({
            orderId: result.order_id
          });
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  get(request: Order.GetRequest): Observable<Order.Order> {
    return Observable.create((observer: Observer<Order.Order>) => {
      const resourceRequest: OrderResource.GetRequest = {
        id: request.id
      };
      return this.resourceService.get(resourceRequest).subscribe(
        (result: OrderResource.Order) => {
          observer.next(this.toPublic(result));
        });
    });
  }

  updateData(request: Order.UpdateDataRequest): Observable<Order.UpdateDataResponse> {
    return Observable.create((observer: Observer<OrderResource.UpdateDataResponse>) => {
      const resourceRequest: OrderResource.UpdateDataRequest = {
        id: request.id,
        external_id: request.externalId,
        description: request.description,
        weight_in_gram: request.weightInGram,
        shipping_state: request.shippingState,
        insurance_price: this.toResourceInsurancePrice(request.insurancePrice),
        cash_on_delivery_price: this.toResourceCashOnDeliveryPrice(request.cashOnDeliveryPrice),
        package_size: this.toResourcePackageSize(request.packageSize),
        delivery_method_id: request.deliveryMethodId,
        recipient: this.toResourceOrderRecipient(request.recipient),
        shipment_tracking_number: request.shipmentTrackingNumber,
        other_data: {
          fragile: request.other.fragile,
          document_handling: request.other.documentHandling,
          package_exchange: request.other.packageExchange,
          handover_by_items: request.other.handoverByItems,
          delivery_time_window: request.other.deliveryTimeWindow,
          oversized: request.other.oversized,
          inverse: request.other.inverse
        },
        return_state: request.returnState,
        return_reason: request.returnReason,
        return_time: Services.offsetDateTimeToString(request.returnTime),
        intake_time: Services.offsetDateTimeToString(request.intakeTime),
        cancel_time: Services.offsetDateTimeToString(request.cancelTime),
        process_end_time: Services.offsetDateTimeToString(request.processEndTime),
        note: {
          customer_note: request.note ? request.note.customerNote : undefined,
          extra_note: request.note ? request.note.extraNote : undefined,
          internal_note: request.note ? request.note.internalNote : undefined
        },
        version: request.version
      };
      return this.resourceService.updateData(resourceRequest).subscribe(
        (result: OrderResource.UpdateDataResponse) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  finalize(request: Order.FinalizeRequest): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      return this.resourceService.finalize({
        id: request.id
      }).subscribe(
        (result: EmptyMessage) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  createStockItem(request: Order.CreateStockItemRequest): Observable<Order.CreateStockItemResponse> {
    return Observable.create((observer: Observer<Order.CreateStockItemResponse>) => {
      return this.resourceService.createStockItem({
        order_id: request.orderId,
        stock_item_id: request.stockItemId,
        amount: request.amount
      }).subscribe(
        (result: Order.CreateStockItemResponse) => {
          observer.next(result);
        });
    });
  }

  updateStockItem(request: Order.UpdateStockItemRequest): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      return this.resourceService.updateStockItem({
        order_id: request.orderId,
        item_id: request.itemId,
        stock_item_id: request.stockItemId,
        amount: request.amount
      }).subscribe(
        (result: EmptyMessage) => {
          observer.next(result);
        });
    });
  }

  deleteStockItem(request: Order.DeleteStockItemRequest): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      return this.resourceService.deleteStockItem({
        order_id: request.orderId,
        item_id: request.itemId
      }).subscribe(
        (result: EmptyMessage) => {
          observer.next(result);
        });
    });
  }

  cancel(request: Order.CancelRequest): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      return this.resourceService.cancel({
        order_id: request.orderId
      }).subscribe(
        (result: EmptyMessage) => {
          observer.next(result);
        });
    });
  }

  returnOrder(request: Order.ReturnOrderRequest): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      return this.resourceService.returnOrder({
        order_id: request.orderId,
        return_reason: request.returnReason
      }).subscribe(
        (result: EmptyMessage) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(error);
        });
    });
  }

  queryShippingStates(request: Order.QueryShippingStates): Observable<QueryResult<string>> {
    return Observable.create((observer: Observer<QueryResult<string>>) => {
      const resourceRequest = {
        shipping_state: request.shippingState,
        page_number: request.paging ? request.paging.pageNumber : undefined,
        number_of_items: request.paging ? request.paging.numberOfItems : undefined
      };
      return this.resourceService.queryShippingStates(resourceRequest).subscribe(
        (result: ResourceQueryResult<string>) => {
          observer.next({
            items: List.of(...result.items),
            pagingResult: result.pagingResult
          });
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  exportXls(request: Order.QueryRequest): Observable<DownloadedFile> {
    return Observable.create((observer: Observer<DownloadedFile>) => {
      const resourceRequest: OrderResource.QueryRequest = this.toResourceQueryRequest(request);
      return this.resourceService.exportXls(resourceRequest).subscribe(
        (result: DownloadedFile) => {
          observer.next(result);
        });
    });
  }

  constructor(
    private resourceService: OrderResourceService,
    private iconService: IconService
  ) {
  }

  private toPublicList(resourceList: OrderResource.Order[]): List<Order.Order> {
    return List.of(...resourceList.map((r) => this.toPublic(r)));
  }

  private toPublic(r: OrderResource.Order): Order.Order {
    return {
      orderId: r.order_id,
      externalId: r.external_id,
      orderState: <OrderState>r.state,
      finalState: <OrderFinalState>r.final_state,
      logicState: <OrderLogicState>r.logic_state,
      description: r.description,
      ownerUserId: r.owner_user_id,
      ownerCompanyId: r.owner_company_id,
      weightInGram: r.weight_in_gram,
      insurancePrice: this.toPublicInsurancePrice(r.insurance_price),
      cashOnDeliveryPrice: this.toPublicCashOnDeliveryPrice(r.cash_on_delivery_price),
      packageSize: this.toPublicPackageSize(r.package_size),
      deliveryMethod: r.delivery_method ? {
        id: r.delivery_method.id,
        name: r.delivery_method.name
      } : undefined,
      recipient: this.toPublicOrderRecipient(r.recipient),
      items: this.toPublicOrderItemList(r.items),
      shipmentTrackingNumber: r.shipment_tracking_number,
      creationTime: Services.toOffsetDateTime(r.creation_time),
      updateTime: Services.toOffsetDateTime(r.update_time),
      submitTime: Services.toOffsetDateTime(r.submit_time),
      processId: r.process_id,
      other: {
        fragile: r.other_data.fragile,
        documentHandling: r.other_data.document_handling,
        packageExchange: r.other_data.package_exchange,
        handoverByItems: r.other_data.handover_by_items,
        deliveryTimeWindow: r.other_data.delivery_time_window,
        oversized: r.other_data.oversized,
        inverse: r.other_data.inverse
      },
      returnState: <OrderReturnState>r.return_state,
      returnReason: r.return_reason,
      returnTime: Services.toOffsetDateTime(r.return_time),
      intakeTime: Services.toOffsetDateTime(r.intake_time),
      cancelTime: Services.toOffsetDateTime(r.cancel_time),
      processEndTime: Services.toOffsetDateTime(r.process_end_time),
      process: this.toPublicOrderProcess(r.process),
      note: this.toPublicOrderNote(r.note),
      version: r.version,
      mplLastSyncTime: Services.toOffsetDateTime(r.mpl_last_sync_time)
    };
  }

  private toPublicInsurancePrice(r?: OrderResource.InsurancePrice): Order.InsurancePrice | undefined {
    if (r) {
      return {
        amount: Services.reqDecimal(r.amount),
        currencyCode: r.currency_code
      };
    }
    return undefined;
  }

  private toResourceInsurancePrice(r?: Order.InsurancePrice): OrderResource.InsurancePrice | undefined {
    if (!r) {
      return undefined;
    }
    return {
      amount: Services.decimalToString(r.amount)!,
      currency_code: r.currencyCode
    };
  }

  private toPublicCashOnDeliveryPrice(r?: OrderResource.CashOnDeliveryPrice): Order.CashOnDeliveryPrice | undefined {
    if (r) {
      return {
        amount: Services.toDecimal(r.amount)!,
        currencyCode: r.currency_code
      };
    }
    return undefined;
  }

  private toResourceCashOnDeliveryPrice(r: Order.CashOnDeliveryPrice): OrderResource.CashOnDeliveryPrice {
    return {
      amount: Services.decimalToString(r.amount)!,
      currency_code: r.currencyCode
    };
  }

  private toPublicPackageSize(r?: OrderResource.PackageSize): Order.PackageSize | undefined {
    if (r) {
      return {
        widthInMeter: Services.toDecimal(r.width_in_meter)!,
        heightInMeter: Services.toDecimal(r.height_in_meter)!,
        depthInMeter: Services.toDecimal(r.depth_in_meter)!,
      };
    }
    return undefined;
  }

  private toResourceQueryRequest(request: Order.QueryRequest): OrderResource.QueryRequest {
    return {
      fields: request.fields ? request.fields.join(',') : undefined,
      order_id: Services.createIdParameter(request.orderId),
      owner_user_id: request.ownerUserId,
      external_id: request.externalId,
      order_state: request.orderState,
      order_logic_state: request.orderLogicState,
      process_id: request.processId,
      owner_company_name: request.ownerCompanyName,
      shipment_tracking_number: request.shipmentTrackingNumber,
      shipping_state: request.shippingState,
      weight_in_gram_from: request.weightInGramFrom,
      weight_in_gram_to: request.weightInGramTo,
      cash_on_delivery_price_from: request.cashOnDeliveryPriceFrom,
      cash_on_delivery_price_to: request.cashOnDeliveryPriceTo,
      recipient_name: request.recipientName,
      recipient_address: request.recipientAddress,
      submit_time_from: request.submitTimeFrom && request.submitTimeFrom.isValid() ? request.submitTimeFrom.toUtcIsoString() : undefined,
      submit_time_to: request.submitTimeTo && request.submitTimeTo.isValid() ? request.submitTimeTo.toUtcIsoString() : undefined,
      return_time_from: request.returnTimeFrom && request.returnTimeFrom.isValid() ? request.returnTimeFrom.toUtcIsoString() : undefined,
      return_time_to: request.returnTimeTo && request.returnTimeTo.isValid() ? request.returnTimeTo.toUtcIsoString() : undefined,
      process_deadline_from: request.processDeadlineFrom && request.processDeadlineFrom.isValid()
        ? request.processDeadlineFrom.toUtcIsoString()
        : undefined,
      process_deadline_to: request.processDeadlineTo && request.processDeadlineTo.isValid()
        ? request.processDeadlineTo.toUtcIsoString()
        : undefined,
      minimum_identical_item_count: request.minimumIdenticalItemCount,
      return_reason_empty: request.returnReasonEmpty,
      order: Services.createOrderFieldParameter(Keys.toOrderFieldKey, request.orders),
      page_number: request.paging ? request.paging.pageNumber : undefined,
      number_of_items: request.paging ? request.paging.numberOfItems : undefined,
      with_items: request.withItems,
      with_only_id: request.withOnlyId
    };
  }

  private toResourcePackageSize(r?: Order.PackageSize): OrderResource.PackageSize | undefined {
    if (r) {
      if (r.widthInMeter && r.heightInMeter && r.depthInMeter) {
        return {
          width_in_meter: r.widthInMeter.toNumber(),
          height_in_meter: r.heightInMeter.toNumber(),
          depth_in_meter: r.depthInMeter.toNumber()
        };
      }
      return undefined;
    }
    return undefined;
  }

  private toResourceOrderRecipient(r: Order.OrderRecipient): OrderResource.OrderRecipient {
    return {
      name: r.name,
      delivery_address: AddressResource.Mapper.fromPublicPostalAddressOpt(r.deliveryAddress),
      parcel_collection_point: r.parcelCollectionPoint ? {
        id: r.parcelCollectionPoint.id
      } : undefined,
      email_address: Services.emailAddressToString(r.emailAddress),
      phone_number: Services.phoneNumberToString(r.phoneNumber)
    };
  }

  private toPublicOrderRecipient(r?: OrderResource.OrderRecipient): Order.OrderRecipient | undefined {
    if (r) {
      return {
        name: r.name,
        deliveryAddress: AddressResource.Mapper.toPublicPostalAddressOpt(r.delivery_address),
        parcelCollectionPoint: r.parcel_collection_point ? {
          id: r.parcel_collection_point.id,
          name: r.parcel_collection_point.name
        } : undefined,
        emailAddress: Services.toEmailAddress(r.email_address),
        phoneNumber: Services.toPhoneNumber(r.phone_number)
      };
    }
    return undefined;
  }

  private toPublicOrderItemList(resourceList?: OrderResource.OrderItem[]): Order.OrderItem[] | undefined {
    if (resourceList) {
      return resourceList.map((r) => this.toPublicOrderItem(r));
    }
    return undefined;
  }

  private toPublicOrderItem(r: OrderResource.OrderItem): Order.OrderItem {
    return {
      itemId: r.item_id,
      stockItemId: r.stock_item_id,
      amount: r.amount
    };
  }

  private toPublicOrderProcess(r?: OrderResource.OrderProcess): Order.OrderProcess | undefined {
    if (r) {
      return {
        processId: r.process_id,
        name: r.name,
        displayName: r.display_name,
        deadline: Services.toOffsetDateTime(r.deadline),
        state: <ProcessState>r.state,
        workflow: {
          name: r.workflow.name,
          icon: this.iconService.toPublicIcon(r.workflow.icon),
        }
      };
    }
    return undefined;
  }

  private toPublicOrderNote(r?: OrderResource.OrderNote): Order.OrderNote | undefined {
    if (r) {
      return {
        customerNote: r.customer_note,
        extraNote: r.extra_note,
        internalNote: r.internal_note
      };
    }
    return undefined;
  }

}

export namespace Order {

  import ProcessState = LegacyProcess.LegacyProcessState;

  export interface Service {

    query(request: QueryRequest): Observable<QueryResult<Order>>;

    create(request: CreateRequest): Observable<CreateResponse>;

    get(request: GetRequest): Observable<Order>;

    updateData(request: UpdateDataRequest): Observable<UpdateDataResponse>;

    finalize(request: FinalizeRequest): Observable<EmptyMessage>;

    createStockItem(request: CreateStockItemRequest): Observable<CreateStockItemResponse>;

    updateStockItem(request: UpdateStockItemRequest): Observable<EmptyMessage>;

    deleteStockItem(request: DeleteStockItemRequest): Observable<EmptyMessage>;

    exportXls(request: QueryRequest): Observable<DownloadedFile>;

  }

  export interface QueryRequest {
    fields?: Set<string>;
    orderId?: Set<number>;
    ownerUserId?: number;
    externalId?: string;
    orderState?: OrderState;
    orderLogicState?: OrderLogicState;
    processId?: number;
    ownerCompanyName?: string;
    shipmentTrackingNumber?: string;
    shippingState?: string;
    weightInGramFrom?: string;
    weightInGramTo?: string;
    cashOnDeliveryPriceFrom?: string;
    cashOnDeliveryPriceTo?: string;
    recipientName?: string;
    recipientAddress?: string;
    submitTimeFrom?: OffsetDateTime;
    submitTimeTo?: OffsetDateTime;
    returnTimeFrom?: OffsetDateTime;
    returnTimeTo?: OffsetDateTime;
    processDeadlineFrom?: OffsetDateTime;
    processDeadlineTo?: OffsetDateTime;
    minimumIdenticalItemCount?: number;
    returnReasonEmpty?: boolean;
    orders?: Set<UtilOrder<OrderField>>;
    paging?: PagingRequest;
    withItems?: boolean;
    withOnlyId?: boolean;
  }

  export interface GetRequest {
    id: number;
  }

  export interface CancelRequest {
    orderId: number;
  }

  export interface ReturnOrderRequest {
    orderId: number;
    returnReason: string;
  }

  export interface CreateRequest {
    companyId: number;
  }

  export interface UpdateDataRequest {
    id: number;
    externalId: string;
    description?: string;
    weightInGram?: number;
    shippingState?: string;
    insurancePrice?: InsurancePrice;
    cashOnDeliveryPrice: CashOnDeliveryPrice;
    packageSize?: PackageSize;
    deliveryMethodId: number;
    recipient: OrderRecipient;
    shipmentTrackingNumber?: string;
    other: OtherData;
    returnState: OrderReturnState;
    returnReason?: string;
    returnTime?: OffsetDateTime;
    intakeTime?: OffsetDateTime;
    cancelTime?: OffsetDateTime;
    processEndTime?: OffsetDateTime;
    note?: OrderNote;
    version: number;
  }

  export interface UpdateDataResponse {
    version: number;
  }

  export interface CreateResponse {
    orderId: number;
  }

  export interface FinalizeRequest {
    id: number;
  }

  export interface CreateStockItemRequest {
    orderId: number;
    stockItemId: number;
    amount: number;
  }

  export interface CreateStockItemResponse {
    item_id: number;
  }

  export interface UpdateStockItemRequest {
    orderId: number;
    itemId: number;
    stockItemId: number;
    amount: number;
  }

  export interface DeleteStockItemRequest {
    orderId: number;
    itemId: number;
  }

  export interface Order {
    orderId: number;
    externalId: string;
    orderState: OrderState;
    finalState: OrderFinalState;
    logicState: OrderLogicState;
    description?: string;
    ownerUserId: number;
    ownerCompanyId: number;
    weightInGram?: number;
    insurancePrice?: InsurancePrice;
    cashOnDeliveryPrice?: CashOnDeliveryPrice;
    packageSize?: PackageSize;
    deliveryMethod?: {
      id: number;
      name: string;
    };
    recipient?: OrderRecipient;
    items?: OrderItem[];
    shippingState?: string;
    shipmentTrackingNumber?: string;
    creationTime: OffsetDateTime;
    updateTime: OffsetDateTime;
    submitTime?: OffsetDateTime;
    processId?: number;
    other: OtherData;
    returnState: OrderReturnState;
    returnReason?: string;
    returnTime?: OffsetDateTime;
    intakeTime?: OffsetDateTime;
    cancelTime?: OffsetDateTime;
    processEndTime?: OffsetDateTime;
    process?: OrderProcess;
    note?: OrderNote;
    version: number;
    mplLastSyncTime?: OffsetDateTime;
  }

  export interface QueryShippingStates {
    shippingState?: string;
    paging?: PagingRequest;
  }

  export interface OtherData {
    fragile: boolean;
    documentHandling: boolean;
    packageExchange: boolean;
    handoverByItems: boolean;
    deliveryTimeWindow: boolean;
    oversized: boolean;
    inverse: boolean;
  }

  export interface OrderProcess {
    processId: number;
    name?: string;
    displayName: string;
    deadline?: OffsetDateTime;
    state: ProcessState;
    workflow: OrderWorkflow;
  }

  export interface OrderWorkflow {
    name: string;
    icon?: Icon.Icon;
  }

  export interface OrderNote {
    customerNote?: string;
    extraNote?: string;
    internalNote?: string;
  }

  export type OtherDataField =
    'FRAGILE'
    | 'DOCUMENT_HANDLING'
    | 'PACKAGE_EXCHANGE'
    | 'HANDOVER_BY_ITEMS'
    | 'DELIVERY_TIME_WINDOW'
    | 'OVERSIZED'
    | 'INVERSE';

  export class OtherDataFieldObject {
    field: OtherDataField;
    stringKey: string;
  }

  export const otherDataFields: OtherDataFieldObject[] = [
    {field: 'FRAGILE', stringKey: 'ORDER_OTHER_DATA_FRAGILE'},
    {field: 'DOCUMENT_HANDLING', stringKey: 'ORDER_OTHER_DATA_DOCUMENT_HANDLING'},
    {field: 'PACKAGE_EXCHANGE', stringKey: 'ORDER_OTHER_DATA_PACKAGE_EXCHANGE'},
    {field: 'HANDOVER_BY_ITEMS', stringKey: 'ORDER_OTHER_DATA_HANDOVER_BY_ITEMS'},
    {field: 'DELIVERY_TIME_WINDOW', stringKey: 'ORDER_OTHER_DATA_DELIVERY_TIME_WINDOW'},
    {field: 'OVERSIZED', stringKey: 'ORDER_OTHER_DATA_OVERSIZED'},
    {field: 'INVERSE', stringKey: 'ORDER_OTHER_DATA_INVERSE'},
  ];

  export type OrderState =
    'DRAFT'
    | 'SUBMITTED'
    | 'CANCELED'
    | 'UNDER_PROCESSING'
    | 'PROCESSED'
    | 'PROCESS_FAILED'
    | 'SENT_BACK';

  export type OrderFinalState = 'UNKNOWN' | 'DELIVERY_IN_PROGRESS' | 'DELIVERED' | 'DELIVERY_FAILED' | 'RETURNED';

  export type OrderLogicState =
  // Order states                                                                            stronger than FinalState.RETURNED
    'DRAFT' | 'SUBMITTED' | 'CANCELED' | 'UNDER_PROCESSING' | 'PROCESSED' | 'PROCESS_FAILED' | 'SENT_BACK'
    // Final states
    | 'DELIVERY_IN_PROGRESS' | 'DELIVERED' | 'DELIVERY_FAILED' | 'RETURNED';

  export type OrderReturnState = 'NOT_RETURNED' | 'RETURNED_MANUALLY' | 'RETURNED';

  export class OrderReturnStateObject {
    state: OrderReturnState;
    stringKey: string;
    style: string;
    icon?: string;
  }

  export const orderReturnStates: OrderReturnStateObject[] = [
    {state: 'NOT_RETURNED', stringKey: 'ORDER_RETURN_STATE_NOT_RETURNED', style: 'order-state-style-normal'},
    {
      state: 'RETURNED_MANUALLY', stringKey: 'ORDER_RETURN_STATE_RETURNED_MANUALLY',
      style: 'order-state-style-normal', icon: 'icomoon-order-state-returned'
    },
    {
      state: 'RETURNED', stringKey: 'ORDER_RETURN_STATE_RETURNED',
      style: 'order-state-style-normal', icon: 'icomoon-order-state-returned'
    }
  ];

  export interface CashOnDeliveryPrice {
    amount: Decimal;
    currencyCode: string;
  }

  export interface InsurancePrice {
    amount: Decimal;
    currencyCode: string;
  }

  export interface OrderRecipient {
    name: string;
    deliveryAddress?: Address.PostalAddressData;
    parcelCollectionPoint?: {
      id: number;
      name?: string;
    };
    phoneNumber: PhoneNumber;
    emailAddress: EmailAddress;
  }

  export interface OrderItem {
    itemId: number;
    stockItemId: number;
    amount: number;
  }

  export interface PackageSize {
    widthInMeter: Decimal;
    heightInMeter: Decimal;
    depthInMeter: Decimal;
  }

  export enum ProcessValidatedField {
    WORKFLOW_ID,
    NAME,
    EXTERNAL_ID,
  }

  export enum OrderField {
    ID,
    OWNER_COMPANY_ID,
    EXTERNAL_ID,
    SHIPMENT_TRACKING_NUMBER,
    WEIGHT_IN_GRAM,
    CASH_ON_DELIVERY_PRICE,
    RECIPIENT,
    SUBMIT_TIME,
    USER_PERSON_NAME,
  }

  export enum ValidatedField {
    UNKNOWN,
    EXTERNAL_ID,
    INSURANCE_PRICE,
    CASH_ON_DELIVERY_PRICE,
    WEIGHT_IN_GRAM,
    PACKAGE_SIZE_WIDTH,
    PACKAGE_SIZE_HEIGHT,
    PACKAGE_SIZE_DEPTH,
    RECIPIENT_NAME
  }

}

class Keys {

  private static readonly ID = 'order_id';
  private static readonly OWNER_COMPANY_ID = 'owner_company_id';
  private static readonly EXTERNAL_ID = 'external_id';
  private static readonly SHIPMENT_TRACKING_NUMBER = 'shipment_tracking_number';
  private static readonly WEIGHT_IN_GRAM = 'weight_in_gram';
  private static readonly INSURANCE_PRICE = 'insurance_price';
  private static readonly CASH_ON_DELIVERY_PRICE = 'cash_on_delivery_price';
  private static readonly RECIPIENT = 'recipient';
  private static readonly SUBMIT_TIME = 'submit_time';
  private static readonly PACKAGE_SIZE_WIDTH = 'package_size_width';
  private static readonly PACKAGE_SIZE_HEIGHT = 'package_size_height';
  private static readonly PACKAGE_SIZE_DEPTH = 'package_size_depth';
  private static readonly RECIPIENT_NAME = 'recipient_name';
  private static readonly USER_PERSON_NAME = 'owner_user_id';

  private static readonly orderFieldKeyMap: Map<Order.OrderField, string> = Map.of(
    Order.OrderField.ID, Keys.ID,
    Order.OrderField.OWNER_COMPANY_ID, Keys.OWNER_COMPANY_ID,
    Order.OrderField.EXTERNAL_ID, Keys.EXTERNAL_ID,
    Order.OrderField.SHIPMENT_TRACKING_NUMBER, Keys.SHIPMENT_TRACKING_NUMBER,
    Order.OrderField.WEIGHT_IN_GRAM, Keys.WEIGHT_IN_GRAM,
    Order.OrderField.CASH_ON_DELIVERY_PRICE, Keys.CASH_ON_DELIVERY_PRICE,
    Order.OrderField.RECIPIENT, Keys.RECIPIENT,
    Order.OrderField.SUBMIT_TIME, Keys.SUBMIT_TIME,
    Order.OrderField.USER_PERSON_NAME, Keys.USER_PERSON_NAME
  );

  private static readonly keyValidatedFieldMap: Map<string, Order.ValidatedField> = Map.of(
    Keys.EXTERNAL_ID, Order.ValidatedField.EXTERNAL_ID,
    Keys.INSURANCE_PRICE, Order.ValidatedField.INSURANCE_PRICE,
    Keys.CASH_ON_DELIVERY_PRICE, Order.ValidatedField.CASH_ON_DELIVERY_PRICE,
    Keys.WEIGHT_IN_GRAM, Order.ValidatedField.WEIGHT_IN_GRAM,
    Keys.PACKAGE_SIZE_WIDTH, Order.ValidatedField.PACKAGE_SIZE_WIDTH,
    Keys.PACKAGE_SIZE_HEIGHT, Order.ValidatedField.PACKAGE_SIZE_HEIGHT,
    Keys.PACKAGE_SIZE_DEPTH, Order.ValidatedField.PACKAGE_SIZE_DEPTH,
    Keys.RECIPIENT_NAME, Order.ValidatedField.RECIPIENT_NAME
  );

  public static toOrderFieldKey(field: Order.OrderField): string {
    return Keys.orderFieldKeyMap.get(field)!;
  }

  public static toValidatedField(fieldKey: string): Order.ValidatedField {
    return Keys.keyValidatedFieldMap.get(fieldKey, Order.ValidatedField.UNKNOWN);
  }

}

export interface OrderEditFieldErrorMap {
  external_id?: FieldError;
  insurance_price?: FieldError;
  cash_on_delivery_price?: FieldError;
  weight_in_gram?: FieldError;
  package_size_width?: FieldError;
  package_size_height?: FieldError;
  package_size_depth?: FieldError;
  recipient_name?: FieldError;
  recipient_phone_number?: FieldError;
  recipient_email_address?: FieldError;
}
