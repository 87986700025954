import { WorkflowEditorCanvas } from './workflow-editor-canvas';
import { Label } from './shape/label';
import { CanvasDragLogService } from './service/canvas-drag-log-service';
import { DragDropEditPolicy } from './policy/drag-drop-edit-policy';
import { Connection } from './connection/connection';
import { Group } from './shape/group';
import { Workflow } from '../../../../lib/workflow/workflow.service';
import { CanvasSelectionService } from './service/canvas-selection-service';
import { TaskRecordStateMachine } from '../../../../lib/task/task-record-statemachine';
import { OffsetDateTime } from '../../../../lib/util/dates';

export class WorkflowEditorPainter {

  constructor(
    private canvasDragLogService: CanvasDragLogService,
    private canvasSelectionService: CanvasSelectionService,
    private canvas: WorkflowEditorCanvas
  ) {}

  setStateMachine(
    readonly: boolean,
    stateMachine: Workflow.StateMachine,
    taskStateMap?: Map<number, {state: TaskRecordStateMachine.State, creationTime: OffsetDateTime}>) {
    // Adding groups
    const groupMap: Map<number, GroupData> = new Map();
    stateMachine.groups.forEach(g => {
      const group = new Group(readonly);
      group.setId(g.id);
      if (g.name && g.name.length > 0) {
        group.setName(g.name);
      }
      else {
        // todo: from dictionary
        group.setName('Group');
      }
      group.setUserData(g);
      group.installEditPolicy(new DragDropEditPolicy(this.canvasDragLogService));
      this.canvas.add(group, g.location!.topLeftPoint.x, g.location.topLeftPoint.y);
      groupMap.set(g.id, {group: group, labels: []});
    });
    // Adding labels
    stateMachine.tasks.forEach(task => {
      const label = new Label(!task.groupId, readonly);
      label.setId(task.id);
      if (taskStateMap) {
        label.setIcon(this.getTaskIcon(taskStateMap, task.id));
      }
      if (task.location) {
        if (task.task.name.length > task.location.size.width) {
          label.setName(task.task.name.substr(0, task.location.size.width - 1) + '…');
        }
        else {
          label.setName(task.task.name);
        }
      }
      label.setUserData(task);
      label.installEditPolicy(new DragDropEditPolicy(this.canvasDragLogService));
      if (!task.groupId) {
        this.canvas.add(label, task.location!.topLeftPoint.x, task.location!.topLeftPoint.y);
      }
      else {
        groupMap.get(task.groupId)!.labels.push({label: label, index: task.groupIndex!});
      }
    });
    // Adding labels inside groups
    groupMap.forEach((value, key, map) => {
      value.labels = value.labels.sort((a, b) => a.index - b.index);
    });
    groupMap.forEach((value, key, map) => {
      const group = value.group.getUserData();
      value.labels.forEach(label => {
        const task = label.label.getUserData();
        if (task.task.name.length > Math.max(group.location.size.width, 15)) {
          label.label.setName(task.task.name.substr(0, Math.max(group.location.size.width, 15) - 1) + '…');
        }
        else {
          label.label.setName(task.task.name);
        }
        value.group.addItem(label.label);
      });
    });
    // Setting entry point id
    this.canvas.getStartingCircle().setId(stateMachine.entryPoint.id);
    // Adding connections
    stateMachine.transitions.forEach(transition => {
      const connection = new Connection(readonly);
      const outputObject = this.canvas.getFigure(transition.outputObjectId);
      let outputPort;
      if (outputObject.id === this.canvas.getStartingCircle().getId()) {
        outputPort = this.canvas.getStartingPort();
      }
      else {
        outputPort = outputObject.getOutputPort();
      }
      connection.setId(transition.id);
      connection.setSource(outputPort);
      connection.setTarget(this.canvas.getFigure(transition.inputObjectId).getInputPort());
      connection.setUserData({
        addedByPainter: true,
        transition: transition
      });
      if (transition.points.length > 2 && transition.points.length % 2 === 0) {
        // @ts-ignore
        connection.setVertices(transition.points);
      }
      this.canvas.add(connection);
    });
  }

  private getTaskIcon(
    map: Map<number, {state: TaskRecordStateMachine.State, creationTime: OffsetDateTime}>,
    id: number) {
    const stateObject = map.get(id);
    if (stateObject) {
      return this.getTaskRecordStateCorrespondingIconPath(stateObject.state);
    }
    return '../../../../../assets/img/workflow/state_6.svg';
  }

  private getTaskRecordStateCorrespondingIconPath(state: TaskRecordStateMachine.State) {
    switch (state) {
      case 'NEW':
      case 'OPEN':
        return '../../../../../assets/img/workflow/state_5.svg';
      case 'IN_PROGRESS':
        return '../../../../../assets/img/workflow/state_1.svg';
      case 'PAUSED':
        return '../../../../../assets/img/workflow/state_7.svg';
      case 'SUBMITTED':
      case 'FINISHED':
      case 'NEW_FINISHED':
      case 'PENDING_APPROVAL':
      case 'ARCHIVED':
      case 'EXPORTED':
        return '../../../../../assets/img/workflow/state_2.svg';
      case 'REJECTED':
      case 'RECALLED':
        return '../../../../../assets/img/workflow/state_4.svg';
      case 'REVERTED':
        return '../../../../../assets/img/workflow/state_3.svg';
    }
  }

}

interface GroupData {
  group: Group;
  labels: LabelData[];
}

interface LabelData {
  label: Label;
  index: number;
}
