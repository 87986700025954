/* eslint-disable */
import { Injectable } from '@angular/core';
import { Observable, Observer } from 'rxjs';
import { Order, OrderType } from '../util/services';
import { UiConstants } from '../../util/core-utils';
import { MemStorage } from '../util/mem-storage';
import { Token } from './token.service';
import { LoggedInUserStorage } from '../util/storages';

/* eslint-enable */

@Injectable()
export class TokenSearchService {

  constructor() {
  }

  getSearchData(request: TokenSearch.SearchDataGetRequest): Observable<TokenSearch.SearchDataResult> {
    const storage = this.createStorage();
    return Observable.create((observer: Observer<TokenSearch.SearchDataResult>) => {
      try {
        observer.next(storage.read(request));
      } catch (e) {
        observer.error(e);
      }
      observer.complete();
      return function () {
      };
    });
  }

  setSearchData(request: TokenSearch.SearchDataSetRequest): Observable<TokenSearch.SearchDataResult> {
    const storage = this.createStorage();
    return Observable.create((observer: Observer<TokenSearch.SearchDataResult>) => {
      try {
        observer.next(storage.save(request));
      } catch (e) {
        observer.error(e);
      }
      observer.complete();
      return function () {
      };
    });
  }

  resetSearchData(request: TokenSearch.SearchDataGetRequest): Observable<TokenSearch.SearchDataResult> {
    const storage = this.createStorage();
    return Observable.create((observer: Observer<TokenSearch.SearchDataResult>) => {
      try {
        observer.next(storage.reset(request));
      } catch (e) {
        observer.error(e);
      }
      observer.complete();
      return function () {
      };
    });
  }

  private createStorage(): TokenSearch.Storage {
    return new TokenSearch.Storage();
  }
}

export namespace TokenSearch {

  import TokenType = Token.TokenType;

  export interface SearchDataGetRequest {
  }

  export interface SearchDataSetRequest {
    searchData: SearchData;
  }

  export interface SearchDataResult {
    searchData: SearchData;
  }

  export interface SearchData {
    userProfilePersonName: string;
    mobileApplicationName: string;
    type: TokenType;
    itemsPerPage: number;
    pageNumber: number;
    order: Order<Token.OrderField>;
  }

  interface StoredSearchRawDataBase {
    version: number;
  }

  interface StoredSearchRawData extends StoredSearchRawDataBase {
    userProfilePersonName: string;
    mobileApplicationName: string;
    type: TokenType;
    itemsPerPage: number;
    pageNumber: number;
    order: Order<Token.OrderField>;
  }

  export class Storage {

    /**
     * Increment this version if you change the SearchData interface.
     */
    private static readonly VERSION: number = 1;

    private static readonly DEFAULT_SEARCH_DATA: SearchData = {
      itemsPerPage: UiConstants.pageNumbers[0],
      pageNumber: 1,
      order: {
        field: Token.OrderField.USER_PROFILE_PERSON_NAME,
        type: OrderType.DESC
      },
      userProfilePersonName: '',
      mobileApplicationName: '',
      type: 'DEFAULT'
    };

    constructor() {
    }

    public read(request: SearchDataGetRequest): SearchDataResult {
      let rawBase: StoredSearchRawDataBase;
      try {
        const key = this.generateKey();
        const json = MemStorage.getItem(key);
        if (!json) {
          return this.createDefaultResult();
        }
        rawBase = JSON.parse(json);
      } catch (error) {
        return this.createDefaultResult();
      }
      if (Storage.VERSION === rawBase.version) {
        const raw: StoredSearchRawData = <StoredSearchRawData>rawBase;
        return this.fromRaw(raw);
      }
      return this.createDefaultResult();
    }

    public reset(request: SearchDataGetRequest): SearchDataResult {
      return this.save({
        searchData: Storage.DEFAULT_SEARCH_DATA,
      });
    }

    public save(request: SearchDataSetRequest): SearchDataResult {
      const key = this.generateKey();
      const raw = this.toRaw(request.searchData);
      const json = JSON.stringify(raw);
      MemStorage.setItem(key, json);
      return this.read(request);
    }

    private generateKey(): string {
      return 'UserId(' + LoggedInUserStorage.getInstance().getUserId() + ')-Search-Token';
    }

    private createDefaultResult(): SearchDataResult {
      return {
        searchData: Storage.DEFAULT_SEARCH_DATA,
      };
    }

    private fromRaw(data: StoredSearchRawData): SearchDataResult {
      return {
        searchData: {
          itemsPerPage: data.itemsPerPage,
          pageNumber: data.pageNumber,
          order: data.order,
          userProfilePersonName: data.userProfilePersonName,
          mobileApplicationName: data.mobileApplicationName,
          type: data.type
        }
      };
    }

    private toRaw(data: SearchData): StoredSearchRawData {
      return {
        version: Storage.VERSION,
        itemsPerPage: data.itemsPerPage,
        pageNumber: data.pageNumber,
        order: data.order,
        userProfilePersonName: data.userProfilePersonName,
        mobileApplicationName: data.mobileApplicationName,
        type: data.type
      };
    }

  }
}
