<div class="card ">

  <div class="card-header">
    {{'STOCK_RECORDS'|translate}}
    <div class="card-actions">
      <a class="btn-setting table-header-action-button d-flex align-items-center px-1 w-auto font-weight-normal"
         title="{{'COMMON_BUTTON_EXPORT' | translate}}" (click)="onExportClicked()">
        <i class="icomoon pr-1 icomoon-csv-export"></i>
        <span class="breadcrumb-button-text-visible">{{'COMMON_BUTTON_EXPORT' | translate}}</span>
      </a>
      <a class="btn-setting table-header-action-button d-flex align-items-center px-1 w-auto font-weight-normal"
         (click)="toggleLotMode()"
         title="{{'STOCK_RECORD_LIST_LOT_MODE' | translate}}">
        <i class="icomoon pr-1 icomoon-bulk-operation"></i>
        <span class="breadcrumb-button-text-visible">{{'STOCK_RECORD_LIST_LOT_MODE' | translate}}</span>
      </a>
      <a class="btn-setting cursor-pointer" (click)="toggleSearch()"
         title="{{'COMMON_SHOW_SEARCH_HINT' | translate}}">
        <i class="icomoon icomoon-search"></i>
      </a>
      <a class="btn-setting cursor-pointer" (click)="openNewStockRecordTypeSelectorDialog()"
         title="{{'COMMON_BUTTON_ADD' | translate}}"
         *ngIf="!readonly && rightModel.stockRecordCreate.hasRight()">
        <i class="icomoon icomoon-add"></i>
      </a>
    </div> <!-- ./card-actions end -->
  </div> <!-- ./card header end -->
  <div class="card-body border-bottom" *ngIf="showSearch">
    <div class="row form-group">
      <div class="col-md-3">
        <label class="search-label">{{'COMMON_NAME' | translate}}</label>
        <input type="text" class="form-control" placeholder="{{'COMMON_NAME' | translate}}"
               maxlength="{{UiConstants.maxInputLongLength}}"
               (keyup.enter)="onSearchClicked()"
               [(ngModel)]="searchModel.stockItemName" name="stockItemName" id="stockItemName" #stockItemName="ngModel">
      </div>
      <div class="col-md-3">
        <label class="search-label">{{'STOCK_ITEM_TABLE_HEADER_EXTERNAL_ID' | translate}}</label>
        <input type="text" class="form-control" placeholder="{{'STOCK_ITEM_TABLE_HEADER_EXTERNAL_ID' | translate}}"
               maxlength="{{UiConstants.maximumVarcharLength}}"
               (keyup.enter)="onSearchClicked()"
               [(ngModel)]="searchModel.stockItemExternalId" name="stockItemExternalId" id="stockItemExternalId"
               #stockItemExternalId="ngModel">
      </div>
      <div class="col-md-3">
        <label class="search-label">{{'STOCK_ITEM_TABLE_HEADER_PRODUCT_CODE' | translate}}</label>
        <input type="text" class="form-control" placeholder="{{'STOCK_ITEM_TABLE_HEADER_PRODUCT_CODE' | translate}}"
               maxlength="{{UiConstants.maximumVarcharLength}}"
               (keyup.enter)="onSearchClicked()"
               [(ngModel)]="searchModel.stockItemProductCode" name="stockItemProductCode" id="stockItemProductCode"
               #stockItemProductCode="ngModel">
      </div>
      <div class="col-md-3">
        <label class="search-label">{{'STOCK_ITEM_TABLE_HEADER_SERIAL_CODE' | translate}}</label>
        <input type="text" class="form-control" placeholder="{{'STOCK_ITEM_TABLE_HEADER_SERIAL_CODE' | translate}}"
               maxlength="{{UiConstants.maximumVarcharLength}}"
               (keyup.enter)="onSearchClicked()"
               [(ngModel)]="searchModel.stockItemSerialCode" name="stockItemSerialCode" id="stockItemSerialCode"
               #stockItemSerialCode="ngModel">
      </div>
      <div class="col-md-3">
        <label class="search-label">{{'STOCK_RECORD_SEARCH_AMOUNT_FROM' | translate}}</label>
        <input type="text" class="form-control" placeholder="{{'STOCK_RECORD_SEARCH_AMOUNT_FROM' | translate}}"
               maxlength="{{UiConstants.maximumVarcharLength}}"
               (keyup.enter)="onSearchClicked()"
               [textMask]="{mask: InputMask.NATURAL_FLOAT_WITH_THOUSAND_SEPARATOR, guide: true}"
               [(ngModel)]="searchModel.amountFrom" name="amountFrom" id="amountFrom" #amountFrom="ngModel">
      </div>
      <div class="col-md-3">
        <label class="search-label">{{'STOCK_ITEM_TABLE_HEADER_TYPE' | translate}}</label>
        <select class="form-control"
                [(ngModel)]="searchModel.stockItemType"
                [ngModelOptions]="{standalone: true}">
          <option [ngValue]="null">{{'COMMON_VALUE_UNSELECTED' | translate}}</option>
          <option *ngFor="let item of stockItemTypes" [ngValue]="item.id">{{item.text}}</option>
        </select>
      </div>
      <div class="col-md-3">
        <label class="search-label">{{'STOCK_RECORD_SEARCH_AMOUNT_TO' | translate}}</label>
        <input type="text" class="form-control" placeholder="{{'STOCK_RECORD_SEARCH_AMOUNT_TO' | translate}}"
               maxlength="{{UiConstants.maximumVarcharLength}}"
               (keyup.enter)="onSearchClicked()"
               [textMask]="{mask: InputMask.NATURAL_FLOAT_WITH_THOUSAND_SEPARATOR, guide: true}"
               [(ngModel)]="searchModel.amountTo" name="amountTo" id="amountTo" #amountTo="ngModel">
      </div>
      <div class="col-md-3">
        <label class="search-label">{{'STOCK_RECORD_SEARCH_CATEGORIES' | translate}}</label>
        <angular2-multiselect [data]="stockItemCategories" [settings]="dropdownSettings"
                              class="form-control"
                              [(ngModel)]="searchModel.stockItemCategories"
                              (onRemoteSearch)="loadStockItemCategories($event.target.value)">
        </angular2-multiselect>
      </div>
    </div>
    <div class="col-md-12 d-flex justify-content-md-end align-items-end px-0">
      <div class="btn-group" role="group">
        <button type="button" (click)="onSearchReset()" class="btn btn-outline-primary search-button mr-1">
          {{'COMMON_BUTTON_SEARCH_DELETE' | translate}}
        </button>
        <button type="button" (click)="onSearchClicked()" class="btn btn-primary search-button">
          {{'COMMON_BUTTON_SEARCH' | translate}}
        </button>
      </div>
    </div>
  </div><!-- /.card-body-->

  <div class="card-body">
    <table class="table table-striped table-bordered">
      <thead>
      <tr>
        <th class="table-sorter-header hidden-xs-down">
          <app-table-field-sorter [orderField]="StockRecord.OrderField.ID"
                                  [orderType]="queryModel.getOrderType(StockRecord.OrderField.ID)"
                                  (orderChange)="orderBy($event)"
                                  [text]="'COMMON_TABLE_HEADER_ID' | translate"></app-table-field-sorter>
        </th>
        <th class="table-sorter-header long">
          <app-table-field-sorter [orderField]="StockRecord.OrderField.STOCK_ITEM_NAME"
                                  [orderType]="queryModel.getOrderType(StockRecord.OrderField.STOCK_ITEM_NAME)"
                                  (orderChange)="orderBy($event)"
                                  [text]="'COMMON_NAME' | translate"></app-table-field-sorter>
        </th>
        <th class="table-sorter-header hidden-xs-down">
          <app-table-field-sorter [orderField]="StockRecord.OrderField.AMOUNT"
                                  [orderType]="queryModel.getOrderType(StockRecord.OrderField.AMOUNT)"
                                  (orderChange)="orderBy($event)"
                                  [text]="'STOCK_RECORD_AMOUNT' | translate"></app-table-field-sorter>
        </th>
        <th class="table-sorter-header hidden-xs-down">
          <app-table-sorter-no-op [text]="'STOCK_RECORD_LOT_INTAKE_PRICE_AVG' | translate"></app-table-sorter-no-op>
        </th>
        <th class="table-sorter-header hidden-xs-down">
          <app-table-sorter-no-op [text]="'STOCK_RECORD_LOT_INTAKE_PRICE_SUM' | translate"></app-table-sorter-no-op>
        </th>
        <th class="w-table-options-menu">
          <app-table-sorter-no-op></app-table-sorter-no-op>
        </th>
      </tr>
      <tbody>
      <ng-container *ngFor="let stockRecord of stockRecordList | paginate: {
                                                                id: pagingId,
                                                                itemsPerPage: queryModel.itemsPerPage,
                                                                currentPage: queryModel.currentPage,
                                                                totalItems: queryModel.currentNumberOfItems }">
        <tr>
          <td class="responsive-table-column fit hidden-xs-down align-middle">{{stockRecord.stock_record_id}}</td>
          <td class="responsive-table-column long">
            <div class="d-flex align-items-center">
              <div class="stock-item-type-icon-container mr-1">
                <i class="icomoon {{getStockItemTypeIconRes(stockRecord.stock_item.type)}}"
                   popover="{{getStockItemTypeText(stockRecord.stock_item.type)}}" triggers="mouseenter:mouseleave">
                </i>
              </div>
              <div>
                <a class="id-text cursor-pointer"
                   href="#" onclick="return false"
                   uiSref="Admin.StockEditWithStockItemDetail"
                   [uiParams]="{ stockId: stockId, id: stockRecord.stock_item.stock_item_id }"
                   title="{{'COMMON_BUTTON_DETAILS' | translate}}">
                  {{stockRecord.stock_item.name}}
                </a>
                <div class="text-truncate table-text-subtitle">
                  {{stockRecord.stock_item.product_code}}
                </div>
              </div>
            </div>
          </td>
          <td class="responsive-table-column hidden-xs-down align-middle">
            <ng-template #reservedAmountPopover>
              <i class="icomoon icomoon-reserved popover-icon"></i>
              {{'STOCK_ITEM_RESERVED_TITLE' | translate }}
              <br>
              <span
                class="text-muted">{{'STOCK_ITEM_RESERVED_MESSAGE' | translate }}</span> {{stockRecord.stock_item.reserved_amount | formattedNumber}} {{stockRecord.stock_item.unit}}
            </ng-template>
            <ng-container *ngIf="stockRecord.amount">
              {{stockRecord.amount | formattedNumber}} {{stockRecord.stock_item.unit}}
              <div *ngIf="stockRecord.stock_item.package_data"
                   class="table-text-subtitle">{{StockItemUtils.convertToPackageStr(stockRecord.amount, stockRecord.stock_item.package_data)}}</div>
              <i class="icomoon icomoon-reserved popover-icon"
                 *ngIf="stockRecord.stock_item.reserved_amount"
                 [popover]="reservedAmountPopover" triggers="mouseenter:mouseleave">
              </i>

            </ng-container>
          </td>
          <td class="responsive-table-column hidden-xs-down align-middle">
            {{stockRecord.intake_net_price_avg | formattedNumber}} {{getCurrencyName(stockRecord.stock_item.unit_price.currency_code)}}
          </td>
          <td class="responsive-table-column hidden-xs-down align-middle">
            {{stockRecord.intake_net_price_sum | formattedNumber}} {{getCurrencyName(stockRecord.stock_item.unit_price.currency_code)}}
          </td>
          <td class="align-middle">
            <app-table-options-menu>
              <app-dropdown-item
                [iconClass]="'icomoon-detail'"
                [titleStringKey]="'COMMON_BUTTON_DETAILS'"
                class="cursor-pointer"
                uiSref="Admin.StockEditWithStockItemDetail"
                [uiParams]="{ stockId: stockId, id: stockRecord.stock_item.stock_item_id }">
              </app-dropdown-item>
              <app-dropdown-item
                *ngIf="!readonly && rightModel.stockRecordUpdate.hasRight() && stockRecord.stock_item.type !== 'SERVICE' && stockRecord.amount !== undefined && (stockRecord.lots && stockRecord.lots.length === 1)"
                [iconClass]="'icomoon-modify'"
                [titleStringKey]="'COMMON_BUTTON_EDIT'"
                class="cursor-pointer"
                (click)="onEditClicked(stockRecord)">
              </app-dropdown-item>
            </app-table-options-menu>
          </td>
        </tr>
        <tr *ngIf="stockRecord.lots && stockRecord.lots.length > 1">
          <td colspan="100%" class="stock-record-lot-shadow">
            <app-stock-stock-record-lot-list
              [lots]="stockRecord.lots!"
              [stockRecord]="stockRecord"
              [currencies]="currencies"
              [readonly]="readonly || !rightModel.stockRecordUpdate.hasRight()"
              (onEdit)="onLotEdited($event)">
            </app-stock-stock-record-lot-list>
          </td>
        </tr>
      </ng-container>
      </tbody>
      <tfoot>
      <tr>
        <th class="table-sorter-header hidden-xs-down">
          <app-table-field-sorter [orderField]="StockRecord.OrderField.ID"
                                  [orderType]="queryModel.getOrderType(StockRecord.OrderField.ID)"
                                  (orderChange)="orderBy($event)"
                                  [text]="'COMMON_TABLE_HEADER_ID' | translate"></app-table-field-sorter>
        </th>
        <th class="table-sorter-header long">
          <app-table-field-sorter [orderField]="StockRecord.OrderField.STOCK_ITEM_NAME"
                                  [orderType]="queryModel.getOrderType(StockRecord.OrderField.STOCK_ITEM_NAME)"
                                  (orderChange)="orderBy($event)"
                                  [text]="'COMMON_NAME' | translate"></app-table-field-sorter>
        </th>
        <th class="table-sorter-header hidden-xs-down">
          <app-table-field-sorter [orderField]="StockRecord.OrderField.AMOUNT"
                                  [orderType]="queryModel.getOrderType(StockRecord.OrderField.AMOUNT)"
                                  (orderChange)="orderBy($event)"
                                  [text]="'STOCK_RECORD_AMOUNT' | translate"></app-table-field-sorter>
        </th>
        <th class="table-sorter-header hidden-xs-down">
          <app-table-sorter-no-op [text]="'STOCK_RECORD_LOT_INTAKE_PRICE_AVG' | translate"></app-table-sorter-no-op>
        </th>
        <th class="table-sorter-header hidden-xs-down">
          <app-table-sorter-no-op [text]="'STOCK_RECORD_LOT_INTAKE_PRICE_SUM' | translate"></app-table-sorter-no-op>
        </th>
        <th class="w-table-options-menu">
          <app-table-sorter-no-op></app-table-sorter-no-op>
        </th>
      </tr>
      </tfoot>
    </table>
    <app-table-paging
      [id]="pagingId"
      [currentNumberOfItems]="queryModel.currentNumberOfItems"
      [totalNumberOfItems]="queryModel.totalNumberOfItems"
      [itemsPerPage]="queryModel.itemsPerPage"
      (pageChange)="pageChanged($event)"
      (itemsPerPageChange)="itemsPerPageChanged($event)">
    </app-table-paging>
  </div>
</div>
