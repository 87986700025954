/* eslint-disable */
import { Observable, Observer } from 'rxjs';
import { EnvironmentConfigResourceService } from './environment-config-resource.service';
import { Injectable } from '@angular/core';
import { EmptyMessage } from '../util/messages';
import { Set } from 'immutable';
/* eslint-enable */

@Injectable()
export class EnvironmentConfigService implements EnvironmentConfig.Service {

  constructor(private resourceService: EnvironmentConfigResourceService) {
  }

  get(request: EnvironmentConfig.GetRequest): Observable<Map<string, string>> {
    return Observable.create((observer: Observer<Map<string, string>>) => {
      const resourceRequest: EnvironmentConfig.GetRequest = {};
      return this.resourceService.get(resourceRequest).subscribe(
        (result: Map<string, string>) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  getReadonlyKeys(request: EnvironmentConfig.GetRequest): Observable<Set<string>> {
    return Observable.create((observer: Observer<Set<string>>) => {
      const resourceRequest: EnvironmentConfig.GetRequest = {};
      return this.resourceService.getReadonlyKeys(resourceRequest).subscribe(
        (result: Set<string>) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  update(request: Map<string, string>): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      return this.resourceService.update(request).subscribe(
        (result: EmptyMessage) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }
}

export namespace EnvironmentConfig {

  export interface Service {

    get(request: GetRequest): Observable<Map<string, string>>;

    update(request: Map<string, string>): Observable<EmptyMessage>;

  }

  export interface GetRequest {
  }

}
