/* eslint-disable */
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FileItem, FileUploader, ParsedResponseHeaders } from 'ng2-file-upload';
import {
  DocumentFileCreateModel,
  DocumentFileFieldErrorMap,
  DocumentGroupItem,
  LanguageItem
} from '../../../../util/document/document-file-utils';
import { TranslateService } from '@ngx-translate/core';
import { UploadErrorLocalizer } from '../../../../util/upload-error-localizer';
import { LanguageService } from '../../../../lib/language.service';
import { StateName } from '../../../../app.state-names';
import { Arrays } from '../../../../lib/util/arrays';
import { StringKey } from '../../../../app.string-keys';
import { Numbers } from '../../../../lib/util/numbers';
import { DocumentFileService } from '../../../../lib/document/document-file.service';
import { DocumentGroupService } from '../../../../lib/document/document-group.service';
import { UIRouter } from '@uirouter/angular';
import { BreadcrumbParent } from '../../../../shared/breadcrumb/breadcrumb/breadcrumb.component';
import { DocumentGroup } from '../../../../lib/document/document-group.service';
import { MultiselectOptionItem, UiConstants } from '../../../../util/core-utils';
import { ResourceQueryResult } from '../../../../lib/util/services';
import { EmptyMessage } from '../../../../lib/util/messages';
import { FieldError, FieldErrors, ObservableErrorResourceParser } from '../../../../lib/util/errors';
import { FileUploaderUtil } from '../../../../util/file-uploader-util';
import { Strings } from '../../../../lib/util/strings';
import { Angular2Multiselects } from '../../../../util/multiselect';
import { UploadResponse } from '../../../../shared/file-upload/flat/file-upload.component';
import { UploadMimeType } from '../../../../shared/file-upload/upload-mime-type';
import { RightModel } from '../../../../app.rights';
import { RightResolver, RightService } from '../../../../lib/right.service';
/* eslint-enable */

@Component({
  selector: 'app-document-file-create',
  templateUrl: 'document-file-create.component.html',
  styleUrls: ['document-file-create.component.scss']
})
export class DocumentFileCreateComponent implements OnInit, AfterViewInit {

  UploadMimeType = UploadMimeType;

  model: DocumentFileCreateModel;
  fileUploadPath: string = '/documents/files/upload';
  thumbnailUploadPath: string;
  uploadGlobalErrors: string;
  uploadCollectionFieldErrors: string;
  fieldErrors: DocumentFileFieldErrorMap;
  thumbnailSuccessfullyUploaded: boolean = false;
  documentGroupList: MultiselectOptionItem<number>[] = [];
  fileDocumentId?: number;
  languageItems: LanguageItem[] = [];
  breadcrumbParents: BreadcrumbParent[] = [];
  breadcrumbSelf: string;
  compactSidebar: boolean = document.querySelector('body')!.classList.contains('sidebar-compact');
  dropdownSettings: Angular2Multiselects.Settings;

  rightModel: RightModel = RightModel.empty();

  constructor(
    private languageService: LanguageService,
    private translateService: TranslateService,
    private documentGroupService: DocumentGroupService,
    private fileDocumentService: DocumentFileService,
    private fileUploaderUtil: FileUploaderUtil,
    private rightService: RightService,
    private uiRouter: UIRouter) {
    this.fieldErrors = {};
    this.model = new DocumentFileCreateModel();
  }

  ngOnInit() {
    this.translateService.get('DOCUMENT_PANEL_HEADING_ADD').subscribe(
      (result: string) => {
        this.breadcrumbSelf = result;
      }
    );
    this.translateService.get('COMMON_DOCUMENTS').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.DOCUMENT_DASHBOARD});
      }
    );
    this.translateService.get('MENU_NAVBAR_DOCUMENT_FILE').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.DOCUMENT_FILE_LIST});
      }
    );
    this.initDropdownSettings();
    this.loadRightModels();
  }

  initDropdownSettings() {
    this.dropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(true)
      .enableSearchFilter(true)
      .remoteSearch(true)
      .enableCheckAll(false)
      .build();
  }

  private loadRightModels() {
    this.rightService.getRightResolver().subscribe(
      (resolver: RightResolver) => {
        this.rightModel = RightModel.of(resolver);
      }
    );
  }

  onThumbnailUploaded() {
    this.thumbnailSuccessfullyUploaded = true;
  }

  private loadFileDocumentData() {
    this.fileDocumentService.get({
      id: this.fileDocumentId!
    }).subscribe(result => {
      this.model.name = result.name;
      this.model.external_id = result.external_id;
    });
  }

  private createDefaultLanguageCode(): LanguageItem {
    const def = new LanguageItem();
    def.id = null;
    def.text = '';
    this.translateService.get(StringKey.COMMON_VALUE_UNSELECTED).subscribe(
      (text: string) => {
        def.text = text;
      }
    );
    return def;
  }

  loadLanguageCodes() {
    this.languageService.query({}).subscribe((result: ResourceQueryResult<string>) => {
      const def = this.createDefaultLanguageCode();
      this.model.language_code = def;
      this.languageItems.push(def);
      Arrays.iterateByIndex(result.items, language => {
        const languageItem = new LanguageItem();
        languageItem.id = language;
        languageItem.text = language;
        this.languageItems.push(languageItem);
      });
    });
  }

  onUploadSuccess(response: UploadResponse) {
    if (response.status === 200) {
      const responseObject = JSON.parse(response.response);
      this.fileDocumentId = Numbers.undefinedOrInt(responseObject.id);
      // Thumbnail
      this.thumbnailUploadPath = '/documents/files/' + this.fileDocumentId + '/update-thumbnail';
      // Name and eternalId
      this.loadFileDocumentData();
    }
  }

  ngAfterViewInit() {
    this.loadLanguageCodes();
    this.loadDocumentGroupList();
  }

  loadDocumentGroupList(q?: string) {
    this.documentGroupService.query({
      name: q ? Strings.undefinedOrNonEmpty(q) : undefined,
      order: '+name',
      disabled: false,
      page_number: 1,
      number_of_items: UiConstants.autocompletePageSize,
      no_progress_bar: true
    }).subscribe((result: ResourceQueryResult<DocumentGroup>) => {
      this.documentGroupList = result.items.map(g => ({id: g.id!, itemName: g.name}));
    });
  }

  update() {
    if (!this.fileDocumentId) {
      return;
    }

    const document_group_id: number | null = this.model.document_group.length === 1 ? this.model.document_group[0].id : null;
    const languageCodeId = (this.model.language_code) ? this.model.language_code.id : null;

    this.fileDocumentService.update({
      id: this.fileDocumentId,
      name: this.model.name,
      external_id: this.model.external_id,
      document_group_id: document_group_id ? document_group_id : undefined,
      language_code: languageCodeId ? languageCodeId : undefined,
      description: this.model.description
    }).subscribe(
      (response: EmptyMessage) => {
        this.uiRouter.stateService.go(StateName.DOCUMENT_FILE_LIST);
      },
      (error: any) => {
        const res = ObservableErrorResourceParser.parseError(error);
        this.fieldErrors = ObservableErrorResourceParser.extractFieldErrors(res);
      }
    );
  }

  back() {
    window.history.back();
  }

  getTextMaximumLength(): number {
    return UiConstants.maximumVarcharLength;
  }

  removeFieldError(fieldError?: FieldError) {
    FieldErrors.remove(this.fieldErrors, fieldError);
  }

  delete() {
    this.fileDocumentService.delete({
      id: this.fileDocumentId!
    }).subscribe(
      (res: EmptyMessage) => {
        this.uiRouter.stateService.go(StateName.DOCUMENT_FILE_LIST);
      }
    );
  }

}
