import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Address, AddressModel, AddressResource } from '../../lib/address';
import { TranslateService } from '@ngx-translate/core';
import { ConfigurationService } from '../../lib/core-ext/configuration.service';
import { Country, CountryService } from '../../lib/country.service';
import { StringKey } from '../../app.string-keys';
import { QueryResult } from '../../lib/util/services';
import { GoogleMapsLoaderUtil } from '../../util/google/google-maps-loader.util';
import { Decimal } from 'decimal.js';
import { PlaceService } from '../../lib/place/place.service';
import { List } from 'immutable';
import { TransportTask } from '../../lib/transport/transport-task/transport-task.service';
import TransportTaskState = TransportTask.TransportTaskState;

@Component({
  selector: 'app-place-update',
  templateUrl: './place-update.component.html',
  styleUrls: ['./place-update.component.scss']
})
export class PlaceUpdateComponent implements OnInit, AfterViewInit {

  @ViewChild('gmap', { static: true }) gmapElement: any;
  map: google.maps.Map;
  marker?: google.maps.Marker;


  @Input()
  enableCoordinateEdit: boolean = true;
  @Input()
  enablePostalAddressEdit: boolean = false;

  @Input()
  state: TransportTaskState;

  @Output()
  onModify: EventEmitter<void> = new EventEmitter<void>();

  private readonly _m: PlaceEditModel;

  private _modified: boolean = false;
  private countries: List<AddressModel.CountryItem>;

  constructor(private translateService: TranslateService,
              private configService: ConfigurationService,
              private countryService: CountryService,
              private placeService: PlaceService) {
    this._m = new PlaceEditModel();
  }

  ngOnInit(): void {
  }

  initMap(completion: () => void) {
    if (!this.map) {
      if (GoogleMapsLoaderUtil.didMapLoad()) {
        this.createMap();
        completion();
      }
      else {
        GoogleMapsLoaderUtil.subscribe(() => {
          this.createMap();
          completion();
        });
      }
    }
    else {
      completion();
    }

  }

  createMap() {
    const mapProp = {
      center: new google.maps.LatLng(47.49801, 19.03991),
      zoom: 15,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      streetViewControl: false,
      mapTypeControl: false
    };
    this.map = new google.maps.Map(this.gmapElement.nativeElement, mapProp);
  }

  ngAfterViewInit(): void {
  }

  getPostalAddressText() {
    return Address.PostalAddressMapper.toString(this.editModel.postalAddress, this.configService.getPostalAddressFormat());
  }

  @Input()
  set place(p: Address.Place | undefined) {
    if (p) {
      if (!this.countries) {
        this.countryService.query({}).subscribe((result: QueryResult<Country.Country>) => {
          this.countries = AddressModel.CountryItem.fromCountryList(result.items);
          this.loadModel(p);
        });
      }
      else {
        this.loadModel(p);
      }
    }
  }

  private loadModel(p: Address.Place) {
    const newPlace = p && this._m.id !== p.id;
    this.editModel.loadCountries(this.countries, this.configService.getDefaultSelectedCountryCode());
    this.editModel.loadData(p);
    if (newPlace) {
      this.initMap(() => {
        this.setMarker();
      })
    }

  }

  get editModel() {
    return this._m;
  }

  private setMarker() {
    if (this.map) {
      let latlng: google.maps.LatLng;
      if (this.editModel.coordinate) {
        latlng = new google.maps.LatLng(this.editModel.coordinate.latitude.toNumber(), this.editModel.coordinate.longitude.toNumber());
      }
      else {
        latlng = new google.maps.LatLng(47.49801, 19.03991);
      }
      if (!this.marker) {
        this.marker = new google.maps.Marker({
          position: latlng,
          map: this.map,
          icon: {
            url: '../../../../assets/img/poi/ic_map_poi_active.svg',
          }
        });
      }
      else {
        this.marker.setPosition(latlng);
      }
      this.marker.addListener('dragend', () => {
        if (this.enableCoordinateEdit) {
          this._modified = true;
          this.editModel.geocodeStatus = AddressResource.geocodeStatuses.find(s => s.status === 'MANUAL')!;
          const newPos = this.marker!.getPosition()!;
          this.editModel.coordinate = {
            latitude: new Decimal(newPos.lat()),
            longitude: new Decimal(newPos.lng()),
          }
        }
      });
      this.marker.setDraggable(this.enableCoordinateEdit);
      this.map.setCenter(latlng);
    }
  }

  get modified() {
    return this._modified;
  }

  cancelModification() {
    this._modified = false;
    this.editModel.reset();
  }

  saveModification() {
    this.placeService.update(this.editModel.id, {
      coordinate: this.editModel.coordinate,
      name: this.editModel.name,
      postalAddress: this.editModel.postalAddress.toData()!
    }).subscribe(result => {
      this.onModify.emit();
      this._modified = false;
    });
  }

  getTaskStateIcon(state: TransportTask.TransportTaskState): string {
    const so = TransportTask.transportTaskStates.get(state);
    return so ? so.iconClass : '';
  }

  getTaskStateNameKey(state: TransportTask.TransportTaskState): string {
    const so = TransportTask.transportTaskStates.get(state);
    return so ? so.stringKey : '';
  }
}

export class PlaceEditModel {

  id: number = -1;
  name: string = '';
  coordinate: Address.Coordinate | undefined;
  postalAddress: AddressModel.PostalAddressModel;
  geocodeStatus: AddressResource.GeocodeStatusObject = {status: 'FAILED', stringKey: 'COMMON_UNKNOWN', iconClass: ''};

  private _place?: Address.Place;

  constructor() {
    this.postalAddress = new AddressModel.PostalAddressModel();
    this.postalAddress.type = AddressModel.PostalAddressModelType.COMPLEX;
  }

  loadData(place: Address.Place) {
    this._place = place;
    this.id = place.id;
    this.coordinate = place.coordinate;
    this.name = place.name;
    this.postalAddress.load(place.postalAddress);
    this.geocodeStatus = AddressResource.geocodeStatuses.find(gc => gc.status === place.geocodeStatus)!;
  }

  reset() {
    if (this._place) {
      this.loadData(this._place);
    }
    else {
      this.id = -1;
      this.name = '';
      this.coordinate = undefined;
      this.geocodeStatus = {status: 'FAILED', stringKey: 'COMMON_UNKNOWN', iconClass: ''};
      this.postalAddress.reset();
    }
  }

  loadCountries(countryItems: List<AddressModel.CountryItem>, defaultSelectedCountryCode: string | undefined) {
    this.postalAddress.loadCountryItems(countryItems, defaultSelectedCountryCode);
  }
}
