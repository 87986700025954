import { DqlQuery, Query } from '../field';
import { LazyReference } from '../../../util/lazy';
import { UserGroups } from './user-group';

export class User {

  readonly id: Query.NumberField;

  private readonly userGroupsReference: LazyReference<UserGroups>; // Lazy to support circular references.

  get userGroup(): UserGroups {
    return this.userGroupsReference.get();
  }

  constructor(prefix?: string) {
    prefix = prefix ? prefix : '';
    this.id = new DqlQuery.NumberField(prefix + 'id');
    this.userGroupsReference = LazyReference.of(
      () => new UserGroups(prefix + 'user_groups.')
    );
  }

}
