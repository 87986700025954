/* eslint-disable */
import { Invoice } from '../../../../lib/invoice/invoice/invoice.service';
import { Map } from 'immutable';
import { BadgeStyle } from '../../../../shared/table-badge/badge-style';
import { DataTypeObject } from '../../../../util/core-utils';
/* eslint-enable */

export namespace InvoiceListModel {
  export interface InvoiceCategoryTypeObject {
    categoryType: Invoice.InvoiceCategoryType;
    stringKey: string;
    iconClass: string;
  }

  export interface NavExportStateTypeObject {
    id: Invoice.NavExportState;
    stringKey: string;
    style: BadgeStyle;
  }

  export const InvoiceNavExportStates: Map<Invoice.NavExportState, NavExportStateTypeObject> = Map.of(
    'EXPORT_NEEDED', {id: 'EXPORT_NEEDED', stringKey: 'NAV_EXPORT_STATE_EXPORT_NEEDED', style: BadgeStyle.WARNING},
    'EXPORT_NOT_NEEDED', {id: 'EXPORT_NOT_NEEDED', stringKey: 'NAV_EXPORT_STATE_EXPORT_NOT_NEEDED', style: BadgeStyle.NONE},
    'IN_PROGRESS', {id: 'IN_PROGRESS', stringKey: 'NAV_EXPORT_STATE_IN_PROGRESS', style: BadgeStyle.WARNING},
    'DONE', {id: 'DONE', stringKey: 'NAV_EXPORT_STATE_DONE', style: BadgeStyle.SUCCESS},
    'FAILED', {id: 'FAILED', stringKey: 'NAV_EXPORT_STATE_FAILED', style: BadgeStyle.DANGER}
  );

  export const InvoicePostProcessStates: Map<Invoice.PostProcessState, DataTypeObject<Invoice.PostProcessState>> = Map.of(
    'UNKNOWN', {id: 'UNKNOWN', stringKey: 'INVOICE_POST_PROCESS_STATE_UNKNOWN', style: BadgeStyle.SECONDARY},
    'WAITING', {id: 'WAITING', stringKey: 'INVOICE_POST_PROCESS_STATE_WAITING', style: BadgeStyle.WARNING},
    'DONE', {id: 'DONE', stringKey: 'INVOICE_POST_PROCESS_STATE_DONE', style: BadgeStyle.SUCCESS},
    'FAILED', {id: 'FAILED', stringKey: 'INVOICE_POST_PROCESS_STATE_FAILED', style: BadgeStyle.DANGER}
  );

  export const InvoiceCategoryTypes: Map<Invoice.InvoiceCategoryType, InvoiceCategoryTypeObject> = Map.of(
    'NORMAL', {categoryType: 'NORMAL', stringKey: 'COMMON_NORMAL', iconClass: 'icomoon-invoice-normal'},
    'STORNO', {categoryType: 'STORNO', stringKey: 'INVOICE_CATEGORY_TYPE_STORNO', iconClass: 'icomoon-invoice-storno'},
    'MODIFY', {categoryType: 'MODIFY', stringKey: 'INVOICE_CATEGORY_TYPE_MODIFY', iconClass: 'icomoon-invoice-modify'},
  );

  export const InvoiceDirectionTypes: Map<Invoice.InvoiceDirectionType, DataTypeObject<Invoice.InvoiceDirectionType>> = Map.of(
    'OUTBOUND', {id: 'OUTBOUND', stringKey: 'INVOICE_DIRECTION_OUTBOUND'},
    'INBOUND', {id: 'INBOUND', stringKey: 'INVOICE_DIRECTION_INBOUND'},
  );

  export const InvoicePaymentTypes: Map<Invoice.InvoicePaymentType, DataTypeObject<Invoice.InvoicePaymentType>> = Map.of(
    'CASH', {id: 'CASH', stringKey: 'INVOICE_PAYMENT_TYPE_CASH'},
    'CREDIT_CARD', {id: 'CREDIT_CARD', stringKey: 'INVOICE_PAYMENT_TYPE_CREDIT_CARD'},
    'TRANSFER', {id: 'TRANSFER', stringKey: 'INVOICE_PAYMENT_TYPE_TRANSFER'},
    'VOUCHER', {id: 'VOUCHER', stringKey: 'INVOICE_PAYMENT_TYPE_VOUCHER'},
    'COD', {id: 'COD', stringKey: 'INVOICE_PAYMENT_TYPE_COD'},
    'OTHER', {id: 'OTHER', stringKey: 'INVOICE_PAYMENT_TYPE_OTHER'},
  );
}
