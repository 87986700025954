/* eslint-disable */
import { Component, OnInit } from '@angular/core';
import { TransportDashboard, TransportDashboardService } from '../../../lib/transport-dashboard/transport-dashboard.service';
import { LoggerFactory } from '../../../util/logger-factory';
import { Arrays } from '../../../lib/util/arrays';
import { RightModel } from '../../../app.rights';
import { RightResolver, RightService } from '../../../lib/right.service';
import StateStat = TransportDashboard.StateStat;
import TransportDashboardState = TransportDashboard.TransportDashboardState;
/* eslint-enable */

@Component({
  selector: 'app-anysped-dashboard',
  templateUrl: './anysped.component.html',
  styleUrls: ['./anysped.component.scss']
})
export class AnyspedComponent implements OnInit {

  private readonly logger = LoggerFactory.createLogger('AnyspedComponent').disableDebug();

  isDemandChartVisible: boolean = true;
  isTransportChartVisible: boolean = true;

  dashboardDemandModel: AppworksDashboardDemandModel;
  dashboardTransportModel: AppworksDashboardTransportModel;

  showGoogleChartsDemand = false;
  showGoogleChartsTransport = false;

  messageList: MessageListModel[] = [];
  lostDriverList: LostDriverListModel[] = [];

  rightModel: RightModel = RightModel.empty();

  constructor(
    private transportDashboardService: TransportDashboardService,
    private rightService: RightService,
  ) {
  }

  ngOnInit() {
    this.loadRightModels(() => {
      this.loadlist();
    });
  }

  private loadRightModels(completion: () => void) {
    this.rightService.getRightResolver().subscribe(
      (resolver: RightResolver) => {
        this.rightModel = RightModel.of(resolver);
        completion();
      }
    );
  }

  loadlist() {
    if (this.rightModel.shippingDemandRead.hasRight() && this.rightModel.transportRead.hasRight()) {
      this.transportDashboardService.get({}).subscribe(value => {

        // Demand
        this.setDemandStates(value.demandStatistics);

        // Transport
        this.setTransportStates(value.transportStatistics);

        // Messages
        this.loadMessages(value.recentMessages);

        // LostDrivers
        this.loadLostDrivers(value.lostDriver);

      });
    }
  }

  setDemandStates(demand: TransportDashboard.DemandStatistics) {
    const demandModel = new AppworksDashboardDemandModel();

    demandModel.stats = demand.stats;
    demandModel.taskRecordCount = demand.taskRecordCount;

    if (demandModel.taskRecordCount !== 0) {
      this.dashboardDemandModel = demandModel;
      this.showGoogleChartsDemand = true;
    }
    else {
      this.isDemandChartVisible = false;
    }

  }


  setTransportStates(transport: TransportDashboard.TransportStatistics) {
    const transportModel = new AppworksDashboardTransportModel();

    transportModel.stats = transport.stats;
    transportModel.taskRecordCount = transport.taskRecordCount;

    if (transportModel.taskRecordCount !== 0) {
      this.dashboardTransportModel = transportModel;
      this.showGoogleChartsTransport = true;
    }
    else {
      this.isTransportChartVisible = false;
    }
  }

  private loadMessages(messages: TransportDashboard.Message[]) {
    this.messageList = [];


    Arrays.iterateByIndex(messages, message => {
      const messageModel = new MessageListModel();

      messageModel.transportId = message.transportId;
      messageModel.id = message.chatMessage.id;
      messageModel.content = message.chatMessage.content;
      messageModel.creationTime = message.chatMessage.creationTime.toUtcIsoString();
      messageModel.isTodayMessage = message.chatMessage.creationTime.isToday();

      messageModel.userName = message.chatMessage.userName;
      messageModel.userPersonName = message.chatMessage.userPersonName;

      this.messageList.push(messageModel);
    });
  }

  private loadLostDrivers(lostDrivers: TransportDashboard.DriverItem[]) {
    this.lostDriverList = [];

    Arrays.iterateByIndex(lostDrivers, lostDriver => {
      const lostDriverModel = new LostDriverListModel();

      lostDriverModel.transportId = lostDriver.transportId;
      lostDriverModel.creationTime = lostDriver.creationTime.toUtcIsoString();
      lostDriverModel.driverPersonName = lostDriver.driver.personName;
      lostDriverModel.transportWaybillNumber = lostDriver.transportWaybillNumber;
      lostDriverModel.vehicleLicencePlate = lostDriver.vehicleLicencePlate;

      this.lostDriverList.push(lostDriverModel);
    });
  }
}


class AppworksDashboardDemandModel {
  taskRecordCount: number;
  stats: Map<TransportDashboardState, StateStat> = new Map<TransportDashboardState, StateStat>();

  getStat(state: TransportDashboardState): StateStat {
    const stat = this.stats[state];
    return stat ? stat : {state: state, count: 0, percent: 0};
  }
}

class AppworksDashboardTransportModel {
  taskRecordCount: number;
  stats: Map<TransportDashboardState, StateStat> = new Map<TransportDashboardState, StateStat>();

  getStat(state: TransportDashboardState): StateStat {
    const stat = this.stats[state];
    return stat ? stat : {state: state, count: 0, percent: 0};
  }
}


export class MessageListModel {
  transportId: number;
  id: number = -1;
  content: string = '';
  creationTime: string = '';
  userName: string = '';
  userPersonName: string = '';
  isTodayMessage: boolean = false;
}

export class LostDriverListModel {
  transportId: number;
  driverPersonName: string = '';
  creationTime: string = '';
  transportWaybillNumber: string = '';
  vehicleLicencePlate: string = '';
}
