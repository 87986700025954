import { Injectable } from '@angular/core';
import { MultiselectProvider } from '../../multiselect-provider';
import { Observable, Observer } from 'rxjs';
import { MultiselectOptionItem, UiConstants } from '../../../util/core-utils';
import { QueryResult } from '../../util/services';
import { LedgerNumber, LedgerNumberService } from './ledger-number.service';
import { List } from 'immutable';
import { FilterField } from '../../query/filterfields';
import { CriteriaBuilder } from '../../../util/model-utils';
import { Query } from '../../query/field';

@Injectable()
export class LedgerNumberMultiselectProvider implements MultiselectProvider {

  private multiselectQueryRequest: LedgerNumber.QueryRequest = {
    fields: f => f.multiselect,
    order: f => List.of(f.ledgerNumber.asc().nullsLast()),
    paging: {
      pageNumber: 1,
      numberOfItems: UiConstants.autocompletePageSize,
    },
    noProgressBar: true,
  };

  constructor(private ledgerNumberService: LedgerNumberService) {
  }

  load(queryRequest: LedgerNumber.QueryRequest): Observable<MultiselectOptionItem<number>[]> {
    return Observable.create((observer: Observer<MultiselectOptionItem<number>[]>) => {
      this.ledgerNumberService.query(queryRequest).subscribe((result: QueryResult<LedgerNumber.LedgerNumber>) => {
          observer.next(result.items.map(item => this.toMultiselectOptionItem(item!)).toArray());
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  loadAll(predicate?: string): Observable<MultiselectOptionItem<number>[]> {
    const queryRequest = Object.assign({}, this.multiselectQueryRequest);
    queryRequest.filter = (f: FilterField.LedgerNumber) => CriteriaBuilder.builder()
      .addString((predicate) => f.name.containsIgnoreCase(predicate), predicate)
      .build();
    return this.load(queryRequest);
  }

  loadActive(predicate?: string): Observable<MultiselectOptionItem<number>[]> {
    const queryRequest = Object.assign({}, this.multiselectQueryRequest);
    queryRequest.filter = (f: FilterField.LedgerNumber) => CriteriaBuilder.builder()
      .addString((predicate) => f.ledgerNumber.containsIgnoreCase(predicate)
        .or(f.name.containsIgnoreCase(predicate)), predicate)
      .addBoolean(() => f.disabled.isFalse())
      .build();
    return this.load(queryRequest);
  }

  getById(id: number): Observable<MultiselectOptionItem<number>> {
    return Observable.create((observer: Observer<MultiselectOptionItem<number>>) => {
      this.ledgerNumberService.get({
        id: id,
        fields: f => f.multiselect
      }).subscribe((result: LedgerNumber.LedgerNumber) => {
          observer.next(this.toMultiselectOptionItem(result!));
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  getByIds(ids: number[]): Observable<MultiselectOptionItem<number>[]> {
    const queryRequest = Object.assign({}, this.multiselectQueryRequest);
    const filters: Query.Criteria[] = [];
    const f: FilterField.LedgerNumber = new FilterField.LedgerNumber();
    ids.forEach(id => {
      filters.push(f.id.eq(id));
    });
    queryRequest.filter = f => Query.Criterias.anyOf(List.of(...filters));
    return this.load(queryRequest);
  }

  toMultiselectOptionItem(item: LedgerNumber.LedgerNumber): MultiselectOptionItem<number> {
    return LedgerNumberMultiselectProvider.toPublic(item);
  }

  static toPublic(item: LedgerNumber.LedgerNumber): MultiselectOptionItem<number> {
    const ret = new MultiselectOptionItem<number>();
    ret.id = item.id;
    ret.itemName = item.ledgerNumber;
    ret.itemSubtitle = item.name;
    ret.disabled = item.disabled;
    return ret;
  }
}
