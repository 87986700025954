import { Component, Input, OnInit } from '@angular/core';
import { ShipmentGroupModel } from '../shipment-group.model';

@Component({
  selector: 'app-shipment-group-base-data',
  templateUrl: './shipment-group-base-data.component.html',
  styleUrls: ['./shipment-group-base-data.component.scss']
})
export class ShipmentGroupBaseDataComponent implements OnInit {

  @Input()
  shipmentGroupModel: ShipmentGroupModel;

  constructor() {
  }

  ngOnInit(): void {
  }



}
