<div class="breadcrumb-container" [ngClass]="{ 'breadcrumb-container-compact': compactSidebar }" id="breadcrumb">
  <div class="breadcrumb-component">
    <app-breadcrumb
      [parents]="breadcrumbParents"
      [self]="breadcrumbSelf">
    </app-breadcrumb>
  </div>
  <div class="breadcrumb-menu d-flex">
    <div class="breadcrumb-button-icon-container cursor-pointer"
         *ngIf="rightModel.shopRenterShopImportProducts.hasRight()"
         (click)="productImportDialog.showDialog()">
      <a class="btn-setting d-flex align-items-center" title="{{'SHOP_RENTER_BUTTON_IMPORT' | translate}}">
        <i class="icomoon icomoon-package-handover"></i>
        <span class="breadcrumb-button-text-visible">{{'SHOP_RENTER_BUTTON_IMPORT' | translate}}</span>
      </a>
    </div>
    <div class="breadcrumb-button-icon-container cursor-pointer"
         *ngIf="rightModel.shopRenterShopSync.hasRight()"
         (click)="shopSyncDialog.showDialog()">
      <a class="btn-setting d-flex align-items-center" title="{{'SHOP_RENTER_BUTTON_SYNC' | translate}}">
        <i class="icomoon icomoon-sync"></i>
        <span class="breadcrumb-button-text-visible">{{'SHOP_RENTER_BUTTON_SYNC' | translate}}</span>
      </a>
    </div>
    <app-breadcrumb-button
      class="negative-margin-right"
      *ngIf="rightModel.shopRenterShopUpdate.hasRight() && componentState.isDetailView()"
      [iconClass]="'icomoon-modify'"
      [titleStringKey]="'COMMON_BUTTON_EDIT'"
      uiSref="Admin.ShopRenterShopEdit"
      [uiParams]="{ id: componentState.id }">
    </app-breadcrumb-button>
  </div>
</div>
<div class="breadcrumb-placeholder"></div>
<form class="container-horizontal-padding-half container-vertical-padding-half animated fadeIn"
      (ngSubmit)="f.form.valid && update()" #f="ngForm" novalidate>

  <div class="row m-0">

    <!-- region LEFT -->
    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 p-0">

      <!-- region BASE DATA -->
      <div class="container-horizontal-padding-half container-vertical-padding-half">
        <div class="p-0">
          <div class="card">
            <div class="card-header">
              {{'SHOP_RENTER_SHOP_BASE_DATA'|translate}}
            </div>

            <div class="card-body mb-0">
              <div class="form-horizontal">

                <!--region Import active-->
                <div class="form-group row">
                  <label class="col-form-label form-control-label col-md-4 detail-title">{{'SHOP_RENTER_IMPORT_ACTIVE' |
                    translate}}
                    <i class="icomoon icomoon-info info-icon" popover="{{'SHOP_RENTER_IMPORT_ACTIVE_HINT' | translate}}"
                       triggers="mouseenter:mouseleave" container="body" placement="right">
                    </i>
                  </label>
                  <div class="col-md-8">
                    <label class="switch switch-text switch-info mb-0" *ngIf="componentState.isEditable()">
                      <input type="checkbox" class="switch-input"
                             [ngModelOptions]="{standalone: true}"
                             [(ngModel)]="model.importActive">
                      <span class="switch-label" data-on="&#xe957;" data-off="&#xe98e;"></span>
                      <span class="switch-handle"></span>
                    </label>
                    <!--Appears in detailView-->
                    <label
                      *ngIf="componentState.isReadonly()" class="form-control detail-description"
                      readonly>{{(model.importActive ? 'COMMON_YES' : 'COMMON_NO') | translate}}
                    </label>
                  </div>
                </div>
                <!--endregion Import active-->

                <!--region HumanName-->
                <div class="form-group row" [ngClass]="{ 'has-danger': (fieldErrors.human_name)}">
                  <label
                    class="col-form-label form-control-label col-md-4 detail-title">{{'SHOP_RENTER_SHOP_HUMAN_NAME' |
                    translate}}</label>
                  <div class="col-md-8">
                    <input *ngIf="componentState.isEditable()"
                           [ngClass]="{ 'form-control-danger': fieldErrors.human_name }"
                           [ngModelOptions]="{standalone: true}"
                           type="text" class="form-control"
                           maxlength="{{UiConstants.maximumVarcharLength}}"
                           placeholder="{{'SHOP_RENTER_SHOP_HUMAN_NAME' | translate}}"
                           (ngModelChange)="removeFieldError(fieldErrors.human_name)"
                           [(ngModel)]="model.humanName">
                    <!--Appears in detailView-->
                    <label
                      *ngIf="componentState.isReadonly()" class="form-control detail-description"
                      readonly>{{model.humanName}}
                    </label>
                    <div class="form-control-feedback"
                         *ngIf="fieldErrors.human_name">{{fieldErrors.human_name.text}}</div>
                  </div>
                </div>
                <!--endregion HumanName-->

                <!--region HumanName-->
                <div class="form-group row"
                     [ngClass]="{ 'has-danger': (fieldErrors.company_id)}"
                     *ngIf="componentState.isEditable() || model.companyName">
                  <label class="col-form-label form-control-label col-md-4 detail-title">{{'SHOP_RENTER_SHOP_COMPANY' |
                    translate}}</label>
                  <div class="col-md-8">
                    <angular2-multiselect
                      *ngIf="componentState.isEditable()"
                      [ngClass]="{ 'form-control-danger': fieldErrors.company_id }"
                      [ngModelOptions]="{standalone: true}"
                      [(ngModel)]="model.company"
                      (ngModelChange)="removeFieldError(fieldErrors.company_id)"
                      [data]="companies"
                      [settings]="remoteDropdownSettings"
                      (onRemoteSearch)="loadCompanies($event.target.value)">
                    </angular2-multiselect>
                    <!--Appears in detailView-->
                    <label
                      *ngIf="componentState.isReadonly()" class="form-control detail-description"
                      readonly>{{model.companyName}}
                    </label>
                    <div class="form-control-feedback"
                         *ngIf="fieldErrors.company_id">{{fieldErrors.company_id.text}}</div>
                  </div>
                </div>
                <!--endregion HumanName-->

                <!--region User-->
                <div class="form-group row">
                  <label class="col-form-label form-control-label col-md-4 detail-title">{{'SHOP_RENTER_SHOP_USER' |
                    translate}}</label>
                  <div class="col-md-8">
                    <label
                      class="form-control detail-description"
                      readonly>{{model.userPersonName}}
                    </label>
                  </div>
                </div>
                <!--endregion User-->

                <!--region Logo-->
                <div class="form-group row">
                  <label class="col-form-label form-control-label col-md-4 detail-title">{{'SHOP_RENTER_SHOP_WEBSHOP' |
                    translate}}</label>
                  <div class="col-md-8">
                    <img src="../../../../assets/img/shoprenter/shoprenter.png" class="w-100 mw-250 border-x border-y"
                         alt="ShopRenter">
                  </div>
                </div>
                <!--endregion Logo-->

                <!--region Name-->
                <div class="form-group row">
                  <label class="col-form-label form-control-label col-md-4 detail-title">{{'SHOP_RENTER_SHOP_NAME' |
                    translate}}</label>
                  <div class="col-md-8">
                    <label
                      class="form-control detail-description"
                      readonly>{{model.name}}
                    </label>
                  </div>
                </div>
                <!--endregion Name-->

              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- endregion BASE DATA -->

    </div>
    <!-- endregion LEFT -->

    <!-- region RIGHT -->
    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 p-0">
      <div class="container-horizontal-padding-half container-vertical-padding-half">
        <div class="p-0">
          <div class="card">
            <div class="card-header">
              {{'SHOP_RENTER_SHOP_SHIPPING_MODES'|translate}}
            </div>

            <div class="card-body mb-0">
              <div class="form-horizontal">

                <div *ngFor="let transporterCompany of model.transporterCompanies">

                  <h4 class="mb-1">{{transporterCompany.itemName}}</h4>

                  <div class="form-group row" *ngFor="let deliveryMethod of transporterCompany.deliveryMethods">
                    <div class="col-md-4">
                      <label
                        class="col-form-label form-control-label detail-title p-0">{{deliveryMethod.itemName}}</label>
                    </div>
                    <div class="col-md-8">
                      <angular2-multiselect
                        *ngIf="componentState.isEditable()"
                        [data]="shippingModes"
                        [(ngModel)]="deliveryMethod.shippingMode"
                        [ngModelOptions]="{standalone: true}"
                        [settings]="dropdownSettings">
                      </angular2-multiselect>
                      <!--Appears in detailView-->
                      <label
                        *ngIf="componentState.isReadonly()" class="form-control detail-description"
                        readonly>{{deliveryMethod.shippingMode.length > 0 ? deliveryMethod.shippingMode[0].itemName : ''}}
                      </label>
                    </div>
                  </div>

                </div>

                <div class="text-center container-vertical-padding" *ngIf="model.transporterCompanies.length === 0">
                  <h5 class="text-muted">{{'SHOP_RENTER_SHOP_NO_DELIVERY_METHOD' | translate}}</h5>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- endregion RIGHT -->

  </div>

  <!-- region STATE MAPPING -->
  <div class="row">

    <div class="col-md-12">

      <!-- region IMPORT STATUSES -->
      <div class="container-horizontal-padding-half container-vertical-padding-half">
        <div class="p-0">
          <div class="card">
            <div class="card-header">
              {{'SHOP_RENTER_SHOP_PROCESSING_STATUSES'|translate}}
            </div>

            <div class="row m-0">

              <div class="card-body mb-0 col-md-6 col-sm-12">
                <div class="form-horizontal">

                  <label class="inner-card-title">{{'SHOP_RENTER_SHOP_IMPORT_STATUSES' | translate}}</label>
                  <!--region ToBeImported-->
                  <div class="form-group row" *ngIf="componentState.isEditable() || model.importableOrderStatusNames"
                       [ngClass]="{ 'has-danger': hasLocalFieldError(importableOrderStatuses) }">
                    <div class="col-md-4">
                      <label
                        class="col-form-label form-control-label detail-title p-0">{{'SHOP_RENTER_SHOP_ORDER_STATUS_TO_BE_IMPORTED' |
                        translate}}
                        <i class="icomoon icomoon-info info-icon"
                           popover="{{'SHOP_RENTER_SHOP_ORDER_STATUS_TO_BE_IMPORTED_HINT' | translate}}"
                           triggers="mouseenter:mouseleave" container="body" placement="right">
                        </i>
                      </label>
                      <br>
                      <small
                        class="text-muted">{{'SHOP_RENTER_SHOP_ORDER_STATUS_TO_BE_IMPORTED_DESCRIPTION' | translate}}</small>
                    </div>
                    <div class="col-md-8">
                      <angular2-multiselect
                        *ngIf="componentState.isEditable()"
                        [data]="model.orderStatuses"
                        [(ngModel)]="model._importableOrderStatuses"
                        [settings]="multiDropdownSettings"
                        name="importableOrderStatuses"
                        id="importableOrderStatuses"
                        #importableOrderStatuses="ngModel"
                        required>
                      </angular2-multiselect>
                      <div class="form-control-feedback"
                           *ngIf="hasLocalFieldError(importableOrderStatuses)">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
                      </div>
                      <!--Appears in detailView-->
                      <label
                        *ngIf="componentState.isReadonly()" class="form-control detail-description"
                        readonly>{{model.importableOrderStatusNames}}
                      </label>
                    </div>
                  </div>
                  <!--endregion ToBeImported-->

                  <!--region Imported-->
                  <div class="form-group row" *ngIf="componentState.isEditable() || model.importedOrderStatusName"
                       [ngClass]="{ 'has-danger': hasLocalFieldError(importedOrderStatus) }">
                    <div class="col-md-4">
                      <label
                        class="col-form-label form-control-label detail-title p-0">{{'SHOP_RENTER_SHOP_ORDER_STATUS_IMPORTED' |
                        translate}}
                        <i class="icomoon icomoon-info info-icon"
                           popover="{{'SHOP_RENTER_SHOP_ORDER_STATUS_IMPORTED_HINT' | translate}}"
                           triggers="mouseenter:mouseleave" container="body" placement="right">
                        </i>
                      </label>
                      <br>
                      <small
                        class="text-muted">{{'SHOP_RENTER_SHOP_ORDER_STATUS_IMPORTED_DESCRIPTION' | translate}}</small>
                    </div>
                    <div class="col-md-8">
                      <angular2-multiselect
                        *ngIf="componentState.isEditable()"
                        [data]="model.orderStatuses"
                        [(ngModel)]="model._importedOrderStatus"
                        [required]="true"
                        [settings]="dropdownSettings"
                        name="importedOrderStatus"
                        id="importedOrderStatus"
                        #importedOrderStatus="ngModel"
                        required>
                      </angular2-multiselect>
                      <div class="form-control-feedback"
                           *ngIf="hasLocalFieldError(importedOrderStatus)">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
                      </div>
                      <!--Appears in detailView-->
                      <label
                        *ngIf="componentState.isReadonly()" class="form-control detail-description"
                        readonly>{{model.importedOrderStatusName}}
                      </label>
                    </div>
                  </div>
                  <!--endregion Imported-->

                  <!--region UnknownProduct-->
                  <div class="form-group row"
                       *ngIf="componentState.isEditable() || model.unknownProductOrderStatusName">
                    <div class="col-md-4">
                      <label
                        class="col-form-label form-control-label detail-title p-0">{{'SHOP_RENTER_SHOP_ORDER_STATUS_UNKNOWN_PRODUCT' |
                        translate}}
                        <i class="icomoon icomoon-info info-icon"
                           popover="{{'SHOP_RENTER_SHOP_ORDER_STATUS_UNKNOWN_PRODUCT_HINT' | translate}}"
                           triggers="mouseenter:mouseleave" container="body" placement="right">
                        </i>
                      </label>
                      <br>
                      <small
                        class="text-muted">{{'SHOP_RENTER_SHOP_ORDER_STATUS_UNKNOWN_PRODUCT_DESCRIPTION' | translate}}</small>
                    </div>
                    <div class="col-md-8">
                      <angular2-multiselect
                        *ngIf="componentState.isEditable()"
                        [data]="model.orderStatuses"
                        [(ngModel)]="model._unknownProductOrderStatus"
                        [ngModelOptions]="{standalone: true}"
                        [settings]="dropdownSettings">
                      </angular2-multiselect>
                      <!--Appears in detailView-->
                      <label
                        *ngIf="componentState.isReadonly()" class="form-control detail-description"
                        readonly>{{model.unknownProductOrderStatusName}}
                      </label>
                    </div>
                  </div>
                  <!--endregion UnknownProduct-->

                  <!--region InvalidOrder-->
                  <div class="form-group row" *ngIf="componentState.isEditable() || model.invalidOrderOrderStatusName"
                       [ngClass]="{ 'has-danger': hasLocalFieldError(invalidOrderOrderStatus) }">
                    <div class="col-md-4">
                      <label
                        class="col-form-label form-control-label detail-title p-0">{{'SHOP_RENTER_SHOP_ORDER_STATUS_INVALID_ORDER' |
                        translate}}
                        <i class="icomoon icomoon-info info-icon"
                           popover="{{'SHOP_RENTER_SHOP_ORDER_STATUS_INVALID_ORDER_HINT' | translate}}"
                           triggers="mouseenter:mouseleave" container="body" placement="right">
                        </i>
                      </label>
                      <br>
                      <small
                        class="text-muted">{{'SHOP_RENTER_SHOP_ORDER_STATUS_INVALID_ORDER_DESCRIPTION' | translate}}</small>
                    </div>
                    <div class="col-md-8">
                      <angular2-multiselect
                        *ngIf="componentState.isEditable()"
                        [data]="model.orderStatuses"
                        [(ngModel)]="model._invalidOrderOrderStatus"
                        [required]="true"
                        [settings]="dropdownSettings"
                        name="invalidOrderOrderStatus"
                        id="invalidOrderOrderStatus"
                        #invalidOrderOrderStatus="ngModel"
                        required>
                      </angular2-multiselect>
                      <div class="form-control-feedback"
                           *ngIf="hasLocalFieldError(invalidOrderOrderStatus)">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
                      </div>
                      <!--Appears in detailView-->
                      <label
                        *ngIf="componentState.isReadonly()" class="form-control detail-description"
                        readonly>{{model.invalidOrderOrderStatusName}}
                      </label>
                    </div>
                  </div>
                  <!--endregion InvalidOrder-->

                  <!--region InsufficientAmount-->
                  <div class="form-group row"
                       *ngIf="componentState.isEditable() || model.insufficientAmountOrderStatusName"
                       [ngClass]="{ 'has-danger': hasLocalFieldError(insufficientAmountOrderStatus) }">
                    <div class="col-md-4">
                      <label
                        class="col-form-label form-control-label detail-title p-0">{{'SHOP_RENTER_SHOP_ORDER_STATUS_INSUFFICIENT_AMOUNT' |
                        translate}}
                        <i class="icomoon icomoon-info info-icon"
                           popover="{{'SHOP_RENTER_SHOP_ORDER_STATUS_INSUFFICIENT_AMOUNT_HINT' | translate}}"
                           triggers="mouseenter:mouseleave" container="body" placement="right">
                        </i>
                      </label>
                      <br>
                      <small
                        class="text-muted">{{'SHOP_RENTER_SHOP_ORDER_STATUS_INSUFFICIENT_AMOUNT_DESCRIPTION' | translate}}</small>
                    </div>
                    <div class="col-md-8">
                      <angular2-multiselect
                        *ngIf="componentState.isEditable()"
                        [data]="model.orderStatuses"
                        [(ngModel)]="model._insufficientAmountOrderStatus"
                        [required]="true"
                        [settings]="dropdownSettings"
                        name="insufficientAmountOrderStatus"
                        id="insufficientAmountOrderStatus"
                        #insufficientAmountOrderStatus="ngModel"
                        required>
                      </angular2-multiselect>
                      <div class="form-control-feedback"
                           *ngIf="hasLocalFieldError(insufficientAmountOrderStatus)">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
                      </div>
                      <!--Appears in detailView-->
                      <label
                        *ngIf="componentState.isReadonly()" class="form-control detail-description"
                        readonly>{{model.insufficientAmountOrderStatusName}}
                      </label>
                    </div>
                  </div>
                  <!--endregion InsufficientAmount-->

                </div>
              </div>

              <div class="card-body mb-0 col-md-6 col-sm-12">
                <div class="form-horizontal">

                  <label class="inner-card-title">{{'SHOP_RENTER_SUCCESS_STATES' | translate}}</label>

                  <!--region ProcessStarted-->
                  <div class="form-group row"
                       *ngIf="componentState.isEditable() || model.processStartedOrderStatusName">
                    <div class="col-md-4">
                      <label
                        class="col-form-label form-control-label detail-title p-0">{{'SHOP_RENTER_SHOP_ORDER_STATUS_PROCESS_STARTED' |
                        translate}}
                      </label>
                      <br>
                      <small
                        class="text-muted">{{'SHOP_RENTER_SHOP_ORDER_STATUS_PROCESS_STARTED_DESCRIPTION' | translate}}</small>
                    </div>
                    <div class="col-md-8">
                      <angular2-multiselect
                        *ngIf="componentState.isEditable()"
                        [data]="model.orderStatuses"
                        [(ngModel)]="model._processStartedOrderStatus"
                        [ngModelOptions]="{standalone: true}"
                        [settings]="dropdownSettings">
                      </angular2-multiselect>
                      <!--Appears in detailView-->
                      <label
                        *ngIf="componentState.isReadonly()" class="form-control detail-description"
                        readonly>{{model.processStartedOrderStatusName}}
                      </label>
                    </div>
                  </div>
                  <!--endregion ProcessStarted-->

                  <!--region ProcessFinished-->
                  <div class="form-group row"
                       *ngIf="componentState.isEditable() || model.processFinishedOrderStatusName">
                    <div class="col-md-4">
                      <label
                        class="col-form-label form-control-label detail-title p-0">{{'SHOP_RENTER_SHOP_ORDER_STATUS_PROCESS_FINISHED' |
                        translate}}
                        <i class="icomoon icomoon-info info-icon"
                           popover="{{'SHOP_RENTER_SHOP_ORDER_STATUS_PROCESS_FINISHED_HINT' | translate}}"
                           triggers="mouseenter:mouseleave" container="body" placement="right">
                        </i>
                      </label>
                      <br>
                      <small
                        class="text-muted">{{'SHOP_RENTER_SHOP_ORDER_STATUS_PROCESS_FINISHED_DESCRIPTION' | translate}}</small>
                    </div>
                    <div class="col-md-8">
                      <angular2-multiselect
                        *ngIf="componentState.isEditable()"
                        [data]="model.orderStatuses"
                        [(ngModel)]="model._processFinishedOrderStatus"
                        [ngModelOptions]="{standalone: true}"
                        [settings]="dropdownSettings">
                      </angular2-multiselect>
                      <!--Appears in detailView-->
                      <label
                        *ngIf="componentState.isReadonly()" class="form-control detail-description"
                        readonly>{{model.processFinishedOrderStatusName}}
                      </label>
                    </div>
                  </div>
                  <!--endregion ProcessFinished-->

                  <!--region Delivered-->
                  <div class="form-group row" *ngIf="componentState.isEditable() || model.deliveredOrderStatusName"
                       [ngClass]="{ 'has-danger': hasLocalFieldError(deliveredOrderStatus) }">
                    <div class="col-md-4">
                      <label
                        class="col-form-label form-control-label detail-title p-0">{{'SHOP_RENTER_SHOP_ORDER_STATUS_DELIVERED' |
                        translate}}
                        <i class="icomoon icomoon-info info-icon"
                           popover="{{'SHOP_RENTER_SHOP_ORDER_STATUS_DELIVERED_HINT' | translate}}"
                           triggers="mouseenter:mouseleave" container="body" placement="right">
                        </i>
                      </label>
                      <br>
                      <small
                        class="text-muted">{{'SHOP_RENTER_SHOP_ORDER_STATUS_DELIVERED_DESCRIPTION' | translate}}</small>
                    </div>
                    <div class="col-md-8">
                      <angular2-multiselect
                        *ngIf="componentState.isEditable()"
                        [data]="model.orderStatuses"
                        [(ngModel)]="model._deliveredOrderStatus"
                        [required]="true"
                        [settings]="dropdownSettings"
                        name="deliveredOrderStatus"
                        id="deliveredOrderStatus"
                        #deliveredOrderStatus="ngModel"
                        required>
                      </angular2-multiselect>
                      <div class="form-control-feedback"
                           *ngIf="hasLocalFieldError(deliveredOrderStatus)">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
                      </div>
                      <!--Appears in detailView-->
                      <label
                        *ngIf="componentState.isReadonly()" class="form-control detail-description"
                        readonly>{{model.deliveredOrderStatusName}}
                      </label>
                    </div>
                  </div>
                  <!--endregion Delivered-->

                  <label class="inner-card-title">{{'SHOP_RENTER_ERROR_STATES' | translate}}</label>

                  <!--region Canceled-->
                  <div class="form-group row" *ngIf="componentState.isEditable() || model.canceledOrderStatusName"
                       [ngClass]="{ 'has-danger': hasLocalFieldError(canceledOrderStatus) }">
                    <div class="col-md-4">
                      <label
                        class="col-form-label form-control-label detail-title p-0">{{'SHOP_RENTER_SHOP_ORDER_STATUS_CANCELED' |
                        translate}}
                        <i class="icomoon icomoon-info info-icon"
                           popover="{{'SHOP_RENTER_SHOP_ORDER_STATUS_CANCELED_HINT' | translate}}"
                           triggers="mouseenter:mouseleave" container="body" placement="right">
                        </i>
                      </label>
                      <br>
                      <small
                        class="text-muted">{{'SHOP_RENTER_SHOP_ORDER_STATUS_CANCELED_DESCRIPTION' | translate}}</small>
                    </div>
                    <div class="col-md-8">
                      <angular2-multiselect
                        *ngIf="componentState.isEditable()"
                        [data]="model.orderStatuses"
                        [(ngModel)]="model._canceledOrderStatus"
                        [required]="true"
                        [settings]="dropdownSettings"
                        name="canceledOrderStatus"
                        id="canceledOrderStatus"
                        #canceledOrderStatus="ngModel"
                        required>
                      </angular2-multiselect>
                      <div class="form-control-feedback"
                           *ngIf="hasLocalFieldError(canceledOrderStatus)">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
                      </div>
                      <!--Appears in detailView-->
                      <label
                        *ngIf="componentState.isReadonly()" class="form-control detail-description"
                        readonly>{{model.canceledOrderStatusName}}
                      </label>
                    </div>
                  </div>
                  <!--endregion Canceled-->

                  <!--region Rejected-->
                  <div class="form-group row" *ngIf="componentState.isEditable() || model.rejectedOrderStatusName"
                       [ngClass]="{ 'has-danger': hasLocalFieldError(rejectedOrderStatus) }">
                    <div class="col-md-4">
                      <label
                        class="col-form-label form-control-label detail-title p-0">{{'SHOP_RENTER_SHOP_ORDER_STATUS_REJECTED' |
                        translate}}
                      </label>
                      <br>
                      <small
                        class="text-muted">{{'SHOP_RENTER_SHOP_ORDER_STATUS_REJECTED_DESCRIPTION' | translate}}</small>
                    </div>
                    <div class="col-md-8">
                      <angular2-multiselect
                        *ngIf="componentState.isEditable()"
                        [data]="model.orderStatuses"
                        [(ngModel)]="model._rejectedOrderStatus"
                        [required]="true"
                        [settings]="dropdownSettings"
                        name="rejectedOrderStatus"
                        id="rejectedOrderStatus"
                        #rejectedOrderStatus="ngModel"
                        required>
                      </angular2-multiselect>
                      <div class="form-control-feedback"
                           *ngIf="hasLocalFieldError(rejectedOrderStatus)">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
                      </div>
                      <!--Appears in detailView-->
                      <label
                        *ngIf="componentState.isReadonly()" class="form-control detail-description"
                        readonly>{{model.rejectedOrderStatusName}}
                      </label>
                    </div>
                  </div>
                  <!--endregion Rejected-->

                  <!--region Reopened-->
                  <div class="form-group row" *ngIf="componentState.isEditable() || model.reopenedOrderStatusName">
                    <div class="col-md-4">
                      <label
                        class="col-form-label form-control-label detail-title p-0">{{'SHOP_RENTER_SHOP_ORDER_STATUS_REOPENED' |
                        translate}}
                        <i class="icomoon icomoon-info info-icon"
                           popover="{{'SHOP_RENTER_SHOP_ORDER_STATUS_REOPENED_HINT' | translate}}"
                           triggers="mouseenter:mouseleave" container="body" placement="right">
                        </i>
                      </label>
                      <br>
                      <small
                        class="text-muted">{{'SHOP_RENTER_SHOP_ORDER_STATUS_REOPENED_DESCRIPTION' | translate}}</small>
                    </div>
                    <div class="col-md-8">
                      <angular2-multiselect
                        *ngIf="componentState.isEditable()"
                        [data]="model.orderStatuses"
                        [(ngModel)]="model._reopenedOrderStatus"
                        [ngModelOptions]="{standalone: true}"
                        [settings]="dropdownSettings">
                      </angular2-multiselect>
                      <!--Appears in detailView-->
                      <label
                        *ngIf="componentState.isReadonly()" class="form-control detail-description"
                        readonly>{{model.reopenedOrderStatusName}}
                      </label>
                    </div>
                  </div>
                  <!--endregion Reopened-->

                  <!--region Returned-->
                  <div class="form-group row" *ngIf="componentState.isEditable() || model.returnedOrderStatusName"
                       [ngClass]="{ 'has-danger': hasLocalFieldError(returnedOrderStatus) }">
                    <div class="col-md-4">
                      <label
                        class="col-form-label form-control-label detail-title">{{'SHOP_RENTER_SHOP_ORDER_STATUS_RETURNED' |
                        translate}}
                      </label>
                    </div>
                    <div class="col-md-8">
                      <angular2-multiselect
                        *ngIf="componentState.isEditable()"
                        [data]="model.orderStatuses"
                        [(ngModel)]="model._returnedOrderStatus"
                        [required]="true"
                        [settings]="dropdownSettings"
                        name="returnedOrderStatus"
                        id="returnedOrderStatus"
                        #returnedOrderStatus="ngModel"
                        required>
                      </angular2-multiselect>
                      <div class="form-control-feedback"
                           *ngIf="hasLocalFieldError(returnedOrderStatus)">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
                      </div>
                      <!--Appears in detailView-->
                      <label
                        *ngIf="componentState.isReadonly()" class="form-control detail-description"
                        readonly>{{model.returnedOrderStatusName}}
                      </label>
                    </div>
                  </div>
                  <!--endregion Returned-->

                </div>
              </div>

            </div>

            <div class="alert alert-warning mx-1 mb-1">
              <p class="mb-0">
                <i class="icomoon icomoon-warning icon-large mr-1"></i>
                {{'SHOP_RENTER_STATE_MAPPING_HINT' | translate}}
              </p>
            </div>

          </div>
        </div>
      </div>
      <!-- endregion -->

    </div>

  </div>
  <!-- endregion STATE MAPPING -->

  <input type="submit" class="btn btn-primary floating_save_button" *ngIf="!componentState.isReadonly()"
         value="{{'COMMON_BUTTON_SAVE'|translate}}"/>

</form>

<app-confirm-dialog
  #shopSyncDialog
  [title]="'SHOP_RENTER_BUTTON_SYNC' | translate"
  [messageEnabled]="false"
  [positiveButton]="'SHOP_RENTER_BUTTON_SYNC_SHORT' | translate"
  (onConfirm)="sync()">
  <div class="form-group row">
    <div class="col-md-12">
      <div class="d-flex flex-row flex-wrap justify-content-center align-items-center">
        <i class="icomoon icomoon-cloud-download icon-4x mx-1"></i>
        <i class="icomoon icomoon-arrow-right icon-2x mx-1"></i>
        <img src="../../../../assets/img/shoprenter/appworks-logo-big.png" class="mx-1 image-4x">
      </div>
      <div>
        <label class="font-weight-bold pl-1">{{'SHOP_RENTER_PRODUCT_SYNC_TITLE' | translate}}</label>
        <ul>
          <li>{{'SHOP_RENTER_PRODUCT_SYNC_ORDER_STATES' | translate}}</li>
          <li>{{'SHOP_RENTER_PRODUCT_SYNC_DELIVERY_METHODS' | translate}}</li>
          <li>{{'SHOP_RENTER_PRODUCT_SYNC_COUNTRY' | translate}}</li>
          <li>{{'SHOP_RENTER_PRODUCT_SYNC_LANGUAGE' | translate}}</li>
          <li>{{'SHOP_RENTER_PRODUCT_SYNC_CURRENCY' | translate}}</li>
        </ul>
      </div>
    </div>
  </div>
</app-confirm-dialog>

<app-confirm-dialog
  #productImportDialog
  [title]="'SHOP_RENTER_BUTTON_IMPORT' | translate"
  [messageEnabled]="false"
  [positiveButton]="'SHOP_RENTER_BUTTON_IMPORT_SHORT' | translate"
  (onConfirm)="import()">
  <div class="form-group row">
    <div class="col-md-12">
      <div class="d-flex flex-row flex-wrap justify-content-center align-items-center">
        <i class="icomoon icomoon-products icon-4x mx-1"></i>
        <i class="icomoon icomoon-arrow-right icon-2x mx-1"></i>
        <img src="../../../../assets/img/shoprenter/appworks-logo-big.png" class="mx-1 image-4x">
      </div>
      <div>
        <label class="font-weight-bold pl-1">{{'SHOP_RENTER_PRODUCT_IMPORT_TITLE' | translate}}</label>
        <ul>
          <li>{{'SHOP_RENTER_PRODUCT_IMPORT_PRODUCT_NAME' | translate}}</li>
          <li>{{'SHOP_RENTER_PRODUCT_IMPORT_PRODUCT_CODE' | translate}}</li>
          <li>{{'SHOP_RENTER_PRODUCT_IMPORT_PRODUCT_DESCRIPTION' | translate}}</li>
          <li>{{'SHOP_RENTER_PRODUCT_IMPORT_PRODUCT_UNIT' | translate}}</li>
          <li>{{'SHOP_RENTER_PRODUCT_IMPORT_PRODUCT_NET_PRICE' | translate}}</li>
          <li>{{'SHOP_RENTER_PRODUCT_IMPORT_PRODUCT_VAT_RATE' | translate}}</li>
        </ul>
      </div>
    </div>
  </div>
</app-confirm-dialog>
