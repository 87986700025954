import {Component, Input, OnInit} from '@angular/core';
import {DetailTab} from '../../../transport/transport-detail/detail-tab';
import {TaskRecord, TaskRecordService} from '../../../../lib/task/task-record.service';
import {RightModel} from '../../../../app.rights';
import {Invoice, InvoiceService} from '../../../../lib/invoice/invoice/invoice.service';
import {DownloadedFile} from '../../../../lib/util/downloaded-files';
import {UiConstants} from '../../../../util/core-utils';
import {InvoiceListModel} from '../../../invoicing/invoices/invoice-list/invoice-list.model';
import {TranslateService} from '@ngx-translate/core';
import {ToasterService} from '../../../../fork/angular2-toaster/src/toaster.service';
import {saveAs} from 'file-saver';
import {InvoiceSettings} from '../../../../lib/invoice/invoice-settings/invoice-settings.service';
import invoiceAppearanceTypes = InvoiceSettings.invoiceAppearanceTypes;

@Component({
  selector: 'app-task-record-invoice',
  templateUrl: './task-record-invoice.component.html',
  styleUrls: ['./task-record-invoice.component.scss']
})
export class TaskRecordInvoiceComponent implements OnInit, DetailTab {

  @Input()
  taskId: number;

  @Input()
  taskRecordId: number;

  invoices: TaskRecord.RelatedInvoice[] = [];

  rightModel: RightModel = RightModel.empty();

  constructor(
    private taskRecordService: TaskRecordService,
    private invoiceService: InvoiceService,
    private translateService: TranslateService,
    private toasterService: ToasterService) {
  }

  ngOnInit() {
  }

  initComponent() {
    this.loadInvoices();
  }

  loadInvoices() {
    this.taskRecordService.getRelatedInvoices({
      taskRecordId: this.taskRecordId,
      taskId: this.taskId,
    }).subscribe(invoices => this.invoices = invoices.sort(
        (a, b) =>
          a.invoiceNumber.localeCompare(b.invoiceNumber)
      )
    );
  }

  downloadInvoice(invoiceId: number) {
    this.invoiceService.downloadPdf({invoiceId: invoiceId}).subscribe(
      (res: DownloadedFile) => {
        saveAs(res.getBlob(), res.getFileName('invoice.pdf'));
      },
      (error) => {
        this.toasterService.pop({
          timeout: UiConstants.ToastTimeoutLong,
          type: UiConstants.toastTypeError,
          title: this.translateService.instant('PDF_DOWNLOAD'),
          body: this.translateService.instant('PDF_DOWNLOAD_ERROR_MESSAGE')
        });
      }
    )
  }

  getInvoiceIconClass(type: Invoice.InvoiceCategoryType): string {
    return InvoiceListModel.InvoiceCategoryTypes.get(type).iconClass;
  }

  getInvoiceTooltipKey(type: Invoice.InvoiceCategoryType): string {
    return InvoiceListModel.InvoiceCategoryTypes.get(type).stringKey;
  }

  hasIntegrationTypeIcon(type: InvoiceSettings.InvoiceIntegrationType): boolean {
    return InvoiceSettings.invoiceIntegrationTypes.find(t => t.type === type)!.icon !== undefined;
  }

  getIntegrationTypeIcon(type: InvoiceSettings.InvoiceIntegrationType): string | undefined {
    return InvoiceSettings.invoiceIntegrationTypes.find(t => t.type === type)!.icon;
  }

  getIntegrationTypeString(type: InvoiceSettings.InvoiceIntegrationType): string | undefined {
    return InvoiceSettings.invoiceIntegrationTypes.find(t => t.type === type)!.stringKey;
  }


  getAppearanceTypeStringKey(appearanceType: Invoice.InvoiceAppearanceType) {
    return invoiceAppearanceTypes.find(type => type.type === appearanceType)!.stringKey;
  }
}
