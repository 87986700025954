import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { DqlStoredQueryArgs } from '../dql-search-container/dql-stored-query.args';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Set } from 'immutable';
import { MultiselectOptionItem, UiConstants } from '../../../util/core-utils';
import { DqlStoredQuery } from '../../../lib/dql/dql-stored-query.service';
import { Strings } from '../../../lib/util/strings';
import { Services } from '../../../lib/util/services';
import { UserGroup, UserGroupService } from '../../../lib/user-group.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppValidators } from '../../../util/app-validators';
import { Angular2Multiselects } from '../../../util/multiselect';
import * as $ from '../../../../../node_modules/jquery/dist/jquery.js';

@Component({
  selector: 'app-dql-stored-query-save-dialog',
  templateUrl: './dql-stored-query-save-dialog.component.html',
  styleUrls: ['./dql-stored-query-save-dialog.component.scss']
})
export class DqlStoredQuerySaveDialogComponent implements OnInit {

  UiConstants = UiConstants;

  saveDialogVisible: boolean = false;

  @ViewChild('saveDialog', { static: true }) saveDialog: ModalDirective;

  @Output()
  onQuerySavedOrDeleted: EventEmitter<number> = new EventEmitter<number>();

  model: DqlStoredQueryModel;

  querySaveForm: FormGroup;
  userGroups: MultiselectOptionItem<number>[] = [];

  dropdownSettings: Angular2Multiselects.Settings;
  private dqlStoredQueryArgs: DqlStoredQueryArgs;

  constructor(private userGroupService: UserGroupService,
              private formBuilder: FormBuilder,
  ) {
    this.querySaveForm = formBuilder.group({
      name: [[], Validators.required],
      userGroup: [[], AppValidators.validateEnabledItems],
    });
  }

  openDialog(dqlStoredQueryArgs: DqlStoredQueryArgs, query: string, storedQuery?: DqlStoredQuery.DqlStoredQuery) {
    this.dqlStoredQueryArgs = dqlStoredQueryArgs;
    this.model = storedQuery
      ? DqlStoredQueryModel.fromQuery(storedQuery, query, storedQuery.parentId, this.dqlStoredQueryArgs.documentType)
      : DqlStoredQueryModel.fromScratch(query, this.dqlStoredQueryArgs.parentId, this.dqlStoredQueryArgs.documentType);
    this.saveDialogVisible = true;
    this.saveDialog.show();
    $('#dqlSaveDialog').appendTo($('body'));
    this.loadUserGroups(this.model.previousUserGroupIds);
  }

  initDropdown() {
    this.dropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(false)
      .enableSearchFilter(true)
      .enableCheckAll(true)
      .remoteSearch(true)
      .build();
  }

  ngOnInit() {
    this.initDropdown();
  }

  loadUserGroups(idSet?: Set<number>, q?: string) {
    this.userGroups = [];
    this.userGroupService.query({
      name: Strings.undefinedOrNonEmpty(q),
      page_number: 1,
      disabled: false,
      number_of_items: UiConstants.autocompletePageSize,
      order: Services.createOrderFieldParameter(UserGroup.Keys.toOrderFieldKey, Set.of(UserGroup.DEFAULT_ORDER)),
      no_progress_bar: true
    }).subscribe(result => {
      result.items.forEach(group => {
        const i = {
          itemName: group.name,
          id: group.id,
          disabled: group.disabled
        };
        this.userGroups.push(i);
        if (idSet && idSet.contains(i.id)) {
          this.model.userGroups.push(i);
        }
      });
      if (idSet && this.model.userGroups.length < idSet.size) {
        this.model.userGroups = [];
        this.userGroupService.query({
          id: Services.createIdParameter(idSet),
          order: Services.createOrderFieldParameter(UserGroup.Keys.toOrderFieldKey, Set.of(UserGroup.DEFAULT_ORDER))
        }).subscribe(result => {
          result.items.forEach(group => {
            const i = {
              itemName: group.name,
              id: group.id,
              disabled: group.disabled
            };
            this.model.userGroups.push(i);
          });

        });
      }
    });
  }

  closeSaveDialog() {
    this.querySaveForm.reset();
    this.saveDialogVisible = true;
    this.saveDialog.hide();
  }

  onModalHide(event: ModalDirective) {
    if (event.dismissReason === 'backdrop-click' || event.dismissReason === 'esc') {
      this.closeSaveDialog();
    }
  }

  deleteStoredQuery() {
    if (this.dqlStoredQueryArgs && this.model.id) {
      const id = this.model.id;
      this.dqlStoredQueryArgs.service.deleteDqlQuery(id)
        .subscribe(result => {
          this.onQuerySavedOrDeleted.emit(-1);
          this.closeSaveDialog();
        });
    }
  }

  saveQuery() {
    if (!this.querySaveForm.valid) {
      this.querySaveForm.get('name')!.markAsTouched();
      this.querySaveForm.get('userGroup')!.markAsTouched();
      return;
    }
    if (this.dqlStoredQueryArgs) {

      const request: DqlStoredQuery.DqlStoredQueryCreateRequest = {
        userGroupIds: this.model.selectedUserGroupIds,
        documentType: this.model.documentType,
        parentId: this.model.parentId,
        name: this.model.name,
        query: this.model.query
      };

      if (this.model.id) {
        this.dqlStoredQueryArgs.service.updateDqlQuery(this.model.id, request).subscribe(result => {
          this.onQuerySavedOrDeleted.emit(this.model.id);
          this.closeSaveDialog();
        });
      }
      else {
        this.dqlStoredQueryArgs.service.createDqlQuery(request).subscribe(result => {
          this.onQuerySavedOrDeleted.emit(result.id);
          this.closeSaveDialog();
        });
      }
    }
  }
}

class DqlStoredQueryModel {
  id?: number;
  name: string = '';
  query: string = '';
  parentId?: number;
  documentType?: string;
  userGroups: MultiselectOptionItem<number>[] = [];
  private _previousUserGroupIds: Set<number> = Set.of();

  get previousUserGroupIds(): Set<number> {
    return this._previousUserGroupIds;
  }

  get selectedUserGroupIds(): Set<number> {
    return Set.of(...this.userGroups.map(ug => ug.id));
  }

  static fromQuery(storedQuery: DqlStoredQuery.DqlStoredQuery, query: string, parentId?: number, documentType?: string):
    DqlStoredQueryModel {
    const model = new DqlStoredQueryModel();
    model.id = storedQuery.id;
    model.name = storedQuery.name;
    model.query = query;
    model.parentId = parentId;
    model.documentType = documentType;
    model._previousUserGroupIds = storedQuery.userGroupIds;
    return model;
  }

  static fromScratch(query: string, parentId?: number, documentType?: string):
    DqlStoredQueryModel {
    const model = new DqlStoredQueryModel();
    model.query = query;
    model.parentId = parentId;
    model.documentType = documentType;
    return model;
  }
}


