export class MemStorage {

  private static mem: Map<string, string> = new Map();

  static getItem(key: string): string | null {
    try {
      return localStorage.getItem(key);
    } catch (error) {
      return MemStorage.getItemFromMem(key);
    }
  }

  static setItem(key: string, data: string): void {
    try {
      MemStorage.setItemToMem(key, data);
      return localStorage.setItem(key, data);
    } catch (error) {
      return MemStorage.setItemToMem(key, data);
    }
  }

  private static getItemFromMem(key: string): string | null {
    const value = MemStorage.mem.get(key);
    return value === undefined ? null : value;
  }

  private static setItemToMem(key: string, data: string): void {
    MemStorage.mem.set(key, data);
  }

}
