/* eslint-disable */
import { Injectable } from '@angular/core';
import { Observable, Observer } from 'rxjs';
import { Order, PagingRequest, QueryResult, ResourceQueryResult, Services } from '../util/services';
import { DownloadedFile } from '../util/downloaded-files';
import { StockTakings } from './stock-takings.service';
import { List, Map, Set } from 'immutable';
import { OffsetDateTime } from '../util/dates';
import { Decimal } from 'decimal.js';
import { StockTakingSheetResource, StockTakingSheetResourceService } from './stock-taking-sheet-resource.service';
import { EmptyMessage } from '../util/messages';
import StockTakingSheetState = StockTakingSheet.StockTakingSheetState;
/* eslint-enable */

@Injectable()
export class StockTakingSheetService implements StockTakingSheet.Service {
  query(request: StockTakingSheet.QueryRequest): Observable<QueryResult<StockTakingSheet.StockTakingSheet>> {
    return Observable.create((observer: Observer<QueryResult<StockTakingSheet.StockTakingSheet>>) => {
      const resourceRequest: StockTakingSheetResource.QueryRequest = {
        with_items: request.withItems ? request.withItems : false,
        sheet_ids: Services.createIdParameter(request.sheetIdSet),
        assignee_user_id: request.assigneeUserId,
        trx_id: request.trxId,
        stock_taking_id: request.stockTakingId,
        stock_id: request.stockId,
        state: Services.createListParameter(request.state),
        order: Services.createOrderFieldParameter(Keys.toOrderFieldKey, request.orders),
        page_number: request.paging ? request.paging.pageNumber : undefined,
        number_of_items: request.paging ? request.paging.numberOfItems : undefined,
      };
      return this.resourceService.query(resourceRequest).subscribe(
        (result: ResourceQueryResult<StockTakingSheetResource.StockTakingSheet>) => {
          observer.next({
            items: this.toPublicList(result.items),
            pagingResult: result.pagingResult
          });
        });
    });
  }

  create(request: StockTakingSheet.CreateRequest): Observable<StockTakingSheet.CreateResponse> {
    return Observable.create((observer: Observer<StockTakingSheet.CreateResponse>) => {
      const resourceRequest: StockTakingSheetResource.CreateRequest = {
        stock_taking_id: request.stockTakingId,
        stock_id: request.stockId,
        assignee_user_id: request.assigneeUserId
      };
      return this.resourceService.create(resourceRequest).subscribe((result: StockTakingSheetResource.CreateResponse) => {
          observer.next({
            sheetId: result.sheet_id
          });
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  get(request: StockTakingSheet.GetRequest): Observable<StockTakingSheet.StockTakingSheet> {
    return Observable.create((observer: Observer<StockTakingSheet.StockTakingSheet>) => {
      const resourceRequest: StockTakingSheetResource.GetRequest = {
        sheet_id: request.sheetId,
      };
      return this.resourceService.get(resourceRequest).subscribe(
        (result: StockTakingSheetResource.StockTakingSheet) => {
          observer.next(this.toPublic(result));
        });
    });
  }

  update(request: StockTakingSheet.UpdateRequest): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      const resourceRequest: StockTakingSheetResource.UpdateRequest = {
        sheet_id: request.sheetId,
        assignee_user_id: request.assigneeUserId
      };
      return this.resourceService.update(resourceRequest).subscribe(
        (result: EmptyMessage) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  updateStock(request: StockTakingSheet.GetRequest): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      const resourceRequest: StockTakingSheetResource.GetRequest = {
        sheet_id: request.sheetId,
      };
      return this.resourceService.updateStock(resourceRequest).subscribe(
        (result: EmptyMessage) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  downloadPdf(stockTakingSheetId: number): Observable<DownloadedFile> {
    return this.resourceService.downloadPdf({
      sheetId: stockTakingSheetId
    });
  }

  exportXls(stockTakingSheetId: number): Observable<DownloadedFile> {
    return this.resourceService.exportXls({
      sheet_id: stockTakingSheetId
    });
  }

  constructor(
    private resourceService: StockTakingSheetResourceService,
  ) {}

  private toPublicList(resourceList: StockTakingSheetResource.StockTakingSheet[]): List<StockTakingSheet.StockTakingSheet> {
    return List.of(...resourceList.map((r) => this.toPublic(r)));
  }

  private toPublic(r: StockTakingSheetResource.StockTakingSheet): StockTakingSheet.StockTakingSheet {
    return {
      sheetId: r.sheet_id,
      stockTakingId: r.stock_taking_id,
      stockId: r.stock_id,
      trxId: r.trx_id,
      creationTime: Services.toOffsetDateTime(r.creation_time),
      updateTime: Services.toOffsetDateTime(r.update_time),
      assigneeUserId: r.assignee_user_id,
      state: <StockTakingSheetState> r.state,
      note: r.note,
      items: r.items ? List.of(...r.items.map((item) => this.toPublicStockTakingSheetItem(item))) : undefined
    };
  }

  private toPublicStockTakingSheetItem(r: StockTakingSheetResource.StockTakingSheetRecord): StockTakingSheet.StockTakingSheetRecord {
    return {
      itemId: r.item_id,
      stockItemId: r.stock_item_id,
      stockItemExternalId: r.stock_item_external_id,
      stockItemProductCode: r.stock_item_product_code,
      stockItemName: r.stock_item_name,
      stockItemUnit: r.stock_item_unit,
      stockItemSerialCode: r.stock_item_serial_code,
      amountFound: Services.toDecimal(r.amount_found) ? Services.toDecimal(r.amount_found) : undefined,
      stockAmount: Services.toDecimal(r.stock_amount) ? Services.toDecimal(r.stock_amount) : undefined,
  };
  }

}

export namespace StockTakingSheet {

  export interface Service {

    query(request: QueryRequest): Observable<QueryResult<StockTakingSheet>>;

    create(request: CreateRequest): Observable<CreateResponse>;

    get(request: GetRequest): Observable<StockTakingSheet>;

  }

  export interface StockTakingSheet {
    sheetId: number;
    stockTakingId: number;
    stockId: number;
    trxId: string;
    creationTime: OffsetDateTime;
    updateTime: OffsetDateTime;
    assigneeUserId: number;
    state: StockTakingSheetState;
    note?: string;
    items?: List<StockTakingSheetRecord>;
  }

  export interface StockTakingSheetRecord {
    itemId: number;
    stockItemId: number;
    stockItemExternalId: string;
    stockItemProductCode: string;
    stockItemName: string;
    stockItemUnit?: string;
    stockItemSerialCode?: string;
    amountFound?: Decimal;
    stockAmount?: Decimal;
  }

  export interface QueryRequest {
    withItems?: boolean;
    sheetIdSet?: Set<number>;
    assigneeUserId?: number;
    trxId?: string;
    stockTakingId?: number;
    stockId?: number;
    state?: Set<StockTakingSheetState>;
    orders?: Set<Order<OrderField>>;
    paging?: PagingRequest;
  }

  export interface GetRequest {
    sheetId: number;
  }

  export interface CreateRequest {
    stockTakingId: number;
    stockId: number;
    assigneeUserId: number;
  }

  export interface CreateResponse {
    sheetId: number;
  }

  export interface UpdateRequest {
    sheetId: number;
    assigneeUserId: number;
  }

  export type StockTakingSheetState = 'OPEN' | 'REOPEN' | 'IN_PROGRESS' | 'CLOSED' | 'UPDATED_STOCK';

  export class StockTakingSheetStateObject {
    state: StockTakingSheetState | null;
    stringKey: string;
    text: string;
  }

  export const stockTakingSheetStates: StockTakingSheetStateObject[] = [
    {state: 'OPEN', stringKey: 'STOCK_TAKING_SHEET_STATE_OPEN', text: ''},
    {state: 'REOPEN', stringKey: 'STOCK_TAKING_SHEET_STATE_REOPEN', text: ''},
    {state: 'IN_PROGRESS', stringKey: 'STOCK_TAKING_SHEET_STATE_IN_PROGRESS', text: ''},
    {state: 'CLOSED', stringKey: 'STOCK_TAKING_SHEET_STATE_CLOSED', text: ''},
    {state: 'UPDATED_STOCK', stringKey: 'STOCK_TAKING_SHEET_STATE_UPDATED_STOCK', text: ''},
  ];

  export enum OrderField {
    CREATION_TIME,
    TRX_ID
  }

}

class Keys {

  private static readonly CREATION_TIME = 'creation_time';
  private static readonly TRX_ID = 'trx_id';

  private static readonly orderFieldKeyMap: Map<StockTakings.OrderField, string> = Map.of(
    StockTakings.OrderField.CREATION_TIME, Keys.CREATION_TIME,
    StockTakings.OrderField.TRX_ID, Keys.TRX_ID
  );

  public static toOrderFieldKey(field: StockTakings.OrderField): string {
    return Keys.orderFieldKeyMap.get(field)!;
  }

}
