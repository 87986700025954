<div class="breadcrumb-container" [ngClass]="{ 'breadcrumb-container-compact': compactSidebar }" id="breadcrumb">
  <div class="breadcrumb-component">
    <app-breadcrumb
      [parents]="breadcrumbParents"
      [self]="breadcrumbSelf">
    </app-breadcrumb>
  </div>
</div>
<div class="breadcrumb-placeholder"></div>
<div class="container-horizontal-padding container-vertical-padding animated fadeIn">

  <div class="card col-lg-8 col-md-8 col-xs-12 col-sm-12 p-0">

    <div class="card-header">
      {{'MASTERDATA_PANEL_HEADING_ADD'|translate}}
    </div>

    <div class="card-body">
    <form class="form-horizontal bordered-row" (ngSubmit)="f.form.valid && createMasterData()" #f="ngForm" novalidate>

      <div class="form-group row" [ngClass]="{ 'has-danger': hasFieldError(MasterData.ValidatedField.NAME) || (f.submitted && !name.valid) }">
        <label class="col-form-label form-control-label col-md-4 detail-title required-field-label">
          {{'COMMON_NAME' | translate}}
        </label>
        <div class="col-md-8">
          <input type="text" class="form-control" placeholder="{{'COMMON_NAME' | translate}}"
                 maxlength="{{UiConstants.maximumVarcharLength}}"
                 [ngClass]="{ 'form-control-danger': hasFieldError(MasterData.ValidatedField.NAME) || (f.submitted && !name.valid) }"
                 [(ngModel)]="model.name" (ngModelChange)="removeFieldError(MasterData.ValidatedField.NAME)" name="name" id="name" #name="ngModel" required>
          <div class="form-control-feedback" *ngIf="hasFieldError(MasterData.ValidatedField.NAME)">{{getFieldErrorText(MasterData.ValidatedField.NAME)}}</div>
          <div class="form-control-feedback" *ngIf="f.submitted && !name.valid">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</div>
        </div>
      </div>

      <div class="form-group row" [ngClass]="{ 'has-danger': hasFieldError(MasterData.ValidatedField.EXTERNAL_ID) }">
        <label class="col-form-label form-control-label col-md-4 detail-title">
          {{'COMMON_EXTERNAL_ID' | translate}}
        </label>
        <div class="col-md-8">
          <input type="text" class="form-control" placeholder="{{'COMMON_EXTERNAL_ID' | translate}}"
                 maxlength="{{UiConstants.maximumVarcharLength}}"
                 [ngClass]="{ 'form-control-danger': hasFieldError(MasterData.ValidatedField.EXTERNAL_ID) }"
                 [ngModelOptions]="{standalone: true}"
                 [(ngModel)]="model.externalId" (ngModelChange)="removeFieldError(MasterData.ValidatedField.EXTERNAL_ID)" name="external_id" id="external_id" #external_id="ngModel">
          <div class="form-control-feedback" *ngIf="hasFieldError(MasterData.ValidatedField.EXTERNAL_ID)">{{getFieldErrorText(MasterData.ValidatedField.EXTERNAL_ID)}}</div>
        </div>
      </div>

      <div class="form-group row">
        <label class="col-form-label form-control-label col-md-4 detail-title">{{'COMMON_DESCRIPTION' | translate}}</label>
        <div class="col-md-8">
          <input type="text" class="form-control" placeholder="{{'COMMON_DESCRIPTION' | translate}}"
                 maxlength="{{UiConstants.maximumVarcharLength}}"
                 [(ngModel)]="model.description" name="description" id="description" #description="ngModel">
        </div>
      </div>

      <div class="form-group row" [ngClass]="{ 'has-danger': hasFieldError(MasterData.ValidatedField.CLIENT_REQUIRED_ID_FIELD) || (f.submitted && !requiredMasterDataId.valid) }">
        <label class="col-form-label form-control-label col-md-4 detail-title required-field-label">
          {{'MASTERDATA_REQUIRED_ID_FIELD' | translate}}
        </label>
        <div class="col-md-8">
          <select
            class="form-control"
            [(ngModel)]="model.requiredMasterDataId"
            [compareWith]="SelectUtils.compareStrings"
            [ngClass]="{ 'form-control-danger': hasFieldError(MasterData.ValidatedField.CLIENT_REQUIRED_ID_FIELD) || (f.submitted && !requiredMasterDataId.valid) }"
            name="requiredMasterDataId"
            id="requiredMasterDataId"
            #requiredMasterDataId="ngModel"
            required>
            <option *ngFor="let item of RequiredMasterDataIdsItems"
                    [ngValue]="item.id">{{item.name | translate}}</option>
          </select>
          <div class="form-control-feedback" *ngIf="f.submitted && !requiredMasterDataId.valid">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</div>
        </div>
      </div>

      <!-- region User groups -->
      <div class="form-group row"
           [ngClass]="{ 'has-danger': hasFieldError(MasterData.ValidatedField.OWNER_USER_GROUPS) || (f.submitted && !ownerGroups.valid) }">
        <div class="col-md-4">
          <label class="mw-100 col-form-label form-control-label required-field-label">
            {{'MASTERDATA_USER_GROUPS' | translate}}
            <i class="icomoon icomoon-info info-icon" popover="{{'MASTERDATA_USER_GROUPS_HINT' | translate}}"
               triggers="mouseenter:mouseleave" container="body" placement="right">
            </i>
          </label>
        </div>
        <div class="col-md-8">
          <angular2-multiselect
            [(ngModel)]="model.userGroups"
            [ngClass]="{ 'has-danger': hasFieldError(MasterData.ValidatedField.OWNER_USER_GROUPS) || (f.submitted && !ownerGroups.valid) }"
            (ngModelChange)="removeFieldError(MasterData.ValidatedField.OWNER_USER_GROUPS)"
            (onRemoteSearch)="loadUserGroups($event)"
            name="ownerGroups" id="ownerGroups" #ownerGroups="ngModel" required
            [data]="model.userGroupItems"
            [settings]="userGroupDropdownSettings">
          </angular2-multiselect>
          <div class="form-control-feedback" *ngIf="f.submitted && !ownerGroups.valid">
            {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
          </div>
          <div class="form-control-feedback" *ngIf="hasFieldError(MasterData.ValidatedField.OWNER_USER_GROUPS)">
            {{getFieldErrorText(MasterData.ValidatedField.OWNER_USER_GROUPS)}}
          </div>
        </div>
      </div>
      <!-- endregion User groups -->

      <!-- region Mobile owner type -->
      <div class="form-group row"
           [ngClass]="{ 'has-danger': hasFieldError(MasterData.ValidatedField.MOBILE_OWNER_TYPE) || (f.submitted && !mobileOwnerType.valid) }">
        <div class="col-md-4">
          <label class="mw-100 col-form-label form-control-label required-field-label">
            {{'MASTERDATA_MOBILE_OWNER_TYPE' | translate}}
            <i class="icomoon icomoon-info info-icon" popover="{{'MASTERDATA_MOBILE_OWNER_TYPE_HINT' | translate}}"
               triggers="mouseenter:mouseleave" container="body" placement="right">
            </i>
          </label>
        </div>
        <div class="col-md-8">
          <div class="mw-100">
            <select type="text" class="form-control"
                    [ngClass]="{ 'form-control-danger': hasFieldError(MasterData.ValidatedField.MOBILE_OWNER_TYPE) || (f.submitted && !mobileOwnerType.valid) }"
                    [(ngModel)]="model.mobileOwnerType"
                    (ngModelChange)="removeFieldError(MasterData.ValidatedField.MOBILE_OWNER_TYPE)"
                    name="mobileOwnerType" id="mobileOwnerType" #mobileOwnerType="ngModel" required>
              <option *ngFor="let mobileOwnerType of ownerTypes" [ngValue]="mobileOwnerType">
                {{mobileOwnerType.text}}
              </option>
            </select>
            <div class="form-control-feedback" *ngIf="hasFieldError(MasterData.ValidatedField.MOBILE_OWNER_TYPE)">
              {{getFieldErrorText(MasterData.ValidatedField.MOBILE_OWNER_TYPE)}}
            </div>
            <div class="form-control-feedback" *ngIf="f.submitted && !mobileOwnerType.valid">
              {{'COMMON_VALIDATION_MESSAGE_REQUIRED'
              | translate}}
            </div>
          </div>
        </div>
      </div>
      <!-- endregion Mobile owner type -->

      <div>
        <div class="edit-form-button-group pl-0">
          <input type="submit" class="btn btn-primary" value="{{'COMMON_BUTTON_SAVE'|translate}}"/>
        </div>
      </div>

    </form>
    </div>
  </div>

</div>
