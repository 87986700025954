import {Injectable} from '@angular/core';
import {MultiselectProvider} from '../multiselect-provider';
import {Observable, Observer} from 'rxjs';
import {MultiselectOptionItem, UiConstants} from '../../util/core-utils';
import {OrderType, ResourceQueryResult, Services} from '../util/services';
import {Set} from 'immutable';
import {UserGroup, UserGroupQuery, UserGroupService} from '../user-group.service';

@Injectable()
export class UserGroupMultiselectProvider implements MultiselectProvider {

  private multiselectQueryRequest: UserGroupQuery = {
    page_number: 1,
    number_of_items: UiConstants.autocompletePageSize,
    no_progress_bar: true,
    order: Services.createOrderFieldParameter(UserGroup.Keys.toOrderFieldKey,
      Set.of({field: UserGroup.OrderField.NAME, type: OrderType.ASC})),
    disabled: undefined,
    fields: ['id', 'name', 'disabled'].join(','),
    q: undefined
  };

  constructor(private userGroupService: UserGroupService) {
  }

  load(queryRequest: UserGroupQuery): Observable<MultiselectOptionItem<number>[]> {
    return Observable.create((observer: Observer<MultiselectOptionItem<number>[]>) => {
      this.userGroupService.query(queryRequest).subscribe((result: ResourceQueryResult<UserGroup>) => {
          observer.next(result.items.map(item => this.toMultiselectOptionItem(item!)));
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  loadAll(predicate?: string): Observable<MultiselectOptionItem<number>[]> {
    const queryRequest = Object.assign({}, this.multiselectQueryRequest);
    queryRequest.q = predicate;
    return this.load(queryRequest);
  }

  loadActive(predicate?: string): Observable<MultiselectOptionItem<number>[]> {
    const queryRequest = Object.assign({}, this.multiselectQueryRequest);
    queryRequest.q = predicate;
    queryRequest.disabled = false;
    return this.load(queryRequest);
  }

  loadActiveWithIdSet(id: Set<number>, predicate?: string): Observable<MultiselectOptionItem<number>[]> {
    const queryRequest = Object.assign({}, this.multiselectQueryRequest);
    queryRequest.q = predicate;
    queryRequest.id = Services.createIdParameter(id);
    queryRequest.disabled = false;
    return this.load(queryRequest);
  }

  getById(id: number): Observable<MultiselectOptionItem<number>> {
    return Observable.create((observer: Observer<MultiselectOptionItem<number>>) => {
      this.userGroupService.get({
        id: id
      }).subscribe((result: UserGroup) => {
          observer.next(this.toMultiselectOptionItem(result!));
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  getByIds(ids: number[]): Observable<MultiselectOptionItem<number>[]> {
    const queryRequest = Object.assign([], this.multiselectQueryRequest);
    queryRequest.id = ids.join(',');
    return this.load(queryRequest);
  }

  toMultiselectOptionItem(item: UserGroup): MultiselectOptionItem<number> {
    const ret = new MultiselectOptionItem<number>();
    ret.id = item.id;
    ret.itemName = item.name;
    ret.itemSubtitle = item.companies ? item.companies
        .sort((a, b) => a.name.localeCompare(b.name))
        .map(c => c.name).join(', ')
      : undefined;
    ret.disabled = item.disabled;
    return ret;
  }

}
