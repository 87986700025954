/* eslint-disable */
import { Injectable } from '@angular/core';
import {
  HTTP_INTERCEPTORS,
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { bindNodeCallback, Observable, throwError } from 'rxjs';
import { BaseHttpService, EchoHeader, HeaderKeys, HeaderValues, ServiceErrorCode } from './http-services';
import { SettingsService } from '../settings.service';
import { catchError, finalize, flatMap, map, tap } from 'rxjs/operators';
import { LoadingHandler } from '../loading-handler';
import { Strings } from './strings';
import { StateName } from '../../app.state-names';
import { UIRouter } from '@uirouter/core';
import { Logger, LoggerFactory } from '../../util/logger-factory';
import {
  AuthTokenStorage,
  LocalStorages,
  LoginRequiredReason,
  LoginRequiredReasonStorage,
  RecaptchaV3TokenStorage,
  ServiceErrorStorage
} from './storages';
import { ErrorResource } from './errors';
import { ToasterService } from '../../fork/angular2-toaster/src/toaster.service';
import { UiConstants } from '../../util/core-utils';
import { ErrorDetail, ErrorMessageService } from '../error-message-parser.service';
import { ShopRenterStorage } from '../shoprenter/shoprenter.service';
import { BodyOutputType } from '../../fork/angular2-toaster/src/bodyOutputType';
import {AppTypeHelperService} from "./app-type-helper.service";

/* eslint-enable */


@Injectable()
export class NoopInterceptor implements HttpInterceptor {

  intercept(req: HttpRequest<any>, next: HttpHandler):
    Observable<HttpEvent<any>> {
    return next.handle(req);
  }
}

@Injectable()
export class HeaderRequestInterceptor implements HttpInterceptor {

  constructor(private settingsService: SettingsService,
              private appTypeHelperService: AppTypeHelperService) {

  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const cloned = req.clone({
      headers: this.createHeaders(req)
    });
    if (!Strings.contains(req.urlWithParams, LoadingHandler.HANDLER_OFF)) {
      LoadingHandler.getInstance().onRequest();
    }
    if (Strings.contains(req.urlWithParams, BaseHttpService.LOCATION_ON)) {
      return this.tryCreateRequestWithLocation(cloned, next);
    } else {
      return next.handle(cloned);
    }
  }

  private createHeaders(req: HttpRequest<any>) {
    const token = AuthTokenStorage.getInstance().getToken(this.appTypeHelperService.appType());
    const lang = this.settingsService.getLanguageCode();
    const shopRenterStorage = ShopRenterStorage.getInstance();
    const recpatchaV3TokenStorage = RecaptchaV3TokenStorage.getInstance();
    let headers = req.headers;
    if (token) {
      headers = headers.set(HeaderKeys.AUTHORIZATION, token);
    }
    if (lang) {
      headers = headers.set(HeaderKeys.ACCEPT_LANGUAGE, lang);
    }
    if (shopRenterStorage.isShopRenterTokenAvailable()) {
      const value = JSON.stringify(shopRenterStorage.getShopRenterTokenResource());
      if (value) {
        headers = headers.set(HeaderKeys.SHOPRENTER_TOKEN, value);
      }
    }
    if (recpatchaV3TokenStorage.isTokenAvailable()) {
      const token = recpatchaV3TokenStorage.getToken();
      if (token) {
        headers = headers.set(HeaderKeys.RECAPTCHA_TOKEN_V3, token);
      }
    }
    return headers;
  }

  private tryCreateRequestWithLocation(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (window.navigator.geolocation) {
      // bindNodeCallback() is used instead of bindCallback() because this way we are also able to manage errors
      const positionObservable: Observable<GeolocationPosition | undefined>
        = bindNodeCallback(this.getCurrentPosition)();
      return positionObservable.pipe(flatMap(pos => {
        const cloned = req.clone({
          headers: req.headers.set(HeaderKeys.GPS_LATITUDE, pos!.coords.latitude.toString())
            .set(HeaderKeys.GPS_LONGITUDE, pos!.coords.longitude.toString())
        });
        return next.handle(cloned);
      })).pipe(catchError((err, caught) => {
        // this happens if the user denies location access
        return next.handle(req);
      }));
    } else {
      // this happens if the browser doesn't support location services
      return next.handle(req);
    }
  }

  private getCurrentPosition(callback: PositionCallback) {
    /* Set up getCurrentPosition options with a timeout */
    const navigatorLocationOptions = {
      enableHighAccuracy: true,
      timeout: 5000,
      maximumAge: 0
    };
    // this function rewrite is needed because rxjs' bindNodeCallback() only works with this specific signature
    window.navigator.geolocation.getCurrentPosition(
      pos => callback(undefined, pos),
        err => callback(err),
      navigatorLocationOptions);
  }
}

type PositionCallback = (error?: GeolocationPositionError, position?: GeolocationPosition) => void;

@Injectable()
export class HeaderResponseInterceptor implements HttpInterceptor {

  private logger: Logger;

  constructor(
    private uiRouter: UIRouter,
    private toasterService: ToasterService,
    private errorMessageService: ErrorMessageService
  ) {
    this.logger = LoggerFactory.createLogger('HeaderResponseInterceptor');
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap(event => {
        if (event instanceof HttpResponse) {
          if (event.headers) {
            const newToken: string | null = event.headers.get(HeaderKeys.AUTHORIZATION);
            if (newToken) {
              AuthTokenStorage.getInstance().setToken(newToken);
            }
          }
        }
      }), catchError((error) => {
        this.parseError(req, error);
        return throwError(error);
      }), finalize(() => {
        if (!Strings.contains(req.urlWithParams, LoadingHandler.HANDLER_OFF)) {
          LoadingHandler.getInstance().onResponse();
        }
      })
    );
  }

  private parseError(req: HttpRequest<any>, err: any) {
    if (err instanceof HttpErrorResponse) {
      if (err.error instanceof Blob) {
        // nextline: support for iOS webkit errors (because those also contain the charset at the end of the type)
        const types = err.error.type.split(';');
        if (types[0] === 'application/json') {
          // https://github.com/angular/angular/issues/19888
          // When request of type Blob, the error is also in Blob instead of object of the json data
          const prom = new Promise<any>((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (e: Event) => {
              try {
                const errmsg = JSON.parse((<any>e.target).result);
                const newErr = new HttpErrorResponse({
                  error: errmsg,
                  headers: err.headers,
                  status: err.status,
                  statusText: err.statusText,
                  url: err.url ? err.url : undefined
                });
                reject(newErr);
              } catch (e) {
                reject(err);
              }
            };
            reader.onerror = (e) => {
              reject(err);
            };
            reader.readAsText(err.error);
          }).catch(e => {
            this.onError(req, e);
          });
        }
      } else {
        this.onError(req, err);
      }
    }
  }


  private onError(request: HttpRequest<any>, error: HttpErrorResponse) {
    this.logger.error(error);
    const searchUrl: string = request.urlWithParams;
    const headers: HttpHeaders = request.headers;
    const echoHeader: EchoHeader | undefined = HeaderValues.parseEchoHeader(headers.get(HeaderKeys.ECHO));
    let body: ErrorResource | undefined;
    const router = this.uiRouter;
    body = error.error;
    if (body && body.error) {
      // Remote error
      switch (body.error) {
        case ServiceErrorCode.REQUEST_VALIDATION_ERROR:
          // Handle localized errors here, handle field errors in the corresponding component
          if (!echoHeader || (echoHeader && !echoHeader.ignore_global_errors)) {
            this.defaultLocalizedErrorHandling(error);
          }
          break;
        case ServiceErrorCode.CONFLICT:
          // Show custom conflict error(s) on the page - skip!
          break;
        case ServiceErrorCode.USER_AUTHENTICATION_ERROR:
          // Show login failed on the login page - skip!
          break;
        case ServiceErrorCode.UNAUTHORIZED_ACCESS_INVALID_ACCESS_TOKEN:
          // Save reason (invalid access token), logout
          LoginRequiredReasonStorage.getInstance().setReason(LoginRequiredReason.INVALID_ACCESS_TOKEN, {
            stateName: router.stateService.$current.name,
            params: router.stateService.params
          });
          LocalStorages.onLogout();
          if (!ServiceErrorStorage.getInstance().permissionDeniedHandlingActive) {
            router.stateService.go(StateName.getLoginStateName(this.uiRouter.stateService.$current.name));
          }
          break;
        case ServiceErrorCode.UNAUTHORIZED_ACCESS_UNKNOWN_USER:
          // Save reason (unknown user), logout
          LoginRequiredReasonStorage.getInstance().setReason(LoginRequiredReason.UNKNOWN_USER, {
            stateName: router.stateService.$current.name,
            params: router.stateService.params
          });
          LocalStorages.onLogout();
          router.stateService.go(StateName.getLoginStateName(this.uiRouter.stateService.$current.name));
          break;
        case ServiceErrorCode.FUNCTION_DISABLED:
          if (!echoHeader || (echoHeader && !echoHeader.ignore_function_disabled)) {
            if (router.stateService.$current.name === StateName.LOGIN
              || router.stateService.$current.name === StateName.HELPDESK_LOGIN) {
              this.loginDisabledHandling(router);
            } else {
              this.defaultErrorHandling(router, error, body);
            }
          }
          break;
        case ServiceErrorCode.UNAUTHORIZED_ACCESS_PERMISSION_DENIED:
          if (!echoHeader || (echoHeader && !echoHeader.ignore_permission_denied)) {
            // Permission denied. Show the error screen, invalidate token (to go to login after error screen)
            ServiceErrorStorage.getInstance().permissionDeniedHandlingActive = true;
            LocalStorages.onLogout();
            this.defaultErrorHandling(router, error, body);
          }
          break;
        case ServiceErrorCode.ENTITY_NOT_FOUND:
          if (!echoHeader || (echoHeader && !echoHeader.ignore_entity_not_found)) {
            this.defaultErrorHandling(router, error, body);
          }
          break;
        default:
          // Show server error page (that contains a back to main page button - if retryable)
          this.defaultErrorHandling(router, error, body);
          break;
      }
    } else {
      // Local error (connection error, server is not running, war not deployed, fatal server error etc)
      // Show server unavailable page (that contains a refresh button)
      this.connectionErrorHandling(router);
    }
  }

  private connectionErrorHandling(router) {
    ServiceErrorStorage.getInstance().setConnectionError({}, {
      stateName: router.stateService.$current.name,
      params: router.stateService.params
    });
    router.stateService.go(StateName.CONNECTION_ERROR);
  }

  private defaultErrorHandling(router, error, body) {
    // Show server error page (that contains a back to main page button - if retryable)
    ServiceErrorStorage.getInstance().setServerError({
      httpCode: error.status.valueOf(),
      error: body
    }, {
      stateName: router.stateService.$current.name,
      params: router.stateService.params
    });
    router.stateService.go(StateName.SERVER_ERROR);
  }

  private defaultLocalizedErrorHandling(error) {
    const msg = this.errorMessageService.parseError(error);
    if (msg && !msg.globalErrors.isEmpty()) {
      this.errorMessageService.localize(msg).subscribe((detail: ErrorDetail) => {
        this.toasterService.pop({
          timeout: UiConstants.ToastTimeoutLong,
          type: UiConstants.toastTypeError,
          bodyOutputType: BodyOutputType.TrustedHtml,
          title: detail.title,
          body: detail.message.replace('\n', '<br/>')
        });
      });
    }
  }

  private loginDisabledHandling(router) {
    router.stateService.go(StateName.LOGIN_DISABLED);
  }
}

@Injectable()
export class ArrayHeaderResponseInterceptor implements HttpInterceptor {

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(map(event => {
      if (event instanceof HttpResponse) {
        if (event.headers) {
          const otherHeaders = new Map();
          if (event.headers.has(HeaderKeys.PLACE_AUTOCOMPLETE_TOKEN)) {
            otherHeaders.set(HeaderKeys.PLACE_AUTOCOMPLETE_TOKEN, event.headers.get(HeaderKeys.PLACE_AUTOCOMPLETE_TOKEN));
          }
          if (event.headers.has(HeaderKeys.TOTAL_NUMBER_OF_ITEMS)) {
            return event.clone({
              body:
                {
                  items: event.body,
                  pagingResult: {
                    numberOfPages: this.parsePagingNumber(event.headers.get(HeaderKeys.NUMBER_OF_PAGES)),
                    currentNumberOfItems: this.parsePagingNumber(event.headers.get(HeaderKeys.CURRENT_NUMBER_OF_ITEMS)),
                    totalNumberOfItems: this.parsePagingNumber(event.headers.get(HeaderKeys.TOTAL_NUMBER_OF_ITEMS))
                  },
                  otherHeaders: otherHeaders
                }
            });
          }
        }
      }
      return event;
    }));
  }

  private parsePagingNumber(header: string | null): number {
    if (header) {
      return Number(header);
    }
    return 0;
  }

}

export const HTTP_INTERCEPTOR_PROVIDER = [
  {provide: HTTP_INTERCEPTORS, useClass: NoopInterceptor, multi: true},
  {provide: HTTP_INTERCEPTORS, useClass: HeaderRequestInterceptor, multi: true},
  {provide: HTTP_INTERCEPTORS, useClass: HeaderResponseInterceptor, multi: true},
  {provide: HTTP_INTERCEPTORS, useClass: ArrayHeaderResponseInterceptor, multi: true},
];
