import { DqlQuery, Query } from '../field';

export class User {

  readonly id: Query.OrderField;
  readonly userName: Query.OrderField;
  readonly personName: Query.OrderField;

  constructor(prefix?: string) {
    prefix = prefix ? prefix : '';
    this.id = new DqlQuery.OrderField(prefix + 'id');
    this.userName = new DqlQuery.OrderField(prefix + 'user_name');
    this.personName = new DqlQuery.OrderField(prefix + 'person_name');
  }

}
