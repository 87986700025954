<div class="breadcrumb-container" [ngClass]="{ 'breadcrumb-container-compact': compactSidebar }" id="breadcrumb">
  <div class="breadcrumb-component">
    <app-breadcrumb
      [self]="breadcrumbSelf">
    </app-breadcrumb>
  </div>
  <app-breadcrumb-menu>
    <app-breadcrumb-button
      *ngIf="rightModel.invoiceSpendingCreate.hasRight() && validInvoicesSelectedForSettlement"
      [iconClass]="'icomoon-price'"
      [titleStringKey]="'INVOICE_SETTLE'"
      (click)="openBatchSettlementDialog(selectedInvoices)">
    </app-breadcrumb-button>
    <app-breadcrumb-button
      *ngIf="rightModel.invoiceAction.hasRight() && rightModel.invoiceTagRead.hasRight() && anyInvoiceSelected"
      [iconClass]="'icomoon-price'"
      [titleStringKey]="'INVOICE_ADD_TAG'"
      (click)="openInvoiceTaggerDialog(selectedInvoices, 'SET')">
    </app-breadcrumb-button>
    <app-breadcrumb-dropdown>
      <app-dropdown-item
        *ngIf="rightModel.invoiceAction.hasRight() && rightModel.invoiceTagRead.hasRight() && anyInvoiceSelected"
        [iconClass]="'icomoon-order-import-error-rejected'"
        [titleStringKey]="'INVOICE_CLEAR_TAG'"
        (click)="openInvoiceTaggerDialog(selectedInvoices, 'CLEAR')">
      </app-dropdown-item>
    </app-breadcrumb-dropdown>
    <app-breadcrumb-button
      *ngIf="rightModel.invoiceRead.hasRight()"
      [iconClass]="'icomoon-download'"
      [titleStringKey]="'COMMON_BUTTON_DOWNLOAD'"
      (click)="openBatchDownloadDialog()">
    </app-breadcrumb-button>
    <app-breadcrumb-button
      *ngIf="rightModel.invoiceRead.hasRight()"
      [iconClass]="'icomoon-download'"
      [titleStringKey]="'COMMON_EXPORT_XLSX'"
      (click)="exportXlsx()">
    </app-breadcrumb-button>
    <app-breadcrumb-button
      *ngIf="supExportEnabled && rightModel.invoiceRead.hasRight()"
      [iconClass]="'icomoon-invoice'"
      [titleStringKey]="'TASK_RECORD_EXPORT_SUP_INVOICE'"
      (click)="exportSupInvoice()">
    </app-breadcrumb-button>
    <app-breadcrumb-button
      *ngIf="rightModel.invoiceCreate.hasRight()"
      [iconClass]="'icomoon-add'"
      [titleStringKey]="'INVOICE_CREATE_OUTBOUND'"
      (click)="onCreateInvoice('OUTBOUND')">
    </app-breadcrumb-button>
    <app-breadcrumb-dropdown>
      <app-dropdown-item
        *ngIf="rightModel.invoiceCreate.hasRight()"
        [iconClass]="'icomoon-add'"
        [titleStringKey]="'INVOICE_CREATE_INBOUND'"
        (click)="onCreateInvoice('INBOUND')">
      </app-dropdown-item>
    </app-breadcrumb-dropdown>
  </app-breadcrumb-menu>
</div>
<div class="breadcrumb-placeholder"></div>
<div class="container-horizontal-padding container-vertical-padding animated fadeIn">

  <app-invoice-balance-detail *ngIf="searchModel.advancedMode && rightModel.invoiceBalanceRead.hasRight()">
  </app-invoice-balance-detail>
  <div class="container-vertical-padding-half"></div>

  <div class="card">

    <div class="card-header">
      {{'INVOICES_LIST_HEADING' | translate}}

      <div class="card-actions">
        <a class="btn-setting cursor-pointer" (click)="toggleAdvancedMode()"
           title="{{'INVOICE_BALANCE_MODE' | translate}}"><i #searchIcon
                                                             class="icomoon icomoon-insurance-payment-payroll"></i></a>
        <a class="btn-setting cursor-pointer" (click)="toggleSearch()"
           title="{{'COMMON_SHOW_SEARCH_HINT' | translate}}"><i #searchIcon
                                                                class="icomoon icomoon-search"></i></a>
      </div><!-- /.card-actions -->
    </div>

    <div class="card-body border-bottom" *ngIf="showSearch">
      <div class="row form-group">
        <!--INVOICE NUMBER-->
        <div class="col-lg-3 col-md-4">
          <label class="search-label">{{'INVOICES_TABLE_HEADER_INVOICE_NUMBER' | translate}}</label>
          <input type="text" class="form-control" placeholder="{{'INVOICES_TABLE_HEADER_INVOICE_NUMBER' | translate}}"
                 maxlength="{{UiConstants.maximumVarcharLength}}"
                 (keyup.enter)="onSearchClicked()"
                 [(ngModel)]="searchModel.invoiceNumber" name="invoiceNumber" id="invoiceNumber"
                 #invoiceNumber="ngModel">
        </div>
        <!--ASSIGNEE USER-->
        <div class="col-lg-3 col-md-4">
          <label class="search-label"> {{ 'INVOICE_SEARCH_ASSIGNEE_USER' | translate }} </label>
          <angular2-multiselect [data]="assigneeUsers" [settings]="remoteSearchDropdownSettings"
                                class="form-control"
                                [(ngModel)]="searchModel.assigneeUsers"
                                (onRemoteSearch)="loadAssigneeUsers($event.target.value)"
                                #assigneeUsersInput="ngModel" name="assigneeUsers" id="assigneeUsers">
          </angular2-multiselect>
        </div>
        <!--CUSTOMER NAME-->
        <div class="col-lg-3 col-md-4">
          <label class="search-label">{{'INVOICES_TABLE_HEADER_CUSTOMER' | translate}}</label>
          <input type="text" class="form-control" placeholder="{{'INVOICES_TABLE_HEADER_CUSTOMER' | translate}}"
                 maxlength="{{UiConstants.maximumVarcharLength}}"
                 (keyup.enter)="onSearchClicked()"
                 [(ngModel)]="searchModel.customerName" name="customerName" id="customerName" #customerName="ngModel">
        </div>
        <!--CREATOR USER-->
        <div class="col-lg-3 col-md-4">
          <label class="search-label"> {{ 'INVOICE_SEARCH_CREATOR_USER' | translate }} </label>
          <angular2-multiselect [data]="assigneeUsers" [settings]="remoteSearchDropdownSettings"
                                class="form-control"
                                [(ngModel)]="searchModel.creatorUsers"
                                (onRemoteSearch)="loadAssigneeUsers($event.target.value)"
                                #creatorUsersInput="ngModel" name="creatorUsers" id="creatorUsers">
          </angular2-multiselect>
        </div>
        <!--INVOICE BOOK-->
        <div class="col-lg-3 col-md-4" *ngIf="rightModel.invoiceBookRead.hasRight()">
          <label class="search-label"> {{ 'INVOICE_BOOK_HEADING_LIST' | translate }} </label>
          <angular2-multiselect [data]="invoiceBooks" [settings]="remoteSearchDropdownSettings"
                                class="form-control"
                                (onRemoteSearch)="onInvoiceBookSearch($event.target.value)"
                                [(ngModel)]="searchModel.invoiceBooks"
                                #invoiceBookInput="ngModel" name="invoiceBook" id="invoiceBook">
          </angular2-multiselect>
        </div>
        <!--INVOICE SETTINGS-->
        <div class="col-lg-3 col-md-4" *ngIf="rightModel.invoiceSettingsRead.hasRight()">
          <label class="search-label"> {{ 'INVOICE_CREATE_INVOICE_SETTINGS' | translate }} </label>
          <angular2-multiselect [data]="invoiceSettings" [settings]="remoteSearchDropdownSettings"
                                class="form-control"
                                [(ngModel)]="searchModel.invoiceSettings"
                                (onRemoteSearch)="onInvoiceSettingsSearch($event.target.value)"
                                #invoiceSettingsInput="ngModel" name="invoiceSettings" id="invoiceSettings">
          </angular2-multiselect>
        </div>
        <!--INVOICE TAG-->
        <div class="col-lg-3 col-md-4" *ngIf="rightModel.invoiceTagRead.hasRight()">
          <label class="search-label"> {{ 'INVOICE_CREATE_INVOICE_TAG' | translate }} </label>
          <angular2-multiselect [data]="invoiceTags" [settings]="remoteSearchDropdownSettings"
                                class="form-control"
                                [(ngModel)]="searchModel.invoiceTags"
                                (onRemoteSearch)="onInvoiceTagSearch($event.target.value)"
                                #invoiceTagInput="ngModel" name="invoiceTag" id="invoiceTag">
          </angular2-multiselect>
        </div>
        <div class="col-lg-3 col-md-4">
          <label class="search-label"> {{ 'INVOICE_NAV_EXPORT_STATE' | translate }} </label>
          <angular2-multiselect [data]="exportStates" [settings]="dropdownSettingsForExportStates"
                                class="form-control"
                                [(ngModel)]="searchModel.exportStates"
                                #exportState="ngModel" name="exportState" id="exportState">
          </angular2-multiselect>
        </div>
        <div class="col-lg-3 col-md-4">
          <label class="search-label"> {{ 'INVOICE_SETTINGS_DIRECTION_TYPE' | translate }} </label>
          <angular2-multiselect [data]="directionTypes" [settings]="dropdownSettingsForDirectionTypes"
                                class="form-control"
                                [(ngModel)]="searchModel.directionTypes"
                                #directionType="ngModel" name="directionType" id="directionType">
          </angular2-multiselect>
        </div>
        <div class="col-lg-3 col-md-4">
          <label class="search-label"> {{ 'INVOICE_CREATE_PAYMENT_TYPE' | translate }} </label>
          <angular2-multiselect [data]="paymentTypes" [settings]="dropdownSettingsForDirectionTypes"
                                class="form-control"
                                [(ngModel)]="searchModel.paymentTypes"
                                #paymentType="ngModel" name="paymentType" id="paymentType">
          </angular2-multiselect>
        </div>
        <div class="col-lg-3 col-md-4">
          <label class="search-label"> {{ 'INVOICE_CATEGORY_TYPE' | translate }} </label>
          <angular2-multiselect [data]="categoryTypes" [settings]="dropdownSettingsForDirectionTypes"
                                class="form-control"
                                [(ngModel)]="searchModel.categoryTypes"
                                #categoryType="ngModel" name="categoryType" id="categoryType">
          </angular2-multiselect>
        </div>
        <!--STOCK ITEM-->
        <div class="col-lg-3 col-md-4" *ngIf="rightModel.stockItemRead.hasRight()">
          <label class="search-label"> {{ 'FORM_RECORD_STOCK_ITEM' | translate }} </label>
          <angular2-multiselect [data]="stockItems" [settings]="remoteSearchDropdownSettings"
                                class="form-control"
                                (onRemoteSearch)="onStockItemSearch($event.target.value)"
                                [(ngModel)]="searchModel.recordStockItems"
                                #recordStockItemInput="ngModel" name="recordStockItem" id="recordStockItem">
          </angular2-multiselect>
        </div>
      </div>
      <div class="row form-group">
        <div class="col-lg-2 col-md-4">
          <label class="search-label">{{'INVOICE_SEARCH_ISSUE_DATE_FROM' | translate}}</label>
          <div class="input-group">
            <input type="text" class="form-control date" placeholder="{{'INVOICE_SEARCH_ISSUE_DATE_FROM' | translate}}"
                   [(ngModel)]="searchModel.issueDateFrom" ngbDatepicker name="issueDateFrom" id="issueDateFrom"
                   #issueDateFrom="ngbDatepicker">
            <div class="input-group-addon-gray" (click)="issueDateFrom.toggle()">
              <i class="icomoon icomoon-calendar"></i>
            </div>
          </div>
        </div>
        <div class="col-lg-2 col-md-4">
          <label class="search-label">{{'INVOICE_SEARCH_ISSUE_DATE_TO' | translate}}</label>
          <div class="input-group">
            <input type="text" class="form-control date"
                   placeholder="{{'INVOICE_SEARCH_ISSUE_DATE_TO' | translate}}"
                   [(ngModel)]="searchModel.issueDateTo" ngbDatepicker name="issueDateTo" #issueDateTo="ngbDatepicker"
                   id="issueDateTo">
            <div class="input-group-addon-gray" (click)="issueDateTo.toggle()">
              <i class="icomoon icomoon-calendar"></i>
            </div>
          </div>
        </div>
        <div class="col-lg-2 col-md-4">
          <label class="search-label">{{'INVOICE_SEARCH_DELIVERY_DATE_FROM' | translate}}</label>
          <div class="input-group">
            <input type="text" class="form-control date"
                   placeholder="{{'INVOICE_SEARCH_DELIVERY_DATE_FROM' | translate}}"
                   [(ngModel)]="searchModel.deliveryDateFrom" ngbDatepicker name="deliveryDateFrom"
                   id="deliveryDateFrom"
                   #deliveryDateFrom="ngbDatepicker">
            <div class="input-group-addon-gray" (click)="deliveryDateFrom.toggle()">
              <i class="icomoon icomoon-calendar"></i>
            </div>
          </div>
        </div>
        <div class="col-lg-2 col-md-4">
          <label class="search-label">{{'INVOICE_SEARCH_DELIVERY_DATE_TO' | translate}}</label>
          <div class="input-group">
            <input type="text" class="form-control date"
                   placeholder="{{'INVOICE_SEARCH_DELIVERY_DATE_TO' | translate}}"
                   [(ngModel)]="searchModel.deliveryDateTo" ngbDatepicker name="deliveryDateTo"
                   #deliveryDateTo="ngbDatepicker"
                   id="deliveryDateTo">
            <div class="input-group-addon-gray" (click)="deliveryDateTo.toggle()">
              <i class="icomoon icomoon-calendar"></i>
            </div>
          </div>
        </div>
        <div class="col-lg-2 col-md-4">
          <label class="search-label">{{'INVOICE_SEARCH_DEADLINE_DATE_FROM' | translate}}</label>
          <div class="input-group">
            <input type="text" class="form-control date"
                   placeholder="{{'INVOICE_SEARCH_DEADLINE_DATE_FROM' | translate}}"
                   [(ngModel)]="searchModel.deadlineDateFrom" ngbDatepicker name="deadlineDateFrom"
                   id="deadlineDateFrom"
                   #deadlineDateFrom="ngbDatepicker">
            <div class="input-group-addon-gray" (click)="deadlineDateFrom.toggle()">
              <i class="icomoon icomoon-calendar"></i>
            </div>
          </div>
        </div>
        <div class="col-lg-2 col-md-4">
          <label class="search-label">{{'INVOICE_SEARCH_DEADLINE_DATE_TO' | translate}}</label>
          <div class="input-group">
            <input type="text" class="form-control date"
                   placeholder="{{'INVOICE_SEARCH_DEADLINE_DATE_TO' | translate}}"
                   [(ngModel)]="searchModel.deadlineDateTo" ngbDatepicker name="deadlineDateTo"
                   #deadlineDateTo="ngbDatepicker"
                   id="deadlineDateTo">
            <div class="input-group-addon-gray" (click)="deadlineDateTo.toggle()">
              <i class="icomoon icomoon-calendar"></i>
            </div>
          </div>
        </div>
      </div>
      <div class="row form-group">
        <div class="col-lg-2 col-md-4">
          <label class="search-label"> {{ 'INVOICE_SEARCH_SETTLED' | translate }} </label>
          <select class="form-control" [(ngModel)]="searchModel.settled"
                  id="settled" name="settled" #settled="ngModel">
            <option *ngFor="let item of searchBooleanItemObjects"
                    [ngValue]="item.value">{{item.stringKey | translate}}</option>
          </select>
        </div>
        <div class="col-lg-2 col-md-4">
          <label class="search-label">{{'INVOICE_SEARCH_SETTLEMENT_DATE_FROM' | translate}}</label>
          <div class="input-group">
            <input type="text" class="form-control date"
                   placeholder="{{'INVOICE_SEARCH_SETTLEMENT_DATE_FROM' | translate}}"
                   [(ngModel)]="searchModel.settlementDateFrom" ngbDatepicker name="settlementDateFrom"
                   id="settlementDateFrom"
                   #settlementDateFrom="ngbDatepicker">
            <div class="input-group-addon-gray" (click)="settlementDateFrom.toggle()">
              <i class="icomoon icomoon-calendar"></i>
            </div>
          </div>
        </div>
        <div class="col-lg-2 col-md-4">
          <label class="search-label">{{'INVOICE_SEARCH_SETTLEMENT_DATE_TO' | translate}}</label>
          <div class="input-group">
            <input type="text" class="form-control date"
                   placeholder="{{'INVOICE_SEARCH_SETTLEMENT_DATE_TO' | translate}}"
                   [(ngModel)]="searchModel.settlementDateTo" ngbDatepicker name="settlementDateTo"
                   #settlementDateTo="ngbDatepicker"
                   id="settlementDateTo">
            <div class="input-group-addon-gray" (click)="settlementDateTo.toggle()">
              <i class="icomoon icomoon-calendar"></i>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12 d-flex justify-content-md-end px-0">
        <div class="btn-group" role="group">
          <button type="button" (click)="onSearchReset()" class="btn btn-outline-primary search-button mr-1">
            {{'COMMON_BUTTON_SEARCH_DELETE' | translate}}
          </button>
          <button type="button" (click)="onSearchClicked()"
                  class="btn btn-primary search-button">{{'COMMON_BUTTON_SEARCH' | translate}}
          </button>
        </div>
      </div>
    </div> <!-- ./card-body end -->

    <div class="card-body">
      <table class="table table-striped table-bordered">
        <thead>
        <tr>
          <th class="table-sorter-header hidden-md-down">
            <app-table-sorter-no-op
              [checkAllEnabled]="true"
              [checkAllSelected]="eachInvoiceSelected"
              (onCheckAllClicked)="toggleEachInvoice()">
            </app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header">
            <app-table-field-sorter [orderField]="Invoice.OrderField.INVOICE_NUMBER"
                                    [orderType]="queryModel.getOrderType(Invoice.OrderField.INVOICE_NUMBER)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'INVOICES_TABLE_HEADER_INVOICE_NUMBER' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-xs-down">
            <app-table-field-sorter [orderField]="Invoice.OrderField.ISSUE_DATE"
                                    [orderType]="queryModel.getOrderType(Invoice.OrderField.ISSUE_DATE)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'INVOICES_TABLE_HEADER_ISSUE_DATE' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-lg-down">
            <app-table-field-sorter [orderField]="Invoice.OrderField.DEADLINE"
                                    [orderType]="queryModel.getOrderType(Invoice.OrderField.DEADLINE)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'INVOICES_TABLE_HEADER_DUE_DATE' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-lg-down">
            <app-table-field-sorter [orderField]="Invoice.OrderField.DELIVERY_DATE"
                                    [orderType]="queryModel.getOrderType(Invoice.OrderField.DELIVERY_DATE)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'INVOICE_CREATE_DELIVERY_DATE' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-lg-down">
            <app-table-sorter-no-op [text]="'INVOICE_CREATE_PAYMENT_TYPE' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header hidden-sm-down">
            <app-table-field-sorter [orderField]="Invoice.OrderField.CUSTOMER_NAME"
                                    [orderType]="queryModel.getOrderType(Invoice.OrderField.CUSTOMER_NAME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'INVOICES_TABLE_HEADER_CUSTOMER' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-lg-down">
            <app-table-field-sorter [orderField]="Invoice.OrderField.NET_SUM_PRICE"
                                    [orderType]="queryModel.getOrderType(Invoice.OrderField.NET_SUM_PRICE)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'INVOICES_TABLE_HEADER_SUM' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header w-table-options-menu">
            <app-table-sorter-no-op></app-table-sorter-no-op>
          </th>
        </tr>
        </thead>
        <tbody>
        <ng-container *ngFor="let item of list | paginate: {
                                                      itemsPerPage: queryModel.itemsPerPage,
                                                      currentPage: queryModel.currentPage,
                                                      totalItems: queryModel.currentNumberOfItems }">
          <tr
            [ngClass]="[getExportStateClass(item.invoice.navData.exportState), searchModel.advancedMode ? 'invoice-group-row' : '']">
            <td class="fit hidden-md-down">
              <div class="d-flex type-cell">
                <app-animated-checkbox
                  class="toggle-checkbox"
                  [(selected)]="item.selected">
                </app-animated-checkbox>
                <div>
                  <img src="{{getIntegrationTypeIcon(item.invoice.invoiceSettings.invoiceIntegrationType)}}"
                       class="invoice-integration-icon" alt="IntegrationIcon"
                       *ngIf="hasIntegrationTypeIcon(item.invoice.invoiceSettings.invoiceIntegrationType)">
                  <span
                    *ngIf="!hasIntegrationTypeIcon(item.invoice.invoiceSettings.invoiceIntegrationType)">{{getIntegrationTypeString(item.invoice.invoiceSettings.invoiceIntegrationType) | translate}}</span>
                </div>
              </div>
            </td>
            <td class="responsive-table-column">
              <div class="row" style="margin-left: 0px !important;">
                <a class="id-text cursor-pointer mr-05"
                   href="#" onclick="return false"
                   uiSref="Admin.InvoiceDetail"
                   [uiParams]="{ id: item.invoice.id }"
                   title="{{'COMMON_BUTTON_DETAILS' | translate}}">
                  {{item.invoice.invoiceNumber}}
                </a>
                <i class="icomoon icomoon-price mr-05" *ngIf="item.invoice.settlementData.settled"
                   title="{{item.invoice.settlementData.date!.toIsoString() | date:'shortDate'}}"></i>
                <app-table-badge
                  [badgeStyle]="item.invoice.invoiceTag.disabled ? BadgeStyle.LIGHT_GRAY : BadgeStyle.SUCCESS"
                  [breakWord]="true"
                  (click)="filterByTag(item.invoice.invoiceTag)"
                  [text]="item.invoice.invoiceTag.invoiceTag"
                  [fontSize]="'small'"
                  *ngIf="item.invoice.invoiceTag">
                </app-table-badge>
              </div>
              <div class="text-truncate table-text-subtitle">
                {{getInvoiceCategoryType(item.invoice.categoryType) | translate}}
              </div>
            </td>
            <td class="fit hidden-xs-down">{{item.invoice.issueDate.toIsoString() | date:'shortDate' }}</td>
            <td class="fit hidden-lg-down">{{item.invoice.deadline.toIsoString() | date:'shortDate' }}</td>
            <td class="fit hidden-lg-down">{{item.invoice.deliveryDate.toIsoString() | date:'shortDate' }}</td>
            <td class="fit hidden-lg-down">{{getInvoicePaymentType(item.invoice.paymentType) | translate}}</td>
            <td
              class="responsive-table-column hidden-sm-down">{{item.invoice.directionType === 'OUTBOUND' ? item.invoice.customerName : item.invoice.sellerName}}</td>
            <td class="responsive-table-column hidden-lg-down">
              {{item.invoice.footer.grossSumPrice.toFixed(0) | formattedNumber}} {{item.invoice.currencyCode}}
              <p
                class="text-secondary m-0">{{'INVOICES_TABLE_HEADER_NET_SHORT' | translate}} {{item.invoice.footer.netSumPrice.toFixed(0) | formattedNumber}} {{item.invoice.currencyCode}}</p>
            </td>
            <td class="align-middle">
              <app-table-options-menu>
                <app-dropdown-item
                  [iconClass]="'icomoon-detail'"
                  [titleStringKey]="'COMMON_BUTTON_DETAILS'"
                  class="cursor-pointer"
                  uiSref="Admin.InvoiceDetail"
                  [uiParams]="{ id: item.invoice.id }">
                </app-dropdown-item>
                <app-dropdown-item
                  *ngIf="item.invoice.hasPdf"
                  [iconClass]="'icomoon-download'"
                  [titleStringKey]="'COMMON_BUTTON_DOWNLOAD'"
                  class="cursor-pointer"
                  (click)="downloadPdf(item.invoice.id)">
                </app-dropdown-item>
                <app-dropdown-item
                  *ngIf="rightModel.invoiceStorno.hasRight() && item.invoice.categoryType !== 'STORNO' && !item.invoice.reverted"
                  [iconClass]="'icomoon-invoice-storno-alt'"
                  [titleStringKey]="'INVOICE_STORNO'"
                  class="cursor-pointer"
                  (click)="openStornoPreselectDialog(item.invoice)">
                </app-dropdown-item>
                <app-dropdown-item
                  *ngIf="rightModel.invoiceAction.hasRight() && !item.invoice.settlementData.settled"
                  [iconClass]="'icomoon-price'"
                  [titleStringKey]="'INVOICE_SETTLE'"
                  class="cursor-pointer"
                  (click)="openSettlementDialog(item.invoice)">
                </app-dropdown-item>
                <app-dropdown-item
                  *ngIf="rightModel.invoiceCreate.hasRight() && item.invoice.categoryType !== 'STORNO'"
                  [iconClass]="'icomoon-clone'"
                  [titleStringKey]="'COMMON_BUTTON_CLONE'"
                  class="cursor-pointer"
                  uiSref="Admin.InvoiceClone"
                  [uiParams]="{ id: item.invoice.id, type: item.invoice.directionType }">
                </app-dropdown-item>
              </app-table-options-menu>
            </td>
          </tr>
          <tr *ngIf="searchModel.advancedMode && item.records" class="no-hover">
            <td colspan="100%" class="invoice-record-shadow">
              <app-invoice-record-container
                [recordCreateModels]="item.records"
                [filterStockItemIds]="searchModel.recordStockItemIdArray"
                [selectableVatRates]="[]"
                [selectableZeroVatRateTypes]="[]"
                [readonly]="true"
                [rightModel]="rightModel"
                [paymentType]="item.invoice.paymentType"
                [showSummary]="false">
              </app-invoice-record-container>
            </td>
          </tr>

        </ng-container>
        </tbody>
        <tfoot>
        <tr>
          <th class="table-sorter-header hidden-md-down">
            <app-table-sorter-no-op
              [checkAllEnabled]="true"
              [checkAllSelected]="eachInvoiceSelected"
              (onCheckAllClicked)="toggleEachInvoice()">
            </app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header">
            <app-table-field-sorter [orderField]="Invoice.OrderField.INVOICE_NUMBER"
                                    [orderType]="queryModel.getOrderType(Invoice.OrderField.INVOICE_NUMBER)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'INVOICES_TABLE_HEADER_INVOICE_NUMBER' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-xs-down">
            <app-table-field-sorter [orderField]="Invoice.OrderField.ISSUE_DATE"
                                    [orderType]="queryModel.getOrderType(Invoice.OrderField.ISSUE_DATE)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'INVOICES_TABLE_HEADER_ISSUE_DATE' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-lg-down">
            <app-table-field-sorter [orderField]="Invoice.OrderField.DEADLINE"
                                    [orderType]="queryModel.getOrderType(Invoice.OrderField.DEADLINE)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'INVOICES_TABLE_HEADER_DUE_DATE' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-lg-down">
            <app-table-field-sorter [orderField]="Invoice.OrderField.DELIVERY_DATE"
                                    [orderType]="queryModel.getOrderType(Invoice.OrderField.DELIVERY_DATE)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'INVOICE_CREATE_DELIVERY_DATE' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-lg-down">
            <app-table-sorter-no-op [text]="'INVOICE_CREATE_PAYMENT_TYPE' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header hidden-sm-down">
            <app-table-field-sorter [orderField]="Invoice.OrderField.CUSTOMER_NAME"
                                    [orderType]="queryModel.getOrderType(Invoice.OrderField.CUSTOMER_NAME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'INVOICES_TABLE_HEADER_CUSTOMER' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-lg-down">
            <app-table-field-sorter [orderField]="Invoice.OrderField.NET_SUM_PRICE"
                                    [orderType]="queryModel.getOrderType(Invoice.OrderField.NET_SUM_PRICE)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'INVOICES_TABLE_HEADER_SUM' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header w-table-options-menu">
            <app-table-sorter-no-op></app-table-sorter-no-op>
          </th>
        </tr>
        </tfoot>
      </table>
      <app-table-paging
        [currentNumberOfItems]="queryModel.currentNumberOfItems"
        [totalNumberOfItems]="queryModel.totalNumberOfItems"
        [itemsPerPage]="queryModel.itemsPerPage"
        (pageChange)="pageChanged($event)"
        (itemsPerPageChange)="itemsPerPageChanged($event)">
      </app-table-paging>
    </div>

  </div>
</div>
