import { DqlQuery, Query } from '../field';

export class ShopRenter {

  readonly id: Query.OrderField;
  readonly name: Query.OrderField;
  readonly humanName: Query.OrderField;

  constructor(prefix?: string) {
    prefix = prefix ? prefix : '';
    this.id = new DqlQuery.OrderField(prefix + 'id');
    this.name = new DqlQuery.OrderField(prefix + 'name');
    this.humanName = new DqlQuery.OrderField(prefix + 'human_name');
  }

}
