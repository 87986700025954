/* eslint-disable */
import { Observable, Observer } from 'rxjs';
import { environment } from '../../environments/environment';
import { MemStorage } from './util/mem-storage';
import { Injectable } from '@angular/core';
import { UiConstants } from '../util/core-utils';
import { Order, OrderType, Services } from './util/services';
import { StockChange } from './stock/stock-change.service';
import { StockChangeTypeItem } from '../admin/stock-change/stock-change-list/stock-change-list.component';
import { Dates, LocalDate } from './util/dates';
import { RuntimeConfiguration } from './runtime-configuration';
import { LoggedInUserStorage } from './util/storages';
/* eslint-enable */

@Injectable()
export class StockChangeSearchService {

  constructor() {
  }

  getSearchData(request: StockChangeSearch.SearchDataGetRequest): Observable<StockChangeSearch.SearchDataResult> {
    const storage = this.createStorage();
    return Observable.create((observer: Observer<StockChangeSearch.SearchDataResult>) => {
      try {
        observer.next(storage.read(request));
      }
      catch (e) {
        observer.error(e);
      }
      observer.complete();
      return function () {
      };
    });
  }

  setSearchData(request: StockChangeSearch.SearchDataSetRequest): Observable<StockChangeSearch.SearchDataResult> {
    const storage = this.createStorage();
    return Observable.create((observer: Observer<StockChangeSearch.SearchDataResult>) => {
      try {
        observer.next(storage.save(request));
      }
      catch (e) {
        observer.error(e);
      }
      observer.complete();
      return function () {
      };
    });
  }

  resetSearchData(request: StockChangeSearch.SearchDataGetRequest): Observable<StockChangeSearch.SearchDataResult> {
    const storage = this.createStorage();
    return Observable.create((observer: Observer<StockChangeSearch.SearchDataResult>) => {
      try {
        observer.next(storage.reset(request));
      }
      catch (e) {
        observer.error(e);
      }
      observer.complete();
      return function () {
      };
    });
  }

  private createStorage(): StockChangeSearch.Storage {
    return new StockChangeSearch.Storage();
  }

}

export namespace StockChangeSearch {

  export interface SearchDataGetRequest {
  }

  export interface SearchDataSetRequest {
    searchData: SearchData;
  }

  export interface SearchDataResult {
    searchData: SearchData;
  }

  export interface SearchData {
    itemsPerPage: number;
    pageNumber: number;
    order: Order<StockChange.OrderField>;
    trxId: string;
    stockChangeType: StockChangeTypeItem | null;
    selectedUserId?: number;
    startDate: LocalDate;
    endDate: LocalDate;
  }

  interface StoredSearchRawDataBase {
    version: number;
  }

  interface StoredSearchRawData extends StoredSearchRawDataBase {
    itemsPerPage: number;
    pageNumber: number;
    order: Order<StockChange.OrderField>;
    trxId: string;
    stockChangeType: StockChangeTypeItem | null;
    selectedUserId?: number;
    startDate?: string;
    endDate?: string;
  }

  export class Storage {

    /**
     * Increment this version if you change the SearchData interface.
     */
    private static readonly VERSION: number = 2;

    private static readonly DEFAULT_SEARCH_DATA: SearchData = {
      itemsPerPage: UiConstants.pageNumbers[0],
      pageNumber: 1,
      order: {
        field: StockChange.OrderField.ID,
        type: OrderType.DESC
      },
      trxId: '',
      stockChangeType: null,
      selectedUserId: undefined,
      startDate: Dates.emptyLocalDate(),
      endDate: Dates.emptyLocalDate(),
    };

    constructor() {
    }

    public read(request: SearchDataGetRequest): SearchDataResult {
      let rawBase: StoredSearchRawDataBase;
      try {
        const key = this.generateKey();
        const json = MemStorage.getItem(key);
        if (!json) {
          return this.createDefaultResult();
        }
        rawBase = JSON.parse(json);
      }
      catch (error) {
        return this.createDefaultResult();
      }
      if (Storage.VERSION === rawBase.version) {
        const raw: StoredSearchRawData = <StoredSearchRawData> rawBase;
        return this.fromRaw(raw);
      }
      return this.createDefaultResult();
    }

    public reset(request: SearchDataGetRequest): SearchDataResult {
      return this.save({
        searchData: Storage.DEFAULT_SEARCH_DATA,
      });
    }

    public save(request: SearchDataSetRequest): SearchDataResult {
      const key = this.generateKey();
      const raw = this.toRaw(request.searchData);
      const json = JSON.stringify(raw);
      MemStorage.setItem(key, json);
      return this.read(request);
    }

    private generateKey(): string {
      return 'UserId(' + LoggedInUserStorage.getInstance().getUserId() + ')-Search-Stock-Change';
    }

    private createDefaultResult(): SearchDataResult {
      return {
        searchData: Storage.DEFAULT_SEARCH_DATA,
      };
    }

    private fromRaw(data: StoredSearchRawData): SearchDataResult {
      return {
        searchData: {
          itemsPerPage: data.itemsPerPage,
          pageNumber: data.pageNumber,
          order: data.order,
          trxId: data.trxId,
          stockChangeType: data.stockChangeType,
          selectedUserId: data.selectedUserId,
          startDate: Services.toLocalDate(data.startDate),
          endDate: Services.toLocalDate(data.endDate),
        }
      };
    }

    private toRaw(data: SearchData): StoredSearchRawData {
      return {
        version: Storage.VERSION,
        itemsPerPage: data.itemsPerPage,
        pageNumber: data.pageNumber,
        order: data.order,
        trxId: data.trxId,
        stockChangeType: data.stockChangeType,
        selectedUserId: data.selectedUserId,
        startDate: Services.localDateToString(data.startDate),
        endDate: Services.localDateToString(data.endDate),
      };
    }

  }

}
