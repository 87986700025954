import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { RightModel } from '../../../app.rights';
import { GrantedPermissionSetResolver, RightResolver, RightService } from '../../../lib/right.service';
import { OffsetDateTime } from '../../../lib/util/dates';
import { Address } from '../../../lib/address';
import { ConfigModel, TaskRecordRightModel } from '../../../util/task-record-utils';
import { ConfigurationService } from '../../../lib/core-ext/configuration.service';
import { TaskRecordStateMachine } from '../../../lib/task/task-record-statemachine';
import { TaskRecord, TaskRecordService } from '../../../lib/task/task-record.service';
import { QueryResult } from '../../../lib/util/services';
import { DeviceManagementService } from '../../../lib/device-management.service';
import { UserMultiselectProvider } from '../../../lib/user/user-multiselect.provider';
import { UserGroupMultiselectProvider } from '../../../lib/user-group/user-group-multiselect.provider';
import { CustomerRecordMultiselectProvider } from '../../../lib/customer/customer-record-multiselect-provider.service';
import { UIRouter } from '@uirouter/angular';
import { StateName } from '../../../app.state-names';
import PostalAddressMapper = Address.PostalAddressMapper;
import { Set } from 'immutable';
import { OperationRights } from '../../../app.right-definitions';
import { CustomerRecordRightModel } from '../../../util/customer-record-utils';

@Component({
  selector: 'app-calendar-task-record-detail-dialog',
  templateUrl: './calendar-task-record-detail-dialog.component.html',
  styleUrls: ['./calendar-task-record-detail-dialog.component.scss']
})
export class CalendarTaskRecordDetailDialogComponent implements OnInit {

  rightModel: RightModel = RightModel.empty();
  detailModel: DetailModel = new DetailModel();
  config: ConfigModel = new ConfigModel();

  constructor(
    private rightService: RightService,
    private taskRecordService: TaskRecordService,
    private configService: ConfigurationService,
    private deviceManagementService: DeviceManagementService,
    private customerRecordMultiselectProvider: CustomerRecordMultiselectProvider,
    private userMultiselectProvider: UserMultiselectProvider,
    private uiRouter: UIRouter,
    private userGroupMultiselectProvider: UserGroupMultiselectProvider,
    public dialogRef: MatDialogRef<CalendarTaskRecordDetailDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CalendarTaskRecordDetailDialogData
  ) {
    this.config = this.configService.getConfigurationModel();
    this.loadRightModels(() => this.loadTaskRecord());
  }

  private loadRightModels(completion: () => void) {
    this.rightService.getRightResolver().subscribe(
      (resolver: RightResolver) => {
        this.rightModel = RightModel.of(resolver);
        completion();
      }
    );
  }

  public static openDialog(
    dialog: MatDialog,
    data: CalendarTaskRecordDetailDialogData,
    resultCallback: () => void) {
    const dialogRef = dialog.open(CalendarTaskRecordDetailDialogComponent, {
      closeOnNavigation: true,
      data: data,
      width: '60%',
      height: 'auto',
      panelClass: 'custom-dialog-container'
    });
    dialogRef.afterClosed().subscribe(() => {
      resultCallback();
    });
  }

  ngOnInit(): void {
  }

  closeDialog() {
    this.dialogRef.close();
  }

  navigateToDetail() {
    this.uiRouter.stateService.go(StateName.TASK_RECORD_DETAIL, {
      taskId: this.detailModel.taskId,
      taskRecordId: this.detailModel.taskRecordId
    });
    this.closeDialog();
  }

  navigateToEdit() {
    this.uiRouter.stateService.go(StateName.TASK_RECORD_EDIT, {
      taskId: this.detailModel.taskId,
      taskRecordId: this.detailModel.taskRecordId
    });
    this.closeDialog();
  }

  canModify(state: TaskRecordStateMachine.State): boolean {
    if (!this.detailModel.rightModel.update.hasRight()) {
      return false;
    }
    return TaskRecordStateMachine.StateMachine.canEdit(this.detailModel.taskData, state);
  }

  private loadTaskRecord() {
    console.log(this.data.taskRecordId)
    this.taskRecordService.globalQuery({
      taskRecordIdSet: Set.of(this.data.taskRecordId),
      rights: Set.of(OperationRights.TASK_RECORD_UPDATE)
    }).subscribe((result: QueryResult<TaskRecord.TaskRecord>) => {
      const taskRecord = result.items.toArray()[0];
      this.detailModel.taskName = taskRecord.task!.name;
      this.detailModel.taskRecordName = taskRecord.name;
      this.detailModel.taskId = taskRecord.taskId;
      this.detailModel.taskRecordId = taskRecord.taskRecordId;
      this.detailModel.taskRecordExternalId = taskRecord.externalId ? taskRecord.externalId : '';
      this.detailModel.description = taskRecord.description ? taskRecord.description : '';
      const importance = TaskRecord.taskRecordImportances.find((i) => (i.importance === taskRecord.importance));
      this.detailModel.importance = importance ? importance.stringKey : '';
      this.detailModel.state = taskRecord.state;
      const state = TaskRecordStateMachine.taskRecordStates.get(taskRecord.state);
      this.detailModel.stateText = state ? state.stringKey : '';
      this.loadUserGroupForTaskRecord(taskRecord.userGroupId);
      this.loadAssigneeForTaskRecord(taskRecord.assignee);
      this.loadCustomerForTaskRecord(taskRecord.customerId);
      this.detailModel.creationTime = taskRecord.creationTime;
      this.detailModel.deadline = taskRecord.deadline ? taskRecord.deadline : undefined;
      this.detailModel.agreedTime = taskRecord.agreedTime ? taskRecord.agreedTime : undefined;
      const address = taskRecord.placeOfConsumption ? taskRecord.placeOfConsumption.address ?
          taskRecord.placeOfConsumption.address : undefined
        : undefined;
      this.detailModel.address = address ? PostalAddressMapper.toString(address, this.config.postalAddressFormat) : '';
      this.detailModel.rightModel = new TaskRecordRightModel(GrantedPermissionSetResolver.byGrantedRights(taskRecord.grantedRights));
      this.detailModel.taskData = taskRecord.task;
    });
  }

  loadCustomerForTaskRecord(customerId?: number) {
    this.detailModel.customer = '';
    if (customerId) {
      this.customerRecordMultiselectProvider.getById(customerId).subscribe((customer) => {
        this.detailModel.customer = customer.itemName;
      });
    }
  }

  loadAssigneeForTaskRecord(assignee?: TaskRecord.Assignee) {
    this.detailModel.assigneeDevice = '';
    this.detailModel.assignee = '';
    if (assignee) {
      if (assignee.userId) {
        this.userMultiselectProvider.getById(assignee.userId).subscribe(u => {
          this.detailModel.assignee = u ? u.itemName : '';
        });
      }
      else if (assignee.mobileApplicationId) {
        this.deviceManagementService.get({id: assignee.mobileApplicationId}).subscribe(device => {
          this.detailModel.assigneeDevice = device ? device.name : '';
        });
      }
    }
  }

  loadUserGroupForTaskRecord(userGroupId: number | undefined) {
    this.detailModel.userGroup = '';
    if (userGroupId) {
      this.userGroupMultiselectProvider.getById(userGroupId).subscribe(ug => {
        this.detailModel.userGroup = ug.itemName;
      });
    }
  }
}

export class CalendarTaskRecordDetailDialogData {
  taskRecordId: number;
}

class DetailModel {
  // Task record
  taskName: string = '';
  taskRecordName: string = '';
  taskRecordExternalId: string = '';
  description: string = '';
  importance: string = '';
  state: TaskRecordStateMachine.State;
  stateText: string = '';
  userGroup: string = '';
  assignee: string = '';
  assigneeDevice: string = '';
  customer: string = '';
  creationTime?: OffsetDateTime = undefined;
  deadline?: OffsetDateTime = undefined;
  agreedTime?: OffsetDateTime = undefined;
  address: string = '';
  taskId?: number = undefined;
  taskRecordId?: number = undefined;
  rightModel: TaskRecordRightModel;
  taskData?: TaskRecord.TaskData;
}
