import { Component, Input, OnInit } from '@angular/core';
import { Address } from '../../../../lib/address';
import { CustomerRecord, CustomerRecordService } from '../../../../lib/customer/customer-record.service';
import { ConfigurationService } from '../../../../lib/core-ext/configuration.service';
import { RightModel } from '../../../../app.rights';
import { DropdownItemType } from '../../../../shared/dropdown/dropdown-item/dropdown-item-type';
import { MatDialog } from '@angular/material/dialog';
import {
  CustomerRecordBillingInfoBaseDialogComponent
} from '../customer-record-billing-info-base-dialog/customer-record-billing-info-base-dialog.component';
import PostalAddressData = Address.PostalAddressData;
import { CustomerRecordBillingInfoComponentMode } from '../customer-record-billing-info-base/customer-record-billing-info-base.component';

@Component({
  selector: 'app-customer-record-billing-info-list',
  templateUrl: './customer-record-billing-info-list.component.html',
  styleUrls: ['./customer-record-billing-info-list.component.scss']
})
export class CustomerRecordBillingInfoListComponent implements OnInit {
  DropdownItemType = DropdownItemType;

  @Input()
  customerId: number;

  @Input()
  customerRecordId: number;

  @Input()
  readonly: boolean = true;

  @Input()
  rightModel: RightModel = RightModel.empty();

  list: CustomerRecord.BillingInfo[] = [];

  private postalAddressFormat: string;

  constructor(
    private customerRecordService: CustomerRecordService,
    private configService: ConfigurationService,
    private dialog: MatDialog) {
  }

  ngOnInit() {
    this.postalAddressFormat = this.configService.getPostalAddressFormat();
  }

  initComponent() {
    this.loadList();
  }
  loadList() {
    this.customerRecordService.listBillingInfo({
      customerId: this.customerId,
      customerRecordId: this.customerRecordId
    }).subscribe((result: CustomerRecord.BillingInfo[]) => {
      this.list = result;
    });
  }

  setDisabled(info: CustomerRecord.BillingInfo, disabled: boolean) {
    this.customerRecordService.setBillingInfoDisabled({
      customerId: this.customerId,
      customerRecordId: this.customerRecordId,
      id: info.id,
      disabled: disabled
    }).subscribe(result => this.loadList());
  }

  formatPostalAddress(postalAddress?: PostalAddressData): string {
    return postalAddress ? Address.PostalAddressMapper.toString(postalAddress, this.postalAddressFormat) : '';
  }

  createBillingInfo() {
    CustomerRecordBillingInfoBaseDialogComponent.openDialog(this.dialog, {
      customerId: this.customerId,
      customerRecordId: this.customerRecordId,
      mode: CustomerRecordBillingInfoComponentMode.CREATE,
      data: undefined
    }, result => {
      if (result) {
        this.loadList();
      }
    });
  }

  editBillingInfo(data: CustomerRecord.BillingInfo) {
    CustomerRecordBillingInfoBaseDialogComponent.openDialog(this.dialog, {
      customerId: this.customerId,
      customerRecordId: this.customerRecordId,
      mode: CustomerRecordBillingInfoComponentMode.EDIT,
      data: data
    }, result => {
      if (result) {
        this.loadList();
      }
    });
  }

  detailBillingInfo(data: CustomerRecord.BillingInfo) {
    CustomerRecordBillingInfoBaseDialogComponent.openDialog(this.dialog, {
      customerId: this.customerId,
      customerRecordId: this.customerRecordId,
      mode: CustomerRecordBillingInfoComponentMode.DETAIL,
      data: data
    }, result => {
      if (result) {
        this.loadList();
      }
    });
  }


}
