import { DqlModel } from '../dql/dql.model';
import { Map } from 'immutable';
import { Transport } from './transport.service';

export class TransportDqlFieldTextProvider {
  public static getHelper(): DqlModel.DqlStaticFieldTextHelper {
    const fields: Map<string, DqlModel.DqlFieldTexts> = Map.of(
      'id', {stringKey: 'COMMON_TABLE_HEADER_ID'},
      'external_id', {stringKey: 'COMMON_EXTERNAL_ID'},
      'creation_time', {stringKey: 'COMMON_TABLE_HEADER_CREATION_TIME'},
      'update_time', {stringKey: 'COMMON_TABLE_HEADER_UPDATE_TIME'},
      'state', {
        stringKey: 'COMMON_STATUS', enumType: Transport.transportStates.map((value: Transport.TransportStateObject) => {
          return {id: <string>value.state, stringKey: value.stringKey};
        })
      },
      'safety_shipping', {stringKey: 'TRANSPORT_SAFETY_SHIPPING'},
      'waybill_number', {stringKey: 'TRANSPORT_WAYBILL_NUMBER'},
      'vehicle_licence_plate', {stringKey: 'VEHICLE_LICENCE_PLATE'},
      'driver_id', {stringKey: 'TRANSPORT_DRIVER'},
      'dispatcher_user_id', {stringKey: 'RELATED_PERSON_TYPE_CREATOR'},
      'transporter_company_id', {stringKey: 'TRANSPORT_CREATE_TRANSPORTER_COMPANY'},
      'security_guard_name', {stringKey: 'TRANSPORT_CREATE_SECURITY_GUARD_NAME'},
      'start_time', {stringKey: 'TRANSPORT_START_TIME'},
      'estimated_start_time', {stringKey: 'TRANSPORT_ESTIMATED_START_TIME'},
      'open_time', {stringKey: 'TRANSPORT_OPEN_TIME'},
      'close_time', {stringKey: 'TRANSPORT_CLOSE_TIME'},
    );
    return new DqlModel.DqlStaticFieldTextHelper(fields);
  }
}
