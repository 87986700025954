import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EmptyMessage } from '../util/messages';
import { Address, AddressResource } from '../address';
import { PlaceResourceService } from './place-resource.service';


@Injectable()
export class PlaceService {

  update(id: number, r: Address.PlaceCreateRequest): Observable<EmptyMessage> {
    const request = AddressResource.Mapper.fromPublicPlace(r);
    return this.resourceService.update(id, request);
  }

  constructor(private resourceService: PlaceResourceService) {

  }

}
