/* eslint-disable */
import { Observable, Observer } from 'rxjs';
import { Injectable } from '@angular/core';
import { StockTakingSheet } from './stock-taking-sheet.service';
import { Order, OrderType } from '../util/services';
import { UiConstants } from '../../util/core-utils';
import { MemStorage } from '../util/mem-storage';
import { environment } from '../../../environments/environment';
import { RuntimeConfiguration } from '../runtime-configuration';
import { LoggedInUserStorage } from '../util/storages';
/* eslint-enable */

@Injectable()
export class StockTakingSheetSearchService {

  constructor() {
  }

  getSearchData(request: StockTakingSheetSearch.SearchDataGetRequest): Observable<StockTakingSheetSearch.SearchDataResult> {
    const storage = this.createStorage();
    return Observable.create((observer: Observer<StockTakingSheetSearch.SearchDataResult>) => {
      try {
        observer.next(storage.read(request));
      }
      catch (e) {
        observer.error(e);
      }
      observer.complete();
      return function () {
      };
    });
  }

  setSearchData(request: StockTakingSheetSearch.SearchDataSetRequest): Observable<StockTakingSheetSearch.SearchDataResult> {
    const storage = this.createStorage();
    return Observable.create((observer: Observer<StockTakingSheetSearch.SearchDataResult>) => {
      try {
        observer.next(storage.save(request));
      }
      catch (e) {
        observer.error(e);
      }
      observer.complete();
      return function () {
      };
    });
  }

  resetSearchData(request: StockTakingSheetSearch.SearchDataGetRequest): Observable<StockTakingSheetSearch.SearchDataResult> {
    const storage = this.createStorage();
    return Observable.create((observer: Observer<StockTakingSheetSearch.SearchDataResult>) => {
      try {
        observer.next(storage.reset(request));
      }
      catch (e) {
        observer.error(e);
      }
      observer.complete();
      return function () {
      };
    });
  }

  private createStorage(): StockTakingSheetSearch.Storage {
    return new StockTakingSheetSearch.Storage();
  }

}

export namespace StockTakingSheetSearch {

  export interface SearchDataGetRequest {
  }

  export interface SearchDataSetRequest {
    searchData: SearchData;
  }

  export interface SearchDataResult {
    searchData: SearchData;
  }

  export interface SearchData {
    itemsPerPage: number;
    pageNumber: number;
    order: Order<StockTakingSheet.OrderField>;
    trxId: string;
    selectedState: StockTakingSheet.StockTakingSheetState | null;
    stockIds: string;
    ownerUserName: string;
    ownerUserId?: number;
  }

  interface StoredSearchRawDataBase {
    version: number;
  }

  interface StoredSearchRawData extends StoredSearchRawDataBase {
    itemsPerPage: number;
    pageNumber: number;
    order: Order<StockTakingSheet.OrderField>;
    trxId: string;
    selectedState: StockTakingSheet.StockTakingSheetState | null;
    stockIds: string;
    ownerUserName: string;
    ownerUserId?: number;
  }

  export class Storage {

    /**
     * Increment this version if you change the SearchData interface.
     */
    private static readonly VERSION: number = 2;

    private static readonly DEFAULT_SEARCH_DATA: SearchData = {
      itemsPerPage: UiConstants.pageNumbers[0],
      pageNumber: 1,
      order: {
        field: StockTakingSheet.OrderField.CREATION_TIME,
        type: OrderType.DESC
      },
      trxId: '',
      selectedState: null,
      stockIds: '',
      ownerUserName: '',
      ownerUserId: undefined,
    };

    constructor() {
    }

    public read(request: SearchDataGetRequest): SearchDataResult {
      let rawBase: StoredSearchRawDataBase;
      try {
        const key = this.generateKey();
        const json = MemStorage.getItem(key);
        if (!json) {
          return this.createDefaultResult();
        }
        rawBase = JSON.parse(json);
      }
      catch (error) {
        return this.createDefaultResult();
      }
      if (Storage.VERSION === rawBase.version) {
        const raw: StoredSearchRawData = <StoredSearchRawData> rawBase;
        return this.fromRaw(raw);
      }
      return this.createDefaultResult();
    }

    public reset(request: SearchDataGetRequest): SearchDataResult {
      return this.save({
        searchData: Storage.DEFAULT_SEARCH_DATA,
      });
    }

    public save(request: SearchDataSetRequest): SearchDataResult {
      const key = this.generateKey();
      const raw = this.toRaw(request.searchData);
      const json = JSON.stringify(raw);
      MemStorage.setItem(key, json);
      return this.read(request);
    }

    private generateKey(): string {
      return 'UserId(' + LoggedInUserStorage.getInstance().getUserId() + ')-Search-Stock-Taking-Sheet';
    }

    private createDefaultResult(): SearchDataResult {
      return {
        searchData: Storage.DEFAULT_SEARCH_DATA,
      };
    }

    private fromRaw(data: StoredSearchRawData): SearchDataResult {
      return {
        searchData: {
          itemsPerPage: data.itemsPerPage,
          pageNumber: data.pageNumber,
          order: data.order,
          trxId: data.trxId,
          selectedState: data.selectedState,
          stockIds: data.stockIds,
          ownerUserName: data.ownerUserName,
          ownerUserId: data.ownerUserId,
        }
      };
    }

    private toRaw(data: SearchData): StoredSearchRawData {
      return {
        version: Storage.VERSION,
        itemsPerPage: data.itemsPerPage,
        pageNumber: data.pageNumber,
        order: data.order,
        trxId: data.trxId,
        selectedState: data.selectedState,
        stockIds: data.stockIds,
        ownerUserName: data.ownerUserName,
        ownerUserId: data.ownerUserId,
      };
    }

  }

}
