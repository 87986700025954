<header class="app-header navbar">
  <button class="navbar-toggler mobile-sidebar-toggler hidden-lg-up" type="button"><i
    class="icomoon icomoon-menu-thick"></i></button>
  <a class="navbar-brand" uiSref="Admin.Startup" *ngIf="!brandLogoSrc"></a>
  <a class="navbar-asset-brand" uiSref="Admin.Startup" *ngIf="brandLogoSrc">
    <img src="{{brandLogoSrc}}"/>
  </a>
  <ul class="nav navbar-nav hidden-md-down">
    <li class="nav-item">
      <a class="nav-link navbar-toggler sidebar-toggler" href="#" onclick="return false"><i
        class="icomoon icomoon-menu-thick"></i></a>
    </li>
  </ul>
  <ul class="nav navbar-nav m-auto hidden-md-down" *ngIf="demoModeEnabled && inactivationPeriod">
    <div class="alert alert-danger alert-inactivation-time">
      <label>{{'USER_PANEL_INACTIVATION_TIME' | translate: {time: inactivationPeriod} }}</label>
    </div>
  </ul>
  <ul class="nav navbar-nav" [class.ml-auto]="!demoModeEnabled || !inactivationPeriod" id="user-data">
    <li *ngIf="!userDataToDisplay.id" class="nav-item cursor-pointer" (click)="logout()">
      <i class="icon-logout"></i>
    </li>
    <li *ngIf="userDataToDisplay.id" class="nav-item dropdown" dropdown placement="bottom right">
      <div class="d-flex">
        <app-user-profile-picture
          class="user-profile-picture"
          [userPersonName]="userDataToDisplay.personName"
          [profilePicture]="userDataToDisplay.profilePicture"
          [size]="40">
        </app-user-profile-picture>
        <div>
          <a class="nav-link dropdown-toggle text-left" data-toggle="dropdown" href="#" onclick="return false"
             role="button"
             aria-haspopup="true"
             aria-expanded="false" dropdownToggle>
            <span class="hidden-md-down">{{userDataToDisplay.userName}}</span>
          </a>
          <span class="table-text-subtitle user-person-name">{{userDataToDisplay.personName}}</span>
        </div>
      </div>
      <div class="dropdown-menu dropdown-menu-right" *dropdownMenu aria-labelledby="simple-dropdown">
        <!-- USER DATA -->
        <div class="d-flex align-items-center dropdown-item cursor-pointer line-height-normal border-bottom-0"
             (click)="navigate(StateName.USER_DETAIL, {id: userDataToDisplay.id})">
          <i class="icomoon icomoon-user-local user-dropdown-icon mr-1"></i>
          <div>
            <div class="text-nowrap">{{userDataToDisplay.personName}} - {{userDataToDisplay.userName}}</div>
            <div class="text-nowrap small">{{userDataToDisplay.emailAddress}}</div>
          </div>
        </div>
        <!-- USER GROUPS -->
        <div class="d-flex dropdown-item pointer-events-none border-bottom-0"
             *ngIf="rightModel.userGroupRead.hasRight()">
          <i class="icomoon icomoon-user-groups user-dropdown-icon mr-1"></i>
          <div>
            <div class="text-nowrap small">{{'USER_LABEL_USER_GROUPS' | translate}}</div>
            <div *ngFor="let userGroup of userDataToDisplay.userGroups">
              <div class="text-nowrap">{{userGroup.itemName}}</div>
              <div *ngIf="userGroup.itemSubtitle" class="text-wrap">{{userGroup.itemSubtitle}}</div>
            </div>
          </div>
        </div>
        <!-- USER ROLES -->
        <div class="d-flex dropdown-item pointer-events-none" *ngIf="rightModel.roleRead.hasRight()">
          <i class="icomoon icomoon-roles user-dropdown-icon mr-1"></i>
          <div>
            <div class="text-nowrap small">{{'MENU_NAVBAR_MENU_ROLES' | translate}}</div>
            <div *ngFor="let role of userDataToDisplay.roles" class="text-nowrap">{{role.text}}</div>
          </div>
        </div>
        <!-- INACTIVATION TIME -->
        <div class="d-flex dropdown-item pointer-events-none hidden-lg-up"
             *ngIf="demoModeEnabled && inactivationPeriod">
          <div class="alert alert-danger alert-inactivation-time">
            <label>{{'USER_PANEL_INACTIVATION_TIME' | translate: {time: inactivationPeriod} }}</label>
          </div>
        </div>
        <!-- API KEY -->
        <div *ngIf="rightModel.apiUserKeyRead.hasRight()"
             class="d-flex align-items-center dropdown-item cursor-pointer" (click)="navigate(StateName.API_USER_KEY)">
          <i class="icomoon icomoon-key user-dropdown-icon mr-1"></i>
          <div class="text-nowrap">{{'API_KEY_HEADER' | translate}}</div>
        </div>
        <!-- PASSWORD CHANGE -->
        <div *ngIf="rightModel.userChangePreviousPassword.hasRight() && userDataToDisplay.type !== 'LDAP'"
             class="d-flex align-items-center dropdown-item cursor-pointer" (click)="showChangePasswordDialog()">
          <i class="icomoon icomoon-password-change user-dropdown-icon mr-1"></i>
          <div class="text-nowrap">{{'USER_PANEL_HEADING_CHANGE_PASSWORD' | translate}}</div>
        </div>
        <!-- SNOW TOGGLE -->
        <div class="d-flex align-items-center dropdown-item cursor-pointer" (click)="toggleSnow()" *ngIf="santaEnabled">
          <i class="icomoon icomoon-snowflake user-dropdown-icon mr-1"></i>
          <div class="text-nowrap" *ngIf="snowEnabled">{{'MENU_NAVBAR_SNOW_OFF' | translate}}</div>
          <div class="text-nowrap" *ngIf="!snowEnabled">{{'MENU_NAVBAR_SNOW_ON' | translate}}</div>
        </div>
        <!-- SITE TOUR SETINGS -->
          <div class="d-flex align-items-center dropdown-item cursor-pointer" (click)="resetSiteTours()" *ngIf="demoModeEnabled">
            <i class="icomoon icomoon-restart user-dropdown-icon mr-1"></i>
            <div class="text-nowrap">{{'MENU_NAVBAR_SITE_TOUR_RESET' | translate}}</div>
          </div>
          <div class="d-flex align-items-center dropdown-item cursor-pointer" (click)="skipSiteTours()" *ngIf="demoModeEnabled">
            <i class="icomoon icomoon-stop-circle user-dropdown-icon mr-1"></i>
            <div class="text-nowrap">{{'MENU_NAVBAR_SITE_TOUR_SKIP' | translate}}</div>
          </div>
        <!-- LOG OUT -->
        <div class="d-flex align-items-center dropdown-item cursor-pointer" (click)="logout()">
          <i class="icomoon icomoon-logout user-dropdown-icon mr-1"></i>
          <div class="text-nowrap">{{'MENU_NAVBAR_MENU_LOGOUT' | translate}}</div>
        </div>
      </div>
    </li>
    <li class="nav-item nav-item-right-padding hidden-md-down"></li>
  </ul>
  <ul class="nav navbar-nav mx-1">
    <li class="nav-item">
      <a class="nav-link" href="#" onclick="return false" *ngIf="rightModel.messageRead.hasRight()"
         (click)="notificationIconClicked()">
        <i class="icomoon icomoon-notification position-relative"></i>
        <div *ngIf="sidebarRight.hasNewNotification" class="notification-badge"></div>
      </a>
    </li>
  </ul>
  <div style="display: none" id="test_admin_meta" [attr.current-locate]="currentLocale"></div>
</header>

<div class="sidebar">
  <a class="navbar-brand" id="navbar-brand" uiSref="Admin.Startup" *ngIf="!brandLogoSrc || !brandLogoSmallSrc"></a>
  <a class="navbar-asset-brand" id="navbar-asset-brand" #brandLogoElement uiSref="Admin.Startup"
     *ngIf="brandLogoSrc && brandLogoSmallSrc">
    <img *ngIf="!sidebarClosed" class="brand-logo-dimensions" src="{{brandLogoSrc}}"/>
    <img *ngIf="sidebarClosed" class="brand-logo-small-dimensions" src="{{brandLogoSmallSrc}}"/>
  </a>
  <nav class="sidebar-nav">
    <!-- region Sidebar list -->
    <ul class="nav">
      <app-sidebar-nav-item
        *ngFor="let item of navItems"
        [model]="item">
      </app-sidebar-nav-item>
    </ul>
    <!-- endregion -->
  </nav>
</div>

<div class="app-body">
  <!-- Right sidebar-->
  <app-sidebar-right
    [brandLogoSrc]="brandLogoSrc">
  </app-sidebar-right>
  <!-- Main content -->
  <main class="main">
    <div class="container-fluid">
      <ui-view></ui-view>
    </div>
    <!-- /.conainer-fluid -->
  </main>
</div>

<footer class="app-footer">
  <div class="d-flex justify-content-between">
    <div>
      {{ developerCompanyName }} &copy; {{ releaseYear }}
      <ng-container *ngIf="poweredByCompanyUrl && poweredByCompanyName">
      <span class="float-right">Powered by <a target="_blank"
                                              href="{{ poweredByCompanyUrl }}">{{ poweredByCompanyName }}</a>
      </span>
      </ng-container>
    </div>
    <div class="d-flex">
      <div class="mr-1 hidden-sm-down">Admin: {{adminVersion}}</div>
      <div class="mr-1 hidden-sm-down">Server: {{serverVersion}}</div>
      <i class="icomoon icomoon-service-info icon-large cursor-pointer" (click)="serviceInfoDialog.showDialog()"></i>
    </div>
  </div>
</footer>

<span id="network-loader" *ngIf="networkLoading"></span>
<toaster-container [toasterconfig]="toasterConfig"></toaster-container>

<div bsModal [config]="UiConstants.modalConfig" #changePasswordDialog="bs-modal" class="modal fade" tabindex="-1"
     role="dialog"
     aria-labelledby="myModalLabel"
     aria-hidden="true"
     (onHide)="onPasswordChangeModalHide()">
  <div class="modal-dialog modal-lg" role="document">
    <form class="modal-content"
          *ngIf="changePasswordDialogVisible"
          (ngSubmit)="changePassword()"
          [formGroup]="passwordChangeForm">
      <div class="modal-header">
        <h4 class="modal-title">{{'USER_PANEL_HEADING_CHANGE_PASSWORD' | translate}}</h4>
        <button type="button" class="close" (click)="hideChangePasswordDialog()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-body">
        <div class="card-body">
          <div class="form-group" [ngClass]="{ 'has-danger': passwordFieldError.current_password
                                                || (passwordChangeForm.controls['current_password'].touched
                                                && !passwordChangeForm.controls['current_password'].valid)
                                                }">
            <label for="password-current"
                   class="edit-form-label form-control-label">{{'USER_LABEL_PASSWORD'|translate}}</label>
            <div class="edit-form-input-group">
              <div class="input-group">
                <input type="{{passwordVisible ? 'text' : 'password'}}"
                       autocomplete="current-password" [(ngModel)]="passwordModel.prevPassword"
                       (ngModelChange)="resetPasswordFieldError()" class="form-control"
                       maxlength="{{getCurrentPasswordMaximumLength()}}"
                       [ngClass]="{ 'form-control-danger': passwordFieldError.current_password }"
                       name="password-current" id="password-current"
                       formControlName="current_password"
                       placeholder="{{'USER_PLACEHOLDER_PASSWORD'|translate}}"/>
                <span (click)="togglePasswordVisibility()"
                      class="input-group-addon-gray icomoon icomoon-enable cursor-pointer font-2xl"></span>
              </div>
              <div class="form-control-feedback" *ngIf="passwordFieldError.current_password">
                {{passwordFieldError.current_password.text}}
              </div>
              <div class="form-control-feedback" *ngIf="passwordChangeForm.controls['current_password'].touched
                                                        && passwordChangeForm.controls['current_password'].hasError('required')">
                {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
              </div>
            </div>
          </div><!-- /.form-group -->

          <div class="form-group" [ngClass]="{ 'has-danger': passwordFieldError.new_password ||
                                                  (passwordChangeForm.controls['password'].touched
                                                  && !passwordChangeForm.controls['password'].valid)
                                                  || (passwordChangeForm.controls['confirm_password'].touched
                                                  && passwordChangeForm.controls['confirm_password'].hasError('no_match'))
                                                  }">
            <label for="password-new"
                   class="edit-form-label form-control-label">{{'USER_LABEL_PASSWORD_NEW'|translate}}</label>
            <div class="edit-form-input-group">
              <div class="input-group">
                <input type="{{passwordVisible ? 'text' : 'password'}}"
                       autocomplete="new-password" [(ngModel)]="passwordModel.password"
                       (ngModelChange)="onPasswordModelChange()"
                       class="form-control"
                       maxlength="{{getNewPasswordMaximumLength()}}"
                       [ngClass]="{ 'form-control-danger': passwordFieldError.new_password }"
                       name="password-new" id="password-new" placeholder="{{'USER_PLACEHOLDER_PASSWORD_NEW'|translate}}"
                       formControlName="password"/>
                <span (click)="togglePasswordVisibility()"
                      class="input-group-addon-gray icomoon icomoon-enable cursor-pointer font-2xl"></span>
              </div>
              <div class="form-control-feedback" *ngIf="passwordFieldError.new_password">
                {{passwordFieldError.new_password.text}}
              </div>
              <div class="form-control-feedback" *ngIf="passwordChangeForm.controls['password'].touched
                                                        && passwordChangeForm.controls['password'].hasError('required')">
                {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
              </div>
            </div>
          </div><!-- /.form-group -->

          <div class="form-group" [ngClass]="{ 'has-danger': passwordFieldError.new_password ||
                                                  (passwordChangeForm.controls['confirm_password'].touched
                                                  && !passwordChangeForm.controls['confirm_password'].valid)
                                                  }">
            <label for="password-confirm"
                   class="edit-form-label form-control-label">{{'USER_PLACEHOLDER_PASSWORD_NEW_CONFIRM'|translate}}</label>
            <div class="edit-form-input-group">
              <div class="input-group">
                <input type="{{passwordVisible ? 'text' : 'password'}}"
                       autocomplete="confirm-password" [(ngModel)]="passwordModel.confirmPassword"
                       (ngModelChange)="resetPasswordFieldError()"
                       class="form-control"
                       maxlength="{{getNewPasswordMaximumLength()}}"
                       [ngClass]="{ 'form-control-danger': passwordFieldError.confirm_password }"
                       name="password-confirm" id="password-confirm"
                       placeholder="{{'USER_PLACEHOLDER_PASSWORD_NEW_CONFIRM'|translate}}"
                       formControlName="confirm_password"/>
                <span (click)="togglePasswordVisibility()"
                      class="input-group-addon-gray icomoon icomoon-enable cursor-pointer font-2xl"></span>
              </div>
              <div class="form-control-feedback" *ngIf="passwordFieldError.new_password">
                {{passwordFieldError.new_password.text}}
              </div>
              <div class="form-control-feedback" *ngIf="passwordChangeForm.controls['confirm_password'].touched
                                                        && passwordChangeForm.controls['confirm_password'].hasError('required')">
                {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
              </div>
              <div class="form-control-feedback" *ngIf="passwordChangeForm.controls['confirm_password'].touched
                                                        && passwordChangeForm.controls['confirm_password'].hasError('no_match')">
                {{'COMMON_VALIDATION_MESSAGE_PASSWORD_MATCH' | translate}}
              </div>
            </div>
          </div><!-- /.form-group -->

        </div><!-- /.card-body -->
      </div><!-- /.modal-body -->

      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="hideChangePasswordDialog()">{{'COMMON_BUTTON_CANCEL' |
          translate}}
        </button>
        <button type="submit" class="btn btn-primary">{{'COMMON_BUTTON_SAVE_CHANGES' |
          translate}}
        </button>
      </div><!-- /.modal-footer -->
    </form>
  </div><!-- /.modal-dialog -->
</div><!-- /.modal -->

<app-service-info-dialog
  #serviceInfoDialog>
</app-service-info-dialog>
