/* eslint-disable */
import { AddressResource } from '../address';
import { EmptyMessage, IdentityMessage } from '../util/messages';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { BaseHttpService, ResourceHelper } from '../util/http-services';
import { ResourceQueryResult } from '../util/services';
import SplitRequest = ShippingDemandResource.SplitRequest;
import { Cacheable } from 'ts-cacheable';
import { UiConstants } from '../../util/core-utils';
import { DqlStoredQueryResourceService } from '../dql/dql-stored-query-resource.service';
import { GeneralDqlStoredQueryResourceService } from '../dql/general-dql-stored-query-resource.service';
import { DqlResourceModel } from '../dql/dql-resource.model';
import { TaskResource } from '../task/task-resource.service';
import { CompanyResource } from '../company/company-resource.service';
import { HistoryLog } from '../history-log/history-log.service';
import { DownloadedFile } from '../util/downloaded-files';
import { UrlBuilder } from '../../util/url-builder';
import { map } from 'rxjs/operators';
import { TransportDocumentResource } from '../transport/transport-document/transport-document-resource.service';

/* eslint-enable */

@Injectable()
export class ShippingDemandResourceService extends BaseHttpService {

  private readonly _dqlStoredResourceService: DqlStoredQueryResourceService;

  query(request: ShippingDemandResource.QueryRequest): Observable<ResourceQueryResult<ShippingDemandResource.ShippingDemand>> {
    return this.http.get<ResourceQueryResult<ShippingDemandResource.ShippingDemand>>(this.url, this.parseParams(request));
  }

  get(request: ShippingDemandResource.GetRequest): Observable<ShippingDemandResource.ShippingDemand> {
    return this.http.get<ShippingDemandResource.ShippingDemand>(this.url + `${request.demand_id}`);
  }

  create(request: ShippingDemandResource.CreateRequest): Observable<EmptyMessage> {
    return this.http.post(this.url, request);
  }

  update(request: ShippingDemandResource.UpdateRequest): Observable<EmptyMessage> {
    return this.http.put(this.url + `${request.id}`, request);
  }

  cancel(request: IdentityMessage): Observable<EmptyMessage> {
    return this.http.post(this.url + `${request.id}/cancel`, null);
  }

  split(request:  SplitRequest): Observable<IdentityMessage> {
    return this.http.post<IdentityMessage>(this.url + `${request.shipping_demand_id}/split`, request.items);
  }

  history(request: ShippingDemandResource.HistoryRequest): Observable<ResourceQueryResult<HistoryLog.HistoryItemBaseResource>> {
    return this.http.get<ResourceQueryResult<HistoryLog.HistoryItemBaseResource>>(
      this.url + `${request.id}/history-logs`,
      this.parseParams(request)
    );
  }

  @Cacheable({
    maxAge: UiConstants.defaultCacheMaxAge,
    slidingExpiration: true
  })
  getEditableFields(): Observable<ShippingDemandResource.EditableFields> {
    return this.http.get<ShippingDemandResource.EditableFields>(this.url + `editable-fields`);
  }

  @Cacheable({
    maxAge: UiConstants.defaultCacheMaxAge,
    slidingExpiration: true
  })
  getDqlModel(): Observable<DqlResourceModel.QueryableModel> {
      return this.http.get<DqlResourceModel.QueryableModel>(this.url + `dql-model`);
  }

  generateCarriageTagPdfDocument(request: ShippingDemandResource.GenerateCarriageTagPdfDocumentRequest): Observable<DownloadedFile> {
    const url = UrlBuilder.create(
      '/shipping-demands/generate-carriage-tag-pdf-document')
      .baseUrl(this.resourceHelper.getBaseUrl())
      .build();
    return this.http.post(url, request, {
      observe: 'response',
      responseType: 'blob'
    }).pipe(map((res) => {
      return new DownloadedFile(res);
    }));
  }

  downloadCarriageReceiptPdfDocument(request: IdentityMessage): Observable<DownloadedFile> {
    const url = UrlBuilder.create(
      '/shipping-demands/:id/carriage-receipt-pdf-document')
      .baseUrl(this.resourceHelper.getBaseUrl())
      .namedNumber('id', request.id)
      .build();
    return this.http.get(url, {
      observe: 'response',
      responseType: 'blob'
    }).pipe(map((res) => {
      return new DownloadedFile(res);
    }));
  }

  constructor(private http: HttpClient, private resourceHelper: ResourceHelper) {
    super(resourceHelper, '/shipping-demands/');
    this._dqlStoredResourceService = new GeneralDqlStoredQueryResourceService(http, resourceHelper, 'SHIPPING_DEMAND');
  }

  get dqlStoredResourceService(): DqlStoredQueryResourceService {
    return this._dqlStoredResourceService;
  }

}

export namespace ShippingDemandResource {

  export interface QueryRequest {
    has_transport?: boolean;
    demand_id?: number;
    tour_route?: string;
    transporter_name?: string;
    demand_category?: string;
    source_name?: string;
    source_postal_address?: string;
    safety_shipping?: boolean;
    deadline_from?: string;
    deadline_to?: string;
    destination_name?: string;
    destination_postal_address?: string;
    demand_state?: string;
    shipping_item?: string;
    fields?: string;
    q?: string;
    dql?: string;
    order?: string;
    page_number?: number;
    number_of_items?: number;
  }

  export interface GetRequest {
    demand_id: number;
  }

  export interface ShippingDemand {
    id: number;
    external_id: string;
    creation_time: string;
    update_time: string;
    state: string;
    safety_shipping: boolean;
    recipient?: ExchangingPerson;
    transferor?: ExchangingPerson;
    transporter?: Company;
    demander?: Demander;
    source: ShippingPlace;
    destination: ShippingPlace;
    transports: Transport[];
    shipment_group?: ShipmentGroup;
    source_system_name?: string;
    comment?: string;
    demand_category?: string;
    ekaer_id?: string;
    ekaer_required?: boolean;
    tour_route?: string;
    deadline?: string;
    weight_in_gram?: number;
    insurance_price?: InsurancePrice;
    cash_on_delivery_price?: CashOnDeliveryPrice;
    delivery_note_number?: string;
    demand_group_serial: string;
    demand_serial: string;
    packaging_attributes: PackagingAttributes;
    carriage_state?: string;
  }

  export interface ExchangingPerson {
    id: number;
    name: string;
    license_category: string;
    license_number: string;
    comment?: string;
    email_address?: string;
    phone_number?: string;
  }

  export interface UpdateExchangingPerson {
    name: string;
    license_category?: string;
    license_number?: string;
    comment?: string;
    email_address?: string;
    phone_number?: string;
  }

  export interface Company {
    id: number;
    name: string;
    address?: AddressResource.PostalAddressResource;
  }

  export interface Demander {
    company?: Company;
  }

  export interface ShippingPlace {
    id: number;
    stock?: Stock;
    place: AddressResource.PlaceResource;
    location?: CompanyLocation;
    location_changed: boolean;
  }

  export interface Transport {
    id: number;
    waybill_number: string;
    security_guard_name?: string;
  }

  export interface ShipmentGroup {
    id: number;
    shipments: Shipment[];
  }

  export interface Shipment {
    id: number;
    delivery_note_number: string;
  }

  export interface Stock {
    id: number;
    name: string;
    assignee_group_id: number;
  }

  export interface CompanyLocation {
    id: number;
    name: string;
    place?: AddressResource.PlaceResource;
  }

  export interface CashOnDeliveryPrice {
    amount: string;
    currency_code: string;
  }

  export interface InsurancePrice {
    amount: string;
    currency_code: string;
  }

  export interface PackagingAttributes {
    fragile: boolean;
    document_handling: boolean;
    package_exchange: boolean;
    handover_by_items: boolean;
    delivery_time_window: boolean;
    oversized: boolean;
    inverse: boolean;
  }

  export interface CreateRequest {
    external_id?: string;
    safety_shipping: boolean;
    recipient?: UpdateExchangingPerson;
    transferor?: UpdateExchangingPerson;
    transporter_id?: number;
    demander_id?: number;
    source: SourceDestination;
    destination: SourceDestination;
    source_system_name?: string;
    comment?: string;
    demand_category?: string;
    ekaer_id?: string;
    ekaer_required?: boolean;
    tour_route?: string;
    deadline?: string;
    weight_in_gram?: number;
    items: Item[];
    insurance_price?: InsurancePrice;
    cash_on_delivery_price?: CashOnDeliveryPrice;
    delivery_note_number?: string;
    packaging_attributes: PackagingAttributes;
    carriage_by_demander_company: boolean;
  }

  export interface SourceDestination {
    stock_id?: number;
    place?: AddressResource.PlaceCreateResourceRequest;
    location_id?: number;
  }

  export interface Item {
    id?: number;
    external_id?: string;
    name: string;
    category?: string;
    product_code?: string;
    amount?: Amount;
    comment?: string;
    box_code?: string;
    label_code?: string;
    weight_in_gram?: number;
    item_size?: ItemSize;
    price?: Price;
  }

  export interface Amount {
    value: number;
    unit: string;
  }

  export interface ItemSize {
    width_in_meter: string;
    height_in_meter: string;
    depth_in_meter: string;
  }

  export interface Price {
    amount: string;
    currency_code: string;
  }

  export interface UpdateRequest {
    id: number;
    external_id?: string;
    safety_shipping: boolean;
    recipient?: UpdateExchangingPerson;
    transferor?: UpdateExchangingPerson;
    transporter_id?: number;
    demander_id?: number;
    source: SourceDestination;
    destination: SourceDestination;
    source_system_name?: string;
    comment?: string;
    demand_category?: string;
    ekaer_id?: string;
    ekaer_required?: boolean;
    tour_route?: string;
    deadline?: string;
    weight_in_gram?: number;
    items: Item[];
    insurance_price?: InsurancePrice;
    cash_on_delivery_price?: CashOnDeliveryPrice;
    packaging_attributes: PackagingAttributes;
    carriage_by_demander_company: boolean;
  }

  export interface SplitRequest {
    shipping_demand_id: number;
    items: SplitItem[];
  }

  export interface SplitItem {
    external_id: string;
    amount: number;
  }

  export interface EditableFields {
    editable_normal_fields: string[];
    editable_simple_fields: string[];
  }

  export interface HistoryRequest {
    id: number;
    q?: string;
    order?: string;
    page_number?: number;
    number_of_items?: number;
  }

  export interface GenerateCarriageTagPdfDocumentRequest {
    demand_ids: number[];
  }
}
