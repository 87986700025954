/* eslint-disable */
import { HttpParams } from '@angular/common/http';
import { RuntimeConfiguration } from '../runtime-configuration';
import { environment } from '../../../environments/environment';
import { BuildConfig } from '../../build-config';
import { Injectable } from '@angular/core';
import { SettingsService } from '../settings.service';

/* eslint-enable */

@Injectable()
export class ResourceHelper {

  constructor(private settingsService: SettingsService) {
  }

  public getBaseUrl(): string {
    return this.replaceApiVersion(
      RuntimeConfiguration.getInstance().getBaseUrl(environment.baseUrl)
    );
  }

  public getBrandLogoSrc(): string | undefined {
    const src = RuntimeConfiguration.getInstance().getBrandLogoSrc();
    return this.replaceOptVars(src);
  }

  public getBrandLogoSmallSrc(): string | undefined {
    const src = RuntimeConfiguration.getInstance().getBrandLogoSmallSrc();
    return this.replaceOptVars(src);
  }

  public getBrandLogoLoginSrc(): string | undefined {
    const src = RuntimeConfiguration.getInstance().getBrandLogoLoginSrc();
    return this.replaceOptVars(src);
  }

  public getLoginBackgroundSrc(): string | undefined {
    const src = RuntimeConfiguration.getInstance().getLoginBackgroundSrc();
    return this.replaceOptVars(src);
  }

  public getFaviconPngSrc(): string | undefined {
    const src = RuntimeConfiguration.getInstance().getFaviconPngSrc();
    return this.replaceOptVars(src);
  }

  private replaceApiVersion(text: string): string {
    return text.replace('{{apiVersion}}', BuildConfig.INT_API_VERSION.toString())
  }

  private replaceLanguageCode(text: string): string {
    const languageCode = this.settingsService.getLanguageCode()
    return text.replace('{{languageCode}}', languageCode)
  }

  private replaceBaseUrl(text: string): string {
    const baseUrl = this.getBaseUrl()
    return text.replace('{{baseUrl}}', baseUrl)
  }

  private replaceVars(text: string): string {
    return this.replaceBaseUrl(
      this.replaceLanguageCode(
        this.replaceApiVersion(text)
      )
    );
  }

  private replaceOptVars(text: string | undefined): string | undefined {
    if (text) {
      return this.replaceVars(text);
    }
    return text;
  }

}

export class BaseHttpService {

  static readonly WITH_LOCATION: string = 'with_location';
  static readonly LOCATION_ON: string = BaseHttpService.WITH_LOCATION + '=true';

  private readonly _path: string;
  private readonly _baseUrl: string;

  constructor(resourceHelper: ResourceHelper, url: string) {
    url = url.startsWith('/') ? url.substr(1) : url;
    url = url.endsWith('/') ? url.substr(0, url.length - 1) : url;
    this._path = url;
    this._baseUrl = resourceHelper.getBaseUrl();
  }

  get url(): string {
    return `${this._baseUrl}/${this._path}/`;
  }

  get resourcePath(): string {
    return this._path;
  }

  parseParams(params: Object) {
    const stringParams = {};
    for (const key in params) {
      if (params.hasOwnProperty(key)) {
        const value = params[key];
        if (value !== undefined) {
          stringParams[key] = value.toString().trim();
        }
      }
    }
    const ret = new HttpParams({fromObject: stringParams});
    return {params: ret};
  }

  parseLocation(params: Object) {
    const stringParams = {};
    if (params[BaseHttpService.WITH_LOCATION]) {
      stringParams[BaseHttpService.WITH_LOCATION] = 'true';
    }
    return {params: new HttpParams({fromObject: stringParams})};
  }
}

export class HeaderKeys {
  public static readonly AUTHORIZATION = 'Authorization';
  public static readonly ACCEPT_LANGUAGE = 'Accept-Language';
  public static readonly CONTENT_TYPE = 'Content-Type';
  public static readonly CONTENT_DISPOSITION = 'Content-Disposition';
  public static readonly TOTAL_NUMBER_OF_ITEMS = 'x-total-number-of-items';
  public static readonly NUMBER_OF_PAGES = 'x-number-of-pages';
  public static readonly PLACE_AUTOCOMPLETE_TOKEN = 'x-place-autocomplete-token';
  public static readonly CURRENT_NUMBER_OF_ITEMS = 'x-current-number-of-items';
  public static readonly GPS_LATITUDE = 'x-gps-latitude';
  public static readonly GPS_LONGITUDE = 'x-gps-longitude';
  public static readonly ECHO = 'x-echo';
  public static readonly ACCEPT = 'Accept';
  public static readonly SHOPRENTER_TOKEN = 'x-shoprenter-token';
  public static readonly RECAPTCHA_TOKEN_V2 = 'x-recaptcha-v2';
  public static readonly RECAPTCHA_TOKEN_V3 = 'x-recaptcha-v3';
}

export class HeaderValues {
  public static serializeEchoHeader(header: EchoHeader): string {
    return JSON.stringify(header);
  }

  public static parseEchoHeader(header: string | null): EchoHeader | undefined {
    if (header) {
      return <EchoHeader>JSON.parse(header);
    }
    return undefined;
  }
}

export interface EchoHeader {
  ignore_global_errors?: boolean;
  ignore_entity_not_found?: boolean;
  ignore_function_disabled?: boolean;
  ignore_permission_denied?: boolean;
}

export class EchoHeaderBuilder {

  private _ignoreGlobalErrors?: boolean;
  private _ignoreEntityNotFound?: boolean;
  private _ignoreFunctionDisabled?: boolean;
  private _ignorePermissionDenied?: boolean;

  public ignoreGlobalErrors(value: boolean): EchoHeaderBuilder {
    this._ignoreGlobalErrors = value;
    return this;
  }

  public ignoreEntityNotFound(value: boolean): EchoHeaderBuilder {
    this._ignoreEntityNotFound = value;
    return this;
  }

  public ignoreFunctionDisabled(value: boolean): EchoHeaderBuilder {
    this._ignoreFunctionDisabled = value;
    return this;
  }

  public ignorePermissionDenied(value: boolean): EchoHeaderBuilder {
    this._ignorePermissionDenied = value;
    return this;
  }

  public build(): EchoHeader {
    return {
      ignore_global_errors: this._ignoreGlobalErrors,
      ignore_entity_not_found: this._ignoreEntityNotFound,
      ignore_function_disabled: this._ignoreFunctionDisabled,
      ignore_permission_denied: this._ignorePermissionDenied
    }
  }
}

export class ServiceErrorCode {
  public static readonly UNSUPPORTED_APPLICATION_OUTDATED_API_VERSION = 'UNSUPPORTED_APPLICATION_OUTDATED_API_VERSION';
  public static readonly UNSUPPORTED_APPLICATION_UNKNOWN_API_VERSION = 'UNSUPPORTED_APPLICATION_UNKNOWN_API_VERSION';
  public static readonly UNAUTHORIZED_ACCESS_INVALID_ACCESS_TOKEN = 'UNAUTHORIZED_ACCESS_INVALID_ACCESS_TOKEN';
  public static readonly UNAUTHORIZED_ACCESS_PERMISSION_DENIED = 'UNAUTHORIZED_ACCESS_PERMISSION_DENIED';
  public static readonly UNAUTHORIZED_ACCESS_UNKNOWN_USER = 'UNAUTHORIZED_ACCESS_UNKNOWN_USER';
  public static readonly USER_AUTHENTICATION_ERROR = 'USER_AUTHENTICATION_ERROR';
  public static readonly REQUEST_VALIDATION_ERROR = 'REQUEST_VALIDATION_ERROR';
  public static readonly CONFLICT = 'CONFLICT';
  public static readonly ENTITY_NOT_FOUND = 'ENTITY_NOT_FOUND';
  public static readonly CHILD_ENTITY_NOT_FOUND = 'CHILD_ENTITY_NOT_FOUND';
  public static readonly FUNCTION_DISABLED = 'FUNCTION_DISABLED';
  public static readonly GENERAL_ERROR = 'GENERAL_ERROR';
}
