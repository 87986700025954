<div class="breadcrumb-container" [ngClass]="{ 'breadcrumb-container-compact': compactSidebar }" id="breadcrumb">
  <div class="breadcrumb-component">
    <app-breadcrumb
      [parents]="breadcrumbParents"
      [self]="breadcrumbSelf">
    </app-breadcrumb>
  </div>
</div>
<div class="breadcrumb-placeholder"></div>
<div class="container-vertical-padding animated fadeIn">

  <div class="col-xs-12 col-sm-12 col-md-8">
    <!--BASIC DATA-->
    <div class="card">
      <div class="card-header">
        {{'DOCUMENT_LINK_PANEL_HEADING_ADD'|translate}}
      </div>
      <div class="card-body">
        <form class="form-horizontal bordered-row" (ngSubmit)="f.form.valid && update()" #f="ngForm" novalidate>

          <!--NAME-->
          <div class="form-group row" [ngClass]="{ 'has-danger': f.submitted && !name.valid }">
            <label class="col-form-label form-control-label col-md-4 detail-title required-field-label">
              {{'COMMON_NAME' | translate}}
            </label>
            <div class="col-md-8">
              <input
                type="text"
                class="form-control"
                maxlength="{{getTextMaximumLength()}}"
                placeholder="{{'COMMON_NAME' | translate}}"
                [ngClass]="{ 'form-control-danger': f.submitted && !name.valid }"
                [(ngModel)]="model.name"
                name="name"
                id="name"
                #name="ngModel"
                required>
              <div class="form-control-feedback" *ngIf="f.submitted && !name.valid">
                {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
              </div>
            </div>
          </div>

          <!--CODE-->
          <div class="form-group row">
            <label class="col-form-label form-control-label col-md-4 detail-title">{{'DOCUMENT_TEXT_CODE' | translate}}</label>
            <div class="col-md-8">
              <input
                type="text"
                class="form-control"
                maxlength="{{getTextMaximumLength()}}"
                placeholder="{{'DOCUMENT_TEXT_CODE' | translate}}"
                [(ngModel)]="model.code"
                name="code"
                id="code"
                #code="ngModel">
            </div>
          </div>

          <!--COMMENT-->
          <div class="form-group row">
            <label class="col-form-label form-control-label col-md-4 detail-title">{{'DOCUMENT_TEXT_COMMENT' | translate}}</label>
            <div class="col-md-8">
              <input
                type="text"
                class="form-control"
                maxlength="{{getTextMaximumLength()}}"
                placeholder="{{'DOCUMENT_TEXT_COMMENT' | translate}}"
                [(ngModel)]="model.comment"
                name="comment"
                id="comment"
                #description="ngModel">
            </div>
          </div>

          <!--GROUP-->
          <div class="form-group row">
            <label class="col-form-label form-control-label col-md-4 detail-title">{{'DOCUMENT_GROUP' | translate}}</label>
            <div class="col-md-8">
              <select
                class="form-control"
                title=""
                [(ngModel)]="model.document_group"
                id="document_group"
                name="document_group"
                #document_group="ngModel">
                <option *ngFor="let documentGroup of documentGroupList" [ngValue]="documentGroup">
                  {{documentGroup.text}}
                </option>
              </select>
            </div>
          </div>

          <!--LANGUAGE-->
          <div class="form-group row">
            <label class="col-form-label form-control-label col-md-4 detail-title">{{'DOCUMENT_LANGUAGE' | translate}}</label>
            <div class="col-md-8">
              <select
                class="form-control"
                title=""
                [(ngModel)]="model.language_code"
                id="language"
                name="language"
                #language="ngModel">
                <option *ngFor="let language of documentLanguageList" [ngValue]="language">{{language.text}}</option>
              </select>
            </div>
          </div>
          <!--TEXT_VALUE-->
          <div class="form-group row" [ngClass]="{ 'has-danger': f.submitted && !value.valid }">
            <label class="col-form-label form-control-label col-md-4 detail-title required-field-label">
              {{'DOCUMENT_LINK_VALUE' | translate}}
            </label>
            <div class="col-md-8">
              <input
                type="url"
                class="form-control"
                maxlength="{{getTextMaximumLength()}}"
                placeholder="{{'DOCUMENT_LINK_VALUE' | translate}}"
                [ngClass]="{ 'form-control-danger': f.submitted && !value.valid }"
                [(ngModel)]="model.value"
                name="value"
                id="value"
                #value="ngModel"
                required>
              <div class="form-control-feedback" *ngIf="f.submitted && !value.valid">
                {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
              </div>
            </div>
          </div>

          <!--SEND BUTTON-->
          <div>
            <div class="pull-right">
              <input type="submit" class="btn btn-primary" value="{{'COMMON_BUTTON_SAVE'|translate}}"/>
            </div>
          </div>
        </form>
      </div>  <!-- ./card-body end -->

    </div> <!-- ./card end -->
  </div>
</div>
