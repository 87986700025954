import { DqlQuery, Query } from '../field';
import { LazyReference } from '../../../util/lazy';
import { Company } from './company';
import { ExteriorTransport as extTrans } from '../../exterior-transport/exterior-transport.service';
import ExteriorTransportState = extTrans.ExteriorTransportState;

// Each class should be an interface, but it is easier to define the constant fields in the constructors.
// For now we have only one query language so it is not a problem.
// Always use the public Query namespace for variable definitions so we will be able to introduce other language easily.

export class ExteriorTransport {

  private readonly serialRef: LazyReference<ExteriorTransportSerialFilterField>;

  private readonly transporterCompanyRef: LazyReference<Company>;

  readonly state: Query.EnumField<ExteriorTransportState>;

  get serial(): ExteriorTransportSerialFilterField {
    return this.serialRef.get();
  }

  get transporterCompany(): Company {
    return this.transporterCompanyRef.get();
  }

  constructor(prefix?: string) {
    prefix = prefix ? prefix : '';
    this.serialRef = LazyReference.of(
      () => new ExteriorTransportSerialFilterField(prefix + 'serial.')
    );
    this.transporterCompanyRef = LazyReference.of(
      () => new Company(prefix + 'transporter_company.')
    );
    this.state = new DqlQuery.EnumField<ExteriorTransportState>(prefix + 'state', (sts) => sts);
  }

}

export class ExteriorTransportSerialFilterField {
  number: Query.StringField;

  constructor(prefix?: string) {
    prefix = prefix ? prefix : '';
    this.number = new DqlQuery.StringField(prefix + 'number');
  }
}
