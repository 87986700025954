/* eslint-disable */
import { EmptyMessage, IdentityMessage } from '../util/messages';
import { Injectable } from '@angular/core';
import { AddressResource } from '../address';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { BaseHttpService, ResourceHelper } from '../util/http-services';
import { ResourceQueryResult } from '../util/services';
import { HistoryLog } from '../history-log/history-log.service';

/* eslint-enable */

@Injectable()
export class CompanyResourceService extends BaseHttpService {

  query(request: CompanyResource.QueryRequest): Observable<ResourceQueryResult<CompanyResource.Company>> {
    return this.http.get<ResourceQueryResult<CompanyResource.Company>>(this.url, this.parseParams(request));
  }

  get(request: CompanyResource.GetRequest): Observable<CompanyResource.Company> {
    return this.http.get<CompanyResource.Company>(this.url + `${request.id}`);
  }

  create(request: CompanyResource.CreateRequest): Observable<IdentityMessage> {
    return this.http.post<IdentityMessage>(this.url, request);
  }

  update(request: CompanyResource.UpdateRequest): Observable<EmptyMessage> {
    return this.http.put(this.url + `${request.id}`, request);
  }

  setDisabled(request: CompanyResource.DisableRequest): Observable<EmptyMessage> {
    return this.http.patch(this.url + `${request.id}/disabled`, request);
  }

  updateRole(request: CompanyResource.UpdateRoleRequest): Observable<EmptyMessage> {
    return this.http.patch(this.url + `${request.id}/roles?force_remove=${request.force_remove}`, request);
  }

  history(request: CompanyResource.HistoryRequest): Observable<ResourceQueryResult<CompanyResource.HistoryItem>> {
    return this.http.get<ResourceQueryResult<CompanyResource.HistoryItem>>(
      this.url + `${request.id}/history-logs?with_read=${request.with_read}`,
      this.parseParams(request)
    );
  }

  constructor(private http: HttpClient, resourceHelper: ResourceHelper) {
    super(resourceHelper, '/companies/');
  }

}

export namespace CompanyResource {

  export interface Company {
    id: number;
    external_id: string;
    type: string;
    name: string;
    disabled: boolean;
    comment?: string;
    postal_address: AddressResource.PostalAddressResource;
    phone_number?: string;
    email_address?: string;
    tax_number?: string;
    eu_tax_number?: string;
    allowed_role_ids?: number[];
    transporter_company_ids?: number[];
    location_ids?: number[];
    last_editor_id?: number;
    creation_time?: string;
    update_time?: string;
    update_count?: number;
  }

  export interface QueryRequest {
    id?: string;
    external_id?: string;
    name?: string;
    q?: string;
    order?: string;
    page_number?: number;
    number_of_items?: number;
    disabled?: boolean;
    type?: string;
    transporter_company_ids?: string;
    demander_company_ids?: string;
    no_progress_bar?: boolean;
  }

  export interface GetRequest {
    id: number;
  }

  export interface CreateRequest {
    external_id?: string;
    name: string;
    type: string;
    comment?: string;
    postal_address: AddressResource.PostalAddressResource;
    phone_number?: string;
    email_address?: string;
    tax_number?: string;
    eu_tax_number?: string;
    allowed_role_ids?: number[];
    transporter_company_ids?: number[];
  }

  export interface UpdateRequest {
    id: number;
    external_id?: string;
    name: string;
    comment?: string;
    postal_address: AddressResource.PostalAddressResource;
    phone_number?: string;
    email_address?: string;
    tax_number?: string;
    eu_tax_number?: string;
    transporter_company_ids?: number[];
  }

  export interface DisableRequest {
    id: number;
    disabled: boolean;
  }

  export interface UpdateRoleRequest {
    id: number;
    force_remove: boolean;
    allowed_role_ids: number[];
  }

  export interface HistoryRequest {
    id: number;
    with_read: boolean;
    q?: string;
    order?: string;
    page_number?: number;
    number_of_items?: number;
  }

  export interface HistoryItem extends HistoryLog.HistoryItemBaseResource {
  }


}
