import { Component, Input, OnInit } from '@angular/core';
import { CustomerRecord } from '../../../../lib/customer/customer-record.service';
import { MultiselectOptionItem, UiConstants } from '../../../../util/core-utils';
import { InputMask } from '../../../../util/input-masks';
import { Angular2Multiselects } from '../../../../util/multiselect';
import { List } from 'immutable';
import { Address, AddressModel } from '../../../../lib/address';
import { Country, CountryService } from '../../../../lib/country.service';
import { ConfigurationService } from '../../../../lib/core-ext/configuration.service';
import { ForwardingNgFormRef, LocalFormGroupValidationErrors, QueryResult } from '../../../../lib/util/services';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { AppValidators } from '../../../../util/app-validators';
import InvoiceVatStatus = CustomerRecord.InvoiceVatStatus;

@Component({
  selector: 'app-customer-record-billing-info-base',
  templateUrl: './customer-record-billing-info-base.component.html',
  styleUrls: ['./customer-record-billing-info-base.component.scss']
})
export class CustomerRecordBillingInfoBaseComponent implements OnInit {

  UiConstants = UiConstants;
  InputMask = InputMask;
  InvoiceVatStatus = CustomerRecord.InvoiceVatStatus;

  @Input()
  data?: CustomerRecord.BillingInfo;

  @Input()
  mode: CustomerRecordBillingInfoComponentMode = CustomerRecordBillingInfoComponentMode.CREATE;

  @Input()
  form: NgForm;
  formGroup: FormGroup;
  private formGroupValidationErrors: LocalFormGroupValidationErrors;

  @Input()
  model: CustomerRecordBillingInfoModel = new CustomerRecordBillingInfoModel();

  dropdownSettings: Angular2Multiselects.Settings;

  invoiceVatStatuses: MultiselectOptionItem<CustomerRecord.InvoiceVatStatus>[]
    = CustomerRecord.invoiceVatStatuses.map(s => ({id: s.type, itemName: s.stringKey}));

  countryItems: List<AddressModel.CountryItem>;

  get readonly(): boolean {
    return this.mode === CustomerRecordBillingInfoComponentMode.DETAIL;
  }

  constructor(private fb: FormBuilder,
              private countryService: CountryService,
              private configService: ConfigurationService) {
  }

  ngOnInit() {
    this.initDropdownSettings();
    this.loadBillingInfo();
    this.formGroup = this.createFormGroup(this.fb);
    this.formGroupValidationErrors = LocalFormGroupValidationErrors.ofForm(
      this.createForwardingHtmlForm(),
      this.formGroup
    );
  }

  private createForwardingHtmlForm() {
    return new ForwardingNgFormRef({
      formFn: () => {
        return this.form;
      }
    });
  }

  hasLocalFieldError(formControlName?: string, errorCode?: string): boolean {
    return this.form !== undefined && this.form.submitted && this.formGroupValidationErrors.hasFieldError(formControlName, errorCode);
  }

  private createFormGroup(fb: FormBuilder): FormGroup {
    return fb.group(
      {
        name: fb.control(
          {value: this.model.name, disabled: this.readonly},
          [
            AppValidators.tempValidator({validator: Validators.required, disabled: () => this.readonly})
          ]
        ),
        vatStatuses: fb.control(
          {value: this.model._invoiceVatStatus, disabled: this.readonly},
          [
            AppValidators.tempValidator({validator: Validators.required, disabled: () => this.readonly})
          ]
        ),
        taxNumber: fb.control(
          {value: this.model.taxNumber, disabled: this.readonly},
          [
            AppValidators.tempValidator({
              validator: Validators.required,
              disabled: () => this.readonly || this.model.invoiceVatStatus !== this.InvoiceVatStatus.DOMESTIC
            })
          ]
        ),
        euTaxNumber: fb.control(
          {value: this.model.euTaxNumber, disabled: this.readonly},
          [
            AppValidators.tempValidator({
              validator: AppValidators.validateEuVatNumber,
              disabled: () => this.model.invoiceVatStatus !== InvoiceVatStatus.OTHER || this.readonly
            })
          ]
        ),
        bankAccount: fb.control(
          {value: this.model.bankAccount, disabled: this.readonly},
          []
        )
      }
    );
  }

  initDropdownSettings() {
    this.dropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(true)
      .enableSearchFilter(false)
      .enableCheckAll(false)
      .translate(true)
      .build();
  }

  loadCountries(completion?: () => void) {
    this.countryService.query({}).subscribe((result: QueryResult<Country.Country>) => {
      this.countryItems = AddressModel.CountryItem.fromCountryList(result.items);
      this.model.invoiceAddress.loadCountryItems(this.countryItems, this.configService.getDefaultSelectedCountryCode());
      if (completion) {
        completion();
      }
    });
  }

  onVatStatusChanged() {
    if (this.model.invoiceVatStatus !== InvoiceVatStatus.DOMESTIC) {
      this.model.taxNumber = '';
    }
    if (this.model.invoiceVatStatus !== InvoiceVatStatus.OTHER) {
      this.model.euTaxNumber = '';
    }
  }

  loadBillingInfo() {
    this.model.reset();
    if (!this.data) {
      this.loadCountries();
      return;
    }
    this.model.id = this.data.id;
    this.model.name = this.data.name;
    this.model._invoiceVatStatus
      = CustomerRecord.invoiceVatStatuses.filter(s => s.type === this.data!.invoiceVatStatus)
      .map(s => ({id: s.type, itemName: s.stringKey}));
    this.model.taxNumber = this.data.taxNumber ? this.data.taxNumber : '';
    this.model.euTaxNumber = this.data.euTaxNumber ? this.data.euTaxNumber : '';
    this.model.bankAccount = this.data.bankAccount ? this.data.bankAccount : '';
    if (this.mode === CustomerRecordBillingInfoComponentMode.EDIT) {
      this.loadCountries(() => {
        this.model.invoiceAddress.load(this.data!.invoiceAddress);
      });
    }
    else {
      this.model.invoiceAddressString
        = Address.PostalAddressMapper.toString(this.data.invoiceAddress, this.configService.getPostalAddressFormat());
    }
  }

}

export class CustomerRecordBillingInfoModel {

  id?: number;
  name: string = '';
  taxNumber: string = '';
  euTaxNumber: string = '';
  bankAccount: string = '';
  _invoiceVatStatus: MultiselectOptionItem<CustomerRecord.InvoiceVatStatus>[] = [];
  invoiceAddress: AddressModel.PostalAddressModel;
  invoiceAddressString: string = '';

  constructor() {
    this.invoiceAddress = new AddressModel.PostalAddressModel();
    this.invoiceAddress.type = AddressModel.PostalAddressModelType.COMPLEX;
  }

  get invoiceVatStatus(): CustomerRecord.InvoiceVatStatus | undefined {
    return this._invoiceVatStatus.length === 1 ? this._invoiceVatStatus[0].id : undefined;
  }

  reset() {
    this.id = undefined;
    this.name = '';
    this.taxNumber = '';
    this.euTaxNumber = '';
    this.bankAccount = '';
    this._invoiceVatStatus = [];
    this.invoiceAddress.complexValue.reset();
    this.invoiceAddressString = '';
  }

}

export enum CustomerRecordBillingInfoComponentMode {
  CREATE,
  EDIT,
  DETAIL
}
