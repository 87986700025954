import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Task, TaskService } from '../../../../../lib/task/task.service';
import { Set } from 'immutable';
import { OrderType } from '../../../../../lib/util/services';

@Component({
  selector: 'app-workflow-task-selector-dialog',
  templateUrl: './workflow-task-selector-dialog.component.html',
  styleUrls: ['./workflow-task-selector-dialog.component.scss']
})
export class WorkflowTaskSelectorDialogComponent implements OnInit {

  tasks: Task.Task[] = [];

  constructor(
    public dialogRef: MatDialogRef<WorkflowTaskSelectorDialogComponent, Task.Task>,
    private taskService: TaskService
  ) {
  }

  ngOnInit() {
    this.loadTasks();
  }

  private loadTasks() {
    this.taskService.query({
      disabled: false,
      orders: Set.of({type: OrderType.ASC, field: Task.OrderField.NAME})
    }).subscribe(result => {
      this.tasks = result.items.toArray();
    });
  }


  closeDialog(task?: Task.Task) {
    this.dialogRef.close(task);
  }

}
