/* eslint-disable */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgForm } from '@angular/forms';
import { TaskRecordStateItem } from '../../../../util/task-record-utils';
import { StateName } from '../../../../app.state-names';
import { TaskRecordBase } from '../task-record-base/task-record-base-view';
import { TaskRecord, TaskRecordService } from '../../../../lib/task/task-record.service';
import { UIRouter } from '@uirouter/angular';
import { TaskRecordStateMachine } from '../../../../lib/task/task-record-statemachine';
import { Set } from 'immutable';
/* eslint-enable */

@Component({
  selector: 'app-task-record-breadcrumb-buttons',
  templateUrl: './task-record-breadcrumb-buttons.component.html',
  styleUrls: ['./task-record-breadcrumb-buttons.component.scss']
})
export class TaskRecordBreadcrumbButtonsComponent implements OnInit {

  @Input()
  form?: NgForm;

  @Input()
  readonly: boolean;

  @Input()
  allowEdit?: boolean = false;

  @Input()
  allowInvoice?: boolean = false;

  @Input()
  allowClone: boolean;

  @Input()
  allowBulkClone: boolean;

  @Input()
  allowAssigneeChangeInProgress: boolean;

  @Input()
  allowApprovalStart: boolean;

  @Input()
  allowApprovalCancel: boolean;

  @Input()
  allowStateChange: boolean;

  @Input()
  allowGeocode: boolean = true;

  @Input()
  allowExport: boolean;

  @Input()
  taskRecordDefined: boolean;

  @Input()
  taskId: number;

  @Input()
  taskRecordId: number;

  @Input()
  settableStateList: TaskRecordStateItem[];

  @Input()
  sendMessage?: boolean;

  @Output()
  private stateChange = new EventEmitter<TaskRecordStateMachine.State>();

  @Output()
  private sendMessageEmitter = new EventEmitter();

  @Output()
  private invoiceEmitter = new EventEmitter();

  @Output()
  bulkClone: EventEmitter<any> = new EventEmitter();

  @Output()
  private assigneeChangeInProgressEmitter = new EventEmitter();

  @Output()
  startApproval: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  cancelApproval: EventEmitter<any> = new EventEmitter<any>();

  taskRecord?: TaskRecord.TaskRecord;

  constructor(
    private taskRecordService: TaskRecordService,
    private uiRouter: UIRouter,
  ) {
  }

  ngOnInit() {
  }

  editTaskRecord() {
    if (this.taskRecordDefined && this.allowEdit) {
      this.uiRouter.stateService.go(StateName.TASK_RECORD_EDIT, {
        taskId: this.taskId,
        taskRecordId: this.taskRecordId
      });
    }
  }

  cloneTaskRecord() {
    if (this.taskRecordDefined && this.allowClone) {
      this.uiRouter.stateService.go(StateName.TASK_RECORD_CLONE, {
        taskId: this.taskId,
        taskRecordId: this.taskRecordId
      });
    }
  }

  bulkCloneTaskRecord() {
    if (this.taskRecordDefined && this.allowBulkClone) {
      this.bulkClone.emit(this.taskRecordId);
    }
  }

  changeState(newState: TaskRecordStateMachine.State) {
    this.stateChange.emit(newState);
  }

  geocode() {
    if (this.taskRecordDefined) {
      this.taskRecordService.geocode({taskRecordIdSet: Set.of(this.taskRecordId)});
    }
  }

  messageClicked() {
    this.sendMessageEmitter.emit();
  }

  invoiceClicked() {
    this.invoiceEmitter.emit();
  }

  changeAssigneeInProgress() {
    if (this.taskRecordDefined && this.allowAssigneeChangeInProgress) {
      this.assigneeChangeInProgressEmitter.emit();
    }
  }

  allowStartApproval() {
    return this.allowApprovalStart;
  }
  allowCancelApproval() {
    return this.allowApprovalCancel;
  }
}
