/* eslint-disable */
import { Injectable } from '@angular/core';
import { MultiselectOptionItem, UiConstants } from '../../../util/core-utils';
import { Order, OrderType } from '../../util/services';
import { Observable, Observer } from 'rxjs';
import { MemStorage } from '../../util/mem-storage';
import { LoggedInUserStorage } from '../../util/storages';
import { TaskRecordStateMachine } from '../../task/task-record-statemachine';
import { TaskRecord } from '../../task/task-record.service';
import { EmptyMessage } from '../../util/messages';
import { Task } from '../../task/task.service';
import {TaskStatistics} from "../../statistics/task-statistics/task-statistics.service";
import {HelpdeskState} from "../../statistics/task-statistics/helpdesk.state";

/* eslint-enable */

@Injectable()
export class BugReportSearchService {

  constructor() {
  }

  getSearchData(request: BugReportSearch.SearchDataGetRequest): Observable<BugReportSearch.SearchDataResult> {
    const storage = this.createStorage();
    return Observable.create((observer: Observer<BugReportSearch.SearchDataResult>) => {
      try {
        observer.next(storage.read(request));
      } catch (e) {
        observer.error(e);
      }
      observer.complete();
      return function () {
      };
    });
  }

  setSearchData(request: BugReportSearch.SearchDataSetRequest): Observable<BugReportSearch.SearchDataResult> {
    const storage = this.createStorage();
    return Observable.create((observer: Observer<BugReportSearch.SearchDataResult>) => {
      try {
        observer.next(storage.save(request));
      } catch (e) {
        observer.error(e);
      }
      observer.complete();
      return function () {
      };
    });
  }

  setQuery(query: string): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      this.getSearchData({}).subscribe(result => {
          const previousSearchData = Object.assign({}, result);
          previousSearchData.searchData.query = query;
          previousSearchData.searchData.pageNumber = 1;
          this.setSearchData(previousSearchData).subscribe(result => {
              observer.next({});
            },
            error => observer.error(error),
            () => observer.complete());
        },
        error => observer.error(error),
        () => observer.complete())
    });
  }

  setStates(states: HelpdeskState.State[]): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      this.getSearchData({}).subscribe(result => {
          const previousSearchData = Object.assign({}, result);
          previousSearchData.searchData.states = HelpdeskState.orderedHelpdeskStates
            .filter(s => states.includes(s.id));
          previousSearchData.searchData.pageNumber = 1;
          this.setSearchData(previousSearchData).subscribe(result => {
              observer.next({});
            },
            error => observer.error(error),
            () => observer.complete());
        },
        error => observer.error(error),
        () => observer.complete())
    });
  }

  resetSearchData(request: BugReportSearch.SearchDataGetRequest): Observable<BugReportSearch.SearchDataResult> {
    const storage = this.createStorage();
    return Observable.create((observer: Observer<BugReportSearch.SearchDataResult>) => {
      try {
        observer.next(storage.reset(request));
      } catch (e) {
        observer.error(e);
      }
      observer.complete();
      return function () {
      };
    });
  }

  private createStorage(): BugReportSearch.Storage {
    return new BugReportSearch.Storage();
  }

}

export namespace BugReportSearch {

  export class Model {
    query: string = '';
    states: MultiselectOptionItem<HelpdeskState.State>[] = [];

    reset() {
      this.query = '';
      this.states = [];
    }
  }

  export interface SearchDataGetRequest {
  }

  export interface SearchDataSetRequest {
    searchData: SearchData;
  }

  export interface SearchDataResult {
    searchData: SearchData;
  }

  export interface SearchData {
    itemsPerPage: number;
    pageNumber: number;
    order: Order<TaskRecord.OrderField>;
    query: string;
    states: MultiselectOptionItem<HelpdeskState.State>[];
  }

  interface StoredSearchRawDataBase {
    version: number;
  }

  interface StoredSearchRawData extends StoredSearchRawDataBase {
    itemsPerPage: number;
    pageNumber: number;
    order: Order<TaskRecord.OrderField>;
    query: string;
    states: MultiselectOptionItem<HelpdeskState.State>[];
  }

  export class Storage {

    /**
     * Increment this version if you change the SearchData interface.
     */
    private static readonly VERSION: number = 2;

    private static readonly DEFAULT_SEARCH_DATA: SearchData = {
      itemsPerPage: UiConstants.pageNumbers[0],
      pageNumber: 1,
      order: {
        field: TaskRecord.OrderField.CREATION_TIME,
        type: OrderType.DESC
      },
      query: '',
      states: []
    };

    constructor() {
    }

    public read(request: SearchDataGetRequest): SearchDataResult {
      let rawBase: StoredSearchRawDataBase;
      try {
        const key = this.generateKey();
        const json = MemStorage.getItem(key);
        if (!json) {
          return this.createDefaultResult();
        }
        rawBase = JSON.parse(json);
      } catch (error) {
        return this.createDefaultResult();
      }
      if (Storage.VERSION === rawBase.version) {
        const raw: StoredSearchRawData = <StoredSearchRawData>rawBase;
        return this.fromRaw(raw);
      }
      return this.createDefaultResult();
    }

    public reset(request: SearchDataGetRequest): SearchDataResult {
      return this.save({
        searchData: Storage.DEFAULT_SEARCH_DATA,
      });
    }

    public save(request: SearchDataSetRequest): SearchDataResult {
      const key = this.generateKey();
      const raw = this.toRaw(request.searchData);
      const json = JSON.stringify(raw);
      MemStorage.setItem(key, json);
      return this.read(request);
    }

    private generateKey(): string {
      return 'UserId(' + LoggedInUserStorage.getInstance().getUserId() + ')-Search-Bug-Report';
    }

    private createDefaultResult(): SearchDataResult {
      return {
        searchData: Storage.DEFAULT_SEARCH_DATA,
      };
    }

    private fromRaw(data: StoredSearchRawData): SearchDataResult {
      return {
        searchData: {
          itemsPerPage: data.itemsPerPage,
          pageNumber: data.pageNumber,
          order: data.order,
          query: data.query,
          states: data.states
        }
      };
    }

    private toRaw(data: SearchData): StoredSearchRawData {
      return {
        version: Storage.VERSION,
        itemsPerPage: data.itemsPerPage,
        pageNumber: data.pageNumber,
        order: data.order,
        query: data.query,
        states: data.states
      };
    }

  }

}
