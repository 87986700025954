/* eslint-disable */
import { OptionItem } from './core-utils';
import { UserDisabled } from '../lib/user.service';
import { DisabledItem } from './search-utils';

/* eslint-enable */

export class MasterdataRecordSearchModel {
  dqlText?: string;
  id: string;
  name: string;
  externalId: string;
  disabled?: DisabledItem;

  public isEmpty(): boolean {
    return this.id.length === 0
      && this.name.length === 0
      && this.externalId.length === 0
      && OptionItem.idOrUndefined(this.disabled) === undefined
      && this.dqlText === undefined
      ;
  }

  public clear() {
    this.dqlText = undefined;
    this.name = '';
    this.id = '';
    this.externalId = '';
    this.disabled = undefined;
  }
}

export class MasterdataRecordDisabledItem extends OptionItem<MasterdataRecordDisabled> {
}

export class MasterdataRecordDisabledItems {
  public static readonly values: UserDisabled[] = ['ALL', 'ACTIVE', 'INACTIVE'];
}

export type MasterdataRecordDisabled =
  'ALL' |
  'ACTIVE' |
  'INACTIVE';
