/* eslint-disable */
import { UserProfile } from '../auth.service';
import { FieldError } from '../util/errors';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RecipientsCreateModel } from '../../util/message/message-utils';
import { Map as ImmutableMap } from 'immutable';
import { HttpClient } from '@angular/common/http';
import { BaseHttpService, ResourceHelper } from '../util/http-services';
import { ResourceQueryResult } from '../util/services';
import { EmptyMessage, IdentityMessage } from '../util/messages';
/* eslint-enable */

export class CreateMessageRequest {
  title: string;
  content: string;
  recipients: RecipientsCreateModel;
  send_to_all: boolean;
}

export class QueryRequest {
  title?: string;
  content?: string;
  type?: string;
  send_date_from?: string;
  send_date_to?: string;
  sender?: string;
  q?: string;
  order?: string;
  page_number?: number;
  number_of_items?: number;
}

export class Recipients {
  user_profiles?: UserProfile[];
  user_group_ids?: number[];
  mobile_app_ids?: number[];
}

export interface Message {
  id: number;
  title: string;
  content: string;
  sent_date: string;
  creation_time: string;
  recipients: Recipients;
  relevantRecipientName: string;
}

export interface MessageErrorMap {
  recipient?: FieldError;
  title?: FieldError;
  message?: FieldError;
}

@Injectable()
export class MessagesService extends BaseHttpService {

  query(request: QueryRequest): Observable<ResourceQueryResult<Message>> {
    return this.http.get<ResourceQueryResult<Message>>(this.url, this.parseParams(request));
  }

  create(request: CreateMessageRequest): Observable<EmptyMessage> {
    return this.http.post(this.url, request);
  }

  get(request: IdentityMessage): Observable<Message> {
    return this.http.get<Message>(this.url + `${request.id}`);
  }

  constructor(private http: HttpClient, resourceHelper: ResourceHelper) {
    super(resourceHelper, '/message-parents/');
  }
}

export namespace MessageList {

  export enum OrderField {
    ID,
    TITLE,
    CREATION_TIME,
  }

  export class Keys {

    private static readonly ID = 'id';
    private static readonly TITLE = 'title';
    private static readonly CREATION_TIME = 'creation_time';

    private static readonly orderFieldKeyMap: ImmutableMap<MessageList.OrderField, string> = ImmutableMap.of(
      MessageList.OrderField.ID, Keys.ID,
      MessageList.OrderField.TITLE, Keys.TITLE,
      MessageList.OrderField.CREATION_TIME, Keys.CREATION_TIME,
    );

    public static toOrderFieldKey(field: MessageList.OrderField): string {
      return Keys.orderFieldKeyMap.get(field)!;
    }

  }

}

