/* eslint-disable */
import { AbstractControl, FormControl, NG_VALIDATORS, ValidationErrors, Validator, Validators, } from '@angular/forms';
import { Directive, forwardRef, } from '@angular/core';
import { PhoneNumbers } from '../lib/util/phone-number';
import { Dates, LocalDate, } from '../lib/util/dates';
import { SettingsService } from '../lib/settings.service';
import { DatePickerInputFactory, DatePickerTemplateFactory } from './datepicker-utils';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { AppNgbTimeStruct, NgbDatePickerParserFormatter } from './ngb-datepicker';
import { Arrays } from '../lib/util/arrays';
import { EmailAddresses } from '../lib/util/email-address';
import { Models } from './model-utils';
import { EuVatNumber, HuTaxNumber } from '../lib/util/tax-numbers';
import { equal } from 'assert';
/* eslint-enable */

// region Directives for template-driven forms

/* eslint-disable */
@Directive({
  selector: '[validateUrl][ngModel],[validateUrl][formControl]',
  providers: [
    {provide: NG_VALIDATORS, useExisting: forwardRef(() => UrlValidator), multi: true}
  ]
})
export class UrlValidator implements Validator {
  validate(c: FormControl) {
    return AppValidators.validateUrl(c);
  }
}

/* eslint-enable */

/* eslint-disable */
@Directive({
  selector: '[validateHuTaxNumber][ngModel],[validateHuTaxNumber][formControl]',
  providers: [
    {provide: NG_VALIDATORS, useExisting: forwardRef(() => HuTaxNumberValidator), multi: true}
  ]
})
export class HuTaxNumberValidator implements Validator {
  validate(c: FormControl) {
    return AppValidators.validateHuTaxNumber(c);
  }
}

/* eslint-enable */

/* eslint-disable */
@Directive({
  selector: '[validateEuVatNumber][ngModel],[validateEuVatNumber][formControl]',
  providers: [
    {provide: NG_VALIDATORS, useExisting: forwardRef(() => EuVatNumberValidator), multi: true}
  ]
})
export class EuVatNumberValidator implements Validator {
  validate(c: FormControl) {
    return AppValidators.validateEuVatNumber(c);
  }
}

/* eslint-enable */

/* eslint-disable */
@Directive({
  selector: '[validatePhoneNumber][ngModel],[validatePhoneNumber][formControl]',
  providers: [
    {provide: NG_VALIDATORS, useExisting: forwardRef(() => PhoneNumberValidator), multi: true}
  ]
})
export class PhoneNumberValidator implements Validator {
  validate(c: FormControl) {
    return AppValidators.validatePhoneNumber(c);
  }
}

/* eslint-enable */

/* eslint-disable */
@Directive({
  selector: '[validateLocalDate][ngModel],[validateLocalDate][formControl]',
  providers: [
    {provide: NG_VALIDATORS, useExisting: forwardRef(() => LocalDateValidator), multi: true}
  ]
})
export class LocalDateValidator implements Validator {
  validate(c: FormControl) {
    return AppValidators.validateLocalDate(c);
  }
}

/* eslint-enable */

/* eslint-disable */
@Directive({
  selector: '[validateOptionalEmail][ngModel],[validateOptionalEmail][formControl]',
  providers: [
    {provide: NG_VALIDATORS, useExisting: forwardRef(() => OptionalEmailValidator), multi: true}
  ]
})
export class OptionalEmailValidator implements Validator {
  validate(c: FormControl) {
    return AppValidators.validateOptionalEmail(c);
  }
}

/* eslint-enable */

/* eslint-disable */
@Directive({
  selector: '[validateRequiredAutoComplete][ngModel],[validateRequiredAutoComplete][formControl]',
  providers: [
    {provide: NG_VALIDATORS, useExisting: forwardRef(() => RequiredAutoCompleteValidator), multi: true}
  ]
})
export class RequiredAutoCompleteValidator implements Validator {
  validate(c: FormControl) {
    return AppValidators.validateRequiredAutoComplete(c);
  }
}

/* eslint-enable */

/* eslint-disable */
@Directive({
  selector: '[validateOptionalPositiveNumber][ngModel],[validateOptionalPositiveNumber][formControl]',
  providers: [
    {provide: NG_VALIDATORS, useExisting: forwardRef(() => OptionalPositiveNumberValidator), multi: true}
  ]
})
export class OptionalPositiveNumberValidator implements Validator {
  validate(c: FormControl) {
    return AppValidators.validateOptionalPositiveNumber(c);
  }
}

/* eslint-enable */

/* eslint-disable */
@Directive({
  selector: '[validateEnabledItems][ngModel],[validateEnabledItems][formControl]',
  providers: [
    {provide: NG_VALIDATORS, useExisting: forwardRef(() => EnabledItemsValidator), multi: true}
  ]
})
export class EnabledItemsValidator implements Validator {
  validate(c: FormControl) {
    return AppValidators.validateEnabledItems(c);
  }
}
/* eslint-enable */

/* eslint-disable */
@Directive({
  selector: '[validateOptionalLongitude][ngModel],[validateOptionalLongitude][formControl]',
  providers: [
    {provide: NG_VALIDATORS, useExisting: forwardRef(() => OptionalLongitudeValidator), multi: true}
  ]
})
export class OptionalLongitudeValidator implements Validator {
  validate(c: FormControl) {
    return AppValidators.validateOptionalLongitude(c);
  }
}
/* eslint-enable */

/* eslint-disable */
@Directive({
  selector: '[validateOptionalLatitude][ngModel],[validateOptionalLatitude][formControl]',
  providers: [
    {provide: NG_VALIDATORS, useExisting: forwardRef(() => OptionalLatitudeValidator), multi: true}
  ]
})
export class OptionalLatitudeValidator implements Validator {
  validate(c: FormControl) {
    return AppValidators.validateOptionalLatitude(c);
  }
}
/* eslint-enable */

/* eslint-disable */
@Directive({
  selector: '[validateMatchingPassword][ngModel],[validateMatchingPassword][formControl]',
  providers: [
    {provide: NG_VALIDATORS, useExisting: forwardRef(() => MatchingPasswordValidator), multi: true}
  ]
})
export class MatchingPasswordValidator implements Validator {
  validate(c: FormControl) {
    return AppValidators.validateMatchingPassword(c);
  }
}
/* eslint-enable */

// endregion

// region Validator functions for reactive forms
export namespace AppValidators {

  type ValidatorFn = (c: AbstractControl) => ValidationErrors | null;

  export const validatorChain = (...validators: ValidatorFn[]) => {
    return (c: FormControl) => {
      const errors: ValidationErrors[] = [];
      Arrays.iterateByIndex(validators, (validator: ValidatorFn) => {
        const error = validator(c);
        if (error !== null) {
          errors.push(error);
        }
      });
      if (errors.length === 0) {
        return null;
      }
      const result = Object.assign({}, ...errors);
      return result; // debug line
    };
  };

  export const noOpValidator = () => {
    return (c: FormControl) => {
      return null;
    };
  };

  export interface RequiredObjectArgs {
    object: () => any;
  }

  export const requiredObject = (args: RequiredObjectArgs) => {
    return (c: FormControl) => {
      if (args.object()) {
        return null;
      }
      return {
        requiredObject: {
          valid: false
        }
      };
    };
  };

  export interface NotNullArgs<T> {
    errorCode?: string;
    mapper: (value) => T | null;
  }

  export const notNull = <T>(args: NotNullArgs<T>) => {
    const errorCode = args.errorCode ? args.errorCode : 'notNull';
    return (c: FormControl) => {
      const value: T | null = args.mapper(c.value);
      if (value !== null) {
        return null;
      }
      const result = {};
      result[errorCode] = {
        valid: false
      };
      return result;
    };
  };

  export interface MinArraySizeArgs<T> {
    array: () => Array<T>;
    minSize: number;
    errorCode?: string;
  }

  export const minArraySize = <T>(args: MinArraySizeArgs<T>) => {
    const errorCode = args.errorCode ? args.errorCode : 'minArraySize';
    return (c: FormControl) => {
      const array = args.array();
      if (array && array.length >= args.minSize) {
        return null;
      }
      const result = {};
      result[errorCode] = {
        valid: false
      };
      return result;
    };
  };

  export interface MinArraySizeControlArgs {
    minSize: number;
    errorCode?: string;
  }

  export const minArraySizeControl = (args: MinArraySizeControlArgs) => {
    const errorCode = args.errorCode ? args.errorCode : 'minArraySizeControl';
    return (c: FormControl) => {
      const array: any[] = c.value;
      if (array && array.length >= args.minSize) {
        return null;
      }
      const result = {};
      result[errorCode] = {
        valid: false
      };
      return result;
    };
  };

  export interface MaxArraySizeArgs<T> {
    array: () => Array<T>;
    maxSize: number;
    errorCode?: string;
  }

  export const maxArraySize = <T>(args: MaxArraySizeArgs<T>) => {
    const errorCode = args.errorCode ? args.errorCode : 'maxArraySize';
    return (c: FormControl) => {
      const array = args.array();
      if (array && array.length <= args.maxSize) {
        return null;
      }
      const result = {};
      result[errorCode] = {
        valid: false
      };
      return result;
    };
  };

  export interface TempValidatorArgs {
    validator: ValidatorFn;
    disabled: () => boolean;
    label?: () => string | undefined;
  }

  export const tempValidator = (args: TempValidatorArgs) => {
    return (c: FormControl) => {
      if (args.disabled()) {
        return null;
      }
      return args.validator(c);
    };
  };

  // Made value() argument optional, see: https://github.com/angular/angular/pull/21514#issuecomment-367524768
  export interface RequiredArgs {
    errorCode?: string;
    disabled: () => boolean;
    value?: () => string;
  }

  export const required = (args: RequiredArgs) => {
    return (c: FormControl) => {
      if (args.disabled()) {
        return null;
      }
      const errorCode = args.errorCode ? args.errorCode : 'required';
      const val = args.value ? args.value() : c.value;
      if (val === null || val === undefined || val.length === 0) {
        const result = {};
        result[errorCode] = {
          valid: false
        };
        return result;
      }
      return null;
    };
  };

  export function noWhiteSpace(c: FormControl) {
    if (!c.value) {
      return null;
    }
    const value = c.value;
    if (!value || typeof value !== 'string') {
      return null;
    }
    const text = value.trim();
    if (text.length > 0) {
      return null;
    }
    return {
      noWhiteSpace: {
        valid: false
      }
    };
  }

  export const optMin = (minFn: () => number | undefined | null) => {
    return (c: FormControl) => {
      const min = minFn();
      if (min === undefined || min === null) {
        return null;
      }
      const result = Validators.min(min)(c);
      return result; // debug line
    };
  };

  export const optMax = (maxFn: () => number | undefined | null) => {
    return (c: FormControl) => {
      const max = maxFn();
      if (max === undefined || max === null) {
        return null;
      }
      const result = Validators.max(max)(c);
      return result; // debug line
    };
  };

  export const optMinLength = (minFn: () => number | undefined | null) => {
    return (c: FormControl) => {
      const min = minFn();
      if (min === undefined || min === null) {
        return null;
      }
      const result = Validators.minLength(min)(c);
      return result; // debug line
    };
  };

  export const optMaxLength = (maxFn: () => number | undefined | null) => {
    return (c: FormControl) => {
      const max = maxFn();
      if (max === undefined || max === null) {
        return null;
      }
      const result = Validators.maxLength(max)(c);
      return result; // debug line
    };
  };

  export function requiredListItem(value: () => any) {
    return (c: FormControl) => {
      const errorCode = 'required';
      if (!value() || !value().id) {
        const result = {};
        result[errorCode] = {
          valid: false
        };
        return result;
      }
      return null;
    };
  }

  export function validateUrl(c: FormControl) {
    if (!c.value) {
      return null;
    }
    const text = c.value;
    if (text.length === 0) {
      return null;
    }
    const pattern = new RegExp(
      '^' +
      // protocol identifier
      '(?:(?:https?)://)?' +
      // user:pass authentication
      '(?:\\S+(?::\\S*)?@)?' +
      '(?:' +
      // IP address exclusion
      // private & local networks
      '(?!(?:10|127)(?:\\.\\d{1,3}){3})' +
      '(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})' +
      '(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})' +
      // IP address dotted notation octets
      // excludes loopback network 0.0.0.0
      // excludes reserved space >= 224.0.0.0
      // excludes network & broacast addresses
      // (first & last IP address of each class)
      '(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])' +
      '(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}' +
      '(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))' +
      '|' +
      // host name
      '(?:(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)' +
      // domain name
      '(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*' +
      // TLD identifier
      '(?:\\.(?:[a-z\\u00a1-\\uffff]{2,}))' +
      // TLD may end with dot
      '\\.?' +
      ')' +
      // port number
      '(?::\\d{2,5})?' +
      // resource path
      '(?:[/?#]\\S*)?' +
      '$', 'i'
    );
    if (pattern.test(text)) {
      return null;
    }
    return {
      validateUrl: {
        valid: false
      }
    };
  }

  export function validateHuTaxNumber(c: FormControl) {
    if (!c.value) {
      return null;
    }
    const text = c.value;
    if (text.length === 0) {
      return null;
    }
    if (HuTaxNumber.getPattern().test(text)) {
      return null;
    }
    return {
      validateHuTaxNumber: {
        valid: false
      }
    };
  }

  export function validateEuVatNumber(c: FormControl) {
    if (!c.value) {
      return null;
    }
    const text = c.value;
    if (text.length === 0) {
      return null;
    }
    if (EuVatNumber.getPattern().test(text)) {
      return null;
    }
    return {
      validateEuVatNumber: {
        valid: false
      }
    };
  }

  export function validatePhoneNumber(c: FormControl) {
    if (!c.value) {
      return null;
    }
    const text = c.value;
    if (text.length === 0) {
      return null;
    }
    const pn = PhoneNumbers.parse(text);
    if (pn.isValid()) {
      return null;
    }
    return {
      validatePhoneNumber: {
        valid: false
      }
    };
  }

  export function validateLocalDate(c: FormControl) {
    const input = extractLocalDate(c);
    if (input === null || input.isValid()) {
      return null;
    }
    return {
      validateLocalDate: {
        valid: false
      }
    };
  }

  export function validateCountryItem(c: FormControl) {
    const countryItem = c.value;
    if (countryItem && countryItem.id !== null) {
      return null;
    }
    return {
      validateCountryItem: {
        valid: false
      }
    };
  }

  type NgbDateValue = NgbDateStruct | null; // Can be used in view model, but better to be non-null.
  type NgbTimeValue = AppNgbTimeStruct | null; // Can be used in view model, but better to be non-null.
  type NgbDateTimeValue = (NgbDateStruct & AppNgbTimeStruct) | null; // Do not use this in a view model!

  export interface LocalDateArgs {
    datePickerParserFormatter: NgbDatePickerParserFormatter,
    errorCode?: string,
    value: () => NgbDateValue,
  }

  export const validateLocalDateValue = (
    args: LocalDateArgs) => {
    return (c: FormControl) => {
      if (!args.value()) {
        return null;
      }
      const errorCode = args.errorCode ? args.errorCode : 'validateLocalDate';
      const value = args.datePickerParserFormatter.toLocalDate(args.value());
      if (!value.isValid()) {
        const result = {};
        result[errorCode] = {
          valid: false
        };
        return result;
      }
      return null;
    };
  };

  export interface LocalDateFromToArgs {
    datePickerParserFormatter: NgbDatePickerParserFormatter,
    errorCode?: string,
    allowEquality?: boolean,
    from: () => NgbDateValue,
    to: () => NgbDateValue,
  }

  export const validateLocalDateFromTo = (
    args: LocalDateFromToArgs) => {
    return (c: FormControl) => {
      const errorCode = args.errorCode ? args.errorCode : 'validateLocalDateFromTo';
      const minValue = args.datePickerParserFormatter.toLocalDate(args.from());
      const maxValue = args.datePickerParserFormatter.toLocalDate(args.to());
      if (minValue.isAfter(maxValue)) {
        const result = {};
        result[errorCode] = {
          valid: false
        };
        return result;
      }
      if (minValue.equals(maxValue) && (args.allowEquality !== undefined && !args.allowEquality)) {
        const result = {};
        result[errorCode] = {
          valid: false
        };
        return result;
      }
      return null;
    };
  };

  export interface LocalDateRangeArgs {
    datePickerParserFormatter: NgbDatePickerParserFormatter,
    errorCode?: string,
    min: () => NgbDateValue,
    max: () => NgbDateValue,
    value: () => NgbDateValue
  }

  export const validateLocalDateRange = (
    args: LocalDateRangeArgs) => {
    return (c: FormControl) => {
      const errorCode = args.errorCode ? args.errorCode : 'validateLocalDateRange';
      const minValue = args.datePickerParserFormatter.toLocalDate(args.min());
      const maxValue = args.datePickerParserFormatter.toLocalDate(args.max());
      const value = args.datePickerParserFormatter.toLocalDate(args.value());
      if (minValue.isAfter(maxValue)) {
        // Invalid range, skip validation.
        // If range validation required, use function validateLocalDateFromTo too in the validator chain.
        return null;
      }
      if (value.isAfter(maxValue)) {
        const result = {};
        result[errorCode] = {
          valid: false
        };
        return result;
      }
      if (value.isBefore(minValue)) {
        const result = {};
        result[errorCode] = {
          valid: false
        };
        return result;
      }
      return null;
    };
  };

  export interface MaxLocalDateArgs {
    datePickerParserFormatter: NgbDatePickerParserFormatter,
    errorCode?: string,
    maxValue: () => NgbDateValue,
  }

  export const validateMaxLocalDate = (
    args: MaxLocalDateArgs) => {
    return (c: FormControl) => {
      const errorCode = args.errorCode ? args.errorCode : 'validateMaxLocalDate';
      const maxValue = args.datePickerParserFormatter.toLocalDate(args.maxValue());
      const value = extractLocalDate(c);
      if (value !== null && value.isAfter(maxValue)) {
        const result = {};
        result[errorCode] = {
          valid: false
        };
        return result;
      }
      return null;
    };
  };

  export interface NgbDateTimeArgs {

    datePickerParserFormatter: NgbDatePickerParserFormatter,
    errorCode?: string,

    /**
     * There is no date-time without date.
     */
    dateValue: () => NgbDateValue,

    /**
     * There are special dates in some timezone when 1 day !== 24 hours.
     * Like 1890.09.30. in Europe/Budapest when they switched from LMT to GMT
     * and the offset is changed from 1:16:20 to 1:00.
     */
    timeValue: () => NgbTimeValue,

  }

  export const validateNgbDateTime = (
    args: NgbDateTimeArgs) => {
    return (c: FormControl) => {
      const errorCode = args.errorCode ? args.errorCode : 'validateNgbDateTime';
      const date = args.dateValue();
      const time = args.timeValue();
      if (date === null) {
        return null;
      }
      const formatter = args.datePickerParserFormatter;
      const dateTime = formatter.toOffsetDateTime(date, time);
      if (dateTime.isValid()) {
        return null;
      }
      const result = {};
      result[errorCode] = {
        valid: false
      };
      return result;
    };
  };

  export interface OffsetDateTimeFromToArgs {
    datePickerParserFormatter: NgbDatePickerParserFormatter,
    errorCode?: string,
    allowEquality?: boolean,
    fromDate: () => NgbDateValue,
    fromTime: () => NgbTimeValue,
    toDate: () => NgbDateValue,
    toTime: () => NgbTimeValue,
  }

  export const validateOffsetDateTimeFromTo = (
    args: OffsetDateTimeFromToArgs) => {
    return (c: FormControl) => {
      const errorCode = args.errorCode ? args.errorCode : 'validateOffsetDateTimeFromTo';
      const minValue = args.datePickerParserFormatter.toOffsetDateTime(args.fromDate(), args.fromTime());
      const maxValue = args.datePickerParserFormatter.toOffsetDateTime(args.toDate(), args.toTime());
      if (minValue.isAfter(maxValue)) {
        const result = {};
        result[errorCode] = {
          valid: false
        };
        return result;
      }
      if (minValue.equals(maxValue) && (args.allowEquality !== undefined && !args.allowEquality)) {
        const result = {};
        result[errorCode] = {
          valid: false
        };
        return result;
      }
      return null;
    };
  };

  export interface OffsetDateTimeRangeArgs {
    datePickerParserFormatter: NgbDatePickerParserFormatter,
    errorCode?: string,
    minDate: () => NgbDateValue,
    minTime: () => NgbTimeValue,
    maxDate: () => NgbDateValue,
    maxTime: () => NgbTimeValue,
    dateValue: () => NgbDateValue,
    timeValue: () => NgbTimeValue,
  }

  export const validateOffsetDateTimeRange = (
    args: OffsetDateTimeRangeArgs) => {
    return (c: FormControl) => {
      const errorCode = args.errorCode ? args.errorCode : 'validateOffsetDateTimeRange';
      const minValue = args.datePickerParserFormatter.toOffsetDateTime(args.minDate(), args.minTime());
      const maxValue = args.datePickerParserFormatter.toOffsetDateTime(args.maxDate(), args.maxTime());
      const value = args.datePickerParserFormatter.toOffsetDateTime(args.dateValue(), args.timeValue());
      if (minValue.isAfter(maxValue)) {
        // Invalid range, skip validation.
        // If range validation required, use function validateLocalDateFromTo too in the validator chain.
        return null;
      }
      if (value.isAfter(maxValue)) {
        const result = {};
        result[errorCode] = {
          valid: false
        };
        return result;
      }
      if (minValue.isAfter(value)) {
        const result = {};
        result[errorCode] = {
          valid: false
        };
        return result;
      }
      return null;
    };
  };

  export interface MaxNgbDateTimeArgs {
    datePickerParserFormatter: NgbDatePickerParserFormatter,
    errorCode?: string,
    maxValue: () => NgbDateTimeValue,
    value: () => NgbDateTimeValue,
  }

  export const validateMaxNgbDateTime = (args: MaxNgbDateTimeArgs) => {
    return (c: FormControl) => {
      const errorCode = args.errorCode ? args.errorCode : 'validateMaxNgbDateTime';
      const maxValue = args.datePickerParserFormatter.toOffsetDateTime(args.maxValue(), args.maxValue());
      const value = args.datePickerParserFormatter.toOffsetDateTime(args.value(), args.value());
      if (value.isAfter(maxValue)) {
        const result = {};
        result[errorCode] = {
          valid: false
        };
        return result;
      }
      return null;
    };
  };

  export interface MinNgbDateTimeArgs {
    datePickerParserFormatter: NgbDatePickerParserFormatter,
    errorCode?: string,
    minValue: () => NgbDateTimeValue,
    value: () => NgbDateTimeValue,
  }

  export const validateMinNgbDateTime = (args: MinNgbDateTimeArgs) => {
    return (c: FormControl) => {
      const errorCode = args.errorCode ? args.errorCode : 'validateMinNgbDateTime';
      const minValue = args.datePickerParserFormatter.toOffsetDateTime(args.minValue(), args.minValue());
      const value = args.datePickerParserFormatter.toOffsetDateTime(args.value(), args.value());
      if (value.isBefore(minValue)) {
        const result = {};
        result[errorCode] = {
          valid: false
        };
        return result;
      }
      return null;
    };
  };

  export interface AppNgbTimeIntervalArgs {
    errorCode?: string,
    startValue: () => AppNgbTimeStruct,
    endValue: () => AppNgbTimeStruct,
  }

  export const validateAppNgbTimeInterval = (args: AppNgbTimeIntervalArgs) => {
    return (c: FormControl) => {
      const errorCode = args.errorCode ? args.errorCode : 'validateAppNgbTimeInterval';
      const startMinutes = Models.ngbTimeToMinutes(args.startValue());
      const endMinutes = Models.ngbTimeToMinutes(args.endValue());
      if (startMinutes > endMinutes) {
        const result = {};
        result[errorCode] = {
          valid: false
        };
        return result;
      }
      return null;
    };
  };

  export interface MaxNumberArgs {
    errorCode?: string,
    maxValue: () => number | undefined | null,
    value?: () => number | undefined,
    disabled?: () => boolean
  }

  export const validateMaxNumber = (
    args: MaxNumberArgs) => {
    return (c: FormControl) => {
      const errorCode = args.errorCode ? args.errorCode : 'validateMaxNumber';
      const maxValue = args.maxValue();
      const value = args.value ? args.value() : c.value;
      if (value && typeof value === 'number'
        && maxValue !== undefined && maxValue !== null && value > maxValue) {
        const result = {};
        result[errorCode] = {
          max: maxValue,
          actual: value
        };
        return result;
      }
      return null;
    };
  };

  export interface NumberFromToArgs {
    errorCode?: string,
    allowEquality?: boolean,
    from: () => number | undefined,
    to: () => number | undefined,
  }

  export const validateNumberFromTo = (args: NumberFromToArgs) => {
    return (c: FormControl) => {
      const errorCode = args.errorCode ? args.errorCode : 'validateNumberFromTo';
      const minValue = args.from();
      const maxValue = args.to();
      if (minValue === undefined || maxValue === undefined) {
        return null;
      }
      if (minValue > maxValue) {
        const result = {};
        result[errorCode] = {
          valid: false
        };
        return result;
      }
      if (minValue === maxValue && (args.allowEquality !== undefined && !args.allowEquality)) {
        const result = {};
        result[errorCode] = {
          valid: false
        };
        return result;
      }
      return null;
    };
  };

  export interface NumberEqualsArgs {
    errorCode?: string,
    equalsTo: () => number | undefined,
  }

  export const validateNumberEquals = (args: NumberEqualsArgs) => {
    return (c: FormControl) => {
      const errorCode = args.errorCode ? args.errorCode : 'validateNumberEquals';
      const equalsTo = args.equalsTo();
      const value = Models.parseNumber(c.value);
      if (equalsTo === undefined) {
        return null;
      }
      if (value !== equalsTo) {
        const result = {};
        result[errorCode] = {
          valid: false
        };
        return result;
      }
      return null;
    };
  };

  export function validateOptionalEmail(c: FormControl) {
    const text = c.value;
    if (!text) {
      return null;
    }
    if (typeof text !== 'string') {
      return {
        email: true // not a string so invalid
      };
    }
    if (text.length === 0) {
      return null;
    }
    const mail = EmailAddresses.parse(text);
    if (mail.isValid()) {
      return null;
    }
    return {
      email: true // invalid result of ng2-validation/CustomValidators.email(c);
    };
  }

  export const validateOptionalEmailValue = (value: () => string) => {
    return (c: FormControl) => {
      const text = value();
      if (!text) {
        return null;
      }
      if (text.length === 0) {
        return null;
      }
      const mail = EmailAddresses.parse(text);
      if (mail.isValid()) {
        return null;
      }
      return {
        email: true // invalid result of ng2-validation/CustomValidators.email(c);
      };
    };
  };

  export function validateEnabledItems(c: FormControl) {
    const items = c.value;
    if (!items || items.length === 0) {
      return null;
    }
    for (let i = 0; i < items.length; i++) {
      if (items[i].disabled) {
        return {
          hasDisabledItem: true
        };
      }
    }
    return null;
  }

  export function validateRequiredAutoComplete(c: FormControl) {
    if (c.value) {
      return null;
    }
    return {
      validateRequiredAutoComplete: {
        valid: false
      }
    };
  }

  export interface UniqueTextArgs {
    errorCode?: string,
    noneOf: () => string[]
  }

  export const validateUniqueText = (
    args: UniqueTextArgs) => {
    return (c: FormControl) => {
      const errorCode = args.errorCode ? args.errorCode : 'validateUniqueText';
      const match = args.noneOf().filter((rejected) => {
        return rejected === c.value;
      });
      if (match.length === 0) {
        return null;
      }
      const result = {};
      result[errorCode] = {
        valid: false
      };
      return result;
    };
  };

  export interface UniqueValueArgs<K, V> {
    errorCode?: string,
    keys: () => K[],
    keyProvider: (value: V) => K | null,
  }

  export const validateUniqueKey = <K, V>(
    args: UniqueValueArgs<K, V>) => {
    return (c: FormControl) => {
      const errorCode = args.errorCode ? args.errorCode : 'validateUniqueKey';
      const value: V = c.value;
      const key: K | null = args.keyProvider(value);
      const array: K[] = args.keys();
      let count = 0;
      array.forEach((k: K) => {
        if (k === key) {
          count++;
        }
      });
      if (count <= 1) {
        return null;
      }
      const result = {};
      result[errorCode] = {
        valid: false
      };
      return result;
    };
  };

  // region Internal utils

  function extractLocalDate(c: FormControl): LocalDate | null {
    const value = c.value;
    if (!c.value) {
      return null;
    }
    const settings = SettingsService.getInstance();
    if (settings) {
      if (typeof value === 'string') {
        const text = value;
        if (text.length === 0) {
          return null;
        }
        const input = new DatePickerInputFactory(settings).createFromDate(text);
        return Dates.parseLocalDateInput(input);
      }
      else {
        const dateModel: NgbDateStruct = value;
        return new NgbDatePickerParserFormatter(new DatePickerTemplateFactory(settings))
          .toLocalDate(dateModel);
      }
    }
    return null;
  }

  export function validateMatchingPassword(c: FormControl) {
    const password = c.parent!.controls['password'].value;
    const confirmPassword = c.value;
    if (password !== confirmPassword) {
      return {
        no_match: true
      };
    }
    return null;
  }

  export function validateMatchingPasswordPromise(c: FormControl) {
    return new Promise(resolve => {
      const result = validateMatchingPassword(c);
      return resolve(result ? result : {});
    });
  }

  export function validateOptionalPositiveNumber(c: FormControl) {
    const value = c.value;
    if (value === null || value === undefined || value.length === 0) {
      return null;
    }
    const number = parseFloat(value);
    if (number <= 0) {
      return {
        numberNotPositive: true
      };
    }
    return null;
  }

  export function validateOptionalPositiveOrZeroNumber(c: FormControl) {
    const value = c.value;
    if (value === null || value === undefined || value.length === 0) {
      return null;
    }
    const number = parseFloat(value);
    if (number < 0) {
      return {
        numberNotPositive: true
      };
    }
    return null;
  }

  export function validateOptionalLatitude(c: FormControl) {
    const value = c.value;
    if (!value) {
      return null;
    }
    if (value.length === 0) {
      return null;
    }
    const number = parseFloat(value);
    if (number < -90 || number > 90) {
      return {
        invalidLatitude: true
      };
    }
    return null;
  }

  export function validateOptionalLongitude(c: FormControl) {
    const value = c.value;
    if (!value) {
      return null;
    }
    if (value.length === 0) {
      return null;
    }
    const number = parseFloat(value);
    if (number < -180 || number > 180) {
      return {
        invalidLongitude: true
      };
    }
    return null;
  }
}

// endregion
