/* eslint-disable */
import { AfterViewInit, Component, ViewChild, } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Form } from '../../../../../../lib/form/form.service';
import { FormEditContext, FormGroupModel, } from '../../../../../../util/form/form-utils';
import {
  FieldValidationError,
  FormRef,
  LocalFieldValidationErrors,
  LocalFieldValidationErrorsFactory,
} from '../../../../../../lib/util/services';
import { NgModel, } from '@angular/forms';
import { List } from 'immutable';
import { UiConstants } from '../../../../../../util/core-utils';
import { Strings } from '../../../../../../lib/util/strings';
import { Models } from '../../../../../../util/model-utils';

/* eslint-enable */

@Component({
  selector: 'app-form-edit-create-update-group-dialog',
  templateUrl: 'form-edit-create-update-group-dialog.component.html',
  styleUrls: ['form-edit-create-update-group-dialog.component.scss'],
})
export class FormEditCreateUpdateGroupDialogComponent implements AfterViewInit, FormRef {

  Form = Form;
  UiConstants = UiConstants;

  @ViewChild('newGroupDialog', {static: true})
  newGroupDialog: ModalDirective;

  @ViewChild('title', {static: true})
  titleInput: NgModel;

  @ViewChild('apiExportName', {static: true})
  apiExportNameInput: NgModel;

  model: FormEditNewGroupModel = new FormEditNewGroupModel();
  dialogTitleDictionaryKey: string = '';
  isUpdate: boolean = false;

  private fieldErrors: FieldValidationError<Form.GroupValidatedField> =
    FieldValidationError.empty<Form.GroupValidatedField>();

  private validatedInputs: LocalFieldValidationErrors<NgModel> =
    LocalFieldValidationErrorsFactory.empty();

  private context: FormEditContext;
  private group?: FormGroupModel;
  private submitted: boolean = false;

  getForm() {
    return {
      submitted: this.submitted
    };
  }

  ngAfterViewInit(): void {
    this.loadLocalFieldValidationErrors();
  }

  registerContext(context: FormEditContext): void {
    this.context = context;
  }

  saveGroup() {
    this.submitted = true;
    if (this.hasLocalFieldError()) {
      return;
    }
    if (this.group) {
      this.updateGroup(this.group);
    }
    else {
      this.createGroup();
    }
  }

  showDialog(group?: FormGroupModel) {
    this.newGroupDialog.show();
    if (group) {
      this.dialogTitleDictionaryKey = 'TASK_FORM_GROUP_UPDATE_TITLE';
      this.isUpdate = true;
      this.loadGroup(group);
    }
    else {
      this.dialogTitleDictionaryKey = 'TASK_FORM_GROUP_CREATE_TITLE';
      this.isUpdate = false;
    }
  }

  cancelDialog() {
    this.group = undefined;
    this.submitted = false;
    this.newGroupDialog.hide();
    this.model = new FormEditNewGroupModel();
  }

  hasLocalFieldError(field?: NgModel): boolean {
    return this.validatedInputs.hasLocalError(field);
  }

  hasFieldError(field?: Form.GroupValidatedField): boolean {
    return this.fieldErrors.hasError(field);
  }

  removeFieldError(field: Form.GroupValidatedField) {
    this.fieldErrors = this.fieldErrors.removeError(field);
  }

  getFieldErrorText(field: Form.GroupValidatedField): string {
    return this.fieldErrors.getErrorText(field);
  }

  private loadLocalFieldValidationErrors() {
    const validatedInputs = List.of(this.titleInput, this.apiExportNameInput);
    this.validatedInputs = LocalFieldValidationErrorsFactory.ofFields(this, validatedInputs);
  }

  onTitleBlur() {
    const normalizedText = Models.normalizeText(this.model.title);
    if (this.model.apiExportName === '') {
      this.model.apiExportName = normalizedText;
    }
  }

  private loadGroup(group: FormGroupModel) {
    this.group = group;
    this.model.title = group.title;
    this.model.shortTitle = group.shortTitle;
    this.model.apiExportName = group.apiExportName;
  }

  private createGroup() {
    this.context.formService.createGroup({
      parentId: this.context.parentId,
      title: this.model.title,
      shortTitle: Strings.undefinedOrNonEmpty(this.model.shortTitle),
      apiExportName: Strings.undefinedOrNonEmpty(this.model.apiExportName)
    }).subscribe(
      (response: Form.CreateGroupResponse) => {
        this.cancelDialog();
        this.context.formView.reloadForm();
      },
      (error: any) => {
        if (error instanceof FieldValidationError) {
          this.fieldErrors = error.withFormRef(this);
        }
      },
    );
  }

  private updateGroup(group: FormGroupModel) {
    this.context.formService.updateGroup({
      parentId: this.context.parentId,
      groupId: group.groupId,
      title: this.model.title,
      shortTitle: Strings.undefinedOrNonEmpty(this.model.shortTitle),
      apiExportName: this.model.apiExportName
    }).subscribe(
      (response: Form.CreateGroupResponse) => {
        this.cancelDialog();
        this.context.formView.reloadForm();
      },
      (error: any) => {
        if (error instanceof FieldValidationError) {
          this.fieldErrors = error.withFormRef(this);
        }
      },
    );
  }

}

export class FormEditNewGroupModel {
  title: string = '';
  shortTitle?: string = '';
  apiExportName: string = '';
}
