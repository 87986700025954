import { Component, OnInit } from '@angular/core';
import { UIRouter } from '@uirouter/angular';
import { StateName } from '../../app.state-names';
import { RightResolver, RightService } from '../../lib/right.service';
import { RightModel } from '../../app.rights';
import { ConfigurationResource, ConfigurationService } from '../../lib/core-ext/configuration.service';

@Component({
  selector: 'app-startup',
  templateUrl: 'startup.component.html'
})
export class StartupComponent implements OnInit {

  public nothingToShow: boolean = false;

  constructor(
    private router: UIRouter,
    private rightService: RightService,
    private configService: ConfigurationService,
  ) {
  }

  ngOnInit() {
    this.configService.configuration.subscribe((configuration: ConfigurationResource.Configuration) => {
      this.rightService.getRightResolver().subscribe((resolver: RightResolver) => {
        this.goToStartPage(RightModel.of(resolver), configuration);
      });
    });
  }

  private goToStartPage(rightModel: RightModel, configuration: ConfigurationResource.Configuration) {
    if (rightModel.webMenuDashboard.hasRight()) {
      this.router.stateService.go(StateName.HOME);
    }
    else if (rightModel.webMenuAdmin.hasRight()) {
      this.router.stateService.go(StateName.ADMIN_DASHBOARD);
    }
    else if (ConfigurationService.isEnabledByServer('TASKS', configuration)
      && rightModel.webMenuTaskRecords.hasRight()
      && rightModel.taskRead.hasRight()
      && rightModel.taskRecordRead.hasRight()) {
      this.router.stateService.go(StateName.TASK_DASHBOARD);
    }
    else if (ConfigurationService.isEnabledByServer('TASKS', configuration)
      && rightModel.webMenuTaskRecordBoard.hasRight()
      && rightModel.taskRead.hasRight()
      && rightModel.taskRecordRead.hasRight()) {
      this.router.stateService.go(StateName.TASK_RECORD_BOARD);
    }
    else if (ConfigurationService.isEnabledByServer('CUSTOMERS', configuration)
      && rightModel.webMenuCustomerRecords.hasRight()
      && rightModel.customerRead.hasRight()
      && rightModel.customerRecordRead.hasRight()) {
      this.router.stateService.go(StateName.CUSTOMER_DASHBOARD);
    }
    else if (ConfigurationService.isEnabledByServer('MASTER_DATA', configuration)
      && rightModel.webMenuMasterdataRecords.hasRight()
      && rightModel.masterDataRecordRead.hasRight()
      && rightModel.masterDataRead.hasRight()) {
      this.router.stateService.go(StateName.MASTERDATA_DASHBOARD);
    }
    else if (ConfigurationService.isEnabledByServer('SURVEY', configuration)
      && rightModel.webMenuSurveyRecords.hasRight()
      && rightModel.surveyRead.hasRight()
      && rightModel.surveyRecordRead.hasRight()) {
      this.router.stateService.go(StateName.SURVEY_DASHBOARD);
    }
    else if (ConfigurationService.isEnabledByServer('INVOICE', configuration)
      && rightModel.webMenuInvoices.hasRight()
      && rightModel.invoiceRead.hasRight()) {
      this.router.stateService.go(StateName.INVOICE_LIST);
    }
    else if (ConfigurationService.isEnabledByServer('INVOICE', configuration)
      && rightModel.webMenuInvoiceSpendings.hasRight()
      && rightModel.invoiceSpendingRead.hasRight()) {
      this.router.stateService.go(StateName.INVOICE_SPENDING_LIST);
    }
    else if (rightModel.calendarEventRead.hasRight()
      && rightModel.webMenuCalendar.hasRight()
      && ConfigurationService.isEnabledByServer('CALENDAR', configuration)) {
      this.router.stateService.go(StateName.CALENDAR);
    }
    else if (rightModel.globalCalendarEventRead.hasRight()
      && rightModel.webMenuGlobalCalendar.hasRight()
      && ConfigurationService.isEnabledByServer('CALENDAR', configuration)) {
      this.router.stateService.go(StateName.GLOBAL_CALENDAR);
    }
    else if (ConfigurationService.isEnabledByServer('PROJECTS', configuration)
      && rightModel.webMenuProjects.hasRight()
      && rightModel.projectRead.hasRight()) {
      this.router.stateService.go(StateName.PROJECT_LIST);
    }
    else if (ConfigurationService.isEnabledByServer('ORDER', configuration)
      && rightModel.webMenuOrders.hasRight()
      && rightModel.orderRead.hasRight()) {
      this.router.stateService.go(StateName.ORDER_LIST);
    }
    else if (ConfigurationService.isEnabledByServer('LEGACY_WORKFLOW', configuration)
      && rightModel.webMenuLegacyProcesses.hasRight()
      && rightModel.legacyProcessRead.hasRight()) {
      this.router.stateService.go(StateName.LEGACY_PROCESS_LIST);
    }
    else if (ConfigurationService.isEnabledByServer('WORKFLOW', configuration)
      && rightModel.webMenuProcesses.hasRight()
      && rightModel.processRead.hasRight()) {
      this.router.stateService.go(StateName.PROCESS_LIST);
    }
    else if (ConfigurationService.isEnabledByServer('STOCK', configuration)
      && rightModel.webMenuStocks.hasRight()
      && rightModel.stockRead.hasRight()) {
      this.router.stateService.go(StateName.STOCK_LIST);
    }
    else if (ConfigurationService.isEnabledByServer('STOCK', configuration)
      && ConfigurationService.isEnabledByServer('STOCK_TAKING', configuration)
      && rightModel.webMenuStockTakings.hasRight()
      && rightModel.stockTakingRead.hasRight()) {
      this.router.stateService.go(StateName.STOCK_TAKINGS_LIST);
    }
    else if (ConfigurationService.isEnabledByServer('COMPANY', configuration)
      && ConfigurationService.isEnabledByServer('SHIPPING_DEMAND', configuration)
      && rightModel.webMenuShippingDemands.hasRight()
      && (rightModel.shippingDemandRead.hasRight() || rightModel.shippingDemandReadSimple.hasRight())) {
      this.router.stateService.go(StateName.SHIPPING_DEMAND_LIST);
    }
    else if (ConfigurationService.isEnabledByServer('COMPANY', configuration)
      && (ConfigurationService.isEnabledByServer('TRANSPORT', configuration)
        || ConfigurationService.isEnabledByServer('EXTERIOR_TRANSPORT', configuration))
      && rightModel.webMenuShipments.hasRight()
      && rightModel.shipmentGroupRead.hasRight()) {
      this.router.stateService.go(StateName.SHIPMENT_LIST);
    }
    else if (ConfigurationService.isEnabledByServer('COMPANY', configuration)
      && ConfigurationService.isEnabledByServer('TRANSPORT', configuration)
      && rightModel.webMenuTransports.hasRight()
      && rightModel.transportRead.hasRight()) {
      this.router.stateService.go(StateName.TRANSPORT_LIST);
    }
    else if (ConfigurationService.isEnabledByServer('COMPANY', configuration)
      && ConfigurationService.isEnabledByServer('EXTERIOR_TRANSPORT', configuration)
      && rightModel.webMenuExteriorTransports.hasRight()
      && rightModel.exteriorTransportRead.hasRight()) {
      this.router.stateService.go(StateName.EXTERIOR_TRANSPORT_LIST);
    }
    else if (ConfigurationService.isEnabledByServer('CRM', configuration)
      && rightModel.webMenuNotes.hasRight()
      && rightModel.noteRead.hasRight()) {
      this.router.stateService.go(StateName.NOTE_LIST);
    }
    else if (ConfigurationService.isEnabledByServer('DOCUMENTS', configuration)
      && rightModel.webMenuDocuments.hasRight()
      && rightModel.documentRead.hasRight()) {
      this.router.stateService.go(StateName.DOCUMENT_DASHBOARD);
    }
    else if (ConfigurationService.isEnabledByServer('DOCUMENTS', configuration)
      && rightModel.webMenuDocumentLibrary.hasRight()
      && rightModel.documentRead.hasRight()
      && rightModel.documentGroupRead.hasRight()) {
      this.router.stateService.go(StateName.DOCUMENT_LIBRARY);
    }
    else if (ConfigurationService.isEnabledByServer('COLONNADE_INSURANCE_1', configuration)
      && (rightModel.clientDocumentRead.hasRight() || rightModel.colonnadeB2bRead.hasRight())) {
      this.router.stateService.go(StateName.COLONNADE_INSURANCE_LIST);
    }
    else {
      this.nothingToShow = true;
    }
  }

}
