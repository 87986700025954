/* eslint-disable */
import { AfterViewInit, Component, Injector, OnInit, ViewChild, ElementRef } from '@angular/core';
import {
  ErrorResource,
  FieldError, FieldErrors,
  ObservableErrorResourceParser,
  ObservableErrorResponse
} from '../lib/util/errors';
import { Language, SettingsService } from '../lib/settings.service';
import { ShopRenterStorage } from '../lib/shoprenter/shoprenter.service';
import { UIRouter } from '@uirouter/angular';
import { TranslateService } from '@ngx-translate/core';
import { AppConfigurationService } from '../lib/app-configuration.service';
import { ConfigurationResource, ConfigurationService } from '../lib/core-ext/configuration.service';
import { RegistrationService } from '../lib/registration/registration.service';
import { Strings } from '../lib/util/strings';
import { QueryResult, Services } from '../lib/util/services';
import { LoginRequiredReason, LoginRequiredReasonStorage } from '../lib/util/storages';
import { StateName } from '../app.state-names';
import { UiConstants } from '../util/core-utils';
import { AddressModel } from '../lib/address';
import { Country, CountryService } from '../lib/country.service';
import { List } from 'immutable';
import { toasterConfig } from '../fork/angular2-toaster/src/toaster-config';
import { ResourceHelper } from '../lib/util/http-services';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import RegistrationType = ConfigurationResource.RegistrationType;
import { NgForm, NgModel } from '@angular/forms';
import { RecaptchaV3Utils } from '../util/recaptcha-utils';
import { Dates } from '../lib/util/dates';
import { LoginRegisterIntroductionComponent } from '../shared/login-register-introduction/login-register-introduction.component';
import { RuntimeConfiguration } from '../lib/runtime-configuration';

/* eslint-enable */

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit, AfterViewInit {
  toasterConfig = toasterConfig;
  RegistrationType = RegistrationType;

  @ViewChild(LoginRegisterIntroductionComponent)
  introductionComponent: LoginRegisterIntroductionComponent;

  @ViewChild('registerContainer')
  registerContainer: ElementRef;

  @ViewChild('f')
  fForm: NgForm;

  @ViewChild('password')
  passwordInput: NgModel;

  @ViewChild('confirmPassword')
  confirmPassword: NgModel;

  model: RegistrationModel = new RegistrationModel();
  fieldErrors: RegistrationFieldErrorMap;
  registrationInProgress = false;
  countryItems: List<AddressModel.CountryItem>;
  passwordVisible: boolean = false;

  get languages(): Language[] {
    return this.settingsService.getAvailableLanguages().toArray();
  }

  private shopRenterStorage = ShopRenterStorage.getInstance();

  constructor(
    private router: UIRouter,
    private injector: Injector,
    private translateService: TranslateService,
    private configurationService: ConfigurationService,
    private settingsService: SettingsService,
    private registrationService: RegistrationService,
    private recaptchaV3Service: ReCaptchaV3Service,
    private countryService: CountryService,
    private resourceHelper: ResourceHelper) {
    this.fieldErrors = {};
  }

  ngOnInit() {
    this.loadCountries(() => {
      this.model.companyAddress.loadCountryItems(this.countryItems, this.configurationService.getDefaultSelectedCountryCode());
    });
  }

  ngAfterViewInit(): void {
    this.introductionComponent.bottom.nativeElement.classList.remove('bottom-out');
    this.registerContainer.nativeElement.classList.remove('register-out');
  }

  isCurrentLanguage(language: Language): boolean {
    return this.settingsService.isCurrentLanguage(language);
  }

  getLanguageCode(): string {
    return this.settingsService.getLanguageCode();
  }

  changeLanguage(language: Language) {
    this.settingsService.setLocaleCode(language.localeCode);
    this.translateService.use(language.localeCode).subscribe(
      () => {
        // This does not work:
        // this.router.stateService.reload(StateName.LOGIN);
        // Reload the Angular application instead:
        window.location.reload();
        // Reason:
        // Changing LOCALE_ID (in @angular/core, provided by LOCALE_CODE_PROVIDER) in runtime
        // does no change formatting of pipes.
      });
  }

  register() {
    if (this.registrationType !== RegistrationType.REQUEST_USER) {
      this.passwordInput.control.updateValueAndValidity();
      this.confirmPassword.control.updateValueAndValidity();
    }
    if (!this.fForm.valid) {
      return;
    }
    if (!this.model.companyAddress.valid) {
      return;
    }
    this.registrationInProgress = true;
    RecaptchaV3Utils.preAuthenticate(
      this.recaptchaV3Service,
      'registration',
      this.doRegistration()).subscribe(
      (result) => {
        this.shopRenterStorage.clear();
        const callback: () => void = () => {
          this.registrationInProgress = false;
          LoginRequiredReasonStorage.getInstance().setReason(LoginRequiredReason.REGISTRATION_SUCCESSFUL);
          this.router.stateService.go(StateName.LOGIN);
        };
        const conversionCode = RuntimeConfiguration.getInstance().getRegistrationConversionCode('');
        if (conversionCode.length > 0) {
          const gtag = window['gtag'];
          gtag('event', 'conversion', {
            'send_to': 'AW-879766362/' + conversionCode,
            'event_callback': callback
          });
        }
        else {
          callback();
        }
      },
      (error: ObservableErrorResponse) => {
        this.registrationInProgress = false;
        const body: ErrorResource | null = ObservableErrorResourceParser.parseError(error);
        if (body) {
          this.fieldErrors = ObservableErrorResourceParser.extractFieldErrors(body);
        }
      }
    );
  }

  private doRegistration() {
    switch (this.registrationType) {
      case ConfigurationResource.RegistrationType.REQUEST_USER:
        return this.registrationService.requestRegistration(this.getRegistrationRequest());
      case ConfigurationResource.RegistrationType.CREATE_USER_SHOPRENTER:
        return this.registrationService.registerUserShoprenter(this.getRegistrationRequest());
      case ConfigurationResource.RegistrationType.CREATE_USER_DEMO:
        return this.registrationService.registerUserDemo(this.getRegistrationRequest());
      default:
        return this.registrationService.requestRegistration(this.getRegistrationRequest());
    }
  }

  private getRegistrationRequest() {
    const request = {
      personName: this.model.personName,
        emailAddress: Services.toEmailAddress(this.model.emailAddress),
      phoneNumber: Strings.undefinedOrNonEmpty(this.model.phoneNumber) ? Services.toPhoneNumber(this.model.phoneNumber) : undefined,
      company: {
        name: this.model.companyName,
        address: this.model.companyAddress.toData()!
      },
      shoprenterWebshop: this.shopRenterStorage.getShopName() ? this.shopRenterStorage.getShopName()! : 'placeholder',
      user: {
        userName: this.model.userName,
        password: this.model.password
      }
    };
    switch (this.registrationType) {
      case ConfigurationResource.RegistrationType.CREATE_USER_SHOPRENTER:
        request['shoprenterWebshop'] = this.shopRenterStorage.getShopName()!;
      // eslint-disable-next-line no-fallthrough
      case ConfigurationResource.RegistrationType.CREATE_USER_DEMO:
        request['user'] = {
          userName: this.model.userName,
          password: this.model.password
        };
    }
    return request;
  }

  private loadCountries(completion: () => void) {
    RecaptchaV3Utils.preAuthenticate(
      this.recaptchaV3Service,
      'countryQuery',
      this.countryService.query({}))
      .subscribe((result: QueryResult<Country.Country>) => {
        this.countryItems = AddressModel.CountryItem.fromCountryList(result.items);
        completion();
      });
  }

  get loginBackgroundSrc(): string | undefined {
    return this.resourceHelper.getLoginBackgroundSrc()
  }

  get registrationType(): RegistrationType {
    return this.configurationService.getConfiguration().feature_flags.registration_enabled!;
  }

  getTextMaximumLength(): number {
    return UiConstants.maximumVarcharLength;
  }

  removeFieldError(fieldError?: FieldError) {
    FieldErrors.remove(this.fieldErrors, fieldError);
  }

  togglePasswordVisibility() {
    this.passwordVisible = !this.passwordVisible;
  }

  back() {
    window.history.back();
  }

  get isSantaOn(): boolean {
    return this.configurationService.getConfiguration().santa === undefined ? false : this.configurationService.getConfiguration().santa!;
  }

  get isAlmostChristmas() {
    return Dates.isAlmostChristmas();
  }

  navigateToLogin() {
    this.introductionComponent.bottom.nativeElement.classList.add('bottom-out');
    this.registerContainer.nativeElement.classList.add('register-out');
    setTimeout(() => this.router.stateService.go(StateName.LOGIN, {fromRegister: true}), 300);
  }

}

class RegistrationModel {
  personName: string = '';
  userName: string = '';
  password: string = '';
  confirmPassword: string = '';
  emailAddress: string = '';
  phoneNumber: string = '';
  companyName: string = '';
  companyAddress: AddressModel.PostalAddressModel;

  constructor() {
    this.companyAddress = new AddressModel.PostalAddressModel();
    this.companyAddress.type = AddressModel.PostalAddressModelType.COMPLEX;
  }
}

export interface RegistrationFieldErrorMap {
  user_name?: FieldError;
  password?: FieldError;
  company?: FieldError;
}
