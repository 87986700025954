/* eslint-disable */
import { AfterViewInit, Component, Inject, OnInit, Optional, ViewChild } from '@angular/core';
import { UIRouter } from '@uirouter/angular';
import { Arrays } from '../../../../lib/util/arrays';
import { StateName } from '../../../../app.state-names';
import { TranslateService } from '@ngx-translate/core';
import {
  ListItemFieldErrorMap,
  ListItemParam,
  listItemParamTypes,
  ListItemResourceService,
  RawListTypeItem
} from '../../../../lib/list-item/list-item-resource.service';
import { FieldError, FieldErrors, ObservableErrorResourceParser } from '../../../../lib/util/errors';
import { ListItemCreateModel, ListItemParamTypeItem, ListItemTypeItem } from '../../../../util/list-item-utils';
import { RootCoreService } from '../../../../lib/root-core.service';
import { BreadcrumbParent } from '../../../../shared/breadcrumb/breadcrumb/breadcrumb.component';
import { MultiselectOptionItem, OptionItem, SelectUtils, UiConstants } from '../../../../util/core-utils';
import { NgForm } from '@angular/forms';
import { ResourceQueryResult } from '../../../../lib/util/services';
import { Angular2Multiselects } from '../../../../util/multiselect';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { Strings } from '../../../../lib/util/strings';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

/* eslint-enable */

@Component({
  selector: 'app-list-item-create',
  templateUrl: 'list-item-create.component.html',
  styleUrls: ['list-item-create.component.css']
})
export class ListItemCreateComponent implements OnInit, AfterViewInit {
  SelectUtils = SelectUtils;
  UiConstants = UiConstants;

  @ViewChild('f', {static: true})
  fForm: NgForm;

  typeId?: number;
  componentEmbedded: boolean = false;

  listItemTypes: ListItemTypeItem[] = [];
  filterItems: MultiselectOptionItem<number>[] = [];
  paramTypes: ListItemParamTypeItem[];
  model: ListItemCreateModel;
  fieldErrors: ListItemFieldErrorMap;
  breadcrumbParents: BreadcrumbParent[] = [];
  breadcrumbSelf: string;
  compactSidebar: boolean = document.querySelector('body')!.classList.contains('sidebar-compact');
  stay: boolean = false;
  submitted: boolean = false;
  dropdownSettings: Angular2Multiselects.Settings;
  filterItemDropdownSettings: Angular2Multiselects.Settings;

  private typeQuerySubject: Subject<string> = new Subject<string>();

  constructor(private translateService: TranslateService,
              private rootService: RootCoreService,
              private listItemService: ListItemResourceService,
              @Optional() public dialogRef: MatDialogRef<ListItemCreateComponent>,
              @Optional() @Inject(MAT_DIALOG_DATA) private dialogData: any,
              private uiRouter: UIRouter) {
    this.model = new ListItemCreateModel();
    this.fieldErrors = {};
    if (this.dialogData) {
      this.componentEmbedded = true;
      this.typeId = this.dialogData.typeId;
      this.loadReadonlyType();
    }
  }

  ngOnInit() {
    this.translateService.get('LIST_ITEM_PANEL_HEADING_CREATE').subscribe(
      (result: string) => {
        this.breadcrumbSelf = result;
      }
    );
    this.translateService.get('MENU_NAVBAR_MENU_ADMINISTRATION').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.ADMIN_DASHBOARD});
      }
    );
    this.translateService.get('MENU_NAVBAR_LIST_ITEM').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.LIST_ITEM_LIST});
      }
    );
    this.initListItemTypeLoader();
    this.initDropdown();
  }

  ngAfterViewInit(): void {
    this.loadListItemTypes();
    this.loadParamTypes();
  }

  private initDropdown() {
    this.dropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(true)
      .enableSearchFilter(true)
      .labelKey(OptionItem.KEY_TEXT)
      .enableCheckAll(true)
      .remoteSearch(true)
      .build();
    this.filterItemDropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(false)
      .enableSearchFilter(true)
      .enableCheckAll(true)
      .remoteSearch(true)
      .build();
  }

  initListItemTypeLoader() {
    this.typeQuerySubject.pipe(debounceTime(UiConstants.autocompleteDebounceTime)).subscribe(q => {
      this.listItemService.getTypeList({
        disabled: false,
        number_of_items: 50,
        page_number: 1,
        order: 'key',
        key: q,
        no_progress_bar: true
      }).subscribe((result: ResourceQueryResult<RawListTypeItem>) => {
        this.listItemTypes = [];
        Arrays.iterateByIndex(result.items, (listItemType) => {
          const item = this.itemFromType(listItemType);
          this.listItemTypes.push(item);
        });
      });
    });
  }

  private loadReadonlyType() {
    this.listItemService.getType({id: this.typeId!}).subscribe(result => {
      this.model.type = this.itemFromType(result);
      this.onListItemTypeChange()
    });
  }

  private itemFromType(listItemType: RawListTypeItem) {
    const item = new ListItemTypeItem();
    item.id = listItemType.key;
    item.text = listItemType.key;
    item.params = listItemType.params;
    item.disabled = listItemType.disabled;
    item.filterTypeId = listItemType.filter_type ? listItemType.filter_type.id : undefined;
    return item;
  }

  loadListItemTypes(q?: string) {
    this.typeQuerySubject.next(q);
  }


  private loadParamTypes() {
    this.paramTypes = [];
    listItemParamTypes.forEach((type) => {
      const item = {
        id: type.type,
        text: '....'
      };
      this.paramTypes.push(item);
      this.translateService.get(type.stringKey).subscribe((text: string) => {
        item.text = text;
      });
    });
  }

  loadFilterItems(q?: string) {
    this.listItemService.getList({
      type_id: this.model.type!.filterTypeId,
      text: q ? Strings.undefinedOrNonEmpty(q) : undefined,
      order: '+text',
      disabled: false,
      page_number: 1,
      number_of_items: UiConstants.autocompletePageSize,
      no_progress_bar: true
    }).subscribe(result => {
      this.filterItems = result.items.map(i => ({id: i.id, itemName: i.text}));
    });
  }

  getParamTypeString(param: ListItemParam): string {
    const result = this.paramTypes.find((p) => p.id === param.param_type);
    return result!.text;
  }

  back() {
    window.history.back();
  }

  removeFieldError(fieldError?: FieldError) {
    FieldErrors.remove(this.fieldErrors, fieldError);
  }

  onListItemTypeChange() {
    this.removeFieldError(this.fieldErrors.type);
    this.model.params = [];
    if (this.model.type) {
      this.model.type.params.forEach((param) => {
        this.model.params.push({
          param_type: param.param_type,
          key: param.key,
          string_value: param.default_string_value,
          number_value: param.default_number_value
        });
      });
    }
    this.model.filterItems = [];
    if (this.model.type && this.model.type.filterTypeId) {
      this.loadFilterItems();
    }
  }

  create(stay: boolean) {
    this.stay = stay;
    this.submitted = true;

    if (this.fForm.invalid) {
      this.stay = false;
      return;
    }

    let type: string | undefined = undefined;
    if (this.model.type && this.model.type.id) {
      type = this.model.type.id;
    }

    if (type === undefined) {
      this.stay = false;
      return;
    }

    this.listItemService.create({
      code: this.model.code,
      type: type,
      text: this.model.text,
      params: this.model.params,
      filter_item_ids: this.model.filterItemIds
    }).subscribe(response => {
      if (this.stay) {
        this.submitted = false;
      }
      else {
        if (this.componentEmbedded) {
          this.closeDialog(true);
        } else {
          this.uiRouter.stateService.go(StateName.LIST_ITEM_LIST);
        }
      }
    }, error => {
      const res = ObservableErrorResourceParser.parseError(error);
      this.fieldErrors = ObservableErrorResourceParser.extractFieldErrors(res);
    });
  }

  closeDialog(result: boolean) {
    if (this.dialogRef) {
      this.dialogRef.close(result);
    }
  }
}
