<div class="form-group" [formGroup]="formGroup" [ngClass]="{ 'has-danger': hasLocalFieldError('value')}">
  <label class="form-control-label text-break" [ngClass]="{ 'required-field-label': required }">{{ model.title }}
    <i *ngIf="model.hint !== ''" class="icon icon-info popover-info-icon" title="{{ model.hint }}"></i>
  </label>
  <angular2-multiselect
    (onDeSelect)="onManualChange()"
    (onDeSelectAll)="onManualChange()"
    (onSelect)="onManualChange()"
    (onSelectAll)="onManualChange()"
    (onRemoteSearch)="loadUsers($event.target.value)"
    [(ngModel)]="model.values"
    [data]="userList"
    [settings]="dropdownSettings"
    [ngClass]="{ 'cursor-not-allowed': nonEditable}"
    [readonly]="nonEditable"
    [readonlyBorderEnabled]="true"
    (onReadonlyItemClick)="navigateToUserDetail($event)"
    formControlName="value">
  </angular2-multiselect>
  <div class="form-control-feedback" *ngIf="hasLocalFieldError('value')">
    <span *ngIf="hasLocalFieldError('value', 'required')">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</span>
    <span *ngIf="hasLocalFieldError('value', 'maxArraySize')">{{'COMMON_VALIDATION_MESSAGE_SINGLE_SELECT' | translate}}</span>
    <span
      *ngIf="hasLocalFieldError('value', 'hasDisabledItem')">{{'COMMON_VALIDATION_MESSAGE_DISABLED' | translate}}</span>
  </div>
</div>
