<div class="record" [ngClass]="{'record-selected': record.selected || allSelected}" (click)="toggleSelected()">
  <mat-checkbox class="position-absolute"
                [checked]="record.selected || allSelected"></mat-checkbox>
  <div style="background-image: url('../../../../../assets/img/stock/stock-item-placeholder.png')" class="record-picture" *ngIf="!record.pictureSrc"></div>
  <div [ngStyle]="{'background-image': 'url(' + record.pictureSrc + ')'}" class="record-picture" *ngIf="record.pictureSrc"></div>
  <div class="d-flex">
    <span class="record-title">{{record.name}}</span>
  </div>
    <div class="d-flex">
    <span class="record-external-id">({{record.externalId}})</span>
  </div>
  <div class="d-flex justify-content-between record-amount-container">
    <span class="record-amount-title">{{'STOCK_RECORD_SELECTOR_DIALOG_STOCK_NAME' | translate}}</span>
    <span class="record-amount-value">{{record.stockName}}</span>
  </div>
  <div class="d-flex justify-content-between record-amount-container">
    <span class="record-amount-title">{{'STOCK_RECORD_SELECTOR_DIALOG_RECORD_PRODUCT_CODE' | translate}}</span>
    <span class="record-amount-value">{{record.productCode}}</span>
  </div>
  <div class="d-flex justify-content-between record-amount-container">
    <span class="record-amount-title">{{'STOCK_RECORD_SELECTOR_DIALOG_RECORD_AMOUNT' | translate}}</span>
    <span class="record-amount-value">{{record.amount}} {{record.unit}}</span>
  </div>
  <div class="d-flex justify-content-between align-items-center record-price-container">
    <span class="record-price">{{record.unitPrice | number:undefined:'hu-HU'}} {{record.priceCurrency}}</span>
    <i class="icomoon icomoon-info record-info"
       (click)="toggleSelected()"
       [popover]="infoPopover"
       [outsideClick]="true"
       placement="bottom left"
       container="body"></i>
    <ng-template #infoPopover>
      <div class="record-info-name">{{record.name}}</div>
      <div class="record-info-external-id">{{record.externalId}}</div>
      <div class="record-info-price">
        {{'STOCK_RECORD_SELECTOR_DIALOG_RECORD_PRICE' | translate}} {{record.unitPrice | number:undefined:'hu-HU'}} {{record.priceCurrency}}
      </div>
      <div class="record-info-container">
        <span class="record-info-label">{{'STOCK_RECORD_SELECTOR_DIALOG_RECORD_AMOUNT' | translate}}</span>
        <span class="record-info-description">{{record.amount}} {{record.unit}}</span>
      </div>
      <div class="record-info-container">
        <span class="record-info-label">{{'STOCK_RECORD_SELECTOR_DIALOG_RECORD_VAT_RATE' | translate}}</span>
        <span class="record-info-description">{{record.vatRate}}%</span>
      </div>
      <div class="record-info-container">
        <span class="record-info-label">{{'STOCK_RECORD_SELECTOR_DIALOG_STOCK_NAME' | translate}}</span>
        <span class="record-info-description">{{record.stockName}}</span>
      </div>
      <div class="record-info-container" *ngIf="record.productCode">
        <span class="record-info-label">{{'STOCK_RECORD_SELECTOR_DIALOG_RECORD_PRODUCT_CODE' | translate}}</span>
        <span class="record-info-description">{{record.productCode}}</span>
      </div>
      <div class="record-info-container" *ngIf="record.hunVtszNumber">
        <span class="record-info-label">{{'STOCK_RECORD_SELECTOR_DIALOG_RECORD_HUN_VTSZ_NUMBER' | translate}}</span>
        <span class="record-info-description">{{record.hunVtszNumber}}</span>
      </div>
      <div class="record-info-container" *ngIf="record.weight">
        <span class="record-info-label">{{'STOCK_RECORD_SELECTOR_DIALOG_RECORD_WEIGHT' | translate}}</span>
        <span class="record-info-description">{{record.weight}}</span>
      </div>
      <div class="record-info-description" *ngIf="record.description">
        <span [innerHTML]="record.description"></span>
      </div>
    </ng-template>
  </div>
</div>
