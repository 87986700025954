/* eslint-disable */
import { Component, Input, OnInit } from '@angular/core';
import { DetailTab } from '../../admin/transport/transport-detail/detail-tab';
import { ChatService } from './chat-service.interface';
import { RightModel } from '../../app.rights';
import { Chat } from '../../lib/chat/chat.utils';
import { RightResolver, RightService } from '../../lib/right.service';
import { Set } from 'immutable';
import { OrderType, QueryResult } from '../../lib/util/services';
import { IdentityMessage } from '../../lib/util/messages';
/* eslint-enable */

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss']
})
export class ChatComponent implements OnInit, DetailTab {

  @Input()
  id: number;

  @Input()
  parentId?: number;

  @Input()
  messageSendEnable?: boolean = true;

  @Input()
  private chatService: ChatService;

  messageList: Chat.Message[] = [];
  newMessage: string = '';
  newMessageError: boolean = false;
  pages: number = 1;
  totalNumberOfItems: number = 0;

  rightModel: RightModel = RightModel.empty();

  constructor(private rightService: RightService) { }

  ngOnInit() {
  }

  initComponent() {
    this.loadRightModels();
    this.loadMessages();
  }

  private loadRightModels() {
    this.rightService.getRightResolver().subscribe(
      (resolver: RightResolver) => {
        this.rightModel = RightModel.of(resolver);
      }
    );
  }

  loadMessages(afterLoad?: boolean) {
    if (afterLoad) {
      this.pages++;
    }
    this.chatService.messageQuery({
      id: this.id,
      parentId: this.parentId ? this.parentId : undefined,
      order: Set.of({type: OrderType.DESC, field: Chat.OrderField.CREATION_TIME}),
      paging: {
        pageNumber: afterLoad ? this.pages : 1,
        numberOfItems: 10
      },
    }).subscribe((result: QueryResult<Chat.Message>) => {
      if (afterLoad) {
        result.items.toArray().forEach((m) => {
          this.messageList.unshift(m);
        });
        const messageContainers = document.getElementsByClassName('message-container');
        messageContainers[messageContainers.length - (this.pages - 1) * 10].scrollIntoView();
      }
      else {
        this.messageList = result.items.toArray().reverse();
        this.pages = 1;
      }
      this.totalNumberOfItems = result.pagingResult.totalNumberOfItems;
    });
  }



  sendMessage() {
    if (this.newMessage.length === 0) {
      this.newMessageError = true;
      return;
    }
    this.chatService.sendMessage({
      id: this.id,
      parentId: this.parentId ? this.parentId : undefined,
      content: this.newMessage
    }).subscribe((result: IdentityMessage) => {
      this.loadMessages();
      this.newMessage = '';
    });
  }

  removeNewMessageError() {
    this.newMessageError = false;
  }

}
