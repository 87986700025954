<div class="card">

  <div class="card-header">
    {{'TASK_RECORD_SUB_TASKS' | translate}}
    <div class="card-actions">
      <a class="btn-setting table-header-action-button d-flex align-items-center px-1 w-auto font-weight-normal"
         *ngIf="rightModel.taskRecordSubTaskCreate.hasRight() && this.taskRecordState &&
                TaskRecordSubTask.ValidTaskRecordStatesForCreate.contains(this.taskRecordState)"
         (click)="onSubTaskCreateEditDialogButtonClicked.emit()"
         title="{{'TASK_RECORD_SUB_TASK_CREATE' | translate}}">
        <i class="icomoon pr-1 icomoon-subtask"></i>{{'TASK_RECORD_SUB_TASK_CREATE' | translate}}
      </a>
      <a class="btn-setting table-header-action-button d-flex align-items-center px-1 w-auto font-weight-normal"
         (click)="toggleSearch()"
         title="{{'COMMON_BUTTON_SEARCH' | translate}}">
        <i class="icomoon pr-1 icomoon-search"></i>{{'COMMON_BUTTON_SEARCH' | translate}}
      </a>
    </div><!-- /.card-actions -->
  </div>

  <div class="card-body border-bottom" *ngIf="showSearch">
    <div class="row form-group">
      <div class="col-md-4">
        <label class="search-label">{{'COMMON_TABLE_HEADER_ID' | translate}}</label>
        <input type="text" class="form-control" placeholder="{{'COMMON_TABLE_HEADER_ID' | translate}}"
               maxlength="{{UiConstants.maxInputLongLength}}"
               (keyup.enter)="onSearchClicked()"
               [textMask]="{mask: InputMask.NATURAL_INTEGER, guide: false}"
               [(ngModel)]="searchModel.id" name="id" id="id" #id="ngModel">
      </div>
      <div class="col-md-4">
        <label class="search-label">{{'COMMON_NAME' | translate}}</label>
        <input type="text" class="form-control" placeholder="{{'COMMON_NAME' | translate}}"
               maxlength="{{UiConstants.maximumVarcharLength}}"
               (keyup.enter)="onSearchClicked()"
               [(ngModel)]="searchModel.name" name="name" id="name" #name="ngModel">
      </div>
      <div class="col-md-4">
        <label class="search-label">{{'TASK_RECORD_GLOBAL_LIST_TASK_TYPE_NAME' | translate}}</label>
        <input type="text" class="form-control" placeholder="{{'TASK_RECORD_GLOBAL_LIST_TASK_TYPE_NAME' | translate}}"
               maxlength="{{UiConstants.maximumVarcharLength}}"
               (keyup.enter)="onSearchClicked()"
               [(ngModel)]="searchModel.type" name="type" id="type" #type="ngModel">
      </div>
    </div>
    <div class="row form-group">
      <div class="col-md-4">
        <label class="search-label">{{'COMMON_NOTE' | translate}}</label>
        <input type="text" class="form-control" placeholder="{{'COMMON_NOTE' | translate}}"
               maxlength="{{UiConstants.maximumVarcharLength}}"
               (keyup.enter)="onSearchClicked()"
               [(ngModel)]="searchModel.note" name="note" id="note" #note="ngModel">
      </div>
      <div class="col-md-4">
        <label class="search-label">{{'TASK_RECORD_TABLE_HEADER_USER_NAME'|translate}}</label>
        <angular2-multiselect
          [(ngModel)]="searchModel.workerUser"
          [ngModelOptions]="{standalone: true}"
          (onRemoteSearch)="loadWorkerUsers($event.target.value)"
          [data]="users"
          [settings]="dropdownSettings">
        </angular2-multiselect>
      </div>
      <div class="col-md-4">
        <label class="search-label">{{'TASK_RECORD_SEARCH_STATE' | translate}}</label>
        <select class="form-control" [(ngModel)]="searchModel.state"
                id="state" name="state" #state="ngModel">
          <option *ngFor="let item of states" [ngValue]="item">{{item.stringKey | translate}}</option>
        </select>
      </div>
    </div>
    <div class="col-md-12 d-flex justify-content-md-end align-items-end px-0">
      <div class="btn-group" role="group">
        <button type="button" (click)="onSearchReset()" class="btn btn-outline-primary search-button mr-1">
          {{'COMMON_BUTTON_SEARCH_DELETE' | translate}}
        </button>
        <button type="button" (click)="onSearchClicked()" class="btn btn-primary search-button">
          {{'COMMON_BUTTON_SEARCH' | translate}}
        </button>
      </div>
    </div>
  </div>

  <div class="card-body">
    <table class="table table-striped table-bordered">
      <thead>
      <tr>
        <th class="table-sorter-header w-5">
          <app-table-field-sorter [orderField]="OrderFunctions.ID"
                                  [orderType]="queryModel.getOrderType(OrderFunctions.ID)"
                                  (orderChange)="orderBy($event)"
                                  [text]="'COMMON_TABLE_HEADER_ID' | translate"></app-table-field-sorter>
        </th>
        <th class="table-sorter-header w-10 hidden-1300-down">
          <app-table-field-sorter [orderField]="OrderFunctions.NAME"
                                  [orderType]="queryModel.getOrderType(OrderFunctions.NAME)"
                                  (orderChange)="orderBy($event)"
                                  [text]="'COMMON_NAME' | translate"></app-table-field-sorter>
        </th>
        <th class="table-sorter-header w-10">
          <app-table-field-sorter [orderField]="OrderFunctions.TYPE"
                                  [orderType]="queryModel.getOrderType(OrderFunctions.TYPE)"
                                  (orderChange)="orderBy($event)"
                                  [text]="'TASK_RECORD_GLOBAL_LIST_TASK_TYPE_NAME' | translate"></app-table-field-sorter>
        </th>
        <th class="table-sorter-header w-15 hidden-1300-down">
          <app-table-sorter-no-op
            [text]="'TASK_RECORD_TABLE_HEADER_USER_NAME' | translate"></app-table-sorter-no-op>
        </th>
        <th class="table-sorter-header long hidden-1300-down">
          <app-table-sorter-no-op
            [text]="'COMMON_NOTE' | translate"></app-table-sorter-no-op>
        </th>
        <th class="table-sorter-header w-10 hidden-xs-down text-center">
          <app-table-sorter-no-op [text]="'TASK_RECORD_TABLE_HEADER_STATE' | translate"></app-table-sorter-no-op>
        </th>
        <th class="table-sorter-header w-15 hidden-1300-down">
          <app-table-sorter-no-op
            [text]="'TASK_RECORD_SUB_TASK_TIME_SPENT' | translate"></app-table-sorter-no-op>
        </th>
        <th class="table-sorter-header w-10">
          <app-table-sorter-no-op [text]="'COMMON_TABLE_HEADER_ACTIONS' | translate"></app-table-sorter-no-op>
        </th>
      </tr>
      </thead>
      <tbody>
      <tr class="table"
          *ngFor="let subTask of subTaskList | paginate: { id: pagingId,
                                                      itemsPerPage: queryModel.itemsPerPage,
                                                      currentPage: queryModel.currentPage,
                                                      totalItems: queryModel.currentNumberOfItems }">
        <td class="responsive-table-column">{{subTask.id}}</td>
        <td class="responsive-table-column hidden-1300-down">{{subTask.name}}</td>
        <td class="responsive-table-column">{{subTask.type}}</td>
        <td class="responsive-table-column hidden-1300-down">{{subTask.workerUser}}</td>
        <td class="responsive-table-column hidden-1300-down">{{subTask.note}}</td>
        <td class="responsive-table-column text-center icon-sizing hidden-xs-down icomoon-state-icons hidden-xs-down">
          <ng-template #statePopover>
            <i class="icomoon {{subTask.stateIconClass}} popover-icon"></i>
            {{subTask.stateStringKey | translate }}
          </ng-template>
          <span class="icomoon {{subTask.stateIconClass}}"
                [popover]="statePopover" triggers="mouseenter:mouseleave"></span>
        </td>
        <td class="responsive-table-column hidden-1300-down">{{subTask.timeSpent}}</td>
        <td class="responsive-table-column long">
          <a class="icons-table-group cursor-pointer"
             (click)="onSubTaskDetailDialogButtonClicked.emit(subTask)">
            <i class="icomoon icons-table-item icomoon-detail icomoon-detail-table"
               title="{{'COMMON_BUTTON_DETAILS' | translate}}"></i>
          </a>
          <a class="icons-table-group cursor-pointer"
             *ngIf="subTask.rights.update.hasRight()"
             (click)="onSubTaskCreateEditDialogButtonClicked.emit(subTask.id)">
            <i class="icomoon icons-table-item icomoon-modify icomoon-modify-table"
               title="{{'COMMON_BUTTON_EDIT' | translate}}"></i>
          </a>
          <a class="icons-table-group cursor-pointer"
             *ngIf="subTask.rights.delete.hasRight() &&
             this.taskRecordState &&
             TaskRecordSubTask.ValidTaskRecordStatesForDelete.contains(this.taskRecordState) &&
             TaskRecordSubTask.ValidSubTaskStatesForDelete.contains(subTask.state)"
             (click)="onSubTaskDeleteDialogButtonClicked.emit(subTask.id)">
            <i class="icomoon icons-table-item icomoon-trash icomoon-modify-table"
               title="{{'COMMON_BUTTON_DELETE' | translate}}"></i>
          </a>
        </td>
      </tr>
      </tbody>
      <tfoot>
      <tr>
        <th class="table-sorter-header w-5">
          <app-table-field-sorter [orderField]="OrderFunctions.ID"
                                  [orderType]="queryModel.getOrderType(OrderFunctions.ID)"
                                  (orderChange)="orderBy($event)"
                                  [text]="'COMMON_TABLE_HEADER_ID' | translate"></app-table-field-sorter>
        </th>
        <th class="table-sorter-header w-10 hidden-1300-down">
          <app-table-field-sorter [orderField]="OrderFunctions.NAME"
                                  [orderType]="queryModel.getOrderType(OrderFunctions.NAME)"
                                  (orderChange)="orderBy($event)"
                                  [text]="'COMMON_NAME' | translate"></app-table-field-sorter>
        </th>
        <th class="table-sorter-header w-10">
          <app-table-field-sorter [orderField]="OrderFunctions.TYPE"
                                  [orderType]="queryModel.getOrderType(OrderFunctions.TYPE)"
                                  (orderChange)="orderBy($event)"
                                  [text]="'TASK_RECORD_GLOBAL_LIST_TASK_TYPE_NAME' | translate"></app-table-field-sorter>
        </th>
        <th class="table-sorter-header w-15 hidden-1300-down">
          <app-table-sorter-no-op
            [text]="'TASK_RECORD_TABLE_HEADER_USER_NAME' | translate"></app-table-sorter-no-op>
        </th>
        <th class="table-sorter-header long hidden-1300-down">
          <app-table-sorter-no-op
            [text]="'COMMON_NOTE' | translate"></app-table-sorter-no-op>
        </th>
        <th class="table-sorter-header w-10 hidden-xs-down text-center">
          <app-table-sorter-no-op [text]="'TASK_RECORD_TABLE_HEADER_STATE' | translate"></app-table-sorter-no-op>
        </th>
        <th class="table-sorter-header w-15 hidden-1300-down">
          <app-table-sorter-no-op
            [text]="'TASK_RECORD_SUB_TASK_TIME_SPENT' | translate"></app-table-sorter-no-op>
        </th>
        <th class="table-sorter-header w-10">
          <app-table-sorter-no-op [text]="'COMMON_TABLE_HEADER_ACTIONS' | translate"></app-table-sorter-no-op>
        </th>
      </tr>
      </tfoot>
    </table>
    <app-table-paging
      [id]="pagingId"
      [currentNumberOfItems]="queryModel.currentNumberOfItems"
      [totalNumberOfItems]="queryModel.totalNumberOfItems"
      [itemsPerPage]="queryModel.itemsPerPage"
      (pageChange)="pageChanged($event)"
      (itemsPerPageChange)="itemsPerPageChanged($event)">
    </app-table-paging>
  </div>
</div>
