import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { merge, Observable, Subject } from 'rxjs';
import { debounceTime, flatMap, map } from 'rxjs/operators';
import { UiConstants } from '../../../../../../util/core-utils';
import { Strings } from '../../../../../../lib/util/strings';
import { Map } from 'immutable';
import { NgModel } from '@angular/forms';
import { FormStockService } from '../../../../../../lib/form/form-stock.service';

@Component({
  selector: 'app-form-record-stock-field-comment-edit',
  templateUrl: './form-record-stock-field-comment-edit.component.html',
  styleUrls: ['./form-record-stock-field-comment-edit.component.scss']
})
export class FormRecordStockFieldCommentEditComponent implements OnInit, AfterViewInit {

  @Input()
  commentText?: string;

  @Input()
  disabled: boolean = false;

  @Input()
  required: boolean = false;

  @Input()
  customLabel: string | undefined = undefined;

  @Output()
  commentTextChange: EventEmitter<string> = new EventEmitter<string>();

  @ViewChild('comment', {static: true})
  comment: NgModel;

  constructor() {
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
  }

  onCommentChanged(c?: string) {
    this.commentTextChange.emit(c);
  }

}
