<div class="modal-content">
  <div class="modal-header">
    <h4 class="modal-title">{{'HELPDESK_DASHBOARD_BUG_REPORT_TYPE_SELECTOR_TITLE' | translate}}</h4>
    <button type="button" class="close" (click)="closeDialog(true)" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row m-0 p-5px">
      <div class="col-md-3 col-sm-6 p-0" *ngFor="let task of tasks">
        <div class="task-card text-break" [class.card-selected]="selectedTaskId === task.taskId"
             (click)="onSelection(task.taskId)">
          <app-type-icon [icon]="task.icon" class="mb-1"></app-type-icon>
          <div class="h4 text-center">{{task.name}}</div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="closeDialog()" [disabled]="!selectedTaskId">{{'COMMON_BUTTON_CONTINUE' | translate}}</button>
  </div>
</div>
