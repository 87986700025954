import { GrantedPermissionSetResolver } from '../../../lib/right.service';
import { Dates, OffsetDateTime } from '../../../lib/util/dates';
import { WorkflowRightModel } from '../../../lib/workflow/workflow-right.model';
import { Workflow } from '../../../lib/workflow/workflow.service';
import { Icon } from '../../../lib/task/icon.service';

export class WorkflowListModel {
  id: number;
  rights: WorkflowRightModel = new WorkflowRightModel(GrantedPermissionSetResolver.empty());
  version?: number;
  versionState: Workflow.VersionState;
  stateObject?: Workflow.VersionStateObject;
  name: string;
  externalId: string;
  // creationTime: OffsetDateTime = Dates.emptyOffsetDateTime();
  icon?: Icon.Icon;
  updateTime: OffsetDateTime = Dates.emptyOffsetDateTime();
  lastModifiedUser: {
    id: number;
    userName: string;
    personName: string;
  };

  getLastModifierName(): string {
    return this.lastModifiedUser.personName;
  }

}
