<div [formGroup]="formGroup" *ngIf="model">

  <div class="row"
       [class.has-danger]="hasLocalFieldError('name')">
    <label class="col-form-label col-sm-{{labelRatio}}"
           [ngClass]="[useMdTextAlign ? 'text-md-right' : '']">
      {{'COMMON_ADDRESS_COMPLEX_NAME' | translate}}
    </label>
    <div class="form-group col-sm-{{fieldRatio}}"
         [ngClass]="[hasLocalFieldError('name') ? 'has-danger' : '']">
    <input type="text" class="form-control"
           maxlength="{{UiConstants.maximumVarcharLength}}"
           formControlName="name"
           [ngClass]="{ 'form-control-danger': hasLocalFieldError('name') }"
           [readonly]="readonly"
           [(ngModel)]="m.complexValue.name"
           (ngModelChange)="nameControl.updateValueAndValidity()"
           placeholder="{{'COMMON_ADDRESS_COMPLEX_NAME' | translate}}">
    <div class="form-control-feedback"
         *ngIf="hasLocalFieldError('name')">
        <span *ngIf="hasLocalFieldError('name', 'noWhiteSpace')">
          {{'COMMON_VALIDATION_MESSAGE_NO_WHITE_SPACE' | translate}}
        </span>
    </div>
    </div>
  </div>


  <div class="row" [class.has-danger]="hasLocalFieldError('countryItem')">
    <label class="col-form-label required-field-label col-sm-{{labelRatio}}"
      [ngClass]="[useMdTextAlign ? 'text-md-right' : '']">
      {{'COMMON_ADDRESS_COMPLEX_COUNTRY' | translate}}
    </label>
    <div class="form-group col-sm-{{fieldRatio}}"
         [ngClass]="[hasLocalFieldError('countryItem') ? 'has-danger' : '']">
      <angular2-multiselect
        [(ngModel)]="model.complexValue._country"
        [data]="model.countryItems"
        [settings]="dropdownSettings"
        [readonly]="readonly"
        [readonlyBorderEnabled]="true"
        formControlName="countryItem">
      </angular2-multiselect>
      <div class="form-control-feedback" *ngIf="hasLocalFieldError('countryItem')">
        {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
      </div>
    </div>
  </div>

  <div class="row" [ngClass]="{ 'has-danger': hasLocalFieldError('zipCode') }">
    <label class="col-form-label required-field-label col-sm-{{labelRatio}}"
           [ngClass]="[useMdTextAlign ? 'text-md-right' : '']">
      {{'COMMON_ADDRESS_COMPLEX_ZIP_CODE' | translate}}
    </label>
    <div class="form-group col-sm-{{fieldRatio}}"
         [ngClass]="[hasLocalFieldError('zipCode') ? 'has-danger' : '']">
      <input type="text" class="form-control"
             formControlName="zipCode"
             maxlength="{{UiConstants.maximumVarcharLength}}"
             [ngClass]="{ 'form-control-danger': hasLocalFieldError('zipCode') }"
             [readonly]="readonly || fillZipCodeInProgress"
             [(ngModel)]="m.complexValue.zipCode"
             [textMask]="getZipCodeTextMaskConfiguration()"
             placeholder="{{'COMMON_ADDRESS_COMPLEX_ZIP_CODE' | translate}}">
      <div class="form-control-feedback"
           *ngIf="hasLocalFieldError('zipCode')">
        <span *ngIf="hasLocalFieldError('zipCode', 'required')">
          {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
        </span>
        <span *ngIf="hasLocalFieldError('zipCode', 'noWhiteSpace')">
          {{'COMMON_VALIDATION_MESSAGE_NO_WHITE_SPACE' | translate}}
        </span>
        <span *ngIf="hasLocalFieldError('zipCode', 'zipCodeFormatInvalid')">
          {{'COMMON_ADDRESS_COMPLEX_ZIP_CODE_FORMAT_ERROR' | translate}}
        </span>
      </div>
    </div>
  </div>

  <div class="row" [ngClass]="{ 'has-danger': hasLocalFieldError('city') }">
    <label class="col-form-label required-field-label col-sm-{{labelRatio}}"
           [ngClass]="[useMdTextAlign ? 'text-md-right' : '']">
      {{'COMMON_ADDRESS_COMPLEX_CITY' | translate}}
    </label>
    <div class="form-group col-sm-{{fieldRatio}}"
         [ngClass]="[hasLocalFieldError('city') ? 'has-danger' : '']">
      <input type="text" class="form-control"
             formControlName="city"
             maxlength="{{UiConstants.maximumVarcharLength}}"
             [ngClass]="{ 'form-control-danger': hasLocalFieldError('city') }"
             [readonly]="readonly || fillCityInProgress"
             [(ngModel)]="m.complexValue.city"
             [matAutocomplete]="cityAuto"
             (blur)="fillZipCode()"
             placeholder="{{'COMMON_ADDRESS_COMPLEX_CITY_HINT' | translate}}">
      <mat-autocomplete #cityAuto="matAutocomplete">
        <mat-option *ngFor="let cityOption of cityOptions | async" [value]="cityOption.localizedName">
          {{cityOption.localizedName}}
        </mat-option>
      </mat-autocomplete>
      <div class="form-control-feedback" *ngIf="hasLocalFieldError('city')">
        <span *ngIf="hasLocalFieldError('city', 'required')">
          {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
        </span>
        <span *ngIf="hasLocalFieldError('city', 'invalidCity')">
            {{'COMPLEX_ADDRESS_VALIDATION_INVALID_CITY' | translate}}
          </span>
        <span *ngIf="hasLocalFieldError('city', 'noWhiteSpace')">
          {{'COMMON_VALIDATION_MESSAGE_NO_WHITE_SPACE' | translate}}
        </span>
      </div>
    </div>
  </div>

  <div class="row" *ngIf="model.type === PostalAddressModelType.SIMPLE"
       [class.has-danger]="hasLocalFieldError('address')">
    <label class="col-form-labelrequired-field-label col-sm-{{labelRatio}}"
           [ngClass]="[useMdTextAlign ? 'text-md-right' : '']">
      {{'COMMON_ADDRESS_COMPLETE_ADDRESS' | translate}}
    </label>
    <div class="form-group col-sm-{{fieldRatio}}"
         [ngClass]="[hasLocalFieldError('address') ? 'has-danger' : '']">
      <input type="text" class="form-control"
             formControlName="address"
             maxlength="{{UiConstants.maximumVarcharLength}}"
             [ngClass]="{ 'form-control-danger': hasLocalFieldError('address') }"
             [readonly]="readonly"
             [(ngModel)]="m.complexValue.address"
             placeholder="{{'COMMON_ADDRESS_COMPLETE_ADDRESS_HINT' | translate}}">
      <div class="form-control-feedback" *ngIf="hasLocalFieldError('address')">
        <span *ngIf="hasLocalFieldError('address', 'required')">
          {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
        </span>
        <span *ngIf="hasLocalFieldError('address', 'noWhiteSpace')">
          {{'COMMON_VALIDATION_MESSAGE_NO_WHITE_SPACE' | translate}}
        </span>
      </div>
    </div>
  </div>

  <div class="row" *ngIf="model.type === PostalAddressModelType.COMPLEX"
       [class.has-danger]="hasLocalFieldError('address') || hasLocalFieldError('addressType')">
    <label class="col-form-label required-field-label col-sm-{{labelRatio}}"
           [ngClass]="[useMdTextAlign ? 'text-md-right' : '']">
      {{'COMMON_ADDRESS_COMPLEX_ADDRESS' | translate}}
    </label>
    <div class="form-group row pr-0 col-sm-{{fieldRatio}}">
      <div class="col-8" [ngClass]="{ 'has-danger': hasLocalFieldError('address') }">
        <input type="text" class="form-control"
               formControlName="address"
               maxlength="{{UiConstants.maximumVarcharLength}}"
               [ngClass]="{ 'form-control-danger': hasLocalFieldError('address') }"
               [readonly]="readonly"
               [(ngModel)]="m.complexValue.address"
               [matAutocomplete]="addressAuto"
               placeholder="{{'COMMON_ADDRESS_COMPLEX_ADDRESS' | translate}}">
        <mat-autocomplete #addressAuto="matAutocomplete" (optionSelected)="onAddressOptionSelected($event)">
          <mat-option *ngFor="let addressOption of streetOptions | async" [value]="addressOption.nativeName">
            {{addressOption.nativeName + ' ' + addressOption.nativeType}}
          </mat-option>
        </mat-autocomplete>
        <div class="form-control-feedback" *ngIf="hasLocalFieldError('address')">
          <span *ngIf="hasLocalFieldError('address', 'required')">
            {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
          </span>
          <span *ngIf="hasLocalFieldError('address', 'noWhiteSpace')">
            {{'COMMON_VALIDATION_MESSAGE_NO_WHITE_SPACE' | translate}}
          </span>
        </div>
      </div>
      <div class="col-4 p-0"
           [ngClass]="{ 'has-danger': hasLocalFieldError('addressType') }">
        <input type="text" class="form-control"
               formControlName="addressType"
               maxlength="{{UiConstants.maximumVarcharLength}}"
               [ngClass]="{ 'form-control-danger': hasLocalFieldError('addressType') }"
               [readonly]="readonly"
               [(ngModel)]="m.complexValue.addressType"
               (ngModelChange)="addressTypeControl.updateValueAndValidity()"
               [matAutocomplete]="addressTypeAuto"
               placeholder="{{'COMMON_ADDRESS_COMPLEX_ADDRESS_TYPE' | translate}}">
        <mat-autocomplete #addressTypeAuto="matAutocomplete">
          <mat-option *ngFor="let typeOption of typeOptions | async" [value]="typeOption">
            {{typeOption}}
          </mat-option>
        </mat-autocomplete>
        <div class="form-control-feedback"
             *ngIf="hasLocalFieldError('addressType')">
          <span *ngIf="hasLocalFieldError('addressType', 'required')">
            {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
          </span>
          <span *ngIf="hasLocalFieldError('addressType', 'invalidStreetType')">
            {{'COMPLEX_ADDRESS_VALIDATION_INVALID_ADDRESS_TYPE' | translate}}
          </span>
          <span *ngIf="hasLocalFieldError('addressType', 'noWhiteSpace')">
            {{'COMMON_VALIDATION_MESSAGE_NO_WHITE_SPACE' | translate}}
          </span>
        </div>
      </div>
    </div>
  </div>
  <div class="row" *ngIf="model.type === PostalAddressModelType.COMPLEX && otherFieldColumns === undefined">
    <div class="col-sm-{{labelRatio}}" [ngClass]="[useMdTextAlign ? 'text-md-right' : '', hasLocalFieldError('houseNumber') ? 'has-danger' : '']">
      <label class="col-form-label required-field-label">
        {{'COMMON_ADDRESS_COMPLEX_HOUSE_NUMBER' | translate}}
      </label>
    </div>
    <div class="form-group  col-sm-{{labelRatio}}"
         [ngClass]="[hasLocalFieldError('houseNumber') ? 'has-danger' : '']">
      <input type="text" class="form-control"
             maxlength="{{UiConstants.maximumVarcharLength}}"
             formControlName="houseNumber"
             [ngClass]="{ 'form-control-danger': hasLocalFieldError('houseNumber') }"
             [readonly]="readonly"
             [(ngModel)]="m.complexValue.houseNumber"
             (ngModelChange)="houseNumberControl.updateValueAndValidity()"
             placeholder="{{'COMMON_ADDRESS_COMPLEX_HOUSE_NUMBER' | translate}}">
      <div class="form-control-feedback"
           *ngIf="hasLocalFieldError('houseNumber')">
        <span *ngIf="hasLocalFieldError('houseNumber', 'required')">
          {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
        </span>
        <span *ngIf="hasLocalFieldError('houseNumber', 'noWhiteSpace')">
          {{'COMMON_VALIDATION_MESSAGE_NO_WHITE_SPACE' | translate}}
        </span>
      </div>
    </div>
    <div class="col-sm-{{labelRatio}}" *ngIf="12 / labelRatio % 2 !== 0"></div>
    <div class="col-sm-{{labelRatio}}" [ngClass]="[useMdTextAlign ? 'text-md-right' : '', hasLocalFieldError('building') ? 'has-danger' : '']">
      <label class="col-form-label">
        {{'COMMON_ADDRESS_COMPLEX_BUILDING' | translate}}
      </label>
    </div>
    <div class="form-group col-sm-{{labelRatio}}"
         [ngClass]="[hasLocalFieldError('building') ? 'has-danger' : '']">
      <input type="text" class="form-control"
             maxlength="{{UiConstants.maximumVarcharLength}}"
             formControlName="building"
             [ngClass]="{ 'form-control-danger': hasLocalFieldError('building') }"
             [readonly]="readonly"
             [(ngModel)]="m.complexValue.building"
             (ngModelChange)="buildingControl.updateValueAndValidity()"
             placeholder="{{'COMMON_ADDRESS_COMPLEX_BUILDING' | translate}}">
      <div class="form-control-feedback"
           *ngIf="hasLocalFieldError('building')">
        <span *ngIf="hasLocalFieldError('building', 'noWhiteSpace')">
          {{'COMMON_VALIDATION_MESSAGE_NO_WHITE_SPACE' | translate}}
        </span>
      </div>
    </div>
  </div>

  <div class="row" *ngIf="model.type === PostalAddressModelType.COMPLEX && otherFieldColumns === undefined">
    <div class="col-sm-{{labelRatio}}" [ngClass]="[useMdTextAlign ? 'text-md-right' : '', hasLocalFieldError('stairway') ? 'has-danger' : '']">
      <label class="col-form-label">
        {{'COMMON_ADDRESS_COMPLEX_STAIRWAY' | translate}}
      </label>
    </div>
    <div class="form-group col-sm-{{labelRatio}}"
         [ngClass]="[hasLocalFieldError('stairway') ? 'has-danger' : '']">
      <input type="text" class="form-control"
             maxlength="{{UiConstants.maximumVarcharLength}}"
             formControlName="stairway"
             [ngClass]="{ 'form-control-danger': hasLocalFieldError('stairway') }"
             [readonly]="readonly"
             [(ngModel)]="m.complexValue.stairway"
             (ngModelChange)="stairwayControl.updateValueAndValidity()"
             placeholder="{{'COMMON_ADDRESS_COMPLEX_STAIRWAY' | translate}}">
      <div class="form-control-feedback"
           *ngIf="hasLocalFieldError('stairway')">
        <span *ngIf="hasLocalFieldError('stairway', 'noWhiteSpace')">
          {{'COMMON_VALIDATION_MESSAGE_NO_WHITE_SPACE' | translate}}
        </span>
      </div>
    </div>
    <div class="col-sm-{{labelRatio}}" *ngIf="12 / labelRatio % 2 !== 0"></div>
    <div class="col-sm-{{labelRatio}}" [ngClass]="[useMdTextAlign ? 'text-md-right' : '', hasLocalFieldError('floor') ? 'has-danger' : '']">
      <label class="col-form-label">
        {{'COMMON_ADDRESS_COMPLEX_FLOOR' | translate}}
      </label>
    </div>
    <div class="form-group col-sm-{{labelRatio}}"
         [ngClass]="[hasLocalFieldError('floor') ? 'has-danger' : '']">
      <input type="text" class="form-control"
             maxlength="{{UiConstants.maximumVarcharLength}}"
             formControlName="floor"
             [ngClass]="{ 'form-control-danger': hasLocalFieldError('floor') }"
             [readonly]="readonly"
             [(ngModel)]="m.complexValue.floor"
             (ngModelChange)="floorControl.updateValueAndValidity()"
             placeholder="{{'COMMON_ADDRESS_COMPLEX_FLOOR' | translate}}">
      <div class="form-control-feedback"
           *ngIf="hasLocalFieldError('floor')">
        <span *ngIf="hasLocalFieldError('floor', 'noWhiteSpace')">
          {{'COMMON_VALIDATION_MESSAGE_NO_WHITE_SPACE' | translate}}
        </span>
      </div>
    </div>
  </div>

  <div class="row" *ngIf="model.type === PostalAddressModelType.COMPLEX && otherFieldColumns === undefined">
    <div class="col-sm-{{labelRatio}}" [ngClass]="[useMdTextAlign ? 'text-md-right' : '', hasLocalFieldError('door') ? 'has-danger' : '']">
      <label class="col-form-label">
        {{'COMMON_ADDRESS_COMPLEX_DOOR' | translate}}
      </label>
    </div>
    <div class="form-group col-sm-{{labelRatio}}"
         [ngClass]="[hasLocalFieldError('door') ? 'has-danger' : '']">
      <input type="text" class="form-control"
             maxlength="{{UiConstants.maximumVarcharLength}}"
             formControlName="door"
             [ngClass]="{ 'form-control-danger': hasLocalFieldError('door') }"
             [readonly]="readonly"
             [(ngModel)]="m.complexValue.door"
             (ngModelChange)="doorControl.updateValueAndValidity()"
             placeholder="{{'COMMON_ADDRESS_COMPLEX_DOOR' | translate}}">
      <div class="form-control-feedback"
           *ngIf="hasLocalFieldError('door')">
        <span *ngIf="hasLocalFieldError('door', 'noWhiteSpace')">
          {{'COMMON_VALIDATION_MESSAGE_NO_WHITE_SPACE' | translate}}
        </span>
      </div>
    </div>
  </div>

  <div class="row" *ngIf="model.type === PostalAddressModelType.COMPLEX && otherFieldColumns !== undefined">
    <div class="col-sm-{{12/otherFieldColumns}}">
      <div [ngClass]="[useMdTextAlign ? 'text-md-right' : '', hasLocalFieldError('houseNumber') ? 'has-danger' : '']">
        <label class="col-form-label required-field-label">
          {{'COMMON_ADDRESS_COMPLEX_HOUSE_NUMBER' | translate}}
        </label>
      </div>
      <div class="form-group"
           [ngClass]="[hasLocalFieldError('houseNumber') ? 'has-danger' : '']">
        <input type="text" class="form-control"
               maxlength="{{UiConstants.maximumVarcharLength}}"
               formControlName="houseNumber"
               [ngClass]="{ 'form-control-danger': hasLocalFieldError('houseNumber') }"
               [readonly]="readonly"
               [(ngModel)]="m.complexValue.houseNumber"
               (ngModelChange)="houseNumberControl.updateValueAndValidity()"
               placeholder="{{'COMMON_ADDRESS_COMPLEX_HOUSE_NUMBER' | translate}}">
        <div class="form-control-feedback"
             *ngIf="hasLocalFieldError('houseNumber')">
        <span *ngIf="hasLocalFieldError('houseNumber', 'required')">
          {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
        </span>
          <span *ngIf="hasLocalFieldError('houseNumber', 'noWhiteSpace')">
          {{'COMMON_VALIDATION_MESSAGE_NO_WHITE_SPACE' | translate}}
        </span>
        </div>
      </div>
    </div>
    <div class="col-sm-{{12/otherFieldColumns}}">
      <div [ngClass]="[useMdTextAlign ? 'text-md-right' : '', hasLocalFieldError('building') ? 'has-danger' : '']">
        <label class="col-form-label">
          {{'COMMON_ADDRESS_COMPLEX_BUILDING' | translate}}
        </label>
      </div>
      <div class="form-group"
           [ngClass]="[hasLocalFieldError('building') ? 'has-danger' : '']">
        <input type="text" class="form-control"
               maxlength="{{UiConstants.maximumVarcharLength}}"
               formControlName="building"
               [ngClass]="{ 'form-control-danger': hasLocalFieldError('building') }"
               [readonly]="readonly"
               [(ngModel)]="m.complexValue.building"
               (ngModelChange)="buildingControl.updateValueAndValidity()"
               placeholder="{{'COMMON_ADDRESS_COMPLEX_BUILDING' | translate}}">
        <div class="form-control-feedback"
             *ngIf="hasLocalFieldError('building')">
        <span *ngIf="hasLocalFieldError('building', 'noWhiteSpace')">
          {{'COMMON_VALIDATION_MESSAGE_NO_WHITE_SPACE' | translate}}
        </span>
        </div>
      </div>
    </div>
    <div class="col-sm-{{12/otherFieldColumns}}">
      <div [ngClass]="[useMdTextAlign ? 'text-md-right' : '', hasLocalFieldError('stairway') ? 'has-danger' : '']">
        <label class="col-form-label">
          {{'COMMON_ADDRESS_COMPLEX_STAIRWAY' | translate}}
        </label>
      </div>
      <div class="form-group"
           [ngClass]="[hasLocalFieldError('stairway') ? 'has-danger' : '']">
        <input type="text" class="form-control"
               maxlength="{{UiConstants.maximumVarcharLength}}"
               formControlName="stairway"
               [ngClass]="{ 'form-control-danger': hasLocalFieldError('stairway') }"
               [readonly]="readonly"
               [(ngModel)]="m.complexValue.stairway"
               (ngModelChange)="stairwayControl.updateValueAndValidity()"
               placeholder="{{'COMMON_ADDRESS_COMPLEX_STAIRWAY' | translate}}">
        <div class="form-control-feedback"
             *ngIf="hasLocalFieldError('stairway')">
        <span *ngIf="hasLocalFieldError('stairway', 'noWhiteSpace')">
          {{'COMMON_VALIDATION_MESSAGE_NO_WHITE_SPACE' | translate}}
        </span>
        </div>
      </div>
    </div>
    <div class="col-sm-{{12/otherFieldColumns}}">
      <div [ngClass]="[useMdTextAlign ? 'text-md-right' : '', hasLocalFieldError('floor') ? 'has-danger' : '']">
        <label class="col-form-label">
          {{'COMMON_ADDRESS_COMPLEX_FLOOR' | translate}}
        </label>
      </div>
      <div class="form-group"
           [ngClass]="[hasLocalFieldError('floor') ? 'has-danger' : '']">
        <input type="text" class="form-control"
               maxlength="{{UiConstants.maximumVarcharLength}}"
               formControlName="floor"
               [ngClass]="{ 'form-control-danger': hasLocalFieldError('floor') }"
               [readonly]="readonly"
               [(ngModel)]="m.complexValue.floor"
               (ngModelChange)="floorControl.updateValueAndValidity()"
               placeholder="{{'COMMON_ADDRESS_COMPLEX_FLOOR' | translate}}">
        <div class="form-control-feedback"
             *ngIf="hasLocalFieldError('floor')">
        <span *ngIf="hasLocalFieldError('floor', 'noWhiteSpace')">
          {{'COMMON_VALIDATION_MESSAGE_NO_WHITE_SPACE' | translate}}
        </span>
        </div>
      </div>
    </div>
    <div class="col-sm-{{12/otherFieldColumns}}">
      <div [ngClass]="[useMdTextAlign ? 'text-md-right' : '', hasLocalFieldError('door') ? 'has-danger' : '']">
        <label class="col-form-label">
          {{'COMMON_ADDRESS_COMPLEX_DOOR' | translate}}
        </label>
      </div>
      <div class="form-group"
           [ngClass]="[hasLocalFieldError('door') ? 'has-danger' : '']">
        <input type="text" class="form-control"
               maxlength="{{UiConstants.maximumVarcharLength}}"
               formControlName="door"
               [ngClass]="{ 'form-control-danger': hasLocalFieldError('door') }"
               [readonly]="readonly"
               [(ngModel)]="m.complexValue.door"
               (ngModelChange)="doorControl.updateValueAndValidity()"
               placeholder="{{'COMMON_ADDRESS_COMPLEX_DOOR' | translate}}">
        <div class="form-control-feedback"
             *ngIf="hasLocalFieldError('door')">
        <span *ngIf="hasLocalFieldError('door', 'noWhiteSpace')">
          {{'COMMON_VALIDATION_MESSAGE_NO_WHITE_SPACE' | translate}}
        </span>
        </div>
      </div>
    </div>
  </div>

  <div class="row" *ngIf="model.type === PostalAddressModelType.SIMPLE">
    <div class="col-sm-3"></div>
    <div class="col-sm-9 switch-button d-flex align-items-center cursor-pointer" (click)="switchType(PostalAddressModelType.COMPLEX)">
      <i class="icomoon icomoon-postal-address-complex"></i>
      <span>{{'POSTAL_ADDRESS_SWITCH_TO_COMPLEX' | translate}}</span>
    </div>
  </div>

  <div class="row" *ngIf="model.type === PostalAddressModelType.COMPLEX && config.simpleAddressEnabled() && allowSimpleAddress">
    <div class="col-sm-3"></div>
    <div class="col-sm-9 switch-button d-flex align-items-center cursor-pointer" (click)="switchType(PostalAddressModelType.SIMPLE)">
      <i class="icomoon icomoon-postal-address-simple"></i>
      <span>{{'POSTAL_ADDRESS_SWITCH_TO_SIMPLE' | translate}}</span>
    </div>
  </div>

</div>
