import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-login-register-introduction',
  templateUrl: './login-register-introduction.component.html',
  styleUrls: ['./login-register-introduction.component.scss']
})
export class LoginRegisterIntroductionComponent implements OnInit, AfterViewInit {

  @ViewChild('wave')
  wave: ElementRef;

  @ViewChild('waveBackground')
  waveBackground: ElementRef;

  @ViewChild('registerLogo')
  registerLogo: ElementRef;

  @ViewChild('loginLogo')
  loginLogo: ElementRef;

  @ViewChild('bottom')
  bottom: ElementRef;

  @Input()
  titleStringKey: string = '';

  @Input()
  subtitleStringKey: string = '';

  @Input()
  registerUseCase: boolean = false;

  _toRegister?: boolean;

  @Input()
  set toRegister(value: boolean) {
    this._toRegister = value;
    if (this.wave) {
      if (value) {
        this.wave.nativeElement.classList.add('wave-out');
        this.waveBackground.nativeElement.classList.add('wave-out');
        this.bottom.nativeElement.classList.add('bottom-out');
      }
      else {
        this.wave.nativeElement.classList.remove('wave-out');
        this.waveBackground.nativeElement.classList.remove('wave-out');
        this.bottom.nativeElement.classList.remove('bottom-out');
      }
    }
  }

  @Input()
  set loginBackgroundSrc(loginBackgroundSrc: string | undefined) {
    if (loginBackgroundSrc) {
      this._loginBackgroundSrc = loginBackgroundSrc;
      this.updateBackgroundStyle();
    }
  }

  _loginBackgroundSrc: string = 'assets/img/appworks/login-default-background.jpg';

  backgroundStyle: any;

  constructor() {
    this.updateBackgroundStyle();
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    if (this._toRegister) {
      this.toRegister = this._toRegister;
    }
  }

  updateBackgroundStyle() {
    this.backgroundStyle = {'background-image': 'url(' + this._loginBackgroundSrc + ')'};
  }

  onNavigate() {
    window.open('https://www.applion.hu', '_blank');
  }

}
