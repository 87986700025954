<form class="form-horizontal bordered-row" (ngSubmit)="f.form.valid && onSubmit.emit(model)" #f="ngForm" novalidate>
  <!-- region Type -->
  <div class="form-group row" [ngClass]="{ 'has-danger': f.submitted && !typeInput.valid }" *ngIf="type === Type.USERGROUP_TASK  || type === Type.USERGROUP_CUSTOMER">
    <div class="col-md-4">
      <label class="mw-100 col-form-label form-control-label required-field-label">
        {{typeKey |translate}}
      </label>
    </div>
    <div class="col-md-8">
      <div class="mw-100">
        <angular2-multiselect
          [(ngModel)]="model.type"
          [ngClass]="{ 'form-control-danger': f.submitted && !typeInput.valid }"
          (onRemoteSearch)="loadTypes($event.target.value)"
          [data]="types"
          [settings]="typeDropdownSettings"
          name="type"
          #typeInput="ngModel"
          required>
        </angular2-multiselect>
        <div class="form-control-feedback" *ngIf="f.submitted && !typeInput.valid">
          {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
        </div>
      </div>
    </div>
  </div>
  <div class="form-group row" [ngClass]="{ 'has-danger': f.submitted && !typeInput.valid }" *ngIf="type === Type.TASK  || type === Type.CUSTOMER">
    <div class="col-md-4">
      <label class="mw-100 col-form-label form-control-label required-field-label">
        {{typeKey |translate}}
      </label>
    </div>
    <div class="col-md-8">
      <div class="mw-100">
        <angular2-multiselect
          [(ngModel)]="model.userGroups"
          [ngClass]="{ 'form-control-danger': f.submitted && !typeInput.valid }"
          (onRemoteSearch)="loadTypes($event.target.value)"
          [data]="types"
          [settings]="typeDropdownSettings"
          name="type"
          #typeInput="ngModel"
          required>
        </angular2-multiselect>
        <div class="form-control-feedback" *ngIf="f.submitted && !typeInput.valid">
          {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
        </div>
      </div>
    </div>
  </div>
  <!-- endregion Type -->
  <!-- region Privileges -->
  <div class="form-group row" [ngClass]="{ 'has-danger': f.submitted && !privilegesInput.valid }">
    <div class="col-md-4">
      <label class="mw-100 col-form-label form-control-label required-field-label">
        {{'PERMISSION_LIST_SEARCH_PANEL_HEADING_LIST' |translate}}
      </label>
    </div>
    <div class="col-md-8">
      <div class="mw-100">
        <angular2-multiselect
          [(ngModel)]="model.privileges"
          [ngClass]="{ 'form-control-danger': f.submitted && !privilegesInput.valid }"
          [data]="privileges"
          [settings]="privilegeDropdownSettings"
          name="privileges"
          id="privileges"
          #privilegesInput="ngModel"
          required>
        </angular2-multiselect>
        <div class="form-control-feedback" *ngIf="f.submitted && !privilegesInput.valid">
          {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
        </div>
      </div>
    </div>
  </div>
  <!-- endregion Privileges -->

  <div class="d-flex justify-content-end">
    <input type="button" class="btn btn-outline-primary mr-1" (click)="onCancel.emit()" value="{{'COMMON_BUTTON_CANCEL' | translate}}"/>
    <input type="submit" class="btn btn-primary" value="{{'COMMON_BUTTON_SAVE'|translate}}"/>
  </div>
</form>
