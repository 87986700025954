/* eslint-disable */
import { Dates, LocalDate, } from '../util/dates';
import { Observable, Observer } from 'rxjs';
import { Order, OrderType, Services, } from '../util/services';
import { MemStorage } from '../util/mem-storage';
import { Injectable } from '@angular/core';
import { MultiselectOptionItem, UiConstants } from '../../util/core-utils';
import { Order as OrderNamespace } from './order.service';
import { LoggedInUserStorage } from '../util/storages';

/* eslint-enable */

@Injectable()
export class OrderSearchService {

  constructor() {
  }

  getSearchData(request: OrderSearch.SearchDataGetRequest): Observable<OrderSearch.SearchDataResult> {
    const storage = this.createStorage();
    return Observable.create((observer: Observer<OrderSearch.SearchDataResult>) => {
      try {
        observer.next(storage.read(request));
      } catch (e) {
        observer.error(e);
      }
      observer.complete();
      return function () {
      };
    });
  }

  setSearchData(request: OrderSearch.SearchDataSetRequest): Observable<OrderSearch.SearchDataResult> {
    const storage = this.createStorage();
    return Observable.create((observer: Observer<OrderSearch.SearchDataResult>) => {
      try {
        observer.next(storage.save(request));
      } catch (e) {
        observer.error(e);
      }
      observer.complete();
      return function () {
      };
    });
  }

  resetSearchData(request: OrderSearch.SearchDataGetRequest): Observable<OrderSearch.SearchDataResult> {
    const storage = this.createStorage();
    return Observable.create((observer: Observer<OrderSearch.SearchDataResult>) => {
      try {
        observer.next(storage.reset(request));
      } catch (e) {
        observer.error(e);
      }
      observer.complete();
      return function () {
      };
    });
  }

  private createStorage(): OrderSearch.Storage {
    return new OrderSearch.Storage();
  }

}

export namespace OrderSearch {

  export interface SearchDataGetRequest {
  }

  export interface SearchDataSetRequest {
    searchData: SearchData;
  }

  export interface SearchDataResult {
    searchData: SearchData;
  }

  export interface SearchData {
    itemsPerPage: number;
    pageNumber: number;
    order: Order<OrderNamespace.OrderField>;
    id: string;
    externalId: string;
    logicState: MultiselectOptionItem<OrderNamespace.OrderLogicState>[];
    ownerCompanyName: string;
    shipmentTrackingNumber: string;
    shippingState: string;
    weightInGramFrom: string;
    weightInGramTo: string;
    cashOnDeliveryPriceFrom: string;
    cashOnDeliveryPriceTo: string;
    recipientName: string;
    recipientAddress: string;
    submitTimeFrom: LocalDate;
    submitTimeTo: LocalDate;
    returnTimeFrom: LocalDate;
    returnTimeTo: LocalDate;
    processDeadlineFrom: LocalDate;
    processDeadlineTo: LocalDate;
    returnReasonEmpty?: boolean;
    minimumIdenticalItemCount: string;
  }

  interface StoredSearchRawDataBase {
    version: number;
  }

  interface StoredSearchRawData extends StoredSearchRawDataBase {
    itemsPerPage: number;
    pageNumber: number;
    order: Order<OrderNamespace.OrderField>;
    id: string;
    externalId: string;
    logicState: MultiselectOptionItem<OrderNamespace.OrderLogicState>[];
    ownerCompanyName: string;
    shipmentTrackingNumber: string;
    shippingState: string;
    weightInGramFrom: string;
    weightInGramTo: string;
    cashOnDeliveryPriceFrom: string;
    cashOnDeliveryPriceTo: string;
    recipientName: string;
    recipientAddress: string;
    submitTimeFrom?: string;
    submitTimeTo?: string;
    returnTimeFrom?: string;
    returnTimeTo?: string;
    processDeadlineFrom?: string;
    processDeadlineTo?: string;
    returnReasonEmpty?: boolean;
    minimumIdenticalItemCount: string;
  }

  export class Storage {

    /**
     * Increment this version if you change the SearchData interface.
     */
    private static readonly VERSION: number = 8;

    private static readonly DEFAULT_SEARCH_DATA: SearchData = {
      itemsPerPage: UiConstants.pageNumbers[0],
      pageNumber: 1,
      order: {
        field: OrderNamespace.OrderField.ID,
        type: OrderType.DESC
      },
      id: '',
      externalId: '',
      logicState: [],
      ownerCompanyName: '',
      shipmentTrackingNumber: '',
      shippingState: '',
      weightInGramFrom: '',
      weightInGramTo: '',
      cashOnDeliveryPriceFrom: '',
      cashOnDeliveryPriceTo: '',
      recipientName: '',
      recipientAddress: '',
      submitTimeFrom: Dates.emptyLocalDate(),
      submitTimeTo: Dates.emptyLocalDate(),
      returnTimeFrom: Dates.emptyLocalDate(),
      returnTimeTo: Dates.emptyLocalDate(),
      processDeadlineFrom: Dates.emptyLocalDate(),
      processDeadlineTo: Dates.emptyLocalDate(),
      returnReasonEmpty: undefined,
      minimumIdenticalItemCount: ''
    };

    constructor() {
    }

    public read(request: SearchDataGetRequest): SearchDataResult {
      const key = this.generateKey();
      let rawBase: StoredSearchRawDataBase;
      try {
        const json = MemStorage.getItem(key);
        if (!json) {
          return this.createDefaultResult();
        }
        rawBase = JSON.parse(json);
      } catch (error) {
        return this.createDefaultResult();
      }
      if (Storage.VERSION === rawBase.version) {
        const raw: StoredSearchRawData = <StoredSearchRawData>rawBase;
        return this.fromRaw(raw);
      }
      return this.createDefaultResult();
    }

    public reset(request: SearchDataGetRequest): SearchDataResult {
      return this.save({
        searchData: Storage.DEFAULT_SEARCH_DATA,
      });
    }

    public save(request: SearchDataSetRequest): SearchDataResult {
      const key = this.generateKey();
      const raw = this.toRaw(request.searchData);
      const json = JSON.stringify(raw);
      MemStorage.setItem(key, json);
      return this.read(request);
    }

    private generateKey(): string {
      return 'UserId(' + LoggedInUserStorage.getInstance().getUserId() + ')-Search-Order';
    }

    private createDefaultResult(): SearchDataResult {
      return {
        searchData: Storage.DEFAULT_SEARCH_DATA,
      };
    }

    private fromRaw(data: StoredSearchRawData): SearchDataResult {
      return {
        searchData: {
          itemsPerPage: data.itemsPerPage,
          pageNumber: data.pageNumber,
          order: data.order,
          id: data.id,
          externalId: data.externalId,
          logicState: data.logicState,
          ownerCompanyName: data.ownerCompanyName,
          shipmentTrackingNumber: data.shipmentTrackingNumber,
          shippingState: data.shippingState,
          weightInGramFrom: data.weightInGramFrom,
          weightInGramTo: data.weightInGramTo,
          cashOnDeliveryPriceFrom: data.cashOnDeliveryPriceFrom,
          cashOnDeliveryPriceTo: data.cashOnDeliveryPriceTo,
          recipientName: data.recipientName,
          recipientAddress: data.recipientAddress,
          submitTimeFrom: Services.toLocalDate(data.submitTimeFrom),
          submitTimeTo: Services.toLocalDate(data.submitTimeTo),
          returnTimeFrom: Services.toLocalDate(data.returnTimeFrom),
          returnTimeTo: Services.toLocalDate(data.returnTimeTo),
          processDeadlineFrom: Services.toLocalDate(data.returnTimeFrom),
          processDeadlineTo: Services.toLocalDate(data.returnTimeTo),
          returnReasonEmpty: data.returnReasonEmpty,
          minimumIdenticalItemCount: data.minimumIdenticalItemCount
        }
      };
    }

    private toRaw(data: SearchData): StoredSearchRawData {
      return {
        version: Storage.VERSION,
        itemsPerPage: data.itemsPerPage,
        pageNumber: data.pageNumber,
        order: data.order,
        id: data.id,
        externalId: data.externalId,
        logicState: data.logicState,
        ownerCompanyName: data.ownerCompanyName,
        shipmentTrackingNumber: data.shipmentTrackingNumber,
        shippingState: data.shippingState,
        weightInGramFrom: data.weightInGramFrom,
        weightInGramTo: data.weightInGramTo,
        cashOnDeliveryPriceFrom: data.cashOnDeliveryPriceFrom,
        cashOnDeliveryPriceTo: data.cashOnDeliveryPriceTo,
        recipientName: data.recipientName,
        recipientAddress: data.recipientAddress,
        submitTimeFrom: Services.localDateToString(data.submitTimeFrom),
        submitTimeTo: Services.localDateToString(data.submitTimeTo),
        returnTimeFrom: Services.localDateToString(data.returnTimeFrom),
        returnTimeTo: Services.localDateToString(data.returnTimeTo),
        processDeadlineFrom: Services.localDateToString(data.returnTimeFrom),
        processDeadlineTo: Services.localDateToString(data.returnTimeTo),
        returnReasonEmpty: data.returnReasonEmpty,
        minimumIdenticalItemCount: data.minimumIdenticalItemCount
      }
        ;
    }

  }

}
