/* eslint-disable */
import { Injectable } from '@angular/core';
import { Observable, Observer } from 'rxjs';
import { Order, PagingRequest, Services } from '../util/services';
import { Map, Set } from 'immutable';
import { OffsetDateTime } from '../util/dates';
import { TokenResource, TokenResourceService } from './token-resource.service';
import { CustomerRecordResource } from '../customer/customer-record-resource.service';
import { EmptyMessage } from '../util/messages';
import { UserItem, UserItemResource } from '../user.service';
import { HistoryLog } from '../history-log/history-log.service';
import TokenType = Token.TokenType;

/* eslint-enable */

@Injectable()
export class TokenService implements Token.Service {

  constructor(private resourceService: TokenResourceService) {
  }

  query(request: Token.QueryRequest): Observable<Token.Token[]> {
    return Observable.create((observer: Observer<Token.Token[]>) => {
      const resourceRequest: TokenResource.QueryRequest = {
        user_profile_person_name: request.userProfilePersonName,
        mobile_application_name: request.mobileApplicationName,
        mobile_application_display_name: request.displayName,
        type: request.type,
        q: request.queryText,
        order: Services.createOrderFieldParameter(Keys.toOrderFieldKey, request.orders),
        page_number: request.paging ? request.paging.pageNumber : undefined,
        number_of_items: request.paging ? request.paging.numberOfItems : undefined,
      };
      return this.resourceService.query(resourceRequest).subscribe(
        (result: TokenResource.Token[]) => {
          observer.next(this.toPublicList(result));
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  deleteWithTokenId(request: Token.DeleteTokenIdRequest): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      const resourceRequest: TokenResource.DeleteTokenIdRequest = {
        access_token_id: request.accessTokenId
      };
      return this.resourceService.deleteWithTokenId(resourceRequest).subscribe(
        (result: EmptyMessage) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  deleteWithUserMobile(request: Token.DeleteUserMobileRequest): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      const resourceRequest: TokenResource.DeleteUserMobileRequest = {
        user_profile_id: request.userProfileId,
        mobile_application_id: request.mobileApplicationId,
      };
      return this.resourceService.deleteWithUserMobile(resourceRequest).subscribe(
        (result: EmptyMessage) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  private toPublicList(resourceList: TokenResource.Token[]): Token.Token[] {
    return resourceList.map((r) => this.toPublic(r));
  }

  private toPublic(r: TokenResource.Token): Token.Token {
    return {
      accessTokenId: r.access_token_id,
      userProfile: this.toPublicUser(r.user_profile),
      mobileApplication: this.toPublicMobileApplication(r.mobile_application),
      createDate: Services.toOffsetDateTime(r.create_date),
      accessDate: Services.toOffsetDateTime(r.access_date),
      expiry: Services.toOffsetDateTime(r.expiry),
      type: <TokenType>r.type
    };
  }

  private toPublicUser(r: UserItemResource): UserItem {
    return {
      id: r.id,
      personName: r.person_name,
    };
  }

  private toPublicMobileApplication(r: CustomerRecordResource.MobileApplicationItem | undefined):
    HistoryLog.MobileApplicationItem | undefined {
    if (r) {
      return {
        id: r.id,
        applicationId: r.application_id,
        name: r.name
      };
    }
    return undefined;
  }

}

export namespace Token {

  import MobileApplicationItem = HistoryLog.MobileApplicationItem;

  export interface Service {

    // <editor-fold desc="CRUD">

    query(request: QueryRequest): Observable<Token.Token[]>;


    // </editor-fold>

  }

  export interface Token {
    accessTokenId?: number;
    userProfile: UserItem;
    mobileApplication?: MobileApplicationItem;
    createDate: OffsetDateTime;
    accessDate?: OffsetDateTime;
    expiry: OffsetDateTime;
    type: TokenType;
  }

  export interface QueryRequest {
    userProfilePersonName?: string;
    mobileApplicationName?: string;
    displayName?: string;
    queryText?: string;
    orders?: Set<Order<OrderField>>;
    paging?: PagingRequest;
    type?: TokenType;
  }

  export interface DeleteTokenIdRequest {
    accessTokenId: number;
  }

  export interface DeleteUserMobileRequest {
    userProfileId: number;
    mobileApplicationId: number;
  }

  export type TokenType =
    'ACCESS_TOKEN' |
    'MOBILE_USER_TOKEN' |
    'DEFAULT';

  export enum OrderField {
    USER_PROFILE_PERSON_NAME
  }

  export enum ValidatedField {
    UNKNOWN,


  }

}


class Keys {
  private static readonly USER_PROFILE_PERSON_NAME = 'user_profile_person_name';

  private static readonly orderFieldKeyMap: Map<Token.OrderField, string> = Map.of(
    Token.OrderField.USER_PROFILE_PERSON_NAME, Keys.USER_PROFILE_PERSON_NAME,
  );

  private static readonly keyValidatedFieldMap: Map<string, Token.ValidatedField> = Map.of(

  );

  public static toOrderFieldKey(field: Token.OrderField): string {
    return Keys.orderFieldKeyMap.get(field)!;
  }

  public static toValidatedField(fieldKey: string): Token.ValidatedField {
    return Keys.keyValidatedFieldMap.get(fieldKey, Token.ValidatedField.UNKNOWN);
  }

}
