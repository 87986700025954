/* eslint-disable */
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { TransportTask, TransportTaskService } from '../../../lib/transport/transport-task/transport-task.service';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Address, AddressResource } from '../../../lib/address';
import { Duration } from 'moment';
import { ErrorMessageService } from '../../../lib/error-message-parser.service';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Models } from '../../../util/model-utils';
import { AppNgbTimeStruct, NgbDatePickerParserFormatter } from '../../../util/ngb-datepicker';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { ForwardingNgFormRef, LocalFormGroupValidationErrors } from '../../../lib/util/services';
import { AppValidators } from '../../../util/app-validators';
import { SelectUtils, UiConstants } from '../../../util/core-utils';
import { Logger, LoggerFactory } from '../../../util/logger-factory';
import { TranslateService } from '@ngx-translate/core';
import { StringKey } from '../../../app.string-keys';
import { Dates, OffsetDateTime } from '../../../lib/util/dates';
import { Transport } from '../../../lib/transport/transport.service';
import { CompanyLocationService } from '../../../lib/company-location/company-location.service';
import { RightModel } from '../../../app.rights';
import { RightResolver, RightService } from '../../../lib/right.service';
import { ShippingDemand, ShippingDemandService } from '../../../lib/shipping-demand/shipping-demand.service';
import { ComponentStateResolver } from '../../../util/component-state/component-state-resolver';
import { StateName } from '../../../app.state-names';
import { Transition, UIRouter } from '@uirouter/angular';
import { ShippingDemandAddressModel } from '../../shipping-demand/shipping-demand-edit/shipping-demand-address/shipping-demand-address.model';
/* eslint-enable */

/* eslint-enable */

@Component({
  selector: 'app-transport-task-dialog-container',
  templateUrl: './transport-task-dialog-container.component.html',
  styleUrls: ['./transport-task-dialog-container.component.scss']
})
export class TransportTaskDialogContainerComponent implements OnInit {

  UiConstants = UiConstants;
  Address = Address;
  SelectUtils = SelectUtils;

  private readonly logger: Logger;

  transportTaskCreateVisible: boolean = false;
  transportTaskDetailVisible: boolean = false;
  transportTaskTimePlanVisible: boolean = false;
  transportOptimizeWaypointsVisible: boolean = false;

  detailTask?: TransportTask.TransportTask = undefined;
  placeEdit?: Address.Place;

  TimePlanModel: TransportTaskTimePlanModel;
  editableFieldModel: ShippingDemand.EditableFields;

  createModel: TransportTaskCreateModel;
  optimizeWaypointsModel: WaypointOptimizeModel;

  @Input()
  postalAddressFormat: string;

  @Input()
  transportId: number;

  @Input()
  transportState: Transport.TransportState;

  rightModel: RightModel = RightModel.empty();

  @ViewChild('transportTaskDetail', { static: true }) transportTaskDetail: ModalDirective;
  @ViewChild('transportTaskCreate', { static: true }) transportTaskCreate: ModalDirective;
  @ViewChild('transportTaskTimePlan', { static: true }) transportTaskTimePlan: ModalDirective;
  @ViewChild('optimizeWaypoints', { static: true }) transportOptimizeWaypoints: ModalDirective;

  @Output()
  reload: EventEmitter<TransportTask.WaypointOptimizationResponse> =
    new EventEmitter<TransportTask.WaypointOptimizationResponse>();

  TimePlanForm: FormGroup;
  @ViewChild('TimePlanF') TimePlanF?: NgForm;
  private TimePlanFormGroupValidationErrors: LocalFormGroupValidationErrors;
  createForm: FormGroup;
  @ViewChild('createF') createF?: NgForm;
  private createPlanFormGroupValidationErrors: LocalFormGroupValidationErrors;

  componentState: ComponentStateResolver;

  constructor(private uiRouter: UIRouter,
              private transition: Transition,
              private transportTaskService: TransportTaskService,
              private translateService: TranslateService,
              private errorMessageService: ErrorMessageService,
              private datePickerParserFormatter: NgbDatePickerParserFormatter,
              private rightService: RightService,
              private companyLocationService: CompanyLocationService,
              private shippingDemandService: ShippingDemandService,
              private formBuilder: FormBuilder) {
    // The dialog appears on the detail screen, however, acts as a create dialog, therefore the transport detail state
    // will be the create state for the component.
    this.componentState = new ComponentStateResolver(uiRouter, transition,
      'id',
      {stateName: StateName.TRANSPORT_DETAIL, stateHeaderKey: 'TRANSPORT_DETAIL'});
    this.TimePlanModel = new TransportTaskTimePlanModel(datePickerParserFormatter);
    this.createModel
      = new TransportTaskCreateModel(
      0,
      0,
      companyLocationService);
    this.logger = LoggerFactory.createLogger('TransportTaskDialogContainerComponent');
    this.TimePlanForm = this.createPlanFormGroup(this.formBuilder);
    this.TimePlanFormGroupValidationErrors = LocalFormGroupValidationErrors.ofForm(this.createForwardingHtmlForm(), this.TimePlanForm);
    this.createForm = this.createCreateFormGroup(this.formBuilder);
    this.createPlanFormGroupValidationErrors = LocalFormGroupValidationErrors.ofForm(this.createForwardingHtmlForm(), this.createForm);
    this.placeEdit = undefined;
  }

  ngOnInit() {
    this.loadRightModels();
    this.loadEditableFields();
  }

  private loadRightModels() {
    this.rightService.getRightResolver().subscribe(
      (resolver: RightResolver) => {
        this.rightModel = RightModel.of(resolver);
      }
    );
  }

  private loadEditableFields() {
    this.shippingDemandService.getEditableFields().subscribe((editableFields: ShippingDemand.EditableFields) => {
      this.editableFieldModel = editableFields;
    })
  }

  public openTransportTaskDetailDialog(task: TransportTask.TransportTask) {
    this.logger.debug('taskdetail');
    this.detailTask = task;
    this.placeEdit = {
      id: this.detailTask.shippingPlace.placeId,
      name: this.detailTask.shippingPlace.name,
      postalAddress: this.detailTask.shippingPlace.postalAddress,
      coordinate: this.detailTask.shippingPlace.coordinate,
      geocodeStatus: this.detailTask.shippingPlace.geocodeStatus
    };
    this.transportTaskDetailVisible = true;
    this.transportTaskDetail.show();
  }

  public timePlan(task: TransportTask.TransportTask) {
    this.TimePlanModel.clear();
    this.TimePlanModel.task = task;
    this.openTransportTaskTimePlan();
  }

  private openTransportTaskTimePlan() {
    const arrival = this.TimePlanModel.task!.timetable.currentETA.isValid()
      ? this.TimePlanModel.task!.timetable.currentETA
      : this.TimePlanModel.task!.timetable.plannedETA;
    const departure = this.TimePlanModel.task!.timetable.currentETD.isValid()
      ? this.TimePlanModel.task!.timetable.currentETD
      : this.TimePlanModel.task!.timetable.plannedETD;
    const parsedArrival = this.datePickerParserFormatter.fromOffsetDateTime(arrival);
    const replanDate = this.getPlanDate();
    this.TimePlanModel.newArrivalDate = parsedArrival ? parsedArrival : replanDate;
    this.TimePlanModel.newArrivalTime = parsedArrival ? parsedArrival : replanDate;

    const parsedDeparture = this.datePickerParserFormatter.fromOffsetDateTime(departure);
    this.TimePlanModel.newDepartureDate = parsedDeparture ? parsedDeparture : replanDate;
    this.TimePlanModel.newDepartureTime = parsedDeparture ? parsedDeparture : replanDate;

    this.transportTaskTimePlanVisible = true;
    this.transportTaskTimePlan.show();
    this.TimePlanForm.reset();
  }

  private getPlanDate(): NgbDateStruct & AppNgbTimeStruct {
    const now = Dates.now();
    const newDate = Dates.createOffsetDateTime({
      year: now.getYear(),
      month: now.getMonth(),
      day: now.getDay(),
      hour: now.getHour(),
      minute: now.getMinute() + 1,
      second: 0,
      milliseconds: 0
    });
    const date = this.datePickerParserFormatter.fromOffsetDateTime(newDate)!;
    return date;
  }

  public openTransportTaskCreateDialog(indexPair: IndexPair) {
    this.createModel = new TransportTaskCreateModel(indexPair.minIndex, indexPair.maxIndex, this.companyLocationService);

    this.transportTaskCreateVisible = true;
    this.transportTaskCreate.show();
  }

  createTransportTask() {
    if (this.createModel.addressModel.isValid()) {
      this.transportTaskService.create({
        transportId: this.transportId,
        index: this.createModel.selectedIndex,
        shippingPlace: this.createModel.addressModel.toSourceDestination()
      }).subscribe(result => {
        this.closeTransportTaskCreateDialog();
        this.reload.emit();
      }, (error) => {
        this.reload.emit();
      });
    }
  }

  public openTransportOptimizeWaypointsDialog(indexPair: IndexPair) {
    if (indexPair.maxIndex - indexPair.minIndex < 3) {
      throw Error('There must be at least 4 points');
    }
    this.optimizeWaypointsModel = new WaypointOptimizeModel(indexPair);
    this.transportOptimizeWaypointsVisible = true;
    this.transportOptimizeWaypoints.show();
  }

  optimize() {
    this.transportTaskService.optimizeWaypoints({
      transportId: this.transportId,
      fromIndex: this.optimizeWaypointsModel.fromIndex,
      toIndex: this.optimizeWaypointsModel.toIndex
    }).subscribe(result => {
      this.closeTransportOptimizeWaypointsDialog();
      this.reload.emit(result);
    }, (error) => {
      this.reload.emit();
    });
  }

  replanTime() {
    this.refreshForm();
    if (this.TimePlanForm.invalid) {
      return;
    }
    if (!this.TimePlanModel.task) {
      return;
    }
    this.transportTaskService.updateTimetable({
      plannedETA: this.datePickerParserFormatter.toOffsetDateTime(this.TimePlanModel.newArrivalDate, this.TimePlanModel.newArrivalTime),
      plannedETD:
        this.datePickerParserFormatter.toOffsetDateTime(this.TimePlanModel.newDepartureDate, this.TimePlanModel.newDepartureTime),
      transportId: this.transportId,
      id: this.TimePlanModel.task.id,
    }).subscribe(result => {
      this.closeTransportTaskTimePlanDialog();
      this.reload.emit();
    }, (error) => {
      this.reload.emit();
    });
  }

  refreshForm() {
    this.TimePlanForm.controls['newArrivalDate'].markAsTouched();
    this.TimePlanForm.controls['newArrivalTime'].markAsTouched();
    this.TimePlanForm.controls['newDepartureDate'].markAsTouched();
    this.TimePlanForm.controls['newDepartureTime'].markAsTouched();
    this.TimePlanForm.controls['newArrivalDate'].updateValueAndValidity();
    this.TimePlanForm.controls['newArrivalTime'].updateValueAndValidity();
    this.TimePlanForm.controls['newDepartureDate'].updateValueAndValidity();
    this.TimePlanForm.controls['newDepartureTime'].updateValueAndValidity();
    this.TimePlanForm.updateValueAndValidity();
  }

  closeTransportOptimizeWaypointsDialog() {
    this.transportOptimizeWaypointsVisible = false;
    this.transportOptimizeWaypoints.hide();
  }

  closeTransportTaskCreateDialog() {
    this.transportTaskCreateVisible = false;
    this.transportTaskCreate.hide();
  }

  closeTransportTaskTimePlanDialog() {
    this.TimePlanModel.clear();
    this.transportTaskTimePlanVisible = false;
    this.transportTaskTimePlan.hide();
  }

  closeTransportTaskDetailDialog() {
    this.detailTask = undefined;
    this.transportTaskDetailVisible = false;
    this.transportTaskDetail.hide();
  }

  getStateStringKey(state: TransportTask.TransportTaskState): string {
    return TransportTask.transportTaskStates.get(state).stringKey;
  }

  getPlannedStayDuration(timetable: TransportTask.Timetable): Duration | undefined {
    return timetable.plannedETD.diffDuration(timetable.plannedETA);
  }

  getCurrentStayDuration(timetable: TransportTask.Timetable): Duration | undefined {
    return timetable.currentETD.diffDuration(timetable.currentETA);
  }

  getRealStayDuration(timetable: TransportTask.Timetable): Duration | undefined {
    return timetable.realDepartureTime.diffDuration(timetable.realArrivalTime);
  }

  private createCreateFormGroup(fb: FormBuilder): FormGroup {
    return fb.group({});
  }

  private createPlanFormGroup(fb: FormBuilder): FormGroup {
    return fb.group({
      newArrivalDate: fb.control({value: this.TimePlanModel.newArrivalDate},
        [
          Validators.required,
          AppValidators.validateLocalDate,
          AppValidators.validateNgbDateTime({
            datePickerParserFormatter: this.datePickerParserFormatter,
            dateValue: () => this.TimePlanModel.newArrivalDate,
            timeValue: () => this.TimePlanModel.newArrivalTime
          }),
          AppValidators.validateMaxNgbDateTime({
            value: () => this.TimePlanModel.newArrival,
            maxValue: () => this.TimePlanModel.newDeparture,
            datePickerParserFormatter: this.datePickerParserFormatter
          }),
          AppValidators.validateMinNgbDateTime({
            value: () => this.TimePlanModel.newArrival,
            minValue: () => this.minStartValue,
            datePickerParserFormatter: this.datePickerParserFormatter
          })
        ]),

      newArrivalTime: fb.control({value: this.TimePlanModel.newArrivalTime},
        [
          Validators.required,
          AppValidators.validateNgbDateTime({
            datePickerParserFormatter: this.datePickerParserFormatter,
            dateValue: () => this.TimePlanModel.newArrivalDate,
            timeValue: () => this.TimePlanModel.newArrivalTime
          })
        ]),

      newDepartureDate: fb.control({value: this.TimePlanModel.newDepartureDate},
        [
          Validators.required,
          AppValidators.validateLocalDate,
          AppValidators.validateNgbDateTime({
            datePickerParserFormatter: this.datePickerParserFormatter,
            dateValue: () => this.TimePlanModel.newDepartureDate,
            timeValue: () => this.TimePlanModel.newDepartureTime
          }),
          AppValidators.validateMinNgbDateTime({
            value: () => this.TimePlanModel.newDeparture,
            minValue: () => this.TimePlanModel.newArrival,
            datePickerParserFormatter: this.datePickerParserFormatter
          })
        ]),

      newDepartureTime: fb.control({value: this.TimePlanModel.newDepartureTime},
        [
          Validators.required,
          AppValidators.validateNgbDateTime({
            datePickerParserFormatter: this.datePickerParserFormatter,
            dateValue: () => this.TimePlanModel.newDepartureDate,
            timeValue: () => this.TimePlanModel.newDepartureTime
          })
        ]),

    });
  }

  private createForwardingHtmlForm() {
    return new ForwardingNgFormRef({
      formFn: () => {
        return this.TimePlanF;
      }
    });
  }

  hasLocalFieldError(formControlName?: string, errorCode?: string): boolean {
    this.TimePlanForm.updateValueAndValidity();
    return this.TimePlanFormGroupValidationErrors.hasFieldError(formControlName, errorCode);
  }

  get minStartValue(): NgbDateStruct & AppNgbTimeStruct | null {
    return this.datePickerParserFormatter.fromOffsetDateTime(Dates.now());
  }

  getGeocodeStatus(status: AddressResource.GeocodeStatus) {
    return AddressResource.geocodeStatuses.find(s => s.status === status)!.stringKey;
  }
}

export class TransportTaskCreateModel {
  selectedIndex: number;
  addressModel: ShippingDemandAddressModel;

  readonly availableIndexes: number[] = [];

  constructor(
    private currentMinIndex: number,
    private currentMaxIndex: number,
    private companyLocationService: CompanyLocationService) {
    for (let i = currentMinIndex; i <= this.currentMaxIndex + 1; i++) {
      this.availableIndexes.push(i);
    }
    this.selectedIndex = this.availableIndexes[this.availableIndexes.length - 1];
    this.addressModel = new ShippingDemandAddressModel('DESTINATION', companyLocationService);
    this.addressModel.addressCategory = 'DESTINATION';
  }

}

class WaypointOptimizeModel {
  private _fromIndex: number;
  private _toIndex: number;
  private readonly _availableIndexes: number[] = [];

  constructor(indexPair: IndexPair) {
    for (let i = indexPair.minIndex; i <= indexPair.maxIndex; i++) {
      this._availableIndexes.push(i);
    }
    this._fromIndex = this._availableIndexes[0];
    this._toIndex = this._availableIndexes[this._availableIndexes.length - 1];
  }

  get availableFromIndexes() {
    return this._availableIndexes.slice(0, this._toIndex - 2);
  }

  get availableToIndexes() {
    return this._availableIndexes.slice(this._fromIndex + 3, this._availableIndexes.length);
  }

  get toIndex(): number {
    return this._toIndex;
  }

  set toIndex(value: number) {
    this._toIndex = value;
  }

  get fromIndex(): number {
    return this._fromIndex;
  }

  set fromIndex(value: number) {
    this._fromIndex = value;
  }

}

export class TransportTaskTimePlanModel {
  task?: TransportTask.TransportTask;

  private _newArrivalDate: NgbDateStruct | null = null;
  private _newArrivalTime: AppNgbTimeStruct = Models.zeroNgbTime();
  private _newDepartureDate: NgbDateStruct | null = null;
  private _newDepartureTime: AppNgbTimeStruct = Models.zeroNgbTime();

  constructor(private datePickerParserFormatter: NgbDatePickerParserFormatter) {
  }

  clear() {
    this._newArrivalDate = null;
    this._newDepartureDate = null;
    this._newArrivalTime = Models.zeroNgbTime();
    this._newDepartureTime = Models.zeroNgbTime();
    this.task = undefined;
  }

  get newArrival(): NgbDateStruct & AppNgbTimeStruct | null {
    return Models.mergeNgbDateAndTime(this._newArrivalDate, this._newArrivalTime);
  }

  get newDeparture(): NgbDateStruct & AppNgbTimeStruct | null {
    return Models.mergeNgbDateAndTime(this._newDepartureDate, this._newDepartureTime);
  }

  get newArrivalDate(): NgbDateStruct | null {
    return this._newArrivalDate;
  }

  get newArrivalTime(): AppNgbTimeStruct {
    return this._newArrivalTime;
  }

  get newDepartureDate(): NgbDateStruct | null {
    return this._newDepartureDate;
  }

  get newDepartureTime(): AppNgbTimeStruct {
    return this._newDepartureTime;
  }

  set newArrivalDate(value) {
    this.setDepartureWithArrival(value, undefined);
    this._newArrivalDate = value;
  }

  set newArrivalTime(value) {
    this.setDepartureWithArrival(undefined, value);
    this._newArrivalTime = value;
  }

  set newDepartureDate(value) {
    this._newDepartureDate = value;
  }

  set newDepartureTime(value) {
    this._newDepartureTime = value;
  }

  private setDepartureWithArrival(newArrivalDate?: NgbDateStruct | null, newArrivalTime?: AppNgbTimeStruct) {
    const newDate = this.datePickerParserFormatter.toOffsetDateTime(newArrivalDate === undefined ? this._newArrivalDate : newArrivalDate,
      newArrivalTime ? newArrivalTime : this._newArrivalTime);
    const oldDate = this.datePickerParserFormatter.toOffsetDateTime(this._newArrivalDate, this._newArrivalTime);
    const diffMins = this.calculateDiffMins(newDate, oldDate);
    const depDateTime = this.datePickerParserFormatter.toOffsetDateTime(this._newDepartureDate, this._newDepartureTime);
    if (diffMins && depDateTime.isValid()) {
      const newDepDate = depDateTime.plusSeconds(diffMins * 60);
      this._newDepartureDate = this.datePickerParserFormatter.fromOffsetDateTime(newDepDate);
      this._newDepartureTime = this.datePickerParserFormatter.fromOffsetDateTime(newDepDate)!;
    }

  }

  private calculateDiffMins(date1: OffsetDateTime, date2: OffsetDateTime): number | undefined {
    const newDate = Dates.createOffsetDateTime({
      year: date1.getYear(),
      month: date1.getMonth(),
      day: date1.getDay(),
      hour: date1.getHour(),
      minute: date1.getMinute(),
      second: 0,
      milliseconds: 0
    });
    const oldDate = Dates.createOffsetDateTime({
      year: date2.getYear(),
      month: date2.getMonth(),
      day: date2.getDay(),
      hour: date2.getHour(),
      minute: date2.getMinute(),
      second: 0,
      milliseconds: 0
    });
    return newDate.diffMinutes(oldDate);
  }
}

export interface IndexPair {
  minIndex: number;
  maxIndex: number;
}
