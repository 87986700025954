/* eslint-disable */
import { Injectable } from '@angular/core';
import { StockItemCategoryResource, StockItemCategoryResourceService } from './stock-item-category-resource.service';
import { Observable, Observer } from 'rxjs';
import { FieldValidationError, Order, PagingRequest, QueryResult, ResourceQueryResult, Services } from '../util/services';
import { EmptyMessage, IdentityMessage } from '../util/messages';
import { List, Map, Set } from 'immutable';
import { OffsetDateTime } from '../util/dates';
import { ObservableErrorResourceParser } from '../util/errors';
import { DownloadedFile, NamedBlobFile, NamedBlobFileDecorator } from '../util/downloaded-files';
import { map } from 'rxjs/operators';

/* eslint-enable */

@Injectable()
export class StockItemCategoryService implements StockItemCategory.Service {

  constructor(private resourceService: StockItemCategoryResourceService) {
  }

  query(request: StockItemCategory.QueryRequest): Observable<QueryResult<StockItemCategory.StockItemCategory>> {
    return Observable.create((observer: Observer<QueryResult<StockItemCategory.StockItemCategory>>) => {
      return this.resourceService.query(this.toResourceQueryRequest(request)).subscribe(
        (result: ResourceQueryResult<StockItemCategoryResource.StockItemCategory>) => {
          observer.next({
            items: this.toPublicList(result.items),
            pagingResult: result.pagingResult
          });
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  get(request: StockItemCategory.GetRequest): Observable<StockItemCategory.StockItemCategory> {
    return Observable.create((observer: Observer<StockItemCategory.StockItemCategory>) => {
      const resourceRequest: StockItemCategoryResource.GetRequest = {
        id: request.id
      };
      return this.resourceService.get(resourceRequest).subscribe(
        (result: StockItemCategoryResource.StockItemCategory) => {
          observer.next(this.toPublic(result));
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  create(request: StockItemCategory.CreateRequest): Observable<IdentityMessage> {
    return Observable.create((observer: Observer<IdentityMessage>) => {
      const resourceRequest: StockItemCategoryResource.CreateRequest = {
        external_id: request.externalId,
        parent_id: request.parentId,
        name: request.name,
      };
      return this.resourceService.create(resourceRequest).subscribe(
        (result: IdentityMessage) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  update(request: StockItemCategory.UpdateRequest): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      const resourceRequest: StockItemCategoryResource.UpdateRequest = {
        id: request.id,
        external_id: request.externalId,
        parent_id: request.parentId,
        name: request.name,
      };
      return this.resourceService.update(resourceRequest).subscribe(
        (result: EmptyMessage) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  delete(request: IdentityMessage): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      return this.resourceService.delete(request).subscribe(
        (result: EmptyMessage) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  exportXls(request: StockItemCategory.QueryRequest): Observable<NamedBlobFile> {
    return this.resourceService.exportXls(
      this.toResourceQueryRequest(request)
    ).pipe(map((file: DownloadedFile) => {
      return new NamedBlobFileDecorator(file, 'stock-item-categories.xlsx');
    }));
  }

  exportXlsTemplate(): Observable<NamedBlobFile> {
    return this.resourceService.exportXlsTemplate(
    ).pipe(map((file: DownloadedFile) => {
      return new NamedBlobFileDecorator(file, 'stock-item-categories-template.xlsx');
    }));
  }


  private translateError(error: any): any {
    const res = ObservableErrorResourceParser.parseError(error);
    const fieldErrors = ObservableErrorResourceParser.extractFieldErrors(res);
    const fieldErrorMap = ObservableErrorResourceParser.toFieldErrorMap(Keys.toValidatedField, fieldErrors);
    if (!fieldErrorMap.isEmpty()) {
      return FieldValidationError.of(fieldErrorMap);
    }
    return error;
  }

  private toPublicList(resourceList: StockItemCategoryResource.StockItemCategory[]): List<StockItemCategory.StockItemCategory> {
    return List.of(...resourceList.map((r) => this.toPublic(r)));
  }

  private toPublic(r: StockItemCategoryResource.StockItemCategory): StockItemCategory.StockItemCategory {
    return {
      id: r.id,
      externalId: r.external_id,
      parentId: r.parent_id,
      name: r.name,
      creationTime: Services.toOffsetDateTime(r.creation_time),
      disabled: r.disabled
    };
  }

  private toResourceQueryRequest(request: StockItemCategory.QueryRequest): StockItemCategoryResource.QueryRequest {
    return {
      id: Services.createIdParameter(request.id),
      enabled_category_ids: Services.createListParameter(request.enabledCategoryIds),
      external_id: request.externalId,
      name: request.name,
      parent_id: request.mainCategory
        ? ''
        : request.parentId
          ? request.parentId + ''
          : undefined,
      q: request.queryText,
      order: Services.createOrderFieldParameter(Keys.toOrderFieldKey, request.orders),
      page_number: request.paging ? request.paging.pageNumber : undefined,
      number_of_items: request.paging ? request.paging.numberOfItems : undefined,
      no_progress_bar: request.noProgressBar
    };
  }
}


export namespace StockItemCategory {

  export interface Service {

    // <editor-fold desc="CRUD">

    query(request: QueryRequest): Observable<QueryResult<StockItemCategory>>;

    get(request: GetRequest): Observable<EmptyMessage>;

    create(request: CreateRequest): Observable<EmptyMessage>;

    update(request: UpdateRequest): Observable<EmptyMessage>;

    // </editor-fold>

  }

  export interface StockItemCategory {
    id: number;
    externalId: string;
    parentId?: number;
    name: string;
    creationTime: OffsetDateTime;
    disabled: boolean;
  }

  export interface QueryRequest {
    id?: Set<number>;
    externalId?: string;
    name?: string;
    parentId?: number;
    enabledCategoryIds?: Set<number>;
    mainCategory?: boolean;
    queryText?: string;
    orders?: Set<Order<OrderField>>;
    paging?: PagingRequest;
    disabled?: boolean;
    type?: Set<string>;
    noProgressBar?: boolean;
  }

  export interface GetRequest {
    id: number;
  }

  export interface CreateRequest {
    externalId?: string;
    parentId?: number;
    name: string;
  }

  export interface UpdateRequest {
    id: number;
    externalId: string;
    parentId?: number;
    name: string;
  }

  export enum OrderField {
    ID,
    EXTERNAL_ID,
    NAME
  }

  export enum ValidatedField {
    UNKNOWN,

    EXTERNAL_ID,
    NAME,
    PARENT_ID
  }

}

class Keys {

  private static readonly ID = 'id';
  private static readonly EXTERNAL_ID = 'external_id';
  private static readonly PARENT_ID = 'parent_id';
  private static readonly NAME = 'name';

  private static readonly orderFieldKeyMap: Map<StockItemCategory.OrderField, string> = Map.of(
    StockItemCategory.OrderField.ID, Keys.ID,
    StockItemCategory.OrderField.EXTERNAL_ID, Keys.EXTERNAL_ID,
    StockItemCategory.OrderField.NAME, Keys.NAME,
  );

  private static readonly keyValidatedFieldMap: Map<string, StockItemCategory.ValidatedField> = Map.of(
    Keys.EXTERNAL_ID, StockItemCategory.ValidatedField.EXTERNAL_ID,
    Keys.PARENT_ID, StockItemCategory.ValidatedField.PARENT_ID,
    Keys.NAME, StockItemCategory.ValidatedField.NAME,
  );

  public static toOrderFieldKey(field: StockItemCategory.OrderField): string {
    return Keys.orderFieldKeyMap.get(field)!;
  }

  public static toValidatedField(fieldKey: string): StockItemCategory.ValidatedField {
    return Keys.keyValidatedFieldMap.get(fieldKey, StockItemCategory.ValidatedField.UNKNOWN);
  }

}
