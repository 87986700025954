import * as draw2d from '../../../../../../../node_modules/draw2d/dist/draw2d.js';
import { FigureSelectionFeedbackPolicy } from '../policy/figure-selection-feedback-policy';
import { PortFeedbackPolicy } from '../policy/port-feedback-policy';

export class Label extends draw2d.shape.layout.HorizontalLayout {

  public static readonly minWidth: number = 20;

  private icon: draw2d.shape.basic.Image;
  private label: draw2d.shape.basic.Label;
  private inputPort: draw2d.Port;
  private outputPort: draw2d.Port;

  public static getFormattedName(name: string, minWidth?: number): string {
    if (minWidth && name.length < Label.minWidth) {
      let newName = name;
      for (let i = name.length; i < Label.minWidth; i++) {
        newName += '\u00A0';
      }
      return newName;
    }
    else {
      return name;
    }
  }

  constructor(withPorts?: boolean, readonly?: boolean) {
    super();
    super.setRadius(4);
    super.setStroke(1);
    super.setColor(new draw2d.util.Color('#8D949C'));
    super.setBackgroundColor(new draw2d.util.Color('#FFFFFF'));
    super.setDeleteable(false);
    super.setSelectable(false);
    super.setDraggable(!readonly);

    this.icon = new draw2d.shape.basic.Image({
      width: 23,
      height: 39,
      minWidth: 23,
      minHeight: 39,
      boundingBox: new draw2d.geo.Rectangle(0, 0, 23, 39)
    });
    this.label = new draw2d.shape.basic.Label();
    this.label.setFontFamily('Lucida Console');
    this.label.setStroke(0);
    this.label.setPadding(12);
    super.add(this.label);
    super.add(this.icon);

    // Custom policy to show green outline on selection
    super.installEditPolicy(new FigureSelectionFeedbackPolicy());

    if (withPorts) {
      this.outputPort = this.createOutputPort(readonly);
      this.inputPort = this.createInputPort(readonly);
    }
  }

  private createInputPort(readonly?: boolean) {
    // Create input port
    const inputPort = super.createPort('input');
    inputPort.setStroke(0);
    inputPort.setDiameter(4);
    inputPort.setBackgroundColor(new draw2d.util.Color('#8D949C'));
    inputPort.useGradient = false;
    inputPort.setColor(new draw2d.util.Color('#00727B'));
    inputPort.installEditPolicy(new PortFeedbackPolicy());
    inputPort.setDraggable(!readonly);
    inputPort.setSelectable(!readonly);
    return inputPort;
  }

  private createOutputPort(readonly?: boolean) {
    // Create output port
    const outputPort = super.createPort('output');
    outputPort.setStroke(0);
    outputPort.setDiameter(4);
    outputPort.setBackgroundColor(new draw2d.util.Color('#8D949C'));
    outputPort.useGradient = false;
    outputPort.setColor(new draw2d.util.Color('#00727B'));
    outputPort.installEditPolicy(new PortFeedbackPolicy());
    outputPort.setDraggable(!readonly);
    outputPort.setSelectable(!readonly);
    return outputPort;
  }

  setIcon(path?: string) {
    this.icon.setPath(path);
    if (path) {
      super.remove(this.label);
      super.add(this.label);
    }
    else {
      super.remove(this.icon);
      super.add(this.icon);
    }
  }

  getIcon(): string {
    return this.icon.getPath();
  }

  setName(name: string, minWidth?: number) {
      this.label.setText(Label.getFormattedName(name, minWidth));
  }

  getName(): string {
    return this.label.getText().replace(/\u00A0/g, '');
  }

  // Required for drag and drop to work properly
  delegateTarget(draggedFigure) {
    return this;
  }

  installEditPolicy(policy) {
    super.installEditPolicy(policy);
  }

  onClick() {
  }

  getConnections() {
    return super.getConnections();
  }

  getInputPort(): draw2d.Port {
    return this.inputPort;
  }

  getOutputPort(): draw2d.Port {
    return this.outputPort;
  }

  getId(): number {
    return super.getId();
  }

  setId(id: number) {
    super.setId(id);
  }

  setUserData(data) {
    super.setUserData(data);
  }

  getUserData() {
    return super.getUserData();
  }

  on(event, callback) {
    super.on(event, callback);
  }

  off(eventOrFunction) {
    super.off(eventOrFunction);
  }

}
