/* eslint-disable */
import { Component, OnInit } from '@angular/core';
import { InvoiceStatistics, InvoiceStatisticsService } from '../../../../lib/statistics/invoice-statistics/invoice-statistics.service';
import { OrderType, QueryResult } from '../../../../lib/util/services';
import { BreadcrumbParent } from '../../../../shared/breadcrumb/breadcrumb/breadcrumb.component';
import { TranslateService } from '@ngx-translate/core';
import { StateName } from '../../../../app.state-names';
import { QueryFieldModel } from '../../../../util/core-utils';
import { Set } from 'immutable';
/* eslint-enable */

@Component({
  selector: 'app-invoice-statistics-list',
  templateUrl: './invoice-statistics-list.component.html',
  styleUrls: ['./invoice-statistics-list.component.scss']
})
export class InvoiceStatisticsListComponent implements OnInit {
  InvoiceStatistics = InvoiceStatistics;

  queryModel: QueryFieldModel<InvoiceStatistics.OrderField> = new QueryFieldModel(InvoiceStatistics.OrderField.TASK_ID, OrderType.DESC);
  invoiceStatistics: InvoiceStatistics.InvoiceStatistic[] = [];
  breadcrumbParents: BreadcrumbParent[] = [];
  breadcrumbSelf: string;
  compactSidebar: boolean = document.querySelector('body')!.classList.contains('sidebar-compact');

  constructor(
    private translateService: TranslateService,
    private invoiceStatisticsService: InvoiceStatisticsService
  ) {}

  ngOnInit() {
    this.translateService.get('MENU_NAVBAR_INVOICE_STATISTICS').subscribe(
      (result: string) => {
        this.breadcrumbSelf = result;
      }
    );
    this.translateService.get('MENU_NAVBAR_MENU_ADMINISTRATION').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.ADMIN_DASHBOARD});
      }
    );
    this.loadList();
  }

  private loadList(pageNumber?: number) {
    const requestedPage = pageNumber ? pageNumber : this.queryModel.currentPage;
    const order = this.queryModel.getOrder();

    this.invoiceStatisticsService.query({
      paging: {
        pageNumber: requestedPage,
        numberOfItems: this.queryModel.itemsPerPage
      },
      order: Set.of(order)
    })
      .subscribe(
        (stats: QueryResult<InvoiceStatistics.InvoiceStatistic>) => {
          this.invoiceStatistics = stats.items.toArray();
          this.queryModel.currentPage = requestedPage;
          this.queryModel.totalNumberOfItems = stats.pagingResult.totalNumberOfItems;
          this.queryModel.currentNumberOfItems = stats.pagingResult.currentNumberOfItems;
        }
      );
  }

  pageChanged(selectedPage: number) {
    this.loadList(selectedPage);
  }

  itemsPerPageChanged(itemsPerPage: number) {
    this.queryModel.itemsPerPage = itemsPerPage;
    this.loadList(1);
  }

  orderBy(field: InvoiceStatistics.OrderField) {
    this.queryModel.onOrderFieldChanged(field);
    this.loadList(1);
  }

}
