import { DisableRequest, ListQuery } from '../util/messages';
import { Map as ImmutableMap } from 'immutable';

export namespace StockItemUnitOfMeasure {
  export interface StockItemUnitOfMeasureQuery extends ListQuery.Paging, ListQuery.Order {
    id?: string;
    q?: string;
    stock_item_id: number;
    include_base_unit: boolean;
    disabled?: boolean;
    no_progress_bar?: boolean;
  }

  export interface GetRequest {
    stock_item_id: number;
    id: number;
  }
  export interface StockItemUnitOfMeasure {
    stock_item_id: number;
    id: number;
    unit_name: string;
    unit_conversion: number;
    code: string;
    disabled: boolean;
    base_unit: boolean;
  }

  export interface StockItemUnitOfMeasureCreateRequest {
    stock_item_id?: number;
    unit_name: string;
    unit_conversion: number;
    code: string;
  }

  export interface StockItemUnitOfMeasureUpdateRequest extends StockItemUnitOfMeasureCreateRequest {
    id: number;
  }

  export interface StockItemUnitOfMeasureDisableRequest extends DisableRequest {
    stock_item_id: number;
  }

  export enum ValidatedField {
    UNKNOWN,
    UNIT_NAME,
    CODE,
    UNIT_CONVERSION,
  }

  export enum OrderField {
    ID,
    UNIT_CONVERSION,
    UNIT_NAME,
    CODE
  }

  export class Keys {

    private static readonly ID = 'id';
    private static readonly UNIT_NAME = 'unit_name';
    private static readonly UNIT_CONVERSION = 'unit_conversion';
    private static readonly CODE = 'code';

    private static readonly keyValidatedFieldMap: ImmutableMap<string, StockItemUnitOfMeasure.ValidatedField> = ImmutableMap.of(
      Keys.UNIT_NAME, StockItemUnitOfMeasure.ValidatedField.UNIT_NAME,
      Keys.CODE, StockItemUnitOfMeasure.ValidatedField.CODE,
      Keys.UNIT_CONVERSION, StockItemUnitOfMeasure.ValidatedField.UNIT_CONVERSION,
    );

    public static toValidatedField(fieldKey: string): StockItemUnitOfMeasure.ValidatedField {
      return Keys.keyValidatedFieldMap.get(fieldKey, StockItemUnitOfMeasure.ValidatedField.UNKNOWN);
    }

    private static readonly orderFieldKeyMap: ImmutableMap<StockItemUnitOfMeasure.OrderField, string> = ImmutableMap.of(
      StockItemUnitOfMeasure.OrderField.ID, Keys.ID,
      StockItemUnitOfMeasure.OrderField.UNIT_NAME, Keys.UNIT_NAME,
      StockItemUnitOfMeasure.OrderField.UNIT_CONVERSION, Keys.UNIT_CONVERSION,
      StockItemUnitOfMeasure.OrderField.CODE, Keys.CODE,
    );

    public static toOrderFieldKey(field: StockItemUnitOfMeasure.OrderField): string {
      return Keys.orderFieldKeyMap.get(field)!;
    }
  }
}
