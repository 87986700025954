/* eslint-disable */
import { Injectable } from '@angular/core';
import { Observable, Observer } from 'rxjs';
import { Order, PagingRequest, QueryResult, ResourceQueryResult, Services } from '../util/services';
import { EmptyMessage } from '../util/messages';
import { List, Map, Set } from 'immutable';
import { OffsetDateTime } from '../util/dates';
import { EmailTemplateResource, EmailTemplateResourceService } from './email-template-resource.service';
/* eslint-enable */

@Injectable()
export class EmailTemplateService implements EmailTemplate.Service {

  constructor(private resourceService: EmailTemplateResourceService) {
  }

  query(request: EmailTemplate.QueryRequest): Observable<QueryResult<EmailTemplate.EmailTemplate>> {
    return Observable.create((observer: Observer<QueryResult<EmailTemplate.EmailTemplate>>) => {
      const resourceRequest: EmailTemplateResource.QueryRequest = {
        id: Services.createIdParameter(request.id),
        external_id: request.externalId,
        name: request.name,
        subject: request.subject,
        disabled: request.disabled,
        q: request.queryText,
        order: Services.createOrderFieldParameter(Keys.toOrderFieldKey, request.orders),
        page_number: request.paging ? request.paging.pageNumber : undefined,
        number_of_items: request.paging ? request.paging.numberOfItems : undefined,
        no_progress_bar: request.noProgressBar,
      };
      return this.resourceService.query(resourceRequest).subscribe(
        (result: ResourceQueryResult<EmailTemplateResource.EmailTemplate>) => {
          observer.next({
            items: this.toPublicList(result.items),
            pagingResult: result.pagingResult
          });
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  get(request: EmailTemplate.GetRequest): Observable<EmailTemplate.EmailTemplate> {
    return Observable.create((observer: Observer<EmailTemplate.EmailTemplate>) => {
      const resourceRequest: EmailTemplateResource.GetRequest = {
        id: request.id
      };
      return this.resourceService.get(resourceRequest).subscribe(
        (result: EmailTemplateResource.EmailTemplate) => {
          observer.next(this.toPublic(result));
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  create(request: EmailTemplate.CreateRequest): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      const resourceRequest: EmailTemplateResource.CreateRequest = {
        external_id: request.externalId,
        name: request.name,
        subject: request.subject,
        template: request.template,
      };
      return this.resourceService.create(resourceRequest).subscribe(
        (result: EmptyMessage) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  update(request: EmailTemplate.UpdateRequest): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      const resourceRequest: EmailTemplateResource.UpdateRequest = {
        id: request.id,
        external_id: request.externalId,
        name: request.name,
        subject: request.subject,
        template: request.template,
      };
      return this.resourceService.update(resourceRequest).subscribe(
        (result: EmptyMessage) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  setDisabled(request: EmailTemplate.DisableRequest): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      const resourceRequest: EmailTemplateResource.DisableRequest = {
        id: request.id,
        disabled: request.disabled
      };
      return this.resourceService.disable(resourceRequest).subscribe(
        (result: EmptyMessage) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  getAvailableSystemCodes(request: EmailTemplate.AvailableSystemCodesRequest): Observable<string[]> {
    return Observable.create((observer: Observer<string[]>) => {
      const resourceRequest = {
        code: request.code,
        no_progress_bar: true
      };
      return this.resourceService.getAvailableSystemCodes(resourceRequest).subscribe(
        (result: string[]) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(/*this.translateError(*/error/*)*/);
        },
        () => {
          observer.complete();
        });
    });
  }

  private toPublicList(resourceList: EmailTemplateResource.EmailTemplate[]): List<EmailTemplate.EmailTemplate> {
    return List.of(...resourceList.map((r) => this.toPublic(r)));
  }

  private toPublic(r: EmailTemplateResource.EmailTemplate): EmailTemplate.EmailTemplate {
    return {
      id: r.id,
      externalId: r.external_id,
      name: r.name,
      subject: r.subject,
      template: r.template,
      creationTime: Services.toOffsetDateTime(r.creation_time),
      updateTime: Services.toOffsetDateTime(r.update_time),
      disabled: r.disabled
    };
  }

}


export namespace EmailTemplate {

  export interface Service {

    // <editor-fold desc="CRUD">

    query(request: QueryRequest): Observable<QueryResult<EmailTemplate>>;

    get(request: GetRequest): Observable<EmptyMessage>;

    create(request: CreateRequest): Observable<EmptyMessage>;

    update(request: UpdateRequest): Observable<EmptyMessage>;

    setDisabled(request: DisableRequest): Observable<EmptyMessage>;

    // </editor-fold>

  }

  export interface EmailTemplate {
    id: number;
    externalId: string;
    name: string;
    subject: string;
    template: string;
    disabled: boolean;
    creationTime: OffsetDateTime;
    updateTime: OffsetDateTime;
  }

  export interface QueryRequest {
    id?: Set<number>;
    externalId?: string;
    name?: string;
    subject?: string;
    queryText?: string;
    orders?: Set<Order<OrderField>>;
    paging?: PagingRequest;
    disabled?: boolean;
    noProgressBar?: boolean;
  }

  export interface GetRequest {
    id: number;
  }

  export interface CreateRequest {
    externalId?: string;
    name: string;
    subject: string;
    template: string;
  }

  export interface UpdateRequest {
    id: number;
    externalId: string;
    name: string;
    subject: string;
    template: string;
  }

  export interface DisableRequest {
    id: number;
    disabled: boolean;
  }

  export interface AvailableSystemCodesRequest {
    code: string;
  }

  export enum OrderField {
    ID,
    EXTERNAL_ID,
    NAME,
    CREATION_TIME,
    UPDATE_TIME
  }

  export enum ValidatedField {
    UNKNOWN,

    EXTERNAL_ID,
    NAME,
    SUBJECT
  }

}

class Keys {

  private static readonly ID = 'id';
  private static readonly EXTERNAL_ID = 'external_id';
  private static readonly NAME = 'name';
  private static readonly SUBJECT = 'subject';
  private static readonly CREATION_TIME = 'creation_time';
  private static readonly UPDATE_TIME = 'update_time';

  private static readonly orderFieldKeyMap: Map<EmailTemplate.OrderField, string> = Map.of(
    EmailTemplate.OrderField.ID, Keys.ID,
    EmailTemplate.OrderField.EXTERNAL_ID, Keys.EXTERNAL_ID,
    EmailTemplate.OrderField.NAME, Keys.NAME,
    EmailTemplate.OrderField.CREATION_TIME, Keys.CREATION_TIME,
    EmailTemplate.OrderField.UPDATE_TIME, Keys.UPDATE_TIME,
  );

  private static readonly keyValidatedFieldMap: Map<string, EmailTemplate.ValidatedField> = Map.of(
    EmailTemplate.ValidatedField.EXTERNAL_ID, Keys.EXTERNAL_ID,
    EmailTemplate.ValidatedField.NAME, Keys.NAME,
    EmailTemplate.ValidatedField.SUBJECT, Keys.SUBJECT
  );

  public static toOrderFieldKey(field: EmailTemplate.OrderField): string {
    return Keys.orderFieldKeyMap.get(field)!;
  }

  public static toValidatedField(fieldKey: string): EmailTemplate.ValidatedField {
    return Keys.keyValidatedFieldMap.get(fieldKey, EmailTemplate.ValidatedField.UNKNOWN);
  }

}
