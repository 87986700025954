import { GrantedPermissionSetResolver } from '../../../lib/right.service';
import { Dates, OffsetDateTime } from '../../../lib/util/dates';
import { ProcessRightModel } from '../../../lib/process/process-right.model';
import { Process } from '../../../lib/process/process.service';
import { Icon } from '../../../lib/task/icon.service';

export class ProcessListModel {
  id: number;
  rights: ProcessRightModel = new ProcessRightModel(GrantedPermissionSetResolver.empty());
  state: Process.ProcessState;
  stateObject?: Process.ProcessStateObject;
  name: string;
  externalId: string;
  creationTime: OffsetDateTime = Dates.emptyOffsetDateTime();
  icon?: Icon.Icon;
  updateTime: OffsetDateTime = Dates.emptyOffsetDateTime();
  deadline?: OffsetDateTime = Dates.emptyOffsetDateTime();
  lastModifiedUser: {
    id: number;
    userName: string;
    personName: string;
  };
  workflow: {
    id: number;
    name: string;
  }

  getLastModifierName(): string {
    return this.lastModifiedUser.personName;
  }

}
