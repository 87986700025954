/* eslint-disable */
import { Injectable } from '@angular/core';
import { Observable, Observer } from 'rxjs';
import { DownloadedFile, NamedBlobFile, NamedBlobFileDecorator } from '../util/downloaded-files';
import { FormAttachmentResource, FormAttachmentResourceService } from './form-attachment-resource.service';
import { List } from 'immutable';
import { EmptyMessage } from '../util/messages';
import { FileAttachment, FileAttachmentResource, FileAttachments, FileAttachmentUpdateRequest } from '../util/file-attachments';
import { map } from 'rxjs/operators';

/* eslint-enable */

@Injectable()
export class FormAttachmentService {

  constructor(private resourceService: FormAttachmentResourceService) {
  }

  deleteFile(request: FormAttachment.DeleteRequest): Observable<List<FileAttachment>> {
    return Observable.create((observer: Observer<List<FileAttachment>>) => {
      const resourceRequest: FormAttachmentResource.DeleteRequest = {
        form_record_id: request.formRecordId,
        field_id: request.fieldId,
        file_id: request.fileId
      };
      return this.resourceService.deleteFile(resourceRequest).subscribe(
        (result: FileAttachmentResource[]) => {
          observer.next(
            this.toPublicList(result)
          );
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  updateAttachment(request: FormAttachment.AttachmentUpdateRequest): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      const resourceRequest: FormAttachmentResource.AttachmentUpdateRequest = {
        form_record_id: request.formRecordId,
        field_id: request.fieldId,
        file_id: request.fileId,
        name: request.name
      };
      return this.resourceService.updateAttachment(resourceRequest).subscribe(
        (result: EmptyMessage) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  downloadFile(request: FormAttachment.DownloadFileRequest): Observable<NamedBlobFile> {
    return this.resourceService.downloadFile({
      formRecordId: request.formRecordId,
      fieldId: request.fieldId,
      fileId: request.fileId
    }).pipe(map((file: DownloadedFile) => {
      return new NamedBlobFileDecorator(file, request.attachment.fullFileName!);
    }));
  }

  downloadThumbnail(request: FormAttachment.DownloadFileRequest): Observable<NamedBlobFile> {
    return this.resourceService.downloadThumbnail({
      formRecordId: request.formRecordId,
      fieldId: request.fieldId,
      fileId: request.fileId
    }).pipe(map((file: DownloadedFile) => {
      return new NamedBlobFileDecorator(file, request.attachment.fullFileName!);
    }));
  }

  private translateError(error: any): any {
    return error;
  }

  private toPublicList(resourceList: FileAttachmentResource[]): List<FileAttachment> {
    return List.of(...FileAttachments.toPublicList(resourceList));
  }

  private toPublic(r: FileAttachmentResource): FileAttachment {
    return FileAttachments.toPublic(r);
  }

}

export namespace FormAttachment {

  export interface Service {

    // <editor-fold desc="CRUD">

    deleteFile(request: DeleteRequest): Observable<EmptyMessage>;

    // </editor-fold>

  }

  // <editor-fold desc="CRUD">

  export interface DeleteRequest {
    formRecordId: number;
    fieldId: number;
    fileId: number;
  }

  export interface AttachmentUpdateRequest extends FileAttachmentUpdateRequest {
    formRecordId: number;
    fieldId: number;
  }

  export interface DownloadFileRequest {
    formRecordId: number;
    fieldId: number;
    fileId: number;
    attachment: FileAttachment;
  }
}
