import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ForwardingNgFormRef, LocalFormGroupValidationErrors, OrderType, ResourceQueryResult, Services } from '../../../lib/util/services';
import { MultiselectOptionItem, SelectUtils, UiConstants } from '../../../util/core-utils';
import { Set } from 'immutable';
import { Strings } from '../../../lib/util/strings';
import { Stock, StockQuery, StockService } from '../../../lib/stock/stock.service';
import { Angular2Multiselects } from '../../../util/multiselect';
import { StockTypeName } from '../../../util/stock/stock-utils';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';

@Component({
  selector: 'app-stock-selector-dialog',
  templateUrl: './stock-selector-dialog.component.html',
  styleUrls: ['./stock-selector-dialog.component.scss']
})
export class StockSelectorDialogComponent implements OnInit {

  UiConstants = UiConstants;
  SelectUtils = SelectUtils;

  stocks: MultiselectOptionItem<number>[] = [];
  _selectedStock: MultiselectOptionItem<number>[] = [];
  private get selectedStock(): MultiselectOptionItem<number> | undefined {
    return this._selectedStock.length === 0 ? undefined : this._selectedStock[0];
  }

  dropdownSettings: Angular2Multiselects.Settings;
  @ViewChild('f', {static: true})
  form: NgForm;
  formGroup: FormGroup;
  private formGroupValidationErrors: LocalFormGroupValidationErrors;

  constructor(
    public dialogRef: MatDialogRef<StockSelectorDialogComponent, MultiselectOptionItem<number>>,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: StockSelectorDialogData,
    private stockService: StockService
  ) {
    this.formGroup = this.createFormGroup(this.fb);
    this.formGroupValidationErrors = LocalFormGroupValidationErrors.ofForm(
      this.createForwardingHtmlForm(),
      this.formGroup
    );
  }

  get titleKey(): string {
    return this.data.titleKey ? this.data.titleKey : 'STOCK_SELECTOR_DIALOG_TITLE';
  }

  ngOnInit() {
    this.initDropdownSettings();
    this.loadStocks(undefined, true);
  }

  private initDropdownSettings() {
    this.dropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(true)
      .enableSearchFilter(true)
      .remoteSearch(true)
      .enableCheckAll(false)
      .build();
  }

  private createForwardingHtmlForm() {
    return new ForwardingNgFormRef({
      formFn: () => {
        return this.form;
      }
    });
  }

  private createFormGroup(fb: FormBuilder): FormGroup {
    return fb.group(
      {
        stockInput: fb.control(
          {value: this._selectedStock},
          [Validators.required]
        )
      }
    );
  }

  hasLocalFieldError(formControlName?: string, errorCode?: string): boolean {
    return this.formGroupValidationErrors.hasFieldError(formControlName, errorCode);
  }

  loadStocks(q?: string, initial?: boolean) {
    const request: StockQuery = {
      q: Strings.undefinedOrNonEmpty(q),
      disabled: false,
      id: this.data.forceStockIds?.join(','),
      excluded_ids: this.data.excludedIds.length > 0 ? this.data.excludedIds.join() : undefined,
      type: this.data.typeName,
      customer_record_id: this.data.ownerCustomerRecordId ? '' + this.data.ownerCustomerRecordId : undefined,
      contact_location_id: this.data.ownerContactLocationId ? '' + this.data.ownerContactLocationId : undefined,
      order: Services.createOrderFieldParameter(Stock.Keys.toOrderFieldKey, Set.of({
        field: Stock.OrderField.NAME,
        type: OrderType.ASC
      })),
      page_number: 1,
      number_of_items: UiConstants.autocompletePageSize,
      no_progress_bar: true
    };
    this.stockService.query(request).subscribe((result: ResourceQueryResult<Stock>) => {
      this.stocks = result.items.map(s => ({id: s.id, itemName: s.name, itemSubtitle: s.external_id}));
      if (initial === true && this.stocks.length === 1) {
        this._selectedStock = [this.stocks[0]];
      }
    });
  }

  save() {
    if (this.formGroup.invalid || this._selectedStock.length === 0) {
      return;
    }
    this.closeDialog(this.selectedStock);
  }

  cancel() {
    this.closeDialog();
  }

  closeDialog(result?: MultiselectOptionItem<number> | null) {
    this.dialogRef.close(result ? result : undefined);
  }

  public static openSelector(
    dialog: MatDialog,
    data: StockSelectorDialogData,
    resultCallback: (result?: MultiselectOptionItem<number>) => void
  ) {
    const dialogRef = dialog.open(StockSelectorDialogComponent, {
      width: '60vw',
      closeOnNavigation: true,
      data: data,
      panelClass: 'custom-dialog-container'
    });

    dialogRef.afterClosed().subscribe((result: MultiselectOptionItem<number>) => {
      resultCallback(result);
    });
  }

}

export interface StockSelectorDialogData {
  excludedIds: number[];
  forceStockIds?: number[];
  typeName?: StockTypeName;
  ownerCustomerRecordId?: number;
  ownerContactLocationId?: number;
  titleKey?: string;
}
