import { Component, Input, OnInit } from '@angular/core';
import { Strings } from '../../lib/util/strings';
import { TranslateService } from '@ngx-translate/core';
import { UserGroup } from '../../lib/user-group.service';
import { User, UserService } from '../../lib/user.service';
import { Device } from '../../lib/device-management.service';
import { DomSanitizer } from '@angular/platform-browser';
import { UIRouter } from '@uirouter/core';
import { RightModel } from '../../app.rights';
import { StateName } from '../../app.state-names';
import { MultiselectOptionItem } from '../../util/core-utils';
import { UserProfilePicture, UserProfilePictureService } from '../../lib/user/user-profile-picture.service';

@Component({
  selector: 'app-assignee-table-cell',
  templateUrl: './assignee-table-cell.component.html',
  styleUrls: ['./assignee-table-cell.component.scss']
})
export class AssigneeTableCellComponent implements OnInit {
  Strings = Strings;
  AssigneeType = AssigneeType;

  @Input()
  set user(value: User) {
    this._user = value;
    this.updateData();
  }

  @Input()
  set userGroup(value: UserGroup) {
    this._userGroup = value;
    this.updateData();
  }

  @Input()
  set device(value: Device) {
    this._device = value;
    this.updateData();
  }

  @Input()
  assigneeType: AssigneeType;

  @Input()
  rightModel: RightModel = RightModel.empty();

  @Input()
  helpdeskMode: boolean = false;

  _user?: User;

  get personName(): string {
    return this._user
      ? this._user.person_name
        ? this._user.person_name
        : this._user['personName']
          ? this._user['personName']
          : ''
      : '';
  }

  get userName(): string {
    return this._user
      ? this._user.user_name
        ? this._user.user_name
        : this._user['userName']
          ? this._user['userName']
          : ''
      : '';
  }

  get userGroupIds(): number[] {
    return this._user
      ? this._user.user_group_ids
        ? this._user.user_group_ids
        : this._user['userGroupIds']
          ? this._user['userGroupIds']
          : []
      : [];
  }

  get profilePictureHash(): string | undefined {
    return this._user
      ? this._user.profile_picture_hash
        ? this._user.profile_picture_hash
        : this._user['profilePictureHash']
          ? this._user['profilePictureHash']
          : undefined
      : undefined;
  }

  _userGroup?: UserGroup;

  _device?: Device;

  cellTitle: string = '';
  cellSubtitle: string = '';
  profilePicture: UserProfilePicture;

  @Input()
  userGroups: MultiselectOptionItem<number>[] = [];

  constructor(private translateService: TranslateService,
              private userService: UserService,
              private userProfilePictureService: UserProfilePictureService,
              private sanitizer: DomSanitizer,
              private uiRouter: UIRouter) {
  }

  ngOnInit() {
  }

  private updateData() {
    if (this._user) {
      this.cellTitle = this.personName;
      this.cellSubtitle = this.userName;
      if (this.profilePictureHash && this.rightModel.userRead.hasRight()) {
        this.profilePicture = this.userProfilePictureService.getProfilePicture(this._user.id, this.profilePictureHash);
      }
      return;
    }
    if (this._device) {
      this.cellTitle = this._device.name;
      this.translateService.get('TASK_RECORD_TABLE_HEADER_DEVICE_NAME').subscribe(
        (result: string) => {
          this.cellSubtitle = result;
        }
      );
      return;
    }
    if (this._userGroup) {
      this.cellTitle = this._userGroup.name;
      this.translateService.get('TASK_DATA_MODIFICATION_USER_GROUP').subscribe(
        (result: string) => {
          this.cellSubtitle = result;
        }
      );
      return;
    }
  }

  navigateToAssigneeDetail() {
    if (this.assigneeType === AssigneeType.USER && this._user) {
      if (this.rightModel.userRead.hasRight()) {
        this.uiRouter.stateService.go(StateName.USER_DETAIL, {id: this._user.id});
      }
    }
    else if (this.assigneeType === AssigneeType.MOBILE && this._device) {
      if (this.rightModel.mobileAppRead.hasRight()) {
        this.uiRouter.stateService.go(StateName.APPLICATION_DEVICE_MANAGEMENT_LIST);
      }
    }
    else if (this._userGroup) {
      if (this.rightModel.userGroupRead.hasRight()) {
        this.uiRouter.stateService.go(StateName.USER_GROUP_DETAIL, {id: this._userGroup.id});
      }
    }
  }

  getUserGroups(): MultiselectOptionItem<number>[] {
    return this.userGroups.filter(group => this.userGroupIds.find(id => group.id === id));
  }
}

export enum AssigneeType {
  MOBILE,
  USER
}
