/* eslint-disable */
import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { FormRecordContainerComponent } from '../../form/form-record/form-record-container.component';
import { FormRecordContainer } from '../../form/form-record/form-record-container';
import { NgForm } from '@angular/forms';
import { BreadcrumbParent } from '../../../shared/breadcrumb/breadcrumb/breadcrumb.component';
import { ToasterService } from '../../../fork/angular2-toaster/angular2-toaster';
import { TranslateService } from '@ngx-translate/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ConfigurationService } from '../../../lib/core-ext/configuration.service';
import { StateName } from '../../../app.state-names';
import { combineLatest, Observable } from 'rxjs';
import { Form } from '../../../lib/form/form.service';
import { LegacyProcess, LegacyProcessService } from '../../../lib/legacy-process/legacy-process.service';
import { LegacyProcessTask, LegacyProcessTaskService } from '../../../lib/legacy-process/legacy-process-task.service';
import { LegacyWorkflowTaskService } from '../../../lib/legacy-workflow/legacy-workflow-tasks/legacy-workflow-task.service';
import { LegacyProcessTaskBaseComponent } from '../legacy-process-task-base/legacy-process-task-base.component';
import { LegacyProcessTaskBase } from '../legacy-process-task-base/legacy-process-task-base-view';
import { Transition, UIRouter } from '@uirouter/angular';
import { FileAttachmentGalleryComponent } from '../../../shared/file-attachment-gallery/file-attachment-gallery.component';
import { RightModel } from '../../../app.rights';
import { RightResolver, RightService } from '../../../lib/right.service';

/* eslint-enable */

@Component({
  selector: 'app-legacy-process-task-detail',
  templateUrl: './legacy-process-task-detail.component.html',
  styleUrls: ['./legacy-process-task-detail.component.scss']
})
export class LegacyProcessTaskDetailComponent implements AfterViewInit {

  @ViewChild(LegacyProcessTaskBaseComponent, { static: true })
  baseView: LegacyProcessTaskBase.View;

  @ViewChild(FormRecordContainerComponent, { static: true })
  formRecordContainerView: FormRecordContainer.View;

  @ViewChild('f', { static: true })
  fForm: NgForm;

  @ViewChild('attachmentView', { static: true })
  attachmentView: FileAttachmentGalleryComponent;

  forceSaveButton: boolean = false;

  processId: number;
  processTaskId: number;
  workflowId: number;
  workflowTaskId: number;
  breadcrumbSelf: string;
  compactSidebar: boolean = document.querySelector('body')!.classList.contains('sidebar-compact');
  breadcrumbParents: BreadcrumbParent[] = [];
  processName: string;

  rightModel: RightModel = RightModel.empty();

  saveButtonClicked: boolean = false;

  private combinedResult?: CombinedResult;

  constructor(
    private toasterService: ToasterService,
    private translateService: TranslateService,
    private modalService: BsModalService,
    private configurationService: ConfigurationService,
    private processService: LegacyProcessService,
    private processTaskService: LegacyProcessTaskService,
    private workflowTaskService: LegacyWorkflowTaskService,
    private uiRouter: UIRouter,
    private transition: Transition,
    private rightService: RightService) {
    this.processId = this.transition.params().processId;
    this.processTaskId = this.transition.params().id;
  }

  ngAfterViewInit(): void {
    this.loadRightModels();
    this.processTaskService.get({
      processId: this.processId,
      processTaskId: this.processTaskId
    }).subscribe((processTask: LegacyProcessTask.LegacyProcessTask) => {
      this.workflowId = processTask.workflowId;
      this.workflowTaskId = processTask.workflowTaskId;
      LegacyWorkflowTaskService.setWorkflowId(this.workflowId);
      this.createCombinedObservable().subscribe(
        (result: CombinedResult) => {
          this.breadcrumbSelf = result.processTask.displayName;
          this.processName = result.process.displayName ? result.process.displayName : String(result.process.processId);
          this.baseView.loadProcessTask({
            processTask: result.processTask
          });
          this.formRecordContainerView.loadFormRecord({
            configuration: this.configurationService.getConfiguration(),
            form: result.form,
            record: result.processTask.formRecord,
            other: {processId: this.processId}
          });
          this.combinedResult = result;
          this.forceSaveButton = !Form.Forms.hasVisibleGroup(result.form);
          this.translateService.get('MENU_NAVBAR_LEGACY_PROCESSES').subscribe(
            (text: string) => {
              this.breadcrumbParents.push({name: text, uiSref: StateName.LEGACY_PROCESS_LIST});
            }
          );
          this.breadcrumbParents.push({
            name: String(this.processId),
            uiSref: StateName.LEGACY_PROCESS_EDIT,
            uiParam: {id: this.processId}
          });
        },
        () => {
        },
        () => {
        }
      );
    });
  }

  nothing() {
  }

  private loadRightModels() {
    this.rightService.getRightResolver().subscribe(
      (resolver: RightResolver) => {
        this.rightModel = RightModel.of(resolver);
      }
    );
  }

  private createCombinedObservable(): Observable<CombinedResult> {
    return combineLatest(
      this.workflowTaskService.getForm({
        parentId: this.workflowTaskId
      }),
      this.processService.get({
        id: this.processId
      }),
      this.processTaskService.get({
        withFormRecord: true,
        processId: this.processId,
        processTaskId: this.processTaskId
      }),
      (
        form: Form.Form,
        process: LegacyProcess.LegacyProcess,
        processTask: LegacyProcessTask.LegacyProcessTask) => {
        return {
          form: form,
          process: process,
          processTask: processTask
        };
      }
    );
  }

}

interface CombinedResult {
  form: Form.Form;
  process: LegacyProcess.LegacyProcess;
  processTask: LegacyProcessTask.LegacyProcessTask;
}
