/* eslint-disable */
import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CustomerRecord, CustomerRecordService, } from '../../../lib/customer/customer-record.service';
import { OrderType, QueryResult } from '../../../lib/util/services';
import { OptionItem, QueryFieldModel } from '../../../util/core-utils';
import { Set } from 'immutable';
import { UserItem } from '../../../lib/user.service';
import { TranslateService } from '@ngx-translate/core';
import CustomerRecordHistoryType = CustomerRecord.CustomerRecordHistoryType;

/* eslint-enable */

@Component({
  selector: 'app-customer-record-history',
  templateUrl: './customer-record-history.component.html',
  styleUrls: ['./customer-record-history.component.scss']
})
export class CustomerRecordHistoryComponent implements OnInit, AfterViewInit {

  @Output() openHistoryDetail = new EventEmitter<string>();

  CustomerRecord = CustomerRecord;

  @Input()
  customerId?: number;
  @Input()
  customerRecordId?: number;

  queryModel: QueryFieldModel<CustomerRecord.OrderField> =
    new QueryFieldModel(CustomerRecord.OrderField.HISTORY_CREATION_TIME, OrderType.DESC);

  customerRecordHistoryList: CustomerRecordHistoryModel[] = [];

  historyTypes: CustomerRecordHistoryTypeItem[];

  readonly pagingId = 'customerRecordHistoryPaging';

  constructor(
    private customerRecordService: CustomerRecordService,
    private translateService: TranslateService
  ) {
  }

  ngOnInit() {
    this.loadHistoryTypes();
  }

  ngAfterViewInit(): void {
  }

  initComponent() {
    this.loadList();
  }

  loadList(pageNumber?: number) {
    if (!this.customerId || !this.customerRecordId) {
      return;
    }
    const requestedPage = pageNumber ? pageNumber : this.queryModel.currentPage;
    const order = this.queryModel.getOrder();
    this.customerRecordService.history({
      customerId: this.customerId,
      customerRecordId: this.customerRecordId,
      withRead: true!,
      orders: Set.of(order),
      paging: requestedPage ? {
        pageNumber: requestedPage,
        numberOfItems: this.queryModel.itemsPerPage
      } : undefined
    }).subscribe((result: QueryResult<CustomerRecord.HistoryItem>) => {
      this.customerRecordHistoryList = [];
      result.items.forEach((customerRecordHistory: CustomerRecord.HistoryItem) => {
        const customerRecordHistoryModel = new CustomerRecordHistoryModel();
        customerRecordHistoryModel.id = customerRecordHistory.id;
        customerRecordHistoryModel.creationTime = customerRecordHistory.creationTime.toUtcIsoString();
        customerRecordHistoryModel.type = customerRecordHistory.type;
        customerRecordHistoryModel.typeString = this.getHistoryType(customerRecordHistory.type);
        customerRecordHistoryModel.changeLog = customerRecordHistory.changeLog;
        customerRecordHistoryModel.user = customerRecordHistory.user;
        customerRecordHistoryModel.applicationClassification = customerRecordHistory.applicationClassification
          ? 'HISTORY_APPLICATION_CLASS_TYPE_' + customerRecordHistory.applicationClassification
          : '';
        customerRecordHistoryModel.applicationId =
          customerRecordHistory.mobileApplication ? customerRecordHistory.mobileApplication.applicationId : '';
        this.customerRecordHistoryList.push(customerRecordHistoryModel);
      });
      this.queryModel.currentPage = requestedPage;
      this.queryModel.totalNumberOfItems = result.pagingResult.totalNumberOfItems;
      this.queryModel.currentNumberOfItems = result.pagingResult.currentNumberOfItems;
    });
  }

  orderBy(field: CustomerRecord.OrderField) {
    this.queryModel.onOrderFieldChanged(field);
    this.loadList(1);
  }

  pageChanged(selectedPage: number) {
    this.loadList(selectedPage);
  }

  itemsPerPageChanged(itemsPerPage: number) {
    this.queryModel.itemsPerPage = itemsPerPage;
    this.loadList(1);
  }

  openDetailDialog(selectedCustomerRecordHistory: CustomerRecord.HistoryItem) {
    this.openHistoryDetail.emit(selectedCustomerRecordHistory.changeLog);
  }

  getHistoryType(selectedType: CustomerRecordHistoryType): string {
    let text = '';
    this.historyTypes.forEach((type: CustomerRecordHistoryTypeItem) => {
      if (type.id === selectedType) {
        text = type.text;
      }
    });
    return text;
  }

  private loadHistoryTypes() {
    this.historyTypes = [];
    CustomerRecord.customerRecordHistoryTypes.forEach((type) => {
      const item = {
        id: type.type,
        text: '....'
      };
      this.historyTypes.push(item);
      this.translateService.get(type.stringKey).subscribe((text: string) => {
        item.text = text;
      })
    });
  }

}

class CustomerRecordHistoryModel {
  id: number;
  creationTime: string = '';
  type: CustomerRecordHistoryType;
  typeString: string = '';
  changeLog: string = '';
  user: UserItem;
  applicationClassification: string = '';
  applicationId: string = '';
}

export class CustomerRecordHistoryTypeItem extends OptionItem<CustomerRecordHistoryType> {
}
