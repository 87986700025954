/* eslint-disable */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DqlSearchFieldComponent } from '../dql-search-field.component';
import { SimpleDqlExpressionBuilder } from '../../dql-search-container/dql-search-container.model';
import { TranslateUtils } from '../../../../util/translate';
import { SearchItem } from '../dql-search-field-list/dql-search-field-list.model';
import { TranslateService } from '@ngx-translate/core';
import { Angular2Multiselects } from '../../../../util/multiselect';

/* eslint-enable */

@Component({
  selector: 'app-dql-search-field-boolean',
  templateUrl: './dql-search-field-boolean.component.html',
  styleUrls: ['./dql-search-field-boolean.component.scss']
})
export class DqlSearchFieldBooleanComponent implements OnInit, DqlSearchFieldComponent {
  @Input()
  disableDelete: boolean = false;

  @Input()
  expression: SimpleDqlExpressionBuilder;

  @Output()
  onDelete: EventEmitter<SimpleDqlExpressionBuilder> = new EventEmitter<SimpleDqlExpressionBuilder>();

  items: SearchItem[] = [];
  selectedValue: SearchItem[] = [];

  dropdownSettings?: Angular2Multiselects.Settings;

  constructor(private translateService: TranslateService) {
  }

  ngOnInit() {
    this.initDropDown();

    if (this.expression.criteria.field) {
      const stringKeys: string[] = ['COMMON_VALUE_BOOLEAN_FALSE', 'COMMON_VALUE_BOOLEAN_TRUE'];
      this.translateService.get(stringKeys).subscribe((texts) => {
        this.items.push({
          id: 'false',
          itemName: TranslateUtils.extractValueFromObject(texts, 'COMMON_VALUE_BOOLEAN_FALSE')
        });
        this.items.push({
          id: 'true',
          itemName: TranslateUtils.extractValueFromObject(texts, 'COMMON_VALUE_BOOLEAN_TRUE')
        });
        const value = this.expression.criteria.getValue();
        if (value.length > 0) {
          this.selectedValue = [];
          this.selectedValue.push(this.items.find(i => i.id === value)!);
        }
      });
    }
  }

  initDropDown() {
    this.dropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(true)
      .enableSearchFilter(false)
      .enableCheckAll(false)
      .build();
  }

  onItemSelect(item: SearchItem) {
    this.expression.criteria.setValue(item.id);
  }

  onItemDeselect(item: SearchItem) {
    this.expression.criteria.setValue('');
  }
}
