<div bsModal [config]="UiConstants.modalConfig" #fieldDialog="bs-modal" (onHide)="onModalHide($event)"
     class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content" *ngIf="visible">
      <div class="modal-header">
        <h4 class="modal-title">{{dialogTitleDictionaryKey | translate}}</h4>
        <button type="button" class="close" (click)="closeDialog()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" [formGroup]="formGroup">
        <div class="card-body p-0">

          <h6 class="mb-1">{{'COMMON_BASIC_DATA' | translate}}</h6>

          <div class="row">

            <!-- region Title -->
            <div class="form-group col-md-6"
                 [ngClass]="{ 'has-danger': hasFieldError(Form.FieldValidatedField.TITLE) || hasLocalFieldError('title')}">
              <label>{{'COMMON_NAME' | translate}}</label>
              <input type="text" class="form-control" placeholder="{{'COMMON_NAME' | translate}}"
                     maxlength="{{UiConstants.maximumVarcharLength}}"
                     [ngClass]="{ 'form-control-danger': hasFieldError(Form.FieldValidatedField.TITLE) || hasLocalFieldError('title') }"
                     [(ngModel)]="fieldModel.title" formControlName="title"
                     (ngModelChange)="removeFieldError(Form.FieldValidatedField.TITLE)"
                     (blur)="onTitleBlur()"
                     trim="blur" validateOnBlur [validateFormControl]="formGroup.controls['title']">
              <div class="form-control-feedback" *ngIf="hasFieldError(Form.FieldValidatedField.TITLE)">
                {{getFieldErrorText(Form.FieldValidatedField.TITLE)}}
              </div>
              <div class="form-control-feedback" *ngIf="hasLocalFieldError('title')">
                <span *ngIf="hasLocalFieldError('title', 'required')">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</span>
              </div>
            </div>
            <!-- endregion Title -->

            <!-- region field width -->
            <div class="form-group col-md-3">
              <label>{{'FORM_ITEM_COMMON_FIELD_WIDTH' | translate}}</label>
              <select class="form-control"
                      [ngModelOptions]="{standalone: true}"
                      [(ngModel)]="fieldModel.fieldWidthType">
                <option *ngFor="let widthType of fieldWidthTypes" [ngValue]="widthType">{{widthType.textDictionaryKey | translate}}</option>
              </select>
            </div>
            <!-- endregion field width -->

            <!-- region display on new row -->
            <div class="form-group col-md-3">
              <label>{{'FORM_ITEM_COMMON_DISPLAY_ON_NEW_ROW' | translate}}</label>
              <br>
              <label class="switch switch-text switch-info mb-0">
                <input type="checkbox" class="switch-input"
                       [ngModelOptions]="{standalone: true}"
                       [(ngModel)]="fieldModel.displayOnNewRow">
                <span class="switch-label" data-on="&#xe957;" data-off="&#xe98e;"></span>
                <span class="switch-handle"></span>
              </label>
            </div>
            <!-- endregion display on new row -->

          </div> <!-- ./row end -->

          <div class="row">

            <!-- region Field validation type (admin) -->
            <div class="form-group col-md-3">
              <label>{{'FORM_ITEM_COMMON_ADMIN_FIELD_VALIDATION_TYPE' | translate}}</label>
              <select class="form-control"
                      [(ngModel)]="fieldModel.adminFormFieldValidationType"
                      [ngModelOptions]="{standalone: true}"
                      (change)="onAdminFormFieldValidationTypeChanged()">
                <option *ngFor="let vt of fieldValidationTypes" [ngValue]="vt">{{getFieldValidationTypeDictionaryKey(vt) | translate}}</option>
              </select>
            </div>
            <!-- endregion Field validation type (admin) -->

            <!-- region Field validation type (mobile) -->
            <div class="form-group col-md-3">
              <label>{{'FORM_ITEM_COMMON_MOBILE_FIELD_VALIDATION_TYPE' | translate}}</label>
              <select class="form-control"
                      [(ngModel)]="fieldModel.mobileFormFieldValidationType"
                      [ngModelOptions]="{standalone: true}"
                      (change)="onMobileFormFieldValidationTypeChanged()">
                <option *ngFor="let vt of fieldValidationTypes" [ngValue]="vt">{{getFieldValidationTypeDictionaryKey(vt) | translate}}</option>
              </select>
            </div>
            <!-- endregion Field validation type (mobile) -->

            <!-- region Field validation type (helpdesk) -->
            <div class="form-group col-md-3">
              <label>{{'FORM_ITEM_COMMON_HELPDESK_FIELD_VALIDATION_TYPE' | translate}}</label>
              <select class="form-control"
                      [(ngModel)]="fieldModel.helpdeskFormFieldValidationType"
                      [ngModelOptions]="{standalone: true}"
                      #helpdeskFormFieldValidationType="ngModel" id="helpdeskFormFieldValidationType" name="helpdeskFormFieldValidationType">
                <option *ngFor="let vt of fieldValidationTypes" [ngValue]="vt">{{getFieldValidationTypeDictionaryKey(vt) | translate}}</option>
              </select>
            </div>
            <!-- endregion Field validation type (helpdesk) -->

            <!-- region visible on master detail (mobile) -->
            <div class="form-group col-md-4">
              <label>{{'FORM_ITEM_COMMON_MOBILE_VISIBLE_ON_MASTER_DETAIL' | translate}}</label>
              <br>
              <label class="switch switch-text switch-info mb-0">
                <input type="checkbox" class="switch-input"
                       [(ngModel)]="fieldModel.mobileVisibleOnMasterDetail"
                       [ngModelOptions]="{standalone: true}">
                <span class="switch-label" data-on="&#xe957;" data-off="&#xe98e;"></span>
                <span class="switch-handle"></span>
              </label>
            </div>
            <!-- endregion visible on master detail (mobile) -->

          </div> <!-- ./row end -->

          <div class="row">

            <!-- region API export name -->
            <div class="form-group col-md-6" [ngClass]="{ 'has-danger': hasFieldError(Form.FieldValidatedField.API_EXPORT_NAME) || hasLocalFieldError('apiExportName')}">
              <label>{{'FORM_ITEM_COMMON_EXPORT_NAME' | translate}}</label>
              <input type="text" class="form-control"
                     maxlength="{{UiConstants.maximumVarcharLength}}"
                     [ngClass]="{ 'form-control-danger': hasFieldError(Form.FieldValidatedField.API_EXPORT_NAME) || hasLocalFieldError('apiExportName') }"
                     [(ngModel)]="fieldModel.apiExportName"
                     formControlName="apiExportName"
                     (ngModelChange)="removeFieldError(Form.FieldValidatedField.API_EXPORT_NAME)"
                     placeholder="{{'FORM_ITEM_COMMON_EXPORT_NAME' | translate}}"
                     trim="blur" validateOnBlur [validateFormControl]="formGroup.controls['apiExportName']"
                     >
              <div class="form-control-feedback" *ngIf="hasFieldError(Form.FieldValidatedField.API_EXPORT_NAME)">
                {{getFieldErrorText(Form.FieldValidatedField.API_EXPORT_NAME)}}
              </div>
              <div class="form-control-feedback" *ngIf="hasLocalFieldError('apiExportName')">
                {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
              </div>
            </div>
            <!-- endregion API export name -->

            <!-- region PDF export name -->
            <div class="form-group col-md-6" [ngClass]="{ 'has-danger': hasFieldError(Form.FieldValidatedField.PDF_EXPORT_NAME) }">
              <label>{{'FORM_ITEM_COMMON_PDF_EXPORT_NAME' | translate}}</label>
              <input type="text" class="form-control"
                     maxlength="{{UiConstants.maximumVarcharLength}}"
                     [ngModelOptions]="{standalone: true}"
                     [(ngModel)]="fieldModel.pdfExportName"
                     (ngModelChange)="removeFieldError(Form.FieldValidatedField.PDF_EXPORT_NAME)"
                     placeholder="{{'FORM_ITEM_COMMON_PDF_EXPORT_NAME' | translate}}"
                     [ngClass]="{ 'form-control-danger': hasFieldError(Form.FieldValidatedField.PDF_EXPORT_NAME) }">
              <div class="form-control-feedback" *ngIf="hasFieldError(Form.FieldValidatedField.PDF_EXPORT_NAME)">
                {{getFieldErrorText(Form.FieldValidatedField.PDF_EXPORT_NAME)}}
              </div>
            </div>
            <!-- endregion PDF export name -->

          </div> <!-- ./row end -->

          <div class="row">

            <!-- region placeholder -->
            <div class="form-group col-md-6">
              <label>{{'FORM_ITEM_COMMON_PLACEHOLDER' | translate}}</label>
              <input type="text" class="form-control"
                     maxlength="{{UiConstants.maximumVarcharLength}}"
                     [ngModelOptions]="{standalone: true}"
                     [(ngModel)]="fieldModel.placeholder"
                     placeholder="{{'FORM_ITEM_COMMON_PLACEHOLDER' | translate}}">
            </div>
            <!-- endregion placeholder -->

            <!-- region default value -->
            <div class="form-group col-md-6" [ngClass]="{ 'has-danger': hasFieldError(Form.FieldValidatedField.DEFAULT_VALUE) || hasLocalFieldError('defaultDateValue') || hasLocalFieldError('defaultTimeValue') || hasLocalFormError('validateOffsetDateTimeRange') }">
              <label>{{'FORM_ITEM_COMMON_DEFAULT_VALUE' | translate}}</label>
              <div class="input-group">
                <div class="row">

                  <div class="input-group col-md-6">
                    <input placeholder="{{'FORM_ITEM_COMMON_DEFAULT_VALUE' | translate}}" type="text" class="form-control"
                           disabled="{{isDefaultDateDisabled()}}"
                           [(ngModel)]="fieldModel.defaultDateValue"
                           formControlName="defaultDateValue"
                           ngbDatepicker #date_default_date="ngbDatepicker"
                           (ngModelChange)="removeFieldError(Form.FieldValidatedField.DEFAULT_VALUE)"
                           [ngClass]="{ 'form-control-danger': hasFieldError(Form.FieldValidatedField.DEFAULT_VALUE) || hasLocalFieldError('defaultDateValue') || hasLocalFieldError('defaultTimeValue') || hasLocalFormError('validateOffsetDateTimeRange') }"
                           validateLocalDate>
                    <span *ngIf="!readonly" class="add-on input-group-addon-gray" (click)="date_default_date.toggle()">
                        <i class="icomoon icomoon-calendar"></i>
                       </span>
                  </div>

                  <div class="input-group col-md-6">
                    <input placeholder="{{'DATE_TIME_PICKER_PLACEHOLDER_TIME' | translate}}" type="text" class="form-control hidden-value-when-disabled"
                           [(ngModel)]="fieldModel.defaultTimeValue"
                           formControlName="defaultTimeValue"
                           (ngModelChange)="removeFieldError(Form.FieldValidatedField.DEFAULT_VALUE)"
                           [ngClass]="{ 'form-control-danger': hasFieldError(Form.FieldValidatedField.DEFAULT_VALUE) || hasLocalFieldError('defaultDateValue') || hasLocalFieldError('defaultTimeValue') || hasLocalFormError('validateOffsetDateTimeRange') }"
                           [seconds]="false"
                           [disabled]="fieldModel.defaultDateValue === null || isDefaultDateDisabled()"
                           [allowEmptyValue]="false"
                           ngbTimepicker #time_default_time="ngbTimepicker" [toggleButtonId]="'default_time_toggle_button'"
                           [spinners]="true" [hourStep]="1" [minuteStep]="10">
                    <span class="add-on input-group-addon-gray"
                          id="default_time_toggle_button"
                          (click)="time_default_time.toggle()">
                        <i class="icomoon icomoon-time"></i>
                      </span>
                  </div>

                </div>
              </div>
              <div class="form-control-feedback" *ngIf="hasFieldError(Form.FieldValidatedField.DEFAULT_VALUE)">
                {{getFieldErrorText(Form.FieldValidatedField.DEFAULT_VALUE)}}
              </div>
              <div class="form-control-feedback" *ngIf="hasLocalFieldError('defaultDateValue') || hasLocalFieldError('defaultTimeValue') || hasLocalFormError('validateOffsetDateTimeRange')">
                <span *ngIf="hasLocalFieldError('defaultDateValue', 'validateNgbDateTime') || hasLocalFieldError('defaultTimeValue', 'validateNgbDateTime')">{{'COMMON_VALIDATION_MESSAGE_INVALID_LOCAL_DATE' | translate}}</span>
                <span *ngIf="hasLocalFormError('validateOffsetDateTimeRange')">{{'COMMON_VALIDATION_MESSAGE_INVALID_LOCAL_DATE_RANGE' | translate}}</span>
              </div>
            </div>
            <!-- endregion default value -->

          </div>

          <div class="row">

            <!-- region Description -->
            <div class="form-group col-md-6">
              <label>{{'COMMON_DESCRIPTION' | translate}}</label>
              <input type="text" class="form-control"
                     maxlength="{{UiConstants.maximumVarcharLength}}"
                     [ngModelOptions]="{standalone: true}"
                     [(ngModel)]="fieldModel.description"
                     placeholder="{{'COMMON_DESCRIPTION' | translate}}">
            </div>
            <!-- endregion Description -->

            <!-- region hint -->
            <div class="form-group col-md-6">
              <label>{{'FORM_ITEM_COMMON_HINT' | translate}}</label>
              <textarea rows="1" class="form-control" [(ngModel)]="fieldModel.hint"
                        [ngModelOptions]="{standalone: true}"
                        placeholder="{{'FORM_ITEM_COMMON_HINT' | translate}}"></textarea>
            </div>
            <!-- endregion hint -->

          </div>

          <h6 class="mb-1 mt-1">{{'FORM_ITEM_CREATE_DOCUMENT_EXTRA_DATA' | translate}}</h6>

          <div class="row">

            <!-- region min value -->
            <div class="form-group col-md-6" [ngClass]="{ 'has-danger': hasFieldError(Form.FieldValidatedField.MIN_VALUE) || hasLocalFieldError('minDateValue') || hasLocalFieldError('minTimeValue') || hasLocalFormError('validateOffsetDateTimeFromTo')}">
              <label>{{'FORM_ITEM_CREATE_DATE_MIN_DATE' | translate}}</label>
              <div class="input-group">
                <div class="row">

                  <div class="input-group col-md-6">
                    <input placeholder="{{'FORM_ITEM_CREATE_DATE_MIN_DATE' | translate}}" type="text" class="form-control"
                           disabled="{{isMinValueDisabled()}}"
                           [(ngModel)]="fieldModel.minDateValue"
                           formControlName="minDateValue"
                           ngbDatepicker #date_min_date="ngbDatepicker"
                           (ngModelChange)="removeFieldError(Form.FieldValidatedField.MIN_VALUE)"
                           [ngClass]="{ 'form-control-danger': hasFieldError(Form.FieldValidatedField.MIN_VALUE) || hasLocalFieldError('minDateValue') || hasLocalFieldError('minTimeValue') || hasLocalFormError('validateOffsetDateTimeFromTo')}"
                           validateLocalDate>
                    <span *ngIf="!readonly" class="add-on input-group-addon-gray" (click)="date_min_date.toggle()">
                        <i class="icomoon icomoon-calendar"></i>
                       </span>
                  </div>

                  <div class="input-group col-md-6">
                    <input placeholder="{{'DATE_TIME_PICKER_PLACEHOLDER_TIME' | translate}}" type="text" class="form-control hidden-value-when-disabled"
                           [(ngModel)]="fieldModel.minTimeValue"
                           formControlName="minTimeValue"
                           (ngModelChange)="removeFieldError(Form.FieldValidatedField.MIN_VALUE)"
                           [ngClass]="{ 'form-control-danger': hasFieldError(Form.FieldValidatedField.MIN_VALUE) || hasLocalFieldError('minDateValue') || hasLocalFieldError('minTimeValue') || hasLocalFormError('validateOffsetDateTimeFromTo')}"
                           [seconds]="false"
                           [disabled]="fieldModel.minDateValue === null || isMinValueDisabled()"
                           [allowEmptyValue]="false"
                           ngbTimepicker #time_min_time="ngbTimepicker" [toggleButtonId]="'min_time_toggle_button'"
                           [spinners]="true" [hourStep]="1" [minuteStep]="10">
                    <span class="add-on input-group-addon-gray"
                          id="min_time_toggle_button"
                          (click)="time_min_time.toggle()">
                        <i class="icomoon icomoon-time"></i>
                      </span>
                  </div>

                </div>
              </div>
              <div class="form-control-feedback" *ngIf="hasFieldError(Form.FieldValidatedField.MIN_VALUE)">
                {{getFieldErrorText(Form.FieldValidatedField.MIN_VALUE)}}
              </div>
              <div class="form-control-feedback" *ngIf="hasLocalFieldError('minDateValue') || hasLocalFieldError('minTimeValue') || hasLocalFormError('validateOffsetDateTimeFromTo')">
                <span *ngIf="hasLocalFieldError('minDateValue', 'validateNgbDateTime') || hasLocalFieldError('minTimeValue', 'validateNgbDateTime')">{{'COMMON_VALIDATION_MESSAGE_INVALID_LOCAL_DATE' | translate}}</span>
                <span *ngIf="hasLocalFieldError('minDateValue', 'validateMaxNgbDateTime') || hasLocalFieldError('minTimeValue', 'validateMaxNgbDateTime')">{{'COMMON_VALIDATION_MESSAGE_FUTURE_IS_NOT_VALID' | translate}}</span>
                <span *ngIf="hasLocalFormError('validateOffsetDateTimeFromTo')">{{'COMMON_VALIDATION_MESSAGE_INVALID_LOCAL_DATE_FROM_TO' | translate}}</span>
              </div>
            </div>
            <!-- endregion min value -->

            <!-- region max value -->
            <div class="form-group col-md-6" [ngClass]="{ 'has-danger': hasFieldError(Form.FieldValidatedField.MAX_VALUE) || hasLocalFieldError('maxDateValue') || hasLocalFieldError('maxTimeValue') || hasLocalFormError('validateOffsetDateTimeFromTo') }">
              <label>{{'FORM_ITEM_CREATE_DATE_MAX_DATE' | translate}}</label>
              <div class="input-group">
                <div class="row">

                  <div class="input-group col-md-6">
                    <input placeholder="{{'FORM_ITEM_CREATE_DATE_MAX_DATE' | translate}}" type="text" class="form-control"
                           disabled="{{isMaxValueDisabled()}}"
                           [(ngModel)]="fieldModel.maxDateValue"
                           formControlName="maxDateValue"
                           ngbDatepicker #date_max_date="ngbDatepicker"
                           (ngModelChange)="removeFieldError(Form.FieldValidatedField.MAX_VALUE)"
                           [ngClass]="{ 'form-control-danger': hasFieldError(Form.FieldValidatedField.MAX_VALUE) || hasLocalFieldError('maxDateValue') || hasLocalFieldError('maxTimeValue') || hasLocalFormError('validateOffsetDateTimeFromTo') }"
                           validateLocalDate>
                    <span *ngIf="!readonly" class="add-on input-group-addon-gray" (click)="date_max_date.toggle()">
                        <i class="icomoon icomoon-calendar"></i>
                       </span>
                  </div>

                  <div class="input-group col-md-6">
                    <input placeholder="{{'DATE_TIME_PICKER_PLACEHOLDER_TIME' | translate}}" type="text" class="form-control hidden-value-when-disabled"
                           [(ngModel)]="fieldModel.maxTimeValue"
                           formControlName="maxTimeValue"
                           (ngModelChange)="removeFieldError(Form.FieldValidatedField.MAX_VALUE)"
                           [ngClass]="{ 'form-control-danger': hasFieldError(Form.FieldValidatedField.MIN_VALUE) || hasLocalFieldError('minDateValue') || hasLocalFieldError('minTimeValue') || hasLocalFormError('validateOffsetDateTimeFromTo')}"
                           [seconds]="false"
                           [disabled]="fieldModel.maxDateValue === null || isMaxValueDisabled()"
                           [allowEmptyValue]="false"
                           ngbTimepicker #time_max_time="ngbTimepicker" [toggleButtonId]="'max_time_toggle_button'"
                           [spinners]="true" [hourStep]="1" [minuteStep]="10">
                    <span class="add-on input-group-addon-gray"
                          id="max_time_toggle_button"
                          (click)="time_max_time.toggle()">
                        <i class="icomoon icomoon-time"></i>
                      </span>
                  </div>

                </div>
              </div>
              <div class="form-control-feedback" *ngIf="hasFieldError(Form.FieldValidatedField.MAX_VALUE)">
                {{getFieldErrorText(Form.FieldValidatedField.MAX_VALUE)}}
              </div>
              <div class="form-control-feedback" *ngIf="hasLocalFieldError('maxDateValue') || hasLocalFieldError('maxTimeValue') || hasLocalFormError('validateOffsetDateTimeFromTo')">
                <span *ngIf="hasLocalFieldError('maxDateValue', 'validateNgbDateTime') || hasLocalFieldError('maxTimeValue', 'validateNgbDateTime')">{{'COMMON_VALIDATION_MESSAGE_INVALID_LOCAL_DATE' | translate}}</span>
                <span *ngIf="hasLocalFormError('validateOffsetDateTimeFromTo')">{{'COMMON_VALIDATION_MESSAGE_INVALID_LOCAL_DATE_FROM_TO' | translate}}</span>
              </div>
            </div>
            <!-- endregion max value -->

          </div> <!-- ./row end -->

          <div class="row">
            <!-- region default today-->
            <div class="form-group col-md-3">
              <label>{{'FORM_FIELD_DEFAULT_TODAY' | translate}}</label>
              <br>
              <label class="switch switch-text switch-info mb-0">
                <input type="checkbox" class="switch-input"
                       [ngModelOptions]="{standalone: true}"
                       [(ngModel)]="fieldModel.defaultToday"
                       (change)="dateDefaultTodayChanged()">
                <span class="switch-label" data-on="&#xe957;" data-off="&#xe98e;"></span>
                <span class="switch-handle"></span>
              </label>
            </div>
            <!-- endregion default today-->

            <!-- region disable past-->
            <div class="form-group col-md-3">
              <label>{{'FORM_FIELD_DISABLE_PAST_DATE' | translate}}</label>
              <br>
              <label class="switch switch-text switch-info mb-0">
                <input type="checkbox" class="switch-input"
                       [ngModelOptions]="{standalone: true}"
                       [(ngModel)]="fieldModel.disablePast"
                       (change)="dateDisablePastChanged()">
                <span class="switch-label" data-on="&#xe957;" data-off="&#xe98e;"></span>
                <span class="switch-handle"></span>
              </label>
            </div>
            <!-- endregion disable past-->

            <!-- region disable future-->
            <div class="form-group col-md-3">
              <label>{{'FORM_FIELD_DISABLE_FUTURE_DATE' | translate}}</label>
              <br>
              <label class="switch switch-text switch-info mb-0">
                <input type="checkbox" class="switch-input"
                       [ngModelOptions]="{standalone: true}"
                       [(ngModel)]="fieldModel.disableFuture"
                       (change)="dateDisableFutureChanged()"
                       disabled="{{isDisableFutureDisabled()}}">
                <span class="switch-label" data-on="&#xe957;" data-off="&#xe98e;"></span>
                <span class="switch-handle"></span>
              </label>
            </div>
            <!-- endregion disable future-->

            <!-- region show on receipt -->
            <div class="form-group col-md-3">
              <label>{{'FORM_ITEM_COMMON_SHOW_ON_RECEIPT' | translate}}
                <i class="icomoon icomoon-info info-icon" popover="{{'FORM_EDIT_SHOW_ON_RECEIPT_INFO' | translate}}"
                   triggers="mouseenter:mouseleave" container="body" placement="right"></i></label>
              <br>
              <label class="switch switch-text switch-info mb-0">
                <input type="checkbox" class="switch-input" [(ngModel)]="fieldModel.showOnReceipt"
                       #showOnReceipt="ngModel" id="showOnReceipt" name="showOnReceipt">
                <span class="switch-label" data-on="&#xe957;" data-off="&#xe98e;"></span>
                <span class="switch-handle"></span>
              </label>
            </div>
            <!-- endregion show on receipt -->

          </div>

        </div> <!-- ./card-body end -->
      </div>

      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="closeDialog()">{{'COMMON_BUTTON_CANCEL' | translate}}</button>
        <button type="button" class="btn btn-primary" #functionButton (click)="saveField()">{{'COMMON_BUTTON_SAVE' | translate}}</button>
      </div>

    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div><!-- /.modal -->
