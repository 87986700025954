/* eslint-disable */
import { AfterViewInit, Component, ViewChild, } from '@angular/core';
import {
  FormEditFieldCreateDialogView,
  FormEditFieldDialogContext,
  FormEditFieldUpdateDialogView,
  FormGroupModel,
  FormModel,
} from '../../../../../../../util/form/form-utils';
import { InputMask } from '../../../../../../../util/input-masks';
import { Form } from '../../../../../../../lib/form/form.service';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { FieldWidthType } from '../../../../../../../util/form/form-field-width-type';
import {
  FieldValidationError,
  FormRef,
  LocalFieldValidationErrors,
  LocalFieldValidationErrorsFactory,
  OrderType,
  QueryResult,
} from '../../../../../../../lib/util/services';
import { NgModel } from '@angular/forms';
import { List, Set } from 'immutable';
import { OptionItem, TypeItem, UiConstants, } from '../../../../../../../util/core-utils';
import { FormTable, FormTableService } from '../../../../../../../lib/form/form-table.service';
import {
  FieldValidationType,
  FormFieldValidationTypeModel,
} from '../../../../../../../util/form/form-field-validation-type';
import { Models } from '../../../../../../../util/model-utils';
import { Angular2Multiselects } from '../../../../../../../util/multiselect';
import { Strings } from '../../../../../../../lib/util/strings';

/* eslint-enable */

@Component({
  selector: 'app-form-edit-create-update-form-table-field-dialog',
  templateUrl: 'form-edit-create-update-form-table-field-dialog.component.html',
  styleUrls: ['form-edit-create-update-form-table-field-dialog.component.scss'],
})
export class FormEditCreateUpdateFormTableFieldDialogComponent
  implements FormEditFieldCreateDialogView, FormEditFieldUpdateDialogView, FormRef, AfterViewInit {

  public readonly selector = Form.FieldDataTypeSelector.FORM_TABLE;
  public readonly createTitle = 'FORM_ITEM_CREATE_FORM_TABLE_TITLE';
  public readonly updateTitle = 'FORM_ITEM_UPDATE_FORM_TABLE_TITLE';
  public readonly cloneTitle = 'FORM_ITEM_CLONE_FORM_TABLE_TITLE';

  InputMask = InputMask;
  Form = Form;
  UiConstants = UiConstants;

  @ViewChild('fieldDialog', { static: true })
  dialogComponent: ModalDirective;

  @ViewChild('title')
  titleInput: NgModel;

  @ViewChild('api_export_name')
  apiExportNameInput: NgModel;

  @ViewChild('formTable')
  formTableInput: NgModel;

  visible: boolean = false;
  fieldModel: Model = new Model();
  dialogTitleDictionaryKey: string = '';
  latestApiExportName = '';

  fieldWidthTypes: FieldWidthType[] = [FieldWidthType.FULL_SCREEN];
  fieldValidationTypes: Form.FormFieldValidationType[] = FieldValidationType.VALUES.toArray();

  private fieldErrors: FieldValidationError<Form.FieldValidatedField> =
    FieldValidationError.empty<Form.FieldValidatedField>();

  private validatedInputs: LocalFieldValidationErrors<NgModel> =
    LocalFieldValidationErrorsFactory.empty();

  private context: FormEditFieldDialogContext;
  private group: FormGroupModel;
  private field?: Form.Field;
  private clone?: boolean;
  private submitted: boolean = false;

  availableItems: TypeItem[] = [];

  dropdownSettings?: Angular2Multiselects.Settings;

  getForm() {
    return {
      submitted: this.submitted
    };
  }

  public constructor(private formTableService: FormTableService) {
  }

  ngAfterViewInit(): void {
    this.loadLocalFieldValidationErrors();
    this.initDropdownSettings();
  }

  initDropdownSettings() {
    this.dropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(true)
      .enableSearchFilter(true)
      .enableCheckAll(false)
      .remoteSearch(true)
      .labelKey(OptionItem.KEY_TEXT)
      .build();
  }

  registerContext(context: FormEditFieldDialogContext): void {
    this.context = context;
  }

  showDialog(form: FormModel, group: FormGroupModel, field?: Form.Field, clone?: boolean): void {
    this.latestApiExportName = '';
    this.visible = true;
    this.clone = clone;
    this.loadFormTablesAndShowDialog(form, group, field);
  }

  onModalHide(event: ModalDirective) {
    if (event.dismissReason === 'backdrop-click' || event.dismissReason === 'esc') {
      this.visible = false;
      this.submitted = false;
      this.fieldModel = new Model();
    }
  }

  closeDialog(): void {
    this.visible = false;
    this.submitted = false;
    this.dialogComponent.hide();
    this.fieldModel = new Model();
    this.availableItems = [];
  }

  saveField() {
    this.submitted = true;
    if (this.hasLocalFieldError(this.titleInput)
      || this.hasLocalFieldError(this.formTableInput)
      || this.hasLocalFieldError(this.apiExportNameInput)) {
      return;
    }
    if (this.field && this.clone) {
      this.createField();
    }
    else if (this.field) {
      this.updateField(this.field);
    }
    else {
      this.createField();
    }
  }

  hasLocalFieldError(field?: NgModel): boolean {
    return this.validatedInputs.hasLocalError(field);
  }

  hasFieldError(field?: Form.FieldValidatedField): boolean {
    return this.fieldErrors.hasError(field);
  }

  removeFieldError(field: Form.FieldValidatedField) {
    this.fieldErrors = this.fieldErrors.removeError(field);
  }

  getFieldErrorText(field: Form.FieldValidatedField): string {
    return this.fieldErrors.getErrorText(field);
  }

  getFieldValidationTypeDictionaryKey(vt: Form.FormFieldValidationType): string {
    return FieldValidationType.getFieldValidationTypeDictionaryKey(vt);
  }

  onAdminFormFieldValidationTypeChanged() {
    FieldValidationType.onAdminFormFieldValidationTypeChanged(this.fieldModel);
  }

  onMobileFormFieldValidationTypeChanged() {
    FieldValidationType.onMobileFormFieldValidationTypeChanged(this.fieldModel);
  }

  private loadLocalFieldValidationErrors() {
    const validatedInputs = List.of(this.titleInput, this.formTableInput, this.apiExportNameInput);
    this.validatedInputs = LocalFieldValidationErrorsFactory.ofFields(this, validatedInputs);
  }

  private loadFormTablesAndShowDialog(form: FormModel, group: FormGroupModel, field?: Form.Field, clone?: boolean) {
    this.loadFormTables(() => {
      this.loadDialog(form, group, field, clone);
    })
  }

  private loadFormTables(completion: () => void, searchValue?: string) {
    this.formTableService.query({
      name: Strings.undefinedOrNonEmpty(searchValue),
      orders: Set.of({field: FormTable.OrderField.NAME, type: OrderType.ASC}),
      disabled: false,
      paging: {
        numberOfItems: UiConstants.autocompletePageSize,
        pageNumber: 1
      },
      noProgressBar: true
    }).subscribe(
      (result: QueryResult<FormTable.FormTable>) => {
        this.availableItems = [];
        result.items.forEach(item => {
          if (item) {
            this.availableItems.push({id: item.id, text: item.name, disabled: item.disabled});
          }
        });
        if (completion) {
          completion();
        }
      });
  }

  private loadDialog(form: FormModel, group: FormGroupModel, field?: Form.Field, clone?: boolean) {
    this.group = group;
    this.dialogComponent.show();
    if (field && clone) {
      this.dialogTitleDictionaryKey = this.updateTitle;
      this.loadField(field);
    }
    else if (field) {
      this.dialogTitleDictionaryKey = this.updateTitle;
      this.loadField(field);
    }
    else {
      this.dialogTitleDictionaryKey = this.createTitle;
    }
  }

  private loadField(field: Form.Field) {
    this.field = field;
    const attrs = field.dataType.formTableAttributes!;
    const formTable = this.getFormTableById(attrs.formTableId);
    const fieldWidth = FieldWidthType.fromPercentValue(field.formFieldWidthPercent);
    this.fieldModel.title = field.title;
    this.fieldModel.description = field.description ? field.description : '';
    this.fieldModel.apiExportName = field.apiExportName ? field.apiExportName : '';
    this.fieldModel.pdfExportName = field.pdfExportName ? field.pdfExportName : '';
    this.fieldModel.fieldWidthType = fieldWidth ? fieldWidth : FieldWidthType.DEFAULT;
    this.fieldModel.displayOnNewRow = field.displayOnNewRow;
    this.fieldModel.adminVisibleOnFormDetail = field.admin.visibleOnFormDetail;
    this.fieldModel.mobileVisibleOnFormDetail = field.mobile.visibleOnFormDetail;
    this.fieldModel.mobileVisibleOnMasterDetail = field.mobile.visibleOnMasterDetail;
    this.fieldModel.adminFormFieldValidationType = field.admin.validationType;
    this.fieldModel.helpdeskFormFieldValidationType = field.helpdesk.validationType;
    this.fieldModel.mobileFormFieldValidationType = field.mobile.validationType;
    this.fieldModel.showOnReceipt = field.showOnReceipt;
    this.fieldModel.formTable = [];
    if (formTable) {
      this.fieldModel.formTable.push(formTable)
    }
    else {
      this.formTableService.get({
        formTableId: attrs.formTableId
      }).subscribe(formTable => {
        if (formTable) {
          const item = new TypeItem();
          item.id = formTable.id;
          item.text = formTable.name;
          item.disabled = formTable.disabled;
          this.fieldModel.formTable.push(item);
          this.formTableInput.control.updateValueAndValidity();
        }
      })
    }
  }

  private getFormTableById(formTable: number): TypeItem | undefined {
    let item: TypeItem | undefined = undefined;
    this.availableItems.forEach(i => {
      if (i.id === formTable) {
        item = i;
      }
    });
    return item;
  }

  private createField() {
    this.context.formService.createField({
      parentId: this.context.parentId,
      groupId: this.group.groupId,
      title: this.fieldModel.title,
      description: this.fieldModel.description,
      pdfExportName: this.fieldModel.pdfExportName,
      apiExportName: this.fieldModel.apiExportName,
      dataTypeSelector: this.selector,
      dataType: this.getDataTypeRequest(),
      formFieldWidthPercent: this.fieldModel.fieldWidthType.percentValue,
      displayOnNewRow: this.fieldModel.displayOnNewRow,
      admin: {
        visibleOnFormDetail: this.fieldModel.adminVisibleOnFormDetail,
        validationType: this.fieldModel.adminFormFieldValidationType
      },
      helpdesk: {
        validationType: this.fieldModel.helpdeskFormFieldValidationType
      },
      mobile: {
        visibleOnFormDetail: this.fieldModel.mobileVisibleOnFormDetail,
        visibleOnMasterDetail: this.fieldModel.mobileVisibleOnMasterDetail,
        validationType: this.fieldModel.mobileFormFieldValidationType
      },
      showOnReceipt: this.fieldModel.showOnReceipt
    }).subscribe(
      (result) => {
        this.context.formView.reloadForm();
        this.closeDialog();
      },
      (error) => {
        if (error instanceof FieldValidationError) {
          this.fieldErrors = error.withFormRef(this);
        }
      }
    );
  }

  private updateField(field: Form.Field) {
    this.context.formService.updateField({
      parentId: this.context.parentId,
      groupId: this.group.groupId,
      fieldId: field.fieldId,
      title: this.fieldModel.title,
      description: this.fieldModel.description,
      pdfExportName: this.fieldModel.pdfExportName,
      apiExportName: this.fieldModel.apiExportName,
      dataTypeSelector: this.selector,
      dataType: this.getDataTypeRequest(),
      formFieldWidthPercent: this.fieldModel.fieldWidthType.percentValue,
      displayOnNewRow: this.fieldModel.displayOnNewRow,
      admin: {
        visibleOnFormDetail: this.fieldModel.adminVisibleOnFormDetail,
        validationType: this.fieldModel.adminFormFieldValidationType
      },
      helpdesk: {
        validationType: this.fieldModel.helpdeskFormFieldValidationType
      },
      mobile: {
        visibleOnFormDetail: this.fieldModel.mobileVisibleOnFormDetail,
        visibleOnMasterDetail: this.fieldModel.mobileVisibleOnMasterDetail,
        validationType: this.fieldModel.mobileFormFieldValidationType
      },
      showOnReceipt: this.fieldModel.showOnReceipt
    }).subscribe(
      (result) => {
        this.context.formView.reloadForm();
        this.closeDialog();
      },
      (error) => {
        if (error instanceof FieldValidationError) {
          this.fieldErrors = error.withFormRef(this);
        }
      }
    );
  }

  private getDataTypeRequest(): Form.FieldDataType {
    return {
      formTableAttributes: {
        formTableId: this.fieldModel.formTable[0]!.id!
      }
    };
  }

  onTitleBlur() {
    const normalizedText = Models.normalizeText(this.fieldModel.title);
    if (this.fieldModel.apiExportName === '') {
      this.fieldModel.apiExportName = normalizedText;
    }
    if (this.fieldModel.pdfExportName === '') {
      this.fieldModel.pdfExportName = normalizedText;
    }
  }
}

export class Model implements FormFieldValidationTypeModel {
  title: string = '';
  description: string = '';
  apiExportName: string = '';
  pdfExportName: string = '';
  fieldWidthType: FieldWidthType = FieldWidthType.FULL_SCREEN;
  displayOnNewRow: boolean = false;
  formTable: TypeItem[] = [];
  adminVisibleOnFormDetail: boolean = true;
  mobileVisibleOnFormDetail: boolean = true;
  mobileVisibleOnMasterDetail: boolean = false;
  adminFormFieldValidationType: Form.FormFieldValidationType = Form.FormFieldValidationType.OPTIONAL;
  helpdeskFormFieldValidationType: Form.FormFieldValidationType = Form.FormFieldValidationType.OPTIONAL;
  mobileFormFieldValidationType: Form.FormFieldValidationType = Form.FormFieldValidationType.OPTIONAL;
  showOnReceipt?: boolean = false;
}
