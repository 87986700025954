/* eslint-disable */
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
/* eslint-enable */

@Component({
  selector: 'app-json',
  templateUrl: './json.component.html',
  styleUrls: ['./json.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class JsonComponent {

  private static readonly highlightRegex =
    /("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g;

  private _content = '';
  private _rawContent = '';
  private _unknownContent = '';

  private _spacingLevel = 2;

  @Input()
  showUnknownContent = true;

  get unknownContent(): string {
    return this._unknownContent;
  }

  get content(): string {
    return this._content;
  }

  @Input()
  set content(json: string) {
    this._rawContent = json;
    const content = JsonComponent.toJson(json, this.spacingLevel);
    this.setContent(content);
  }

  get spacingLevel() {
    return this._spacingLevel;
  }

  @Input()
  set spacingLevel(spacingLevel: number) {
    this._spacingLevel = spacingLevel;
    const content = JsonComponent.toJson(this._rawContent, spacingLevel);
    this.setContent(content);
  }

  private static toJson(object: any, spacingLevel: number): Content {
    if (object === undefined) {
      return {
        unknown: '',
        json: ''
      };
    }
    let o;
    if (typeof object === 'string') {
      try {
        /* eslint-disable */
        o = JSON.parse(object);
        /* eslint-enable */
        if (o === undefined) {
          return {
            unknown: object,
            json: ''
          };
        }
      }
      catch (e) {
        return {
          unknown: object,
          json: ''
        };
      }
    }
    const json = JSON.stringify(o, undefined, spacingLevel);
    return {
        unknown: '',
        json: JsonComponent.syntaxHighlight(json)
    };
  }

  private static syntaxHighlight(json: string): string {
    return json
      .replace(/&/g, '&amp;')
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;')
      .replace(/\n/g, '<br>')
      .replace(JsonComponent.highlightRegex, function (match) {
        let cls = 'number';
        if (/^"/.test(match)) {
          if (/:$/.test(match)) {
            cls = 'key';
          }
          else {
            cls = 'string';
          }
        }
        else if (/true|false/.test(match)) {
          cls = 'boolean';
        }
        else if (/null/.test(match)) {
          cls = 'null';
        }
        return '<span class="' + cls + '">' + match + '</span>';
      });
  }

  private setContent(content: Content) {
    this._content = content.json;
    this._unknownContent = content.unknown;
  }

}

interface Content {
  json: string;
  unknown: string;
}
