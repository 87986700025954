/* eslint-disable */
import { Form } from '../../lib/form/form.service';
import { List } from 'immutable';
/* eslint-enable */

export class FieldValidationType {

  public static readonly VALUES: List<Form.FormFieldValidationType> = List.of(
    Form.FormFieldValidationType.OPTIONAL,
    Form.FormFieldValidationType.REQUIRED,
    Form.FormFieldValidationType.READONLY,
    Form.FormFieldValidationType.HIDDEN,
  );

  public static getFieldValidationTypeDictionaryKey(vt: Form.FormFieldValidationType): string {
    switch (vt) {
      case Form.FormFieldValidationType.READONLY:
        return 'COMMON_FORM_FIELD_VALIDATION_TYPE_READONLY';
      case Form.FormFieldValidationType.HIDDEN:
        return 'COMMON_FORM_FIELD_VALIDATION_TYPE_HIDDEN';
      case Form.FormFieldValidationType.REQUIRED:
        return 'COMMON_FORM_FIELD_VALIDATION_TYPE_REQUIRED';
      case Form.FormFieldValidationType.OPTIONAL:
        return 'COMMON_FORM_FIELD_VALIDATION_TYPE_OPTIONAL';
      default:
        throw new Error('Unhandled field validation type');
    }
  }

  static onAdminFormFieldValidationTypeChanged(model: FormFieldValidationTypeModel) {
    if (Form.FormFieldValidationType.OPTIONAL === model.mobileFormFieldValidationType
      && Form.FormFieldValidationType.REQUIRED === model.adminFormFieldValidationType) {
      model.mobileFormFieldValidationType = Form.FormFieldValidationType.REQUIRED;
    }
  }

  static onMobileFormFieldValidationTypeChanged(model: FormFieldValidationTypeModel) {
    if (Form.FormFieldValidationType.OPTIONAL === model.mobileFormFieldValidationType
      && Form.FormFieldValidationType.REQUIRED === model.adminFormFieldValidationType) {
      model.adminFormFieldValidationType = Form.FormFieldValidationType.OPTIONAL;
    }
  }

}

export interface FormFieldValidationTypeModel {
  adminFormFieldValidationType: Form.FormFieldValidationType,
  mobileFormFieldValidationType: Form.FormFieldValidationType
}
