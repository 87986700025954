/* eslint-disable */
import { Component, OnInit } from '@angular/core';
import { SelectUtils } from '../../util/core-utils';
import { ConfigurationResource, ConfigurationService } from '../../lib/core-ext/configuration.service';
import { SiteTourId } from '../../lib/site-tour/site-tour.factory';
/* eslint-enable */

@Component({
  selector: 'app-dashboard',
  templateUrl: 'dashboard.component.html'
})
export class DashboardComponent implements OnInit {

  SelectUtils = SelectUtils;

  selectableDashboards: SelectableDashboard[] = [];

  selectedDashboard: SelectableDashboard = 'UNKNOWN';

  configuration: ConfigurationResource.Configuration;

  constructor(private configurationService: ConfigurationService) {
    this.configuration = this.configurationService.getConfiguration();
  }

  ngOnInit(): void {
    if (ConfigurationService.isEnabledByServer('TASKS', this.configuration)) {
      this.selectableDashboards.push('APPWORKS');
    }
    if (ConfigurationService.isEnabledByServer('TRANSPORT', this.configuration)) {
      this.selectableDashboards.push('ANYSPED');
    }
    this.selectedDashboard = this.selectableDashboards[0];
  }

  get appworksFeatures() {
    return this.selectedDashboard === 'APPWORKS';
  }

  get anyspedFeatures() {
    return this.selectedDashboard === 'ANYSPED';
  }

  get featureSelector() {
    return this.selectableDashboards.length > 1;
  }

}

type SelectableDashboard = 'HELPAPP' | 'APPWORKS' | 'ANYSPED' | 'UNKNOWN';
