/* eslint-disable */
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { AuthService } from '../../../../lib/auth.service';
import { Strings } from '../../../../lib/util/strings';
import { User, UserService } from '../../../../lib/user.service';
import { AuditLog, AuditLogService } from '../../../../lib/statistics/audit-log/audit-log.service';
import { AuditLogDetailModel } from '../../../../util/audit-log-utils';
import { Dates } from '../../../../lib/util/dates';
import { Arrays } from '../../../../lib/util/arrays';
import { UserItem } from '../../../../util/core-utils';
import { BreadcrumbParent } from '../../../../shared/breadcrumb/breadcrumb/breadcrumb.component';
import { TranslateService } from '@ngx-translate/core';
import { StateName } from '../../../../app.state-names';
import { ResourceQueryResult } from '../../../../lib/util/services';
import { ApiOperationService } from '../../../../lib/util/api-operation.service';
import { saveAs } from 'file-saver';
import { Transition } from '@uirouter/angular';
/* eslint-enable */

@Component({
  selector: 'app-audit-log-detail',
  templateUrl: 'audit-log-detail.component.html',
  styleUrls: ['./audit-log-detail.component.scss']
})
export class AuditLogDetailComponent implements OnInit, AfterViewInit {
  Strings = Strings;

  model: AuditLogDetailModel = new AuditLogDetailModel();

  private auditLogId: number;
  breadcrumbParents: BreadcrumbParent[] = [];
  breadcrumbSelf: string;
  compactSidebar: boolean = document.querySelector('body')!.classList.contains('sidebar-compact');

  static parseCreationTime(creation_time: string): string {
    const parsedDeadline = Dates.parseOffsetDateTimeIsoString(creation_time);
    return parsedDeadline.toUtcIsoString();
  }

  constructor(
    private authService: AuthService,
    private userService: UserService,
    private auditLogService: AuditLogService,
    private translateService: TranslateService,
    private transition: Transition,
    private apiOperationService: ApiOperationService) {
    this.auditLogId = this.transition.params().id;
  }


  ngOnInit() {
    this.translateService.get('MENU_NAVBAR_MENU_ADMINISTRATION').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.ADMIN_DASHBOARD});
      }
    );
    this.translateService.get('MENU_NAVBAR_AUDIT_LOG').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.AUDIT_LOG_LIST});
      }
    );
  }

  ngAfterViewInit(): void {
      this.loadModel();
  }

  back() {
    window.history.back();
  }

  private initAuditLogModel(auditLog: AuditLog): AuditLogDetailModel {
    const auditLogModel = new AuditLogDetailModel();
    auditLogModel.id = auditLog.id;
    auditLogModel.user_id = auditLog.user_id;
    auditLogModel.user_name = auditLog.user_name;
    auditLogModel.result = auditLog.result ? auditLog.result : '';
    auditLogModel.creation_time = AuditLogDetailComponent.parseCreationTime(auditLog.creation_time);
    auditLogModel.finish_time = auditLogModel.finish_time ? AuditLogDetailComponent.parseCreationTime(auditLog.finish_time!) : undefined;
    auditLogModel.duration_in_milliseconds = auditLog.duration_in_nanoseconds
      ? auditLog.duration_in_nanoseconds / 10e5 // 10e5 = 1M
      : undefined;
    auditLogModel.ip_address = auditLog.ip_address;
    auditLogModel.exception = auditLog.exception ? auditLog.exception : '';
    auditLogModel.request_uri = auditLog.request_uri;
    auditLogModel.request_query = auditLog.request_query ? auditLog.request_query : '';
    auditLogModel.call_type = 'AUDIT_LOG_CALL_TYPE_ENUM_' + auditLog.call_type;
    auditLogModel.request_method = auditLog.request_method;
    auditLogModel.request_json = auditLog.request_json ? auditLog.request_json : '';
    auditLogModel.response_json = auditLog.response_json ? auditLog.response_json : '';
    auditLogModel.content_type = auditLog.content_type ? auditLog.content_type : '';
    auditLogModel.device_id = auditLog.device_id ? auditLog.device_id : '';
    auditLogModel.application_id = auditLog.application_id ? auditLog.application_id : '';
    auditLogModel.application_name = auditLog.application_name ? auditLog.application_name : '';
    auditLogModel.api_operation_id = auditLog.api_operation_id;
    auditLogModel.api_operation_definition = auditLog.api_operation_definition ? auditLog.api_operation_definition : '';
    return auditLogModel;
  }

  public exportHtml() {
    this.auditLogService.getHtml({
      id: this.auditLogId
    }).subscribe((auditLog: Blob) => {
      saveAs(auditLog, 'AuditLog_' + this.auditLogId);
    });
  }

  private loadModel() {
    this.auditLogService.get({
      id: this.auditLogId
    })
      .subscribe(
        (auditLog: AuditLog) => {
          this.breadcrumbSelf = auditLog.id.toString();
          this.model = this.initAuditLogModel(auditLog);
        }
      );
  }

  isJson(contentType: string) {
    return contentType.includes('application/json');
  }

}
