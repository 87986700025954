<div class="breadcrumb-container" [ngClass]="{ 'breadcrumb-container-compact': compactSidebar }" id="breadcrumb">
  <div class="breadcrumb-component">
    <app-breadcrumb
      [parents]="breadcrumbParents"
      [self]="breadcrumbSelf">
    </app-breadcrumb>
  </div>
</div>
<div class="breadcrumb-placeholder"></div>
<div class="container-horizontal-padding container-vertical-padding animated fadeIn">
  <div class="card">
    <div class="card-header">{{'COMMON_DICTIONARY' | translate}}</div>
    <div class="card-body">

      <div class="row form-group">
        <div class="col-6"></div>
        <div class="col-3">
          <select class="form-control" *ngIf="applicationTypeKeys.length > 1" [(ngModel)]="model.appKey" (ngModelChange)="onAppKeyChanged()" id="appKey" name="appKey" #appKey="ngModel">
            <option *ngFor="let item of applicationTypeKeys" [ngValue]="item">{{item}}</option>
          </select>
        </div>
        <div class="col-3">
          <select class="form-control" [(ngModel)]="model.languageCode" (ngModelChange)="onLanguageCodeChanged()" id="languageCode" name="languageCode" #languageCode="ngModel">
            <option *ngFor="let languageCode of languageCodes" [ngValue]="languageCode">{{languageCode}}</option>
          </select>
        </div>
      </div>

      <table class="table table-striped table-bordered">

        <thead>
        <tr>
          <th class="table-sorter-header"><app-table-sorter-no-op [text]="'COMMON_TABLE_HEADER_ID' | translate"></app-table-sorter-no-op></th>
          <th class="table-sorter-header"><app-table-sorter-no-op [text]="'APP_DICTIONARY_TABLE_HEADER_VALUE' | translate"></app-table-sorter-no-op></th>
          <th class="table-sorter-header hidden-xs-down"><app-table-sorter-no-op [text]="'COMMON_NOTE' | translate"></app-table-sorter-no-op></th>
          <th class="table-sorter-header w-10"><app-table-sorter-no-op [text]="'COMMON_TABLE_HEADER_ACTIONS' | translate"></app-table-sorter-no-op></th>
        </tr>
        </thead>

        <tbody>
        <tr *ngFor="let item of model.items">
          <td><i>{{item.key}}</i></td>

          <td>
            <div (click)="onItemClick(item, item.value)" style="width:100%">
              <div *ngIf="!item.value.editing" style="width:100%">{{item.value.text}}</div>
              <div *ngIf="item.value.editing" style="width:100%"><input class="form-control" style="width:100%" [(ngModel)]="item.value.text" (keyup.enter)="onItemEnter(item, item.value)" (keyup.esc)="onItemEscape(item, item.value)" name="value" id="value" #value="ngModel" /></div>
            </div>
          </td>

          <td class="hidden-xs-down">
            <div (click)="onItemClick(item, item.note)" style="width:100%">
              <div *ngIf="!item.note.editing" style="width:100%">{{item.note.text}}</div>
              <div *ngIf="item.note.editing" style="width:100%"><input class="form-control" style="width:100%" [(ngModel)]="item.note.text" (keyup.enter)="onItemEnter(item, item.note)" (keyup.esc)="onItemEscape(item, item.note)" name="note" id="note" #note="ngModel" /></div>
            </div>
          </td>

          <td>
            <a class="icons-table-group" href="#" onclick="return false" (click)="openEditDialog(editDialog, item)">
              <i *ngIf="rightModel.appDictionaryUpdate.hasRight()" class="icomoon icons-table-item icomoon-modify icomoon-modify-table" title="{{'COMMON_BUTTON_EDIT' | translate}}"></i>
            </a>
          </td>

        </tr>
        </tbody>

        <tfoot>
        <tr>
          <th class="table-sorter-header"><app-table-sorter-no-op [text]="'COMMON_TABLE_HEADER_ID' | translate"></app-table-sorter-no-op></th>
          <th class="table-sorter-header"><app-table-sorter-no-op [text]="'APP_DICTIONARY_TABLE_HEADER_VALUE' | translate"></app-table-sorter-no-op></th>
          <th class="table-sorter-header hidden-xs-down"><app-table-sorter-no-op [text]="'COMMON_NOTE' | translate"></app-table-sorter-no-op></th>
          <th class="table-sorter-header w-10"><app-table-sorter-no-op [text]="'COMMON_TABLE_HEADER_ACTIONS' | translate"></app-table-sorter-no-op></th>
        </tr>
        </tfoot>

      </table>

    </div>
  </div>
</div>

<div bsModal [config]="UiConstants.modalConfig" #editDialog="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">{{'COMMON_BUTTON_EDIT' | translate}}</h4>
        <button type="button" class="close" (click)="cancelEditDialog(editDialog)" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="col-sm-12">
          {{'APP_DICTIONARY_TABLE_HEADER_VALUE' | translate}}
        </div>
        <div class="col-sm-12">
          <textarea class="form-control" [(ngModel)]="editItemValueText" rows="1" name="dvalue" id="dvalue" #dvalue="ngModel"></textarea>
        </div>
        <div class="col-sm-12 mt-1">
          {{'COMMON_NOTE' | translate}}
        </div>
        <div class="col-sm-12">
          <input class="form-control" maxlength="{{getTextMaximumLength()}}" [(ngModel)]="editItemNoteText" name="dnote" id="dnote" #dnote="ngModel" />
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="cancelEditDialog(editDialog)">{{'COMMON_BUTTON_CLOSE' | translate}}</button>
        <button type="button" class="btn btn-primary" (click)="closeEditDialog(editDialog)">{{'COMMON_BUTTON_SAVE' | translate}}</button>
      </div>
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div><!-- /.modal -->
