/* eslint-disable */
import { SetTmpReadonlyResult } from '../../../util/form/form-utils';
import { List } from 'immutable';

/* eslint-enable */

export class CommandResultStore {

  private results: List<SetTmpReadonlyResult> = List.of<SetTmpReadonlyResult>();
  private _toastIds: List<string> = List.of<string>();

  get toastIds(): string[] {
    return this._toastIds.toArray();
  }

  add(result: SetTmpReadonlyResult) {
    this.results = this.results.push(result);
  }

  addToastId(toastId: string) {
    this._toastIds = this._toastIds.push(toastId);
  }

  clear(): void {
    this.results = List.of<SetTmpReadonlyResult>();
    this._toastIds = List.of<string>();
  }

  hasChangedField(): boolean {
    let changed: boolean = false;
    this.results.forEach((result) => {
      changed = changed || result!.changed;
    });
    return changed;
  }

}
