/* eslint-disable */
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseHttpService, ResourceHelper } from '../util/http-services';
import { HttpClient } from '@angular/common/http';
import { ResourceQueryResult } from '../util/services';
import { DownloadedFile } from '../util/downloaded-files';
import { map } from 'rxjs/operators';
import { DqlStoredQueryResourceService } from '../dql/dql-stored-query-resource.service';

/* eslint-enable */

@Injectable()
export class ClientDocumentResourceService extends BaseHttpService {
  private static readonly baseUrl = '/client-document/';

  private readonly _dqlStoredResourceService: DqlStoredQueryResourceService;

  get dqlStoredResourceService(): DqlStoredQueryResourceService {
    return this._dqlStoredResourceService;
  }

  query(request: ClientDocumentResource.QueryRequest): Observable<ResourceQueryResult<ClientDocumentResource.ClientDocument>> {
    return this.http.get<ResourceQueryResult<ClientDocumentResource.ClientDocument>>(this.url, this.parseParams(request));
  }

  downloadPdf(request: ClientDocumentResource.DownloadPdfRequest): Observable<DownloadedFile> {
    return this.http.get(this.url + `${request.document_type}/` + request.id + '/pdf/download', {
      observe: 'response',
      responseType: 'blob',
    }).pipe(map((res) => {
      return new DownloadedFile(res);
    }));
  }

  downloadZip(request: ClientDocumentResource.QueryRequest): Observable<DownloadedFile> {
    return this.http.get(this.url + `/pdf/download-zip`, {
      observe: 'response',
      responseType: 'blob',
      params: this.parseParams(request).params
    }).pipe(map((res) => {
      return new DownloadedFile(res);
    }));
  }

  constructor(private http: HttpClient, resourceHelper: ResourceHelper) {
    super(resourceHelper, ClientDocumentResourceService.baseUrl);
    this._dqlStoredResourceService = new DqlStoredQueryResourceService(
      http, resourceHelper, ClientDocumentResourceService.baseUrl
    );
  }

}

export namespace ClientDocumentResource {

  export interface ClientDocument {
    id: string,
    document_type: string,
    external_id: string,
    state: string,
    customer_record_id?: number,
    task_record_id?: number,
    creator_user_id: number,
    last_editor_user_id: number,
    submitter_user_id?: number,
    creation_time: string,
    update_time: string,
    submit_time: string,
    has_pdf: boolean,
    pdf_id?: number,
    version?: ElasticVersion,
    form: ClientDocumentForm
  }

  export interface ElasticVersion {
    seq_no: number,
    primary_term: number
  }

  export interface ClientDocumentForm {
    insurance_product?: ObjectWithText,
    insurance_type?: ObjectWithText,
    insurance_level?: LevelObject,
    payment_type?: ObjectWithText,
    sponsor?: ObjectWithText,
    deletion_date?: string,
    deletion_cause?: string
  }

  export interface ObjectWithText {
    text?: string;
    code?: string;
  }

  export interface LevelObject {
    level?: number;
  }

  export interface DownloadPdfRequest {
    id: string;
    document_type: string;
  }

  export interface QueryRequest {
    ids?: string;
    document_type?: string;
    submitted_only?: boolean;
    dql?: string;
    order?: string;
    page_number?: number;
    number_of_items?: number;
  }

}
