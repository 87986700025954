<div class="modal-content">
  <div class="modal-header">
    <p class="modal-title"><strong>{{'PROCESS_TASK_PANEL_HEADING_DETAIL' | translate}}</strong></p>
    <button type="button" class="close" (click)="closeDialog()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <mat-dialog-content>
    <label class="col-form-label mw-100 form-control-label font-weight-bold">
      {{'TASK_RECORD_DETAIL_DIALOG_BASE_LABEL'|translate}}
    </label>

    <!--TASK NAME-->
    <div class="row" *ngIf="detailModel.taskName !== ''">
      <label readonly
             class="col-form-label form-control-label detail-title text-md-right col-md-4 mb-0">{{'TASK_RECORD_SEARCH_TASK_NAME'|translate}}</label>
      <div class="form-group col-md-8 mb-0">
        <label readonly class="col-form-label form-control-label detail-description"> {{ detailModel.taskName
          }} </label>
      </div>
    </div>

    <!--NAME-->
    <div class="row" *ngIf="detailModel.taskRecordName !== ''">
      <label readonly
             class="col-form-label form-control-label detail-title text-md-right col-md-4 mb-0">{{'TASK_RECORD_CREATE_NAME'|translate}}</label>
      <div class="form-group col-md-8 mb-0">
        <label readonly class="col-form-label form-control-label detail-description"> {{ detailModel.taskRecordName
          }} </label>
      </div>
    </div>

    <!--EXTERNAL ID-->
    <div class="row" *ngIf="detailModel.taskRecordExternalId !== ''">
      <label readonly
             class="col-form-label form-control-label detail-title text-md-right col-md-4 mb-0">{{'TASK_RECORD_EXTERNAL_ID'|translate}}</label>
      <div class="form-group col-md-8 mb-0">
        <label readonly
               class="col-form-label form-control-label detail-description"> {{ detailModel.taskRecordExternalId
          }} </label>
      </div>
    </div>

    <!--DESCRIPTION-->
    <div class="row" *ngIf="detailModel.description !== ''">
      <label readonly
             class="col-form-label form-control-label detail-title text-md-right col-md-4 mb-0">{{'COMMON_DESCRIPTION'|translate}}</label>
      <div class="form-group col-md-8 mb-0">
        <label readonly class="col-form-label form-control-label detail-description"> {{ detailModel.description
          }} </label>
      </div>
    </div>

    <!--IMPORTANCE-->
    <div class="row" *ngIf="detailModel.importance !== ''">
      <label readonly
             class="col-form-label form-control-label detail-title text-md-right col-md-4 mb-0">{{'TASK_IMPORTANCE_TITLE'|translate}}</label>
      <div class="form-group col-md-8 mb-0">
        <label readonly
               class="col-form-label form-control-label detail-description"> {{ detailModel.importance | translate }} </label>
      </div>
    </div>

    <!--STATE-->
    <div class="row" *ngIf="detailModel.stateText !== ''">
      <label readonly
             class="col-form-label form-control-label detail-title text-md-right col-md-4 mb-0">{{'COMMON_STATE'|translate}}</label>
      <div class="form-group col-md-8 mb-0">
        <label readonly
               class="col-form-label form-control-label detail-description"> {{ detailModel.stateText | translate }} </label>
      </div>
    </div>

    <!--ASSIGNEE-->
    <div class="row" *ngIf="detailModel.assignee !== ''">
      <label readonly
             class="col-form-label form-control-label detail-title text-md-right col-md-4 mb-0">{{'TASK_LABEL_OWNER_USER'|translate}}</label>
      <div class="form-group col-md-8 mb-0">
        <label readonly
               class="col-form-label form-control-label detail-description"> {{ detailModel.assignee }} </label>
      </div>
    </div>

    <!--ASSIGNEE USER GROUP-->
    <div class="row" *ngIf="detailModel.userGroup !== ''">
      <label readonly
             class="col-form-label form-control-label detail-title text-md-right col-md-4 mb-0">{{'TASK_DATA_MODIFICATION_USER_GROUP'|translate}}</label>
      <div class="form-group col-md-8 mb-0">
        <label readonly class="col-form-label form-control-label detail-description"> {{
          detailModel.userGroup }} </label>
      </div>
    </div>

    <!--ASSIGNEE DEVICE-->
    <div class="row" *ngIf="detailModel.assigneeDevice !== ''">
      <label readonly
             class="col-form-label form-control-label detail-title text-md-right col-md-4 mb-0">{{'TASK_DATA_MODIFICATION_DEVICE'|translate}}</label>
      <div class="form-group col-md-8 mb-0">
        <label readonly class="col-form-label form-control-label detail-description"> {{ detailModel.assigneeDevice
          }} </label>
      </div>
    </div>

    <!--CREATION TIME-->
    <div class="row" *ngIf="detailModel.creationTime">
      <label readonly
             class="col-form-label form-control-label detail-title text-md-right col-md-4 mb-0">{{'COMMON_CREATION_TIME'|translate}}</label>
      <div class="form-group col-md-8 mb-0">
        <label readonly class="col-form-label form-control-label detail-description"> {{
          detailModel.creationTime.toUtcIsoString() | date:'short' }} </label>
      </div>
    </div>

    <!--DEADLINE-->
    <div class="row" *ngIf="detailModel.deadline">
      <label readonly
             class="col-form-label form-control-label detail-title text-md-right col-md-4 mb-0">{{'TASK_LABEL_DEADLINE'|translate}}</label>
      <div class="form-group col-md-8 mb-0">
        <label readonly class="col-form-label form-control-label detail-description"> {{
          detailModel.deadline.toUtcIsoString() | date:'short' }} </label>
      </div>
    </div>

    <label class="col-form-label mw-100 form-control-label font-weight-bold">
      {{'CUSTOMER_RECORD_QUICK_CREATE_BASIC_DATA'|translate}}
    </label>

    <!--CUSTOMER-->
    <div class="row" *ngIf="detailModel.customer !== ''">
      <label readonly
             class="col-form-label form-control-label detail-title text-md-right col-md-4 mb-0">{{'TASK_LABEL_CUSTOMER'|translate}}</label>
      <div class="form-group col-md-8 mb-0">
        <label readonly
               class="col-form-label form-control-label detail-description"> {{ detailModel.customer }} </label>
      </div>
    </div>

    <!--ADDRESS-->
    <div class="row" *ngIf="detailModel.address">
      <label readonly
             class="col-form-label form-control-label detail-title text-md-right col-md-4 mb-0">{{'TASK_ADDRESS'|translate}}</label>
      <div class="form-group col-md-8 mb-0">
        <label readonly class="col-form-label form-control-label detail-description"> {{ detailModel.address }}
        </label>
      </div>
    </div>

    <!--AGREED TIME-->
    <div class="row" *ngIf="detailModel.agreedTime">
      <label readonly
             class="col-form-label form-control-label detail-title text-md-right col-md-4 mb-0">{{'TASK_LABEL_AGREED_TIME'|translate}}</label>
      <div class="form-group col-md-8 mb-0">
        <label readonly class="col-form-label form-control-label detail-description"> {{
          detailModel.agreedTime.toUtcIsoString() | date:'short' }} </label>
      </div>
    </div>
    </mat-dialog-content>
  </div>

  <div class="modal-footer justify-content-between">
        <span>
          <button type="button" class="btn btn-secondary-green-gray mr-1" (click)="navigateToDetail()">
            {{'COMMON_BUTTON_DETAILS' | translate}}
          </button>
          <button *ngIf="rightModel.taskRecordUpdate.hasRight() && canModify(detailModel.state)"
                  type="button" class="btn btn-primary-green-gray" (click)="navigateToEdit()">
            {{'COMMON_BUTTON_EDIT' | translate}}
          </button>
        </span>
    <button type="button" class="btn btn-outline-primary" (click)="closeDialog()">
      {{'COMMON_BUTTON_CLOSE' | translate}}
    </button>
  </div>

</div>
