<div class="breadcrumb-button-icon-container cursor-pointer"
     *ngIf="allowTaskRecordQuickCreate" (click)="createTaskRecord()">
  <a class="btn-setting d-flex align-items-center" title="{{'COMMON_CREATE_TASK' | translate}}">
    <i class="icomoon icomoon-sidebar-tasks"></i>
    <span class="breadcrumb-button-text-visible">{{'COMMON_CREATE_TASK' | translate}}</span>
  </a>
</div>

<div class="breadcrumb-button-icon-container cursor-pointer"
     *ngIf="readonly && allowEdit" (click)="editCustomer()">
  <a class="btn-setting d-flex align-items-center" title="{{'COMMON_BUTTON_EDIT' | translate}}">
    <i class="icomoon icomoon-modify"></i>
    <span class="breadcrumb-button-text-visible">{{'COMMON_BUTTON_EDIT' | translate}}</span>
  </a>
</div>

<div class="negative-margin-right"></div>
