/* eslint-disable */
import { Component, Input } from '@angular/core';
import { TaskRecord } from '../../../../lib/task/task-record.service';

/* eslint-enable */

@Component({
  selector: 'app-task-record-sms-rating',
  templateUrl: './task-record-sms-rating.component.html',
  styleUrls: ['./task-record-sms-rating.component.scss']
})
export class TaskRecordSmsRatingComponent {
  TaskRecord = TaskRecord;

  @Input()
  smsRating: number = 9;

  @Input()
  smsContent: string[] = ['Elso uzenet content', 'Masodik uzenet content'];

  constructor() {}

}
