<div class="breadcrumb-container" [ngClass]="{ 'breadcrumb-container-compact': compactSidebar }" id="breadcrumb">
  <div class="breadcrumb-component">
    <app-breadcrumb
      [parents]="breadcrumbParents"
      [self]="breadcrumbSelf">
    </app-breadcrumb>
  </div>
  <div class="breadcrumb-menu d-flex">
    <div *ngIf="rightModel.companyCreate.hasRight()"
         uiSref="Admin.CompanyCreate"
         class="breadcrumb-button-icon-container negative-margin-right cursor-pointer"
    >
      <a class="btn-setting" title="{{'COMPANY_ADD' | translate}}" >
        <i class="icomoon icomoon-add"></i>
        <span class="breadcrumb-button-text-visible">{{'COMPANY_ADD' | translate}}</span>
      </a>
    </div>
  </div>
</div>
<div class="breadcrumb-placeholder"></div>

<div class="container-horizontal-padding container-vertical-padding animated fadeIn">

  <div class="card">

    <div class="card-header">
      {{'MENU_NAVBAR_COMPANIES' | translate}}

      <div class="card-actions">
        <a class="btn-setting cursor-pointer" (click)="toggleSearch()"
           title="{{'COMMON_SHOW_SEARCH_HINT' | translate}}"><i class="icomoon icomoon-search"></i></a>
      </div><!-- /.card-actions -->
    </div>
    <div class="card-body border-bottom" *ngIf="showSearch">
      <div class="row form-group">
        <div class="col-md-4">
          <label class="search-label">{{'COMMON_TABLE_HEADER_ID' | translate}}</label>
          <input type="text" class="form-control"
                 maxlength="{{UiConstants.maxInputLongLength}}"
                 (keyup.enter)="onSearchClicked()"
                 placeholder="{{'COMMON_TABLE_HEADER_ID' | translate}}"
                 [textMask]="{mask: InputMask.NATURAL_INTEGER, guide: false}"
                 [(ngModel)]="searchModel.id">
        </div>
        <div class="col-md-4">
          <label class="search-label">{{'COMMON_EXTERNAL_ID' | translate}}</label>
          <input type="text" class="form-control"
                 maxlength="{{UiConstants.maximumVarcharLength}}"
                 (keyup.enter)="onSearchClicked()"
                 placeholder="{{'COMMON_EXTERNAL_ID' | translate}}"
                 [(ngModel)]="searchModel.externalId">
        </div>
        <div class="col-md-4">
          <label class="search-label">{{'COMMON_COMPANY_NAME' | translate}}</label>
          <input type="text" class="form-control"
                 maxlength="{{UiConstants.maximumVarcharLength}}"
                 (keyup.enter)="onSearchClicked()"
                 placeholder="{{'COMMON_COMPANY_NAME' | translate}}"
                 [(ngModel)]="searchModel.companyName">
        </div>
        <div class="col-md-4">
          <label class="search-label"> {{ 'COMPANY_TYPE' | translate }} </label>
          <select
            class="form-control"
            [(ngModel)]="searchModel.companyType" [compareWith]="SelectUtils.compareObjects">
            <option *ngFor="let item of companyTypeItems" [ngValue]="item">{{item.text}}</option>
          </select>
        </div>
        <div class="col-md-4">
          <label class="search-label"> {{ 'COMMON_STATE' | translate }} </label>
          <select
            class="form-control"
            [(ngModel)]="searchModel.disabled" [compareWith]="SelectUtils.compareObjects">
            <option *ngFor="let item of disabledItems" [ngValue]="item">{{item.text}}</option>
          </select>
        </div>
      </div>
      <div class="col-md-12 d-flex justify-content-md-end align-items-end px-0">
        <div class="btn-group" role="group">
          <button type="button" (click)="onSearchReset()" class="btn btn-outline-primary search-button mr-1">
            {{'COMMON_BUTTON_SEARCH_DELETE' | translate}}
          </button>
          <button type="button" (click)="onSearchClicked()" class="btn btn-primary search-button">
            {{'COMMON_BUTTON_SEARCH' | translate}}
          </button>
        </div>
      </div>
    </div><!-- /.card-body-->

    <div class="card-body">
      <table class="table table-striped table-bordered">
        <thead>
        <tr>
          <th class="table-sorter-header">
            <app-table-field-sorter [orderField]="Company.OrderField.ID"
                                    [orderType]="queryModel.getOrderType(Company.OrderField.ID)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_TABLE_HEADER_ID' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-md-down">
            <app-table-field-sorter [orderField]="Company.OrderField.EXTERNAL_ID"
                                    [orderType]="queryModel.getOrderType(Company.OrderField.EXTERNAL_ID)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_EXTERNAL_ID' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header">
            <app-table-field-sorter [orderField]="Company.OrderField.NAME"
                                    [orderType]="queryModel.getOrderType(Company.OrderField.NAME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_COMPANY_NAME' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-xs-down">
            <app-table-sorter-no-op [text]="'COMMON_LOCATION_POSTAL_ADDRESS' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header hidden-sm-down">
            <app-table-sorter-no-op [text]="'COMPANY_TYPE' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header w-table-actions">
            <app-table-sorter-no-op></app-table-sorter-no-op>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let company of companyList | paginate: {
                                                      itemsPerPage: queryModel.itemsPerPage,
                                                      currentPage: queryModel.currentPage,
                                                      totalItems: queryModel.currentNumberOfItems }" [disabledTableRow]="company.disabled">
          <td class="responsive-table-column test_company_id">{{company.id}}</td>
          <td class="responsive-table-column hidden-md-down test_company_externalId">{{company.externalId}}</td>
          <td class="responsive-table-column test_company_name">{{company.companyName}}</td>
          <td class="responsive-table-column hidden-xs-down">{{company.companyAddress}}</td>
          <td class="responsive-table-column hidden-sm-down">{{getCompanyTypeStringKey(company.type) | translate}}</td>
          <td class="responsive-table-column">
            <a class="icons-table-group cursor-pointer" uiSref="Admin.CompanyDetail"
               [uiParams]="{ id: company.id }">
              <i class="icomoon icons-table-item icomoon-detail icomoon-detail-table"
                 title="{{'COMMON_BUTTON_DETAILS' | translate}}"></i>
            </a>
            <a class="icons-table-group cursor-pointer" uiSref="Admin.CompanyEdit"
               [uiParams]="{ id: company.id }">
              <i class="icomoon icons-table-item icomoon-modify icomoon-modify-table"
                 *ngIf="rightModel.companyUpdate.hasRight()"
                 title="{{'COMMON_BUTTON_EDIT' | translate}}"></i>
            </a>
            <a class="icons-table-group" href="#" onclick="return false" (click)="setDisabled($event, company, true)"
               *ngIf="!company.disabled && rightModel.companyDisable.hasRight()">
              <i class="icomoon icons-table-item icomoon-disable icomoon-disable-table"
                 title="{{'COMMON_BUTTON_DISABLE' | translate}}"></i>
            </a>
            <a class="icons-table-group" href="#" onclick="return false" (click)="setDisabled($event, company, false)"
               *ngIf="company.disabled && rightModel.companyDisable.hasRight()">
              <i class="icomoon icons-table-item icomoon-enable icomoon-enable-table"
                 title="{{'COMMON_BUTTON_ENABLE' | translate}}"></i>
            </a>
          </td>
        </tr>
        </tbody>
        <tfoot>
        <tr>
          <th class="table-sorter-header">
            <app-table-field-sorter [orderField]="Company.OrderField.ID"
                                    [orderType]="queryModel.getOrderType(Company.OrderField.ID)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_TABLE_HEADER_ID' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-md-down">
            <app-table-field-sorter [orderField]="Company.OrderField.EXTERNAL_ID"
                                    [orderType]="queryModel.getOrderType(Company.OrderField.EXTERNAL_ID)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_EXTERNAL_ID' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header">
            <app-table-field-sorter [orderField]="Company.OrderField.NAME"
                                    [orderType]="queryModel.getOrderType(Company.OrderField.NAME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_COMPANY_NAME' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-xs-down">
            <app-table-sorter-no-op [text]="'COMMON_LOCATION_POSTAL_ADDRESS' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header hidden-sm-down">
            <app-table-sorter-no-op [text]="'COMPANY_TYPE' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header w-table-actions">
            <app-table-sorter-no-op></app-table-sorter-no-op>
          </th>
        </tr>
        </tfoot>
      </table>
      <app-table-paging
        [currentNumberOfItems]="queryModel.currentNumberOfItems"
        [totalNumberOfItems]="queryModel.totalNumberOfItems"
        [itemsPerPage]="queryModel.itemsPerPage"
        (pageChange)="pageChanged($event)"
        (itemsPerPageChange)="itemsPerPageChanged($event)">
      </app-table-paging>
    </div>
  </div>
</div>
