/* eslint-disable */
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ResourceQueryResult } from '../../util/services';
import { HttpClient } from '@angular/common/http';
import { BaseHttpService, ResourceHelper } from '../../util/http-services';
/* eslint-enable */

@Injectable()
export class InvoiceStatisticsResourceService extends BaseHttpService {

  query(request: InvoiceStatisticsResource.QueryRequest): Observable<ResourceQueryResult<InvoiceStatisticsResource.InvoiceStatistic>> {
    return this.http.get<ResourceQueryResult<InvoiceStatisticsResource.InvoiceStatistic>>(this.url, this.parseParams(request));
  }

  constructor(private http: HttpClient, resourceHelper: ResourceHelper) {
    super(resourceHelper, '/tasks/statistics/invoice-stat/');
  }

}

export namespace InvoiceStatisticsResource {

  export interface QueryRequest {
    order?: string;
    page_number?: number;
    number_of_items?: number;
  }

  export interface InvoiceStatistic {
    task_id: number;
    task_name: string;
    task_record_count: number;
    net_sum: number;
    gross_sum: number;
  }
}
