import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Subject } from 'rxjs';
import { CalendarUtils } from '../../../util/calendar/calendar-utils';
import { WeekDay } from 'calendar-utils';
import { CalendarUtils as LibCalendarUtils } from '../../../fork/angular-calendar/src/modules/common/calendar-utils.provider';
import { DateAdapter, getWeekViewPeriod } from '../../../fork/angular-calendar/src';
import { QueryFieldModel } from '../../../util/core-utils';
import { Calendar } from '../../../lib/calendar/calendar.service';
import { OrderType } from '../../../lib/util/services';
import { UserService } from '../../../lib/user.service';
import { DownloadedFile } from '../../../lib/util/downloaded-files';
import LocalCalendarEvent = CalendarUtils.LocalCalendarEvent;

@Component({
  selector: 'app-calendar-timeline-view',
  templateUrl: './calendar-timeline-view.component.html',
  styleUrls: ['./calendar-timeline-view.component.scss']
})
export class CalendarTimelineViewComponent implements OnInit, OnChanges {

  /**
   * An array of events to display on view
   * The schema is available here:
   * https://github.com/mattlewis92/calendar-utils/blob/c51689985f59a271940e30bc4e2c4e1fee3fcb5c/src/calendarUtils.ts#L49-L63
   */
  @Input()
  set events(rawEvents: LocalCalendarEvent[]) {
    this.userIds = [];
    this._events = new Map();
    rawEvents.forEach(e => {
      if (e.owner && e.owner.userId) {
        if (!this.userIds.includes(e.owner.userId)) {
          this.userIds.push(e.owner.userId);
          this._events.set(e.owner.userId!, [e]);
          this.loadProfilePicture(e.owner.userId!);
        }
        else {
          this._events.get(e.owner.userId!)!.push(e);
        }
      }
    });
  }

  /**
   * The current view date
   */
  @Input() viewDate: Date;

  userIds: number[] = [];
  _events: Map<number, LocalCalendarEvent[]> = new Map();
  profilePictures: Map<number, string> = new Map();

  /**
   * An observable that when emitted on will re-render the current view
   */
  @Input() refresh: Subject<any>;

  /**
   * The locale used to format dates
   */
  @Input() locale: string;

  /**
   * The start number of the week
   */
  @Input() weekStartsOn: number;

  /**
   * The number of days in a week. Can be used to create a shorter or longer week view.
   * The first day of the week will always be the `viewDate`
   */
  @Input() daysInWeek: number;

  /**
   * An array of day indexes (0 = sunday, 1 = monday etc) that will be hidden on the view
   */
  @Input() excludeDays: number[] = [];

  /**
   * Called when the event title is clicked
   */
  @Output()
  eventClicked = new EventEmitter<{
    event: LocalCalendarEvent;
  }>();

  @Output()
  pageChange = new EventEmitter<number>();

  @Output()
  itemsPerPageChange = new EventEmitter<number>();

  /**
   * @hidden
   */
  days: WeekDay[];

  queryModel: QueryFieldModel<Calendar.OrderField> = new QueryFieldModel(Calendar.OrderField.START_TIME, OrderType.DESC);

  constructor(
    private utils: LibCalendarUtils,
    private dateAdapter: DateAdapter,
    private userService: UserService
  ) {
    this.queryModel.itemsPerPage = 5;
  }

  getUser(userId: number): Calendar.CalendarEventOwner {
    return this._events.get(userId)![0].owner!;
  }

  getProfilePicture(userId: number): string | undefined {
    return this.profilePictures.get(userId);
  }

  getEvents(userId: number): LocalCalendarEvent[] {
    return this._events.get(userId)!;
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.viewDate
      || changes.daysInWeek) {
      this.refreshHeader();
    }
  }

  private refreshHeader(): void {
    this.days = this.utils.getWeekViewHeader({
      viewDate: this.viewDate,
      weekStartsOn: this.weekStartsOn,
      ...getWeekViewPeriod(
        this.dateAdapter,
        this.viewDate,
        this.weekStartsOn,
        this.excludeDays
      )
    });
  }

  private loadProfilePicture(userId: number) {
    this.userService.downloadProfilePicture(userId).subscribe(
      (res: DownloadedFile) => {
        this.profilePictures.set(userId, URL.createObjectURL(res.getBlob()));
      },
      () => {
      }
    );
  }
}
