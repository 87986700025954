/* eslint-disable */
import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';
import { ErrorResource, FieldError, GlobalError } from '../lib/util/errors';
import { Arrays } from '../lib/util/arrays';
/* eslint-enable */

export interface LocalizedUploadError {
  globalErrors: string;
  collectionFieldErrors: string;
}

@Injectable()
export class UploadErrorLocalizer {

  public constructor(private translateService: TranslateService) {
  }

  public localizeError(response: string): LocalizedUploadError {
    const error: ErrorResource = JSON.parse(response);
    let globalErrors: GlobalError[] | undefined;
    let fieldErrors: FieldError[] | undefined;
    if (error && error.extra) {
      globalErrors = error.extra.global_errors;
      fieldErrors = error.extra.collection_field_errors;
    }
    let uploadGlobalErrors = '';
    if (error && error.detail) {
      uploadGlobalErrors = error.detail.description;
    }
    if (globalErrors) {
      Arrays.iterateByIndex(globalErrors, (currentItem) => {
        if (uploadGlobalErrors === '') {
          uploadGlobalErrors += currentItem.text;
        }
        else {
          uploadGlobalErrors += '\n' + currentItem.text;
        }
      });
    }
    let collectionFieldErrors = '';
    if (fieldErrors) {
      for (const i in fieldErrors) {
        if (Object.prototype.hasOwnProperty.call(fieldErrors, i)) {
          const fieldError = fieldErrors[i];
          for (const j in fieldError) {
            if (Object.prototype.hasOwnProperty.call(fieldError, j)) {
              const field = fieldError[j];
              if (field.text) {
                const errorText = field.text;
                const localizedError = this.translateService.instant('COMMON_UPLOAD_ROW_ERROR', {
                  rowNumber: i,
                  fieldName: j,
                  errorText: errorText
                });
                collectionFieldErrors += localizedError + '<br>';
              }
            }
          }
        }
      }
    }
    return {
      globalErrors: uploadGlobalErrors,
      collectionFieldErrors: collectionFieldErrors
    };
  }

}
