import { Injectable } from '@angular/core';
import { MultiselectProvider } from '../multiselect-provider';
import { Observable, Observer } from 'rxjs';
import { MultiselectOptionItem, UiConstants } from '../../util/core-utils';
import { QueryResult } from '../util/services';
import { Workflow, WorkflowService } from './workflow.service';
import { List, Set } from 'immutable';
import { FilterField } from '../query/filterfields';
import { CriteriaBuilder } from '../../util/model-utils';
import { Query } from '../query/field';
import { Process } from '../process/process.service';

@Injectable()
export class WorkflowMultiselectProvider implements MultiselectProvider {

  private multiselectQueryRequest: Workflow.QueryRequest = {
    fields: f => f.forSearch,
    order: f => List.of(f.name.asc().nullsLast()),
    paging: {
      pageNumber: 1,
      numberOfItems: UiConstants.autocompletePageSize,
    },
    noProgressBar: true,
  };

  constructor(private workflowService: WorkflowService) {
  }

  load(queryRequest: Workflow.QueryRequest): Observable<WorkflowMultiselectOptionItem[]> {
    return Observable.create((observer: Observer<WorkflowMultiselectOptionItem[]>) => {
      this.workflowService.query(queryRequest).subscribe((result: QueryResult<Workflow.Workflow>) => {
          observer.next(result.items.map(item => this.toMultiselectOptionItem(item!)).toArray());
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  loadAll(predicate?: string): Observable<WorkflowMultiselectOptionItem[]> {
    const queryRequest = Object.assign({}, this.multiselectQueryRequest);
    queryRequest.filter = (f: FilterField.Workflow) => CriteriaBuilder.builder()
      .addString((predicate) => f.name.containsIgnoreCase(predicate), predicate)
      .build();
    return this.load(queryRequest);
  }

  loadLatestVersions(predicate?: string): Observable<WorkflowMultiselectOptionItem[]> {
    const queryRequest = Object.assign({}, this.multiselectQueryRequest);
    queryRequest.filter = (f: FilterField.Workflow) => CriteriaBuilder.builder()
      .addString((predicate) => f.name.containsIgnoreCase(predicate), predicate)
      .build();
    queryRequest.latestVersionsOnly = true;
    return this.load(queryRequest);
  }

  loadActive(predicate?: string): Observable<WorkflowMultiselectOptionItem[]> {
    const queryRequest = Object.assign({}, this.multiselectQueryRequest);
    queryRequest.filter = (f: FilterField.Workflow) => CriteriaBuilder.builder()
      .addString((predicate) => f.name.containsIgnoreCase(predicate), predicate)
      .addEnum((state) => f.versionState.eq(state), Workflow.VersionState.FINALIZED)
      .build();
    return this.load(queryRequest);
  }

  getById(id: number): Observable<WorkflowMultiselectOptionItem> {
    return Observable.create((observer: Observer<WorkflowMultiselectOptionItem>) => {
      this.workflowService.get({
        id: id
      }).subscribe((result: Workflow.Workflow) => {
          observer.next(this.toMultiselectOptionItem(result!));
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  getByIds(ids: number[]): Observable<WorkflowMultiselectOptionItem[]> {
    const queryRequest = Object.assign({}, this.multiselectQueryRequest);
    const filters: Query.Criteria[] = [];
    const f: FilterField.Workflow = new FilterField.Workflow();
    ids.forEach(id => {
      filters.push(f.id.eq(id));
    });
    queryRequest.filter = f => Query.Criterias.anyOf(List.of(...filters));
    return this.load(queryRequest);
  }

  toMultiselectOptionItem(item: Workflow.Workflow): WorkflowMultiselectOptionItem {
    return WorkflowMultiselectProvider.toOptionItem(item);
  }

  static toOptionItem(item: Workflow.Workflow): WorkflowMultiselectOptionItem {
    const ret = new WorkflowMultiselectOptionItem();
    ret.id = item.id;
    ret.baseId = item.baseId!;
    ret.itemName = item.name;
    ret.disabled = item.versionState !== Workflow.VersionState.FINALIZED;
    ret.requiredFields = item.processConfig!.requiredFields;
    ret.firstTaskId = item.firstTaskId;
    return ret;
  }
}

export class WorkflowMultiselectOptionItem extends MultiselectOptionItem<number> {
  requiredFields: Set<Process.ProcessFieldType>;
  firstTaskId?: number;
  baseId: number;
}
