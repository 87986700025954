/* eslint-disable */
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CustomerRecordResource } from './customer-record-resource.service';
import { ResourceQueryResult } from '../util/services';
import { BaseHttpService, ResourceHelper } from '../util/http-services';
import { HttpClient } from '@angular/common/http';
import { HistoryLog } from '../history-log/history-log.service';
import { UserItemResource } from '../user.service';
/* eslint-enable */

@Injectable()
export class CustomerRecordLogResourceService extends BaseHttpService {

  history(request: CustomerRecordLogResource.HistoryRequest): Observable<ResourceQueryResult<CustomerRecordLogResource.HistoryItem>> {
    return this.http.get<ResourceQueryResult<CustomerRecordLogResource.HistoryItem>>(
      this.url + `${request.customer_id}/records/${request.customer_record_id}/history-logs`,
      this.parseParams(request)
    );
  }

  constructor(private http: HttpClient, resourceHelper: ResourceHelper) {
    super(resourceHelper, '/customers/');
  }
}


export namespace CustomerRecordLogResource {

  // <editor-fold desc="CRUD">

  export interface HistoryRequest {
    customer_id: number;
    customer_record_id: number;
    with_read: boolean;
    q?: string;
    order?: string;
    page_number?: number;
    number_of_items?: number;
  }

  export interface HistoryItem extends HistoryLog.HistoryItemBaseResource {
    user: UserItemResource;
  }

  // </editor-fold>

}
