/* eslint-disable */
import { Form } from '../../lib/form/form.service';
import { FormModel, FormRecordFieldContext } from './form-utils';
import { LoggerFactory } from '../logger-factory';
/* eslint-enable */

export class FormEditViews {

  public static collectFormFieldNames(form?: FormModel, ignoreField?: Form.Field): string[] {
    if (!form) {
      return [];
    }
    const fieldArray: Form.Field[] = [];
    form.formGroups.map((group) => {
      fieldArray.push(...group.fields);
      return 1;
    });
    return fieldArray
      .filter((field) => {
        return !ignoreField ? true : field.fieldId !== ignoreField.fieldId
      })
      .map((field) => {
        return field.title
      });
  }

  private constructor() {
  }

}

export enum FieldActivationState {

  /**
   * Field is editable (non-readonly).
   */
  EDITABLE,

  /**
   * Readonly form.
   *
   * Each validator is disabled.
   */
  READONLY_FORM,

  /**
   * Readonly field.
   *
   * Each validator is enabled.
   */
  READONLY_FIELD,

  /**
   * Readonly field (temporarily).
   *
   * Each validator is enabled - except required.
   * If we enter this state, the content of the field will be cleared.
   */
  READONLY_TMP,

}

export interface FieldActivationStateResolverArgs {
  readonlyFormFn: () => boolean;
  readonlyFieldFn: () => boolean;
  tmpReadonlyFieldFn: () => boolean;
}

export interface FormRecordDefaultValueArgs {
  formRecordFieldContext: () => FormRecordFieldContext | undefined;
  fieldActivationState: () => FieldActivationState;
}

export interface InactivationChangesTheValueArgs {
  debugId: string,
  valueIsEmpty: boolean,
  valueEqualsDefaultValue: boolean,
  defaultValueIsEmpty: boolean,
  canApplyDefaultValue: boolean
}

interface InactivationChangesTheValueRawArgs {
  debugId: string,
  valueIsEmpty: boolean,
  valueEqualsDefaultValue: boolean,
  willActivateToEmptyValue: boolean
  willActivateToDefaultValue: boolean
}

export class FieldActivationStateResolver {

  private static readonly logger = LoggerFactory.createLogger('FieldActivationStateResolver');

  public static resolveFieldActivationState(args: FieldActivationStateResolverArgs): FieldActivationState {
    const readonlyForm = args.readonlyFormFn();
    const readonlyField = args.readonlyFieldFn();
    const tmpReadonlyField = args.tmpReadonlyFieldFn();
    if (readonlyForm) {
      return FieldActivationState.READONLY_FORM;
    }
    if (readonlyField) {
      return FieldActivationState.READONLY_FIELD;
    }
    if (tmpReadonlyField) {
      return FieldActivationState.READONLY_TMP;
    }
    return FieldActivationState.EDITABLE;
  }

  public static canApplyDefaultValue(args: FormRecordDefaultValueArgs): boolean {
    const context = args.formRecordFieldContext()!;
    return !context.fieldRecord && args.fieldActivationState() === FieldActivationState.EDITABLE;
  }

  public static willBeAbleToApplyDefaultValue(args: FormRecordDefaultValueArgs): boolean {
    const context = args.formRecordFieldContext()!;
    return !context.fieldRecord
      && (args.fieldActivationState() === FieldActivationState.EDITABLE
        || args.fieldActivationState() === FieldActivationState.READONLY_TMP);
  }

  public static inactivationChangesTheValue(args: InactivationChangesTheValueArgs): boolean {
    return FieldActivationStateResolver.inactivationChangesTheValueRaw({
      debugId: args.debugId,
      valueIsEmpty: args.valueIsEmpty,
      valueEqualsDefaultValue: args.valueEqualsDefaultValue,
      willActivateToEmptyValue: args.defaultValueIsEmpty || !args.canApplyDefaultValue,
      willActivateToDefaultValue: args.defaultValueIsEmpty || args.canApplyDefaultValue
    });
  }

  private static inactivationChangesTheValueRaw(args: InactivationChangesTheValueRawArgs): boolean {
    if (!args.willActivateToEmptyValue && !args.willActivateToDefaultValue) {
      throw Error('Must activate to empty or default value');
    }
    const result = !(
      (args.valueIsEmpty === args.willActivateToEmptyValue)
      &&
      (args.valueEqualsDefaultValue === args.willActivateToDefaultValue)
    );
    FieldActivationStateResolver.logger.debug(
      'inactivationChangesTheValueRaw of ' + args.debugId + ': ' + result,
      args
    );
    return result;
  }

  public static isEditable(state: FieldActivationState): boolean {
    return state === FieldActivationState.EDITABLE;
  }

  public static isNonEditable(state: FieldActivationState): boolean {
    return state !== FieldActivationState.EDITABLE;
  }

  public static isRequiredDisabled(state: FieldActivationState): boolean {
    return state === FieldActivationState.READONLY_TMP || state === FieldActivationState.READONLY_FORM;
  }

  private constructor() {
  }

}
