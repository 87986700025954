/* eslint-disable */
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { StockTakings, StockTakingsService } from '../../../lib/stock-takings/stock-takings.service';
import { MultiselectOptionItem, QueryFieldModel, SelectUtils, UiConstants, UserItem } from '../../../util/core-utils';
import { OrderType, QueryResult, ResourceQueryResult, Services } from '../../../lib/util/services';
import { RightModel } from '../../../app.rights';
import { RightResolver, RightService } from '../../../lib/right.service';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, Observable } from 'rxjs';
import { Set } from 'immutable';
import { Strings } from '../../../lib/util/strings';
import {
  StockTakingsSearch,
  StockTakingsSearchModel,
  StockTakingsSearchService
} from '../../../lib/stock-takings/stock-takings-search.service';
import { User, UserService } from '../../../lib/user.service';
import { Stock, StockService } from '../../../lib/stock/stock.service';
import { Angular2Multiselects } from '../../../util/multiselect';
import { StringKey } from '../../../app.string-keys';
import { TranslateUtils } from '../../../util/translate';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { StateName } from '../../../app.state-names';
import { UIRouter } from '@uirouter/angular';
import { InputMask } from '../../../util/input-masks';
import { DisabledItem } from '../../../util/search-utils';
import { StockMultiselectProvider } from '../../../lib/stock/stock-multiselect.provider';
import { UserMultiselectProvider } from '../../../lib/user/user-multiselect.provider';
import {
  FieldError,
  FieldErrors,
  ObservableErrorResourceParser,
  ObservableErrorResponse
} from '../../../lib/util/errors';
import { AddStockTakingSheetFieldErrorMap } from '../stock-takings-edit/stock-takings-edit.component';
/* eslint-enable */

@Component({
  selector: 'app-stock-takings-list',
  templateUrl: './stock-takings-list.component.html',
  styleUrls: ['./stock-takings-list.component.scss']
})
export class StockTakingsListComponent implements OnInit, OnDestroy {
  StockTakings = StockTakings;
  InputMask = InputMask;
  UiConstants = UiConstants;

  @ViewChild('stockTakingCreateDialog', { static: true })
  stockTakingCreateDialog: ModalDirective;
  stockTakingCreateDialogVisible: boolean = false;
  newStockTaking: StockTakingCreateModel = new StockTakingCreateModel();
  newStockTakingSubmitted: boolean = false;

  queryModel: QueryFieldModel<StockTakings.OrderField> = new QueryFieldModel(StockTakings.OrderField.CREATION_TIME, OrderType.DESC);
  stockTakingList: StockTakings.StockTaking[] = [];
  showSearch: boolean = false;
  rightModel: RightModel = RightModel.empty();
  closedItems: DisabledItem[] = [];
  stocks: MultiselectOptionItem<number>[] = [];
  users: MultiselectOptionItem<number>[] = [];
  breadcrumbSelf: string;
  compactSidebar: boolean = document.querySelector('body')!.classList.contains('sidebar-compact');

  dropdownSettings: Angular2Multiselects.Settings;
  searchModel: StockTakingsSearchModel = new StockTakingsSearchModel();
  SelectUtils = SelectUtils;

  createStockTakingFieldErrors: CreateStockTakingSheetFieldErrorMap;

  constructor(
    private rightService: RightService,
    private translateService: TranslateService,
    private stockMultiselectProvider: StockMultiselectProvider,
    private userMultiselectProvider: UserMultiselectProvider,
    private uiRouter: UIRouter,
    private stockTakingsService: StockTakingsService,
    private stockTakingsSearchService: StockTakingsSearchService) {
    this.createStockTakingFieldErrors = {};
  }

  ngOnInit() {
    this.translateService.get('MENU_NAVBAR_STOCK_TAKINGS').subscribe(
      (result: string) => {
        this.breadcrumbSelf = result;
      }
    );
    this.loadRightModels(() => {
      if (this.rightModel.userRead.hasRight()) {
        this.loadUsers(() => {
          this.loadSearch(() => {
            this.showSearch = !this.searchModel.isEmpty();
            this.loadList();
          });
        });
      }
      else {
        this.loadSearch(() => {
          this.showSearch = !this.searchModel.isEmpty();
          this.loadList();
        });
      }
    });
    this.initDropDown();
    this.loadStocks();
  }

  private loadUsers(completion?: () => void, predicate?: string) {
    this.userMultiselectProvider.loadAll(predicate).subscribe(items => {
      this.users = items;
      if (completion) {
        completion();
      }
    });
  }

  initDropDown() {
    this.dropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(false)
      .enableSearchFilter(true)
      .enableCheckAll(false)
      .remoteSearch(true)
      .build();
  }

  private loadSearch(completion: () => void) {
    const obs: Observable<SearchLoadResult> = combineLatest(
      this.stockTakingsSearchService.getSearchData({}),
      (storedSearchData: StockTakingsSearch.SearchDataResult) => {
        const result: SearchLoadResult = {
          storedSearchData: storedSearchData
        };
        return result;
      }
    );
    obs.subscribe(
      (result: SearchLoadResult) => {
        this.postInitSearch(result.storedSearchData);
        completion();
      }
    );
  }

  private postInitSearch(storedSearchData: StockTakingsSearch.SearchDataResult) {
    this.queryModel.itemsPerPage = storedSearchData.searchData.itemsPerPage;
    this.queryModel.currentPage = storedSearchData.searchData.pageNumber;
    this.queryModel.setOrder(storedSearchData.searchData.order);
    this.searchModel.stockTakingId = storedSearchData.searchData.stockTakingId;
    this.searchModel.name = storedSearchData.searchData.name;
    this.searchModel.trxId = storedSearchData.searchData.trxId;
    this.loadSearchOwnerUsers(storedSearchData.searchData.ownerUserId);
  }

  private loadSearchOwnerUsers(ids: number[]) {
    this.searchModel.ownerUserId = [];
    if (ids && ids.length > 0) {
      ids.forEach((id) => {
        const user = this.users.filter(current_user => current_user.id === id);
        if (user && user.length === 1) {
          this.searchModel.ownerUserId.push(user[0]);
        }
      });
    }
  }

  private loadRightModels(completion: () => void) {
    this.rightService.getRightResolver().subscribe(
      (resolver: RightResolver) => {
        this.rightModel = RightModel.of(resolver);
        completion();
      }
    );
  }

  pageChanged(selectedPage: number) {
    this.loadList(selectedPage);
  }

  itemsPerPageChanged(itemsPerPage: number) {
    this.queryModel.itemsPerPage = itemsPerPage;
    this.loadList(1);
  }

  orderBy(field: StockTakings.OrderField) {
    this.queryModel.onOrderFieldChanged(field);
    this.loadList(1);
  }

  toggleSearch() {
    this.showSearch = !this.showSearch;
  }

  private loadList(pageNumber?: number) {
    const requestedPage = pageNumber ? pageNumber : this.queryModel.currentPage;
    const order = this.queryModel.getOrder();
    this.stockTakingsService.query({
      stockTakingId: Strings.undefinedOrNonEmpty(this.searchModel.stockTakingId),
      ownerUserId: Strings.undefinedOrNonEmpty(this.getSearchUserIds().join(',')),
      name: Strings.undefinedOrNonEmpty(this.searchModel.name),
      trxId: Strings.undefinedOrNonEmpty(this.searchModel.trxId),
      orders: Set.of(order),
      paging: {
        pageNumber: requestedPage,
        numberOfItems: this.queryModel.itemsPerPage
      }
    }).subscribe(
      (result: QueryResult<StockTakings.StockTaking>) => {
        this.stockTakingList = result.items.toArray();
        this.queryModel.currentPage = requestedPage;
        this.queryModel.totalNumberOfItems = result.pagingResult.totalNumberOfItems;
        this.queryModel.currentNumberOfItems = result.pagingResult.currentNumberOfItems;
      }
    );
  }

  private getSearchUserIds(): number[] {
    const ids: number[] = [];
    this.searchModel.ownerUserId.forEach((u) => {
      ids.push(u.id);
    });
    return ids;
  }

  private loadStocks(predicate?: string) {
    this.stockMultiselectProvider.loadActive(predicate).subscribe(items => {
      this.stocks = items;
    })
  }

  onSearchClicked() {
    this.loadList();
  }

  onSearchReset() {
    this.stockTakingsSearchService.resetSearchData({}).subscribe(
      (result) => {
        this.loadSearch(() => {
          this.showSearch = true;
          this.loadList(1);
        });
      }
    );
  }

  private saveSearch() {
    const request = {
      searchData: {
        itemsPerPage: this.queryModel.itemsPerPage,
        pageNumber: this.queryModel.currentPage,
        order: this.queryModel.getOrder(),
        stockTakingId: this.searchModel.stockTakingId,
        ownerUserId: this.getSearchUserIds(),
        name: this.searchModel.name,
        trxId: this.searchModel.trxId,
      }
    };
    this.stockTakingsSearchService.setSearchData(request).subscribe(
      (result) => {
      },
      (error) => {
      }
    );
  }

  getStockNames(ids: number[]): string {
    const result: string[] = [];
    ids.forEach((id) => {
      const stock = this.stocks.filter(current_stock => current_stock.id === id);
      if (stock && stock.length === 1) {
        result.push(stock[0].itemName);
      }
    });
    return result.join(', ');
  }

  getUserName(id: number): string {
    const filtered_users = this.users.filter(current_user => current_user.id === id);
    if (filtered_users === undefined || filtered_users.length === 0) {
      return ''
    }
    return filtered_users[0].itemName;
  }

  openStockTakingCreateDialog() {
    this.newStockTaking = new StockTakingCreateModel();
    this.newStockTakingSubmitted = false;
    this.stockTakingCreateDialogVisible = true;
    this.stockTakingCreateDialog.show();
  }

  closeStockTakingCreateDialog() {
    this.stockTakingCreateDialogVisible = false;
    this.stockTakingCreateDialog.hide();
  }

  createStockTaking() {
    this.newStockTakingSubmitted = true;
    if (this.newStockTaking.name.length === 0) {
      return;
    }
    const stockIds: number[] = [];
    this.newStockTaking.stocks.forEach((s) => {
      stockIds.push(s.id);
    });
    this.stockTakingsService.create({
      name: this.newStockTaking.name,
      stockIds: stockIds
    }).subscribe((result: StockTakings.CreateResponse) => {
      this.uiRouter.stateService.go(StateName.STOCK_TAKINGS_EDIT, {id: result.stockTakingId});
    }, (error: ObservableErrorResponse) => {
      const res = ObservableErrorResourceParser.parseError(error);
      this.createStockTakingFieldErrors = ObservableErrorResourceParser.extractFieldErrors(res);
    });
  }

  removeFieldError(fieldError?: FieldError) {
    FieldErrors.remove(this.createStockTakingFieldErrors, fieldError);
  }

  ngOnDestroy() {
    this.saveSearch();
  }

}

interface SearchLoadResult {
  storedSearchData: StockTakingsSearch.SearchDataResult,
}

interface OptionItem {
  id: number;
  itemName: string;
}

class StockTakingCreateModel {
  name: string = '';
  stocks: OptionItem[] = [];
}

class CreateStockTakingSheetFieldErrorMap {
  name?: FieldError;
  stock?: FieldError;
}
