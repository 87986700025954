/* eslint-disable */
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FormRecordResource } from '../form/form-record-resource.service';
import { HttpClient } from '@angular/common/http';
import { BaseHttpService, ResourceHelper } from '../util/http-services';
import { ResourceQueryResult } from '../util/services';
import { FileAttachmentResource } from '../util/file-attachments';
import { DownloadedFile } from '../util/downloaded-files';
import { UrlBuilder } from '../../util/url-builder';
import { map } from 'rxjs/operators';
import { EmptyMessage, IdentityMessage } from '../util/messages';

/* eslint-enable */

@Injectable()
export class MasterDataRecordResourceService extends BaseHttpService {

  // <editor-fold desc="CRUD">

  query(request: MasterDataRecordResource.QueryRequest): Observable<ResourceQueryResult<MasterDataRecordResource.MasterDataRecord>> {
    return this.http.get<ResourceQueryResult<MasterDataRecordResource.MasterDataRecord>>(
      this.url + `${request.master_data_id}/records`,
      this.parseParams(request));
  }

  globalQuery(request: MasterDataRecordResource.QueryRequest): Observable<ResourceQueryResult<MasterDataRecordResource.MasterDataRecord>> {
    return this.http.get<ResourceQueryResult<MasterDataRecordResource.MasterDataRecord>>(
      this.url + `${request.master_data_id}/records`,
      this.parseParams(request));
  }

  get(request: MasterDataRecordResource.GetRequest): Observable<MasterDataRecordResource.MasterDataRecord> {
    return this.http.get<MasterDataRecordResource.MasterDataRecord>(
      this.url + `${request.master_data_id}/records/${request.master_data_record_id}`, this.parseParams(request)
    );
  }

  create(request: MasterDataRecordResource.CreateRequest): Observable<MasterDataRecordResource.MasterDataRecord> {
    return this.http.post<MasterDataRecordResource.MasterDataRecord>(
      this.url + `${request.master_data_id}/records?with_form_record=${request.with_form_record}`,
      request
    );
  }

  quickCreate(request: MasterDataRecordResource.QuickCreateRequest): Observable<MasterDataRecordResource.MasterDataRecord> {
    return this.http.post<MasterDataRecordResource.MasterDataRecord>(
      this.url + `${request.master_data_id}/records/quick-create?with_form_record=${request.with_form_record}`,
      request
    );
  }

  update(request: MasterDataRecordResource.UpdateRequest): Observable<MasterDataRecordResource.MasterDataRecord> {
    return this.http.put<MasterDataRecordResource.MasterDataRecord>(
      this.url + `${request.master_data_id}/records/${request.master_data_record_id}?with_form_record=${request.with_form_record}`,
      request
    );
  }

  setDisabled(request: MasterDataRecordResource.DisableRequest): Observable<MasterDataRecordResource.MasterDataRecord> {
    return this.http.patch<MasterDataRecordResource.MasterDataRecord>(
      this.url +
      `${request.master_data_id}/records/${request.master_data_record_id}/disabled?with_form_record=${request.with_form_record}`,
      request
    );
  }

  connectToCustomerRecord(request: MasterDataRecordResource.ConnectToCustomerRecordRequest):
    Observable<EmptyMessage> {
    return this.http.patch<EmptyMessage>(
      this.url + `/records/connect-to-customer-record`,
      request
    );
  }

  getAttachments(request: MasterDataRecordResource.GetAttachmentRequest): Observable<FileAttachmentResource[]> {
    return this.http.get<FileAttachmentResource[]>
    (this.url + `${request.master_data_id}/records/${request.master_data_record_id}/attachments`, this.parseParams(request));
  }

  downloadAttachment(masterDataId: number, masterDataRecordId: number, attachmentId: number, thumbnail?: boolean): Observable<DownloadedFile> {
    const url = UrlBuilder.create('master-data/:masterDataId/records/:masterDataRecordId/attachments/:attachmentId'
      + (thumbnail ? '/thumbnail' : ''))
      .baseUrl(this.resourceHelper.getBaseUrl())
      .namedNumber('masterDataId', masterDataId)
      .namedNumber('masterDataRecordId', masterDataRecordId)
      .namedNumber('attachmentId', attachmentId)
      .build();
    return this.http.get(url, {
      observe: 'response',
      responseType: 'blob',
    }).pipe(map((res) => {
      return new DownloadedFile(res);
    }));
  }

  deleteAttachment(request: MasterDataRecordResource.DeleteAttachmentRequest): Observable<EmptyMessage> {
    return this.http.delete<IdentityMessage>(
      this.url + `${request.master_data_id}/records/${request.master_data_record_id}/attachments/${request.file_id}`);
  }

  updateAttachment(request: MasterDataRecordResource.AttachmentUpdateRequest): Observable<EmptyMessage> {
    return this.http.patch<EmptyMessage>(
      this.url + `${request.master_data_id}/records/${request.master_data_record_id}/attachments/${request.file_id}`, request);
  }

  exportXls(request: MasterDataRecordResource.QueryRequest): Observable<DownloadedFile> {
    const url = UrlBuilder.create('master-data/:masterDataId/records/export-xls')
      .baseUrl(this.resourceHelper.getBaseUrl())
      .namedNumber('masterDataId', <number>request.master_data_id!)
      .build();
    return this.http.get(url, {
      observe: 'response',
      responseType: 'blob',
      params: this.parseParams(request).params
    }).pipe(map((res) => {
      return new DownloadedFile(res);
    }));
  }

  exportXlsTemplate(request: IdentityMessage): Observable<DownloadedFile> {
    const url = UrlBuilder.create('master-data/:masterDataId/records/xls-template')
      .baseUrl(this.resourceHelper.getBaseUrl())
      .namedNumber('masterDataId', <number>request.id)
      .build();
    return this.http.get(url, {
      observe: 'response',
      responseType: 'blob',
      params: this.parseParams(request).params
    }).pipe(map((res) => {
      return new DownloadedFile(res);
    }));
  }

  // </editor-fold>

  constructor(private http: HttpClient, private resourceHelper: ResourceHelper) {
    super(resourceHelper, '/master-data/');
  }

}

@Injectable()
export class MasterDataRecordGlobalResourceService extends BaseHttpService {

  // <editor-fold desc="CRUD">

  query(request: MasterDataRecordResource.GlobalQueryRequest): Observable<ResourceQueryResult<MasterDataRecordResource.MasterDataRecord>> {
    return this.http.get<ResourceQueryResult<MasterDataRecordResource.MasterDataRecord>>(
      this.url,
      this.parseParams(request));
  }

  connectToCustomerRecord(request: MasterDataRecordResource.ConnectToCustomerRecordRequest):
    Observable<EmptyMessage> {
    return this.http.patch<EmptyMessage>(
      this.url + `/connect-to-customer-record`,
      request
    );
  }

  downloadAttachmentZip(request: MasterDataRecordResource.AttachmentZipRequest): Observable<DownloadedFile> {
    const url = this.url + 'attachments/download-zip';
    return this.http.post(url, request, {
      observe: 'response',
      responseType: 'blob',
    }).pipe(map((res) => {
      return new DownloadedFile(res);
    }));
  }

  // </editor-fold>

  constructor(private http: HttpClient, private resourceHelper: ResourceHelper) {
    super(resourceHelper, '/master-data-records/');
  }

}

export namespace MasterDataRecordResource {

  // <editor-fold desc="CRUD">

  export interface QueryRequest {
    fields?: string;
    with_form_record: boolean;
    displayed_form_field_id?: number;
    rights?: string;

    master_data_id: number;
    id?: string;
    disabled?: boolean;
    name?: string;
    external_id?: string;
    description?: string;
    owner_user_ids?: string;
    owner_group_ids?: string;
    owner_customer_record_id?: string;
    owner_contact_location_id?: string;
    q?: string;
    dql?: string;
    order?: string;
    page_number?: number;
    number_of_items?: number;
    no_progress_bar?: boolean;
  }

  export interface GlobalQueryRequest {
    fields?: string;
    with_form_record: boolean;
    displayed_form_field_id?: number;
    rights?: string;

    id?: string;
    disabled?: boolean;
    name?: string;
    external_id?: string;
    description?: string;
    owner_user_ids?: string;
    owner_group_ids?: string;
    owner_customer_record_id?: string;
    owner_contact_location_id?: string;
    q?: string;
    dql?: string;
    order?: string;
    page_number?: number;
    number_of_items?: number;
    no_progress_bar?: boolean;
  }

  export interface GetRequest {
    fields?: string;
    with_form_record: boolean;
    rights?: string;

    master_data_id: number;
    master_data_record_id: number;
  }

  export interface CreateRequest {
    fields?: string;
    with_form_record: boolean;

    master_data_id: number;
    external_id?: string;
    name?: string;
    description?: string;
    form_record: FormRecordResource.FormRecordCreateRequest;
    owner_user_ids: number[];
    owner_group_ids: number[];
    owner_customer_record_id?: number;
    owner_contact_location_id?: number;
  }

  export interface QuickCreateRequest {
    fields?: string;
    with_form_record: boolean;

    master_data_id: number;
    external_id?: string;
    name?: string;
    description?: string;
    owner_user_ids: number[];
    owner_group_ids: number[];
    owner_customer_record_id?: number;
    owner_contact_location_id?: number;
  }

  export interface UpdateRequest {
    fields?: string;
    with_form_record: boolean;

    master_data_id: number;
    master_data_record_id: number;
    external_id?: string;
    name?: string;
    description?: string;
    form_record: FormRecordResource.FormRecordUpdateRequest;
    owner_user_ids: number[];
    owner_group_ids: number[];
    owner_customer_record_id?: number;
    owner_contact_location_id?: number;
  }

  export interface DisableRequest {
    fields?: string;
    with_form_record: boolean;

    master_data_id: number;
    master_data_record_id: number;
    disabled: boolean;
  }

  export interface ConnectToCustomerRecordRequest {
    ids: number[];
    owner_customer_record_id?: number;
    owner_contact_location_id?: number;
  }

  export interface GetAttachmentRequest {
    master_data_id: number;
    master_data_record_id: number;
  }

  export interface DeleteAttachmentRequest {
    master_data_id: number;
    master_data_record_id: number;
    file_id: number;
  }

  export interface AttachmentUpdateRequest {
    master_data_id: number;
    master_data_record_id: number;
    file_id: number;
    name: string;
  }

  // Each field is optional (based on field attribute in the request).
  export interface MasterDataRecord {
    id?: number; // non-null if requested
    master_data_id?: number; // non-null if requested
    external_id?: string;
    disabled?: boolean; // non-null if requested
    creation_time?: string; // non-null if requested
    update_time?: string; // non-null if requested
    name?: string; //
    description?: string;
    form_record?: FormRecordResource.FormRecord; // non-null if requested
    owner_user_ids?: number[];
    owner_group_ids?: number[];
    owner_customer_record_id?: number;
    owner_contact_location_id?: number;
    granted_rights?: string[];
  }

  export interface AttachmentZipRequest {
    master_data_record_ids: number[];
  }

  // </editor-fold>

}
