<div class="form-group">
  <div class="d-inline">
    <label class="d-flex justify-content-between">
      {{expression.criteria.field!.title + ' ' + expression.criteria.operation!.operation}}
      <i class="icomoon icomoon-exit align-self-center" *ngIf="!disableDelete" (click)="onDelete.emit(expression)"></i>
    </label>
  </div>
  <div class="input-group">
    <input type="text" class="form-control date"
           [ngModel]="dateModel" (ngModelChange)="setValue($event)" ngbDatepicker name="datePicker" id="datePicker"
           #datePicker="ngbDatepicker">
    <div class="input-group-addon-gray" (click)="datePicker.toggle()">
      <i class="icomoon icomoon-calendar"></i>
    </div>
  </div>
</div>
