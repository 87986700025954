import { MultiselectOptionItem } from '../../../util/core-utils';
import { Workflow } from '../../../lib/workflow/workflow.service';
import { Icon } from '../../../lib/task/icon.service';
import { Strings } from '../../../lib/util/strings';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Map, Set } from 'immutable';
import { Models } from '../../../util/model-utils';
import { Task } from '../../../lib/task/task.service';
import ProcessConfig = Workflow.ProcessConfig;
import TaskRecordExternalIdSequenceData = Task.TaskRecordExternalIdSequenceData;
import { Process } from '../../../lib/process/process.service';

export class WorkflowCreateModel {

  externalId: string;
  name: string;
  description: string;
  inheritanceMode: MultiselectOptionItem<Workflow.InheritanceMode>[];
  icon?: Icon.Icon = {
    iconType: 'ICON_SET',
    iconSet: {
      setCode: 'google_material',
      setClass: 'material-icons',
      iconCode: 'call_split',
      usageType: 'TEXT'
    }
  };
  startWithFirstTask: boolean = false;
  processConfig: ProcessConfigModel = new ProcessConfigModel();

  readonly inheritanceModes: MultiselectOptionItem<Workflow.InheritanceMode>[] = [];
  readonly requirableFields: MultiselectOptionItem<Process.ProcessFieldType>[] = [];

  private readonly _id?: number;
  private readonly _inheritanceModeKey: string = '';
  private readonly _versionStateKey: string = '';

  constructor(private _workflow?: Workflow.Workflow) {
    this.inheritanceModes = Workflow.inheritanceModes.map(m => {
      return {
        itemName: m.stringKey,
        id: m.mode
      };
    });
    this.requirableFields = Process.processFieldTypes.map(m => {
      return {
        itemName: m.stringKey,
        id: m.fieldType
      };
    });
    if (_workflow) {
      const mode = this.inheritanceModes.find(im => im.id === _workflow.inheritanceMode);
      const versionState = Workflow.versionStates.find(im => im.state === _workflow.versionState);
      this._inheritanceModeKey = mode ? mode.itemName : '';
      this._versionStateKey = versionState ? versionState.stringKey : '';
      this._id = _workflow.id;
      this.externalId = _workflow.externalId;
      this.name = _workflow.name;
      this.description = _workflow.description ? _workflow.description : '';
      this.inheritanceMode = mode ? [mode] : [];
      this.icon = _workflow.icon;
      this.startWithFirstTask = _workflow.startWithFirstTask;
      if (_workflow.processConfig) {
        this.processConfig = new ProcessConfigModel(_workflow.processConfig);
      }
    }
  }

  toCreateRequest(): Workflow.CreateRequest {
    return {
      name: this.name,
      externalId: Strings.undefinedOrNonEmpty(this.externalId),
      description: Strings.undefinedOrNonEmpty(this.description),
      inheritanceMode: this.inheritanceMode[0].id!,
      icon: this.icon,
      stateMachine: { // some random location
        entryPoint: {
          location: {
            size: {
              height: 20,
              width: 20
            },
            topLeftPoint: {
              x: 10,
              y: 10
            }
          }
        }
      },
      startWithFirstTask: this.startWithFirstTask,
      processConfig: {
        namePattern: this.startWithFirstTask ? Strings.undefinedOrNonEmpty(this.processConfig.namePattern) : undefined,
        externalIdSequence: this.startWithFirstTask && Strings.undefinedOrNonEmpty(this.processConfig.externalIdSequence.template) ? {
          template: this.processConfig.externalIdSequence.template,
          withinYear: this.processConfig.externalIdSequence.withinYear
        } : undefined,
        requiredFields: Set.of(...this.processConfig.requiredFields.map(f => f.id!))
      }
    };
  }

  toUpdateRequest(): Workflow.UpdateRequest {
    return {
      id: this._id!,
      name: this.name,
      externalId: this.externalId,
      description: Strings.undefinedOrNonEmpty(this.description),
      inheritanceMode: this.inheritanceMode[0].id!,
      icon: this.icon,
      startWithFirstTask: this.startWithFirstTask,
      processConfig: {
        namePattern: this.startWithFirstTask ? Strings.undefinedOrNonEmpty(this.processConfig.namePattern) : undefined,
        externalIdSequence: this.startWithFirstTask && Strings.undefinedOrNonEmpty(this.processConfig.externalIdSequence.template) ? {
          template: this.processConfig.externalIdSequence.template,
          withinYear: this.processConfig.externalIdSequence.withinYear
        } : undefined,
        requiredFields: Set.of(...this.processConfig.requiredFields.map(f => f.id!))
      }
    };
  }

  get id(): number | undefined {
    return this._id;
  }

  get workflow(): Workflow.Workflow | undefined {
    return this._workflow;
  }

  get inheritanceModeKey(): string {
    return this._inheritanceModeKey;
  }

  get versionStateKey(): string {
    return this._versionStateKey;
  }

  isInheritanceEditable(): boolean {
    if (!this.workflow) {
      return true;
    }
    return this.workflow.versionState === Workflow.VersionState.DRAFT;
  }

  getLastModifierName(): string {
    if (!this.workflow) {
      return '';
    }
    return this.workflow.lastModifiedUser!.personName;
  }
}

export class ProcessConfigModel {
  namePattern: string = '';
  externalIdSequence: ExternalIdSequenceModel = new ExternalIdSequenceModel();
  requiredFields: MultiselectOptionItem<Process.ProcessFieldType>[] = [];

  constructor(config?: ProcessConfig) {
    if (config) {
      this.namePattern = Models.optToString(config.namePattern);
      this.externalIdSequence = new ExternalIdSequenceModel(config.externalIdSequence);
      this.requiredFields = config.requiredFields.map(f => Process.processFieldTypes.find(x => x.fieldType === f))
        .filter(f => f !== undefined)
        .map(f => {
          return {itemName: f!.stringKey, id: f!.fieldType}
        }).toArray();
    }
  }
}

class ExternalIdSequenceModel {
  template: string = '';
  withinYear: boolean = false;

  constructor(sequence?: TaskRecordExternalIdSequenceData) {
    if (sequence) {
      this.template = Models.optToString(sequence.template);
      this.withinYear = sequence.withinYear;
    }
  }
}

export namespace WorkflowProcessConfigNameTemplate {
  export const FIRST_TASK_RECORD_NAME = '{{FirstTaskRecordName}}';
  export const FIRST_TASK_RECORD_EXTERNAL_ID = '{{FirstTaskRecordExternalId}}';
  export const CUSTOMER_NAME: string = '{{CustomerName}}';
  export const CUSTOMER_RECORD_NAME: string = '{{CustomerRecordName}}';
  export const ACTUAL_DATE: string = '{{ActualDate}}';
  export const POC_ADDRESS: string = '{{PocAddress}}';
  export const ASSIGNEE_USER_GROUP_NAME: string = '{{AssigneeUserGroupName}}';
  export const ASSIGNEE_USER_NAME: string = '{{AssigneeUserName}}';
  export const ASSIGNEE_USER_PERSON_NAME: string = '{{AssigneeUserPersonName}}';
  export const CREATOR_USER_NAME: string = '{{CreatorUserName}}';
  export const CREATOR_USER_PERSON_NAME: string = '{{CreatorUserPersonName}}';
  export const WORKFLOW_NAME: string = '{{WorkflowName}}';
  export const WORKFLOW_EXTERNAL_ID: string = '{{WorkflowExternalId}}';

  export const templateBlockSeparator = '/';
  export const separatorKeysCodes: number[] = [ENTER, COMMA];

  export const availableTemplateBlocks: string[] = [
    FIRST_TASK_RECORD_NAME,
    FIRST_TASK_RECORD_EXTERNAL_ID,
    CUSTOMER_NAME,
    CUSTOMER_RECORD_NAME,
    ACTUAL_DATE,
    POC_ADDRESS,
    ASSIGNEE_USER_GROUP_NAME,
    ASSIGNEE_USER_NAME,
    ASSIGNEE_USER_PERSON_NAME,
    CREATOR_USER_NAME,
    CREATOR_USER_PERSON_NAME,
    WORKFLOW_NAME,
    WORKFLOW_EXTERNAL_ID
  ];

  export const templateBlockExampleMap: Map<string, string> = Map.of(
    FIRST_TASK_RECORD_NAME, 'TASK_RECORD_CREATE_NAME_TEMPLATE_EXAMPLE_FIRST_TASK_RECORD_NAME',
    FIRST_TASK_RECORD_EXTERNAL_ID, 'TASK_RECORD_CREATE_NAME_TEMPLATE_EXAMPLE_FIRST_TASK_RECORD_EXTERNAL_ID',
    CUSTOMER_NAME, 'TASK_RECORD_CREATE_NAME_TEMPLATE_EXAMPLE_CUSTOMER_NAME',
    CUSTOMER_RECORD_NAME, 'TASK_RECORD_CREATE_NAME_TEMPLATE_EXAMPLE_CUSTOMER_RECORD_NAME',
    ACTUAL_DATE, 'TASK_RECORD_CREATE_NAME_TEMPLATE_EXAMPLE_ACTUAL_DATE',
    POC_ADDRESS, 'TASK_RECORD_CREATE_NAME_TEMPLATE_EXAMPLE_POC_ADDRESS',
    ASSIGNEE_USER_GROUP_NAME, 'TASK_RECORD_CREATE_NAME_TEMPLATE_EXAMPLE_ASSIGNEE_USER_GROUP_NAME',
    ASSIGNEE_USER_NAME, 'TASK_RECORD_CREATE_NAME_TEMPLATE_EXAMPLE_ASSIGNEE_USER_NAME',
    ASSIGNEE_USER_PERSON_NAME, 'TASK_RECORD_CREATE_NAME_TEMPLATE_EXAMPLE_ASSIGNEE_USER_PERSON_NAME',
    CREATOR_USER_NAME, 'TASK_RECORD_CREATE_NAME_TEMPLATE_EXAMPLE_ASSIGNEE_USER_NAME',
    CREATOR_USER_PERSON_NAME, 'TASK_RECORD_CREATE_NAME_TEMPLATE_EXAMPLE_ASSIGNEE_USER_PERSON_NAME',
    WORKFLOW_NAME, 'WORKFLOW_CREATE_PROCESS_CONFIG_NAME_TEMPLATE_EXAMPLE_WORKFLOW_NAME',
    WORKFLOW_EXTERNAL_ID, 'WORKFLOW_CREATE_PROCESS_CONFIG_NAME_TEMPLATE_EXAMPLE_WORKFLOW_EXTERNAL_ID',
  );

}
