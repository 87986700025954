/* eslint-disable */
import { Injectable } from '@angular/core';
import { Observable, Observer } from 'rxjs';
import {
  FieldValidationError,
  Order,
  PagingRequest,
  QueryResult,
  ResourceQueryResult,
  Services
} from '../../util/services';
import { ObservableErrorResourceParser } from '../../util/errors';
import { List, Map, Set } from 'immutable';
import { InvoiceBookResource, InvoiceBookResourceService } from './invoice-book-resource.service';
import { LocalDate, OffsetDateTime } from '../../util/dates';
import { EmptyMessage } from '../../util/messages';

/* eslint-enable */

@Injectable()
export class InvoiceBookService implements InvoiceBook.Service {

  constructor(private resourceService: InvoiceBookResourceService) {
  }

  query(request: InvoiceBook.QueryRequest): Observable<QueryResult<InvoiceBook.InvoiceBook>> {
    return Observable.create((observer: Observer<QueryResult<InvoiceBook.InvoiceBook>>) => {
      const resourceRequest: InvoiceBookResource.QueryRequest = {
        id: Services.createIdParameter(request.invoiceBookIdSet),
        disabled: request.disabled,
        comment: request.comment,
        prefix: request.prefix,
        external_id: request.externalId,
        q: request.queryText,
        invoice_settings_id: Services.createIdParameter(request.invoiceSettingsIds),
        order: Services.createOrderFieldParameter(Keys.toOrderFieldKey, request.orders),
        page_number: request.paging ? request.paging.pageNumber : undefined,
        number_of_items: request.paging ? request.paging.numberOfItems : undefined,
        no_progress_bar: request.noProgressBar
      };
      return this.resourceService.query(resourceRequest).subscribe(
        (result: ResourceQueryResult<InvoiceBookResource.InvoiceBook>) => {
          observer.next({
            items: this.toPublicList(result.items),
            pagingResult: result.pagingResult
          });
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  get(request: InvoiceBook.GetRequest): Observable<InvoiceBook.InvoiceBook> {
    return Observable.create((observer: Observer<InvoiceBook.InvoiceBook>) => {
      const resourceRequest: InvoiceBookResource.GetRequest = {
        invoice_book_id: request.id
      };
      return this.resourceService.get(resourceRequest).subscribe(
        (result: InvoiceBookResource.InvoiceBook) => {
          observer.next(this.toPublic(result));
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  create(request: InvoiceBook.CreateRequest): Observable<InvoiceBook.InvoiceBook> {
    return Observable.create((observer: Observer<InvoiceBook.InvoiceBook>) => {
      const resourceRequest: InvoiceBookResource.CreateRequest = {
        prefix: request.prefix,
        external_id: request.externalId,
        start_number: request.startNumber,
        comment: request.comment,
        invoice_settings_id: request.invoiceSettingsId
      };
      return this.resourceService.create(resourceRequest).subscribe(
        (result: InvoiceBookResource.InvoiceBook) => {
          observer.next(this.toPublic(result));
        },
        (error: any) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  update(request: InvoiceBook.UpdateRequest): Observable<InvoiceBook.InvoiceBook> {
    return Observable.create((observer: Observer<InvoiceBook.InvoiceBook>) => {
      const resourceRequest: InvoiceBookResource.UpdateRequest = {
        invoice_book_id: request.invoiceBookId,
        external_id: request.externalId,
        comment: request.comment
      };
      return this.resourceService.update(resourceRequest).subscribe(
        (result: InvoiceBookResource.InvoiceBook) => {
          observer.next(this.toPublic(result));
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  setDisabled(request: InvoiceBook.DisableRequest): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      const resourceRequest: InvoiceBookResource.DisableRequest = {
        invoice_book_id: request.invoiceBookId,
        disabled: request.disabled
      };
      return this.resourceService.setDisabled(resourceRequest).subscribe(
        (result: EmptyMessage) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  private translateError(error: any): any {
    const res = ObservableErrorResourceParser.parseError(error);
    const fieldErrors = ObservableErrorResourceParser.extractFieldErrors(res);
    const fieldErrorMap = ObservableErrorResourceParser.toFieldErrorMap(Keys.toValidatedField, fieldErrors);
    if (!fieldErrorMap.isEmpty()) {
      return FieldValidationError.of(fieldErrorMap);
    }
    return error;
  }

  private toPublicList(resourceList: InvoiceBookResource.InvoiceBook[]): List<InvoiceBook.InvoiceBook> {
    return List.of(...resourceList.map((r) => this.toPublic(r)));
  }

  private toPublic(r: InvoiceBookResource.InvoiceBook): InvoiceBook.InvoiceBook {
    return {
      id: r.id,
      prefix: r.prefix,
      externalId: r.external_id,
      startNumber: r.start_number,
      nextInvoiceNumber: r.next_invoice_number,
      lastInvoiceString: r.last_invoice_string,
      lastInvoiceDate: Services.toLocalDate(r.last_invoice_date),
      invoiceSettings: {
        id: r.invoice_settings.id,
        name: r.invoice_settings.name
      },
      comment: r.comment,
      disabled: r.disabled,
      creationTime: Services.toOffsetDateTime(r.creation_time),
      updateTime: Services.toOffsetDateTime(r.update_time)
    };
  }

}

export namespace InvoiceBook {

  export interface Service {

    query(request: QueryRequest): Observable<QueryResult<InvoiceBook>>;

    get(request: GetRequest): Observable<InvoiceBook>;

    create(request: CreateRequest): Observable<InvoiceBook>;

    update(request: UpdateRequest): Observable<InvoiceBook>;

    setDisabled(request: DisableRequest): Observable<EmptyMessage>;

  }

  export interface InvoiceBook {
    id: number,
    prefix: string,
    externalId: string,
    startNumber: number,
    nextInvoiceNumber: number,
    lastInvoiceString: string,
    lastInvoiceDate: LocalDate,
    invoiceSettings: {
      id: number;
      name: string;
    };
    comment: string,
    disabled: boolean,
    creationTime: OffsetDateTime,
    updateTime: OffsetDateTime,
  }

  export interface QueryRequest {
    invoiceBookIdSet?: Set<number>;
    disabled?: boolean,
    comment?: string,
    prefix?: string,
    externalId?: string,
    queryText?: string,
    invoiceSettingsIds?: Set<number>;
    orders?: Set<Order<OrderField>>;
    paging?: PagingRequest;
    noProgressBar?: boolean;
  }

  export interface GetRequest {
    id: number;
  }

  export interface CreateRequest {
    prefix: string,
    externalId?: string,
    startNumber: number,
    comment?: string,
    invoiceSettingsId: number;
  }

  export interface UpdateRequest {
    invoiceBookId: number,
    externalId: string,
    comment?: string;
  }

  export interface DisableRequest {
    invoiceBookId: number;
    disabled: boolean;
  }

  export enum OrderField {
    ID,
    PREFIX,
    EXTERNAL_ID,
    COMMENT,
    UPDATE_TIME,
    LAST_INVOICE_DATE
  }

  export enum ValidatedField {
    UNKNOWN,
    PREFIX,
    EXTERNAL_ID,
    START_NUMBER
  }

}


class Keys {

  private static readonly ID = 'id';
  private static readonly PREFIX = 'prefix';
  private static readonly EXTERNAL_ID = 'external_id';
  private static readonly START_NUMBER = 'start_number';
  private static readonly LAST_INVOICE_STRING = 'last_invoice_string';
  private static readonly UPDATE_TIME = 'update_time';
  private static readonly COMMENT = 'comment';

  private static readonly orderFieldKeyMap: Map<InvoiceBook.OrderField, string> = Map.of(
    InvoiceBook.OrderField.ID, Keys.ID,
    InvoiceBook.OrderField.UPDATE_TIME, Keys.UPDATE_TIME,
    InvoiceBook.OrderField.PREFIX, Keys.PREFIX,
    InvoiceBook.OrderField.EXTERNAL_ID, Keys.EXTERNAL_ID,
    InvoiceBook.OrderField.COMMENT, Keys.COMMENT,
  );

  private static readonly keyValidatedFieldMap: Map<string, InvoiceBook.ValidatedField> = Map.of(
    Keys.PREFIX, InvoiceBook.ValidatedField.PREFIX,
    Keys.EXTERNAL_ID, InvoiceBook.ValidatedField.EXTERNAL_ID,
    Keys.START_NUMBER, InvoiceBook.ValidatedField.START_NUMBER,
  );

  public static toOrderFieldKey(field: InvoiceBook.OrderField): string {
    return Keys.orderFieldKeyMap.get(field)!;
  }

  public static toValidatedField(fieldKey: string): InvoiceBook.ValidatedField {
    return Keys.keyValidatedFieldMap.get(fieldKey, InvoiceBook.ValidatedField.UNKNOWN);
  }

}
