/* eslint-disable */
import { EmptyMessage } from '../util/messages';
import { Injectable } from '@angular/core';
import { CustomerRecordResource } from '../customer/customer-record-resource.service';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { BaseHttpService, ResourceHelper } from '../util/http-services';
import { UserItemResource } from '../user.service';

/* eslint-enable */

@Injectable()
export class TokenResourceService extends BaseHttpService {

  query(request: TokenResource.QueryRequest): Observable<TokenResource.Token[]> {
    return this.http.get<TokenResource.Token[]>(this.url, this.parseParams(request));
  }

  deleteWithTokenId(request: TokenResource.DeleteTokenIdRequest): Observable<EmptyMessage> {
    return this.http.delete(this.url + `access-token/${request.access_token_id}`);
  }

  deleteWithUserMobile(request: TokenResource.DeleteUserMobileRequest): Observable<EmptyMessage> {
    return this.http.delete(this.url + `user-profile/${request.user_profile_id}/mobile-application/${request.mobile_application_id}`);
  }

  constructor(private http: HttpClient, resourceHelper: ResourceHelper) {
    super(resourceHelper, '/tokens/');
  }

}

export namespace TokenResource {

  import MobileApplicationItem = CustomerRecordResource.MobileApplicationItem;

  export interface Token {
    access_token_id?: number;
    user_profile: UserItemResource;
    mobile_application?: MobileApplicationItem;
    create_date: string;
    access_date?: string;
    expiry: string;
    type: string;
  }

  export interface QueryRequest {
    user_profile_person_name?: string;
    mobile_application_name?: string;
    mobile_application_application_id?: string;
    mobile_application_display_name?: string;
    q?: string;
    order?: string;
    page_number?: number;
    number_of_items?: number;
    type?: string;
  }

  export interface DeleteTokenIdRequest {
    access_token_id: number;
  }

  export interface DeleteUserMobileRequest {
    user_profile_id: number;
    mobile_application_id: number;
  }


}
