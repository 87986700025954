<div class="card">

  <div class="card-header">
    {{'COMMON_DOCUMENTS' | translate}}
    <div class="card-actions">
      <a class="btn-setting table-header-action-button d-flex align-items-center px-1 w-auto font-weight-normal"
         *ngIf="rightModel.orderDocumentCreate.hasRight()"
         (click)="onOrderCreateButtonClicked.emit(orderId)"
         title="{{'TABLE_DOCUMENT_CREATE_TITLE' | translate}}">
        <i class="icomoon pr-1 icomoon-add"></i>{{'TABLE_DOCUMENT_CREATE_TITLE' | translate}}
      </a>
    </div><!-- /.card-actions -->
  </div>

  <div class="card-body" *ngIf="!rightModel.orderDocumentRead.hasRight()">
    <div class="p-2 text-center text-muted">
      <h6>{{ "ORDER_DOCUMENT_LIST_READ_PERMISSION_DENIED" | translate }}</h6>
    </div>
  </div>

  <div class="card-body" *ngIf="rightModel.orderDocumentRead.hasRight()">
    <table class="table table-striped table-bordered">
      <thead>
      <tr>
        <th class="table-sorter-header">
          <app-table-field-sorter [orderField]="OrderFunctions.TYPE"
                                  [orderType]="queryModel.getOrderType(OrderFunctions.TYPE)"
                                  (orderChange)="orderBy($event)"
                                  [text]="'ORDER_DOCUMENT_TYPE' | translate"></app-table-field-sorter>
        </th>
        <th class="table-sorter-header hidden-sm-down">
          <app-table-sorter-no-op [text]="'DOCUMENT_ID' | translate"></app-table-sorter-no-op>
        </th>
        <th class="table-sorter-header hidden-sm-down">
          <app-table-field-sorter [orderField]="OrderFunctions.ORIGINAL_FILE_NAME"
                                  [orderType]="queryModel.getOrderType(OrderFunctions.ORIGINAL_FILE_NAME)"
                                  (orderChange)="orderBy($event)"
                                  [text]="'COMMON_FILE_NAME' | translate"></app-table-field-sorter>
        </th>
        <th class="table-sorter-header hidden-sm-down">
          <app-table-sorter-no-op [text]="'COMMON_COMMENT' | translate"></app-table-sorter-no-op>
        </th>
        <th class="table-sorter-header">
          <app-table-field-sorter [orderField]="OrderFunctions.CREATION_TIME"
                                  [orderType]="queryModel.getOrderType(OrderFunctions.CREATION_TIME)"
                                  (orderChange)="orderBy($event)"
                                  [text]="'COMMON_UPLOADED_TIME' | translate"></app-table-field-sorter>
        </th>
        <th class="table-sorter-header w-10">
          <app-table-sorter-no-op></app-table-sorter-no-op>
        </th>
      </tr>
      </thead>
      <tbody>
      <tr class="table"
          *ngFor="let orderDocument of orderDocumentList | paginate: { id: pagingId,
                                                      itemsPerPage: queryModel.itemsPerPage,
                                                      currentPage: queryModel.currentPage,
                                                      totalItems: queryModel.currentNumberOfItems }">
        <td class="responsive-table-column">{{orderDocument.type}}</td>
        <td class="responsive-table-column hidden-sm-down long">{{orderDocument.id}}</td>
        <td class="responsive-table-column hidden-sm-down">{{orderDocument.originalFileName}}</td>
        <td class="responsive-table-column hidden-sm-down">{{orderDocument.comment}}</td>
        <td class="responsive-table-column">{{orderDocument.creationTime.toUtcIsoString() | date:'short'}}</td>
        <td class="responsive-table-column text-right">
          <a class="icons-table-group cursor-pointer" *ngIf="rightModel.orderDocumentDownload.hasRight()"
             (click)="downloadFile(orderDocument)">
            <i class="icomoon icons-table-item icomoon-download icomoon-modify-table"
               title="{{'COMMON_BUTTON_DOWNLOAD' | translate}}"></i>
          </a>
          <a class="icons-table-group cursor-pointer" *ngIf="rightModel.orderDocumentDelete.hasRight() && orderDocument.uploadedByUser"
             (click)="deleteFile(orderDocument.id)">
            <i class="icomoon icons-table-item icomoon-trash icomoon-modify-table"
               title="{{'COMMON_BUTTON_DELETE' | translate}}"></i>
          </a>
        </td>
      </tr>
      </tbody>
      <tfoot>
      <tr>
        <th class="table-sorter-header">
          <app-table-field-sorter [orderField]="OrderFunctions.TYPE"
                                  [orderType]="queryModel.getOrderType(OrderFunctions.TYPE)"
                                  (orderChange)="orderBy($event)"
                                  [text]="'ORDER_DOCUMENT_TYPE' | translate"></app-table-field-sorter>
        </th>
        <th class="table-sorter-header hidden-sm-down">
          <app-table-sorter-no-op [text]="'DOCUMENT_ID' | translate"></app-table-sorter-no-op>
        </th>
        <th class="table-sorter-header hidden-sm-down">
          <app-table-field-sorter [orderField]="OrderFunctions.ORIGINAL_FILE_NAME"
                                  [orderType]="queryModel.getOrderType(OrderFunctions.ORIGINAL_FILE_NAME)"
                                  (orderChange)="orderBy($event)"
                                  [text]="'COMMON_FILE_NAME' | translate"></app-table-field-sorter>
        </th>
        <th class="table-sorter-header hidden-sm-down">
          <app-table-sorter-no-op [text]="'COMMON_COMMENT' | translate"></app-table-sorter-no-op>
        </th>
        <th class="table-sorter-header">
          <app-table-field-sorter [orderField]="OrderFunctions.CREATION_TIME"
                                  [orderType]="queryModel.getOrderType(OrderFunctions.CREATION_TIME)"
                                  (orderChange)="orderBy($event)"
                                  [text]="'COMMON_UPLOADED_TIME' | translate"></app-table-field-sorter>
        </th>
        <th class="table-sorter-header w-10">
          <app-table-sorter-no-op></app-table-sorter-no-op>
        </th>
      </tr>
      </tfoot>
    </table>
    <app-table-paging
      [id]="pagingId"
      [currentNumberOfItems]="queryModel.currentNumberOfItems"
      [totalNumberOfItems]="queryModel.totalNumberOfItems"
      [itemsPerPage]="queryModel.itemsPerPage"
      (pageChange)="pageChanged($event)"
      (itemsPerPageChange)="itemsPerPageChanged($event)">
    </app-table-paging>
  </div>
</div>
