<div class="modal-content">
  <div class="modal-header">
    <p class="modal-title"><strong>{{'INVOICE_SETTLE' | translate}}</strong></p>
    <button type="button" class="close" (click)="closeDialog(false)" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div *ngIf="!readonly">
      <div class="alert alert-danger">
        <h6 class="alert-title mb-0">{{'INVOICE_SETTLE_APPROVE_MESSAGE' | translate }}</h6>
      </div>
    </div>
    <mat-dialog-content style="overflow:visible">
      <form (ngSubmit)="save()" #f="ngForm" id="form"
            [formGroup]="formGroup" novalidate>

        <div class="row form-group"
             [ngClass]="{ 'has-danger': hasLocalFieldError('eventName')}">
          <label
            class="col-form-label form-control-label col-md-4 detail-title"
            [class.required-field-label]="true">{{'INVOICE_SPENDING_EVENT_NAME' | translate}}</label>
          <div class="col-md-8">
            <div class="mw-100">
              <input type="text"
                     [(ngModel)]="model.eventName"
                     [ngClass]="{ 'form-control-danger': hasLocalFieldError('eventName') }"
                     trim="blur"
                     validateOnBlur
                     class="form-control"
                     name="eventName"
                     id="eventName"
                     formControlName="eventName"
                     placeholder="{{'INVOICE_SPENDING_EVENT_NAME'|translate}}">
              <div class="form-control-feedback" *ngIf="hasLocalFieldError('eventName')">
                {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
              </div>
            </div>
          </div>
        </div>
        <div class="row form-group"[ngClass]="{ 'has-danger': hasLocalFieldError('invoiceSpendingType')}">
          <label
            class="col-form-label form-control-label col-md-4 detail-title"
            [class.required-field-label]="!hasInvoice()">{{'INVOICE_SPENDING_SPENDING_TYPE' | translate}}</label>
          <div class="col-md-8">
            <div class="mw-100">
              <select
                class="form-control"
                [ngClass]="{ 'form-control-danger': hasLocalFieldError('invoiceSpendingType') }"
                name="invoiceSpendingType"
                id="invoiceSpendingType"
                formControlName="invoiceSpendingType"
                [(ngModel)]="model.invoiceSpendingType" [compareWith]="SelectUtils.compareObjects">
                <option *ngFor="let item of spendingTypes" [ngValue]="item">{{item.stringKey | translate}}</option>
              </select>
              <div class="form-control-feedback" *ngIf="hasLocalFieldError('invoiceSpendingType')">
                {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
              </div>

            </div>
          </div>
        </div>

        <div class="row form-group"
             [ngClass]="{ 'has-danger': hasLocalFieldError('amount')}">
          <label
            class="col-form-label form-control-label col-md-4 detail-title"
            [class.required-field-label]="!hasInvoice()">{{'INVOICE_SPENDING_AMOUNT' | translate}}</label>
          <div class="col-md-8">
            <div class="mw-100">
              <input type="text"
                     [textMask]="{mask: InputMask.NATURAL_INTEGER_WITH_THOUSAND_SEPARATOR, guide: false}"
                     [(ngModel)]="model.amount"
                     [ngClass]="{ 'form-control-danger': hasLocalFieldError('amount') }"
                     class="form-control"
                     name="amount"
                     id="amount"
                     formControlName="amount"
                     placeholder="{{'INVOICE_SPENDING_AMOUNT'|translate}}">
              <div class="form-control-feedback" *ngIf="hasLocalFieldError('amount')">
                {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
              </div>

            </div>
          </div>
        </div>
        <div class="row form-group"
             [ngClass]="{ 'has-danger': hasLocalFieldError('amountCheck')}" *ngIf="this.hasInvoice()">
          <label
            class="col-form-label form-control-label col-md-4 detail-title"
            [class.required-field-label]="hasInvoice()">{{'INVOICE_SPENDING_AMOUNT_CHECK' | translate}}</label>
          <div class="col-md-8">
            <div class="mw-100">
              <input type="text"
                     [textMask]="{mask: InputMask.NATURAL_INTEGER_WITH_THOUSAND_SEPARATOR, guide: false}"
                     [(ngModel)]="model.amountCheck"
                     [ngClass]="{ 'form-control-danger': hasLocalFieldError('amountCheck') }"
                     class="form-control"
                     name="amountCheck"
                     id="amountCheck"
                     formControlName="amountCheck"
                     placeholder="{{'INVOICE_SPENDING_AMOUNT_CHECK'|translate}}">
              <div class="form-control-feedback" *ngIf="hasLocalFieldError('amountCheck', 'required')">
                {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
              </div>
              <div class="form-control-feedback" *ngIf="hasLocalFieldError('amountCheck', 'validateNumberEquals')">
                {{'INVOICE_SPENDING_AMOUNT_CHECK_NOT_MATCHING' | translate}}
              </div>
            </div>
          </div>
        </div>

        <div class="row form-group">
          <label
            class="col-form-label form-control-label col-md-4 detail-title">{{'COMMON_COMMENT' | translate}}</label>
          <div class="col-md-8">
            <div class="mw-100">
              <input type="text"
                     [(ngModel)]="model.comment"
                     trim="blur"
                     validateOnBlur
                     class="form-control"
                     name="comment"
                     id="comment"
                     formControlName="comment"
                     placeholder="{{'COMMON_COMMENT'|translate}}">
            </div>
          </div>
        </div>
      </form>
      <div *ngIf="readonly">
        <div class="row form-group">
          <label
            class="col-form-label form-control-label col-md-4 detail-title">{{'COMMON_TABLE_HEADER_CREATION_TIME' | translate}}</label>
          <div class="col-md-8">
            <div class="mw-100">
              <label class="form-control detail-input">{{model.creationTime.toUtcIsoString() | date:'short'}}</label>
            </div>
          </div>
        </div>
        <div class="row form-group" *ngIf="model._invoices.length > 0">
          <label
            class="col-form-label form-control-label col-md-4 detail-title">{{'INVOICES_LIST_HEADING' | translate}}</label>
          <div class="col-md-8">
            <ul style="margin-left: 0;">
              <li *ngFor="let invoice of model._invoices">
                <a class="id-text cursor-pointer mr-05"
                   href="#" (click)="closeDialog(false)"
                   uiSref="Admin.InvoiceDetail"
                   [uiParams]="{ id: invoice.id }"
                   title="{{'COMMON_BUTTON_DETAILS' | translate}}">
                  {{invoice.invoiceNumber}}
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </mat-dialog-content>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-outline-primary" (click)="closeDialog(false)">
      {{'COMMON_BUTTON_CANCEL' | translate}}
    </button>
    <button type="submit" form="form" class="btn btn-primary btn-ladda"
            data-style="zoom-in" [ladda]="inProgress" [disabled]="inProgress">
      {{'COMMON_BUTTON_SAVE' | translate}}
    </button>
  </div>
</div>
