<div class="breadcrumb-container" [ngClass]="{ 'breadcrumb-container-compact': compactSidebar }" id="breadcrumb">
  <div class="breadcrumb-component">
    <app-breadcrumb
      [parents]="breadcrumbParents"
      [self]="breadcrumbSelf">
    </app-breadcrumb>
  </div>
</div>
<div class="breadcrumb-placeholder"></div>
<div class="container-vertical-padding animated fadeIn">

  <div class="col-xs-12 col-sm-12 col-md-8">
    <div class="card">

      <div class="card-header">
        {{'STOCK_PANEL_HEADING_ADD'|translate}}
      </div>

      <div class="card-body">
        <form class="form-horizontal bordered-row" (ngSubmit)="f.form.valid && update()" #f="ngForm" novalidate>

          <div class="form-group row" [ngClass]="{ 'has-danger': (fieldErrors.name) || (f.submitted && !name.valid) }">
            <label class="col-form-label form-control-label col-md-4 detail-title required-field-label">
              {{'COMMON_NAME' | translate}}
            </label>
            <div class="col-md-8">
              <input type="text" class="form-control" placeholder="{{'COMMON_NAME' | translate}}"
                     maxlength="{{UiConstants.maximumVarcharLength}}"
                     [ngClass]="{ 'form-control-danger': (fieldErrors.name) || (f.submitted && !name.valid) }"
                     [(ngModel)]="model.name" (ngModelChange)="removeFieldError(fieldErrors.name)" name="name"
                     id="person_name" #name="ngModel" required>
              <div class="form-control-feedback" *ngIf="fieldErrors.name">{{fieldErrors.name.text}}</div>
              <div class="form-control-feedback" *ngIf="f.submitted && !name.valid">
                {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
              </div>
            </div>
          </div>

          <div class="form-group row">
            <label
              class="col-form-label form-control-label col-md-4 detail-title">{{'STOCK_EXTERNAL_ID' | translate}}</label>
            <div class="col-md-8">
              <input type="text" class="form-control" placeholder="{{'STOCK_EXTERNAL_ID' | translate}}"
                     maxlength="{{UiConstants.maximumVarcharLength}}"
                     [(ngModel)]="model.externalId" name="external_id"
                     id="external_id" #external_id="ngModel">
            </div>
          </div>

          <div class="form-group row">
            <label class="col-form-label form-control-label col-md-4 detail-title">{{'STOCK_TYPE' | translate}}</label>
            <div class="col-md-8">
              <select class="form-control" [(ngModel)]="model.type"
                      id="stockType" name="stockType" #stockType="ngModel">
                <option *ngFor="let stockType of stockTypes" [ngValue]="stockType.name">
                  {{stockType.textDictionaryKey | translate}}
                </option>
              </select>
            </div>
          </div>

          <div *ngIf="model.type === 'PERSONAL'" class="form-group row"
               [ngClass]="{ 'has-error': fieldErrors.owner_user_id }">
            <label
              class="col-form-label form-control-label col-md-4 detail-title">{{'STOCK_LABEL_OWNER_USER' | translate}}</label>
            <div class="col-md-8">
              <angular2-multiselect [data]="ownerUsers" [settings]="userDropdownSettings"
                                    class="form-control"
                                    (ngModelChange)="onOwnerUserChange(fieldErrors.owner_user_id)"
                                    [(ngModel)]="model.owner_users"
                                    name="owner_user"
                                    id="owner_user"
                                    #owner_user="ngModel">
              </angular2-multiselect>
              <span class="help-block" *ngIf="fieldErrors.owner_user_id">{{fieldErrors.owner_user_id.text}}</span>
              <div class="form-control-feedback" *ngIf="f.submitted && !owner_user.valid">
                {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
              </div>
            </div>
          </div>

          <div *ngIf="model.type === 'COMPANY'" class="form-group row">
            <div class="col-md-4">
              <label class="mw-100 col-form-label form-control-label">{{'STOCK_CREATE_EDIT_COMPANY'|translate}}</label>
            </div>
            <div class="col-md-8">
              <div class="mw-100">
                <angular2-multiselect
                  [(ngModel)]="model.company"
                  [ngModelOptions]="{standalone: true}"
                  [data]="selectableCompanies"
                  [settings]="dropdownSettings">
                </angular2-multiselect>
              </div>
            </div>
          </div>

          <div *ngIf="model.type === 'CUSTOMER'" class="form-group row">
            <div class="col-md-4">
              <label
                class="mw-100 col-form-label form-control-label required-field-label">{{'STOCK_CREATE_EDIT_CUSTOMER_RECORD'|translate}}</label>
            </div>
            <div class="col-md-8">
              <div class="mw-100">
                <angular2-multiselect
                  [(ngModel)]="model.customerRecord"
                  [data]="customerRecords"
                  (onRemoteSearch)="searchCustomerRecord($event.target.value)"
                  (onSelect)="onCustomerRecordChanged()"
                  (onDeSelect)="onCustomerRecordChanged()"
                  name="customerRecord"
                  id="customerRecord"
                  #customerRecord="ngModel"
                  [settings]="customerDropdownSettings"
                  [required]="model.type === 'CUSTOMER'">
                </angular2-multiselect>
              </div>
              <div class="form-control-feedback" *ngIf="f.submitted && !customerRecord.valid">
                {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
              </div>
            </div>
          </div>

          <div *ngIf="model.type === 'CUSTOMER' && model.customerRecord.length === 1" class="form-group row">
            <div class="col-md-4">
              <label
                class="mw-100 col-form-label form-control-label">{{'STOCK_CREATE_EDIT_CONTACT_LOCATION'|translate}}</label>
            </div>
            <div class="col-md-8">
              <div class="mw-100">
                <angular2-multiselect
                  [(ngModel)]="model.contactLocation"
                  [ngModelOptions]="{standalone: true}"
                  [data]="contactLocations"
                  (onRemoteSearch)="searchContactLocation($event.target.value)"
                  [settings]="customerDropdownSettings">
                </angular2-multiselect>
              </div>
            </div>
          </div>

          <div *ngIf="model.type === 'COMPANY'" class="form-group row">
            <div class="col-md-4">
              <label class="mw-100 col-form-label form-control-label">{{'STOCK_CREATE_EDIT_CENTRAL'|translate}}</label>
            </div>
            <div class="col-md-8">
              <div class="row pl-1">
                <app-animated-checkbox [(selected)]="model.central"
                                       class="mr-1 col-form-label"></app-animated-checkbox>
              </div>
            </div>
          </div>

          <div *ngIf="model.type === 'COMPANY'" class="form-group row">
            <div class="col-md-4">
              <label class="mw-100 col-form-label form-control-label">{{'STOCK_CREATE_EDIT_WASTE'|translate}}</label>
            </div>
            <div class="col-md-8">
              <div class="row pl-1">
                <app-animated-checkbox [(selected)]="model.waste"
                                       class="mr-1 col-form-label"></app-animated-checkbox>
              </div>
            </div>
          </div>

          <div>
            <div class="pull-right">
              <input type="submit" class="btn btn-primary" value="{{'COMMON_BUTTON_SAVE'|translate}}"/>
            </div>
          </div>

        </form>
      </div>
    </div>
  </div>
</div>
