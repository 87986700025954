/* eslint-disable */
import { createNumberMask } from 'text-mask-addons/dist/textMaskAddons';
/* eslint-enable */

export class InputMask {

  public static readonly DECIMAL_SYMBOL = '.';
  public static readonly THOUSAND_SEPARATOR_SYMBOL = ' ';

  public static readonly YEAR = [/\d/, /\d/, /\d/, /\d/];

  public static readonly NATURAL_FLOAT = createNumberMask({
    prefix: '',
    suffix: '',
    decimalSymbol: InputMask.DECIMAL_SYMBOL,
    allowDecimal: true,
    includeThousandsSeparator: false,
    decimalLimit: 2,
  });

  public static readonly RELATIVE_FLOAT = createNumberMask({
    prefix: '',
    suffix: '',
    decimalSymbol: InputMask.DECIMAL_SYMBOL,
    allowDecimal: true,
    allowNegative: true,
    includeThousandsSeparator: false,
    decimalLimit: 2,
  });

  public static readonly NATURAL_FLOAT_WITH_THOUSAND_SEPARATOR = createNumberMask({
    prefix: '',
    suffix: '',
    decimalSymbol: InputMask.DECIMAL_SYMBOL,
    thousandsSeparatorSymbol: InputMask.THOUSAND_SEPARATOR_SYMBOL,
    allowDecimal: true,
    includeThousandsSeparator: true,
    decimalLimit: 2,
  });

  public static readonly RELATIVE_FLOAT_WITH_THOUSAND_SEPARATOR = createNumberMask({
    prefix: '',
    suffix: '',
    decimalSymbol: InputMask.DECIMAL_SYMBOL,
    thousandsSeparatorSymbol: InputMask.THOUSAND_SEPARATOR_SYMBOL,
    allowDecimal: true,
    allowNegative: true,
    includeThousandsSeparator: true,
    decimalLimit: 2,
  });

  public static readonly NATURAL_INTEGER = createNumberMask({
    prefix: '',
    suffix: '',
    allowDecimal: false,
    includeThousandsSeparator: false,
  });

  public static readonly RELATIVE_INTEGER = createNumberMask({
    prefix: '',
    suffix: '',
    allowDecimal: false,
    allowNegative: true,
    includeThousandsSeparator: false,
  });

  public static readonly NATURAL_INTEGER_WITH_THOUSAND_SEPARATOR = createNumberMask({
    prefix: '',
    suffix: '',
    allowDecimal: false,
    thousandsSeparatorSymbol: InputMask.THOUSAND_SEPARATOR_SYMBOL,
    includeThousandsSeparator: true,
  });

  public static readonly RELATIVE_INTEGER_WITH_THOUSAND_SEPARATOR = createNumberMask({
    prefix: '',
    suffix: '',
    allowDecimal: false,
    allowNegative: true,
    thousandsSeparatorSymbol: InputMask.THOUSAND_SEPARATOR_SYMBOL,
    includeThousandsSeparator: true,
  });

  public static readonly ZIP_CODE = [/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];

  public static readonly HU_TAX_NUMBER = [/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/,
    '-', /[1-5]/, '-', /[0-9]/, /[0-9]/];

  public static readonly NFC_HEX_ID =
    [/[a-fA-F0-9]/, /[a-fA-F0-9]/, /[a-fA-F0-9]/, /[a-fA-F0-9]/, /[a-fA-F0-9]/, /[a-fA-F0-9]/, /[a-fA-F0-9]/,
      /[a-fA-F0-9]/, /[a-fA-F0-9]/, /[a-fA-F0-9]/, /[a-fA-F0-9]/, /[a-fA-F0-9]/, /[a-fA-F0-9]/, /[a-fA-F0-9]/];

  public static readonly HU_BANK_ACCOUNT_NUMBER =
    [/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, '-',
     /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, '-',
     /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];

  public static readonly IBAN_BANK_ACCOUNT_NUMBER =
    [/[A-Z]/, /[A-Z]/, /[0-9]/, /[0-9]/, /[0-9A-Za-z]/, /[0-9A-Za-z]/, /[0-9A-Za-z]/, /[0-9A-Za-z]/, /[0-9A-Za-z]/,
     /[0-9A-Za-z]/, /[0-9A-Za-z]/, /[0-9A-Za-z]/, /[0-9A-Za-z]/, /[0-9A-Za-z]/, /[0-9A-Za-z]/, /[0-9A-Za-z]/,
     /[0-9A-Za-z]/, /[0-9A-Za-z]/, /[0-9A-Za-z]/, /[0-9A-Za-z]/, /[0-9A-Za-z]/, /[0-9A-Za-z]/, /[0-9A-Za-z]/,
     /[0-9A-Za-z]/, /[0-9A-Za-z]/, /[0-9A-Za-z]/, /[0-9A-Za-z]/, /[0-9A-Za-z]/, /[0-9A-Za-z]/, /[0-9A-Za-z]/,
     /[0-9A-Za-z]/, /[0-9A-Za-z]/, /[0-9A-Za-z]/, /[0-9A-Za-z]/];

  public static bankAccountNumberMask(rawValue: string) {
    if (rawValue.length > 0) {
      if (new RegExp(/[A-Z]/).test(rawValue[0])) {
        return InputMask.IBAN_BANK_ACCOUNT_NUMBER;
      }
      else {
        return InputMask.HU_BANK_ACCOUNT_NUMBER;
      }
    }
    else {
      return [/[A-Z]/];
    }
  }

  public static currencyMask(currencyCode: string) {
    return createNumberMask({
      prefix: '',
      allowDecimal: true,
      includeThousandsSeparator: false,
      decimalSymbol: InputMask.DECIMAL_SYMBOL,
      suffix: currencyCode,
      decimalLimit: 4,
    });
  }
}

function stringMask(length: number): string {
  return mask(length, 'A');
}

function numberMask(length: number): string {
  return mask(length, '0');
}

function mask(length: number, mask: string): string {
  let resultString = '';
  for (let i = 1; i <= length; i++) {
    resultString += mask;
  }
  return resultString;
}
