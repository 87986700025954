import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UiConstants } from '../../../util/core-utils';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { DownloadedFile } from '../../../lib/util/downloaded-files';
import { LegacyProcess, LegacyProcessService } from '../../../lib/legacy-process/legacy-process.service';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-order-document-zip-download-dialog',
  templateUrl: './order-document-zip-download-dialog.component.html',
  styleUrls: ['./order-document-zip-download-dialog.component.scss']
})
export class OrderDocumentZipDownloadDialogComponent implements OnInit {

  get mergePdf(): boolean {
    return this._mergePdf;
  }

  set mergePdf(value: boolean) {
    this._mergePdf = value;
    if (!value) {
      this.groupByIdenticalItems = false;
    }
  }

  UiConstants = UiConstants;

  @Input()
  processId: number;

  @ViewChild('dialog', { static: true })
  dialog: ModalDirective;
  dialogVisible: boolean = false;

  private _mergePdf: boolean = true;
  groupByIdenticalItems: boolean = true;

  downloading: boolean = false;

  constructor(private processService: LegacyProcessService) {
  }

  ngOnInit() {
  }

  showDialog() {
    this.dialogVisible = true;
    this.dialog.show();
  }

  closeDialog() {
    this.dialogVisible = false;
    this.dialog.hide();
    this._mergePdf = true;
    this.groupByIdenticalItems = true;
  }

  download() {
    this.downloading = true;
    this.processService.downloadOrderDocumentZip({
      processId: this.processId,
      pdfMergeMethod: this.resolvePdfMergeMethod()
    }).subscribe(
      (res: DownloadedFile) => {
        saveAs(res.getBlob(), res.getFileName('process-' + this.processId));
        this.closeDialog();
        this.downloading = false;
      }, error => {
        this.downloading = false;
      }
    );
  }

  private resolvePdfMergeMethod(): LegacyProcess.PdfMergeMethod {
    if (this._mergePdf) {
      if (this.groupByIdenticalItems) {
        return 'GROUPED_MERGE';
      }
      return 'MERGE';
    }
    return 'OFF';
  }

}
