<div class="breadcrumb-container" [ngClass]="{ 'breadcrumb-container-compact': compactSidebar }" id="breadcrumb">
  <div class="breadcrumb-component">
    <app-breadcrumb
      [parents]="breadcrumbParents"
      [self]="breadcrumbSelf">
    </app-breadcrumb>
  </div>
  <div class="breadcrumb-menu">
    <!--CREATE-->
    <div *ngIf="rightModel.messageParentCreate.hasRight()"
         class="breadcrumb-button-icon-container cursor-pointer negative-margin-right"
         uiSref="Admin.NotificationCreate">
      <a class="btn-setting d-flex align-items-center" title="{{'COMMON_BUTTON_ADD' | translate}}">
        <i class="icomoon icomoon-add"></i>
        <span class="breadcrumb-button-text-visible">{{'COMMON_BUTTON_ADD' | translate}}</span>
      </a>
    </div>
  </div>
</div>
<div class="breadcrumb-placeholder"></div>

<div class="container-horizontal-padding container-vertical-padding animated fadeIn">

  <div class="card">

    <div class="card-header">
      {{'NOTIFICATION_PANEL_HEADING_LIST' | translate}}

      <div class="card-actions">
        <a class="btn-setting cursor-pointer" (click)="toggleSearch()" title="{{'COMMON_SHOW_SEARCH_HINT' | translate}}"><i #searchIcon
                                                                                    class="icomoon icomoon-search"></i></a>
      </div><!-- /.card-actions -->
    </div>

    <div class="card-body border-bottom" *ngIf="showSearch">
      <div class="row form-group mb-0">
        <!--TITLE-->
        <div class="col-md-4">
          <label class="search-label">{{'NOTIFICATIONS_SEARCH_TITLE' | translate}}</label>
          <input type="text" class="form-control" placeholder="{{'NOTIFICATIONS_SEARCH_TITLE' | translate}}"
                 maxlength="{{UiConstants.maximumVarcharLength}}"
                 (keyup.enter)="onSearchClicked()"
                 [(ngModel)]="searchModel.title" name="title" id="title" #title="ngModel">
        </div>
        <!--CONTENT-->
        <div class="col-md-4">
          <label class="search-label">{{'NOTIFICATIONS_SEARCH_CONTENT' | translate}}</label>
          <input type="text" class="form-control" placeholder="{{'NOTIFICATIONS_SEARCH_CONTENT' | translate}}"
                 maxlength="{{UiConstants.maximumVarcharLength}}"
                 (keyup.enter)="onSearchClicked()"
                 [(ngModel)]="searchModel.content" name="content" id="content" #content="ngModel">
        </div>
        <!--TYPE-->
        <div class="col-md-4 d-flex align-items-end">
          <div class="row">
            <app-animated-checkbox class="include-system-checkbox"
                                   [selected]="includeSystemMessagesSelected"
                                   (clickEvent)="systemMessagesChecked()">
            </app-animated-checkbox>
            <label class="search-label">{{'NOTIFICATIONS_SEARCH_TYPE' | translate}}</label>
          </div>
          <!-- /.row -->
        </div>
      </div>
      <div class="row form-group">
        <!--SEND DATE FROM-->
        <div class="col-md-4">
          <label class="search-label">{{'NOTIFICATIONS_SEARCH_SENDDATEFROM' | translate}}</label>
          <div class="input-group">
            <input type="text" class="form-control date" placeholder="{{'NOTIFICATIONS_SEARCH_SENDDATEFROM' | translate}}"
                   [(ngModel)]="searchModel.sendDateFrom" ngbDatepicker name="sendDateFrom" id="sendDateFrom"
                   #sendDateFrom="ngbDatepicker">
            <div class="input-group-addon-gray" (click)="sendDateFrom.toggle()">
              <i class="icomoon icomoon-calendar"></i>
            </div>
          </div>
        </div>
        <!--SEND DATE TO-->
        <div class="col-md-4">
          <label class="search-label">{{'NOTIFICATIONS_SEARCH_SENDDATETO' | translate}}</label>
          <div class="input-group">
            <input type="text" class="form-control date"
                   placeholder="{{'NOTIFICATIONS_SEARCH_SENDDATETO' | translate}}"
                   [(ngModel)]="searchModel.sendDateTo" ngbDatepicker name="sendDateTo" #sendDateTo="ngbDatepicker"
                   id="sendDateTo">
            <div class="input-group-addon-gray" (click)="sendDateTo.toggle()">
              <i class="icomoon icomoon-calendar"></i>
            </div>
          </div>
        </div>
        <!--SENDER-->
        <div class="col-md-4">
          <label class="search-label">{{'NOTIFICATIONS_SEARCH_SENDER' | translate}}</label>
          <input type="text" class="form-control" placeholder="{{'NOTIFICATIONS_SEARCH_SENDER' | translate}}"
                 maxlength="{{UiConstants.maximumVarcharLength}}"
                 (keyup.enter)="onSearchClicked()"
                 [(ngModel)]="searchModel.sender" name="sender" id="sender" #sender="ngModel">
        </div>
      </div>
      <div class="row p-0">
        <div class="col-md-12 d-flex justify-content-md-end align-items-end pr-1 pl-0">
          <div class="btn-group" role="group">
            <button type="button" (click)="onSearchReset()" class="btn btn-outline-primary search-button mr-1">
              {{'COMMON_BUTTON_SEARCH_DELETE' | translate}}
            </button>
            <button type="button" (click)="onSearchClicked()" class="btn btn-primary search-button">
              {{'COMMON_BUTTON_SEARCH' | translate}}
            </button>
          </div>
        </div>
      </div>
    </div> <!-- ./card-body end -->

    <div class="card-body">

      <table class="table table-striped table-bordered">
        <thead>
        <tr>
          <th class="table-sorter-header w-10 hidden-sm-down"><app-table-field-sorter [orderField]="MessageList.OrderField.ID"
                                                                                      [orderType]="queryModel.getOrderType(MessageList.OrderField.ID)"
                                                                                      (orderChange)="orderBy($event)"
                                                                                      [text]="'NOTIFICATION_TABLE_HEADER_ID' | translate"></app-table-field-sorter></th>
          <th class="table-sorter-header long hidden-sm-down"><app-table-field-sorter [orderField]="MessageList.OrderField.TITLE"
                                                                                      [orderType]="queryModel.getOrderType(MessageList.OrderField.TITLE)"
                                                                                      (orderChange)="orderBy($event)"
                                                                                      [text]="'NOTIFICATION_TABLE_HEADER_TITLE' | translate"></app-table-field-sorter></th>
          <th class="table-sorter-header w-15 "><app-table-sorter-no-op [text]="'NOTIFICATION_TABLE_HEADER_RECIPIENTS' | translate"></app-table-sorter-no-op></th>
          <th class="table-sorter-header w-25 "><app-table-field-sorter [orderField]="MessageList.OrderField.CREATION_TIME"
                                                                                      [orderType]="queryModel.getOrderType(MessageList.OrderField.CREATION_TIME)"
                                                                                      (orderChange)="orderBy($event)"
                                                                                      [text]="'COMMON_TABLE_HEADER_CREATION_TIME' | translate"></app-table-field-sorter></th>
          <th class="table-sorter-header w-10"><app-table-sorter-no-op [text]="'COMMON_TABLE_HEADER_ACTIONS' | translate"></app-table-sorter-no-op></th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let message of messageList | paginate: {
                                                      itemsPerPage: queryModel.itemsPerPage,
                                                      currentPage: queryModel.currentPage,
                                                      totalItems: queryModel.currentNumberOfItems }">

          <td class="responsive-table-column hidden-sm-down">{{ message.id }}</td>
          <td class="responsive-table-column hidden-sm-down">{{ message.title }}</td>
          <td class="responsive-table-column ">{{ message.relevantRecipientName }}</td>
          <td class="responsive-table-column ">{{message.creation_time | date:'short'}}</td>
          <td class="responsive-table-column">
            <span class="text-nowrap">
                <a class="icons-table-group" uiSref="Admin.NotificationRead" [uiParams]="{ id: message.id }">
                  <!-- @TODO: MESSAGE LIST -->
                  <i class="icomoon icons-table-item icomoon-detail icomoon-detail-table" title="{{'COMMON_BUTTON_DETAILS' | translate}}"></i>
                </a>
            </span>
          </td>
        </tr>
        </tbody>
        <tfoot>
        <tr>
          <th class="table-sorter-header w-10 hidden-sm-down"><app-table-field-sorter [orderField]="MessageList.OrderField.ID"
                                                                                      [orderType]="queryModel.getOrderType(MessageList.OrderField.ID)"
                                                                                      (orderChange)="orderBy($event)"
                                                                                      [text]="'NOTIFICATION_TABLE_HEADER_ID' | translate"></app-table-field-sorter></th>
          <th class="table-sorter-header long hidden-sm-down"><app-table-field-sorter [orderField]="MessageList.OrderField.TITLE"
                                                                                      [orderType]="queryModel.getOrderType(MessageList.OrderField.TITLE)"
                                                                                      (orderChange)="orderBy($event)"
                                                                                      [text]="'NOTIFICATION_TABLE_HEADER_TITLE' | translate"></app-table-field-sorter></th>
          <th class="table-sorter-header w-15"><app-table-sorter-no-op [text]="'NOTIFICATION_TABLE_HEADER_RECIPIENTS' | translate"></app-table-sorter-no-op></th>
          <th class="table-sorter-header w-25"><app-table-field-sorter [orderField]="MessageList.OrderField.CREATION_TIME"
                                                                                      [orderType]="queryModel.getOrderType(MessageList.OrderField.CREATION_TIME)"
                                                                                      (orderChange)="orderBy($event)"
                                                                                      [text]="'COMMON_TABLE_HEADER_CREATION_TIME' | translate"></app-table-field-sorter></th>
          <th class="table-sorter-header w-10"><app-table-sorter-no-op [text]="'COMMON_TABLE_HEADER_ACTIONS' | translate"></app-table-sorter-no-op></th>
        </tr>
        </tfoot>
      </table>
      <app-table-paging
        [currentNumberOfItems]="queryModel.currentNumberOfItems"
        [totalNumberOfItems]="queryModel.totalNumberOfItems"
        [itemsPerPage]="queryModel.itemsPerPage"
        (pageChange)="pageChanged($event)"
        (itemsPerPageChange)="itemsPerPageChanged($event)">
      </app-table-paging>
    </div>
  </div>
</div>
