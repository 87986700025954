<div class="breadcrumb-container" [ngClass]="{ 'breadcrumb-container-compact': compactSidebar }" id="breadcrumb">
  <div class="breadcrumb-component">
    <app-breadcrumb
      [parents]="breadcrumbParents"
      [self]="breadcrumbSelf">
    </app-breadcrumb>
  </div>
</div>
<div class="breadcrumb-placeholder"></div>

<div class="container-horizontal-padding container-vertical-padding animated fadeIn">

  <div class="card">

    <div class="card-header">
      {{'SEARCH_PANEL_HEADING_TITLE' | translate}}

      <div class="card-actions">
        <a class="btn-setting cursor-pointer" (click)="toggleSearch()"
           title="{{'COMMON_SHOW_SEARCH_HINT' | translate}}"><i #searchIcon
                                                                class="icomoon icomoon-search"></i></a>
      </div><!-- /.card-actions -->
    </div>

    <div class="card-body border-bottom" *ngIf="showSearch">

      <div class="row form-group">
        <div class="col-md-4">
          <label class="search-label">{{'AUDIT_LOG_SEARCH_USER_NAME' | translate}}</label>
          <input type="text" class="form-control" placeholder="{{'AUDIT_LOG_SEARCH_USER_NAME' | translate}}"
                 maxlength="{{UiConstants.maximumVarcharLength}}"
                 (keyup.enter)="onSearchClicked()"
                 [(ngModel)]="searchModel.userName" name="user_name" id="user_name" #user_name="ngModel">
        </div>
        <div class="col-md-4">
          <label class="search-label">{{'TASK_LOG_SEARCH_DEVICE' | translate}}</label>
          <select class="form-control" [(ngModel)]="searchModel.applicationTypeId"
                  id="application_type_id" name="application_type_id" #application_type_id="ngModel">
            <option value="2">{{'TASK_LOG_TABLE_DEVICE_MOBILE' | translate}}</option>
            <option value="1">{{'TASK_LOG_TABLE_DEVICE_WEB' | translate}}</option>
          </select>
        </div>
        <div class="col-md-4">
          <label class="search-label">{{'TASK_LOG_SEARCH_TASK_TYPE_NAME' | translate}}</label>
          <input type="text" class="form-control" placeholder="{{'TASK_LOG_SEARCH_TASK_TYPE_NAME' | translate}}"
                 maxlength="{{UiConstants.maximumVarcharLength}}"
                 (keyup.enter)="onSearchClicked()"
                 [(ngModel)]="searchModel.taskTypeName" name="task_type_name" id="task_type_name"
                 #task_type_name="ngModel">
        </div>
      </div>
      <div class="row form-group">
        <div class="col-md-4">
          <label class="search-label">{{'TASK_LOG_SEARCH_TASK_NAME' | translate}}</label>
          <input type="text" class="form-control" placeholder="{{'TASK_LOG_SEARCH_TASK_NAME' | translate}}"
                 maxlength="{{UiConstants.maximumVarcharLength}}"
                 (keyup.enter)="onSearchClicked()"
                 [(ngModel)]="searchModel.taskName" name="task_name" id="task_name" #task_name="ngModel">
        </div>
        <div class="col-md-4">
          <label class="search-label">{{'TASK_LOG_SEARCH_LOG_TYPE' | translate}}</label>
          <input type="text" class="form-control" placeholder="{{'TASK_LOG_SEARCH_LOG_TYPE' | translate}}"
                 maxlength="{{UiConstants.maximumVarcharLength}}"
                 (keyup.enter)="onSearchClicked()"
                 [(ngModel)]="searchModel.logType" name="log_type" id="log_type" #log_type="ngModel">
        </div>
        <div class="col-md-4">
          <label class="search-label">{{'TASK_LOG_SEARCH_MESSAGE' | translate}}</label>
          <input type="text" class="form-control" placeholder="{{'TASK_LOG_SEARCH_MESSAGE' | translate}}"
                 maxlength="{{UiConstants.maximumVarcharLength}}"
                 (keyup.enter)="onSearchClicked()"
                 [(ngModel)]="searchModel.message" name="message" id="message"
                 #message="ngModel">
        </div>
      </div>
      <div class="row form-group">
        <div class="col-md-4">
          <label class="search-label">{{'COMMON_SEARCH_CREATION_TIME_FROM' | translate}}</label>
          <div class="input-group">
            <input type="text" class="form-control date"
                   placeholder="{{'COMMON_SEARCH_CREATION_TIME_FROM' | translate}}"
                   [(ngModel)]="searchModel.logTimeFrom" ngbDatepicker name="creation_time_from" id="creation_time_from"
                   #creation_time_from="ngbDatepicker">
            <div class="input-group-addon-gray" (click)="creation_time_from.toggle()">
              <i class="icomoon icomoon-calendar"></i>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <label class="search-label">{{'COMMON_SEARCH_CREATION_TIME_TO' | translate}}</label>
          <div class="input-group">
            <input type="text" class="form-control date"
                   placeholder="{{'COMMON_SEARCH_CREATION_TIME_TO' | translate}}"
                   [(ngModel)]="searchModel.logTimeTo" ngbDatepicker name="creation_time_to"
                   #creation_time_to="ngbDatepicker"
                   id="creation_time_to">
            <div class="input-group-addon-gray" (click)="creation_time_to.toggle()">
              <i class="icomoon icomoon-calendar"></i>
            </div>
          </div>
        </div>
        <div class="col-md-4 d-flex justify-content-md-end align-items-end pr-1 pl-0">
          <div class="btn-group" role="group">
            <button type="button" (click)="onSearchReset()" class="btn btn-outline-primary search-button mr-1">
              {{'COMMON_BUTTON_SEARCH_DELETE' | translate}}
            </button>
            <button type="button" (click)="onSearchClicked()" class="btn btn-primary search-button">
              {{'COMMON_BUTTON_SEARCH' | translate}}
            </button>
          </div>
        </div>
      </div>
    </div><!-- /.card-body-->

    <div class="card-body">

      <table class="table table-striped table-bordered">
        <thead>
        <tr>
          <th class="table-sorter-header">
            <app-table-field-sorter [orderField]="TaskLog.OrderField.USER_NAME"
                                    [orderType]="queryModel.getOrderType(TaskLog.OrderField.USER_NAME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'TASK_LOG_TABLE_HEADER_USER_NAME' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-sm-down">
            <app-table-field-sorter [orderField]="TaskLog.OrderField.DEVICE"
                                    [orderType]="queryModel.getOrderType(TaskLog.OrderField.DEVICE)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'TASK_LOG_TABLE_HEADER_DEVICE' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-xs-down">
            <app-table-field-sorter [orderField]="TaskLog.OrderField.TASK_ID"
                                    [orderType]="queryModel.getOrderType(TaskLog.OrderField.TASK_ID)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'TASK_LOG_TABLE_HEADER_TASK_RECORD_ID' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-sm-down">
            <app-table-sorter-no-op></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header hidden-xs-down">
            <app-table-field-sorter [orderField]="TaskLog.OrderField.TASK_NAME"
                                    [orderType]="queryModel.getOrderType(TaskLog.OrderField.TASK_NAME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'TASK_LOG_TABLE_HEADER_TASK_RECORD_NAME' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header">
            <app-table-field-sorter [orderField]="TaskLog.OrderField.LOG_TYPE"
                                    [orderType]="queryModel.getOrderType(TaskLog.OrderField.LOG_TYPE)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'TASK_LOG_TABLE_HEADER_LOG_TYPE' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-sm-down">
            <app-table-field-sorter [orderField]="TaskLog.OrderField.MESSAGE"
                                    [orderType]="queryModel.getOrderType(TaskLog.OrderField.MESSAGE)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'TASK_LOG_TABLE_HEADER_MESSAGE' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-lg-down">
            <app-table-field-sorter [orderField]="TaskLog.OrderField.LOG_TIME"
                                    [orderType]="queryModel.getOrderType(TaskLog.OrderField.LOG_TIME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_TABLE_HEADER_CREATION_TIME' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header">
            <app-table-sorter-no-op></app-table-sorter-no-op>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let taskLog of taskLogList | paginate: {
                                                      itemsPerPage: queryModel.itemsPerPage,
                                                      currentPage: queryModel.currentPage,
                                                      totalItems: queryModel.currentNumberOfItems }">
          <td class="responsive-table-column">{{getUserName(taskLog.userProfileId)}}</td>
          <td
            class="responsive-table-column hidden-sm-down">{{getDeviceType(taskLog.applicationTypeId) | translate}}</td>
          <td class="responsive-table-column hidden-xs-down">{{taskLog.taskRecordId}}</td>
          <td class="responsive-table-column long hidden-sm-down">
            <app-type-icon [icon]="getTaskIcon(taskLog.taskId)" class="mr-05"></app-type-icon>
          </td>
          <td class="responsive-table-column hidden-xs-down">{{taskLog.taskRecordName}}</td>
          <td class="responsive-table-column">{{TaskLog.LogTypeMap.get(taskLog.logType) | translate}}</td>
          <td class="responsive-table-column hidden-sm-down">{{taskLog.message}}</td>
          <td class="responsive-table-column hidden-lg-down">{{taskLog.logTime.toUtcIsoString() | date:'short' }}</td>
          <td nowrap>
            <a class="icons-table-group pull-right">
              <a class="icons-table-group" uiSref="Admin.TaskRecordDetail"
                 [uiParams]="{ taskId: taskLog.taskId, taskRecordId: taskLog.taskRecordId }">
                <i class="icomoon icons-table-item icomoon-sidebar-tasks icomoon-detail-table"
                   title="{{'COMMON_BUTTON_DETAILS' | translate}}"></i>
              </a>
            </a>
          </td>
        </tr>
        </tbody>
        <tfoot>
        <tr>
          <th class="table-sorter-header">
            <app-table-field-sorter [orderField]="TaskLog.OrderField.USER_NAME"
                                    [orderType]="queryModel.getOrderType(TaskLog.OrderField.USER_NAME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'TASK_LOG_TABLE_HEADER_USER_NAME' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-sm-down">
            <app-table-field-sorter [orderField]="TaskLog.OrderField.DEVICE"
                                    [orderType]="queryModel.getOrderType(TaskLog.OrderField.DEVICE)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'TASK_LOG_TABLE_HEADER_DEVICE' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-xs-down">
            <app-table-field-sorter [orderField]="TaskLog.OrderField.TASK_ID"
                                    [orderType]="queryModel.getOrderType(TaskLog.OrderField.TASK_ID)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'TASK_LOG_TABLE_HEADER_TASK_RECORD_ID' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-sm-down">
            <app-table-sorter-no-op></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header hidden-xs-down">
            <app-table-field-sorter [orderField]="TaskLog.OrderField.TASK_NAME"
                                    [orderType]="queryModel.getOrderType(TaskLog.OrderField.TASK_NAME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'TASK_LOG_TABLE_HEADER_TASK_RECORD_NAME' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header">
            <app-table-field-sorter [orderField]="TaskLog.OrderField.LOG_TYPE"
                                    [orderType]="queryModel.getOrderType(TaskLog.OrderField.LOG_TYPE)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'TASK_LOG_TABLE_HEADER_LOG_TYPE' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-sm-down">
            <app-table-field-sorter [orderField]="TaskLog.OrderField.MESSAGE"
                                    [orderType]="queryModel.getOrderType(TaskLog.OrderField.MESSAGE)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'TASK_LOG_TABLE_HEADER_MESSAGE' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-lg-down">
            <app-table-field-sorter [orderField]="TaskLog.OrderField.LOG_TIME"
                                    [orderType]="queryModel.getOrderType(TaskLog.OrderField.LOG_TIME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_TABLE_HEADER_CREATION_TIME' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header">
            <app-table-sorter-no-op></app-table-sorter-no-op>
          </th>
        </tr>
        </tfoot>
      </table>

      <app-table-paging
        [currentNumberOfItems]="queryModel.currentNumberOfItems"
        [totalNumberOfItems]="queryModel.totalNumberOfItems"
        [itemsPerPage]="queryModel.itemsPerPage"
        (pageChange)="pageChanged($event)"
        (itemsPerPageChange)="itemsPerPageChanged($event)">
      </app-table-paging>

    </div><!-- /.card-body -->
  </div><!-- /.card-->
</div>
