/* eslint-disable */
import {List as ImmutableList, Map as ImmutableMap, Set as ImmutableSet} from 'immutable';
import {Decimal} from 'decimal.js';
import {FormControl, FormGroup, NgForm, NgModel,} from '@angular/forms';
import {Arrays} from './arrays';
import {Dates, LocalDate, OffsetDateTime,} from './dates';
import {EmailAddress, EmailAddresses,} from './email-address';
import {PhoneNumber, PhoneNumbers,} from './phone-number';
import {Strings} from './strings';
/* eslint-enable */

type Multiset<K, V> = ImmutableMap<K, ImmutableSet<V>>;

export class EMPTY_QUERY_RESULT<T> implements QueryResult<T> {
  readonly items: ImmutableList<T> = ImmutableList.of();
  readonly pagingResult: {
    numberOfPages: 0;
    currentNumberOfItems: 0;
    totalNumberOfItems: 0;
  }
}

export class EMPTY_RESOURCE_QUERY_RESULT<T> implements ResourceQueryResult<T> {
  readonly items: T[] = [];
  readonly pagingResult: {
    numberOfPages: 0;
    currentNumberOfItems: 0;
    totalNumberOfItems: 0;
  }
  readonly otherHeaders: Map<string, string> = new Map<string, string>();
}

export interface QueryResult<T> {
  items: ImmutableList<T>;
  pagingResult: PagingResult;
}

export interface ResourceQueryResult<T> {
  items: Array<T>;
  pagingResult: PagingResult;
  otherHeaders: Map<string, string>;
}

export interface PagingResult {
  numberOfPages: number;
  currentNumberOfItems: number;
  totalNumberOfItems: number;
}

export interface PagingRequest {
  pageNumber: number;
  numberOfItems: number;
}

export enum OrderType {
  ASC, DESC
}

export interface Order<T> {
  type: OrderType;
  field: T;
}

export interface FieldError {
  text: string;
}

export class FieldValidationError<T> {

  public static empty<T>(): FieldValidationError<T> {
    return new FieldValidationError(ImmutableMap.of());
  }

  public static of<T>(fieldErrorMap: ImmutableMap<T, FieldError>): FieldValidationError<T> {
    return new FieldValidationError(fieldErrorMap);
  }

  public hasError(field?: T): boolean {
    if (field === undefined) {
      return !this.fieldErrorMap.isEmpty();
    }
    return this.fieldErrorMap.get(field) !== undefined;
  }

  public getErrorText(field: T): string {
    const e = this.fieldErrorMap.get(field);
    if (e) {
      return e.text;
    }
    return '';
  }

  public removeError(field: T): FieldValidationError<T> {
    if (this.hasError(field)) {
      return FieldValidationError.of(this.fieldErrorMap.remove(field));
    } else {
      return this;
    }
  }

  public withForm(form: NgForm) {
    return new FieldValidationErrorForm(this, new FormRefForm(form));
  }

  public withFormRef(formRef: FormRef) {
    return new FieldValidationErrorForm(this, formRef);
  }

  constructor(public fieldErrorMap: ImmutableMap<T, FieldError>) {
  }

}

class FieldValidationErrorForm<T> extends FieldValidationError<T> {

  constructor(delegate: FieldValidationError<T>, private formRef: FormRef) {
    super(delegate.fieldErrorMap)
  }

  hasError(field?: T): boolean {
    if (!this.formRef.getForm().submitted) {
      return false;
    }
    return super.hasError(field);
  }

}

export interface FormRef {
  getForm(): FormStrategy;
}

interface ForwardingFormRefArgs {
  formFn: () => FormRef | undefined;
  defaultSubmitted?: boolean;
}

export class ForwardingFormRef implements FormRef {

  constructor(private args: ForwardingFormRefArgs) {
  }

  getForm(): FormStrategy {
    const form = this.args.formFn();
    if (form) {
      return form.getForm();
    }
    const submitted = this.args.defaultSubmitted;
    return {
      submitted: submitted ? submitted : false
    };
  }

}

interface ForwardingNgFormRefArgs {
  formFn: () => NgForm | undefined;
  defaultSubmitted?: boolean;
}

export class ForwardingNgFormRef implements FormRef {

  constructor(private args: ForwardingNgFormRefArgs) {
  }

  getForm(): FormStrategy {
    const form = this.args.formFn();
    if (form) {
      return form;
    }
    const submitted = this.args.defaultSubmitted;
    return {
      submitted: submitted ? submitted : false
    };
  }

}

class FormRefForm implements FormRef {

  constructor(private form: NgForm) {
  }

  getForm(): FormStrategy {
    return this.form;
  }

}

export class LocalFieldValidationErrorsFactory {

  public static empty(): LocalFieldValidationErrors<any> {
    return new LocalFieldValidationErrorsEmpty();
  }

  public static ofFormFields(form: NgForm, fields: ImmutableList<NgModel>): LocalFieldValidationErrors<NgModel> {
    return new LocalFieldValidationErrorsPresent(fields, new FormRefForm(form));
  }

  public static ofFields(formRef: FormRef, fields: ImmutableList<NgModel>): LocalFieldValidationErrors<NgModel> {
    return new LocalFieldValidationErrorsPresent(fields, formRef);
  }

}

export interface LocalFieldValidationErrors<T> {

  hasLocalError(field?: T): boolean;

}

class LocalFieldValidationErrorsEmpty implements LocalFieldValidationErrors<any> {

  hasLocalError(field?: any): boolean {
    return false;
  }

}

class LocalFieldValidationErrorsPresent<T extends ItemStrategy> implements LocalFieldValidationErrors<T> {

  public constructor(private fields: ImmutableList<ItemStrategy>, private formRef: FormRef) {
  }

  public hasLocalError(field?: T): boolean {
    const form = this.formRef.getForm();
    if (!form.submitted) {
      return false;
    }
    if (field === undefined) {
      let valid: boolean = true;
      Arrays.iterateByIndex(this.fields.toArray(), (f: NgModel) => {
        const v: boolean | null = f.valid;
        const validField: boolean = (v === null ? true : v);
        valid = valid && validField;
      });
      return !valid;
    } else {
      const v: boolean | null = field.valid;
      const validField: boolean = (v === null ? true : v);
      return !validField;
    }
  }

}

export interface FormStrategy {
  readonly submitted: boolean;
}

interface ItemStrategy {
  readonly valid: boolean | null;
}

export interface FormGroupRef {
  getForm(): FormStrategy;
}

export class LocalFormGroupValidationErrors {

  public static ofForm(ref: FormGroupRef, formGroup: FormGroup) {
    return new LocalFormGroupValidationErrors(ref, formGroup);
  }

  private constructor(private formRef: FormRef, private formGroup: FormGroup) {
  }

  public hasFieldError(formControlName?: string, errorCode?: string): boolean {
    const form = this.formRef.getForm();
    if (formControlName === undefined) {
      if (!this.formGroup.touched && !form.submitted) {
        return false;
      }
      this.validateAllFormFields(this.formGroup);
      return this.formGroup.invalid;
    }
    const control = this.formGroup.get(formControlName);
    if (control === null) {
      throw new Error('Form control has not found by name: ' + formControlName);
    }
    if (!control.touched && !form.submitted) {
      return false;
    }
    if (errorCode === undefined) {
      return control.invalid;
    }
    return control.hasError(errorCode);
  }

  public hasFormError(errorCode: string): boolean {
    return this.formGroup.hasError(errorCode);
  }

  private validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.updateValueAndValidity();
        control.markAsTouched({onlySelf: true});
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
    formGroup.updateValueAndValidity();
    formGroup.markAsTouched({onlySelf: true});
  }
}

export class FormGroupValidationUtils {

  /**
   * Marks all controls in a form group as touched
   * @param formGroup - The form group to touch
   */
  public static markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();

      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }

}

/**
 * Utility for services.
 * Do NOT use it in the UI layer. NEVER!
 * Any usage in the UI layer will be removed first day of each month. (It breaks the build.)
 */
export class Services {

  private static readonly DELIMITER = ',';
  private static readonly ASC_SIGN = '+';
  private static readonly DESC_SIGN = '-';

  /**
   * Just a quick solution. Do not use it if possible!
   * Use it when we have no enum or enum parser (YET), so we pass the raw string from the API,
   * but we have a custom type that limits the string values.
   * Instead the correct, type-safe enum parser, we just cast it to the requested type blindly.
   * This approach can cause runtime errors! (For example we write a switch, but we miss a newly added value.)
   * @param text to cast unsafely
   * @deprecated the idea is wrong, but better than nothing
   */
  public static unsafeCast<T>(text: string): T {
    const dummy: any = text;
    return dummy;
  }

  public static optToString(text?: string): string {
    return Strings.optToString(text);
  }

  public static optArrayToSet<T>(array?: T[]): ImmutableSet<T> {
    if (!array) {
      return ImmutableSet.of<T>();
    }
    return ImmutableSet.of(...array);
  }

  public static createIdParameter(idSet?: ImmutableSet<number>): string | undefined {
    if (!idSet || idSet.size === 0) {
      return undefined;
    }
    return idSet.join(Services.DELIMITER);
  }

  public static createListParameter(idSet?: ImmutableSet<any>): string | undefined {
    if (!idSet || idSet.size === 0) {
      return undefined;
    }
    return idSet.join(Services.DELIMITER);
  }

  public static createFieldParameter<T>(keyResolver: (value: T) => string, fieldSet?: ImmutableSet<T>): string | undefined {
    if (!fieldSet || fieldSet.size === 0) {
      return undefined;
    }
    return fieldSet.map((f: T) => {
      return keyResolver(f);
    }).join(Services.DELIMITER);
  }

  public static createOrderFieldParameter<T>(keyResolver: (value: T) => string, fieldSet?: ImmutableSet<Order<T>>): string | undefined {
    if (!fieldSet || fieldSet.size === 0) {
      return undefined;
    }
    return fieldSet.map((o: Order<T>) => {
      return encodeURIComponent(
        (o.type === OrderType.ASC ? Services.ASC_SIGN : Services.DESC_SIGN) + keyResolver(o.field),
      );
    }).join(Services.DELIMITER);
  }

  public static toLocalDate(value?: string): LocalDate {
    return Dates.parseLocalDateIsoString(value);
  }

  public static localDateToString(date?: LocalDate): string | undefined {
    return (date && date.isValid()) ? date.toIsoString() : undefined;
  }

  public static toOffsetDateTime(value?: string): OffsetDateTime {
    return Dates.parseOffsetDateTimeIsoString(value);
  }

  public static offsetDateTimeToString(date?: OffsetDateTime): string | undefined {
    return (date && date.isValid()) ? date.toUtcIsoString() : undefined;
  }

  public static toDecimal(value?: string | number | null): Decimal | undefined {
    return value !== undefined && value !== '' && value != null ? new Decimal(value) : undefined;
  }

  public static reqDecimal(value: string | number): Decimal {
    return new Decimal(value);
  }

  public static decimalToString(value?: Decimal): string | undefined {
    return value ? value.toJSON() : undefined;
  }

  public static toEmailAddress(value?: string): EmailAddress {
    return EmailAddresses.parse(value);
  }

  public static emailAddressToString(value?: EmailAddress): string | undefined {
    const text = !value || !value.isValid() ? undefined : value.toIso();
    if (text === null) {
      return undefined;
    }
    return text;
  }

  public static toPhoneNumber(value?: string): PhoneNumber {
    return PhoneNumbers.parse(value);
  }

  public static phoneNumberToString(value?: PhoneNumber): string | undefined {
    const text = !value || !value.isValid() ? undefined : value.toIso();
    if (text === null) {
      return undefined;
    }
    return text;
  }

  public static resourceToStringMap<V, O>(resource, valueMapper: (value: V) => O): ImmutableMap<string, O> {
    if (!resource) {
      return ImmutableMap.of();
    }
    const m: Map<string, O> = new Map();
    for (const key in resource) {
      if (resource.hasOwnProperty(key)) {
        const id: string = key;
        const value: V = resource[id];
        m.set(id, valueMapper(value));
      }
    }
    return ImmutableMap(m);
  }

  public static resourceToNumberMultiset<V>(resource): Multiset<number, V> {
    if (!resource) {
      return ImmutableMap.of();
    }
    const m: Map<number, ImmutableSet<V>> = new Map();
    for (const key in resource) {
      if (resource.hasOwnProperty(key)) {
        const id: number = Number(key);
        const values: V[] = resource[id];
        const set: Set<V> = new Set();
        for (const value of values) {
          set.add(value);
        }
        m.set(id, ImmutableSet(set));
      }
    }
    return ImmutableMap(m);
  }

  public static numberMultisetToResource<V>(map: Multiset<number, V>) {
    const out = {};
    map.forEach((value: ImmutableSet<V>, key: number) => {
      out[key] = value.toArray();
    });
    return out;
  }

  private constructor() {
  }

}
