import { Component, Input, OnInit } from '@angular/core';
import { TriggerUtils } from '../../../lib/trigger/trigger-utils';
import { OrderType, QueryResult } from '../../../lib/util/services';
import { TriggerInstance } from '../../../lib/trigger/trigger-instance.service';
import { Dates, OffsetDateTime } from '../../../lib/util/dates';
import { Trigger } from '../../../lib/trigger/trigger.service';
import { QueryFieldModel } from '../../../util/core-utils';
import TriggerService = Trigger.TriggerService;
import TriggerInstanceOrderField = Trigger.TriggerInstanceOrderField;
import { Set } from 'immutable';

@Component({
  selector: 'app-trigger-instances',
  templateUrl: './trigger-instances.component.html',
  styleUrls: ['./trigger-instances.component.scss']
})
export class TriggerInstancesComponent implements OnInit {

  TriggerInstanceOrderField = TriggerInstanceOrderField;

  @Input()
  triggerService: TriggerService;

  @Input()
  triggerParentId?: number;

  @Input()
  triggerId: number;

  @Input()
  triggerScope: TriggerUtils.TriggerScope;

  triggerInstanceList: TriggerInstanceModel[] = [];

  queryModel: QueryFieldModel<Trigger.TriggerInstanceOrderField> =
    new QueryFieldModel(Trigger.TriggerInstanceOrderField.CREATION_TIME, OrderType.DESC);

  constructor() {
  }

  ngOnInit() {
    this.loadList();
  }

  loadList(pageNumber?: number) {
    const requestedPage = pageNumber ? pageNumber : this.queryModel.currentPage;
    const paging = {
      pageNumber: requestedPage,
      numberOfItems: this.queryModel.itemsPerPage
    };
    this.triggerService.getTriggerInstances({
      id: this.triggerId,
      triggerParentId: this.triggerParentId,
      paging: paging,
      order: Set.of(this.queryModel.getOrder())
    }).subscribe((result: QueryResult<TriggerInstance.TriggerInstance>) => {
      this.triggerInstanceList = [];
      result.items.forEach((instance) => {
        const item = new TriggerInstanceModel();
        if (instance) {
          item.creationTime = instance.creationTime;
          if (this.triggerScope === 'TASK' && instance.taskRecord) {
            item.taskRecordId = instance.taskRecord.id.toString();
            item.taskRecordName = instance.taskRecord.name;
            this.triggerInstanceList.push(item);
          }
          if (this.triggerScope === 'TRANSPORT' && instance.transport) {
            item.transportId = instance.transport.id.toString();
            item.transportExternalId = instance.transport.externalId;
            this.triggerInstanceList.push(item);
          }
        }
      })
      this.queryModel.currentPage = requestedPage;
      this.queryModel.totalNumberOfItems = result.pagingResult.totalNumberOfItems;
      this.queryModel.currentNumberOfItems = result.pagingResult.currentNumberOfItems;
    })
  }

  orderBy(field: Trigger.TriggerInstanceOrderField) {
    this.queryModel.onOrderFieldChanged(field);
    this.loadList();
  }

  pageChanged(selectedPage: number) {
    this.loadList(selectedPage);
  }

  itemsPerPageChanged(itemsPerPage: number) {
    this.queryModel.itemsPerPage = itemsPerPage;
    this.loadList(1);
  }


}

class TriggerInstanceModel {

  // common fields
  creationTime: OffsetDateTime = Dates.emptyOffsetDateTime();

  // task record specific fields
  taskRecordId: string = '';
  taskRecordName: string = '';

  // transport specific fields
  transportId: string = '';
  transportExternalId: string = '';

}
