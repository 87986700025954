<div class="container-vertical-padding container-horizontal-padding-half animated fadeIn">

  <div class="card">

    <div class="card-header">
      {{'TRANSPORT_DELAY_HEADER' | translate}}
    </div>

    <div class="text-center mt-2 container-vertical-padding" *ngIf="!showTransport">
      <img src="../../../../assets/img/empty-transport.png"
           alt=""
           class="dashboard-image"
           style="width:50%; height:50%">
      <h5 class="mt-2 empty-text">{{'EMPTY_DASHBOARD_NO_TRANSPORT' | translate}}</h5>
    </div>
    <div *ngIf="showTransport">
      <div class="card-body">
        <table class="table table-striped table-bordered">
          <thead>
          <tr>
            <th class="table-sorter-header hidden-sm-down">
              <app-table-field-sorter [orderField]="Transport.OrderField.ID"
                                      [orderType]="queryModel.getOrderType(Transport.OrderField.ID)"
                                      (orderChange)="orderBy($event)"
                                      [text]="'COMMON_TABLE_HEADER_ID' | translate"></app-table-field-sorter>
            </th>
            <th class="table-sorter-header hidden-md-down">
              <app-table-field-sorter [orderField]="Transport.OrderField.WAYBILL_NUMBER"
                                      [orderType]="queryModel.getOrderType(Transport.OrderField.WAYBILL_NUMBER)"
                                      (orderChange)="orderBy($event)"
                                      [text]="'TRANSPORT_WAYBILL_NUMBER' | translate"></app-table-field-sorter>
            </th>
            <th class="table-sorter-header">
              <app-table-field-sorter [orderField]="Transport.OrderField.VEHICLE_LICENCE_PLATE"
                                      [orderType]="queryModel.getOrderType(Transport.OrderField.VEHICLE_LICENCE_PLATE)"
                                      (orderChange)="orderBy($event)"
                                      [text]="'TRANSPORT_LICENCE_PLATE' | translate"></app-table-field-sorter>
            </th>
            <th class="table-sorter-header hidden-md-down">
              <app-table-field-sorter [orderField]="Transport.OrderField.DRIVER_PERSON_NAME"
                                      [orderType]="queryModel.getOrderType(Transport.OrderField.DRIVER_PERSON_NAME)"
                                      (orderChange)="orderBy($event)"
                                      [text]="'TRANSPORT_DRIVER' | translate"></app-table-field-sorter>
            </th>
            <th class="table-sorter-header">
              <app-table-sorter-no-op [text]="'TRANSPORT_STATE' | translate"></app-table-sorter-no-op>
            </th>
            <th class="table-sorter-header hidden-lg-down">
              <app-table-field-sorter [orderField]="Transport.OrderField.START_TIME"
                                      [orderType]="queryModel.getOrderType(Transport.OrderField.START_TIME)"
                                      (orderChange)="orderBy($event)"
                                      [text]="'TRANSPORT_START_TIME' | translate"></app-table-field-sorter>
            </th>
            <th class="table-sorter-header w-table-actions-single">
              <app-table-sorter-no-op></app-table-sorter-no-op>
            </th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let transport of transportList | paginate: { id: pagingId,
                                                      itemsPerPage: queryModel.itemsPerPage,
                                                      currentPage: queryModel.currentPage,
                                                      totalItems: queryModel.currentNumberOfItems }">
            <td class="responsive-table-column hidden-sm-down">{{transport.id}}
              <ng-template #delay>
                <i class="{{getDelayIcon(transport.delayMinutes)}} popover-icon"></i>
                {{getDelay(transport.delayMinutes) | formattedDuration}} {{'TRANSPORT_DELAY' | translate }}
              </ng-template>
              <span class="{{getDelayIcon(transport.delayMinutes)}}"
                    [popover]="delay" triggers="mouseenter:mouseleave" container="body"></span>
            </td>
            <td class="responsive-table-column hidden-md-down">{{transport.waybillNumber}}
              <ng-template #safetyShipping>
                <i class="{{getSafetyShippingIcon(transport.safetyShipping)}} popover-icon"></i>
                {{'TRANSPORT_SAFETY_SHIPPING' | translate }}
                <div class="d-flex align-items-center">
                  <div class="security-guard-title ">{{'TRANSPORT_SECURITY_GUARD' | translate}}</div>
                  <div class="security-guard-name">{{transport.securityGuard.personName}}</div>
                </div>
              </ng-template>

              <span class="{{getSafetyShippingIcon(transport.safetyShipping)}}"
                    [popover]="safetyShipping" triggers="mouseenter:mouseleave" container="body"></span>

            </td>
            <td class="responsive-table-column">{{transport.vehicleLicencePlate}}</td>
            <td class="responsive-table-column hidden-md-down">{{transport.driverName}}</td>
            <td class="responsive-table-column">{{transport.state}}</td>
            <td class="responsive-table-column hidden-lg-down">{{transport.startTime | date:'short'}}</td>
            <td class="responsive-table-column">
              <a class="icons-table-group cursor-pointer" uiSref="Admin.TransportDetail"
                 [uiParams]="{ id: transport.id }">
                <i class="icomoon icons-table-item icomoon-detail icomoon-detail-table"
                   title="{{'COMMON_BUTTON_DETAILS' | translate}}"></i>
              </a>
            </td>
          </tr>
          </tbody>
          <tfoot>
          <tr>
            <th class="table-sorter-header hidden-sm-down">
              <app-table-field-sorter [orderField]="Transport.OrderField.ID"
                                      [orderType]="queryModel.getOrderType(Transport.OrderField.ID)"
                                      (orderChange)="orderBy($event)"
                                      [text]="'COMMON_TABLE_HEADER_ID' | translate"></app-table-field-sorter>
            </th>
            <th class="table-sorter-header hidden-md-down">
              <app-table-field-sorter [orderField]="Transport.OrderField.WAYBILL_NUMBER"
                                      [orderType]="queryModel.getOrderType(Transport.OrderField.WAYBILL_NUMBER)"
                                      (orderChange)="orderBy($event)"
                                      [text]="'TRANSPORT_WAYBILL_NUMBER' | translate"></app-table-field-sorter>
            </th>
            <th class="table-sorter-header">
              <app-table-field-sorter [orderField]="Transport.OrderField.VEHICLE_LICENCE_PLATE"
                                      [orderType]="queryModel.getOrderType(Transport.OrderField.VEHICLE_LICENCE_PLATE)"
                                      (orderChange)="orderBy($event)"
                                      [text]="'TRANSPORT_LICENCE_PLATE' | translate"></app-table-field-sorter>
            </th>
            <th class="table-sorter-header hidden-md-down">
              <app-table-field-sorter [orderField]="Transport.OrderField.DRIVER_PERSON_NAME"
                                      [orderType]="queryModel.getOrderType(Transport.OrderField.DRIVER_PERSON_NAME)"
                                      (orderChange)="orderBy($event)"
                                      [text]="'TRANSPORT_DRIVER' | translate"></app-table-field-sorter>
            </th>
            <th class="table-sorter-header">
              <app-table-sorter-no-op [text]="'TRANSPORT_STATE' | translate"></app-table-sorter-no-op>
            </th>
            <th class="table-sorter-header hidden-lg-down">
              <app-table-field-sorter [orderField]="Transport.OrderField.START_TIME"
                                      [orderType]="queryModel.getOrderType(Transport.OrderField.START_TIME)"
                                      (orderChange)="orderBy($event)"
                                      [text]="'TRANSPORT_START_TIME' | translate"></app-table-field-sorter>
            </th>
            <th class="table-sorter-header w-table-actions-single">
              <app-table-sorter-no-op></app-table-sorter-no-op>
            </th>
          </tr>
          </tfoot>
        </table>
        <app-table-paging
          [id]="pagingId"
          [currentNumberOfItems]="queryModel.currentNumberOfItems"
          [totalNumberOfItems]="queryModel.totalNumberOfItems"
          [itemsPerPage]="queryModel.itemsPerPage"
          (pageChange)="pageChanged($event)"
          (itemsPerPageChange)="itemsPerPageChanged($event)">
        </app-table-paging>
      </div>
    </div>
  </div>
</div>
