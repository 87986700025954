/* eslint-disable */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
/* eslint-enable */

@Component({
  selector: 'app-table-sorter-no-op',
  templateUrl: './table-sorter-no-op.component.html',
  styleUrls: ['./table-sorter-no-op.component.scss']
})
export class TableSorterNoOpComponent implements OnInit {

  @Input()
  text: string;

  @Input()
  checkAllEnabled?: boolean = false;

  @Input()
  checkAllSelected?: boolean = false;

  @Output()
  onCheckAllClicked: EventEmitter<any> = new EventEmitter<any>();

  constructor() {
  }

  ngOnInit() {
  }

}
