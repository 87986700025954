import { DqlQuery, Query } from '../field';
import { LazyReference } from '../../../util/lazy';

// Each class should be an interface, but it is easier to define the constant fields in the constructors.
// For now we have only one query language so it is not a problem.
// Always use the public Query namespace for variable definitions so we will be able to introduce other language easily.

export class MplTracking {

  private readonly shipmentRef: LazyReference<ShipmentFilterField>;
  private readonly orderRef: LazyReference<OrderFilterField>;

  get shipment(): ShipmentFilterField {
    return this.shipmentRef.get();
  }

  get order(): OrderFilterField {
    return this.orderRef.get();
  }

  constructor(prefix?: string) {
    prefix = prefix ? prefix : '';
    this.shipmentRef = LazyReference.of(
      () => new ShipmentFilterField(prefix + 'shipment.')
    );
    this.orderRef = LazyReference.of(
      () => new OrderFilterField(prefix + 'order.')
    );
  }

}

export class ShipmentFilterField {
  id: Query.NumberField;
  private readonly shipmentGroupRef: LazyReference<ShipmentGroupFilterField>;

  get shipmentGroup(): ShipmentGroupFilterField {
    return this.shipmentGroupRef.get();
  }

  constructor(prefix?: string) {
    prefix = prefix ? prefix : '';
    this.id = new DqlQuery.NumberField(prefix + 'id');
    this.shipmentGroupRef = LazyReference.of(
      () => new ShipmentGroupFilterField(prefix + 'shipment_group.')
    );
  }
}

export class ShipmentGroupFilterField {
  id: Query.NumberField;
  private readonly shippingDemandsRef: LazyReference<ShippingDemandFilterField>;

  get shippingDemand(): ShippingDemandFilterField {
    return this.shippingDemandsRef.get();
  }

  constructor(prefix?: string) {
    prefix = prefix ? prefix : '';
    this.id = new DqlQuery.NumberField(prefix + 'id');
    this.shippingDemandsRef = LazyReference.of(
      () => new ShippingDemandFilterField(prefix + 'demands.')
    );
  }
}

export class ShippingDemandFilterField {
  id: Query.NumberField;

  constructor(prefix?: string) {
    prefix = prefix ? prefix : '';
    this.id = new DqlQuery.NumberField(prefix + 'id');
  }
}

export class OrderFilterField {
  id: Query.NumberField;

  constructor(prefix?: string) {
    prefix = prefix ? prefix : '';
    this.id = new DqlQuery.NumberField(prefix + 'id');
  }
}
