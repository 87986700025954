/* eslint-disable */
import { Component, } from '@angular/core';

/* eslint-enable */

@Component({
  selector: 'app-form-record-field-line-break',
  templateUrl: 'form-record-field-line-break.component.html',
  styleUrls: ['form-record-field-line-break.component.scss'],
})
export class FormRecordFieldLineBreakComponent {
}
