<div class="breadcrumb-container" [ngClass]="{ 'breadcrumb-container-compact': compactSidebar }" id="breadcrumb">
  <div class="breadcrumb-component">
    <app-breadcrumb
      [parents]="breadcrumbParents"
      [self]="breadcrumbSelf">
    </app-breadcrumb>
  </div>
  <div class="breadcrumb-menu d-flex">
    <div class="breadcrumb-button-icon-container cursor-pointer"
         (click)="formImportDialog.openImportDialog()">
      <a class="btn-setting d-flex align-items-center" title="{{'COMMON_BUTTON_IMPORT' | translate}}">
        <i class="icomoon icomoon-csv-import"></i>
        <span class="breadcrumb-button-text-visible">{{'COMMON_BUTTON_IMPORT' | translate}}</span>
      </a>
    </div>
    <div class="breadcrumb-button-icon-container cursor-pointer negative-margin-right"
         (click)="exportForm()">
      <a class="btn-setting d-flex align-items-center" title="{{'COMMON_BUTTON_EXPORT' | translate}}">
        <i class="icomoon icomoon-csv-export"></i>
        <span class="breadcrumb-button-text-visible">{{'COMMON_BUTTON_EXPORT' | translate}}</span>
      </a>
    </div>
  </div>
</div>
<div class="breadcrumb-placeholder"></div>
<!-- region Content -->
<div class="container-horizontal-padding-half container-vertical-padding-half animated fadeIn">

  <!-- region Customer card -->
  <form class="form-horizontal bordered-row" (ngSubmit)="f.form.valid && update()" #f="ngForm" novalidate>
    <div class="container-vertical-padding-half card-custom-padding-outer">
      <div class="col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 p-0">
        <div class="card">

          <div class="card-header">
            {{'CUSTOMER_PANEL_HEADING_EDIT'|translate}}
          </div><!-- /.card-header -->

          <div class="card-body">

            <!-- region Name -->
            <div class="form-group row"
                 [ngClass]="{ 'has-danger': hasFieldError(Customer.ValidatedField.NAME) || hasLocalFieldError(name) }">
              <div class="col-md-4">
                <label class="mw-100 col-form-label form-control-label required-field-label">
                  {{'COMMON_NAME' | translate}}
                </label>
              </div>
              <div class="col-md-8">
                <div class="mw-100">
                  <input type="text" class="form-control" placeholder="{{'COMMON_NAME' | translate}}"
                         maxlength="{{UiConstants.maximumVarcharLength}}"
                         [ngClass]="{ 'form-control-danger': hasFieldError(Customer.ValidatedField.NAME) || hasLocalFieldError(name) }"
                         [(ngModel)]="model.name" (ngModelChange)="removeFieldError(Customer.ValidatedField.NAME)"
                         name="name" id="name" #name="ngModel" required>
                  <div class="form-control-feedback"
                       *ngIf="hasFieldError(Customer.ValidatedField.NAME)">{{getFieldErrorText(Customer.ValidatedField.NAME)}}</div>
                  <div class="form-control-feedback"
                       *ngIf="hasLocalFieldError(name)">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</div>
                </div><!-- /.edit-form-input-group -->
              </div>
            </div><!-- /.form-group -->
            <!-- endregion Name -->

            <!-- region External id -->
            <div class="form-group row"
                 [ngClass]="{ 'has-danger': hasFieldError(Customer.ValidatedField.EXTERNAL_ID) || hasLocalFieldError(external_id) }">
              <div class="col-md-4">
                <label class="mw-100 col-form-label form-control-label required-field-label">
                  {{'COMMON_EXTERNAL_ID' | translate}}
                </label>
              </div>
              <div class="col-md-8">
                <div class="mw-100">
                  <input type="text" class="form-control" placeholder="{{'COMMON_EXTERNAL_ID' | translate}}"
                         maxlength="{{UiConstants.maximumVarcharLength}}"
                         [ngClass]="{ 'form-control-danger': hasFieldError(Customer.ValidatedField.EXTERNAL_ID) || hasLocalFieldError(external_id) }"
                         [(ngModel)]="model.externalId"
                         (ngModelChange)="removeFieldError(Customer.ValidatedField.EXTERNAL_ID)" name="external_id"
                         id="external_id" #external_id="ngModel" required>
                  <div class="form-control-feedback"
                       *ngIf="hasFieldError(Customer.ValidatedField.EXTERNAL_ID)">{{getFieldErrorText(Customer.ValidatedField.EXTERNAL_ID)}}</div>
                  <div class="form-control-feedback"
                       *ngIf="hasLocalFieldError(external_id)">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</div>
                </div><!-- /.edit-form-input-group -->
              </div>
            </div><!-- /.form-group -->
            <!-- endregion External id -->
            <app-icon-selector
              [(selectedIcon)]="model.icon"
            ></app-icon-selector>

            <div class="form-group row"
                 [ngClass]="{ 'has-danger': hasFieldError(Customer.ValidatedField.TYPE) || (f.submitted && !type.valid) }">
              <div class="col-md-4">
                <label class="mw-100 col-form-label form-control-label required-field-label">
                  {{'COMMON_TYPE' | translate}}
                </label>
              </div>
              <div class="col-md-8">
                <div class="mw-100">
                  <select type="text" class="form-control"
                          [compareWith]="SelectUtils.compareObjects"
                          [ngClass]="{ 'form-control-danger': hasFieldError(Customer.ValidatedField.TYPE) || (f.submitted && !type.valid) }"
                          [(ngModel)]="model.type" (ngModelChange)="onCustomerTypeChanged()" name="type" id="type"
                          #type="ngModel" required>
                    <option *ngFor="let type of customerTypes" [ngValue]="type">{{type.text}}</option>
                  </select>
                  <div class="form-control-feedback"
                       *ngIf="hasFieldError(Customer.ValidatedField.TYPE)">{{getFieldErrorText(Customer.ValidatedField.TYPE)}}</div>
                  <div class="form-control-feedback"
                       *ngIf="f.submitted && !type.valid">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</div>
                </div>
              </div>
            </div>

            <div class="form-group row">
              <label
                class="col-form-label form-control-label col-md-4 detail-title">{{'CUSTOMER_USE_DEFAULT_MANAGED_FIELDS' | translate}}
              </label>
              <div class="col-md-8">
                <label class="switch switch-text switch-info mb-0">
                  <input type="checkbox" class="switch-input"
                         [ngModelOptions]="{standalone: true}"
                         [(ngModel)]="model.useDefaultManagedFields">
                  <span class="switch-label" data-on="&#xe957;" data-off="&#xe98e;"></span>
                  <span class="switch-handle"></span>
                </label>
              </div>
            </div>

            <div class="form-group row" *ngIf="!model.useDefaultManagedFields">
              <label
                class="col-form-label form-control-label col-md-4 detail-title">{{'CUSTOMER_MANAGED_FIELDS' | translate}}
              </label>
              <div class="col-md-8">
                <angular2-multiselect [data]="manageableFields" [settings]="fieldsDropdownSettings"
                                      class="form-control"
                                      [(ngModel)]="model.managedFields"
                                      [ngModelOptions]="{standalone: true}"
                                      (ngModelChange)="onManagedFieldsChanged()">
                </angular2-multiselect>
              </div>
            </div>

            <div class="form-group row" [ngClass]="{ 'has-danger': hasLocalFieldError(requiredFieldsInput) }">
              <label
                class="col-form-label form-control-label col-md-4 detail-title">{{'CUSTOMER_REQUIRED_FIELDS' | translate}}
              </label>
              <div class="col-md-8">
                <angular2-multiselect [data]="requirableFields" [settings]="fieldsDropdownSettings"
                                      class="form-control"
                                      [(ngModel)]="model.requiredFields"
                                      [ngClass]="{ 'form-control-danger': hasLocalFieldError(requiredFieldsInput) }"
                                      name="requiredFields" id="requiredFields" #requiredFieldsInput="ngModel"
                                      validateEnabledItems>
                </angular2-multiselect>
                <div class="form-control-feedback"
                     *ngIf="hasLocalFieldError(requiredFieldsInput) && requiredFieldsInput.errors['hasDisabledItem']">
                  {{'CUSTOMER_EDIT_DISABLED_REQUIRED_FIELD_ERROR' | translate}}
                </div>
              </div>
            </div>

            <div class="form-group row"
                 *ngIf="model.type ? model.type.id !== 'COMPANY': false"
                 [ngClass]="{ 'has-danger': hasFieldError(Customer.ValidatedField.COMPLEX_NAME)}">
              <div class="col-md-4">
                <label class="mw-100 col-form-label form-control-label">{{'CUSTOMER_COMPLEX_NAME' | translate}}</label>
              </div>
              <div class="col-md-8">
                <div class="mw-100">
                  <label class="switch switch-text switch-info mb-0">
                    <input type="checkbox" class="switch-input"
                           [(ngModel)]="model.complexName"
                           name="complexName">
                    <span class="switch-label" data-on="&#xe957;" data-off="&#xe98e;"></span>
                    <span class="switch-handle"></span>
                  </label>
                  <div class="form-control-feedback" *ngIf="hasFieldError(Customer.ValidatedField.COMPLEX_NAME)">
                    {{getFieldErrorText(Customer.ValidatedField.COMPLEX_NAME)}}
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group row"
                 *ngIf="model.type ? model.type.id !== 'COMPANY': false"
                 [ngClass]="{ 'has-danger': hasFieldError(Customer.ValidatedField.CONTACT_PERSON)}">
              <div class="col-md-4">
                <label
                  class="mw-100 col-form-label form-control-label">{{'CUSTOMER_CONTACT_PERSON' | translate}}</label>
              </div>
              <div class="col-md-8">
                <div class="mw-100">
                  <label class="switch switch-text switch-info mb-0">
                    <input type="checkbox" class="switch-input"
                           [(ngModel)]="model.contactPerson"
                           name="contactPerson">
                    <span class="switch-label" data-on="&#xe957;" data-off="&#xe98e;"></span>
                    <span class="switch-handle"></span>
                  </label>
                  <div class="form-control-feedback" *ngIf="hasFieldError(Customer.ValidatedField.CONTACT_PERSON)">
                    {{getFieldErrorText(Customer.ValidatedField.CONTACT_PERSON)}}
                  </div>
                </div>
              </div>
            </div>

            <!-- region User groups -->
            <div class="form-group row"
                 [ngClass]="{ 'has-danger': hasFieldError(Customer.ValidatedField.OWNER_USER_GROUPS) || (f.submitted && !ownerGroups.valid) }">
              <div class="col-md-4">
                <label class="mw-100 col-form-label form-control-label required-field-label">
                  {{'CUSTOMER_RECORD_USER_GROUPS' | translate}}
                  <i class="icomoon icomoon-info info-icon" popover="{{'CUSTOMER_RECORD_USER_GROUPS_HINT' | translate}}"
                     triggers="mouseenter:mouseleave" container="body" placement="right">
                  </i>
                </label>
              </div>
              <div class="col-md-8">
                <angular2-multiselect
                  [(ngModel)]="model.userGroups"
                  [ngClass]="{ 'has-danger': hasFieldError(Customer.ValidatedField.OWNER_USER_GROUPS) || (f.submitted && !ownerGroups.valid) }"
                  (ngModelChange)="removeFieldError(Customer.ValidatedField.OWNER_USER_GROUPS)"
                  (onRemoteSearch)="loadUserGroups($event.target.value)"
                  name="ownerGroups" id="ownerGroups" #ownerGroups="ngModel" required validateEnabledItems
                  [data]="userGroups" [settings]="userGroupDropdownSettings">
                </angular2-multiselect>
                <div class="form-control-feedback"
                     *ngIf="f.submitted && !ownerGroups.valid && ownerGroups.errors['required']">
                  {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
                </div>
                <div class="form-control-feedback"
                     *ngIf="f.submitted && !ownerGroups.valid && ownerGroups.errors['hasDisabledItem']">
                  {{'COMMON_VALIDATION_MESSAGE_DISABLED' | translate}}
                </div>
                <div class="form-control-feedback" *ngIf="hasFieldError(Customer.ValidatedField.OWNER_USER_GROUPS)">
                  {{getFieldErrorText(Customer.ValidatedField.OWNER_USER_GROUPS)}}
                </div>
              </div>
            </div>
            <!-- endregion User groups -->

            <div class="form-group row"
                 [ngClass]="{ 'has-danger':  hasFieldError(Customer.ValidatedField.EXPLICIT_ORDER_NUMBER) }">
              <label
                class="col-form-label form-control-label col-md-4 detail-title">
                {{'CUSTOMER_ORDER_NUMBER' | translate}}
                <i class="icomoon icomoon-info info-icon" popover="{{'CUSTOMER_ORDER_NUMBER_HINT' | translate}}"
                   triggers="mouseenter:mouseleave" container="body" placement="right"></i>
              </label>
              <div class="col-md-8">
                <input type="text" class="form-control"
                       [ngClass]="{ 'form-control-danger': hasFieldError(Customer.ValidatedField.EXPLICIT_ORDER_NUMBER)}"
                       [(ngModel)]="model.explicitOrderNumber"
                       [textMask]="{mask: ordinalNumberMask, guide: false}"
                       (ngModelChange)="removeFieldError(Customer.ValidatedField.EXPLICIT_ORDER_NUMBER)"
                       name="explicitOrderNumber" id="explicitOrderNumber" #explicitOrderNumber="ngModel">
                <div class="form-control-feedback" *ngIf="hasFieldError(Customer.ValidatedField.EXPLICIT_ORDER_NUMBER)">
                  {{getFieldErrorText(Customer.ValidatedField.EXPLICIT_ORDER_NUMBER)}}
                </div>
              </div>
            </div>

            <!-- region Ledger number -->
            <div class="form-group row" *ngIf="rightModel.ledgerNumberRead.hasRight()"
                 [ngClass]="{ 'has-danger': hasFieldError(Customer.ValidatedField.DEFAULT_LEDGER_NUMBER) || (f.submitted && !defaultLedgerNumber.valid) }">
              <div class="col-md-4">
                <label class="mw-100 col-form-label form-control-label ">
                  {{'CUSTOMER_DEFAULT_LEDGER_NUMBER' | translate}}
                  <i class="icomoon icomoon-info info-icon"
                     popover="{{'CUSTOMER_DEFAULT_LEDGER_NUMBER_HINT' | translate}}"
                     triggers="mouseenter:mouseleave" container="body" placement="right">
                  </i>
                </label>
              </div>
              <div class="col-md-8">
                <angular2-multiselect
                  [(ngModel)]="model.defaultLedgerNumber"
                  [ngClass]="{ 'has-danger': hasFieldError(Customer.ValidatedField.DEFAULT_LEDGER_NUMBER) || (f.submitted && !defaultLedgerNumber.valid) }"
                  (ngModelChange)="removeFieldError(Customer.ValidatedField.DEFAULT_LEDGER_NUMBER)"
                  (onRemoteSearch)="loadLedgerNumbers($event.target.value)"
                  name="defaultLedgerNumber" id="defaultLedgerNumber" #defaultLedgerNumber="ngModel"
                  validateEnabledItems
                  [data]="ledgerNumberList" [settings]="ledgerNumberDropdownSettings">
                </angular2-multiselect>
                <div class="form-control-feedback"
                     *ngIf="f.submitted && !defaultLedgerNumber.valid && defaultLedgerNumber.errors['hasDisabledItem']">
                  {{'COMMON_VALIDATION_MESSAGE_DISABLED' | translate}}
                </div>
                <div class="form-control-feedback" *ngIf="hasFieldError(Customer.ValidatedField.DEFAULT_LEDGER_NUMBER)">
                  {{getFieldErrorText(Customer.ValidatedField.DEFAULT_LEDGER_NUMBER)}}
                </div>
              </div>
            </div>
            <!-- endregion Ledger number -->

            <!-- region Description -->
            <div class="form-group row">
              <div class="col-md-4">
                <label class="mw-100 col-form-label form-control-label">{{'COMMON_DESCRIPTION' | translate}}</label>
              </div>
              <div class="col-md-8">
                <div class="mw-100">
                  <input type="text" class="form-control" placeholder="{{'COMMON_DESCRIPTION' | translate}}"
                         [(ngModel)]="model.description" name="description" id="description" #description="ngModel">
                </div><!-- /.edit-form-input-group -->
              </div>
            </div><!-- /.form-group -->
            <!-- endregion Description -->

          </div><!-- /.card-body -->

        </div><!-- /.card -->
      </div>
    </div><!-- /.container-vertical-padding-half -->

    <div class="container-vertical-padding-half card-custom-padding-outer">
      <div class="col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 p-0">
        <div class="card">
          <div class="card-header">
            {{'CUSTOMER_OWNER_TYPES'|translate}}
          </div>

          <div class="card-body">

            <div class="form-group row"
                 [ngClass]="{ 'has-danger': hasFieldError(Customer.ValidatedField.MOBILE_OWNER_TYPE) || (f.submitted && !mobileOwnerType.valid) }">
              <div class="col-md-4">
                <label class="mw-100 col-form-label form-control-label required-field-label">
                  {{'CUSTOMER_MOBILE_OWNER_TYPE' | translate}}
                  <i class="icomoon icomoon-info info-icon" popover="{{'CUSTOMER_MOBILE_OWNER_TYPE_HINT' | translate}}"
                     triggers="mouseenter:mouseleave" container="body" placement="right">
                  </i>
                </label>
              </div>
              <div class="col-md-8">
                <div class="mw-100">
                  <select type="text" class="form-control"
                          [compareWith]="SelectUtils.compareObjects"
                          [ngClass]="{ 'form-control-danger': hasFieldError(Customer.ValidatedField.MOBILE_OWNER_TYPE) || (f.submitted && !mobileOwnerType.valid) }"
                          [(ngModel)]="model.mobileOwnerType"
                          (ngModelChange)="removeFieldError(Customer.ValidatedField.MOBILE_OWNER_TYPE)"
                          name="mobileOwnerType" id="mobileOwnerType" #mobileOwnerType="ngModel" required>
                    <option *ngFor="let mobileOwnerType of mobileOwnerTypes"
                            [ngValue]="mobileOwnerType">{{mobileOwnerType.text}}</option>
                  </select>
                  <div class="form-control-feedback"
                       *ngIf="hasFieldError(Customer.ValidatedField.MOBILE_OWNER_TYPE)">{{getFieldErrorText(Customer.ValidatedField.MOBILE_OWNER_TYPE)}}</div>
                  <div class="form-control-feedback"
                       *ngIf="f.submitted && !mobileOwnerType.valid">{{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}</div>
                </div>
              </div>
            </div>

            <div class="form-group row"
                 [ngClass]="{ 'has-danger': hasFieldError(Customer.ValidatedField.WEB_OWNER_TYPE) || (f.submitted && !webOwnerType.valid) }">
              <div class="col-md-4">
                <label class="mw-100 col-form-label form-control-label">
                  {{'CUSTOMER_WEB_OWNER_TYPE' | translate}}
                  <i class="icomoon icomoon-info info-icon" popover="{{'CUSTOMER_WEB_OWNER_TYPE_HINT' | translate}}"
                     triggers="mouseenter:mouseleave" container="body" placement="right">
                  </i>
                </label>
              </div>
              <div class="col-md-8">
                <div class="mw-100">
                  <angular2-multiselect [data]="webOwnerTypes" [settings]="fieldsDropdownSettings"
                                        [ngClass]="{ 'form-control-danger': hasFieldError(Customer.ValidatedField.WEB_OWNER_TYPE) || (f.submitted && !webOwnerType.valid) }"
                                        (ngModelChange)="removeFieldError(Customer.ValidatedField.WEB_OWNER_TYPE)"
                                        class="form-control"
                                        [(ngModel)]="model.webOwnerTypes"
                                        name="webOwnerType" id="webOwnerType" #webOwnerType="ngModel">
                  </angular2-multiselect>
                  <div class="form-control-feedback" *ngIf="hasFieldError(Customer.ValidatedField.WEB_OWNER_TYPE)">
                    {{getFieldErrorText(Customer.ValidatedField.WEB_OWNER_TYPE)}}
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group row" *ngIf="model.presetWebUsersEnabled">
              <div class="col-md-4">
                <label class="mw-100 col-form-label form-control-label required-field-label">
                  {{'CUSTOMER_RECORD_USERS' | translate}}
                </label>
              </div>
              <div class="col-md-8">
                <div class="mw-100">
                  <angular2-multiselect [data]="ownerUsers" [settings]="userGroupDropdownSettings"
                                        class="form-control"
                                        (onRemoteSearch)="loadUsers($event.target.value)"
                                        [(ngModel)]="model.webOwnerPresetUsers"
                                        name="presetUsers" id="presetUsers" #presetUsers="ngModel"
                                        validateEnabledItems
                                        [required]="model.presetWebUsersEnabled">
                  </angular2-multiselect>
                  <div class="form-control-feedback"
                       *ngIf="f.submitted && !presetUsers.valid && presetUsers.errors['hasDisabledItem']">
                    {{'COMMON_VALIDATION_MESSAGE_DISABLED' | translate}}
                  </div>
                  <div class="form-control-feedback" *ngIf="f.submitted && !presetUsers.valid && presetUsers.errors['required']">
                    {{'COMMON_VALIDATION_MESSAGE_REQUIRED'
                    | translate}}
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group row" *ngIf="model.presetWebUserGroupsEnabled">
              <div class="col-md-4">
                <label class="mw-100 col-form-label form-control-label required-field-label">
                  {{'CUSTOMER_RECORD_USER_GROUPS' | translate}}
                </label>
              </div>
              <div class="col-md-8">
                <div class="mw-100">
                  <angular2-multiselect [data]="userGroups" [settings]="userGroupDropdownSettings"
                                        class="form-control"
                                        (onRemoteSearch)="loadUserGroups($event.target.value)"
                                        [(ngModel)]="model.webOwnerPresetUserGroups"
                                        name="presetUserGroups" id="presetUserGroups" #presetUserGroups="ngModel"
                                        validateEnabledItems
                                        [required]="model.presetWebUserGroupsEnabled">
                  </angular2-multiselect>
                  <div class="form-control-feedback"
                       *ngIf="f.submitted && !presetUserGroups.valid && presetUserGroups.errors['hasDisabledItem']">
                    {{'COMMON_VALIDATION_MESSAGE_DISABLED' | translate}}
                  </div>
                  <div class="form-control-feedback" *ngIf="f.submitted && !presetUserGroups.valid && presetUserGroups.errors['required']">
                    {{'COMMON_VALIDATION_MESSAGE_REQUIRED'
                    | translate}}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <input type="submit" class="btn btn-primary floating_save_button" value="{{'COMMON_BUTTON_SAVE'|translate}}"/>
  </form>
  <!-- endregion Customer -->

  <!-- region Customer card -->
  <div class="container-vertical-padding-half card-custom-padding-outer">
    <div class="col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 p-0">
      <app-usergroup-type-based-role-card
        [type]="UserGroupRoleType.CUSTOMER"
        [typeId]="customerId"
        [readonly]="false"
        [rightModel]="rightModel">
      </app-usergroup-type-based-role-card>
    </div>
  </div>

  <!-- region Form card -->
  <div class="container-vertical-padding-half card-custom-padding-outer form-editor-card-container"
       id="form-editor-card-container">
    <app-form-edit #formEdit
                   [formServiceCode]="FormServiceCode.CUSTOMER"
                   [formRights]="formRights"
                   [parentId]="customerId"
                   [dialogContainer]="dialogContainer">
    </app-form-edit>
  </div><!-- /.container-vertical-padding -->
  <!-- endregion Form card -->

</div>
<!-- endregion Content -->

<!-- region Dialogs -->
<app-form-edit-dialog-container #dialogContainer></app-form-edit-dialog-container>

<app-form-import-dialog
  #formImportDialog
  [parentId]="customerId"
  [formService]="formService"
  (onFormImported)="formEdit.reloadForm()">
</app-form-import-dialog>
<!-- endregion Dialogs -->
