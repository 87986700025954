
export class RuntimeConfiguration {

  private static instance: RuntimeConfiguration = new RuntimeConfiguration();

  public static init(instance: RuntimeConfiguration) {
    RuntimeConfiguration.instance = instance;
  }

  public static getInstance() {
    return RuntimeConfiguration.instance;
  }

  constructor(
    private readonly brandLogoSrc?: string,
    private readonly brandLogoLoginSrc?: string,
    private readonly brandLogoSmallSrc?: string,
    private readonly loginBackgroundSrc?: string,
    private readonly faviconPngSrc?: string,
    private readonly baseUrl?: string,
    private readonly googleMapsApiKey?: string,
    private readonly recaptchaV2SiteKey?: string,
    private readonly recaptchaV3SiteKey?: string,
    private readonly showBackgroundErrors?: boolean,
    private readonly dummyPostalAddress?: boolean,
    private readonly firebase?: FirebaseConfig,
    private readonly registrationConversionCode?: string) {
  }

  public getBrandLogoSrc(): string | undefined {
    return this.brandLogoSrc;
  }

  public getBrandLogoLoginSrc(): string | undefined {
    return this.brandLogoLoginSrc;
  }

  public getBrandLogoSmallSrc(): string | undefined {
    return this.brandLogoSmallSrc;
  }

  public getLoginBackgroundSrc(): string | undefined {
    return this.loginBackgroundSrc;
  }

  public getFaviconPngSrc(): string | undefined {
    return this.faviconPngSrc;
  }

  public getBaseUrl(def: string): string {
    if (!this.baseUrl) {
      return def;
    }
    return this.baseUrl;
  }

  public getGoogleMapsApiKey(def: string): string {
    if (!this.googleMapsApiKey) {
      return def;
    }
    return this.googleMapsApiKey;
  }

  public getRecaptchaV2SiteKey(def: string): string {
    if (!this.recaptchaV2SiteKey) {
      return def;
    }
    return this.recaptchaV2SiteKey;
  }

  public getRecaptchaV3SiteKey(def: string): string {
    if (!this.recaptchaV3SiteKey) {
      return def;
    }
    return this.recaptchaV3SiteKey;
  }

  public getShowBackgroundErrors(def: boolean): boolean {
    if (this.showBackgroundErrors === undefined) {
      return def;
    }
    return this.showBackgroundErrors;
  }

  public getDummyPostalAddress(def: boolean): boolean {
    if (this.dummyPostalAddress === undefined) {
      return def;
    }
    return this.dummyPostalAddress;
  }

  public getFirebase(def: FirebaseConfig): FirebaseConfig {
    if (this.firebase === undefined) {
      return def;
    }
    return this.firebase;
  }

  public getRegistrationConversionCode(def: string): string {
    if (!this.registrationConversionCode) {
      return def;
    }
    return this.registrationConversionCode;
  }

}

export interface FirebaseConfig {

  /**
   * The globally unique, user-assigned project ID of the parent Project for the App.
   */
  projectId: string;

  /**
   * The globally unique, Firebase-assigned identifier of the App.
   */
  appId: string;

  /**
   * The default Firebase Realtime Database URL.
   */
  databaseURL: string;

  /**
   * The default Cloud Storage for Firebase storage bucket name.
   */
  storageBucket: string;

  /**
   * The ID of the project's default GCP resource location.
   *
   * The location is one of the available GCP resource locations:
   * https://firebase.google.com/docs/projects/locations
   *
   * This field is omitted if the default GCP resource location has not been finalized yet.
   */
  locationId?: string;

  /**
   * The API key associated with the web App.
   */
  apiKey: string;

  /**
   * The domain Firebase Auth configures for OAuth redirects, in the format:
   * projectId.firebaseapp.com
   */
  authDomain: string;

  /**
   * The sender ID for use with Firebase Cloud Messaging.
   */
  messagingSenderId: string;

  /**
   * The unique Google-assigned identifier of the Google Analytics web stream associated with the Firebase Web App.
   * Firebase SDKs use this ID to interact with Google Analytics APIs.
   * This field is only present if the App is linked to a web stream in a Google Analytics App + Web property.
   *
   * Learn more about this ID and Google Analytics web streams in the Analytics documentation:
   * https://support.google.com/analytics/topic/9303475
   */
  measurementId?: string;

}
