import { AfterViewInit, Component, ContentChildren, Input, OnInit, QueryList, ViewChildren } from '@angular/core';
import { DropdownItemComponent } from '../../../dropdown/dropdown-item/dropdown-item.component';

@Component({
  selector: 'app-breadcrumb-dropdown',
  templateUrl: './breadcrumb-dropdown.component.html',
  styleUrls: ['./breadcrumb-dropdown.component.scss']
})
export class BreadcrumbDropdownComponent implements OnInit, AfterViewInit {

  @ContentChildren(DropdownItemComponent)
  dropdownItems: QueryList<DropdownItemComponent>;

  @Input()
  titleStringKey: string;

  viewInitialized: boolean = false;

  constructor() { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.viewInitialized = true;
  }

  anyChildVisible(): boolean {
    if (this.viewInitialized) {
      return this.dropdownItems.length > 0;
    }
    return false;
  }

}
