import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-table-badge',
  templateUrl: './table-badge.component.html',
  styleUrls: ['./table-badge.component.scss']
})
export class TableBadgeComponent implements OnInit {

  @Input()
  badgeStyle: string;

  @Input()
  iconClass: string;

  @Input()
  text: string;

  @Input()
  textKey: string;

  @Input()
  breakWord: boolean = false;

  @Input()
  showNotificationDot: boolean = false;

  @Output()
  iconClick: EventEmitter<void> = new EventEmitter();

  @Input()
  fontSize: 'normal' | 'small' = 'normal';

  @Input()
  textAsPopover: boolean = false;

  constructor() {
  }

  ngOnInit() {
  }

  onIconClick() {
    this.iconClick.emit();
  }

}
