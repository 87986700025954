import { DqlQuery, Query } from '../field';

export class MplTracking {

  readonly time: Query.OrderField;

  constructor(prefix?: string) {
    prefix = prefix ? prefix : '';
    this.time = new DqlQuery.OrderField(prefix + 'time');
  }

}
