/* eslint-disable */
import { Injectable } from '@angular/core';
import { Map as ImmutableMap } from 'immutable';
import { Observable } from 'rxjs';
import { BaseHttpService, ResourceHelper } from './util/http-services';
import { HttpClient } from '@angular/common/http';
import { ResourceQueryResult } from './util/services';
import { DisableRequest, EmptyMessage, IdentityMessage, ListQuery } from './util/messages';
import { FieldError } from './util/errors';
/* eslint-enable */

export namespace DeviceManagement {

  export enum OrderField {
    APPLICATION_ID,
    NAME,
    DEVICE_MODEL,
    LATEST_LOGIN_USER_NAME,
    LATEST_LOGIN_TIME,
  }

  export class Keys {

    private static readonly APPLICATION_ID = 'application_id';
    private static readonly NAME = 'name';
    private static readonly DEVICE_MODEL = 'device_model';
    private static readonly LATEST_LOGIN_USER_NAME = 'latest_login_user_name';
    private static readonly LATEST_LOGIN_TIME = 'latest_login_time';

    private static readonly orderFieldKeyMap: ImmutableMap<DeviceManagement.OrderField, string> = ImmutableMap.of(
      DeviceManagement.OrderField.APPLICATION_ID, Keys.APPLICATION_ID,
      DeviceManagement.OrderField.NAME, Keys.NAME,
      DeviceManagement.OrderField.DEVICE_MODEL, Keys.DEVICE_MODEL,
      DeviceManagement.OrderField.LATEST_LOGIN_USER_NAME, Keys.LATEST_LOGIN_USER_NAME,
      DeviceManagement.OrderField.LATEST_LOGIN_TIME, Keys.LATEST_LOGIN_TIME,
    );

    public static toOrderFieldKey(field: DeviceManagement.OrderField): string {
      return Keys.orderFieldKeyMap.get(field)!;
    }

  }

}

export interface SetDeviceRequest {
  id: number;
  name: string;
}

export interface DeviceQuery extends ListQuery.Paging, ListQuery.Order, ListQuery.Fields {
  id?: string;
  q?: string;
  name?: string;
  application_id?: string;
  latest_login_undefined?: boolean;
  disabled?: boolean;
  device_model?: string;
  latest_login_from?: string;
  latest_login_to?: string;
  latest_login_user_id?: number;
  latest_login_user_name?: string;
  no_progress_bar?: boolean;
}

export interface Device {
  id: number;
  application_id: string;
  application_type_id: number;
  device_id: string;
  name: string;
  device_model: string;
  operation_system_version: string;
  operation_system_type: string;
  disabled: boolean;
  latest_login_time: string;
  latest_login_user_name: string;
  latest_login_user_id?: number;
}

export interface DeviceFieldErrorMap {
  name?: FieldError;
}

@Injectable()
export class DeviceManagementService extends BaseHttpService {

  setDisabled(request: DisableRequest): Observable<EmptyMessage> {
    return this.http.patch(this.url + `${request.id}/disabled`, request);
  }

  setDevice(request: SetDeviceRequest): Observable<EmptyMessage> {
    return this.http.post(this.url + `${request.id}?name=${request.name}`, request);
  }

  get(request: IdentityMessage): Observable<Device> {
    return this.http.get<Device>(this.url + `${request.id}`);
  }

  query(request: DeviceQuery): Observable<ResourceQueryResult<Device>> {
    return this.http.get<ResourceQueryResult<Device>>(this.url, this.parseParams(request));
  }

  constructor(private http: HttpClient, resourceHelper: ResourceHelper) {
    super(resourceHelper, '/mobile-apps/');
  }

}
