/* eslint-disable */

/* eslint-enable */


import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PagingRequest, QueryResult, ResourceQueryResult } from '../util/services';
import { FormStockResourceService } from './form-stock-resource.service';
import { CompanyResource } from '../company/company-resource.service';
import { List } from 'immutable';
import { map } from 'rxjs/operators';

@Injectable()
export class FormStockService implements FormStock.Service {


  queryComments(request: FormStock.QueryRequest): Observable<QueryResult<string>> {
    const resourceRequest: CompanyResource.QueryRequest = {
      q: request.queryText,
      page_number: request.paging ? request.paging.pageNumber : undefined,
      number_of_items: request.paging ? request.paging.numberOfItems : undefined,
      no_progress_bar: true
    };
    return this.resourceService.queryComments(resourceRequest).pipe(
      map((result: ResourceQueryResult<string>) => {
        return {
          items: List.of(...result.items),
          pagingResult: result.pagingResult
        };
      }));
  }

  constructor(private resourceService: FormStockResourceService) {
  }
}

export namespace FormStock {

  export interface Service {

    // <editor-fold desc="CRUD">

    queryComments(request: QueryRequest): Observable<QueryResult<string>>;

    // </editor-fold>

  }

  export interface QueryRequest {
    queryText?: string;
    paging?: PagingRequest;
  }
}
