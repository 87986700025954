/* eslint-disable */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Set } from 'immutable';
import {
  OptionItem,
  OwnerUserItem,
  OwnerUserItemFactory,
  QueryFieldModel,
  SelectUtils,
  TypeItem,
  UiConstants,
} from '../../../util/core-utils';
import { SurveyRecord, SurveyRecordService } from '../../../lib/survey/survey-record.service';
import { OrderType, QueryResult, } from '../../../lib/util/services';
import { SurveyRecordSearchModel } from '../../../util/survey-utils';
import { Transition } from '@uirouter/angular';
import { UserData, UserDataLoader, UserDataLoaderPermissionDeniedStrategy } from '../../../lib/user-data-loader';
import { Arrays } from '../../../lib/util/arrays';
import { StringKey } from '../../../app.string-keys';
import { TranslateService } from '@ngx-translate/core';
import { Dates, OffsetDateTime } from '../../../lib/util/dates';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap/datepicker/ngb-date-struct';
import { RightResolver, RightService } from '../../../lib/right.service';
import { UserProfile } from '../../../lib/auth.service';
import { BreadcrumbParent } from '../../../shared/breadcrumb/breadcrumb/breadcrumb.component';
import { Survey, SurveyService } from '../../../lib/survey/survey.service';
import { StateName } from '../../../app.state-names';
import { combineLatest, Observable } from 'rxjs';
import { Models } from '../../../util/model-utils';
import { SurveyRecordSearch, SurveyRecordSearchService } from '../../../lib/survey-record-search-service';
import { CustomerRecord, CustomerRecordService } from '../../../lib/customer/customer-record.service';
import { DisabledEnum, DisabledItem } from '../../../util/search-utils';
import { RightModel } from '../../../app.rights';
/* eslint-enable */

@Component({
  selector: 'app-survey-record-list',
  templateUrl: 'survey-record-list.component.html',
  styleUrls: ['survey-record-list.component.scss']
})
export class SurveyRecordListComponent implements OnInit, OnDestroy {

  SurveyRecord = SurveyRecord;
  UiConstants = UiConstants;
  surveyId: number;
  breadcrumbParents: BreadcrumbParent[] = [];
  breadcrumbSelf: string;
  compactSidebar: boolean = document.querySelector('body')!.classList.contains('sidebar-compact');

  queryModel: QueryFieldModel<SurveyRecord.OrderField> = new QueryFieldModel(SurveyRecord.OrderField.ID, OrderType.DESC);
  searchModel: SurveyRecordSearchModel = new SurveyRecordSearchModel();
  rightModel: RightModel = RightModel.empty();
  surveyRecordList: Array<SurveyRecord.SurveyRecord> = [];
  showSearch: boolean = false;
  SelectUtils = SelectUtils;
  searchResult: SurveyRecordSearch.SearchDataResult;
  ownerUsers: OwnerUserItem[] = [];
  customerRecords: CustomerRecordItem[] = [];
  disabledItems: DisabledItem[] = [];

  constructor(private surveyRecordService: SurveyRecordService,
              private userDataLoader: UserDataLoader,
              private translateService: TranslateService,
              private ownerUserItemFactory: OwnerUserItemFactory,
              private rightService: RightService,
              private customerRecordService: CustomerRecordService,
              private surveyService: SurveyService,
              private surveyRecordSearchService: SurveyRecordSearchService,
              private transition: Transition) {
    this.surveyId = this.transition.params().id;
  }

  ngOnInit() {
    this.loadRightModels();
    this.translateService.get('MENU_NAVBAR_SURVEY_RECORD').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.SURVEY_DASHBOARD});
      }
    );
    this.loadSurveyName();
    this.loadSearch(() => {
      this.showSearch = !this.searchModel.isEmpty();
      this.initDisabledOptions(DisabledEnum.FALSE);
      this.loadList();
      this.loadUsers();
    });
  }

  private loadRightModels() {
    this.rightService.getRightResolver().subscribe(
      (resolver: RightResolver) => {
        this.rightModel = RightModel.of(resolver);
      }
    );
  }

  private loadSearch(completion: () => void) {
    const obs: Observable<SearchLoadResult> = combineLatest(
      this.surveyRecordSearchService.getSearchData({}),
      (storedSearchData: SurveyRecordSearch.SearchDataResult) => {
        const result: SearchLoadResult = {
          storedSearchData: storedSearchData
        };
        return result;
      }
    );
    obs.subscribe(
      (result: SearchLoadResult) => {
        this.searchResult = result.storedSearchData;
        this.postInitSearch(result.storedSearchData);
        completion();
      }
    );
  }

  private postInitSearch(storedSearchData: SurveyRecordSearch.SearchDataResult) {
    this.queryModel.itemsPerPage = storedSearchData.searchData.itemsPerPage;
    this.queryModel.currentPage = storedSearchData.searchData.pageNumber;
    this.queryModel.setOrder(storedSearchData.searchData.order);
    this.searchModel.ownerUser = storedSearchData.searchData.ownerUser;
    this.searchModel.updateTimeFrom = Models.localDateToNgbDate(storedSearchData.searchData.updateTimeFrom);
    this.searchModel.updateTimeTo = Models.localDateToNgbDate(storedSearchData.searchData.updateTimeTo);
    this.searchModel.name = storedSearchData.searchData.name;
    this.searchModel.disabled = storedSearchData.searchData.disabled;
  }

  private initDisabledOptions(initValue: DisabledEnum) {
    this.disabledItems = [];
    const disabledEnums: DisabledEnum[] = [DisabledEnum.NONE, DisabledEnum.FALSE, DisabledEnum.TRUE];
    Arrays.iterateByIndex(disabledEnums, (key) => {
      const item = new DisabledItem();
      item.id = key;
      this.translateService.get('COMMON_VALUE_DISABLED_ENUM_' + key).subscribe(
        (text: string) => {
          item.text = text;
        }
      );
      this.disabledItems.push(item);
      if (!this.searchModel.disabled) {
        if (key === initValue) {
          this.searchModel.disabled = item;
        }
      }
    });
  }

  loadSurveyName() {
    this.surveyService.get({
      surveyId: this.surveyId,
    }).subscribe(
      (result: Survey.Survey) => {
        this.breadcrumbSelf = result.name;
      }
    )
  }

  pageChanged(selectedPage: number) {
    this.loadList(selectedPage);
  }

  itemsPerPageChanged(itemsPerPage: number) {
    this.queryModel.itemsPerPage = itemsPerPage;
    this.loadList(1);
  }

  orderBy(field: SurveyRecord.OrderField) {
    this.queryModel.onOrderFieldChanged(field);
    this.loadList(1);
  }

  setDisabled(event: any, surveyRecord: SurveyRecord.SurveyRecord, disabled: boolean) {
    this.surveyRecordService.setDisabled({
      surveyId: this.surveyId,
      surveyRecordId: surveyRecord.id,
      disabled: disabled
    }).subscribe(
      (result: SurveyRecord.SurveyRecord) => {
        this.loadList();
      },
      (error: any) => {
        this.loadList();
      }
    );
  }

  toggleSearch() {
    if (!this.searchModel.ownerUser) {
      this.searchModel.ownerUser = this.createDefaultType();
    }
    this.showSearch = !this.showSearch;
  }

  onSearchClicked() {
    this.loadList();
  }

  onSearchReset() {
    this.surveyRecordSearchService.resetSearchData({}).subscribe(
      (result) => {
        this.loadSearch(() => {
          this.showSearch = true;
          this.searchModel.ownerUser = this.createDefaultType();
          this.loadList(1);
        });
      }
    );
  }

  private saveSearch() {
    const request = {
      searchData: {
        itemsPerPage: this.queryModel.itemsPerPage,
        pageNumber: this.queryModel.currentPage,
        order: this.queryModel.getOrder(),
        name: this.searchModel.name,
        ownerUser: this.searchModel.ownerUser,
        updateTimeFrom: Models.ngbDateToLocalDate(this.searchModel.updateTimeFrom),
        updateTimeTo: Models.ngbDateToLocalDate(this.searchModel.updateTimeTo),
        disabled: this.searchModel.disabled
      }
    };
    this.surveyRecordSearchService.setSearchData(request).subscribe(
      (result) => {
      },
      (error) => {
      }
    );
  }

  private loadList(pageNumber?: number) {
    const requestedPage = pageNumber ? pageNumber : this.queryModel.currentPage;
    const order = this.queryModel.getOrder();
    const customerRecordIds: number[] = [];
    const disabled: boolean | undefined = !this.searchModel.disabled ||
    this.searchModel.disabled.id === DisabledEnum.NONE ?
      undefined : this.searchModel.disabled.id === DisabledEnum.TRUE;
    this.surveyRecordService.query({
      surveyId: this.surveyId,
      name: this.searchModel.name,
      ownerUserId: (this.searchModel.ownerUser && this.searchModel.ownerUser.id) ? this.searchModel.ownerUser.id : undefined,
      updateTimeFrom: this.searchModel.updateTimeFrom ? this.parseDateFrom(this.searchModel.updateTimeFrom) : undefined,
      updateTimeTo: this.searchModel.updateTimeTo ? this.parseDateTo(this.searchModel.updateTimeTo) : undefined,
      disabled: disabled,
      orders: Set.of(order),
      paging: {
        pageNumber: requestedPage,
        numberOfItems: this.queryModel.itemsPerPage
      }
    }).subscribe(
      (result: QueryResult<SurveyRecord.SurveyRecord>) => {
        this.surveyRecordList = result.items.toArray();
        this.surveyRecordList.forEach((surveyRecord) => {
          if (surveyRecord.customerRecordId) {
            customerRecordIds.push(surveyRecord.customerRecordId);
          }
        });
        this.loadCustomerRecords(customerRecordIds);
        this.queryModel.currentPage = requestedPage;
        this.queryModel.totalNumberOfItems = result.pagingResult.totalNumberOfItems;
        this.queryModel.currentNumberOfItems = result.pagingResult.currentNumberOfItems;
      }
    );
  }

  private loadCustomerRecords(ids: number[]) {
    const fields: string[] = ['id', 'name'];
    this.customerRecordService.globalQuery({
      customerRecordIdSet: Set.of(...ids),
      fields: Set.of(...fields)
    }).subscribe((result: QueryResult<CustomerRecord.CustomerRecord>) => {
      result.items.toArray().forEach((cr) => {
        this.customerRecords.push({
          id: cr.customerRecordId,
          text: cr.name
        })
      });
    });
  }

  private parseDateFrom(model: NgbDateStruct | null): OffsetDateTime | undefined {
    return !model ? undefined : Dates.createOffsetDateTime({
      year: model.year,
      month: model.month,
      day: model.day - 1,
      hour: 23,
      minute: 59,
      second: 59,
      milliseconds: 999
    });
  }

  private parseDateTo(model: NgbDateStruct | null): OffsetDateTime | undefined {
    return !model ? undefined : Dates.createOffsetDateTime({
      year: model.year,
      month: model.month,
      day: model.day + 1,
      hour: 0,
      minute: 0,
      second: 0,
      milliseconds: 0
    });
  }

  private createDefaultType(): TypeItem {
    const def = new TypeItem();
    def.id = null;
    def.text = '';
    this.translateService.get(StringKey.COMMON_VALUE_UNSELECTED).subscribe(
      (text: string) => {
        def.text = text;
      }
    );
    return def;
  }

  private loadUsers() {
    this.userDataLoader.loadAll(UserDataLoaderPermissionDeniedStrategy.MISS_ALL).subscribe(
      (users: UserData[]) => {
        const def = this.createDefaultType();
        this.ownerUsers = [];
        this.ownerUsers.push(def);
        this.searchModel.ownerUser = def;
        const items = this.ownerUserItemFactory.createAll(users);
        const sortedOwnerUsers: OwnerUserItem[] = items;

        Arrays.iterateByIndex(sortedOwnerUsers, (item) => {
          this.ownerUsers.push(item);
        });
        this.searchModel.ownerUser = this.searchResult.searchData.ownerUser;
      }
    );
  }

  private getUserName(ownerUserId: UserProfile): string {
    const user = this.ownerUsers.find(o => o.id === ownerUserId.id);
    return user ? user.text : this.createDefaultType().text;
  }

  private getCustomerRecordName(id: number): string {
    const filtered_records = this.customerRecords.filter(current_record => current_record.id === id);
    if (filtered_records === undefined || filtered_records.length === 0) {
      return '';
    }
    return filtered_records[0].text;
  }

  ngOnDestroy() {
    this.saveSearch();
  }

}

interface SearchLoadResult {
  storedSearchData: SurveyRecordSearch.SearchDataResult,
}

interface CustomerRecordItem extends OptionItem<number> {}
