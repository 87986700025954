/* eslint-disable */
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { RightModel } from '../../../../app.rights';
import { GrantedPermissionSetResolver, RightResolver, RightService } from '../../../../lib/right.service';
import { User, UserService } from '../../../../lib/user.service';
import { AbstractControl, FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { StringKey } from '../../../../app.string-keys';
import {
  DeviceItem,
  MultiselectOptionItem,
  OptionItem,
  OptionItems,
  OwnerUserItem,
  OwnerUserItemFactory,
  UiConstants,
  UserGroupItem
} from '../../../../util/core-utils';
import { TranslateService } from '@ngx-translate/core';
import { Angular2Multiselects } from '../../../../util/multiselect';
import {
  ForwardingNgFormRef,
  LocalFormGroupValidationErrors,
  OrderType,
  ResourceQueryResult,
  Services
} from '../../../../lib/util/services';
import { Task, TaskService } from '../../../../lib/task/task.service';
import { CalendarUtils } from '../../../../util/calendar/calendar-utils';
import { ConfigModel, CustomerAddressItem, TaskRecordImportanceItem } from '../../../../util/task-record-utils';
import { TaskRecord, TaskRecordService } from '../../../../lib/task/task-record.service';
import { TaskRecordBase } from '../task-record-base/task-record-base-view';
import { CustomerRecordFieldType, CustomerRecordItem } from '../../../../util/customer-record-utils';
import { TaskRecordCreateFieldErrorMap } from '../../../calendar/calendar.component';
import { FieldError, FieldErrors } from '../../../../lib/util/errors';
import { CustomerRecord, CustomerRecordContactLocation, CustomerRecordService } from '../../../../lib/customer/customer-record.service';
import { Address, AddressModel } from '../../../../lib/address';
import { merge, Observable, Subject } from 'rxjs';
import { List, Map, Set } from 'immutable';
import { Strings } from '../../../../lib/util/strings';
import { Arrays } from '../../../../lib/util/arrays';
import { UserGroup, UserGroupService } from '../../../../lib/user-group.service';
import { Device, DeviceManagementService } from '../../../../lib/device-management.service';
import { StateName } from '../../../../app.state-names';
import { AppNgbTimeStruct, NgbDatePickerParserFormatter } from '../../../../util/ngb-datepicker';
import { ToasterService } from '../../../../fork/angular2-toaster/angular2-toaster';
import { UIRouter } from '@uirouter/angular';
import { ConfigurationService } from '../../../../lib/core-ext/configuration.service';
import { IdentityMessage } from '../../../../lib/util/messages';
import { AppValidators } from '../../../../util/app-validators';
import { TaskRecordQuickCreateNameTemplate, TaskRightModel } from '../../../../util/task-utils';
import { TaskRecordStateMachine } from '../../../../lib/task/task-record-statemachine';
import { CustomerService } from '../../../../lib/customer/customer.service';
import { debounceTime, flatMap, map } from 'rxjs/operators';
import { CountryService } from '../../../../lib/country.service';
import { ProjectRecordMultiselectProvider } from '../../../../lib/project/record/project-record-multiselect.provider';
import { OperationRights } from '../../../../app.right-definitions';
import { TaskMultiselectOptionItem, TaskMultiselectProvider } from '../../../../lib/task/task-multiselect.provider';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import {
  CustomerRecordQuickCreateMaterialDialogComponent
} from '../../../customer/customer-record-quick-create-material/customer-record-quick-create-material-dialog.component';
import AssigneeModel = TaskRecordBase.AssigneeModel;
import ComplexTimeModel = TaskRecordBase.ComplexTimeModel;
import LocalCalendarEvent = CalendarUtils.LocalCalendarEvent;
import PlaceOfConsumptionModel = TaskRecordBase.PlaceOfConsumptionModel;
import PlaceOfConsumptionRequirement = Task.PlaceOfConsumptionRequirement;
import PostalAddressModelType = AddressModel.PostalAddressModelType;
import TaskRecordField = TaskRecord.TaskRecordField;
import TaskDefaultAssigneeType = Task.TaskDefaultAssigneeType;

/* eslint-enable */

@Component({
  selector: 'app-task-record-create-material-dialog',
  templateUrl: './task-record-create-material-dialog.component.html',
  styleUrls: ['./task-record-create-material-dialog.component.scss']
})
export class TaskRecordCreateMaterialDialogComponent implements OnInit {

  UiConstants = UiConstants;
  TaskRecordCreateDialogMode = TaskRecordCreateDialogMode;
  TaskRecordQuickCreateNameTemplate = TaskRecordQuickCreateNameTemplate;
  POCRequirement = Task.PlaceOfConsumptionRequirement;

  @ViewChild('f') form: NgForm;

  formGroup: FormGroup;

  mode?: TaskRecordCreateDialogMode;
  private creatorUserId: number | undefined;

  get calendarEvent(): boolean {
    return this.mode === TaskRecordCreateDialogMode.CALENDAR;
  }

  rightModel: RightModel = RightModel.empty();

  customerDropdownSettings: Angular2Multiselects.Settings;
  offlineDropdownSettings: Angular2Multiselects.Settings;
  billingInfoDropdownSettings: Angular2Multiselects.Settings;
  assigneeDropdownSettings: Angular2Multiselects.Settings;
  pocDropdownSettings: Angular2Multiselects.Settings;

  tasksForCreate: TaskMultiselectOptionItem[] = [];

  createModel: TaskRecordCreateModel;
  taskImportances: TaskRecordImportanceItem[] = [];
  availableStates: TaskRecordStateMachine.StateObject[] = [];
  private allAvailableStates: TaskRecordStateMachine.StateObject[] = [];

  customers: CustomerRecordItem[] = [];
  billingInfos: MultiselectOptionItem<number>[] = [];

  projects: MultiselectOptionItem<number>[] = [];

  taskRecordCreateFieldErrors: TaskRecordCreateFieldErrorMap;
  private formGroupValidationErrors: LocalFormGroupValidationErrors;

  config: ConfigModel = new ConfigModel();

  userGroups: UserGroupItem[] = [];
  users: OwnerUserItem[] = [];
  devices: DeviceItem[] = [];
  _selectedAddress: CustomerAddressItem[];
  get selectedAddress(): CustomerAddressItem {
    if (this._selectedAddress.length === 0) {
      return this.loadNoneAddress();
    }
    return this._selectedAddress[0];
  }

  set selectedAddress(value: CustomerAddressItem) {
    this._selectedAddress = [];
    this._selectedAddress.push(value);
  }

  selectableAddresses: CustomerAddressItem[] = [];
  countryItems: List<AddressModel.CountryItem>;

  refresh: Subject<any> = new Subject();
  creationInProgress: boolean;

  private readonly formControlClickEvents: Map<string, Subject<void>> = Map.of('name', new Subject());
  nameOptions$: Observable<string[]>;

  get task(): TaskMultiselectOptionItem | undefined {
    return this.createModel && this.createModel.task.length === 1 ? this.createModel.task[0] : undefined;
  }

  get nameRequired(): boolean {
    if (this.data.nameOptional === undefined) {
      if (!this.task) {
        return true;
      }
      return this.task.taskRecordNameTemplate === undefined;
    }
    return !this.data.nameOptional;
  }

  get nameRequiredByProcess(): boolean {
    if (this.data.nameOptional === undefined) {
      return true;
    }
    return !this.data.nameOptional;
  }

  get nameRequiredByTask(): boolean {
    if (!this.task) {
      return true;
    }
    return this.task.taskRecordNameTemplate === undefined;
  }

  constructor(
    private translateService: TranslateService,
    private rightService: RightService,
    private userService: UserService,
    private formBuilder: FormBuilder,
    private taskService: TaskService,
    private configService: ConfigurationService,
    private customerRecordService: CustomerRecordService,
    private customerService: CustomerService,
    private ownerUserItemFactory: OwnerUserItemFactory,
    private userGroupService: UserGroupService,
    private deviceManagementService: DeviceManagementService,
    private taskRecordService: TaskRecordService,
    private datePickerParserFormatter: NgbDatePickerParserFormatter,
    private uiRouter: UIRouter,
    private toasterService: ToasterService,
    private projectRecordMultiselectProvider: ProjectRecordMultiselectProvider,
    private taskMultiselectProvider: TaskMultiselectProvider,
    private countryService: CountryService,
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<TaskRecordCreateMaterialDialogComponent, TaskRecordCreateDialogResult>,
    @Inject(MAT_DIALOG_DATA) public data: TaskRecordCreateDialogData
  ) {
    this.formGroup = this.createFormGroup(formBuilder);
    this.formGroupValidationErrors = LocalFormGroupValidationErrors.ofForm(
      this.createForwardingHtmlForm(),
      this.formGroup
    );

    this.taskRecordCreateFieldErrors = {};
    const nameClickEvent: Observable<void> = this.formControlClickEvents.get('name').asObservable();
    const nameChangeEvent: Observable<any> = this.formGroup.controls['name'].valueChanges;
    this.nameOptions$ = merge(nameClickEvent, nameChangeEvent).pipe(
      debounceTime(UiConstants.autocompleteDebounceTime),
      flatMap(
        (value) => {
          return this.taskRecordService.queryTaskRecordNames({
            name: value,
            numberOfItems: UiConstants.autocompletePageSize,
          }).pipe(map((result) => {
            return result;
          }));
        }
      )
    );

    this.selectedAddress = this.loadNoneAddress();
  }

  public static openDialog(
    dialog: MatDialog,
    data: TaskRecordCreateDialogData,
    resultCallback: (result?: TaskRecordCreateDialogResult) => void) {
    const dialogRef = dialog.open(TaskRecordCreateMaterialDialogComponent, {
      closeOnNavigation: true,
      data: data,
      width: '60%',
      height: 'auto',
      panelClass: 'custom-dialog-container'
    });
    dialogRef.afterClosed().subscribe((result: TaskRecordCreateDialogResult) => {
      resultCallback(result);
    });
  }


  ngOnInit() {
    this.loadTaskImportances();
    this.loadTaskStates();
    this.initDropdownSettings();
    this.loadConfig();
    this.loadCountryItems();
    this.formGroup.reset();
    this.initData();
  }

  initData() {
    this.createModel = new TaskRecordCreateModel(this.taskImportances[0]);
    this.mode = this.data.mode;
    this.loadRightModels(() => {
      this.loadTasks(true);
      this.getCustomers([]);
      this.loadProjects();
    });
    if (this.data.date) {
      this.createModel.agreedTimeDate = {
        year: this.data.date.date.getUTCFullYear(),
        month: this.data.date.date.getMonth() + 1,
        day: this.data.date.date.getDate()
      };
      this.createModel.agreedTimeTime = {
        hour: this.data.date.date.getHours(),
        minute: this.data.date.date.getMinutes(),
        second: this.data.date.date.getSeconds(),
        millis: 0
      };
    }
    else {
      this.createModel.agreedTimeTime = {
        hour: 8,
        minute: 0,
        second: 0,
        millis: 0
      };
    }

    this.createModel.deadlineTimeTime = {
      hour: 8,
      minute: 0,
      second: 0,
      millis: 0
    };

    if (this.data.customerRecordItem) {
      this.createModel.customerM.push(this.data.customerRecordItem);
      this.onCustomerChanged();
    }
    else if (this.data.customerRecordIds) {
      this.createModel.customerRecordIds = this.data.customerRecordIds;
    }
    else {
      this.onCustomerChanged();
    }
    if (this.data.event) {
      this.onCalendarEventClicked(this.data.event);
    }
  }

  closeDialog(success: boolean, request?: TaskRecord.QuickCreateRequest) {
    this.dialogRef.close({success: success, request: request});
  }

  private loadRightModels(completion: () => void) {
    this.rightService.getRightResolver().subscribe(
      (resolver: RightResolver) => {
        this.creatorUserId = resolver.userProfile?.id;
        this.rightModel = RightModel.of(resolver);
        completion();
      }
    );
  }

  onTaskChanged() {
    if (this.createModel.selectedTask) {
      this.createModel.state = this.createModel.selectedTask.defaultState;
      if (this.createModel.selectedTask.defaultStateForceNew) {
        this.availableStates = this.allAvailableStates.filter(s => s.state === 'NEW');
      }
      else {
        this.availableStates = this.allAvailableStates;
      }
    }
    this.updateEstimatedTime(this.createModel.selectedTask);
    this.formGroup.get('billingInfo')!.updateValueAndValidity();
    this.loadAssigneeModels(this.createModel.selectedTask);
    this.onStateChanged();
  }

  updateEstimatedTime(selectedTask: undefined | TaskMultiselectOptionItem) {
    if (selectedTask && selectedTask.defaultEstimatedTimeInMinutes) {
      this.createModel.estimatedTime = TaskRecordBase.ComplexTimeModel.minutesToComplexTime(
        this.createModel.task[0].defaultEstimatedTimeInMinutes!);
    }
  }

  private loadTaskImportances() {
    this.taskImportances = [];
    TaskRecord.taskRecordImportances.forEach((importance) => {
      const item = {
        id: importance.importance,
        text: '...'
      };
      this.taskImportances.push(item);
      this.translateService.get(importance.stringKey).subscribe((text: string) => {
        item.text = text;
      });
    });
  }

  private loadTaskStates() {
    this.allAvailableStates = [];
    this.allAvailableStates.push(TaskRecordStateMachine.taskRecordStates.get('NEW'));
    this.allAvailableStates.push(TaskRecordStateMachine.taskRecordStates.get('OPEN'));
  }

  private loadConfig() {
    this.config = this.configService.getConfigurationModel();
  }

  private loadCountryItems() {
    this.countryService.query({}).subscribe(result => {
      this.countryItems = AddressModel.CountryItem.fromCountryList(result.items);
    });
  }

  loadTasks(init?: boolean) {
    if (this.rightModel.taskRead.hasRight()) {
      this.taskService.query({
        taskIdSet: this.data.taskId ? Set.of(this.data.taskId) : undefined,
        disabled: false,
        rights: Set.of(OperationRights.TASK_RECORD_CREATE),
        hasDefaultEstimatedTime: this.mode === TaskRecordCreateDialogMode.CALENDAR ? true : undefined
      }).subscribe(result => {
        this.tasksForCreate = [];
        result.items.forEach(t => {
          const task = this.taskMultiselectProvider.toMultiselectOptionItem(t!);
          const taskGrantedRights = new TaskRightModel(GrantedPermissionSetResolver.byGrantedRights(task.grantedRights));

          if (this.data.taskId) {
            this.tasksForCreate.push(task);
          }
          if (this.mode === TaskRecordCreateDialogMode.CALENDAR) {
            if (taskGrantedRights.taskRecordCreate.hasRight()
              && task.defaultEstimatedTimeInMinutes !== undefined) {
              this.tasksForCreate.push(task);
            }
          }
          if (this.mode === TaskRecordCreateDialogMode.QUICK_CREATE) {
            if (taskGrantedRights.taskRecordCreate.hasRight()) {
              this.tasksForCreate.push(task);
            }
          }
          if (this.mode === TaskRecordCreateDialogMode.QUICK_CREATE_BY_CUSTOMER_RECORDS) {
            if (taskGrantedRights.taskRecordCreate.hasRight()) {
              this.tasksForCreate.push(task);
            }
          }

        });
        if (init && this.tasksForCreate.length === 1) {
          this.createModel.task = this.tasksForCreate;
          this.onTaskChanged();
        }
      });
    }
  }

  loadAssigneeModels(task?: TaskMultiselectOptionItem) {
    if (this.rightModel.userGroupRead.hasRight()) {
      this.loadUserGroups();
    }
    if (this.config.assigneeWithUser) {
      this.loadUsers(undefined, task && task.webCreateDefaultAssigneeType === TaskDefaultAssigneeType.CREATOR);
    }
    if (this.config.assigneeWithMobileApp) {
      if (this.rightModel.mobileAppRead.hasRight()) {
        this.loadDevices();
      }
    }
  }

  filterUsers(q?: string) {
    this.loadUsers(q);
  }

  filterUserGroups(q?: string) {
    const ids: number[] = [];
    if (this.createModel.selectedTask) {
      ids.push(...this.createModel.selectedTask.enabledAssignees.userGroups.toArray());
    }
    this.loadUserGroups(q, ids.length > 0 ? ids : undefined);
  }

  private loadUsers(q?: string, setCreator?: boolean) {
    const groupIds: number[] = this.createModel.selectedUserGroup && this.createModel.selectedUserGroup.id
      ? [this.createModel.selectedUserGroup.id]
      : [];

    if (groupIds.length === 0 && this.createModel.selectedTask) {
      groupIds.push(...this.createModel.selectedTask.enabledAssignees.userGroups.toArray());
    }

    if (setCreator) {
      this.userService.get({id: this.creatorUserId!}).subscribe(me => {
        this.createModel.assignee.load(this.ownerUserItemFactory.create(me));
      })
    }

    this.userService.query({
      q: Strings.undefinedOrNonEmpty(q),
      user_group_ids: groupIds.length > 0 ? groupIds.join(',') : undefined,
      fields: 'id,user_name,person_name',
      disabled: false,
      number_of_items: UiConstants.autocompletePageSize,
      page_number: 1,
      order: Services.createOrderFieldParameter(User.Keys.toOrderFieldKey, Set.of(User.DEFAULT_ORDER)),
      no_progress_bar: true,
    }).subscribe(result => {
      this.users = [];
      const items = this.ownerUserItemFactory.createAll(result.items);
      const sortedOwnerUsers: OwnerUserItem[] = items;
      sortedOwnerUsers.sort(OptionItems.createNameComparator());
      Arrays.iterateByIndex(sortedOwnerUsers, (item) => {
        this.users.push(item);
      });
    });
  }

  private loadUserGroups(q?: string, ids?: number[]) {
    if (!this.rightModel.userGroupRead.hasRight()) {
      return;
    }
    this.userGroupService.query({
      order: Services.createOrderFieldParameter(UserGroup.Keys.toOrderFieldKey, Set.of(UserGroup.DEFAULT_ORDER)),
      name: Strings.undefinedOrNonEmpty(q),
      disabled: false,
      number_of_items: UiConstants.autocompletePageSize,
      id: ids ? ids.join(',') : undefined,
      page_number: 1,
      no_progress_bar: true
    }).subscribe((result: ResourceQueryResult<UserGroup>) => {
      this.userGroups = [];
      result.items.forEach((group) => {
        const item = {
          id: group.id,
          text: group.name
        };
        this.userGroups.push(item);
      });
    });
  }

  private loadDevices() {
    if (!this.rightModel.mobileAppRead.hasRight()) {
      return;
    }
    let observable: Observable<ResourceQueryResult<Device>>;
    observable = this.deviceManagementService.query({
      disabled: false,
    });
    observable.subscribe((devices: ResourceQueryResult<Device>) => {
      this.devices = [];
      devices.items.forEach((device) => {
        const item = {
          id: device.id,
          text: device.name ? device.name : device.application_id
        };
        this.devices.push(item);
      });
    });
  }

  getCustomers(custIds: number[], q?: any) {
    if (!this.rightModel.customerRecordRead.hasRight()) {
      return;
    }
    this.queryCustomers(custIds, q).subscribe((result: CustomerRecord.CustomerRecord[]) => {
      this.customers = [];
      result.forEach((customerRecord) => {
        if (customerRecord) {
          const item = this.parseCustomer(customerRecord);
          this.customers.push(item);
        }
      });
    });
  }

  private queryCustomers(custIds: number[], q?: any): Observable<CustomerRecord.CustomerRecord[]> {
    return this.customerRecordService.globalQuery({
      withFormRecord: false,
      customerRecordIdSet: Set.of(...custIds),
      fields: Set.of('id', 'name', 'postal_address', 'customer_id', 'customer'),
      contactPersonType: false,
      orders: Set.of({type: OrderType.ASC, field: CustomerRecord.OrderField.NAME}),
      paging: {
        numberOfItems: UiConstants.autocompletePageSize,
        pageNumber: 1
      },
      disabled: false,
      queryText: q ? Strings.undefinedOrNonEmpty(q.target.value) : undefined,
      noProgressBar: true
    }).pipe(map(result => result.items.toArray()))
  }

  loadProjects(q?: string) {
    if (!this.rightModel.projectRecordRead.hasRight()) {
      return;
    }
    this.projectRecordMultiselectProvider.loadActive(q).subscribe(result => {
      this.projects = result;
    });
  }

  private initDropdownSettings() {
    this.customerDropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(true)
      .enableSearchFilter(true)
      .enableCheckAll(false)
      .remoteSearch(true)
      .autoPosition(true)
      .build();
    this.offlineDropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(true)
      .enableSearchFilter(true)
      .enableCheckAll(false)
      .remoteSearch(false)
      .searchBy(['itemName'])
      .autoPosition(true)
      .build();
    this.billingInfoDropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(true)
      .enableSearchFilter(true)
      .enableCheckAll(false)
      .autoPosition(true)
      .build();
    this.assigneeDropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(true)
      .enableSearchFilter(true)
      .enableCheckAll(false)
      .labelKey(OptionItem.KEY_TEXT)
      .remoteSearch(true)
      .autoPosition(true)
      .build();
    this.pocDropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(true)
      .enableSearchFilter(true)
      .enableCheckAll(false)
      .remoteSearch(false)
      .primaryKey('itemName')
      .searchBy(['itemName', 'itemSubtitle'])
      .autoPosition(true)
      .build();
  }

  removeFieldError(fieldError?: FieldError) {
    FieldErrors.remove(this.taskRecordCreateFieldErrors, fieldError);
  }

  onCalendarEventClicked(event: { event: LocalCalendarEvent }) {
    if (event.event.type === 'AVAILABLE') {
      if (!this.rightModel.calendarEventCreate.hasRight()) {
        return;
      }
      this.createModel.agreedTimeDate = {
        year: event.event.start.getUTCFullYear(),
        month: event.event.start.getMonth() + 1,
        day: event.event.start.getDate()
      };
      this.createModel.agreedTimeTime = {
        hour: event.event.start.getHours(),
        minute: event.event.start.getMinutes(),
        second: event.event.start.getSeconds(),
        millis: 0
      };
      if (this.config.assigneeWithUser && event.event.owner && event.event.owner.userId) {
        const userItem = this.users.find((u) => u.id === event.event.owner!.userId);
        if (userItem) {
          this.createModel.assignee._user = [];
          this.createModel.assignee._user.push(userItem);
        }
      }
      if (this.config.assigneeWithMobileApp && event.event.owner && event.event.owner.mobileAppId) {
        const deviceItem = this.users.find((d) => d.id === event.event.owner!.mobileAppId);
        if (deviceItem) {
          this.createModel.assignee._device = [];
          this.createModel.assignee._device.push(deviceItem);
        }
      }
    }
  }

  loadSelectableAddresses(customerRecord?: CustomerRecord.CustomerRecord, addressManaged?: boolean) {
    this.selectableAddresses = [];
    const def = this.loadNoneAddress();
    const newAddress = this.loadNewSelectableAddress();
    this.selectableAddresses.push(def);
    this.selectedAddress = def;
    if (customerRecord) {
      this.translateService.get('CUSTOMER_RECORD_MAIN_ADDRESS_TEXT').subscribe((value: string) => {
        const mainAddress: Address.PostalAddressData | undefined = customerRecord.postalAddress;
        if (mainAddress) {
          const mainAddressItem: CustomerAddressItem = {
            id: mainAddress.id ? mainAddress.id : null,
            itemName: value,
            itemSubtitle: Address.PostalAddressMapper.toString(mainAddress, this.config.postalAddressFormat),
            postalAddress: mainAddress
          };
          this.selectableAddresses.push(mainAddressItem);
          if (this.createModel.placeOfConsumption.address.id === mainAddressItem.id) {
            this.selectedAddress = mainAddressItem;
            this.createModel.placeOfConsumption.loadPostalAddressData(
              this.selectedAddress.postalAddress,
              this.configService.getDefaultSelectedCountryCode(),
              this.countryItems
            );
          }
        }
      });
      if (this.configService.isCustomerRecordManagedField(CustomerRecordFieldType.CONTACT_LOCATIONS)) {
        this.customerRecordService.queryContactLocations({
          customerId: customerRecord.customerId,
          customerRecordId: customerRecord.customerRecordId,
          noProgressBar: true,
          orders: Set.of({type: OrderType.ASC, field: CustomerRecordContactLocation.OrderField.NAME})
        }).subscribe(result => {
          result.items.forEach((l) => {
            if (l && l.postalAddress) {
              const addressItem: CustomerAddressItem = {
                id: l.postalAddress.id ? l.postalAddress.id : null,
                itemName: l.name,
                itemSubtitle: Address.PostalAddressMapper.toString(l.postalAddress, this.config.postalAddressFormat),
                postalAddress: l.postalAddress,
                contactLocationId: l.id
              };
              this.selectableAddresses.push(addressItem);
              if (this.createModel.placeOfConsumption.address.id === addressItem.id) {
                this.selectedAddress = addressItem;
                this.createModel.contactLocationId = addressItem.contactLocationId;
                this.createModel.placeOfConsumption.loadPostalAddressData(
                  this.selectedAddress.postalAddress,
                  this.configService.getDefaultSelectedCountryCode(),
                  this.countryItems
                );
              }
            }
          });
        });
        this.selectableAddresses.push(newAddress);
        if (this.selectedAddress === def && this.createModel.placeOfConsumption.address.id) {
          this.selectedAddress = newAddress;
          this.createModel.placeOfConsumption.reset();
        }
      }
      else {
        this.selectableAddresses.push(newAddress);
        if (this.selectedAddress === def && this.createModel.placeOfConsumption.address.id) {
          this.selectedAddress = newAddress;
          this.createModel.placeOfConsumption.reset();
        }
      }
    }
    if (!customerRecord || !addressManaged) {
      if (!this.selectableAddresses.find(a => a === newAddress)) {
        this.selectableAddresses.push(newAddress);
      }
    }

  }

  loadNoneAddress(): CustomerAddressItem {
    return {id: null, itemName: this.translateService.instant(StringKey.TASK_RECORD_POC_ADDRESS_NONE)};
  }

  loadNewSelectableAddress(): CustomerAddressItem {
    return {id: null, itemName: this.translateService.instant(StringKey.TASK_RECORD_POC_ADDRESS_NEW)};
  }

  onSelectedAddressChanged() {
    const newAddress = this.loadNewSelectableAddress();
    if (this.selectedAddress.id) {
      this.createModel.contactLocationId = this.selectedAddress.contactLocationId;
      this.createModel.placeOfConsumption.loadPostalAddressData(
        this.selectedAddress.postalAddress,
        this.configService.getDefaultSelectedCountryCode(),
        this.countryItems
      );
    }
    else if (this.selectedAddress.id === newAddress.id
      && this.selectedAddress.itemName === newAddress.itemName
      && this.selectedAddress.itemSubtitle === newAddress.itemSubtitle) {
      this.createModel.placeOfConsumption.loadCountryItems(this.configService.getDefaultSelectedCountryCode(), this.countryItems);
      this.createModel.contactLocationId = undefined;
      this.createModel.placeOfConsumption.reset();
      this.createModel.placeOfConsumption.latitude = undefined;
      this.createModel.placeOfConsumption.longitude = undefined;
      this.createModel.placeOfConsumption.address.type = AddressModel.PostalAddressModelType.NONE;
      const thisComponent = this;
      setTimeout(function () {
        thisComponent.createModel.placeOfConsumption.address.type = AddressModel.PostalAddressModelType.COMPLEX;
      }, 0);
    }
    else {
      this.createModel.contactLocationId = undefined;
      this.createModel.placeOfConsumption.reset();
      this.createModel.placeOfConsumption.latitude = undefined;
      this.createModel.placeOfConsumption.longitude = undefined;
      this.createModel.placeOfConsumption.address.type = AddressModel.PostalAddressModelType.NONE;
    }
  }

  showPOCAddress(): boolean {
    if (this.createModel.placeOfConsumption) {
      return this.createModel.placeOfConsumption.address.type !== PostalAddressModelType.NONE;
    }
    return false;
  }

  private loadBillingInfos() {
    this.customerRecordService.listBillingInfo({
      customerId: this.createModel.customer!.customerRecord!.customerId,
      customerRecordId: this.createModel.customer!.customerRecord!.customerRecordId,
      disabled: false
    }).subscribe(result => {
      this.billingInfos = result.map(i => (
        {
          id: i.id,
          itemName: i.name,
          itemSubtitle: this.createBillingInfoSubtitle(i)
        }));
    });
  }

  private createBillingInfoSubtitle(info: CustomerRecord.BillingInfo) {
    switch (info.invoiceVatStatus) {
      case CustomerRecord.InvoiceVatStatus.PRIVATE_PERSON:
        return this.translateService.instant('INVOICE_VAT_STATUS_PRIVATE_PERSON');
      case CustomerRecord.InvoiceVatStatus.DOMESTIC:
        return this.translateService.instant('INVOICE_VAT_STATUS_DOMESTIC') + ' / ' + info.taxNumber;
      case CustomerRecord.InvoiceVatStatus.OTHER:
        return this.translateService.instant('INVOICE_VAT_STATUS_OTHER') + ' / ' + info.euTaxNumber;
    }
  }

  onCustomerChanged() {
    if (!this.createModel.customer) {
      this.loadSelectableAddresses();
      this.createModel.placeOfConsumption.reset();
      this.createModel.billingInfo = [];
    }
    else {
      this.loadSelectableAddresses(this.createModel.customer.customerRecord, !!this.createModel.customer.address);
      this.loadBillingInfos();
    }
    this.onSelectedAddressChanged();
  }

  onStateChanged() {
    this.formGroup.controls['userGroup'].updateValueAndValidity();
    this.formGroup.controls['user'].updateValueAndValidity();
    this.formGroup.controls['device'].updateValueAndValidity();
  }

  onUserGroupChanged() {
    this.formGroup.controls['user'].updateValueAndValidity();
    this.formGroup.controls['device'].updateValueAndValidity();
  }

  onAssigneeChanged() {
    this.formGroup.controls['userGroup'].updateValueAndValidity();
  }

  createTaskRecord() {
    if (this.hasLocalFieldError()) {
      return;
    }
    if (this.createModel.placeOfConsumption.address.type === PostalAddressModelType.COMPLEX
      && this.createModel.placeOfConsumption.address.id === undefined
      && !this.createModel.placeOfConsumption.address.valid) {
      return;
    }
    if (!this.validatePlaceOfConsumption()) {
      return;
    }
    if (this.mode === TaskRecordCreateDialogMode.REQUEST_ONLY) {
      this.closeDialog(true, this.createRequest());
    }
    else if (this.mode !== TaskRecordCreateDialogMode.QUICK_CREATE_BY_CUSTOMER_RECORDS) {
      this.quickCreate();
    }
    else {
      this.quickCreateByCustomerRecords();
    }
  }

  validatePlaceOfConsumption(): boolean {
    const requirement: Task.PlaceOfConsumptionRequirement | undefined = this.createModel.placeOfConsumptionRequirement;
    if (requirement === PlaceOfConsumptionRequirement.CONTACT_LOCATION_REQUIRED) {
      if (!this.createModel.contactLocationId) {
        this.showValidationErrorToast(StringKey.TASK_RECORD_PLACE_OF_CONSUMPTION_CONTACT_LOCATION_REQUIRED);
        return false;
      }
      return true;
    }
    if (this.mode === TaskRecordCreateDialogMode.QUICK_CREATE_BY_CUSTOMER_RECORDS) {
      return true;
    }
    if (requirement === PlaceOfConsumptionRequirement.REQUIRED) {
      if (!this.selectedAddress.id && this.createModel.placeOfConsumption.address.type === PostalAddressModelType.NONE) {
        this.showValidationErrorToast(StringKey.TASK_RECORD_PLACE_OF_CONSUMPTION_REQUIRED);
        return false;
      }
      return true;
    }
    return true;
  }

  private showValidationErrorToast(body?: string) {
    this.toasterService.pop({
      timeout: UiConstants.ToastTimeoutLong,
      type: UiConstants.toastTypeError,
      title: this.translateService.instant(StringKey.COMMON_FORM_VALIDATION_ERROR_TOAST_TITLE),
      body: this.translateService.instant(body ? body : StringKey.COMMON_FORM_VALIDATION_ERROR_TOAST_MESSAGE)
    });
  }

  private quickCreate() {
    const taskId = this.createModel.task[0]!.id;
    this.creationInProgress = true;
    this.taskRecordService.quickCreate(this.createRequest()).subscribe((record: IdentityMessage) => {
        this.refresh.next();
        this.creationInProgress = false;
        this.closeDialog(true);
        if (this.data.navigateToTaskRecordEdit) {
          this.uiRouter.stateService.go(StateName.TASK_RECORD_EDIT, {taskId: taskId, taskRecordId: record.id});
        }
      },
      () => {
        this.creationInProgress = false;
        this.toasterService.pop({
          timeout: UiConstants.ToastTimeoutLong,
          type: UiConstants.toastTypeError,
          title: this.translateService.instant(StringKey.COMMON_ERROR_DIALOG_TITLE),
          body: this.translateService.instant(StringKey.COMMON_ERROR_DIALOG_MESSAGE)
        });
      });
  }

  private quickCreateByCustomerRecords() {
    const taskId = this.createModel.task[0]!.id;
    const assignee: TaskRecord.AssigneeChangeRequest | undefined =
      (!this.createModel.assignee.user && !this.createModel.assignee.device)
        ? undefined
        : {
          userId: this.createModel.assignee.user && this.createModel.assignee.user.id ?
            this.createModel.assignee.user.id : undefined,
          mobileApplicationId: this.createModel.assignee.device && this.createModel.assignee.device.id ?
            this.createModel.assignee.device.id : undefined,
        };
    const importance = this.createModel.importance && this.createModel.importance.id ?
      this.createModel.importance.id : TaskRecord.DefImportance;

    this.creationInProgress = true;
    this.taskRecordService.quickCreateByCustomerRecords({
      taskId: taskId!,
      nameTemplate: this.createModel.nameTemplate,
      importance: importance,
      state: this.createModel.state,
      description: this.createModel.description,
      customerIds: this.createModel.customerRecordIds,
      projectId: this.createModel.projectId,
      userGroupId: this.createModel.userGroup.length > 0 && this.createModel.userGroup[0].id ?
        this.createModel.userGroup[0].id! : undefined,
      assignee: assignee,
      agreedTime: this.datePickerParserFormatter.toOffsetDateTime(this.createModel.agreedTimeDate, this.createModel.agreedTimeTime),
      deadline: this.datePickerParserFormatter.toOffsetDateTime(this.createModel.deadlineTimeDate, this.createModel.deadlineTimeTime),
      estimatedTimeInMinutes: this.createModel.estimatedTime.toMinutes()
    }).subscribe((result: IdentityMessage[]) => {
        this.refresh.next();
        this.creationInProgress = false;
        this.closeDialog(true);
        this.toasterService.pop({
          timeout: UiConstants.ToastTimeoutShort,
          type: UiConstants.toastTypeSuccess,
          title: this.translateService.instant(StringKey.COMMON_SUCCESS),
          body: this.translateService.instant(
            StringKey.TASK_RECORD_QUICK_CREATE_BATCH_CUSTOMER_RECORD_SUCCESS,
            {number: this.createModel.customerRecordIds.length})
        });
      },
      () => {
        this.creationInProgress = false;
        this.toasterService.pop({
          timeout: UiConstants.ToastTimeoutLong,
          type: UiConstants.toastTypeError,
          title: this.translateService.instant(StringKey.COMMON_ERROR_DIALOG_TITLE),
          body: this.translateService.instant(StringKey.COMMON_ERROR_DIALOG_MESSAGE)
        });
      });
  }

  onAutoCompleteClick(formControlName: string) {
    const eventEmitter: Subject<void> = this.formControlClickEvents.get(formControlName);
    const c: AbstractControl = this.formGroup.controls[formControlName];
    const value: string = c.value;
    if (value.length === 0) {
      eventEmitter.next();
    }
  }

  private createForwardingHtmlForm() {
    return new ForwardingNgFormRef({
      formFn: () => {
        return this.form;
      }
    });
  }

  hasLocalFieldError(formControlName?: string, errorCode?: string): boolean {
    return this.formGroupValidationErrors.hasFieldError(formControlName, errorCode);
  }

  private createFormGroup(fb: FormBuilder): FormGroup {
    return fb.group({
      task: [
        [],
        Validators.required
      ],
      name: [
        [],
        AppValidators.tempValidator({
          validator: AppValidators.required({
            disabled: () => {
              return !this.nameRequired;
            }
          }),
          disabled: () => {
            return this.mode === TaskRecordCreateDialogMode.QUICK_CREATE_BY_CUSTOMER_RECORDS;
          }
        })
      ],
      nameTemplate: [
        [],
        AppValidators.tempValidator({
          validator: AppValidators.required({
            disabled: () => {
              return this.nameRequired;
            }
          }),
          disabled: () => {
            return this.mode !== TaskRecordCreateDialogMode.QUICK_CREATE_BY_CUSTOMER_RECORDS;
          }
        })
      ],
      billingInfo: [
        [],
        [
          AppValidators.validateEnabledItems,
          AppValidators.required({
            disabled: () => {
              return !!this.task && !this.task.autoInvoiceGenerate;
            }
          })
        ]
      ],
      user: [
        [],
        AppValidators.tempValidator({
          validator: Validators.required,
          disabled: () => {
            return !this.isUserRequired();
          }
        })
      ],
      userGroup: [
        [],
        AppValidators.tempValidator({
          validator: Validators.required,
          disabled: () => {
            return !this.isUserGroupRequired();
          }
        })
      ],
      device: [
        [],
        AppValidators.tempValidator({
          validator: Validators.required,
          disabled: () => {
            return !this.isDeviceRequired();
          }
        })
      ],
      agreedDatePicker: [
        [],
        AppValidators.tempValidator({
          validator: Validators.required,
          disabled: () => {
            return !this.isManagedField('AGREED_TIME') || (!this.isRequiredField('AGREED_TIME')
              && !this.calendarEvent);
          }
        })
      ],
      agreedTimePicker: [
        [],
        AppValidators.tempValidator({
          validator: Validators.required,
          disabled: () => {
            return !this.isManagedField('AGREED_TIME') || (!this.isRequiredField('AGREED_TIME')
              && !this.calendarEvent);
          }
        })
      ],
      deadlineDatePicker: [
        [],
        AppValidators.tempValidator({
          validator: Validators.required,
          disabled: () => {
            return !this.isRequiredField('DEADLINE');
          }
        })
      ],
      deadlineTimePicker: [
        [],
        AppValidators.tempValidator({
          validator: Validators.required,
          disabled: () => {
            return !this.isRequiredField('DEADLINE');
          }
        })
      ],
      estimatedTimeHours: [
        {
          value: this.createModel && this.createModel.estimatedTime.hours !== null
            ? this.createModel.estimatedTime.hours
            : null
        },
        [
          AppValidators.tempValidator({
            validator: Validators.required,
            disabled: () => {
              return !this.isRequiredField('ESTIMATED_TIME');
            }
          }),
          Validators.min(0)
        ]
      ],
      estimatedTimeMinutes: [
        {
          value: this.createModel && this.createModel.estimatedTime.minutes !== null
            ? this.createModel.estimatedTime.minutes
            : null
        },
        [
          AppValidators.tempValidator({
            validator: Validators.required,
            disabled: () => {
              return !this.isRequiredField('ESTIMATED_TIME');
            }
          }),
          Validators.min(0),
          Validators.max(59)
        ]
      ],
      project: fb.control(
        [],
        [
          AppValidators.tempValidator({
            validator: Validators.required,
            disabled: () => !this.isRequiredField('PROJECT')
          })
        ]
      ),
      customer: fb.control(
        [],
        [
          AppValidators.tempValidator({
            validator: Validators.required,
            disabled: () => !this.isRequiredField('CUSTOMER')
          })
        ]
      ),
      description: fb.control(
        '',
        [
          AppValidators.tempValidator({
            validator: Validators.required,
            disabled: () => !this.isRequiredField('DESCRIPTION')
          })
        ]
      ),
      importance: fb.control(
        [],
        [
          AppValidators.tempValidator({
            validator: Validators.required,
            disabled: () => !this.isRequiredField('IMPORTANCE')
          })
        ]
      ),
    });
  }

  isUserRequired(): boolean {
    return (this.createModel?.state === 'OPEN' || this.isRequiredField('ASSIGNEE_USER_APPLICATION'))
      && (this.config && this.config.assigneeWithUser);
  }

  isDeviceRequired(): boolean {
    return (this.createModel?.state === 'OPEN' || this.isRequiredField('ASSIGNEE_USER_APPLICATION'))
      && (this.config && this.config.assigneeWithMobileApp);
  }

  isUserGroupRequired(): boolean {
    return this.isRequiredField('ASSIGNEE_USER_GROUP');
  }

  isManagedField(field: TaskRecordField): boolean {
    return !!this.task && this.task.managedFields.contains(field);
  }

  isRequiredField(field: TaskRecordField): boolean {
    return !!this.task && this.task.requiredFields.contains(field);
  }

  createCustomerRecord() {
    CustomerRecordQuickCreateMaterialDialogComponent.openDialog(this.dialog,
      {}, result => {
        if (result?.success && result.customerRecord) {
          this.onCustomerRecordCreated(result.customerRecord)
        }
      });
  }

  onCustomerRecordCreated(customerRecord: CustomerRecord.CustomerRecord) {
    this.createModel.customerM = [this.parseCustomer(customerRecord)];
    this.onCustomerChanged();
  }

  private parseCustomer(customerRecord: CustomerRecord.CustomerRecord) {
    const c = customerRecord.customer!;
    const addr = !customerRecord.postalAddress
    || !c.managedFields.contains(CustomerRecordFieldType.POSTAL_ADDRESS_AND_INVOICE_ADDRESS_AND_NOTIFICATION_ADDRESS)
      ? ''
      : Address.PostalAddressMapper.toString(customerRecord.postalAddress, this.config.postalAddressFormat);
    const item = {
      id: customerRecord.customerRecordId,
      text: customerRecord.name,
      itemName: customerRecord.name,
      itemSubtitle: addr === '' ? undefined : addr,
      customerRecord: customerRecord,
      address: addr
    };
    return item;
  }

  private createRequest() {
    const taskId = this.createModel.task[0]!.id;
    const customerId = this.createModel.customer ? this.createModel.customer.id : undefined;
    const assignee: TaskRecord.AssigneeChangeRequest | undefined =
      (!this.createModel.assignee.user && !this.createModel.assignee.device)
        ? undefined
        : {
          userId: this.createModel.assignee.user && this.createModel.assignee.user.id ?
            this.createModel.assignee.user.id : undefined,
          mobileApplicationId: this.createModel.assignee.device && this.createModel.assignee.device.id ?
            this.createModel.assignee.device.id : undefined,
        };
    const importance = this.createModel.importance && this.createModel.importance.id ?
      this.createModel.importance.id : TaskRecord.DefImportance;
    return {
      taskId: taskId!,
      externalId: Strings.undefinedOrNonEmpty(this.createModel.externalId),
      name: Strings.undefinedOrNonEmpty(this.createModel.name),
      importance: importance,
      state: this.createModel.state,
      description: this.createModel.description,
      customerId: customerId ? customerId : undefined,
      billingInfoId: this.createModel.billingInfoId,
      projectId: this.createModel.projectId,
      userGroupId: this.createModel.userGroup.length > 0 && this.createModel.userGroup[0].id ?
        this.createModel.userGroup[0].id! : undefined,
      assignee: assignee,
      agreedTime: this.datePickerParserFormatter.toOffsetDateTime(this.createModel.agreedTimeDate, this.createModel.agreedTimeTime),
      deadline: this.datePickerParserFormatter.toOffsetDateTime(this.createModel.deadlineTimeDate, this.createModel.deadlineTimeTime),
      estimatedTimeInMinutes: this.createModel.estimatedTime.toMinutes(),
      contactLocationId: this.createModel.contactLocationId,
      placeOfConsumption: this.createModel.placeOfConsumption.getServicePOC()
    };
  }

  getTitleKey() {
    if (this.data.mode === TaskRecordCreateDialogMode.REQUEST_ONLY) {
      return 'TASK_RECORD_CREATE_REQUEST_ONLY_HEADER';
    }
    return 'EMPTY_DASHBOARD_CREATE_TASK';
  }

  getSubmitButtonKey() {
    if (this.data.mode === TaskRecordCreateDialogMode.REQUEST_ONLY) {
      return 'COMMON_BUTTON_SAVE';
    }
    return 'EMPTY_DASHBOARD_CREATE_TASK';
  }
}

export class TaskRecordCreateModel {
  task: TaskMultiselectOptionItem[] = [];
  name: string = '';
  nameTemplate: string = '';
  externalId: string = '';
  description: string = '';
  importance: TaskRecordImportanceItem;
  state: TaskRecordStateMachine.State = 'NEW';
  userGroup: UserGroupItem[] = [];
  assignee: AssigneeModel = new AssigneeModel();
  customerM: CustomerRecordItem[] = [];
  billingInfo: MultiselectOptionItem<number>[] = [];
  customerRecordIds: number[] = [];
  project: MultiselectOptionItem<number>[] = [];
  deadlineTimeDate?: NgbDateStruct = undefined;
  deadlineTimeTime?: AppNgbTimeStruct = undefined;
  agreedTimeDate?: NgbDateStruct = undefined;
  agreedTimeTime?: AppNgbTimeStruct = undefined;
  estimatedTime: ComplexTimeModel = new ComplexTimeModel(0, 0);
  placeOfConsumption: PlaceOfConsumptionModel;
  contactLocationId?: number;

  constructor(taskRecordImportanceItem: TaskRecordImportanceItem) {
    this.importance = taskRecordImportanceItem;
    this.placeOfConsumption = new PlaceOfConsumptionModel();
  }

  get customer(): CustomerRecordItem | undefined {
    return this.customerM.length > 0 ? this.customerM[0] : undefined;
  }

  set customer(customer: CustomerRecordItem | undefined) {
    if (customer) {
      this.customerM[0] = customer;
    }
  }

  get billingInfoId(): number | undefined {
    return this.billingInfo.length > 0 ? this.billingInfo[0].id : undefined;
  }

  get projectId(): number | undefined {
    return this.project.length === 1 ? this.project[0].id : undefined;
  }

  get placeOfConsumptionRequirement(): Task.PlaceOfConsumptionRequirement | undefined {
    return this.task.length === 1 ? this.task[0].placeOfConsumptionRequirement : undefined;
  }

  get selectedTask(): TaskMultiselectOptionItem | undefined {
    return this.task.length > 0 ? this.task[0] : undefined;
  }

  get selectedUserGroup(): UserGroupItem | undefined {
    return this.userGroup.length > 0 ? this.userGroup[0] : undefined;
  }

  get selectedUser(): OwnerUserItem | undefined {
    return this.assignee.user;
  }
}


export enum TaskRecordCreateDialogMode {
  QUICK_CREATE,
  QUICK_CREATE_BY_CUSTOMER_RECORDS,
  CALENDAR,
  REQUEST_ONLY
}

export interface TaskRecordCreateDialogData {
  navigateToTaskRecordEdit?: boolean;
  mode: TaskRecordCreateDialogMode,
  date?: { date: Date },
  event?: { event: LocalCalendarEvent },
  customerRecordItem?: CustomerRecordItem,
  customerRecordIds?: number[],
  taskId?: number
  nameOptional?: boolean;
}

export interface TaskRecordCreateDialogResult {
  request?: TaskRecord.QuickCreateRequest;
  success: boolean;
}
