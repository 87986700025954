import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CustomerRecordContact, CustomerRecordContactService } from '../../../lib/customer/contact/customer-record-contact.service';
import { CustomerRecord, CustomerRecordService } from '../../../lib/customer/customer-record.service';
import { Set } from 'immutable';
import { QueryFieldModel } from '../../../util/core-utils';
import { OrderType, QueryResult } from '../../../lib/util/services';
import { Address } from '../../../lib/address';
import { ConfigurationService } from '../../../lib/core-ext/configuration.service';
import { OperationRights } from '../../../app.right-definitions';
import { GrantedPermissionSetResolver } from '../../../lib/right.service';
import { CustomerRecordRightModel } from '../../../util/customer-record-utils';
import PostalAddressData = Address.PostalAddressData;

@Component({
  selector: 'app-customer-record-main-customer-record',
  templateUrl: './customer-record-main-customer-record.component.html',
  styleUrls: ['./customer-record-main-customer-record.component.scss']
})
export class CustomerRecordMainCustomerRecordComponent implements OnInit {
  NewCustomerRecordContact = CustomerRecordContact;
  CustomerRecord = CustomerRecord;
  pagingId = 'customerRecordMainCustomerRecordLocationList';
  mainPagingId = 'customerRecordMainCustomerRecordList';

  @Input()
  customerRecordId: number;

  @Output()
  detailContactLocation: EventEmitter<Address.ContactLocation> = new EventEmitter();

  mainCustomerRecords: MainCustomerModel[] = [];

  queryModel: QueryFieldModel<CustomerRecordContact.OrderField> =
    new QueryFieldModel(CustomerRecordContact.OrderField.ID, OrderType.ASC);

  mainQueryModel: QueryFieldModel<CustomerRecord.OrderField> =
    new QueryFieldModel(CustomerRecord.OrderField.ID, OrderType.ASC);

  locationList: Address.ContactLocation[] = [];

  private postalAddressFormat: string;

  constructor(
    private configService: ConfigurationService,
    private contactService: CustomerRecordContactService,
    private customerRecordService: CustomerRecordService
  ) {
  }

  ngOnInit() {
    this.postalAddressFormat = this.configService.getPostalAddressFormat();
  }

  initComponent() {
    this.loadMainCustomerRecord();
    this.loadContactLocations();
  }
  private loadMainCustomerRecord(pageNumber?: number) {
    const order = this.mainQueryModel.getOrder();
    const requestedPage = pageNumber ? pageNumber : this.queryModel.currentPage;
    this.customerRecordService.globalQuery({
      rights: Set.of(...[OperationRights.CUSTOMER_RECORD_UPDATE, OperationRights.CUSTOMER_RECORD_DISABLE]),
      orders: Set.of(order),
      paging: requestedPage ? {
        pageNumber: requestedPage,
        numberOfItems: this.mainQueryModel.itemsPerPage
      } : undefined,
      contactPersonId: this.customerRecordId,
      fields: Set.of('id', 'customer_id', 'name', 'postal_address', 'granted_rights'),
    }).subscribe(result => {
      this.mainCustomerRecords = result.items.map(c => {
        return {
          data: c!,
          rights: new CustomerRecordRightModel(GrantedPermissionSetResolver.byGrantedRights(c!.grantedRights))
        };
      }).toArray();
      this.mainQueryModel.currentPage = requestedPage;
      this.mainQueryModel.totalNumberOfItems = result.pagingResult.totalNumberOfItems;
      this.mainQueryModel.currentNumberOfItems = result.pagingResult.currentNumberOfItems;
    });
  }

  private loadContactLocations(pageNumber?: number) {
    const requestedPage = pageNumber ? pageNumber : this.queryModel.currentPage;
    const order = this.queryModel.getOrder();
    this.contactService.getLocations({
      contactCustomerRecordId: this.customerRecordId,
      orders: Set.of(order),
      paging: requestedPage ? {
        pageNumber: requestedPage,
        numberOfItems: this.queryModel.itemsPerPage
      } : undefined
    }).subscribe((result: QueryResult<Address.ContactLocation>) => {
      this.locationList = result.items.toArray();
      this.queryModel.currentPage = requestedPage;
      this.queryModel.totalNumberOfItems = result.pagingResult.totalNumberOfItems;
      this.queryModel.currentNumberOfItems = result.pagingResult.currentNumberOfItems;
    });
  }

  orderBy(field: CustomerRecordContact.OrderField) {
    this.queryModel.onOrderFieldChanged(field);
    this.loadContactLocations(1);
  }

  pageChanged(selectedPage: number) {
    this.loadContactLocations(selectedPage);
  }

  itemsPerPageChanged(itemsPerPage: number) {
    this.queryModel.itemsPerPage = itemsPerPage;
    this.loadContactLocations(1);
  }

  mainOrderBy(field: CustomerRecord.OrderField) {
    this.mainQueryModel.onOrderFieldChanged(field);
    this.loadMainCustomerRecord(1);
  }

  mainPageChanged(selectedPage: number) {
    this.loadMainCustomerRecord(selectedPage);
  }

  mainItemsPerPageChanged(itemsPerPage: number) {
    this.mainQueryModel.itemsPerPage = itemsPerPage;
    this.loadMainCustomerRecord(1);
  }

  formatPostalAddress(postalAddress?: PostalAddressData): string {
    return postalAddress ? Address.PostalAddressMapper.toString(postalAddress, this.postalAddressFormat) : '';
  }

  getOpeningTimeLines(value: string): string[] {
    return value.replace('</div><div>', '\n')
      .replace('<div>', '\n')
      .replace(/<[^>]*>/g, '')
      .split('\n')
      .filter((l, i) => i < 2);
  }

}

class MainCustomerModel {
  data: CustomerRecord.CustomerRecord;
  rights: CustomerRecordRightModel = new CustomerRecordRightModel(GrantedPermissionSetResolver.empty());
}
