/* eslint-disable */
import { AfterViewInit, Component, OnInit, ViewChild, } from '@angular/core';
import { Transition, UIRouter } from '@uirouter/angular';
import { OwnerUserItem, UiConstants, } from '../../../util/core-utils';
import { StateName } from '../../../app.state-names';
import { InputMask } from '../../../util/input-masks';
import { SurveyEditModel } from '../../../util/survey-utils';
import { Survey, SurveyService } from '../../../lib/survey/survey.service';
import { FieldValidationError, LocalFieldValidationErrors, LocalFieldValidationErrorsFactory, } from '../../../lib/util/services';
import { FormServiceCode } from '../../../lib/form/form-service-factory';
import { NgForm, NgModel, } from '@angular/forms';
import { List } from 'immutable';
import { DownloadedFile } from '../../../lib/util/downloaded-files';
import { StringKey } from '../../../app.string-keys';
import { ToasterService } from '../../../fork/angular2-toaster/angular2-toaster';
import { TranslateService } from '@ngx-translate/core';
import { saveAs } from 'file-saver';
import { LoadingHandler } from '../../../lib/loading-handler';
import { RightModel, Rights, } from '../../../app.rights';
import { RightResolver, RightService } from '../../../lib/right.service';
import { FormRights } from '../../../util/form/form-utils';
import { BreadcrumbParent } from '../../../shared/breadcrumb/breadcrumb/breadcrumb.component';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { Form } from '../../../lib/form/form.service';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { FileUploaderUtil } from '../../../util/file-uploader-util';
import { Subject } from 'rxjs';
import { SurveyResourceService } from '../../../lib/survey/survey-resource.service';
import { FormEditComponent } from '../../form/form-edit/form-edit.component';
import { EmptyMessage } from '../../../lib/util/messages';
import { ErrorMessageService } from '../../../lib/error-message-parser.service';
import { FileUploadComponent } from '../../../shared/file-upload/flat/file-upload.component';

/* eslint-enable */

@Component({
  selector: 'app-survey-edit',
  templateUrl: 'survey-edit.component.html',
  styleUrls: ['survey-edit.component.scss']
})
export class SurveyEditComponent implements OnInit, AfterViewInit {

  InputMask = InputMask;
  Survey = Survey;
  FormServiceCode = FormServiceCode;
  UiConstants = UiConstants;

  breadcrumbParents: BreadcrumbParent[] = [];
  breadcrumbSelf: string;
  compactSidebar: boolean = document.querySelector('body')!.classList.contains('sidebar-compact');

  formRights: FormRights = {
    formRead: Rights.SURVEY_FORM_READ,
    formGroupUpdate: Rights.SURVEY_FORM_GROUP_UPDATE,
    formFieldUpdate: Rights.SURVEY_FORM_FIELD_UPDATE,
    formGroupCreate: Rights.SURVEY_FORM_GROUP_CREATE,
    formFieldCreate: Rights.SURVEY_FORM_FIELD_CREATE,
    formGroupDisable: Rights.SURVEY_FORM_GROUP_DISABLE,
    formFieldDisable: Rights.SURVEY_FORM_FIELD_DISABLE,
    formGroupMove: Rights.SURVEY_FORM_GROUP_MOVE,
    formFieldMove: Rights.SURVEY_FORM_FIELD_MOVE,
  };

  @ViewChild('f', {static: true})
  fForm: NgForm;

  @ViewChild('formEdit', {static: true})
  formEdit: FormEditComponent;

  @ViewChild('name', {static: true})
  nameInput: NgModel;

  @ViewChild('externalId', {static: true})
  externalIdInput: NgModel;

  model: SurveyEditModel = new SurveyEditModel();
  ownerUsers: OwnerUserItem[] = [];
  surveyId: number;

  rightModel: RightModel = RightModel.empty();

  @ViewChild('fileUploadComponent', {static: false})
  fileUploadComponent: FileUploadComponent;
  uploadGlobalErrors: string;

  private fieldErrors: FieldValidationError<Survey.ValidatedField> =
    FieldValidationError.empty<Survey.ValidatedField>();

  private validatedInputs: LocalFieldValidationErrors<NgModel> =
    LocalFieldValidationErrorsFactory.empty();

  pdfTemplateUploadPath: string = '';
  thumbnailUploadPath: string = '';

  imageToShow: any | null = null;
  thumbnailLoaded = false;
  thumbnailSrc: SafeStyle;

  @ViewChild('importDialog', {static: true}) importDialog: ModalDirective;
  importDialogVisible: boolean = false;
  importDnDVisible: boolean = true;

  surveyImportModel: SurveyImportModel = new SurveyImportModel();

  constructor(private surveyService: SurveyService,
              private uiRouter: UIRouter,
              private sanitizer: DomSanitizer,
              private transition: Transition,
              private toasterService: ToasterService,
              private translateService: TranslateService,
              private rightService: RightService,
              private uploadUtil: FileUploaderUtil,
              private resourceService: SurveyResourceService,
              private errorMessageService: ErrorMessageService
  ) {
    this.surveyId = this.transition.params().id;
    this.pdfTemplateUploadPath = '/surveys/' + this.surveyId + '/attachments/pdf-template';
    this.thumbnailUploadPath = '/surveys/' + this.surveyId + '/attachments/thumbnail';
  }

  ngOnInit() {
    this.translateService.get('MENU_NAVBAR_MENU_ADMINISTRATION').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.ADMIN_DASHBOARD});
      }
    );
    this.translateService.get('MENU_NAVBAR_SURVEY').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.SURVEY_LIST});
      }
    );
  }

  ngAfterViewInit(): void {
    this.loadRightModels();
    this.loadLocalFieldValidationErrors();
    this.loadModel();
  }

  private loadRightModels() {
    this.rightService.getRightResolver().subscribe(
      (resolver: RightResolver) => {
        this.rightModel = RightModel.of(resolver);
      }
    );
  }

  update() {
    if (this.hasLocalFieldError()) {
      this.showValidationErrorToast();
      return;
    }
    this.surveyService.update({
      surveyId: this.surveyId,
      name: this.model.name,
      description: this.model.description,
      externalId: this.model.externalId
    }).subscribe(
      (response: Survey.Survey) => {
        LoadingHandler.getInstance().refresh();
        this.uiRouter.stateService.go(StateName.SURVEY_LIST);
      },
      (error: any) => {
        if (error instanceof FieldValidationError) {
          this.fieldErrors = error.withForm(this.fForm);
          this.showValidationErrorToast();
        }
      });
  }

  private showValidationErrorToast() {
    this.toasterService.pop({
      timeout: UiConstants.ToastTimeoutLong,
      type: UiConstants.toastTypeError,
      title: this.translateService.instant(StringKey.COMMON_FORM_VALIDATION_ERROR_TOAST_TITLE),
      body: this.translateService.instant(StringKey.COMMON_FORM_VALIDATION_ERROR_TOAST_MESSAGE)
    });
  }

  onThumbnailUpload(success: boolean) {
    if (success) {
      this.model.hasThumbnail = true;
      this.downloadThumbnailForPreview();
    }
  }

  hasLocalFieldError(field?: NgModel): boolean {
    return this.validatedInputs.hasLocalError(field);
  }

  hasFieldError(field?: Survey.ValidatedField): boolean {
    return this.fieldErrors.hasError(field);
  }

  removeFieldError(field: Survey.ValidatedField) {
    this.fieldErrors = this.fieldErrors.removeError(field);
  }

  getFieldErrorText(field: Survey.ValidatedField): string {
    return this.fieldErrors.getErrorText(field);
  }

  back() {
    window.history.back();
  }

  private loadLocalFieldValidationErrors() {
    const validatedInputs = List.of(this.nameInput, this.externalIdInput);
    this.validatedInputs = LocalFieldValidationErrorsFactory.ofFormFields(this.fForm, validatedInputs);
  }

  private loadModel() {
    this.surveyService.get({
      surveyId: this.surveyId
    }).subscribe(
      (survey: Survey.Survey) => {
        this.breadcrumbSelf = survey.name;
        this.model = {
          name: survey.name,
          externalId: survey.externalId,
          description: survey.description,
          hasPdfTemplate: survey.hasPdfTemplate,
          hasThumbnail: survey.hasThumbnail
        };
        if (this.model.hasThumbnail) {
          this.downloadThumbnailForPreview();
        }
      });
  }

  private downloadThumbnailForPreview() {
    this.thumbnailLoaded = false;
    this.surveyService.downloadThumbnail({
      surveyId: this.surveyId,
    }).subscribe((res: DownloadedFile) => {
        this.imageToShow = URL.createObjectURL(res.getBlob());
        this.thumbnailSrc = this.sanitizer.bypassSecurityTrustStyle(`url(${this.imageToShow})`);
        this.thumbnailLoaded = true;
      }
    );
  }


  // pdf template

  deletePdfTemplate() {
    if (this.model.hasPdfTemplate) {
      this.surveyService.deletePdfTemplate({
        surveyId: this.surveyId
      }).subscribe(
        (result: EmptyMessage) => {
          this.toasterService.pop({
            timeout: UiConstants.ToastTimeoutShort,
            type: UiConstants.toastTypeSuccess,
            title: this.translateService.instant(StringKey.TASK_ATTACHMENT_TITLE),
            body: this.translateService.instant(StringKey.TASK_ATTACHMENT_DELETE_MESSAGE)
          });
          this.model.hasPdfTemplate = false;
        },
        (error: Error) => {
          this.toasterService.pop({
            timeout: UiConstants.ToastTimeoutLong,
            type: UiConstants.toastTypeError,
            title: this.translateService.instant(StringKey.TASK_ATTACHMENT_TITLE),
            body: error.message
          });
        });
    }
  }

  downloadPdfTemplate() {
    this.surveyService.downloadPdfTemplate({
      surveyId: this.surveyId,
    }).subscribe((res: DownloadedFile) => {
      saveAs(res.getBlob(), res.getFileName(this.model.name + '.xml'));
    });
  }

// thumbnail

  deleteThumbnail() {
    if (this.model.hasThumbnail) {
      this.surveyService.deleteThumbnail({
        surveyId: this.surveyId
      }).subscribe(
        (result: EmptyMessage) => {
          this.toasterService.pop({
            timeout: UiConstants.ToastTimeoutShort,
            type: UiConstants.toastTypeSuccess,
            title: this.translateService.instant(StringKey.TASK_ATTACHMENT_TITLE),
            body: this.translateService.instant(StringKey.TASK_ATTACHMENT_DELETE_MESSAGE)
          });
          this.model.hasThumbnail = false;
        },
        (error: Error) => {
          this.toasterService.pop({
            timeout: UiConstants.ToastTimeoutLong,
            type: UiConstants.toastTypeError,
            title: this.translateService.instant(StringKey.TASK_ATTACHMENT_TITLE),
            body: error.message
          });
        });
    }
  }

  exportForm() {
    this.surveyService.exportForm({
      parentId: this.surveyId
    }).subscribe((result: Form.FormImportDocument) => {
      const blob = new Blob([JSON.stringify(result)], {type: 'text/json;charset=utf-8'});
      saveAs(blob, 'survey_' + this.surveyId + '.json');
    });
  }


  downloadThumbnail() {
    this.surveyService.downloadThumbnail({
      surveyId: this.surveyId,
    }).subscribe((res: DownloadedFile) => {
      saveAs(res.getBlob(), res.getFileName(this.model.name + '.png'));
    });
  }

  onImportableSurveySelected(files: File[]) {
    this.surveyImportModel.importResult = undefined;
    const file = files[0];
    this.surveyImportModel.fileName = file.name;
    const fr = new FileReader();
    const subject: Subject<any> = new Subject<any>();
    fr.onloadend = (function (f) {
      return function (e) {
        try {
          const res = JSON.parse(this.result);
          subject.next(res);
        }
        catch (e) {
          subject.error('');
        }
      };
    })(file);
    fr.readAsText(file);
    subject.asObservable().subscribe((survey: any) => {
      if (survey) {
        this.surveyImportModel.importableSurveys = survey;
        this.surveyImportModel.importResult = undefined;
        this.importDnDVisible = false;
      }
      else {
        this.surveyImportModel.importResult = 'FORMAT_ERROR';
        this.importDnDVisible = true;
      }
    });
  }

  importSurveys() {
    this.resourceService.import({
      survey_id: this.surveyId,
      form: this.surveyImportModel.importableSurveys['form'],
      version: this.surveyImportModel.importableSurveys['version']
    })
      .subscribe(r => {
        this.surveyImportModel.importResult = 'SUCCESS';
        this.closeImportDialog();
        this.formEdit.reloadForm();
      }, error => {
        this.surveyImportModel.importResult = 'ERROR';
        this.importDnDVisible = true;
        const localizedError = this.errorMessageService.parseError(error);
        this.uploadGlobalErrors = localizedError!.globalErrors.join(',');

      });
  }

  openImportDialog() {
    this.importDialogVisible = true;
    this.importDnDVisible = true;
    this.importDialog.show();
    this.surveyImportModel = new SurveyImportModel();
  }

  closeImportDialog() {
    this.importDialogVisible = false;
    this.importDialog.hide();
    this.fileUploadComponent.uploader.clearQueue();
    this.fileUploadComponent.uploader.cancelAll();
  }
}


export class SurveyImportModel {
  fileName: string = '';
  importableSurveys: string[] = [];
  importResult?: string = undefined;
}
