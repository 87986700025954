<div bsModal [config]="UiConstants.modalConfig" #fieldDialog="bs-modal" (onHide)="onModalHide($event)"
     class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content" *ngIf="visible">
      <div class="modal-header">
        <h4 class="modal-title">{{dialogTitleDictionaryKey | translate}}</h4>
        <button type="button" class="close" (click)="closeDialog()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="card-body p-0">

          <h6>{{'COMMON_BASIC_DATA' | translate}}</h6>

          <div class="row">

            <!-- region Title -->
            <div class="form-group col-md-6"
                 [ngClass]="{ 'has-danger': hasFieldError(Form.FieldValidatedField.TITLE) || hasLocalFieldError(title)}">
              <label>{{'COMMON_NAME' | translate}}</label>
              <input type="text" class="form-control" placeholder="{{'COMMON_NAME' | translate}}"
                     maxlength="{{UiConstants.maximumVarcharLength}}"
                     [ngClass]="{ 'form-control-danger': hasFieldError(Form.FieldValidatedField.TITLE) || hasLocalFieldError(title) }"
                     [(ngModel)]="fieldModel.title"
                     (ngModelChange)="removeFieldError(Form.FieldValidatedField.TITLE)"
                     (blur)="onTitleBlur()"
                     name="title" id="title" #title="ngModel" required>
              <div class="form-control-feedback" *ngIf="hasFieldError(Form.FieldValidatedField.TITLE)">
                {{getFieldErrorText(Form.FieldValidatedField.TITLE)}}
              </div>
              <div class="form-control-feedback" *ngIf="hasLocalFieldError(title)">
                {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
              </div>
            </div>
            <!-- endregion Title -->

            <!-- region field width -->
            <div class="form-group col-md-3">
              <label>{{'FORM_ITEM_COMMON_FIELD_WIDTH' | translate}}</label>
              <select class="form-control" [(ngModel)]="fieldModel.fieldWidthType"
                      id="fieldWidth" name="fieldWidth" #fieldWidth="ngModel">
                <option *ngFor="let widthType of fieldWidthTypes" [ngValue]="widthType">{{widthType.textDictionaryKey |
                  translate}}
                </option>
              </select>
            </div>
            <!-- endregion field width -->

            <!-- region display on new row -->
            <div class="form-group col-md-3">
              <label>{{'FORM_ITEM_COMMON_DISPLAY_ON_NEW_ROW' | translate}}</label>
              <br>
              <label class="switch switch-text switch-info mb-0">
                <input type="checkbox" class="switch-input" [(ngModel)]="fieldModel.displayOnNewRow"
                       #displayOnNewRow="ngModel" id="displayOnNewRow" name="displayOnNewRow">
                <span class="switch-label" data-on="&#xe957;" data-off="&#xe98e;"></span>
                <span class="switch-handle"></span>
              </label>
            </div>
            <!-- endregion display on new row -->

          </div> <!-- ./row end -->

          <div class="row">

            <!-- region API export name -->
            <div class="form-group col-md-6" [ngClass]="{ 'has-danger': hasFieldError(Form.FieldValidatedField.API_EXPORT_NAME) || hasLocalFieldError(api_export_name) }">
              <label>{{'FORM_ITEM_COMMON_EXPORT_NAME' | translate}}</label>
              <input type="text" class="form-control" [(ngModel)]="fieldModel.apiExportName" #api_export_name="ngModel"
                     maxlength="{{UiConstants.maximumVarcharLength}}"
                     [ngClass]="{ 'form-control-danger': hasFieldError(Form.FieldValidatedField.API_EXPORT_NAME) || hasLocalFieldError(api_export_name) }"
                     (ngModelChange)="removeFieldError(Form.FieldValidatedField.API_EXPORT_NAME)"
                     placeholder="{{'FORM_ITEM_COMMON_EXPORT_NAME' | translate}}"
                     name="api_export_name" id="api_export_name"
                     required>
              <div class="form-control-feedback" *ngIf="hasFieldError(Form.FieldValidatedField.API_EXPORT_NAME)">
                {{getFieldErrorText(Form.FieldValidatedField.API_EXPORT_NAME)}}
              </div>
              <div class="form-control-feedback" *ngIf="hasLocalFieldError(api_export_name)">
                {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
              </div>
            </div>
            <!-- endregion API export name -->

            <!-- region PDF export name -->
            <div class="form-group col-md-6"
                 [ngClass]="{ 'has-danger': hasFieldError(Form.FieldValidatedField.PDF_EXPORT_NAME) }">
              <label>{{'FORM_ITEM_COMMON_PDF_EXPORT_NAME' | translate}}</label>
              <input type="text" class="form-control" [(ngModel)]="fieldModel.pdfExportName"
                     maxlength="{{UiConstants.maximumVarcharLength}}"
                     #pdfExportName="ngModel" name="pdfExportName" id="pdfExportName"
                     (ngModelChange)="removeFieldError(Form.FieldValidatedField.PDF_EXPORT_NAME)"
                     placeholder="{{'FORM_ITEM_COMMON_PDF_EXPORT_NAME' | translate}}"
                     [ngClass]="{ 'form-control-danger': hasFieldError(Form.FieldValidatedField.PDF_EXPORT_NAME) }">
              <div class="form-control-feedback" *ngIf="hasFieldError(Form.FieldValidatedField.PDF_EXPORT_NAME)">
                {{getFieldErrorText(Form.FieldValidatedField.PDF_EXPORT_NAME)}}
              </div>
            </div>
            <!-- endregion PDF export name -->

          </div> <!-- ./row end -->

          <div class="row">

            <!-- region Description -->
            <div class="form-group col-md-6">
              <label>{{'COMMON_DESCRIPTION' | translate}}</label>
              <input type="text" class="form-control" [(ngModel)]="fieldModel.description" #description="ngModel"
                     maxlength="{{UiConstants.maximumVarcharLength}}"
                     placeholder="{{'COMMON_DESCRIPTION' | translate}}"
                     name="description" id="description">
            </div>
            <!-- endregion Description -->

          </div> <!-- ./row end -->

          <h6 class="mt-1">{{'FORM_ITEM_CREATE_DOCUMENT_EXTRA_DATA' | translate}}</h6>

          <div class="row">

            <ng-container *ngIf="availableFileDocuments.length > 0">
              <!-- region file document id -->
              <div class="form-group col-md-6"
                   [ngClass]="{ 'has-danger': hasFieldError(Form.FieldValidatedField.FILE_DOCUMENT_ID) || hasLocalFieldError(file_document) }">
                <label>{{'FORM_ITEM_READONLY_PICTURE_FILE_DOCUMENT_ID' | translate}}</label>
                  <angular2-multiselect
                    class="form-control"
                    [(ngModel)]="fieldModel.fileDocumentItem"
                    [data]="availableFileDocuments"
                    [settings]="dropdownSettings"
                    (ngModelChange)="removeFieldError(Form.FieldValidatedField.FILE_DOCUMENT_ID)"
                    (onRemoteSearch)="loadFileDocuments(undefined, $event.target.value)"
                    name="file_document"
                    id="file_document"
                    #file_document="ngModel"
                    validateEnabledItems
                    required>
                  </angular2-multiselect>
                  <div class="form-control-feedback" *ngIf="hasFieldError(Form.FieldValidatedField.FILE_DOCUMENT_ID)">
                    {{getFieldErrorText(Form.FieldValidatedField.FILE_DOCUMENT_ID)}}
                  </div>
                  <div class="form-control-feedback" *ngIf="hasLocalFieldError(file_document) && file_document.errors['required']">
                    {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
                  </div>
                  <div class="form-control-feedback" *ngIf="hasLocalFieldError(file_document) && file_document.errors['hasDisabledItem']">
                    {{'COMMON_VALIDATION_MESSAGE_DISABLED' | translate}}
                  </div>
              </div>
              <!-- endregion file document id -->
            </ng-container>

            <div class="form-group col-md-6" *ngIf="availableFileDocuments.length === 0">
              <label>{{'FORM_ITEM_READONLY_PICTURE_NO_FILE_WARNING' | translate}}</label>
            </div>

          </div> <!-- ./row end -->

        </div> <!-- ./card-body end -->

      </div>

      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="closeDialog()">{{'COMMON_BUTTON_CANCEL' | translate}}
        </button>
        <button type="button" class="btn btn-primary" #functionButton (click)="saveField()">{{'COMMON_BUTTON_SAVE' |
          translate}}
        </button>
      </div>

    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div><!-- /.modal -->


