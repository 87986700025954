<div class="modal-content">
  <mat-dialog-content style="overflow:visible">
  <form (ngSubmit)="f.form.valid && save()" #f="ngForm" id="form" novalidate>
    <div class="modal-header">
      <p class="modal-title"><strong>{{'WORKFLOW_CLONE_TITLE' | translate}}</strong></p>
      <button type="button" class="close" (click)="closeDialog()" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="form-group row"
           [ngClass]="{ 'has-danger': (f.submitted && !name.valid) || hasFieldError(Workflow.ValidatedField.NAME) }">
        <label for="name" class="col-form-label form-control-label col-md-4 detail-title required-field-label text-md-right">
          {{'COMMON_NAME'|translate}}
        </label>
        <div class="col-md-8">
          <div class="mw-100">
            <input type="text"
                   [(ngModel)]="data.name"
                   maxlength="{{UiConstants.maximumVarcharLength}}"
                   (ngModelChange)="removeFieldError(Workflow.ValidatedField.NAME)"
                   [ngClass]="{ 'form-control-danger': (f.submitted && !name.valid) || hasFieldError(Workflow.ValidatedField.NAME) }"
                   class="form-control"
                   #name="ngModel"
                   name="name"
                   id="name"
                   placeholder="{{'COMMON_NAME'|translate}}"
                   required>
            <div class="form-control-feedback" *ngIf="(f.submitted && !name.valid)">
              {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
            </div>
            <div class="form-control-feedback" *ngIf="hasFieldError(Workflow.ValidatedField.NAME)">
              <span>{{getFieldErrorText(Workflow.ValidatedField.NAME)}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-primary" (click)="closeDialog()">
        {{'COMMON_BUTTON_CANCEL' | translate}}
      </button>
      <button type="submit" class="btn btn-primary" cdkFocusInitial>
        {{'COMMON_BUTTON_SAVE' | translate}}
      </button>
    </div>
  </form>
  </mat-dialog-content>
</div>
