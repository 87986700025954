/* eslint-disable */
import { Component, ViewChild, } from '@angular/core';
import {
  FormEditFieldCreateDialogView,
  FormEditFieldDialogContext,
  FormEditFieldUpdateDialogView,
  FormGroupModel,
  FormModel,
} from '../../../../../../../util/form/form-utils';
import { Form } from '../../../../../../../lib/form/form.service';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { UiConstants } from '../../../../../../../util/core-utils';

/* eslint-enable */

@Component({
  selector: 'app-form-edit-create-update-unknown-field-dialog',
  templateUrl: 'form-edit-create-update-unknown-field-dialog.component.html',
  styleUrls: ['form-edit-create-update-unknown-field-dialog.component.scss'],
})
export class FormEditCreateUpdateUnknownFieldDialogComponent
  implements FormEditFieldCreateDialogView, FormEditFieldUpdateDialogView {
  UiConstants = UiConstants;

  public selector;

  @ViewChild('dialog', { static: true })
  dialogComponent: ModalDirective;

  private context: FormEditFieldDialogContext;

  setSelector(selector: Form.FieldDataTypeSelector): void {
    this.selector = selector;
  }

  registerContext(context: FormEditFieldDialogContext): void {
    this.context = context;
  }

  showDialog(form: FormModel, group: FormGroupModel, field?: Form.Field): void {
    this.dialogComponent.show();
  }

  cancelDialog(): void {
    this.dialogComponent.hide();
  }

}
