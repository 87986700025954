// Each class should be an interface, but it is easier to define the constant fields in the constructors.
// For now we have only one query language so it is not a problem.
// Always use the public Query namespace for variable definitions so we will be able to introduce other language easily.

import { DqlQuery, Query } from '../field';
import { LazyReference } from '../../../util/lazy';
import { User } from './user';
import { Company } from './company';

export class ShopRenter {

  readonly id: Query.NumberField;

  readonly humanName: Query.StringField;

  readonly name: Query.StringField;

  private readonly userReference: LazyReference<User>; // Lazy to support circular references.

  private readonly companyReference: LazyReference<Company>; // Lazy to support circular references.

  get user(): User {
    return this.userReference.get();
  }

  get company(): Company {
    return this.companyReference.get();
  }

  constructor(prefix?: string) {
    prefix = prefix ? prefix : '';
    this.id = new DqlQuery.NumberField(prefix + 'id');
    this.userReference = LazyReference.of(
      () => new User(prefix + 'user.')
    );
    this.companyReference = LazyReference.of(
      () => new Company(prefix + 'company.')
    );
    this.name = new DqlQuery.StringField(prefix + 'name');
    this.humanName = new DqlQuery.StringField(prefix + 'human_name');
  }

}
