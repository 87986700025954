/* eslint-disable */
import { FieldViewItem } from './form-record-inactivity-manager-api';
import { alg as Graphs, Graph } from 'graphlib';
import { FieldActivationStrategyFactory } from './activation-strategy/field-activation-strategy-factory';
import { Form } from '../../../../lib/form/form.service';

/* eslint-enable */

export class FieldViewItemChainBuilder {

  private readonly mapByActivator: Map<FieldViewItem, FieldViewItem[]> = new Map<FieldViewItem, FieldViewItem[]>();
  private readonly mapByActivatee: Map<FieldViewItem, FieldViewItem[]> = new Map<FieldViewItem, FieldViewItem[]>();
  private readonly mapByFieldIdText: Map<string, FieldViewItem> = new Map<string, FieldViewItem>();

  constructor(
    private readonly form: Form.Form,
    private readonly strategyFactory: FieldActivationStrategyFactory) {
  }

  build(): FieldViewItemChain {
    return new FieldViewItemChain(this.form, this.strategyFactory, this.mapByActivator, this.mapByActivatee, this.mapByFieldIdText);
  }

  put(activator: FieldViewItem, activatee: FieldViewItem) {
    this.getByActivator(activator).push(activatee);
    this.getByActivatee(activatee).push(activator);
    this.mapByFieldIdText.set(activator.fieldId.toString(), activator);
    this.mapByFieldIdText.set(activatee.fieldId.toString(), activatee);
  }

  private getByActivator(activator: FieldViewItem) {
    return this.get(this.mapByActivator, activator);
  }

  private getByActivatee(activatee: FieldViewItem) {
    return this.get(this.mapByActivatee, activatee);
  }

  private get(map: Map<FieldViewItem, FieldViewItem[]>, key: FieldViewItem): FieldViewItem[] {
    if (map.has(key)) {
      return map.get(key)!;
    }
    else {
      const a: FieldViewItem[] = [];
      map.set(key, a);
      return a;
    }
  }

}

export class FieldViewItemChain {

  public readonly acyclic: boolean;
  private readonly graph: Graph;

  constructor(
    private readonly form: Form.Form,
    private readonly strategyFactory: FieldActivationStrategyFactory,
    private readonly mapByActivator: Map<FieldViewItem, FieldViewItem[]>,
    private readonly mapByActivatee: Map<FieldViewItem, FieldViewItem[]>,
    private readonly mapByFieldIdText: Map<string, FieldViewItem>) {
    const graph = new Graph();
    this.mapByActivator.forEach((value, key) => {
      graph.setNode(key.fieldId.toString(), key);
      value.forEach((v) => {
        graph.setNode(v.fieldId.toString(), v);
        graph.setEdge(key.fieldId.toString(), v.fieldId.toString());
      });
    });
    this.graph = graph;
    this.acyclic = Graphs.isAcyclic(graph);
  }

  forEach(iterator: (viewItem: FieldViewItem) => void) {
    this.mapByFieldIdText.forEach((value, key) => {
      iterator(value);
    });
  }

  async refreshActivationData() {
    const fieldIdTexts = Graphs.topsort(this.graph);
    for (const fieldIdText of fieldIdTexts) {
      const viewItem: FieldViewItem = this.mapByFieldIdText.get(fieldIdText)!;
      const activators: FieldViewItem[] = this.getActivators(viewItem);
      const activatees: FieldViewItem[] = this.getActivatees(viewItem);
      const active: boolean = this.calculateActive(activators, viewItem);
      const inactivates: FieldViewItem[] = await this.calculateInactivates(active, viewItem, activatees);
      viewItem.activationData.activationChanged = active !== viewItem.activationData.active;
      viewItem.activationData.active = active;
      viewItem.activationData.inactivates = inactivates;
    }
  }

  private calculateActive(activators: FieldViewItem[], viewItem: FieldViewItem) {
    for (const activator of activators) {
      if (!activator.activationData.active) {
        return false;
      }
      if (activator.activationData.inactivates.indexOf(viewItem) >= 0) {
        return false;
      }
    }
    return true;
  }

  private async calculateInactivates(
    active: boolean, viewItem: FieldViewItem, activatees: FieldViewItem[]): Promise<FieldViewItem[]> {
    if (!active) {
      return activatees;
    }
    return this.strategyFactory.getStrategy(viewItem.field.dataTypeSelector).calculateInactivates({
      form: this.form,
      viewItem: viewItem,
      activatees: activatees
    });
  }

  private getActivators(viewItem: FieldViewItem): FieldViewItem[] {
    const a = this.mapByActivatee.get(viewItem);
    if (!a) {
      return [];
    }
    return a;
  }

  private getActivatees(viewItem: FieldViewItem): FieldViewItem[] {
    const a = this.mapByActivator.get(viewItem);
    if (!a) {
      return [];
    }
    return a;
  }

}
