/* eslint-disable */
import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BreadcrumbParent } from '../../../shared/breadcrumb/breadcrumb/breadcrumb.component';
import { StateName } from '../../../app.state-names';
import { ComponentStateResolver } from '../../../util/component-state/component-state-resolver';
import { TranslateService } from '@ngx-translate/core';
import { Transition, UIRouter } from '@uirouter/angular';
import { FormBuilder, NgForm, NgModel } from '@angular/forms';
import { FieldError, FieldErrors, ObservableErrorResourceParser } from '../../../lib/util/errors';
import { List, Set } from 'immutable';
import {
  LocalFieldValidationErrors,
  LocalFieldValidationErrorsFactory,
  OrderType,
  QueryResult,
  Services
} from '../../../lib/util/services';
import { ShippingDemand, ShippingDemandService, } from '../../../lib/shipping-demand/shipping-demand.service';
import { Address, AddressModel } from '../../../lib/address';
import { StringKey } from '../../../app.string-keys';
import { RightResolver, RightService } from '../../../lib/right.service';
import { RightModel } from '../../../app.rights';
import { ConfigurationResource, ConfigurationService } from '../../../lib/core-ext/configuration.service';
import { Country, CountryService } from '../../../lib/country.service';
import { InputMask } from '../../../util/input-masks';
import { MultiselectOptionItem, OptionItem, QueryFieldModel, SelectUtils, UiConstants } from '../../../util/core-utils';
import { WeightFactory } from '../../../util/weight-utils';
import { NgbDatePickerParserFormatter } from '../../../util/ngb-datepicker';
import {
  ShippingDemandItemContainerComponent
} from './shipping-demand-item-container/shipping-demand-item-container.component';
import { Angular2Multiselects } from '../../../util/multiselect';
import { CompanyService } from '../../../lib/company/company.service';
import { Strings } from '../../../lib/util/strings';
import { Observable, Subject } from 'rxjs';
import { ConfirmLeaveModalComponent } from '../../../shared/confirm-leave-modal/confirm-leave-modal.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Models } from '../../../util/model-utils';
import { Logger, LoggerFactory } from '../../../util/logger-factory';
import { EmptyMessage } from '../../../lib/util/messages';
import {
  PriceModel,
  ShippingDemandDetailModel,
  ShippingDemandEditModel,
  ShippingDemandFieldErrorMap
} from './shipping-demand-edit.model';
import { Currency, CurrencyService } from '../../../lib/currency.service';
import packagingAttributeTypes = ShippingDemand.packagingAttributeTypes;
import { TranslateUtils } from '../../../util/translate';
import PackagingAttributeType = ShippingDemand.PackagingAttributeType;
import { CompanyLocationService } from '../../../lib/company-location/company-location.service';
import { ToasterService } from '../../../fork/angular2-toaster/src/toaster.service';
import { combineLatest } from 'rxjs';
import ShippingDemandState = ShippingDemand.ShippingDemandState;
import { HistoryBaseModel } from '../../history-log/history-log/history-log.component';
import { CompanyMultiselectProvider } from '../../../lib/company/company-multiselect.provider';
import CarriageFlag = ConfigurationResource.CarriageFlag;
import { DownloadedFile } from '../../../lib/util/downloaded-files';
import { saveAs } from 'file-saver';
/* eslint-enable */

@Component({
  selector: 'app-shipping-demand-edit',
  templateUrl: './shipping-demand-edit.component.html',
  styleUrls: ['./shipping-demand-edit.component.scss']
})
export class ShippingDemandEditComponent implements OnInit, AfterViewInit, OnDestroy {
  ConfigurationService = ConfigurationService;
  ShippingDemand = ShippingDemand;

  InputMask = InputMask;
  SelectUtils = SelectUtils;
  UiConstants = UiConstants;
  EditableField = ShippingDemand.EditableField;
  private readonly logger: Logger;

  fieldStyle = {
    'padding': UiConstants.formRecordFieldPadding
  };

  @ViewChild('f', { static: true })
  form?: NgForm;

  @ViewChild('recipientName')
  recipientName: NgModel;

  @ViewChild('transferorName')
  transferorName: NgModel;

  @ViewChild('recipientPhoneNumber')
  recipientPhoneNumber: NgModel;

  @ViewChild('recipientEmailAddress')
  recipientEmailAddress: NgModel;

  @ViewChild('transferorPhoneNumber')
  transferorPhoneNumber: NgModel;

  @ViewChild('transferorEmailAddress')
  transferorEmailAddress: NgModel;

  @ViewChild('demandItemComponent', { static: true })
  demandItemComponent: ShippingDemandItemContainerComponent;

  // Form for validation
  @ViewChild('f', { static: true })
  fForm: NgForm;

  @ViewChild('externalId')
  externalId: NgModel;

  @ViewChild('demanderC')
  demanderC: NgModel;

  // Variables used for breadcrumb
  breadcrumbParents: BreadcrumbParent[] = [];

  breadcrumbSelf: string;
  compactSidebar: boolean = document.querySelector('body')!.classList.contains('sidebar-compact');
  // Component state resolver, determines the state of the component
  componentState: ComponentStateResolver;

  // Field errors for server validation
  fieldErrors: ShippingDemandFieldErrorMap;

  // Declare models before use
  editModel: ShippingDemandEditModel;

  detailModel: ShippingDemandDetailModel;
  editableFieldModel: ShippingDemand.EditableFields;
  // Right model to store the rights available to the User
  rightModel: RightModel = RightModel.empty();
  configuration: ConfigurationResource.Configuration;

  // Format of postal address, received from server
  postalAddressFormat: string;
  carriageFlag: CarriageFlag;

  // Country items for postal address
  countryItems: List<AddressModel.CountryItem>;

  private validatedInputs: LocalFieldValidationErrors<NgModel> =
    LocalFieldValidationErrorsFactory.empty();

  selectableWeightUnits: string[] = [];
  selectableCashOnDeliveryCurrencies: string[] = [];
  selectableInsuranceCurrencies: string[] = [];
  packageWeightUnit: string = 'g';
  packagingAttributes: MultiselectOptionItem<PackagingAttributeType>[] = [];

  dropdownSettingsForDemanderCompany: Angular2Multiselects.Settings;
  dropdownSettingsForTransporterCompany: Angular2Multiselects.Settings;
  dropdownSettingsForPackagingAttributes: Angular2Multiselects.Settings;
  transporterCompanies: MultiselectOptionItem<number>[] = [];
  demanderCompanies: MultiselectOptionItem<number>[] = [];

  deregisterWarningModal;
  saveButtonClicked: boolean = false;

  maxWeightInGram: number = 100000000;
  weightIsTooHigh: boolean = false;

  // History

  historyList: HistoryBaseModel[] = [];

  states: OptionItem<ShippingDemandState>[] = [];

  historyQueryModel: QueryFieldModel<ShippingDemand.OrderField> =
    new QueryFieldModel(ShippingDemand.OrderField.HISTORY_CREATION_TIME, OrderType.DESC);

  get isNewCarriage(): boolean {
    return this.componentState.isEditView() && this.editModel.state === 'NEW' && this.editModel.carriageByDemanderCompany;
  }

  constructor(
    private uiRouter: UIRouter,
    private transition: Transition,
    private translateService: TranslateService,
    private rightService: RightService,
    private configService: ConfigurationService,
    private countryService: CountryService,
    private currencyService: CurrencyService,
    private shippingDemandService: ShippingDemandService,
    private formBuilder: FormBuilder,
    private companyLocationService: CompanyLocationService,
    private ngbDatePickerParserFormatter: NgbDatePickerParserFormatter,
    private companyService: CompanyService,
    private modalService: BsModalService,
    private toasterService: ToasterService,
    private companyMultiselectProvider: CompanyMultiselectProvider
  ) {
    this.configuration = this.configService.getConfiguration();
    this.logger = LoggerFactory.createLogger('ShippingDemandEditComponent');
    this.fieldErrors = {};
    this.componentState = new ComponentStateResolver(uiRouter, transition,
      'id',
      {stateName: StateName.SHIPPING_DEMAND_CREATE, stateHeaderKey: 'SHIPPING_DEMAND_CREATE'},
      {stateName: StateName.SHIPPING_DEMAND_EDIT, stateHeaderKey: 'SHIPPING_DEMAND_EDIT'},
      {stateName: StateName.SHIPPING_DEMAND_DETAIL, stateHeaderKey: 'SHIPPING_DEMAND_DETAIL'},
      {stateName: StateName.SHIPPING_DEMAND_CLONE, stateHeaderKey: 'SHIPPING_DEMAND_CLONE'});
  }

  ngOnInit() {
    this.initComponentState(); // Must be called first
    this.initDropdownSettings();
    this.loadDropdowns();
    this.initBreadcrumb();

    const context = this;
    if (this.componentState.isEditable()) {
      this.deregisterWarningModal = this.uiRouter.transitionService.onStart({}, function (transition) {
        if (context.saveButtonClicked
          || transition.targetState().name() === StateName.CONNECTION_ERROR
          || transition.targetState().name() === StateName.SERVER_ERROR
          || transition.targetState().name() === StateName.LOGIN) {
          return true;
        }
        else {
          const subject = new Subject<boolean>();
          const modal = context.modalService.show(ConfirmLeaveModalComponent, {backdrop: 'static',  keyboard: false});
          modal.content!.subject = subject;
          return subject.asObservable().toPromise();
        }
      });
    }
  }

  ngAfterViewInit(): void {
    this.loadLocalFieldValidationErrors();
    this.loadConfig(() => {
      this.loadStuff(() => {
        // component state contains the id
        if (this.componentState.id) {
          this.loadModel();
        }
        if (this.componentState.isDetailView()) {
          this.loadStates(() => {
            this.loadHistoryList()
          });
        }
      });
    });
  }

  private loadConfig(completion: () => void) {
    this.postalAddressFormat = this.configService.getPostalAddressFormat();
    this.carriageFlag = this.configService.getConfiguration().feature_flags.carriage_mode;
    completion();
  }

  private loadStuff(completion: () => void) {
    combineLatest(
      this.getRightResolverObservable(),
      this.getCountriesObservable(),
      this.getEditableFieldsObservable(),
      this.getDemanderCompaniesObservable(),
      this.getTransporterCompaniesObservable(),
      (
        rightResolver: RightResolver,
        countries: QueryResult<Country.Country>,
        editableFields: ShippingDemand.EditableFields,
        demanderCompanies: MultiselectOptionItem<number>[],
        transporterCompanies: MultiselectOptionItem<number>[]
      ): CombinedResult => {
        return {
          rightResolver: rightResolver,
          countries: countries,
          editableFields: editableFields,
          demanderCompanies: demanderCompanies,
          transporterCompanies: transporterCompanies
        }
      }
    ).subscribe((result: CombinedResult) => {
      this.rightModel = RightModel.of(result.rightResolver);
      this.countryItems = AddressModel.CountryItem.fromCountryList(result.countries.items);
      this.editableFieldModel = result.editableFields;
      this.demanderCompanies = result.demanderCompanies;
      this.transporterCompanies = result.transporterCompanies;
      completion();
    });
  }

  private getRightResolverObservable(): Observable<RightResolver> {
    return this.rightService.getRightResolver();
  }

  private getCountriesObservable(): Observable<QueryResult<Country.Country>> {
    return this.countryService.query({});
  }

  private getEditableFieldsObservable(): Observable<ShippingDemand.EditableFields> {
    return this.shippingDemandService.getEditableFields();
  }

  private getDemanderCompaniesObservable(): Observable<MultiselectOptionItem<number>[]> {
    return this.companyMultiselectProvider.loadDemanderCompanies();
  }

  private getTransporterCompaniesObservable(): Observable<MultiselectOptionItem<number>[]> {
    return this.companyMultiselectProvider.loadTransporterCompanies();
  }

  ngOnDestroy() {
    if (this.componentState.isEditable()) {
      this.deregisterWarningModal();
    }
  }

  private initComponentState() {
    // Creates the editModel if not readonly
    if (!this.componentState.isDetailView()) {
      this.editModel
        = new ShippingDemandEditModel(this.companyLocationService);
    }
    else {
      this.detailModel
        = new ShippingDemandDetailModel(this.companyLocationService);
    }
  }

  initBreadcrumb() {
    // Set breadcrumbSelf if createView, otherwise set in loadModel()
    if (this.componentState.isCreateView()) {
      this.translateService.get('SHIPPING_DEMAND_CREATE').subscribe(
        (result: string) => {
          this.breadcrumbSelf = result;
        }
      );
    }
    else if (this.componentState.isCloneView()) {
      this.translateService.get('SHIPPING_DEMAND_CLONE').subscribe(
        (result: string) => {
          this.breadcrumbSelf = result;
        }
      );
    }
    else {
      this.breadcrumbSelf = this.componentState.id!.toString();
    }
    this.translateService.get('MENU_NAVBAR_MENU_SHIPPING_DEMAND').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.SHIPPING_DEMAND_LIST});
      }
    );
  }

  // Called by the init method first
  private loadModel() {
    this.shippingDemandService.get({
      demandId: this.componentState.id!
    }).subscribe((shippingDemand: ShippingDemand.ShippingDemand) => {
      if (this.componentState.isEditView() || this.componentState.isCloneView()) {
        this.loadEditModel(shippingDemand);
      }
      if (this.componentState.isDetailView()) {
        this.loadDetailModel(shippingDemand);
      }
      if (this.componentState.isCreateView()) {
        this.editModel.carriageByDemanderCompany = this.carriageFlag === 'FORCE';
      }
    });
  }

  private loadEditModel(shippingDemand: ShippingDemand.ShippingDemand) {
    const recipientPhoneNumber = Services.phoneNumberToString(
      shippingDemand.recipient
        ? shippingDemand.recipient.phoneNumber
        : undefined);
    const recipientEmailAddress = Services.emailAddressToString(
      shippingDemand.recipient
        ? shippingDemand.recipient.emailAddress
        : undefined);
    const transferorPhoneNumber = Services.phoneNumberToString(
      shippingDemand.transferor
        ? shippingDemand.transferor.phoneNumber
        : undefined);
    const transferorEmailAddress = Services.emailAddressToString(
      shippingDemand.transferor
        ? shippingDemand.transferor.emailAddress
        : undefined);

    this.editModel.id = shippingDemand.id;
    this.editModel.state = shippingDemand.state;
    this.editModel.externalId = this.componentState.isEditView() ? shippingDemand.externalId : '';
    this.editModel.demandCategory = shippingDemand.demandCategory ? shippingDemand.demandCategory : '';
    this.editModel.weightInGram = shippingDemand.weightInGram ? shippingDemand.weightInGram + '' : '';
    this.packageWeightUnit = 'g';
    this.editModel.cashOnDeliveryPriceAmount =
      shippingDemand.cashOnDeliveryPrice
        ? Models.decimalToString(shippingDemand.cashOnDeliveryPrice.amount)
        : '';
    this.editModel.cashOnDeliveryPriceCurrencyCode =
      shippingDemand.cashOnDeliveryPrice
        ? shippingDemand.cashOnDeliveryPrice.currencyCode
        : '';
    this.editModel.insurancePriceAmount =
      shippingDemand.insurancePrice
        ? Models.decimalToString(shippingDemand.insurancePrice.amount)
        : '';
    this.editModel.insurancePriceCurrencyCode =
      shippingDemand.insurancePrice
        ? shippingDemand.insurancePrice.currencyCode
        : '';
    this.editModel.comment = shippingDemand.comment ? shippingDemand.comment : '';
    this.editModel.destinationModel.postalAddress.loadCountryItems(this.countryItems, this.configService.getDefaultSelectedCountryCode());
    this.editModel.destinationModel.loadShippingPlace(shippingDemand.destination);

    this.editModel.recipientName = shippingDemand.recipient ? shippingDemand.recipient.name : '';
    this.editModel.recipientLicenseCategory = shippingDemand.recipient ? shippingDemand.recipient.licenseCategory : '';
    this.editModel.recipientLicenseNumber = shippingDemand.recipient ? shippingDemand.recipient.licenseNumber : '';
    this.editModel.recipientPhoneNumber = recipientPhoneNumber;
    this.editModel.recipientEmailAddress = recipientEmailAddress;
    this.editModel.recipientComment =
      shippingDemand.recipient
        ? shippingDemand.recipient.comment
          ? shippingDemand.recipient.comment
          : ''
        : '';

    this.editModel.transferorName = shippingDemand.transferor ? shippingDemand.transferor.name : '';
    this.editModel.transferorLicenseCategory = shippingDemand.transferor ? shippingDemand.transferor.licenseCategory : '';
    this.editModel.transferorLicenseNumber = shippingDemand.transferor ? shippingDemand.transferor.licenseNumber : '';
    this.editModel.transferorPhoneNumber = transferorPhoneNumber;
    this.editModel.transferorEmailAddress = transferorEmailAddress;
    this.editModel.transferorComment =
      shippingDemand.transferor
        ? shippingDemand.transferor.comment
          ? shippingDemand.transferor.comment
          : ''
        : '';

    this.editModel.sourceModel.postalAddress.loadCountryItems(this.countryItems, this.configService.getDefaultSelectedCountryCode());
    this.editModel.sourceModel.loadShippingPlace(shippingDemand.source);

    this.editModel.safetyShipping = shippingDemand.safetyShipping;
    this.editModel.ekaerRequired = shippingDemand.ekaerRequired ? shippingDemand.ekaerRequired : false;
    this.editModel.ekaerId = shippingDemand.ekaerId ? shippingDemand.ekaerId : '';
    this.editModel.tourRoute = shippingDemand.tourRoute ? shippingDemand.tourRoute : '';

    if (shippingDemand.transporter) {
      this.editModel.transporterCompany = this.transporterCompanies.filter(value => +value.id === shippingDemand.transporter!.id);
      const c = this.transporterCompanies.filter(value => +value.id === shippingDemand.transporter!.id);
      if (c) {
        this.editModel.transporterCompany = c;
      }
      else {
        this.companyMultiselectProvider.getById(shippingDemand.transporter.id).subscribe((item) => {
          this.editModel.transporterCompany.push(item);
        })
      }
    }

    if (shippingDemand.demander && shippingDemand.demander.company) {
      const c = this.demanderCompanies.filter(value => +value.id === shippingDemand.demander!.company!.id);
      if (c) {
        this.editModel.demanderCompany = c;
      }
      else {
        this.companyMultiselectProvider.getById(shippingDemand.demander.company.id).subscribe((item) => {
          this.editModel.demanderCompany.push(item);
        })
      }
    }

    const parsedDeadline = this.ngbDatePickerParserFormatter.fromOffsetDateTime(shippingDemand.deadline);
    if (parsedDeadline) {
      this.editModel.deadlineDate = parsedDeadline;
      this.editModel.deadlineTime = parsedDeadline ? parsedDeadline : Models.zeroNgbTime();
    }
    this.editModel.sourceSystemName = shippingDemand.sourceSystemName ? shippingDemand.sourceSystemName : '';

    this.editModel.packagingAttributes = [];
    shippingDemand.packagingAttributes.forEach(t => {
      this.translateService.get(
        packagingAttributeTypes.find(o => o.type === t)!.stringKey)
        .subscribe(result => {
        this.editModel.packagingAttributes.push({id: t, itemName: result});
      })
    });

    this.editModel.carriageByDemanderCompany = !!shippingDemand.carriageState;

    this.demandItemComponent.setComponent(this.editModel.id);

    this.editModel.demandGroupSerial = shippingDemand.demandGroupSerial;
    this.editModel.demandSerial = shippingDemand.demandSerial;

    this.updateDemanderDisability();
  }

  private loadDetailModel(shippingDemand: ShippingDemand.ShippingDemand) {
    const recipientPhoneNumber = Services.phoneNumberToString(
      shippingDemand.recipient
        ? shippingDemand.recipient.phoneNumber
        : undefined);
    const recipientEmailAddress = Services.emailAddressToString(
      shippingDemand.recipient
        ? shippingDemand.recipient.emailAddress
        : undefined);
    const transferorPhoneNumber = Services.phoneNumberToString(
      shippingDemand.transferor
        ? shippingDemand.transferor.phoneNumber
        : undefined);
    const transferorEmailAddress = Services.emailAddressToString(
      shippingDemand.transferor
        ? shippingDemand.transferor.emailAddress
        : undefined);

    this.detailModel
      = new ShippingDemandDetailModel(this.companyLocationService);
    this.detailModel.id = shippingDemand.id;
    this.detailModel.state = shippingDemand.state;
    this.detailModel.externalId = shippingDemand.externalId;
    this.detailModel.demandCategory = shippingDemand.demandCategory ? shippingDemand.demandCategory : '';
    this.detailModel.weightInGram = shippingDemand.weightInGram ? shippingDemand.weightInGram + ' g' : '';
    this.detailModel.cashOnDeliveryPrice =
      shippingDemand.cashOnDeliveryPrice
        ? Models.decimalToString(shippingDemand.cashOnDeliveryPrice.amount) + ' ' + shippingDemand.cashOnDeliveryPrice.currencyCode
        : '';
    this.detailModel.insurancePrice =
      shippingDemand.insurancePrice
        ? Models.decimalToString(shippingDemand.insurancePrice.amount) + ' ' + shippingDemand.insurancePrice.currencyCode
        : '';
    this.detailModel.comment = shippingDemand.comment ? shippingDemand.comment : '';

    this.detailModel.safetyShipping = shippingDemand.safetyShipping ? 'COMMON_YES' : 'COMMON_NO';
    this.detailModel.ekaerRequired = shippingDemand.ekaerRequired ? 'COMMON_YES' : 'COMMON_NO';
    this.detailModel.ekaerId = shippingDemand.ekaerId ? shippingDemand.ekaerId : '';
    this.detailModel.tourRoute = shippingDemand.tourRoute ? shippingDemand.tourRoute : '';
    this.detailModel.deadline = shippingDemand.deadline ? shippingDemand.deadline.toUtcIsoString() : '';
    if (shippingDemand.transporter) {
      const c = this.transporterCompanies.find(value => +value.id === shippingDemand.transporter!.id);
      if (c) {
        this.detailModel.transporterCompany = c.itemName;
      }
      else {
        this.companyMultiselectProvider.getById(shippingDemand.transporter.id).subscribe((item) => {
          this.detailModel.transporterCompany = item.itemName;
        })
      }
    }
    if (shippingDemand.demander && shippingDemand.demander.company) {
      const c = this.demanderCompanies.find(value => +value.id === shippingDemand.demander!.company!.id);
      if (c) {
        this.detailModel.demanderCompany = c.itemName;
      }
      else {
        this.companyMultiselectProvider.getById(shippingDemand.demander.company.id).subscribe((item) => {
          this.detailModel.demanderCompany = item.itemName;
        })
      }
    }
    this.detailModel.sourceSystemName = shippingDemand.sourceSystemName ? shippingDemand.sourceSystemName : '';

    this.detailModel.destinationModel.postalAddress.loadCountryItems(this.countryItems, this.configService.getDefaultSelectedCountryCode());
    this.detailModel.destinationModel.loadShippingPlace(shippingDemand.destination);
    if (this.detailModel.destinationModel.addressType.length === 1
      && this.detailModel.destinationModel.addressType[0].id === 'NEW_ADDRESS') {
      this.detailModel.selectedDestinationCountry = this.detailModel.destinationModel.postalAddress.complexValue.country.itemName;
      this.detailModel.selectedDestinationAddress =
        Address.PostalAddressMapper.toString(this.detailModel.destinationModel.postalAddress, this.postalAddressFormat);
    }
    if (this.detailModel.destinationModel.addressType.length === 1
      && this.detailModel.destinationModel.addressType[0].id === 'LOCATION') {
      this.detailModel.selectedDestinationStockName = this.detailModel.destinationModel.stockItem.length === 1 ?
        this.detailModel.destinationModel.stockItem[0].itemName : '';
    }

    this.detailModel.sourceModel.postalAddress.loadCountryItems(this.countryItems, this.configService.getDefaultSelectedCountryCode());
    this.detailModel.sourceModel.loadShippingPlace(shippingDemand.source);
    if (this.detailModel.sourceModel.addressType.length === 1
      && this.detailModel.sourceModel.addressType[0].id === 'NEW_ADDRESS') {
      this.detailModel.selectedSourceCountry = this.detailModel.sourceModel.postalAddress.complexValue.country.itemName;
      this.detailModel.selectedSourceAddress =
        Address.PostalAddressMapper.toString(this.detailModel.sourceModel.postalAddress, this.postalAddressFormat);
    }
    if (this.detailModel.sourceModel.addressType.length === 1
      && this.detailModel.sourceModel.addressType[0].id === 'LOCATION') {
      this.detailModel.selectedSourceStockName = this.detailModel.sourceModel.stockItem.length === 1 ?
        this.detailModel.sourceModel.stockItem[0].itemName : '';
    }

    this.detailModel.recipientName = shippingDemand.recipient ? shippingDemand.recipient.name : '';
    this.detailModel.recipientLicenseCategory = shippingDemand.recipient ? shippingDemand.recipient.licenseCategory : '';
    this.detailModel.recipientLicenseNumber = shippingDemand.recipient ? shippingDemand.recipient.licenseNumber : '';
    this.detailModel.recipientPhoneNumber = recipientPhoneNumber;
    this.detailModel.recipientEmailAddress = recipientEmailAddress;
    this.detailModel.recipientComment =
      shippingDemand.recipient
        ? shippingDemand.recipient.comment
        ? shippingDemand.recipient.comment
        : ''
        : '';

    this.detailModel.transferorName = shippingDemand.transferor ? shippingDemand.transferor.name : '';
    this.detailModel.transferorLicenseCategory = shippingDemand.transferor ? shippingDemand.transferor.licenseCategory : '';
    this.detailModel.transferorLicenseNumber = shippingDemand.transferor ? shippingDemand.transferor.licenseNumber : '';
    this.detailModel.transferorPhoneNumber = transferorPhoneNumber;
    this.detailModel.transferorEmailAddress = transferorEmailAddress;
    this.detailModel.transferorComment =
      shippingDemand.transferor
        ? shippingDemand.transferor.comment
        ? shippingDemand.transferor.comment
        : ''
        : '';

    const types: string[] = [];
    shippingDemand.packagingAttributes.forEach(t => {
      this.translateService.get(
        packagingAttributeTypes.find(o => o.type === t)!.stringKey)
        .subscribe(result => {
          types.push(result);
        })
    });
    this.detailModel.packagingAttributes = types.join(', ');

    this.detailModel.carriageByDemanderCompany = shippingDemand.carriageState ? 'COMMON_YES' : 'COMMON_NO';
    this.detailModel.carriageByDemanderCompanyValue = !!shippingDemand.carriageState;

    this.demandItemComponent.setComponent(this.detailModel.id);

    this.detailModel.demandGroupSerial = shippingDemand.demandGroupSerial;
    this.detailModel.demandSerial = shippingDemand.demandSerial;
  }

  initDropdownSettings() {
    this.dropdownSettingsForTransporterCompany = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(true)
      .enableSearchFilter(true)
      .enableCheckAll(false)
      .remoteSearch(true)
      .build();
    this.dropdownSettingsForDemanderCompany = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(true)
      .enableSearchFilter(true)
      .enableCheckAll(false)
      .remoteSearch(true)
      .build();
    this.dropdownSettingsForPackagingAttributes = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(false)
      .enableSearchFilter(true)
      .enableCheckAll(true)
      .build();
  }

  private updateDemanderDisability() {
    this.dropdownSettingsForDemanderCompany = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(true)
      .enableSearchFilter(true)
      .enableCheckAll(false)
      .remoteSearch(true)
      .disabled(this.isNewCarriage)
      .build();
  }

  private loadDropdowns() {
    this.selectableCashOnDeliveryCurrencies = [];
    this.selectableInsuranceCurrencies = [];
    this.currencyService.query({}).subscribe((result: QueryResult<Currency.Currency>) => {
      const array = result.items.toArray();
      array.forEach((currency) => {
        this.selectableCashOnDeliveryCurrencies.push(currency.currencyCode);
        this.selectableInsuranceCurrencies.push(currency.currencyCode);
        if (currency.currencyCode === 'HUF' && !this.componentState.id) {
          this.editModel.cashOnDeliveryPriceCurrencyCode = currency.currencyCode;
          this.editModel.insurancePriceCurrencyCode = currency.currencyCode;
        }
      });
    });
    this.selectableWeightUnits = WeightFactory.getOptions();
    this.packagingAttributes = [];
    const keys: string[] = [];
    packagingAttributeTypes.forEach(t => {
      this.packagingAttributes.push({id: t.type, itemName: t.stringKey});
      keys.push(t.stringKey);
    });
    this.translateService.get(keys).subscribe((texts) => {
      this.packagingAttributes.forEach(t => {
        t.itemName = TranslateUtils.extractValueFromObject(texts, t.itemName);
      });
    });
  }

  submit() {
    let hasError = false;
    if (!this.editModel.destinationModel.isValid() || !this.editModel.sourceModel.isValid()) {
      hasError = true;
    }
    if (this.hasLocalFieldError()) {
      hasError = true;
    }

    this.weightIsTooHigh = false;
    if (this.weightConverterToGram(this.editModel.weightInGram) &&
      this.weightConverterToGram(this.editModel.weightInGram)! > this.maxWeightInGram) {
      this.weightIsTooHigh = true;
      hasError = true;
    }

    const itemCount = this.demandItemComponent.getItems().length;
    if (itemCount === 0) {
      this.toasterService.pop({
        timeout: UiConstants.ToastTimeoutLong,
        type: UiConstants.toastTypeError,
        title: this.translateService.instant(StringKey.COMMON_ERROR_DIALOG_TITLE),
        body: this.translateService.instant(StringKey.SHIPPING_DEMAND_SAVE_ERROR)
      });
      hasError = true;
    }

    if (hasError) {
      this.toasterService.pop({
        timeout: UiConstants.ToastTimeoutLong,
        type: UiConstants.toastTypeError,
        title: this.translateService.instant(StringKey.COMMON_ERROR_DIALOG_TITLE),
        body: this.translateService.instant(StringKey.COMMON_FORM_VALIDATION_ERROR_TOAST_MESSAGE)
      });
      return;
    }

    if (this.componentState.isCreateView() || this.componentState.isCloneView()) {
      this.create();
    }
    else {
      this.update();
    }
  }

  create() {
    const insurancePriceAmount = Models.parseDecimal(this.editModel.insurancePriceAmount);
    const cashOnDeliveryPriceAmount = Models.parseDecimal(this.editModel.cashOnDeliveryPriceAmount);
    this.shippingDemandService.create({
      externalId: Strings.undefinedOrNonEmpty(this.editModel.externalId),
      demandCategory: Strings.undefinedOrNonEmpty(this.editModel.demandCategory),
      weightInGram: this.weightConverterToGram(this.editModel.weightInGram),
      insurancePrice: insurancePriceAmount === undefined ? undefined : {
        amount: insurancePriceAmount,
        currencyCode: this.editModel.insurancePriceCurrencyCode
      },
      cashOnDeliveryPrice: cashOnDeliveryPriceAmount === undefined ? undefined : {
        amount: cashOnDeliveryPriceAmount,
        currencyCode: this.editModel.insurancePriceCurrencyCode
      },
      comment: Strings.undefinedOrNonEmpty(this.editModel.comment),

      destination: this.editModel.destinationModel.toSourceDestination(),

      recipient: this.createRecipient(),
      transferor: this.createTransferor(),

      source: this.editModel.sourceModel.toSourceDestination(),

      safetyShipping: this.editModel.safetyShipping,
      ekaerRequired: this.editModel.ekaerRequired,
      ekaerId: Strings.undefinedOrNonEmpty(this.editModel.ekaerId),
      tourRoute: Strings.undefinedOrNonEmpty(this.editModel.tourRoute),

      transporterId: this.editModel.transporterCompany ? this.editModel.transporterCompany.length === 1 ?
        +this.editModel.transporterCompany[0].id : undefined : undefined,

      demanderId: this.editModel.demanderCompany ? this.editModel.demanderCompany.length === 1 ?
        +this.editModel.demanderCompany[0].id : undefined : undefined,

      deadline: this.editModel.deadlineDate ?
        this.ngbDatePickerParserFormatter.toOffsetDateTime(this.editModel.deadlineDate, this.editModel.deadlineTime) :
        undefined,
      sourceSystemName: Strings.undefinedOrNonEmpty(this.editModel.sourceSystemName),

      items: this.createItems(),

      packagingAttributes: this.editModel.packagingAttributes.map(o => o.id),

      carriageByDemanderCompany: this.editModel.carriageByDemanderCompany

    }).subscribe(
      (response: EmptyMessage) => {
        this.saveButtonClicked = true;
        this.uiRouter.stateService.go(StateName.SHIPPING_DEMAND_LIST);
      },
      (error: any) => {
        const res = ObservableErrorResourceParser.parseError(error);
        this.fieldErrors = ObservableErrorResourceParser.extractFieldErrors(res);
      });
  }

  update() {
    const insurancePriceAmount = Models.parseDecimal(this.editModel.insurancePriceAmount);
    const cashOnDeliveryPriceAmount = Models.parseDecimal(this.editModel.cashOnDeliveryPriceAmount);
    this.shippingDemandService.update({
      id: this.editModel.id,
      externalId: this.editModel.externalId,
      demandCategory: Strings.undefinedOrNonEmpty(this.editModel.demandCategory),
      weightInGram: this.weightConverterToGram(this.editModel.weightInGram),
      insurancePrice: insurancePriceAmount === undefined ? undefined : {
        amount: insurancePriceAmount,
        currencyCode: this.editModel.insurancePriceCurrencyCode
      },
      cashOnDeliveryPrice: cashOnDeliveryPriceAmount === undefined ? undefined : {
        amount: cashOnDeliveryPriceAmount,
        currencyCode: this.editModel.insurancePriceCurrencyCode
      },
      comment: Strings.undefinedOrNonEmpty(this.editModel.comment),

      destination: this.editModel.destinationModel.toSourceDestination(),

      recipient: this.createRecipient(),
      transferor: this.createTransferor(),

      source: this.editModel.sourceModel.toSourceDestination(),

      safetyShipping: this.editModel.safetyShipping,
      ekaerRequired: this.editModel.ekaerRequired,
      ekaerId: Strings.undefinedOrNonEmpty(this.editModel.ekaerId),
      tourRoute: Strings.undefinedOrNonEmpty(this.editModel.tourRoute),

      transporterId: this.editModel.transporterCompany ? this.editModel.transporterCompany.length === 1 ?
        +this.editModel.transporterCompany[0].id : undefined : undefined,

      demanderId: this.editModel.demanderCompany ? this.editModel.demanderCompany.length === 1 ?
        +this.editModel.demanderCompany[0].id : undefined : undefined,

      deadline: this.editModel.deadlineDate ?
        this.ngbDatePickerParserFormatter.toOffsetDateTime(this.editModel.deadlineDate, this.editModel.deadlineTime) :
        undefined,
      sourceSystemName: Strings.undefinedOrNonEmpty(this.editModel.sourceSystemName),

      items: this.createItems(),

      packagingAttributes: this.editModel.packagingAttributes.map(o => o.id),

      carriageByDemanderCompany: this.editModel.carriageByDemanderCompany

    }).subscribe(
      (response: EmptyMessage) => {
        this.saveButtonClicked = true;
        this.uiRouter.stateService.go(StateName.SHIPPING_DEMAND_LIST);
      },
      (error: any) => {
        const res = ObservableErrorResourceParser.parseError(error);
        this.fieldErrors = ObservableErrorResourceParser.extractFieldErrors(res);
      });
  }

  createRecipient(): ShippingDemand.UpdateExchangingPerson | undefined {
    const name = Strings.undefinedOrNonEmpty(this.editModel.recipientName);
    const licenseCategory = Strings.undefinedOrNonEmpty(this.editModel.recipientLicenseCategory);
    const licenseNumber = Strings.undefinedOrNonEmpty(this.editModel.recipientLicenseNumber);
    if (name) {
      return {
        name: name,
        licenseCategory: licenseCategory,
        licenseNumber: licenseNumber,
        comment: Strings.undefinedOrNonEmpty(this.editModel.recipientComment),
        emailAddress: Services.toEmailAddress(this.editModel.recipientEmailAddress),
        phoneNumber: Services.toPhoneNumber(this.editModel.recipientPhoneNumber),
      }
    }
    return undefined;
  }

  createTransferor(): ShippingDemand.UpdateExchangingPerson | undefined {
    const name = Strings.undefinedOrNonEmpty(this.editModel.transferorName);
    const licenseCategory = Strings.undefinedOrNonEmpty(this.editModel.transferorLicenseCategory);
    const licenseNumber = Strings.undefinedOrNonEmpty(this.editModel.transferorLicenseNumber);
    if (name) {
      return {
        name: name,
        licenseCategory: licenseCategory,
        licenseNumber: licenseNumber,
        comment: Strings.undefinedOrNonEmpty(this.editModel.transferorComment),
        emailAddress: Services.toEmailAddress(this.editModel.transferorEmailAddress),
        phoneNumber: Services.toPhoneNumber(this.editModel.transferorPhoneNumber),
      }
    }
    return undefined;
  }

  createItems(): ShippingDemand.Item[] {
    const list: ShippingDemand.Item[] = [];
    const demandItems = this.demandItemComponent.getItems();
    demandItems.forEach(demandItem => {
      const item: ShippingDemand.Item = {
        amount: this.createAmount(demandItem.amount),
        boxCode: Strings.undefinedOrNonEmpty(demandItem.boxCode),
        category: Strings.undefinedOrNonEmpty(demandItem.category),
        comment: Strings.undefinedOrNonEmpty(demandItem.comment),
        externalId: demandItem.externalId,
        id: this.componentState.isCloneView() ? undefined : demandItem.id,
        labelCode: Strings.undefinedOrNonEmpty(demandItem.labelCode),
        name: demandItem.name,
        price: this.createPrice(demandItem.price),
        productCode: demandItem.productCode.length > 0 ? demandItem.productCode : undefined,
        weightInGram: demandItem.weightInGram ? +demandItem.weightInGram : undefined,
        itemSize: demandItem.itemSize
      };
      list.push(item);
    });

    return list;
  }

  createAmount(amount): ShippingDemand.Amount | undefined {
    if (amount.value !== '' && amount.value !== null) {
      return {
        value: amount.value.replace(' ', ''),
        unit: amount.unit
      }
    }
    return undefined;
  }

  createPrice(price: PriceModel): ShippingDemand.Price | undefined {
    if (price.amount !== '' && price.amount !== null) {
      return {
        amount: price.amount,
        currencyCode: price.currencyCode
      }
    }
    return undefined;
  }

  hasLocalFieldError(field?: NgModel): boolean {
    return this.validatedInputs.hasLocalError(field);
  }

  removeFieldError(fieldError?: FieldError) {
    FieldErrors.remove(this.fieldErrors, fieldError);
  }

  weightConverterToGram(weight: string | undefined): number | undefined {
    if (weight && weight.length > 0) {
      let weightInGram = Models.parseNumber(weight);
      if (weightInGram) {
        switch (this.packageWeightUnit) {
          case 'g':
            break;
          case 'dkg':
            weightInGram = weightInGram * 10;
            break;
          case 'kg':
            weightInGram = weightInGram * 1000;
            break;
          case 't':
            weightInGram = weightInGram * 1000 * 1000;
        }
        return weightInGram;
      }
    }
    return undefined;
  }

  private loadLocalFieldValidationErrors() {
    const validatedInputs = List.of(
      this.externalId,
      this.recipientName,
      this.recipientPhoneNumber,
      this.recipientEmailAddress,
      this.transferorName,
      this.transferorPhoneNumber,
      this.transferorEmailAddress,
    );
    this.validatedInputs = LocalFieldValidationErrorsFactory.ofFormFields(this.fForm, validatedInputs);
  }

  isEnabledField(field: ShippingDemand.EditableField): boolean {
    if (this.componentState.isCreateView() || this.componentState.isCloneView()) {
      if (this.rightModel.shippingDemandCreate.hasRight()) {
        return this.editableFieldModel.isEnabledByServer(false, field);
      }
      if (this.rightModel.shippingDemandCreateSimple.hasRight()) {
        return this.editableFieldModel.isEnabledByServer(true, field);
      }
      return false;
    }
    if (this.componentState.isEditView()) {
      if (this.rightModel.shippingDemandUpdate.hasRight()) {
        return this.editableFieldModel.isEnabledByServer(false, field);
      }
      if (this.rightModel.shippingDemandUpdateSimple.hasRight()) {
        return this.editableFieldModel.isEnabledByServer(true, field);
      }
      return false;
    }
    if (this.componentState.isDetailView()) {
      if (this.rightModel.shippingDemandRead.hasRight()) {
        return this.editableFieldModel.isEnabledByServer(false, field);
      }
      if (this.rightModel.shippingDemandReadSimple.hasRight()) {
        return this.editableFieldModel.isEnabledByServer(true, field);
      }
      return false;
    }
    return false;
  }

  loadHistoryList(pageNumber?: number) {
    const requestedPage = pageNumber ? pageNumber : this.historyQueryModel.currentPage;
    const order = this.historyQueryModel.getOrder();
    this.shippingDemandService.history({
      id: this.componentState.id!,
      orders: Set.of(order),
      paging: requestedPage ? {
        pageNumber: requestedPage,
        numberOfItems: this.historyQueryModel.itemsPerPage
      } : undefined
    }).subscribe((result: QueryResult<ShippingDemand.HistoryItem>) => {
      this.historyList = [];
      result.items.forEach((history: ShippingDemand.HistoryItem) => {
        const historyModel = new HistoryBaseModel();
        historyModel.id = history.id;
        historyModel.creationTime = history.creationTime.toUtcIsoString();
        historyModel.stateString = this.getStateText(history.state);
        historyModel.changeLog = history.changeLog;
        historyModel.user = history.issuerUser!;
        historyModel.applicationClassification =
          'HISTORY_APPLICATION_CLASS_TYPE_' + history.applicationClassification;
        historyModel.applicationId =
          history.mobileApplication ? history.mobileApplication.applicationId : '';
        this.historyList.push(historyModel);
      });
      this.historyQueryModel.currentPage = requestedPage;
      this.historyQueryModel.totalNumberOfItems = result.pagingResult.totalNumberOfItems;
      this.historyQueryModel.currentNumberOfItems = result.pagingResult.currentNumberOfItems;
    });
  }

  onTransporterCompanySearch(predicate?: string) {
    this.companyMultiselectProvider.searchTransporterCompanies(predicate).subscribe((companies) => {
      this.transporterCompanies = companies;
    })
  }

  onDemanderCompanySearch(predicate?: string) {
    this.companyMultiselectProvider.searchDemanderCompanies(predicate).subscribe((companies) => {
      this.demanderCompanies = companies;
    })
  }

  orderBy(field: ShippingDemand.OrderField) {
    this.historyQueryModel.onOrderFieldChanged(field);
    this.loadHistoryList(1);
  }

  pageChanged(selectedPage: number) {
    this.loadHistoryList(selectedPage);
  }

  itemsPerPageChanged(itemsPerPage: number) {
    this.historyQueryModel.itemsPerPage = itemsPerPage;
    this.loadHistoryList(1);
  }

  getStateText(selectedState: ShippingDemandState): string {
    let text = '';
    this.states.forEach( (s: OptionItem<ShippingDemand.ShippingDemandState>) => {
      if (s.id === selectedState) {
        text = s.text;
      }
    });
    return text;
  }

  private loadStates(completion: () => void) {
    this.states = [];
    ShippingDemand.shippingDemandStates.forEach((s) => {
      const item = {
        id: s.state,
        text: '....'
      };
      this.states.push(item);
      this.translateService.get(s.stringKey).subscribe((text: string) => {
        item.text = text;
      })
    });
    completion();
  }

  generateCarriageTagPdfDocument() {
    this.shippingDemandService.generateCarriageTagPdfDocument({
      demandIds: [this.componentState.id!]
    }).subscribe((res: DownloadedFile) => {
      saveAs(res.getBlob(), res.getFileName('shipping-demand-' + this.componentState.id! + 'carriage-tag-document.pdf'))
    });
  }

  downloadCarriageReceipt() {
    this.shippingDemandService.downloadCarriageReceiptPdfDocument({
      id: this.componentState.id!
    }).subscribe((res: DownloadedFile) => {
      saveAs(res.getBlob(), res.getFileName('shipping-demand-' + this.componentState.id! + 'carriage-receipt.pdf'))
    });
  }

}

interface CombinedResult {
  rightResolver: RightResolver;
  countries: QueryResult<Country.Country>;
  editableFields: ShippingDemand.EditableFields;
  demanderCompanies: MultiselectOptionItem<number>[];
  transporterCompanies: MultiselectOptionItem<number>[];
}
