<div class="card">

  <div class="card-header">
    <label class="form-control-label text-break" [ngClass]="{ 'required-field-label': required }">{{ getTitle() }} </label>
    <div class="card-actions" *ngIf="!nonEditable">
      <a class="btn-setting cursor-pointer" (click)="startStockMove()"
         title="{{'FORM_RECORD_STOCK_ADD' | translate}}"><i class="icomoon icomoon-add"></i></a>
    </div><!-- /.card-actions -->
  </div>
  <div class="card-body">
    <label class="warning-text"
           *ngIf="hasDisabledItem && !nonEditable">{{'FORM_RECORD_STOCK_INTAKE_DISABLED_WARNING_TEXT' | translate}}</label>
    <label class="warning-text"
           *ngIf="!optionalValue && htmlForm && htmlForm.getForm().submitted && (!model.movements || model.movements.length === 0)">{{'FORM_RECORD_STOCK_INTAKE_REQUIRED_WARNING_TEXT' | translate}}</label>
    <div class="text-center text-muted p-2" *ngIf="model.movements.length === 0">
      <div style="background-image: url('../../../../../../assets/img/stock/stock-item-placeholder.png')"
           class="record-picture"></div>
      <label class="mb-1" *ngIf="!nonEditable">{{'FORM_RECORD_STOCK_MOVE_NO_ITEM' | translate}}</label>
      <br>
      <button type="button" class="btn btn-secondary"
              *ngIf="!nonEditable"
              (click)="startStockMove()">
        {{'FORM_RECORD_STOCK_MOVE_START' | translate}}
      </button>
      <label class="m-0" *ngIf="nonEditable">{{'FORM_RECORD_STOCK_NO_ITEM_READONLY' | translate}}</label>
    </div>
    <div *ngIf="model.movements.length > 0">
      <div class="stock-container p-1" *ngFor="let movement of model.movements; let index = index; let last = last"
           [ngClass]="{'disabled-stock': (movement.sourceStock.disabled || movement.destinationStock.disabled) && !nonEditable, 'mb-1': !last}">
        <div class="d-flex mb-05">
          <div class="d-flex flex-grow-1 justify-content-between">
            <label class="font-weight-bold font-lg">{{index + 1}}. {{movement.sourceStock.name}}</label>
            <i class="icomoon icomoon-arrow-right"></i>
            <label class="font-weight-bold font-lg mr-1">{{movement.destinationStock.name}}</label>
          </div>
          <div class="more-options-container" *ngIf="!nonEditable">
            <app-table-options-menu>
              <app-dropdown-item
                [iconClass]="'icomoon-modify'"
                [titleStringKey]="'FORM_RECORD_STOCK_INTAKE_ADD_ITEM_TO_STOCK'"
                class="cursor-pointer"
                (click)="startStockMove(movement.id)">
              </app-dropdown-item>
              <app-dropdown-item
                [iconClass]="'icomoon-trash'"
                [titleStringKey]="'FORM_RECORD_STOCK_INTAKE_DELETE_STOCK'"
                class="cursor-pointer"
                (click)="removeMovement(movement.id)">
              </app-dropdown-item>
            </app-table-options-menu>
          </div>
        </div>
        <table class="table table-sm table-bordered mb-0">
          <thead>
          <tr>
            <th class="responsive-table-column">{{'COMMON_NAME' | translate}}</th>
            <th class="responsive-table-column">{{'STOCK_ITEM_TABLE_HEADER_PRODUCT_CODE' | translate}}</th>
            <th
              class="responsive-table-column hidden-md-down">{{'FORM_RECORD_STOCK_MOVE_IN_STOCK_AMOUNT' | translate}}</th>
            <th
              class="responsive-table-column hidden-md-down">{{'STOCK_RECORD_SELECTOR_DIALOG_RECORD_WEIGHT' | translate}}</th>
            <th class="responsive-table-column">{{'FORM_RECORD_STOCK_MOVE_AMOUNT' | translate}}</th>
            <th class="delete-column" *ngIf="!nonEditable"></th>
          </tr>
          </thead>
          <tbody *ngFor="let item of movement.stockItems">
          <tr [ngClass]="{'disabled-item': item.disabled && !nonEditable}">
            <td class="responsive-table-column align-middle">{{item.name}}</td>
            <td class="responsive-table-column align-middle">{{item.productCode}}</td>
            <td class="responsive-table-column hidden-md-down align-middle">{{item.inStockAmount}} {{item.baseUnit}}
              <div *ngIf="item.packageData"
                   class="table-text-subtitle">{{StockItemUtils.convertToPackageStr(item.inStockAmount, item.packageData)}}</div>
            </td>
            <td class="responsive-table-column hidden-md-down align-middle"> {{item.sumWeightFormatted}}</td>
            <td class="responsive-table-column align-middle"
                *ngIf="nonEditable">{{item.numberAmount}} {{item.selectedUnitOfMeasure.unit_name}}
              <div *ngIf="item.packageData && item.isBaseUnitSelected()"
                   class="table-text-subtitle">{{StockItemUtils.convertToPackageStr(item.numberAmount, item.packageData)}}</div>
              <div *ngIf="!item.isBaseUnitSelected()"
                   class="table-text-subtitle">{{StockItemUtils.convertToBaseStr(item.numberAmount, item.selectedUnitOfMeasure, item.baseUnit)}}</div>
            </td>
            <td class="responsive-table-column align-middle" style="overflow:visible" *ngIf="!nonEditable">
             <div class="d-flex mb-0">
              <div class="form-group w-50"
                   [ngClass]="{ 'has-danger': htmlForm && htmlForm.getForm().submitted && (!item.baseAmount || item.baseAmount <= 0 || item.baseAmount > item.inStockAmount) }">
                <input type="number" class="form-control" [(ngModel)]="item.numberAmount"
                       placeholder="{{'FORM_RECORD_STOCK_MOVE_AMOUNT' | translate}}"
                       [ngClass]="{ 'form-control-danger': htmlForm && htmlForm.getForm().submitted && (!item.baseAmount || item.baseAmount <= 0 || item.baseAmount > item.inStockAmount) }">
                <div class="form-control-feedback"
                     *ngIf="htmlForm && htmlForm.getForm().submitted && !item.numberAmount">
                  {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
                </div>
                <div class="form-control-feedback"
                     *ngIf="htmlForm && htmlForm.getForm().submitted && item.numberAmount <= 0">
                  {{'COMMON_VALIDATION_MESSAGE_NUMBER_NOT_POSITIVE' | translate}}
                </div>
                <div class="form-control-feedback"
                     *ngIf="htmlForm && htmlForm.getForm().submitted && item.baseAmount > item.inStockAmount">
                  {{'STOCK_VALIDATION_IN_STOCK_AMOUNT_MAX' | translate: {maxValue: item.inStockAmount, unit: item.baseUnit} }}
                </div>
              </div>
              <div class="form-group w-50">
                <angular2-multiselect
                  class="form-control ml-05 flex-grow-2"
                  [(ngModel)]="item.unitOfMeasure"
                  [data]="item.measurements"
                  [settings]="dropdownSettings"
                  [ngClass]="{ 'form-control-danger': htmlForm && htmlForm.getForm().submitted && (!item.selectedUnitOfMeasure) }"
                  validateEnabledItems
                  required>
                </angular2-multiselect>
              </div>
             </div>
              <div *ngIf="item.packageData && item.isBaseUnitSelected()"
                   class="table-text-subtitle">{{StockItemUtils.convertToPackageStr(item.numberAmount, item.packageData)}}</div>
              <div *ngIf="!item.isBaseUnitSelected()"
                   class="table-text-subtitle">{{StockItemUtils.convertToBaseStr(item.numberAmount, item.selectedUnitOfMeasure, item.baseUnit)}}</div>
            </td>
            <td class="responsive-table-column align-middle text-center" *ngIf="!nonEditable">
              <i class="icomoon icomoon-trash cursor-pointer font-xl" (click)="removeItem(movement.id, item.id)"></i>
            </td>
          </tr>
          </tbody>
        </table>
        <div class="d-flex justify-content-end">
          <div class="col-md-4 p-0">
            <table class="table table-sm mb-0" *ngIf="movement.sumWeight.length > 0">
              <tbody>
              <tr>
                <td class="font-weight-bold text-small">{{'FORM_RECORD_STOCK_SUM_WEIGHT' | translate}}</td>
                <td class="pull-right pr-1">{{ movement.sumWeight }}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="card-footer" *ngIf="!nonEditable">
    <a class="id-text cursor-pointer"
       (click)="startStockMove()"
       title="{{'FORM_RECORD_STOCK_INTAKE_ADD_STOCK' | translate}}">
      {{'FORM_RECORD_STOCK_INTAKE_ADD_STOCK' | translate}}
    </a>
  </div>
</div>
