<div class="timeline-container" #container>
  <div *ngFor="let i of days"
       class="timeline-cell"
       [ngStyle]="{
        'width.%': 100 / daysInWeek,
        'height.px': rows.length * cellHeight
       }"
       [class.timeline-weekend-cell]="i > 4">
  </div>
  <div *ngFor="let e of _events"
       class="timeline-event-container"
       [ngStyle]="{
        'left.%': e.start * 100 / daysInWeek,
        'top.px': e.row * cellHeight,
        'width.%': e.length * 100 / daysInWeek
        }">
    <div class="timeline-event" (click)="eventClicked.emit({event: e.event})"
         [ngStyle]="{
          'background-color': lightenColor(e.event.color.secondary),
          'border-left': e.event.type === 'PROJECT_RECORD' ? '2px solid ' + e.event.color.secondary : 'none'
         }">
      <span class="timeline-event-title">
        <span *ngIf="false && e.event.type !== 'PROJECT_RECORD'"
              [ngStyle]="{'background-color':  e.event.color.secondary}" class="title-bullet"></span>
        <i *ngIf="e.event.iconClass" class="{{e.event.iconClass}} font-xs"
           [ngStyle]="{'color':  e.event.color.secondary}"></i>
        {{e.event.line1}}
      </span>
      <span class="timeline-event-subtitle">
        {{e.event.line2}}
      </span>
      <span *ngIf="e.event.type === 'PROJECT_RECORD'"
            [ngStyle]="{'color': e.event.color.secondary}"
            class="timeline-event-description d-flex align-items-center">
        <span [ngStyle]="{'background-color':  e.event.color.secondary}"
              class="description-bullet"></span>{{e.event.line3}} {{'COMMON_TASK' | translate}}
      </span>
      <span *ngIf="e.event.type !== 'PROJECT_RECORD'"
            class="timeline-event-description">
        {{e.event.line3}}
      </span>
    </div>
  </div>
</div>
