import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm, NgModel } from '@angular/forms';
import { RegistrationFieldErrorMap } from '../../../register/register.component';
import { UiConstants } from '../../../util/core-utils';
import { FieldError, FieldErrors } from '../../../lib/util/errors';
import { LocalStorages } from '../../../lib/util/storages';
import { ConfigurationService } from '../../../lib/core-ext/configuration.service';

@Component({
  selector: 'app-helpdesk-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss']
})
export class HelpdeskRegistrationComponent implements OnInit {

  @Input()
  model: HelpdeskRegistrationModel;

  @Output()
  onSubmit: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  toLogin: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('f')
  fForm: NgForm;

  @ViewChild('password')
  passwordInput: NgModel;

  @ViewChild('confirmPassword')
  confirmPassword: NgModel;

  passwordVisible: boolean = false;

  get environmentName(): string {
    return this.configurationService.getConfiguration().environment
      ? this.configurationService.getConfiguration().environment!
      : '';
  }

  constructor(
    private configurationService: ConfigurationService
  ) { }

  ngOnInit(): void {
    LocalStorages.onLogout();
  }

  getTextMaximumLength(): number {
    return UiConstants.maximumVarcharLength;
  }

  removeFieldError(fieldError?: FieldError) {
    FieldErrors.remove(this.model.fieldErrors, fieldError);
  }

  togglePasswordVisibility() {
    this.passwordVisible = !this.passwordVisible;
  }

  submit() {
    this.passwordInput.control.updateValueAndValidity();
    this.confirmPassword.control.updateValueAndValidity();
    if (!this.fForm.valid) {
      return;
    }
    this.onSubmit.emit();
  }

}

export class HelpdeskRegistrationModel {
  emailAddress: string = '';
  personName: string = '';
  customerRecord: string = '';
  password: string = '';
  confirmPassword: string = '';
  inProgress: boolean = false;
  fieldErrors: RegistrationFieldErrorMap = {};
  globalError: Error;
}
