import { Injectable } from '@angular/core';
import { UiConstants } from '../../util/core-utils';
import { StringKey } from '../../app.string-keys';
import { ToasterService } from '../../fork/angular2-toaster/src/toaster.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class FormRecordStockPercentValidatorService {

  private fieldPercentages: FieldPercentageData[] = [];

  private shouldDisplayErrorToast = true;

  constructor(private toasterService: ToasterService,
              private translateService: TranslateService) {
  }

  clear() {
    this.fieldPercentages = [];
  }

  register(data: FieldPercentageData) {
    this.fieldPercentages.push(data);
  }

  updatePercentage(data: FieldPercentageData) {
    this.shouldDisplayErrorToast = true;
    const fieldPercentageIndex = this.fieldPercentages.findIndex(f => f.fieldId === data.fieldId);
    if (fieldPercentageIndex > -1) {
      this.fieldPercentages[fieldPercentageIndex] = data;
    }
  }

  validate(): boolean {
    const fieldPercentages = this.fieldPercentages.map(f => f.percentage).reduce((sum, current) => sum + current, 0);
    const valid = fieldPercentages === 100 || fieldPercentages === 0;
    if (!valid && this.shouldDisplayErrorToast) {
      this.showDispersionPercentSumErrorToast();
    }
    return valid;
  }

  private showDispersionPercentSumErrorToast() {
    this.toasterService.pop({
      timeout: UiConstants.ToastTimeoutLong,
      type: UiConstants.toastTypeError,
      title: this.translateService.instant(StringKey.COMMON_FORM_VALIDATION_ERROR_TOAST_TITLE),
      body: this.translateService.instant(StringKey.FORM_RECORD_STOCK_DISPERSION_PERCENT_ERROR)
    });
  }

}

export interface FieldPercentageData {
  fieldId: number;
  percentage: number;
}
