import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DownloadedFile } from '../util/downloaded-files';
import { UserService } from '../user.service';

@Injectable({
  providedIn: 'root'
})
export class UserProfilePictureService {

  private savedProfilePictures: UserProfilePicture[] = [];

  getProfilePicture(userId: number, profilePictureContentHash: string): UserProfilePicture {
    const savedProfilePicture = this.getSavedProfilePicture(userId, profilePictureContentHash);
    if (!savedProfilePicture) {
      const savedProfilePicture: UserProfilePicture = {
        userId: userId,
        profilePictureContentHash: profilePictureContentHash,
        profilePicture: undefined
      };
      this.savedProfilePictures.push(savedProfilePicture);
      this.userService.downloadProfilePicture(userId).subscribe(
        (result: DownloadedFile) => {
          savedProfilePicture.profilePicture = URL.createObjectURL(result.getBlob());
        });
      return savedProfilePicture;
    } else {
      return savedProfilePicture;
    }
  }

  private getSavedProfilePicture(userId: number, profilePictureContentHash: string): UserProfilePicture | undefined {
    return this.savedProfilePictures.find(p => p.userId === userId && p.profilePictureContentHash === profilePictureContentHash);
  }

  constructor(private userService: UserService) {
  }

}

export interface UserProfilePicture {
  userId: number;
  profilePictureContentHash: string;
  profilePicture: any | undefined;
}

interface QueuedProfilePicture {
  userId: number;
  observable: Observable<string>;
}
