import { Injectable } from '@angular/core';
import { Transition } from '@uirouter/angular';
import { TabSelectService } from '../../lib/util/tab-select.service';

@Injectable()
export class TabAnchors {

  constructor(private childTransition: Transition, private selectService: TabSelectService) {}

  // This method should be called from the child component
  // when the tab visibility conditions have been loaded (eg. RightModel)
  protected resolveAnchor() {
    const rawParam = this.childTransition.params()['#'];
    if (rawParam) {
      const anchor = rawParam.split('?')[0];
      if (anchor) {
        this.selectService.select(anchor);
      }
    }
  }
}
