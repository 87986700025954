<div class="row">
  <div class="col-md-12 col-sm-12 col-xs-12 form-group half-padding" *ngIf="discount === null">
    <label class="form-control-label">{{'FORM_RECORD_STOCK_REDUCED_NET_PRICE' | translate}}</label>
    <div class="d-flex align-items-center">
      <input class="form-control flex-grow-1" type="number" placeholder="{{'FORM_RECORD_STOCK_REDUCED_NET_PRICE' | translate}}"
             [(ngModel)]="netReducedPrice" (ngModelChange)="setGrossPrice()"
             [disabled]="disabled"
             [ngModelOptions]="{standalone: true}" name="netPrice">
      <span class="ml-05">{{currencyCodeString}}</span>
    </div>
  </div>
  <div class="col-md-12 col-sm-12 col-xs-12 form-group half-padding" *ngIf="discount === null">
    <label class="form-control-label">{{'FORM_RECORD_STOCK_REDUCED_GROSS_PRICE' | translate}}</label>
    <div class="d-flex align-items-center">
      <input class="form-control flex-grow-1" type="number" placeholder="{{'FORM_RECORD_STOCK_REDUCED_GROSS_PRICE' | translate}}"
             [(ngModel)]="grossReducedPrice"
             (ngModelChange)="setNetPrice()"
             [disabled]="disabled"
             [ngModelOptions]="{standalone: true}" name="grossPrice">
      <span class="ml-05">{{currencyCodeString}}</span>
    </div>
  </div>
</div>
<div class="row d-flex justify-content-end"><div class="text-right">
  <div class="col-md-12 text-right d-flex">
    <input type="button" (click)="close()" class="btn btn-outline-primary cursor-pointer" value="{{'COMMON_BUTTON_CLOSE'|translate}}"/>
    <input type="button" (click)="save()" class="btn btn-primary ml-1 cursor-pointer" value="{{'COMMON_BUTTON_SAVE'|translate}}"/>
  </div>
</div>
