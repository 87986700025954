/* eslint-disable */
import { Injectable } from '@angular/core';
import { OrderField } from '../../query/orderfields';
import { OrderFieldModel, OrderFunctionResolver } from '../../../util/core-utils';
import { NewBaseSearch, NewBaseSearchService } from '../../new-base.search-service';
import { OrderType } from '../../util/services';
import { DisabledEnum, DisabledItem, SearchUtils } from '../../../util/search-utils';
import { LoggedInUserStorage } from '../../util/storages';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

/* eslint-enable */

@Injectable()
export class InvoiceTagSearchService extends NewBaseSearchService<OrderField.InvoiceTag> {

  protected createStorage(): InvoiceTagSearch.Storage {
    return new InvoiceTagSearch.Storage();
  }

}

export namespace InvoiceTagSearch {

  export class Model extends SearchUtils.SearchModel {

    showSearch: boolean = false;

    id?: number;
    invoiceTag: string;
    description: string;
    creationTimeFrom: NgbDateStruct | null;
    creationTimeTo: NgbDateStruct | null;
    disabled: DisabledEnum;

    constructor() {
      super();
      this.reset();
    }

    reset() {
      this.id = undefined;
      this.invoiceTag = '';
      this.description = '';
      this.creationTimeFrom = null;
      this.creationTimeTo = null;
      this.disabled = DisabledEnum.FALSE;
    }

    isEmpty(): boolean {
      return this.id === undefined &&
        this.invoiceTag === '' &&
        this.description === '' &&
        this.creationTimeFrom === null &&
        this.creationTimeTo === null &&
        this.disabled === DisabledEnum.FALSE;
    }

  }

  export namespace OrderFunctions {
    export const ID = (f: OrderField.InvoiceTag) => f.id;
    export const DESCRIPTION = (f: OrderField.InvoiceTag) => f.description;
    export const INVOICE_TAG = (f: OrderField.InvoiceTag) => f.invoiceTag;
    export const CREATION_TIME = (f: OrderField.InvoiceTag) => f.creationTime;
    export const ISSUE_DATE = (f: OrderField.InvoiceTag) => f.creationTime;

    export const VALUES = OrderFunctionResolver.builder<OrderField.InvoiceTag>()
      .add(ID, 'id')
      .add(INVOICE_TAG, 'invoice_tag')
      .add(DESCRIPTION, 'description')
      .add(CREATION_TIME, 'creation_time')
      .add(ISSUE_DATE, 'issue_date')
      .build();
  }

  export interface SearchDataResult {
    searchData: SearchData;
  }

  export interface SearchData extends NewBaseSearch.SearchData<OrderField.InvoiceTag> {
    searchModel: Model;
  }

  interface StoredSearchRawData extends NewBaseSearch.StoredSearchRawData<OrderField.InvoiceTag> {
    id?: number;
    invoiceTag: string;
    description: string;
    creationTimeFrom: NgbDateStruct | null;
    creationTimeTo: NgbDateStruct | null;
    disabled: DisabledEnum;
  }

  export class Storage extends NewBaseSearch.Storage<OrderField.InvoiceTag> {

    constructor() {
      const defaultSearchData: SearchData = {
        queryModel: new OrderFieldModel(OrderFunctions.ID, OrderType.DESC),
        searchModel: new Model(),
      };
      /**
       * Increment this version if you change the SearchData interface.
       */
      const versionNumber: number = 1;
      super(defaultSearchData,
        versionNumber);
    }

    protected generateKey(): string {
      return 'UserId(' + LoggedInUserStorage.getInstance().getUserId() + ')-Search-InvoiceTag';
    }

    protected fromRaw(data: StoredSearchRawData): NewBaseSearch.SearchDataResult<OrderField.InvoiceTag> {
      const searchModel = new Model();
      searchModel.id = data.id;
      searchModel.invoiceTag = data.invoiceTag;
      searchModel.description = data.description;
      searchModel.creationTimeFrom = data.creationTimeFrom;
      searchModel.creationTimeTo = data.creationTimeTo;
      searchModel.disabled = data.disabled;
      const searchData: SearchData = {
        queryModel: OrderFieldModel.create<OrderField.InvoiceTag>(OrderFunctions.VALUES, data.order, data.queryModel),
        searchModel: searchModel,
      };
      return {
        searchData: searchData
      };
    }

    protected toRaw(data: SearchData): StoredSearchRawData {
      return {
        version: this.VERSION,
        queryModel: data.queryModel,
        order: data.queryModel.createOrder(OrderFunctions.VALUES),
        id: data.searchModel.id,
        invoiceTag: data.searchModel.invoiceTag,
        description: data.searchModel.description,
        creationTimeFrom: data.searchModel.creationTimeFrom,
        creationTimeTo: data.searchModel.creationTimeTo,
        disabled: data.searchModel.disabled
      };
    }

  }

}
