/* eslint-disable */
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BreadcrumbParent } from '../../../shared/breadcrumb/breadcrumb/breadcrumb.component';
import { StateName } from '../../../app.state-names';
import { TranslateService } from '@ngx-translate/core';
import { OptionItem, QueryFieldModel, SelectUtils, UiConstants } from '../../../util/core-utils';
import { ForwardingNgFormRef, LocalFormGroupValidationErrors, OrderType, QueryResult } from '../../../lib/util/services';
import { RightModel } from '../../../app.rights';
import { GeneralPdfTemplate, GeneralPdfTemplateService } from '../../../lib/general-pdf-template/general-pdf-template.service';
import { OffsetDateTime } from '../../../lib/util/dates';
import { Map, Set } from 'immutable';
import { combineLatest, merge, Observable, Subject } from 'rxjs';
import {
  GeneralPdfTemplateSearch,
  GeneralPdfTemplateSearchService
} from '../../../lib/general-pdf-template/general-pdf-template-search-service';
import { InputMask } from '../../../util/input-masks';
import { Arrays } from '../../../lib/util/arrays';
import { Strings } from '../../../lib/util/strings';
import { DownloadedFile } from '../../../lib/util/downloaded-files';
import { saveAs } from 'file-saver';
import { RightResolver, RightService } from '../../../lib/right.service';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { AbstractControl, FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { FileItem, FileUploader, FileUploaderOptions, ParsedResponseHeaders } from 'ng2-file-upload';
import { FileUploaderUtil } from '../../../util/file-uploader-util';
import { UploadErrorLocalizer } from '../../../util/upload-error-localizer';
import { EmptyMessage } from '../../../lib/util/messages';
import { FieldError, FieldErrors } from '../../../lib/util/errors';
import { ResourceHelper } from '../../../lib/util/http-services';
import { debounceTime, flatMap, map } from 'rxjs/operators';
import { DisabledEnum, DisabledItem } from '../../../util/search-utils';
import { DropdownItemType } from '../../../shared/dropdown/dropdown-item/dropdown-item-type';
import { FileUploadComponent } from '../../../shared/file-upload/flat/file-upload.component';
/* eslint-enable */

@Component({
  selector: 'app-general-pdf-template',
  templateUrl: './general-pdf-template-list.component.html',
  styleUrls: ['./general-pdf-template-list.component.scss']
})
export class GeneralPdfTemplateListComponent implements OnInit, OnDestroy {
  GeneralPdfTemplate = GeneralPdfTemplate;
  SelectUtils = SelectUtils;
  UiConstants = UiConstants;
  InputMask = InputMask;
  DropdownItemType = DropdownItemType;

  compactSidebar: boolean = document.querySelector('body')!.classList.contains('sidebar-compact');
  breadcrumbParents: BreadcrumbParent[] = [];
  breadcrumbSelf: string;
  queryModel: QueryFieldModel<GeneralPdfTemplate.OrderField> = new QueryFieldModel(GeneralPdfTemplate.OrderField.ID, OrderType.DESC);
  rightModel: RightModel = RightModel.empty();
  searchModel: GeneralPdfTemplateSearchModel = new GeneralPdfTemplateSearchModel();

  codeOptions$: Observable<string[]>;

  private readonly formControlClickEvents: Map<string, Subject<void>> = Map.of('code', new Subject());

  generalPdfTemplateList: GeneralPdfTemplateModel[] = [];
  searchResult: GeneralPdfTemplateSearch.SearchDataResult;
  showSearch: boolean = false;
  disabledItems: DisabledItem[] = [];

  @ViewChild('f')
  fForm: NgForm;

  @ViewChild('baseDialog', { static: true }) baseDialog: ModalDirective;
  baseDialogVisible: boolean = false;
  baseGeneralPdfTemplateModel: GeneralPdfTemplateModel = new GeneralPdfTemplateModel();
  fieldErrors: GeneralPdfTemplateErrorMap;

  @ViewChild('fileUploadComponent', {static: false})
  fileUploadComponent: FileUploadComponent;

  formGroup: FormGroup;
  private formGroupValidationErrors: LocalFormGroupValidationErrors;
  fileUploadEmptyError: boolean = false;
  fileFormatError: boolean = false;
  templateSaving: boolean = false;

  constructor(
    private translateService: TranslateService,
    private generalPdfTemplateService: GeneralPdfTemplateService,
    private generalPdfTemplateSearchService: GeneralPdfTemplateSearchService,
    private rightService: RightService,
    fb: FormBuilder,
    private uploadUtil: FileUploaderUtil,
    private changeDetector: ChangeDetectorRef,
    private uploadErrorLocalizer: UploadErrorLocalizer,
    private resourceHelper: ResourceHelper
  ) {
    this.fieldErrors = {};
    this.formGroup = this.createFormGroup(fb);
    this.formGroupValidationErrors = LocalFormGroupValidationErrors.ofForm(this.createForwardingHtmlForm(), this.formGroup);
  }

  ngOnInit() {
    this.initBreadcrumb();
    this.loadRightModels();
    this.loadSearch(() => {
      this.initDisabledOptions(DisabledEnum.FALSE);
      this.loadList();
    });
    const codeClickEvent: Observable<void> = this.formControlClickEvents.get('code').asObservable();
    const codeChangeEvent: Observable<any> = this.formGroup.controls['code'].valueChanges;
    this.codeOptions$ = merge(codeClickEvent, codeChangeEvent).pipe(
      debounceTime(UiConstants.autocompleteDebounceTime),
      flatMap(
        (value) => {
          return this.generalPdfTemplateService.getAvailableSystemCodes({
            code: value === null ? undefined : value
          }).pipe(map((result) => {
            return result;
          }));
        }
      )
    );
  }

  ngOnDestroy() {
    this.saveSearch();
  }

  initBreadcrumb() {
    this.translateService.get('GENERAL_PDF_TEMPLATE_HEADER').subscribe(
      (result: string) => {
        this.breadcrumbSelf = result;
      }
    );
    this.translateService.get('MENU_NAVBAR_MENU_ADMINISTRATION').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.ADMIN_DASHBOARD});
      }
    );
  }

  private loadRightModels() {
    this.rightService.getRightResolver().subscribe(
      (resolver: RightResolver) => {
        this.rightModel = RightModel.of(resolver);
      }
    );
  }

  private loadSearch(completion: () => void) {
    const obs: Observable<SearchLoadResult> = combineLatest(
      this.generalPdfTemplateSearchService.getSearchData({}),
      (storedSearchData: GeneralPdfTemplateSearch.SearchDataResult) => {
        const result: SearchLoadResult = {
          storedSearchData: storedSearchData
        };
        return result;
      }
    );
    obs.subscribe(
      (result: SearchLoadResult) => {
        this.searchResult = result.storedSearchData;
        this.postInitSearch(result.storedSearchData, completion);
      }
    );
  }

  private createFormGroup(fb: FormBuilder): FormGroup {
    return fb.group(
      {
        code: fb.control(
          {value: this.baseGeneralPdfTemplateModel.code},
          [
            Validators.required,
          ]
        ),
      }
    );
  }

  hasLocalFieldError(formControlName?: string, errorCode?: string): boolean {
    this.formGroup.updateValueAndValidity();
    return this.formGroupValidationErrors.hasFieldError(formControlName, errorCode);
  }

  removeFieldError(fieldError?: FieldError) {
    FieldErrors.remove(this.fieldErrors, fieldError);
  }

  onAutoCompleteClick(formControlName: string) {
    const eventEmitter: Subject<void> = this.formControlClickEvents.get(formControlName);
    const c: AbstractControl = this.formGroup.controls[formControlName];
    const value: string = c.value;
    if (value === null || value.length === 0) {
      eventEmitter.next();
    }
  }

  private createForwardingHtmlForm() {
    return new ForwardingNgFormRef({
      formFn: () => {
        return this.fForm;
      }
    });
  }

  loadList(pageNumber?: number) {
    const requestedPage = pageNumber ? pageNumber : this.queryModel.currentPage;
    const order = this.queryModel.getOrder();
    const idSet: number[] = [];
    if (Strings.undefinedOrNonEmpty(this.searchModel.id)) {
      idSet.push(+this.searchModel.id);
    }
    const disabled: boolean | undefined = !this.searchModel.disabled ||
    this.searchModel.disabled.id === DisabledEnum.NONE ?
      undefined : this.searchModel.disabled.id === DisabledEnum.TRUE;
    this.generalPdfTemplateService.query({
      ids: idSet.length > 0 ? Set.of(...idSet) : undefined,
      code: Strings.undefinedOrNonEmpty(this.searchModel.code),
      disabled: disabled,
      orders: Set.of(order),
      paging: requestedPage ? {
        pageNumber: requestedPage,
        numberOfItems: this.queryModel.itemsPerPage
      } : undefined
    }).subscribe((result: QueryResult<GeneralPdfTemplate.GeneralPdfTemplate>) => {
      this.generalPdfTemplateList = [];
      result.items.forEach((generalPdfTemplate: GeneralPdfTemplate.GeneralPdfTemplate) => {
        const generalPdfTemplateModel = new GeneralPdfTemplateModel();
        generalPdfTemplateModel.id = generalPdfTemplate.id;
        generalPdfTemplateModel.code = generalPdfTemplate.code;
        generalPdfTemplateModel.version = generalPdfTemplate.version;
        generalPdfTemplateModel.creationTime = generalPdfTemplate.creationTime;
        generalPdfTemplateModel.updateTime = generalPdfTemplate.updateTime;
        generalPdfTemplateModel.disabled = generalPdfTemplate.disabled;
        generalPdfTemplateModel.systemCode = generalPdfTemplate.systemCode;
        this.generalPdfTemplateList.push(generalPdfTemplateModel);
      });
      this.queryModel.currentPage = requestedPage;
      this.queryModel.totalNumberOfItems = result.pagingResult.totalNumberOfItems;
      this.queryModel.currentNumberOfItems = result.pagingResult.currentNumberOfItems;
    });

  }

  private postInitSearch(storedSearchData: GeneralPdfTemplateSearch.SearchDataResult, completion: () => void) {
    this.queryModel.itemsPerPage = storedSearchData.searchData.itemsPerPage;
    this.queryModel.currentPage = storedSearchData.searchData.pageNumber;
    this.queryModel.setOrder(storedSearchData.searchData.order);
    this.searchModel.id = storedSearchData.searchData.id;
    this.searchModel.code = storedSearchData.searchData.code;
    this.searchModel.disabled = storedSearchData.searchData.disabled;
    completion()
  }

  private saveSearch() {
    const request = {
      searchData: {
        itemsPerPage: this.queryModel.itemsPerPage,
        pageNumber: this.queryModel.currentPage,
        order: this.queryModel.getOrder(),
        id: this.searchModel.id,
        code: this.searchModel.code,
        disabled: this.searchModel.disabled,
      }
    };
    this.generalPdfTemplateSearchService.setSearchData(request).subscribe(
      (result) => {
      },
      (error) => {
      }
    );
  }

  toggleSearch() {
    this.showSearch = !this.showSearch;
  }

  private initDisabledOptions(initValue: DisabledEnum) {
    this.disabledItems = [];
    const disabledEnums: DisabledEnum[] = [DisabledEnum.NONE, DisabledEnum.FALSE, DisabledEnum.TRUE];
    Arrays.iterateByIndex(disabledEnums, (key) => {
      const item = new DisabledItem();
      item.id = key;
      this.translateService.get('COMMON_VALUE_DISABLED_ENUM_' + key).subscribe(
        (text: string) => {
          item.text = text;
        }
      );
      this.disabledItems.push(item);
      if (!this.searchModel.disabled) {
        if (key === initValue) {
          this.searchModel.disabled = item;
        }
      }
    });
  }

  onSearchClicked() {
    this.loadList(1);
  }

  onSearchReset() {
    this.generalPdfTemplateSearchService.resetSearchData({}).subscribe(
      (result) => {
        this.loadSearch(() => {
          this.showSearch = true;
          this.loadList(1);
        });
      }
    );
  }


  orderBy(field: GeneralPdfTemplate.OrderField) {
    this.queryModel.onOrderFieldChanged(field);
    this.loadList(1);
  }

  pageChanged(selectedPage: number) {
    this.loadList(selectedPage);
  }

  itemsPerPageChanged(itemsPerPage: number) {
    this.queryModel.itemsPerPage = itemsPerPage;
    this.loadList(1);
  }

  setDisabled(event: any, generalPdfTemplate: GeneralPdfTemplate.GeneralPdfTemplate, disabled: boolean) {
    this.generalPdfTemplateService.setDisabled({
      code: generalPdfTemplate.code,
      disabled: disabled
    }).subscribe(
      (result: EmptyMessage) => {
        this.loadList();
      },
      (error: any) => {
        this.loadList();
      }
    );
  }

  exportDocument(model: GeneralPdfTemplateModel) {

    this.generalPdfTemplateService.export({
      code: model.code
    }).subscribe(
      (res: DownloadedFile) => {
        saveAs(res.getBlob(), res.getFileName('General_pdf_document_' + model.code + '.fodt'));
      });
  }

  showBaseDialog(code?: string) {
    if (code) {
      this.loadEditModel(code)
    }
    else {
      this.baseGeneralPdfTemplateModel = new GeneralPdfTemplateModel();
      // this.tableDocumentEditModel.status = TableDocumentEditStatus.CREATE;
      this.openBaseDialog();
    }
  }

  loadEditModel(code: string) {
    this.generalPdfTemplateService.get(
      {
        code: code
      }
    ).subscribe((template: GeneralPdfTemplate.GeneralPdfTemplate) => {
      this.baseGeneralPdfTemplateModel = new GeneralPdfTemplateModel();

      this.baseGeneralPdfTemplateModel.code = template.code;
      this.baseGeneralPdfTemplateModel.version = template.version;
      this.baseGeneralPdfTemplateModel.isEdit = true;
      this.openBaseDialog();
    });
  }

  openBaseDialog() {
    this.baseDialogVisible = true;
    this.baseDialog.show();
    this.changeDetector.detectChanges();
    this.formGroupValidationErrors = LocalFormGroupValidationErrors.ofForm(this.createForwardingHtmlForm(), this.formGroup);
    this.fileUploadComponent.uploader.clearQueue();
    this.fileUploadComponent.uploader.cancelAll();
    this.fileUploadEmptyError = false;
    this.fileFormatError = false;
    this.formGroup.reset();
  }

  closeBaseDialog() {
    this.baseDialogVisible = false;
    this.baseDialog.hide();
    this.formGroup.reset();
  }

  createGeneralPdfTemplate() {
    this.templateSaving = true;

    let error: boolean = false;

    this.fileUploadEmptyError = false;
    this.fileFormatError = false;
    this.formGroup.updateValueAndValidity();
    if (this.formGroup.invalid && !this.baseGeneralPdfTemplateModel.isEdit) {
      this.formGroup.get('code')!.markAsTouched();
      error = true;
    }

    if (this.fileUploadComponent.uploader.queue.length === 0) {
      this.fileUploadEmptyError = true;
      error = true;
    }
    else {

      const fileName = this.fileUploadComponent.uploader.queue[0].file.name.toLocaleLowerCase();
      const regex = new RegExp('(.*?)\.(fodt)$');
      const regexTest = regex.test(fileName);

      if (!regexTest) {
        this.fileFormatError = true;
        error = true;
      }
    }

    if (error) {
      this.templateSaving = false;
      return;
    }

    const version = this.baseGeneralPdfTemplateModel.version ? this.baseGeneralPdfTemplateModel.version : 0;
    const url = this.resourceHelper.getBaseUrl() + '/general-pdf/template/' +
      this.baseGeneralPdfTemplateModel.code + '?v=' + version;

    const options: FileUploaderOptions = this.fileUploadComponent.uploader.options;
    options.url = url;
    this.fileUploadComponent.uploader.setOptions(options);
    this.fileUploadComponent.uploader.uploadAll();
  }

  getBaseModalTitle(): string {
    if (this.baseGeneralPdfTemplateModel.isEdit) {
      return 'GENERAL_PDF_TEMPLATE_EDIT';
    }
    else {
      return 'GENERAL_PDF_TEMPLATE_CREATE';
    }
  }

  onUploadResult(result: boolean) {
    if (result) {
      this.templateSaving = false;
      this.closeBaseDialog();
      this.loadList();
    }
    else {
      this.templateSaving = false;
    }
  }
}

interface SearchLoadResult {
  storedSearchData: GeneralPdfTemplateSearch.SearchDataResult,
}


class GeneralPdfTemplateModel {
  id: number;
  code: string = '';
  version: number;
  creationTime?: OffsetDateTime;
  updateTime?: OffsetDateTime;
  disabled: boolean = false;
  systemCode: boolean = false;
  isEdit: boolean = false;
}

class GeneralPdfTemplateSearchModel {
  id: string;
  code: string;
  disabled?: DisabledItem;

  public isEmpty(): boolean {
    return this.id.length === 0
      && this.code.length === 0
      && OptionItem.idOrUndefined(this.disabled) === undefined
      ;
  }

  public clear() {
    this.id = '';
    this.code = '';
    this.disabled = undefined;
  }

  constructor() {
  }

}

interface GeneralPdfTemplateErrorMap {
  code?: FieldError;
}
