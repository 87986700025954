/* eslint-disable */
import { AfterViewInit, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { InputMask } from '../../../../../../util/input-masks';
import { InvoiceItemModel } from '../form-record-invoice-field.component';
import { INVOICE_VALID_CURRENCY_CODE } from '../../../../../invoicing/invoices/invoice-create/invoice-create-clone.model';
import { Invoice, InvoiceService } from '../../../../../../lib/invoice/invoice/invoice.service';
import { merge, Observable, Subject } from 'rxjs';
import { NgModel } from '@angular/forms';
import { Map, Set } from 'immutable';
import { debounceTime, flatMap, map } from 'rxjs/operators';
import { OptionItem, UiConstants } from '../../../../../../util/core-utils';
import { OrderType } from '../../../../../../lib/util/services';
import { FormRecord } from '../../../../../../lib/form/form-record.service';
import { Models } from '../../../../../../util/model-utils';
import { Strings } from '../../../../../../lib/util/strings';
import { VatRate } from '../../../../../../lib/vat-rate.service';
import InvoiceItem = FormRecord.InvoiceItem;

/* eslint-enable */

@Component({
  selector: 'app-form-record-invoice-field-item-edit',
  templateUrl: './form-record-invoice-field-item-edit.component.html',
  styleUrls: ['./form-record-invoice-field-item-edit.component.scss']
})
export class FormRecordInvoiceFieldItemEditComponent implements AfterViewInit {

  InputMask = InputMask;
  VatRate = VatRate;

  m: InvoiceItemEditModel;

  @Input()
  isRemovable: boolean = true;

  @Input()
  readonly: boolean = false;

  @Input()
  selectableVatRates: VatRate.VatRate[] = [];

  @Input()
  selectableZeroVatRateTypes: OptionItem<VatRate.ZeroVatRateType>[] = [];

  @ViewChild('recordName', { static: true })
  recordName: NgModel;

  @ViewChild('externalId', { static: true })
  externalId: NgModel;

  @ViewChild('category', { static: true })
  category: NgModel;

  itemOptions$: Observable<InvoiceItem[]>;

  availableCategories$: Observable<string[]>;

  private readonly formControlClickEvents: Map<string, Subject<void>> = Map.of('recordName', new Subject(),
    'externalId', new Subject(), 'category', new Subject());

  @Input()
  set model(model: InvoiceItemModel) {
    this.m = new InvoiceItemEditModel();
    this.m.id = model.id;
    this.m.amount = model.amount;
    this.m.comment = model.comment;
    this.m.currencyCode = model.currencyCode;
    this.m.externalId = model.externalId;
    this.m.category = model.category;
    this.m.hunVtszNumber = model.hunVtszNumber;
    this.m.netUnitPrice = model.netUnitPrice;
    this.m.recordName = model.recordName;
    this.m.unitType = model.unitType;
    this.m.vatRate = model.vatRate;
    this.m.zeroVatRateType = model.zeroVatRateType;
    this.m.invoiced = model.invoiced;
  }

  get model(): InvoiceItemModel {
    const model = new InvoiceItemModel();
    model.id = this.m.id;
    model.amount = this.m.amount;
    model.comment = this.m.comment;
    model.currencyCode = this.m.currencyCode;
    model.externalId = this.m.externalId;
    model.category = this.m.category;
    model.hunVtszNumber = this.m.hunVtszNumber;
    model.netUnitPrice = this.m.netUnitPrice;
    model.recordName = this.m.recordName;
    model.unitType = this.m.unitType;
    model.vatRate = this.m.vatRate;
    model.zeroVatRateType = this.m.zeroVatRateType;
    model.editing = true;
    model.invoiced = this.m.invoiced;
    return model;
  }

  @Output()
  remove: EventEmitter<undefined> = new EventEmitter();

  @Output()
  save: EventEmitter<InvoiceItemModel> = new EventEmitter<InvoiceItemModel>();

  @Output()
  cancel: EventEmitter<undefined> = new EventEmitter();

  constructor(private invoiceService: InvoiceService) {
  }

  ngAfterViewInit() {
    const recordNameClickEvent: Observable<void> = this.formControlClickEvents.get('recordName').asObservable();
    const recordNameChangeEvent: Observable<any> = this.recordName.control.valueChanges;
    const externalIdClickEvent: Observable<void> = this.formControlClickEvents.get('externalId').asObservable();
    const externalIdChangeEvent: Observable<any> = this.externalId.control.valueChanges;
    const categoryClickEvent: Observable<void> = this.formControlClickEvents.get('category').asObservable();
    const categoryChangeEvent: Observable<any> = this.category.control.valueChanges;
    this.itemOptions$ = merge(recordNameClickEvent, recordNameChangeEvent, externalIdClickEvent, externalIdChangeEvent).pipe(
      debounceTime(UiConstants.autocompleteDebounceTime),
      flatMap(
        (value) => {
          return this.invoiceService.getFormRecordInvoiceItems({
            recordName: Strings.undefinedOrNonEmpty(this.model.recordName),
            externalId: Strings.undefinedOrNonEmpty(this.model.externalId),
            order: Set.of({field: Invoice.FormRecordInvoiceItemOrderField.RECORD_NAME, type: OrderType.ASC}),
            paging: {
              numberOfItems: 20,
              pageNumber: 1
            }
          }).pipe(map((result) => {
            return result.items.toArray();
          }));
        }
      )
    );
    this.availableCategories$ = merge(categoryClickEvent, categoryChangeEvent).pipe(
      debounceTime(UiConstants.autocompleteDebounceTime),
      flatMap((value) => {
          return this.invoiceService.getFormRecordInvoiceItemCategories({
            category: Strings.undefinedOrNonEmpty(this.model.category),
            paging: {
              numberOfItems: 20,
              pageNumber: 1
            }
          }).pipe(map((result) => {
            return result.items.toArray();
          }));
        }
      )
    );
  }

  onAutoCompleteClick(formControlName: string) {
    const eventEmitter: Subject<void> = this.formControlClickEvents.get(formControlName);
    let value: string | undefined = undefined;
    if (formControlName === 'recordName') {
      value = this.recordName.value;
    }
    else if (formControlName === 'externalId') {
      value = this.externalId.value;
    }
    if (value && value.length === 0) {
      eventEmitter.next();
    }
  }

  onCategorySelected() {
    const eventEmitter: Subject<void> = this.formControlClickEvents.get('category');
    let value: string | undefined;
    value = this.category.value;
    if (value && value.length === 0) {
      eventEmitter.next();
    }
  }

  setItem(item: InvoiceItem) {
    this.m.recordName = item.recordName;
    this.m.externalId = item.externalId ? item.externalId : '';
    this.m.category = item.category ? item.category : '';
    this.m.unitType = item.unitType;
    this.m.vatRate = item.vatRate.toNumber();
    this.m.currencyCode = item.currencyCode;
    this.m.netUnitPrice = Models.decimalToString(item.netUnitPrice);
  }

  setCategory(cat: string) {
    // this.m.category = cat;
  }

  saveRecord() {
    this.save.emit(this.model);
  }

  displayName(item: InvoiceItem | string) {
    if (typeof item === 'string') {
      return item;
    }
    return item ? item.recordName : '';
  }

  displayExternalId(item: InvoiceItem | string) {
    if (typeof item === 'string') {
      return item;
    }
    return item ? item.externalId
      : '';
  }
}

class InvoiceItemEditModel {
  id: number;
  amount: string = '';
  comment: string = '';
  currencyCode: string = INVOICE_VALID_CURRENCY_CODE;
  externalId: string = '';
  category: string = '';
  hunVtszNumber: string = '';
  netUnitPrice: string = '';
  _recordName: string = '';
  unitType: string = '';
  vatRate: number;
  zeroVatRateType: string = '';
  invoiced?: boolean;

  set recordName(value: string) {
    this._recordName = value;
  }

  get recordName(): string {
    return this._recordName;
  }
}
