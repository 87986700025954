/* eslint-disable */
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Map as ImmutableMap } from 'immutable';
import { FieldError, } from '../util/errors';
import { StockRecord } from './stock-record.service';
import { StockTypeName } from '../../util/stock/stock-utils';
import { HttpClient } from '@angular/common/http';
import { BaseHttpService, ResourceHelper } from '../util/http-services';
import { ResourceQueryResult } from '../util/services';
import { DisableRequest, EmptyMessage, IdentityMessage, ListQuery } from '../util/messages';
import { DownloadedFile } from '../util/downloaded-files';
import { map } from 'rxjs/operators';
import { StockRecordLog, StockRecordLogQuery } from './stock-record-log.service';
/* eslint-enable */

export namespace Stock {

  export enum OrderField {
    ID,
    NAME,
    USER_NAME,
    CREATION_TIME,
    UPDATE_TIME,
  }

  export class Keys {

    private static readonly ID = 'id';
    private static readonly NAME = 'name';
    private static readonly USER_NAME = 'user_name';
    private static readonly CREATION_TIME = 'creation_time';
    private static readonly UPDATE_TIME = 'update_time';

    private static readonly orderFieldKeyMap: ImmutableMap<Stock.OrderField, string> = ImmutableMap.of(
      Stock.OrderField.ID, Keys.ID,
      Stock.OrderField.NAME, Keys.NAME,
      Stock.OrderField.USER_NAME, Keys.USER_NAME,
      Stock.OrderField.CREATION_TIME, Keys.CREATION_TIME,
      Stock.OrderField.UPDATE_TIME, Keys.UPDATE_TIME,
    );

    public static toOrderFieldKey(field: Stock.OrderField): string {
      return Keys.orderFieldKeyMap.get(field)!;
    }

  }

}

export interface StockQuery extends ListQuery.Paging, ListQuery.Order, ListQuery.QueryText, ListQuery.Fields {
  id?: string;
  name?: string;
  owner_user_id?: string;
  company_id?: string;
  customer_record_id?: string;
  contact_location_id?: string;
  stock_item_ids?: string;
  central?: boolean;
  waste?: boolean;
  excluded_ids?: string;
  type?: string;
  disabled?: boolean;
  no_progress_bar?: boolean;
}

export interface GetStockQuery {
  id: number;
  fields?: string;
  with_sums?: boolean;
}

export interface StockFieldErrorMap {
  name?: FieldError;
  externalId?: FieldError;
  owner_user_id?: FieldError;
  company_id?: number;
  customer_record_id?: number;
  contact_location_id?: number;
  central?: boolean;
  waste?: boolean;
}

export interface StockItemUpdateFieldErrorMap {
  amount?: FieldError;
  intake_unit_net_price?: FieldError;
}

export interface CreateStockRequest {
  name: string;
  external_id?: string;
  owner_user_ids: number[];
  company_id?: number;
  customer_record_id?: number;
  contact_location_id?: number;
  central: boolean;
  waste: boolean;
  type: StockTypeName;
}

export interface Stock {
  id: number;
  name: string;
  external_id: string;
  type: StockTypeName;
  creation_time: string;
  update_time: string;
  disabled: boolean;
  records: StockRecord[];
  owner_user_ids: number[];
  company_id?: number;
  customer_record_id?: number;
  contact_location_id?: number;
  central: boolean;
  waste: boolean;
  stock_sums?: StockSums[];
}

export interface StockSums {
  amount: number;
  intake_sum_net_price: number;
  currency_code: string;
}

export interface UpdateStockRequest extends CreateStockRequest, IdentityMessage {
}

@Injectable()
export class StockService extends BaseHttpService {

  query(request: StockQuery): Observable<ResourceQueryResult<Stock>> {
    return this.http.get<ResourceQueryResult<Stock>>(this.url, this.parseParams(request));
  }

  get(request: GetStockQuery): Observable<Stock> {
    return this.http.get<Stock>(this.url + `${request.id}`, this.parseParams(request));
  }

  create(request: CreateStockRequest): Observable<EmptyMessage> {
    return this.http.post(this.url, request);
  }

  update(request: UpdateStockRequest): Observable<EmptyMessage> {
    return this.http.put(this.url + `${request.id}`, request);
  }

  setDisabled(request: DisableRequest): Observable<EmptyMessage> {
    return this.http.patch(this.url + `${request.id}/disabled`, request);
  }

  exportXls(id: number): Observable<DownloadedFile> {
    return this.http.get(this.url + id + '/export-xls', {
      observe: 'response',
      responseType: 'blob'
    }).pipe(map((res) => {
      return new DownloadedFile(res);
    }));
  }

  stockRecordLogQuery(request: StockRecordLogQuery): Observable<ResourceQueryResult<StockRecordLog>> {
    return this.http.get<ResourceQueryResult<StockRecordLog>>(this.url + `${request.stock_id}/stock-record-logs/`,
      this.parseParams(request));
  }

  constructor(private http: HttpClient, resourceHelper: ResourceHelper) {
    super(resourceHelper, '/stocks/');
  }

}
