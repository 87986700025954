/* eslint-disable */
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Set } from 'immutable';
import { AuthResult, AuthService, Right, UserProfile, } from './auth.service';
import { PermissionDefinition } from '../app.permission-definitions';
import { Observable } from 'rxjs';
import { Cacheable } from 'ts-cacheable';
import { UiConstants } from '../util/core-utils';
import { RightDefinition } from '../app.right-definitions';
/* eslint-enable */

@Injectable()
export class RightService {

  constructor(private authService: AuthService) {
  }

  @Cacheable({
    maxAge: UiConstants.defaultCacheMaxAge,
    slidingExpiration: true
  })
  public getRightResolver(): Observable<RightResolver> {
    return this.authService.check({}).pipe(map((authResult: AuthResult) => {
      let perms = authResult.granted_permissions;
      if (!perms) {
        perms = [];
      }
      let rights = authResult.granted_rights;
      if (!rights) {
        rights = [];
      }
      return new RightResolver(
        Set.of(...perms),
        Set.of(...rights),
        authResult.user_profile
      );
    }));
  }

}

export class RightResolver {

  public constructor(
    private grantedPermissions: Set<PermissionDefinition>,
    private grantedRights: Set<RightDefinition>,
    public readonly userProfile?: UserProfile) {
  }

  public getGrantedPermissions(obj: Right | RightDefinition): GrantedPermissionSet {
    if (obj instanceof Right) {
      return this.getGrantedPermissionsByRight(obj);
    }
    else {
      return this.getGrantedPermissionsByRightDefinition(obj);
    }
  }

  private getGrantedPermissionsByRight(right: Right): GrantedPermissionSet {
    return new GrantedPermissionSetByRight(right.filter(this.grantedPermissions));
  }

  private getGrantedPermissionsByRightDefinition(rightDefinition: RightDefinition): GrantedPermissionSet {
    return new GrantedPermissionSetByRightDefinition(this.grantedRights, rightDefinition);
  }

}

export class GrantedPermissionSet {
  static each(set: GrantedPermissionSet[]) {
    for (let i = 0; i < set.length; i++) {
      if (!set[i].hasRight()) {
        return false;
      }
    }
    return true;
  }

  // Must be overriden
  hasRight(): boolean {
    return false;
  }

  // Must be overriden
  contains(permission: PermissionDefinition): boolean {
    return false;
  }
}

export namespace GrantedPermissionSet {

  export function empty(): GrantedPermissionSet {
    return new GrantedPermissionSetByRight(Set.of<PermissionDefinition>());
  }

}

export interface GrantedPermissionSetResolver {

  of(rightDefinition: RightDefinition): GrantedPermissionSet;

}

export namespace GrantedPermissionSetResolver {

  export function empty(): GrantedPermissionSetResolver {
    return new GrantedPermissionSetResolverAbsent();
  }

  export function byGrantedRights(
    grantedRights: Set<RightDefinition>): GrantedPermissionSetResolver {
    return new GrantedPermissionSetResolverPresent(grantedRights);
  }

}

class GrantedPermissionSetResolverPresent implements GrantedPermissionSetResolver {

  constructor(private readonly grantedRights: Set<RightDefinition>) {
  }

  public of(rightDefinition: RightDefinition): GrantedPermissionSet {
    return new GrantedPermissionSetByRightDefinition(this.grantedRights, rightDefinition);
  }

}

class GrantedPermissionSetResolverAbsent implements GrantedPermissionSetResolver {

  constructor() {
  }

  public of(rightDefinition: RightDefinition): GrantedPermissionSet {
    return GrantedPermissionSet.empty();
  }

}

class GrantedPermissionSetByRight implements GrantedPermissionSet {

  constructor(private permissions: Set<PermissionDefinition>) {
  }

  public hasRight(): boolean {
    return this.permissions.size > 0;
  }

  public contains(permission: PermissionDefinition): boolean {
    return this.permissions.contains(permission);
  }

}

class GrantedPermissionSetByRightDefinition implements GrantedPermissionSet {

  private readonly _hasRight: boolean;

  constructor(private grantedRights: Set<RightDefinition>, private rightDefinition: RightDefinition) {
    this._hasRight = grantedRights.contains(rightDefinition);
  }

  public hasRight(): boolean {
    return this._hasRight;
  }

  public contains(permission: PermissionDefinition): boolean {
    return false;
  }

}
