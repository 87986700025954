/* eslint-disable */
import { OptionItem } from '../core-utils';
import { DocumentGroupEditModel } from '../document-group.utils';
import { DisabledItem } from '../search-utils';
/* eslint-enable */

// HTML DOCUMENT
export class DocumentHTMLListModel {
  id: number | null = null;
  name: string = '';
  code: string = '';
  value: string = '';
  comment: string = '';
  document_group?: DocumentGroupItem;
  language_code?: DocumentLanguageItem;
  creation_time: string = '';
  update_time: string = '';
  disabled: boolean = false;
}

export class DocumentHTMLEditModel {
  id: number | null = null;
  name: string = '';
  code: string = '';
  value: string = '';
  comment: string = '';
  document_group?: DocumentGroupItem;
  language_code?: DocumentLanguageItem;
  creation_time: string = '';
  update_time: string = '';
  disabled: boolean = false;
}

export class DocumentHTMLReadModel {
  id: number | null = null;
  name: string = '';
  code: string = '';
  value: string = '';
  comment: string = '';
  document_group?: DocumentGroupItem;
  language_code?: DocumentLanguageItem;
  creation_time: string = '';
  update_time: string = '';
  disabled: boolean = false;
}

export class DocumentHTMLSearchModel {
  name?: string;
  code?: string;
  value?: string;
  language_code?: DocumentLanguageItem | null = null;
  document_group?: DocumentGroupItem | null = null;
  disabled?: DisabledItem | null = null;
}

export class DocumentHTMLCreateModel extends DocumentHTMLEditModel  {
}
// ./HTML


// TEXT DOCUMENT
export class DocumentTextListModel {
  id: number | null = null;
  name: string = '';
  code: string = '';
  value: string = '';
  comment: string = '';
  document_group?: DocumentGroupItem;
  language_code: string = '';
  creation_time: string = '';
  update_time: string = '';
  disabled: boolean = false;
}

export class DocumentTextEditModel {
  id: number | null = null;
  name: string = '';
  code: string = '';
  value: string = '';
  comment: string = '';
  document_group?: DocumentGroupItem;
  language_code?: DocumentLanguageItem;
  creation_time: string = '';
  update_time: string = '';
  disabled: boolean = false;
}

export class DocumentTextReadModel {
  id: number | null = null;
  name: string = '';
  code: string = '';
  value: string = '';
  comment: string = '';
  document_group?: DocumentGroupItem;
  language_code?: DocumentLanguageItem;
  creation_time: string = '';
  update_time: string = '';
  disabled: boolean = false;
}

export class DocumentTextSearchModel {
  name?: string;
  code?: string;
  value?: string;
  language_code?: DocumentLanguageItem;
  document_group?: DocumentGroupItem;
  disabled?: DisabledItem;

  public isEmpty(): boolean {
    return this.name === undefined
      && this.code === undefined
      && this.value === undefined
      && OptionItem.idOrUndefined(this.disabled) === undefined
      && (this.language_code === undefined || (this.language_code !== undefined && this.language_code.id === null))
      && (this.document_group === undefined || (this.document_group !== undefined && this.document_group.id === null))
      ;
  }


}

export class DocumentTextCreateModel extends DocumentTextEditModel  {
}
// ./TEXT


export class DocumentLanguageItem extends OptionItem<string> {
}


export class DocumentGroupCreateModel extends DocumentGroupEditModel {

}


export class DocumentGroupItem extends OptionItem<number> {
}
