<div class="breadcrumb-container" [ngClass]="{ 'breadcrumb-container-compact': compactSidebar }" id="breadcrumb">
  <div class="breadcrumb-component">
    <app-breadcrumb
      [parents]="breadcrumbParents"
      [self]="breadcrumbSelf">
    </app-breadcrumb>
  </div>
  <div class="breadcrumb-menu d-flex">
    <!--CREATE-->
    <div *ngIf="rightModel.documentLinkCreate.hasRight()"
         class="breadcrumb-button-icon-container cursor-pointer negative-margin-right"
         uiSref="Admin.DocumentLinkCreate">
      <a class="btn-setting d-flex align-items-center" title="{{'COMMON_BUTTON_ADD' | translate}}">
        <i class="icomoon icomoon-add"></i>
        <span class="breadcrumb-button-text-visible">{{'COMMON_BUTTON_ADD' | translate}}</span>
      </a>
    </div>
  </div>
</div>
<div class="breadcrumb-placeholder"></div>
<div class="container-horizontal-padding container-vertical-padding animated fadeIn">

  <!--SEARCH-->
  <div class="card">
    <div class="card-header">
      {{'DOCUMENT_LINK_PANEL_HEADING_LIST' | translate}}

      <div class="card-actions">
        <a class="btn-setting cursor-pointer" (click)="toggleSearch()" title="{{'COMMON_SHOW_SEARCH_HINT' | translate}}"><i #searchIcon class="icomoon icomoon-search"></i></a>
      </div><!-- /.card-actions -->
    </div>

    <div class="card-body border-bottom" *ngIf="showSearch">

      <div class="row form-group">

        <!--NAME-->
        <div class="col-md-4">
          <label class="search-label">{{'COMMON_NAME' | translate}}</label>
          <input type="text" class="form-control" placeholder="{{'COMMON_NAME' | translate}}"
                 maxlength="{{UiConstants.maximumVarcharLength}}"
                 (keyup.enter)="onSearchClicked()"
                 [(ngModel)]="searchModel.name" name="name" id="name" #name="ngModel">
        </div>

        <!--CODE-->
        <div class="col-md-4">
          <label class="search-label">{{'DOCUMENT_HTML_SEARCH_CODE' | translate}}</label>
          <input type="text" class="form-control" placeholder="{{'DOCUMENT_HTML_SEARCH_CODE' | translate}}"
                 maxlength="{{UiConstants.maximumVarcharLength}}"
                 (keyup.enter)="onSearchClicked()"
                 [(ngModel)]="searchModel.code" name="code" id="code" #name="ngModel">
        </div>

        <!--VALUE-->
        <div class="col-md-4">
          <label class="search-label">{{'DOCUMENT_HTML_SEARCH_VALUE' | translate}}</label>
          <input type="text" class="form-control" placeholder="{{'DOCUMENT_HTML_SEARCH_VALUE' | translate}}"
                 maxlength="{{UiConstants.maximumVarcharLength}}"
                 (keyup.enter)="onSearchClicked()"
                 [(ngModel)]="searchModel.value" name="value" id="value" #name="ngModel">
        </div>
      </div>

      <div class="row form-group">

        <!--LANGUAGE-->
        <div class="col-md-4">
          <label class="search-label">{{'DOCUMENT_HTML_SEARCH_LANGUAGE' | translate}}</label>
          <select class="form-control"
                  [(ngModel)]="searchModel.language_code"
                  id="language"
                  name="language"
                  #state="ngModel">
            <option *ngFor="let item of documentLanguageList" [ngValue]="item">{{item.text}}</option>
          </select>
        </div>

        <!--DOCUMENT GROUP-->
        <div class="col-md-4">
          <label class="search-label">{{'DOCUMENT_HTML_SEARCH_DOCUMENT_GROUP' | translate}}</label>
          <select class="form-control"
                  [(ngModel)]="searchModel.document_group"
                  id="documentGroup"
                  name="documentGroup"
                  #documentGroup="ngModel">
            <option *ngFor="let item of documentGroupList" [ngValue]="item">{{item.text}}</option>
          </select>
        </div>

        <!--DISABLED-->
        <div class="col-md-4">
          <label class="search-label">{{'COMMON_STATE' | translate}}</label>
          <select class="form-control"
                  [(ngModel)]="searchModel.disabled"
                  id="disabled"
                  name="disabled"
                  #disabled="ngModel"
                  [compareWith]="SelectUtils.compareObjects">
            <option *ngFor="let item of disabledItems" [ngValue]="item">{{item.text}}</option>
          </select>
        </div>
      </div>
      <!--SEARCH-->
      <div class="col-md-12 d-flex justify-content-md-end align-items-end px-0">
        <div class="btn-group" role="group">
          <button type="button" (click)="onSearchReset()" class="btn btn-outline-primary search-button mr-1">
            {{'COMMON_BUTTON_SEARCH_DELETE' | translate}}
          </button>
          <button type="button" (click)="onSearchClicked()"  class="btn btn-primary search-button">{{'COMMON_BUTTON_SEARCH' | translate}}</button>
        </div>
      </div>

    </div><!-- /.card-body-->

    <div class="card-body">

      <table class="table table-striped table-bordered">
        <thead>
        <tr>
          <th class="table-sorter-header hidden-xs-down">
            <app-table-field-sorter [orderField]="DocumentLinkList.OrderField.ID"
                                    [orderType]="queryModel.getOrderType(DocumentLinkList.OrderField.ID)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_TABLE_HEADER_ID' | translate">
            </app-table-field-sorter>
          </th>
          <th class="table-sorter-header long hidden-xs-down">
            <app-table-field-sorter [orderField]="DocumentLinkList.OrderField.NAME"
                                    [orderType]="queryModel.getOrderType(DocumentLinkList.OrderField.NAME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_NAME' | translate">
            </app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-xs-down">
            <app-table-field-sorter [orderField]="DocumentLinkList.OrderField.DOCUMENT_GROUP_NAME"
                                    [orderType]="queryModel.getOrderType(DocumentLinkList.OrderField.DOCUMENT_GROUP_NAME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_TABLE_HEADER_DOCUMENT_GROUP' | translate">
            </app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-sm-down">
            <app-table-field-sorter [orderField]="DocumentLinkList.OrderField.CREATION_TIME"
                                    [orderType]="queryModel.getOrderType(DocumentLinkList.OrderField.CREATION_TIME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_TABLE_HEADER_CREATION_TIME' | translate">
            </app-table-field-sorter>
          </th>
          <th class="table-sorter-header w-table-options-menu">
            <app-table-sorter-no-op></app-table-sorter-no-op>
          </th>
        </tr>
        </thead>

        <tbody>
          <tr *ngFor="let documentModel of documentListModel | paginate: {
                                                      itemsPerPage: queryModel.itemsPerPage,
                                                      currentPage: queryModel.currentPage,
                                                      totalItems: queryModel.currentNumberOfItems }" [disabledTableRow]="documentModel.disabled">
            <td class="responsive-table-column fit align-middle"> {{ documentModel.id }} </td>
            <td class="responsive-table-column long align-middle">
              <div class="d-flex">
                <div class="d-flex flex-column justify-content-center">
                  <a *ngIf="rightModel.documentLinkUpdate.hasRight()"
                     class="id-text cursor-pointer"
                     href="#" onclick="return false"
                     uiSref="Admin.DocumentLinkEdit"
                     [uiParams]="{ id: documentModel.id }"
                     title="{{'COMMON_BUTTON_EDIT' | translate}}">
                    {{documentModel.name}}
                  </a>
                  <span *ngIf="!rightModel.documentLinkUpdate.hasRight()">{{documentModel.name}}</span>
                  <span class="text-truncate table-text-subtitle">
                  {{ documentModel.code }}
                </span>
                </div>
              </div>
            </td>
            <td class="responsive-table-column hidden-xs-down align-middle"> {{ documentModel.document_group.text }} </td>
            <td class="responsive-table-column hidden-sm-down align-middle"> {{ documentModel.creation_time | date: 'short' }} </td>
            <td class="align-middle">
              <app-table-options-menu>
                <app-dropdown-item
                  [iconClass]="'icomoon-detail'"
                  [titleStringKey]="'COMMON_BUTTON_DETAILS'"
                  class="cursor-pointer"
                  uiSref="Admin.DocumentLinkRead"
                  [uiParams]="{ id: documentModel.id }">
                </app-dropdown-item>
                <app-dropdown-item
                  *ngIf="rightModel.documentLinkUpdate.hasRight()"
                  [iconClass]="'icomoon-modify'"
                  [titleStringKey]="'COMMON_BUTTON_EDIT'"
                  class="cursor-pointer"
                  uiSref="Admin.DocumentLinkEdit"
                  [uiParams]="{ id: documentModel.id }">
                </app-dropdown-item>
                <app-dropdown-item
                  *ngIf="documentModel.disabled && rightModel.documentLinkDisable.hasRight()"
                  [iconClass]="'icomoon-enable'"
                  [titleStringKey]="'COMMON_BUTTON_ENABLE'"
                  [itemType]="DropdownItemType.ENABLE"
                  class="cursor-pointer"
                  (click)="setDisabled($event, documentModel, false)">
                </app-dropdown-item>
                <app-dropdown-item
                  *ngIf="!documentModel.disabled && rightModel.documentLinkDisable.hasRight()"
                  [iconClass]="'icomoon-disable'"
                  [titleStringKey]="'COMMON_BUTTON_DISABLE'"
                  [itemType]="DropdownItemType.DISABLE"
                  class="cursor-pointer"
                  (click)="setDisabled($event, documentModel, true)">
                </app-dropdown-item>
              </app-table-options-menu>
            </td>
          </tr>
        </tbody>

        <tfoot>
        <tr>
          <th class="table-sorter-header hidden-xs-down">
            <app-table-field-sorter [orderField]="DocumentLinkList.OrderField.ID"
                                    [orderType]="queryModel.getOrderType(DocumentLinkList.OrderField.ID)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_TABLE_HEADER_ID' | translate">
            </app-table-field-sorter>
          </th>
          <th class="table-sorter-header long hidden-xs-down">
            <app-table-field-sorter [orderField]="DocumentLinkList.OrderField.NAME"
                                    [orderType]="queryModel.getOrderType(DocumentLinkList.OrderField.NAME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_NAME' | translate">
            </app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-xs-down">
            <app-table-field-sorter [orderField]="DocumentLinkList.OrderField.DOCUMENT_GROUP_NAME"
                                    [orderType]="queryModel.getOrderType(DocumentLinkList.OrderField.DOCUMENT_GROUP_NAME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_TABLE_HEADER_DOCUMENT_GROUP' | translate">
            </app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-sm-down">
            <app-table-field-sorter [orderField]="DocumentLinkList.OrderField.CREATION_TIME"
                                    [orderType]="queryModel.getOrderType(DocumentLinkList.OrderField.CREATION_TIME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_TABLE_HEADER_CREATION_TIME' | translate">
            </app-table-field-sorter>
          </th>
          <th class="table-sorter-header w-table-options-menu">
            <app-table-sorter-no-op></app-table-sorter-no-op>
          </th>
        </tr>
        </tfoot>

      </table>
      <app-table-paging
        [currentNumberOfItems]="queryModel.currentNumberOfItems"
        [totalNumberOfItems]="queryModel.totalNumberOfItems"
        [itemsPerPage]="queryModel.itemsPerPage"
        (pageChange)="pageChanged($event)"
        (itemsPerPageChange)="itemsPerPageChanged($event)">
      </app-table-paging>
    </div>
  </div>
</div>

