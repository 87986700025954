/* eslint-disable */
import { UserGroup } from '../../../../lib/user-group.service';
import { DeviceManagementService } from '../../../../lib/device-management.service';
import { CustomerRecord } from '../../../../lib/customer/customer-record.service';
import { DqlModel } from '../../../../lib/dql/dql.model';
import { MultiselectOptionItem, UiConstants } from '../../../../util/core-utils';
import { Set } from 'immutable';
import { OrderType, Services } from '../../../../lib/util/services';
import { RightModel } from '../../../../app.rights';
import { BehaviorSubject, empty, Observable } from 'rxjs';
import { Logger, LoggerFactory } from '../../../../util/logger-factory';
import { Company, CompanyService } from '../../../../lib/company/company.service';
import { CompanyLocation, CompanyLocationService } from '../../../../lib/company-location/company-location.service';
import { map, tap } from 'rxjs/operators';
import { ProcessMultiselectProvider } from '../../../../lib/process/process-multiselect.provider';
import { CustomerRecordContactLocationMultiselectProvider } from '../../../../lib/customer/contact/customer-record-contact-location-multiselect.provider';
import { UserMultiselectProvider } from '../../../../lib/user/user-multiselect.provider';
import { UserGroupMultiselectProvider } from '../../../../lib/user-group/user-group-multiselect.provider';
import { CustomerRecordMultiselectProvider } from '../../../../lib/customer/customer-record-multiselect-provider.service';
import { CompanyMultiselectProvider } from '../../../../lib/company/company-multiselect.provider';
import { CompanyLocationMultiselectProvider } from '../../../../lib/company-location/company-location-multiselect.provider';
import {
  ContractNumberMultiselectProvider
} from "../../../../lib/customer/contract-number/contract-number-multiselect-provider.service";

/* eslint-enable */

export class DqlSearchFieldByIdLoader {

  private logger: Logger;

  private loader: (q?: string) => void;
  private selectedLoader: (idSet: Set<number>) => Observable<MultiselectOptionItem<number>[]>;

  private _data: BehaviorSubject<MultiselectOptionItem<number>[]> = new BehaviorSubject([]);

  private _dataType: DqlModel.QueryableFieldDataType = DqlModel.QueryableFieldDataType.UNKNOWN;

  private _rightModel: RightModel = RightModel.empty();

  constructor(private userMultiselectProvider: UserMultiselectProvider,
              private userGroupMultiselectProvider: UserGroupMultiselectProvider,
              private mobileAppService: DeviceManagementService,
              private customerRecordMultiselectProvider: CustomerRecordMultiselectProvider,
              private contactLocationMultiselectProvider: CustomerRecordContactLocationMultiselectProvider,
              private companyMultiselectProvider: CompanyMultiselectProvider,
              private companyLocationMultiselectProvider: CompanyLocationMultiselectProvider,
              private processMultiselectProvider: ProcessMultiselectProvider,
              private contractNumberMultiselectProvider: ContractNumberMultiselectProvider
  ) {
    this.logger = LoggerFactory.createLogger('DqlSearchFieldByIdLoader');
    this.initLoaderForDataType();
  }

  load(q?: string) {
    this.loader(q);
  }

  loadSelected(ids: Set<number>) {
    return this.selectedLoader(ids).pipe(tap((items: MultiselectOptionItem<number>[]) => {
      const currentData = this._data.getValue();
      items.forEach(item => {
        const d = currentData.find(i => i.id === item.id);
        if (!d) {
          currentData.unshift(item);
        }
        this._data.next(currentData);
      });
    }));
  }

  set dataType(dt: DqlModel.QueryableFieldDataType) {
    this._dataType = dt;
    this.initLoaderForDataType();
  }

  get dataType() {
    return this._dataType;
  }

  get data(): BehaviorSubject<MultiselectOptionItem<number>[]> {
    return this._data;
  }

  set rightModel(r: RightModel) {
    this._rightModel = r;
    this.initLoaderForDataType();
  }

  private initLoaderForDataType() {
    switch (this._dataType) {
      case DqlModel.QueryableFieldDataType.MOBILE_APP:
        if (this._rightModel.mobileAppRead.hasRight()) {
          this.loader = this.loadMobileAppData;
          this.selectedLoader = this.loadSelectedMobileAppData;
        }
        break;
      case DqlModel.QueryableFieldDataType.USER:
        if (this._rightModel.userRead.hasRight()) {
          this.loader = this.loadUserData;
          this.selectedLoader = this.loadSelectedUserData;
        }
        break;
      case DqlModel.QueryableFieldDataType.USER_GROUP:
        if (this._rightModel.userGroupRead.hasRight()) {
          this.loader = this.loadUserGroupData;
          this.selectedLoader = this.loadSelectedUserGroupData;
        }
        break;
      case DqlModel.QueryableFieldDataType.CUSTOMER:
        if (this._rightModel.customerRecordRead.hasRight()) {
          this.loader = this.loadCustomerData;
          this.selectedLoader = this.loadSelectedCustomerData;
        }
        break;
      case DqlModel.QueryableFieldDataType.CONTACT_LOCATION:
        if (this._rightModel.customerRecordRead.hasRight()) {
          this.loader = this.loadContactLocationData;
          this.selectedLoader = this.loadSelectedContactLocationData;
        }
        break;
      case DqlModel.QueryableFieldDataType.COMPANY:
        if (this._rightModel.companyRead.hasRight()) {
          this.loader = this.loadCompanyData;
          this.selectedLoader = this.loadSelectedCompanyData;
        }
        break;
      case DqlModel.QueryableFieldDataType.COMPANY_LOCATION:
        if (this._rightModel.companyLocationRead.hasRight()) {
          this.loader = this.loadCompanyLocationData;
          this.selectedLoader = this.loadSelectedCompanyLocationData;
        }
        break;
      case DqlModel.QueryableFieldDataType.PROCESS:
        if (this._rightModel.processRead.hasRight()) {
          this.loader = this.loadProcessData;
          this.selectedLoader = this.loadSelectedProcessData;
        }
        break;
      case DqlModel.QueryableFieldDataType.CONTRACT_NUMBER:
        if (this._rightModel.contractNumberRead.hasRight()) {
          this.loader = this.loadContractNumberData;
          this.selectedLoader = this.loadSelectedContractNumberData;
        }
        break;
      default:
        this.loader = this.loadNothing;
        this.selectedLoader = this.loadSelectedNothing;
        break;
    }
    this.loader();
  }

  private loadUserData(q?: string) {
    this.userMultiselectProvider.loadAll(q).subscribe(result => {
      this._data.next(result);
    });
  }

  private loadUserGroupData(q ?: string) {
    this.userGroupMultiselectProvider.loadAll(q).subscribe(result => {
      this._data.next(result);
    });
  }

  private loadMobileAppData(q ?: string) {
    this.mobileAppService.query({
      page_number: 1,
      number_of_items: UiConstants.autocompletePageSize,
      name: q,
      fields: 'id,name,device_model,latest_login_user_name,disabled',
      order: '+name',
      no_progress_bar: true
    }).subscribe(result => {
      const data: MultiselectOptionItem<number>[] = [];
      result.items.forEach(u => {
        data.push({
          id: u.id,
          itemName: u.name ? u.name : u.device_model,
          itemSubtitle: u.latest_login_user_name,
          disabled: u.disabled
        });
      });
      this._data.next(data);
    });
  }

  private loadCustomerData(q ?: string) {
    this.customerRecordMultiselectProvider.loadAll(q).subscribe(result => {
      this._data.next(result);
    });
  }

  private loadContactLocationData(q ?: string) {
    this.contactLocationMultiselectProvider.loadAll(q).subscribe(result => {
      this._data.next(result);
    });
  }

  private loadCompanyData(q ?: string) {
    this.companyMultiselectProvider.loadAll(q).subscribe(result => {
      this.data.next(result);
    });
  }

  private loadCompanyLocationData(q ?: string) {
    this.companyLocationMultiselectProvider.loadAll(q).subscribe(result => {
      this._data.next(result);
    });
  }

  private loadProcessData(q ?: string) {
    this.processMultiselectProvider.loadAll(q).subscribe(result => {
      this._data.next(result);
    });
  }

  private loadContractNumberData(q ?: string) {
    this.contractNumberMultiselectProvider.loadAll(q).subscribe(result => {
      this._data.next(result);
    });
  }

  private loadNothing(q?: string) {
    this.logger.debug('nothing to select from');
  }

  private loadSelectedUserData(ids: Set<number>): Observable<MultiselectOptionItem<number>[]> {
    if (ids.isEmpty()) {
      return empty();
    }
    return this.userMultiselectProvider.getByIds(ids.toArray());
  }

  private loadSelectedUserGroupData(ids: Set<number>): Observable<MultiselectOptionItem<number>[]> {
    if (ids.isEmpty()) {
      return empty();
    }
    return this.userGroupMultiselectProvider.getByIds(ids.toArray());
  }

  private loadSelectedMobileAppData(ids: Set<number>): Observable<MultiselectOptionItem<number>[]> {
    if (ids.isEmpty()) {
      return empty();
    }
    return this.mobileAppService.query({
      page_number: 1,
      number_of_items: UiConstants.autocompletePageSize,
      id: ids.join(','),
      fields: 'id,name,device_model,latest_login_user_name,disabled',
      order: 'name',
      no_progress_bar: true
    }).pipe(map(result => {
      const data: MultiselectOptionItem<number>[] = [];
      result.items.forEach(u => {
        data.push({
          id: u.id,
          itemName: u.name ? u.name : u.device_model + ' (' + u.latest_login_user_name + ')',
          disabled: u.disabled
        });
      });
      return data;
    }));
  }

  private loadSelectedCustomerData(ids: Set<number>): Observable<MultiselectOptionItem<number>[]> {
    if (ids.isEmpty()) {
      return empty();
    }
    return this.customerRecordMultiselectProvider.getByIds(ids.toArray());
  }

  private loadSelectedCompanyData(ids: Set<number>): Observable<MultiselectOptionItem<number>[]> {
    if (ids.isEmpty()) {
      return empty();
    }
    return this.companyMultiselectProvider.getByIds(ids.toArray());
  }

  private loadSelectedCompanyLocationData(ids: Set<number>): Observable<MultiselectOptionItem<number>[]> {
    if (ids.isEmpty()) {
      return empty();
    }
    return this.companyLocationMultiselectProvider.getByIds(ids.toArray());
  }

  private loadSelectedProcessData(ids: Set<number>): Observable<MultiselectOptionItem<number>[]> {
    if (ids.isEmpty()) {
      return empty();
    }
    return this.processMultiselectProvider.getByIds(ids.toArray());
  }

  private loadSelectedContractNumberData(ids: Set<number>): Observable<MultiselectOptionItem<number>[]> {
    if (ids.isEmpty()) {
      return empty();
    }
    return this.contractNumberMultiselectProvider.getByIds(ids.toArray());
  }

  private loadSelectedContactLocationData(ids: Set<number>): Observable<MultiselectOptionItem<number>[]> {
    if (ids.isEmpty()) {
      return empty();
    }
    return this.contactLocationMultiselectProvider.getByIds(ids.toArray());
  }

  private loadSelectedNothing(ids?: Set<number>): Observable<MultiselectOptionItem<number>[]> {
    this.logger.debug('nothing to select from');
    return empty();
  }

}
