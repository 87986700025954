import { Component, OnInit } from '@angular/core';
import { BreadcrumbParent } from '../../../shared/breadcrumb/breadcrumb/breadcrumb.component';
import { UIRouter, Transition } from '@uirouter/core';
import { StateName } from '../../../app.state-names';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { Trigger } from '../../../lib/trigger/trigger.service';
import { TaskService } from '../../../lib/task/task.service';
import { OptionItem, OptionItems, OwnerUserItem, OwnerUserItemFactory } from '../../../util/core-utils';
import { TriggerUtils } from '../../../lib/trigger/trigger-utils';
import { ResourceQueryResult, Services } from '../../../lib/util/services';
import { User, UserService } from '../../../lib/user.service';
import { UserGroup, UserGroupService } from '../../../lib/user-group.service';
import { Device, DeviceManagementService } from '../../../lib/device-management.service';
import { EmailTemplateService } from '../../../lib/email-template/email-template.service';
import { DocumentFileService } from '../../../lib/document/document-file.service';
import { DocumentGroupService } from '../../../lib/document/document-group.service';
import { GeneralPdfTemplateService } from '../../../lib/general-pdf-template/general-pdf-template.service';
import { GeneralPrinterTemplateService } from '../../../lib/general-printer-template/general-printer-template.service';
import { MessageTemplateService } from '../../../lib/message-template/message-template.service';
import { ToasterService } from '../../../fork/angular2-toaster/src/toaster.service';
import { TransportDocument } from '../../../lib/transport/transport-document/transport-document.service';
import TransportDocumentType = TransportDocument.TransportDocumentType;
import { TransportTriggerService } from '../../../lib/transport/transport-trigger/transport-trigger.service';
import { Set } from 'immutable';
import { EmailAddressTypeService } from '../../../lib/email-address-type/email-address-type.service';

@Component({
  selector: 'app-trigger-detail',
  templateUrl: './trigger-detail.component.html',
  styleUrls: ['./trigger-detail.component.scss']
})
export class TriggerDetailComponent implements OnInit {

  breadcrumbParents: BreadcrumbParent[] = [];
  breadcrumbSelf: string;
  compactSidebar: boolean = document.querySelector('body')!.classList.contains('sidebar-compact');

  triggerParentId?: number;
  triggerId: number;
  triggerObservable: Observable<Trigger.Trigger>;
  triggerService: Trigger.TriggerService;
  triggerType: TriggerUtils.TriggerType;
  triggerScope: TriggerUtils.TriggerScope;

  pdfTriggerDetailModel: PdfTriggerDetailModel;
  receiptTriggerDetailModel: ReceiptTriggerDetailModel;
  emailTriggerDetailModel: EmailTriggerDetailModel;
  messageTriggerDetailModel: MessageTriggerDetailModel;

  constructor(private emailTemplateService: EmailTemplateService,
              private fileDocumentService: DocumentFileService,
              private documentGroupService: DocumentGroupService,
              private userService: UserService,
              private userGroupService: UserGroupService,
              private deviceService: DeviceManagementService,
              private generalPdfTemplateService: GeneralPdfTemplateService,
              private generalPrinterTemplateService: GeneralPrinterTemplateService,
              private messageTemplateService: MessageTemplateService,
              private ownerUserItemFactory: OwnerUserItemFactory,
              private toasterService: ToasterService,
              private router: UIRouter,
              private transition: Transition,
              private translateService: TranslateService,
              private taskService: TaskService,
              private transportTriggerService: TransportTriggerService,
              private emailAddressTypeService: EmailAddressTypeService) {
    this.triggerId = this.transition.params().id;
    this.triggerParentId = this.transition.params().triggerParentId ? this.transition.params().triggerParentId : undefined;
  }

  ngOnInit() {
    switch (this.router.stateService.current.name) {
      case StateName.TASK_TRIGGER_DETAIL:
        this.triggerScope = 'TASK';
        this.triggerService = this.taskService;
        this.initTaskBreadcrumb();
        this.triggerObservable = this.getTaskTriggerObservable();
        break;
      case StateName.TRANSPORT_TRIGGER_DETAIL:
        this.triggerScope = 'TRANSPORT';
        this.triggerService = this.transportTriggerService;
        this.initTransportBreadcrumb();
        this.triggerObservable = this.getTransportTriggerObservable();
        break;
    }
    this.loadTrigger();
  }

  loadTrigger() {
    this.triggerObservable.subscribe(trigger => {
      this.breadcrumbSelf = trigger.name;
      this.triggerType = trigger.type;
      switch (trigger.type) {
        case 'EMAIL':
          this.loadEmailTriggerModel(trigger);
          break;
        case 'MESSAGE':
          this.loadMessageTriggerModel(trigger);
          break;
        case 'PDF':
          this.loadPdfTriggerModel(trigger);
          break;
        case 'RECEIPT':
          this.loadReceiptTriggerModel(trigger);
          break;
      }
    });
  }

  loadPdfTriggerModel(trigger: Trigger.Trigger) {
    const event = TriggerUtils.notificationEvents.find(e => e.notificationEvent === trigger.event);
    this.pdfTriggerDetailModel = new PdfTriggerDetailModel();
    this.pdfTriggerDetailModel.name = trigger.name;
    this.pdfTriggerDetailModel.event = event ? event.stringKey : '';
    this.pdfTriggerDetailModel.pdfTemplate = trigger.pdf!.pdfTemplate.code;
    this.pdfTriggerDetailModel.namePattern = trigger.pdf!.namePattern;
  }

  loadEmailTriggerModel(trigger: Trigger.Trigger) {
    const event = TriggerUtils.notificationEvents.find(e => e.notificationEvent === trigger.event);
    this.emailTriggerDetailModel = new EmailTriggerDetailModel();
    this.emailTriggerDetailModel.name = trigger.name;
    this.emailTriggerDetailModel.event = event ? event.stringKey : '';
    this.emailTriggerDetailModel.emailTemplate = trigger.email!.emailTemplate.name;
    this.emailTriggerDetailModel.emailAddresses = trigger.email!.recipient.emailAddresses.join(', ');
    this.emailTriggerDetailModel.sendToEveryone = trigger.email!.recipient.sendToEveryone ? 'COMMON_YES' : 'COMMON_NO';
    this.emailTriggerDetailModel.ignoredWithEmptyRecipients = trigger.email!.ignoredWithEmptyRecipients ? 'COMMON_YES' : 'COMMON_NO';
    this.emailTriggerDetailModel.attachEachShipmentDocument =
      trigger.email!.attachment.attachEachShipmentDocument ? 'COMMON_YES' : '';
    this.emailTriggerDetailModel.attachInvoices = trigger.email!.attachment.attachInvoices ? 'COMMON_YES' : 'COMMON_NO';
    this.loadTransportDocumentTypes(trigger);
    this.loadFileDocuments(trigger);
    this.loadDocumentGroups(trigger);
    this.loadUsers(trigger);
    this.loadUserGroups(trigger);
    this.loadRelatedPersonTypes(trigger);
    this.loadEmailAddressTypes(trigger);
    switch (this.triggerScope) {
      case 'TASK':
        this.loadPdfTriggers(trigger);
        break;
      case 'TRANSPORT':
        this.loadTransportDocumentTypes(trigger);
        break;
    }
  }

  loadMessageTriggerModel(trigger: Trigger.Trigger) {
    const event = TriggerUtils.notificationEvents.find(e => e.notificationEvent === trigger.event);
    this.messageTriggerDetailModel = new MessageTriggerDetailModel();
    this.messageTriggerDetailModel.name = trigger.name;
    this.messageTriggerDetailModel.event = event ? event.stringKey : '';
    this.messageTriggerDetailModel.messageTemplate = trigger.message!.messageTemplate.name;
    this.messageTriggerDetailModel.sendToEveryone = trigger.message!.recipient.sendToEveryone ? 'COMMON_YES' : 'COMMON_NO';
    this.messageTriggerDetailModel.ignoredWithEmptyRecipients = trigger.message!.ignoredWithEmptyRecipients ? 'COMMON_YES' : 'COMMON_NO';
    this.loadUsers(trigger);
    this.loadUserGroups(trigger);
    this.loadMobileApplications(trigger);
    this.loadRelatedPersonTypes(trigger);
  }

  loadReceiptTriggerModel(trigger: Trigger.Trigger) {
    const event = TriggerUtils.notificationEvents.find(e => e.notificationEvent === trigger.event);
    this.receiptTriggerDetailModel = new ReceiptTriggerDetailModel();
    this.receiptTriggerDetailModel.name = trigger.name;
    this.receiptTriggerDetailModel.event = event ? event.stringKey : '';
    this.receiptTriggerDetailModel.receiptTemplate = trigger.receipt!.receiptTemplate.code;
    this.receiptTriggerDetailModel.namePattern = trigger.receipt!.namePattern;
  }

  loadFileDocuments(trigger: Trigger.Trigger) {
        this.fileDocumentService.query({
          id: trigger.email!.attachment.fileDocumentIds.join(',')
        }).subscribe(result => {
          const fileDocuments: OptionItem<number>[] = [];
          result.items.forEach((template) => {
            if (template) {
              const item = new OptionItem<number>();
              item.id = template.id;
              item.text = template.name;
              fileDocuments.push(item);
            }
          });
          this.emailTriggerDetailModel.fileDocuments = fileDocuments.map(item => item.text).join(', ');
        });
  }

  loadDocumentGroups(trigger: Trigger.Trigger) {
        this.documentGroupService.query({
          id: trigger.email!.attachment.documentGroupIds.join(',')
        }).subscribe(result => {
          const documentGroups: OptionItem<number>[] = [];
          result.items.forEach((group) => {
            if (group) {
              const item = new OptionItem<number>();
              item.id = group.id;
              item.text = group.name;
              documentGroups.push(item);
            }
          });
          this.emailTriggerDetailModel.documentGroups = documentGroups.map(item => item.text).join(', ');
        });
  }

  loadPdfTriggers(trigger: Trigger.Trigger) {
    const pdfTriggers: OptionItem<number>[] = [];
    this.triggerService.queryTriggers({
      triggerParentId: this.triggerParentId,
    }).subscribe(result => {
      result.items.forEach((trigger) => {
        if (trigger && trigger.type === 'EMAIL' &&
          trigger.email!.attachment.pdfTriggerIds &&
          trigger.email!.attachment.pdfTriggerIds!.contains(trigger.id)) {
          const item = new OptionItem<number>();
          item.id = trigger.id;
          item.text = trigger.name;
          pdfTriggers.push(item);
        }
      });
      this.emailTriggerDetailModel.pdfTriggers = pdfTriggers.map(item => item.text).join(', ');
    });
  }

  loadUsers(trigger: Trigger.Trigger) {
    const userIds: number[] = [];
    switch (trigger.type) {
      case 'EMAIL':
        userIds.push(...trigger.email!.recipient.userIds.toArray());
        break;
      case 'MESSAGE':
        userIds.push(...trigger.message!.recipient.userIds.toArray());
        break;
    }
    this.userService.query({
      id: userIds.join(','),
      fields: 'id,user_name,person_name,disabled',
    }).subscribe((users: ResourceQueryResult<User>) => {
      const sortedOwnerUsers: OwnerUserItem[] = this.ownerUserItemFactory.createAll(users.items);
      sortedOwnerUsers.sort(OptionItems.createNameComparator());
      switch (trigger.type) {
        case 'EMAIL':
          this.emailTriggerDetailModel.users = sortedOwnerUsers.map(item => item.text).join(', ');
          break;
        case 'MESSAGE':
          this.messageTriggerDetailModel.users = sortedOwnerUsers.map(item => item.text).join(', ');
          break;
      }
    })
  }

  loadUserGroups(trigger: Trigger.Trigger) {
    const userGroupIds: number[] = [];
    switch (trigger.type) {
      case 'EMAIL':
        userGroupIds.push(...trigger.email!.recipient.userGroupIds.toArray());
        break;
      case 'MESSAGE':
        userGroupIds.push(...trigger.message!.recipient.userGroupIds.toArray());
        break;
    }
    this.userGroupService.query({
      id: userGroupIds.join(','),
      order: Services.createOrderFieldParameter(UserGroup.Keys.toOrderFieldKey, Set.of(UserGroup.DEFAULT_ORDER))
    }).subscribe((groups: ResourceQueryResult<UserGroup>) => {
      const userGroups: OptionItem<number>[] = [];
      groups.items.forEach(group => {
        const item = new OptionItem<number>();
        item.id = group.id;
        item.text = group.name;
        userGroups.push(item);
      });
      switch (trigger.type) {
        case 'EMAIL':
          this.emailTriggerDetailModel.userGroups = userGroups.map(item => item.text).join(', ');
          break;
        case 'MESSAGE':
          this.messageTriggerDetailModel.userGroups = userGroups.map(item => item.text).join(', ');
          break;
      }
    });
  }

  loadMobileApplications(trigger: Trigger.Trigger) {
    this.deviceService.query({
      id: trigger.message!.recipient.mobileApplicationIds.join(',')
    }).subscribe((devices: ResourceQueryResult<Device>) => {
      const mobileApplications: OptionItem<number>[] = [];
      devices.items.forEach(device => {
        const item = new OptionItem<number>();
        item.id = device.id;
        item.text = device.name ? device.name : device.application_id;
        mobileApplications.push(item);
      });
      this.messageTriggerDetailModel.mobileApplications = mobileApplications.map(item => item.text).join(', ');
    });
  }

  loadRelatedPersonTypes(trigger: Trigger.Trigger) {
    const relatedPersonTypes: OptionItem<TriggerUtils.RelatedPersonType>[] = [];
    TriggerUtils.relatedPersonTypes.forEach(relatedPersonType => {
      const item = new OptionItem<TriggerUtils.RelatedPersonType>();
      item.id = relatedPersonType.type;
      this.translateService.get(relatedPersonType.stringKey).subscribe(text => {
        item.text = text;
        switch (trigger.type) {
          case 'EMAIL':
            if (trigger.email!.recipient.relatedPersonTypes.find((pt) => pt === item.id)) {
              relatedPersonTypes.push(item);
            }
            break;
          case 'MESSAGE':
            if (trigger.message!.recipient.relatedPersonTypes.find((pt) => pt === item.id)) {
              relatedPersonTypes.push(item);
            }
            break;
        }
      });
    });
    switch (trigger.type) {
      case 'EMAIL':
        this.emailTriggerDetailModel.relatedPersonTypes = relatedPersonTypes.map(item => item.text).join(', ');
        break;
      case 'MESSAGE':
        this.messageTriggerDetailModel.relatedPersonTypes = relatedPersonTypes.map(item => item.text).join(', ');
        break;
    }
  }

  private loadEmailAddressTypes(trigger: Trigger.Trigger) {
    if (trigger.email!.recipient.emailAddressTypeIds.size > 0) {
      this.emailAddressTypeService.getLocalizedList(this.translateService.currentLang.substr(0, 2))
        .subscribe(result =>
          this.emailTriggerDetailModel.emailAddressTypes = result
            .filter(t => trigger.email!.recipient.emailAddressTypeIds.contains(t.id))
            .map(t => t.itemName)
            .join(', ')
        );
    }
  }

  loadTransportDocumentTypes(trigger: Trigger.Trigger) {
    const transportDocumentTypes: OptionItem<TransportDocumentType>[] = [];
    TransportDocument.transportDocumentTypes.forEach(transportDocumentType => {
      const item = new OptionItem<TransportDocumentType>();
      item.id = transportDocumentType.type;
      this.translateService.get(transportDocumentType.stringKey).subscribe(text => {
        item.text = text;
        if (trigger.email!.attachment.transportDocumentTypes &&
          trigger.email!.attachment.transportDocumentTypes!.find((pt) => pt === item.id)) {
          transportDocumentTypes.push(item);
        }
      });
    });
    this.emailTriggerDetailModel.transportDocumentTypes = transportDocumentTypes.map(item => item.text).join(', ');
  }

  initTaskBreadcrumb() {
    this.translateService.get('MENU_NAVBAR_MENU_ADMINISTRATION').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.ADMIN_DASHBOARD});
      }
    );
    this.translateService.get('MENU_FORMS').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.TASK_LIST});
      }
    );
    this.translateService.get('FORM_EDIT_TITLE').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.TASK_EDIT, uiParam: {id: this.triggerParentId}});
      }
    );
  }

  initTransportBreadcrumb() {
    this.translateService.get('MENU_NAVBAR_MENU_ADMINISTRATION').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.ADMIN_DASHBOARD});
      }
    );
    this.translateService.get('TRANSPORT_TRIGGER_LIST').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.TRANSPORT_TRIGGER_LIST});
      }
    );
  }

  getTaskTriggerObservable(): Observable<Trigger.Trigger> {
    return this.taskService.getTrigger({
      triggerParentId: this.triggerParentId,
      id: this.triggerId
    });
  }

  getTransportTriggerObservable(): Observable<Trigger.Trigger> {
    return this.transportTriggerService.getTrigger({
      id: this.triggerId
    });
  }

}

class TriggerDetailModel {
  name: string = '';
  event: string = '';
}

class PdfTriggerDetailModel extends TriggerDetailModel {

  // base data
  pdfTemplate: string = '';
  namePattern: string = '';

}

class ReceiptTriggerDetailModel extends TriggerDetailModel {

  // base data
  receiptTemplate: string = '';
  namePattern: string = '';

}

class EmailTriggerDetailModel extends TriggerDetailModel {

  // base data
  emailTemplate: string = '';
  ignoredWithEmptyRecipients: string = '';

  // attachments
  pdfTriggers: string = '';
  transportDocumentTypes: string = '';
  fileDocuments: string = '';
  documentGroups: string = '';
  attachEachShipmentDocument: string = '';
  attachInvoices: string = '';

  // email recipient
  sendToEveryone: string = '';
  users: string = '';
  userGroups: string = '';
  emailAddresses: string = '';
  emailAddressTypes: string = '';
  relatedPersonTypes: string = '';

}

class MessageTriggerDetailModel extends TriggerDetailModel {

  // base data
  messageTemplate: string = '';
  ignoredWithEmptyRecipients: string = '';

  // message recipient
  sendToEveryone: string = '';
  users: string = '';
  userGroups: string = '';
  mobileApplications: string = '';
  relatedPersonTypes: string = '';

}
