/* eslint-disable */
import { MultiselectOptionItem, OptionItem } from './core-utils';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { AssigneeItem } from './task-record-utils';

/* eslint-enable */

export class AuditLogDetailModel {
  id: number;
  user_id?: number;
  user_name?: string = '';
  ip_address: string = '';
  request_uri: string = '';
  call_type: string = '';
  request_method: string = '';
  request_query: string = '';
  request_json: string = '';
  response_json: string = '';
  exception: string = '';
  result: string = '';
  creation_time: string = '';
  finish_time?: string = '';
  duration_in_milliseconds?: number;
  content_type: string = '';
  device_id: string = '';
  has_application_id: boolean;
  application_name: string = '';
  application_id: string = '';
  api_operation_id?: number;
  api_operation_definition: string = '';
}

export class AuditLogSearchModel {
  q: string;
  user: MultiselectOptionItem<number>[];
  exclude_health_check: boolean;
  exclude_su: boolean;
  application_id?: ApplicationIdTypeItem = undefined;
  request_method: string;
  request_uri: string;
  result?: AuditLogResultItem = undefined;
  callType?: AuditLogCallTypeItem = undefined;
  request_device_type?: AuditLogDeviceTypeItem = undefined;
  creation_time: NgbDateStruct | null = null;

  public isEmpty(): boolean {
    return this.q.length === 0
      && this.user.length === 0
      && (this.application_id === undefined || !this.application_id.id)
      && this.request_method.length === 0
      && this.request_uri.length === 0
      && this.result === undefined
      && (this.callType === undefined || !this.callType.id)
      && this.request_device_type === undefined
      && this.creation_time === null
      && !this.exclude_health_check
      && !this.exclude_su
      ;
  }

  get selectedUsers(): AssigneeItem[] | undefined {
    if (this.user.length > 0) {
      return this.user;
    }
    return undefined;
  }
}

export type AuditLogResult = 'ERROR' | 'OK' | 'ALL';
export type AuditLogCallType = 'SERVER' | 'CLIENT';

export type AuditLogDeviceType =
  'AUDIT_LOG_SEARCH_METHOD_DEVICE_TYPE_ALL' |
  'AUDIT_LOG_SEARCH_METHOD_DEVICE_TYPE_MOBILE' |
  'AUDIT_LOG_SEARCH_METHOD_DEVICE_TYPE_WEB';

export class AuditLogResults {
  public static readonly values: AuditLogResult[] = ['ERROR', 'OK', 'ALL'];
}

export class AuditLogCallTypes {
  public static readonly values: AuditLogCallType[] = ['SERVER', 'CLIENT'];
}

export class AuditLogDeviceTypes {
  public static readonly values: AuditLogDeviceType[] = [
    'AUDIT_LOG_SEARCH_METHOD_DEVICE_TYPE_ALL',
    'AUDIT_LOG_SEARCH_METHOD_DEVICE_TYPE_MOBILE',
    'AUDIT_LOG_SEARCH_METHOD_DEVICE_TYPE_WEB'
  ];
}

export class AuditLogResultItem extends OptionItem<AuditLogResult> {
}

export class AuditLogCallTypeItem extends OptionItem<AuditLogCallType> {
}

export class AuditLogDeviceTypeItem extends OptionItem<AuditLogDeviceType> {
}

export class ApplicationIdTypeItem extends OptionItem<string> {
}
