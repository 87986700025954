import { Injectable } from '@angular/core';
import { BaseHttpService, ResourceHelper } from '../../util/http-services';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ResourceQueryResult } from '../../util/services';
import { EmptyMessage, IdentityMessage } from '../../util/messages';

@Injectable()
export class TaskRecordSubTaskResourceService extends BaseHttpService {

  private static readonly baseUrl: string = '/tasks/';

  private static getSubTaskUrl(request: TaskRecordSubTaskResource.SubTaskBaseRequest): string {
    return `${request.task_id}` + '/records/' + `${request.task_record_id}` + `/sub-tasks/`
  }

  query(request: TaskRecordSubTaskResource.QueryRequest): Observable<ResourceQueryResult<TaskRecordSubTaskResource.SubTask>> {
    return this.http.get<ResourceQueryResult<TaskRecordSubTaskResource.SubTask>>(
      this.url + TaskRecordSubTaskResourceService.getSubTaskUrl(request), this.parseParams(request)
    );
  }

  get(request: TaskRecordSubTaskResource.GetRequest): Observable<TaskRecordSubTaskResource.SubTask> {
    return this.http.get<TaskRecordSubTaskResource.SubTask>(
      this.url + TaskRecordSubTaskResourceService.getSubTaskUrl(request) + `${request.id}`
    );
  }

  create(request: TaskRecordSubTaskResource.CreateRequest): Observable<IdentityMessage> {
    return this.http.post<IdentityMessage>(this.url + TaskRecordSubTaskResourceService.getSubTaskUrl(request), request);
  }

  update(request: TaskRecordSubTaskResource.UpdateRequest): Observable<EmptyMessage> {
    return this.http.put<EmptyMessage>(this.url + TaskRecordSubTaskResourceService.getSubTaskUrl(request) + `${request.id}`, request);
  }

  delete(request: TaskRecordSubTaskResource.DeleteRequest): Observable<EmptyMessage> {
    return this.http.delete<EmptyMessage>(this.url + TaskRecordSubTaskResourceService.getSubTaskUrl(request) + `${request.id}`);
  }

  constructor(private http: HttpClient, resourceHelper: ResourceHelper) {
    super(resourceHelper, TaskRecordSubTaskResourceService.baseUrl);
  }

}

export namespace TaskRecordSubTaskResource {

  export interface SubTask {
    id: number;
    version: number;
    granted_rights?: string[];
    creation_time: string;
    state: string;
    worker_user?: WorkerUser;
    name: string;
    type?: string;
    note?: string;
    start_time?: string;
    end_time?: string;
  }

  export interface WorkerUser {
    id: number;
    person_name: string;
    user_name: string;
  }

  export interface QueryRequest extends SubTaskBaseRequest {
    fields?: string;
    filter?: string;
    order?: string;
    page_number?: number;
    number_of_items?: number;
    rights?: string;
  }

  export interface GetRequest extends IdentityMessage, SubTaskBaseRequest {
  }

  export interface CreateRequest extends SubTaskBaseRequest {
    name: string;
    type?: string;
    note?: string;
    worker_user_id?: number;
  }

  export interface UpdateRequest extends IdentityMessage, SubTaskBaseRequest {
    version: number;
    name: string;
    type?: string;
    note?: string;
    worker_user_id?: number;
  }

  export interface DeleteRequest extends IdentityMessage, SubTaskBaseRequest {
  }

  export interface SubTaskBaseRequest {
    task_id: number;
    task_record_id: number;
  }

}
