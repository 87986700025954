/* eslint-disable */
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { WeightFactory } from '../../../../util/weight-utils';
import { ShippingDemandItemEditComponent } from '../shipping-demand-item-edit/shipping-demand-item-edit.component';
import { QueryResult } from '../../../../lib/util/services';
import { ShippingItem, ShippingItemService } from '../../../../lib/shipping-item/shipping-item.service';
import { Logger, LoggerFactory } from '../../../../util/logger-factory';
import { ComponentStateResolver } from '../../../../util/component-state/component-state-resolver';
import { Models } from '../../../../util/model-utils';
import { Amount, PriceModel, ShippingItemModel } from '../shipping-demand-edit.model';
import { ShippingDemand } from '../../../../lib/shipping-demand/shipping-demand.service';
import { RightModel } from '../../../../app.rights';
/* eslint-enable */

@Component({
  selector: 'app-shipping-demand-item-container',
  templateUrl: './shipping-demand-item-container.component.html',
  styleUrls: ['./shipping-demand-item-container.component.scss']
})
export class ShippingDemandItemContainerComponent implements OnInit {

  WeightFactory = WeightFactory;
  EditableField = ShippingDemand.EditableField;

  private readonly logger: Logger;

  _itemErrors: any;

  @Input()
  set itemErrors(v: any) {
    this._itemErrors = v;
    this.logger.debug(this._itemErrors);
  }

  @Input()
  rightModel: RightModel = RightModel.empty();

  @Input()
  editableFieldModel: ShippingDemand.EditableFields;

  @ViewChild('shippingItemCreate')
  shippingItemCreate: ShippingDemandItemEditComponent;

  @ViewChild('shippingItemEdit')
  shippingItemEdit: ShippingDemandItemEditComponent;

  @Input()
  componentState: ComponentStateResolver;

  @Input()
  disabled?: boolean = false;

  shippingItems: ShippingItemModel[] = [];

  addModel?: ShippingItemModel;

  currencyList: string[] = [];

  get shippingItemExternalIds(): string[] {
    return this.shippingItems.map((shippingItem) => shippingItem.externalId)
  }

  constructor(
    private shippingItemService: ShippingItemService
  ) {
    this.logger = LoggerFactory.createLogger('ShippingDemandItemContainerComponent');
  }

  ngOnInit() {
    this.currencyList.push('HUF');
    this.currencyList.push('EUR');
  }

  setComponent(id: number) {

    this.shippingItemService.query({demandId: id}).subscribe((result: QueryResult<ShippingItem.ShippingItem>) => {
      result.items.forEach((shippingItem: ShippingItem.ShippingItem) => {
        const shippingItemModel = new ShippingItemModel();
        shippingItemModel.id = shippingItem.id;
        shippingItemModel.externalId = shippingItem.externalId;
        shippingItemModel.name = shippingItem.name;
        shippingItemModel.category = shippingItem.category ? shippingItem.category : '';
        shippingItemModel.productCode = shippingItem.productCode ? shippingItem.productCode : '';
        shippingItemModel.amount = this.setPublicAmount(shippingItem.amount);
        shippingItemModel.comment = shippingItem.comment ? shippingItem.comment : '';
        shippingItemModel.boxCode = shippingItem.box ? shippingItem.box.value : '';
        shippingItemModel.labelCode = shippingItem.label ? shippingItem.label.value : '';
        shippingItemModel.weightInGram =  shippingItem.weightInGram ? shippingItem.weightInGram : undefined;
        shippingItemModel.price = shippingItem.price ? shippingItem.price : new PriceModel();
        shippingItemModel.editing = false;

        this.shippingItems.push(shippingItemModel);
      });
    });
  }

  setPublicAmount(amount: ShippingItem.Amount): Amount {
    return {
      value: Models.numberToString(amount.value),
      unit: amount.unit
    }
  }

  toggleEdit(index: number) {
    this.shippingItems[index].editing = !this.shippingItems[index].editing;
  }

  hasItemError(externalId: string): boolean {
    if (this.itemErrors && this.itemErrors[externalId]) {
      return true;
    }
    return false;
  }

  getItemError(externalId: string) {
    if (this.hasItemError(externalId)) {
      return this.itemErrors[externalId];
    }
    return null;
  }

  newItem() {
    this.addModel = new ShippingItemModel();
  }

  closeRow(index: number) {
    this.shippingItems[index].editing = false;
  }

  cancelNewItem() {
    this.addModel = undefined;
  }

  saveNewItem(model: ShippingItemModel) {
    this.shippingItems.push(model);
    this.addModel = undefined;
    model.editing = false;
  }

  saveItem(index: number, model: ShippingItemModel) {

    this.shippingItems[index] = model;
    this.closeRow(index);

  }

  removeItem(index: number, id: number) {
    this.shippingItems.splice(index, 1);
  }

  getItems(): ShippingItemModel[] {
    return this.shippingItems;
  }

  isEnabledField(field: ShippingDemand.EditableField): boolean {
    if (this.componentState.isCreateView() || this.componentState.isCloneView()) {
      if (this.rightModel.shippingDemandCreate.hasRight()) {
        return this.editableFieldModel.isEnabledByServer(false, field);
      }
      if (this.rightModel.shippingDemandCreateSimple.hasRight()) {
        return this.editableFieldModel.isEnabledByServer(true, field);
      }
      return false;
    }
    if (this.componentState.isEditView()) {
      if (this.rightModel.shippingDemandUpdate.hasRight()) {
        return this.editableFieldModel.isEnabledByServer(false, field);
      }
      if (this.rightModel.shippingDemandUpdateSimple.hasRight()) {
        return this.editableFieldModel.isEnabledByServer(true, field);
      }
      return false;
    }
    if (this.componentState.isDetailView()) {
      if (this.rightModel.shippingDemandRead.hasRight()) {
        return this.editableFieldModel.isEnabledByServer(false, field);
      }
      if (this.rightModel.shippingDemandReadSimple.hasRight()) {
        return this.editableFieldModel.isEnabledByServer(true, field);
      }
      return false;
    }
    return false;
  }

}
