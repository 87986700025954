import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { RightModel } from '../../../../app.rights';
import {
  ColonnadeClientDocumentStatSearchComponent,
  ColonnadeClientDocumentStatSearchSearchScope
} from '../colonnade-client-document-stat-search/colonnade-client-document-stat-search.component';
import { RightResolver, RightService } from '../../../../lib/right.service';
import {
  ColonnadeClientDocumentStat,
  ColonnadeClientDocumentStatService
} from '../../../../lib/colonnade-client-document-stat/colonnade-client-document-stat.service';
import { DownloadedFile } from '../../../../lib/util/downloaded-files';
import { saveAs } from 'file-saver';
import { UiConstants } from '../../../../util/core-utils';
import { StringKey } from '../../../../app.string-keys';
import { ToasterService } from '../../../../fork/angular2-toaster/src/toaster.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-colonnade-client-document-stat-enroller-report',
  templateUrl: './colonnade-client-document-stat-enroller-report.component.html',
  styleUrls: ['./colonnade-client-document-stat-enroller-report.component.scss']
})
export class ColonnadeClientDocumentStatEnrollerReportComponent implements OnInit {
  ColonnadeClientDocumentStatSearchSearchScope = ColonnadeClientDocumentStatSearchSearchScope;

  @ViewChild('searchComponent', { static: true })
  searchComponent: ColonnadeClientDocumentStatSearchComponent;

  sponsorValues: ColonnadeClientDocumentStat.ColonnadeB2BBySponsorValue[] = [];

  rightModel: RightModel = RightModel.empty();
  currentUserId?: number;

  constructor(private rightService: RightService,
              private toasterService: ToasterService,
              private translateService: TranslateService,
              private colonnadeClientDocumentStatService: ColonnadeClientDocumentStatService) { }

  ngOnInit() {
    this.loadRightModel();
  }

  private loadRightModel() {
    this.rightService.getRightResolver().subscribe(
      (resolver: RightResolver) => {
        this.rightModel = RightModel.of(resolver);
        this.currentUserId = resolver.userProfile!.id;
      }
    );
  }

  downloadXlsx() {
    if (!this.searchComponent.fForm.submitted || !this.searchComponent.validate()) {
      this.toasterService.pop({
        timeout: UiConstants.ToastTimeoutLong,
        type: UiConstants.toastTypeError,
        title: this.translateService.instant(StringKey.COMMON_ERROR_DIALOG_TITLE),
        body: this.translateService.instant(StringKey.COLONNADE_CLIENT_DOCUMENT_STAT_XLSX_EXPORT_NO_RESULT)
      });
      return;
    }
    const request = this.searchComponent.getSearchRequest();
    this.colonnadeClientDocumentStatService.getEnrollerReportXlsx(request).subscribe(
      (res: DownloadedFile) => {
        saveAs(res.getBlob(), res.getFileName('enroller_report.xlsx'));
      }
    );
  }

  loadList() {
    this.colonnadeClientDocumentStatService.getEnrollerReport(this.searchComponent.getSearchRequest())
      .subscribe((result) => {
        this.sponsorValues = result;
      });
  }
}
