import {AfterViewInit, Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {UIRouter} from '@uirouter/angular';
import {FormRef, LocalFormGroupValidationErrors, QueryResult} from '../../lib/util/services';
import {RightModel} from '../../app.rights';
import {BreadcrumbParent} from '../../shared/breadcrumb/breadcrumb/breadcrumb.component';
import {TranslateService} from '@ngx-translate/core';
import {Country, CountryService} from '../../lib/country.service';
import {RightResolver, RightService} from '../../lib/right.service';
import {StateName} from '../../app.state-names';
import {EnvironmentConfigService} from '../../lib/environment-config/environment-config.service';
import {EmptyMessage} from '../../lib/util/messages';
import {Angular2Multiselects} from '../../util/multiselect';
import {MultiselectOptionItem} from '../../util/core-utils';
import {ConfigurationResource, ConfigurationService} from '../../lib/core-ext/configuration.service';
import {AppValidators} from '../../util/app-validators';
import {InputMask} from '../../util/input-masks';
import {TaskRecord} from '../../lib/task/task-record.service';
import {AppNgbTimeStruct} from '../../util/ngb-datepicker';
import {Models} from '../../util/model-utils';
import {Set} from 'immutable';
import {Arrays} from '../../lib/util/arrays';
import {CustomerRecordField, CustomerRecordFieldType} from '../../util/customer-record-utils';
import {UserGroupMultiselectProvider} from '../../lib/user-group/user-group-multiselect.provider';
import {TranslateUtils} from "../../util/translate";
import TaskRecordField = TaskRecord.TaskRecordField;
import AdminFeature = ConfigurationResource.AdminFeature;
import RegistrationType = ConfigurationResource.RegistrationType;
import CalendarDisplayLabel = ConfigurationResource.CalendarDisplayLabel;
import calendarDisplayLabels = ConfigurationResource.calendarDisplayLabels;
import TaskRecordAssigneeFilter = TaskRecord.TaskRecordAssigneeFilter;

@Component({
  selector: 'app-environment-config',
  templateUrl: './environment-config.component.html',
  styleUrls: ['./environment-config.component.scss']
})
export class EnvironmentConfigComponent implements OnInit, AfterViewInit, FormRef {
  InputMask = InputMask;

  model: EnvironmentConfigModel;
  readonlyKeys: Set<string> = Set.of();

  readonly: boolean = true;

  countryItems: MultiselectOptionItem<string>[] = [];
  customerRecordFields: MultiselectOptionItem<CustomerRecordFieldType>[] = [];
  taskRecordFields: MultiselectOptionItem<TaskRecordField>[] = [];
  mobileCompressionLevels: MultiselectOptionItem<MobileCompressionLevel>[] = [];
  taskRecordAssigneeFilters: MultiselectOptionItem<TaskRecordAssigneeFilter>[] = [];
  registrationTypes: MultiselectOptionItem<RegistrationType>[]
    = ConfigurationResource.registrationTypes.map(t => ({id: t.type, itemName: t.stringKey}));
  userGroups: MultiselectOptionItem<number>[] = [];
  calendarDisplayLabels: MultiselectOptionItem<CalendarDisplayLabel>[]
    = calendarDisplayLabels.map(l => ({id: l.type, itemName: l.stringKey}));
  rightModel: RightModel = RightModel.empty();
  configuration: ConfigurationResource.Configuration;
  breadcrumbParents: BreadcrumbParent[] = [];
  breadcrumbSelf: string;
  compactSidebar: boolean = document.querySelector('body')!.classList.contains('sidebar-compact');

  multiselectDropdownSettings: Angular2Multiselects.Settings;
  singleselectDropdownSettings: Angular2Multiselects.Settings;
  registrationEnabledDropdownSettings: Angular2Multiselects.Settings;

  formGroup: FormGroup;
  submitted: boolean = false;
  private formGroupValidationErrors: LocalFormGroupValidationErrors;

  constructor(
    private uiRouter: UIRouter,
    private translateService: TranslateService,
    private countryService: CountryService,
    private environmentConfigService: EnvironmentConfigService,
    private configurationService: ConfigurationService,
    private rightService: RightService,
    private formBuilder: FormBuilder,
    private userGroupMultiselectProvider: UserGroupMultiselectProvider,
  ) {
    this.model = new EnvironmentConfigModel();
    this.configuration = this.configurationService.getConfiguration();
    this.formGroup = this.createFormGroup(formBuilder);
    this.formGroupValidationErrors = LocalFormGroupValidationErrors.ofForm(this, this.formGroup);
  }

  ngOnInit() {
    this.translateService.get('MENU_NAVBAR_SETTINGS').subscribe(
      (result: string) => {
        this.breadcrumbSelf = result;
      }
    );
    this.translateService.get('MENU_NAVBAR_MENU_ADMINISTRATION').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.ADMIN_DASHBOARD});
      }
    );
    this.loadRightModels();
  }

  ngAfterViewInit(): void {
    this.loadCustomerRecordFields();
    this.loadMobileCompressionLevels();
    this.initDropDown();
  }

  private createFormGroup(fb: FormBuilder): FormGroup {
    return fb.group(
      {
        registrationInactivationDays: fb.control(
          this.model.registrationInactivationDays,
          [
            AppValidators.validateOptionalPositiveNumber
          ]
        ),
        orderMaxPackageWeight: fb.control(
          this.model.orderMaxPackageWeight,
          [
            AppValidators.validateOptionalPositiveNumber
          ]
        ),
        shipmentHandoverDuration: fb.control(
          this.model.shipmentHandoverDuration,
          [
            Validators.required,
            AppValidators.validateOptionalPositiveNumber
          ]
        ),
        shipmentTakeoverDuration: fb.control(
          this.model.shipmentTakeoverDuration,
          [
            Validators.required,
            AppValidators.validateOptionalPositiveNumber
          ]
        ),
        transportPositionLogInterval: fb.control(
          this.model.transportPositionLogInterval,
          [
            Validators.required,
            AppValidators.validateOptionalPositiveNumber
          ]
        ),
        transportDirectionsInterval: fb.control(
          this.model.transportDirectionsInterval,
          [
            Validators.required,
            AppValidators.validateOptionalPositiveNumber
          ]
        ),
        transportLostDriverThreshold: fb.control(
          this.model.transportLostDriverThreshold,
          [
            Validators.required,
            AppValidators.validateOptionalPositiveNumber
          ]
        ),
        transportLateThreshold: fb.control(
          this.model.transportLateThreshold,
          [
            Validators.required,
            AppValidators.validateOptionalPositiveNumber,
          ]
        ),
        calendarWorkdayStartInMinutes: fb.control(
          this.model.calendarWorkdayStartInMinutes.value,
          []
        ),
        calendarWorkdayEndInMinutes: fb.control(
          this.model.calendarWorkdayEndInMinutes.value,
          []
        ),
        calendarDisplayStartInMinutes: fb.control(
          this.model.calendarDisplayStartInMinutes.value,
          []
        ),
        calendarDisplayEndInMinutes: fb.control(
          this.model.calendarDisplayEndInMinutes.value,
          []
        ),
        taskRecordWebadminDashboardVisibleCount: fb.control(
          this.model.taskRecordWebadminDashboardVisibleCount,
          [
            Validators.required
          ]
        ),
        workChronologyHoursPerDay: fb.control(
          this.model.workChronologyHoursPerDay,
          [
            AppValidators.validateOptionalPositiveNumber,
            AppValidators.optMax(() => {
              return 24;
            })
          ]
        ),
        workChronologyDaysPerWeek: fb.control(
          this.model.workChronologyDaysPerWeek,
          [
            AppValidators.validateOptionalPositiveNumber,
            AppValidators.optMax(() => {
              return 7;
            })
          ]
        ),
        geofencingDefaultRadiusInMeters: fb.control(
          this.model.geofencingDefaultRadiusInMeters,
          [
            Validators.required,
            Validators.min(100),
            Validators.max(5000)
          ]
        ),
      },
      {
        validators: [AppValidators.validateAppNgbTimeInterval({
          startValue: () => this.model.calendarWorkdayStartInMinutes.value,
          endValue: () => this.model.calendarWorkdayEndInMinutes.value
        }),
          AppValidators.validateAppNgbTimeInterval({
            startValue: () => this.model.calendarDisplayStartInMinutes.value,
            endValue: () => this.model.calendarDisplayEndInMinutes.value
          })
        ]
      }
    );
  }

  getForm() {
    return {
      submitted: this.submitted
    };
  }

  private loadRightModels() {
    this.rightService.getRightResolver().subscribe(
      (resolver: RightResolver) => {
        this.rightModel = RightModel.of(resolver);
        this.readonly = !this.rightModel.environmentConfigUpdate.hasRight();
      }
    );
  }

  private loadCustomerRecordFields() {
    this.customerRecordFields = [];
    CustomerRecordField.getFields().forEach((field) => {
      const item = {
        id: field.type,
        itemName: '...'
      };
      this.customerRecordFields.push(item);
      this.translateService.get(field.stringKey).subscribe((text: string) => {
        item.itemName = text;
        const index = this.customerRecordFields.findIndex(f => f.id === item.id);
        if (index === this.customerRecordFields.length - 1) {
          this.customerRecordFields = this.customerRecordFields.sort((a, b) => a.itemName.localeCompare(b.itemName));
        }
      })
    });
    this.loadTaskRecordFields();
  }

  private loadMobileCompressionLevels() {
    this.mobileCompressionLevels = [];
    this.translateService.get(['MOBILE_COMPRESSION_LEVEL_NONE',
      'MOBILE_COMPRESSION_LEVEL_SMALL',
      'MOBILE_COMPRESSION_LEVEL_MEDIUM',
      'MOBILE_COMPRESSION_LEVEL_HIGH'])
      .subscribe(result => {
        this.mobileCompressionLevels.push({
          id: MobileCompressionLevel.NONE,
          itemName: TranslateUtils.extractValueFromObject(result, 'MOBILE_COMPRESSION_LEVEL_NONE')
        });
        this.mobileCompressionLevels.push({
          id: MobileCompressionLevel.SMALL,
          itemName: TranslateUtils.extractValueFromObject(result, 'MOBILE_COMPRESSION_LEVEL_SMALL')
        });
        this.mobileCompressionLevels.push({
          id: MobileCompressionLevel.MEDIUM,
          itemName: TranslateUtils.extractValueFromObject(result, 'MOBILE_COMPRESSION_LEVEL_MEDIUM')
        });
        this.mobileCompressionLevels.push({
          id: MobileCompressionLevel.HIGH,
          itemName: TranslateUtils.extractValueFromObject(result, 'MOBILE_COMPRESSION_LEVEL_HIGH')
        });
      });
  }

  private loadTaskRecordFields() {
    this.taskRecordFields = [];
    TaskRecord.taskRecordFields.forEach((field) => {
      const item = {
        id: field.field,
        itemName: '...'
      };
      this.taskRecordFields.push(item);
      this.translateService.get(field.stringKey).subscribe((text: string) => {
        item.itemName = text;
        const index = this.taskRecordFields.findIndex(f => f.id === item.id);
        if (index === this.taskRecordFields.length - 1) {
          this.taskRecordFields = this.taskRecordFields.sort((a, b) => a.itemName.localeCompare(b.itemName));
        }
      })
    });
    this.loadUserGroups();
  }

  private loadUserGroups() {
    this.userGroupMultiselectProvider.load({}).subscribe(result => {
      this.userGroups = result;
      this.loadAssigneeFilters();
    });
  }

  private loadAssigneeFilters() {
    this.taskRecordAssigneeFilters = [];
    TaskRecord.taskRecordAssigneeFilters.forEach((field) => {
      const item = {
        id: field.field,
        itemName: '...'
      };
      this.taskRecordAssigneeFilters.push(item);
      this.translateService.get(field.stringKey).subscribe((text: string) => {
        item.itemName = text;
      })
    });
    this.loadCountries();
  }

  private loadCountries() {
    this.countryService.query({}).subscribe((result: QueryResult<Country.Country>) => {
      this.countryItems = [];
      result.items.forEach((c: Country.Country) => {
        this.countryItems.push({
          id: c.countryCode,
          itemName: c.localizedName
        });
      });
      this.loadReadonlyFields(() => {
        this.loadModel();
      });
    });
  }

  private loadModel() {
    this.environmentConfigService.get({}).subscribe(
      (result: Map<string, string>) => {
        this.model.load(
          result,
          this.customerRecordFields,
          this.taskRecordFields,
          this.mobileCompressionLevels,
          this.taskRecordAssigneeFilters,
          this.countryItems,
          this.registrationTypes,
          this.userGroups,
          this.calendarDisplayLabels
        );
      });
  }

  private loadReadonlyFields(completion: () => void) {
    this.environmentConfigService.getReadonlyKeys({}).subscribe(
      (result: Set<string>) => {
        this.readonlyKeys = result;
        completion();
      });
  }

  initDropDown() {
    this.multiselectDropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(false)
      .enableSearchFilter(true)
      .enableCheckAll(true)
      .build();
    this.singleselectDropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(true)
      .enableSearchFilter(true)
      .enableCheckAll(false)
      .build();
    this.registrationEnabledDropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(true)
      .enableSearchFilter(true)
      .enableCheckAll(false)
      .translate(true)
      .build();
  }

  back() {
    window.history.back();
  }

  onApplyButtonClicked() {
    this.submitted = true;
    this.formGroup.updateValueAndValidity();
    if (this.formGroup.invalid) {
      return;
    }
    this.environmentConfigService.update(this.model.toMap()).subscribe(
      (response: EmptyMessage) => {
        this.configurationService.load();
      });
  }

  update() {
    this.submitted = true;
    this.formGroup.updateValueAndValidity();
    if (this.formGroup.invalid) {
      return;
    }
    this.environmentConfigService.update(this.model.toMap()).subscribe(
      (response: EmptyMessage) => {
        this.configurationService.load();
        this.back();
      });
  }

  hasLocalFieldError(formControlName?: string, errorCode?: string): boolean {
    return this.formGroupValidationErrors.hasFieldError(formControlName, errorCode);
  }

  hasLocalFormError(errorCode: string): boolean {
    return this.formGroupValidationErrors.hasFormError(errorCode);
  }

  getMultiselectDataReadonlyLabel(data: MultiselectOptionItem<string | CustomerRecordField>[]): string {
    const strings: string[] = [];
    data.forEach((d) => {
      strings.push(d.itemName);
    });
    return strings.join(', ');
  }

  isReadonly(key: string): boolean {
    return this.readonly || !!this.readonlyKeys.find(entry => entry === key);
  }

  isEnabledByServer(key: AdminFeature): boolean {
    return ConfigurationService.isEnabledByServer(key, this.configuration);
  }

  onCalendarDisplayLabelLine1Changed() {
    if (this.model.calendarWebadminLabelsLine1.value.length === 0) {
      this.model.calendarWebadminLabelsLine2.value = [];
      this.onCalendarDisplayLabelLine2Changed();
    }
  }

  onCalendarDisplayLabelLine2Changed() {
    if (this.model.calendarWebadminLabelsLine2.value.length === 0) {
      this.model.calendarWebadminLabelsLine3.value = [];
    }
  }

  get calendarDisplayLabelLine2Disabled(): boolean {
    return this.model.calendarWebadminLabelsLine1.value.length === 0;
  }

  get calendarDisplayLabelLine3Disabled(): boolean {
    return this.model.calendarWebadminLabelsLine2.value.length === 0;
  }

  get calendarDisplayLabelLine1Options(): MultiselectOptionItem<CalendarDisplayLabel>[] {
    return this.calendarDisplayLabels
      .filter(l => !this.model.calendarWebadminLabelsLine2.value.includes(l))
      .filter(l => !this.model.calendarWebadminLabelsLine3.value.includes(l));
  }

  get calendarDisplayLabelLine2Options(): MultiselectOptionItem<CalendarDisplayLabel>[] {
    return this.calendarDisplayLabels
      .filter(l => !this.model.calendarWebadminLabelsLine1.value.includes(l))
      .filter(l => !this.model.calendarWebadminLabelsLine3.value.includes(l));
  }

  get calendarDisplayLabelLine3Options(): MultiselectOptionItem<CalendarDisplayLabel>[] {
    return this.calendarDisplayLabels
      .filter(l => !this.model.calendarWebadminLabelsLine1.value.includes(l))
      .filter(l => !this.model.calendarWebadminLabelsLine2.value.includes(l));
  }

  get calendarDisplayLine1Text(): string | undefined {
    return this.model.calendarWebadminLabelsLine1.value.length === 1 ? this.model.calendarWebadminLabelsLine1.value[0].itemName : undefined;
  }

  get calendarDisplayLine2Text(): string | undefined {
    return this.model.calendarWebadminLabelsLine2.value.length === 1 ? this.model.calendarWebadminLabelsLine2.value[0].itemName : undefined;
  }

  get calendarDisplayLine3Text(): string | undefined {
    return this.model.calendarWebadminLabelsLine3.value.length === 1 ? this.model.calendarWebadminLabelsLine3.value[0].itemName : undefined;
  }

  isGeneralTabValid(): boolean {
    return this.areAllControlsValid([]);
  }

  isUserTabValid(): boolean {
    return this.areAllControlsValid([]);
  }

  isTransportTabValid(): boolean {
    const controls: string[] = [
      'shipmentHandoverDuration',
      'shipmentTakeoverDuration',
      'transportLateThreshold',
      'transportLostDriverThreshold',
      'transportDirectionsInterval',
      'transportPositionLogInterval'
    ];
    return this.areAllControlsValid(controls);
  }

  isFsmTabValid(): boolean {
    const controls: string[] = [
      'geofencingDefaultRadiusInMeters',
      'workChronologyDaysPerWeek',
      'workChronologyHoursPerDay',
      'taskRecordWebadminDashboardVisibleCount',
      'calendarWorkdayEndInMinutes',
      'calendarWorkdayStartInMinutes',
      'calendarDisplayEndInMinutes',
      'calendarDisplayStartInMinutes'
    ];
    return this.areAllControlsValid(controls);
  }

  isOtherTabValid(): boolean {
    const controls: string[] = [
      'registrationInactivationDays',
      'orderMaxPackageWeight'
    ];
    return this.areAllControlsValid(controls);
  }

  private areAllControlsValid(controls: string[]): boolean {
    for (let i = 0; i < controls.length; i++) {
      if (this.hasLocalFieldError(controls[i])) {
        return false;
      }
    }
    return true;
  }

}

export class EnvironmentConfigModel {

  environmentName: TextField = new TextField(Keys.ENVIRONMENT_NAME, '');
  singleUserMode: BooleanField = new BooleanField(Keys.SINGLE_USER_MODE, false);
  singleUserModeTwoWay: BooleanField = new BooleanField(Keys.SINGLE_USER_MODE_TWO_WAY, false);
  demoModeEnabled: BooleanField = new BooleanField(Keys.DEMO_MODE_ENABLED, false);
  helpdeskEnabled: BooleanField = new BooleanField(Keys.HELPDESK_ENABLED, false);
  registrationEnabled: MultiSelectField<RegistrationType>
    = new MultiSelectField(Keys.REGISTRATION_ENABLED, [], false);
  registrationInactivationDays: NumberField = new NumberField(Keys.REGISTRATION_INACTIVATION_DAYS, 30);
  userDriverEnabled: BooleanField = new BooleanField(Keys.USER_DRIVER_ENABLED, false);
  userEmailRequired: BooleanField = new BooleanField(Keys.USER_EMAIL_REQUIRED, false);
  userEmailUnique: BooleanField = new BooleanField(Keys.USER_EMAIL_UNIQUE, false);
  registerMobileApplicationAsDisabled: BooleanField = new BooleanField(Keys.REGISTER_MOBILE_APPLICATION_AS_DISABLED, false);
  calendarAvailableEnabled: BooleanField = new BooleanField(Keys.CALENDAR_AVAILABLE_ENABLED, false);
  calendarUserColorsEnabled: BooleanField = new BooleanField(Keys.CALENDAR_USER_COLORS_ENABLED, false);
  stockItemProductCodeGeneratorEnabled: BooleanField = new BooleanField(Keys.FEATURE_FLAG_STOCK_ITEM_PRODUCT_CODE_GENERATOR, false);
  stockItemCategoryRequired: BooleanField = new BooleanField(Keys.FEATURE_FLAG_STOCK_ITEM_CATEGORY_REQUIRED, false);
  calendarWebadminLabelsLine1: MultiSelectField<CalendarDisplayLabel>
    = new MultiSelectField(Keys.CALENDAR_WEBADMIN_LABELS, []);
  calendarWebadminLabelsLine2: MultiSelectField<CalendarDisplayLabel>
    = new MultiSelectField(Keys.CALENDAR_WEBADMIN_LABELS, []);
  calendarWebadminLabelsLine3: MultiSelectField<CalendarDisplayLabel>
    = new MultiSelectField(Keys.CALENDAR_WEBADMIN_LABELS, []);
  calendarWorkdayStartInMinutes: TimePickerField
    = new TimePickerField(Keys.CALENDAR_WORKDAY_START_IN_MINUTES, Models.minutesToNgbTime(480));
  calendarWorkdayEndInMinutes: TimePickerField
    = new TimePickerField(Keys.CALENDAR_WORKDAY_END_IN_MINUTES, Models.minutesToNgbTime(960));
  calendarDisplayStartInMinutes: TimePickerField
    = new TimePickerField(Keys.CALENDAR_DISPLAY_START_IN_MINUTES, Models.minutesToNgbTime(480));
  calendarDisplayEndInMinutes: TimePickerField
    = new TimePickerField(Keys.CALENDAR_DISPLAY_END_IN_MINUTES, Models.minutesToNgbTime(960));
  calendarDisplayMonogram: BooleanField
    = new BooleanField(Keys.CALENDAR_DISPLAY_MONOGRAM, true);
  calendarWorkdayOnWeekend: BooleanField = new BooleanField(Keys.CALENDAR_WORKDAY_ON_WEEKEND, false);
  calendarSplitToWorkday: BooleanField = new BooleanField(Keys.CALENDAR_SPLIT_TO_WORKDAY, false);
  allowAutomaticSignatureLoading: BooleanField = new BooleanField(Keys.ALLOW_AUTOMATIC_SIGNATURE_LOADING, true);
  workChronologyHoursPerDay: NumberField = new NumberField(Keys.WORK_CHRONOLOGY_HOURS_PER_DAY, 8);
  workChronologyDaysPerWeek: NumberField = new NumberField(Keys.WORK_CHRONOLOGY_DAYS_PER_WEEK, 5);
  mobileImageCompressionLevel: MultiSelectField<MobileCompressionLevel>
    = new MultiSelectField(Keys.MOBILE_IMAGE_COMPRESSION_LEVEL, [], true);
  customerRecordManagedFields: MultiSelectField<CustomerRecordFieldType>
    = new MultiSelectField(Keys.CUSTOMER_RECORD_MANAGED_FIELDS, [], true);
  taskRecordManagedFields: MultiSelectField<TaskRecordField>
    = new MultiSelectField(Keys.TASK_RECORD_MANAGED_FIELDS, [], true);
  taskRecordAssigneeFilters: MultiSelectField<TaskRecordAssigneeFilter>
    = new MultiSelectField(Keys.TASK_RECORD_ASSIGNEE_FILTER, [], true);
  taskRecordWebadminDashboardVisibleCount: NumberField = new NumberField(Keys.FEATURE_FLAG_TASK_RECORD_WEBADMIN_VISIBLE_COUNT, 16);
  taskRecordAdminNavigateOnStateChange: BooleanField =
    new BooleanField(Keys.FEATURE_FLAG_TASK_RECORD_WEBADMIN_NAVIGATE_ON_STATE_CHANGE, false);
  taskRecordMobileShowArchived: BooleanField = new BooleanField(Keys.FEATURE_FLAG_TASK_RECORD_MOBILE_SHOW_ARCHIVED, false);
  taskRecordPhoneNumberRelatedList: BooleanField = new BooleanField(Keys.FEATURE_FLAG_TASK_RECORD_PHONE_NUMBER_RELATED_LIST, false);
  acceptOnlyComplexAddress: BooleanField = new BooleanField(Keys.ACCEPT_ONLY_COMPLEX_ADDRESS, false);
  acceptOnlyValidZipCodeFormat: MultiSelectField<string>
    = new MultiSelectField(Keys.ACCEPT_ONLY_VALID_ZIP_CODE_FORMAT, []);
  acceptOnlyExistingCityInCountries: MultiSelectField<string>
    = new MultiSelectField(Keys.ACCEPT_ONLY_EXISTING_CITY_IN_COUNTRIES, []);
  acceptOnlyExistingStreetTypeInCountries: MultiSelectField<string>
    = new MultiSelectField(Keys.ACCEPT_ONLY_EXISTING_STREET_TYPE_IN_COUNTRIES, []);
  orderMaxPackageWeight: NumberField = new NumberField(Keys.ORDER_MAX_PACKAGE_WEIGHT, undefined);
  shipmentHandoverDuration: NumberField = new NumberField(Keys.SHIPMENT_HANDOVER_DURATION, 0);
  shipmentTakeoverDuration: NumberField = new NumberField(Keys.SHIPMENT_TAKEOVER_DURATION, 0);
  geofencingDefaultRadiusInMeters: NumberField = new NumberField(Keys.TASK_GEOFENCING_DEFAULT_RADIUS_IN_METERS, 100);
  transportPositionLogInterval: NumberField = new NumberField(Keys.TRANSPORT_POSITION_LOG_INTERVAL, 120);
  transportDirectionsInterval: NumberField = new NumberField(Keys.TRANSPORT_DIRECTIONS_INTERVAL, 600);
  transportLostDriverThreshold: NumberField = new NumberField(Keys.TRANSPORT_LOST_DRIVER_THRESHOLD, 120);
  transportLateThreshold: NumberField = new NumberField(Keys.TRANSPORT_LATE_THRESHOLD, 600);
  transportAutoCloseAfterFinish: BooleanField = new BooleanField(Keys.TRANSPORT_AUTO_CLOSE_AFTER_FINISH, false);
  defaultSelectedStreetType: TextField = new TextField(Keys.DEFAULT_SELECTED_STREET_TYPE, '');
  defaultSelectedCountryCode: MultiSelectField<string> = new MultiSelectField(Keys.DEFAULT_SELECTED_COUNTRY_CODE, []);
  integrationProspeditionImporterRejectUnknownProduct: BooleanField
    = new BooleanField(Keys.INTEGRATION_PROSPEDITION_IMPORTER_REJECT_UNKNOWN_PRODUCT, true);
  integrationProspeditionImporterRejectInsufficientAmount: BooleanField
    = new BooleanField(Keys.INTEGRATION_PROSPEDITION_IMPORTER_REJECT_INSUFFICIENT_AMOUNT, true);
  integrationProspeditionImporterSystemMessageUserGroup: MultiSelectField<number>
    = new MultiSelectField<number>(Keys.INTEGRATION_PROSPEDITION_IMPORTER_SYSTEM_MESSAGE_USER_GROUP_ID, []);
  taskRecordRejectedToArchived: BooleanField = new BooleanField(Keys.CONST_TASKRECORD_REJECTED_TO_ARCHIVED_ENABLED, false);

  load(map: Map<string, string>,
       customerRecordFields: MultiselectOptionItem<CustomerRecordFieldType>[],
       taskRecordFields: MultiselectOptionItem<TaskRecordField>[],
       mobileCompressionLevels: MultiselectOptionItem<MobileCompressionLevel>[],
       taskRecordassigneeFilters: MultiselectOptionItem<TaskRecordAssigneeFilter>[],
       countryItems: MultiselectOptionItem<string>[],
       registrationTypes: MultiselectOptionItem<RegistrationType>[],
       userGroups: MultiselectOptionItem<number>[],
       calendarDisplayLabels: MultiselectOptionItem<CalendarDisplayLabel>[]) {
    this.environmentName.load(map);
    this.singleUserMode.load(map);
    this.singleUserModeTwoWay.load(map);
    this.demoModeEnabled.load(map);
    this.helpdeskEnabled.load(map);
    this.registrationEnabled.load(map, registrationTypes);
    this.registrationInactivationDays.load(map);
    this.userDriverEnabled.load(map);
    this.userEmailRequired.load(map);
    this.userEmailUnique.load(map);
    this.registerMobileApplicationAsDisabled.load(map);
    this.calendarAvailableEnabled.load(map);
    this.calendarUserColorsEnabled.load(map);
    this.stockItemProductCodeGeneratorEnabled.load(map);
    this.stockItemCategoryRequired.load(map);
    this.loadCalendarDisplayFields(map, calendarDisplayLabels);
    this.calendarWorkdayStartInMinutes.load(map);
    this.calendarWorkdayEndInMinutes.load(map);
    this.calendarDisplayStartInMinutes.load(map);
    this.calendarDisplayEndInMinutes.load(map);
    this.calendarDisplayMonogram.load(map);
    this.calendarSplitToWorkday.load(map);
    this.calendarWorkdayOnWeekend.load(map);
    this.taskRecordAdminNavigateOnStateChange.load(map);
    this.taskRecordMobileShowArchived.load(map);
    this.taskRecordPhoneNumberRelatedList.load(map);
    this.allowAutomaticSignatureLoading.load(map);
    this.workChronologyHoursPerDay.load(map);
    this.taskRecordWebadminDashboardVisibleCount.load(map);
    this.workChronologyDaysPerWeek.load(map);
    this.customerRecordManagedFields.load(map, customerRecordFields);
    this.mobileImageCompressionLevel.load(map, mobileCompressionLevels,
      mobileCompressionLevels.filter(f => f.id === MobileCompressionLevel.NONE));
    this.taskRecordManagedFields.load(map, taskRecordFields);
    this.taskRecordAssigneeFilters.load(map, taskRecordassigneeFilters,
      taskRecordassigneeFilters.filter(f => {
        return f.id !== 'NONE';
      }));
    this.taskRecordRejectedToArchived.load(map);
    this.acceptOnlyComplexAddress.load(map);
    this.acceptOnlyValidZipCodeFormat.load(map, countryItems);
    this.acceptOnlyExistingCityInCountries.load(map, countryItems);
    this.acceptOnlyExistingStreetTypeInCountries.load(map, countryItems);
    this.geofencingDefaultRadiusInMeters.load(map);
    this.orderMaxPackageWeight.load(map);
    this.shipmentHandoverDuration.load(map);
    this.shipmentTakeoverDuration.load(map);
    this.transportPositionLogInterval.load(map);
    this.transportDirectionsInterval.load(map);
    this.transportLostDriverThreshold.load(map);
    this.transportLateThreshold.load(map);
    this.transportAutoCloseAfterFinish.load(map);
    this.defaultSelectedStreetType.load(map);
    this.defaultSelectedCountryCode.load(map, countryItems);
    this.integrationProspeditionImporterRejectUnknownProduct.load(map);
    this.integrationProspeditionImporterRejectInsufficientAmount.load(map);
    this.integrationProspeditionImporterSystemMessageUserGroup.load(map, userGroups);
  }

  private loadCalendarDisplayFields(map: Map<string, string>, displayLabels: MultiselectOptionItem<CalendarDisplayLabel>[]) {
    // Loading calendar display labels (hack)
    const value = map[Keys.CALENDAR_WEBADMIN_LABELS];
    if (value) {
      const rawFields: string[] = value.split(',');
      const fields = rawFields.map(f => displayLabels.find(l => l.id === (<CalendarDisplayLabel>f))!);
      if (fields[0]) {
        this.calendarWebadminLabelsLine1.value.push(fields[0]);
        if (fields[1]) {
          this.calendarWebadminLabelsLine2.value.push(fields[1]);
          if (fields[2]) {
            this.calendarWebadminLabelsLine3.value.push(fields[2]);
          }
        }
      }
    }
  }

  toMap(): Map<string, string> {
    const map = new Map<string, string>();
    this.environmentName.addToMap(map);
    this.singleUserMode.addToMap(map);
    this.singleUserModeTwoWay.addToMap(map);
    this.demoModeEnabled.addToMap(map);
    this.helpdeskEnabled.addToMap(map);
    this.registrationEnabled.addToMap(map);
    this.registrationInactivationDays.addToMap(map);
    this.userDriverEnabled.addToMap(map);
    this.userEmailRequired.addToMap(map);
    this.userEmailUnique.addToMap(map);
    this.registerMobileApplicationAsDisabled.addToMap(map);
    this.calendarAvailableEnabled.addToMap(map);
    this.calendarUserColorsEnabled.addToMap(map);
    this.stockItemProductCodeGeneratorEnabled.addToMap(map);
    this.stockItemCategoryRequired.addToMap(map);
    this.addCalendarDisplayFieldsToMap(map);
    this.calendarWorkdayStartInMinutes.addToMap(map);
    this.calendarWorkdayEndInMinutes.addToMap(map);
    this.calendarDisplayStartInMinutes.addToMap(map);
    this.calendarDisplayEndInMinutes.addToMap(map);
    this.calendarDisplayMonogram.addToMap(map);
    this.calendarSplitToWorkday.addToMap(map);
    this.calendarWorkdayOnWeekend.addToMap(map);
    this.taskRecordAdminNavigateOnStateChange.addToMap(map);
    this.taskRecordMobileShowArchived.addToMap(map);
    this.taskRecordPhoneNumberRelatedList.addToMap(map);
    this.allowAutomaticSignatureLoading.addToMap(map);
    this.workChronologyHoursPerDay.addToMap(map);
    this.taskRecordWebadminDashboardVisibleCount.addToMap(map);
    this.workChronologyDaysPerWeek.addToMap(map);
    this.customerRecordManagedFields.addToMap(map);
    this.mobileImageCompressionLevel.addToMap(map);
    this.taskRecordManagedFields.addToMap(map);
    this.taskRecordAssigneeFilters.addToMap(map);
    this.taskRecordRejectedToArchived.addToMap(map);
    this.geofencingDefaultRadiusInMeters.addToMap(map);
    this.acceptOnlyComplexAddress.addToMap(map);
    this.acceptOnlyValidZipCodeFormat.addToMap(map);
    this.acceptOnlyExistingCityInCountries.addToMap(map);
    this.acceptOnlyExistingStreetTypeInCountries.addToMap(map);
    this.orderMaxPackageWeight.addToMap(map);
    this.shipmentHandoverDuration.addToMap(map);
    this.shipmentTakeoverDuration.addToMap(map);
    this.transportPositionLogInterval.addToMap(map);
    this.transportDirectionsInterval.addToMap(map);
    this.transportLostDriverThreshold.addToMap(map);
    this.transportLateThreshold.addToMap(map);
    this.transportAutoCloseAfterFinish.addToMap(map);
    this.defaultSelectedStreetType.addToMap(map);
    this.defaultSelectedCountryCode.addToMap(map);
    this.integrationProspeditionImporterRejectUnknownProduct.addToMap(map);
    this.integrationProspeditionImporterRejectInsufficientAmount.addToMap(map);
    this.integrationProspeditionImporterSystemMessageUserGroup.addToMap(map);
    return map;
  }

  private addCalendarDisplayFieldsToMap(map: Map<string, string>) {
    // Converting calendar display labels (hack)
    const fields: CalendarDisplayLabel[] = [];
    fields.push(...this.calendarWebadminLabelsLine1.value.map(l => l.id));
    fields.push(...this.calendarWebadminLabelsLine2.value.map(l => l.id));
    fields.push(...this.calendarWebadminLabelsLine3.value.map(l => l.id));
    map.set(Keys.CALENDAR_WEBADMIN_LABELS,
      fields.map(f => JSON.stringify(f))
        .map(s => s.startsWith('"') ? s.substr(1, s.length - 2) : s)
        .join(','));
  }

}

interface Mappable<T> {

  load(map: Map<string, string>, from?: MultiselectOptionItem<T>[]);

  addToMap(map: Map<string, string>);

}

class BooleanField implements Mappable<null> {
  key: string;
  value: boolean;

  constructor(key: string, value: boolean) {
    this.key = key;
    this.value = value;
  }

  load(map: Map<string, string>) {
    const mapValue = map[this.key];
    if (mapValue !== undefined) {
      this.value = mapValue === 'true';
    }
  }

  addToMap(map: Map<string, string>) {
    map.set(this.key, JSON.stringify(this.value));
  }

}

class MultiSelectField<T> implements Mappable<T> {
  key: string;
  value: MultiselectOptionItem<T>[];
  loadDefaultValuesIfAbsent: boolean = false;

  constructor(key: string, value: MultiselectOptionItem<T>[], loadDefaultValuesIfAbsent?: boolean) {
    this.key = key;
    this.value = value;
    this.loadDefaultValuesIfAbsent = !!loadDefaultValuesIfAbsent;
  }

  load(map: Map<string, string>, from: MultiselectOptionItem<T>[], defaultValues?: MultiselectOptionItem<T>[]) {
    const mapValue = map[this.key];
    if (mapValue !== undefined && mapValue !== null) {
      this.value = [];
      const values: string[] = (<string>mapValue).split(',');
      values.forEach((v) => {
        from.forEach((f) => {
          const s = JSON.stringify(f.id);
          if (v === (s.startsWith('"') ? s.substr(1, s.length - 2) : s)) {
            this.value.push(f);
          }
        });
      });
      this.value = this.value.sort((a, b) => a.itemName.localeCompare(b.itemName));
    } else if (this.loadDefaultValuesIfAbsent) {
      if (defaultValues) {
        this.value = Arrays.clone(defaultValues);
      } else {
        this.value = Arrays.clone(from);
      }
    }
  }

  addToMap(map: Map<string, string>) {
    const ids: string[] = [];
    this.value.forEach((v) => {
      const s = JSON.stringify(v.id);
      ids.push(s.startsWith('"') ? s.substr(1, s.length - 2) : s);
    });
    map.set(this.key, ids.join(','));
  }

}

class TimePickerField implements Mappable<null> {
  key: string;
  value: AppNgbTimeStruct;

  constructor(key: string, value: AppNgbTimeStruct) {
    this.key = key;
    this.value = value;
  }

  load(map: Map<string, string>) {
    const mapValue = map[this.key];
    if (mapValue !== undefined) {
      this.value = Models.minutesToNgbTime(mapValue)
    } else {
      this.value = Models.zeroNgbTime();
    }
  }

  addToMap(map: Map<string, string>) {
    map.set(this.key, Models.ngbTimeToMinutes(this.value) + '');
  }

}

class NumberField implements Mappable<null> {
  key: string;
  value?: number;

  constructor(key: string, value?: number) {
    this.key = key;
    this.value = value;
  }

  load(map: Map<string, string>) {
    const mapValue = map[this.key];
    if (mapValue) {
      this.value = +mapValue;
    }
  }

  addToMap(map: Map<string, string>) {
    if (this.value) {
      map.set(this.key, JSON.stringify(this.value));
    }
  }
}

class TextField implements Mappable<null> {
  key: string;
  value: string;

  constructor(key: string, value: string) {
    this.key = key;
    this.value = value;
  }

  load(map: Map<string, string>) {
    const mapValue = map[this.key];
    if (mapValue) {
      this.value = mapValue;
    }
  }

  addToMap(map: Map<string, string>) {
    map.set(this.key, this.value);
  }
}

class Keys {
  public static readonly ENVIRONMENT_NAME: string = 'Runtime.Const.Environment.Name';
  public static readonly SINGLE_USER_MODE: string = 'Runtime.FeatureFlag.SingleUserMode';
  public static readonly SINGLE_USER_MODE_TWO_WAY: string = 'Runtime.FeatureFlag.SingleUserMode.TwoWay';
  public static readonly DEMO_MODE_ENABLED: string = 'Runtime.FeatureFlag.DemoMode.Enabled';
  public static readonly HELPDESK_ENABLED: string = 'Runtime.FeatureFlag.Helpdesk.Enabled';
  public static readonly REGISTRATION_ENABLED: string = 'Runtime.FeatureFlag.Registration.Enabled';
  public static readonly REGISTRATION_INACTIVATION_DAYS: string = 'Runtime.FeatureFlag.Registration.InactivationDays';
  public static readonly USER_DRIVER_ENABLED: string = 'Runtime.FeatureFlag.User.Driver.Enabled';
  public static readonly USER_EMAIL_REQUIRED: string = 'Runtime.FeatureFlag.User.EmailAddress.Required';
  public static readonly USER_EMAIL_UNIQUE: string = 'Runtime.FeatureFlag.User.EmailAddress.Unique';
  public static readonly REGISTER_MOBILE_APPLICATION_AS_DISABLED: string = 'Runtime.FeatureFlag.RegisterMobileApplicationAsDisabled';
  public static readonly CALENDAR_AVAILABLE_ENABLED: string = 'Runtime.FeatureFlag.Calendar.AvailableEnabled';
  public static readonly CALENDAR_USER_COLORS_ENABLED: string = 'Runtime.FeatureFlag.Calendar.UserColorsEnabled';
  public static readonly CALENDAR_WEBADMIN_LABELS: string = 'Runtime.FeatureFlag.Calendar.WebadminLabels';
  public static readonly CALENDAR_DISPLAY_MONOGRAM: string = 'Runtime.FeatureFlag.Calendar.DisplayMonogram';
  public static readonly CALENDAR_WORKDAY_START_IN_MINUTES: string = 'Runtime.Const.Calendar.Workday.StartInMinutes';
  public static readonly CALENDAR_WORKDAY_END_IN_MINUTES: string = 'Runtime.Const.Calendar.Workday.EndInMinutes';
  public static readonly CALENDAR_DISPLAY_START_IN_MINUTES: string = 'Runtime.Const.Calendar.Display.StartInMinutes';
  public static readonly CALENDAR_DISPLAY_END_IN_MINUTES: string = 'Runtime.Const.Calendar.Display.EndInMinutes';
  public static readonly CALENDAR_WORKDAY_ON_WEEKEND: string = 'Runtime.Const.Calendar.Workday.Weekend';
  public static readonly CALENDAR_SPLIT_TO_WORKDAY: string = 'Runtime.FeatureFlag.Calendar.SplitToWorkday';
  public static readonly ALLOW_AUTOMATIC_SIGNATURE_LOADING: string = 'Runtime.Const.TaskRecord.AllowAutomaticSignatureLoading';
  public static readonly WORK_CHRONOLOGY_MINUTES_PER_HOUR = 'Runtime.Const.WorkChronology.MinutesPerHour';
  public static readonly WORK_CHRONOLOGY_HOURS_PER_DAY = 'Runtime.Const.WorkChronology.HoursPerDay';
  public static readonly WORK_CHRONOLOGY_DAYS_PER_WEEK = 'Runtime.Const.WorkChronology.DaysPerWeek';
  public static readonly MOBILE_IMAGE_COMPRESSION_LEVEL: string = 'Runtime.FeatureFlag.Android.ImageCompression.Level';
  public static readonly CUSTOMER_RECORD_MANAGED_FIELDS: string = 'Runtime.FeatureFlag.CustomerRecord.ManagedFields';
  public static readonly TASK_RECORD_MANAGED_FIELDS: string = 'Runtime.FeatureFlag.TaskRecord.ManagedFields';
  public static readonly FEATURE_FLAG_TASK_RECORD_MOBILE_SHOW_ARCHIVED: string = 'Runtime.FeatureFlag.TaskRecord.Mobile.ShowArchived';
  public static readonly FEATURE_FLAG_TASK_RECORD_PHONE_NUMBER_RELATED_LIST: string = 'Runtime.FeatureFlag.TaskRecord.List.PhoneNumberRelated';
  public static readonly FEATURE_FLAG_STOCK_ITEM_PRODUCT_CODE_GENERATOR: string = 'Runtime.FeatureFlag.StockItem.ProductCode.Generator.Enabled';
  public static readonly FEATURE_FLAG_STOCK_ITEM_CATEGORY_REQUIRED: string = 'Runtime.FeatureFlag.StockItem.Category.Required';
  public static readonly TASK_RECORD_ASSIGNEE_FILTER: string = 'Runtime.FeatureFlag.TaskRecord.AvailableAssigneeFilter';
  public static readonly FEATURE_FLAG_TASK_RECORD_WEBADMIN_VISIBLE_COUNT: string = 'Runtime.FeatureFlag.TaskRecord.Webadmin.Dashboard.VisibleCount';
  public static readonly ACCEPT_ONLY_COMPLEX_ADDRESS: string
    = 'Runtime.FeatureFlag.PostalAddress.AcceptOnlyComplexAddress';
  public static readonly ACCEPT_ONLY_VALID_ZIP_CODE_FORMAT: string
    = 'Runtime.FeatureFlag.PostalAddress.AcceptOnlyValidZipCodeFormat';
  public static readonly ACCEPT_ONLY_EXISTING_CITY_IN_COUNTRIES: string
    = 'Runtime.FeatureFlag.PostalAddress.AcceptOnlyExistingCityInCountries';
  public static readonly ACCEPT_ONLY_EXISTING_STREET_TYPE_IN_COUNTRIES: string
    = 'Runtime.FeatureFlag.PostalAddress.AcceptOnlyExistingStreetTypeInCountries';
  public static readonly FEATURE_FLAG_TASK_RECORD_WEBADMIN_NAVIGATE_ON_STATE_CHANGE
    = 'Runtime.FeatureFlag.TaskRecord.Webadmin.NavigateOnStateChange';
  public static readonly TASK_GEOFENCING_DEFAULT_RADIUS_IN_METERS: string = 'Runtime.FeatureFlag.Task.Geofencing.DefaultRadiusInMeters';
  public static readonly ORDER_MAX_PACKAGE_WEIGHT: string = 'Runtime.Const.Order.MaxPackageWeightInGram';
  public static readonly SHIPMENT_HANDOVER_DURATION: string = 'Runtime.Const.Shipment.Handover.DurationInSeconds';
  public static readonly SHIPMENT_TAKEOVER_DURATION: string = 'Runtime.Const.Shipment.Takeover.DurationInSeconds';
  public static readonly TRANSPORT_POSITION_LOG_INTERVAL: string = 'Runtime.Const.Transport.PositionLog.IntervalInSeconds';
  public static readonly TRANSPORT_DIRECTIONS_INTERVAL: string = 'Runtime.Const.Transport.PositionLog.Directions.IntervalInSeconds';
  public static readonly TRANSPORT_LOST_DRIVER_THRESHOLD: string = 'Runtime.Const.Transport.LostDriver.ThresholdInSeconds';
  public static readonly TRANSPORT_LATE_THRESHOLD: string = 'Runtime.Const.Transport.Late.ThresholdInSeconds';
  public static readonly TRANSPORT_AUTO_CLOSE_AFTER_FINISH: string = 'Runtime.Const.Transport.AutoCloseAfterFinish';
  public static readonly DEFAULT_SELECTED_STREET_TYPE: string = 'Runtime.Const.PostalAddress.DefaultSelectedStreetType';
  public static readonly DEFAULT_SELECTED_COUNTRY_CODE: string = 'Runtime.Const.PostalAddress.DefaultSelectedCountryCode';
  public static readonly INTEGRATION_PROSPEDITION_IMPORTER_REJECT_UNKNOWN_PRODUCT
    = 'Runtime.Integration.Prospedition.Importer.RejectUnknownProduct';
  public static readonly INTEGRATION_PROSPEDITION_IMPORTER_SYSTEM_MESSAGE_USER_GROUP_ID
    = 'Runtime.Integration.Prospedition.Importer.SystemMessageUserGroupId';
  public static readonly INTEGRATION_PROSPEDITION_IMPORTER_REJECT_INSUFFICIENT_AMOUNT
    = 'Runtime.Integration.Prospedition.Importer.RejectInsufficientAmount';
  public static readonly CONST_TASKRECORD_REJECTED_TO_ARCHIVED_ENABLED = 'Runtime.Const.TaskRecord.RejectedToArchivedEnabled';
}

export enum MobileCompressionLevel {
  NONE = 'NONE',
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
  HIGH = 'HIGH'
}
