/* eslint-disable */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DqlExpressionBuilder, SimpleDqlExpressionBuilder } from '../../dql-search-container/dql-search-container.model';
import { DqlSearchFieldComponent } from '../dql-search-field.component';
import { DqlModel } from '../../../../lib/dql/dql.model';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Models } from '../../../../util/model-utils';
import { Dates } from '../../../../lib/util/dates';

/* eslint-enable */

@Component({
  selector: 'app-dql-search-field-date',
  templateUrl: './dql-search-field-date.component.html',
  styleUrls: ['./dql-search-field-date.component.scss']
})
export class DqlSearchFieldDateComponent implements OnInit, DqlSearchFieldComponent {

  @Output()
  onDelete: EventEmitter<DqlExpressionBuilder> = new EventEmitter<DqlExpressionBuilder>();

  @Input()
  expression: SimpleDqlExpressionBuilder;

  @Input()
  disableDelete: boolean = false;

  dateModel?: NgbDateStruct = undefined;

  constructor() {
  }

  ngOnInit() {
    const criteria = this.expression.criteria;
    if (criteria.getValue().length > 0) {
      if (criteria.field!.dataType === DqlModel.QueryableFieldDataType.DATE) {
        const dm = Models.localDateToNgbDate(Dates.parseLocalDateIsoString(criteria.getValue()));
        if (dm) {
          this.dateModel = dm;
        }
      }
      else {
        const dm = Models.offsetDateTimeToNgbDate(Dates.parseOffsetDateTimeIsoString(criteria.getValue()));
        if (dm) {
          this.dateModel = dm;
        }
      }
    }
  }

  setValue(dm: NgbDateStruct | undefined) {
    this.dateModel = dm;

    const criteria = this.expression.criteria;

    if (criteria.field!.dataType === DqlModel.QueryableFieldDataType.DATE) {
      criteria.setValue(Models.ngbDateToLocalDate(this.dateModel).toIsoString());
    }
    if (criteria.field!.dataType === DqlModel.QueryableFieldDataType.DATE_TIME &&
      (criteria.operation!.operation === '<' || criteria.operation!.operation === '<=')) {
      criteria.setValue(Models.parseDateTimeTo(this.dateModel ? this.dateModel : null).toUtcIsoString());
    }
    if (criteria.field!.dataType === DqlModel.QueryableFieldDataType.DATE_TIME &&
      (criteria.operation!.operation === '>' || criteria.operation!.operation === '>=')) {
      criteria.setValue(Models.parseDateTimeFrom(this.dateModel ? this.dateModel : null).toUtcIsoString());
    }
  }

}
