/* eslint-disable */
import { Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ObservableErrorResponse } from '../lib/util/errors';
import { TranslateService } from '@ngx-translate/core';
import { UIRouter } from '@uirouter/angular';
import { AppConfigurationService } from '../lib/app-configuration.service';
import { ConfigurationService } from '../lib/core-ext/configuration.service';
import { Language, SettingsService, } from '../lib/settings.service';
import { UiConstants } from '../util/core-utils';
import { ShopRenterService, ShopRenterStorage } from '../lib/shoprenter/shoprenter.service';
import { toasterConfig } from '../fork/angular2-toaster/src/toaster-config';
import { ResourceHelper } from '../lib/util/http-services';
import { PasswordResetService } from '../lib/password-reset/password-reset.service';
import { StateName, StateRequestParam } from '../app.state-names';
import { NgForm, NgModel } from '@angular/forms';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { MatDialog } from '@angular/material/dialog';
import {
  PasswordResetMode,
  PasswordResetSuccessDialogComponent
} from './password-reset-success-dialog/password-reset-success-dialog.component';
import { RecaptchaV3Utils } from '../util/recaptcha-utils';

/* eslint-enable */

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PasswordResetComponent implements OnInit, OnDestroy {

  UiConstants = UiConstants;
  toasterConfig = toasterConfig;
  PasswordResetMode = PasswordResetMode;

  @ViewChild('f', { static: true })
  fFrom: NgForm;

  @ViewChild('password')
  passwordInput: NgModel;

  @ViewChild('confirmPassword')
  confirmPassword: NgModel;

  mode: PasswordResetMode;
  model: PasswordResetModel = new PasswordResetModel();
  working = false;

  private shopRenterStorage = ShopRenterStorage.getInstance();
  passwordVisible: boolean = false;

  private changeToken: string = '';

  noScrollStyleElement: HTMLStyleElement;

  get brandLogoLoginSrc(): string | undefined {
    return this.resourceHelper.getBrandLogoLoginSrc()
  }

  get loginBackgroundSrc(): string | undefined {
    return this.resourceHelper.getLoginBackgroundSrc()
  }

  get languages(): Language[] {
    return this.settingsService.getAvailableLanguages().toArray();
  }

  get shopRenterCase(): boolean {
    return this.shopRenterStorage.getShopId() !== null;
  }

  get formattedAppVersion(): string {
    return this.appConfigurationService.getFormattedAppVersion();
  }

  get serverVersion(): string {
    return this.configurationService.getServerVersion();
  }

  get titleKey(): string {
    switch (this.mode) {
      case PasswordResetMode.REQUEST:
        return 'PASSWORD_RESET_TITLE_REQUEST';
      case PasswordResetMode.CHANGE:
        return 'PASSWORD_RESET_TITLE_CHANGE';
    }
  }

  get submitKey(): string {
    switch (this.mode) {
      case PasswordResetMode.REQUEST:
        return 'PASSWORD_RESET_SUBMIT_REQUEST';
      case PasswordResetMode.CHANGE:
        return 'PASSWORD_RESET_SUBMIT_CHANGE';
    }
  }

  constructor(
    private router: UIRouter,
    private translateService: TranslateService,
    private shopRenterService: ShopRenterService,
    private passwordResetService: PasswordResetService,
    private settingsService: SettingsService,
    private appConfigurationService: AppConfigurationService,
    private recaptchaV3Service: ReCaptchaV3Service,
    private dialog: MatDialog,
    private configurationService: ConfigurationService,
    private resourceHelper: ResourceHelper) {
    this.resolveMode();
  }

  ngOnInit() {
    this.disableScrolling();
  }

  private resolveMode() {
    if (this.router.stateService.$current.name === StateName.PASSWORD_REQUEST) {
      this.mode = PasswordResetMode.REQUEST;
    }
    else {
      this.mode = PasswordResetMode.CHANGE;
      this.changeToken = this.router.stateService.params[StateRequestParam.PASSWORD_RESET_TOKEN];
    }
  }

  isCurrentLanguage(language: Language): boolean {
    return this.settingsService.isCurrentLanguage(language);
  }

  changeLanguage(language: Language) {
    this.settingsService.setLocaleCode(language.localeCode);
    this.translateService.use(language.localeCode).subscribe(
      () => {
        // This does not work:
        // this.router.stateService.reload(StateName.LOGIN);
        // Reload the Angular application instead:
        window.location.reload();
        // Reason:
        // Changing LOCALE_ID (in @angular/core, provided by LOCALE_CODE_PROVIDER) in runtime
        // does no change formatting of pipes.
      });
  }

  submit() {
    if (!this.fFrom.valid) {
      return;
    }
    switch (this.mode) {
      case PasswordResetMode.REQUEST:
        this.request();
        break;
      case PasswordResetMode.CHANGE:
        this.change();
        break;
    }
  }

  private request() {
    this.working = true;
    RecaptchaV3Utils.preAuthenticate(
      this.recaptchaV3Service,
      'passwordResetRequest',
      this.passwordResetService.request({
        userName: this.model.username
      })).subscribe((result) => {
        this.working = false;
        this.dialog.open(PasswordResetSuccessDialogComponent, {data: {mode: this.mode}});
      },
      (error: ObservableErrorResponse) => {
        this.working = false;
        // only global errors
      }
    );
  }

  private change() {
    this.passwordInput.control.updateValueAndValidity();
    this.confirmPassword.control.updateValueAndValidity();
    if (!this.fFrom.valid) {
      return;
    }
    this.working = true;
    RecaptchaV3Utils.preAuthenticate(
      this.recaptchaV3Service,
      'passwordChange',
      this.passwordResetService.change({
        token: this.changeToken,
        password: this.model.password
      })).subscribe(result => {
        this.working = false;
        this.dialog.open(PasswordResetSuccessDialogComponent, {data: {mode: this.mode}});
      },
      (error: ObservableErrorResponse) => {
        this.working = false;
        // only global errors
      }
    );
  }

  togglePasswordVisibility() {
    this.passwordVisible = !this.passwordVisible;
  }

  backToLogin() {
    this.router.stateService.go(StateName.LOGIN, {fromRegister: true});
  }

  private disableScrolling() {
    this.noScrollStyleElement = document.createElement('style');
    this.noScrollStyleElement.type = 'text/css';
    this.noScrollStyleElement.textContent = `
      body {
        overflow-x: hidden !important;
        overflow-y: scroll !important;
      }
    `;
    document.body.appendChild(this.noScrollStyleElement);
  }

  ngOnDestroy() {
    if (this.noScrollStyleElement) {
      document.body.removeChild(this.noScrollStyleElement);
    }
  }

}

class PasswordResetModel {

  username: string = '';
  password: string = '';
  confirmPassword

  constructor() {
  }

}
