import { Dates, OffsetDateTime } from '../../../lib/util/dates';
import { ShippingDemand } from '../../../lib/shipping-demand/shipping-demand.service';
import { Shipment, ShipmentGroup } from '../../../lib/shipment-group/shipment-group.service';

export class ShipmentGroupModel {
  id: number;
  deliveryNoteNumber: string = '';
  demander: string = '';
  transporterCompany: ShippingDemand.Company;
  endpoints: ShipmentGroupEndpoints = new ShipmentGroupEndpoints();
  creationTime: OffsetDateTime = Dates.emptyOffsetDateTime();
  stateObject: ShipmentGroup.ShipmentGroupStateObject;
  shippingDemands: ShipmentGroupShippingDemandModel[] = [];
  outtake: ShipmentGroupOuttakeModel;
}

export class ShipmentGroupShippingDemandModel {
  id: number;
  deliveryNoteNumber: string = '';
  stateStringKey: string = '';
}

export class ShipmentGroupEndpoints {
  source: ShipmentGroupEndpoint = new ShipmentGroupEndpoint();
  destination: ShipmentGroupEndpoint = new ShipmentGroupEndpoint();
}

export class ShipmentGroupEndpoint {
  name: string = '';
  address: string = '';
  coords?: google.maps.LatLng;
}

export class ShipmentGroupOuttakeModel {
  shipmentId: number;
  state: Shipment.ShipmentOuttakeStateObject;
  storekeeper: {
    name: string,
    id?: number,
    groupId: number,
  };
  progressCount: {
    completedCount: number;
    totalCount: number;
  };
  outtakeStartTime?: OffsetDateTime;
}
