/* eslint-disable */
import { environment } from '../../environments/environment';
import { Injectable } from "@angular/core";
/* eslint-enable */

export interface Logger {

  disableDebug(): Logger;

  isTraceEnabled(): boolean;
  isDebugEnabled(): boolean;
  isInfoEnabled(): boolean;
  isWarnEnabled(): boolean;
  isErrorEnabled(): boolean;

  trace(message: any, ...args: any[]): void;
  debug(message: any, ...args: any[]): void;
  info(message: any, ...args: any[]): void;
  warn(message: any, ...args: any[]): void;
  error(message: any, ...args: any[]): void;

}

@Injectable()
export class LoggerFactory {

  public static createLogger(tag: string): Logger {
    if (!tag) {
      throw Error('tag is required');
    }
    if (environment.production) {
      return NoOpLogger.instance;
    }
    return new DefaultLogger(tag, true);
  }

}

type LoggerFn = (message: any, ...args: any[]) => void;

function noOp(message: any, ...args: any[]): void {
}

class DefaultLogger implements Logger {

  private errorEnabled = true;
  private infoEnabled = true;
  private traceEnabled = true;
  private warnEnabled = true;

  constructor(
    private tag: string,
    private debugEnabled: boolean) {
  }

  disableDebug(): Logger {
    return new DefaultLogger(this.tag, false);
  }

  /* eslint-disable */
  get debug(): LoggerFn {
    if (this.isDebugEnabled()) {
      return console.debug.bind(console);
    }
    return noOp;
  }

  get error(): LoggerFn {
    if (this.isErrorEnabled()) {
      return console.error.bind(console);
    }
    return noOp;
  }

  get info(): LoggerFn {
    if (this.isInfoEnabled()) {
      return console.info.bind(console);
    }
    return noOp;
  }

  get trace(): LoggerFn {
    if (this.isTraceEnabled()) {
      return console.trace.bind(console);
    }
    return noOp;
  }

  get warn(): LoggerFn {
    if (this.isWarnEnabled()) {
      return console.warn.bind(console);
    }
    return noOp;
  }
  /* eslint-enable */

  isDebugEnabled(): boolean {
    return this.debugEnabled;
  }

  isErrorEnabled(): boolean {
    return this.errorEnabled;
  }

  isInfoEnabled(): boolean {
    return this.infoEnabled;
  }

  isTraceEnabled(): boolean {
    return this.traceEnabled;
  }

  isWarnEnabled(): boolean {
    return this.warnEnabled;
  }

}

class NoOpLogger implements Logger {

  static instance = new NoOpLogger();

  disableDebug() {
    return this;
  }

  debug(message: any, ...args: any[]): void {
  }

  error(message: any, ...args: any[]): void {
  }

  info(message: any, ...args: any[]): void {
  }

  trace(message: any, ...args: any[]): void {
  }

  warn(message: any, ...args: any[]): void {
  }

  isDebugEnabled(): boolean {
    return false;
  }

  isErrorEnabled(): boolean {
    return false;
  }

  isInfoEnabled(): boolean {
    return false;
  }

  isTraceEnabled(): boolean {
    return false;
  }

  isWarnEnabled(): boolean {
    return false;
  }

}
