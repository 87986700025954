import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { OptionItem } from '../../../../../../util/core-utils';
import { Angular2Multiselects } from '../../../../../../util/multiselect';

@Component({
  selector: 'app-form-record-invoice-field-copy-dialog',
  templateUrl: './form-record-invoice-field-copy-dialog.component.html',
  styleUrls: ['./form-record-invoice-field-copy-dialog.component.scss']
})
export class FormRecordInvoiceFieldCopyDialogComponent implements OnInit {

  selectedField: InvoiceFormField[] = [];

  dropdownSettings: Angular2Multiselects.Settings;

  constructor(public dialogRef: MatDialogRef<FormRecordInvoiceFieldCopyDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: FormRecordInvoiceFieldCopyDialogData) {
    this.initDropdownSettings();
  }

  ngOnInit() {
  }

  initDropdownSettings() {
    this.dropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(true)
      .enableSearchFilter(true)
      .labelKey(OptionItem.KEY_TEXT)
      .enableCheckAll(false)
      .remoteSearch(false)
      .build();
  }

  closeDialog() {
    this.dialogRef.close();
  }

  get isItemSelected(): boolean {
    return this.selectedField.length > 0;
  }

  get selectedItem(): InvoiceFormField | undefined {
    return this.selectedField.length > 0 ? this.selectedField[0] : undefined;
  }
}

export interface FormRecordInvoiceFieldCopyDialogData {
  selectableFields: InvoiceFormField[];
}

export class InvoiceFormField extends OptionItem<number> {
  groupId: number;
}
