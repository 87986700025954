/* eslint-disable */
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MultiselectOptionItem, QueryFieldModel, UiConstants } from '../../../../util/core-utils';
import { OrderType, QueryResult } from '../../../../lib/util/services';
import { Invoice, InvoiceService } from '../../../../lib/invoice/invoice/invoice.service';
import { Set } from 'immutable';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap/datepicker/ngb-date-struct';
import { Dates, LocalDate } from '../../../../lib/util/dates';
import { DownloadedFile } from '../../../../lib/util/downloaded-files';
import { ToasterService } from '../../../../fork/angular2-toaster/angular2-toaster';
import { saveAs } from 'file-saver';
import { TranslateService } from '@ngx-translate/core';
import { UIRouter } from '@uirouter/angular';
import { RightModel } from '../../../../app.rights';
import { RightResolver, RightService } from '../../../../lib/right.service';
import { Models } from '../../../../util/model-utils';
import { InputMask } from '../../../../util/input-masks';
import { InvoiceListModel } from './invoice-list.model';
import { InvoiceSearch, InvoiceSearchService } from '../../../../lib/invoice-search-service';
import { combineLatest, Observable } from 'rxjs';
import { InvoiceSettings, InvoiceSettingsService } from '../../../../lib/invoice/invoice-settings/invoice-settings.service';
import { Angular2Multiselects } from '../../../../util/multiselect';
import { MatDialog } from '@angular/material/dialog';
import { InvoiceStornoDialogComponent, InvoiceStornoDialogResult } from '../invoice-storno-dialog/invoice-storno-dialog.component';
import { StateName } from '../../../../app.state-names';
import { SearchBooleanItem, searchBooleanItemObjects } from '../../../../util/search-utils';
import { InvoiceRecordCreateModel } from '../invoice-create/invoice-create-clone.model';
import {
  InvoiceSpendingDialogComponent,
  InvoiceSpendingDialogData,
  InvoiceSpendingDialogResult
} from '../../invoice-spending/invoice-spending-dialog/invoice-spending-dialog.component';
import { InvoiceBalanceDetailComponent } from '../../invoice-balance/invoice-balance-detail/invoice-balance-detail.component';
import { InvoiceTaggerDialogComponent, InvoiceTaggerDialogResult } from '../invoice-tagger-dialog/invoice-tagger-dialog.component';
import { BadgeStyle } from '../../../../shared/table-badge/badge-style';
import { InvoiceTag } from '../../../../lib/invoice/tag/invoice-tag.service';
import {
  InvoiceBatchDownloadDialogComponent,
  InvoiceBatchDownloadDialogResult
} from '../invoice-batch-download-dialog/invoice-batch-download-dialog.component';
import { InvoiceBookMultiselectProvider } from '../../../../lib/invoice/invoice-book/invoice-book-multiselect-provider.service';
import { InvoiceTagMultiselectProvider } from '../../../../lib/invoice/tag/invoice-tag-multiselect-provider.service';
import { InvoiceSettingsMultiselectProvider } from '../../../../lib/invoice/invoice-settings/invoice-settings-multiselect-provider.service';
import { StockItemMultiselectProvider } from '../../../../lib/stock/stock-item-multiselect.provider';
import { UserMultiselectProvider } from '../../../../lib/user/user-multiselect.provider';
import {ConfigurationService} from "../../../../lib/core-ext/configuration.service";

/* eslint-enable */

@Component({
  selector: 'app-invoice-list',
  templateUrl: './invoice-list.component.html',
  styleUrls: ['./invoice-list.component.scss']
})
export class InvoiceListComponent implements OnInit, OnDestroy {

  InputMask = InputMask;
  UiConstants = UiConstants;
  InvoiceSettings = InvoiceSettings;
  Invoice = Invoice;
  searchBooleanItemObjects = searchBooleanItemObjects;
  BadgeStyle = BadgeStyle;

  list: {
    invoice: Invoice.Invoice,
    selected: boolean,
    records?: InvoiceRecordCreateModel[]
  }[] = [];
  queryModel: QueryFieldModel<Invoice.OrderField> = new QueryFieldModel(Invoice.OrderField.ID, OrderType.DESC);
  searchModel: InvoiceSearchModel = new InvoiceSearchModel();
  searchResetClicked = false;
  showSearch: boolean = false;
  breadcrumbSelf: string;
  compactSidebar: boolean = document.querySelector('body')!.classList.contains('sidebar-compact');
  invoiceBooks: MultiselectOptionItem<number>[] = [];
  invoiceSettings: MultiselectOptionItem<number>[] = [];
  stockItems: MultiselectOptionItem<number>[] = [];
  invoiceTags: MultiselectOptionItem<number>[] = [];
  exportStates: MultiselectOptionItem<Invoice.NavExportState>[] = [];
  directionTypes: MultiselectOptionItem<Invoice.InvoiceDirectionType>[] = [];
  paymentTypes: MultiselectOptionItem<Invoice.InvoicePaymentType>[] = [];
  categoryTypes: MultiselectOptionItem<Invoice.InvoiceCategoryType>[] = [];
  assigneeUsers: MultiselectOptionItem<number>[] = [];

  rightModel: RightModel = RightModel.empty();

  @ViewChild(InvoiceBalanceDetailComponent)
  invoiceBalanceComponent: InvoiceBalanceDetailComponent;

  dropdownSettings: Angular2Multiselects.Settings;
  dropdownSettingsForExportStates: Angular2Multiselects.Settings;
  dropdownSettingsForDirectionTypes: Angular2Multiselects.Settings;
  remoteSearchDropdownSettings: Angular2Multiselects.Settings;

  get eachInvoiceSelected(): boolean {
    let selected = this.list.length > 0;
    this.list.forEach((i) => {
      selected = selected && i.selected;
    });
    return selected;
  }

  get anyInvoiceSelected(): boolean {
    for (let i = 0; i < this.list.length; i++) {
      if (this.list[i].selected) {
        return true;
      }
    }
    return false;
  }

  get validInvoicesSelectedForSettlement(): boolean {
    if (!this.anyInvoiceSelected) {
      return false;
    }

    let lastDirection: Invoice.InvoiceDirectionType | null = null;
    let lastCategory: Invoice.InvoiceCategoryType | null = null;

    for (let i = 0; i < this.list.length; i++) {
      const item = this.list[i];
      if (item.selected) {
        if (item.invoice.settlementData?.settled) {
          return false;
        }
        if (lastDirection === null) {
          lastDirection = item.invoice.directionType;
        }
        else {
          if (lastDirection !== item.invoice.directionType) {
            return false;
          }
        }
        if (lastCategory === null) {
          lastCategory = item.invoice.categoryType;
        }
        else {
          if (lastCategory !== item.invoice.categoryType) {
            return false;
          }
        }
      }
    }
    return true;
  }

  get selectedInvoiceIds(): number[] {
    const result: number[] = [];
    this.list.forEach(i => {
      if (i.selected) {
        result.push(i.invoice.id);
      }
    });
    return result;
  }

  get selectedInvoices(): Invoice.Invoice[] {
    const result: Invoice.Invoice[] = [];
    this.list.forEach(i => {
      if (i.selected) {
        result.push(i.invoice);
      }
    });
    return result;
  }

  constructor(private invoiceService: InvoiceService,
              private invoiceSearchService: InvoiceSearchService,
              private invoiceSettingsService: InvoiceSettingsService,
              private invoiceBookMultiselectProvider: InvoiceBookMultiselectProvider,
              private invoiceTagMultiselectProvider: InvoiceTagMultiselectProvider,
              private invoiceSettingsMultiselectProvider: InvoiceSettingsMultiselectProvider,
              private stockItemMultiselectProvider: StockItemMultiselectProvider,
              private userMultiselectProvider: UserMultiselectProvider,
              private translateService: TranslateService,
              private toasterService: ToasterService,
              private rightService: RightService,
              private dialog: MatDialog,
              private configService: ConfigurationService,
              private uiRouter: UIRouter) {
  }

  ngOnInit() {
    this.translateService.get('MENU_NAVBAR_INVOICES').subscribe(
      (result: string) => {
        this.breadcrumbSelf = result;
      }
    );
    this.loadSearch(() => {
      this.showSearch = !this.searchModel.isEmpty();
    });
    this.loadRightModels();
    this.initExportStates();
    this.initDirectionTypes();
    this.initPaymentTypes();
    this.initCategoryTypes();
    this.initDropDown();
    this.loadList();
  }

  private loadRightModels() {
    this.rightService.getRightResolver().subscribe(
      (resolver: RightResolver) => {
        this.rightModel = RightModel.of(resolver);
      }
    );
  }

  initDropDown() {
    this.dropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(true)
      .enableSearchFilter(true)
      .enableCheckAll(false)
      .remoteSearch(true)
      .build();
    this.dropdownSettingsForExportStates = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(false)
      .enableSearchFilter(true)
      .enableCheckAll(true)
      .build();
    this.dropdownSettingsForDirectionTypes = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(true)
      .enableSearchFilter(true)
      .enableCheckAll(false)
      .build();
    this.remoteSearchDropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(false)
      .enableSearchFilter(true)
      .remoteSearch(true)
      .enableCheckAll(true)
      .build();
  }

  initExportStates() {
    this.exportStates = [];
    InvoiceListModel.InvoiceNavExportStates.forEach((value, key) => {
      if (value) {
        const item = new MultiselectOptionItem<Invoice.NavExportState>();
        item.id = value.id;
        this.translateService.get(value.stringKey).subscribe(result => {
          item.itemName = result;
        });
        this.exportStates.push(item);
      }
    });
  }

  initDirectionTypes() {
    this.directionTypes = [];
    InvoiceListModel.InvoiceDirectionTypes.forEach((value, key) => {
      if (value) {
        const item = new MultiselectOptionItem<Invoice.InvoiceDirectionType>();
        item.id = value.id;
        this.translateService.get(value.stringKey!).subscribe(result => {
          item.itemName = result;
        });
        this.directionTypes.push(item);
      }
    });
  }

  initPaymentTypes() {
    this.paymentTypes = [];
    InvoiceListModel.InvoicePaymentTypes.forEach((value, key) => {
      if (value) {
        const item = new MultiselectOptionItem<Invoice.InvoicePaymentType>();
        item.id = value.id;
        this.translateService.get(value.stringKey!).subscribe(result => {
          item.itemName = result;
        });
        this.paymentTypes.push(item);
      }
    });
  }

  initCategoryTypes() {
    this.categoryTypes = [];
    const allowed: Set<Invoice.InvoiceCategoryType> = Set.of('NORMAL', 'STORNO');
    InvoiceListModel.InvoiceCategoryTypes.forEach((value, key) => {
      if (value && allowed.includes(value.categoryType)) {
        const item = new MultiselectOptionItem<Invoice.InvoiceCategoryType>();
        item.id = value.categoryType;
        this.translateService.get(value.stringKey!).subscribe(result => {
          item.itemName = result;
        });
        this.categoryTypes.push(item);
      }
    });
  }

  pageChanged(selectedPage: number) {
    this.loadList(selectedPage);
  }

  itemsPerPageChanged(itemsPerPage: number) {
    this.queryModel.itemsPerPage = itemsPerPage;
    this.loadList(1);
  }

  orderBy(field: Invoice.OrderField) {
    this.queryModel.onOrderFieldChanged(field);
    this.loadList(1);
  }

  private loadSearch(completion: () => void) {
    const obs: Observable<SearchLoadResult> = combineLatest(
      this.invoiceSearchService.getSearchData({}),
      (storedSearchData: InvoiceSearch.SearchDataResult) => {
        const result: SearchLoadResult = {
          storedSearchData: storedSearchData
        };
        return result;
      }
    );
    obs.subscribe(
      (result: SearchLoadResult) => {
        this.postInitSearch(result.storedSearchData);
        completion();
      }
    );
  }

  private postInitSearch(storedSearchData: InvoiceSearch.SearchDataResult) {
    this.queryModel.itemsPerPage = storedSearchData.searchData.itemsPerPage;
    this.queryModel.currentPage = storedSearchData.searchData.pageNumber;
    this.queryModel.setOrder(storedSearchData.searchData.order);
    this.searchModel.assigneeUsers = storedSearchData.searchData.assigneeUsers;
    this.searchModel.creatorUsers = storedSearchData.searchData.creatorUsers;
    this.searchModel.paymentTypes = storedSearchData.searchData.paymentTypes;
    this.searchModel.categoryTypes = storedSearchData.searchData.categoryTypes;
    this.searchModel.recordStockItems = storedSearchData.searchData.recordStockItems;
    this.searchModel.invoiceBooks = storedSearchData.searchData.invoiceBooks;
    this.searchModel.invoiceTags = storedSearchData.searchData.invoiceTags;
    this.searchModel.invoiceSettings = storedSearchData.searchData.invoiceSettings;
    this.searchModel.customerName = storedSearchData.searchData.customerName;
    this.searchModel.invoiceNumber = storedSearchData.searchData.invoiceNumber;
    this.searchModel.issueDateFrom = Models.localDateToNgbDate(storedSearchData.searchData.issueDateFrom);
    this.searchModel.issueDateTo = Models.localDateToNgbDate(storedSearchData.searchData.issueDateTo);
    this.searchModel.deliveryDateFrom = Models.localDateToNgbDate(storedSearchData.searchData.deliveryDateFrom);
    this.searchModel.deliveryDateTo = Models.localDateToNgbDate(storedSearchData.searchData.deliveryDateTo);
    this.searchModel.deadlineDateFrom = Models.localDateToNgbDate(storedSearchData.searchData.deadlineDateFrom);
    this.searchModel.deadlineDateTo = Models.localDateToNgbDate(storedSearchData.searchData.deadlineDateTo);
    this.searchModel.settlementDateFrom = Models.localDateToNgbDate(storedSearchData.searchData.settlementDateFrom);
    this.searchModel.settlementDateTo = Models.localDateToNgbDate(storedSearchData.searchData.settlementDateTo);
    this.searchModel.settled = storedSearchData.searchData.settled;
    this.searchModel.exportStates = storedSearchData.searchData.exportStates;
    this.searchModel.directionTypes = storedSearchData.searchData.directionTypes;
    this.searchModel.advancedMode = storedSearchData.searchData.advancedMode;
  }

  toggleSearch() {
    this.showSearch = !this.showSearch;
  }

  onSearchClicked() {
    this.loadList();
  }

  onSearchReset() {
    this.invoiceSearchService.resetSearchData().subscribe(
      (result) => {
        this.searchResetClicked = true;
        this.loadSearch(() => {
          this.showSearch = true;
          this.loadList(1);
        });
      }
    );
  }

  ngOnDestroy() {
    this.saveSearch();
  }

  private saveSearch() {
    const request = {
      searchData: {
        itemsPerPage: this.queryModel.itemsPerPage,
        pageNumber: this.queryModel.currentPage,
        order: this.queryModel.getOrder(),
        assigneeUsers: this.searchModel.assigneeUsers,
        creatorUsers: this.searchModel.creatorUsers,
        paymentTypes: this.searchModel.paymentTypes,
        categoryTypes: this.searchModel.categoryTypes,
        recordStockItems: this.searchModel.recordStockItems,
        invoiceBooks: this.searchModel.invoiceBooks,
        invoiceSettings: this.searchModel.invoiceSettings,
        invoiceTags: this.searchModel.invoiceTags,
        customerName: this.searchModel.customerName,
        invoiceNumber: this.searchModel.invoiceNumber,
        issueDateFrom: Models.ngbDateToLocalDate(this.searchModel.issueDateFrom),
        issueDateTo: Models.ngbDateToLocalDate(this.searchModel.issueDateTo),
        deliveryDateFrom: Models.ngbDateToLocalDate(this.searchModel.deliveryDateFrom),
        deliveryDateTo: Models.ngbDateToLocalDate(this.searchModel.deliveryDateTo),
        deadlineDateFrom: Models.ngbDateToLocalDate(this.searchModel.deadlineDateFrom),
        deadlineDateTo: Models.ngbDateToLocalDate(this.searchModel.deadlineDateTo),
        settlementDateFrom: Models.ngbDateToLocalDate(this.searchModel.settlementDateFrom),
        settlementDateTo: Models.ngbDateToLocalDate(this.searchModel.settlementDateTo),
        settled: this.searchModel.settled,
        exportStates: this.searchModel.exportStates,
        directionTypes: this.searchModel.directionTypes,
        advancedMode: this.searchModel.advancedMode
      }
    };
    this.invoiceSearchService.setSearchData(request).subscribe(
      (result) => {
      },
      (error) => {
      }
    );
  }

  private parseDateFrom(model: NgbDateStruct | null): LocalDate | undefined {
    return !model ? undefined : Dates.createLocalDate({
      year: model.year,
      month: model.month,
      day: model.day
    });
  }

  private parseDateTo(model: NgbDateStruct | null): LocalDate | undefined {
    return !model ? undefined : Dates.createLocalDate({
      year: model.year,
      month: model.month,
      day: model.day
    });
  }

  private loadList(pageNumber?: number) {
    const requestedPage = pageNumber ? pageNumber : this.queryModel.currentPage;
    this.invoiceService.query(this.getQueryRequest(pageNumber)).subscribe(
      (result: QueryResult<Invoice.Invoice>) => {
        this.list = result.items.toArray().map(i => ({
          invoice: i,
          selected: false,
          records: i.records ? i.records.map(InvoiceRecordCreateModel.map) : undefined
        }));
        this.queryModel.currentPage = requestedPage;
        this.queryModel.totalNumberOfItems = result.pagingResult.totalNumberOfItems;
        this.queryModel.currentNumberOfItems = result.pagingResult.currentNumberOfItems;
      }, () => {

      },
      () => {
      }
    );
  }

  private getQueryRequest(pageNumber?: number, noPaging?: boolean, ids?: number[]): Invoice.QueryRequest {
    const requestedPage = pageNumber ? pageNumber : this.queryModel.currentPage;
    const order = this.queryModel.getOrder();
    return {
      invoiceIdSet: ids ? Set.of(...ids) : undefined,
      taskRecordAssigneeUserIds: this.searchModel.assigneeUserIds,
      creatorUserId: this.searchModel.creatorUserIds,
      recordStockItemId: this.searchModel.recordStockItemIds,
      invoiceBookIds: this.searchModel.invoiceBookIds,
      invoiceSettingsIds: this.searchModel.invoiceSettingsIds,
      paymentType: this.searchModel.paymentTypeIds,
      categoryType: this.searchModel.categoryTypeIds,
      invoiceTagIds: this.searchModel.invoiceTagIds,
      customerName: this.searchModel.customerName,
      invoiceNumber: this.searchModel.invoiceNumber,
      issueDateFrom: this.searchModel.issueDateFrom ? this.parseDateFrom(this.searchModel.issueDateFrom) : undefined,
      issueDateTo: this.searchModel.issueDateTo ? this.parseDateTo(this.searchModel.issueDateTo) : undefined,
      deliveryDateFrom: this.searchModel.deliveryDateFrom ? this.parseDateFrom(this.searchModel.deliveryDateFrom) : undefined,
      deliveryDateTo: this.searchModel.deliveryDateTo ? this.parseDateTo(this.searchModel.deliveryDateTo) : undefined,
      deadlineDateFrom: this.searchModel.deadlineDateFrom ? this.parseDateFrom(this.searchModel.deadlineDateFrom) : undefined,
      deadlineDateTo: this.searchModel.deadlineDateTo ? this.parseDateTo(this.searchModel.deadlineDateTo) : undefined,
      settlementDateFrom: this.searchModel.settlementDateFrom ? this.parseDateFrom(this.searchModel.settlementDateFrom) : undefined,
      settlementDateTo: this.searchModel.settlementDateTo ? this.parseDateTo(this.searchModel.settlementDateTo) : undefined,
      settlementSettled: searchBooleanItemObjects.find(b => b.value === this.searchModel.settled)!.bool,
      navExportState: Set.of(...this.searchModel.exportStates.map(et => et.id)),
      directionType: this.searchModel.directionType,
      withRecords: this.searchModel.advancedMode,
      withFooter: true,
      order: Set.of(order),
      paging: noPaging ? undefined : {
        pageNumber: requestedPage,
        numberOfItems: this.queryModel.itemsPerPage
      }
    };
  }

  loadAssigneeUsers(q?: string) {
    this.userMultiselectProvider.loadAll(
      q
    ).subscribe((result) => {
      this.assigneeUsers = result;
    });
  }

  onInvoiceTagSearch(q?: string) {
    if (this.rightModel.invoiceTagRead.hasRight()) {
      this.invoiceTagMultiselectProvider.loadActive(q).subscribe(tags => {
        this.invoiceTags = tags;
      });
    }
  }

  onInvoiceBookSearch(q?: string) {
    if (this.rightModel.invoiceBookRead.hasRight()) {
      this.invoiceBookMultiselectProvider.loadActive(q).subscribe(tags => {
        this.invoiceBooks = tags;
      });
    }
  }

  onInvoiceSettingsSearch(q?: string) {
    if (this.rightModel.invoiceSettingsRead.hasRight()) {
      this.invoiceSettingsMultiselectProvider.loadActive(q).subscribe(tags => {
        this.invoiceSettings = tags;
      });
    }
  }

  onStockItemSearch(q?: string) {
    if (this.rightModel.stockItemRead.hasRight()) {
      this.stockItemMultiselectProvider.loadActive(q).subscribe(items => {
        this.stockItems = items;
      });
    }
  }

  openStornoPreselectDialog(invoice: Invoice.Invoice) {
    const dialogRef = this.dialog.open(InvoiceStornoDialogComponent, {
      width: '60%',
      data: {original: invoice}
    });

    dialogRef.afterClosed().subscribe((result: InvoiceStornoDialogResult) => {
      if (result.modified) {
        this.loadList();
      }
    });
  }

  openSettlementDialog(invoice: Invoice.Invoice) {
    this.openBatchSettlementDialog([invoice]);
  }

  openBatchSettlementDialog(invoices: Invoice.Invoice[]) {
    const dialogRef = this.dialog.open(InvoiceSpendingDialogComponent, {
      width: '60%',
      data: {originals: Set.of(...invoices)},
      panelClass: 'custom-dialog-container'
    });

    dialogRef.afterClosed().subscribe((result: InvoiceSpendingDialogResult) => {
      if (result.modified) {
        this.loadList();
        if (this.invoiceBalanceComponent) {
          this.invoiceBalanceComponent.loadBalance();
        }
      }
    });
  }

  openInvoiceTaggerDialog(invoices: Invoice.Invoice[], mode: 'SET' | 'CLEAR') {
    const dialogRef = this.dialog.open(InvoiceTaggerDialogComponent, {
      width: '60%',
      data: {originals: Set.of(...invoices), mode: mode},
      panelClass: 'custom-dialog-container'
    });

    dialogRef.afterClosed().subscribe((result: InvoiceTaggerDialogResult) => {
      if (result.modified) {
        this.loadList();
      }
    });
  }

  openBatchDownloadDialog() {
    const dialogRef = this.dialog.open(InvoiceBatchDownloadDialogComponent, {
      width: '60%',
      panelClass: 'custom-dialog-container'
    });

    dialogRef.afterClosed().subscribe((result: InvoiceBatchDownloadDialogResult) => {
      // nothing to di
    });
  }

  get supExportEnabled(): boolean {
    return this.configService.getConfiguration().feature_flags.task_record.sup_export_enabled;
  }

  exportSupInvoice() {
    if (this.selectedInvoiceIds.length > 0) {
      this.invoiceService.exportSupInvoice(Set.of(...this.selectedInvoiceIds)).subscribe(
        (res: DownloadedFile) => {
          saveAs(res.getBlob(), res.getFileName('invoice_sup_invoice.xml'));
        }
      );
    } else {
      const request = this.getQueryRequest(undefined, true);
      request.withFooter = false;
      request.withRecords = false;
      this.invoiceService.query(request).subscribe(inv => {
        if (inv.items.size > 0) {
          this.invoiceService.exportSupInvoice(Set.of(...inv.items.toArray()
            .filter(x => x !== undefined)
            .map(x => x!.id))).subscribe(
            (res: DownloadedFile) => {
              saveAs(res.getBlob(), res.getFileName('invoice_sup_invoice.xml'));
            });
        } else {
          this.toasterService.pop({
            timeout: UiConstants.ToastTimeoutLong,
            type: UiConstants.toastTypeError,
            title: this.translateService.instant('TASK_RECORD_SUP_EXPORT_NO_TASK_RECORDS_TITLE'),
            body: this.translateService.instant('TASK_RECORD_SUP_EXPORT_NO_TASK_RECORDS_BODY')
          });
        }
      });
    }
  }

  downloadPdf(selectedInvoiceId: number) {
    this.invoiceService.downloadPdf({
      invoiceId: selectedInvoiceId
    }).subscribe(
      (res: DownloadedFile) => {
        saveAs(res.getBlob(), res.getFileName('invoice.pdf'));
      },
      (error) => {
        this.toasterService.pop({
          timeout: UiConstants.ToastTimeoutLong,
          type: UiConstants.toastTypeError,
          title: this.translateService.instant('PDF_DOWNLOAD'),
          body: this.translateService.instant('PDF_DOWNLOAD_ERROR_MESSAGE')
        });
      });
  }

  getInvoiceIconClass(type: Invoice.InvoiceCategoryType): string {
    return InvoiceListModel.InvoiceCategoryTypes.get(type).iconClass;
  }

  getInvoiceCategoryType(type: Invoice.InvoiceCategoryType): string {
    return InvoiceListModel.InvoiceCategoryTypes.get(type).stringKey;
  }

  getInvoicePaymentType(type: Invoice.InvoicePaymentType): string {
    return InvoiceListModel.InvoicePaymentTypes.get(type).stringKey!;
  }

  generatePdf(selectedInvoiceId: number) {
    this.invoiceService.generatePdf({
      invoiceId: selectedInvoiceId
    }).subscribe(
      (res: DownloadedFile) => {
        saveAs(res.getBlob(), res.getFileName('invoice.pdf'));
      },
      (error) => {
        this.toasterService.pop({
          timeout: UiConstants.ToastTimeoutLong,
          type: UiConstants.toastTypeError,
          title: this.translateService.instant('PDF_DOWNLOAD'),
          body: this.translateService.instant('PDF_DOWNLOAD_ERROR_MESSAGE')
        });

      });
  }

  hasIntegrationTypeIcon(type: InvoiceSettings.InvoiceIntegrationType): string | undefined {
    return InvoiceSettings.invoiceIntegrationTypes.find(t => t.type === type)!.thumbnailIcon;
  }

  getIntegrationTypeIcon(type: InvoiceSettings.InvoiceIntegrationType): string | undefined {
    return InvoiceSettings.invoiceIntegrationTypes.find(t => t.type === type)!.thumbnailIcon;
  }

  getIntegrationTypeString(type: InvoiceSettings.InvoiceIntegrationType): string | undefined {
    return InvoiceSettings.invoiceIntegrationTypes.find(t => t.type === type)!.shortStringKey;
  }

  getExportStateClass(exportState: Invoice.NavExportState) {
    const state = InvoiceListModel.InvoiceNavExportStates.find((v, k) => v!.id === exportState);
    return state ? state.style : '';
  }

  exportXlsx() {
    this.invoiceService.exportXlsx(
      this.anyInvoiceSelected
        ? this.getQueryRequest(undefined, true, this.selectedInvoiceIds)
        : this.getQueryRequest(undefined, true)
    ).subscribe((res: DownloadedFile) => {
      saveAs(res.getBlob(), res.getFileName('invoices.xlsx'));
    });
  }

  toggleEachInvoice() {
    const eachInvoiceSelected = !this.eachInvoiceSelected;
    this.list.forEach(cr => {
      cr.selected = eachInvoiceSelected;
    });
  }

  onCreateInvoice(directionType: Invoice.InvoiceDirectionType) {
    this.uiRouter.stateService.go(StateName.INVOICE_CREATE, {type: directionType});
  }

  toggleAdvancedMode() {
    this.searchModel.advancedMode = !this.searchModel.advancedMode;
    this.loadList();
  }

  filterByTag(invoiceTag: InvoiceTag.InvoiceTag) {
    this.searchModel.invoiceTags = [this.invoiceTagMultiselectProvider.toMultiselectOptionItem(invoiceTag)];
    this.loadList();
  }
}

export class InvoiceSearchModel {
  invoiceNumber?: string = undefined;
  invoiceBooks: MultiselectOptionItem<number>[] = [];
  invoiceSettings: MultiselectOptionItem<number>[] = [];
  invoiceTags: MultiselectOptionItem<number>[] = [];
  assigneeUsers: MultiselectOptionItem<number>[] = [];
  creatorUsers: MultiselectOptionItem<number>[] = [];
  recordStockItems: MultiselectOptionItem<number>[] = [];
  customerName?: string = undefined;
  issueDateFrom: NgbDateStruct | null = null;
  issueDateTo: NgbDateStruct | null = null;
  deliveryDateFrom: NgbDateStruct | null = null;
  deliveryDateTo: NgbDateStruct | null = null;
  deadlineDateFrom: NgbDateStruct | null = null;
  deadlineDateTo: NgbDateStruct | null = null;
  settlementDateFrom: NgbDateStruct | null = null;
  settlementDateTo: NgbDateStruct | null = null;
  settled: SearchBooleanItem = SearchBooleanItem.ALL;
  exportStates: MultiselectOptionItem<Invoice.NavExportState>[] = [];
  directionTypes: MultiselectOptionItem<Invoice.InvoiceDirectionType>[] = [];
  paymentTypes: MultiselectOptionItem<Invoice.InvoicePaymentType>[] = [];
  categoryTypes: MultiselectOptionItem<Invoice.InvoiceCategoryType>[] = [];
  advancedMode: boolean = false;

  get assigneeUserIds(): Set<number> | undefined {
    return this.assigneeUsers.length > 0 ? Set.of(...this.assigneeUsers.map(u => u.id)) : undefined;
  }

  get creatorUserIds(): Set<number> | undefined {
    return this.creatorUsers.length > 0 ? Set.of(...this.creatorUsers.map(u => u.id)) : undefined;
  }

  get recordStockItemIds(): Set<number> | undefined {
    return this.recordStockItems.length > 0 ? Set.of(...this.recordStockItems.map(u => u.id)) : undefined;
  }

  get recordStockItemIdArray(): number[] | undefined {
    return this.recordStockItems.length > 0 ? this.recordStockItems.map(u => u.id) : undefined;
  }

  get invoiceBookIds(): Set<number> | undefined {
    return this.invoiceBooks.length > 0 ? Set.of(...this.invoiceBooks.map(u => u.id)) : undefined;
  }

  get invoiceSettingsIds(): Set<number> | undefined {
    return this.invoiceSettings.length > 0 ? Set.of(...this.invoiceSettings.map(u => u.id)) : undefined;
  }

  get invoiceTagIds(): Set<number> | undefined {
    return this.invoiceTags.length > 0 ? Set.of(...this.invoiceTags.map(u => u.id)) : undefined;
  }

  get directionType(): Invoice.InvoiceDirectionType | undefined {
    return this.directionTypes.length === 1 ? this.directionTypes[0].id : undefined;
  }

  get paymentTypeIds(): Set<Invoice.InvoicePaymentType> | undefined {
    return this.paymentTypes.length === 0 ? undefined : Set.of(...this.paymentTypes.map(p => p.id));
  }

  get categoryTypeIds(): Set<Invoice.InvoiceCategoryType> | undefined {
    return this.categoryTypes.length === 0 ? undefined : Set.of(...this.categoryTypes.map(p => p.id));
  }

  public isEmpty(): boolean {
    return this.invoiceNumber === undefined
      && this.invoiceBooks.length === 0
      && this.invoiceSettings.length === 0
      && this.invoiceTags.length === 0
      && this.assigneeUsers.length === 0
      && this.creatorUsers.length === 0
      && this.recordStockItems.length === 0
      && this.paymentTypes.length === 0
      && this.categoryTypes.length === 0
      && this.customerName === undefined
      && this.issueDateFrom === null
      && this.issueDateTo === null
      && this.deliveryDateFrom === null
      && this.deliveryDateTo === null
      && this.deadlineDateFrom === null
      && this.deadlineDateTo === null
      && this.settlementDateFrom === null
      && this.settlementDateTo === null
      && this.settled === SearchBooleanItem.ALL
      && this.exportStates.length === 0
      && this.directionTypes.length === 0
      ;
  }
}

interface SearchLoadResult {
  storedSearchData: InvoiceSearch.SearchDataResult,
}
