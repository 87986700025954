import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { SearchUtils } from '../../../util/search-utils';
import { ShopRenterSearch, ShopRenterSearchService } from '../../../lib/shoprenter/shop-renter-search.service';
import {
  MultiselectOptionItem,
  OrderFieldFunction,
  OrderFieldModel,
  SelectUtils,
  UiConstants
} from '../../../util/core-utils';
import { OrderField } from '../../../lib/query/orderfields';
import { OrderType, QueryResult } from '../../../lib/util/services';
import { Angular2Multiselects } from '../../../util/multiselect';
import { RightModel } from '../../../app.rights';
import { GrantedPermissionSetResolver, RightResolver, RightService } from '../../../lib/right.service';
import { CompanyMultiselectProvider } from '../../../lib/company/company-multiselect.provider';
import { UserMultiselectProvider } from '../../../lib/user/user-multiselect.provider';
import { Set } from 'immutable';
import { OperationRights } from '../../../app.right-definitions';
import { ShopRenter, ShopRenterService } from '../../../lib/shoprenter/shoprenter.service';
import { FilterField } from '../../../lib/query/filterfields';
import { CriteriaBuilder } from '../../../util/model-utils';
import { ShopRenterRightModel } from '../../../lib/shoprenter/shop-renter-right.model';
import { StateName } from '../../../app.state-names';
import { BreadcrumbParent } from '../../../shared/breadcrumb/breadcrumb/breadcrumb.component';
import { Query } from '../../../lib/query/field';
import Fields = ShopRenter.Fields;

@Component({
  selector: 'app-shop-renter-shop-list',
  templateUrl: './shop-renter-shop-list.component.html',
  styleUrls: ['./shop-renter-shop-list.component.scss']
})
export class ShopRenterShopListComponent extends SearchUtils.SearchableList<ShopRenterSearch.Model> implements OnInit, OnDestroy {

  UiConstants = UiConstants;
  SelectUtils = SelectUtils;
  ShopRenterSearch = ShopRenterSearch;

  queryModel: OrderFieldModel<OrderField.ShopRenter>
    = new OrderFieldModel(ShopRenterSearch.OrderFunctions.ID, OrderType.DESC);
  searchModel: ShopRenterSearch.Model = new ShopRenterSearch.Model();

  users: MultiselectOptionItem<number>[] = [];
  companies: MultiselectOptionItem<number>[] = [];

  dropdownSettings: Angular2Multiselects.Settings;

  shopList: ShopRenterShopModel[] = [];
  rightModel: RightModel = RightModel.empty();

  breadcrumbSelf: string;
  breadcrumbParents: BreadcrumbParent[] = [];
  compactSidebar: boolean = document.querySelector('body')!.classList.contains('sidebar-compact');

  constructor(
    private shopRenterService: ShopRenterService,
    private rightService: RightService,
    private companyMultiselectProvider: CompanyMultiselectProvider,
    private userMultiselectProvider: UserMultiselectProvider,
    private shopRenterSearchService: ShopRenterSearchService,
    injector: Injector) {
    super(ShopRenterSearch.Model, injector);
  }

  ngOnInit() {
    this.loadRightModels();
    this.initBreadcrumb();
    this.initSearch();
    this.initDropdownSettings();
  }

  private loadRightModels() {
    this.rightService.getRightResolver().subscribe(
      (resolver: RightResolver) => {
        this.rightModel = RightModel.of(resolver);
      }
    );
  }

  initBreadcrumb() {
    this.translateService.get('SHOP_RENTER_SHOP_LIST').subscribe(
      (result: string) => {
        this.breadcrumbSelf = result;
      }
    );
    this.translateService.get('MENU_NAVBAR_MENU_ADMINISTRATION').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.ADMIN_DASHBOARD});
      }
    );
  }

  initDropdownSettings() {
    this.dropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(true)
      .enableSearchFilter(true)
      .remoteSearch(true)
      .enableCheckAll(false)
      .build();
  }

  loadSearch(completion: () => void) {
    this.shopRenterSearchService.getSearchData({})
      .subscribe(
        (result: ShopRenterSearch.SearchDataResult) => {
          this.queryModel = result.searchData.queryModel;
          this.searchModel = result.searchData.searchModel;
          completion();
        }
      );
  }

  onFirstSearchOpen(): void {
    this.onUserSearch();
    this.onCompanySearch();
  }

  onUserSearch(predicate?: string) {
    this.userMultiselectProvider.loadAll(predicate).subscribe((users) => {
      this.users = users;
    })
  }

  onCompanySearch(predicate?: string) {
    this.companyMultiselectProvider.searchCompanies(predicate).subscribe((companies) => {
      this.companies = companies;
    })
  }

  pageChanged(selectedPage: number) {
    this.loadList(selectedPage);
  }

  itemsPerPageChanged(itemsPerPage: number) {
    this.queryModel.itemsPerPage = itemsPerPage;
    this.loadList(1);
  }

  orderBy(field: OrderFieldFunction<OrderField.ShopRenter>) {
    this.queryModel.onOrderFieldChanged(field);
    this.loadList(1);
  }

  loadList(pageNumber?: number) {
    const requestedPage = pageNumber ? pageNumber : this.queryModel.currentPage;
    const rights = Set.of(
      OperationRights.SHOPRENTER_SHOP_UPDATE
    );
    const order = this.queryModel.createOrderFunction();
    const filter = this.createFilter();
    const fields = this.createFields();
    this.shopRenterService.query({
      fields: fields,
      order: order,
      filter: filter,
      rights: rights,
      paging: requestedPage ? {
        pageNumber: requestedPage,
        numberOfItems: this.queryModel.itemsPerPage
      } : undefined,
    }).subscribe((result: QueryResult<ShopRenter.ShopRenterShop>) => {
      this.shopList = [];
      result.items.forEach((shop: ShopRenter.ShopRenterShop) => {
        const model = new ShopRenterShopModel();
        model.id = shop.id;
        model.name = shop.name;
        model.humanName = shop.humanName ? shop.humanName : '';
        model.userPersonName = shop.user ? shop.user.personName : '';
        model.companyName = shop.company ? shop.company.name : '';
        model.id = shop.id;
        model.rights = new ShopRenterRightModel(GrantedPermissionSetResolver.byGrantedRights(shop.grantedRights));
        this.shopList.push(model);
      });
      this.queryModel.currentPage = requestedPage;
      this.queryModel.totalNumberOfItems = result.pagingResult.totalNumberOfItems;
      this.queryModel.currentNumberOfItems = result.pagingResult.currentNumberOfItems;
    });
  }

  private createFilter(): Query.FilterFunction<FilterField.ShopRenter> {
    return (f: FilterField.ShopRenter) => CriteriaBuilder.builder()
      .addNumber((id) => f.id.eq(id), this.searchModel.id)
      .addString((name) => f.name.containsIgnoreCase(name), this.searchModel.name)
      .addString((humanName) => f.humanName.containsIgnoreCase(humanName), this.searchModel.humanName)
      .addNumber((userId) => f.user.id.eq(userId), this.searchModel.userId)
      .addNumber((companyId) => f.company.id.eq(companyId), this.searchModel.companyId)
      .build();
  }

  private createFields(): Query.FieldFunction<Fields.ShopRenter> {
    return (f: Fields.ShopRenter) => {
      return f.each;
    };
  }


  onSearchClicked() {
    this.loadList(1);
  }

  onSearchReset() {
    this.shopRenterSearchService.resetSearchData({}).subscribe(
      (result) => {
        this.loadSearch(() => {
          this.toggleSearch();
          this.loadList(1);
        });
      }
    );
  }

  ngOnDestroy() {
    this.saveSearch();
  }

  private saveSearch() {
    const request = {
        searchData: {
          queryModel: this.queryModel,
          searchModel: this.searchModel
        }
      }
    ;
    this.shopRenterSearchService.setSearchData(request).subscribe(
      (result) => {
      },
      (error) => {
      }
    );
  }

}

class ShopRenterShopModel {
  id: number;
  rights: ShopRenterRightModel = new ShopRenterRightModel(GrantedPermissionSetResolver.empty());
  name: string = '';
  humanName: string = '';
  userPersonName: string = '';
  companyName: string = '';
}
