<div class="card">
  <div class="card-header">
    {{'SHIPMENT_DEPOT_LIST'|translate}}
    <div class="card-actions">
      <a *ngIf="canEdit()"
         class="btn-setting table-header-action-button d-flex align-items-center px-1 w-auto font-weight-normal cursor-pointer"
         (click)="openAddShipmentDepotDialog.emit()"
         title="{{'SHIPMENT_DEPOT_LIST_ADD_NEW' | translate}}">
        <i class="icomoon pr-1 icomoon-add"></i>{{'SHIPMENT_DEPOT_LIST_ADD_NEW' | translate}}</a>
    </div><!-- /.card-actions -->
  </div>

  <div class="card-body padding-card-half">
    <div class="row m-0">
      <div class="col-md-6 col-sm-12 col-xs-12 padding-card-half">
        <div class="depot-header p-1">
          {{endpoints.source.name}}
          <p class="text-muted mb-0">{{endpoints.source.address}}</p>
        </div>
        <div cdkDropList
             [cdkDropListData]="depots"
             [cdkDropListDisabled]="!canEdit()"
             (cdkDropListDropped)="onDepotDropped($event)">
          <div *ngFor="let depot of depots; let i = index; let last = last"
               cdkDrag cdkDragLockAxis="y" [cdkDragData]="depot" class="draggable"
               (cdkDragStarted)="onDraggedChanged(depot, true)" (cdkDragEnded)="onDraggedChanged(depot, false)"
               [ngClass]="{'hidden': depot.dragged}">
            <table class="table mb-0">
              <tr class="depot-row">
                <td class="w-table-actions-single align-middle drag-n-drop-thumb">
                  <i class="icomoon icomoon-menu-thick pr-1 cursor-pointer"
                     [ngClass]="{'drag-n-drop-thumb-readonly': !canEdit()}"
                     title="{{'COMMON_BUTTON_MOVE' | translate}}"
                     cdkDragHandle>
                  </i>
                </td>
                <td class="w-5 align-middle">
                  {{i + 1}}.
                </td>
                <td class="responsive-table-column">
                  {{depot.name}}
                  <p class="text-muted mb-0">{{depot.address}}</p>
                </td>
                <td class="w-table-actions align-middle">
                  <ng-template #inPopover>
                    <i class="icomoon icomoon-incoming popover-icon"></i>
                    {{ 'SHIPMENT_GROUP_DEPOT_IN_WAYBILL_NUMBER' | translate }}
                    <p class="font-weight-bold">{{ depot.in.transportWaybillNumber }}</p>
                  </ng-template>
                  <i class="icomoon icons-table-item icomoon-incoming"
                     *ngIf="depot.inInTransport"
                     [popover]="inPopover" triggers="mouseenter:mouseleave"></i>
                  <ng-template #outPopover>
                    <i class="icomoon icomoon-outgoing popover-icon"></i>
                    {{ 'SHIPMENT_GROUP_DEPOT_OUT_WAYBILL_NUMBER' | translate }}
                    <p class="font-weight-bold">{{ depot.out.transportWaybillNumber }}</p>
                  </ng-template>
                  <i class="icomoon icons-table-item icomoon-outgoing"
                     *ngIf="depot.outInTransport"
                     [popover]="outPopover" triggers="mouseenter:mouseleave"></i>
                  <i class="icomoon icons-table-item icomoon-state-action-validate"
                     *ngIf="depot.shipmentArrived"
                     title="{{'SHIPMENT_DEPOT_POINT_CLEARED' | translate}}"></i>
                  <i class="icomoon icons-table-item icomoon-state-action-invalidate"
                     *ngIf="depot.shipmentFailed"
                     title="{{'SHIPMENT_DEPOT_POINT_FAILED' | translate}}"></i>
                </td>
                <td class="w-table-actions-single align-middle">
                  <a href="#" onclick="return false" class="btn-setting"
                     *ngIf="canEdit() && deletable(depot)"
                     title="{{'COMMON_BUTTON_DELETE' | translate}}"
                     (click)="deleteDepot(depot.id)">
                    <i class="icomoon icons-table-item icomoon-trash icomoon-modify-table"></i>
                  </a>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <div class="depot-header p-1">
          {{endpoints.destination.name}}
          <p class="text-muted mb-0">{{endpoints.destination.address}}</p>
        </div>
      </div>
      <div class="col-md-6 col-sm-12 col-xs-12 padding-card-half">
        <div class="maps-container">
          <div #gmap style="flex: 1 1 auto;min-height: 300px"></div>
        </div>
      </div>
    </div>
  </div>
</div>
