<div class="card">

  <div class="card-header" [ngClass]="{ 'font-weight-normal': !contentVisible }">
    {{ title }}
    <div class="card-actions">
      <a class="btn-setting cursor-pointer" (click)="hideContent()" *ngIf="contentVisible" title="{{'COMMON_BUTTON_COLLAPSE_HIDE' | translate}}"><i class="icomoon icomoon-arrow-up"></i></a>
      <a class="btn-setting cursor-pointer" (click)="showContent()" *ngIf="!contentVisible" title="{{'COMMON_BUTTON_COLLAPSE_SHOW' | translate}}"><i class="icomoon icomoon-dropdown"></i></a>
      <a class="btn-setting cursor-pointer" (click)="removeItem()" *ngIf="!readonly" title="{{'COMMON_BUTTON_REMOVE' | translate}}"><i class="icomoon icomoon-trash"></i></a>
    </div>
  </div>

  <div class="card-body" [ngClass]="{ 'd-none': !contentVisible, 'd-block': contentVisible }">
    <ng-template app-form-record-form-table-field-record-holder></ng-template>
  </div>

</div>
