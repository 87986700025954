/* eslint-disable */
import { Injectable } from '@angular/core';
import {
  ShipmentGroupResource,
  ShipmentGroupResourceService,
  ShipmentGroupTransportResourceService, ShipmentOuttakeResourceService,
  ShipmentResource,
  ShipmentResourceService
} from './shipment-group-resource.service';
import { Observable, Observer } from 'rxjs';
import { Order, PagingRequest, QueryResult, ResourceQueryResult, Services } from '../util/services';
import { List, Map, Set } from 'immutable';
import { OffsetDateTime } from '../util/dates';
import { ShippingDemand, ShippingDemandMapper } from '../shipping-demand/shipping-demand.service';
import { EmptyMessage, IdentityMessage } from '../util/messages';
import { Shipment as ShipmentNameSpace } from './/shipment-group.service';
import { DqlStoredQuery, DqlStoredQueryService } from '../dql/dql-stored-query.service';
import { DqlModel } from '../dql/dql.model';
import { DqlResourceModel } from '../dql/dql-resource.model';
import { BadgeStyle } from '../../shared/table-badge/badge-style';
import { TransportLogResource } from '../transport/transport-log/transport-log-resource.service';
import { TransportLog, TransportLogMapper } from '../transport/transport-log/transport-log.service';

/* eslint-enable */

@Injectable()
export class ShipmentGroupService implements ShipmentGroup.Service, DqlStoredQuery.HolderService {

  private readonly _dqlStoredQueryService: DqlStoredQueryService;

  constructor(private resourceService: ShipmentGroupResourceService,
              private transportResourceService: ShipmentGroupTransportResourceService,
              private shipmentResourceService: ShipmentResourceService,
              private outtakeResourceService: ShipmentOuttakeResourceService) {
    this._dqlStoredQueryService = new DqlStoredQueryService(resourceService.dqlStoredResourceService);
  }

  get dqlStoredQueryService(): DqlStoredQueryService {
    return this._dqlStoredQueryService;
  }

  globalQuery(request: ShipmentGroup.GlobalQueryRequest): Observable<QueryResult<ShipmentGroup.ShipmentGroup>> {
    return Observable.create((observer: Observer<QueryResult<ShipmentGroup.ShipmentGroup>>) => {
      const resourceRequest: ShipmentGroupResource.GlobalQueryRequest = {
        id: Services.createIdParameter(request.id),
        external_id: request.externalId,
        delivery_note_number: request.deliveryNoteNumber,
        customer_name: request.customerName,
        ekaer_id: request.ekaerId,
        deadline_from: Services.offsetDateTimeToString(request.deadlineFrom),
        deadline_to: Services.offsetDateTimeToString(request.deadlineTo),
        safety_shipping: request.safetyShipping,
        exterior_transport_external_id: request.exteriorTransportExternalId,
        transport_external_id: request.transportExternalId,
        transport_waybill_number: request.transportWaybillNumber,
        source_address: request.sourceAddress,
        destination_address: request.destinationAddress,
        state: request.state ? request.state.join(',') : undefined,
        delayed: request.delayed,
        shipping_item: request.shippingItem,
        with_demands: request.withDemands,
        q: request.queryText,
        dql: request.dql,
        order: Services.createOrderFieldParameter(Keys.toShipmentGroupOrderFieldKey, request.orders),
        page_number: request.paging ? request.paging.pageNumber : undefined,
        number_of_items: request.paging ? request.paging.numberOfItems : undefined,
      };
      return this.resourceService.globalQuery(resourceRequest).subscribe(
        (result: ResourceQueryResult<ShipmentGroupResource.ShipmentGroup>) => {
          observer.next({
            items: ShipmentGroupMapper.toPublicShipmentGroupList(result.items),
            pagingResult: result.pagingResult
          });
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  query(request: ShipmentGroup.QueryRequest): Observable<QueryResult<ShipmentGroup.ShipmentGroup>> {
    return Observable.create((observer: Observer<QueryResult<ShipmentGroup.ShipmentGroup>>) => {
      const resourceRequest: ShipmentGroupResource.QueryRequest = {
        transport_id: request.transportId,
        q: request.queryText,
        order: Services.createOrderFieldParameter(Keys.toShipmentGroupOrderFieldKey, request.orders),
        page_number: request.paging ? request.paging.pageNumber : undefined,
        number_of_items: request.paging ? request.paging.numberOfItems : undefined,
      };
      return this.transportResourceService.query(resourceRequest).subscribe(
        (result: ResourceQueryResult<ShipmentGroupResource.ShipmentGroup>) => {
          observer.next({
            items: ShipmentGroupMapper.toPublicShipmentGroupList(result.items),
            pagingResult: result.pagingResult
          });
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  get(request: IdentityMessage): Observable<ShipmentGroup.ShipmentGroup> {
    return Observable.create((observer: Observer<ShipmentGroup.ShipmentGroup>) => {
      return this.resourceService.get(request).subscribe(
        (result: ShipmentGroupResource.ShipmentGroup) => {
          observer.next(ShipmentGroupMapper.toPublicShipmentGroup(result));
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  createByDemands(request: ShipmentGroup.CreateByDemandsRequest): Observable<ShipmentGroup.CreateByDemandsResponse> {
    return Observable.create((observer: Observer<ShipmentGroup.CreateByDemandsResponse>) => {
      const resourceRequest: ShipmentGroupResource.CreateByDemandsRequest = {
        demand_ids: request.demandIds.toArray()
      };
      return this.resourceService.createByDemands(resourceRequest).subscribe(
        (result: ShipmentGroupResource.CreateByDemandsResponse) => {
          observer.next({
            shipmentIds: Set.of(...result.shipment_ids)
          });
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  removeDemand(request: ShipmentGroup.RemoveDemandRequest): Observable<ShipmentGroup.RemoveDemandResponse> {
    return Observable.create((observer: Observer<ShipmentGroup.RemoveDemandResponse>) => {
      const resourceRequest: ShipmentGroupResource.RemoveDemandRequest = {
        shipment_group_id: request.shipmentGroupId,
        demand_id: request.demandId
      };
      return this.resourceService.removeDemand(resourceRequest).subscribe(
        (result: ShipmentGroupResource.RemoveDemandResponse) => {
          observer.next({
            shipmentRemoved: result.shipment_removed
          });
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  getDepots(request: IdentityMessage): Observable<ShipmentGroup.Depot[]> {
    return Observable.create((observer: Observer<ShipmentGroup.Depot[]>) => {
      return this.resourceService.getDepots(request).subscribe(
        (result: ShipmentGroupResource.Depot[]) => {
          observer.next(ShipmentGroupMapper.toPublicDepotArray(result));
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  createDepot(request: ShipmentGroup.DepotCreateRequest): Observable<IdentityMessage> {
    return Observable.create((observer: Observer<IdentityMessage>) => {
      return this.resourceService.createDepot({
        shipment_group_id: request.shipmentGroupId,
        company_location_id: request.companyLocationId
      }).subscribe(
        (result: IdentityMessage) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  deleteDepot(request: ShipmentGroup.DepotDeleteRequest): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      return this.resourceService.deleteDepot({
        shipment_group_id: request.shipmentGroupId,
        id: request.id
      }).subscribe(
        (result: EmptyMessage) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  rearrangeDepots(request: ShipmentGroup.DepotRearrangeRequest): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      return this.resourceService.rearrangeDepos({
        shipment_group_id: request.shipmentGroupId,
        ids: request.ids
      }).subscribe(
        (result: EmptyMessage) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  changeSubtransporter(request: Shipment.ChangeSubtransporterRequest): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      return this.shipmentResourceService.changeSubtransporter({
        shipment_ids: request.shipmentIds.toArray(),
        sub_transporter_company_id: request.subtransporterCompanyId
      }).subscribe(
        (result: EmptyMessage) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  getDqlModel(): Observable<DqlModel.QueryableModel> {
    return Observable.create((observer: Observer<DqlModel.QueryableModel>) => {
      return this.resourceService.getDqlModel().subscribe((result: DqlResourceModel.QueryableModel) => {
          observer.next(new DqlModel.DqlQueryableModelConverter(false).toPublic(result));
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        }
      );
    });
  }

  changeOuttakeStorekeeper(request: Shipment.ChangeOuttakeStorekeeperRequest): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      return this.outtakeResourceService.changeStorekeeper({
        shipment_id: request.shipmentId,
        user_id: request.userId
      }).subscribe(
        (result: EmptyMessage) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  forceReopenOuttake(request: Shipment.ForceReopenOuttakeRequest): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      return this.outtakeResourceService.forceReopen({
        shipment_id: request.shipmentId
      }).subscribe(
        (result: EmptyMessage) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

}

export class ShipmentGroupMapper {
  public static toPublicShipmentGroupList(resourceList: ShipmentGroupResource.ShipmentGroup[]): List<ShipmentGroup.ShipmentGroup> {
    return List.of(...resourceList.map((r) => this.toPublicShipmentGroup(r)));
  }

  public static toPublicShipmentGroup(r: ShipmentGroupResource.ShipmentGroup): ShipmentGroup.ShipmentGroup {
    return {
      id: r.id,
      externalId: r.external_id,
      state: <ShipmentGroup.ShipmentGroupState>r.state,
      demander: ShippingDemandMapper.toPublicDemander(r.demander),
      transporter: ShippingDemandMapper.toPublicCompany(r.transporter)!,
      deliveryNote: this.toPublicDeliveryNote(r.delivery_note),
      demands: r.demands ? ShippingDemandMapper.toPublicList(r.demands) : undefined, // to keep toPublicList intact
      source: ShippingDemandMapper.toPublicShippingPlace(r.source),
      safetyShipping: r.safety_shipping,
      destination: ShippingDemandMapper.toPublicShippingPlace(r.destination),
      shipments: this.toPublicShipmentList(r.shipments),
      outtake: this.toPublicOuttake(r.outtake),
    };
  }

  public static toPublicShipmentList(resourceList: ShipmentResource.Shipment[]): List<Shipment.Shipment> {
    return List.of(...resourceList.map((r) => this.toPublicShipment(r)));
  }

  public static toPublicShipment(r: ShipmentResource.Shipment): Shipment.Shipment {
    return {
      id: r.id,
      externalId: r.external_id,
      state: <Shipment.ShipmentState>r.state,
      deliveryNote: this.toPublicDeliveryNote(r.delivery_note),
      trackingNumber: r.tracking_number,
      transport: this.toPublicTransport(r.transport),
      shipmentGroup: this.toPublicShipmentGroupDeliveryNote(r.shipment_group),
      source: ShippingDemandMapper.toPublicShippingPlace(r.source),
      destination: ShippingDemandMapper.toPublicShippingPlace(r.destination),
      delay: this.toPublicDelay(r.delay),
      subTransporter: r.sub_transporter ?
        {
          id: r.sub_transporter.id,
          name: r.sub_transporter.name
        }
        : undefined
    };
  }

  public static toPublicDeliveryNote(r: ShipmentResource.DeliveryNote): Shipment.DeliveryNote {
    return {
      number: r.number
    };
  }

  public static toPublicTransport(r?: ShipmentResource.Transport): Shipment.Transport | undefined {
    if (r) {
      return {
        id: r.id,
        externalId: r.external_id,
        waybillNumber: r.waybill_number
      };
    }
    return undefined;
  }

  public static toPublicDelay(r?: ShipmentResource.Delay): Shipment.Delay | undefined {
    if (r) {
      return {
        plannedETA: Services.toOffsetDateTime(r.planned_arrival_time),
        delayMinutes: r.delay_minutes,
      };
    }
    return undefined;
  }

  public static toPublicShipmentGroupDeliveryNote(r: ShipmentResource.ShipmentGroup): Shipment.ShipmentGroup {
    return {
      deliveryNote: r.delivery_note
    };
  }

  public static toPublicDepot(r: ShipmentGroupResource.Depot): ShipmentGroup.Depot {
    return {
      id: r.id,
      stock: ShippingDemandMapper.toPublicStock(r.stock),
      location: ShippingDemandMapper.toPublicCompanyLocation(r.location)!,
      in: {state: r.in.state, inTransport: r.in.in_transport, transportWaybillNumber: r.in.transport_waybill_number},
      out: {state: r.out.state, inTransport: r.out.in_transport, transportWaybillNumber: r.out.transport_waybill_number}
    };
  }

  public static toPublicDepotArray(r: ShipmentGroupResource.Depot[]): ShipmentGroup.Depot[] {
    return r.map(d => this.toPublicDepot(d));
  }

  public static toPublicOuttake(r?: ShipmentGroupResource.Outtake): ShipmentGroup.Outtake | undefined {
    if (r) {
      return {
        eventLogs: TransportLogMapper.toPublicList(r.event_logs),
        storekeeper: r.storekeeper ? {
          id: r.storekeeper.id,
          userName: r.storekeeper.user_name,
          personName: r.storekeeper.person_name,
          disabled: r.storekeeper.disabled
        } : undefined,
        state: r.state,
        progressCount: {
          completedCount: r.progress_count.completed_count,
          totalCount: r.progress_count.total_count
        },
        shipmentId: r.shipment_id
      };
    }
    return undefined;
  }
}

export namespace ShipmentGroup {

  export interface Service {

    // <editor-fold desc="CRUD">

    globalQuery(request: GlobalQueryRequest): Observable<QueryResult<ShipmentGroup>>;

    query(request: QueryRequest): Observable<QueryResult<ShipmentGroup>>;

    createByDemands(request: CreateByDemandsRequest): Observable<CreateByDemandsResponse>;

    // </editor-fold>

  }

  export interface ShipmentGroup {
    id: number;
    externalId: string;
    state: ShipmentGroupState;
    demander?: ShippingDemand.Demander;
    transporter: ShippingDemand.Company;
    source: ShippingDemand.ShippingPlace;
    destination: ShippingDemand.ShippingPlace;
    deliveryNote: Shipment.DeliveryNote;
    safetyShipping: boolean;
    demands?: List<ShippingDemand.ShippingDemand>;
    shipments: List<Shipment.Shipment>;
    outtake?: Outtake;
  }

  export interface GlobalQueryRequest {
    id?: Set<number>;
    externalId?: string;
    deliveryNoteNumber?: string;
    customerName?: string;
    ekaerId?: string;
    deadlineFrom?: OffsetDateTime;
    deadlineTo?: OffsetDateTime;
    safetyShipping?: boolean;
    transportExternalId?: string;
    exteriorTransportExternalId?: string;
    transportWaybillNumber?: string;
    sourceAddress?: string;
    destinationAddress?: string;
    state?: Set<ShipmentGroupState>;
    delayed?: boolean;
    shippingItem?: string;
    withDemands?: boolean;
    queryText?: string;
    dql?: string;
    orders?: Set<Order<OrderField>>;
    paging?: PagingRequest;
  }

  export interface QueryRequest {
    transportId: number;
    queryText?: string;
    orders?: Set<Order<OrderField>>;
    paging?: PagingRequest;
  }

  export interface CreateByDemandsRequest {
    demandIds: Set<number>;
  }

  export interface CreateByDemandsResponse {
    shipmentIds: Set<number>;
  }

  export interface RemoveDemandRequest {
    shipmentGroupId: number;
    demandId: number;
  }

  export interface RemoveDemandResponse {
    shipmentRemoved: boolean;
  }

  export type ShipmentGroupState =
    'OPEN' |
    'IN_PROGRESS' |
    'DELIVERED' |
    'HANDOVER_FAILED';

  export class ShipmentGroupStateObject {
    state: ShipmentGroupState;
    stringKey: string;
    iconClass: string;
    badgeStyle: string;
  }

  export const shipmentGroupStates: ShipmentGroupStateObject[] = [
    {
      state: 'OPEN',
      stringKey: 'SHIPMENT_GROUP_STATE_OPEN',
      iconClass: 'icomoon-open',
      badgeStyle: BadgeStyle.PRIMARY
    },
    {
      state: 'IN_PROGRESS',
      stringKey: 'SHIPMENT_GROUP_STATE_IN_PROGRESS',
      iconClass: 'icomoon-transport-delay',
      badgeStyle: BadgeStyle.SECONDARY
    },
    {
      state: 'DELIVERED',
      stringKey: 'SHIPMENT_GROUP_STATE_DELIVERED',
      iconClass: 'icomoon-shipment-delivered',
      badgeStyle: BadgeStyle.SUCCESS
    },
    {
      state: 'HANDOVER_FAILED',
      stringKey: 'SHIPMENT_GROUP_STATE_HANDOVER_FAILED',
      iconClass: 'icomoon-shipment-handover-failed',
      badgeStyle: BadgeStyle.DANGER
    }
  ];

  export interface Depot {
    id: number;
    stock?: ShippingDemand.Stock;
    location: ShippingDemand.CompanyLocation;
    in: Shipment;
    out: Shipment;
  }

  export interface DepotCreateRequest {
    shipmentGroupId: number;
    companyLocationId: number;
  }

  export interface DepotDeleteRequest {
    shipmentGroupId: number;
    id: number;
  }

  export interface DepotRearrangeRequest {
    shipmentGroupId: number;
    ids: number[];
  }

  export interface Shipment {
    state: ShipmentNameSpace.ShipmentState;
    inTransport: boolean;
    transportWaybillNumber?: string;
  }

  export interface Outtake {
    eventLogs: List<TransportLog.TransportLog>;
    storekeeper?: {
      id: number;
      userName: string;
      personName: string;
      disabled: boolean;
    };
    state: Shipment.ShipmentOuttakeState;
    progressCount: {
      completedCount: number;
      totalCount: number;
    };
    shipmentId: number;
  }

  export enum OrderField {
    ID,
    EXTERNAL_ID,
    DELIVERY_NOTE_NUMBER,
    DEMANDER_NAME,
    UNKNOWN
  }

}

export namespace Shipment {

  export interface Shipment {
    id: number;
    externalId: string;
    state: ShipmentState;
    deliveryNote: Shipment.DeliveryNote;
    trackingNumber?: string;
    transport?: Transport;
    shipmentGroup: ShipmentGroup;
    source: ShippingDemand.ShippingPlace;
    destination: ShippingDemand.ShippingPlace;
    delay?: Delay;
    subTransporter?: SubTransporter;
  }

  export interface DeliveryNote {
    number: string;
  }

  export interface Transport {
    id: number;
    externalId: string;
    waybillNumber: string;
  }

  export interface Delay {
    plannedETA: OffsetDateTime;
    delayMinutes: number;
  }

  export interface ShipmentGroup {
    deliveryNote: string;
  }

  export interface ChangeSubtransporterRequest {
    shipmentIds: Set<number>;
    subtransporterCompanyId?: number;
  }

  export interface ChangeOuttakeStorekeeperRequest {
    shipmentId: number;
    userId?: number;
  }

  export interface ForceReopenOuttakeRequest {
    shipmentId: number;
  }

  export interface SubTransporter {
    id: number;
    name: string;
  }

  export type ShipmentState =
    'NEW' |
    'OPEN' |
    'STOCK_PROCESSING_DONE' |
    'DRIVER_TOOK_OVER' |
    'UNDER_SHIPPING' |
    'ARRIVED' |
    'DELIVERED' |
    'HANDOVER_FAILED' |
    'TAKEOVER_FAILED';

  export class ShipmentStateObject {
    state: ShipmentState;
    stringKey: string;
    iconClass: string;
    badgeStyle: string;
  }

  export const shipmentStates: ShipmentStateObject[] = [
    {
      state: 'NEW',
      stringKey: 'SHIPMENT_STATE_NEW',
      iconClass: 'icomoon-shipment-new',
      badgeStyle: BadgeStyle.PRIMARY
    },
    {
      state: 'OPEN',
      stringKey: 'SHIPMENT_STATE_OPEN',
      iconClass: 'icomoon-shipment-open',
      badgeStyle: BadgeStyle.PRIMARY
    },
    {
      state: 'STOCK_PROCESSING_DONE',
      stringKey: 'SHIPMENT_STATE_STOCK_PROCESSING_DONE',
      iconClass: 'icomoon-sidebar-stock',
      badgeStyle: BadgeStyle.PRIMARY
    },
    {
      state: 'DRIVER_TOOK_OVER',
      stringKey: 'SHIPMENT_STATE_DRIVER_TOOK_OVER',
      iconClass: 'icomoon-shipment-driver-took-over',
      badgeStyle: BadgeStyle.PRIMARY
    },
    {
      state: 'UNDER_SHIPPING',
      stringKey: 'SHIPMENT_STATE_UNDER_SHIPPING',
      iconClass: 'icomoon-transport-delay',
      badgeStyle: BadgeStyle.SECONDARY
    },
    {
      state: 'ARRIVED',
      stringKey: 'SHIPMENT_STATE_ARRIVED',
      iconClass: 'icomoon-shipment-arrived',
      badgeStyle: BadgeStyle.SECONDARY
    },
    {
      state: 'DELIVERED',
      stringKey: 'SHIPMENT_STATE_DELIVERED',
      iconClass: 'icomoon-order-state-delivered',
      badgeStyle: BadgeStyle.SUCCESS
    },
    {
      state: 'HANDOVER_FAILED',
      stringKey: 'SHIPMENT_STATE_HANDOVER_FAILED',
      iconClass: 'icomoon-shipment-handover-failed',
      badgeStyle: BadgeStyle.DANGER
    },
    {
      state: 'TAKEOVER_FAILED',
      stringKey: 'SHIPMENT_STATE_TAKEOVER_FAILED',
      iconClass: 'icomoon-shipment-takeover-failed',
      badgeStyle: BadgeStyle.DANGER
    },
  ];

  export type ShipmentOuttakeState =
    'OPEN' |
    'IN_PROGRESS' |
    'SUCCEEDED';

  export class ShipmentOuttakeStateObject {
    state: ShipmentOuttakeState;
    stringKey: string;
    iconClass: string;
    badgeStyle: string;
  }

  export const shipmentOuttakeStates: ShipmentOuttakeStateObject[] = [
    {
      state: 'OPEN',
      stringKey: 'SHIPMENT_OUTTAKE_STATE_OPEN',
      iconClass: 'icomoon-shipment-open',
      badgeStyle: BadgeStyle.PRIMARY
    },
    {
      state: 'IN_PROGRESS',
      stringKey: 'SHIPMENT_OUTTAKE_STATE_IN_PROGRESS',
      iconClass: 'icomoon-new-state-in-progress',
      badgeStyle: BadgeStyle.SECONDARY
    },
    {
      state: 'SUCCEEDED',
      stringKey: 'SHIPMENT_OUTTAKE_STATE_SUCCEEDED',
      iconClass: 'icomoon-order-state-delivered',
      badgeStyle: BadgeStyle.SUCCESS
    },
  ];


  export enum OrderField {
    ID,
    EXTERNAL_ID,
    DELIVERY_NOTE_NUMBER,
    TRANSPORT_EXTERNAL_ID,
    TRANSPORT_WAYBILL_NUMBER,
    CUSTOMER_NAME,
    DEADLINE
  }

}

export class Keys {

  private static readonly ID = 'id';
  private static readonly EXTERNAL_ID = 'external_id';
  private static readonly DELIVERY_NOTE_NUMBER = 'delivery_note_number';
  private static readonly TRANSPORT_EXTERNAL_ID = 'transport_external_id';
  private static readonly TRANSPORT_WAYBILL_NUMBER = 'transport_waybill_number';
  private static readonly CUSTOMER_NAME = 'customer_name';
  private static readonly DEADLINE = 'deadline';
  private static readonly DEMANDER_NAME = 'demander_name';

  private static readonly shipmentOrderFieldKeyMap: Map<Shipment.OrderField, string> = Map.of(
    Shipment.OrderField.ID, Keys.ID,
    Shipment.OrderField.EXTERNAL_ID, Keys.EXTERNAL_ID,
    Shipment.OrderField.DELIVERY_NOTE_NUMBER, Keys.DELIVERY_NOTE_NUMBER,
    Shipment.OrderField.TRANSPORT_EXTERNAL_ID, Keys.TRANSPORT_EXTERNAL_ID,
    Shipment.OrderField.TRANSPORT_WAYBILL_NUMBER, Keys.TRANSPORT_WAYBILL_NUMBER,
    Shipment.OrderField.CUSTOMER_NAME, Keys.CUSTOMER_NAME,
    Shipment.OrderField.DEADLINE, Keys.DEADLINE,
  );

  private static readonly shipmentGroupOrderFieldKeyMap: Map<ShipmentGroup.OrderField, string> = Map.of(
    ShipmentGroup.OrderField.ID, Keys.ID,
    ShipmentGroup.OrderField.EXTERNAL_ID, Keys.EXTERNAL_ID,
    ShipmentGroup.OrderField.DELIVERY_NOTE_NUMBER, Keys.DELIVERY_NOTE_NUMBER,
    ShipmentGroup.OrderField.DEMANDER_NAME, Keys.DEMANDER_NAME,
  );

  public static toShipmentOrderFieldKey(field: Shipment.OrderField): string {
    return Keys.shipmentOrderFieldKeyMap.get(field)!;
  }

  public static toShipmentGroupOrderFieldKey(field: ShipmentGroup.OrderField): string {
    return Keys.shipmentGroupOrderFieldKeyMap.get(field)!;
  }

}
