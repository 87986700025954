/* eslint-disable */
import { TranslateService } from '@ngx-translate/core';
import { SettingsService } from '../lib/settings.service';
import { Injectable } from '@angular/core';
/* eslint-enable */

@Injectable()
export class NumberOfItemsFormatter {

  constructor(private settingsService: SettingsService, private translateService: TranslateService) {
  }

  public format(currentNumberOfItems: number, totalNumberOfItems: number) {
    // NOTE: pipe does not work real-time so we created this method
    this.settingsService.getLocaleCode();
    return currentNumberOfItems + ' / ' + totalNumberOfItems;
  }

}
