/* eslint-disable */
import { AfterViewInit, Component, ElementRef, Injector, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { User, UserQuery, UserService } from '../../../lib/user.service';
import { EmptyMessage } from '../../../lib/util/messages';
import { UserProfileType, userProfileTypes } from '../../../lib/auth.service';
import {
  DataTypeObject,
  MultiselectOptionItemWithData,
  OptionItem,
  QueryFieldModel,
  SelectUtils,
  UiConstants
} from '../../../util/core-utils';
import { RightResolver, RightService, } from '../../../lib/right.service';
import { RightModel } from '../../../app.rights';
import { UserRightModel } from '../../../app.rights.user';
import { BreadcrumbParent } from '../../../shared/breadcrumb/breadcrumb/breadcrumb.component';
import { StateName } from '../../../app.state-names';
import { UserSearch, UserSearchService } from '../../../lib/user-search-service';
import { Strings } from '../../../lib/util/strings';
import { Arrays } from '../../../lib/util/arrays';
import { InputMask } from '../../../util/input-masks';
import {
  LocalFieldValidationErrors,
  LocalFieldValidationErrorsFactory,
  OrderType,
  QueryResult,
  ResourceQueryResult,
  Services
} from '../../../lib/util/services';
import { List, Set } from 'immutable';
import { UserDataLoader, UserDataLoaderPermissionDeniedStrategy } from '../../../lib/user-data-loader';
import { Company, CompanyService } from '../../../lib/company/company.service';
import { Angular2Multiselects } from '../../../util/multiselect';
import { UserGroup, UserGroupService } from '../../../lib/user-group.service';
import { FieldError, FieldErrors, ObservableErrorResourceParser } from '../../../lib/util/errors';
import { ToasterService } from '../../../fork/angular2-toaster/angular2-toaster';
import { NgForm, NgModel } from '@angular/forms';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ConfigurationService } from '../../../lib/core-ext/configuration.service';
import { DisabledEnum, DisabledItem, SearchUtils } from '../../../util/search-utils';
import { DownloadedFile } from '../../../lib/util/downloaded-files';
import { saveAs } from 'file-saver';
import { FileUploadDialogComponent } from '../../../shared/file-upload/dialog/file-upload-dialog.component';
import { UserSearchModel, UserTypeFilterOption, userTypeFilterOptions } from '../../../util/user-utils';
import { DropdownItemType } from '../../../shared/dropdown/dropdown-item/dropdown-item-type';
import { UserProfilePicture, UserProfilePictureService } from '../../../lib/user/user-profile-picture.service';
import SearchableList = SearchUtils.SearchableList;
import { DemoModeFeatureDisabledDialogComponent } from '../../../shared/demo-mode-feature-disabled-dialog/demo-mode-feature-disabled-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { CustomerRecordService } from '../../../lib/customer/customer-record.service';

/* eslint-enable */

@Component({
  selector: 'app-user-list',
  templateUrl: 'user-list.component.html',
  styleUrls: ['user-list.component.scss']
})
export class UserListComponent extends SearchableList<UserSearchModel> implements OnInit, AfterViewInit, OnDestroy {
  User = User;
  UiConstants = UiConstants;
  DisabledEnum = DisabledEnum;
  DropdownItemType = DropdownItemType;

  queryModel: QueryFieldModel<User.OrderField> = new QueryFieldModel(User.OrderField.ID, OrderType.DESC);
  userList: UserListModel[] = [];

  rightModel: RightModel = RightModel.empty();
  userRightModel: UserRightModel = UserRightModel.empty();
  breadcrumbParents: BreadcrumbParent[] = [];
  breadcrumbSelf: string;
  compactSidebar: boolean = document.querySelector('body')!.classList.contains('sidebar-compact');

  @ViewChild('searchIcon', { static: true })
  searchIcon: ElementRef;

  @ViewChild('importDialog', { static: true })
  importDialog: FileUploadDialogComponent;
  uploadPath: string = '/users/import-xls';

  userDisabledItems: DisabledItem[] = [];
  companies: CompanySearchItem[] = [];
  userGroupsForSearch: UserGroupSearchItem[] = [];
  userGroups: UserGroupSearchItem[] = [];
  userTypes: UserTypeSearchItem[] = [];
  InputMask = InputMask;
  SelectUtils = SelectUtils;
  dropdownSettings: Angular2Multiselects.Settings;

  // Form for ldap import validation
  @ViewChild('ldapForm')
  ldapForm: NgForm;

  @ViewChild('ldapUsernameField') ldapUsernameField: NgModel;
  ldapUsername: string = '';

  // Field errors for server validation
  ldapImportFieldErrors: LdapImportFieldErrorMap;

  // validated ldap inputs
  private validatedInputs: LocalFieldValidationErrors<NgModel> =
    LocalFieldValidationErrorsFactory.empty();

  @ViewChild('ldapImportDialog', { static: true }) ldapImportDialog: ModalDirective;
  ldapImportDialogVisible = true;

  get demoModeEnabled(): boolean {
    return this.configurationService.getConfiguration().feature_flags.demo_mode_enabled;
  }

  get ldapEnabled(): boolean {
    return this.configurationService.getConfiguration().feature_flags.ldap_user_enabled;
  }

  constructor(
    private userService: UserService,
    private userProfilePictureService: UserProfilePictureService,
    injector: Injector,
    private userSearchService: UserSearchService,
    private userDataLoader: UserDataLoader,
    private rightService: RightService,
    private companyService: CompanyService,
    private userGroupService: UserGroupService,
    private toasterService: ToasterService,
    private dialog: MatDialog,
    private configurationService: ConfigurationService,
    private customerRecordService: CustomerRecordService) {
    super(UserSearchModel, injector);
    this.ldapImportFieldErrors = {};
  }

  ngOnInit() {
    this.initDropdownSettings();
    this.translateService.get('COMMON_USERS').subscribe(
      (result: string) => {
        this.breadcrumbSelf = result;
      }
    );
    this.translateService.get('MENU_NAVBAR_MENU_ADMINISTRATION').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.ADMIN_DASHBOARD});
      }
    );
    this.loadRightModels(() => {
      this.initSearch();
    });
  }

  ngAfterViewInit(): void {
    this.loadLocalFieldValidationErrors();
  }

  loadSearch(completion: () => void) {
    this.userSearchService.getSearchData({})
      .subscribe((storedSearchData: UserSearch.SearchDataResult) => {
          this.queryModel.itemsPerPage = storedSearchData.searchData.itemsPerPage;
          this.queryModel.currentPage = storedSearchData.searchData.pageNumber;
          this.queryModel.setOrder(storedSearchData.searchData.order);
          this.searchModel.id = storedSearchData.searchData.id;
          this.searchModel.username = storedSearchData.searchData.user_name;
          this.searchModel.personName = storedSearchData.searchData.person_name;
          this.searchModel.externalId = storedSearchData.searchData.external_id;
          this.searchModel.emailAddress = storedSearchData.searchData.email_address;
          this.searchModel.disabled = storedSearchData.searchData.disabled;
          this.searchModel.type = storedSearchData.searchData.type;
          this.searchModel.isDriver = storedSearchData.searchData.isDriver;
          this.searchModel.companies = storedSearchData.searchData.companies;
          this.searchModel.userGroups = storedSearchData.searchData.user_groups;
        },
        () => {
        },
        () => {
          completion();
        });
  }

  onFirstSearchOpen(): void {
    this.userDisabledItems = this.initDisabledOptions();
    this.initUserTypeFilterOptions();
    this.loadCompaniesForSearch();
    this.loadUserGroupsForSearch();
  }

  private initUserTypeFilterOptions() {
    this.userTypes = [];
    Arrays.iterateByIndex(userTypeFilterOptions, (userTypeFilterOption) => {
      const item = new UserTypeSearchItem();
      item.id = userTypeFilterOption.type;
      this.translateService.get(userTypeFilterOption.stringKey).subscribe(
        (text: string) => {
          item.text = text;
        }
      );
      this.userTypes.push(item);
    });
  }

  private loadCompaniesForSearch(q?: string) {
    this.companyService.query({
      name: q ? Strings.undefinedOrNonEmpty(q) : undefined,
      orders: Set.of({field: Company.OrderField.NAME, type: OrderType.ASC}),
      paging: {
        pageNumber: 1,
        numberOfItems: UiConstants.autocompletePageSize
      },
      noProgressBar: true
    }).subscribe((result: QueryResult<Company.Company>) => {
      this.companies = [];
      result.items.forEach((company: Company.Company) => {
        const companyItem = {
          id: company.id,
          text: company.name,
          disabled: company.disabled
        };
        this.companies.push(companyItem);
      });
    });
  }

  private loadUserGroupsForSearch(q?: string) {
    this.userGroupService.query({
      name: q ? Strings.undefinedOrNonEmpty(q) : undefined,
      order: Services.createOrderFieldParameter(UserGroup.Keys.toOrderFieldKey, Set.of(UserGroup.DEFAULT_ORDER)),
      page_number: 1,
      number_of_items: UiConstants.autocompletePageSize,
      no_progress_bar: true
    }).subscribe(
      (result: ResourceQueryResult<UserGroup>) => {
        this.userGroupsForSearch = [];
        result.items.forEach(u => {
          const item = {
            id: u.id,
            text: u.name,
            disabled: u.disabled
          };
          this.userGroupsForSearch.push(item);
        });
      }
    );
  }

  private loadLocalFieldValidationErrors() {
    const validatedInputs = List.of(this.ldapUsernameField);
    this.validatedInputs = LocalFieldValidationErrorsFactory.ofFormFields(this.ldapForm, validatedInputs);
  }

  pageChanged(selectedPage: number) {
    this.loadList(selectedPage);
  }

  itemsPerPageChanged(itemsPerPage: number) {
    this.queryModel.itemsPerPage = itemsPerPage;
    this.loadList(1);
  }

  orderBy(field: User.OrderField) {
    this.queryModel.onOrderFieldChanged(field);
    this.loadList(1);
  }

  setDisabled(event: any, user: User, disabled: boolean) {
    this.userService.setDisabled({
      id: user.id,
      disabled: disabled
    })
      .subscribe(
        (result: EmptyMessage) => {
          this.loadList();
        }
      );
  }

  private loadRightModels(completion: () => void) {
    this.rightService.getRightResolver().subscribe(
      (resolver: RightResolver) => {
        this.rightModel = RightModel.of(resolver);
        this.userDataLoader.load(resolver.userProfile!, UserDataLoaderPermissionDeniedStrategy.MISS_ALL).subscribe(userData => {
          this.userRightModel = UserRightModel.of({
            rightModel: this.rightModel,
            currentUser: userData
          });
          completion();
        });
      });
  }

  loadList(pageNumber?: number) {
    const userGroupIds: number[] = [];
    const contactPersonIds: number[] = [];
    const requestedPage = pageNumber ? pageNumber : this.queryModel.currentPage;
    this.userService.query(this.getQueryRequest(pageNumber))
      .subscribe(
        (result: ResourceQueryResult<User>) => {
          this.userList = result.items.map(u => this.toUserListModel(u));
          this.userList.forEach((u) => {
            u.user_group_ids.forEach((id) => {
              if (!userGroupIds.includes(id)) {
                userGroupIds.push(id);
              }
            });
            if (u.helpdesk && u.helpdesk.contact_person_id) {
              contactPersonIds.push(u.helpdesk!.contact_person_id);
            }
          });
          this.searchModel.userGroups.forEach((ug) => {
            if (!userGroupIds.includes(ug.id!)) {
              userGroupIds.push(ug.id!);
            }
          });
          if (userGroupIds.length > 0) {
            this.userGroupService.query({
              id: userGroupIds.join(','),
              order: Services.createOrderFieldParameter(UserGroup.Keys.toOrderFieldKey, Set.of(UserGroup.DEFAULT_ORDER))
            }).subscribe((result: ResourceQueryResult<UserGroup>) => {
              this.userGroups = [];
              result.items.forEach((ug) => {
                const item: UserGroupSearchItem = {
                  id: ug.id,
                  text: ug.name
                };
                this.userGroups.push(item);
              });
              this.searchModel.userGroups.forEach((ug) => {
                ug.text = this.userGroups.find((group) => group.id === ug.id)!.text;
              });
            });
          }
          if (contactPersonIds.length > 0) {
            this.customerRecordService.globalQuery({
              customerRecordIdSet: Set.of(...contactPersonIds)
            }).subscribe(result => {
              this.userList.forEach(u => {
                if (u.helpdesk) {
                  const cr = result.items.toArray().find(c => c.customerRecordId === u.helpdesk!.contact_person_id);
                  if (cr) {
                    u.contactPerson = {
                      id: cr.customerRecordId,
                      itemName: cr.name,
                      data: {
                        customerId: cr.customerId
                      }
                    };
                  }
                }
              })
            });
          }
          this.queryModel.currentPage = requestedPage;
          this.queryModel.totalNumberOfItems = result.pagingResult.totalNumberOfItems;
          this.queryModel.currentNumberOfItems = result.pagingResult.currentNumberOfItems;
        }
      );
  }

  private getQueryRequest(pageNumber?: number): UserQuery {
    const requestedPage = pageNumber ? pageNumber : this.queryModel.currentPage;
    const order = this.queryModel.getOrder();
    const type: UserProfileType | undefined =
      this.searchModel.type === 'ALL'
        ? undefined
        : this.searchModel.type;
    return {
      id: Strings.undefinedOrNonEmpty(this.searchModel.id),
      user_name: Strings.undefinedOrNonEmpty(this.searchModel.username),
      external_id: Strings.undefinedOrNonEmpty(this.searchModel.externalId),
      email_address: Strings.undefinedOrNonEmpty(this.searchModel.emailAddress),
      person_name: Strings.undefinedOrNonEmpty(this.searchModel.personName),
      is_driver: this.searchModel.isDriver ? this.searchModel.isDriver : undefined,
      company_id: this.searchModel.companies.length > 0 ? this.searchModel.companies.map(c => c.id!).join(',') : undefined,
      user_group_ids: this.searchModel.userGroups.length > 0 ? this.searchModel.userGroups.map(t => t.id!).join(',') : undefined,
      disabled: this.getDisabled(),
      type: type,
      page_number: requestedPage,
      number_of_items: this.queryModel.itemsPerPage,
      order: Services.createOrderFieldParameter(User.Keys.toOrderFieldKey, Set.of(order)),
    };
  }

  private toUserListModel(user: User): UserListModel {
    const userListModel: UserListModel = {
      id: user.id,
      external_id: user.external_id,
      calendar_color: user.calendar_color,
      type: user.type,
      user_name: user.user_name,
      person_name: user.person_name,
      email_address: user.email_address,
      phone_number: user.phone_number,
      user_group_ids: user.user_group_ids,
      creation_time: user.creation_time,
      update_time: user.update_time,
      disabled: user.disabled,
      protected_user: user.protected_user,
      inactivation_time: user.inactivation_time,
      driver: user.driver,
      companies: user.companies,
      enabled_mobile_app_ids: user.enabled_mobile_app_ids,
      has_signature: user.has_signature,
      vehicle_id: user.vehicle_id,
      profile_picture_hash: user.profile_picture_hash,
      helpdesk: user.helpdesk,
      selected: false,
      profile_picture: undefined,
      profileTypeObject: userProfileTypes.find(t => t.id === user.type)!
    };
    if (user.profile_picture_hash && this.rightModel.userRead.hasRight()) {
      userListModel.profile_picture =
        this.userProfilePictureService.getProfilePicture(user.id, user.profile_picture_hash);
    }
    return userListModel;
  }

  importLdapUser() {
    if (this.hasLocalFieldError()) {
      return;
    }
    this.userService.syncLdapUser({
      user_name: this.ldapUsername
    }).subscribe(() => {
        this.closeLdapImportDialog();
        this.loadList();
      },
      (error: any) => {
        const res = ObservableErrorResourceParser.parseError(error);
        this.ldapImportFieldErrors = ObservableErrorResourceParser.extractFieldErrors(res);
      });
  }

  getDisabled(): boolean | undefined {
    if (this.searchModel.disabled === DisabledEnum.FALSE) {
      return false;
    }
    else if (this.searchModel.disabled === DisabledEnum.TRUE) {
      return true;
    }
    return undefined;
  }

  onSearchClicked() {
    this.loadList();
  }

  onSearchReset() {
    this.userSearchService.resetSearchData({}).subscribe(
      (result) => {
        this.searchModel.companies = [];
        this.searchModel.userGroups = [];
        this.loadSearch(() => {
          this.loadList(1);
        });
      }
    );
  }

  private saveSearch() {
    const request = {
      searchData: {
        itemsPerPage: this.queryModel.itemsPerPage,
        pageNumber: this.queryModel.currentPage,
        order: this.queryModel.getOrder(),
        id: this.searchModel.id,
        user_name: this.searchModel.username,
        person_name: this.searchModel.personName,
        external_id: this.searchModel.externalId,
        email_address: this.searchModel.emailAddress,
        disabled: this.searchModel.disabled,
        type: this.searchModel.type,
        isDriver: this.searchModel.isDriver,
        companies: this.searchModel.companies,
        user_groups: this.searchModel.userGroups
      }
    };
    this.userSearchService.setSearchData(request).subscribe(
      (result) => {
      },
      (error) => {
      }
    );
  }

  initDropdownSettings() {
    this.dropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(false)
      .enableSearchFilter(true)
      .remoteSearch(true)
      .enableCheckAll(true)
      .labelKey(OptionItem.KEY_TEXT)
      .build();
  }

  getFirstUserGroup(ids: number[]): string {
    if (ids.length > 0) {
      const filtered_user_groups = this.userGroups.filter(current_user_group => current_user_group.id === ids[0]);
      if (filtered_user_groups === undefined || filtered_user_groups.length === 0) {
        return '';
      }
      return filtered_user_groups[0].text;
    }
    return '';
  }

  getUserGroups(ids: number[]): string[] {
    const groups: string[] = [];
    ids.forEach((id) => {
      const filtered_user_groups = this.userGroups.filter(current_user_group => current_user_group.id === id);
      if (!(filtered_user_groups === undefined || filtered_user_groups.length === 0)) {
        groups.push(filtered_user_groups[0].text);
      }
    });
    groups.shift();
    return groups;
  }

  showLdapImportDialog() {
    this.ldapImportDialogVisible = true;
    this.ldapImportDialog.show();
  }

  closeLdapImportDialog() {
    this.ldapImportDialogVisible = false;
    this.ldapImportDialog.hide();
  }

  onImportSuccess(succeeded: boolean) {
    if (succeeded) {
      this.loadList(1);
    }
  }

  toggleEachUserSelected() {
    const eachUserSelected = !this.eachUserSelected;
    this.userList.forEach((u) => {
      u.selected = eachUserSelected;
    });
  }

  get eachUserSelected(): boolean {
    if (this.userList.length === 0) {
      return false;
    }
    let selected = true;
    this.userList.forEach((u) => {
      selected = selected && u.selected;
    });
    return selected;
  }

  getSelectedIds(): number[] {
    return this.userList.filter(u => u.selected).map(u => u.id);
  }

  exportXls() {
    const selectedIdSet = this.getSelectedIds();
    const request = this.getQueryRequest();
    request.id = selectedIdSet.length > 0 ? selectedIdSet.join(',') : undefined;
    this.userService.exportXls(request).subscribe(
      (res: DownloadedFile) => {
        saveAs(res.getBlob(), res.getFileName('user.xlsx'));
      }
    );
  }

  exportXlsTemplate() {
    this.userService.exportXlsTemplate({}).subscribe(
      (res: DownloadedFile) => {
        saveAs(res.getBlob(), res.getFileName('user-template.xlsx'));
      }
    );
  }

  onModalHidden() {
    this.ldapForm.resetForm();
  }

  hasLocalFieldError(field?: NgModel): boolean {
    return this.validatedInputs.hasLocalError(field);
  }

  removeFieldError(fieldError?: FieldError) {
    FieldErrors.remove(this.ldapImportFieldErrors, fieldError);
  }

  showDisabledDialog() {
    this.dialog.open(DemoModeFeatureDisabledDialogComponent);
  }

  ngOnDestroy() {
    this.saveSearch();
  }

}

interface UserListModel extends User {
  selected: boolean;
  profile_picture: UserProfilePicture | undefined;
  profileTypeObject: DataTypeObject<UserProfileType>;
  contactPerson?: MultiselectOptionItemWithData<number>;
}

export interface CompanySearchItem extends OptionItem<number> {
}

export interface UserGroupSearchItem extends OptionItem<number> {
}

export class UserTypeSearchItem extends OptionItem<UserTypeFilterOption> {
}

export interface LdapImportFieldErrorMap {
  user_name?: FieldError;
}
