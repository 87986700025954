<div class="breadcrumb-container" [ngClass]="{ 'breadcrumb-container-compact': compactSidebar }" id="breadcrumb">
  <div class="breadcrumb-component">
    <app-breadcrumb
      [parents]="breadcrumbParents"
      [self]="breadcrumbSelf">
    </app-breadcrumb>
  </div>
  <div *ngIf="model" class="breadcrumb-menu d-flex">
    <div class="breadcrumb-button-icon-container cursor-pointer"
         (click)="orderDocumentZipDownloadDialog.showDialog()"
         *ngIf="rightModel.orderDocumentDownload.hasRight()">
      <a class="btn-setting" title="{{'ORDER_DOCUMENT_DOWNLOAD_ALL' | translate}}">
        <i class="icomoon icomoon-csv-export"></i> {{'ORDER_DOCUMENT_DOWNLOAD_ALL' | translate}}
      </a>
    </div>
    <!--todo: correct conditions for when to show each button-->
    <div class="breadcrumb-button-icon-container cursor-pointer"
         (click)="stateImportDialog.toggleDialog()"
         *ngIf="model.state === 'OPEN' || model.state === 'IN_PROGRESS'">
      <a class="btn-setting" title="{{'ORDER_MPL_IMPORT' | translate}}">
        <i class="icomoon icomoon-csv-import"></i> {{'ORDER_MPL_IMPORT' | translate}}
      </a>
    </div>
    <div class="breadcrumb-button-icon-container cursor-pointer"
         (click)="mplExportDialog.showDialog()"
         *ngIf="model.state === 'OPEN' || model.state === 'IN_PROGRESS'">
      <a class="btn-setting" title="{{'ORDER_MPL_EXPORT' | translate}}">
        <i class="icomoon icomoon-csv-export"></i> {{'ORDER_MPL_EXPORT' | translate}}
      </a>
    </div>
    <div class="breadcrumb-button-icon-container cursor-pointer process-button"
         (click)="reopenProcess()"
         *ngIf="model.state === 'FAILED'">
      <a class="btn-setting" title="{{'PROCESS_EDIT_REOPEN_PROCESS' | translate}}">
        <i class="icomoon icomoon-process-reopen"></i> {{'PROCESS_EDIT_REOPEN_PROCESS' | translate}}
      </a>
    </div>
    <div class="breadcrumb-button-icon-container cursor-pointer process-button"
         (click)="continuePreviousTask()"
         *ngIf="rightModel.legacyProcessChangeState.hasRight()
          && model.state === 'FAILED'
          && processTasks[0].state !== 'FAILED'">
      <a class="btn-setting" title="{{'PROCESS_EDIT_CONTINUE_PREVIOUS_TASK' | translate}}">
        <i class="icomoon icomoon-process-previous"></i> {{'PROCESS_EDIT_CONTINUE_PREVIOUS_TASK' | translate}}
      </a>
    </div>
    <div class="breadcrumb-button-icon-container cursor-pointer process-button"
         (click)="continueCurrentTask()"
         *ngIf="rightModel.legacyProcessChangeState.hasRight() && model.state === 'FAILED'">
      <a class="btn-setting" title="{{'PROCESS_EDIT_CONTINUE_CURRENT_TASK' | translate}}">
        <i class="icomoon icomoon-process-start"></i> {{'PROCESS_EDIT_CONTINUE_CURRENT_TASK' | translate}}
      </a>
    </div>
    <div class="breadcrumb-button-icon-container cursor-pointer process-button-close"
         (click)="showCloseProcessDialog()"
         [ngClass]="{ 'negative-margin-right': model.state === 'FAILED' }"
         *ngIf="model.state === 'OPEN' || model.state === 'FAILED'">
      <a class="btn-setting" title="{{'PROCESS_EDIT_CLOSE_PROCESS' | translate}}">
        <i class="icomoon icomoon-process-close"></i> {{'PROCESS_EDIT_CLOSE_PROCESS' | translate}}
      </a>
    </div>
    <div class="breadcrumb-button-icon-container cursor-pointer process-button-open"
         (click)="startProcess()"
         *ngIf="model.state === 'OPEN'">
      <a class="btn-setting" title="{{'PROCESS_EDIT_START_PROCESS' | translate}}">
        <i class="icomoon icomoon-process-start"></i> {{'PROCESS_EDIT_START_PROCESS' | translate}}
      </a>
    </div>
  </div>
</div>
<div class="breadcrumb-placeholder"></div>
<div class="container-custom-padding-outer animated fadeIn">

  <div class="row ml-0 mr-0">

    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 card-custom-padding">

      <div class="container-vertical-padding-half">

        <!--BASIC DATA-->
        <div class="card">
          <div class="card-header">
            {{'PROCESS_EDIT_BASEDATA_HEADER' | translate}}
          </div>

          <div class="card-body">

            <form class="form-horizontal bordered-row" (ngSubmit)="saveBaseData()" [formGroup]="processEditForm" #f="ngForm"
                  *ngIf="model">

              <div class="form-group row">
                <div class="col-md-4 col-form-label">
                  <label class="col-form-label mw-100 form-control-label">{{'PROCESS_EDIT_STATE'|translate}}</label>
                </div>
                <!-- /.col-md-4 -->
                <div class="col-md-8">
                  <div class="row m-0 d-flex align-items-center">
                    <i class="icomoon {{getProcessStateIcon(model.state)}} state-icon"></i>
                    <span class="state-name">{{getProcessStateName(model.state)}}</span>
                    <span class="text-muted"
                          *ngIf="openTask">{{'PROCESS_EDIT_SUBTASK_IN_PROGRESS' | translate: {taskName: openTask} }}</span>
                  </div>
                  <!-- /.row -->
                </div>
                <!-- /.col-md-8 -->
              </div>
              <!-- /.form-group -->

              <div class="form-group row"
                   [ngClass]="{ 'has-danger': hasFieldError(LegacyProcess.ValidatedField.NAME) }">
                <div class="col-md-4">
                  <label
                    class="col-form-label mw-100 form-control-label">{{'COMMON_NAME'|translate}}</label>
                </div>
                <div class="col-md-8">
                  <input type="text" class="form-control" placeholder="{{'COMMON_NAME' | translate}}"
                         [(ngModel)]="baseDataModel.name"
                         formControlName="name"
                         (ngModelChange)="removeFieldError(LegacyProcess.ValidatedField.NAME)">
                  <div class="form-control-feedback" *ngIf="hasFieldError(LegacyProcess.ValidatedField.NAME)">
                    {{getFieldErrorText(LegacyProcess.ValidatedField.NAME)}}
                  </div>
                </div>
              </div>

              <div class="form-group row"
                   [ngClass]="{ 'has-danger': (formSubmitted
                                     && !processEditForm.controls['externalId'].valid)
                                     || hasFieldError(LegacyProcess.ValidatedField.EXTERNAL_ID)}">
                <div class="col-md-4">
                  <label
                    class="col-form-label mw-100 form-control-label required-field-label">
                    {{'PROCESS_EDIT_EXTERNAL_ID'|translate}}
                  </label>
                </div>
                <div class="col-md-8">
                  <input type="text" class="form-control" placeholder="{{'PROCESS_EDIT_EXTERNAL_ID' | translate}}"
                         [(ngModel)]="baseDataModel.externalId"
                         formControlName="externalId"
                         (ngModelChange)="removeFieldError(LegacyProcess.ValidatedField.EXTERNAL_ID)">
                  <div class="form-control-feedback" *ngIf="formSubmitted && processEditForm.controls['externalId'].touched
                                                   && processEditForm.controls['externalId'].hasError('required')">
                    {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
                  </div>
                  <div class="form-control-feedback" *ngIf="hasFieldError(LegacyProcess.ValidatedField.EXTERNAL_ID)">
                    {{getFieldErrorText(LegacyProcess.ValidatedField.EXTERNAL_ID)}}
                  </div>
                </div>
              </div>

              <div class="form-group row">
                <label class="col-form-label form-control-label col-md-4 detail-title">{{'COMMON_DESCRIPTION' |
                    translate}}</label>
                <div class="col-md-8">
                  <input type="text" class="form-control" placeholder="{{'COMMON_DESCRIPTION' | translate}}"
                         [(ngModel)]="baseDataModel.description" name="description" id="description"
                         #description="ngModel"
                         [ngModelOptions]="{standalone: true}">
                </div>
              </div>

              <div class="form-group row"
                   [ngClass]="{ 'has-danger': (formSubmitted
                                     && !processEditForm.controls['deadlineDate'].valid) }">
                <label class="col-form-label form-control-label col-md-4 detail-title">{{'PROCESS_EDIT_DEADLINE' |
                    translate}}</label>
                <div class="col-md-8">
                  <div class="row">

                    <div class="input-group col-md-6">
                      <input placeholder="{{'PROCESS_EDIT_DEADLINE_DATE' | translate}}" type="text"
                             class="form-control"
                             [(ngModel)]="baseDataModel.deadlineDate"
                             formControlName="deadlineDate"
                             ngbDatepicker #deadlineDate="ngbDatepicker"
                             validateLocalDate>
                      <span class="add-on input-group-addon-gray" (click)="deadlineDate.toggle()">
                        <i class="icomoon icomoon-calendar"></i>
                       </span>
                    </div>

                    <div class="input-group col-md-6">
                      <input placeholder="{{'PROCESS_EDIT_DEADLINE_TIME' | translate}}" type="text"
                             class="form-control hidden-value-when-disabled"
                             [(ngModel)]="baseDataModel.deadlineTime"
                             [ngModelOptions]="{standalone: true}"
                             [seconds]="false"
                             [disabled]="baseDataModel.deadlineDate === null || baseDataModel.deadlineDate === undefined"
                             [allowEmptyValue]="false"
                             ngbTimepicker #deadlineTime="ngbTimepicker" [toggleButtonId]="'deadline_toggle_button'"
                             [spinners]="true" [hourStep]="1" [minuteStep]="10">
                      <span class="add-on input-group-addon-gray"
                            id="deadline_toggle_button"
                            (click)="deadlineTime.toggle()">
                        <i class="icomoon icomoon-time"></i>
                      </span>
                    </div>
                  </div>
                  <div class="row">
                    <div class="form-control-feedback col-md-12" *ngIf="formSubmitted && processEditForm.controls['deadlineDate'].touched
                                                   && processEditForm.controls['deadlineDate'].hasError('required')">
                      {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
                    </div>
                  </div>
                  <!-- /.row -->
                </div>
              </div>

              <div class="d-flex justify-content-end pl-0">
                <input type="submit" class="btn btn-primary" value="{{'COMMON_BUTTON_SAVE'|translate}}"/>
              </div>

            </form>

          </div> <!--./card-body-->
        </div>
        <!--./BASIC DATA-->

      </div>
      <!-- /.container-vertical-padding-half -->

    </div>

    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 card-custom-padding">

      <div class="container-vertical-padding-half">

        <!--STATE HISTORY-->
        <app-legacy-process-edit-state-history
          [processId]="processId">
        </app-legacy-process-edit-state-history>
        <!--./STATE HISTORY-->

      </div>
      <!-- /.continer-vertical-padding-half -->

    </div>

  </div>

  <div class="container-vertical-padding-half card-custom-padding">

    <!--SUBTASKS-->
    <div class="card">
      <div class="card-header">
        {{'PROCESS_EDIT_SUBTASKS_HEADER' | translate}}
      </div>

      <div class="card-body pt-0">
        <div class="row">
          <div *ngFor="let processTask of processTasks; let last = last;"
               class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
            <div class="process-task-container mt-1 mb-0"
                 [ngClass]="{'process-task-container-triangle': !last}">
              <div class="d-flex justify-content-between">
                <span class="text-truncate process-task-title">
                  {{ processTask.displayName }}
                </span>
                <span class="text-nowrap">
                  <i *ngIf="rightModel.legacyProcessTaskRead.hasRight()" class="icomoon icomoon-detail cursor-pointer"
                     uiSref="Admin.LegacyProcessTaskDetail"
                     [uiParams]="{ processId: processId, id: processTask.processTaskId }">
                  </i>
                  <i *ngIf="rightModel.legacyProcessTaskUpdate.hasRight() && isEditableState(processTask.state)"
                     class="icomoon icomoon-modify cursor-pointer padding-left-half padding-right-half"
                     uiSref="Admin.LegacyProcessTaskEdit" [uiParams]="{ processId: processId, id: processTask.processTaskId }">
                  </i>
                </span>
              </div>
              <span class="process-task-state-container" [ngStyle]="getProcessTaskStateColor(processTask.state)">
                {{ getProcessTaskStateName(processTask.state) }}
              </span>
              <span *ngIf="!isEditableState(processTask.state) || !rightModel.legacyProcessTaskChangeAssignee.hasRight()"
                    class="d-flex mt-2 form-control-label margin-bottom-half">
                <i class="icomoon icomoon-sidebar-customers padding-right-half"></i>
                <span class="text-truncate">
                  {{ getUserName(processTask.assigneeUserId) }}
                </span>
              </span>
              <div class="mt-2"
                   *ngIf="rightModel.legacyProcessTaskChangeAssignee.hasRight() && isEditableState(processTask.state)">
                <angular2-multiselect
                  [(ngModel)]="processTask.selectedAssignee"
                  (onSelect)="updateProcessTaskAssignee(processTask)"
                  (onDeSelect)="updateProcessTaskAssignee(processTask)"
                  [ngModelOptions]="{standalone: true}"
                  [data]="users"
                  [settings]="dropdownSettings">
                </angular2-multiselect>
              </div>
              <!-- /.process-task-state-container -->
            </div>
          </div>
        </div>
      </div> <!--./card-body-->
    </div>
    <!--./SUBTASKS-->

  </div>

  <div class="container-vertical-padding-half card-custom-padding">

    <!--ORDERS-->
    <div class="card">
      <div class="card-header">
        {{'PROCESS_EDIT_ORDERS_HEADER' | translate}}
      </div>

      <div class="card-body">

        <table class="table table-striped table-bordered">
          <thead>
          <tr>
            <th class="table-sorter-header">
              <app-table-sorter-no-op [text]="'PROCESS_EDIT_ORDER_ID' | translate"></app-table-sorter-no-op>
            </th>
            <th class="table-sorter-header">
              <app-table-sorter-no-op [text]="'PROCESS_EDIT_ORDER_COMPANY' | translate"></app-table-sorter-no-op>
            </th>
            <th class="table-sorter-header">
              <app-table-sorter-no-op
                [text]="'ORDER_SEARCH_SHIPMENT_TRACKING_NUMBER' | translate"></app-table-sorter-no-op>
            </th>
            <th class="table-sorter-header">
              <app-table-sorter-no-op [text]="'PROCESS_EDIT_ORDER_SUBMIT_TIME' | translate"></app-table-sorter-no-op>
            </th>
            <th class="table-sorter-header">
              <app-table-sorter-no-op
                [text]="'PROCESS_EDIT_ORDER_CASH_ON_DELIVERY_PRICE' | translate"></app-table-sorter-no-op>
            </th>
            <th class="table-sorter-header">
              <app-table-sorter-no-op [text]="'PROCESS_EDIT_ORDER_RECIPIENT_NAME' | translate"></app-table-sorter-no-op>
            </th>
            <th class="table-sorter-header">
              <app-table-sorter-no-op></app-table-sorter-no-op>
            </th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let order of orderList | paginate: { id: pagingId,
                                                      itemsPerPage: orderQueryModel.itemsPerPage,
                                                      currentPage: orderQueryModel.currentPage,
                                                      totalItems: orderQueryModel.currentNumberOfItems }">
            <td class="responsive-table-column">{{order.orderId}}</td>
            <td class="responsive-table-column">{{getCompanyName(order.ownerCompanyId)}}</td>
            <td
              class="responsive-table-column">{{order.shipmentTrackingNumber ? order.shipmentTrackingNumber : ''}}</td>
            <td class="responsive-table-column">{{order.submitTime.toUtcIsoString() | date:'short'}}</td>
            <!--todo: create seperate order model in component and handle optionals there-->
            <td class="responsive-table-column">
              <span *ngIf="order.cashOnDeliveryPrice">
                {{order.cashOnDeliveryPrice.amount | formattedNumber}} {{order.cashOnDeliveryPrice.currencyCode}}
              </span>
            </td>
            <td class="responsive-table-column">
              <span *ngIf="order.recipient.name">
                {{order.recipient.name}}
              </span>
            </td>
            <td>
              <a class="icons-table-group cursor-pointer"
                 uiSref="Admin.OrderDetailWithProcessEdit"
                 [uiParams]="{ id: order.orderId, processId: processId }">
                <i class="icomoon icons-table-item icomoon-detail icomoon-detail-table"
                   title="{{'COMMON_BUTTON_DETAILS' | translate}}"></i>
              </a>
              <a *ngIf="model && model.state === 'OPEN'"
                 class="icons-table-group cursor-pointer" (click)="deleteOrder(order.orderId)">
                <i class="icomoon icons-table-item icomoon-trash"
                   title="{{'COMMON_BUTTON_DELETE' | translate}}"></i>
              </a>
            </td>
          </tr>
          </tbody>
          <tfoot>
          <tr>
            <th class="table-sorter-header">
              <app-table-sorter-no-op [text]="'PROCESS_EDIT_ORDER_ID' | translate"></app-table-sorter-no-op>
            </th>
            <th class="table-sorter-header">
              <app-table-sorter-no-op [text]="'PROCESS_EDIT_ORDER_COMPANY' | translate"></app-table-sorter-no-op>
            </th>
            <th class="table-sorter-header">
              <app-table-sorter-no-op
                [text]="'ORDER_SEARCH_SHIPMENT_TRACKING_NUMBER' | translate"></app-table-sorter-no-op>
            </th>
            <th class="table-sorter-header">
              <app-table-sorter-no-op [text]="'PROCESS_EDIT_ORDER_SUBMIT_TIME' | translate"></app-table-sorter-no-op>
            </th>
            <th class="table-sorter-header">
              <app-table-sorter-no-op
                [text]="'PROCESS_EDIT_ORDER_CASH_ON_DELIVERY_PRICE' | translate"></app-table-sorter-no-op>
            </th>
            <th class="table-sorter-header">
              <app-table-sorter-no-op [text]="'PROCESS_EDIT_ORDER_RECIPIENT_NAME' | translate"></app-table-sorter-no-op>
            </th>
            <th class="table-sorter-header">
              <app-table-sorter-no-op></app-table-sorter-no-op>
            </th>
          </tr>
          </tfoot>
        </table>
        <app-table-paging
          [id]="pagingId"
          [currentNumberOfItems]="orderQueryModel.currentNumberOfItems"
          [totalNumberOfItems]="orderQueryModel.totalNumberOfItems"
          [itemsPerPage]="orderQueryModel.itemsPerPage"
          (pageChange)="orderPageChanged($event)"
          (itemsPerPageChange)="orderItemsPerPageChanged($event)">
        </app-table-paging>

      </div> <!--./card-body-->
    </div>
    <!--./ORDERS-->

  </div>

</div>

<div bsModal [config]="UiConstants.modalConfig" #closeProcessDialog="bs-modal" class="modal fade" tabindex="-1"
     role="dialog"
     aria-labelledby="myModalLabel"
     aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content" *ngIf="closeProcessDialogVisible">

      <div class="modal-header">
        <h4 class="modal-title">{{'PROCESS_EDIT_CLOSE_PROCESS_DIALOG_TITLE' | translate}}</h4>
        <button type="button" class="close" (click)="hideCloseProcessDialog()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-body">
        <div class="card-body">

          <div class="form-group">
            <h5>{{'PROCESS_EDIT_CLOSE_PROCESS_DIALOG_MESSAGE' | translate}}</h5>
          </div>

        </div><!-- /.card-body -->
      </div><!-- /.modal-body -->

      <div class="modal-footer">
        <button type="button" class="btn btn-secondary"
                (click)="closeProcess(false)">{{'PROCESS_EDIT_CLOSE_PROCESS_DIALOG_FALSE' |
            translate}}
        </button>
        <button type="button" class="btn btn-primary"
                (click)="closeProcess(true)">{{'PROCESS_EDIT_CLOSE_PROCESS_DIALOG_TRUE' |
            translate}}
        </button>
      </div><!-- /.modal-footer -->

    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div><!-- /.modal -->

<app-legacy-process-mpl-export-dialog
  #mplExportDialog
  [processId]="processId">
</app-legacy-process-mpl-export-dialog>

<app-file-upload-dialog
  [uploadPath]="mplStateImportPath"
  (onResult)="onImportSuccess($event)"
  #stateImportDialog>
</app-file-upload-dialog>

<app-order-document-zip-download-dialog
    #orderDocumentZipDownloadDialog
    [processId]="processId">
</app-order-document-zip-download-dialog>
