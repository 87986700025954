/* eslint-disable */
import { Component, EventEmitter } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { TransportPositionLogModel } from '../transport-running-map.component';
/* eslint-enable */

@Component({
  selector: 'app-position-log-info-window',
  templateUrl: './position-log-info-window.component.html',
  styleUrls: ['./position-log-info-window.component.scss']
})
export class PositionLogInfoWindowComponent {

  model: TransportPositionLogModel;

  onTransportClicked = new EventEmitter();

  constructor(private sanitizer: DomSanitizer) {

  }

  transportOpen() {
    this.onTransportClicked.emit(this.model.log.transport.id);
  }

  sanitizeUrl(iconUrl: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(iconUrl);
  }

  isLate() {
    return this.model.log.transport.delayMinutes.asMilliseconds() > 0;
  }
}
