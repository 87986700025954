/* eslint-disable */
import { Component, Input, OnInit } from '@angular/core';
import { OrderType, QueryResult, ResourceQueryResult } from '../../../lib/util/services';
import { LegacyProcess, LegacyProcessService } from '../../../lib/legacy-process/legacy-process.service';
import { QueryFieldModel } from '../../../util/core-utils';
import { TranslateService } from '@ngx-translate/core';
import { LegacyProcessTask, LegacyProcessTaskService } from '../../../lib/legacy-process/legacy-process-task.service';
import { User, UserService } from '../../../lib/user.service';

/* eslint-enable */

@Component({
  selector: 'app-legacy-process-edit-state-history',
  templateUrl: './legacy-process-edit-state-history.component.html',
  styleUrls: ['./legacy-process-edit-state-history.component.scss']
})
export class LegacyProcessEditStateHistoryComponent implements OnInit {

  changeLogList: LegacyProcess.ChangeLog[] = [];
  changeLogQueryModel: QueryFieldModel<LegacyProcess.ChangeLogOrderField>
    = new QueryFieldModel(LegacyProcess.ChangeLogOrderField.CREATION_TIME, OrderType.DESC);
  pagingId = 'processEditStateHistory';
  changeLogTypes: ChangeLogTypeItem[] = [];

  @Input() processId: number;
  processTasks: LegacyProcessTaskWithAssignee[] = [];
  users: UserItem[] = [];

  constructor(private translateService: TranslateService,
              private processService: LegacyProcessService,
              private processTaskService: LegacyProcessTaskService,
              private userService: UserService) {
    this.changeLogQueryModel.itemsPerPage = 5;
  }

  ngOnInit() {
    this.loadProcessTasks();
    this.loadChangeLogTypes();
    this.loadUserNames();
  }

  private loadChangeLogList(pageNumber?: number) {
    const requestedPage = pageNumber ? pageNumber : this.changeLogQueryModel.currentPage;
    this.processService.changeLog({
      id: this.processId,
      paging: requestedPage ? {
        pageNumber: requestedPage,
        numberOfItems: this.changeLogQueryModel.itemsPerPage
      } : undefined
    }).subscribe((changeLogs: QueryResult<LegacyProcess.ChangeLog>) => {
      this.changeLogList = [];
      this.changeLogList = changeLogs.items.toArray();
      this.processTasks.forEach((processTask) => {
        this.changeLogList.forEach((changeLog) => {
          if (processTask.processTaskId === changeLog.processTaskId) {
            changeLog.processTaskName = processTask.displayName;
          }
        });
      });
      this.changeLogQueryModel.currentPage = requestedPage;
      this.changeLogQueryModel.totalNumberOfItems = changeLogs.pagingResult.totalNumberOfItems;
      this.changeLogQueryModel.currentNumberOfItems = changeLogs.pagingResult.currentNumberOfItems;
    });
  }

  private loadChangeLogTypes() {
    this.changeLogTypes = [];
    LegacyProcess.changeLogTypes.forEach((type) => {
      const item = {
        type: type.type,
        text: '...',
      };
      this.translateService.get(type.stringKey).subscribe((text: string) => {
        item.text = text;
      });
      this.changeLogTypes.push(item);
    });
  }

  private loadProcessTasks() {
    this.processTaskService.query({
      processId: this.processId
    }).subscribe(
      (result: QueryResult<LegacyProcessTask.LegacyProcessTask>) => {
        this.processTasks = [];
        this.processTasks = result.items.toArray();
        this.loadChangeLogList();
      });
  }

  private loadUserNames() {
    this.users = [];
    const fields: string[] = ['id', 'person_name'];
    this.userService.query({
      fields: fields.join(',')
    }).subscribe((result: ResourceQueryResult<User>) => {
      result.items.forEach((user) => {
        const item = {
          id: user.id,
          name: user.person_name
        };
        this.users.push(item);
      });
    });
  }

  getChangeLogTypeText(type: string): string {
    const filtered_types = this.changeLogTypes.filter(current_type => current_type.type === type);
    if (filtered_types === undefined || filtered_types.length === 0) {
      return '';
    }
    return filtered_types[0].text;
  }

  changeLogPageChanged(selectedPage: number) {
    this.loadChangeLogList(selectedPage);
  }

  changeLogItemsPerPageChanged(itemsPerPage: number) {
    this.changeLogQueryModel.itemsPerPage = itemsPerPage;
    this.loadChangeLogList(1);
  }

  getUserName(id: number): string {
    const filtered_users = this.users.filter(current_user => current_user.id === id);
    if (filtered_users === undefined || filtered_users.length === 0) {
      return '';
    }
    return filtered_users[0].name;
  }

}

interface ChangeLogTypeItem {
  type: string;
  text: string;
}

interface LegacyProcessTaskWithAssignee extends LegacyProcessTask.LegacyProcessTask {
  selectedAssignee?: UserItem[];
}

interface UserItem {
  id: number;
  name: string;
}
