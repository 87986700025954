import { Process } from '../../../lib/process/process.service';
import { Strings } from '../../../lib/util/strings';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { NgbDatePickerParserFormatter } from '../../../util/ngb-datepicker';
import { TaskRecord } from '../../../lib/task/task-record.service';
import { ConfigurationResource } from '../../../lib/core-ext/configuration.service';
import { OffsetDateTime } from '../../../lib/util/dates';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Map } from 'immutable';
import { WorkflowMultiselectOptionItem, WorkflowMultiselectProvider } from '../../../lib/workflow/workflow-multiselect.provider';

export class ProcessCreateModel {

  workflow: WorkflowMultiselectOptionItem[] = [];
  name: string = '';
  externalId: string = '';
  description: string = '';
  deadlineDate: NgbDateStruct | null = null;
  taskRecordCreateRequest?: TaskRecord.QuickCreateRequest;
  taskRecordNameTemplate: string = '';

  private readonly _id?: number;
  private readonly _processStateKey: string = '';

  workflowList: WorkflowMultiselectOptionItem[] = [];

  constructor(private datePickerParserFormatter: NgbDatePickerParserFormatter,
              private config: ConfigurationResource.Configuration,
              private _process?: Process.Process) {
    if (_process) {
      const state = Process.processStates.find(im => im.state === _process.state);
      const deadline = this.datePickerParserFormatter.fromOffsetDateTime(_process.deadline);
      this.workflow = [WorkflowMultiselectProvider.toOptionItem(_process.workflow!)];
      this._processStateKey = state ? state.stringKey : '';
      this._id = _process.id;
      this.externalId = _process.externalId;
      this.name = _process.name;
      this.description = _process.description ? _process.description : '';
      this.deadlineDate = deadline;
      this.taskRecordNameTemplate = _process.taskRecordNamePattern ? _process.taskRecordNamePattern : '';
    }
    else {
      this.taskRecordCreateRequest = undefined;
    }
  }

  toCreateRequest(): Process.CreateRequest {
    return {
      workflowId: this.workflow[0]!.id,
      name: Strings.undefinedOrNonEmpty(this.name),
      externalId: Strings.undefinedOrNonEmpty(this.externalId),
      description: Strings.undefinedOrNonEmpty(this.description),
      deadline: this.deadline,
      taskRecordNamePattern: this.taskRecordNameTemplate,
      taskRecordQuickCreateRequest: this.taskRecordCreateRequest!
    };
  }

  toUpdateRequest(): Process.UpdateRequest {
    return {
      id: this._process!.id,
      name: this.name,
      externalId: this.externalId,
      description: Strings.undefinedOrNonEmpty(this.description),
      taskRecordNamePattern: this.taskRecordNameTemplate,
      deadline: this.deadline
    };
  }

  get id(): number | undefined {
    return this._id;
  }

  get process(): Process.Process | undefined {
    return this._process;
  }

  get deadline(): OffsetDateTime {
    return this.datePickerParserFormatter.toOffsetDateTime(this.deadlineDate);
  }

  get processStateKey(): string {
    return this._processStateKey;
  }

  getLastModifierUserName(): string {
    if (!this.process) {
      return '';
    }
    return this.process.lastModifiedUser!.personName;
  }

  getCreatorUserName(): string {
    if (!this.process) {
      return '';
    }
    return this.process.creatorUser ? this.process.creatorUser.personName : '';
  }

  get selectedWorkflow() {
    return this.workflow.length === 0 ? undefined : this.workflow[0];
  }
}

export namespace ProcessTaskRecordConfigNameTemplate {

  export const YEAR: string = '{{year}}';
  export const YEAR_SHORT: string = '{{year:short}}';
  export const COUNTER: string = '{{counter}}';
  export const TASK_NAME: string = '{{TaskName}}';
  export const POC_ADDRESS: string = '{{PocAddress}}';
  export const PROCESS_NAME: string = '{{ProcessName}}';
  export const PROCESS_EXTERNAL_ID: string = '{{ProcessExternalId}}';
  export const WORKFLOW_NAME: string = '{{WorkflowName}}';
  export const WORKFLOW_EXTERNAL_ID: string = '{{WorkflowExternalId}}';
  export const ASSIGNEE_USER_GROUP_NAME: string = '{{AssigneeUserGroupName}}';
  export const ASSIGNEE_USER_NAME: string = '{{AssigneeUserName}}';
  export const ASSIGNEE_USER_PERSON_NAME: string = '{{AssigneeUserPersonName}}';
  export const CREATOR_USER_NAME: string = '{{CreatorUserName}}';
  export const CREATOR_USER_PERSON_NAME: string = '{{CreatorUserPersonName}}';
  export const ACTUAL_DATE: string = '{{ActualDate}}';
  export const CUSTOMER_RECORD_NAME: string = '{{CustomerRecordName}}';
  export const CUSTOMER_NAME: string = '{{CustomerName}}';
  export const CUSTOMER_RECORD_EXTERNAL_ID: string = '{{CustomerRecordExternalId}}';
  export const CUSTOMER_RECORD_PHONE_NUMBERS: string = '{{CustomerRecordPhoneNumbers}}';
  export const CUSTOMER_EXTERNAL_ID: string = '{{CustomerExternalId}}';
  export const PROJECT_RECORD_NAME: string = '{{ProjectRecordName}}';
  export const PROJECT_RECORD_EXTERNAL_ID: string = '{{ProjectRecordExternalId}}';

  export const sequenceBlockSeparator = '/';
  export const separatorKeysCodes: number[] = [ENTER, COMMA];

  export const availableSequenceBlocks: string[] = [
    PROCESS_NAME,
    PROCESS_EXTERNAL_ID,
    WORKFLOW_NAME,
    WORKFLOW_EXTERNAL_ID,
    COUNTER,
    YEAR,
    YEAR_SHORT,
    TASK_NAME,
    POC_ADDRESS,
    ASSIGNEE_USER_GROUP_NAME,
    ASSIGNEE_USER_NAME,
    ASSIGNEE_USER_PERSON_NAME,
    CREATOR_USER_NAME,
    CREATOR_USER_PERSON_NAME,
    ACTUAL_DATE,
    CUSTOMER_RECORD_NAME,
    CUSTOMER_NAME,
    CUSTOMER_RECORD_EXTERNAL_ID,
    CUSTOMER_RECORD_PHONE_NUMBERS,
    CUSTOMER_EXTERNAL_ID,
    PROJECT_RECORD_NAME,
    PROJECT_RECORD_EXTERNAL_ID,
  ];

  export const sequenceBlockExampleMap: Map<string, string> = Map.of(
    PROCESS_NAME, 'ProcessName',
    PROCESS_EXTERNAL_ID, 'ProcessExternalID',
    WORKFLOW_NAME, 'WorkflowName',
    WORKFLOW_EXTERNAL_ID, 'WorkflowExternalID',
    COUNTER, '001',
    YEAR, new Date().getFullYear().toString(),
    YEAR_SHORT, (new Date().getFullYear() % 100).toString(),
    TASK_NAME, 'TaskName',
    POC_ADDRESS, 'POCAddress',
    ASSIGNEE_USER_GROUP_NAME, 'AssigneeUserGroupName',
    ASSIGNEE_USER_NAME, 'AssigneeUserName',
    ASSIGNEE_USER_PERSON_NAME, 'AssigneeUserPersonName',
    CREATOR_USER_NAME, 'CreatorUserName',
    CREATOR_USER_PERSON_NAME, 'CreatorUserPersonName',
    ACTUAL_DATE, 'ActualDate',
    CUSTOMER_RECORD_NAME, 'CustomerRecordName',
    CUSTOMER_NAME, 'CustomerName',
    CUSTOMER_RECORD_EXTERNAL_ID, 'CR123',
    CUSTOMER_RECORD_PHONE_NUMBERS, '+36201234567',
    CUSTOMER_EXTERNAL_ID, 'C123',
    PROJECT_RECORD_NAME, 'ProjectName',
    PROJECT_RECORD_EXTERNAL_ID, 'ProjectRecordExternalID',
  );

}
