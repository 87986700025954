<div class="breadcrumb-container" [ngClass]="{ 'breadcrumb-container-compact': compactSidebar }" id="breadcrumb">
  <div class="breadcrumb-component">
    <app-breadcrumb
      [self]="breadcrumbSelf"
      [parents]="breadcrumbParents">
    </app-breadcrumb>
  </div>
</div>
<div class="breadcrumb-placeholder"></div>
<div class="container-horizontal-padding container-vertical-padding animated fadeIn">

  <div class="card">

    <div class="card-header">
      {{'MENU_NAVBAR_MILEAGE_RECORDS' | translate}}

      <div class="card-actions">
        <a class="btn-setting table-header-action-button d-flex align-items-center px-1 w-auto font-weight-normal" title="{{'COMMON_BUTTON_EXPORT' | translate}}"
           (click)="exportXls()">
          <i class="icomoon pr-1 icomoon-csv-export"></i>
          <span class="breadcrumb-button-text-visible">{{'COMMON_BUTTON_EXPORT' | translate}}</span>
        </a>
        <a class="btn-setting table-header-action-button d-flex align-items-center px-1 w-auto font-weight-normal" title="{{'COMMON_BUTTON_EXPORT' | translate}}"
           (click)="exportMaconomy()" *ngIf="maconomyEnabled">
          <i class="icomoon pr-1 icomoon-csv-export"></i>
          <span class="breadcrumb-button-text-visible">{{'COMMON_BUTTON_MACONOMY_EXPORT' | translate}}</span>
        </a>
        <a class="btn-setting cursor-pointer" (click)="toggleSearch()" title="{{'COMMON_BUTTON_SEARCH' | translate}}"><i
          class="icomoon icomoon-search"></i></a>
      </div><!-- /.card-actions -->
    </div>

    <div class="card-body border-bottom" *ngIf="showSearch">

      <div class="row form-group">

        <div class="col-md-3">
          <label class="search-label">{{'COMMON_TASK' | translate}}</label>
          <angular2-multiselect class="form-control"
                                [data]="taskRecords"
                                [(ngModel)]="searchModel.taskRecord"
                                (onRemoteSearch)="onTaskRecordSearch($event.target.value)"
                                [settings]="dropdownSettings">
          </angular2-multiselect>
        </div>

        <div class="col-md-3">
          <label class="search-label">{{'WORKLOG_TASK_RECORD_SEARCH_STATE' | translate}}</label>
          <angular2-multiselect class="form-control"
                                [data]="taskRecordStates"
                                [(ngModel)]="searchModel.taskRecordState"
                                [settings]="localDropdownSettings">
          </angular2-multiselect>
        </div>


        <div class="col-md-3">
          <label class="search-label">{{'TASK_LABEL_OWNER_USER' | translate}}</label>
          <angular2-multiselect class="form-control"
                                [data]="users"
                                [(ngModel)]="searchModel.user"
                                (onRemoteSearch)="onUserSearch($event.target.value)"
                                [settings]="dropdownSettings">
          </angular2-multiselect>
        </div>

        <div class="col-md-3">
          <label class="search-label">{{'TRANSPORT_VEHICLE' | translate}}</label>
          <angular2-multiselect class="form-control"
                                [data]="vehicles"
                                [(ngModel)]="searchModel.vehicle"
                                (onRemoteSearch)="onVehicleSearch($event.target.value)"
                                [settings]="dropdownSettings">
          </angular2-multiselect>
        </div>

        <div class="col-md-3">
          <label class="search-label">{{'MILEAGE_RECORD_JOURNEY_TYPE' | translate}}</label>
          <input type="text" class="form-control"
                 placeholder="{{'MILEAGE_RECORD_JOURNEY_TYPE' | translate}}"
                 maxlength="{{UiConstants.maxInputLongLength}}"
                 (keyup.enter)="onSearchClicked()"
                 [(ngModel)]="searchModel.journeyType">
        </div>
      </div>

      <div class="row form-group">

        <div class="col-md-3">
          <label class="search-label">{{'MILEAGE_RECORD_CREATION_TIME_FROM' | translate}}</label>
          <div class="input-group">
            <input type="text" class="form-control date"
                   placeholder="{{'MILEAGE_RECORD_CREATION_TIME_FROM' | translate}}"
                   [(ngModel)]="searchModel.creationTimeFrom" ngbDatepicker #creation_time_from="ngbDatepicker">
            <div class="input-group-addon-gray" (click)="creation_time_from.toggle()">
              <i class="icomoon icomoon-calendar"></i>
            </div>
          </div>
        </div>

        <div class="col-md-3">
          <label class="search-label">{{'MILEAGE_RECORD_CREATION_TIME_TO' | translate}}</label>
          <div class="input-group">
            <input type="text" class="form-control date"
                   placeholder="{{'MILEAGE_RECORD_CREATION_TIME_TO' | translate}}"
                   [(ngModel)]="searchModel.creationTimeTo" ngbDatepicker #creation_time_to="ngbDatepicker">
            <div class="input-group-addon-gray" (click)="creation_time_to.toggle()">
              <i class="icomoon icomoon-calendar"></i>
            </div>
          </div>
        </div>

        <div class="col-md-3">
          <label class="search-label">{{'MILEAGE_RECORD_FINISH_TIME_FROM' | translate}}</label>
          <div class="input-group">
            <input type="text" class="form-control date"
                   placeholder="{{'MILEAGE_RECORD_FINISH_TIME_FROM' | translate}}"
                   [(ngModel)]="searchModel.finishTimeFrom" ngbDatepicker #finish_time_from="ngbDatepicker">
            <div class="input-group-addon-gray" (click)="finish_time_from.toggle()">
              <i class="icomoon icomoon-calendar"></i>
            </div>
          </div>
        </div>

        <div class="col-md-3">
          <label class="search-label">{{'MILEAGE_RECORD_FINISH_TIME_TO' | translate}}</label>
          <div class="input-group">
            <input type="text" class="form-control date"
                   placeholder="{{'MILEAGE_RECORD_FINISH_TIME_TO' | translate}}"
                   [(ngModel)]="searchModel.finishTimeTo" ngbDatepicker #finish_time_to="ngbDatepicker">
            <div class="input-group-addon-gray" (click)="finish_time_to.toggle()">
              <i class="icomoon icomoon-calendar"></i>
            </div>
          </div>
        </div>

      </div>
      <div class="col-md-12 d-flex justify-content-md-end align-items-end px-0">
        <div class="btn-group" role="group">
          <button type="button" (click)="onSearchReset()" class="btn btn-outline-primary search-button mr-1">
            {{'COMMON_BUTTON_SEARCH_DELETE' | translate}}
          </button>
          <button type="button" (click)="onSearchClicked()" class="btn btn-primary search-button">
            {{'COMMON_BUTTON_SEARCH' | translate}}
          </button>
        </div>
      </div>
    </div><!-- /.card-body-->
    <div class="card-body">
      <table class="table table-striped table-bordered">
        <thead>
        <tr>
          <th class="table-sorter-header">
            <app-table-field-sorter [orderField]="MileageRecordSearch.OrderFunctions.ID"
                                    [orderType]="queryModel.getOrderType(MileageRecordSearch.OrderFunctions.ID)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_TABLE_HEADER_ID' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-sm-down">
            <app-table-field-sorter [orderField]="MileageRecordSearch.OrderFunctions.TASK_RECORD_NAME"
                                    [orderType]="queryModel.getOrderType(MileageRecordSearch.OrderFunctions.TASK_RECORD_NAME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_TASK' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-md-down">
            <app-table-field-sorter [orderField]="MileageRecordSearch.OrderFunctions.USER_NAME"
                                    [orderType]="queryModel.getOrderType(MileageRecordSearch.OrderFunctions.USER_NAME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'TASK_LABEL_OWNER_USER' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-md-down">
            <app-table-field-sorter [orderField]="MileageRecordSearch.OrderFunctions.VEHICLE_LICENCE_PLATE"
                                    [orderType]="queryModel.getOrderType(MileageRecordSearch.OrderFunctions.VEHICLE_LICENCE_PLATE)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'TRANSPORT_VEHICLE' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header">
            <app-table-sorter-no-op
              [text]="'MILEAGE_RECORD_KILOMETER_COUNT' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header hidden-lg-down">
            <app-table-field-sorter [orderField]="MileageRecordSearch.OrderFunctions.CREATION_TIME"
                                    [orderType]="queryModel.getOrderType(MileageRecordSearch.OrderFunctions.CREATION_TIME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'MILEAGE_RECORD_CREATION_TIME' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-lg-down">
            <app-table-field-sorter [orderField]="MileageRecordSearch.OrderFunctions.FINISH_TIME"
                                    [orderType]="queryModel.getOrderType(MileageRecordSearch.OrderFunctions.FINISH_TIME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'MILEAGE_RECORD_FINISH_TIME' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-lg-down">
            <app-table-sorter-no-op [text]="'COMMON_COMMENT' | translate"></app-table-sorter-no-op>
            <app-table-sorter-no-op class="table-text-subtitle" [text]="'MILEAGE_RECORD_JOURNEY_TYPE' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header w-table-options-menu">
            <app-table-sorter-no-op></app-table-sorter-no-op>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let mileageRecord of mileageRecordList | paginate: {
                                                      itemsPerPage: queryModel.itemsPerPage,
                                                      currentPage: queryModel.currentPage,
                                                      totalItems: queryModel.currentNumberOfItems }">
          <td class="fit">{{mileageRecord.id}}</td>
          <td class="responsive-table-column hidden-sm-down">
            <a uiSref="Admin.TaskRecordDetail"
               [uiParams]="{taskRecordId: mileageRecord.taskRecordId, taskId: mileageRecord.taskId}">
                {{mileageRecord.taskName}}
            </a>
            <p class="table-text-subtitle mb-0">{{mileageRecord.customerName}}</p>
          </td>
          <td class="responsive-table-column hidden-md-down">
            <app-assignee-table-cell
              [assigneeType]="AssigneeType.USER"
              [user]="mileageRecord.user"
              [rightModel]="rightModel"
              [userGroups]="relatedUserGroups">
            </app-assignee-table-cell>
          </td>
          <td class="responsive-table-column hidden-md-down">{{mileageRecord.vehicleLicencePlate}}
          </td>
          <td class="responsive-table-column">{{mileageRecord.kilometerCountText}}
            <p class="table-text-subtitle mb-0">{{mileageRecord.kilometerIntervalText}}</p>
          </td>
          <td class="responsive-table-column hidden-lg-down">{{mileageRecord.creationTime.toUtcIsoString() | date: 'short'}}</td>
          <td class="responsive-table-column hidden-lg-down">{{mileageRecord.finishTime.toUtcIsoString() | date: 'short'}}</td>
          <td class="responsive-table-column hidden-lg-down">{{mileageRecord.note}}
            <p class="table-text-subtitle mb-0">{{mileageRecord.journeyType}}</p>
          </td>
          <td class="align-middle">
            <app-table-options-menu>
              <app-dropdown-item
                [iconClass]="'icomoon-detail'"
                [titleStringKey]="'COMMON_BUTTON_DETAILS'"
                class="cursor-pointer"
                uiSref="Admin.MileageRecordDetail"
                [uiParams]="{ mileageRecordId: mileageRecord.id }">
              </app-dropdown-item>
              <app-dropdown-item
                *ngIf="mileageRecord.finishTime.isValid() && rightModel.mileageRecordUpdate.hasRight()"
                [iconClass]="'icomoon-modify'"
                [titleStringKey]="'COMMON_BUTTON_EDIT'"
                class="cursor-pointer"
                (click)="mileageRecordUpdateDialog.showDialog(mileageRecord)">
              </app-dropdown-item>
            </app-table-options-menu>
          </td>
        </tr>
        </tbody>
        <tfoot>
        <tr>
          <th class="table-sorter-header">
            <app-table-field-sorter [orderField]="MileageRecordSearch.OrderFunctions.ID"
                                    [orderType]="queryModel.getOrderType(MileageRecordSearch.OrderFunctions.ID)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_TABLE_HEADER_ID' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-sm-down">
            <app-table-field-sorter [orderField]="MileageRecordSearch.OrderFunctions.TASK_RECORD_NAME"
                                    [orderType]="queryModel.getOrderType(MileageRecordSearch.OrderFunctions.TASK_RECORD_NAME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'COMMON_TASK' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-md-down">
            <app-table-field-sorter [orderField]="MileageRecordSearch.OrderFunctions.USER_NAME"
                                    [orderType]="queryModel.getOrderType(MileageRecordSearch.OrderFunctions.USER_NAME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'TASK_LABEL_OWNER_USER' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-md-down">
            <app-table-field-sorter [orderField]="MileageRecordSearch.OrderFunctions.VEHICLE_LICENCE_PLATE"
                                    [orderType]="queryModel.getOrderType(MileageRecordSearch.OrderFunctions.VEHICLE_LICENCE_PLATE)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'TRANSPORT_VEHICLE' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header">
            <app-table-sorter-no-op
              [text]="'MILEAGE_RECORD_KILOMETER_COUNT' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header hidden-lg-down">
            <app-table-field-sorter [orderField]="MileageRecordSearch.OrderFunctions.CREATION_TIME"
                                    [orderType]="queryModel.getOrderType(MileageRecordSearch.OrderFunctions.CREATION_TIME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'MILEAGE_RECORD_CREATION_TIME' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-lg-down">
            <app-table-field-sorter [orderField]="MileageRecordSearch.OrderFunctions.FINISH_TIME"
                                    [orderType]="queryModel.getOrderType(MileageRecordSearch.OrderFunctions.FINISH_TIME)"
                                    (orderChange)="orderBy($event)"
                                    [text]="'MILEAGE_RECORD_FINISH_TIME' | translate"></app-table-field-sorter>
          </th>
          <th class="table-sorter-header hidden-lg-down">
            <app-table-sorter-no-op [text]="'COMMON_COMMENT' | translate"></app-table-sorter-no-op>
            <app-table-sorter-no-op class="table-text-subtitle" [text]="'MILEAGE_RECORD_JOURNEY_TYPE' | translate"></app-table-sorter-no-op>
          </th>
          <th class="table-sorter-header w-table-options-menu">
            <app-table-sorter-no-op></app-table-sorter-no-op>
          </th>
        </tr>
        </tfoot>
      </table>
      <app-table-paging
        [currentNumberOfItems]="queryModel.currentNumberOfItems"
        [totalNumberOfItems]="queryModel.totalNumberOfItems"
        [itemsPerPage]="queryModel.itemsPerPage"
        (pageChange)="pageChanged($event)"
        (itemsPerPageChange)="itemsPerPageChanged($event)">
      </app-table-paging>
    </div>
  </div>
</div>

<app-mileage-record-edit-dialog
  #mileageRecordUpdateDialog
  (onMileageRecordUpdated)="loadList()">
</app-mileage-record-edit-dialog>

