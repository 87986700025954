/* eslint-disable */
import { AfterViewInit, Component, Input, OnInit, } from '@angular/core';
import { TaskRecord, TaskRecordService } from '../../../../lib/task/task-record.service';
import { NamedBlobFile, } from '../../../../lib/util/downloaded-files';
import { Observable } from 'rxjs';
import { FileAttachment, FileAttachments, FileAttachmentUpdateRequest } from '../../../../lib/util/file-attachments';
import { RightResolver, RightService } from '../../../../lib/right.service';
import { RightModel } from '../../../../app.rights';
import { ThumbnailLoaderFactory } from '../../../../shared/material-attachment-gallery/material-attachment-gallery.component';
import { BaseHttpService } from '../../../../lib/util/http-services';
import { EmptyMessage } from '../../../../lib/util/messages';

/* eslint-enable */

@Component({
  selector: 'app-task-record-attachment',
  templateUrl: './task-record-attachment.component.html',
  styleUrls: ['./task-record-attachment.component.scss'],
})
export class TaskRecordAttachmentComponent implements OnInit, AfterViewInit, ThumbnailLoaderFactory {

  @Input()
  taskRecordId: number;

  @Input()
  readonly: boolean;

  @Input()
  taskId: number;

  fileLoaderFactory = this;

  rightModel: RightModel = RightModel.empty();

  constructor(private taskRecordService: TaskRecordService,
              private rightService: RightService) {
  }

  getUploadPath(): string {
    return '/tasks/' + this.taskId + '/records/' + this.taskRecordId + '/attachments?' + BaseHttpService.LOCATION_ON;
  }

  ngOnInit() {
    this.loadRightModels(() => {
      if (this.rightModel.taskRecordAttachmentReadList.hasRight()) {
        this.loadAttachments();
      }
    });
  }

  loadAttachments() {
    return this.taskRecordService.getAttachments({
      taskRecordId: this.taskRecordId,
      taskId: this.taskId,
    });
  }

  deleteAttachment(fileId: number) {
    return this.taskRecordService.deleteAttachment({
      taskId: this.taskId,
      taskRecordId: this.taskRecordId,
      fileId: fileId
    });
  }

  private loadRightModels(completion: () => void) {
    this.rightService.getRightResolver().subscribe(
      (resolver: RightResolver) => {
        this.rightModel = RightModel.of(resolver);
        completion();
      }
    );
  }

  public createFileLoader(attachment: FileAttachment): Observable<NamedBlobFile> {
    return this.taskRecordService.downloadAttachment({
      taskId: this.taskId,
      taskRecordId: this.taskRecordId,
      attachment: attachment,
    });
  }

  hasThumbnails(): boolean {
    return true;
  }

  createThumbnailLoader(attachment: FileAttachment): Observable<NamedBlobFile> {
    return this.taskRecordService.downloadAttachmentThumbnail({
      taskId: this.taskId,
      taskRecordId: this.taskRecordId,
      attachment: attachment,
    });
  }

  updateAttachment(r: FileAttachmentUpdateRequest): Observable<EmptyMessage> {
    const request: TaskRecord.UpdateAttachmentRequest = {
      name: r.name,
      fileId: r.fileId,
      taskId: this.taskId,
      taskRecordId: this.taskRecordId
    };
    return this.taskRecordService.updateAttachment(request);
  }

  ngAfterViewInit(): void {
  }

}
