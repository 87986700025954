/* eslint-disable */
import { Observable, Observer } from 'rxjs';
import { Address, AddressResource } from '../../address';
import { LocalDate, OffsetDateTime } from '../../util/dates';
import { Decimal } from 'decimal.js';
import { Injectable } from '@angular/core';
import { InvoiceResource, InvoiceResourceService } from './invoice-resource.service';
import { DownloadedFile } from '../../util/downloaded-files';
import { FieldValidationError, Order, PagingRequest, QueryResult, ResourceQueryResult, Services } from '../../util/services';
import { List, Map, Set } from 'immutable';
import { TaskRecord } from '../../task/task-record.service';
import { EmptyMessage } from '../../util/messages';
import { ObservableErrorResourceParser } from '../../util/errors';
import { FormRecord } from '../../form/form-record.service';
import { FormRecordResource } from '../../form/form-record-resource.service';
import { InvoiceSettings } from '../invoice-settings/invoice-settings.service';
import { LedgerNumber } from '../../ledger/number/ledger-number.service';
import { InvoiceTag } from '../tag/invoice-tag.service';
import InvoiceItem = FormRecord.InvoiceItem;
import {findAllSubstringIndices} from "@angular/cdk/schematics";

/* eslint-enable */

@Injectable()
export class InvoiceService implements Invoice.Service {
  private mapper: Invoice.InvoiceMapper;

  query(request: Invoice.QueryRequest): Observable<QueryResult<Invoice.Invoice>> {
    return Observable.create((observer: Observer<QueryResult<Invoice.Invoice>>) => {
      return this.resourceService.query(this.mapper.toResourceQueryRequest(request)).subscribe(
        (result: ResourceQueryResult<InvoiceResource.Invoice>) => {
          observer.next({
            items: this.mapper.toPublicList(result.items),
            pagingResult: result.pagingResult
          });
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  create(request: Invoice.InvoiceCreateRequest): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      return this.resourceService.create({
        invoice_number: request.invoiceNumber,
        invoice_settings_id: request.invoiceSettingsId,
        invoice_book_id: request.invoiceBookId,
        appearance_type: request.appearanceType.toString(),
        comment_template: request.commentTemplate,
        currency_code: request.currencyCode,
        payment_type: <string>request.paymentType,
        direction_type: <string>request.directionType,
        issue_date: Services.localDateToString(request.issueDate)!,
        delivery_date: Services.localDateToString(request.deliveryDate)!,
        deadline: Services.localDateToString(request.deadline)!,
        customer_record_id: request.customerRecordId,
        billing_info_id: request.billingInfoId,
        invoice_tag_id: request.invoiceTagId,
        records: request.records.map((record: Invoice.InvoiceRecordCreateRequest): InvoiceResource.InvoiceRecordCreateRequest => {
          return {
            record_name: record.recordName,
            hun_vtsz_number: record.hunVtszNumber,
            amount: Services.decimalToString(record.amount)!,
            unit_type: record.unitType,
            net_unit_price: Services.decimalToString(record.netUnitPrice)!,
            vat_rate: Services.decimalToString(record.vatRate)!,
            zero_vat_rate_type: record.zeroVatRateType,
            comment: record.comment,
            currency_code: record.currencyCode,
            stock_item_id: record.stockItemId,
            ledger_number: record.ledgerNumberId
          };
        })

      }).subscribe((result: InvoiceResource.Invoice) => {
          observer.next({});
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  get(request: Invoice.GetRequest): Observable<Invoice.Invoice> {
    return Observable.create((observer: Observer<Invoice.Invoice>) => {
      const resourceRequest: InvoiceResource.GetRequest = {
        id: request.id
      };
      return this.resourceService.get(resourceRequest).subscribe(
        (result: InvoiceResource.Invoice) => {
          observer.next(this.mapper.toPublic(result));
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  storno(request: Invoice.InvoiceStornoRequest): Observable<Invoice.InvoiceStornoResponse> {
    return Observable.create((observer: Observer<Invoice.InvoiceStornoResponse>) => {
      return this.resourceService.storno({
        invoice_id: request.invoiceId,
        invoice_settings_id: request.invoiceSettingsId,
        invoice_book_id: request.invoiceBookId,
        invoice_number: request.invoiceNumber,
        task_revert: request.taskRevert,
        comment_template: request.commentTemplate
      }).subscribe((result: InvoiceResource.InvoiceStornoResponse) => {
          observer.next({
            invoice: result.invoice ? this.mapper.toPublic(result.invoice) : undefined,
            revertResult: result.revert_result ? TaskRecord.RevertResultMapper.map(result.revert_result.result) : undefined
          });
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  retryPostProcess(request: Invoice.PostProcessRetryRequest): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      return this.resourceService.retryPostProcess({
        invoice_id: request.invoiceId,
      }).subscribe((result: EmptyMessage) => {
          observer.next({});
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  settle(request: Invoice.SettlementRequest): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      return this.resourceService.settle({
        invoice_id: request.invoiceId,
        date: Services.localDateToString(request.date)!,
        comment: request.comment
      }).subscribe((result: EmptyMessage) => {
          observer.next({});
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  setTag(request: Invoice.InvoiceTagRequest): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      return this.resourceService.setTag({
        invoice_ids: request.invoiceIds.toArray(),
        invoice_tag_id: request.invoiceTagId
      }).subscribe((result: EmptyMessage) => {
          observer.next({});
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  clearTag(request: Invoice.InvoiceTagRequest): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      return this.resourceService.clearTag({
        invoice_ids: request.invoiceIds.toArray(),
        invoice_tag_id: request.invoiceTagId
      }).subscribe((result: EmptyMessage) => {
          observer.next({});
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  downloadPdf(request: Invoice.DownloadPdfRequest): Observable<DownloadedFile> {
    return this.resourceService.downloadPdf({
      invoice_id: request.invoiceId
    });
  }

  batchDownloadPdf(request: Invoice.BatchDownloadPdfRequest): Observable<DownloadedFile> {
    return this.resourceService.batchDownloadPdf({
      invoice_book_id: request.invoiceBookId,
      invoice_seq_from: request.invoiceSeqFrom,
      invoice_seq_to: request.invoiceSeqTo,
      issue_date_from: Services.localDateToString(request.issueDateFrom),
      issue_date_to: Services.localDateToString(request.issueDateTo)
    });
  }

  generatePdf(request: Invoice.DownloadPdfRequest): Observable<DownloadedFile> {
    return this.resourceService.generatePdf({
      invoice_id: request.invoiceId
    });
  }

  exportSupInvoice(ids: Set<number>): Observable<DownloadedFile> {
    return this.resourceService.exportSupInvoice(Services.createListParameter(ids)!);
  }

  getFormRecordInvoiceItems(request: Invoice.FormRecordInvoiceItemQueryRequest): Observable<QueryResult<InvoiceItem>> {
    return Observable.create((observer: Observer<QueryResult<InvoiceItem>>) => {
      const resourceRequest: InvoiceResource.FormRecordInvoiceItemQueryRequest = {
        record_name: request.recordName,
        external_id: request.externalId,
        excluded_ids: Services.createIdParameter(request.excludedIds),
        order: Services.createOrderFieldParameter(Keys.toformRecordInvoiceItemOrderFieldKey, request.order),
        page_number: request.paging ? request.paging.pageNumber : undefined,
        number_of_items: request.paging ? request.paging.numberOfItems : undefined,
        no_progress_bar: true
      };
      return this.resourceService.getFormRecordInvoiceItems(resourceRequest).subscribe(
        (result: ResourceQueryResult<FormRecordResource.InvoiceItem>) => {
          observer.next({
            items: List.of(...result.items.map((r) => FormRecord.ResourceMapper.toPublicInvoiceItem(r))),
            pagingResult: result.pagingResult
          });
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  getFormRecordInvoiceItemCategories(request: Invoice.FormRecordInvoiceItemCategoryQueryRequest): Observable<QueryResult<string>> {
    return Observable.create((observer: Observer<QueryResult<string>>) => {
      const resourceRequest: InvoiceResource.FormRecordInvoiceItemCategoryQueryRequest = {
        category: request.category,
        page_number: request.paging ? request.paging.pageNumber : undefined,
        number_of_items: request.paging ? request.paging.numberOfItems : undefined,
        no_progress_bar: true
      };
      return this.resourceService.getFormRecordInvoiceItemCategories(resourceRequest).subscribe(
        (result: ResourceQueryResult<string>) => {
          observer.next({
            items: List.of(...result.items),
            pagingResult: result.pagingResult
          });
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  exportXlsx(request: Invoice.QueryRequest): Observable<DownloadedFile> {
    return this.resourceService.exportXlsx(this.mapper.toResourceQueryRequest(request));
  }

  private translateError(error: any): any {
    const res = ObservableErrorResourceParser.parseError(error);
    const fieldErrors = ObservableErrorResourceParser.extractFieldErrors(res);
    const fieldErrorMap = ObservableErrorResourceParser.toFieldErrorMap(Keys.toValidatedField, fieldErrors);
    if (!fieldErrorMap.isEmpty()) {
      return FieldValidationError.of(fieldErrorMap);
    }
    return error;
  }

  constructor(private resourceService: InvoiceResourceService) {
    this.mapper = new Invoice.InvoiceMapper();
  }
}

export namespace Invoice {


  export interface Service {

    query(request: QueryRequest): Observable<QueryResult<Invoice>>;

    create(request: InvoiceCreateRequest): Observable<EmptyMessage>; // TODO return Observable<Invoice>

    storno(request: InvoiceStornoRequest): Observable<Invoice.InvoiceStornoResponse>;

    get(request: GetRequest): Observable<Invoice>;

  }

  export interface QueryRequest {
    invoiceIdSet?: Set<number>;
    invoiceBookIds?: Set<number>;
    invoiceSettingsIds?: Set<number>;
    invoiceTagIds?: Set<number>;
    invoiceNumber?: string;
    categoryType?: Set<Invoice.InvoiceCategoryType>;
    appearanceType?: string;
    paymentType?: Set<Invoice.InvoicePaymentType>;
    directionType?: string;
    taskRecordAssigneeUserIds?: Set<number>;
    creatorUserId?: Set<number>;
    recordStockItemId?: Set<number>;
    customerName?: string;
    issueDateFrom?: LocalDate;
    issueDateTo?: LocalDate;
    deliveryDateFrom?: LocalDate;
    deliveryDateTo?: LocalDate;
    settlementDateFrom?: LocalDate;
    settlementDateTo?: LocalDate;
    settlementSettled?: boolean;
    deadlineDateFrom?: LocalDate;
    deadlineDateTo?: LocalDate;
    navExportState?: Set<string>;
    q?: string,
    order?: Set<Order<OrderField>>;
    paging?: PagingRequest;
    withRecords?: boolean;
    withFooter?: boolean;
  }

  export interface GetRequest {
    id: number;
  }

  export interface Invoice {
    id: number;
    invoiceSettings: {
      id: number;
      profileName: string;
      invoiceIntegrationType: InvoiceSettings.InvoiceIntegrationType;
    };
    invoiceBookId?: number;
    invoiceNumber?: string;
    categoryType: InvoiceCategoryType;
    appearanceType: InvoiceAppearanceType;
    paymentType: InvoicePaymentType;
    directionType: InvoiceDirectionType;
    referencedInvoiceNumber?: string;
    comment?: string;
    technicalComment?: string;
    currencyCode: string;
    sellerName: string;
    sellerTaxNumber: string;
    sellerEuVatNumber?: string;
    sellerBankAccount?: string;
    sellerPostalAddress?: Address.PostalAddressData;
    customerName: string;
    customerTaxNumber: string;
    customerEuVatNumber?: string;
    customerBankAccount?: string;
    customerPostalAddress?: Address.PostalAddressData;
    customerRecordId?: number;
    billingInfoId?: number;
    issueDate: LocalDate;
    deliveryDate: LocalDate;
    deadline: LocalDate;
    creationTime: OffsetDateTime;
    updateTime: OffsetDateTime;
    creatorUser?: {
      id: number;
      userName: string;
      personName: string;
    };
    footer?: InvoiceFooter;
    records?: InvoiceRecord[];
    navData?: NavData;
    postProcessData?: PostProcessData;
    settlementData?: SettlementData;
    invoiceTag?: InvoiceTag.InvoiceTag;
    hasPdf: boolean;
    reverted: boolean;
    taskRecordData?: {
      taskId: number,
      id: number,
      name: string,
      externalId: string
    };
  }

  export interface InvoiceFooter {
    netSumPrice: Decimal;
    grossSumPrice: Decimal;
    sumVatAmount: Decimal;
    vatSums: InvoiceVatSum[];
  }

  export interface InvoiceVatSum {
    vatRate: Decimal;
    zeroVatRateType?: string;
    netSumPrice: Decimal;
    grossSumPrice: Decimal;
    sumVatAmount: Decimal;
  }

  export interface InvoiceRecord {
    id: number;
    recordName: string;
    hunVtszNumber?: string;
    amount: Decimal;
    unitType: string;
    vatRate: Decimal;
    zeroVatRateType?: string;
    comment?: string;
    currencyCode: string;
    vatAmount: Decimal;
    netPrice: Decimal;
    netUnitPrice: Decimal;
    grossPrice: Decimal;
    grossUnitPrice: Decimal;
    stockItemId?: number;
    ledgerNumber?: LedgerNumber.LedgerNumber;
  }

  export interface NavData {
    exportState: NavExportState;
    requestId?: string;
    technicalValidationMessages?: NavValidationMessage[];
    businessValidationMessages?: NavValidationMessage[];
  }

  export interface NavValidationMessage {
    validationResultCode: string,
    validationErrorCode: string,
    message: string,
    pointer?: any
  }


  export interface PostProcessData {
    state: PostProcessState;
    info?: string;
    time?: OffsetDateTime;
  }

  export interface SettlementData {
    settled: boolean;
    date?: LocalDate;
    comment?: string;
    spending?: {
      id: number;
      spendingNumber: string;
    }
  }

  export interface InvoiceCreateRequest {
    invoiceNumber?: string;
    invoiceSettingsId: number;
    invoiceBookId?: number;
    appearanceType: InvoiceSettings.InvoiceAppearanceType;
    commentTemplate?: string;
    currencyCode: string;
    paymentType: InvoicePaymentType;
    directionType: InvoiceDirectionType;
    issueDate: LocalDate;
    deliveryDate: LocalDate;
    deadline: LocalDate;
    customerRecordId: number;
    billingInfoId?: number;
    invoiceTagId?: number;
    records: InvoiceRecordCreateRequest[];
  }

  export interface InvoiceRecordCreateRequest {
    recordName: string;
    hunVtszNumber?: string;
    amount: Decimal;
    unitType: string;
    netUnitPrice: Decimal;
    vatRate: Decimal;
    zeroVatRateType?: string;
    comment?: string;
    currencyCode: string;
    stockItemId?: number;
    ledgerNumberId?: number;
  }

  export interface InvoiceStornoRequest {
    invoiceId: number;
    invoiceSettingsId: number;
    invoiceBookId?: number;
    invoiceNumber?: string;
    commentTemplate?: string;
    taskRevert: boolean;
  }

  export interface PostProcessRetryRequest {
    invoiceId: number;
  }

  export interface SettlementRequest {
    invoiceId: number;
    date: LocalDate;
    comment?: string;
  }

  export interface InvoiceTagRequest {
    invoiceIds: Set<number>;
    invoiceTagId?: number;
  }

  export interface InvoiceStornoResponse {
    invoice?: Invoice;
    revertResult?: TaskRecord.RevertResult;
  }

  export interface DownloadPdfRequest {
    invoiceId: number;
  }

  export interface BatchDownloadPdfRequest {
    invoiceBookId?: number;
    invoiceSeqFrom?: number;
    invoiceSeqTo?: number;
    issueDateFrom?: LocalDate;
    issueDateTo?: LocalDate;
  }

  export interface FormRecordInvoiceItemQueryRequest {
    recordName?: string;
    externalId?: string;
    excludedIds?: Set<number>;
    order?: Set<Order<FormRecordInvoiceItemOrderField>>;
    paging?: PagingRequest;
  }

  export interface FormRecordInvoiceItemCategoryQueryRequest {
    category?: string;
    paging?: PagingRequest;
  }

  export type InvoiceCategoryType = 'NORMAL' | 'MODIFY' | 'STORNO';

  export type InvoiceAppearanceType = 'PAPER' | 'ELECTRONIC' | 'EDI' | 'UNKNOWN';

  export type InvoicePaymentType = 'CASH' | 'CREDIT_CARD' | 'TRANSFER' | 'VOUCHER' | 'COD' | 'OTHER';

  export type InvoiceDirectionType = 'INBOUND' | 'OUTBOUND';

  export type NavExportState = 'EXPORT_NEEDED' | 'EXPORT_NOT_NEEDED' | 'IN_PROGRESS' | 'DONE' | 'FAILED';

  export type PostProcessState = 'UNKNOWN' | 'WAITING' | 'DONE' | 'FAILED';

  export enum OrderField {
    ID,
    CREATION_TIME,
    UPDATE_TIME,
    INVOICE_NUMBER,
    CATEGORY_TYPE,
    APPEARANCE_TYPE,
    PAYMENT_TYPE,
    DIRECTION_TYPE,
    ISSUE_DATE,
    DELIVERY_DATE,
    DEADLINE,
    CUSTOMER_NAME,
    NET_SUM_PRICE,
    GROSS_SUM_PRICE
  }

  export enum ValidatedField {
    INVOICE_SETTINGS,
    INVOICE_BOOK,
    INVOICE_NUMBER,
    REFERENCED_INVOICE_NUMBER,
    ISSUE_DATE,
    DELIVERY_DATE,
    DEADLINE,
    CURRENCY_CODE,

    RECORD_PRICE,
    RECORD_AMOUNT,
    RECORD_VAT_RATE,
    RECORD_NAME,
    SUM_VAT_AMOUNT,

    CUSTOMER_RECORD,
    DIRECTION_TYPE,
    INVOICE_TAG,
    UNKNOWN
  }

  export enum FormRecordInvoiceItemOrderField {
    RECORD_NAME,
    EXTERNAL_ID,
  }

  export class InvoiceMapper {
    public toPublicList(resourceList: InvoiceResource.Invoice[]): List<Invoice.Invoice> {
      return List.of(...resourceList.map((r) => this.toPublic(r)));
    }

    public toPublic(r: InvoiceResource.Invoice): Invoice.Invoice {
      return {
        id: r.id,
        invoiceSettings: {
          id: r.invoice_settings.id,
          profileName: r.invoice_settings.profile_name,
          invoiceIntegrationType: InvoiceSettings.InvoiceIntegrationType[r.invoice_settings.invoice_integration_type]
        },
        invoiceBookId: r.invoice_book_id,
        invoiceNumber: r.invoice_number,
        categoryType: <InvoiceCategoryType>r.category_type,
        appearanceType: <InvoiceAppearanceType>r.appearance_type,
        paymentType: <InvoicePaymentType>r.payment_type,
        directionType: <InvoiceDirectionType>r.direction_type,
        referencedInvoiceNumber: r.referenced_invoice_number,
        comment: r.comment,
        technicalComment: r.technical_comment,
        currencyCode: r.currency_code,
        sellerName: r.seller_name,
        sellerTaxNumber: r.seller_tax_number,
        sellerEuVatNumber: r.seller_eu_vat_number,
        sellerBankAccount: r.seller_bank_account,
        sellerPostalAddress: AddressResource.Mapper.toPublicPostalAddressOpt(r.seller_postal_address),
        customerName: r.customer_name,
        customerTaxNumber: r.customer_tax_number,
        customerEuVatNumber: r.customer_eu_vat_number,
        customerBankAccount: r.customer_bank_account,
        customerPostalAddress: AddressResource.Mapper.toPublicPostalAddressOpt(r.customer_postal_address),
        customerRecordId: r.customer_record_id,
        billingInfoId: r.billing_info_id,
        issueDate: Services.toLocalDate(r.issue_date),
        deliveryDate: Services.toLocalDate(r.delivery_date),
        deadline: Services.toLocalDate(r.deadline),
        creationTime: Services.toOffsetDateTime(r.creation_time),
        updateTime: Services.toOffsetDateTime(r.update_time),
        creatorUser: r.creator_user ? {
          id: r.creator_user.id,
          userName: r.creator_user.user_name,
          personName: r.creator_user.person_name,
        } : undefined,
        footer: this.toInvoiceFooter(r.footer),
        records: this.toInvoiceRecords(r.records),
        navData: this.toNavData(r.nav_data),
        postProcessData: this.toPostProcessData(r.post_process_data),
        settlementData: this.toSettlementData(r.settlement_data),
        invoiceTag: r.invoice_tag === undefined ? undefined : InvoiceTag.toPublic(r.invoice_tag),
        hasPdf: r.has_pdf,
        reverted: r.reverted === undefined ? false : r.reverted,
        taskRecordData: r.task_record_data ? {
          taskId: r.task_record_data.task_id,
          id: r.task_record_data.id,
          name: r.task_record_data.name,
          externalId: r.task_record_data.external_id,
        } : undefined
      };
    }

    public toInvoiceFooter(r?: InvoiceResource.InvoiceFooter): Invoice.InvoiceFooter | undefined {
      return r ? {
        netSumPrice: Services.reqDecimal(r.net_sum_price),
        grossSumPrice: Services.reqDecimal(r.gross_sum_price),
        sumVatAmount: Services.reqDecimal(r.sum_vat_amount),
        vatSums: this.toInvoiceVatSums(r.vat_sums)
      } : undefined;
    }

    public toInvoiceVatSums(r: InvoiceResource.InvoiceVatSum[]): Invoice.InvoiceVatSum[] {
      const item: Invoice.InvoiceVatSum[] = [];
      r.forEach((a) => item.push({
        vatRate: Services.reqDecimal(a.vat_rate),
        zeroVatRateType: a.zero_vat_rate_type,
        netSumPrice: Services.reqDecimal(a.net_sum_price),
        grossSumPrice: Services.reqDecimal(a.gross_sum_price),
        sumVatAmount: Services.reqDecimal(a.sum_vat_amount)
      }));
      return item;
    }

    public toInvoiceRecords(r: InvoiceResource.InvoiceRecord[] | undefined): Invoice.InvoiceRecord[] {
      const item: Invoice.InvoiceRecord[] = [];
      if (r) {
        r.forEach((a) => {
          item.push({
            id: a.id,
            recordName: a.record_name,
            hunVtszNumber: a.hun_vtsz_number,
            amount: Services.reqDecimal(a.amount),
            unitType: a.unit_type,
            vatRate: Services.reqDecimal(a.vat_rate),
            zeroVatRateType: a.zero_vat_rate_type,
            comment: a.comment,
            currencyCode: a.currency_code,
            vatAmount: Services.reqDecimal(a.vat_amount),
            netPrice: Services.reqDecimal(a.net_price),
            netUnitPrice: Services.reqDecimal(a.net_unit_price),
            grossPrice: Services.reqDecimal(a.gross_price),
            grossUnitPrice: Services.reqDecimal(a.gross_unit_price),
            stockItemId: a.stock_item_id,
            ledgerNumber: a.ledger_number ? LedgerNumber.toPublic(a.ledger_number) : undefined
          });
        });
      }
      return item;
    }

    public toNavData(r?: InvoiceResource.NavData): NavData | undefined {
      if (!r) {
        return undefined;
      }
      return {
        exportState: <NavExportState>r.export_state,
        requestId: r.request_id,
        technicalValidationMessages: r.technical_validation_message
          ? this.toValidationMessage(JSON.parse(r.technical_validation_message))
          : undefined,
        businessValidationMessages: r.business_validation_message
          ? this.toValidationMessage(JSON.parse(r.business_validation_message))
          : undefined
      };
    }

    public toValidationMessage(r: InvoiceResource.NavValidationMessage[]): Invoice.NavValidationMessage[] {
      return r.map(m => {
        return {
          validationResultCode: m.validation_result_code,
          validationErrorCode: m.validation_error_code,
          message: m.message,
          pointer: m.pointer
        };
      })
    }

    public toPostProcessData(r?: InvoiceResource.PostProcessData): Invoice.PostProcessData | undefined {
      if (r) {
        return {
          state: <Invoice.PostProcessState>r.state,
          info: r.info,
          time: r.time ? Services.toOffsetDateTime(r.time) : undefined
        };
      }
      return undefined;
    }

    public toSettlementData(r: InvoiceResource.SettlementData): Invoice.SettlementData | undefined {
      if (!r) {
        return undefined;
      }
      return {
        settled: r.settled,
        comment: r.comment,
        date: Services.toLocalDate(r.date),
        spending: r.spending ? {
          id: r.spending.id,
          spendingNumber: r.spending.spending_number
        } : undefined
      };
    }

    public toResourceQueryRequest(request: Invoice.QueryRequest): InvoiceResource.QueryRequest {
      return {
        id: Services.createIdParameter(request.invoiceIdSet),
        invoice_book_id: Services.createIdParameter(request.invoiceBookIds),
        invoice_settings_id: Services.createIdParameter(request.invoiceSettingsIds),
        invoice_tag_id: Services.createIdParameter(request.invoiceTagIds),
        invoice_number: request.invoiceNumber,
        category_type: Services.createListParameter(request.categoryType),
        appearance_type: request.appearanceType,
        payment_type: Services.createListParameter(request.paymentType),
        direction_type: request.directionType,
        task_record_assignee_user_id: Services.createIdParameter(request.taskRecordAssigneeUserIds),
        creator_user_id: Services.createIdParameter(request.creatorUserId),
        record_stock_item_id: Services.createIdParameter(request.recordStockItemId),
        customer_name: request.customerName,
        issue_date_from: Services.localDateToString(request.issueDateFrom),
        issue_date_to: Services.localDateToString(request.issueDateTo),
        delivery_date_from: Services.localDateToString(request.deliveryDateFrom),
        delivery_date_to: Services.localDateToString(request.deliveryDateTo),
        settlement_date_from: Services.localDateToString(request.settlementDateFrom),
        settlement_date_to: Services.localDateToString(request.settlementDateTo),
        settlement_settled: request.settlementSettled,
        deadline_from: Services.localDateToString(request.deadlineDateFrom),
        deadline_to: Services.localDateToString(request.deadlineDateTo),
        nav_export_state: Services.createListParameter(request.navExportState),
        q: request.q,
        order: Services.createOrderFieldParameter(Keys.toOrderFieldKey, request.order),
        page_number: request.paging ? request.paging.pageNumber : undefined,
        number_of_items: request.paging ? request.paging.numberOfItems : undefined,
        with_records: request.withRecords,
        with_footer: request.withFooter
      };
    }
  }
}

class Keys {

  private static readonly ID = 'id';
  private static readonly CREATION_TIME = 'creation_time';
  private static readonly UPDATE_TIME = 'update_time';
  private static readonly INVOICE_NUMBER = 'invoice_number';
  private static readonly CATEGORY_TYPE = 'category_type';
  private static readonly APPEARANCE_TYPE = 'appearance_type';
  private static readonly PAYMENT_TYPE = 'payment_type';
  private static readonly DIRECTION_TYPE = 'direction_type';
  private static readonly ISSUE_DATE = 'issue_date';
  private static readonly DELIVERY_DATE = 'delivery_date';
  private static readonly DEADLINE = 'deadline';
  private static readonly CUSTOMER_NAME = 'customer_name';
  private static readonly NET_SUM_PRICE = 'net_sum_price';
  private static readonly GROSS_SUM_PRICE = 'gross_sum_price';
  private static readonly RECORD_NAME = 'record_name';
  private static readonly EXTERNAL_ID = 'external_id';

  private static readonly orderFieldKeyMap: Map<Invoice.OrderField, string> = Map.of(
    Invoice.OrderField.ID, Keys.ID,
    Invoice.OrderField.CREATION_TIME, Keys.CREATION_TIME,
    Invoice.OrderField.UPDATE_TIME, Keys.UPDATE_TIME,
    Invoice.OrderField.INVOICE_NUMBER, Keys.INVOICE_NUMBER,
    Invoice.OrderField.CATEGORY_TYPE, Keys.CATEGORY_TYPE,
    Invoice.OrderField.APPEARANCE_TYPE, Keys.APPEARANCE_TYPE,
    Invoice.OrderField.PAYMENT_TYPE, Keys.PAYMENT_TYPE,
    Invoice.OrderField.DIRECTION_TYPE, Keys.DIRECTION_TYPE,
    Invoice.OrderField.ISSUE_DATE, Keys.ISSUE_DATE,
    Invoice.OrderField.DELIVERY_DATE, Keys.DELIVERY_DATE,
    Invoice.OrderField.DEADLINE, Keys.DEADLINE,
    Invoice.OrderField.CUSTOMER_NAME, Keys.CUSTOMER_NAME,
    Invoice.OrderField.NET_SUM_PRICE, Keys.NET_SUM_PRICE,
    Invoice.OrderField.GROSS_SUM_PRICE, Keys.GROSS_SUM_PRICE
  );

  private static readonly keyValidatedFieldMap: Map<string, Invoice.ValidatedField> = Map.of(
    'invoice_settings', Invoice.ValidatedField.INVOICE_SETTINGS,
    'invoice_book', Invoice.ValidatedField.INVOICE_BOOK,
    'invoice_number', Invoice.ValidatedField.INVOICE_NUMBER,
    'referenced_invoice_number', Invoice.ValidatedField.REFERENCED_INVOICE_NUMBER,
    'issue_date', Invoice.ValidatedField.ISSUE_DATE,
    'delivery_date', Invoice.ValidatedField.DELIVERY_DATE,
    'deadline', Invoice.ValidatedField.DEADLINE,
    'currency_code', Invoice.ValidatedField.CURRENCY_CODE,
    'invoice_tag_id', Invoice.ValidatedField.INVOICE_TAG,
    'record_net_unit_price', Invoice.ValidatedField.RECORD_PRICE,
    'record_amount', Invoice.ValidatedField.RECORD_AMOUNT,
    'record_vat_rate', Invoice.ValidatedField.RECORD_VAT_RATE,
    'record_record_name', Invoice.ValidatedField.RECORD_NAME,
    'customer_record', Invoice.ValidatedField.CUSTOMER_RECORD,
    'direction_type', Invoice.ValidatedField.DIRECTION_TYPE,
  );

  private static readonly formRecordInvoiceItemOrderFieldKeyMap: Map<Invoice.FormRecordInvoiceItemOrderField, string> = Map.of(
    Invoice.FormRecordInvoiceItemOrderField.RECORD_NAME, Keys.RECORD_NAME,
    Invoice.FormRecordInvoiceItemOrderField.EXTERNAL_ID, Keys.EXTERNAL_ID
  );

  public static toOrderFieldKey(field: Invoice.OrderField): string {
    return Keys.orderFieldKeyMap.get(field)!;
  }

  public static toValidatedField(fieldKey: string): Invoice.ValidatedField {
    return Keys.keyValidatedFieldMap.get(fieldKey, Invoice.ValidatedField.UNKNOWN);
  }

  public static toformRecordInvoiceItemOrderFieldKey(field: Invoice.FormRecordInvoiceItemOrderField): string {
    return Keys.formRecordInvoiceItemOrderFieldKeyMap.get(field)!;
  }

}
