/* eslint-disable */
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FieldError } from '../util/errors';
import { Map as ImmutableMap } from 'immutable';
import { HttpClient } from '@angular/common/http';
import { BaseHttpService, ResourceHelper } from '../util/http-services';
import { ResourceQueryResult } from '../util/services';
import { DisableRequest, EmptyMessage, IdentityMessage, ListQuery } from '../util/messages';
import { StockRecordLog, StockRecordLogQuery } from './stock-record-log.service';
import { DownloadedFile } from '../util/downloaded-files';
import { UrlBuilder } from '../../util/url-builder';
import { map } from 'rxjs/operators';
import { LedgerNumberResource } from '../ledger/number/ledger-number-resource.service';
import { InvoiceTag } from '../invoice/tag/invoice-tag.service';
import { StockItemUnitOfMeasure } from './stock-item-unit-of-measure';
/* eslint-enable */

export namespace StockItem {

  export enum OrderField {
    ID,
    NAME,
    EXTERNAL_ID,
    SERIAL_CODE,
    UNIT,
    UNIT_VALUE,
    UNIT_VAT_RATE,
    COMPANY_ID,
    PRODUCT_CODE
  }

  export class Keys {

    private static readonly ID = 'id';
    private static readonly NAME = 'name';
    private static readonly EXTERNAL_ID = 'external_id';
    private static readonly SERIAL_CODE = 'serial_code';
    private static readonly PACKAGE_DATA = 'package_data';
    private static readonly UNIT = 'unit';
    private static readonly UNIT_VALUE = 'unit_value';
    private static readonly UNIT_VAT_RATE = 'unit_vat_rate';
    private static readonly COMPANY_ID = 'company_id';
    private static readonly PRODUCT_CODE = 'product_code';

    private static readonly orderFieldKeyMap: ImmutableMap<StockItem.OrderField, string> = ImmutableMap.of(
      StockItem.OrderField.ID, Keys.ID,
      StockItem.OrderField.NAME, Keys.NAME,
      StockItem.OrderField.EXTERNAL_ID, Keys.EXTERNAL_ID,
      StockItem.OrderField.SERIAL_CODE, Keys.SERIAL_CODE,
      StockItem.OrderField.UNIT, Keys.UNIT,
      StockItem.OrderField.UNIT_VALUE, Keys.UNIT_VALUE,
      StockItem.OrderField.UNIT_VAT_RATE, Keys.UNIT_VAT_RATE,
      StockItem.OrderField.COMPANY_ID, Keys.COMPANY_ID,
      StockItem.OrderField.PRODUCT_CODE, Keys.PRODUCT_CODE,
    );

    public static toOrderFieldKey(field: StockItem.OrderField): string {
      return Keys.orderFieldKeyMap.get(field)!;
    }
  }

  export enum CustomerRecordPriceOrderField {
    ID,
    CUSTOM_UNIT_PRICE,
    CUSTOMER_RECORD_ID
  }

  export class CustomerRecordPriceKeys {

    private static readonly ID = 'id';
    private static readonly CUSTOM_UNIT_PRICE = 'custom_unit_price';
    private static readonly CUSTOMER_RECORD_ID = 'customer_record_id';

    private static readonly orderFieldKeyMap: ImmutableMap<StockItem.CustomerRecordPriceKeys, string> = ImmutableMap.of(
      StockItem.CustomerRecordPriceOrderField.ID, CustomerRecordPriceKeys.ID,
      StockItem.CustomerRecordPriceOrderField.CUSTOM_UNIT_PRICE, CustomerRecordPriceKeys.CUSTOM_UNIT_PRICE,
      StockItem.CustomerRecordPriceOrderField.CUSTOMER_RECORD_ID, CustomerRecordPriceKeys.CUSTOMER_RECORD_ID,
    );

    public static toOrderFieldKey(field: StockItem.CustomerRecordPriceOrderField): string {
      return CustomerRecordPriceKeys.orderFieldKeyMap.get(field)!;
    }
  }
}

export interface StockItemQuery extends ListQuery.Paging, ListQuery.Order, ListQuery.QueryText, ListQuery.Fields {
  id?: string;
  excluded_ids?: string;
  type?: StockItemType;
  usable_for_form_field?: boolean;
  external_id?: string;
  external_id_part?: string;
  product_code?: string;
  product_code_part?: string;
  disabled?: boolean;
  name?: string;
  unit?: string;
  unit_vat_rate?: string;
  unit_price_from?: string;
  unit_price_to?: string;
  unit_currency_code?: string;
  hun_vtsz_number?: string;
  created_on?: string;
  product_category?: string;
  weight_in_grams_from?: string;
  weight_in_grams_to?: string;
  place?: string;
  serial_code?: string;
  category_ids?: string;
  company_ids?: string;
  ledger_number_ids?: string;
  no_progress_bar?: boolean;
  with_amount?: boolean;
}

export interface StockItemFieldErrorMap {
  name?: FieldError;
  external_id?: FieldError;
  product_code?: FieldError;
  company_id?: FieldError;
  description?: FieldError;
  hun_vtsz_number?: FieldError;
  unit?: FieldError;
  unit_price?: FieldError;
  unit_vat_rate?: FieldError;
  unit_price_currency_code?: FieldError;
  product_category?: FieldError;
  category_ids?: FieldError;
  weight_in_grams?: FieldError;
  place?: FieldError;
  serial_code?: FieldError;
  package_data?: FieldError;
  default_package_measurement_id?: FieldError;
}

export interface StockItemMoveFieldErrorMap {
  source_stock_id?: FieldError;
  destination_stock_id?: FieldError;
  amount?: FieldError;
}

export interface StockItemDisposeFieldErrorMap extends StockItemMoveFieldErrorMap {
}

export interface UnitPrice {
  value: number; // decimal
  vat_rate: number; // decimal
  currency_code: string;
  zero_vat_rate_type?: string;
}

export type StockItemType = 'STOCK' | 'SERVICE';

export class StockItemTypeObject {
  type: StockItemType;
  stringKey: string;
  iconRes: string;
}

export const stockItemTypes: StockItemTypeObject[] = [
  {type: 'STOCK', stringKey: 'STOCK_ITEM_TYPE_STOCK', iconRes: 'icomoon-sidebar-stock'},
  {type: 'SERVICE', stringKey: 'STOCK_ITEM_TYPE_SERVICE', iconRes: 'icomoon-sidebar-customers'}
];

export type StockItemTypeUrlParam = 'stock-type' | 'service-type';

export class StockItemTypeUrlMapper {

  public static fromUrl(urlType: StockItemTypeUrlParam): StockItemType | undefined {
    if (!urlType) {
      return undefined;
    }
    if ('stock-type' === urlType) {
      return 'STOCK';
    }
    if ('service-type' === urlType) {
      return 'SERVICE';
    }
    return undefined;
  }

  public static toUrl(urlType: StockItemType): StockItemTypeUrlParam | undefined {
    if (!urlType) {
      return undefined;
    }
    if ('STOCK' === urlType) {
      return 'stock-type';
    }
    if ('SERVICE' === urlType) {
      return 'service-type';
    }
    return undefined;
  }
}

export interface UpdateStockItemRequest extends IdentityMessage {
  usable_for_form_field: boolean;
  external_id?: string;
  product_code: string;
  company_id?: number;
  name: string;
  description?: string;
  hun_vtsz_number?: string;
  unit: string;
  unit_price: UnitPrice;
  product_category?: string;
  category_ids?: number[];
  weight_in_grams?: number;
  place?: string;
  serial_code?: string;
  ledger_number?: number;
  default_package_measurement_id?: number;
}

export interface CreateStockItemRequest {
  type: StockItemType;
  usable_for_form_field: boolean;
  external_id?: string;
  product_code?: string;
  company_id?: number;
  name: string;
  description?: string;
  hun_vtsz_number?: string;
  unit: string;
  unit_price: UnitPrice;
  product_category?: string;
  category_ids?: number[];
  weight_in_grams?: number;
  place?: string;
  serial_code?: string;
  ledger_number?: number;
  package_data?: StockItemUnitOfMeasure.StockItemUnitOfMeasureCreateRequest;
}

export interface MoveStockItemRequest extends IdentityMessage {
  source_stock_id: number;
  destination_stock_id: number;
  amount: number;
  note?: string;
}

export interface StockItemBulkDisableRequest {
  ids: number[];
  disabled: boolean;
}

export interface DisposeStockItemRequest extends MoveStockItemRequest {
}

export interface StockItem {
  id: number;
  type: StockItemType;
  usable_for_form_field: boolean;
  external_id: string;
  product_code: string;
  company?: Company;
  disabled: boolean;
  creation_time: string;
  update_time: string;
  picture?: {
    content_type?: string;
    content_hash?: string;
  };
  name: string;
  description: string;
  unit: string;
  unit_price: UnitPrice;
  hun_vtsz_number?: string;
  product_category: string;
  category_ids: number[];
  weight_in_grams?: number;
  place: string;
  serial_code: string;
  stock_amount?: StockItemStock[];
  ledger_number?: LedgerNumberResource.LedgerNumber;
  package_data?: StockItemUnitOfMeasure.StockItemUnitOfMeasure;
  measurements?: StockItemUnitOfMeasure.StockItemUnitOfMeasure[];
  created_on?: string;
  creator_user?: {
    id: number;
    user_name: string;
    person_name: string;
  };
}

export interface StockItemStock {
  stock_id: number;
  stock_name: string;
  amount: number;
  intake_sum_net_price?: number;
  intake_avg_net_price?: number;
  disabled: boolean;
  waste: boolean;
}

export interface StockItemReservation {
  amount: number;
  company: Company;
}

export interface Company {
  id: number;
  name: string;
}

export interface StockItemCustomerRecordPriceQuery extends ListQuery.Paging, ListQuery.Order {
  stock_item_id: number;
  custom_unit_price_from?: string;
  custom_unit_price_to?: string;
  customer_record_id?: string;
}

export interface StockItemCustomerRecordPrice {
  id: number;
  stock_item_id: number;
  custom_unit_price: string;
  customer_record: {
    id: number;
    name: string;
  };
}

export interface StockItemCustomerRecordPriceCreateRequest {
  stock_item_id: number;
  custom_unit_price: string;
  customer_record_id: number;
}

export interface StockItemCustomerRecordPriceUpdateRequest extends StockItemCustomerRecordPriceCreateRequest {
  id: number;
}

export interface StockItemCustomerRecordPriceDeleteRequest {
  stockItemId: number;
  id: number;
}


@Injectable()
export class StockItemService extends BaseHttpService {

  create(request: CreateStockItemRequest): Observable<IdentityMessage> {
    return this.http.post<IdentityMessage>(this.url, request);
  }

  query(request: StockItemQuery): Observable<ResourceQueryResult<StockItem>> {
    return this.http.get<ResourceQueryResult<StockItem>>(this.url, this.parseParams(request));
  }

  getStock(request: { id: number, waste?: boolean, disabled?: boolean }): Observable<StockItemStock[]> {
    return this.http.get<StockItemStock[]>(this.url + `${request.id}/stock`, this.parseParams(request));
  }

  getReservations(request: IdentityMessage): Observable<StockItemReservation[]> {
    return this.http.get<StockItemReservation[]>(this.url + `${request.id}/reservations`);
  }

  get(request: IdentityMessage): Observable<StockItem> {
    return this.http.get<StockItem>(this.url + `${request.id}`);
  }

  update(request: UpdateStockItemRequest): Observable<EmptyMessage> {
    return this.http.put(this.url + `${request.id}`, request);
  }

  setDisabled(request: DisableRequest): Observable<EmptyMessage> {
    return this.http.patch(this.url + `${request.id}/disabled`, request);
  }

  bulkDisable(request: StockItemBulkDisableRequest): Observable<EmptyMessage> {
    return this.http.patch(this.url + `/bulk-disable`, request);
  }

  move(request: MoveStockItemRequest): Observable<EmptyMessage> {
    return this.http.post(this.url + `${request.id}/move`, request);
  }

  dispose(request: DisposeStockItemRequest): Observable<EmptyMessage> {
    return this.http.post(this.url + `${request.id}/dispose`, request);
  }

  stockRecordLogQuery(request: StockRecordLogQuery): Observable<ResourceQueryResult<StockRecordLog>> {
    return this.http.get<ResourceQueryResult<StockRecordLog>>(this.url + `${request.stock_item_id}/stock-record-logs/`,
      this.parseParams(request));
  }

  getCustomerRecordPrices(request: StockItemCustomerRecordPriceQuery): Observable<ResourceQueryResult<StockItemCustomerRecordPrice>> {
    return this.http.get<ResourceQueryResult<StockItemCustomerRecordPrice>>(this.url + `${request.stock_item_id}/prices`,
      this.parseParams(request));
  }

  createCustomerRecordPrice(request: StockItemCustomerRecordPriceCreateRequest): Observable<IdentityMessage> {
    return this.http.post<IdentityMessage>(this.url + `${request.stock_item_id}/prices`, request);
  }

  updateCustomerRecordPrice(request: StockItemCustomerRecordPriceUpdateRequest): Observable<EmptyMessage> {
    return this.http.put(this.url + `${request.stock_item_id}/prices/${request.id}`, request);
  }

  deleteCustomerRecordPrice(request: StockItemCustomerRecordPriceDeleteRequest): Observable<EmptyMessage> {
    return this.http.delete(this.url + `${request.stockItemId}/prices/${request.id}`);
  }

  queryUnitOfMeasures(request: StockItemUnitOfMeasure.StockItemUnitOfMeasureQuery):
    Observable<ResourceQueryResult<StockItemUnitOfMeasure.StockItemUnitOfMeasure>> {
    return this.http.get<ResourceQueryResult<StockItemUnitOfMeasure.StockItemUnitOfMeasure>>(
      this.url + `${request.stock_item_id}/unit-of-measures`, this.parseParams(request));
  }

  getUnitOfMeasure(request: StockItemUnitOfMeasure.GetRequest):
    Observable<StockItemUnitOfMeasure.StockItemUnitOfMeasure> {
    return this.http.get<StockItemUnitOfMeasure.StockItemUnitOfMeasure>(
      this.url + `${request.stock_item_id}/unit-of-measures/${request.id}`);
  }

  createUnitOfMeasure(request: StockItemUnitOfMeasure.StockItemUnitOfMeasureCreateRequest): Observable<IdentityMessage> {
    return this.http.post<IdentityMessage>(this.url + `${request.stock_item_id!}/unit-of-measures`, request);
  }

  updateUnitOfMeasure(request: StockItemUnitOfMeasure.StockItemUnitOfMeasureUpdateRequest): Observable<EmptyMessage> {
    return this.http.put(this.url + `${request.stock_item_id}/unit-of-measures/${request.id}`, request);
  }

  disableUnitOfMeasure(request: StockItemUnitOfMeasure.StockItemUnitOfMeasureDisableRequest): Observable<EmptyMessage> {
    return this.http.delete(this.url + `${request.stock_item_id}/unit-of-measures/${request.id}`);
  }

  exportXlsTemplate(): Observable<DownloadedFile> {
    const url = UrlBuilder.create('stock-items/export-xls-template')
      .baseUrl(this.resourceHelper.getBaseUrl())
      .build();
    return this.http.get(url, {
      observe: 'response',
      responseType: 'blob'
    }).pipe(map((res) => {
      return new DownloadedFile(res);
    }));
  }

  exportXls(request: StockItemQuery): Observable<DownloadedFile> {
    const url = UrlBuilder.create('stock-items/export-xls')
      .baseUrl(this.resourceHelper.getBaseUrl())
      .build();
    return this.http.get(url, {
      observe: 'response',
      responseType: 'blob',
      params: this.parseParams(request).params
    }).pipe(map((res) => {
      return new DownloadedFile(res);
    }));
  }

  downloadPicture(request: IdentityMessage): Observable<DownloadedFile> {
    const url = UrlBuilder.create(
      'stock-items/:stockItemId/picture')
      .baseUrl(this.resourceHelper.getBaseUrl())
      .namedNumber('stockItemId', request.id)
      .build();
    return this.http.get(url, {
      observe: 'response',
      responseType: 'blob'
    }).pipe(map((res) => {
      return new DownloadedFile(res);
    }));
  }

  deletePicture(request: IdentityMessage): Observable<EmptyMessage> {
    return this.http.delete<EmptyMessage>(this.url + `${request.id}/picture`);
  }

  constructor(private http: HttpClient, private resourceHelper: ResourceHelper) {
    super(resourceHelper, '/stock-items/');
  }

}
