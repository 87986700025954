<div class="card">

  <div class="card-header">
    {{ model.title }}
    <div class="card-actions" *ngIf="!nonEditable">
      <a class="btn-setting cursor-pointer" (click)="addItem()" title="{{'COMMON_BUTTON_ADD' | translate}}"><i class="icomoon icomoon-add title"></i></a>
    </div>
  </div>

  <div class="card-body p-0">
    <ng-template app-form-record-form-table-field-holder></ng-template>
  </div>

  <div class="card-footer" *ngIf="!nonEditable">
    <div class="col-sm-12 col-md-12 p-0">
      <div *ngIf="wrapperViews.length > 0">
        <input class="btn btn-add-record cursor-pointer p-0" type="button"
               value="{{'COMMON_BUTTON_ADD_SOMETHING'|translate: {name: model.formTableName} }}" (click)="addItem()">
      </div>
      <div class="card-body text-center" *ngIf="wrapperViews.length === 0">
        <p class="empty-form-hint-text">{{ 'FORM_TABLE_ADD_TEXT' | translate: {name: model.formTableName} }}</p>
        <button type="button" class="btn btn-secondary" (click)="addItem()">
          {{'COMMON_BUTTON_ADD'|translate: {name: model.formTableName} }}
        </button>
      </div>
    </div>
  </div>

</div>
