import { AfterViewInit, Component, ElementRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { Dates } from '../../lib/util/dates';

@Component({
  selector: 'app-santa',
  templateUrl: './santa.component.html',
  styleUrls: ['./santa.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SantaComponent implements AfterViewInit {

  @ViewChild('snow_canvas')
  snowCanvasRef: ElementRef;

  ngAfterViewInit(): void {
    // this.initSnow();
  }

  get snowEnabled() {
    const enabledStr = localStorage.getItem('snow_enabled');
    return enabledStr === undefined || enabledStr === null || enabledStr === 'true';
  }

  get isAlmostChristmas() {
    return Dates.isAlmostChristmas();
  }

  private initSnow() {
    const c = this.snowCanvasRef.nativeElement;
    const $ = c.getContext('2d');
    let w = c.width = window.innerWidth;
    let h = c.height = window.innerHeight;

    Snowy();

    function Snowy() {
      let snow;
      const arr: any[] = [];
      const num = 400, tsc = 1, sp = 1;
      const sc = 1.3, t = 0, mv = 20, min = 1;
      for (let i = 0; i < num; ++i) {
        snow = new Flake();
        snow.y = Math.random() * (h + 50);
        snow.x = Math.random() * w;
        snow.t = Math.random() * (Math.PI * 2);
        snow.sz = (100 / (10 + (Math.random() * 100))) * sc;
        snow.sp = (Math.pow(snow.sz * .8, 2) * .15) * sp;
        snow.sp = snow.sp < min ? min : snow.sp;
        arr.push(snow);
      }
      go();

      function go() {
        window.requestAnimationFrame(go);
        $.clearRect(0, 0, w, h);
        $.fillStyle = 'hsla(244,100%,3%,0)';
        $.fillRect(0, 0, w, h);
        $.fill();
        for (let i = 0; i < arr.length; ++i) {
          const f = arr[i];
          f.t += .05;
          f.t = f.t >= Math.PI * 2 ? 0 : f.t;
          f.y += f.sp;
          f.x += Math.sin(f.t * tsc) * (f.sz * .3);
          if (f.y > h + 50) {
            f.y = -10 - Math.random() * mv;
          }
          if (f.x > w + mv) {
            f.x = -mv;
          }
          if (f.x < -mv) {
            f.x = w + mv;
          }
          f.draw();
        }
      }

      function Flake() {
        this.draw = function () {
          this.g = $.createRadialGradient(this.x, this.y, 0, this.x, this.y, this.sz);
          this.g.addColorStop(0, 'hsla(113,8%,80%,0.5)');
          this.g.addColorStop(1, 'hsla(113,8%,80%,0.05)');
          $.moveTo(this.x, this.y);
          $.fillStyle = this.g;
          $.beginPath();
          $.arc(this.x, this.y, this.sz, 0, Math.PI * 2, true);
          $.fill();
        }
      }
    }

    window.addEventListener('resize', function () {
      c.width = w = window.innerWidth;
      c.height = h = window.innerHeight;
    }, false);
  }

}
