/* eslint-disable */
import { OwnerUserItem, TypeItem } from './core-utils';
import { CustomerItem } from './customer-utils';
import { DisabledItem } from './search-utils';
/* eslint-enable */

export class NoteSearchModel {
  id: string = '';
  content: string = '';
  disabled: DisabledItem | null = null;
  taskName: string = '';
  customerName: string = '';
  ownerUserName: string = '';

  public isEmpty(): boolean {
    return this.id.length === 0
      && this.content.length === 0
      && this.taskName.length === 0
      && this.customerName.length === 0
      && this.ownerUserName.length === 0
      && (this.disabled ? this.disabled.id === DisabledItem.active().id : true)
      ;
  }
}

export class NoteDetailModel {
  creation_time: string;
  update_time: string;
  content: string;
  customer: CustomerItem;
  owner_user: OwnerUserItem;
  task_record: TypeItem;
  attachment_ids: number[] = [];
}
