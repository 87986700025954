import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ShipmentGroup, ShipmentGroupService } from '../../../../lib/shipment-group/shipment-group.service';
import { Address } from '../../../../lib/address';
import { ConfigurationService } from '../../../../lib/core-ext/configuration.service';
import { ConfigModel } from '../../../../util/task-record-utils';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { GoogleMapsLoaderUtil } from '../../../../util/google/google-maps-loader.util';
import { ShipmentGroupEndpoints, ShipmentGroupModel } from '../shipment-group.model';
import { RightResolver, RightService } from '../../../../lib/right.service';
import { RightModel } from '../../../../app.rights';
import { ShipmentRightModel } from '../../../../app.rights.shpiment';
import { UserService } from '../../../../lib/user.service';
import { ToasterService } from '../../../../fork/angular2-toaster/src/toaster.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-shipment-group-depot-list',
  templateUrl: './shipment-group-depot-list.component.html',
  styleUrls: ['./shipment-group-depot-list.component.scss']
})
export class ShipmentGroupDepotListComponent implements OnInit {

  @Input()
  shipmentGroupId: number;

  @Input()
  readonly: boolean;

  private _endpoints: ShipmentGroupEndpoints = new ShipmentGroupEndpoints();

  get endpoints(): ShipmentGroupEndpoints {
    return this._endpoints;
  }

  @Input()
  set endpoints(value: ShipmentGroupEndpoints) {
    this._endpoints = value;
    this.loadConfig(() => {
      this.initMap(() => {
        this.loadDepots();
      });
    });
  }

  @Output()
  openAddShipmentDepotDialog: EventEmitter<any> = new EventEmitter();

  @Input()
  shipmentGroupModel: ShipmentGroupModel;

  @ViewChild('gmap', { static: true }) gmapElement: any;
  map: google.maps.Map;
  private markers: google.maps.Marker[] = [];
  private isMapReady: boolean = false;

  config: ConfigModel = new ConfigModel();

  depots: Depot[] = [];
  firstLoadDone: boolean = false;

  shipmentRightModel: ShipmentRightModel = ShipmentRightModel.empty();

  lastOpenedInfoWindow: google.maps.InfoWindow;

  constructor(
    private shipmentGroupService: ShipmentGroupService,
    private configurationService: ConfigurationService,
    private rightService: RightService,
    private userService: UserService,
    private toasterService: ToasterService,
    private translateService: TranslateService
  ) {
  }

  ngOnInit() {
    this.loadRightModels();
  }

  private loadRightModels() {
    this.rightService.getRightResolver().subscribe(
      (resolver: RightResolver) => {
        this.userService.get({
          id: resolver.userProfile!.id
        }).subscribe((user) => {
          const rightModel = RightModel.of(resolver);
          this.shipmentRightModel = ShipmentRightModel.of({
            rightModel: rightModel,
            currentCompanies: user.companies.map(c => c.id)
          });
        });
      }
    );
  }

  private loadConfig(completion: () => void) {
    this.config = this.configurationService.getConfigurationModel();
    completion();
  }

  initMap(completion: () => void) {
    if (!this.map) {
      if (GoogleMapsLoaderUtil.didMapLoad()) {
        this.createMap();
        completion();
      }
      else {
        GoogleMapsLoaderUtil.subscribe(() => {
          this.createMap();
          completion();
        });
      }
    }
    else {
      completion();
    }
  }

  createMap() {
    const mapProp = {
      center: new google.maps.LatLng(47.49801, 19.03991),
      zoom: 15,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      streetViewControl: false,
      mapTypeControl: false
    };
    this.map = new google.maps.Map(this.gmapElement.nativeElement, mapProp);
    const context = this;
    google.maps.event.addListenerOnce(this.map, 'idle', function () {
      context.isMapReady = true;
    });
  }

  loadDepots() {
    this.shipmentGroupService.getDepots({
      id: this.shipmentGroupId
    }).subscribe((result: ShipmentGroup.Depot[]) => {
      this.markers.forEach(m => m.setMap(null));
      this.markers = [];
      this.depots = result.map(d => {
        const lat: number | undefined
          = d.location.place
          ? d.location.place.coordinate
            ? d.location.place.coordinate.latitude.toNumber()
            : undefined
          : undefined;
        const lon: number | undefined
          = d.location.place
          ? d.location.place.coordinate
            ? d.location.place.coordinate.longitude.toNumber()
            : undefined
          : undefined;
        const address: string
          = d.location.place && d.location.place.postalAddress
          ? Address.PostalAddressMapper.toString(d.location.place.postalAddress, this.config.postalAddressFormat)
          : '';
        const resultDepot = new Depot();
        resultDepot.id = d.id;
        resultDepot.name = d.location.name;
        resultDepot.address = address;
        resultDepot.coords = lat && lon ? new google.maps.LatLng(lat, lon) : undefined;
        resultDepot.in = d.in;
        resultDepot.out = d.out;
        return resultDepot;
      });
      this.firstLoadDone = true;
      this.loadMapBounds();
    });
  }

  private loadMapBounds() {
    const bounds = new google.maps.LatLngBounds();
    let depotsWithCoords: number = 0;
    this.depots.forEach((d, index) => {
      if (d.coords) {
        depotsWithCoords++;
        bounds.extend(d.coords);

        const infoWindow = new google.maps.InfoWindow({
          content: '<center><b>' + d.name + '</b></center><br/>' +
            d.address
        });

        const marker = new google.maps.Marker({
          position: d.coords,
          map: this.map,
          label: {
            text: (1 + index) + '',
            fontSize: '16px'
          },
          icon: {
            url: this.resolvePoiUrl(),
            labelOrigin: this.getLabelOrigin()
          },
          zIndex: index

        });
        const that = this;
        marker.addListener('click', function () {
          if (that.lastOpenedInfoWindow) {
            that.lastOpenedInfoWindow.close();
          }
          that.lastOpenedInfoWindow = infoWindow;
          infoWindow.open(map, marker);
        });
        this.markers[index] = marker;
      }
    });
    if (this._endpoints.source.coords) {
      bounds.extend(this._endpoints.source.coords);
      const infoWindow = new google.maps.InfoWindow({
        content: '<center><b>' + this._endpoints.source.name + '</b></center><br/>' +
          this._endpoints.source.address
      });

      const marker = new google.maps.Marker({
        position: this._endpoints.source.coords,
        map: this.map,
        icon: {
          url: '../../../../assets/img/poi/ic_map_poi_active_blue.svg'
        },
        zIndex: depotsWithCoords

      });
      const that = this;
      marker.addListener('click', function () {
        if (that.lastOpenedInfoWindow) {
          that.lastOpenedInfoWindow.close();
        }
        that.lastOpenedInfoWindow = infoWindow;
        infoWindow.open(map, marker);
      });
      this.markers[depotsWithCoords] = marker;
    }
    if (this._endpoints.destination.coords) {
      bounds.extend(this._endpoints.destination.coords);
      const infoWindow = new google.maps.InfoWindow({
        content: '<center><b>' + this._endpoints.destination.name + '</b></center><br/>' +
          this._endpoints.destination.address
      });

      const marker = new google.maps.Marker({
        position: this._endpoints.destination.coords,
        map: this.map,
        icon: {
          url: '../../../../assets/img/poi/ic_map_poi_active_green.svg'
        },
        zIndex: depotsWithCoords + 1

      });
      const that = this;
      marker.addListener('click', function () {
        if (that.lastOpenedInfoWindow) {
          that.lastOpenedInfoWindow.close();
        }
        that.lastOpenedInfoWindow = infoWindow;
        infoWindow.open(map, marker);
      });
      this.markers[depotsWithCoords + 1] = marker;
    }
    const map = this.map;
    if (!bounds.isEmpty()) {
      if (this.isMapReady) {
        map.fitBounds(bounds);
        map.panToBounds(bounds);
      }
      else {
        google.maps.event.addListenerOnce(this.map, 'idle', function () {
          map.fitBounds(bounds);
          map.panToBounds(bounds);
        });
      }
    }
  }

  private resolvePoiUrl() {
    return '../../../../assets/img/poi/ic_map_poi_number.svg';
  }

  private getLabelOrigin() {
    return new google.maps.Point(18, 15);
  }

  onDepotDropped(event: CdkDragDrop<Depot[]>) {
    moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    this.rearrangeDepots();
  }

  private rearrangeDepots() {
    this.shipmentGroupService.rearrangeDepots({
      shipmentGroupId: this.shipmentGroupId,
      ids: this.depots.map(d => d.id)
    }).subscribe(() => {
        this.loadDepots();
    }, (error: any) => {
      this.loadDepots();
    });
  }

  onDraggedChanged(depot: Depot, dragged: boolean) {
    depot.dragged = dragged;
  }

  deleteDepot(depotId: number) {
    this.shipmentGroupService.deleteDepot({
      shipmentGroupId: this.shipmentGroupId,
      id: depotId
    }).subscribe(() => {
      this.loadDepots();
    });
  }

  deletable(depot: Depot): boolean {
    return !(depot.in.inTransport || depot.out.inTransport);
  }

  canEdit() {
    return !this.readonly &&
      this.shipmentGroupModel && this.shipmentGroupModel.transporterCompany && this.shipmentRightModel.shipmentGroupDepotUpdate
        .hasRightForShipment({transporterId: this.shipmentGroupModel.transporterCompany.id}, undefined);
  }

}

class Depot {
  id: number;
  name: string;
  address: string;
  coords?: google.maps.LatLng;
  in: ShipmentGroup.Shipment;
  out: ShipmentGroup.Shipment;
  dragged: boolean = false;

  get inInTransport(): boolean {
    return this.in.inTransport;
  }

  get outInTransport(): boolean {
    return this.out.inTransport;
  }

  get shipmentArrived(): boolean {
    return this.in.state === 'DELIVERED';
  }

  get shipmentFailed(): boolean {
    return this.in.state === 'HANDOVER_FAILED';
  }

}
