/* eslint-disable */
import { MultiselectOptionItem, OptionItem } from '../core-utils';
import { DocumentGroup } from '../../lib/document/document-group.service';
import { FileSize, FileTypeModel, FileTypeModelFactory } from '../file-attachment.model';
import { DocumentFile } from '../../lib/document/document-file.service';
import { FieldError } from '../../lib/util/services';
import { DisabledItem } from '../search-utils';
import {LazyFileModel} from "../../lib/util/downloaded-files";
/* eslint-enable */

export class DocumentGroupDashboardModel {
  id: number | null = null;
  name: string = '';
  code: string = '';
  creation_time: string = '';
  update_time: string = '';
  disabled: boolean = false;
  documentCount: number = 0;
  percentage: number = 0.0;
}

export class DocumentFileListModel {

  id: number = -1;
  name: string = '';
  language_code: string = '';
  content_size: FileSize = FileSize.zero();
  content_type: string = '';
  file_type: FileTypeModel;
  creation_time: string = '';
  update_time: string = '';
  content_update_time: string = '';
  disabled: boolean = false;
  owner_user: number = -1;
  document_group_id: number | null = null;
  hasThumbnail: boolean = false;
  thumbnailFile: LazyFileModel = new LazyFileModel();

  public static fromApi(file: DocumentFile): DocumentFileListModel {
    const m = new DocumentFileListModel();
    m.id = file.id;
    m.name = file.name;
    m.language_code = file.language_code;
    m.content_size = FileTypeModelFactory.createFileSizeFromBytesBy10(file.content_size);
    m.content_type = file.content_type;
    m.file_type = FileTypeModelFactory.createFileTypeFromContentType(file.content_type);
    m.creation_time = file.creation_time;
    m.update_time = file.update_time;
    m.content_update_time = file.content_update_time;
    m.disabled = file.disabled;
    m.owner_user = file.owner_user;
    m.document_group_id = file.document_group_id;
    m.hasThumbnail = file.has_thumbnail;
    return m;
  }

}

export class DocumentFileEditModel {
  id: number = -1;
  name: string = '';
  external_id: string = '';
  has_thumbnail: boolean = false;
  document_group: MultiselectOptionItem<number>[] = [];
  language_code?: LanguageItem;
  description: string = '';
  contentType: string = '';
}

export class DocumentFileReadModel {
  id: number = -1;
  external_id: string = '';
  name: string = '';
  size: number = -1;
  user_id: number = -1;
  language_code?: string;
  document_group_id?: number;
  file_type: string;
  content_update_time: string = '';
  creation_time: string = '';
  update_time: string = '';
  disabled: boolean = false;
  description: string = '';
  has_thumbnail: boolean = false;
  contentType: string = '';
}

export class DocumentFileSearchModel {
  name: string;
  documentGroupId: number | null;
  group: DocumentGroup | null = null;
  disabled?: DisabledItem;

  public isEmpty(): boolean {
    return this.name.length === 0
      && this.group === null
      && OptionItem.idOrUndefined(this.disabled) === undefined
      ;
  }
}

export interface DocumentFileFieldErrorMap {
  external_id?: FieldError;
}

export class LanguageItem extends OptionItem<string> {
}

export class DocumentFileCreateModel extends DocumentFileEditModel  { }

export class DocumentGroupItem extends OptionItem<number> {
}
