<div class="app flex-row align-items-center">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-6 text-center">
        <img class="w-100" src="../../../assets/img/error/{{httpCode}}.svg">
        <h4 class="pt-3">{{('HTTP_ERROR_TITLE_' + httpCode) | translate}}</h4>
        <p class="pt-1 error-text-label">{{('HTTP_ERROR_SUBTITLE_' + httpCode) | translate}}</p>
        <small class="pt-1 error-text-label">{{errorCode}} - {{errorDetailMessage}}: {{errorDetailDescription}}</small>
        <div class="d-flex justify-content-center pt-1">
          <button class="btn btn-primary-green mr-1" type="button" (click)="back()">{{ 'COMMON_BUTTON_BACK' | translate }}</button>
          <button class="btn btn-primary-green mr-1" type="button" (click)="home()">{{ 'MENU_NAVBAR_MENU_MAIN' | translate }}</button>
          <button class="btn btn-primary-green" type="button"
                  (click)="auditLog()"
                  *ngIf="errorAuditLogId !== ''
                         && rightModel.auditLogRead.hasRight()
                         && rightModel.webAdminAuditLogs.hasRight()">
            {{ 'SERVER_ERROR_VIEW_AUDIT_LOG' | translate }}
          </button>
          <button class="btn btn-primary-green" type="button"
                  (click)="forceReload()"
                  *ngIf="errorCode === '410'">
            {{ 'COMMON_BUTTON_REFRESH' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
