<div class="modal-content">
  <div class="modal-header">
    <p class="modal-title"><strong>{{'ICON_SELECTOR_DIALOG_TITLE' | translate}}</strong></p>
    <button type="button" class="close" (click)="cancelDialog()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row form-group">
      <div class="col-md-4">
        <label class="search-label">{{'ICON_SET' | translate}}</label>
        <angular2-multiselect
          [(ngModel)]="selectedIconSet"
          [data]="selectableSets"
          [settings]="dropdownSettings"
          (onSelect)="iconSetChanged()"
          (onDeSelect)="iconSetChanged()">
        </angular2-multiselect>
      </div>
    </div>
    <mat-dialog-content>
      <div class="row" *ngIf="selectedIconSet.length > 0">
        <div class="col-2 d-flex justify-content-start align-items-center flex-column"
             *ngFor="let item of selectableIcons">
          <app-type-icon [iconModel]="item"
                         big="true"
                         (click)="iconSelected(item)" [selected]="isSelected(item)"></app-type-icon>
          <p style="text-align: center; font-size: 0.5em;">{{item.text}}</p>
        </div>
      </div>
    </mat-dialog-content>

  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-outline-primary" (click)="closeDialog()">
      {{'COMMON_BUTTON_DELETE' | translate}}
    </button>
    <button type="button" class="btn btn-outline-primary" (click)="cancelDialog()">
      {{'COMMON_BUTTON_CANCEL' | translate}}
    </button>
    <button type="button" class="btn btn-primary" (click)="closeDialog(selectedIcon)">
      {{'COMMON_BUTTON_SAVE' | translate}}
    </button>
  </div>
</div>
