/* eslint-disable */
import { List } from 'immutable';
/* eslint-enable */

export class FieldWidthType {

  public static readonly SMALL = new FieldWidthType('FORM_FIELD_WIDTH_25', 25);
  public static readonly ONE_THIRD = new FieldWidthType('FORM_FIELD_WIDTH_33', 33);
  public static readonly MEDIUM = new FieldWidthType('FORM_FIELD_WIDTH_50', 50);
  public static readonly TWO_THIRD = new FieldWidthType('FORM_FIELD_WIDTH_66', 66);
  public static readonly LARGE = new FieldWidthType('FORM_FIELD_WIDTH_75', 75);
  public static readonly FULL_SCREEN = new FieldWidthType('FORM_FIELD_WIDTH_100', 100);

  public static readonly DEFAULT = FieldWidthType.MEDIUM;

  public static readonly VALUES: List<FieldWidthType> = List.of(
    FieldWidthType.SMALL,
    FieldWidthType.ONE_THIRD,
    FieldWidthType.MEDIUM,
    FieldWidthType.TWO_THIRD,
    FieldWidthType.LARGE,
    FieldWidthType.FULL_SCREEN
  );

  public static fromPercentValue(percentValue: number): FieldWidthType | undefined {
    const res = FieldWidthType.VALUES.toArray().filter((v) => {
      return v.percentValue === percentValue;
    });
    if (res.length < 1) {
      return undefined;
    }
    return res[0];
  }

  private constructor(
    public readonly textDictionaryKey: string,
    public readonly percentValue: number) {
  }

}
