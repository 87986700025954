import { AfterViewInit, Component, ContentChildren, ElementRef, Input, OnInit, QueryList, ViewChild } from '@angular/core';
import { NgForm, NgModel } from '@angular/forms';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'stepper-item',
  templateUrl: './stepper-item.component.html',
  styleUrls: ['./stepper-item.component.scss']
})
export class StepperItemComponent implements OnInit, AfterViewInit {

  @ViewChild('container', { static: true })
  private container: ElementRef;

  @ViewChild('form', { static: true })
  form: NgForm;

  @ViewChild('submitButton', { static: true })
  submitButton: ElementRef;

  @ContentChildren(NgModel, {descendants: true})
  private controls: QueryList<NgModel>;

  @Input()
  iconClass: string;

  @Input()
  title: string;

  @Input()
  additionalValidationRule?: () => boolean;

  constructor() { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.controls.forEach(c => this.form.addControl(c));
  }

  setVisibility(visible: boolean) {
    if (visible) {
      this.container.nativeElement.classList.remove('d-none');
    }
    else {
      this.container.nativeElement.classList.add('d-none');
    }
  }

  submit(): boolean {
    this.submitButton.nativeElement.click();
    if (this.additionalValidationRule) {
      return this.additionalValidationRule() && this.form.valid!;
    }
    return this.form.valid!;
  }

  get submitted(): boolean {
    return this.form.submitted;
  }

}
