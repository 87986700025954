// IDs
export interface EmptyMessage {
}

export interface IdentityMessage {
  id: number;
}

export interface CountMessage {
  total_number_of_items: number;
  current_number_of_items: number;
}

export interface DisableRequest extends IdentityMessage {
  disabled: boolean;
}

export namespace ListQuery {

  export interface QueryText {
    q?: string;
  }

  export interface Order {
    order?: string;
  }

  export interface Fields {
    fields?: string;
  }

  export interface Paging {
    page_number?: number;
    number_of_items?: number;
  }

}

export interface InterceptedRequest {
  missPermissionDenied?: boolean;
  missFunctionDisabled?: boolean;
}

/**
 * @deprecated
 */

export interface Identity {
  id: number;
}

/**
 * @deprecated
 */
export class Identities {

  public static compareById(l?: Identity, r?: Identity): number {
    if (l && r) {
      if (l.id < r.id) {
        return -1;
      }
      if (l.id > r.id) {
        return 1;
      }
      return 0;
    } else if (!l && !r) {
      return 0;
    } else if (!l) {
      return -1;
    } else {
      return 1;
    }
  }

  private Identities() {
  }

}

export class IdentityArray<T extends Identity> extends Array<T> {

}
