/* eslint-disable */
import { Injectable } from '@angular/core';
import { List, Map, Set } from 'immutable';
import { CustomerResource, CustomerResourceService, CustomerRoleResourceService } from './customer-resource.service';
import { Observable, Observer } from 'rxjs';
import { FieldValidationError, Order, PagingRequest, QueryResult, ResourceQueryResult, Services } from '../util/services';
import { ObservableErrorResourceParser } from '../util/errors';
import { OffsetDateTime } from '../util/dates';
import { Form } from '../form/form.service';
import { FormResource } from '../form/form-resource.service';
import { Icon, IconService } from '../task/icon.service';
import { DqlModel } from '../dql/dql.model';
import { DqlResourceModel } from '../dql/dql-resource.model';
import { EmptyMessage, IdentityMessage } from '../util/messages';
import { DqlStoredQuery, DqlStoredQueryService } from '../dql/dql-stored-query.service';
import { CustomerRecordFieldType } from '../../util/customer-record-utils';
import { TypeBasedRoleResource } from '../user-group.service';
import { LedgerNumber } from '../ledger/number/ledger-number.service';

/* eslint-enable */

@Injectable()
export class CustomerService implements Customer.Service, Form.Service, DqlStoredQuery.HolderService {
  private formMapper: Form.ResourceMapper;

  private _dqlStoredQueryService: DqlStoredQueryService;

  constructor(private resourceService: CustomerResourceService,
              private roleResourceService: CustomerRoleResourceService,
              private iconService: IconService) {
    this.formMapper = new Form.ResourceMapper();
    this._dqlStoredQueryService = new DqlStoredQueryService(resourceService.dqlStoredResourceService);
  }

  get dqlStoredQueryService(): DqlStoredQueryService {
    return this._dqlStoredQueryService;
  }

  // <editor-fold desc="CRUD">

  query(request: Customer.QueryRequest): Observable<QueryResult<Customer.Customer>> {
    return Observable.create((observer: Observer<QueryResult<Customer.Customer>>) => {
      const resourceRequest: CustomerResource.QueryRequest = {
        id: Services.createIdParameter(request.customerIdSet),
        disabled: request.disabled,
        name: request.name,
        types: request.types,
        contact_person: request.contactPerson,
        external_id: request.externalId,
        q: request.queryText,
        order: Services.createOrderFieldParameter(Keys.toOrderFieldKey, request.orders),
        page_number: request.paging ? request.paging.pageNumber : undefined,
        number_of_items: request.paging ? request.paging.numberOfItems : undefined,
        rights: Services.createListParameter(request.rights),
        no_progress_bar: request.noProgressBar
      };
      return this.resourceService.query(resourceRequest).subscribe(
        (result: ResourceQueryResult<CustomerResource.Customer>) => {
          observer.next({
            items: this.toPublicList(result.items),
            pagingResult: result.pagingResult
          });
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  get(request: Customer.GetRequest): Observable<Customer.Customer> {
    return Observable.create((observer: Observer<Customer.Customer>) => {
      const resourceRequest: CustomerResource.GetRequest = {
        customer_id: request.customerId,
        with_form: request.withForm,
        rights: Services.createListParameter(request.rights)
      };
      return this.resourceService.get(resourceRequest).subscribe(
        (result: CustomerResource.Customer) => {
          observer.next(this.toPublic(result));
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  create(request: Customer.CreateRequest): Observable<Customer.Customer> {
    return Observable.create((observer: Observer<Customer.Customer>) => {
      const resourceRequest: CustomerResource.CreateRequest = {
        name: request.name,
        complex_name: request.complexName,
        description: request.description,
        external_id: request.externalId,
        default_ledger_number: request.defaultLedgerNumber,
        icon: this.iconService.toResourceIcon(request.icon),
        type: request.type,
        mobile_owner_type: request.mobileOwnerType,
        web_owner_types: request.webOwnerTypes ? request.webOwnerTypes.toArray().map(o => {
          return {
            owner_type: o.ownerType,
            preset_user_ids: o.presetUserIds ? o.presetUserIds.toArray() : undefined,
            preset_user_group_ids: o.presetUserGroupIds ? o.presetUserGroupIds.toArray() : undefined,
          }
        }) : undefined,
        owner_user_group_ids: request.ownerUserGroupIds,
        use_default_managed_fields: request.useDefaultManagedFields,
        managed_fields: request.managedFields ? request.managedFields.toArray().map(f => <string>f) : undefined,
        required_fields: request.requiredFields.toArray().map(f => <string>f),
        explicit_order_number: request.explicitOrderNumber,
        contact_person: request.contactPerson
      };
      return this.resourceService.create(resourceRequest).subscribe(
        (result: CustomerResource.Customer) => {
          observer.next(this.toPublic(result));
        },
        (error: any) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  update(request: Customer.UpdateRequest): Observable<Customer.Customer> {
    return Observable.create((observer: Observer<Customer.Customer>) => {
      const resourceRequest: CustomerResource.UpdateRequest = {
        customer_id: request.customerId,
        name: request.name,
        complex_name: request.complexName,
        description: request.description,
        external_id: request.externalId,
        default_ledger_number: request.defaultLedgerNumber,
        icon: this.iconService.toResourceIcon(request.icon),
        type: request.type,
        mobile_owner_type: request.mobileOwnerType,
        web_owner_types: request.webOwnerTypes ? request.webOwnerTypes.toArray().map(o => {
          return {
            owner_type: o.ownerType,
            preset_user_ids: o.presetUserIds ? o.presetUserIds.toArray() : undefined,
            preset_user_group_ids: o.presetUserGroupIds ? o.presetUserGroupIds.toArray() : undefined,
          }
        }) : undefined,
        owner_user_group_ids: request.ownerUserGroupIds,
        use_default_managed_fields: request.useDefaultManagedFields,
        managed_fields: request.managedFields ? request.managedFields.toArray().map(f => <string>f) : undefined,
        required_fields: request.requiredFields.toArray().map(f => <string>f),
        explicit_order_number: request.explicitOrderNumber,
        contact_person: request.contactPerson
      };
      return this.resourceService.update(resourceRequest).subscribe(
        (result: CustomerResource.Customer) => {
          observer.next(this.toPublic(result));
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  setDisabled(request: Customer.DisableRequest): Observable<Customer.Customer> {
    return Observable.create((observer: Observer<Customer.Customer>) => {
      const resourceRequest: CustomerResource.DisableRequest = {
        customer_id: request.customerId,
        disabled: request.disabled
      };
      return this.resourceService.setDisabled(resourceRequest).subscribe(
        (result: CustomerResource.Customer) => {
          observer.next(this.toPublic(result));
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  getDqlModel(request: Customer.DqlRequest): Observable<DqlModel.QueryableModel> {
    return Observable.create((observer: Observer<DqlModel.QueryableModel>) => {
      return this.resourceService.getDqlModel({
        customer_id: request.customerId
      }).subscribe((result: DqlResourceModel.QueryableModel) => {
          observer.next(new DqlModel.DqlQueryableModelConverter(!request.customerId).toPublic(result));
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        }
      );
    });
  }

  // </editor-fold>

  // <editor-fold desc="Form">

  getForm(request: Form.GetFormRequest): Observable<Form.Form> {
    return Observable.create((observer: Observer<Form.Form>) => {
      const resourceRequest: FormResource.GetFormRequest =
        this.formMapper.toResourceGetFormRequest(request);
      return this.resourceService.getForm(resourceRequest).subscribe(
        (result: FormResource.Form) => {
          observer.next(this.formMapper.toPublicForm(result));
        },
        (error: Error) => {
          observer.error(this.formMapper.translateGetFormError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  createGroup(request: Form.CreateGroupRequest): Observable<Form.CreateGroupResponse> {
    return Observable.create((observer: Observer<Form.CreateGroupResponse>) => {
      const resourceRequest: FormResource.CreateGroupRequest =
        this.formMapper.toResourceCreateGroupRequest(request);
      return this.resourceService.createFormGroup(resourceRequest).subscribe(
        (result: FormResource.CreateGroupResponse) => {
          observer.next(this.formMapper.toPublicCreateGroupResponse(result));
        },
        (error: Error) => {
          observer.error(this.formMapper.translateCreateGroupError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  updateGroup(request: Form.UpdateGroupRequest): Observable<Form.UpdateGroupResponse> {
    return Observable.create((observer: Observer<Form.UpdateGroupResponse>) => {
      const resourceRequest: FormResource.UpdateGroupRequest =
        this.formMapper.toResourceUpdateGroupRequest(request);
      return this.resourceService.updateFormGroup(resourceRequest).subscribe(
        (result: FormResource.UpdateGroupResponse) => {
          observer.next(this.formMapper.toPublicUpdateGroupResponse(result));
        },
        (error: Error) => {
          observer.error(this.formMapper.translateUpdateGroupError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  disableGroup(request: Form.DisableGroupRequest): Observable<Form.DisableGroupResponse> {
    return Observable.create((observer: Observer<Form.DisableGroupResponse>) => {
      const resourceRequest: FormResource.DisableGroupRequest =
        this.formMapper.toResourceDisableGroupRequest(request);
      return this.resourceService.disableFormGroup(resourceRequest).subscribe(
        (result: FormResource.DisableGroupResponse) => {
          observer.next(this.formMapper.toPublicDisableGroupResponse(result));
        },
        (error: Error) => {
          observer.error(this.formMapper.translateDisableGroupError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  moveGroup(request: Form.MoveGroupRequest): Observable<Form.MoveGroupResponse> {
    return Observable.create((observer: Observer<Form.MoveGroupResponse>) => {
      const resourceRequest: FormResource.MoveGroupRequest =
        this.formMapper.toResourceMoveGroupRequest(request);
      return this.resourceService.moveFormGroup(resourceRequest).subscribe(
        (result: FormResource.MoveGroupResponse) => {
          observer.next(this.formMapper.toPublicMoveGroupResponse(result));
        },
        (error: Error) => {
          observer.error(this.formMapper.translateMoveGroupError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  createField(request: Form.CreateFieldRequest): Observable<Form.CreateFieldResponse> {
    return Observable.create((observer: Observer<Form.CreateFieldResponse>) => {
      const resourceRequest: FormResource.CreateFieldRequest =
        this.formMapper.toResourceCreateFieldRequest(request);
      return this.resourceService.createFormField(resourceRequest).subscribe(
        (result: FormResource.CreateFieldResponse) => {
          observer.next(this.formMapper.toPublicCreateFieldResponse(result));
        },
        (error: Error) => {
          observer.error(this.formMapper.translateCreateFieldError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  updateField(request: Form.UpdateFieldRequest): Observable<Form.UpdateFieldResponse> {
    return Observable.create((observer: Observer<Form.UpdateFieldResponse>) => {
      const resourceRequest: FormResource.UpdateFieldRequest =
        this.formMapper.toResourceUpdateFieldRequest(request);
      return this.resourceService.updateFormField(resourceRequest).subscribe(
        (result: FormResource.UpdateFieldResponse) => {
          observer.next(this.formMapper.toPublicUpdateFieldResponse(result));
        },
        (error: Error) => {
          observer.error(this.formMapper.translateUpdateFieldError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  disableField(request: Form.DisableFieldRequest): Observable<Form.DisableFieldResponse> {
    return Observable.create((observer: Observer<Form.DisableFieldResponse>) => {
      const resourceRequest: FormResource.DisableFieldRequest =
        this.formMapper.toResourceDisableFieldRequest(request);
      return this.resourceService.disableFormField(resourceRequest).subscribe(
        (result: FormResource.DisableFieldResponse) => {
          observer.next(this.formMapper.toPublicDisableFieldResponse(result));
        },
        (error: Error) => {
          observer.error(this.formMapper.translateDisableFieldError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  moveFieldToGroup(request: Form.MoveFieldToGroupRequest): Observable<Form.MoveFieldToGroupResponse> {
    return Observable.create((observer: Observer<Form.MoveFieldToGroupResponse>) => {
      const resourceRequest: FormResource.MoveFieldToGroupRequest =
        this.formMapper.toResourceMoveFieldToGroupRequest(request);
      return this.resourceService.moveFormFieldToGroup(resourceRequest).subscribe(
        (result: FormResource.MoveFieldToGroupResponse) => {
          observer.next(this.formMapper.toPublicMoveFieldToGroupResponse(result));
        },
        (error: Error) => {
          observer.error(this.formMapper.translateMoveFieldToGroupError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  importForm(request: Form.ImportRequest): Observable<Form.Form> {
    return Observable.create((observer: Observer<Form.Form>) => {
      return this.resourceService.import({
        parent_id: request.parentId,
        ignore_disabled_items: request.ignoreDisabledFields,
        form: request.form,
        version: request.version
      }).subscribe((result: FormResource.Form) => {
          observer.next(this.formMapper.toPublicForm(result));
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        }
      );
    });
  }

  exportForm(request: Form.ExportRequest): Observable<Form.FormImportDocument> {
    return Observable.create((observer: Observer<Form.FormImportDocument>) => {
      return this.resourceService.export({
        parent_id: request.parentId,
      }).subscribe((result: FormResource.FormImportDocument) => {
          observer.next({form: result.form, version: result.version});
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        }
      );
    });
  }

  counts(request: EmptyMessage): Observable<Customer.CustomerCount[]> {
    return Observable.create((observer: Observer<Customer.CustomerCount[]>) => {
      return this.resourceService.counts({}).subscribe(
        (result: CustomerResource.CustomerCount[]) => {
          observer.next(this.toPublicCountList(result));
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  getRoles(request: IdentityMessage): Observable<TypeBasedRoleResource[]> {
    return Observable.create((observer: Observer<TypeBasedRoleResource[]>) => {
      return this.roleResourceService.getRoles(request).subscribe(
        (result: TypeBasedRoleResource[]) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  createRole(request: TypeBasedRoleResource): Observable<IdentityMessage> {
    return Observable.create((observer: Observer<IdentityMessage>) => {
      return this.roleResourceService.createRole(request).subscribe(
        (result: IdentityMessage) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  updateRole(request: TypeBasedRoleResource): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      return this.roleResourceService.updateRole(request).subscribe(
        (result: EmptyMessage) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  deleteRole(request: TypeBasedRoleResource): Observable<EmptyMessage> {
    return Observable.create((observer: Observer<EmptyMessage>) => {
      return this.roleResourceService.deleteRole(request).subscribe(
        (result: EmptyMessage) => {
          observer.next(result);
        },
        (error: Error) => {
          observer.error(this.translateError(error));
        },
        () => {
          observer.complete();
        });
    });
  }

  // </editor-fold>

  // <editor-fold desc="Internal">

  private translateError(error: any): any {
    const res = ObservableErrorResourceParser.parseError(error);
    const fieldErrors = ObservableErrorResourceParser.extractFieldErrors(res);
    const fieldErrorMap = ObservableErrorResourceParser.toFieldErrorMap(Keys.toValidatedField, fieldErrors);
    if (!fieldErrorMap.isEmpty()) {
      return FieldValidationError.of(fieldErrorMap);
    }
    return error;
  }

  private toPublicList(resourceList: CustomerResource.Customer[]): List<Customer.Customer> {
    return List.of(...resourceList.map((r) => this.toPublic(r)));
  }

  private toPublic(r: CustomerResource.Customer): Customer.Customer {
    return {
      customerId: r.id,
      disabled: r.disabled,
      creationTime: Services.toOffsetDateTime(r.creation_time),
      updateTime: Services.toOffsetDateTime(r.update_time),
      name: r.name,
      complexName: r.complex_name,
      description: r.description,
      externalId: r.external_id,
      defaultLedgerNumber: r.default_ledger_number ? LedgerNumber.toPublic(r.default_ledger_number) : undefined,
      icon: this.iconService.toPublicIcon(r.icon),
      type: <Customer.CustomerType>r.type,
      mobileOwnerType: <Customer.CustomerOwnerType>r.mobile_owner_type,
      webOwnerTypes: r.web_owner_types ? Set.of(...r.web_owner_types.map(o => {
        return {
          ownerType: <Customer.CustomerOwnerType>o.owner_type,
          presetUserIds: o.preset_user_ids ? Set.of(...o.preset_user_ids) : undefined,
          presetUserGroupIds: o.preset_user_group_ids ? Set.of(...o.preset_user_group_ids) : undefined,
        }
      })) : undefined,
      ownerUserGroupIds: r.owner_user_group_ids ? r.owner_user_group_ids : [],
      useDefaultManagedFields: r.use_default_managed_fields,
      managedFields: Set.of(...r.managed_fields.map(f => <CustomerRecordFieldType>f)),
      requiredFields: Set.of(...r.required_fields.map(f => <CustomerRecordFieldType>f)),
      form: r.form ? this.formMapper.toPublicForm(r.form) : undefined,
      explicitOrderNumber: r.explicit_order_number,
      contactPerson: r.contact_person,
      grantedRights: r.granted_rights ? Set.of(...r.granted_rights) : Set.of(),
    };
  }

  private toPublicCountList(resList: CustomerResource.CustomerCount[]): Customer.CustomerCount[] {
    return resList.map((s) => this.toPublicCount(s));
  }

  private toPublicCount(r: CustomerResource.CustomerCount): Customer.CustomerCount {
    return {
      customerId: r.customer_id,
      customerName: r.customer_name,
      customerIcon: this.iconService.toPublicIcon(r.customer_icon),
      customerRecordCount: r.customer_record_count
    };
  }

  // </editor-fold>

}

export namespace Customer {

  export interface Service {

    // <editor-fold desc="CRUD">

    query(request: QueryRequest): Observable<QueryResult<Customer>>;

    get(request: GetRequest): Observable<Customer>;

    create(request: CreateRequest): Observable<Customer>;

    update(request: UpdateRequest): Observable<Customer>;

    setDisabled(request: DisableRequest): Observable<Customer>;

    // </editor-fold>

  }

  // <editor-fold desc="CRUD">

  export interface Customer {
    customerId: number;
    disabled: boolean;
    creationTime: OffsetDateTime;
    updateTime: OffsetDateTime;
    name: string;
    complexName: boolean;
    description: string;
    externalId?: string;
    defaultLedgerNumber?: LedgerNumber.LedgerNumber;
    icon?: Icon.Icon;
    type: CustomerType;
    mobileOwnerType: CustomerOwnerType;
    webOwnerTypes?: Set<WebOwnerType>;
    ownerUserGroupIds: number[];
    useDefaultManagedFields: boolean;
    managedFields: Set<CustomerRecordFieldType>;
    requiredFields: Set<CustomerRecordFieldType>;
    form?: Form.Form;
    explicitOrderNumber?: number;
    contactPerson: boolean;
    grantedRights: Set<string>;
  }

  export interface QueryRequest {
    customerIdSet?: Set<number>;
    disabled?: boolean;
    name?: string;
    externalId?: string;
    types?: CustomerType;
    contactPerson?: boolean;
    queryText?: string;
    orders?: Set<Order<OrderField>>;
    paging?: PagingRequest;
    rights?: Set<string>;
    noProgressBar?: boolean;
  }

  export interface GetRequest {
    customerId: number;
    withForm?: boolean;
    rights?: Set<string>;
  }

  export interface DqlRequest {
    customerId?: number;
  }

  export interface CreateRequest {
    name: string;
    complexName: boolean;
    description?: string;
    externalId?: string;
    defaultLedgerNumber?: number;
    icon?: Icon.Icon;
    type: CustomerType;
    mobileOwnerType: CustomerOwnerType;
    webOwnerTypes?: Set<WebOwnerType>;
    ownerUserGroupIds: number[];
    useDefaultManagedFields: boolean;
    managedFields?: Set<CustomerRecordFieldType>;
    requiredFields: Set<CustomerRecordFieldType>;
    explicitOrderNumber?: number;
    contactPerson: boolean;
  }

  export interface UpdateRequest {
    customerId: number;
    name: string;
    complexName: boolean;
    description?: string;
    externalId?: string;
    defaultLedgerNumber?: number;
    icon?: Icon.Icon;
    type: CustomerType;
    mobileOwnerType: CustomerOwnerType;
    webOwnerTypes?: Set<WebOwnerType>;
    ownerUserGroupIds: number[];
    useDefaultManagedFields: boolean;
    managedFields?: Set<CustomerRecordFieldType>;
    requiredFields: Set<CustomerRecordFieldType>;
    explicitOrderNumber?: number;
    contactPerson: boolean;
  }

  export interface DisableRequest {
    customerId: number;
    disabled: boolean;
  }

  export interface CustomerCount {
    customerId: number;
    customerName: string;
    customerIcon?: Icon.Icon;
    customerRecordCount: number;
  }

  export interface WebOwnerType {
    ownerType: CustomerOwnerType;
    presetUserIds?: Set<number>;
    presetUserGroupIds?: Set<number>;
  }

  export enum OrderField {
    ID,
    DISABLED,
    CREATION_TIME,
    UPDATE_TIME,
    NAME,
    DESCRIPTION,
    TYPE,
    EXPLICIT_ORDER_NUMBER
  }

  export enum ValidatedField {
    UNKNOWN,
    NAME,
    TYPE,
    COMPLEX_NAME,
    CONTACT_PERSON,
    EMAIL_ADDRESS_REQUIRED,
    MOBILE_OWNER_TYPE,
    WEB_OWNER_TYPE,
    OWNER_USER_GROUPS,
    EXTERNAL_ID,
    EXPLICIT_ORDER_NUMBER,
    DEFAULT_LEDGER_NUMBER,
  }

  export type CustomerType = 'PERSON' | 'COMPANY';

  export class CustomerTypeObject {
    type: CustomerType;
    stringKey: string;
  }

  export const customerTypes: CustomerTypeObject[] = [{
    type: 'PERSON',
    stringKey: 'CUSTOMER_TABLE_TYPE_PERSON'
  },
    {
      type: 'COMPANY',
      stringKey: 'CUSTOMER_TABLE_TYPE_COMPANY'
    }];

  export type CustomerOwnerType = 'CURRENT_USER' | 'CURRENT_USER_GROUP' | 'PRESET_USER' | 'PRESET_USER_GROUP';

  export class CustomerMobileOwnerTypeObject {
    type: CustomerOwnerType;
    stringKey: string;
  }

  export const customerMobileOwnerTypes: CustomerMobileOwnerTypeObject[] = [
    {
      type: 'CURRENT_USER',
      stringKey: 'CUSTOMER_OWNER_TYPE_CURRENT_USER'
    },
    {
      type: 'CURRENT_USER_GROUP',
      stringKey: 'CUSTOMER_OWNER_TYPE_CURRENT_USER_GROUP'
    }];

  export const customerWebOwnerTypes: CustomerMobileOwnerTypeObject[] = [
    {
      type: 'CURRENT_USER',
      stringKey: 'CUSTOMER_OWNER_TYPE_CURRENT_USER'
    },
    {
      type: 'CURRENT_USER_GROUP',
      stringKey: 'CUSTOMER_OWNER_TYPE_CURRENT_USER_GROUP'
    },
    {
      type: 'PRESET_USER',
      stringKey: 'CUSTOMER_OWNER_TYPE_PRESET_USER'
    },
    {
      type: 'PRESET_USER_GROUP',
      stringKey: 'CUSTOMER_OWNER_TYPE_PRESET_USER_GROUP'
    }];
  // </editor-fold>

  export type CustomerTypeForSearch = 'PERSON' | 'COMPANY' | 'ALL';

  export class CustomerTypeForSearchObject {
    type: CustomerTypeForSearch;
    stringKey: string;
  }

  export const CustomerTypesForSearch: CustomerTypeForSearchObject[] = [{
    type: 'PERSON',
    stringKey: 'CUSTOMER_TABLE_TYPE_PERSON'
  },
    {
      type: 'COMPANY',
      stringKey: 'CUSTOMER_TABLE_TYPE_COMPANY'
    },
    {
      type: 'ALL',
      stringKey: 'CUSTOMER_SEARCH_FIELD_DISABLED_ALL'
    }];

  export type CustomerDisabled =
    'ALL' |
    'ACTIVE' |
    'INACTIVE';

}

// <editor-fold desc="Internal">

class Keys {

  private static readonly ID = 'id';
  private static readonly DISABLED = 'disabled';
  private static readonly CREATION_TIME = 'creation_time';
  private static readonly UPDATE_TIME = 'update_time';
  private static readonly NAME = 'name';
  private static readonly COMPLEX_NAME = 'complex_name';
  private static readonly CONTACT_PERSON = 'contact_person';
  private static readonly DESCRIPTION = 'description';
  private static readonly TYPE = 'type';
  private static readonly EMAIL_ADDRESS_REQUIRED = 'email_address_required';
  private static readonly MOBILE_OWNER_TYPE = 'mobile_owner_type';
  private static readonly WEB_OWNER_TYPE = 'web_owner_type';
  private static readonly OWNER_USER_GROUPS = 'owner_user_group_ids';
  private static readonly EXTERNAL_ID = 'external_id';
  private static readonly EXPLICIT_ORDER_NUMBER = 'explicit_order_number';
  private static readonly DEFAULT_LEDGER_NUMBER = 'default_ledger_number';

  private static readonly orderFieldKeyMap: Map<Customer.OrderField, string> = Map.of(
    Customer.OrderField.ID, Keys.ID,
    Customer.OrderField.DISABLED, Keys.DISABLED,
    Customer.OrderField.CREATION_TIME, Keys.CREATION_TIME,
    Customer.OrderField.UPDATE_TIME, Keys.UPDATE_TIME,
    Customer.OrderField.NAME, Keys.NAME,
    Customer.OrderField.DESCRIPTION, Keys.DESCRIPTION,
    Customer.OrderField.EXPLICIT_ORDER_NUMBER, Keys.EXPLICIT_ORDER_NUMBER,
  );

  private static readonly keyValidatedFieldMap: Map<string, Customer.ValidatedField> = Map.of(
    Keys.NAME, Customer.ValidatedField.NAME,
    Keys.EXTERNAL_ID, Customer.ValidatedField.EXTERNAL_ID,
    Keys.TYPE, Customer.ValidatedField.TYPE,
    Keys.MOBILE_OWNER_TYPE, Customer.ValidatedField.MOBILE_OWNER_TYPE,
    Keys.WEB_OWNER_TYPE, Customer.ValidatedField.WEB_OWNER_TYPE,
    Keys.OWNER_USER_GROUPS, Customer.ValidatedField.OWNER_USER_GROUPS,
    Keys.COMPLEX_NAME, Customer.ValidatedField.COMPLEX_NAME,
    Keys.CONTACT_PERSON, Customer.ValidatedField.CONTACT_PERSON,
    Keys.EMAIL_ADDRESS_REQUIRED, Customer.ValidatedField.EMAIL_ADDRESS_REQUIRED,
    Keys.EXPLICIT_ORDER_NUMBER, Customer.ValidatedField.EXPLICIT_ORDER_NUMBER,
    Keys.DEFAULT_LEDGER_NUMBER, Customer.ValidatedField.DEFAULT_LEDGER_NUMBER,
  );

  public static toOrderFieldKey(field: Customer.OrderField): string {
    return Keys.orderFieldKeyMap.get(field)!;
  }

  public static toValidatedField(fieldKey: string): Customer.ValidatedField {
    return Keys.keyValidatedFieldMap.get(fieldKey, Customer.ValidatedField.UNKNOWN);
  }

}

// </editor-fold>
