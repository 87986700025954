<app-loading-spinner *ngIf="loading"></app-loading-spinner>
<div class="container">
  <div class="language-selector">
    <div class="d-flex justify-content-end">
      <a class="id-text cursor-pointer ml-half"
         href="#" onclick="return false"
         uiSref="Login"
         [uiOptions]="{custom: {fromHelpdesk: true}}"
         title="{{'MENU_NAVBAR_BRAND' | translate}}">
        {{'MENU_NAVBAR_BRAND' | translate}}
      </a>
    <ng-container *ngFor="let language of languages">
      <div class="cursor-pointer ml-1"
           *ngIf="!isCurrentLanguage(language)"
           (click)="changeLanguage(language)"
           title="{{language.nativeName}}">
        <img
          src="../../assets/img/language-code-icon/{{language.localeCode.substr(0,2)}}.png"
          class="language-image">
      </div>
    </ng-container>
    </div>
  </div>
  <div class="intro-container">
    <div class="intro-company-image" (click)="onNavigate()"></div>
    <i class="icomoon icomoon-helpdesk-icon intro-icon"></i>
    <div class="intro-title">
      {{(state === State.REGISTRATION ? 'HELPDESK_REGISTRATION_TITLE' : 'HELPDESK_LOGIN_TITLE') | translate}}
    </div>
    <div class="intro-subtitle">
      {{
      (state === State.REGISTRATION
        ? 'HELPDESK_REGISTRATION_SUBTITLE'
        : 'HELPDESK_LOGIN_SUBTITLE') | translate:{environment: environmentName} }}
    </div>
    <div class="intro-image"></div>
  </div>
  <div class="login-container" [class.login-out]="state !== State.LOGIN">
    <div class="card">
      <app-helpdesk-login
        [model]="loginModel"
        (onSubmit)="login()">
      </app-helpdesk-login>
    </div>
  </div>
  <div class="registration-container" [class.registration-out]="state !== State.REGISTRATION">
    <div class="card">
      <app-helpdesk-registration
        [model]="registrationModel"
        (onSubmit)="register()"
        (toLogin)="toLogin()">
      </app-helpdesk-registration>
    </div>
  </div>
</div>

<toaster-container [toasterconfig]="toasterConfig"></toaster-container>

<div bsModal [config]="UiConstants.modalConfig" #loginMethodSelectDialog="bs-modal" class="modal fade" tabindex="-1" role="dialog"
     aria-labelledby="myModalLabel"
     aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document" *ngIf="loginMethodSelectDialogVisible">
    <div class="modal-content">
      <div class="modal-header">
        <p class="modal-title"><strong>{{'LOGIN_METHOD' | translate}}</strong></p>
        <button type="button" class="close" (click)="closeLoginMethodSelectDialog()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row m-0 justify-content-center">
          <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12 p-0 mx-1"
               *ngFor="let loginMethod of loginMethods">
            <div class="create-card card card-hover-border cursor-pointer fixed-card-height"
                 (click)="loginWithMethod(loginMethod)">
              <div class="card-body">
                <div class="h1 mb-0 d-flex justify-content-center">
                  <i class="icomoon" [ngClass]="loginMethod.getIconClass()"></i>
                </div>
                <div><p class="mb-0 text-center">{{loginMethod.getName()}}</p></div>
              </div>
            </div>
          </div>
        </div>
      </div><!-- /.modal-body -->
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div><!-- /.modal -->
