/* eslint-disable */
import { AfterViewInit, Component } from '@angular/core';
import { UIRouter } from '@uirouter/angular';
import { TranslateService } from '@ngx-translate/core';
import { StateName } from '../../../../app.state-names';
import { DocumentHTMLService } from '../../../../lib/document/document-html.service';
import { Arrays } from '../../../../lib/util/arrays';
import { DocumentLanguageUtil } from '../../../../util/document/document-language-utils';
import { DocumentLanguageService } from '../../../../lib/document/document-language.service';
import { DocumentGroupService } from '../../../../lib/document/document-group.service';
import { DocumentGroup } from '../../../../lib/document/document-group.service';
import { DocumentGroupItem } from '../../../../util/document/document-file-utils';
import { UiConstants } from '../../../../util/core-utils';
import { ResourceQueryResult } from '../../../../lib/util/services';
import { DocumentHTMLCreateModel, DocumentLanguageItem } from '../../../../util/document/document-utils';
import { EmptyMessage } from '../../../../lib/util/messages';
/* eslint-enable */

const ClassicEditor = require('@ckeditor/ckeditor5-build-classic');

@Component({
  selector: 'app-document-html-create',
  templateUrl: 'document-html-create.component.html',
  styleUrls: ['document-html-create.component.scss']
})
export class DocumentHTMLCreateComponent implements AfterViewInit {

  model: DocumentHTMLCreateModel;
  uploadGlobalErrors: string;
  uploadCollectionFieldErrors: string;
  documentGroupList: DocumentGroupItem[] = [];
  documentLanguageList: DocumentLanguageItem[] = [];
  editor: any;

  constructor(
    private documentGroupService: DocumentGroupService,
    private documentLanguageService: DocumentLanguageService,
    private documentLanguageUtil: DocumentLanguageUtil,
    private documentService: DocumentHTMLService,
    private translateService: TranslateService,
    private uiRouter: UIRouter) {
    this.model = new DocumentHTMLCreateModel();
  }

  private validate(): boolean {
    if (!this.model.code || this.model.code.length < 1) {
      return false
    }
    return true;
  }

  ngAfterViewInit(): void {
    this.loadDefaultDocumentGroup(() => {
      this.loadDocumentGroups(() => {
        this.loadDocumentLanguageList(() => {
        });
      });
    });

    ClassicEditor.create(document.querySelector('#editor'), {
      toolbar: ['headings', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote'],
      heading: {
        options: [
          {modelElement: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph'},
          {modelElement: 'heading4', viewElement: 'h4', title: 'Heading', class: 'ck-heading_heading4'}
        ]
      }
    }).then(editor => {
      this.editor = editor;
    });
  }

  loadDefaultDocumentGroup(completion: () => void) {
    this.translateService.get('DOCUMENT_GROUP_FILTER_ALL').subscribe(
      (text: string) => {
        const allGroup: DocumentGroupItem = {
          id: null,
          text: text
        };
        this.model.document_group = allGroup;
        this.documentGroupList.push(allGroup);
        completion();
      }
    );
  }

  loadDocumentGroups(completion: () => void) {
    const arrayTemp: DocumentGroup[] = [];

    this.documentGroupService.query({}).subscribe((result: ResourceQueryResult<DocumentGroup>) => {

      result.items.forEach(group => {
        arrayTemp.push(group);
      });

      const sortedArray = arrayTemp.sort((n1, n2) => {
        if (n1.name > n2.name) {
          return 1;
        }

        if (n1.name < n2.name) {
          return -1;
        }

        return 0;
      });
      sortedArray.forEach(group => {
        const groupItem = {
          id: group.id,
          text: group.name
        };
        this.documentGroupList.push(groupItem);
      });
      completion();

    }, (error: any) => {
      completion();
    });

  }

  getDocumentGroupName(documentGroupId: number | null): string {
    let groupName = '';
    if (!documentGroupId) {
      return groupName;
    }
    Arrays.iterateByIndex(this.documentGroupList, (userGroup) => {
      if (userGroup.id === documentGroupId) {
        groupName = userGroup.text;
      }
    });

    return groupName;
  }

  private loadDocumentLanguageList(completion: () => void) {
    this.documentLanguageService.query().subscribe(
      (result: string[]) => {
        const def = this.documentLanguageUtil.createDefaultLanguageCode();
        this.model.language_code = def;
        this.documentLanguageList.push(def);
        Arrays.iterateByIndex(result, language => {
          const languageItem = new DocumentLanguageItem();
          languageItem.id = language;
          languageItem.text = this.documentLanguageUtil.getDocumentLanguageName(language);
          this.documentLanguageList.push(languageItem);
        });
        completion();

      }, (error: any) => {
        completion();
      });
  }

  update() {
    if (!this.validate()) {
      return;
    }
    this.model.value = this.editor.data.get();
    const languageCodeId = (this.model.language_code) ? this.model.language_code.id : null;
    const document_group_id = (this.model.document_group) ? this.model.document_group.id : null;

    this.documentService.create({
      code: this.model.code,
      name: this.model.name,
      value: this.model.value,
      language_code: languageCodeId ? languageCodeId : undefined,
      document_group_id: document_group_id ? document_group_id : undefined,
      comment: this.model.comment

    }).subscribe(
      (response: EmptyMessage) => {
        this.uiRouter.stateService.go(StateName.DOCUMENT_HTML_LIST);
      }
    );
  }

  back() {
    window.history.back();
  }

  getTextMaximumLength(): number {
    return UiConstants.maximumVarcharLength;
  }

}
