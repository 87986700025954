<div class="breadcrumb-container" [ngClass]="{ 'breadcrumb-container-compact': compactSidebar }" id="breadcrumb">
  <div class="breadcrumb-component">
    <app-breadcrumb
      [parents]="breadcrumbParents"
      [self]="breadcrumbSelf">
    </app-breadcrumb>
  </div>
  <div class="breadcrumb-menu d-flex">
    <div class="breadcrumb-button-icon-container cursor-pointer"
         (click)="openImportDialog()">
      <a class="btn-setting d-flex align-items-center" title="{{'PERMISSION_IMPORT' | translate}}">
        <i class="icomoon icomoon-csv-import"></i>
        <span class="breadcrumb-button-text-visible">{{'PERMISSION_IMPORT' | translate}}</span>
      </a>
    </div>
    <div class="breadcrumb-button-icon-container cursor-pointer negative-margin-right"
         (click)="exportPrivileges()">
      <a class="btn-setting d-flex align-items-center" title="{{'PERMISSION_EXPORT' | translate}}">
        <i class="icomoon icomoon-csv-export"></i>
        <span class="breadcrumb-button-text-visible">{{'PERMISSION_EXPORT' | translate}}</span>
      </a>
    </div>
  </div>
</div>
<div class="breadcrumb-placeholder"></div>
<div class="container-vertical-padding container-horizontal-padding animated fadeIn col-md-12 col-sm-12 col-xs-12">
  <div class="card">
    <div class="card-header">
      {{'ROLE_EDIT_HEADING' | translate}}
    </div>
    <div class="card-body">
      <form class="form-horizontal" (ngSubmit)="f.form.valid && update()" #f="ngForm" novalidate>
        <div class="form-group row"
             [ngClass]="{ 'has-danger': hasFieldError(Role.ValidatedField.NAME) || (f.submitted && !name.valid) }">
          <label class="col-md-2 col-form-label">{{'ROLE_CREATE_NAME' | translate}}</label>
          <div class="col-md-10 row pr-1">
            <input class="form-control" type="text" placeholder="{{'ROLE_CREATE_NAME' | translate}}"
                   [ngClass]="{ 'form-control-danger': hasFieldError(Role.ValidatedField.NAME) || (f.submitted && !name.valid) }"
                   [(ngModel)]="model.name" (ngModelChange)="removeFieldError(Role.ValidatedField.NAME)"
                   name="name" id="name" #name="ngModel" required>
            <div class="form-control-feedback" *ngIf="hasFieldError(Role.ValidatedField.NAME)">
              {{getFieldErrorText(Role.ValidatedField.NAME)}}
            </div>
            <div class="form-control-feedback" *ngIf="f.submitted && !name.valid">
              {{'COMMON_VALIDATION_MESSAGE_REQUIRED' | translate}}
            </div>
          </div>
        </div>
        <div class="form-group row mr-0">
          <label class="col-md-2 col-form-label">{{'ROLE_CREATE_PERMISSIONS' | translate}}</label>
          <div class="col-md-10 pl-0 pr-1">
            <app-two-way-tree-multiselect
              [selectedTreeTitleStringKey]="StringKey.ROLE_CREATE_UPDATE_SELECTED_ROLES"
              [unselectedTreeTitleStringKey]="StringKey.ROLE_CREATE_UPDATE_AVAILABLE_ROLES"
              #permissionSelector>
            </app-two-way-tree-multiselect>
          </div>
        </div>
        <div class="col-md-12 d-flex justify-content-end p-0 pl-1 pr-1">
          <div>
            <input type="submit" class="btn btn-primary" value="{{'COMMON_BUTTON_SAVE'|translate}}"/>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>

<div bsModal [config]="UiConstants.modalConfig" #importDialog="bs-modal" class="modal fade" tabindex="-1" role="dialog"
     aria-labelledby="myModalLabel"
     aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document" *ngIf="importDialogVisible">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">{{'PERMISSION_IMPORT' | translate}}</h4>
        <button type="button" class="close" (click)="closeImportDialog()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="col-sm-12 p-0">
          <app-file-upload
            #fileUploadComponent
            [uploadPath]="''"
            [uploadOnDrop]="false"
            (onFileDropped)="onImportablePrivilegesSelected($event)">
          </app-file-upload>
        </div>
        <div class="col-sm-12" *ngIf="privilegeImportModel.importResult">
          <div class="alert alert-success" *ngIf="privilegeImportModel.importResult === 'SUCCESS'">
            <h4 class="alert-title">{{'ALERT_TEXT_UPLOAD_SUCCESSFUL' | translate}}</h4>
            <p>{{'ALERT_TEXT_SUCCESSFULLY_UPLOADED' | translate}}</p>
          </div>
          <div class="alert alert-danger" *ngIf="privilegeImportModel.importResult === 'ERROR'">
            <h4 class="alert-title">{{'ALERT_TEXT_UPLOAD_FAILED' | translate}}</h4>
            <p>{{uploadGlobalErrors}}</p>
          </div>
          <div class="alert alert-danger" *ngIf="privilegeImportModel.importResult === 'FORMAT_ERROR'">
            <h4 class="alert-title">{{'ALERT_TEXT_FILE_ERROR' | translate}}</h4>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="closeImportDialog()">
          {{'COMMON_BUTTON_CANCEL' | translate}}
        </button>
        <button type="button" class="btn btn-primary" (click)="importPrivileges()"
                [disabled]="privilegeImportModel.importablePrivileges.length === 0">{{'COMMON_BUTTON_IMPORT' |
          translate}}
        </button>
      </div>
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div><!-- /.modal -->


