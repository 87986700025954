import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Angular2Multiselects } from '../../../util/multiselect';
import { Icon, IconService } from '../../../lib/task/icon.service';
import { MultiselectOptionItem } from '../../../util/core-utils';
import IconModel = Icon.IconModel;

@Component({
  selector: 'app-icon-selector-dialog',
  templateUrl: './icon-selector-dialog.component.html',
  styleUrls: ['./icon-selector-dialog.component.scss']
})
export class IconSelectorDialogComponent implements OnInit {

  dropdownSettings: Angular2Multiselects.Settings;

  selectedIcon?: Icon.IconModel;
  selectableIcons: Icon.IconModel[] = [];

  selectedIconSet: MultiselectOptionItem<string>[] = [];
  selectableSets: MultiselectOptionItem<string>[] = [];

  private _previousSelectedIcon?: Icon.Icon;


  constructor(public dialogRef: MatDialogRef<IconSelectorDialogComponent, IconSelectorDialogResult>,
              private translateService: TranslateService,
              private iconService: IconService,
              @Inject(MAT_DIALOG_DATA) public data: IconSelectorDialogData) {
    this.selectedIcon = IconModel.fromServiceIcon(data.previousSelectedIcon);
    this._previousSelectedIcon = data.previousSelectedIcon;
  }

  ngOnInit() {
    this.initDropdownSettings();
    this.loadIconSets();
  }

  initDropdownSettings() {
    this.dropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(true)
      .enableSearchFilter(true)
      .enableCheckAll(false)
      .remoteSearch(false)
      .translate(true)
      .build();
  }

  private loadIconSets() {
    this.iconService.getAvailableIconSetOptions().subscribe(s => {
      this.selectableSets = s;
      if (this.selectedIcon?.id) {
        const set = this.selectableSets.find(ss => ss.id === this.selectedIcon?.setCode);
        if (set) {
          this.selectedIconSet = [];
          this.selectedIconSet.push(set);
          this.iconSetChanged();
        }
      }
      else {
        this.selectedIconSet = [this.selectableSets[0]];
        this.iconSetChanged();
      }
    });
  }

  cancelDialog() {
    this.dialogRef.close({
      selectedIcon: this._previousSelectedIcon
    })
  }

  closeDialog(selectedIcon?: Icon.IconModel) {
    this.dialogRef.close({
      selectedIcon: IconModel.toServiceIcon(selectedIcon)
    });
  }

  iconSetChanged() {
    this.selectableIcons = [];
    if (this.selectedIconSet.length > 0) {
      const iconSet = this.iconService.getIconSet(this.selectedIconSet[0].id);
      this.selectableIcons = this.iconService.toIconModelList(iconSet);
      if (this.selectedIcon?.setCode !== iconSet?.iconSetCode) {
        this.selectedIcon = undefined;
      }
    }
  }

  iconSelected(item: Icon.IconModel) {
    this.selectedIcon = item;
  }

  isSelected(item: Icon.IconModel) {
    return this.selectedIcon && this.selectedIcon.id === item.id;
  }

}

export interface IconSelectorDialogData {
  previousSelectedIcon?: Icon.Icon;
}

export interface IconSelectorDialogResult {
  selectedIcon?: Icon.Icon;
}
