import { Component, OnInit } from '@angular/core';
import { RightModel } from '../../../app.rights';
import { AppworksDashboardCustomerModel } from '../../../util/appworks-utils';
import { RightService } from '../../../lib/right.service';
import { TranslateService } from '@ngx-translate/core';
import { UIRouter } from '@uirouter/angular';
import { Icon } from '../../../lib/task/icon.service';
import { Customer, CustomerService } from '../../../lib/customer/customer.service';
import { Set } from 'immutable';
import { StateName } from '../../../app.state-names';

@Component({
  selector: 'app-customer-dashboard',
  templateUrl: './customer-dashboard.component.html',
  styleUrls: ['./customer-dashboard.component.scss']
})
export class CustomerDashboardComponent implements OnInit {

  rightModel: RightModel = RightModel.empty();

  customerCount: number = 0;

  customerRecordGlobalCount: number = 0;

  emptyContent: boolean = true;

  dashboardCustomerModels: AppworksDashboardCustomerModel[] = [];

  breadcrumbSelf: string;
  compactSidebar: boolean = document.querySelector('body')!.classList.contains('sidebar-compact');

  constructor(private rightService: RightService,
              private uiRouter: UIRouter,
              private translateService: TranslateService,
              private customerService: CustomerService) {
  }

  ngOnInit() {
    this.init();
    this.translateService.get('COMMON_CUSTOMERS').subscribe(
      (result: string) => {
        this.breadcrumbSelf = result;
      }
    );
  }

  init() {
    this.loadCustomers();
  }

  loadCustomers() {
    this.customerService.counts({}).subscribe((counts: Customer.CustomerCount[]) => {
        if (counts.length === 0) {
          this.uiRouter.stateService.go(StateName.CUSTOMER_RECORD_GLOBAL_LIST);
        }
        else if (counts.length === 1) {
          this.uiRouter.stateService.go(StateName.CUSTOMER_RECORD_LIST, {customerId: counts[0].customerId});
        }
        this.customerCount = counts.length;
        if (this.emptyContent) {
          this.emptyContent = this.emptyContent && this.customerCount <= 1;
        }
        const customerIds = Set.of(...counts.map(customer => customer.customerId));
        this.customerRecordGlobalCount = 0;
        counts.forEach(customer => {
          const dashboardCustomerModel = new AppworksDashboardCustomerModel();
          dashboardCustomerModel.customerId = customer.customerId;
          dashboardCustomerModel.name = customer.customerName;
          dashboardCustomerModel.icon = customer.customerIcon;
          const customerCounts = counts.find((c) => c.customerId === customer.customerId);
          const customerRecordCount = customerCounts ? customerCounts.customerRecordCount : 0;
          dashboardCustomerModel.customerRecordCount = customerRecordCount;
          this.customerRecordGlobalCount += customerRecordCount;
          this.dashboardCustomerModels.push(dashboardCustomerModel);
        });
      },
      () => {
      });
  }

  getCustomerIconFromId(customerId: number): Icon.Icon | undefined {
    let icon: Icon.Icon | undefined = undefined;
    this.dashboardCustomerModels.forEach(dashboardCustomerModel => {
      if (dashboardCustomerModel.icon && dashboardCustomerModel.customerId === customerId) {
        icon = dashboardCustomerModel.icon;
      }
    });
    return icon;
  }

  getCustomerRecordCount(count?: number): number {
    if (count) {
      return count;
    }
    else {
      return 0;
    }
  }

}
