<div bsModal [config]="UiConstants.modalConfig" #deleteSubTaskDialog="bs-modal" class="modal fade" tabindex="-1" role="dialog"
     aria-labelledby="myModalLabel"
     aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document" *ngIf="deleteSubTaskDialogVisible">
    <div class="modal-content">
      <div class="modal-header">
        <p class="modal-title"><strong>{{'TASK_RECORD_SUB_TASK_DELETE' | translate}}</strong></p>
        <button type="button" class="close" (click)="closeDeleteSubTaskDialog()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div>
          <label>{{'TASK_RECORD_SUB_TASK_DELETE_MESSAGE' | translate}}</label>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-primary" (click)="closeDeleteSubTaskDialog()">
          {{'COMMON_BUTTON_CANCEL' | translate}}
        </button>
        <button type="button" class="btn btn-primary" (click)="deleteSubTask()">{{'COMMON_BUTTON_DELETE' |
            translate}}
        </button>
      </div>
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div><!-- /.modal -->
