/* eslint-disable */
import { Component, OnInit, ViewChild } from '@angular/core';
import { InputMask } from '../../../util/input-masks';
import { NgForm } from '@angular/forms';
import { FieldValidationError } from '../../../lib/util/services';
import { BreadcrumbParent } from '../../../shared/breadcrumb/breadcrumb/breadcrumb.component';
import { TranslateService } from '@ngx-translate/core';
import { StateName } from '../../../app.state-names';
import { LoadingHandler } from '../../../lib/loading-handler';
import { Transition, UIRouter } from '@uirouter/angular';
import { LegacyWorkflowCreateModel } from '../../../lib/legacy-workflow/legacy-workflow-utils';
import { LegacyWorkflow, LegacyWorkflowService } from '../../../lib/legacy-workflow/legacy-workflow-service';
import { UiConstants } from '../../../util/core-utils';
import { ToasterService } from '../../../fork/angular2-toaster/src/toaster.service';
import { StringKey } from '../../../app.string-keys';

/* eslint-enable */

@Component({
  selector: 'app-legacy-workflow-create',
  templateUrl: './legacy-workflow-create.component.html',
  styleUrls: ['./legacy-workflow-create.component.scss']
})
export class LegacyWorkflowCreateComponent implements OnInit {

  UiConstants = UiConstants;
  InputMask = InputMask;
  Workflow = LegacyWorkflow;

  @ViewChild('f', {static: true})
  fForm: NgForm;

  model: LegacyWorkflowCreateModel;
  fieldErrors: FieldValidationError<LegacyWorkflow.ValidatedField>;

  breadcrumbParents: BreadcrumbParent[] = [];
  breadcrumbSelf: string;
  compactSidebar: boolean = document.querySelector('body')!.classList.contains('sidebar-compact');


  constructor(private legacyWorkflowService: LegacyWorkflowService,
              private uiRouter: UIRouter,
              private toasterService: ToasterService,
              private transition: Transition,
              private translateService: TranslateService) {
    this.model = new LegacyWorkflowCreateModel();
    this.fieldErrors = FieldValidationError.empty<LegacyWorkflow.ValidatedField>();
  }

  ngOnInit() {
    this.translateService.get('LEGACY_WORKFLOW_CREATE').subscribe(
      (result: string) => {
        this.breadcrumbSelf = result;
      }
    );
    this.translateService.get('MENU_NAVBAR_MENU_ADMINISTRATION').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.ADMIN_DASHBOARD});
      }
    );
    this.translateService.get('WORKFLOW_LIST').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.LEGACY_WORKFLOW_LIST});
      }
    );
  }

  createWorkflow() {
    this.legacyWorkflowService.create({
      name: this.model.name,
      description: this.model.description,
      externalId: this.model.externalId,
      icon: this.model.icon,
    }).subscribe(
      (response: LegacyWorkflow.WorkflowIdentity) => {
        LoadingHandler.getInstance().refresh();
        this.uiRouter.stateService.go(StateName.LEGACY_WORKFLOW_EDIT, {id: response.workflowId});
      },
      (error: any) => {
        if (error instanceof FieldValidationError) {
          this.fieldErrors = error.withForm(this.fForm);
          this.showValidationErrorToast();
        }
      }
    );
  }

  private showValidationErrorToast() {
    this.toasterService.pop({
      timeout: UiConstants.ToastTimeoutLong,
      type: UiConstants.toastTypeError,
      title: this.translateService.instant(StringKey.COMMON_FORM_VALIDATION_ERROR_TOAST_TITLE),
      body: this.translateService.instant(StringKey.COMMON_FORM_VALIDATION_ERROR_TOAST_MESSAGE)
    });
  }

  hasFieldError(field: LegacyWorkflow.ValidatedField): boolean {
    return this.fieldErrors.hasError(field);
  }

  removeFieldError(field: LegacyWorkflow.ValidatedField) {
    this.fieldErrors = this.fieldErrors.removeError(field);
  }

  getFieldErrorText(field: LegacyWorkflow.ValidatedField): string {
    return this.fieldErrors.getErrorText(field);
  }

  back() {
    window.history.back();
  }

}
