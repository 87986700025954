import { Component, Injector, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { SearchUtils } from '../../../util/search-utils';
import { MultiselectOptionItem, OrderFieldFunction, OrderFieldModel, SelectUtils, UiConstants } from '../../../util/core-utils';
import { OrderField } from '../../../lib/query/orderfields';
import { OrderType, QueryResult } from '../../../lib/util/services';
import { Angular2Multiselects } from '../../../util/multiselect';
import { ExteriorTransportSearch } from '../../../lib/exterior-transport/exterior-transport-search-service';
import { RightModel } from '../../../app.rights';
import { BreadcrumbParent } from '../../../shared/breadcrumb/breadcrumb/breadcrumb.component';
import { RightResolver, RightService } from '../../../lib/right.service';
import { StateName } from '../../../app.state-names';
import { FilterField } from '../../../lib/query/filterfields';
import { CriteriaBuilder } from '../../../util/model-utils';
import { DownloadedFile } from '../../../lib/util/downloaded-files';
import { saveAs } from 'file-saver';
import { ParcelCollectionPointListModel } from '../parcel-collection-point.model';
import {
  ParcelCollectionPointSearch,
  ParcelCollectionPointSearchService
} from '../../../lib/parcel-collection-point/parcel-collection-point-search.service';
import {
  ParcelCollectionPoint,
  ParcelCollectionPointService
} from '../../../lib/parcel-collection-point/parcel-collection-point.service';
import { Address } from '../../../lib/address';
import { ConfigModel } from '../../../util/task-record-utils';
import { ConfigurationService } from '../../../lib/core-ext/configuration.service';
import { FileUploadDialogComponent } from '../../../shared/file-upload/dialog/file-upload-dialog.component';
import { EmptyMessage } from '../../../lib/util/messages';
import { DeliveryMethodMultiselectProvider } from '../../../lib/delivery-method/delivery-method-multiselect.provider';

@Component({
  selector: 'app-parcel-collection-point-list',
  templateUrl: './parcel-collection-point-list.component.html',
  styleUrls: ['./parcel-collection-point-list.component.scss']
})
export class ParcelCollectionPointListComponent extends SearchUtils.SearchableList<ParcelCollectionPointSearch.Model>
  implements OnInit, OnDestroy {

  @ViewChild('importDialog', { static: true })
  importDialog: FileUploadDialogComponent;
  uploadPath: string = '/parcel-collection-points/import-xls';

  UiConstants = UiConstants;
  SelectUtils = SelectUtils;
  ParcelCollectionPointSearch = ParcelCollectionPointSearch;
  config: ConfigModel = new ConfigModel();

  queryModel: OrderFieldModel<OrderField.ParcelCollectionPoint>
    = new OrderFieldModel(ParcelCollectionPointSearch.OrderFunctions.ID, OrderType.DESC);
  searchModel: ParcelCollectionPointSearch.Model = new ParcelCollectionPointSearch.Model();
  deliveryMethods: MultiselectOptionItem<number>[] = [];

  dropdownSettings: Angular2Multiselects.Settings;

  parcelCollectionPointList: ParcelCollectionPointListModel[] = [];
  states: ExteriorTransportSearch.StateFilterOption[] = [];
  rightModel: RightModel = RightModel.empty();

  breadcrumbParents: BreadcrumbParent[] = [];
  breadcrumbSelf: string;
  compactSidebar: boolean = document.querySelector('body')!.classList.contains('sidebar-compact');

  constructor(
    private parcelCollectionPointService: ParcelCollectionPointService,
    private parcelCollectionPointSearchService: ParcelCollectionPointSearchService,
    private rightService: RightService,
    private configService: ConfigurationService,
    private deliveryMethodMultiselectProvider: DeliveryMethodMultiselectProvider,
    injector: Injector) {
    super(ParcelCollectionPointSearch.Model, injector);
    this.config = this.configService.getConfigurationModel();
  }

  ngOnInit() {
    this.loadRightModels();
    this.initBreadcrumb();
    this.initSearch();
    this.initDropdownSettings();
  }

  private loadRightModels() {
    this.rightService.getRightResolver().subscribe(
      (resolver: RightResolver) => {
        this.rightModel = RightModel.of(resolver);
      }
    );
  }

  initBreadcrumb() {
    this.translateService.get('MENU_NAVBAR_PARCEL_COLLECTION_POINTS').subscribe(
      (result: string) => {
        this.breadcrumbSelf = result;
      }
    );
    this.translateService.get('MENU_NAVBAR_MENU_ADMINISTRATION').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.ADMIN_DASHBOARD});
      }
    );
  }

  initDropdownSettings() {
    this.dropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(true)
      .enableSearchFilter(true)
      .remoteSearch(true)
      .enableCheckAll(false)
      .build();
  }

  loadSearch(completion: () => void) {
    this.parcelCollectionPointSearchService.getSearchData({})
      .subscribe(
        (result: ParcelCollectionPointSearch.SearchDataResult) => {
          this.queryModel = result.searchData.queryModel;
          this.searchModel = result.searchData.searchModel;
          completion();
        }
      );
  }

  onFirstSearchOpen(): void {
    this.onDeliveryMethodSearch();
  }

  pageChanged(selectedPage: number) {
    this.loadList(selectedPage);
  }

  itemsPerPageChanged(itemsPerPage: number) {
    this.queryModel.itemsPerPage = itemsPerPage;
    this.loadList(1);
  }

  orderBy(field: OrderFieldFunction<OrderField.ParcelCollectionPoint>) {
    this.queryModel.onOrderFieldChanged(field);
    this.loadList(1);
  }

  loadList(pageNumber?: number) {
    const requestedPage = pageNumber ? pageNumber : this.queryModel.currentPage;
    const request = this.getQueryRequest(requestedPage);
    this.parcelCollectionPointService.query(request).subscribe((result: QueryResult<ParcelCollectionPoint.ParcelCollectionPoint>) => {
      this.parcelCollectionPointList = [];
      result.items.forEach((parcelCollectionPoint: ParcelCollectionPoint.ParcelCollectionPoint) => {
        const model = new ParcelCollectionPointListModel();
        model.id = parcelCollectionPoint.id;
        model.creationTime = parcelCollectionPoint.creationTime!;
        model.updateTime = parcelCollectionPoint.updateTime!;
        model.name = parcelCollectionPoint.name;
        model.code = parcelCollectionPoint.code!;
        model.deliveryMethodName = parcelCollectionPoint.deliveryMethod!.name;
        model.disabled = parcelCollectionPoint.disabled;
        model.postalAddress =
          Address.PostalAddressMapper.toString(parcelCollectionPoint.postalAddress!, this.config.postalAddressFormat);
        this.parcelCollectionPointList.push(model);
      });
      this.queryModel.currentPage = requestedPage;
      this.queryModel.totalNumberOfItems = result.pagingResult.totalNumberOfItems;
      this.queryModel.currentNumberOfItems = result.pagingResult.currentNumberOfItems;
    });
  }

  private getQueryRequest(requestedPage?: number): ParcelCollectionPoint.QueryRequest {
    const order = this.queryModel.createOrderFunction();
    const filter = this.createFilter();
    return {
      fields: f => f.each,
      order: order,
      filter: filter,
      paging: requestedPage ? {
        pageNumber: requestedPage,
        numberOfItems: this.queryModel.itemsPerPage
      } : undefined,
    };
  }

  private createFilter() {
    return (f: FilterField.ParcelCollectionPoint) => CriteriaBuilder.builder()
      .addString((name) => f.name.containsIgnoreCase(name), this.searchModel.name)
      .addString((code) => f.code.containsIgnoreCase(code), this.searchModel.code)
      .addString((city) => f.postalAddress.city.containsIgnoreCase(city), this.searchModel.city)
      .addNumber((deliveryMethodId) => f.deliveryMethod.id.eq(deliveryMethodId), this.searchModel.deliveryMethodId)
      .addBoolean(
        () => f.coordinates.longitude.isNull().and(f.coordinates.latitude.isNull()),
          this.trueOrUndefined(this.searchModel.coordinatesMissing))
      .build();
  }

  onDeliveryMethodSearch(predicate?: string) {
    this.deliveryMethodMultiselectProvider.loadForParcelCollectionPoint(predicate).subscribe((items) => {
      this.deliveryMethods = items;
    })
  }

  onSearchClicked() {
    this.loadList(1);
  }

  onSearchReset() {
    this.parcelCollectionPointSearchService.resetSearchData({}).subscribe(
      (result) => {
        this.loadSearch(() => {
          this.toggleSearch();
          this.loadList(1);
        });
      }
    );
  }

  ngOnDestroy() {
    this.saveSearch();
  }

  private saveSearch() {
    const request = {
        searchData: {
          queryModel: this.queryModel,
          searchModel: this.searchModel
        }
      }
    ;
    this.parcelCollectionPointSearchService.setSearchData(request).subscribe(
      (result) => {
      },
      (error) => {
      }
    );
  }

  exportXlsTemplate() {
    const request = this.getQueryRequest();
    this.parcelCollectionPointService.exportXlsTemplate(request).subscribe(
      (res: DownloadedFile) => {
        saveAs(res.getBlob(), res.getFileName('parcel-collection-point-template.xlsx'));
      }
    );
  }

  onImportSuccess(succeeded: boolean) {
    if (succeeded) {
      this.loadList(1);
    }
  }

  setDisabled(event: any, parcelCollectionPointId: number, disabled: boolean) {
    this.parcelCollectionPointService.setDisabled({
      id: parcelCollectionPointId,
      disabled: disabled
    })
      .subscribe(
        (result: EmptyMessage) => {
          this.loadList();
        },
        (error: any) => {
          this.loadList();
        }
      );
  }

  // Helper for coordinatesMissing search value
  private trueOrUndefined(value: boolean): boolean | undefined {
    if (value) {
      return true;
    }
    return undefined;
  }

}
