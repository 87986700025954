/* eslint-disable */
import { ContentTypeParser, LazyFileModel, } from '../lib/util/downloaded-files';
import { FileAttachment } from '../lib/util/file-attachments';
/* eslint-enable */

/**
 * A file model with file attachment data.
 * Do not place any component dependent code here.
 */
export class FileAttachmentModel extends LazyFileModel {

  private readonly ctp: ContentTypeParser;

  private _fileType?: FileTypeModel;

  constructor(public attachment: FileAttachment) {
    super();
    this.ctp = new ContentTypeParser(attachment.contentType);
  }

  get contentTypeParser(): ContentTypeParser {
    return this.ctp;
  }

  get fileType(): FileTypeModel {
    if (this._fileType === undefined) {
      this._fileType = this.createFileType();
    }
    return this._fileType;
  }

  get placeholderImageSrc(): string {
    return this.fileType.placeholderImageSrc;
  }

  get fileTypeNameDictionaryKey(): string {
    return this.fileType.nameDictionaryKey;
  }

  private createFileType(): FileTypeModel {
    return FileTypeModelFactory.createFileTypeFromContentTypeParser(this.ctp);
  }

}

export class FileTypeModel {
  constructor(public readonly iconImageSrc: string, public readonly placeholderImageSrc: string, public readonly nameDictionaryKey) {
  }
}

export class FileTypeModelFactory {
  private static iconBase = 'assets/img/file-icon/';
  private static placeholderBase = 'assets/img/file-icon/placeholder/';
  public static DEFAULT = new FileTypeModel(FileTypeModelFactory.iconBase + 'unknown.svg',
    FileTypeModelFactory.placeholderBase + 'unknown.svg', 'COMMON_READABLE_EXTENSION_UNKNOWN');

  public static createFileTypeFromContentType(ct?: string): FileTypeModel {
    return FileTypeModelFactory.createFileTypeFromContentTypeParser(new ContentTypeParser(ct));
  }

  public static createFileTypeFromContentTypeParser(ctp: ContentTypeParser): FileTypeModel {
    const iconBase = FileTypeModelFactory.iconBase;
    const placeholderBase = FileTypeModelFactory.placeholderBase;
    if (ctp.isPdf) {
      return new FileTypeModel(iconBase + 'pdf.svg', placeholderBase + 'pdf-ext.svg', 'COMMON_READABLE_EXTENSION_PDF');
    }
    if (ctp.isFodt) {
      return new FileTypeModel(iconBase + 'fodt.svg', placeholderBase + 'unknown.svg', 'COMMON_READABLE_EXTENSION_FODT');
    }
    if (ctp.isZip) {
      return new FileTypeModel(iconBase + 'zip.svg', placeholderBase + 'unknown.svg', 'COMMON_READABLE_EXTENSION_ZIP');
    }
    if (ctp.isAudioType) {
      return new FileTypeModel(iconBase + 'mp3.svg', placeholderBase + 'audio.svg', 'COMMON_READABLE_EXTENSION_AUDIO');
    }
    if (ctp.isImageType) {
      if (ctp.isJpeg) {
        return new FileTypeModel(iconBase + 'jpg.svg', placeholderBase + 'image.svg', 'COMMON_READABLE_EXTENSION_IMAGE');
      }
      if (ctp.isPng) {
        return new FileTypeModel(iconBase + 'png.svg', placeholderBase + 'image.svg', 'COMMON_READABLE_EXTENSION_IMAGE');
      }
      return new FileTypeModel(iconBase + 'img.svg', placeholderBase + 'image.svg', 'COMMON_READABLE_EXTENSION_IMAGE');
    }
    if (ctp.isTextType) {
      return new FileTypeModel(iconBase + 'doc.svg', placeholderBase + 'text.svg', 'COMMON_READABLE_EXTENSION_TEXT');
    }
    if (ctp.isDoc) {
      return new FileTypeModel(iconBase + 'doc.svg', placeholderBase + 'text.svg', 'COMMON_READABLE_EXTENSION_TEXT');
    }
    if (ctp.isXls) {
      return new FileTypeModel(iconBase + 'xls.svg', placeholderBase + 'text.svg', 'COMMON_READABLE_EXTENSION_TEXT');
    }
    if (ctp.isTextType) {
      return new FileTypeModel(iconBase + 'doc.svg', placeholderBase + 'text.svg', 'COMMON_READABLE_EXTENSION_TEXT');
    }
    if (ctp.isVideoType) {
      return new FileTypeModel(iconBase + 'video.svg', placeholderBase + 'video.svg', 'COMMON_READABLE_EXTENSION_VIDEO');
    }
    return FileTypeModelFactory.DEFAULT;
  }

  public static createFileSizeFromBytesBy10(documentSizeInByte: number): FileSize {
    if (documentSizeInByte <= 0) {
      return FileSize.zero();
    }
    if (documentSizeInByte <= 1) {
      return new FileSize('COMMON_FILE_SIZE_UNIT_BYTE', documentSizeInByte);
    }
    if (documentSizeInByte < 1000) {
      return new FileSize('COMMON_FILE_SIZE_UNIT_BYTES', documentSizeInByte);
    }
    if (documentSizeInByte < 1000 * 1000) {
      return new FileSize('COMMON_FILE_SIZE_UNIT_KB', documentSizeInByte / 1000);
    }
    if (documentSizeInByte < 1000 * 1000 * 1000) {
      return new FileSize('COMMON_FILE_SIZE_UNIT_MB', documentSizeInByte / 1000 / 1000);
    }
    return new FileSize('COMMON_FILE_SIZE_UNIT_GB', documentSizeInByte / 1000 / 1000 / 1000);
  }

  public static createFileSizeFromBytesBy2(documentSizeInByte: number): FileSize {
    if (documentSizeInByte <= 0) {
      return FileSize.zero();
    }
    if (documentSizeInByte <= 1) {
      return new FileSize('COMMON_FILE_SIZE_UNIT_BYTE', documentSizeInByte);
    }
    if (documentSizeInByte < 1024) {
      return new FileSize('COMMON_FILE_SIZE_UNIT_BYTES', documentSizeInByte);
    }
    if (documentSizeInByte < 1024 * 1024) {
      return new FileSize('COMMON_FILE_SIZE_UNIT_KIB', documentSizeInByte / 1024);
    }
    if (documentSizeInByte < 1024 * 1024 * 1024) {
      return new FileSize('COMMON_FILE_SIZE_UNIT_MIB', documentSizeInByte / 1024 / 1024);
    }
    return new FileSize('COMMON_FILE_SIZE_UNIT_GIB', documentSizeInByte / 1024 / 1024 / 1024);
  }

}

export class FileSize {

  public static zero(): FileSize {
    return new FileSize('COMMON_FILE_SIZE_UNIT_ZERO', 0);
  }

  constructor(
    public readonly unitDictKey: string,
    public readonly value: number) {}

}
