/* eslint-disable */
import { Injectable } from '@angular/core';
import {MultiselectOptionItem, UiConstants} from '../util/core-utils';
import { Order, OrderType } from './util/services';
import { CustomerRecord } from './customer/customer-record.service';
import { DisabledEnum, SearchBooleanItem } from '../util/search-utils';
import { BaseSearch, BaseSearchService } from './base.search-service';
import { Customer } from './customer/customer.service';
import { AssigneeItem } from '../util/task-record-utils';
import { CustomerItemForSearch } from '../util/customer-record-utils';
import { LoggedInUserStorage } from './util/storages';
/* eslint-enable */

@Injectable()
export class CustomerRecordSearchService extends BaseSearchService {

  protected createStorage(): CustomerRecordSearch.Storage {
    return new CustomerRecordSearch.Storage();
  }

}

export namespace CustomerRecordSearch {

  export interface SearchDataGetRequest extends BaseSearch.SearchDataGetRequest {
    customerId?: number;
  }

  export interface SearchDataResult {
    searchData: SearchData;
  }

  export interface SearchDataSetRequest extends BaseSearch.SearchDataSetRequest {
    customerId?: number;
  }

  export interface SearchData extends BaseSearch.SearchData {
    order: Order<CustomerRecord.OrderField>;
    name: string;
    externalId: string;
    comment: string;
    emailAddress: string;
    phoneNumber: string;
    postalAddressCity: string;
    postalAddressZipCode: string;
    postalAddressStreet: string;
    postalAddressHouseNumber: string;
    customers: CustomerItemForSearch[];
    contractNumbers: MultiselectOptionItem<number>[];
    disabled?: DisabledEnum;
    users: AssigneeItem[];
    userGroups: AssigneeItem[];
    contactPersonType: SearchBooleanItem;
    contactPerson: SearchBooleanItem;
    dqlText?: string;
  }

  interface StoredSearchRawData extends BaseSearch.StoredSearchRawData {
    order: Order<CustomerRecord.OrderField>;
    name: string;
    externalId: string;
    comment: string;
    emailAddress: string;
    phoneNumber: string;
    postalAddressCity: string;
    postalAddressZipCode: string;
    postalAddressStreet: string;
    postalAddressHouseNumber: string;
    customers: CustomerItemForSearch[];
    contractNumbers: MultiselectOptionItem<number>[];
    disabled?: DisabledEnum;
    users: AssigneeItem[];
    userGroups: AssigneeItem[];
    contactPersonType: SearchBooleanItem;
    contactPerson: SearchBooleanItem;
    dqlText?: string;
  }

  export class Storage extends BaseSearch.Storage {

    constructor() {
      const defaultSearchData = {
        itemsPerPage: UiConstants.pageNumbers[0],
        pageNumber: 1,
        order: {
          field: CustomerRecord.OrderField.ID,
          type: OrderType.DESC
        },
        name: '',
        externalId: '',
        comment: '',
        emailAddress: '',
        phoneNumber: '',
        postalAddressCity: '',
        postalAddressZipCode: '',
        postalAddressStreet: '',
        postalAddressHouseNumber: '',
        customers: [],
        contractNumbers: [],
        disabled: DisabledEnum.FALSE,
        users: [],
        userGroups: [],
        contactPerson: SearchBooleanItem.ALL,
        contactPersonType: SearchBooleanItem.ALL,
        dqlText: undefined
      };
      /**
       * Increment this version if you change the SearchData interface.
       */
      const versionNumber: number = 15;
      super(defaultSearchData,
        versionNumber);
    }

    protected generateKey(request: SearchDataGetRequest | SearchDataSetRequest): string {
      return request.customerId
        ? 'UserId(' + LoggedInUserStorage.getInstance().getUserId() + ')-Search-CustomerRecord-CustomerId(' + request.customerId + ')'
        : 'UserId(' + LoggedInUserStorage.getInstance().getUserId() + ')-Search-CustomerRecord-Global';
    }

    protected fromRaw(data: StoredSearchRawData): BaseSearch.SearchDataResult {
      const searchData: SearchData = {
        itemsPerPage: data.itemsPerPage,
        pageNumber: data.pageNumber,
        order: data.order,
        name: data.name,
        externalId: data.externalId,
        comment: data.comment,
        emailAddress: data.emailAddress,
        phoneNumber: data.phoneNumber,
        postalAddressCity: data.postalAddressCity,
        postalAddressZipCode: data.postalAddressZipCode,
        postalAddressStreet: data.postalAddressStreet,
        postalAddressHouseNumber: data.postalAddressHouseNumber,
        customers: data.customers,
        contractNumbers: data.contractNumbers,
        disabled: data.disabled,
        users: data.users,
        userGroups: data.userGroups,
        contactPerson: data.contactPerson,
        contactPersonType: data.contactPersonType,
        dqlText: data.dqlText,
      };
      return {
        searchData: searchData
      };
    }

    protected toRaw(data: SearchData): StoredSearchRawData {
      return {
        version: this.VERSION,
        itemsPerPage: data.itemsPerPage,
        pageNumber: data.pageNumber,
        order: data.order,
        name: data.name,
        externalId: data.externalId,
        comment: data.comment,
        emailAddress: data.emailAddress,
        phoneNumber: data.phoneNumber,
        postalAddressCity: data.postalAddressCity,
        postalAddressZipCode: data.postalAddressZipCode,
        postalAddressStreet: data.postalAddressStreet,
        postalAddressHouseNumber: data.postalAddressHouseNumber,
        customers: data.customers,
        contractNumbers: data.contractNumbers,
        disabled: data.disabled,
        users: data.users,
        userGroups: data.userGroups,
        contactPerson: data.contactPerson,
        contactPersonType: data.contactPersonType,
        dqlText: data.dqlText,
      };
    }

  }

}
