<div class="breadcrumb-container" [ngClass]="{ 'breadcrumb-container-compact': compactSidebar }" id="breadcrumb">
  <div class="breadcrumb-component">
    <app-breadcrumb
      [parents]=breadcrumbParents
      [self]="breadcrumbSelf">
    </app-breadcrumb>
  </div>
</div>
<div class="breadcrumb-placeholder"></div>
<div class="container-custom-padding-outer animated fadeIn">
  <form class="form-horizontal bordered-row" (ngSubmit)="createCustomerRecord()" #f="ngForm" novalidate>
    <app-customer-record-base
      headingDictionaryKey="CUSTOMER_RECORD_PANEL_HEADING_CLONE"
      [readonly]="false"
      [allowClone]="false"
      [form]="f"
      [customerItem]="combinedResult ? combinedResult.customer : undefined"
      (submitted)="submit($event)"
      (showDialog)="openWarningDialog()"></app-customer-record-base>
    <div class="card-custom-padding-outer">
      <app-form-record-container
        [readonly]="false"
        [cloning]="true"
        [form]="f"></app-form-record-container>
    </div>
  </form>
</div>

<div bsModal [config]="UiConstants.modalConfig" #warningDialog="bs-modal" class="modal fade" tabindex="-1" role="dialog"
     aria-labelledby="myModalLabel"
     aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document" *ngIf="warningDialogVisible">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">{{'INVOICE_ADDRESS_MODAL_HEADER' | translate}}</h4>
        <button type="button" class="close" (click)="closeWarningDialog()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="form-group col-md-12 mb-0">
          <label>{{'INVOICE_ADDRESS_MODAL_BODY' | translate}}</label>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="closeWarningDialog()">{{'COMMON_BUTTON_OK' |
          translate}}
        </button>
      </div>
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div><!-- /.modal -->
