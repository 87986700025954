/* eslint-disable */
import { Component, OnInit } from '@angular/core';
import { RightResolver, RightService } from '../../../lib/right.service';
import { RightModel } from '../../../app.rights';
import { TranslateService } from '@ngx-translate/core';
import { DocumentFileService } from '../../../lib/document/document-file.service';
import { CountMessage } from '../../../lib/util/messages';
import { DocumentLinkService } from '../../../lib/document/document-link.service';
/* eslint-enable */

@Component({
  selector: 'app-document-dashboard',
  templateUrl: './document-dashboard.component.html',
  styleUrls: ['./document-dashboard.component.scss']
})
export class DocumentDashboardComponent implements OnInit {

  documentFileCount: number = 0;
  documentLinkCount: number = 0;

  rightModel: RightModel = RightModel.empty();

  breadcrumbSelf: string;
  compactSidebar: boolean = document.querySelector('body')!.classList.contains('sidebar-compact');

  constructor(private rightService: RightService,
              private translateService: TranslateService,
              private documentFileService: DocumentFileService,
              private documentLinkService: DocumentLinkService) { }

  ngOnInit() {
    this.loadRightModels();
    this.translateService.get('COMMON_DOCUMENTS').subscribe(
      (result: string) => {
        this.breadcrumbSelf = result;
      }
    );
    this.loadDocumentFiles();
    this.loadDocumentLinks();
  }

  private loadRightModels() {
    this.rightService.getRightResolver().subscribe(
      (resolver: RightResolver) => {
        this.rightModel = RightModel.of(resolver);
      }
    );
  }

  loadDocumentFiles() {
    this.documentFileService.count({
      disabled: false
    }).subscribe((res: CountMessage) => {
      this.documentFileCount = res.current_number_of_items;
    });
  }

  loadDocumentLinks() {
    this.documentLinkService.count({
      disabled: false
    }).subscribe((res: CountMessage) => {
      this.documentLinkCount = res.current_number_of_items;
    });
  }

}
