/* eslint-disable */
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { Transition, UIRouter } from '@uirouter/angular';
import { StateName } from '../../../app.state-names';
import { Company, CompanyService } from '../../../lib/company/company.service';
import { BreadcrumbParent } from '../../../shared/breadcrumb/breadcrumb/breadcrumb.component';
import { TranslateService } from '@ngx-translate/core';
import { Address, AddressModel } from '../../../lib/address';
import { EuVatNumber, HuTaxNumber } from '../../../lib/util/tax-numbers';
import { InputMask } from '../../../util/input-masks';
import { NgForm, NgModel } from '@angular/forms';
import { List, Set } from 'immutable';
import {
  LocalFieldValidationErrors,
  LocalFieldValidationErrorsFactory,
  OrderType,
  QueryResult,
  Services
} from '../../../lib/util/services';
import { Country, CountryService } from '../../../lib/country.service';
import { Strings } from '../../../lib/util/strings';
import { FieldError, FieldErrors, ObservableErrorResourceParser } from '../../../lib/util/errors';
import { CompanyItem, MultiselectOptionItem, OptionItem, QueryFieldModel, SelectUtils, UiConstants } from '../../../util/core-utils';
import { ConfigurationService } from '../../../lib/core-ext/configuration.service';
import { Role, RoleService } from '../../../lib/role/role.service';
import { Angular2Multiselects } from '../../../util/multiselect';
import { RightResolver, RightService } from '../../../lib/right.service';
import { RightModel } from '../../../app.rights';
import { ComponentStateResolver } from '../../../util/component-state/component-state-resolver';
import { ToasterService } from '../../../fork/angular2-toaster/angular2-toaster';
import { EmptyMessage, IdentityMessage } from '../../../lib/util/messages';
import { HistoryDetailDialogComponent } from '../../history-log/history-detail-dialog/history-detail-dialog.component';
import { HistoryBaseModel, HistoryLogComponent } from '../../history-log/history-log/history-log.component';
import CompanyHistoryType = Company.CompanyHistoryType;
import { UserMeService } from '../../../lib/user/user-me.service';
import { DeliveryMethodMultiselectProvider } from '../../../lib/delivery-method/delivery-method-multiselect.provider';
/* eslint-enable */

@Component({
  selector: 'app-company-base',
  templateUrl: './company-base.component.html',
  styleUrls: ['./company-base.component.scss']
})
export class CompanyBaseComponent implements OnInit, AfterViewInit {

  @ViewChild('historyComponent')
  historyComponent: HistoryLogComponent;

  @ViewChild('historyDetailModal', { static: true })
  historyDetailModal: HistoryDetailDialogComponent;

  // If you need to access certain classes from HTML, declare them here
  SelectUtils = SelectUtils;
  AddressModel = AddressModel;
  InputMask = InputMask;
  Company = Company;
  UiConstants = UiConstants;

  // Declare models before use
  editModel: CompanyEditModel;
  detailModel: CompanyDetailModel;

  // Component state resolver, determines the state of the component
  componentState: ComponentStateResolver;

  // Variables used for breadcrumb
  breadcrumbParents: BreadcrumbParent[] = [];
  breadcrumbSelf: string;
  compactSidebar: boolean = document.querySelector('body')!.classList.contains('sidebar-compact');

  typeModels: TypeModel[] = [
    {id: 'DEMANDER', text: 'DEMANDER'},
    {id: 'TRANSPORTER', text: 'TRANSPORTER'}
  ];

  // Form for validation
  @ViewChild('f', { static: true })
  fForm: NgForm;

  // Validated inputs
  @ViewChild('name')
  name: NgModel;

  @ViewChild('externalId')
  externalId: NgModel;

  @ViewChild('type')
  type: NgModel;

  @ViewChild('deliveryMethodsInput')
  deliveryMethodsInput: NgModel;

  @ViewChild('emailAddress')
  emailAddress: NgModel;

  @ViewChild('phoneNumber')
  phoneNumber: NgModel;

  @ViewChild('taxNumber')
  taxNumber: NgModel;

  // Form submission state for Postal Address validation
  formSubmitted: boolean = false;

  // Field errors for server validation
  fieldErrors: CompanyFieldErrorMap;

  private validatedInputs: LocalFieldValidationErrors<NgModel> =
    LocalFieldValidationErrorsFactory.empty();

  // Right model to store the rights available to the User
  rightModel: RightModel = RightModel.empty();

  // Format of postal address, received from server
  postalAddressFormat: string;

  // Country items for postal address
  countryItems: List<AddressModel.CountryItem>;

  // Available roles and selected roles for multiselect dropdown
  roles: RoleItem[] = [];
  selectedRoles: RoleItem[] = [];

  // Available transporter companies for multiselect dropdown
  transporterCompanies: OptionItem<number>[] = [];

  // Settings for the multiselect dropdown
  dropdownSettings: Angular2Multiselects.Settings;
  remoteDropdownSettings: Angular2Multiselects.Settings;

  historyList: CompanyHistoryModel[] = [];

  historyTypes: CompanyHistoryTypeItem[];

  historyQueryModel: QueryFieldModel<Company.OrderField> = new QueryFieldModel(Company.OrderField.HISTORY_CREATION_TIME, OrderType.DESC);
  private _currentUserProtected: boolean = false;

  constructor(private uiRouter: UIRouter,
              private transition: Transition,
              private companyService: CompanyService,
              private translateService: TranslateService,
              private countryService: CountryService,
              private configService: ConfigurationService,
              private roleService: RoleService,
              private rightService: RightService,
              private userMeService: UserMeService,
              private toasterService: ToasterService) {
    this.postalAddressFormat = this.configService.getPostalAddressFormat();
    this.fieldErrors = {};
    this.componentState = new ComponentStateResolver(uiRouter, transition,
      'id',
      {stateName: StateName.COMPANY_CREATE, stateHeaderKey: 'COMPANY_CREATE'},
      {stateName: StateName.COMPANY_EDIT, stateHeaderKey: 'COMPANY_EDIT'},
      {stateName: StateName.COMPANY_DETAIL, stateHeaderKey: 'COMPANY_DETAIL'});
  }

  ngOnInit() {
    this.initComponentState(); // Must be called first
    this.loadRightModels();
    this.initBreadcrumb();
    this.initDropdownSettings();
  }

  ngAfterViewInit(): void {
    this.loadLocalFieldValidationErrors();
      this.loadCountries(() => {
        // component state contains the id
        if (this.componentState.id) {
          this.loadModel();
        }
        else {
          // Load roles and country items for create. Otherwise handled in loadModel()
          this.editModel.postalAddress.loadCountryItems(this.countryItems, this.configService.getDefaultSelectedCountryCode());
          this.loadRoles();
          this.loadTransporterCompanies();
        }
      });
  }

  // Loads user rights
  private loadRightModels() {
    this.rightService.getRightResolver().subscribe(
      (resolver: RightResolver) => {
        this.rightModel = RightModel.of(resolver);
        this.userMeService.amIProtected().subscribe(p => {
          this._currentUserProtected = p;
        });
      }
    );
  }

  // Called by the init method first
  private loadModel() {
    this.companyService.get({
      id: this.componentState.id!
    }).subscribe((company: Company.Company) => {
      if (this.componentState.isEditView()) {
        this.loadEditModel(company);
      }
      else if (this.componentState.isDetailView()) {
        this.loadDetailModel(company);
      }
      this.breadcrumbSelf = company.name;
      if (this.componentState.isDetailView() && this.rightModel.companyHistoryLog.hasRight()) {
        this.loadHistoryTypes(() => {
          this.loadHistoryList()
        });
      }
    });
  }

  private loadEditModel(company: Company.Company) {
    const phoneNumber = Services.phoneNumberToString(company.phoneNumber);
    const emailAddress = Services.emailAddressToString(company.emailAddress);
    const selectedType = this.typeModels.filter(value => value.id === company.type);
    const unknownType = this.typeModels.filter(value => value.id === 'UNKNOWN')[0];

    this.editModel = new CompanyEditModel();
    this.editModel.name = company.name;
    this.editModel.externalId = company.externalId;
    this.editModel.type = selectedType.length === 1 ? selectedType[0] : unknownType;
    this.editModel.comment = company.comment ? company.comment : '';
    this.editModel.phoneNumber = phoneNumber ? phoneNumber : '';
    this.editModel.emailAddress = emailAddress ? emailAddress : '';
    this.editModel.taxNumber.raw = company.taxNumber ? company.taxNumber : '';
    this.editModel.euTaxNumber.raw = company.euTaxNumber ? company.euTaxNumber : '';
    this.editModel.postalAddress.loadCountryItems(this.countryItems, this.configService.getDefaultSelectedCountryCode());
    this.editModel.postalAddress.load(company.postalAddress);
    this.loadRoles(company);
    this.loadTransporterCompanies(undefined, company.transporterCompanyIds);
  }

  private loadDetailModel(company: Company.Company) {
    const phoneNumber = Services.phoneNumberToString(company.phoneNumber);
    const emailAddress = Services.emailAddressToString(company.emailAddress);
    const selectedType = this.typeModels.filter(value => value.id === company.type);
    const unknownType = this.typeModels.filter(value => value.id === 'UNKNOWN')[0];

    this.detailModel = new CompanyDetailModel();
    this.detailModel.id = company.id;
    this.detailModel.name = company.name;
    this.detailModel.externalId = company.externalId;
    this.detailModel.type = selectedType.length === 1 ? selectedType[0] : unknownType;
    this.detailModel.comment = company.comment ? company.comment : '-';
    this.detailModel.phoneNumber = phoneNumber ? phoneNumber : '-';
    this.detailModel.emailAddress = emailAddress ? emailAddress : '-';
    this.detailModel.taxNumber = company.taxNumber ? company.taxNumber : '-';
    this.detailModel.euTaxNumber = company.euTaxNumber ? company.euTaxNumber : '-';
    this.detailModel.postalAddress = Address.PostalAddressMapper.toString(company.postalAddress, this.postalAddressFormat);
    this.loadRoles(company);
    this.loadTransporterCompanies(undefined, company.transporterCompanyIds);
  }

  private loadRoles(company?: Company.Company) {
    const roleIdSet: number[] = [];
    if (this.componentState.isDetailView() && company) {
      company.allowedRoleIds!.forEach((allowedRoleId) => {
        roleIdSet.push(allowedRoleId!);
      });
    }
    this.roleService.query({
      id: roleIdSet.length > 0 ? Set.of(...roleIdSet) : undefined,
      disabled: false,
      orders: Set.of({field: Role.OrderField.NAME, type: OrderType.ASC})
    }).subscribe(
      (result: QueryResult<Role.Role>) => {
        this.roles = [];
        this.selectedRoles = [];
        result.items.toArray().forEach((role: Role.Role) => {
          const roleItem = {
            id: role.id,
            text: role.name,
            disabled: role.disabled
          };
          if (!role.protectedRole || this._currentUserProtected) {
            this.roles.push(roleItem);
          }
          if (company) {
            const roleId = company.allowedRoleIds!.find((rId) => rId === role.id);
            if (roleId) {
              this.selectedRoles.push(roleItem);
            }
          }
        });
      });
  }

  private initComponentState() {
    // Creates the editModel if not readonly
    if (!this.componentState.isDetailView()) {
      this.editModel = new CompanyEditModel();
    }
    else {
      this.detailModel = new CompanyDetailModel()
    }
    // normally you would need to call loadModel after this
  }

  initBreadcrumb() {
    // Set breadcrumbSelf if createView, otherwise set in loadModel()
    if (this.componentState.isCreateView()) {
      this.translateService.get('COMPANY_CREATE').subscribe(
        (result: string) => {
          this.breadcrumbSelf = result;
        }
      );
    }
    this.translateService.get('MENU_NAVBAR_MENU_ADMINISTRATION').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.ADMIN_DASHBOARD});
      }
    );
    this.translateService.get('MENU_NAVBAR_COMPANIES').subscribe(
      (result: string) => {
        this.breadcrumbParents.push({name: result, uiSref: StateName.COMPANY_LIST});
      }
    );
  }

  // Get corresponding dictionary key
  getHeadingDictionaryKey(): string {
    return this.componentState.getCurrentHeaderKey();
  }

  hasLocalFieldError(field?: NgModel): boolean {
    return this.validatedInputs.hasLocalError(field);
  }

  removeFieldError(fieldError?: FieldError) {
    FieldErrors.remove(this.fieldErrors, fieldError);
  }

  private loadLocalFieldValidationErrors() {
    const validatedInputs = List.of(this.name,
      this.emailAddress,
      this.phoneNumber,
      this.taxNumber,
      this.externalId,
      this.type,
      this.deliveryMethodsInput
    );
    this.validatedInputs = LocalFieldValidationErrorsFactory.ofFormFields(this.fForm, validatedInputs);
  }

  private loadCountries(completion: () => void) {
    this.countryService.query({}).subscribe((result: QueryResult<Country.Country>) => {
      this.countryItems = AddressModel.CountryItem.fromCountryList(result.items);
      completion();
    });
  }

  submit() {
    this.formSubmitted = true;
    if (this.componentState.isCreateView()) {
      this.create();
    }
    else if (this.componentState.isEditView()) {
      this.update();
    }
  }

  create() {
    if (this.hasLocalFieldError()) {
      return;
    }
    if (!this.editModel.postalAddress.valid) {
      return;
    }

    if (this.editModel.type.id === 'UNKNOWN') {
      return
    }
    const transporterCompanyIds = this.editModel.type.id === 'DEMANDER' ?
      Set.of(...this.editModel.transporterCompanies.map((c) => c.id!)) : Set.of(...[]);
    this.companyService.create({
      name: this.editModel.name,
      externalId: Strings.undefinedOrNonEmpty(this.editModel.externalId),
      type: this.editModel.type.id,
      comment: Strings.undefinedOrNonEmpty(this.editModel.comment),
      taxNumber: this.editModel.taxNumber.truncated ? this.editModel.taxNumber.truncated : '',
      euTaxNumber: this.editModel.euTaxNumber.truncated ? this.editModel.euTaxNumber.truncated : '',
      emailAddress: Services.toEmailAddress(this.editModel.emailAddress),
      phoneNumber: Services.toPhoneNumber(this.editModel.phoneNumber),
      postalAddress: this.editModel.postalAddress.toData()!,
      allowedRoleIds: Set.of(... this.selectedRoles.map((selectedRole) => selectedRole.id!)),
      transporterCompanyIds: transporterCompanyIds
    }).subscribe(
      (response: IdentityMessage) => {
        this.uiRouter.stateService.go(StateName.COMPANY_LIST);
      },
      (error: any) => {
        const res = ObservableErrorResourceParser.parseError(error);
        this.fieldErrors = ObservableErrorResourceParser.extractFieldErrors(res);
      });
  }

  update() {
    if (this.hasLocalFieldError()) {
      return;
    }
    if (!this.editModel.postalAddress.valid) {
      return;
    }
    const transporterCompanyIds = this.editModel.type.id === 'DEMANDER' ?
      Set.of(... this.editModel.transporterCompanies.map((c) => c.id!)) : Set.of(...[]);
    this.companyService.update({
      id: this.componentState.id!,
      name: this.editModel.name,
      externalId: Strings.undefinedOrNonEmpty(this.editModel.externalId),
      comment: Strings.undefinedOrNonEmpty(this.editModel.comment),
      taxNumber: this.editModel.taxNumber.truncated ? this.editModel.taxNumber.truncated : '',
      euTaxNumber: this.editModel.euTaxNumber.truncated ? this.editModel.euTaxNumber.truncated : '',
      emailAddress: Services.toEmailAddress(this.editModel.emailAddress),
      phoneNumber: Services.toPhoneNumber(this.editModel.phoneNumber),
      postalAddress: this.editModel.postalAddress.toData()!,
      transporterCompanyIds: transporterCompanyIds
    }).subscribe(
      (response: EmptyMessage) => {
        this.uiRouter.stateService.go(StateName.COMPANY_LIST);
      },
      (error: any) => {
        const res = ObservableErrorResourceParser.parseError(error);
        this.fieldErrors = ObservableErrorResourceParser.extractFieldErrors(res);
      });
  }

  updateRoles() {
    this.companyService.updateRole({
      id: this.componentState.id!,
      forceRemove: this.editModel.forceRemove,
      allowedRoleIds: Set.of(... this.selectedRoles.map((selectedRole) => selectedRole.id!))
    }).subscribe(
      (response: EmptyMessage) => {
        this.uiRouter.stateService.go(StateName.COMPANY_LIST);
      });
  }

  back() {
    window.history.back();
  }

  // Initializes settings for the multiselect dropdown
  initDropdownSettings() {
      this.dropdownSettings = new Angular2Multiselects.SettingsBuilder()
        .singleSelection(false)
        .enableSearchFilter(true)
        .labelKey(OptionItem.KEY_TEXT)
        .enableCheckAll(true)
        .build();
    this.remoteDropdownSettings = new Angular2Multiselects.SettingsBuilder()
      .singleSelection(false)
      .enableSearchFilter(true)
      .labelKey(OptionItem.KEY_TEXT)
      .enableCheckAll(true)
      .remoteSearch(true)
      .build();
  }

  loadHistoryList(pageNumber?: number) {
    const requestedPage = pageNumber ? pageNumber : this.historyQueryModel.currentPage;
    const order = this.historyQueryModel.getOrder();
    this.companyService.history({
      id: this.componentState.id!,
      withRead: true!,
      orders: Set.of(order),
      paging: requestedPage ? {
        pageNumber: requestedPage,
        numberOfItems: this.historyQueryModel.itemsPerPage
      } : undefined
    }).subscribe((result: QueryResult<Company.HistoryItem>) => {
      this.historyList = [];
      result.items.forEach((history: Company.HistoryItem) => {
        const historyModel = new CompanyHistoryModel();
        historyModel.id = history.id;
        historyModel.creationTime = history.creationTime.toUtcIsoString();
        historyModel.type = history.type;
        historyModel.typeString = this.getHistoryType(history.type);
        historyModel.changeLog = history.changeLog;
        historyModel.user = history.issuerUser!;
        historyModel.applicationClassification =
          'HISTORY_APPLICATION_CLASS_TYPE_' + history.applicationClassification;
        historyModel.applicationId =
          history.mobileApplication ? history.mobileApplication.applicationId : '';
        this.historyList.push(historyModel);
      });
      this.historyQueryModel.currentPage = requestedPage;
      this.historyQueryModel.totalNumberOfItems = result.pagingResult.totalNumberOfItems;
      this.historyQueryModel.currentNumberOfItems = result.pagingResult.currentNumberOfItems;
    });
  }

  private loadTransporterCompanies(q?: string, transporterCompanyIds?: Set<number>) {
    const allowedCompanyTypes: Company.CompanyType[] = ['TRANSPORTER'];
    if (this.componentState.isEditable()) {
      this.companyService.query({
        name: q ? Strings.undefinedOrNonEmpty(q) : undefined,
        disabled: false,
        type: Set.of(...allowedCompanyTypes),
        orders: Set.of({field: Company.OrderField.NAME, type: OrderType.ASC}),
        paging: {
          pageNumber: 1,
          numberOfItems: UiConstants.autocompletePageSize
        },
        noProgressBar: true
      }).subscribe(
        (result: QueryResult<Company.Company>) => {
          this.transporterCompanies = [];
          if (q === undefined) {
            this.editModel.transporterCompanies = [];
          }
          result.items.forEach(c => {
            if (c) {
              const item = new CompanyItem();
              item.id = c.id;
              item.text = c.name;
              item.disabled = c.disabled;
              this.transporterCompanies.push(item);
              if (q === undefined && transporterCompanyIds && transporterCompanyIds.contains(item.id)) {
                this.editModel.transporterCompanies.push(item);
              }
            }
          });
          if (q === undefined && transporterCompanyIds && transporterCompanyIds.size !== this.editModel.transporterCompanies.length) {
            this.companyService.query({
              id: transporterCompanyIds
            }).subscribe(
              (result: QueryResult<Company.Company>) => {
                this.editModel.transporterCompanies = [];
                result.items.forEach(c => {
                  if (c) {
                    const item = new CompanyItem();
                    item.id = c.id;
                    item.text = c.name;
                    item.disabled = c.disabled;
                    this.editModel.transporterCompanies.push(item);
                  }
                });
              });
          }
        });
    }
    else if (this.componentState.isDetailView() && transporterCompanyIds && transporterCompanyIds.size > 0) {
      this.companyService.query({
        id: transporterCompanyIds
      }).subscribe(
        (result: QueryResult<Company.Company>) => {
          this.detailModel.transporterCompanies
            = result.items.toArray().map(c => ({id: c.id, text: c.name, disabled: c.disabled}));
        });
    }
  }

  orderBy(field: Company.OrderField) {
    this.historyQueryModel.onOrderFieldChanged(field);
    this.loadHistoryList(1);
  }

  pageChanged(selectedPage: number) {
    this.loadHistoryList(selectedPage);
  }

  itemsPerPageChanged(itemsPerPage: number) {
    this.historyQueryModel.itemsPerPage = itemsPerPage;
    this.loadHistoryList(1);
  }

  getHistoryType(selectedType: CompanyHistoryType): string {
    let text = '';
    this.historyTypes.forEach( (type: CompanyHistoryTypeItem) => {
      if (type.id === selectedType) {
        text = type.text;
      }
    });
    return text;
  }

  private loadHistoryTypes(completion: () => void) {
    this.historyTypes = [];
    Company.companyHistoryTypes.forEach((type) => {
      const item = {
        id: type.type,
        text: '....'
      };
      this.historyTypes.push(item);
      this.translateService.get(type.stringKey).subscribe((text: string) => {
        item.text = text;
      })
    });
    completion()
  }

}

// Used for create/edit views
export class CompanyEditModel {
  name: string = '';
  externalId: string = '';
  type: TypeModel = new TypeModel();
  comment: string = '';
  postalAddress: AddressModel.PostalAddressModel;
  taxNumber: HuTaxNumber = new HuTaxNumber();
  euTaxNumber: EuVatNumber = new EuVatNumber();
  emailAddress: string = '';
  phoneNumber: string = '';
  forceRemove: boolean = false;
  transporterCompanies: OptionItem<number>[] = [];

  constructor() {
    this.postalAddress = new AddressModel.PostalAddressModel();
    this.postalAddress.type = AddressModel.PostalAddressModelType.COMPLEX;
  }

}

// Used for detail view
export class CompanyDetailModel {
  id: number;
  name: string = '';
  externalId: string = '';
  type: TypeModel = new TypeModel();
  comment: string = '';
  postalAddress: string = '';
  taxNumber: string = '';
  euTaxNumber: string = '';
  emailAddress: string = '';
  phoneNumber: string = '';
  transporterCompanies: OptionItem<number>[] = [];
}

// RoleItem for multiselect dropdown
export interface RoleItem extends OptionItem<number> {
}

export interface CompanyFieldErrorMap {
  external_id?: FieldError;
  tax_number?: FieldError;
  transporter_company_ids?: FieldError;
}

export type CompanyType = 'DEMANDER' | 'TRANSPORTER' | 'UNKNOWN';

export class TypeModel {
  id: CompanyType = 'UNKNOWN';
  text: string = '';
}

class CompanyHistoryModel extends HistoryBaseModel {
  type: CompanyHistoryType;
}

export class CompanyHistoryTypeItem extends OptionItem<CompanyHistoryType> {
}
