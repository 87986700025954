/* eslint-disable */
import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild, } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Transition, UIRouter } from '@uirouter/angular';
import { combineLatest, Observable, Subject } from 'rxjs';
import { MasterDataRecord, MasterDataRecordService } from '../../../../lib/masterdata/master-data-record.service';
import { MasterData, MasterDataService } from '../../../../lib/masterdata/master-data.service';
import { MasterDataRecordBase } from '../masterdata-record-base/masterdata-record-base-view';
import { MasterDataRecordBaseComponent } from '../masterdata-record-base/masterdata-record-base.component';
import { StateName } from '../../../../app.state-names';
import { FieldValidationError, } from '../../../../lib/util/services';
import { FormRecordContainer } from '../../../form/form-record/form-record-container';
import { FormRecordContainerComponent } from '../../../form/form-record/form-record-container.component';
import { Form } from '../../../../lib/form/form.service';
import { ConfigurationService, } from '../../../../lib/core-ext/configuration.service';
import { StringKey } from '../../../../app.string-keys';
import { TranslateService } from '@ngx-translate/core';
import { ToasterService } from '../../../../fork/angular2-toaster/angular2-toaster';
import { UiConstants } from '../../../../util/core-utils';
import { BreadcrumbParent } from '../../../../shared/breadcrumb/breadcrumb/breadcrumb.component';
import { ConfirmLeaveModalComponent } from '../../../../shared/confirm-leave-modal/confirm-leave-modal.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Strings } from '../../../../lib/util/strings';
import { RightModel } from '../../../../app.rights';
import { GrantedPermissionSetResolver, RightResolver, RightService } from '../../../../lib/right.service';
import { UploadErrorLocalizer } from '../../../../util/upload-error-localizer';
import { UploadMimeType } from '../../../../shared/file-upload/upload-mime-type';
import { MasterDataRecordRightModel } from '../../../../lib/masterdata/master-data-record-right.model';
import { Set } from 'immutable';
import { OperationRights } from '../../../../app.right-definitions';

/* eslint-enable */

@Component({
  selector: 'app-masterdata-record-edit',
  templateUrl: 'masterdata-record-edit.component.html',
  styleUrls: ['masterdata-record-edit.component.scss'],
})
export class MasterDataRecordEditComponent implements OnInit, AfterViewInit, OnDestroy {
  UploadMimeType = UploadMimeType;

  @ViewChild(MasterDataRecordBaseComponent, {static: true})
  baseView: MasterDataRecordBase.View;

  @ViewChild(FormRecordContainerComponent, {static: true})
  formRecordContainerView: FormRecordContainer.View;

  @ViewChild('f', {static: true})
  fForm: NgForm;

  forceSaveButton: boolean = false;

  masterDataId: number;
  masterDataRecordId: number;
  breadcrumbSelf: string;
  compactSidebar: boolean = document.querySelector('body')!.classList.contains('sidebar-compact');
  breadcrumbParents: BreadcrumbParent[] = [];
  masterdataName: string;
  masterdataRequiredId: string = '';
  masterDataRecordRightModel: MasterDataRecordRightModel = new MasterDataRecordRightModel(GrantedPermissionSetResolver.empty());
  rightModel: RightModel = RightModel.empty();

  deregisterWarningModal;
  saveButtonClicked: boolean = false;
  navigateOnSubmit: boolean = false;

  private combinedResult?: CombinedResult;

  constructor(
    private toasterService: ToasterService,
    private translateService: TranslateService,
    private modalService: BsModalService,
    private configurationService: ConfigurationService,
    private masterDataService: MasterDataService,
    private masterDataRecordService: MasterDataRecordService,
    private uploadErrorLocalizer: UploadErrorLocalizer,
    private rightService: RightService,
    private uiRouter: UIRouter,
    private transition: Transition) {
    this.masterDataId = this.transition.params().masterDataId;
    this.masterDataRecordId = this.transition.params().masterDataRecordId;
  }

  ngOnInit() {
    const context = this;
    this.deregisterWarningModal = this.uiRouter.transitionService.onStart({}, function (transition) {
      if (context.saveButtonClicked
        || transition.targetState().name() === StateName.CONNECTION_ERROR
        || transition.targetState().name() === StateName.SERVER_ERROR
        || transition.targetState().name() === StateName.LOGIN) {
        return true;
      }
      else {
        const subject = new Subject<boolean>();
        const modal = context.modalService.show(ConfirmLeaveModalComponent, {backdrop: 'static', keyboard: false});
        modal.content!.subject = subject;
        return subject.asObservable().toPromise();
      }
    });
    this.loadRightModels();
  }

  private loadRightModels() {
    this.rightService.getRightResolver().subscribe(
      (resolver: RightResolver) => {
        this.rightModel = RightModel.of(resolver);
      }
    );
  }

  ngAfterViewInit(): void {
    this.createCombinedObservable().subscribe(
      (result: CombinedResult) => {
        this.breadcrumbSelf = result.masterDataRecord.name ? result.masterDataRecord.name : result.masterDataRecord.externalId!;
        this.masterdataName = result.masterData.name;
        this.masterdataRequiredId = result.masterData.clientRequiredIdField;
        this.masterDataRecordRightModel
          = new MasterDataRecordRightModel(GrantedPermissionSetResolver.byGrantedRights(result.masterDataRecord.grantedRights));
        this.baseView.loadMasterDataRecord({
          masterDataRecord: result.masterDataRecord
        });
        this.formRecordContainerView.loadFormRecord({
          configuration: this.configurationService.getConfiguration(),
          form: result.form,
          record: result.masterDataRecord.formRecord,
        });
        this.combinedResult = result;
        this.forceSaveButton = !Form.Forms.hasVisibleGroup(result.form);
      },
      () => {
      },
      () => {
        this.translateService.get('MENU_NAVBAR_MASTERDATA_RECORD').subscribe(
          (result: string) => {
            this.breadcrumbParents.push({name: result, uiSref: StateName.MASTERDATA_DASHBOARD});
          }
        );
        this.breadcrumbParents.push({
          name: this.masterdataName,
          uiSref: StateName.MASTERDATA_RECORD_LIST,
          uiParam: {id: this.masterDataId}
        });
      }
    );
  }

  updateMasterDataRecord() {
    this.baseView.getFormGroup().get('ownerCustomer')!.updateValueAndValidity();
    if (!this.combinedResult) {
      return;
    }
    if (this.baseView.hasLocalFieldError() || this.formRecordContainerView.hasLocalFieldError()) {
      this.showValidationErrorToast();
      return;
    }
    if (this.formRecordContainerView.validateWithInterrupt()) {
      return;
    }
    const model = this.baseView.getModel();
    const formModel = this.formRecordContainerView.createModel();
    this.masterDataRecordService.update({
      withFormRecord: false,
      masterDataId: this.masterDataId,
      masterDataRecordId: this.masterDataRecordId,
      name: Strings.undefinedOrNonEmpty(model.name),
      externalId: Strings.undefinedOrNonEmpty(model.externalId),
      description: model.description,
      ownerUserIds: model.userIds,
      ownerGroupIds: model.userGroupIds,
      ownerCustomerRecordId: model.ownerCustomerRecord ? model.ownerCustomerRecord.id : undefined,
      ownerContactLocationId: model.ownerContactLocation ? model.ownerContactLocation.id : undefined,
      formRecord: {
        version: this.combinedResult.masterDataRecord.formRecord!.version,
        fields: formModel.fields
      }
    }).subscribe(
      (masterDataRecord: MasterDataRecord.MasterDataRecord) => {
        this.saveButtonClicked = true;
        if (this.navigateOnSubmit) {
          this.uiRouter.stateService.go(StateName.MASTERDATA_RECORD_LIST, {
            id: masterDataRecord.masterDataId
          });
        }
        else {
          this.toasterService.pop({
            timeout: UiConstants.ToastTimeoutShort,
            type: UiConstants.toastTypeSuccess,
            title: this.translateService.instant(StringKey.COMMON_SUCCESS),
            body: this.translateService.instant(StringKey.DEVICE_EDIT_MESSAGE)
          });
          this.ngAfterViewInit();
        }
      },
      (error) => {
        if (error instanceof FieldValidationError) {
          this.baseView.onFieldValidationError(error.withForm(this.fForm));
          this.showValidationErrorToast();
        }
      }
    );
  }

  private createCombinedObservable(): Observable<CombinedResult> {
    const rights = Set.of(
      OperationRights.MASTER_DATA_RECORD_CREATE
    );
    return combineLatest(
      this.masterDataService.get({
        masterDataId: this.masterDataId,
        withForm: true
      }),
      this.masterDataRecordService.get({
        withFormRecord: true,
        masterDataId: this.masterDataId,
        masterDataRecordId: this.masterDataRecordId,
        rights: rights
      }),
      (
        masterData: MasterData.MasterData,
        masterDataRecord: MasterDataRecord.MasterDataRecord) => {
        return {
          form: masterData.form!,
          masterData: masterData,
          masterDataRecord: masterDataRecord
        }
      }
    );
  }

  private showValidationErrorToast() {
    this.toasterService.pop({
      timeout: UiConstants.ToastTimeoutLong,
      type: UiConstants.toastTypeError,
      title: this.translateService.instant(StringKey.COMMON_FORM_VALIDATION_ERROR_TOAST_TITLE),
      body: this.translateService.instant(StringKey.COMMON_FORM_VALIDATION_ERROR_TOAST_MESSAGE)
    });
  }

  submit(navigateOnSubmit: boolean) {
    this.navigateOnSubmit = navigateOnSubmit;
  }

  ngOnDestroy() {
    this.deregisterWarningModal();
  }

}

interface CombinedResult {
  form: Form.Form;
  masterData: MasterData.MasterData;
  masterDataRecord: MasterDataRecord.MasterDataRecord;
}
