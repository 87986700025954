import { Injectable } from '@angular/core';
import { MultiselectProvider } from '../multiselect-provider';
import { Observable, Observer } from 'rxjs';
import { MultiselectOptionItemWithData, UiConstants } from '../../util/core-utils';
import { OrderType, QueryResult } from '../util/services';
import { Set } from 'immutable';
import { MasterData, MasterDataService } from './master-data.service';

@Injectable()
export class MasterDataMultiselectProvider implements MultiselectProvider {

  private multiselectQueryRequest: MasterData.QueryRequest = {
    paging: {
      pageNumber: 1,
      numberOfItems: UiConstants.autocompletePageSize
    },
    noProgressBar: true,
    fields: Set.of('id', 'name', 'external_id', 'client_required_id_field', 'disabled'),
    orders: Set.of({field: MasterData.OrderField.NAME, type: OrderType.ASC})
  };

  constructor(private masterDataService: MasterDataService) {
  }

  load(queryRequest: MasterData.QueryRequest): Observable<MultiselectOptionItemWithData<number>[]> {
    return Observable.create((observer: Observer<MultiselectOptionItemWithData<number>[]>) => {
      this.masterDataService.query(queryRequest).subscribe((result: QueryResult<MasterData.MasterData>) => {
          observer.next(result.items.toArray().map(item => this.toMultiselectOptionItem(item!)));
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  loadAll(predicate?: string): Observable<MultiselectOptionItemWithData<number>[]> {
    const queryRequest = Object.assign({}, this.multiselectQueryRequest);
    queryRequest.queryText = predicate;
    return this.load(queryRequest);
  }

  loadActive(predicate?: string): Observable<MultiselectOptionItemWithData<number>[]> {
    const queryRequest = Object.assign({}, this.multiselectQueryRequest);
    queryRequest.queryText = predicate;
    queryRequest.disabled = false;
    return this.load(queryRequest);
  }

  getById(id: number): Observable<MultiselectOptionItemWithData<number>> {
    return Observable.create((observer: Observer<MultiselectOptionItemWithData<number>>) => {
      this.masterDataService.query({
        masterDataIdSet: Set.of(id),
        paging: {
          numberOfItems: 1,
          pageNumber: 1
        }
      }).subscribe((result: QueryResult<MasterData.MasterData>) => {
          observer.next(this.toMultiselectOptionItem(result.items[0]!));
        },
        (error: Error) => {
          observer.error(error);
        },
        () => {
          observer.complete();
        });
    });
  }

  getByIds(ids: number[]): Observable<MultiselectOptionItemWithData<number>[]> {
    const queryRequest = Object.assign([], this.multiselectQueryRequest);
    queryRequest.masterDataIdSet = Set.of(...ids);
    return this.load(queryRequest);
  }

  toMultiselectOptionItem(item: MasterData.MasterData): MultiselectOptionItemWithData<number> {
    const ret = new MultiselectOptionItemWithData<number>();
    ret.id = item.masterDataId;
    ret.itemName = item.name ? item.name : item.externalId!;
    ret.disabled = item.disabled;
    ret.data = item.clientRequiredIdField;
    return ret;
  }

}
